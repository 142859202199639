import { buffer, createEmpty, createOrUpdateFromCoordinate } from 'ol/extent';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import { fromLonLat } from 'ol/proj.js';

export class GeoCalc {
  map: Map;

  constructor(map: Map) {
    this.map = map;
  }

  entitiesInArea(coordinates: Array<number>, distance: number) {
    const entities = {};
    const mapDistance = distance; // * this.map.getView().getResolution();
    const extent = createEmpty();
    createOrUpdateFromCoordinate(fromLonLat(coordinates), extent);
    buffer(extent, mapDistance, extent);
    this.map.getLayers().forEach(layer => {
      if (layer instanceof VectorLayer) {
        const layerName = layer.get('name');
        const source = layer.getSource();
        const features = source.getFeaturesInExtent(extent);
        entities[layerName] = features.map(ftr => {
          if (ftr.getProperties().features) {
            return ftr.getProperties().features[0].getProperties();
          }
          return ftr.getProperties();
        });
      }
    });
    return entities;
  }
}
