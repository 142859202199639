import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeElapsed',
})
export class TimeElapsedPipe implements PipeTransform {
  transform(time: string): number {
    const now = new Date();
    const date = new Date(time);
    const elapsed = now.getTime() - date.getTime();

    const duration = Math.floor(elapsed / (60 * 1000));
    return duration;
  }
}
