/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { EntityActions, EntityUIQuery, QueryEntity } from '@datorama/akita';
import { LayerType } from '@wc/core';
import { ModifiedEntities, RemovedEntities, TransitBusStoreEntity, TransitLayerType } from '@wc/wc-models/src';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnitsUIState } from '../entities/units/units.store';
import { TransitBusState, TransitFixedRouteBusStore } from './transit_fixed_route_bus.store';

@Injectable({ providedIn: 'root' })
export class TransitFixedRouteBusQuery extends QueryEntity<TransitBusState> {
  ui!: EntityUIQuery<UnitsUIState>;
  constructor(store: TransitFixedRouteBusStore) {
    super(store);
    this.createUIQuery();
  }

  get modifiedTransitFixedRouteBuses$(): Observable<ModifiedEntities> {
    return this.selectEntityAction([EntityActions.Update, EntityActions.Add]).pipe(
      map(({ ids }) => ids.map(id => this.getEntity(id)).filter(e => !!e) as TransitBusStoreEntity[]),
      map(TransitBuses => ({
        [TransitLayerType.TransitFixedRouteBus]: TransitBuses,
      }))
    );
  }

  get removedBuses$(): Observable<RemovedEntities> {
    return this.selectEntityAction([EntityActions.Remove]).pipe(map(({ ids }) => ({ [LayerType.Unit]: ids })));
  }

  get busesAddedQuery$() {
    return this.selectEntityAction([EntityActions.Add]); //);
  }

  get visibleBuses() {
    return this.getAll({ filterBy: bus => bus.show }).map(bus => bus.id as number);
  }
}
