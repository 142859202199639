<mat-form-field
  [floatLabel]="fieldData?.floatLabel"
  [class.invalid-field]="formControl.invalid && formControl.touched"
  [class.tablet-mode]="isTabletMode">
  <mat-label class="field-label">
    <span class="required-section" *ngIf="fieldData.required">*</span>
    {{ fieldData?.label | translate }}
  </mat-label>
  <mat-select
    multiple
    data-focus
    disableOptionCentering
    [errorStateMatcher]="matcher"
    [formControl]="$any(fieldFormControl)"
    [placeholder]="fieldData.placeholder | translate"
    panelClass="select-dropdown"
    [panelClass]="[
      isTabletMode ? 'select-dropdown tablet-mode' : 'select-dropdown',
      uiStore.isDirectionRtl ? 'language-rtl' : ''
    ]"
    [disabled]="fieldData.disabled"
    (selectionChange)="changed()">
    <mat-option *ngFor="let option of fieldData.options" [value]="option.value" [disabled]="option.disabled">
      {{ option.displayName | translate }}
    </mat-option>
  </mat-select>

  <mat-error>
    {{ 'validationErrorMessages.' + validationError | translate }}
  </mat-error>
</mat-form-field>
