import { Injectable } from '@angular/core';
import { ElectronService as electron } from 'ngx-electron';

@Injectable({
  providedIn: 'root',
})
export class ElectronService extends electron {
  constructor() {
    super();
  }

  public context = window['electron'] || { appVersion: () => '0.0.0' }; // Set default version for web e2e testing
}
