<div class="media-gallery-component" [class.tablet-mode]="isTabletMode">
  <ng-container *ngIf="!isEditMode && mediaItemsList.length === 0">
    <ng-template [ngTemplateOutlet]="emptyMedia"></ng-template>
  </ng-container>

  <ng-container *ngIf="isEditMode">
    <div
      [wcTooltipText]="'tooltip.pasteImage' | translate"
      [wcTooltipIconNames]="tooltipIconsNames"
      [wcTooltipDisabled]="disableTooltip"
      wcTooltipPlacement="top"
      class="media-gallery-component__drop-container"
      [class.media-gallery-component__selected]="mediaSectionSelected"
      [class.media-gallery-component__drop-container--error]="dropFileError !== undefined"
      (click)="toggleSelectedSectionState()">
      <div *ngIf="!isTabletMode" (click)="openBrowseFile($event)" class="media-gallery-component__image-placeholder">
        <wc-icon
          class="media-gallery-component__upload-image-icon"
          *ngIf="!isOneFileLimit || (isOneFileLimit && mediaItemsList.length === 0); else singleImage"
          iconName="cloud-arrow-up"></wc-icon>
        <ng-template #singleImage>
          <wc-icon
            (click)="removeSingleImage($event)"
            class="media-gallery-component__remove-image-icon"
            iconName="times-circle"></wc-icon>
          <wc-media-thumbnail
            [hideNewBadge]="true"
            [isTabletMode]="isTabletMode"
            [mediaObj]="mediaItemsList[0]"
            [enableHandelError]="enableHandelError"
            [renewMediaUrlFunc]="renewMediaUrl"
            (mediaDeleted)="mediaDeleted(mediaItemsList[0])">
          </wc-media-thumbnail>
        </ng-template>
      </div>

      <wc-drop-file
        #dropFile
        *ngIf="isEditMode"
        [maxFileSize]="maxFileSize"
        [supportedFileTypes]="supportedFileTypes"
        [isOneFileLimit]="isOneFileLimit"
        [isTabletMode]="isTabletMode"
        [isPasteEnabled]="mediaSectionSelected"
        [currentFilesSize]="currentTotalFilesSize"
        (dropFileUpdated)="uploadedFilesChange($event)"
        (uploadError)="showError($event)">
      </wc-drop-file>
    </div>
  </ng-container>

  <div
    *ngIf="(!isOneFileLimit || !isEditMode) && mediaItemsList.length > 0"
    class="media-gallery-component__media-list">
    <div *ngFor="let mediaItem of mediaItemsList | slice: 0:viewItemLimit; let i = index" (click)="openMediaModal(i)">
      <wc-media-thumbnail
        [isEditMode]="isEditMode"
        [isTabletMode]="isTabletMode"
        [mediaObj]="mediaItem"
        [renewMediaUrlFunc]="renewMediaUrl"
        (mediaDeleted)="mediaDeleted(mediaItem)">
      </wc-media-thumbnail>
    </div>
  </div>
  <div *ngIf="mediaItemsList.length > maxItemView" (click)="showAllClicked()" class="media-gallery-component__show-all">
    {{ 'showAll' | translate }}
    <i class="fas fa-solid fa-chevron-right"></i>
  </div>

  <div [ngSwitch]="dropFileError" class="media-gallery-component__error-msg">
    <ng-container *ngSwitchCase="MediaErrorType.FILE_SIZE">
      {{ 'maxFileSize' | translate: { max: maxFileSize } }}</ng-container
    >
    <ng-container *ngSwitchCase="MediaErrorType.FILE_TYPE">{{
      'mediaGallery.onlyFormatsAccepted' | translate: { formats: supportedFileTypes.join(', ') | lowercase }
    }}</ng-container>
    <ng-container *ngSwitchCase="MediaErrorType.TOTAL_FILES_SIZE">
      {{ 'mediaGallery.validationTotalSize' | translate }}</ng-container
    >
  </div>

  <ng-template #emptyMedia>
    <div class="empty-state-container">
      <i class="empty-state-icon-media"></i>
      <div class="empty-state-message">
        {{ 'emptyStates.noMediaMessage' | translate }}
      </div>
      <div class="empty-state-action">
        {{ 'emptyStates.noMediaAction' | translate }}
      </div>
    </div>
  </ng-template>
</div>
