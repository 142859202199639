import { Pipe, PipeTransform } from '@angular/core';
import { FormArray } from '@angular/forms';
import { IncidentStore, LaneType } from '@wc/core';
import { SelectOption } from '../form-controls/form-models';
import { RoadEventLane } from '../form-fields-components/affected-lanes/affected-lanes.component';

@Pipe({
  name: 'laneOptions',
  pure: true,
})
export class LaneOptionsPipe implements PipeTransform {
  constructor(private incidentStore: IncidentStore) {}

  selectOptions = [...[], ...this.incidentStore.laneTypesOptions];
  lanes: RoadEventLane[] = [];

  transform(lane: any, lanes: FormArray, isOneDirection: boolean) {
    this.lanes = lanes.value;
    if (!isOneDirection) {
      const laneOptions = this.selectOptions.filter(option => {
        switch (option.value) {
          case LaneType.RightShoulder:
          case LaneType.LeftShoulder:
          case LaneType.Gore: {
            return lane.type === option.value;
          }
          default:
            return true;
        }
      });

      return {
        required: true,
        label: 'laneType',
        options: laneOptions,
      };
    }

    return this.getOptions(lane.type, lane.positionIndex);
  }

  getPlainLaneTypes() {
    this.selectOptions.filter(option => {
      switch (option.value) {
        case LaneType.RightShoulder:
        case LaneType.LeftShoulder:
        case LaneType.Gore: {
          return false;
        }
        default:
          return true;
      }
    });
  }

  getOptions(laneType, positionIndex) {
    const laneOptions: SelectOption[] = [];
    const byRight = this.getOptionByType(LaneType.RightLane);
    const byLeft = this.getOptionByType(LaneType.LeftLane);
    const byCenter = this.getOptionByType(LaneType.CentralLane);
    const byHov = this.getOptionByType(LaneType.Hov);
    const byExpress = this.getOptionByType(LaneType.Express);
    const byTurnRight = this.getOptionByType(LaneType.TurnRight);
    const byTurnLeft = this.getOptionByType(LaneType.TurnLeft);

    switch (laneType) {
      case LaneType.RightShoulder:
      case LaneType.LeftShoulder:
      case LaneType.Gore: {
        const laneOption: SelectOption = this.selectOptions.find(option => option.value === laneType) as SelectOption;
        laneOption.disabled = true;
        laneOptions.push(laneOption);
        return {
          required: true,
          label: 'laneType',
          options: laneOptions,
        };
      }

      case LaneType.TurnLeft:
      case LaneType.TurnRight:
      case LaneType.RightLane:
      case LaneType.LeftLane: {
        const laneOption = this.selectOptions.filter(
          option =>
            option.value === LaneType.Hov || option.value === LaneType.Express || option.value === LaneType.CentralLane
        );
        if (laneType === LaneType.RightLane || laneType === LaneType.TurnRight)
          if (byTurnRight && !this.isLaneExists(LaneType.TurnRight)) laneOptions.push(byTurnRight);
          else if (byRight && this.isLaneExists(LaneType.TurnRight)) laneOptions.push(byRight);
        if (laneType === LaneType.LeftLane || laneType === LaneType.TurnLeft)
          if (byTurnLeft && !this.isLaneExists(LaneType.TurnLeft)) laneOptions.push(byTurnLeft);
          else if (byLeft && !this.isLaneExists(LaneType.LeftLane)) laneOptions.push(byLeft);

        laneOptions.push(...laneOption);
        break;
      }
      case LaneType.CentralLane: {
        const canAddRightLane =
          !this.isLaneExists(LaneType.RightLane) && this.getMaxLaneIndex(LaneType.CentralLane) === positionIndex;
        const canAddLeftLane =
          !this.isLaneExists(LaneType.LeftLane) && this.getMinLaneIndex(LaneType.CentralLane) === positionIndex;
        const laneOption = this.selectOptions.filter(
          option => option.value === LaneType.Hov || option.value === LaneType.Express
        );
        if (canAddRightLane && byRight && byTurnRight) laneOptions.push(byRight, byTurnRight);
        if (canAddLeftLane && byLeft && byTurnLeft) laneOptions.push(byLeft, byTurnLeft);
        laneOptions.push(...laneOption);

        break;
      }
      case LaneType.Express:
      case LaneType.Hov: {
        const canAddRightLane =
          !this.isLaneExists(LaneType.RightLane) && this.getMaxLaneIndex(LaneType.CentralLane) <= positionIndex;
        const canAddLeftLane =
          !this.isLaneExists(LaneType.LeftLane) && this.getMinLaneIndex(LaneType.CentralLane) >= positionIndex;
        let canAddCenterLane = true;
        if (
          this.isLaneExists(LaneType.RightLane) ||
          (this.isLaneExists(LaneType.LeftLane) &&
            this.getMinLaneIndex(LaneType.LeftLane) < positionIndex &&
            this.getMinLaneIndex(LaneType.RightLane) > positionIndex)
        ) {
          canAddCenterLane = false;
        }
        // const byType =this.getOptionByType(LaneType.RightLane)

        if (canAddRightLane && byRight) laneOptions.push(byRight);
        if (canAddLeftLane && byLeft) laneOptions.push(byLeft);
        if (canAddCenterLane && byCenter) laneOptions.push(byCenter);
        if (laneType === LaneType.Express && byHov) laneOptions.push(byHov);
        if (laneType === LaneType.Hov && byExpress) laneOptions.push(byExpress);
        break;
      }
      default: {
        if (byRight) laneOptions.push(byRight);
        if (byLeft) laneOptions.push(byLeft);
        if (byCenter) laneOptions.push(byCenter);
        if (byHov) laneOptions.push(byHov);
        if (byExpress) laneOptions.push(byExpress);
      }
    }

    laneOptions.forEach(option => {
      option.selected = false;
    });
    const currentOption = this.getOptionByType(laneType);
    if (currentOption) {
      currentOption.selected = true;
      laneOptions.unshift(currentOption);
    }

    return {
      required: true,
      label: 'laneType',
      options: laneOptions,
    };
  }

  getOptionByType(laneType: LaneType) {
    return this.selectOptions.find(option => option.value === laneType);
  }

  isLaneExists(laneType: LaneType) {
    const isLane = this.lanes.find(lane => lane.type === laneType);
    if (isLane) return true;
    return false;
  }

  getMaxLaneIndex(laneType: LaneType) {
    const lanesArray = this.lanes.filter(lane => lane.type === laneType);
    if (lanesArray && lanesArray.length > 0) {
      return lanesArray[lanesArray.length - 1].positionIndex;
    } else {
      return -1;
    }
  }
  getMinLaneIndex(laneType: LaneType) {
    const lanesArray = this.lanes.filter(lane => lane.type === laneType);
    if (lanesArray && lanesArray.length > 0) {
      return lanesArray[0].positionIndex;
    } else {
      return 10;
    }
  }
}
