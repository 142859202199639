import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { TranslateModule } from '@ngx-translate/core';
import { DurationComponent } from './duration.component';
import { NumberOnlyComponentModule } from '../numbers-only-field/number-only.component.module';

const Materials: any[] = [];

@NgModule({
  declarations: [DurationComponent],
  imports: [CommonModule, WcBaseControlFieldModule, TranslateModule, Materials, NumberOnlyComponentModule],
  providers: [],
  exports: [DurationComponent],
})
export class DurationComponentModule {}
