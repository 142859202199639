import { LayerType } from '@wc/core/models';
import { LayerVisibility, TransitLayerType } from '@wc/wc-models';

export const TransitConfig: { LayersVisibility: LayerVisibility[] } = {
  LayersVisibility: [
    { layerTypeName: TransitLayerType.TransitRoute, layerName: 'transit_fixed_routes', checked: true },
    {
      layerTypeName: TransitLayerType.TransitFixedRouteBus,
      layerName: 'transit_fixed_route_bus_unit',
      checked: true,
    },
    {
      layerTypeName: TransitLayerType.TransitBusStop,
      layerName: 'transit_fixed_stops',
      checked: true,
    },
    { layerTypeName: LayerType.Unit, layerName: 'transit_demand_response_unit', checked: true },
    { layerTypeName: LayerType.Unit, layerName: 'transit_on_demand_unit', checked: true },
  ],
};
