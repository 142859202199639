import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HeapAnalyticsService, MediaService } from '@wc-core';
import { Media, SplitIOService } from '@wc/core';
import { MediaApiService } from '@wc/wc-core/src/lib/services/media-api.service';
import { FILE_SUPPORTED_FORMATS, MediaFilterType } from '@wc/wc-models/src/lib/enums/media';
import { cloneDeep } from 'lodash';
import { Observable, OperatorFunction } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { IconClassName } from '../../../../wc-models/src/lib/types/icon-class-names-type';
import { DropFileComponent, MAX_FILE_SIZE, MediaErrorType } from '../drop-file/drop-file.component';
import { MediaFullScreenComponent } from '../media-full-screen/media-full-screen.component';

@Component({
  selector: 'wc-media-section',
  templateUrl: './media-section.component.html',
  styleUrls: ['./media-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaSectionComponent implements OnInit {
  maxItemView = 9;
  MediaErrorType = MediaErrorType;
  dropFileError?: MediaErrorType;
  mediaItemsList: Array<Media | File> = [];
  currentTotalFilesSize = 0;
  mediaSectionSelected = false;
  tooltipIconsNames: IconClassName[] = ['letter-v'];
  @ViewChild('dropFile') dropFileComponent?: DropFileComponent;

  @Input() isTabletMode = false;
  @Input() isEditMode = false;
  @Input() viewItemLimit = this.maxItemView;
  @Input() isOneFileLimit = false;
  @Input() enableHandelError = true;
  @Input() maxFileSize = MAX_FILE_SIZE;
  @Input() renewMediaUrl!: (args) => Observable<string | undefined>;
  @Input() supportedFileTypes: FILE_SUPPORTED_FORMATS[] = Object.values(FILE_SUPPORTED_FORMATS);
  @Output() uploadedFile = new EventEmitter<File>();
  @Output() deletedMedia = new EventEmitter();

  @Input() set media(value: Array<Media | File>) {
    if (!value) return;
    this.mediaItemsList = cloneDeep(value) || [];
    this.mediaItemsList.reverse();
    this.calcCurrentTotalFilesSize(this.mediaItemsList);
    this.mediaItemsList = this.mediaService.filterMediaBySource(this.mediaItemsList, MediaFilterType.UploadedMedia);
  }

  @HostListener('dragenter', ['$event']) onDragOver(evt: Event) {
    evt.preventDefault();
    const elements = document.getElementsByClassName('media-gallery-component__image-placeholder');
    Array.from(elements).forEach(element => {
      this.render.setStyle(element, 'z-index', 0);
    });
  }

  @HostListener('window:focus', ['$event']) onFocus() {
    this.resetFocus();
  }

  get disableTooltip() {
    return !this.isEditMode;
  }

  constructor(
    public mediaApiService: MediaApiService,
    public mediaService: MediaService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private heapService: HeapAnalyticsService,
    private splitService: SplitIOService,
    private render: Renderer2
  ) {
    navigator.userAgent.includes('Windows')
      ? this.tooltipIconsNames.unshift('ctrl')
      : this.tooltipIconsNames.unshift('mac-command');
  }

  ngOnInit(): void {
    this.renewDocFiles();
  }

  resetFocus() {
    const elements = document.getElementsByClassName('media-gallery-component__image-placeholder');
    Array.from(elements).forEach(element => {
      this.render.setStyle(element, 'z-index', 2);
    });
  }

  renewDocFiles() {
    this.mediaItemsList.forEach(mediaItem => {
      if (!(mediaItem instanceof File) && mediaItem.media.isDocument) {
        this.renewMediaUrl = this.renewMediaUrl.bind(this.mediaApiService);
        this.renewMediaUrl(mediaItem.id)
          .pipe(take(1), filter(url => !!url) as OperatorFunction<string | undefined, string>)
          .subscribe(url => {
            mediaItem.media.url = url;
          });
      }
    });
  }

  uploadedFilesChange(file: File) {
    this.dropFileError = undefined;

    if (this.isOneFileLimit) {
      this.mediaItemsList = [file];
      this.currentTotalFilesSize = file.size * 0.000001;
      this.mediaApiService.pendingUploadFiles = [file];
    } else {
      this.mediaApiService.pendingUploadFiles.push(file);
      this.mediaItemsList.unshift(file);
      this.currentTotalFilesSize = this.currentTotalFilesSize + file.size * 0.000001;
    }
    this.uploadedFile.emit(file);
    this.heapService.trackUserSpecificAction('heap-media-section-file-added');
  }

  mediaDeleted(value: Media | File) {
    if (value instanceof File) {
      const indexOfSameFileName = this.mediaApiService.pendingUploadFiles.findIndex(file => file.name === value.name);
      if (indexOfSameFileName !== -1) {
        this.mediaApiService.pendingUploadFiles.splice(indexOfSameFileName, 1);
      }

      this.mediaItemsList.splice(this.mediaItemsList.indexOf(value), 1);
      this.currentTotalFilesSize = this.currentTotalFilesSize - value.size * 0.000001;
    } else {
      this.deletedMedia.emit(value.id);
      this.currentTotalFilesSize = this.currentTotalFilesSize - this.calcMediaFileSize(value.media.fileSize);
      this.mediaItemsList = this.mediaItemsList.filter(file => (file as Media).id !== value.id);
    }

    this.cdr.detectChanges();
  }

  calcCurrentTotalFilesSize(mediaList) {
    this.currentTotalFilesSize = 0;
    mediaList.forEach((_media: Media) => {
      this.currentTotalFilesSize += this.calcMediaFileSize(_media.media.fileSize);
    });
  }

  showError(error: MediaErrorType) {
    this.dropFileError = error;
    if (this.isOneFileLimit) this.mediaItemsList = [];
  }

  calcMediaFileSize(size) {
    const fileSize = Number(size) || 0;
    const fileSizeInMB = fileSize / 1024 / 1024; // In MB
    return fileSizeInMB;
  }

  openMediaModal(index: number) {
    const dialog = this.dialog.open(MediaFullScreenComponent, {
      panelClass: 'full-screen',
      maxWidth: '100%',
      data: {
        list: [...[], ...this.mediaItemsList],
        selectedIndex: index,
        isEditMode: this.isEditMode,
        isTabletMode: this.isTabletMode,
        renewUrlFunction: this.renewMediaUrl,
      },
    });

    dialog.afterClosed().subscribe((deleted: Array<Media | File>) => {
      deleted?.forEach(media => {
        this.mediaDeleted(media);
      });
    });
  }

  removeSingleImage(event: Event) {
    event.stopPropagation();
    this.mediaItemsList = [];
    this.currentTotalFilesSize = 0;
    this.mediaApiService.pendingUploadFiles = [];
    this.dropFileComponent?.resetFiles();
  }

  openBrowseFile(event: Event) {
    event.stopPropagation();
    this.dropFileComponent?.openBrowserFiles();
  }

  toggleSelectedSectionState() {
    this.mediaSectionSelected = !this.mediaSectionSelected;
  }

  showAllClicked() {
    this.openMediaModal(0);
  }
}
