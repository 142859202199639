import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { WcLetModule } from '@wc/wc-common/src/lib/directives/wc-let.module';
import { WcBaseControlFieldModule } from '../../lib/base/base-control-field.module';
import { WcTooltipModule } from '../tooltip/tooltip.module';
import { WcButtonModule } from '../wc-button/wc-button.module';
import { AutocompleteComponent } from './autocomplete.component';
import { AutocompleteOptionModule } from './components/autocomplete-option/autocomplete-option.module';

@NgModule({
  declarations: [AutocompleteComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AutocompleteOptionModule,
    MatAutocompleteModule,
    WcBaseControlFieldModule,
    MatFormFieldModule,
    TranslateModule,
    MatChipsModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    WcLetModule,
    WcButtonModule,
    WcTooltipModule,
  ],
  exports: [AutocompleteComponent],
})
export class AutocompleteModule {}
