import { Component, forwardRef, Host, Input, OnInit, SkipSelf } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';

interface SelectOption<T = any> {
  value: T;
  displayName?: string;
  icon?: string;
  selected?: boolean;
  data?: any;
  disabled?: boolean;
  hidden?: boolean;
  index?: number;
}

@Component({
  selector: 'wc-base-control',
  templateUrl: './base-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseControlComponent),
      multi: true,
    },
  ],
  styleUrls: ['./base-control.component.scss'],
})
export class BaseControlComponent implements OnInit, ControlValueAccessor {
  @Input() shouldScrollIntoView = false;
  @Input() uniqueId!: string;
  @Input() set fieldData(data) {
    if (!data) return;
    this.label = data.label;
    this.placeholder = data.placeholder;
    this.labelPosition = data.labelPosition;
    this.validators = data.validators ? data.validators : null;
    this.fieldFormControl.setValidators(this.validators);
    this.optionsList = data.options || undefined;
  }

  fieldFormControl = new FormControl();
  label?: string;
  labelPosition?: string;
  placeholder?: string;
  validators: any; // Validators[] | ValidatorFn[] | null;
  optionsList?: SelectOption[];
  validationError?: string = undefined;
  propagateChange: any = () => {}; // Noop function

  constructor(
    @Host()
    @SkipSelf()
    public formControlHost: FormGroupDirective
  ) {}

  ngOnInit() {
    this.fieldFormControl.valueChanges.subscribe(val => {
      this.propagateChange(val);
      this.validationError = this.fieldFormControl.errors ? Object.keys(this.fieldFormControl.errors)[0] : undefined;
    });
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    this.fieldFormControl.setValue(value);
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {}

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.fieldFormControl.disable() : this.fieldFormControl.enable();
  }
}
