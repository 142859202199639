<iframe
  #iFrameSecurityPixel
  *ngIf="securedIframeUrl"
  class="security-pixel"
  [id]="camera.externalId"
  [src]="securedIframeUrl">
</iframe>

<div data-cy-id="camera-thumbnail" class="camera-thumbnail" [class.tablet-mode]="isTabletMode" *ngIf="thumbnailUrl">
  <img class="camera-stream-image" [src]="thumbnailUrl" [title]="cameraTitle" [class.read-mode]="isReadMode" />
</div>
