import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HeapAnalyticsService } from '@wc-core';
import { IncidentInvolvedVehicle, IncidentStore, UiStore } from '@wc/core';
import { Color, InvolvedVehicleInput, State, STATES, VehicleBrand, VehicleCategory } from '@wc/core/models';
import { EnumToOptions } from '@wc/wc-common/src';
import { SelectOption } from '@wc/wc-models/src';
import { FormFieldData } from '../../lib/base/custom-form-control';

@Component({
  selector: 'wc-incident-involved-vehicles-control',
  templateUrl: './incident-involved-vehicles.component.html',
  styleUrls: ['./incident-involved-vehicles.component.scss'],
  providers: [],
})
export class IncidentInvolvedVehiclesComponent implements OnInit {
  @Input() defaultState!: string;
  @Input() controlName = 'involvedVehicles';

  involvedVehicles = this.fb.array([]);

  involvedVehiclesFields: {
    [fieldName in keyof InvolvedVehicleInput]: FormFieldData;
  } = {
    licensePlateNumber: {
      label: 'license',
      placeholder: 'searchLicense',
    },
    state: {
      label: 'state',
      options: this.options.states,
      placeholder: 'searchState',
    },
    color: {
      label: 'color',
      options: this.options.colors,
      placeholder: 'searchColor',
    },
    make: {
      label: 'make',
      options: this.options.brands,
      placeholder: 'searchMake',
    },
    type: {
      label: 'type',
      options: this.options.types,
      placeholder: 'searchType',
    },
  };

  get parentForm() {
    return this.formGroupDirective.form;
  }

  get options() {
    const types = EnumToOptions(VehicleCategory, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'involvedVehicleProperties.types',
      firstOptionValue: VehicleCategory.UnknownVehicleCategory,
    });
    const brands = EnumToOptions(VehicleBrand, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'involvedVehicleProperties.brands',
      firstOptionValue: VehicleBrand.UnknownVehicleBrand,
    });
    const colors = EnumToOptions(Color, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'involvedVehicleProperties.colors',
      firstOptionValue: Color.UnknownColor,
    });

    const states: SelectOption[] = Object.values(State)
      .filter(state => {
        return state !== State.UnknownState;
      })
      .map(state => ({ value: state, displayName: state }));

    states.unshift({
      value: State.UnknownState,
      displayName: this.translateService.instant(`involvedVehicleProperties.states.${State.UnknownState}`),
    });

    return { types, brands, colors, states };
  }

  constructor(
    private fb: FormBuilder,
    public uiStore: UiStore,
    public incidentStore: IncidentStore,
    private translateService: TranslateService,
    private heapService: HeapAnalyticsService,
    @Optional() private formGroupDirective: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    if (this.formGroupDirective?.form) {
      const initValue = (this.formGroupDirective?.form.get(this.controlName)?.value as IncidentInvolvedVehicle[]) || [];
      this.formGroupDirective.form.setControl(this.controlName, this.fb.array([]));
      this.involvedVehicles = this.formGroupDirective.form.get(this.controlName) as FormArray;
      initValue.forEach(vehicle => this.addInvolvedVehicle(vehicle));
    }
  }

  addInvolvedVehicle(incidentInvolvedVehicle?: IncidentInvolvedVehicle) {
    const defaultState = STATES[this.defaultState];

    const newVehicle = this.fb.group({
      licensePlateNumber: [null, [Validators.maxLength(24)]],
      state: defaultState,
      color: [null],
      make: [null],
      model: [null],
      type: [null],
      id: [null],
    });

    if (incidentInvolvedVehicle) {
      newVehicle.patchValue(incidentInvolvedVehicle);
      if (incidentInvolvedVehicle.id === -1) {
        newVehicle.disable();
      }
    } else {
      newVehicle.get('id')?.disable();
    }
    this.involvedVehicles.push(newVehicle);
    this.heapService.trackUserSpecificAction('heap-add-involved-vehicle');
  }

  removeVehicle(index) {
    this.involvedVehicles.removeAt(index);
    this.heapService.trackUserSpecificAction('heap-remove-involved-vehicle');
  }
}
