import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { WcCheckboxModule } from '@wc/features/ui/form-fields-controls/checkbox/checkbox.module';
import { TransitAutocompleteModule } from '@wc/features/ui/form-fields-controls/transit-autocomplete/transit-autocomplete.module';
import { FormatsPipesModule, WcLetModule, WcPopupCloserModule } from '@wc/wc-common/src/lib/pipes';
import { CheckboxModule } from '@wc/wc-ui/src/form-components/checkbox/checkbox.module';
import { AutocompleteModule } from '../../autocomplete/autocomplete.module';
import { WcLoaderComponentModule } from '../../miscellaneous/loader/loader.module';
import { SliderToggleControlModule } from '../../slide-toggle-control/slider-toggle-control.module';
import { WcIconModule } from '../../wc-icon/wc-icon.module';
import { TrainDelaysModule } from '../train-delays/train-delays.module';
import { AffectedServiceCauseComponent } from './affected-service-cause/affected-service-cause.component';
import { AffectedServicesTransitModule } from './transit-panel-main-tabs/route-list-tab/affected-services-transit/affected-services-transit.module';
import { RouteListTabComponent } from './transit-panel-main-tabs/route-list-tab/route-list-tab.component';
import { SelectedBusStopTabComponent } from './transit-panel-main-tabs/selected-bus-stop-tab/selected-bus-stop-tab.component';
import { SelectedRouteTabComponent } from './transit-panel-main-tabs/selected-route-tab/selected-route-tab.component';
import { SelectedUnitTabComponent } from './transit-panel-main-tabs/selected-unit-tab/selected-unit-tab.component';
import { TransitPanelComponent } from './transit-panel.component';

@NgModule({
  declarations: [
    TransitPanelComponent,
    SelectedBusStopTabComponent,
    SelectedRouteTabComponent,
    RouteListTabComponent,
    SelectedUnitTabComponent,
    AffectedServiceCauseComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormatsPipesModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatFormFieldModule,
    SliderToggleControlModule,
    TrainDelaysModule,
    MatCheckboxModule,
    MatTooltipModule,
    TransitAutocompleteModule,
    AffectedServicesTransitModule,
    WcCheckboxModule,
    WcLoaderComponentModule,
    WcLetModule,
    MatSlideToggleModule,
    WcIconModule,
    AutocompleteModule,
    WcPopupCloserModule,
    CheckboxModule,
  ],
  exports: [TransitPanelComponent],
})
export class TransitPanelModule {}
