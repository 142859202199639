import { Injectable } from '@angular/core';
import { AccountStore } from '../stores';

@Injectable({
  providedIn: 'root',
})
export class IncidentConfigInitService {
  constructor(private accountStore: AccountStore) {}

  load(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.accountStore.loadIncidentConfiguration().subscribe({
        next: () => {
          resolve(true);
        },
        error: () => {
          resolve(false);
        },
      });
    });
  }
}
