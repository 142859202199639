import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { throwIfAlreadyLoaded } from '@wc/utils';
// libs
import { MobxAngularModule } from 'mobx-angular';
// app
import { environment } from './environments/environment';
import { GraphQLModule } from './graphql.module';
import { GUARDS } from './guards';
import { RESOLVERS } from './resolvers';
import { LogService } from './services/log.service';

/**
 * DEBUGGING
 */
LogService.DEBUG.LEVEL_4 = !environment.production;

const BASE_PROVIDERS: any[] = [
  ...GUARDS,
  ...RESOLVERS,
  {
    provide: APP_BASE_HREF,
    useValue: '/',
  },
];

@NgModule({
  imports: [CommonModule, GraphQLModule, MobxAngularModule],
})
export class CoreModule {
  // configuredProviders: *required to configure WindowService and others per platform
  static forRoot(configuredProviders: Array<any>): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [...BASE_PROVIDERS, ...configuredProviders],
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
    // @Inject(PlatformLanguageToken) lang: string,
    translate: TranslateService
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
    const lang = 'en';
    // ensure default platform language is set
    translate.use(lang);
  }
}
