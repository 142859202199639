import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionModule } from '@angular/material/expansion';
import { MobxAngularModule } from 'mobx-angular';
import { AddressModalComponent } from './address-modal.component';
import { AddressModule } from '../../form-fields-components/address/address.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const Materials: any[] = [CdkAccordionModule, MatExpansionModule];
@NgModule({
  declarations: [AddressModalComponent],
  imports: [
    CommonModule,
    Materials,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MobxAngularModule,
    AddressModule,
    FlexLayoutModule,
  ],
  providers: [],
  exports: [AddressModalComponent],
  entryComponents: [AddressModalComponent],
})
export class AddressModalComponentModule {}
