import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MeasureUnitType, RegionalSetting, RoadwayStatusSegmentView } from '@wc/core/models/gql.models';
import { LayerVisibilityItem, LiveMapLayerNames } from '@wc/wc-models';
import { RoadwayStatusSegmentsSortOptions } from '@wc/wc-models/src/lib/enums/realtime_analytics';
import { ContextTypesEnum } from '../../../clustering-tooltip-container/clustering-tooltip-container.component';
import { SegmentDataViewMode } from '../../internal-components/segment-data/segment-data.component';

@Component({
  selector: 'wc-segments-list-tab',
  templateUrl: './segments-list-tab.component.html',
  styleUrls: ['./segments-list-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentsListTabComponent {
  @Input() isDirectionRtl = false;
  @Input() isShowOnMap = true;
  @Input() measureUnitType: MeasureUnitType = MeasureUnitType.Metric;
  @Input() mode: SegmentDataViewMode | null = SegmentDataViewMode.Short;
  @Input() context: ContextTypesEnum | null = ContextTypesEnum.Select;
  @Input() segments: RoadwayStatusSegmentView[] = [];
  @Input() selectedSortSegments: RoadwayStatusSegmentsSortOptions = RoadwayStatusSegmentsSortOptions.Deviation;
  @Input() regionalSettings!: RegionalSetting;
  @Input() isPortraitDesktopMode = false;
  @Output() hidePanel = new EventEmitter<boolean>();
  @Output() layersVisibilityStateChange = new EventEmitter<LayerVisibilityItem>();
  @Output() openDetailsClicked = new EventEmitter<{ segmentId: number; selectedVendorId: number }>();
  @Output() sortSelectionChanged = new EventEmitter<RoadwayStatusSegmentsSortOptions>();

  get segmentsSortOptionsArray() {
    return Object.values(RoadwayStatusSegmentsSortOptions);
  }

  showSegmentDetails(segmentVendorData: { segmentId: number; selectedVendorId: number }) {
    this.openDetailsClicked.emit(segmentVendorData);
  }

  saveSortSelectionToLocalStorage(selectedSortType: RoadwayStatusSegmentsSortOptions) {
    this.sortSelectionChanged.emit(selectedSortType);
  }

  closePanel() {
    this.hidePanel.emit();
  }

  toggleShowOnMap(event: MatCheckboxChange) {
    this.layersVisibilityStateChange.emit({
      name: LiveMapLayerNames.ROADWAY_STATUS,
      checked: event.checked,
    });
  }

  trackById(index, item) {
    if (!item) {
      return null;
    }
    return item.id;
  }
}
