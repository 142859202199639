import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { UiStore } from '@wc/core';
import { WCErrorStateMatcher } from '@wc/wc-ui/src/lib/base';
import { BaseControlFieldComponent } from '../../base';

@Component({
  selector: 'wc-number-field-control',
  templateUrl: './number-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberFieldComponent),
      multi: true,
    },
  ],
  styleUrls: ['./number-field.component.scss'],
})
export class NumberFieldComponent extends BaseControlFieldComponent {
  matcher: ErrorStateMatcher = new WCErrorStateMatcher();

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  constructor(public uiStore: UiStore) {
    super();
  }
}
