import { EntityType } from '@wc/core/models/gql.models';
import { PermissionGroupKey, PermissionGroupView } from './enums';

export const permissionGrouping: Record<PermissionGroupKey, Array<EntityType | PermissionGroupView>> = {
  GENERAL: [EntityType.Account, EntityType.Role, EntityType.User],
  EVENTS: [
    EntityType.Incident,
    PermissionGroupView.TRAFFIC_DISRUPTION,
    EntityType.ResponsePlan,
    EntityType.ResponsePlanTemplates,
    EntityType.ShareIncident,
    PermissionGroupView.SHARE_TRAFFIC_DISRUPTION,
  ],
  LIVE_MAP: [EntityType.Alias, EntityType.Camera, EntityType.Dms, EntityType.Transit, EntityType.Unit],
  ADVANCED_ANALYTICS: [PermissionGroupView.REPORTS, PermissionGroupView.HISTORY],
  FIELD_OPERATIONS: [EntityType.RouteType, EntityType.ShiftType, EntityType.Shift],
};

export const permissionGroupViews = {
  TRAFFIC_DISRUPTION: [EntityType.RoadClosure, EntityType.Construction, EntityType.SpecialEvent],
  SHARE_TRAFFIC_DISRUPTION: [EntityType.ShareRoadClosure, EntityType.ShareConstruction],
  REPORTS: [EntityType.DomoFspReport, EntityType.DomoTransitReport, EntityType.DomoIncidentReport],
  HISTORY: [EntityType.HistoricalIncident, EntityType.HistoricalShift, EntityType.HistoricalTrafficDisruption],
};

export const predefinedPermissionsOrder = [
  EntityType.Account,
  EntityType.Role,
  EntityType.User,
  EntityType.Incident,
  EntityType.RoadClosure,
  EntityType.SpecialEvent,
  EntityType.Construction,
  EntityType.RouteType,
  EntityType.ShiftType,
  EntityType.Shift,
  EntityType.Alias,
  EntityType.RoadwayStatusMetric,
  EntityType.ResponsePlan,
  EntityType.ResponsePlanTemplates,
];
