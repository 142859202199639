import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormFieldData } from '@wc/wc-ui/src/lib/base';
import moment from 'moment';
import { takeWhile } from 'rxjs/operators';
import * as Utils from '../../../../utils';
import { BaseControlFieldComponent } from '../../base/base-control-field.component';
import { SelectOption } from '../../form-controls/form-models';
import { CompareDatesValidator, PastDateValidator } from '../../form-validators';

const HALF_A_YEAR_IN_HOURS = 4380;

export enum SchedulerType {
  today = 'today',
  pastWeek = 'pastWeek',
  pastMonth = 'pastMonth',
  custom = 'custom',
}

@Component({
  selector: 'wc-scheduler',
  templateUrl: './scheduler.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SchedulerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SchedulerComponent),
      multi: true,
    },
  ],
  styleUrls: ['./scheduler.component.scss'],
})
export class SchedulerComponent extends BaseControlFieldComponent implements OnInit, OnDestroy, Validator {
  @Output() selectedSchedulerType = new EventEmitter<SchedulerType>();
  @Input() displayDaySelection = true;
  @Input() allowFutureDates = false;
  isAlive = true;
  form!: FormGroup;
  showPresetTypeOptions = false;
  showDays = false;
  SchedulerType = SchedulerType;
  isAllDay = false;

  schedulerTypesOptions: SelectOption[] = Utils.EnumToOptions(SchedulerType, {
    translateService: this.translateService,
    translateBy: 'value',
    translatePath: 'schedulerType',
    removeSort: true,
  });

  formFieldData: FormFieldData = {
    options: this.schedulerTypesOptions.map(option => ({
      ...option,
      dataCyId: option.displayName?.replace(' ', '-')?.toLowerCase() + '-radio-button',
    })),
  };

  schedulerStateForm!: FormGroup;

  days = [
    {
      displayName: 'dayOfWeek.SUNDAY',
      value: 'SUNDAY',
    },
    {
      displayName: 'dayOfWeek.MONDAY',
      value: 'MONDAY',
    },
    {
      displayName: 'dayOfWeek.TUESDAY',
      value: 'TUESDAY',
    },
    {
      displayName: 'dayOfWeek.WEDNESDAY',
      value: 'WEDNESDAY',
    },
    {
      displayName: 'dayOfWeek.THURSDAY',
      value: 'THURSDAY',
    },
    {
      displayName: 'dayOfWeek.FRIDAY',
      value: 'FRIDAY',
    },
    {
      displayName: 'dayOfWeek.SATURDAY',
      value: 'SATURDAY',
    },
  ];

  timeDateFields = {
    from: {
      label: 'startTime',
    },
    to: {
      label: 'endTime',
    },
    daysOfTheWeek: {
      label: 'schedulerForm.days',
      options: this.days,
    },
  };

  schedulerValue!: SchedulerType;

  maxSchedulerDate: Date | null = null;

  get fromTimeFormControl() {
    return this.form.get('from');
  }

  constructor(private fb: FormBuilder, private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.schedulerStateForm = this.fb.group({
      schedule: SchedulerType.today,
    });

    this.form = this.fb.group(
      {
        from: null,
        to: [null, this.allowFutureDates ? [] : PastDateValidator(true)],
        daysOfTheWeek: [],
      },
      { validators: CompareDatesValidator('from', 'to') }
    );

    this.schedulerStateForm
      .get('schedule')
      ?.valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe(this.presetChanged.bind(this));

    this.form.valueChanges.subscribe(val => {
      this.fieldFormControl.setValue(val);
      this.onChanged(this.fieldFormControl.value);
      this.onValidationChange();
      if (this.displayDaySelection && this.showDays !== this.IsTimeLineMoreThan7Days()) {
        this.showDays = !this.showDays;
        this.form.get('daysOfTheWeek')?.setValue([]);
      }
    });

    setTimeout(() => {
      this.reset();
    });

    this.fromTimeFormControl?.valueChanges
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(this.onFromTimeChanged.bind(this));
  }

  onFromTimeChanged(selectedDate: Date) {
    if (selectedDate && this.showPresetTypeOptions) {
      const fromTime = moment(selectedDate).utc(true);

      this.maxSchedulerDate = moment(fromTime).add(6, 'months').toDate();

      const toTimeFormControl = this.form.get('to');
      if (toTimeFormControl) {
        const toTime = moment(toTimeFormControl.value);
        const hourDifference = toTime.diff(fromTime, 'hours');

        if (hourDifference > HALF_A_YEAR_IN_HOURS) {
          this.maxSchedulerDate.setHours(toTime.get('h'));
          this.maxSchedulerDate.setMinutes(toTime.get('m'));
          toTimeFormControl.setValue(this.maxSchedulerDate);
        } else if (hourDifference < -HALF_A_YEAR_IN_HOURS) {
          const newDate = new Date();
          if (this.isAllDay) {
            newDate.setHours(23);
            newDate.setMinutes(59);
          }

          toTimeFormControl.setValue(newDate);
        }
      }
    }
  }

  reset() {
    this.presetChanged(SchedulerType.today);
    this.schedulerValue = SchedulerType.today;
    this.schedulerStateForm.get('schedule')?.setValue(SchedulerType.today);
  }

  presetChanged(value: SchedulerType) {
    this.schedulerStateForm.patchValue({ schedule: value }, { emitEvent: false });
    this.schedulerValue = value;
    this.showPresetTypeOptions = false;
    this.form.get('to')?.setValue(moment().toISOString());
    this.form.get('daysOfTheWeek')?.setValue([]);
    this.selectedSchedulerType.emit(value);
    switch (value) {
      case SchedulerType.today:
        this.form.get('from')?.setValue(moment().startOf('day').toISOString());
        break;

      case SchedulerType.pastWeek:
        this.form.get('from')?.setValue(moment().subtract(7, 'days').toISOString());
        break;

      case SchedulerType.pastMonth:
        this.form.get('from')?.setValue(moment().subtract(1, 'months').toISOString());
        break;

      case SchedulerType.custom: {
        this.showPresetTypeOptions = true;
        this.onFromTimeChanged(this.fromTimeFormControl?.value);
      }
    }
  }

  writeValue(val): void {
    if (!val) this.reset();
    if (val) {
      this.form.patchValue(val);
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.form.valid) {
      if (this.form.get('to')?.errors) {
        return this.form.get('to')?.errors || null;
      }
      if (this.form.get('from')?.errors) {
        return this.form.get('from')?.errors || null;
      }
    }
    return null;
  }

  IsTimeLineMoreThan7Days() {
    if (this.form.valid && this.form.get('from')?.value && this.form.get('to')?.value) {
      const fromDate = moment(this.form.get('from')?.value);
      const toDate = moment(this.form.get('to')?.value);
      const duration = moment.duration(toDate.diff(fromDate)).asDays();

      return duration > 8;
    }
    return false;
  }

  allDayCheckboxChange(checkedState: boolean) {
    if (checkedState) {
      const fromDate = this.form.get('from')?.value;
      const toDate = this.form.get('to')?.value;
      const currentFromDateValue = fromDate ? fromDate : moment().toISOString();
      const currentToDateValue = toDate ? toDate : moment().toISOString();

      this.form.get('from')?.setValue(moment(currentFromDateValue).hour(0).minute(0).toISOString());
      this.form.get('to')?.setValue(moment(currentToDateValue).hour(23).minute(59).toISOString());
    }

    this.isAllDay = checkedState;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
