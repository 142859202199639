import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { WcSelectControlModule } from '@wc/wc-ui/src';
import { AutocompleteModule } from '@wc/wc-ui/src/components/autocomplete/autocomplete.module';
import { WcCheckboxModule } from '../../form-fields-controls/checkbox/checkbox.module';
import { WcTextAreaModule } from '../../form-fields-controls/text-area/text-area.module';
import { IncidentStepOneComponent } from './incident-step-one/incident-step-one.component';
import { IncidentStepTwoComponent } from './incident-step-two/incident-step-two.component';
@NgModule({
  declarations: [IncidentStepOneComponent, IncidentStepTwoComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    WcCheckboxModule,
    WcSelectControlModule,
    MatCheckboxModule,
    WcTextAreaModule,
    AutocompleteModule,
  ],
  exports: [IncidentStepOneComponent, IncidentStepTwoComponent],
})
export class IncidentStepsModule {}
