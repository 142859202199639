import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { APP_TYPE_TOKEN } from '@wc/wc-core/src/lib/injection-tokens';
import { AppTypeUnion } from '@wc/wc-models/src';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { Camera, StreetCamera, UnitDashCamera } from '../../../../../core/models/gql.models';
import { CameraTab, DashCamView } from '../../../../../core/models/models';

export type GeneralCamera = StreetCamera & UnitDashCamera;

@Component({
  selector: 'wc-camera-slider',
  templateUrl: './camera-slider.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CameraSliderComponent),
      multi: true,
    },
  ],
  styleUrls: ['./camera-slider.component.scss'],
})
export class CameraSliderComponent implements OnInit, AfterViewInit {
  @ViewChild(SwiperDirective, { static: false }) swiperElm?: SwiperDirective;
  @Input() readMode!: boolean;
  @Input() isTabletMode!: boolean;
  camerasList: Array<Partial<StreetCamera>> = [];
  @Input() set cameras(roadEventCameras: Array<StreetCamera>) {
    this.camerasList = roadEventCameras || [];
    this.initCamerasSwiperConfig();
  }
  dashCamList: Partial<DashCamView>[] = [];
  @Input() set dashCams(dashCams: Partial<DashCamView>[]) {
    this.dashCamList = dashCams || [];
    this.initCamerasSwiperConfig();
  }
  @Input() defaultCamera!: StreetCamera;
  selectedCameraTab: CameraTab = CameraTab.CCTV;
  @Input() set cameraTab(cameraTab: CameraTab) {
    this.selectedCameraTab = cameraTab;
    this.initCamerasSwiperConfig();
  }
  cameraTabEnum = CameraTab;

  @Output() cameraSelected: EventEmitter<Camera> = new EventEmitter();
  @Output() cameraRemoved: EventEmitter<string> = new EventEmitter();

  index!: number;
  selectedIndex?: number = undefined;
  camerasSwiperConfig!: SwiperConfigInterface;

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    @Inject(APP_TYPE_TOKEN) private _appType: AppTypeUnion
  ) {}

  get appType() {
    return this._appType;
  }

  ngOnInit(): void {
    this.selectedIndex =
      this.selectedCameraTab === CameraTab.CCTV && this.defaultCamera
        ? this.camerasList.indexOf(this.defaultCamera)
        : 0;
    this.initDashCamData();
    this.initCamerasSwiperConfig();
  }

  ngAfterViewInit(): void {
    this.initCamerasSwiperConfig();
    this.swiperElm?.update();
    this.cdr.markForCheck();

    this.swipeToDefaultCameraIndex();
  }

  swipeToDefaultCameraIndex() {
    // Swipe to default camera index
    if (this.selectedCameraTab === CameraTab.CCTV && this.defaultCamera) {
      const defaultCameraIndex = this.camerasList.findIndex(cam => cam.id === this.defaultCamera.id);

      if (defaultCameraIndex !== -1) {
        this.swiperElm?.setIndex(defaultCameraIndex);
      }
    }
  }

  initCamerasSwiperConfig() {
    this.camerasSwiperConfig = {
      direction: 'horizontal',
      slidesPerView: 3,
      spaceBetween: this.isTabletMode ? 30 : 24,
      slideClass: 'camera-slide',
      navigation: {
        nextEl: '.swiper-button-next-camera',
        prevEl: '.swiper-button-prev-camera',
      },
    };
  }

  initDashCamData() {
    this.dashCamList?.forEach((dashCam, index) => {
      const cameraPosition = dashCam.position;
      if (dashCam.assignedUserName) {
        dashCam.title = `${dashCam.assignedUserName} - ${cameraPosition}`;
      } else if (dashCam.unitDisplayId && dashCam.unitVehicleType) {
        dashCam.title = `${this.translateService.instant('VehicleTypes.' + dashCam.unitVehicleType)} #${
          dashCam.unitDisplayId
        } - ${cameraPosition}`;
      }

      dashCam.index = index;
    });
  }

  slideClick(camera: StreetCamera, index) {
    this.cameraSelected.emit(camera);
    this.swiperElm?.update();
    this.selectedIndex = index;
  }

  removeCamera(id: string) {
    this.cameraRemoved.emit(id);
  }

  imgLoadError(e) {
    console.log(e);
    e.target.src = './assets/icons/missing-camera.svg';
  }
}
