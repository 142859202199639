import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CameraStreamType, Status, StreetCamera } from '@wc/core/models/gql.models';
import { APP_TYPE_TOKEN } from '@wc/wc-core/src/lib/injection-tokens';
import { UrlUploadEntityEnum } from '@wc/wc-core/src/lib/services/upload.service';
import { AppTypeUnion, CameraRekorStatuses, CameraViewerContextType, ToastrPositionClass } from '@wc/wc-models';
import { UnitedCamera } from '@wc/wc-models/src/lib/types/camera';
import { WindowService } from 'libs/core/services/window.service';
import { CamerasService } from 'libs/wc-core/src/lib/services/cameras.service';
import { ToastrAlertsService } from 'libs/wc-ui/src/services/toaster-alert.service';
import { interval, Observable, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import videojs from 'video.js';

enum CameraSourceType {
  M3U = 'application/x-mpegURL',
}

@Component({
  selector: 'wc-camera-viewer',
  templateUrl: './camera-viewer.component.html',
  styleUrls: ['./camera-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraViewerComponent implements OnDestroy {
  @ViewChild('video') target!: ElementRef;
  @Input() type: CameraSourceType = CameraSourceType.M3U;
  @Input() forceImageStream = false;
  @Input() isTabletMode = false;
  @Input() contextType = CameraViewerContextType.LiveMapModal;
  @Input() entityId: number | null = null;
  @Input() defaultCameraId: number | null = null;
  @Output() snapshotUploaded: EventEmitter<number | null> = new EventEmitter<number | null>();
  @Output() cameraStatusUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  online$: Observable<boolean> = this.windowService.online$;
  securedIframeUrl!: SafeUrl;
  cameraContextType: typeof CameraViewerContextType = CameraViewerContextType;
  cameraRekorStatuses: typeof CameraRekorStatuses = CameraRekorStatuses;
  cameraBlob!: Blob;

  /** In milliseconds **/
  @Input() set imageReloadInterval(val: number) {
    this._imageReloadInterval = val;
    this.updateCameraStream(this._camera);
  }

  @Input() set camera(camera: Partial<StreetCamera>) {
    if (!camera) return;
    this._camera = { ...camera };
    if (!this.player && camera.streamType === CameraStreamType.VideoStream) {
      setTimeout(() => {
        this.initVideoPlayer();
      });
    }
    this.showCameraStreamError = false;
    this.cameraStatusUpdated.emit(false);
    this.showInactiveCameraError = false;
    this.isCameraImageLoading = true;
    this._camera['thumbnail'] = './assets/icons/camera-loading.svg';

    if (this.isTabletMode) {
      this._imageReloadInterval = 30000;
    }

    setTimeout(() => {
      this.updateCameraStream(this._camera);
    });
  }

  _imageReloadInterval = 5000;
  _camera!: UnitedCamera;
  isCameraLoading = false;
  isCameraImageLoading = false;
  cameraStreamType = CameraStreamType;
  streamType: CameraStreamType = CameraStreamType.VideoStream;

  reloadCamerasSnapshotsInterval!: Subscription;
  getCameraImageSub!: Subscription;
  parseErrorBlobSub!: Subscription;

  get appType() {
    return this._appType;
  }

  player: videojs.Player;
  showCameraStreamError = false;
  showInactiveCameraError = false;
  private isAlive = true;

  constructor(
    public camerasService: CamerasService,
    private cdr: ChangeDetectorRef,
    private windowService: WindowService,
    private alertService: ToastrAlertsService,
    private translateService: TranslateService,
    private domSanitizer: DomSanitizer,
    @Inject(APP_TYPE_TOKEN) private _appType: AppTypeUnion
  ) {
    this.online$ = this.windowService.online$;
  }

  initVideoPlayer() {
    if (!this.target) return;
    this.player = videojs(this.target.nativeElement);

    this.player.on('error', () => {
      setTimeout(() => {
        this.player.src(this.player.currentSrc());
        this.player.load();
        this.player.play();
      }, 3000);
    });
  }

  updateCameraStream(camera: UnitedCamera) {
    if (!camera) return;

    const cameraStatus = (camera as StreetCamera)?.status;
    if (cameraStatus && cameraStatus === Status.Inactive) {
      this.isCameraLoading = false;
      this.showInactiveCameraError = true;
      this.cameraStatusUpdated.emit(true);
      this.cdr.detectChanges();
      return;
    }

    this.reloadCamerasSnapshotsInterval?.unsubscribe();
    this.streamType = camera.streamType as CameraStreamType;

    this.cdr.detectChanges();
    this.showCameraStreamError = false;
    this.cameraStatusUpdated.emit(false);

    if (camera.streamType === CameraStreamType.VideoStream) {
      if (!this.target) return;
      if (this.isTabletMode || this.forceImageStream) {
        this.changeStreamToImageStream();
        return;
      }

      this.initVideoPlayer();
      this.player.src({ src: camera.videoUrl, type: this.type });
    } else {
      this.startReloadCamerasSnapshotsInterval();
    }
    this.isCameraLoading = false;
  }

  changeStreamToImageStream() {
    this._camera.streamType = CameraStreamType.ImageStream;
    this.streamType = CameraStreamType.ImageStream;
    this.cdr.markForCheck();
    this.startReloadCamerasSnapshotsInterval(this._imageReloadInterval);
  }

  startReloadCamerasSnapshotsInterval(ms?: number) {
    this.isCameraLoading = false;
    this.reloadCamerasSnapshotsInterval?.unsubscribe();

    this.setCamerasImage();
    const seconds = interval(ms || this._imageReloadInterval);

    this.reloadCamerasSnapshotsInterval = seconds.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
      this.setCamerasImage();
    });
  }

  setCamerasImage() {
    if ((this._camera as StreetCamera).status === Status.Inactive) {
      this.showInactiveCameraError = true;
      this.cameraStatusUpdated.emit(true);
      this.cdr.markForCheck();
      this.reloadCamerasSnapshotsInterval?.unsubscribe();
      return;
    }

    const url = this.camerasService.buildImageUrl(this._camera);

    this.getCameraImageSub = this.camerasService
      .getCameraImage(url)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        blob => {
          this.cameraBlob = blob;
          this._camera.thumbnail = this.domSanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
          this.showCameraStreamError = false;
          this.cameraStatusUpdated.emit(false);
          this.showInactiveCameraError = false;
          this.isCameraImageLoading = false;
          this.cdr.markForCheck();
        },
        error => {
          this.parseErrorBlobSub = this.camerasService
            .parseErrorBlob(error)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(parsedError => {
              console.warn('getCameraImage error: ', parsedError);
              const rekorStatus = parsedError.rekorStatus;

              if (
                rekorStatus &&
                (rekorStatus === this.cameraRekorStatuses.failedToFetchImageFromUrl ||
                  rekorStatus === this.cameraRekorStatuses.failedToConnectToCameraUrl ||
                  rekorStatus === this.cameraRekorStatuses.failedToFetchJsession ||
                  rekorStatus === this.cameraRekorStatuses.backoffError)
              ) {
                this.showInactiveCameraError = true;
              }

              this.isCameraImageLoading = false;
              this._camera.thumbnail = 'error';
              this.showCameraStreamError = true;
              this.cameraStatusUpdated.emit(true);
              this.cdr.markForCheck();
            });
        }
      );
  }

  isStreetCamera(camera: UnitedCamera) {
    return (camera as StreetCamera).id !== undefined;
  }

  uploadSnapshot(file: File, endpoint: UrlUploadEntityEnum = UrlUploadEntityEnum.INCIDENT) {
    if (!this.entityId) return;

    this.camerasService
      .uploadSnapshot(file, endpoint, this.entityId)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        () => {
          this.snapshotUploaded.emit(this.entityId);
        },
        error => {
          console.error('Snapshot upload failed: ', error);
          this.alertService.error(
            this.translateService.instant('notifications.snapshotSaveFailed'),
            undefined,
            {
              positionClass:
                endpoint === UrlUploadEntityEnum.INCIDENT
                  ? ToastrPositionClass.DesktopBottomAuxiliaryPanel
                  : ToastrPositionClass.DesktopTopCenter,
              timeOut: 5000,
            },
            true
          );
        }
      );
  }

  generateSnapshotName(endpoint: UrlUploadEntityEnum = UrlUploadEntityEnum.INCIDENT): string {
    const dateTimeNow = new Date().toISOString().replaceAll(':', '_');
    const streetCamera = this._camera as StreetCamera;
    const isMainCamera = this.defaultCameraId === streetCamera.id;
    const camera = isMainCamera ? 'main_camera' : streetCamera.externalId;
    return `${endpoint}_snapshot_${dateTimeNow}_user_(${camera}).jpg`.replaceAll(' ', '_');
  }

  captureSnapshotFromVideoStream(endpoint: UrlUploadEntityEnum) {
    const canvas = document.createElement('canvas');
    const video = this.target.nativeElement;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    canvas.getContext('2d')?.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = canvas.toDataURL('image/png');
    const fileName = this.generateSnapshotName(endpoint);

    const file = this.camerasService.convertBase64ToFile(imageData, fileName);
    this.uploadSnapshot(file, endpoint);
  }

  captureSnapshotFromImageStream(endpoint: UrlUploadEntityEnum) {
    if (!this.cameraBlob) return;
    const fileName = this.generateSnapshotName(endpoint);
    const file = new File([this.cameraBlob], fileName, { type: this.cameraBlob.type, lastModified: Date.now() });

    this.uploadSnapshot(file, endpoint);
  }

  takeSnapshot(endpoint: UrlUploadEntityEnum) {
    if (!this.isStreetCamera(this._camera)) return;
    this._camera.streamType === CameraStreamType.VideoStream
      ? this.captureSnapshotFromVideoStream(endpoint)
      : this.captureSnapshotFromImageStream(endpoint);
  }

  ngOnDestroy() {
    this.isAlive = false;

    if (this.target) {
      videojs(this.target.nativeElement).dispose();
    }
  }
}
