/* eslint-disable @typescript-eslint/no-unused-vars */
import { Inject, Injectable } from '@angular/core';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import * as olMap from 'ol/Map';
import { Cluster } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { Subject, throwError } from 'rxjs';
import { wcMapToken } from '../../injection-tokens';
import { LayerPropChanges, WcLayerOptions } from '../../types';
import { WcFeature } from '../classes/wc-feature.class';

@Injectable({
  providedIn: 'root',
})
export class LayersService {
  private readonly _layersMap = new Map<string, VectorLayer>();
  private layerPropUpdated = new Subject<LayerPropChanges>();
  layerPropUpdate$ = this.layerPropUpdated.asObservable();
  private readonly _clusteredLayerSources = new Map<string, VectorSource | undefined>();
  constructor(@Inject(wcMapToken) private wcMap: olMap.default) {}

  getLayerByName(layerName: string): VectorLayer | undefined {
    return this._layersMap.get(layerName);
  }

  getClusterSourceLayerName(layerName: string): VectorSource | undefined {
    return this._clusteredLayerSources.get(layerName);
  }

  getClusteredLayersAsArray() {
    return Array.from(this._clusteredLayerSources.values());
  }

  getAllLayers(): VectorLayer[] {
    return Array.from(this._layersMap.values());
  }

  createLayer(layerName: string, options?: Partial<WcLayerOptions<string>>): VectorLayer {
    if (!layerName || typeof layerName !== 'string') {
      throwError('Please provide valid layerName');
    }
    let source: VectorSource | undefined = undefined;
    const { zIndex, clusterDistance, isVisible, maxZoom, minZoom } = options || {
      zIndex: Infinity,
      clusterDistance: 0,
      isVisible: true,
    };
    source = this.createSource();

    const vectorLayer = new VectorLayer({
      zIndex,
      source: clusterDistance ? this.createCluster(clusterDistance, source, layerName) : source,
      visible: !!isVisible,
      minZoom,
      maxZoom,
    });
    vectorLayer.set('name', layerName);
    this._layersMap.set(layerName, vectorLayer);
    this.emitLayerPropChanged({ isVisible: !!isVisible, layerName });
    return vectorLayer;
  }

  addLayerToMap(layer: VectorLayer) {
    this.wcMap.addLayer(layer);
  }

  emitLayerPropChanged(changes: LayerPropChanges): void {
    this.layerPropUpdated.next(changes);
  }

  addFeaturesToLayer<T extends string>(layerName: string, feature: WcFeature<T>[]) {
    let layerSource = this.getLayerByName(layerName)?.getSource();

    if (layerSource instanceof Cluster) {
      layerSource = layerSource.getSource();
    }
    layerSource?.addFeatures(feature);
  }

  removeFeaturesFromLayer<T extends string>(layerName: string, features: WcFeature<T>[]): void {
    const source = this.getLayerByName(layerName)?.getSource();
    if (source) {
      features.forEach(feature => source.removeFeature(feature as WcFeature<T>));
    }
  }
  private createCluster(distance: number, source: VectorSource | undefined = undefined, layerName: string): Cluster {
    this._clusteredLayerSources.set(layerName, source);
    return new Cluster({
      distance,
      source,

      geometryFunction: feature => {
        return (!feature.get('isVisible') ? null : feature.getGeometry()) as Point;
      },
    });
  }
  private createSource<T extends string>(features: WcFeature<T>[] | [] = []): VectorSource {
    const source = new VectorSource();
    source.addFeatures(features);
    return source;
  }
}
