import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UnitDashcamsModule } from '../cameras-related/unit-dashcams/unit-dashcams.module';
import { MapUnitTooltipModule } from '../map-unit-tooltip/map-unit-tooltip.module';
import { WcChipModule } from '../wc-chip/wc-chip.module';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { UnitDashcamsModalComponent } from './unit-dashcams-modal.component';

@NgModule({
  declarations: [UnitDashcamsModalComponent],
  imports: [CommonModule, UnitDashcamsModule, WcChipModule, MapUnitTooltipModule, TranslateModule, WcIconModule],
  exports: [UnitDashcamsModalComponent],
})
export class UnitDashCamsModalModule {}
