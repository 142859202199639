/* eslint-disable @angular-eslint/component-selector */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { IconClassName } from '../../../../wc-models/src/lib/types/icon-class-names-type';
import { BaseButtonDirective } from '../../lib/base/base-button/base-button.directive';

@Component({
  selector: 'button[rekor-icon], button[rekor-basic], button[rekor-stroked], button[rekor-flat]',
  template: `<wc-icon class="left-icon" *ngIf="icons.leftSide" [iconName]="icons.leftSide"></wc-icon>
    <ng-content></ng-content>
    <wc-icon class="right-icon" *ngIf="icons.rightSide" [iconName]="icons.rightSide"></wc-icon>`,
  styleUrls: ['./button-styles/sizes.button.scss', './button-styles/general.button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class WcButtonComponent extends BaseButtonDirective implements OnInit {
  private readonly _icons = { rightSide: '', leftSide: '' };
  @Input() set rightSideIcon(value: IconClassName) {
    this._icons.rightSide = value;
  }
  @Input() set leftSideIcon(value: IconClassName) {
    this._icons.leftSide = value;
  }

  get icons() {
    return this._icons;
  }

  constructor(elementRef: ElementRef, ngZone: NgZone) {
    super(elementRef, ngZone);
  }
}

@Component({
  selector: 'button[rekor-icon]',
  template: ` <ng-content></ng-content>`,
  styleUrls: ['./button-styles/sizes.button.scss', './button-styles/general.button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class wcIconButtonComponent extends BaseButtonDirective implements OnInit {
  constructor(elementRef: ElementRef, ngZone: NgZone) {
    super(elementRef, ngZone);
  }
}

@Component({
  selector: 'a[rekor-link]',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./button-styles/sizes.button.scss', './button-styles/general.button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class WcLinkButtonComponent extends BaseButtonDirective implements OnInit {
  @Input() link!: string;
  constructor(elementRef: ElementRef, ngZone: NgZone) {
    super(elementRef, ngZone);
  }
}
