import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WcAutocompleteV2Module } from '@wc/wc-ui/src/form-components/autocomplete-v2/autocomplete-v2.module';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { EditableListComponent } from './editable-list.component';

// const Materials: any[] = [
// ]

@NgModule({
  declarations: [EditableListComponent],
  imports: [CommonModule, WcBaseControlFieldModule, WcAutocompleteV2Module],
  providers: [],
  exports: [EditableListComponent],
})
export class EditableListComponentModule {}
