import { Injectable, OnDestroy } from '@angular/core';
import {
  EntitiesStore,
  LiveMapEntity,
  LiveMapStore,
  LocalStorageService,
  SplitIOService,
  UiStore,
  UIWeatherEventEntity,
  WeatherAlertSupportedType,
  WeatherAlertUrgency,
  WeatherAlertView,
} from '@wc/core';
import { WcMapConfig } from '@wc/core/livemap.config';
import { WeatherEventStatus } from '@wc/core/models/enums';
import { WeatherEventsService } from '@wc/core/services/weather-events.service';
import { asWeatherEventUIEntity, compareTwoArrays } from '@wc/utils';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { styleType } from '@wc/wc-map/src/lib/styles/styles';
import { action, computed, makeObservable, observable, reaction, runInAction, toJS } from 'mobx';
import moment from 'moment';
import { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import { Geometry, MultiPolygon, Point, Polygon } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { interval, Subscription } from 'rxjs';
import { coordsFormatFromLonLat } from '../../wc-map/src/lib/utils';
import { LiveMapEntityType } from '../models';

@Injectable({
  providedIn: 'root',
})
export class WeatherEventsStore implements OnDestroy {
  /** All weather events wrapped with UIWeatherEventEntity that allows us to use additional properties relevant for FE */
  allWeatherEvents: Record<number, /** Entity id */ UIWeatherEventEntity> = {};

  /** Dynamic lists of active and forecast weather events ids */
  activeWeatherEventsIds: Set<number> = new Set();
  forecastWeatherEventsIds: Set<number> = new Set();

  /** Contains the list of all weather features that used for weather layers on the map */
  featuresMap: Map<number, /** Entity id */ Feature> = new Map();

  /** Map of all the weather layers by weather event types */
  weatherEventLayersMap: Map<string, /** Layer type */ Set<number>> = new Map();

  /** Set of weather event ids that shouldn't have status 'New' */
  viewedWeatherEventsIds: Set<number> = new Set();

  @observable currentWeatherEventStatusFilter: WeatherEventStatus = WeatherEventStatus.AllAlerts;
  @observable selectedWeatherEventId: number | null = null;
  @observable previousLayer: string | null = null;

  /** All available types we get from backend */
  @observable availableWeatherEventTypes = [] as LiveMapEntityType[];
  /** Counter for Weather events panel badge */
  @observable newEventsCounter = 0;

  statusUpdateInterval: any;
  weatherAlertsSupportedTypes: WeatherAlertSupportedType[] = [];
  weatherAlertsSupportedParentTypesLowercase: LiveMapEntityType[] = [];
  allWeatherEventsCounter = 0;

  /** Dispose of */
  mapSubscription?: Subscription;

  constructor(
    private entitiesStore: EntitiesStore,
    private liveMapStore: LiveMapStore,
    private localStorageService: LocalStorageService,
    private weatherEventsService: WeatherEventsService,
    uiStore: UiStore,
    private splitIOService: SplitIOService
  ) {
    if (!uiStore.isTabletMode) {
      return;
    }
    makeObservable(this);

    reaction(
      () => this.entitiesStore.weatherEventsDiff,
      weatherEventsDiff => {
        this.updateWeatherEvents(weatherEventsDiff);
      }
    );

    /** We should check weather events statuses each minute and if needed change it's status. */
    this.statusUpdateInterval = interval(60000).subscribe(() => {
      this.updateWeatherEventsStatusMaps();
      this.updateLayers();
    });

    this.weatherEventsService.getWeatherAlertsSupportedTypes().subscribe(
      response => {
        this.weatherAlertsSupportedTypes = response;
        this.weatherAlertsSupportedParentTypesLowercase = this.weatherAlertsSupportedTypes.map(
          parent => parent.type.toLowerCase() as unknown as LiveMapEntityType
        );
      },
      error => {
        console.log(error);
      }
    );

    reaction(
      () => liveMapStore.openedLayer,
      (openedLayer: string) => {
        const isPanelClosed = this.previousLayer === 'weather' && openedLayer !== 'weather';
        this.updateViewedWeatherEvents(isPanelClosed);

        runInAction(() => {
          this.previousLayer = openedLayer;
        });
      }
    );

    reaction(
      () => liveMapStore.selectedWorkspacesIds,
      () => {
        this.updateLayers();
      }
    );
  }

  updateViewedWeatherEvents(isPanelClosed?: boolean) {
    if (Object.keys(this.allWeatherEvents).length === 0) return;

    if (isPanelClosed) {
      this.viewedWeatherEventsIds.clear();

      Object.keys(this.allWeatherEvents).forEach(key => {
        this.viewedWeatherEventsIds.add(parseInt(key));
      });

      this.localStorageService.set(LocalStorageKeys.ViewedWeatherEventsIds, Array.from(this.viewedWeatherEventsIds));
      this.newEventsCounter = 0;
      return;
    }

    this.viewedWeatherEventsIds?.forEach(id => {
      this.allWeatherEvents[id].ui.isViewed = true;
    });

    /** Update weather events counter badge */
    this.newEventsCounter = Object.keys(this.allWeatherEvents).length - this.viewedWeatherEventsIds?.size;
  }

  updateWeatherEvents(weatherEventsDiff: { modified: Record<number, WeatherAlertView>; removed: number[] }): void {
    const { modified, removed } = weatherEventsDiff;
    if (Object.keys(modified).length === 0) return;

    this.removeWeatherEvents(removed);

    const isFirstLoad = !Object.keys(this.allWeatherEvents).length;
    const newWeatherEventUiEntities = this.updateWeatherEventEntities(modified);
    this.allWeatherEvents = { ...this.allWeatherEvents, ...newWeatherEventUiEntities };

    this.setAvailableWeatherEventTypes();

    if (isFirstLoad) {
      this.localStorageViewedWeatherEventsSync();
      this.allWeatherEventsCounter = Object.keys(this.allWeatherEvents).length;
      this.newEventsCounter = Object.keys(this.allWeatherEvents).length - this.viewedWeatherEventsIds?.size;
    }

    this.updateWeatherEventsStatusMaps();
    this.updateLayers();
  }

  setAvailableWeatherEventTypes() {
    const uniqueLayerSet = new Set();

    Object.values(this.allWeatherEvents).map((weatherEvent: UIWeatherEventEntity) => {
      uniqueLayerSet.add(weatherEvent.data.type);
    });

    runInAction(() => {
      this.availableWeatherEventTypes = compareTwoArrays(
        this.weatherAlertsSupportedParentTypesLowercase,
        Array.from(uniqueLayerSet)
      ).sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
    });
  }

  removeWeatherEvents(weatherEventIds: number[]): void {
    weatherEventIds.forEach(id => {
      console.log('Remove WeatherEvent ------ ', id);
      delete this.allWeatherEvents[id];
      this.forecastWeatherEventsIds.delete(id);
      this.activeWeatherEventsIds.delete(id);
      this.viewedWeatherEventsIds.delete(id);
      this.featuresMap.delete(id);
    });
  }

  getWeatherEventEntityStatus(weatherEvent: WeatherAlertView): WeatherEventStatus {
    /** Filter out active events that pasted their EndTime */
    if (moment(weatherEvent.endTime).isAfter(moment.utc()) || !weatherEvent.endTime) {
      if (weatherEvent.startTime) {
        if (moment(weatherEvent.startTime).isBefore(moment.utc())) {
          return WeatherEventStatus.Active;
        } else {
          return WeatherEventStatus.Forecast;
        }
      } else {
        /** Event with no start time -> check by Urgency */
        switch (weatherEvent.urgency) {
          case WeatherAlertUrgency.Immediate:
          case WeatherAlertUrgency.Expected:
            return WeatherEventStatus.Active;

          case WeatherAlertUrgency.Future:
          case WeatherAlertUrgency.UnknownWeatherAlertUrgency:
            return WeatherEventStatus.Forecast;
        }
      }
    }

    return WeatherEventStatus.Forecast;
  }

  /**
   * If entities exist - Updates weather event with new data from BE.
   * If doesn't exist - creates new entity and wrap it with the UIWeatherEventEntity.
   *
   * @link: libs/core/models/models.ts -> UIWeatherEventEntity
   * @link: libs/utils/utils.ts -> asWeatherEventUIEntity
   */
  updateWeatherEventEntities(weatherEntities: Record<number, WeatherAlertView>): Record<number, UIWeatherEventEntity> {
    const weatherEventUiEntities = {};
    for (const [id, weatherEntity] of Object.entries(weatherEntities)) {
      if (!weatherEntity?.subType) continue;
      let weatherUiEvent;

      if (!this.allWeatherEvents[id]) {
        weatherUiEvent = asWeatherEventUIEntity(weatherEntity);
        this.featuresMap.set(parseInt(id), this.asFeature(weatherUiEvent));
        weatherEventUiEntities[id] = weatherUiEvent;
      } else {
        this.allWeatherEvents[id].data = weatherEntity;
      }
    }
    return weatherEventUiEntities;
  }

  asFeature(weatherEvent: UIWeatherEventEntity): Feature {
    const coordinates = coordsFormatFromLonLat(weatherEvent.data.location);
    const isPolygon = weatherEvent.data.location.type === 'Polygon';
    const isMultiPolygon = weatherEvent.data.location.type === 'MultiPolygon';

    const feature = new Feature({
      geometry: isMultiPolygon
        ? new MultiPolygon(coordinates as Coordinate[][][])
        : isPolygon
        ? new Polygon(coordinates as Coordinate[][])
        : new Point(coordinates as Coordinate),
    });

    feature.setId(weatherEvent.data.id);
    feature.setProperties({
      address: weatherEvent.data.address,
      certainty: weatherEvent.data.certainty,
      createdAt: weatherEvent.data.createdAt,
      id: weatherEvent.data.id,
      featureType: weatherEvent.ui.featureSubTypeOf,
      featureSubTypeOf: weatherEvent.ui.featureType,
      endTime: weatherEvent.data.endTime,
      location: weatherEvent.data.location,
      severity: weatherEvent.data.severity,
      status: weatherEvent.ui.status,
      subType: weatherEvent.data.subType,
      workspaces: toJS(weatherEvent.data.workspaces),
      urgency: weatherEvent.data.urgency,
    });

    const style = styleType(feature.getProperties() as LiveMapEntity, WcMapConfig);
    feature.setStyle(style);

    return feature;
  }

  localStorageViewedWeatherEventsSync() {
    const savedViewedIds = this.localStorageService.get(LocalStorageKeys.ViewedWeatherEventsIds);
    if (!savedViewedIds?.length) return;

    savedViewedIds.forEach((id: number) => {
      this.viewedWeatherEventsIds.add(id);

      if (this.allWeatherEvents[id]) {
        this.allWeatherEvents[id].ui.isViewed = true;
      } else {
        this.viewedWeatherEventsIds.delete(id);
      }
    });

    this.localStorageService.set(LocalStorageKeys.ViewedWeatherEventsIds, Array.from(this.viewedWeatherEventsIds));
  }

  /**
   * Updates status for all relevant features in the featuresMap.
   * Updated IDs in forecastWeatherEventsIds and activeWeatherEventsIds lists.
   */
  updateWeatherEventsStatusMaps() {
    Object.values(this.allWeatherEvents).forEach((weatherEvent: UIWeatherEventEntity) => {
      const status = this.getWeatherEventEntityStatus(weatherEvent.data);
      const id = weatherEvent.data.id;
      weatherEvent.ui.status = status;

      this.featuresMap.get(id)?.setProperties({ status });

      const feature = this.featuresMap.get(id);
      const style = styleType(feature?.getProperties() as LiveMapEntity, WcMapConfig);
      feature?.setStyle(style);

      if (status === WeatherEventStatus.Active) {
        this.forecastWeatherEventsIds.delete(id);
        this.activeWeatherEventsIds.add(id);
      } else {
        this.activeWeatherEventsIds.delete(id);
        this.forecastWeatherEventsIds.add(id);
      }
    });
  }

  updateLayers() {
    this.weatherEventLayersMap.clear();

    this.featuresMap.forEach(feature => {
      const compareResponse = compareTwoArrays(this.liveMapStore.selectedWorkspacesIds, feature.get('workspaces'));
      if (!compareResponse.length) return;

      const layerName = feature.get('featureType');

      /** If such weather layer doesn't exist, create it in the weatherEventLayersMap */
      if (!this.weatherEventLayersMap.get(layerName)) {
        this.weatherEventLayersMap.set(layerName, new Set());
      }

      if (
        this.currentWeatherEventStatusFilter !== WeatherEventStatus.AllAlerts &&
        this.currentWeatherEventStatusFilter !== feature.get('status')
      ) {
        return;
      }

      this.weatherEventLayersMap.get(layerName)?.add(feature.get('id'));
    });

    this.mapSubscription = this.liveMapStore.wcMapServiceInit$.subscribe(ready => {
      if (ready) {
        this.weatherEventLayersMap?.forEach((featureIds, layerName) => {
          const olMap = this.liveMapStore.wcMap.map;
          const layers = olMap.getLayers();

          let layer: VectorLayer | undefined = layers.getArray().find(_layer => {
            return _layer.get('name') === layerName;
          }) as VectorLayer;

          if (layer) {
            layer.getSource().refresh();
          } else {
            layer = new VectorLayer({
              zIndex: 6,
              source: new VectorSource(),
            });

            layer.set('name', layerName);
            olMap.addLayer(layer);
          }

          const features = Array.from(this.weatherEventLayersMap.get(layerName) as Iterable<number>).map(id =>
            this.featuresMap.get(id)
          );
          layer.getSource().addFeatures(features as Feature<Geometry>[]);

          /** If this weather layer is switched off on the layers panel, don't show this layer. */
          const visibleWeatherLayers = compareTwoArrays(
            this.weatherAlertsSupportedParentTypesLowercase,
            this.liveMapStore.visibleLayers
          );
          if (!visibleWeatherLayers.includes(layerName)) {
            layer.setVisible(false);
          }

          olMap.render();
        });
      }
    });
  }

  selectWeatherEvent(entityId: number) {
    this.allWeatherEvents[entityId].ui.isViewed = true;
    this.viewedWeatherEventsIds.add(entityId);
    this.localStorageService.set(LocalStorageKeys.ViewedWeatherEventsIds, this.viewedWeatherEventsIds);
    this.liveMapStore.selectEntityFeature(this.allWeatherEvents[entityId]);
  }

  @computed
  get activeWeatherEventList(): Array<UIWeatherEventEntity> {
    const activeWeatherEventEntities: any[] = [];

    this.activeWeatherEventsIds.forEach((id: number) => {
      activeWeatherEventEntities.push(this.allWeatherEvents[id]);
    });

    return activeWeatherEventEntities;
  }

  @computed
  get forecastWeatherEventList(): Array<UIWeatherEventEntity> {
    const forecastWeatherEventEntities: UIWeatherEventEntity[] = [];

    this.forecastWeatherEventsIds.forEach((id: number) => {
      forecastWeatherEventEntities.push(this.allWeatherEvents[id]);
    });

    return forecastWeatherEventEntities;
  }

  @action
  weatherEventStatusFilter(status: WeatherEventStatus) {
    runInAction(() => {
      this.currentWeatherEventStatusFilter = status;
    });
    this.updateLayers();
  }

  ngOnDestroy(): void {
    this.mapSubscription?.unsubscribe();
    clearInterval(this.statusUpdateInterval);
  }
}
