import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum AppSounds {
  incidentAlert = 'incident-alert',
  wrongWayAlert = 'wrong-way-alert',
  empty = 'empty',
}

export enum AppSoundFileTypes {
  wav = 'wav',
  mp3 = 'mp3',
}

export enum BrowserSoundSettingsLink {
  Chrome = 'chrome://settings/content/sound',
}

@Injectable({
  providedIn: 'root',
})
export class SoundsService {
  private isSoundNotAllowed = new Subject<boolean>();
  isSoundNotAllowed$ = this.isSoundNotAllowed.asObservable();
  alertSound;
  soundAlertEl = new Audio();
  soundOptions = {
    [AppSounds.incidentAlert]: {
      playCounter: 0,
      isActive: false,
    },
    [AppSounds.wrongWayAlert]: {
      playCounter: 0,
      isActive: false,
    },
  };

  initiateSoundWithEmpty() {
    /* @description used mainly for Safari on top for IOS
     * Seems that Safari and IOS need the same Audio ref, that was triggered by a click form the user
     * while Android is more flexible in the sense a global interaction is enough.
     * Thats the reason why we use the same Audio ref and changing the src.
     */
    this.soundAlertEl.src = `assets/sounds/${AppSounds.empty}.${AppSoundFileTypes.mp3}`;
    this.soundAlertEl?.play();
  }

  playSound(soundName: AppSounds, soundRepeats = 1, type: AppSoundFileTypes = AppSoundFileTypes.wav): void {
    if (!this.soundOptions[soundName].isActive) {
      this.soundOptions[soundName].playCounter = 0;
      return;
    }
    this.soundAlertEl.src = `assets/sounds/${soundName}.${type}`;
    this.soundOptions[soundName].playCounter += soundRepeats;
    if (!(this.soundOptions[soundName].playCounter > soundRepeats)) {
      this.triggerSoundProcess(1300, soundName);
    }
  }

  private triggerSoundProcess(time = 1300, soundName) {
    this.playSoundWhileCounter(time, soundName);
  }

  private playSoundWhileCounter(time: number, soundName) {
    if (this.soundOptions[soundName].playCounter > 0) {
      --this.soundOptions[soundName].playCounter;
      setTimeout(() => {
        this.soundAlertEl?.play();

        this.playSoundWhileCounter(time, soundName);
      }, time);
      return;
    }
  }

  checkIfSoundAllowedInSettings() {
    const audio = new Audio(`../assets/sounds/${AppSounds.empty}.${AppSoundFileTypes.mp3}`);
    audio.play().catch(() => this.isSoundNotAllowed.next(true));
  }
}

export enum SoundAlertLocalStorageKeys {
  AllowAlert = 'allow-alert',
}
