<button data-cy-id="notes-add-btn" class="button-add" (click)="addNote()" [disabled]="disableAddBtn">
  <i class="button-add-icon fas fa-plus"></i>
  <span wcHeapClass="add-new-note">{{ 'add' | translate }}</span>
</button>

<div [formGroup]="notesForm" [class.tablet-mode]="isTabletMode" [class.language-rtl]="uiStore.isHebrew">
  <div formArrayName="notes" *ngIf="notes.controls.length; else noIncidentNotes">
    <div class="note" *ngFor="let noteItem of notes.controls; let i = index" [formGroupName]="i">
      <button
        class="remove-section-button"
        [wcTooltipText]="'tooltip.deleteNote' | translate"
        wcTooltipPlacement="bottom"
        [disabled]="isOfflineNote(noteItem.get('id')?.value)"
        [ngClass]="{
          'disabled-removeNote-btn': isOfflineNote(noteItem.get('id')?.value)
        }"
        (click)="removeNote(i)">
        <i class="fas fa-times-circle icon-remove-circle"></i>
      </button>
      <wc-text-area-control
        #noteRefs
        [formControl]="$any(noteItem.get('note'))"
        [fieldData]="notesFieldData.note"
        [readonly]="isOfflineNote(noteItem.get('id')?.value)">
      </wc-text-area-control>
    </div>
  </div>

  <mat-error *ngIf="showOfflineItemDisabledError" class="offline-not-editable-message">
    {{ 'errorMessages.offlineItemCantBeEdited' | translate }}
  </mat-error>

  <ng-template #noIncidentNotes>
    <div class="empty-state-container" *ngIf="!disableAddBtn || (online$ | async); else offlineEmpty">
      <i class="notes-empty-state-icon"></i>

      <div class="empty-state-message">
        {{ 'emptyStates.noNotesMessage' | translate }}
      </div>
      <div class="empty-state-action">
        {{ 'emptyStates.noNotesAction' | translate }}
      </div>
    </div>
  </ng-template>

  <ng-template #offlineEmpty>
    <div class="empty-state-container" fxLayout="column" fxLayoutAlign="center center">
      <img src="assets/images/empty-offline-involved-vehicles.svg" />
      <span class="caption-notes">{{ 'emptyStates.informationNotAvailable' | translate }}</span>
      <span class="caption-notes">{{ 'emptyStates.whileOffline' | translate }}</span>
    </div>
  </ng-template>
</div>
