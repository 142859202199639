<mat-slide-toggle
  #toggle
  matInput
  class="slide-toggle-control"
  [labelPosition]="direction"
  [class.tablet-mode]="isTabletMode"
  [class.wide]="isWideStyle"
  [checked]="checked"
  [name]="toggleName"
  [class.wide-tablet]="isWideStyle && isTabletMode"
  (change)="changed($event)">
  {{ label }}
</mat-slide-toggle>
