/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { EntityActions, EntityUIQuery, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { WeatherEventStatus } from '../../../../../../core/models/enums';
import { LayerType } from '../../../../../../core/models/gql.models';
import {
  ModifiedEntities,
  RemovedEntities,
  WeatherEventsStoreEntity,
  WeatherEventsUIEntity,
} from '../../../../../../wc-models/src';
import { LiveMapQuery } from '../../live-map/live-map.query';
import {
  WeatherEventsAllStates,
  WeatherEventsState,
  WeatherEventsStore,
  WeatherEventsUIState,
} from './weather_events.store';

@Injectable({ providedIn: 'root' })
export class WeatherEventsQuery extends QueryEntity<WeatherEventsState> {
  private layerTypeName: LayerType.WeatherAlert = LayerType.WeatherAlert;
  ui!: EntityUIQuery<WeatherEventsUIState>;
  constructor(weatherEventsStore: WeatherEventsStore, private liveMapQuery: LiveMapQuery) {
    super(weatherEventsStore);
    this.createUIQuery();
  }

  get modifiedWeatherEvents$(): Observable<ModifiedEntities> {
    return this.selectEntityAction([EntityActions.Update, EntityActions.Add]).pipe(
      map(({ ids }) => ids.map(id => this.getEntity(id)).filter(e => !!e) as WeatherEventsStoreEntity[]),
      map(WeatherEvents => ({
        [this.layerTypeName]: WeatherEvents,
      }))
    );
  }

  get removedWeatherEvents$(): Observable<RemovedEntities> {
    return this.selectEntityAction([EntityActions.Remove]).pipe(map(({ ids }) => ({ [this.layerTypeName]: ids })));
  }

  get UIWeatherEvents$(): Observable<WeatherEventsUIEntity[]> {
    return this.ui.selectAll();
  }

  get weatherEventsByStatus$(): Observable<{
    [WeatherEventStatus.Active]: WeatherEventsAllStates[];
    [WeatherEventStatus.Forecast]: WeatherEventsAllStates[];
  }> {
    return this.liveMapQuery.getVisibleEntitiesIdsByLayerType(this.layerTypeName).pipe(
      switchMap(ids => this.ui.selectMany(ids || [])),
      map(uiEntities => ({
        [WeatherEventStatus.Active]: uiEntities
          .filter(uiEntity => uiEntity.status === WeatherEventStatus.Active)
          .map(uiEntity => ({ entity: this.getEntity(uiEntity.id), ui: this.ui.getEntity(uiEntity.id) })),
        [WeatherEventStatus.Forecast]: uiEntities
          .filter(uiEntity => uiEntity.status === WeatherEventStatus.Forecast)
          .map(uiEntity => ({ entity: this.getEntity(uiEntity.id), ui: this.ui.getEntity(uiEntity.id) })),
      }))
    );
  }

  get viewedWeatherEventIds$() {
    return this.ui.selectAll({ filterBy: entity => entity.isViewed === true });
  }

  get notViewedWeatherEventIds$() {
    return this.ui.selectAll({ filterBy: entity => !entity.isViewed });
  }
}
