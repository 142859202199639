<div class="affected-lanes-container" [class.multiple-directions]="isMultiDirection">
  <wc-loader-component [show]="showLoader"></wc-loader-component>
  <div class="affected-lanes-border-line"></div>
  <wc-affected-lanes-diagram
    *ngIf="!isMultiDirection"
    class="affected-lanes-diagram"
    [hovered]="hoveredLane"
    [lanes]="lanesForm.value"
    (laneHovered)="diagramLaneHovered($event)"
    (laneClick)="handleLaneClick($event)">
  </wc-affected-lanes-diagram>
  <div class="affected-lanes-add-lane">
    <span *ngIf="!isMultiDirection" class="affected-lanes-add-label" data-cy-id="affected-lanes-add-label-content">{{
      'upTo9Lanes' | translate
    }}</span>

    <button
      data-cy-id="affected-lanes-add-button"
      class="affected-lanes-add-button"
      rekor-basic
      [matMenuTriggerFor]="menu"
      (click)="$event.preventDefault()"
      [disabled]="disableAddLane">
      <wc-icon iconName="plus"></wc-icon>
      <span class="affected-lanes-add-button-label">{{ 'add' | translate }}</span>
    </button>

    <mat-menu #menu="matMenu" class="add-nav-menu">
      <button
        class="affected-lanes-menu-button"
        [disabled]="!menuitem.isActive"
        mat-menu-item
        [disableRipple]="true"
        *ngFor="let menuitem of contextMenuOptions"
        (click)="addLane(menuitem)">
        <img class="lane-icon" src="{{ menuitem.icon }}" />
        <span [attr.data-cy-id]="'affected-lanes-menu-type-' + menuitem.type.toLowerCase()">
          {{ menuitem.type | translate }}</span
        >
      </button>
    </mat-menu>
  </div>
  <wc-affected-lanes-slider
    *ngIf="lanesForm.length > 0"
    #affectedLanesSlider
    class="affected-lanes-slider"
    [isMultiDirection]="isMultiDirection"
    [hovered]="diagramHoveredLane"
    (deleteLaneByIndex)="removeLane($event)"
    (moveGoreAction)="moveGore($event)"
    [allLanesAffected]="allAffected">
  </wc-affected-lanes-slider>
</div>
