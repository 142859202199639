import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { SelectOption } from '../../../../features/ui/form-controls/form-models';
import { CustomFormControlComponent, FormFieldOption } from '../../lib/base/custom-form-control';

@Component({
  selector: 'wc-select-multi-boxes',
  templateUrl: './select-multi-boxes.component.html',
  styleUrls: ['./select-multi-boxes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectMultiBoxesComponent extends CustomFormControlComponent {
  defaultIcon = '/assets/icons/details.png';
  isOtherSelected = false;

  @Output() selectBoxesChange: EventEmitter<string> = new EventEmitter();

  valueSelected(option: FormFieldOption) {
    if (option.disabled) return;
    const controlValues: [any] = this.ngControl.control?.value;
    const index = controlValues.findIndex(val => val === option.value);
    if (index > -1) {
      controlValues.splice(index, 1);
    } else {
      controlValues.push(option.value);
    }
    this.onChanged(controlValues);
  }

  isOptionActive(option: SelectOption) {
    const controlValues: [] = this.ngControl.control?.value;
    const index = controlValues.findIndex(val => val === option.value);
    return index !== -1;
  }
}
