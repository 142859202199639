/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AdditionalLayerTypesEnum, NewLiveMapEntity, TransitLayerType, WcEntitiesState } from '@wc/wc-models';
import { merge } from 'rxjs';
import { mergeAll } from 'rxjs/operators';
import { LayerType } from '../../../../../core/models/gql.models';
import { TransitBusStopsQuery } from '../transit/transit_bus_stops.query';
import { TransitFixedRouteBusQuery } from '../transit/transit_fixed_route_bus.query';
import { TransitRoutesQuery } from '../transit/transit_routes.query';
import { CamerasQuery } from './cameras/cameras.query';
import { CongestionsQuery } from './congestions/congestions.query';
import { ConstructionsQuery } from './construction/construction.query';
import { CrashRisksQuery } from './crash_risk/crash_risk.query';
import { DmsQuery } from './dmses/dms.query';
import { EntitiesStore } from './entities.store';
import { IncidentsQuery } from './incidents/incidents.query';
import { IrregularCongestionsQuery } from './irregular_congestion/irregular_congestions.query';
import { RoadwayStatusQuery } from './roadway_status/roadway_status.query';
import { RoadClosuresQuery } from './road_closures/road_closures.query';
import { SpecialEventsQuery } from './special_events/special_events.query';
import { UnitsQuery } from './units/units.query';
import { WeatherEventsQuery } from './weather_events/weather_events.query';

@Injectable({ providedIn: 'root' })
export class EntitiesQuery extends Query<WcEntitiesState> {
  constructor(
    protected store: EntitiesStore,
    private incidentsQuery: IncidentsQuery,
    private dmsQuery: DmsQuery,
    private camerasQuery: CamerasQuery,
    private unitsQuery: UnitsQuery,
    private constructionsQuery: ConstructionsQuery,
    private roadClosuresQuery: RoadClosuresQuery,
    private congestionsQuery: CongestionsQuery,
    private irregularCongestionsQuery: IrregularCongestionsQuery,
    private specialEventQuery: SpecialEventsQuery,
    private weatherEventsQuery: WeatherEventsQuery,
    private crashRisksQuery: CrashRisksQuery,
    private roadwayStatusQuery: RoadwayStatusQuery,
    private transitRouteQuery: TransitRoutesQuery,
    private transitFixedRouteBusQuery: TransitFixedRouteBusQuery,
    private transitBusStopsQuery: TransitBusStopsQuery
  ) {
    super(store);
  }

  getEntityByIDAndEntityType(
    id: number | string,
    entityType: LayerType | TransitLayerType | AdditionalLayerTypesEnum
  ): NewLiveMapEntity {
    let liveMapEntity;
    switch (entityType) {
      case LayerType.Incident:
        liveMapEntity = this.incidentsQuery.getEntity(+id);
        break;
      case LayerType.Dms:
        liveMapEntity = this.dmsQuery.getEntity(+id);
        break;
      case LayerType.Camera:
        liveMapEntity = this.camerasQuery.getEntity(+id);
        break;
      case LayerType.Unit:
        liveMapEntity = this.unitsQuery.getEntity(+id);
        break;
      case LayerType.Construction:
        liveMapEntity = this.constructionsQuery.getEntity(+id);
        break;
      case LayerType.Congestion:
        liveMapEntity = this.congestionsQuery.getEntity(+id);
        break;
      case LayerType.IrregularCongestion:
        liveMapEntity = this.irregularCongestionsQuery.getEntity(+id);
        break;
      case LayerType.RoadClosure:
        liveMapEntity = this.roadClosuresQuery.getEntity(+id);
        break;
      case LayerType.WeatherAlert:
        liveMapEntity = this.weatherEventsQuery.getEntity(+id);
        break;
      case LayerType.CrashRiskArea:
        liveMapEntity = this.crashRisksQuery.getEntity(+id);
        break;
      case LayerType.RoadwayStatus:
        liveMapEntity = this.roadwayStatusQuery.getEntity(+id);
        break;
      case LayerType.SpecialEvent:
        liveMapEntity = this.specialEventQuery.getEntity(+id);
        break;
      case TransitLayerType.TransitRoute:
        liveMapEntity = this.transitRouteQuery.getEntity(+id);
        break;

      case TransitLayerType.TransitFixedRouteBus:
        liveMapEntity = {
          ...this.transitFixedRouteBusQuery.getEntity(+id),
          ...this.transitFixedRouteBusQuery.ui.getEntity(+id),
        };
        liveMapEntity = { ...liveMapEntity, ...{ route: this.transitRouteQuery.getEntity(liveMapEntity.routeId) } };
        break;

      case TransitLayerType.TransitBusStop:
        liveMapEntity = {
          ...this.transitBusStopsQuery.getEntity(+id),
          ...this.transitBusStopsQuery.ui.getEntity(+id),
        };
        break;

      case AdditionalLayerTypesEnum.segment_line:
        liveMapEntity = this.transitBusStopsQuery.getEntity(+id);
        break;

      default:
        console.error('could not find entity for:', entityType, '. you might miss a query?');
        liveMapEntity = {};
    }
    return liveMapEntity as NewLiveMapEntity;
  }

  get modifiedEntities$() {
    return merge([
      this.incidentsQuery.modifiedIncidents$,
      this.dmsQuery.modifiedDms$,
      this.camerasQuery.modifiedCameras$,
      this.unitsQuery.modifiedUnits$,
      this.constructionsQuery.modifiedConstructions$,
      this.roadClosuresQuery.modifiedRoadClosures$,
      this.congestionsQuery.modifiedCongestions$,
      this.irregularCongestionsQuery.modifiedIrregularCongestions$,
      this.specialEventQuery.modifiedSpecialEvents$,
      this.weatherEventsQuery.modifiedWeatherEvents$,
      this.crashRisksQuery.modifiedCrashRisks$,
      this.roadwayStatusQuery.modifiedRoadwayStatusSegments$,
      this.transitBusStopsQuery.modifiedBusStops$,
    ]).pipe(mergeAll());
  }

  get removedEntities$() {
    return merge([
      this.incidentsQuery.removedIncidents$,
      this.dmsQuery.removedDms$,
      this.camerasQuery.removedCameras$,
      this.unitsQuery.removedUnits$,
      this.constructionsQuery.removedConstructions$,
      this.roadClosuresQuery.removedRoadClosures$,
      this.congestionsQuery.removedCongestions$,
      this.irregularCongestionsQuery.removedIrregularCongestions$,
      this.specialEventQuery.removedSpecialEvents$,
      this.weatherEventsQuery.removedWeatherEvents$,
      this.crashRisksQuery.removedCrashRisks$,
    ]).pipe(mergeAll());
  }
}
