import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EntitiesServiceV2, HeapAnalyticsService, IncidentsQuery } from '@wc-core';
import {
  AppFeatureEnum,
  Incident,
  IncidentEndReason,
  IncidentStatus,
  IncidentStore,
  RelatedIncident,
  SplitIOService,
} from '@wc/core';
import { SelectOption } from '@wc/features/ui/form-controls/form-models';
import { generateDisplayNameForRelatedEventOption, strArrayToOptions } from '@wc/utils';
import { APP_TYPE_TOKEN } from '@wc/wc-core/src/lib/injection-tokens';
import { AppTypeUnion } from '@wc/wc-models/src';
import { ToastrAlertsService } from '@wc/wc-ui/src/services/toaster-alert.service';
import { IndividualConfig } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { FormFieldData } from '../../lib/base';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { AutoCompleteOption } from '../autocomplete/components/autocomplete-option/autocomplete-option.component';

@Component({
  selector: 'wc-complete-reject-form',
  templateUrl: './complete-reject-form.component.html',
  styleUrls: ['./complete-reject-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompleteRejectFormComponent implements OnInit, OnDestroy {
  viewMode: 'tablet' | 'desktop' | 'widget';
  incident: Incident;
  isReject = false;
  alertSettings: Partial<IndividualConfig> = {
    enableHtml: true,
    timeOut: 10000,
  };
  @ViewChild(AutocompleteComponent)
  autoCompleteList!: AutocompleteComponent<AutoCompleteOption<number>>;
  //Merge incident
  isTabletMode = false;
  isLoading = false;
  isLoadingIncidents = false;
  isNearByIncidentsExists = false;
  isDuplicatedReasonSelected = false;
  isIncidentResolvedSelected = false;
  incidentOptions: SelectOption<any>[] = [];
  completeReasonSub?: Subscription;
  mergeIncidentSub?: Subscription;

  form: FormGroup = this.fb.group({
    endReason: [null, Validators.required],
    endReasonComment: ['', Validators.maxLength(1000)],
    incidentTargetId: new FormControl(null),
    hazardRemains: this.fb.control(false),
  });

  formData: { [key: string]: FormFieldData } = {
    endReason: {
      label: 'selectReason',
      placeholder: 'reasonForCompletion',
      options: this.incidentEndReason.map((option: SelectOption) => ({
        ...option,
        groupLabel: [
          IncidentEndReason.DuplicateIncident,
          IncidentEndReason.IncidentResolved,
          IncidentEndReason.TrafficNotAffected,
          IncidentEndReason.CausedByMaintenanceWork,
        ].includes(option.value)
          ? 'verified'
          : 'notVerified',
      })),
      floatLabel: 'always',
      required: true,
    },
    incidentTargetId: {
      label: 'mergeToPrimary',
      placeholder: 'mergeToPrimary',
      options: [],
    },
    hazardRemains: {
      label: 'hazardRemains',
    },
  };

  constructor(
    private dialogRef: MatDialogRef<CompleteRejectFormComponent>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private incidentStore: IncidentStore,
    private alertsService: ToastrAlertsService,
    private translateService: TranslateService,
    private heapService: HeapAnalyticsService,
    private router: Router,
    private entitiesService: EntitiesServiceV2,
    private incidentQuery: IncidentsQuery,
    private splitIoService: SplitIOService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(APP_TYPE_TOKEN) private _appType: AppTypeUnion
  ) {
    this.viewMode = this._appType;
    this.incident = data.details.incident;
    this.isReject = data.hasActions;
    this.isTabletMode = this._appType === 'tablet';
  }

  isFeatureToggle$(featureName: AppFeatureEnum) {
    return this.splitIoService.isActiveFeatureToggle$(featureName);
  }

  ngOnInit(): void {
    if (!this.isTabletMode) {
      this.completeReasonSub = this.form.get('endReason')?.valueChanges.subscribe((val: IncidentEndReason) => {
        if (val === IncidentEndReason.IncidentResolved) {
          this.isIncidentResolvedSelected = true;
        } else {
          this.isIncidentResolvedSelected = false;
          this.form.get('hazardRemains')?.setValue(false);
        }

        if (val === IncidentEndReason.DuplicateIncident) {
          this.form.get('incidentTargetId')?.setValidators(Validators.required);
          this.form.get('incidentTargetId')?.updateValueAndValidity();
          if (this.incidentOptions.length === 0 || !this.isNearByIncidentsExists) {
            this.isLoadingIncidents = true;
            this.incidentStore
              .findIncidentByPointRadius({ location: this.incident.location, radiusMeters: 2000 })
              .subscribe((relatedIncident: RelatedIncident[]) => {
                this.incidentOptions = this.relatedIncidentToSelectOptions(relatedIncident, this.incident.id);
                this.formData.incidentTargetId.options = [...[], ...this.incidentOptions];
                this.isLoadingIncidents = false;
                if (this.incidentOptions.length > 0) {
                  this.isNearByIncidentsExists = true;
                }
                this.cdr.markForCheck();
              });
          }
          this.isDuplicatedReasonSelected = true;
        } else {
          this.isDuplicatedReasonSelected = false;
          this.form.get('incidentTargetId')?.reset();
          this.form.get('incidentTargetId')?.clearValidators();
        }
      });
    }
  }

  get appFeatureEnum() {
    return AppFeatureEnum;
  }

  get incidentEndReason() {
    const incidentEndReasons = [
      IncidentEndReason.DuplicateIncident,
      IncidentEndReason.IncidentResolved,
      IncidentEndReason.TrafficNotAffected,
      IncidentEndReason.CausedByMaintenanceWork,
      IncidentEndReason.UnavailableCamera,
      IncidentEndReason.NoVisualConfirmation,
      IncidentEndReason.ResourceConstraints,
      IncidentEndReason.TypeNotRelevant,
      IncidentEndReason.LocationNotRelevant,
    ];

    return strArrayToOptions(incidentEndReasons, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'IncidentEndReason',
      removeSort: true,
    });
  }

  handleClose(val = false) {
    this.dialogRef.close(val);
  }

  handleComplete() {
    this.form.markAllAsTouched();
    this.autoCompleteList?.autocompleteInputFormControl.updateValueAndValidity();

    if (this.form.valid) {
      this.isLoading = true;
      const incidentTargetId = this.form.get('incidentTargetId')?.value;
      if (this.isReject) {
        this.heapService.trackUserSpecificAction('heap-completion-form-reject-click');
        this.heapService.trackUserSpecificAction(`heap-reject-type-${this.form.get('endReason')?.value}`);
      } else {
        this.heapService.trackUserSpecificAction('heap-completion-form-complete-click');
      }
      let mergeText = '';
      let spacer = '';
      let comment = '';
      const endReason = this.form.get('endReason')?.value;
      // Adding manually the merge to the comment
      if (incidentTargetId) {
        mergeText = this.translateService.instant('confirmModal.incidentMergedIntoAnotherIncident.mainMessage', {
          mergedInto: incidentTargetId,
        });
        spacer = '; ';
      }

      // Heap event for merge incidents
      if (endReason === IncidentEndReason.DuplicateIncident && incidentTargetId) {
        this.heapService.trackUserSpecificAction('heap-completion-form-merge-incident', {
          incidentId: this.incident.id,
          incidentTargetId: incidentTargetId,
        });
      }

      comment = this.form.get('endReasonComment')?.value;

      let endReasonComment = comment + spacer + mergeText;
      const hazardRemains = this.form.get('hazardRemains')?.value;
      endReasonComment = hazardRemains
        ? `${endReasonComment ? `${endReasonComment}\n` : ''}${this.translateService.instant('hazardRemains')}`
        : endReasonComment;

      if (!this.isTabletMode) {
        this.entitiesService.emitNewEntitiesDiff({
          modified: {
            INCIDENT: [
              {
                id: this.incident.id,
                endReason,
                endReasonComment: endReasonComment || null,
                mergeDetails: { isBlocked: false, targetId: incidentTargetId },
                persistenceScoreType: null,
                hazardRemains: hazardRemains,
              },
            ],
          },
        });
      } else {
        this.incident.endReason = endReason;
        this.incident.hazardRemains = hazardRemains;
        this.incident.endReasonComment = endReasonComment;
      }
      this.incidentStore
        .setIncidentStatus(
          !this.isTabletMode
            ? (this.incidentQuery.getEntity(this.incident.id) as Incident)
            : { ...this.incident, endReasonComment: endReasonComment, hazardRemains: hazardRemains },
          this.isReject ? IncidentStatus.Rejected : IncidentStatus.Completed,
          incidentTargetId
        )
        .subscribe({
          next: () => {
            this.clickOnViewIncidentListener(true);
            this.alertsService
              .success(this.alertContent(true), undefined, this.alertSettings, true)
              ?.onHidden.subscribe(() => {
                this.clickOnViewIncidentListener(false);
              });

            this.handleClose(true);
          },
          complete: () => {
            this.isLoading = false;
          },
          error: error => {
            console.error(error);
            this.alertsService.error(this.alertContent(false), undefined, this.alertSettings, true);
            this.handleClose();
          },
        });
    }
  }

  alertContent(success: boolean): string {
    return this._appType === 'widget' ? this.messageFunctionWidget(success) : this.messageFunctionRegular(success);
  }

  private messageFunctionRegular(success: boolean): string {
    const mergedId = this.form.get('incidentTargetId')?.value;
    if (success) {
      if (mergedId) {
        return `<div>${this.translateService.instant('incidentWasMergedInto')} ${mergedId}. 
        </div> <a> ${this.translateService.instant('viewMergedIncident')}</a>`;
      } else {
        return this.isReject
          ? this.translateService.instant('notifications.REJECTED')
          : this.translateService.instant('notifications.COMPLETED');
      }
    } else {
      if (mergedId) {
        return this.translateService.instant('incidentCouldNotBeMerged');
      } else {
        return this.translateService.instant('notifications.incidentCompleteFailed');
      }
    }
  }

  private messageFunctionWidget(success: boolean): string {
    const mergedId = this.form.get('incidentTargetId')?.value;
    if (success) {
      if (mergedId) {
        return `${this.translateService.instant('widget.notifications.merged')} ${mergedId}. 
        <a> ${this.translateService.instant('viewMergedIncident')}</a>`;
      } else {
        return ` ${
          this.isReject
            ? this.translateService.instant('widget.notifications.rejected')
            : this.translateService.instant('widget.notifications.completed')
        }  <a class="restore-incident-form-toast-link-widget">${this.translateService.instant('restore')}</a> `;
      }
    } else {
      if (mergedId) {
        return this.translateService.instant('incidentCouldNotBeMerged');
      } else {
        return this.translateService.instant('widget.notifications.failed');
      }
    }
  }

  clickOnViewIncidentListener(add = true) {
    const listener = $event => {
      const id = this.form.get('incidentTargetId')?.value;
      if ($event.target?.innerText?.includes(this.translateService.instant('viewMergedIncident'))) {
        this.router.navigateByUrl(this._appType === 'widget' ? `incident/${id}` : `/live-map/(view:incident/${id})`);
      }
      if ($event.target?.className === 'restore-incident-form-toast-link-widget') {
        this.data?.clickOnToaster.next();
        this.heapService.trackUserSpecificAction('heap-widget-restore-incident-from-toast');
      }
    };
    add ? window.addEventListener('click', listener) : window.removeEventListener('click', listener);
  }

  ngOnDestroy(): void {
    this.completeReasonSub?.unsubscribe();
    this.mergeIncidentSub?.unsubscribe();
  }

  private relatedIncidentToSelectOptions(relatedIncident: RelatedIncident[], filteredIncidentId?: number) {
    const options: AutoCompleteOption<number>[] = [];
    relatedIncident.forEach(incident => {
      if (incident.id !== filteredIncidentId) {
        const currentEditUser =
          this.incidentStore.editCollaboration[incident.id] && this.incidentStore.editCollaboration[incident.id][0]
            ? this.incidentStore.editCollaboration[incident.id][0]
            : undefined;
        const subType = incident['subType']
          ? `| ${this.translateService.instant('incidentSubTypes.' + incident['subType'])}`
          : '';
        const type = this.translateService.instant('incidentTypes.' + incident.type);

        options.push({
          value: incident.id,
          displayName: generateDisplayNameForRelatedEventOption(incident, { translateService: this.translateService }),
          startIcon: incident.type.toLocaleLowerCase(),
          extraLineField: {
            value: `${incident.id} | ${type} ${subType}`.trim(),
          },
          disabled: !!currentEditUser,
          data: {
            address: incident.address,
            id: incident.id,
            status: incident.status,
            type: this.translateService.instant('incidentTypes.' + incident.type),
            driverName: currentEditUser,
          },
        });
      }
    });
    return options;
  }
}
