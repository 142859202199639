import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewLiveMapEntity } from '@wc/wc-models';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { LayerType, MainEntityType } from '../models';

// TODO: Tablet support - remove type after refactor is done

export type oldMapUI = {
  featureSubTypeOf: string;
  featureType: string;
};
export type MapSearchOption = NewLiveMapEntity & {
  displayText: string | undefined;
  displaySearchId: any;
  displayAdditionalInfo: string | undefined;
  isWorkspaceActive: boolean;
  isLayerActive: boolean;
  optionAdditionalText: string | boolean;
  venueName: string;
} & oldMapUI;

@Injectable({
  providedIn: 'root',
})
export class MapSearchService {
  constructor(private httpClient: HttpClient) {}

  searchEntityByStringOrId(searchInput, resultSize = 10): Observable<MapSearchOption[]> {
    const url = `${environment.live_map_api_url}/search?search_value=${searchInput}&results_size=${resultSize}`;
    return this.httpClient.get(url).pipe(
      map(res => this.parseSearchResult(res)),
      catchError(err => throwError(err))
    );
  }

  // TODO: Tablet support - remove type after refactor is done
  parseSearchResult(results) {
    let parserRes: MapSearchOption[] = [];
    parserRes = (results as any[]).map(entity => {
      entity.type = entity.type ? (entity.type as string).replace('wr_', '') : undefined;
      switch (entity.layerType) {
        case LayerType.Camera:
        case LayerType.Dms:
          entity['featureSubTypeOf'] = MainEntityType.asset;
          entity['featureType'] = entity.layerType.toLowerCase();
          break;
        case LayerType.RoadClosure:
        case LayerType.Construction:
          entity['featureSubTypeOf'] = MainEntityType.traffic_disruption;
          entity['featureType'] = entity.layerType.toLowerCase();
          break;
        case LayerType.SpecialEvent:
          entity['featureSubTypeOf'] = MainEntityType.traffic_disruption;
          entity['featureType'] = MainEntityType.special_event;
          break;
        default:
          entity['featureSubTypeOf'] = entity.layerType.toLowerCase();
          entity['featureType'] = entity.type;
      }
      return entity;
    });
    return parserRes;
  }
}
