import { AfterViewInit, Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlFieldComponent } from '../../base/base-control-field.component';
import { SelectOption } from '../../form-controls/form-models';
import { EditableListComponent } from '../../form-fields-controls/editable-list/editable-list.component';

@Component({
  selector: 'wc-roads',
  templateUrl: './roads.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoadsComponent),
      multi: true,
    },
  ],
  styleUrls: ['./roads.component.scss'],
})
export class RoadsComponent extends BaseControlFieldComponent implements OnInit, AfterViewInit {
  @ViewChild('editableList') editableListElm!: EditableListComponent;
  @Input() addlabelTranslateText;
  roadsControl: FormControl = new FormControl();
  disableAddBtn!: boolean;

  roadOptions: SelectOption[] = [];

  get itemsCount() {
    if (this.fieldFormControl.value !== null) {
      const amount = this.fieldFormControl.value.filter(item => item).length;
      return amount;
    }
    return 0;
  }

  constructor() {
    super();
  }

  ngOnInit() {
    this.roadsControl.valueChanges.subscribe(roads => {
      const roadsList = roads.map(road => road.value);
      this.fieldFormControl.setValue(roadsList);
      this.onChanged(this.fieldFormControl.value);
      if (!this.roadsControl.valid) {
        this.formControl.setErrors({ required: true }, { emitEvent: true });
      }
      this.checkForEmptyRoadItem();
    });
  }

  ngAfterViewInit() {
    this.addRoad();
  }

  checkForEmptyRoadItem() {
    const roadsAmount = this.editableListElm ? this.editableListElm.items.length : 0;
    if (!this.roadsControl.value) return;
    const hasEmptyRoadItem = this.roadsControl.value.length === 0 || this.roadsControl.value.length !== roadsAmount;
    this.disableAddBtn = hasEmptyRoadItem;
  }

  addRoad() {
    this.editableListElm.addNewItem();
    this.checkForEmptyRoadItem();
  }

  writeValue(val) {
    if (!val) {
      this.reset();
    }
  }

  updateRoadsOptions(options: SelectOption[]) {
    // console.log(options)
    this.roadOptions = options;
    if (this.editableListElm) this.editableListElm.autoCompleteOptionsUpdate(options);
  }

  reset() {
    if (this.editableListElm) {
      this.editableListElm.deleteAllItems();
      this.editableListElm.addNewItem();
    }
    this.roadsControl.setValue([]);
    this.disableAddBtn = true;
  }
}
