import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { TranslateModule } from '@ngx-translate/core';
import { FocusDirectiveModule, WcPopupCloserModule } from '@wc/wc-common/src';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { LayerTreePanelComponent } from './layer-tree-panel.component';

@NgModule({
  declarations: [LayerTreePanelComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    TranslateModule,
    FormsModule,
    MatTreeModule,
    MatIconModule,
    WcIconModule,
    WcPopupCloserModule,
    FocusDirectiveModule,
  ],
  exports: [LayerTreePanelComponent],
})
export class LayerTreePanelModule {}
