import { Pipe, PipeTransform } from '@angular/core';
import { WeatherEventsAllStates } from '@wc/wc-core/src/lib/stores/entities/weather_events/weather_events.store';
import {
  WeatherCertaintyOrder,
  WeatherSeverityOrder,
  weatherSortOptionsEnum,
} from '@wc/wc-models/src/lib/enums/weather';

@Pipe({
  name: 'weatherEventsSort',
  pure: true,
})
export class WeatherEventSortPipe implements PipeTransform {
  sortByTime(arr): [] {
    return arr.sort((a, b) => {
      return new Date(a.entity.startTime).getTime() - new Date(b.entity.startTime).getTime();
    });
  }

  sortBySubType(arr: WeatherEventsAllStates[]): WeatherEventsAllStates[] {
    return arr.sort((a, b) => {
      if (a.entity?.subType && b.entity?.subType) {
        return a.entity?.subType > b.entity?.subType ? 1 : a.entity?.subType < b.entity?.subType ? -1 : 0;
      } else return 0;
    });
  }

  transform(weatherEvents: WeatherEventsAllStates[], { orderBy }) {
    let sortedWeatherEvents: WeatherEventsAllStates[] = [];
    switch (orderBy) {
      case weatherSortOptionsEnum.certainty:
        WeatherCertaintyOrder.forEach(certainty => {
          let eventWithCurrentCertainty = weatherEvents.filter(
            weatherEvent => weatherEvent.entity?.certainty === certainty
          );
          eventWithCurrentCertainty = this.sortByTime(eventWithCurrentCertainty);
          sortedWeatherEvents = [...sortedWeatherEvents, ...eventWithCurrentCertainty];
        });
        break;

      case weatherSortOptionsEnum.severity:
        WeatherSeverityOrder.forEach(severity => {
          let eventWithCurrentSeverity = weatherEvents.filter(
            weatherEvent => weatherEvent.entity?.severity === severity
          );
          eventWithCurrentSeverity = this.sortByTime(eventWithCurrentSeverity);
          sortedWeatherEvents = [...sortedWeatherEvents, ...eventWithCurrentSeverity];
        });
        break;

      case weatherSortOptionsEnum.type:
        sortedWeatherEvents = this.sortBySubType(weatherEvents);
        break;
      case weatherSortOptionsEnum.startTime:
      default:
        sortedWeatherEvents = this.sortByTime(weatherEvents);
        break;
    }

    return sortedWeatherEvents;
  }
}
