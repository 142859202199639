import { Inject, Injectable, Optional } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AppVersion } from '../app-version';
import { environment } from '../environments/environment';
import {
  AssignUnitToUserGQL,
  CreateUserGQL,
  CreateUserInput,
  DeleteUserGQL,
  DeleteUserInput,
  DeleteUserUnitInput,
  GetCurrentShiftGQL,
  LogoutGQL,
  MeGQL,
  OptionalDateTimePeriodInput,
  SetUserRolesGQL,
  SetUserRolesInput,
  SetUserUnitInput,
  ShiftDto,
  UnassignUnitToUserGQL,
  UnitVehicle,
  UpdateUnitLocationGQL,
  UpdateUnitLocationInput,
  UpdateUserGQL,
  UpdateUserInput,
  User,
  UserGQL,
  UsersGQL,
} from '../models/gql.models';
import { CacheService } from './cache.service';
import { CustomRxOperatorsService } from './custom-rx-operators.service';
import { GeoService } from './geo.service';
import { OfflineRequests, OfflineService, OfflineUpdateRequest } from './offline.service';
import { SmartlookService } from './smartlook.service';
import { IsTabletMode } from './tokens';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends OfflineService {
  authUser!: User;
  isTabletMode = false;
  nameSpace: string = 'UsersService';

  constructor(
    @Optional() @Inject(IsTabletMode) _isTabletMode: boolean,
    private meGQL: MeGQL,
    private userInfoGQL: UserGQL,
    private updateUserGQL: UpdateUserGQL,
    private createUserGQL: CreateUserGQL,
    private deleteUserGQL: DeleteUserGQL,
    private usersGQL: UsersGQL,
    private setUserRolesGQL: SetUserRolesGQL,
    private assignUnitToUserGQL: AssignUnitToUserGQL,
    private unassignUnitToUserGQL: UnassignUnitToUserGQL,
    private logoutGQL: LogoutGQL,
    private getCurrentShiftGQL: GetCurrentShiftGQL,
    private updateUnitLocationGQL: UpdateUnitLocationGQL,
    windowService: WindowService,
    cacheService: CacheService,
    private smartlook: SmartlookService,
    private geoService: GeoService,
    private customRxOperators: CustomRxOperatorsService
  ) {
    super(windowService, cacheService);
    this.isTabletMode = !!_isTabletMode;
  }

  get authUserAccountID() {
    return this.authUser.account.id;
  }

  canUpdate(updateRequest: OfflineUpdateRequest): boolean {
    return true;
  }

  isValidId(id: any) {
    return true;
  }

  getIdFromObject(updateRequest: OfflineUpdateRequest) {
    return null;
  }

  setIdToObject(updateRequest: OfflineUpdateRequest) {}

  getAuthUser() {
    //TODO: info user api
    const remoteUser$ = this.meGQL.fetch().pipe(
      map(res => res.data.me as User),
      map(res => {
        console.log({ res });
        return res;
      }),
      tap(user => {
        this.smartlook
          .init()
          .then(() => {
            this.smartlook.setIdentify(user);
          })
          .catch(() => {
            console.log('No smartlook!!!');
          });
        localStorage.setItem('user', JSON.stringify(user));
        this.geoService.setWorkspaces(user.account.workspaces.workspaces);
      })
    );
    return (navigator.onLine ? remoteUser$ : of(this.getStoredUser())).pipe(
      tap((user: User) => {
        this.authUser = user;
        this.initDatadogLogger();
      }),

      this.customRxOperators.catchGqlErrors()
    );
  }

  initDatadogLogger() {
    if (environment.enableProdMode) {
      datadogLogs.init({
        clientToken: 'pub89fe7e3ea4b0d9977952f83c74ef0559',
        site: 'datadoghq.com',
        forwardErrorsToLogs: true, // sending errors by defualt
        sampleRate: 100,
        env: environment.production ? 'prod' : 'staging',
        version: AppVersion,
      });

      datadogLogs.setLoggerGlobalContext({
        host: this.isTabletMode ? 'fe-tablet' : 'fe-desktop',
        service: 'frontend-client',
        env: environment.production ? 'prod' : 'staging',
        wc_version: AppVersion,
        wc_user_id: this.authUser.id,
        wc_account_id: this.authUser.account.id,
        wc_account_name: this.authUser.account.name,
        wc_user_name: this.authUser.name,
      });

      datadogRum.init({
        applicationId: '4715df34-5499-493a-8fee-c96ed6e16237',
        clientToken: 'pub80cadc05df3dd1cd2fc6f25014809192',
        site: 'datadoghq.com',
        env: environment.production ? 'prod' : 'staging',
        service: this.isTabletMode ? 'fe-tablet' : 'fe-desktop',
        version: AppVersion,
        sampleRate: 100,
        trackInteractions: true,
      });
    }
  }

  getStoredUser(): User {
    let user;
    try {
      const encodedValue = localStorage.getItem('user');
      user = JSON.parse(encodedValue as string);
    } catch (e) {
      console.error(`Unable to load user!`);
    }
    return user;
  }

  getCurrentShift() {
    return this.getCurrentShiftGQL.fetch().pipe(
      map(res => res.data.getCurrentShift as ShiftDto),
      this.customRxOperators.catchGqlErrors()
    );
  }

  getUserByID(userId: number) {
    return this.userInfoGQL.fetch({ id: userId }).pipe(
      map(res => res.data.user as User),
      this.customRxOperators.catchGqlErrors()
    );
  }

  getUsers(optionalDateTimePeriodInput: OptionalDateTimePeriodInput = { value: null }) {
    return this.usersGQL
      .watch({
        optionalDateTimePeriodInput,
      } as never)
      .valueChanges.pipe(
        map(res => res?.data?.users as User[]),
        this.customRxOperators.catchGqlErrors()
      );
  }

  updateUser(input: UpdateUserInput) {
    return this.updateUserGQL.mutate({ input: input }).pipe(
      map(res => res?.data?.updateUser as User),
      this.customRxOperators.catchGqlErrors()
    );
  }

  createUser(input: CreateUserInput) {
    return this.createUserGQL.mutate({ input: input }).pipe(
      map(res => res?.data?.createUser as User),
      this.customRxOperators.catchGqlErrors()
    );
  }

  assignUserRoles(input: SetUserRolesInput) {
    return this.setUserRolesGQL.mutate({ input: input }).pipe(
      map(res => res?.data?.setUserRoles as User),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: this.assignUserRoles,
            params: [input],
            onError: (updateRequest: OfflineUpdateRequest) => {},
          },
          defaultValue: true,
        })
      )
    );
  }

  deleteUser(input: DeleteUserInput) {
    return this.deleteUserGQL.mutate({ input: input }).pipe(
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: this.deleteUser,
            params: [input],
            onError: (updateRequest: OfflineUpdateRequest) => {},
          },
          defaultValue: true,
        })
      )
    );
  }

  assignUnitToUser(input: SetUserUnitInput) {
    return this.assignUnitToUserGQL.mutate({ input }).pipe(
      map(res => res?.data?.assignUnitToUser),
      tap(res => {
        const userDetails = {
          ...res?.unit?.userDetails,
          unitRelationType: res?.unitRelationType,
        };
        this.authUser.unit = res?.unit;
      }),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: OfflineRequests.assignUnitToUser,
            params: [input],
            onError: (updateRequest: OfflineUpdateRequest) => {
              this.authUser.unit = {
                id: input.unitId,
              } as UnitVehicle;
            },
          },
          defaultValue: true,
        })
      )
    );
  }

  unassignUnitToUser(input: DeleteUserUnitInput) {
    return this.unassignUnitToUserGQL.mutate({ input }).pipe(
      map(res => res?.data?.unassignUnitToUser as User),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: this.unassignUnitToUser,
            params: [input],
            onError: (updateRequest: OfflineUpdateRequest) => {},
          },
          defaultValue: true,
        })
      )
    );
  }

  updateUnitLocation(input: UpdateUnitLocationInput) {
    return this.updateUnitLocationGQL.mutate({ input }).pipe(
      map(res => res?.data?.updateUnitLocation),
      // TODO: do not send offline location updates unless backend supports it
      this.customRxOperators.catchGqlErrors()
    );
  }

  logoutUser() {
    return this.logoutGQL.mutate();
  }
}
