import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Media } from '@wc/core';
import { downloadFile as downloadFileUtil } from '@wc/utils';
import { DOC_SUPPORTED_MIME_TYPES, KeyCodes } from '@wc/wc-models/src';
import { interval, Observable, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import Swiper, { SwiperOptions } from 'swiper';

const URL_NAME_REGEX = 'upload(-)[0-9]*-[0-9]*(-)';

@Component({
  selector: 'wc-media-section-modal',
  templateUrl: './media-full-screen.component.html',
  styleUrls: ['./media-full-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaFullScreenComponent implements AfterViewInit {
  @ViewChild('docIframe') docIframe!: ElementRef<HTMLIFrameElement>;
  mediaList: Array<Media | File> = [];
  deletedMedia: Array<Media | File> = [];
  selectedMedia!: Media | File;
  selectedMediaIndex = -1;
  isEmptyState = false;
  isEditMode = false;
  isTabletMode = false;
  mediaTitle = '';
  mediaDate = '';
  urlNameRegex = URL_NAME_REGEX;
  showDocViewer = false;
  iFrameFetchedSuccessfully = false;
  documentRefreshInterval = 5500;
  maximumRetries = 1;
  showPreviewError = false;
  subscription?: Subscription;
  timeOut: ReturnType<typeof setTimeout> | undefined;
  downloadFile = downloadFileUtil;

  constructor(
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<MediaFullScreenComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      list: Array<Media | File>;
      selectedIndex: number;
      isEditMode: boolean;
      isTabletMode?: boolean;
      renewUrlFunction: (args) => Observable<string | undefined>;
    }
  ) {
    this.setSwiperConfig();
    this.mediaList = [...[], ...data.list];
    this.config.initialSlide = data.selectedIndex;
    this.isEditMode = data.isEditMode;
    this.isTabletMode = data.isTabletMode || false;
    this.setMainMedia(data.selectedIndex);
  }

  config!: SwiperOptions;

  setSwiperConfig() {
    this.config = {
      spaceBetween: 10,
      speed: 100,
      mousewheel: {
        sensitivity: 0.1,
        thresholdTime: 200,
      },
      scrollbar: {
        el: '.swiper-pagination-media-modal',
        draggable: true,
      },
      autoHeight: false,
      keyboard: {
        enabled: true,
      },
      slideClass: 'swiper-slide',
      threshold: 5,
      breakpoints: {
        0: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 6,
        },
      },
      slidesPerView: 6,
      navigation: {
        nextEl: '.swiper-button-next-file',
        prevEl: '.swiper-button-prev-file',
      },
    };
  }

  ngAfterViewInit(): void {
    this.setSwiperConfig();
  }

  setMainMedia(index: number) {
    if (this.selectedMediaIndex !== index) {
      this.showDocViewer = false;
      this.showPreviewError = false;
      this.iFrameFetchedSuccessfully = false;
      this.selectedMedia = this.mediaList[index];
      this.selectedMediaIndex = index;

      this.mediaTitle =
        this.selectedMedia instanceof File ? this.selectedMedia.name : this.getMediaTitle(this.selectedMedia);

      // upload phase - frontend side
      const fileType = (this.selectedMedia as File).type;
      if (fileType && DOC_SUPPORTED_MIME_TYPES.includes(fileType)) {
        this.showPreviewError = true;
        this.showDocViewer = true;
      }

      // media came from backend
      const mediaItem = this.selectedMedia as Media;
      if (mediaItem.media) {
        if (mediaItem.media.isDocument) {
          if (this.isTabletMode) {
            this.showPreviewError = true;
          } else {
            this.showPreviewError = false;
            this.restartDocumentIframe();
          }
          this.showDocViewer = true;
        } else this.showDocViewer = false;
      }
    }
  }

  getMediaTitle(mediaItem: Media) {
    const result = mediaItem.media.key.match(this.urlNameRegex);
    const position = result ? mediaItem.media.key.lastIndexOf(result[0]) + result[0].length : 0;

    return mediaItem.media.key.substring(position);
  }

  restartDocumentIframe = () => {
    if (!this.iFrameFetchedSuccessfully) {
      let remainingRetries = this.maximumRetries;
      this.subscription?.unsubscribe();
      this.subscription = interval(this.documentRefreshInterval)
        .pipe(takeWhile(() => remainingRetries > 0 && !this.iFrameFetchedSuccessfully))
        .subscribe(() => {
          this.docIframe.nativeElement.src = `${this.docIframe.nativeElement.src}`;
          remainingRetries--;

          if (remainingRetries === 0)
            this.timeOut = setTimeout(() => {
              this.cdr.detectChanges();
              if (!this.iFrameFetchedSuccessfully) {
                this.showPreviewError = true;
                this.cdr.markForCheck();
              }
            }, this.documentRefreshInterval);
        });
    }
  };

  mediaDeleted(index: number) {
    this.deletedMedia.push(this.mediaList[index]);
    this.mediaList.splice(index, 1);
    this.setSwiperConfig();
    if (this.mediaList.length === 0) {
      this.isEmptyState = true;
    } else if (this.mediaList.length === 1 || index === 0) {
      this.setMainMedia(0);
    } else {
      this.setMainMedia(index - 1);
    }
  }

  save() {
    this.dialogRef.close(this.deletedMedia);
  }

  cancel() {
    this.dialogRef.close();
  }

  handleSwiperKeyPressed([, keyCode]: [Partial<typeof Swiper>, number]) {
    let newIndex = -1;

    if (keyCode === KeyCodes.RIGHT_ARROW) {
      const consideredIndex = this.selectedMediaIndex + 1;
      newIndex = consideredIndex === this.mediaList.length ? this.mediaList.length - 1 : consideredIndex;
    } else if (keyCode === KeyCodes.LEFT_ARROW) {
      const consideredIndex = this.selectedMediaIndex - 1;
      newIndex = consideredIndex < 0 ? 0 : consideredIndex;
    }

    if (newIndex !== -1) this.setMainMedia(newIndex);
  }

  onDocIframeLoaded() {
    this.clearTimeout();
    this.iFrameFetchedSuccessfully = true;
    this.showPreviewError = false;
    this.cdr.markForCheck();
  }

  clearTimeout() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = undefined;
    }
  }
}
