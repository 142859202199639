import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormFieldOption } from '@wc/wc-ui/src/lib/base';

export type GroupedOption<T> = {
  label: string;
  options: T[];
};

@Injectable()
export class AutocompleteGroupingService<T extends FormFieldOption<unknown, unknown>> {
  groupedOptions: GroupedOption<T>[] = [];
  otherTranslation = '';

  constructor(private readonly translateService: TranslateService) {
    this.translateService.get('OTHER').subscribe(translation => (this.otherTranslation = translation));
  }

  groupOptions(options: T[]) {
    const groupedOptionsRecord = options.reduce((groups, option) => {
      const groupLabel = option.groupLabel || this.otherTranslation;
      if (!groups[groupLabel]) {
        groups[groupLabel] = [];
      }
      groups[groupLabel].push(option);
      return groups;
    }, {} as { [key: string]: FormFieldOption[] });
    this.groupedOptions = Object.keys(groupedOptionsRecord).map(label => ({
      label,
      options: groupedOptionsRecord[label] as T[],
    }));
    return this.groupedOptions;
  }

  groupedFilter(inputValue: string) {
    return this.groupedOptions
      .map(group => {
        const value = inputValue.trim().toLocaleLowerCase();
        return {
          ...group,
          options: (group.label || this.otherTranslation).toLocaleLowerCase().includes(value)
            ? group.options
            : group.options.filter(item => item.displayName?.toLocaleLowerCase().includes(value)),
        };
      })
      .filter(group => group.options.length > 0);
  }
}
