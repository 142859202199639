export enum MapStyleNameOnServer {
  light = 'cky1dvfck9wvb14nuqu7myl62',
  dark = 'cl82w734b002114l5pcv2yifc',
}

export enum ThemeName {
  light = 'light',
  dark = 'dark',
}

export enum StaticLayerName {
  vectorMap = 'vectorMap',
  tilesMap = 'tilesMap',
  satelliteMap = 'satelliteMap',
  mapBoxTraffic = 'mapBoxTraffic',
  mileMarkers = 'mileMarkers',
}

export enum mapVendorName {
  MAPBOX = 'mapbox',
  // BING = 'bing',
  HERE = 'here',
  AWS = 'aws',
  REKOR = 'rekor',
}

export enum InteractionsEnum {
  select = 'select',
  hover = 'hover',
  draw = 'draw',
  rightClick = 'rightClick',
}

export enum CustomStyleContextEnum {
  highlight = 'highlight',
}

export enum InteractionsEvents {
  select = 'select',
  unselect = 'unselect',
  hover = 'hover',
  drawend = 'drawend',
  modifyend = 'modifyend',
  rightClick = 'rightClick',
  moveEnd = 'moveEnd',
  moveStart = 'moveStart',
  pointerDrag = 'pointerDrag',
  resolution = 'resolution',
}

export enum MapLanguages {
  English = 'en',
  Hebrew = 'he',
}

export enum WcGeometryEnum {
  POINT = 'Point',
  LINE_STRING = 'LineString',
  LINEAR_RING = 'LinearRing',
  POLYGON = 'Polygon',
  MULTI_POINT = 'MultiPoint',
  MULTI_LINE_STRING = 'MultiLineString',
  MULTI_POLYGON = 'MultiPolygon',
  GEOMETRY_COLLECTION = 'GeometryCollection',
  CIRCLE = 'Circle',
}
