import { Injectable } from '@angular/core';
import { LineString, LiveMapEntity, Point, Polygon } from '@wc/core/models';
import { Coordinate } from 'ol/coordinate';
import Feature, { FeatureLike } from 'ol/Feature';
import { LineString as olLineString, Point as olPoint, Polygon as olPolygon } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import { Draw, Modify, Snap } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import { ModifyEvent } from 'ol/interaction/Modify';
import { Vector as VectorLayer } from 'ol/layer';
import Map from 'ol/Map';
import { toLonLat } from 'ol/proj.js';
import { Vector as VectorSource } from 'ol/source';
import { Subject } from 'rxjs';
import * as wcModels from '../../../core/models';
import * as Styles from './styles/styles';
import { coordsFormatFromLonLat } from './utils';

@Injectable({
  providedIn: 'root',
})
export class WcMapDrawService {
  map!: Map;
  source = new VectorSource();
  vector: VectorLayer = new VectorLayer({
    source: this.source,
    zIndex: 100,
    style: feature => {
      const properties = feature.getProperties();

      if (properties) {
        return Styles.styleType(feature.getProperties() as LiveMapEntity, this.config);
      }
    },
  });
  draw;
  snap;
  modifyInteraction;
  config;

  // geometry: Subject<{coordinates:[] | [][] | [][][], type: 'Point' | 'LineString' | 'Polygon'}> = new Subject();
  geometry: Subject<wcModels.Point | wcModels.LineString | wcModels.Polygon> = new Subject<
    wcModels.Point | wcModels.LineString | wcModels.Polygon
  >();
  currentDrawType!: string; // 'Point' | 'LineString' | 'Polygon';

  constructor() {
    this.vector.set('name', 'draw');
  }

  init(map, config) {
    this.map = map;
    this.config = config;
    this.map.addLayer(this.vector);
    this.resetLayer();
  }

  resetLayer() {
    // this.vector.setSource(new VectorSource());
    if (!this.map) return;
    this.source.refresh();
    this.vector.getSource().refresh();
    this.map.removeLayer(this.vector);
    this.map.addLayer(this.vector);
    if (this.modifyInteraction) this.modifyInteraction.removePoint();
  }

  create(type: 'Point' | 'LineString' | 'Polygon', location?: Point | Polygon | LineString) {
    this.currentDrawType = type;
    this.resetLayer();
    if (location && location.type === 'Point' && type === 'Point' && location.coordinates) {
      const coordinates = coordsFormatFromLonLat(location);
      const feature: Feature = new Feature({
        geometry: new olPoint(coordinates as Coordinate),
        featureType: 'draw',
        status: 'modify_pointer',
      });
      const source = this.vector.getSource();
      source.addFeature(feature);
      this.modify(source);
      // feature.set('featureType', 'draw');
    } else {
      // Stop Double click zoom listener.
      this.map.getInteractions().item(1).setActive(false);
      let drawType: GeometryType | undefined = undefined;
      switch (type) {
        case 'Point':
          drawType = GeometryType.POINT;
          break;
        case 'LineString':
          drawType = GeometryType.LINE_STRING;
          break;
        case 'Polygon':
          drawType = GeometryType.POLYGON;
          break;
      }
      this.draw = new Draw({
        source: this.source,
        type: drawType,
      });

      this.draw.on('drawend', e => {
        e.feature.set('featureType', 'draw');
        e.feature.set('status', 'modify_geometry_pointer');
        console.log(e);
        this.emitUpdateGeometry(e);
        this.map.removeInteraction(this.draw);
        this.map.removeInteraction(this.snap);
        this.modify(this.source);
        setTimeout(() => {
          // Active Double click zoom listener.
          this.map.getInteractions().item(1).setActive(true);
        });
      });

      this.map.addInteraction(this.draw);
    }

    this.snap = new Snap({ source: this.source });
    this.map.addInteraction(this.snap);
  }

  edit(location: Point | Polygon | LineString) {
    //type: 'Point' | 'LineString' | 'Polygon',
    this.currentDrawType = location.type;
    this.resetLayer();
    const coordinates = coordsFormatFromLonLat(location);
    // console.log(location);
    let geometry;
    let status;
    switch (location.type) {
      case 'Point':
        geometry = new olPoint(coordinates as Coordinate);
        status = 'modify_pointer';
        break;
      case 'LineString':
        geometry = new olLineString(coordinates as Coordinate[]);
        status = 'modify_geometry';
        break;
      case 'Polygon':
        geometry = new olPolygon(coordinates as Coordinate[][]);
        status = 'modify_geometry';
        break;
    }
    const feature: Feature = new Feature({
      geometry: geometry,
      featureType: 'draw',
      status: status,
    });
    const source = this.vector.getSource();
    source.addFeature(feature);
    this.modify(source);
  }

  view(location: Point | Polygon | LineString) {
    //type: 'Point' | 'LineString' | 'Polygon',
    this.currentDrawType = location.type;
    this.resetLayer();
    const coordinates = coordsFormatFromLonLat(location);
    // console.log(location);
    let geometry;
    let status;
    switch (location.type) {
      case 'Point':
        geometry = new olPoint(coordinates as Coordinate);
        status = 'view_pointer';
        break;
      case 'LineString':
        geometry = new olLineString(coordinates as Coordinate[]);
        status = 'view_geometry';
        break;
      case 'Polygon':
        geometry = new olPolygon(coordinates as Coordinate[][]);
        status = 'view_geometry';
        break;
    }
    const feature: Feature = new Feature({
      geometry: geometry,
      featureType: 'draw',
      status: status,
    });
    const source = this.vector.getSource();
    source.addFeature(feature);
  }

  modify(source) {
    this.modifyInteraction = new Modify({
      source: source,
      pixelTolerance: 40,
      style: (feature: FeatureLike) => {
        if (feature.getProperties()) {
          const _feature = feature.getProperties() as LiveMapEntity;
          _feature.featureType = 'draw';
          _feature.status = 'modify_geometry';
          return Styles.styleType(_feature, this.config);
        }
      },
    });
    this.map.addInteraction(this.modifyInteraction);
    this.modifyInteraction.on('modifyend', (e: ModifyEvent) => {
      this.emitUpdateGeometry(e);
    });
    // this.modifyInteraction.on('keydown', (e) => {
    //   console.log(e)
    // });
  }

  emitUpdateGeometry(e: ModifyEvent | DrawEvent) {
    let geometry;

    if (e instanceof ModifyEvent && e.features) {
      geometry = e.features.getArray()[0]?.getGeometry();
    } else {
      geometry = (e as DrawEvent).feature.getGeometry();
    }
    const coordinates = geometry?.getCoordinates();

    if (geometry instanceof olPoint) {
      const point: Point = {
        type: 'Point',
        coordinates: toLonLat(coordinates) as Coordinate,
      };
      this.geometry.next(point);
    }

    if (geometry instanceof olLineString) {
      const lineCoords: Coordinate[] = [];
      coordinates.forEach(coord => {
        lineCoords.push(toLonLat(coord));
      });
      this.geometry.next({ coordinates: lineCoords, type: 'LineString' });
    }

    if (geometry instanceof olPolygon) {
      const polyCoords: Coordinate[][] = [];
      const linearRings = geometry.getLinearRings();
      linearRings.forEach(linearRing => {
        const linear: Coordinate[] = [];
        linearRing.getCoordinates().forEach(coord => {
          linear.push(toLonLat(coord));
        });
        polyCoords.push(linear);
      });
      this.geometry.next({ coordinates: polyCoords, type: 'Polygon' });
    }
  }

  stopDraw() {
    if (this.draw) this.map.removeInteraction(this.draw);
    if (this.snap) this.map.removeInteraction(this.snap);
    if (this.modifyInteraction) this.map.removeInteraction(this.modifyInteraction);
  }

  destroy() {
    this.map.removeLayer(this.vector);
    this.stopDraw();
    // Active Double click zoom listener.
    this.map.getInteractions().item(1).setActive(true);
  }
}
