import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { WcDateFieldModule } from '../../form-fields-controls/date-field/date-field.module';
import { WcTimeFieldModule } from '../../form-fields-controls/time-field/time-field.module';
import { DateTimeComponent } from './date-time.component';

@NgModule({
  declarations: [DateTimeComponent],
  imports: [CommonModule, WcBaseControlFieldModule, WcDateFieldModule, WcTimeFieldModule],
  providers: [],
  exports: [DateTimeComponent, WcDateFieldModule, WcTimeFieldModule],
})
export class WcDateTimeModule {}
