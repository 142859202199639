import { TranslateService } from '@ngx-translate/core';
import { OccupancyRange, SpecialEvent, SpecialEventView, TrafficDisruptionViewData, VenueData } from '@wc/core/models';

export const getSpecialEventData = (
  specialEvent: SpecialEvent | SpecialEventView,
  translateService: TranslateService
): TrafficDisruptionViewData['specialEventViewData'] => {
  const { venue, occupancyRange } = specialEvent as SpecialEvent;
  const { venueAddress, venueName } = specialEvent as SpecialEventView;

  let specialEventAttendance: OccupancyRange | 'NA' = translateService.instant('NA');
  const venueData = {} as VenueData;

  if (venue) {
    if (venue.name && venue.address) {
      venueData.venueName = venue.name;
      venueData.venueAddress = venue.address;
    }
  } else {
    if (venueAddress && venueName) {
      venueData.venueName = venueName;
      venueData.venueAddress = venueAddress;
    }
  }

  if (occupancyRange) {
    specialEventAttendance = translateService.instant('specialEventOccupancyRange.' + occupancyRange);
  }

  return {
    venueData,
    specialEventAttendance,
  };
};
