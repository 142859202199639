import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { TextFieldModule } from '../../form-components/text-field/text-field.module';
import { CheckboxListControlModule } from '../checkbox-list-control/checkbox-list-control.module';
import { FreeTextListControlComponent } from './free-text-list-control.component';

@NgModule({
  declarations: [FreeTextListControlComponent],
  imports: [
    CommonModule,
    MatInputModule,
    TextFieldModule,
    ReactiveFormsModule,
    TranslateModule,
    CheckboxListControlModule,
  ],
  exports: [FreeTextListControlComponent],
})
export class FreeTextListControlModule {}
