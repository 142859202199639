import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CustomFormControlComponent } from '../../lib/base/custom-form-control';

@Component({
  selector: 'wc-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RadioButtonComponent extends CustomFormControlComponent {
  @Input() column = true;
  @Input() size: 'small' | 'medium' = 'small';
  @Input() boldLabel = true;
  @Input() helpTextTranslationKey = '';
  @Input() isDisabled = false;
  @Input() disableFocusStyle = false;
}
