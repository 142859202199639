import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MinutesDurationToTimePipe } from './minutesDurationToTime.pipe';

@NgModule({
  declarations: [MinutesDurationToTimePipe],
  imports: [CommonModule],
  exports: [MinutesDurationToTimePipe],
})
export class MinutesDurationToTimePipeModule {}
