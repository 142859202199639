import { LayerType } from '@wc/core';
import { entitySubType } from '@wc/core/models/enums';

export function getLayerName(layerType: LayerType, type: string | undefined): string {
  if (layerType === LayerType.Incident) {
    return `${layerType}-${type?.toLowerCase()}`;
  }

  if (type === 'OTHER') {
    return `${layerType}-${layerType?.toLowerCase()}`;
  }
  return `${layerType}-${(entitySubType[type || layerType] || type || layerType)?.toLowerCase()}`;
}
