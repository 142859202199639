<div class="backdrop" (click)="close($event)" [class.tablet-mode]="isTabletMode">
  <div [formGroup]="form" class="modal-container">
    <div class="modal-title-and-form">
      <div class="address-modal-title bold-title hide-in-landscape">
        {{ 'editLocation' | translate }}
      </div>

      <wc-address-control
        #address
        [isIncidentEntity]="true"
        [showJurisdiction]="true"
        [formControl]="$any(form.get('address'))"
        [showMap]="true"
        [showLoader]="true"
        [updateMapCenter]="false"
        (addressLoading)="onAddressLoading($event)"
        (addressError)="onAddressError($event)">
      </wc-address-control>
    </div>

    <div class="address-modal-buttons-container">
      <button class="mat-stroked-button address-cancel-button" variant="outlined" (click)="cancel()">
        {{ 'confirmModal.cancelButton' | translate }}
      </button>
      <button
        class="primary-button save-button"
        [disabled]="isOutsideJurisdiction || this.form.invalid || this.disableButtons"
        (click)="save()">
        {{ 'save' | translate }}
      </button>
    </div>
  </div>
</div>
