import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IncidentNote, IncidentService, IncidentStore, NoteField, UiStore, WindowService } from '@wc/core';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { BaseControlFieldComponent } from '../../base/base-control-field.component';
import { FieldData } from '../../form-controls/form-models';
import { TextAreaComponent } from '../../form-fields-controls/text-area/text-area.component';

export type RoadEventNote = IncidentNote;

@Component({
  selector: 'wc-notes-control',
  templateUrl: './notes.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NotesFiledComponent),
      multi: true,
    },
  ],
  styleUrls: ['./notes.component.scss'],
})
export class NotesFiledComponent extends BaseControlFieldComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('noteRefs') noteRefs!: QueryList<ElementRef<TextAreaComponent>>;
  @Input() startWithItem: boolean = false;
  notes: FormArray = this.fb.array([]);
  notesForm: FormGroup = this.fb.group({ notes: this.notes });
  notesChangedSubscription?: Subscription;

  // the first one is being added automatically, hence the 1 and no 0
  prevNotesLength = 1;

  disableAddBtn = false;
  online$: Observable<boolean>;

  notesFieldData: { [fieldName in keyof NoteField]: FieldData } = {
    note: {
      placeholder: this.translateService.instant('enterNote'),
      // required: true
    },
  };

  showOfflineItemDisabledError!: boolean;

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  constructor(
    private fb: FormBuilder,
    public uiStore: UiStore,
    private incidentStore: IncidentStore,
    private translateService: TranslateService,
    private incidentService: IncidentService,
    private windowService: WindowService
  ) {
    super();
    this.online$ = this.windowService.online$;
  }

  ngOnInit(): void {
    if (!this.startWithItem) this.prevNotesLength = 0;

    this.notesForm.valueChanges.subscribe(formValue => {
      this.fieldFormControl.setValue(formValue.notes);
      this.onChanged(this.fieldFormControl.value);
      this.markAllAsTouched();
      // if (this.fieldFormControl.invalid) {
      //   this.formControl.setErrors({required: true}, { emitEvent: true });
      // }
    });
  }

  ngAfterViewInit() {
    if (this.startWithItem && !this.notes.controls?.length) {
      this.addNote();
    }

    this.notesChangedSubscription = this.noteRefs.changes.subscribe(v => {
      if (this.prevNotesLength !== v.length) v.first?.textAreas?.nativeElement.focus();
      this.prevNotesLength = v.length;
    });
  }

  removeNote(index) {
    this.notes.removeAt(index);
  }

  writeValue(val): void {
    if (val) {
      this.notes.reset();

      this.showOfflineItemDisabledError = false;
      val.forEach(note => {
        this.addNote(note);
      });
    }
  }

  isOfflineNote(id) {
    return this.isTabletMode && !this.incidentService.isValidId(id);
  }

  addNote(noteInput?: RoadEventNote) {
    const note = this.fb.group({
      id: noteInput?.id,
      createdAt: noteInput?.createdAt,
      note: [null],
    });
    if (this.isTabletMode && !this.incidentService.isValidId(noteInput?.id)) {
      this.showOfflineItemDisabledError = true;
    }

    // Update WR flow
    if (noteInput && noteInput.id) {
      note.patchValue(noteInput);

      // Related Incident/WR flow
    } else if (noteInput && !noteInput.id) {
      note.patchValue(noteInput);
      note.get('id')?.disable();
      note.get('createdAt')?.disable();

      // Create new WR flow
    } else {
      note.get('id')?.disable();
      note.get('createdAt')?.disable();
    }

    this.notes.insert(0, note);
  }

  markAllAsTouched() {
    const notes = this.notes.controls;
    notes.forEach(noteItem => {
      if (!this.formControl.valid && this.formControl.touched) {
        noteItem.get('note')?.markAsTouched();
      }
    });
  }

  setDisabledState() {
    this.disableAddBtn = true;
  }

  ngOnDestroy(): void {
    this.notesChangedSubscription?.unsubscribe();
  }
}
