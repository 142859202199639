import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { TranslateModule } from '@ngx-translate/core';
import { NumbersOnlyFieldComponent } from './numbers-only-field.component';

const Materials: any[] = [];

@NgModule({
  declarations: [NumbersOnlyFieldComponent],
  imports: [CommonModule, WcBaseControlFieldModule, TranslateModule, Materials],
  providers: [],
  exports: [NumbersOnlyFieldComponent],
})
export class NumberOnlyComponentModule {}
