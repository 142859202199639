import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { WcBaseControlFieldModule } from '../../lib/base/base-control-field.module';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { ButtonToggleGroupComponent } from './button-toggle-group.component';

@NgModule({
  declarations: [ButtonToggleGroupComponent],
  imports: [CommonModule, WcBaseControlFieldModule, MatButtonToggleModule, WcIconModule],
  exports: [ButtonToggleGroupComponent],
})
export class ButtonToggleGroupModule {}
