<mat-form-field
  class="wc-autocomplete-wrapper"
  [class.invalid-field]="formControl.invalid && formControl.touched"
  [floatLabel]="floatLabel ? 'auto' : 'never'"
  [class.tablet-mode]="isTabletMode">
  <mat-label class="field-label">
    <span class="required-section" *ngIf="fieldData.required">*</span>
    {{ fieldData?.label | translate }}
  </mat-label>

  <mat-select
    [formControl]="$any(fieldFormControl)"
    #autocompleteSelectPanel
    (opened)="selectPanelOpened()"
    (closed)="selectPanelClosed()"
    [disableOptionCentering]="true"
    [panelClass]="
      isTabletMode
        ? 'autocomplete-select-panel segment-autocomplete tablet-mode'
        : 'autocomplete-select-panel segment-autocomplete'
    ">
    <mat-select-trigger>
      {{ fieldFormControl.value }}
    </mat-select-trigger>

    <mat-form-field class="autocomplete-input-container" floatLabel="never">
      <input
        matInput
        type="text"
        class="autocomplete-search-input"
        data-cy-id="autocomplete-search-input"
        [formControl]="$any(autocompleteInput)"
        autocomplete="off"
        (keydown)="$event.stopPropagation()"
        #autocompleteInputField />
      <mat-placeholder class="placeholder">{{ fieldData?.placeholder | translate }}</mat-placeholder>

      <button
        class="clear-autocomplete-button"
        data-cy-id="clear-autocomplete-button"
        *ngIf="autocompleteInput.value"
        mat-icon-button
        (click)="clear(); $event.preventDefault()">
        <i class="far fa-times"></i>
      </button>
    </mat-form-field>

    <ng-container *ngIf="offlineMode && !showFilteredOptions">
      <mat-option *ngFor="let option of fieldData.options">
        {{ option.displayName }}
      </mat-option>
    </ng-container>

    <ng-container *ngIf="filteredOptions | async as options">
      <ng-container *ngIf="!showFilteredOptions">
        <mat-optgroup
          *ngFor="let group of optionsGroup"
          [label]="group.name"
          [disabled]="group.disabled"
          [class.tablet-mode]="isTabletMode"
          class="group-title">
          <mat-option
            *ngFor="let option of group.options"
            [value]="option.value"
            (click)="selectOption(option, $event)">
            <div class="option-value-content" *ngIf="option.data">
              <div class="option-value-container">
                <ng-container
                  *ngTemplateOutlet="optionIcon; context: { roadType: option.data?.roadType }"></ng-container>
                <span class="additional-option-value">{{ option.displayName }}</span>
              </div>

              <mat-label class="additional-option-label">
                {{ option.data.distance | distanceFormat }}
              </mat-label>
            </div>
          </mat-option>
        </mat-optgroup>
      </ng-container>

      <ng-container *ngIf="showFilteredOptions">
        <mat-option *ngFor="let option of options" [value]="option.value" (click)="selectOption(option, $event)">
          <div class="option-value-content" *ngIf="option.data">
            <div class="option-value-container" data-cy-id="option-value-container">
              <ng-container *ngTemplateOutlet="optionIcon; context: { roadType: option.data?.roadType }"></ng-container>
              <span class="additional-option-value">{{ option.displayName }}</span>
            </div>

            <mat-label class="additional-option-label">
              {{ option.data.distance | distanceFormat }}
            </mat-label>
          </div>
        </mat-option>
        <div
          *ngIf="isTabletMode && textForNearByOptionsBtn && options?.length === 1"
          class="reset-options heap-tablet-show-near-by-options-button"
          (click)="clear(); $event.stopPropagation()">
          {{ textForNearByOptionsBtn }}
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          !showFilteredOptions && options?.length === 1 && options[0].value === '' && !optionsGroup[0]?.options?.length
        ">
        <mat-option *ngFor="let option of options">
          {{ option.displayName }}
        </mat-option>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!fieldData.options">
      <mat-option> </mat-option>
    </ng-container>
  </mat-select>

  <mat-error *ngIf="validationError">
    {{
      'validationErrorMessages.' + $any(validationError)
        | translate
          : {
              min: fieldFormControl.errors?.min?.min,
              max: fieldFormControl.errors?.max?.max,
              minLength: fieldFormControl.errors?.minlength?.requiredLength,
              maxLength: fieldFormControl.errors?.maxlength?.requiredLength
            }
    }}
  </mat-error>
</mat-form-field>

<ng-template #optionIcon let-roadType="roadType">
  <div *ngIf="isHighway(roadType)" class="additional-option-icon">
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5974 1.61133C14.8326 2.37305 14.9796 3.19336 15.0384 4.07227C15.0584 4.37028 15.0625 4.67953 15.0509 5H0.941508C0.934343 4.52308 0.948441 4.06736 0.983802 3.63281C1.04261 2.91016 1.17002 2.23633 1.36604 1.61133C1.42485 1.43555 1.52286 1.30859 1.66007 1.23047C1.81689 1.13281 1.9737 1.10352 2.13052 1.14258C2.69898 1.29883 3.26744 1.37695 3.83589 1.37695C5.28644 1.37695 6.53117 0.966797 7.57008 0.146484C7.68769 0.0488281 7.8249 0 7.98172 0C8.13854 0 8.27575 0.0488281 8.39336 0.146484C9.43227 0.966797 10.677 1.37695 12.1275 1.37695C12.696 1.37695 13.2645 1.29883 13.8329 1.14258C13.9897 1.10352 14.1368 1.13281 14.274 1.23047C14.4308 1.30859 14.5386 1.43555 14.5974 1.61133ZM0.985476 6C1.04608 6.83221 1.23174 7.67401 1.54246 8.52539C1.85609 9.36523 2.26773 10.1855 2.77739 10.9863C3.30664 11.7676 4.01231 12.5098 4.8944 13.2129C5.79609 13.9355 6.8252 14.5312 7.98172 15C9.37346 14.4336 10.5594 13.7012 11.5395 12.8027C12.5392 11.9043 13.2841 10.9766 13.7741 10.0195C14.2642 9.0625 14.6072 8.05664 14.8032 7.00195C14.8723 6.65795 14.9272 6.32397 14.9682 6H0.985476Z"
        fill="#0F1832" />
    </svg>
  </div>
  <div *ngIf="!isHighway(roadType)" class="additional-option-icon">
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 0C0.895431 0 0 0.895429 0 2V10.8333C0 11.9379 0.89543 12.8333 2 12.8333H12C13.1046 12.8333 14 11.9379 14 10.8333V2C14 0.895431 13.1046 0 12 0H2ZM3.20288 2.65011C2.47248 2.96751 2 3.68801 2 4.48439V8.51561C2 9.31199 2.47248 10.0325 3.20288 10.3499L6.20288 11.6536C6.71133 11.8746 7.28867 11.8746 7.79712 11.6536L10.7971 10.3499C11.5275 10.0325 12 9.31199 12 8.51561V4.48439C12 3.68801 11.5275 2.96751 10.7971 2.65011L7.79712 1.3464C7.28867 1.12545 6.71133 1.12545 6.20288 1.3464L3.20288 2.65011ZM4 5C4 4.44772 4.44772 4 5 4C5.55228 4 6 4.44772 6 5V8C6 8.55229 5.55228 9 5 9C4.44772 9 4 8.55229 4 8V5ZM8 4C7.44772 4 7 4.44772 7 5V8C7 8.55229 7.44772 9 8 9H9C9.55228 9 10 8.55229 10 8V5C10 4.44772 9.55229 4 9 4H8Z"
        fill="#0F1832" />
    </svg>
  </div>
</ng-template>
