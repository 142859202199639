import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WcPopupCloserDirective } from './wc-popup-closer.directive';

@NgModule({
  declarations: [WcPopupCloserDirective],
  imports: [CommonModule],
  exports: [WcPopupCloserDirective],
})
export class WcPopupCloserModule {}
