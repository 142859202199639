import { RoadType, SegmentsDropdownDetails } from '@wc/core/models/gql.models';
import { AutoCompleteOption } from '@wc/wc-ui/src/components/autocomplete/components/autocomplete-option/autocomplete-option.component';

const HIGHWAYS_TYPES = [RoadType.Freeway, RoadType.Highway, RoadType.OffRamp, RoadType.OnRamp, RoadType.Ramp];
const STREETS_TYPES = [
  RoadType.Intersection,
  RoadType.Other,
  RoadType.Primary,
  RoadType.Secondary,
  RoadType.Street,
  RoadType.Trail,
  RoadType.UnknownRoadType,
];

export const segmentDetailsToOptions = function (
  segmentNames: SegmentsDropdownDetails[]
): AutoCompleteOption<any, any>[] {
  return segmentNames.map(segmentName => ({
    value: segmentName.name,
    displayName: segmentName.name,
    data: {
      roadType: segmentName.roadType,
      distance: segmentName.distance,
      nearestPoint: segmentName.nearestPoint,
    },
    extraLineField: {
      value: segmentName.distance.toString(),
    },
  }));
};

export function isHighway(roadType: RoadType) {
  return HIGHWAYS_TYPES.find(highwayType => highwayType === roadType);
}

export function isStreet(roadType: RoadType) {
  return STREETS_TYPES.find(streetType => streetType === roadType);
}
