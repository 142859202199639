/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
import { MapBrowserEvent } from 'ol';
import Feature from 'ol/Feature';
import BaseEvent from 'ol/events/Event';
import { Select } from 'ol/interaction';
import PointerInteraction from 'ol/interaction/Pointer';
import { Pixel } from 'ol/pixel';
import { toLonLat } from 'ol/proj';
import RenderFeature from 'ol/render/Feature';
import { StyleLike } from 'ol/style/Style';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { InteractionsEvents } from '../../../enums';
import { WcMapConfigModel } from '../../../interfaces';
import { wcCoordinateTypes } from '../../../types';
import { WcFeature } from '../wc-feature.class';

export type hoverOptions = {
  style: StyleLike | undefined;
  selectInteraction: Select | undefined;
  filter?: (f: Feature | undefined) => boolean;
  config: WcMapConfigModel<string, wcCoordinateTypes, string>;
  action?: (f: WcFeature | Feature | undefined, pixel: Pixel) => void;
};
export class Hover<T extends string> extends PointerInteraction {
  private cursor_ = 'pointer';
  private previousCursor_: string | undefined = undefined;

  private hoveredFeatureArr: any[] = [];
  private eventDispatcher = new Subject<HoverEvent<T>>();
  private hoverHandler$ = new Subject<{ features: undefined | Feature[]; pixel: Pixel }>();
  private filterFn = _f => true;
  constructor(private options: hoverOptions) {
    super();
    this.filterFn = options.filter || this.filterFn;
    this.eventDispatcher
      .pipe(
        filter(x => this.filterFn(x.target.hovered?.[0])),
        distinctUntilChanged((pD, cD) => {
          const pHovered = pD.target?.hovered;
          const cHovered = cD.target?.hovered;
          return !!(
            (!pHovered && !cHovered) ||
            (cHovered &&
              cHovered.length === 1 &&
              pHovered &&
              pHovered.length === 1 &&
              pHovered[0]?.['ol_uid'] === cHovered[0]?.['ol_uid'])
          );
        })
      )
      .subscribe(hoverEvent => {
        this.dispatchEvent(hoverEvent);
      });
    this.hoverHandler$
      .pipe(
        debounceTime(0),
        distinctUntilChanged(),
        filter(({ features }) => this.filterFn(features?.[0])),
        filter(({ features }) => {
          const selectedFeature = this.options.selectInteraction?.getFeatures().getArray()[0];
          return !!(!features || !selectedFeature || !features.includes(selectedFeature));
        })
      )
      .subscribe(({ features, pixel }) => {
        this.options.action?.(features?.[0], pixel);
        this.replaceCurrentHoveredEntities(features);
      });
    // Select takes the previous style and apply it on deselect, and it will cause the hover style to re-appear.
    // that why, we remove the style entirely here:
    //---------------------------------------------------------------------------------------------
    this.options.selectInteraction?.on('select', ({ deselected }) => deselected[0]?.setStyle());
    //---------------------------------------------------------------------------------------------
  }

  handleMoveEvent(evt: MapBrowserEvent) {
    if (
      document
        .elementFromPoint(evt.originalEvent['x'], evt.originalEvent['y'])
        ?.closest('.wc-map-viewer-prevent-hover-interaction')
    ) {
      this.eventDispatcher.next(new HoverEvent(InteractionsEvents.hover, undefined));
      return;
    }
    if (this.cursor_) {
      const map = evt.map;
      let features: Feature[] = [map.forEachFeatureAtPixel(evt.pixel, f => f)] as Feature[];
      const firstFeature = features[0];
      if (firstFeature) {
        if (firstFeature instanceof RenderFeature) return;

        if (firstFeature.getProperties().features) {
          const parentClusterCoords = toLonLat(firstFeature.getGeometry()?.['flatCoordinates']);
          firstFeature.getProperties().features[0].setProperties({ parentClusterCoords }, true);
        }

        const hitTolerance = this.options.config?.layers?.[features[0]?.getProperties()['entitySubType']]?.hitTolerance;
        if (hitTolerance) {
          const originalEntityType = firstFeature.getProperties()['entityType'];
          features = (map.getFeaturesAtPixel(evt.pixel, { hitTolerance }) as Feature[]).filter(
            f => f.getProperties()['entityType'] === originalEntityType
          );
        }
      }

      const element = evt.map.getTargetElement();
      this.hoverHandler$.next({ features, pixel: evt.pixel });
      this.eventDispatcher.next(
        new HoverEvent(InteractionsEvents.hover, features, evt.map.getCoordinateFromPixel(evt.pixel))
      );
      this.changeCursorOnMouseHoveredElement(firstFeature, element);
    }
  }

  private changeCursorOnMouseHoveredElement(feature: Feature, element: HTMLElement) {
    if (feature) {
      if (element.style.cursor != this.cursor_) {
        this.previousCursor_ = element.style.cursor;
        element.style.cursor = this.cursor_;
      }
    } else if (this.previousCursor_ !== undefined) {
      element.style.cursor = this.previousCursor_;
      this.previousCursor_ = undefined;
    }
  }

  private replaceCurrentHoveredEntities(features: undefined | Feature[]) {
    const selectedFeatures = this.options.selectInteraction?.getFeatures().getArray();
    this.hoveredFeatureArr.forEach(f => {
      if (f instanceof Feature && !selectedFeatures?.includes(f)) {
        f.setStyle();
      }
    });

    this.hoveredFeatureArr = [];
    features?.forEach(feature => {
      if (feature) {
        feature.setStyle(this.options.style);
      }
      this.hoveredFeatureArr.push(feature);
    });
  }
}

export class HoverEvent<T extends string> extends BaseEvent {
  target: Record<'hovered', WcFeature<T>[] | Feature[] | undefined>;
  constructor(type: string, target: WcFeature<T>[] | Feature[] | undefined, public pixel?: Pixel) {
    super(type);
    this.target = {
      hovered: target,
    };
  }
}
