import { Injectable } from '@angular/core';
import { AssetsByLocationGQL, LocationInput } from '@wc/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  constructor(private readonly assetsByLocationGql: AssetsByLocationGQL) {}

  getAssetsByLocation(input: LocationInput) {
    return this.assetsByLocationGql.fetch({ input }).pipe(map(res => res.data.assetsByLocation));
  }
}
