import Overlay from 'ol/Overlay';
import { fromLonLat } from 'ol/proj';
import { wcCoordinate, wcOverlayOptions } from '../../types';

export class WcOverlay extends Overlay {
  constructor(options: wcOverlayOptions) {
    super(options);
  }

  setPositionFromCoords(coords: wcCoordinate | undefined): void {
    let normalizedCoords;
    if (coords) {
      normalizedCoords = fromLonLat(coords);
    }
    // passing undefined will make position disappear, which is a desired behavior
    this.setPosition(normalizedCoords);
  }
}
