import { Injectable } from '@angular/core';
import {
  AccountRouteTypesGQL,
  CreateRouteTypeGQL,
  UpdateRouteTypeGQL,
  DeleteRouteTypeGQL,
  CreateRouteTypeInput,
  UpdateRouteTypeInput,
  DeleteRouteTypeInput,
  Scalars,
  RouteType,
} from '../models/gql.models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoutesService {
  constructor(
    private accountRouteTypesGQL: AccountRouteTypesGQL,
    private createRouteTypeGQL: CreateRouteTypeGQL,
    private updateRouteTypeGQL: UpdateRouteTypeGQL,
    private deleteRouteTypeGQL: DeleteRouteTypeGQL
  ) {}

  accountRouteTypes(accountId: number) {
    return this.accountRouteTypesGQL
      .fetch({ accountId: accountId } as never)
      .pipe(map(res => res?.data?.accountRouteTypes));
  }

  createRouteType(input: CreateRouteTypeInput) {
    return this.createRouteTypeGQL.mutate({ input }).pipe(map(res => res?.data?.createRouteType as RouteType));
  }

  updateRouteType(input: UpdateRouteTypeInput) {
    return this.updateRouteTypeGQL.mutate({ input }).pipe(map(res => res?.data?.updateRouteType as RouteType));
  }

  deleteRouteType(input: DeleteRouteTypeInput) {
    return this.deleteRouteTypeGQL.mutate({ input }).pipe(map(res => res?.data?.deleteRouteType as RouteType));
  }
}
