<div #panelContainer class="transit-panel-tab-content">
  <div class="transit-panel-header">
    <span class="transit-title">
      {{ 'transit.mainTitle' | translate }}
    </span>

    <span class="agency-slider">
      <wc-slider-toggle-control
        data-cy-id="agency-slider-btn"
        class="slide-toggle"
        [formFieldData]="{ label: 'transit.myAgencyOnly', labelPosition: 'after' }"
        [formControl]="myAgencyOnly">
      </wc-slider-toggle-control>
    </span>
  </div>

  <ng-container *ngIf="isActiveServicesFeatureActive">
    <wc-train-delays
      [isTrainDelaysPanelOpened]="!isAffectedServicePanelOpened && isTrainDelaysPanelOpened"
      (trainDelaysPanelOpened)="isTrainDelaysPanelOpened = $event"></wc-train-delays>

    <wc-affected-transit-services
      [isAffectedServicePanelOpened]="isAffectedServicePanelOpened"
      (affectedPanelOpened)="isAffectedServicePanelOpened = $event"
      (clickOnAffectedService)="affectedServiceClicked($event)"
      (clickOnUnit)="getUnitId.emit($event)"
      (clickOnRoute)="getRouteId.emit($event)"></wc-affected-transit-services>
  </ng-container>

  <div cdkScrollable class="transit-panel-body">
    <div class="transit-main-panel">
      <div class="services-container">
        <div class="panel-section-title">
          {{ 'transit.services' | translate }}
        </div>

        <div class="transit-map-layers">
          <ng-container class="transit-map-layer" *ngFor="let layer of layerNames">
            <ng-container>
              <div class="transit-map-layer">
                <mat-checkbox
                  class="mat-checkbox"
                  [attr.data-child]="layer.name"
                  [ngClass]="'layer-' + layer.name"
                  matInput
                  [name]="layer.name"
                  [checked]="layer.isVisible"
                  (change)="layerCheckboxClicked.emit({ checked: $event.checked, name: layer.name })">
                  <span class="checkbox-label">
                    {{ 'mapLayers.' + layer.name | translate }}
                  </span>
                </mat-checkbox>
                <wc-icon class="transit-layer-icon" [iconName]="layer.name"></wc-icon>
              </div>
            </ng-container>
          </ng-container>

          <div class="transit-map-layer">
            <mat-checkbox
              class="mat-checkbox"
              [attr.data-child]="transitLayersNames.transit_fixed_routes"
              [ngClass]="'layer-' + transitLayersNames.transit_fixed_routes"
              matInput
              [name]="transitLayersNames.transit_fixed_routes"
              [checked]="isFixedRoutesSelected"
              (change)="
                layerCheckboxClicked.emit({ checked: $event.checked, name: transitLayersNames.transit_fixed_routes })
              ">
            </mat-checkbox>
            <div class="transit-panel-component__autocomplete-container">
              <wc-autocomplete
                #routesAutocomplete
                [formControl]="selectedRoutes"
                [formFieldData]="{
                  label: transitFieldsData.routeSelector.label,
                  placeholder: transitFieldsData.routeSelector.placeholder,
                  options: $any(fixedRoutesAsOptions)
                }"
                [commaSeparatedMultiSelect]="false"
                [showLabel]="false"
                [showSelectAllOption]="true"
                [isMultiSelect]="true"></wc-autocomplete>
            </div>
            <wc-icon class="transit-layer-icon" [iconName]="'transit_fixed_route_bus_unit'"></wc-icon>
          </div>
        </div>
      </div>

      <div class="transit-expansion-wrapper">
        <!-- *ngIf="showFixedRoutes" -->
        <wc-checkbox
          class="show-bus-check-box heap-desktop-transit-layer-show-buses"
          [formControl]="showBuses"
          [formFieldData]="transitFieldsData.showBuses">
        </wc-checkbox>
        <mat-expansion-panel
          [expanded]="!isAffectedServicePanelOpened && !isTrainDelaysPanelOpened"
          (opened)="isAffectedServicePanelOpened = false; isTrainDelaysPanelOpened = false"
          *ngIf="true"
          class="fixed-route-mat-expansion">
          <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
            <div class="fixed-routes-header">
              <div class="fixed-routes-title">{{ 'transit.fixedRoutesDetails' | translate }} &nbsp;</div>
            </div>
          </mat-expansion-panel-header>

          <div class="route-card-list" [class.empty-transit-list]="!!transitRoutes">
            <div
              class="route-card-list__route-card heap-desktop-transit-route-in-list-click"
              *ngFor="let route of transitRoutes; let i = index; trackBy: trackById"
              (click)="getRouteId.emit(route.id)">
              <i class="fas fa-route" [ngStyle]="{ color: route.color }"></i>

              <div class="route-card-list__route-card-details">
                <div class="route-card-list__route-number">{{ 'transit.route' | translate }} {{ route.number }}</div>

                <div
                  class="route-card-list__route-name"
                  matTooltipHideDelay="500"
                  matTooltipShowDelay="500"
                  [matTooltip]="route.name"
                  matTooltipClass="route-name-tooltip">
                  {{ route.name }}
                </div>
              </div>

              <span *ngIf="route.accountName" class="route-card-list__transit-account-badge transit-account-badge">
                <i class="fas fa-briefcase"></i>
                {{ route.accountName }}
              </span>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
</div>
