<div class="dms-modal-container" [class.tablet-mode]="isTabletMode">
  <div class="dms-modal-header">
    <wc-icon [iconName]="'dms'"></wc-icon>
    <span data-cy-id="dms-modal-title" class="dms-modal-title">
      {{ dmsData?.title }} {{ dmsData?.address?.direction }}
    </span>
  </div>

  <ng-container *ngIf="online$ | async; else offlineDmsMessages">
    <div class="dms-modal-component" *ngIf="!showEmptyState; else noDmsMessages">
      <div class="dms-modal-content">
        <wc-dms-content
          [dmsList]="dmsData.messages"
          [lastUpdated]="dmsData.lastUpdated"
          [isTablet]="isTabletMode"
          [regionalSettings]="regionalSettings">
        </wc-dms-content>
      </div>
    </div>
  </ng-container>

  <ng-template #noDmsMessages>
    <div class="dms-empty-state">
      <img data-cy-id="dms-empty-state-image" class="dms-empty-state-image" src="assets/images/empty-dms.svg" />
      <div class="dms-empty-state-title">
        {{ 'emptyStates.noDmsTitle' | translate }}
      </div>
      <div>
        {{ 'emptyStates.noDmsMessage' | translate }}
      </div>
    </div>
  </ng-template>

  <ng-template #offlineDmsMessages>
    <div class="dms-empty-state">
      <img class="dms-empty-state-image" src="assets/images/offline-dms.svg" />
      <div class="dms-empty-state-message">
        {{ 'emptyStates.offlineDMSTitle' | translate }} <br />
        {{ 'emptyStates.offlineDMSMessage' | translate }}
      </div>
    </div>
  </ng-template>
</div>
