import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { TranslateService } from '@ngx-translate/core';
import { RoadClosureStoreEntity } from '@wc/wc-models/src';
import deepmerge from 'deepmerge';
import { isEmpty } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import * as moment from 'moment';
import { of, Subject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { SelectOption } from '../../features/ui/form-controls/form-models';
import * as Utils from '../../utils';
import { TrafficDisruptionTypes } from '../mock-data/roadClosure.mock';
import {
  CreateRoadClosureInput,
  LayerType,
  LiveMapEntity,
  LiveMapEntityType,
  MainEntityType,
  RoadClosure,
  RoadClosureView,
  TrafficDisruptionEntityStore,
  TrafficDisruptionStatus,
  TrafficDisruptionView,
  UpdateRoadClosureInput,
  UpdateTrafficDisruptionLanesInput,
} from '../models';
import { GeoService, RoadClosureService, SplitIOService } from '../services';
import { TrafficDisruptionService } from '../services/traffic-disruption.service';
import { EntitiesStore } from './entities.store';
import { LiveMapStore } from './live-map.store';
import { TrafficDisruptionStore } from './traffic-disruption.store';
import { UiStore } from './ui.store';

@Injectable({
  providedIn: 'root',
})
export class RoadClosureStore implements TrafficDisruptionEntityStore {
  @observable selectedTrafficDisruption = {} as TrafficDisruptionView;
  @observable selectedRoadClosureBeforeEdit = {} as TrafficDisruptionView;
  @observable roadClosureCompleted: (RoadClosure | RoadClosureView)[] = [];
  moment: any = moment;

  private readonly _onRoadClosureEdited = new Subject<number>();
  /**
   * @deprecated This changed to be onTrafficDisruptionEdited$ in trafficDisruptionService
   */
  readonly onRoadClosureEdited$ = this._onRoadClosureEdited.asObservable();

  private set onRoadClosureEdited(roadClosureId: number) {
    this._onRoadClosureEdited.next(roadClosureId);
  }

  constructor(
    private roadClosureService: RoadClosureService,
    private entitiesStore: EntitiesStore,
    private liveMapStore: LiveMapStore,
    private uiStore: UiStore,
    private translateService: TranslateService,
    private trafficDisruptionStore: TrafficDisruptionStore,
    private trafficDisruptionService: TrafficDisruptionService,
    private geoService: GeoService,
    private splitIoService: SplitIOService
  ) {
    makeObservable(this);
  }

  @computed
  get trafficDisruptionTypes(): Array<SelectOption> {
    const types = Utils.EnumToOptions(TrafficDisruptionTypes, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'trafficDisruptionTypes',
    });

    return types;
  }

  @action
  getRoadClosure(id: number) {
    return this.roadClosureService.get(id);
  }

  @action
  // This is still here because Tablet uses this View functionality as well.
  selectTrafficDisruption(roadClosure: TrafficDisruptionView, zoomOn: boolean = true) {
    !this.uiStore.isTabletMode
      ? this.trafficDisruptionStore.selectTrafficDisruption(
          { ...roadClosure, layerType: LayerType.RoadClosure } as RoadClosureStoreEntity,
          zoomOn
        )
      : this.oldSelectRoadClosure(roadClosure, zoomOn);
  }

  oldSelectRoadClosure(roadClosure: TrafficDisruptionView, zoomOn = true) {
    roadClosure.affectedLanes = Utils.sortAffectedLanes(roadClosure.affectedLanes);
    this.selectedTrafficDisruption = { ...{}, ...roadClosure };
    this.selectedRoadClosureBeforeEdit = { ...{}, ...roadClosure };
    this.uiStore.hideLoader('roadClosure');

    if (zoomOn) this.liveMapStore.zoomOnEntity(roadClosure, 15);

    this.trafficDisruptionStore.selectedTrafficDisruption = roadClosure;
    if (this.isCompleted(roadClosure)) {
      this._updateCompletedRoadClosureMapEntity(roadClosure);
    } else {
      setTimeout(() => {
        if (!this.uiStore.isTabletMode)
          if (roadClosure && roadClosure.id) {
            const typeEntities = this.entitiesStore.entities['road_closure'];
            if (typeEntities) {
              const entityFeature = typeEntities[roadClosure.id];
              if (entityFeature) {
                this.liveMapStore.selectEntityFeature(entityFeature);
              }
            }
          }
      });
    }
    datadogLogs.removeLoggerGlobalContext('wc_road_clouser_details');
    datadogLogs.addLoggerGlobalContext('wc_road_clouser_details', JSON.stringify(this.selectedTrafficDisruption));
  }

  clearTrafficDisruptionSelection() {
    if (this.isCompleted(this.selectedTrafficDisruption)) {
      this.liveMapStore.updateRemovedEntities({
        [MainEntityType.road_closure]: [this.selectedTrafficDisruption.id],
      });
    }
    this.liveMapStore.clearMapDraw();
    this.liveMapStore.unselectFeature('road_closure');
    this.selectedRoadClosureBeforeEdit = {} as RoadClosure;
    this.selectedTrafficDisruption = {} as RoadClosure;
    datadogLogs.removeLoggerGlobalContext('wc_road_clouser_details');
  }

  @action
  setRoadClosureAddressCoords(coords: number[] | number[][] | number[][][], type: 'Point' | 'LineString' | 'Polygon') {
    this.selectedTrafficDisruption.address = null;
    this.selectedTrafficDisruption = {
      ...this.selectedTrafficDisruption,
      ...{
        address:
          type === 'Point'
            ? {
                point: {
                  coordinates: coords,
                  type: 'Point',
                },
              }
            : null,
        location: {
          coordinates: coords,
          type: type,
        },
      },
    };
  }

  createRoadClosure(roadClosure: CreateRoadClosureInput) {
    this.clearTrafficDisruptionSelection();
    this.liveMapStore.removeInteractiveEntity();
    return this.roadClosureService.create(roadClosure).pipe(
      tap((_roadClosure: RoadClosure) => {
        this.uiStore.setInteractedEntityId(_roadClosure.id, MainEntityType.traffic_disruption);
        this.liveMapStore.changeLayersVisibility(true, ['road_closure']);
        this.updateRoadClosureMapEntity(_roadClosure);
        this.trafficDisruptionStore.uploadFiles(_roadClosure.id);
      })
    );
  }

  updateRoadClosure(roadClosure: RoadClosure) {
    this.liveMapStore.removeInteractiveEntity();

    const affectedLanesDiff = Utils.affectedLanesArrayDiff(
      this.selectedTrafficDisruption.affectedLanes,
      roadClosure.affectedLanes
    );

    if (
      affectedLanesDiff.modified.length > 0 ||
      affectedLanesDiff.removed.length > 0 ||
      affectedLanesDiff.created.length > 0
    ) {
      this.trafficDisruptionService
        .updateTrafficDisruptionLanes({
          trafficDisruptionId: roadClosure.id,
          modified: affectedLanesDiff.modified,
          removed: affectedLanesDiff.removed,
          created: affectedLanesDiff.created,
        })
        .subscribe();
    }

    // check for update the rest fields
    delete roadClosure.affectedLanes;
    const roadClosureDiff = Utils.objectDiff(this.selectedRoadClosureBeforeEdit, roadClosure);
    if (isEmpty(roadClosureDiff)) {
      this.updateRoadClosureMapEntity({ ...roadClosure, updatedAt: new Date().toISOString() } as any);
      this.trafficDisruptionStore.uploadFiles(roadClosure.id);
      this.trafficDisruptionStore.deleteTrafficDisruptionMedia(roadClosure.id);
      return of({});
    }
    const updateRoadClosureInput: Partial<RoadClosure> = {
      ...{ id: roadClosure.id },
      ...roadClosureDiff,
    };

    const input: UpdateRoadClosureInput = updateRoadClosureInput as unknown as UpdateRoadClosureInput;
    if (Object(updateRoadClosureInput).hasOwnProperty('media')) delete input.media;
    if (Object(updateRoadClosureInput).hasOwnProperty('address'))
      input.address = { value: updateRoadClosureInput.address };
    if (Object(updateRoadClosureInput).hasOwnProperty('contactPerson'))
      input.contactPerson = { value: updateRoadClosureInput.contactPerson };
    if (Object(updateRoadClosureInput).hasOwnProperty('description'))
      input.description = { value: updateRoadClosureInput.description };
    if (Object(updateRoadClosureInput).hasOwnProperty('endTime'))
      input.endTime = { value: updateRoadClosureInput.endTime };

    this.uiStore.showLoader('roadClosure');
    return this.roadClosureService.update(updateRoadClosureInput as UpdateRoadClosureInput).pipe(
      tap((_roadClosure: RoadClosure) => {
        this.updateRoadClosureMapEntity(_roadClosure);
        const roadClosureIndex = this.trafficDisruptionStore.completedTrafficDisruptions.road_closure.findIndex(
          roadClosure => _roadClosure.id === roadClosure.id
        );
        if (roadClosureIndex > -1) {
          const updatedRoadClosure =
            this.trafficDisruptionStore.completedTrafficDisruptions.road_closure[roadClosureIndex];
          this.trafficDisruptionStore.completedTrafficDisruptions.road_closure[roadClosureIndex] = deepmerge(
            updatedRoadClosure,
            _roadClosure
          );
        }
        this.trafficDisruptionStore.uploadFiles(_roadClosure.id);
        this.trafficDisruptionStore.deleteTrafficDisruptionMedia(_roadClosure.id);
      }),
      finalize(() => this.uiStore.hideLoader('roadClosure'))
    );
  }

  updateRoadClosureMapEntity(roadClosure: RoadClosure) {
    const workspaces = this.geoService.workspacesByLocation(roadClosure.location.coordinates);
    const updatedMapLayer = {
      [MainEntityType.road_closure]: {
        [roadClosure.id]: {
          ...roadClosure,
          ...{
            featureType: MainEntityType.road_closure,
            featureSubTypeOf: MainEntityType.road_closure,
            workspaces,
          },
        },
      },
    };

    this.liveMapStore.setSelectedWorkspaces(workspaces, false);
    this.entitiesStore
      .modifiedEntities(
        updatedMapLayer as {
          [layerName in LiveMapEntityType]?: { [id in string]: LiveMapEntity };
        }
      )
      .then(() => {
        this.onRoadClosureEdited = roadClosure.id;
        this.liveMapStore.updateModifiedEntities(
          updatedMapLayer as {
            [layerName in LiveMapEntityType]?: {
              [id in string]: LiveMapEntity;
            };
          }
        );
        this.clearTrafficDisruptionSelection();
      });
  }

  private isCompleted(roadClosure: TrafficDisruptionView) {
    if (roadClosure.status === TrafficDisruptionStatus.Completed) {
      return true;
    }
    return false;
  }

  _updateCompletedRoadClosureMapEntity(roadClosure: TrafficDisruptionView) {
    const workspaces = this.geoService.workspacesByLocation(roadClosure.location.coordinates);
    const updatedMapLayer = {
      [MainEntityType.road_closure]: {
        [roadClosure.id]: {
          ...roadClosure,
          ...{
            featureType: MainEntityType.road_closure,
            featureSubTypeOf: MainEntityType.road_closure,
            workspaces,
          },
        },
      },
    };
    this.liveMapStore.updateModifiedEntities(
      updatedMapLayer as {
        [layerName in LiveMapEntityType]?: { [id in string]: LiveMapEntity };
      }
    );
  }

  setTrafficDisruptionStatus(roadClosure: RoadClosure | LiveMapEntity, status: TrafficDisruptionStatus) {
    const roadClosureId: number = Number(roadClosure.id);

    if (status === TrafficDisruptionStatus.Completed) {
      const entity = this.entitiesStore.entities.road_closure
        ? this.entitiesStore.entities.road_closure[roadClosure.id]
        : undefined;
      this.uiStore.showLoader('roadClosure');
      return this.roadClosureService.complete(roadClosureId).pipe(
        tap((bool: Boolean | undefined) => {
          if (bool) {
            if ('affectedLanes' in roadClosure && roadClosure.affectedLanes?.length) {
              this.openAllAffectedLanes(roadClosure);
            } else {
              this.getRoadClosure(roadClosureId).subscribe(roadClosureRes => {
                if (roadClosureRes && roadClosureRes.affectedLanes?.length) {
                  this.openAllAffectedLanes(roadClosureRes as RoadClosure);
                }
              });
            }
            entity.status = status;
            if (this.entitiesStore.entities.road_closure)
              delete this.entitiesStore.entities.road_closure[roadClosure.id];
            this.entitiesStore.entities = {
              ...{},
              ...this.entitiesStore.entities,
            };
            this.liveMapStore.updateRemovedEntities({
              road_closure: [roadClosure.id as number],
            });
          }
        }),
        finalize(() => this.uiStore.hideLoader('roadClosure'))
      );
    }
    return of(false);
  }

  openAllAffectedLanes(roadClosure: RoadClosure) {
    const affectedLanes = roadClosure.affectedLanes;
    affectedLanes?.forEach(lane => {
      lane.isClosed = false;
    });
    this.trafficDisruptionService
      .updateTrafficDisruptionLanes({
        trafficDisruptionId: roadClosure.id,
        modified: affectedLanes,
        created: [],
        removed: [],
      } as UpdateTrafficDisruptionLanesInput)
      .subscribe();
  }
}
