import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PermissionUserDirective } from './permission.directive';

@NgModule({
  declarations: [PermissionUserDirective],
  imports: [CommonModule],
  exports: [PermissionUserDirective],
})
export class PermissionUserDirectiveModule {}
