import { HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UrlUploadEntityEnum } from '@wc/wc-core/src/lib/services/upload.service';
import { action, makeObservable } from 'mobx';
import { merge, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { AlertsService } from '../../features/ui/services/alerts.service';
import { randomString } from '../../utils';
import { UploadEntityType } from '../models';
import { WindowService } from '../services';
import { CacheService, DataBase } from '../services/cache.service';
import { UploadService } from '../services/upload.service';
import { MediaSource } from 'libs/core/models/gql.models';
import { MediaApiService } from "@wc-core";

export interface FileDescriptor {
  data: File;
  inProgress: boolean;
  progress: number;
  entityId: string;
  entityType: UploadEntityType | UrlUploadEntityEnum;
  source: MediaSource;
}

@Injectable({
  providedIn: 'root',
})
export class UploadStore {
  constructor(
    private uploadService: UploadService,
    private alertService: AlertsService,
    private translateService: TranslateService,
    private cacheService: CacheService,
    private windowService: WindowService,
    private mediaApiService: MediaApiService
  ) {
    makeObservable(this);
    this.windowService.online$.subscribe(async (online: boolean) => {
      if (online) {
        const res = await this.cacheService.find(DataBase.offlineUploads, 'upload-');
        if (!res) return;
        for (const row of res.rows) {
          const fileDescriptor: FileDescriptor = row?.doc?.object;

          if (!this.isValidId(fileDescriptor.entityId)) {
            return;
          }
          if (row.doc) {
            const attachment: PouchDB.Core.FullAttachment = row.doc._attachments?.file as PouchDB.Core.FullAttachment;
            const fileToUpload: File = new File([attachment.data], fileDescriptor.data.name, {
              type: attachment.content_type,
            });
            this._performUpload(fileDescriptor, fileToUpload);
            this.cacheService.remove(DataBase.offlineUploads, row.doc._id, row.doc._rev);
          }
        }
      }
    });
    this.windowService.renewIncidentId$.subscribe(async renew => {
      if (!renew) return;
      const res = await this.cacheService.find(DataBase.offlineUploads, 'upload-');
      if (res) {
        for (const row of res.rows) {
          const fileDescriptor: FileDescriptor = row.doc?.object;
          if (!this.isValidId(fileDescriptor.entityId)) {
            return;
          }

          const attachment: PouchDB.Core.FullAttachment = row.doc?._attachments?.file as PouchDB.Core.FullAttachment;
          const fileToUpload: File = new File([attachment.data], fileDescriptor.data.name, {
            type: attachment.content_type,
          });
          this._performUpload(fileDescriptor, fileToUpload);

          if (row.doc) {
            this.cacheService.remove(DataBase.offlineUploads, row.doc._id, row.doc._rev);
          }
        }
      }
    });
  }

  clearPendingUploadFiles() {
    this.mediaApiService.pendingUploadFiles = [];
  }

  get isPendingFiles() {
    return this.mediaApiService.pendingUploadFiles.length > 0;
  }

  isValidId(id: any) {
    return !(typeof id === 'string' && id.indexOf('temp') === 0);
  }

  /**
   * @param entityType use also UrlUploadEntityEnum to support moving to new UploadService
   */
  @action
  addFilesToQueueAndUpload(entityType: UploadEntityType | UrlUploadEntityEnum, entityId?) {
    const files = this.mediaApiService.pendingUploadFiles;
    const fileDescriptors: FileDescriptor[] = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      fileDescriptors.push({
        data: file,
        inProgress: false,
        progress: 0,
        entityId,
        entityType,
        source: MediaSource.Upload
      });
    }

    this.mediaApiService.pendingUploadFiles = [];
    const updateObservable = fileDescriptors.map(f => this.performUpload(f));
    return merge(...updateObservable).pipe(filter(res => res instanceof HttpResponse));
  }

  private performUpload(fileDescriptor: FileDescriptor) {
    if (navigator.onLine) {
      return this._performUpload(fileDescriptor, fileDescriptor.data);
    } else {
      this.cacheService.put(DataBase.offlineUploads, `upload-${randomString(8)}`, fileDescriptor, {
        file: {
          content_type: fileDescriptor.data.type,
          data: fileDescriptor.data,
        },
      });
      return of();
    }
  }

  private _performUpload(fileDescriptor: FileDescriptor, fileToUpload: File) {
    const formData = new FormData();
    formData.append('file', fileToUpload);
    fileDescriptor.inProgress = true;
    const uploader = this.getUploader(fileDescriptor.entityType);

    return uploader(formData, fileDescriptor.entityId).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            fileDescriptor.progress = Math.round((event.loaded * 100) / Number(event.total));
            console.log(fileDescriptor.data.name, ' => ', fileDescriptor.progress);
            return HttpEventType.UploadProgress;
          case HttpEventType.Response:
            return event;
          case HttpEventType.Sent:
            return HttpEventType.Sent;
          case HttpEventType.DownloadProgress:
            return HttpEventType.DownloadProgress;
        }
        console.warn(event);
        return event.type;
      }),
      catchError((error: HttpErrorResponse) => {
        fileDescriptor.inProgress = false;
        //DOTO  throw error upload failed

        if (error.status !== 0) {
          this.alertService.error(
            `${fileDescriptor.data.name} ${this.translateService.instant('errorMessages.uploadFailed')}`
          );
        }
        return throwError(`${fileDescriptor.data.name} upload failed.`);
      })
    );
  }

  /**
   * @param entityType use also UrlUploadEntityEnum to support moving to new UploadService
   */
  private getUploader(
    entityType: UploadEntityType | UrlUploadEntityEnum
  ): (data: object, entityId: string) => Observable<HttpEvent<any>> {
    switch (entityType) {
      case UploadEntityType.TrafficDisruption:
        return this.uploadService.uploadToTrafficDisruption.bind(this.uploadService);
      case UploadEntityType.Publication:
        return this.uploadService.uploadToSharePublic.bind(this.uploadService);
      default:
        return this.uploadService.uploadToIncident.bind(this.uploadService);
    }
  }
}
