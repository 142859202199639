import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { WeatherAlertsSupportedTypesGQL, WeatherAlertSupportedType } from '../models';
@Injectable({
  providedIn: 'root',
})
export class WeatherEventsService {
  constructor(private weatherAlertsSupportedTypesGQL: WeatherAlertsSupportedTypesGQL) {}

  getWeatherAlertsSupportedTypes() {
    return this.weatherAlertsSupportedTypesGQL.fetch().pipe(
      map(res => res?.data?.weatherAlertsSupportedTypes as WeatherAlertSupportedType[]),
      catchError(err =>
        err.graphQLErrors && err.graphQLErrors[0].extensions
          ? throwError({
              errorCode: err.graphQLErrors[0].extensions.statusCode,
            })
          : throwError(err)
      )
    );
  }
}
