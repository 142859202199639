import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroupDirective } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { FormFieldData } from '../../lib/base';

@Component({
  selector: 'wc-injuries-severity',
  templateUrl: './injuries-severity.component.html',
  styleUrls: ['./injuries-severity.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class InjuriesSeverityComponent implements OnInit {
  @Input() formFieldData!: { [key: string]: FormFieldData };
  @Input() newAutocomplete = false;

  get injurySeveritiesControl() {
    return this.formGroupDirective.form.controls['injurySeverities'] as FormArray;
  }

  constructor(public formGroupDirective: FormGroupDirective, private fb: FormBuilder, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.formGroupDirective.form
      .get('injuries')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe(number => {
        const controlsLen = this.injurySeveritiesControl.length;
        if (controlsLen < number) {
          for (let i = 0; i < number - controlsLen; i++) {
            this.injurySeveritiesControl.push(this.fb.control(null));
          }
        }
        if (controlsLen > number) {
          for (let i = controlsLen; i > number; i--) {
            this.injurySeveritiesControl.removeAt(i - 1);
          }
        }
      });
  }
}
