import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Media } from '@wc/core/models';
import { MediaService } from '@wc/core/services/media.service';
import { DOC_SUPPORTED_MIME_TYPES, MediaType, SnapshotTypeLabel } from '@wc/wc-models';
import { Observable, Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';


@Component({
  selector: 'wc-media-thumbnail',
  templateUrl: './media-thumbnail.component.html',
  styleUrls: ['./media-thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaThumbnailComponent {
  mediaType: MediaType = MediaType.IMAGE;
  mediaTypeEnum = MediaType;
  mediaEntity!: Media;
  fileEntity!: File;
  isVideoReady = false;
  reader = new FileReader();
  isNewFile = false;
  _renewMediaUrlFunc?: (args) => Observable<string | undefined>;
  mediaRenewSubject$ = new Subject<number>();

  @Input() isTabletMode = false;
  @Input() isEditMode = false;
  @Input() isExternalSource = false;
  @Input() allowVideoControls = false;
  @Input() showNewIndication = true;
  @Input() hideNewBadge = false;
  @Input() enableHandelError = true;
  @Input() customObjectFit: 'contain' | 'cover' = 'cover';
  @Input() set renewMediaUrlFunc(fn: (args) => Observable<string | undefined>) {
    if (!this.enableHandelError || !fn) return;
    this._renewMediaUrlFunc = fn.bind(this.mediaService);

    this.mediaRenewSubject$.pipe(switchMap(this._renewMediaUrlFunc), take(1)).subscribe(newUrl => {
        if (newUrl) {
          if (this.mediaType === MediaType.IMAGE) {
            this.imgRef.nativeElement.src = newUrl;
          } else {
            this.videoRef.nativeElement.src = newUrl;
          }
          this.mediaEntity.media.url = newUrl;
        } else {
          this.videoRef.nativeElement.poster = '/assets/icons/media-error.svg';
          this.imgRef.nativeElement.src = '/assets/icons/media-error.svg';
          console.warn('Failed to renew media', this.mediaEntity);
        }
      });
  }
  @Input() isInSliderInFileModal = false;
  @Input() snapshotLabel: {
    snapshotType: SnapshotTypeLabel | undefined,
    id: number
  } | null = null;

  @Output() mediaDeleted = new EventEmitter();
  @Output() mediaClickedEvent = new EventEmitter();
  @ViewChild('imageRef') imgRef!: ElementRef;
  @ViewChild('videoPlayer') videoRef!: ElementRef;

  get isSnapshot () {
    return this.snapshotLabel && this.snapshotLabel.snapshotType;
  }

  constructor(private mediaService: MediaService) {}

  @Input() set mediaObj(mediaEntity: Media | File) {
    if (mediaEntity instanceof File) {
      this.isNewFile = true;
      this.mediaType = mediaEntity.type.includes('image')
        ? MediaType.IMAGE
        : DOC_SUPPORTED_MIME_TYPES.includes(mediaEntity.type)
        ? MediaType.DOCUMENT
        : MediaType.VIDEO;
      this.readMediaAsFile(mediaEntity);
      this.fileEntity = mediaEntity;
    } else {
      this.isNewFile = false;
      this.mediaType = mediaEntity.media.key.includes('mp4') ? MediaType.VIDEO : MediaType.IMAGE;
      this.mediaType = mediaEntity.media.isDocument ? MediaType.DOCUMENT : this.mediaType;
      this.mediaEntity = mediaEntity;
      if (this.mediaType === MediaType.IMAGE && this.imgRef) {
        this.imgRef.nativeElement.src = this.mediaEntity.media.url;
      } else if (this.mediaType === MediaType.VIDEO && this.videoRef) {
        this.videoRef.nativeElement.src = this.mediaEntity.media.url;
      }
    }
  }

  readMediaAsFile(file: File) {
    this.reader.addEventListener(
      'load',
      event => {
        if (this.mediaType === MediaType.IMAGE) {
          if (this.imgRef) {
            this.imgRef.nativeElement.src = event.target?.result?.toString();
          }
        } else {
          if (this.videoRef) {
            this.videoRef.nativeElement.src = event.target?.result?.toString();
          }
        }
      },
      { once: true }
    );

    this.reader.readAsDataURL(file);
  }
  canPlay() {
    this.isVideoReady = true;
  }

  deleteMedia() {
    this.mediaDeleted.emit();
  }

  mediaError($event) {
    if (this.mediaType === MediaType.DOCUMENT) return;
    $event.target.src = '/assets/icons/media-loading-grey.svg';
    if (this.mediaEntity) {
      this.mediaRenewSubject$.next(this.mediaEntity.id);
    }
  }
}
