import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WcLetDirective } from './wc-let.directive';

@NgModule({
  declarations: [WcLetDirective],
  imports: [CommonModule],
  exports: [WcLetDirective],
})
export class WcLetModule {}
