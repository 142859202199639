<div class="camera-modal-container" [class.tablet-mode]="isTabletMode">
  <div class="camera-modal-header">
    <span>
      <i class="fas fa-cctv"></i>
      <span data-cy-id="camera-modal-title" class="camera-modal-title">
        {{ camera.title }}
      </span>
    </span>
  </div>

  <wc-camera-viewer [isTabletMode]="isTabletMode" [camera]="camera" [contextType]="cameraContextType.LiveMapModal">
  </wc-camera-viewer>
</div>
