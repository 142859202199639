/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { EntityActions, EntityUIQuery, QueryEntity } from '@datorama/akita';
import { ModifiedEntities, RemovedEntities, RoadClosureStoreEntity } from '@wc/wc-models/src';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { getLayerName } from 'wc-common';
import { LayerType, TrafficDisruptionStatus } from '../../../../../../core/models/gql.models';
import { LiveMapQuery } from '../../live-map/live-map.query';
import { RoadClosureState, RoadClosureStore, RoadClosureUIState } from './road_closures.store';

@Injectable({ providedIn: 'root' })
export class RoadClosuresQuery extends QueryEntity<RoadClosureState> {
  readonly layerTypeName = LayerType.RoadClosure;
  ui!: EntityUIQuery<RoadClosureUIState>;
  constructor(store: RoadClosureStore, private liveMapQuery: LiveMapQuery) {
    super(store);
    this.createUIQuery();
  }

  get visibleInProgressRoadClosures() {
    return this.liveMapQuery
      .getVisibleEntitiesIdsByLayerType(this.layerTypeName)
      .pipe(
        switchMap(visibleIds =>
          this.selectRoadClosure(visibleIds, [
            TrafficDisruptionStatus.Active,
            TrafficDisruptionStatus.Planned,
            TrafficDisruptionStatus.Inactive,
          ])
        )
      );
  }

  get completedRoadClosures() {
    return combineLatest([
      this.liveMapQuery.currentCheckedLayerNames.asQuery$,
      this.liveMapQuery.currentCheckedWorkSpaces.asQuery$,
    ]).pipe(
      switchMap(([visibleLayerNames, workSpacesIds]) =>
        this.selectAll().pipe(
          map(entities =>
            entities.filter(
              ({ status, workspaces }) =>
                status === TrafficDisruptionStatus.Completed &&
                visibleLayerNames.includes(getLayerName(this.layerTypeName, undefined)) &&
                workSpacesIds.some(id => workspaces?.includes(+id))
            )
          )
        )
      )
    );
  }

  get allRoadClosures() {
    const entities = this.getValue().entities || {};
    return {
      asQuery$: this.selectAll(),
      asArray: Object.values(entities),
      asObject: entities,
    };
  }

  get modifiedRoadClosures$(): Observable<ModifiedEntities> {
    return this.selectEntityAction([EntityActions.Update, EntityActions.Add]).pipe(
      map(({ ids }) => ids.map(id => this.getEntity(id)).filter(e => !!e) as RoadClosureStoreEntity[]),
      map(roadClosures => ({
        [this.layerTypeName]: roadClosures,
      }))
    );
  }

  get removedRoadClosures$(): Observable<RemovedEntities> {
    return this.selectEntityAction([EntityActions.Remove]).pipe(map(({ ids }) => ({ [this.layerTypeName]: ids })));
  }

  selectRoadClosure(ids: number[], statuses: TrafficDisruptionStatus[] = []) {
    return this.selectMany(ids).pipe(
      map(visibleRoadClosures => visibleRoadClosures.filter(({ status }) => statuses.some(s => s === status)))
    );
  }
}
