import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { TranslateService } from '@ngx-translate/core';
import { EntitiesServiceV2, LiveMapService } from '@wc-core';
import { AlertsService } from '@wc/features/ui/services/alerts.service';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { GraphQLError } from 'graphql';
import { isEqual } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, merge, MonoTypeOperatorFunction, Observable, of, pipe, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import * as Utils from '../../utils';
import { SUPPORTED_DOC_MIME_TYPES } from '../constantes';
import { environment } from '../environments/environment';
import { UIIncident } from '../models/extended.models';
import {
  AdditionalInfo,
  CompleteIncidentGQL,
  ConfirmIncidentGQL,
  CreateIncidentGQL,
  CreateIncidentMutationInput,
  DeleteIncidentMediaGQL,
  DeleteIncidentMediaInput,
  DeleteIncidentUnitInput,
  DisassociateUnitFromIncidentGQL,
  EndIncidentInput,
  FindIncidentsInPointRadiusInput,
  FindLatestIncidentsGQL,
  FindRelatedIncidentsByPointRadiusGQL,
  Incident,
  IncidentActivityLogGQL,
  IncidentAdditionalInfoInput,
  IncidentGQL,
  IncidentInput,
  IncidentInvolvedVehicleInput,
  IncidentMitigation,
  IncidentMitigationAndUnitsGQL,
  IncidentMitigationInput,
  IncidentReportSourceInput,
  IncidentStatus,
  IncidentType,
  IncidentUnit,
  IncidentUnitInput,
  IncidentView,
  InvolvedVehicleInput,
  LayerType,
  MediaSource,
  PublishIncidentGQL,
  PublishInput,
  RejectIncidentGQL,
  RelatedIncident,
  RenewIncidentMediaUrlGQL,
  ResponsePlanActionInput,
  ResponsePlanInput,
  Scalars,
  SegmentDetails,
  SegmentGQL,
  Status,
  UndoCompleteGQL,
  UndoRejectGQL,
  UnitResponse,
  UpdateIncidentInput,
  UpdateIncidentMitigationsGQL,
  UpdateIncidentMitigationsInput,
  UpdateIncidentOneTimeGQL,
  UpdateIncidentUnitInput,
  UpdateIncidentUnitResponseGQL,
  VehicleType,
} from '../models/gql.models';
import { MitigationsDiff } from '../models/models';
import { OfflineIncident, OfflineIncidentType } from '../models/offlineIncident';
import { LastActiveEditIncident } from '../stores';
import { EntitiesStore } from '../stores/entities.store';
import { CacheService } from './cache.service';
import { CustomRxOperatorsService } from './custom-rx-operators.service';
import { EntitiesService } from './entites.service';
import { LocalStorageService } from './local-storage.service';
import { OfflineRequests, OfflineService, OfflineUpdateRequest, RequestType } from './offline.service';
import { UsersService } from './users.service';
import { WindowService } from './window.service';

interface ObjectWithID {
  incidentId?: string;
  id?: string;
}

export type IncidentCompletedUpdateMessage = {
  incidentId: number;
  incidentAddress: Incident['address'];
  type: 'incident_completed';
};
@Injectable({
  providedIn: 'root',
})
export class IncidentService extends OfflineService {
  offlineIncidentMap!: Map<string, OfflineIncident>;
  incidentCacheMap!: Map<string, Incident | IncidentView>;
  savedCacheLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  authUser;
  nameSpace: string = 'IncidentService';

  constructor(
    private usersService: UsersService,
    private createIncidentGQL: CreateIncidentGQL,
    private segmentGQL: SegmentGQL,
    private incidentGQL: IncidentGQL,
    private completeIncidentGQL: CompleteIncidentGQL,
    private confirmIncidentGQL: ConfirmIncidentGQL,
    private rejectIncidentGQL: RejectIncidentGQL,
    private findLatestIncidentsGQL: FindLatestIncidentsGQL,
    private deleteIncidentMediaGQL: DeleteIncidentMediaGQL,
    private renewIncidentMediaUrlGQL: RenewIncidentMediaUrlGQL,
    private updateIncidentMitigationsGQL: UpdateIncidentMitigationsGQL,
    private updateIncidentUnitResponseGQL: UpdateIncidentUnitResponseGQL,
    private disassociateUnitFromIncidentGQL: DisassociateUnitFromIncidentGQL,
    private incidentActivityLogGQL: IncidentActivityLogGQL,
    private publishIncidentGQL: PublishIncidentGQL,
    private undoCompleteGQL: UndoCompleteGQL,
    private undoRejectGQL: UndoRejectGQL,
    private findRelatedIncidentsByPointRadiusGQL: FindRelatedIncidentsByPointRadiusGQL,
    private updateIncidentOneTimeGQL: UpdateIncidentOneTimeGQL,
    private entitiesStore: EntitiesStore,
    private entitiesService: EntitiesService,
    private alertService: AlertsService,
    private translateService: TranslateService,
    private entitiesServiceV2: EntitiesServiceV2,
    private liveMapService: LiveMapService,
    private localStorageService: LocalStorageService,
    private customOperators: CustomRxOperatorsService,
    private incidentMitigationAndUnitsGQL: IncidentMitigationAndUnitsGQL,
    windowService: WindowService,
    cacheService: CacheService
  ) {
    super(windowService, cacheService);

    this.loadSavedCache();
    merge(this.entitiesStore.onIncidentModified$, this.entitiesStore.onIncidentRemoved$).subscribe(
      (incident: Incident) => {
        if (navigator.onLine) {
        }
      }
    );

    this.createRequestComplete$.subscribe(({ createRequest, oldId, createResponse }) => {
      const incidentTempId: string = oldId;
      const offlineIncident = this.offlineIncident(incidentTempId);
      this.entitiesStore.removedEntities({
        [createRequest.params[0].type.toLocaleLowerCase()]: [incidentTempId],
      });

      this.removeOfflineIncident(incidentTempId);
      this.storeOfflineCache('offlineIncidentMap', this.offlineIncidentMap);
      if (createRequest.hasError) {
        this.showSyncErrorAlert(RequestType.create);
      } else {
        this.replaceId(incidentTempId, createResponse.id);
        if (offlineIncident.incident) offlineIncident.incident.isOffline = false;
      }
    });

    this.updateRequestComplete$.subscribe(({ updateRequest, updateResponse }) => {
      if (updateRequest.hasError) {
        this.showSyncErrorAlert();
      } else {
        const incidentId: string = updateRequest.getId(updateRequest);
        const offlineIncident = this.offlineIncident(incidentId);
        if (updateResponse?.id) {
          updateResponse.isOffline = false;
          if (offlineIncident.incident?.type) {
            this.entitiesStore.modifiedEntities({
              [offlineIncident.incident.type.toLocaleLowerCase()]: {
                [incidentId]: updateResponse,
              },
            });
          }
        }
        this.removeOfflineIncident(incidentId);
      }
    });

    this.entitiesService.registerDiffProvider(this.offlineDiff);
  }

  async loadSavedCache() {
    this.offlineIncidentMap = await this.loadOfflineCache('offlineIncidentMap');
    if (!(this.offlineIncidentMap instanceof Map)) {
      this.offlineIncidentMap = new Map();
    }
    for (const [key, rawOfflineIncident] of this.offlineIncidentMap.entries()) {
      if (!rawOfflineIncident.input) {
        this.offlineIncidentMap.delete(key);
        continue;
      }
      const offlineIncident: OfflineIncident = OfflineIncident.offlineIncidentFromIncident(
        rawOfflineIncident.incident as UIIncident,
        rawOfflineIncident.input?.offlineUpdatedAt,
        this.usersService.authUser.account.id
      );
      offlineIncident.type = rawOfflineIncident.type;
      this.offlineIncidentMap.set(key, offlineIncident);
    }
    this.incidentCacheMap = await this.loadOfflineCache('incidentCacheMap');
    if (!(this.incidentCacheMap instanceof Map)) {
      this.incidentCacheMap = new Map();
    }

    await this.loadOfflineUpdateQueue();
    if (this.offlineUpdateQueue && navigator.onLine) {
      this.flushRequestQueue();
    }
    this.savedCacheLoaded$.next(true);
  }

  async isCacheLoaded(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.savedCacheLoaded$.pipe(filter(loaded => loaded)).subscribe(() => {
          resolve(true);
        });
      } catch (e) {
        reject(false);
      }
    });
  }

  showSyncErrorAlert(type?: RequestType) {
    if (type === RequestType.create) {
      this.alertService.error(
        this.translateService.instant('errorMessages.errorOccuredSyncingOfflineActivities') +
          '<br/>' +
          this.translateService.instant('notifications.failIncidentCreate'),
        undefined,
        {
          enableHtml: true,
        }
      );
    } else {
      this.alertService.error(
        this.translateService.instant('errorMessages.errorOccuredSyncingOfflineActivities') +
          '<br/>' +
          this.translateService.instant('errorMessages.reeditAndSaveYourOfflineActivities') +
          '<br/>' +
          this.translateService.instant('errorMessages.soTheyAppearOnline'),
        undefined,
        {
          enableHtml: true,
        }
      );
    }
  }

  canUpdate(updateRequest: OfflineUpdateRequest): boolean {
    let canUpdate: boolean = true;
    const incidentId: string | number = updateRequest.getId?.apply(this, [updateRequest]);
    if (!this.isValidId(incidentId)) {
      canUpdate = false;
    }
    return canUpdate;
  }

  offlineDiff = () => {
    let modified: any = {};
    let removed: any = {};

    modified = Array.from(this.offlineIncidentMap?.entries() || []).reduce((diff, [incidentId, offlineIncident]) => {
      if (!offlineIncident?.incident?.isOffline) {
        return diff;
      }

      if (!offlineIncident.incident.type) {
        offlineIncident.incident.type = IncidentType.UnknownIncidentType;
      }

      if (offlineIncident.incident?.mitigations) {
        offlineIncident.incident.mitigations = offlineIncident.incident.mitigations.map(
          (incidentMitigation: IncidentMitigation) => ({
            ...incidentMitigation,
            interval: {
              to: moment(incidentMitigation.interval?.to).toISOString(),
              from: moment(incidentMitigation.interval?.from).toISOString(),
            },
          })
        );
      }

      const entityType = offlineIncident.incident.type.toLowerCase();
      if (!diff[entityType]) {
        diff[entityType] = {};
      }
      diff[entityType][offlineIncident.incident.id] = {
        ...offlineIncident.incident,
        isOffline: offlineIncident.incident.isOffline || false,
      };
      return diff;
    }, {});

    return {
      modified,
      removed,
    };
  };

  updateCachedIncidentEntity(incident: Incident) {
    if (this.incidentCacheMap) {
      this.incidentCacheMap.set(incident.id.toString(), incident);
      this.storeOfflineCache('incidentCacheMap', this.incidentCacheMap);
    }
  }

  offlineIncident(incidentId: string): OfflineIncident {
    let offlineIncident: OfflineIncident = this.offlineIncidentMap.get(incidentId) as OfflineIncident;
    if (!offlineIncident) {
      const _incident = this.entitiesStore.getEntity(incidentId) || this.incidentCacheMap.get(incidentId);
      // convert entity to incident
      _incident.type = _incident.type?.toUpperCase() || IncidentType.Unidentified;
      if (_incident?.affectedLanes) {
        _incident.affectedLanes = [...[], ...Utils.removeUpdatedAtFromAffectedLanes(_incident.affectedLanes)];
        _incident.affectedLanes = [...[], ...Utils.refactorAffectedLanesIds(_incident.affectedLanes)];
      }
      if (_incident?.associatedUnits?.length) {
        _incident.associatedUnits = [...[], ...Utils.refactorAssociatedUnitsIds(_incident.associatedUnits)];
      }
      offlineIncident = new OfflineIncident(_incident);
      if (offlineIncident.incident) offlineIncident.incident.id = Number(incidentId);
      this.offlineIncidentMap.set(incidentId, offlineIncident);
      this.storeOfflineCache('offlineIncidentMap', this.offlineIncidentMap);
    }
    return offlineIncident;
  }

  updateOfflineMedia(incidentId: string, files: File[]) {
    if (!navigator.onLine) {
      const offlineIncident: OfflineIncident = this.offlineIncident(incidentId);
      if (offlineIncident) {
        files.forEach((file: File) => {
          if (offlineIncident.incident) offlineIncident.incident.media = offlineIncident.incident.media || [];
          if (offlineIncident.incident)
            offlineIncident.incident.media.push({
              id: Math.floor(Math.random() * 88888888),
              media: {
                expiration: moment().add(1, 'week').toISOString(),
                fileSize: file.size,
                key: file.name,
                url: URL.createObjectURL(file),
                isDocument: SUPPORTED_DOC_MIME_TYPES.includes(file.type),
                source: MediaSource.Upload,
              },
            });
        });
      }
    }
  }

  removeOfflineIncident(incidentId: string) {
    if (this.offlineIncidentMap?.has(incidentId)) {
      this.offlineIncidentMap.delete(incidentId);
      this.storeOfflineCache('offlineIncidentMap', this.offlineIncidentMap);
    }
  }

  getIncident(id: number) {
    return this.savedCacheLoaded$.pipe(
      filter(loaded => loaded),
      switchMap(() => {
        return this.incidentGQL.fetch({ id: id }).pipe(
          map(res => {
            const resError = res.errors as GraphQLError[];
            if (resError) {
              throw resError[0];
            }
            const incident = res.data.incident as Incident;
            if (incident.affectedLanes) {
              incident.affectedLanes = Utils.sortAffectedLanes(incident.affectedLanes);
              incident.affectedLanes = Utils.removeUpdatedAtFromAffectedLanes(incident.affectedLanes);
            }

            if (this.incidentCacheMap) {
              this.incidentCacheMap.set(incident.id.toString(), incident);
              this.storeOfflineCache('incidentCacheMap', this.incidentCacheMap);
            }
            return incident;
          }),
          catchError((err: any, caught: Observable<Incident>) => {
            if (navigator.onLine && this.isValidId(id)) {
              throw throwError(err);
            } else {
              let incident: Incident | IncidentView | undefined =
                this.offlineIncidentMap?.get(id.toString())?.incident || this.incidentCacheMap?.get(id.toString());
              if (!incident) {
                incident = this.offlineIncident(id.toString()).incident;
              }
              return of(incident);
            }
          })
        );
      })
    );
  }

  segmentDetails(point: Scalars['Point']) {
    if (!navigator.onLine) return of();
    if (point.type !== 'Point' || (point.coordinates[0] && point.coordinates[0] instanceof Array)) {
      return throwError({
        error: `Point type should be Point but got: ${JSON.stringify(point)}`,
      });
    }

    return this.segmentGQL.fetch({ point: point, radiusMeters: 3000 }).pipe(
      map(res => {
        if (res.errors) throw { errorCode: res.errors[0].extensions?.statusCode };

        if (res.data !== null) return res.data.segment as SegmentDetails;
        return throwError('SegmentDetails Error');
      }),
      catchError(err => {
        console.log('SegmentDetails Error: ', err);

        return err.graphQLErrors && err.graphQLErrors[0]
          ? throwError({
              errorCode: err.graphQLErrors[0].extensions.statusCode,
            })
          : throwError(err);
      })
    );
  }

  getStringId = (updateRequest: OfflineUpdateRequest) => updateRequest.params[0].id;
  setStringId = (updateRequest: OfflineUpdateRequest, newId: string) => {
    updateRequest.params[0].id = newId;
  };

  getIdFromObject = (updateRequest: OfflineUpdateRequest) => {
    const input: ObjectWithID = updateRequest.params[0];
    const genertedId = updateRequest.params[1];
    return input.incidentId || genertedId;
  };

  setIdToObject = (updateRequest: OfflineUpdateRequest, newId: string) => {
    const input: ObjectWithID = updateRequest.params[0];
    input.incidentId = newId;
    if (input['updateIncidentInput']?.incidentId) {
      input['updateIncidentInput'].incidentId = newId;
    }
    updateRequest.params = [input];
  };

  completeIncident(inputData: EndIncidentInput) {
    return this.completeIncidentGQL.mutate({ input: inputData }).pipe(
      map(res => res?.data?.completeIncident),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: OfflineRequests.completeIncident,
            params: [{ ...inputData, offlineUpdatedAt: moment().toISOString() }],
            onError: (updateRequest: OfflineUpdateRequest) => {
              const incidentId = updateRequest.params[0].incidentId;
              const offlineIncident: OfflineIncident = this.offlineIncident(incidentId);
              if (offlineIncident.incident) offlineIncident.incident.status = IncidentStatus.Completed;
              this.offlineIncidentMap.set(incidentId, offlineIncident);
              this.storeOfflineCache('offlineIncidentMap', this.offlineIncidentMap);
            },
            getId: this.getIdFromObject,
            setId: this.setIdToObject,
            type: RequestType.update,
          },
          defaultValue: true,
        })
      )
    );
  }

  confirmIIncident(input) {
    return this.confirmIncidentGQL.mutate(input).pipe(
      map(res => res?.data?.confirmIncident),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: OfflineRequests.confirmIIncident,
            params: [{ ...input, offlineUpdatedAt: moment().toISOString() }],
            onError: (updateRequest: OfflineUpdateRequest) => {
              const newUnit = {
                ...this.offlineUnit(),
                unitResponse: input.response,
                dashCameras: [],
              };
              const incidentId = updateRequest.params[0].id;
              const offlineIncident: OfflineIncident = this.offlineIncident(incidentId);
              if (offlineIncident.incident) offlineIncident.incident.status = IncidentStatus.Confirmed;
              const associatedUnit = (offlineIncident?.incident?.associatedUnits || []).find(
                (unit: IncidentUnit) => unit.id === input.unitId
              );
              if (newUnit.id && !associatedUnit && offlineIncident?.incident) {
                offlineIncident.incident.associatedUnits = offlineIncident.incident.associatedUnits || [];
                offlineIncident.incident.associatedUnits.push(newUnit as any);
              }
              this.offlineIncidentMap.set(incidentId, offlineIncident);
              this.storeOfflineCache('offlineIncidentMap', this.offlineIncidentMap);
            },
            getId: this.getStringId,
            setId: this.setStringId,
            type: RequestType.update,
          },
          defaultValue: true,
        })
      )
    );
  }

  rejectIncident(inputData: EndIncidentInput) {
    return this.rejectIncidentGQL.mutate({ input: inputData }).pipe(
      map(res => res?.data?.rejectIncident),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: OfflineRequests.rejectIncident,
            params: [{ ...inputData, offlineUpdatedAt: moment().toISOString() }],
            onError: (updateRequest: OfflineUpdateRequest) => {
              const incidentId = updateRequest.params[0].incidentId;
              const offlineIncident: OfflineIncident = this.offlineIncident(incidentId);
              if (offlineIncident.incident) offlineIncident.incident.status = IncidentStatus.Rejected;
              this.offlineIncidentMap.set(incidentId, offlineIncident);
              this.storeOfflineCache('offlineIncidentMap', this.offlineIncidentMap);
            },
            getId: this.getIdFromObject,
            setId: this.setIdToObject,
            type: RequestType.update,
          },
          defaultValue: true,
        })
      )
    );
  }

  undoCompleteIncident(input) {
    return this.undoCompleteGQL.mutate(input).pipe(
      map(res => {
        return res?.data?.undoComplete;
      }),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: OfflineRequests.undoCompleteIncident,
            params: [{ ...input, offlineUpdatedAt: moment().toISOString() }],
            onError: (updateRequest: OfflineUpdateRequest) => {
              const incidentId = updateRequest.params[0].id;
              const offlineIncident: OfflineIncident = this.offlineIncident(incidentId);
              if (offlineIncident.incident) offlineIncident.incident.status = IncidentStatus.Confirmed;
              this.offlineIncidentMap.set(incidentId, offlineIncident);
              this.storeOfflineCache('offlineIncidentMap', this.offlineIncidentMap);
            },
            getId: this.getStringId,
            setId: this.setStringId,
            type: RequestType.update,
          },
          defaultValue: true,
        })
      )
    );
  }
  undoRejectIncident(input) {
    return this.undoRejectGQL.mutate(input).pipe(
      map(res => {
        return res?.data?.undoReject;
      }),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: OfflineRequests.undoRejectIncident,
            params: [{ ...input, offlineUpdatedAt: moment().toISOString() }],
            onError: (updateRequest: OfflineUpdateRequest) => {
              const incidentId = updateRequest.params[0].id;
              const offlineIncident: OfflineIncident = this.offlineIncident(incidentId);
              if (offlineIncident.incident) offlineIncident.incident.status = IncidentStatus.Unconfirmed;
              this.offlineIncidentMap.set(incidentId, offlineIncident);
              this.storeOfflineCache('offlineIncidentMap', this.offlineIncidentMap);
            },
            getId: this.getStringId,
            setId: this.setStringId,
          },
          defaultValue: true,
        })
      )
    );
  }

  findLatestIncidents() {
    return this.findLatestIncidentsGQL
      .fetch({
        statuses: [IncidentStatus.Completed, IncidentStatus.Rejected],
      } as never)
      .pipe(map(res => res.data.findLatestIncidents));
  }

  createIncident(input: CreateIncidentMutationInput, offlineId?: string): Observable<Incident> {
    if (
      !input.location ||
      JSON.stringify(input.location.coordinates).includes('null') ||
      !input.address.point ||
      JSON.stringify(input.address.point.coordinates).includes('null')
    ) {
      console.log('Try to create incident with no coordinates failed.');
      this.alertService.error(
        this.translateService.instant('errorMessages.errorOccuredSyncingOfflineActivities') +
          '<br/>' +
          this.translateService.instant('errorMessages.unableToLocateTheDeviceLocation'),
        undefined,
        {
          enableHtml: true,
        }
      );
      return of();
    }
    return this.createIncidentGQL.mutate({ input: input }).pipe(
      map(res => res?.data?.createIncident as Incident),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: OfflineRequests.createIncident,
            params: [input, offlineId],
            onError: (updateRequest: OfflineUpdateRequest) => {
              const offlineIncident: OfflineIncident = new OfflineIncident(
                this.usersService.authUser.account.id,
                input,
                offlineId,
                true
              );
              offlineIncident.type = OfflineIncidentType.create;
              this.offlineIncidentMap.set(offlineIncident?.incident?.id.toString(), offlineIncident);
              this.storeOfflineCache('offlineIncidentMap', this.offlineIncidentMap);
              return offlineIncident.incident;
            },
            getId: this.getIdFromObject,
            setId: this.setIdToObject,
            type: RequestType.create,
          },
          defaultValue: {},
        })
      )
    );
  }

  updateIncidentOneTime(
    inputs: { modifiedIncident: Incident; incident: Incident },
    isFromMitigationModal?: boolean,
    isTabletMode?: boolean
  ) {
    try {
      const userLastEdit: LastActiveEditIncident = this.localStorageService.get(LocalStorageKeys.UserLastEdit);
      let { modifiedIncident, incident } = inputs;

      const updateIncidentInput: UpdateIncidentInput = {
        incidentId: modifiedIncident.id,
      };

      if (!navigator.onLine) {
        updateIncidentInput.offlineUpdatedAt = moment().toISOString();
      }

      updateIncidentInput.title =
        isEqual(incident.location, modifiedIncident.location) && isEqual(incident.address, modifiedIncident.address)
          ? null // location did not change, keeps title as is
          : { value: null }; // actually sets the title to null
      updateIncidentInput.address = modifiedIncident.address;
      updateIncidentInput.nearCameras = modifiedIncident.cameras && modifiedIncident.cameras.length > 0 ? true : false;
      updateIncidentInput.location = modifiedIncident.location || modifiedIncident.address.point;
      updateIncidentInput.type = modifiedIncident.type;
      updateIncidentInput.subType = { value: modifiedIncident.subType };
      updateIncidentInput.atmsId = {
        value: modifiedIncident.atmsId || null,
      };
      updateIncidentInput.cadId = {
        value: modifiedIncident.cadId || null,
      };
      updateIncidentInput.startedAt = modifiedIncident.startedAt;
      updateIncidentInput.typeDescription = { value: modifiedIncident.typeDescription };
      updateIncidentInput.injuries = { value: modifiedIncident.injuries } ?? null;
      modifiedIncident.estimatedEndTime &&
        (updateIncidentInput.estimatedEndTime = {
          value: modifiedIncident.estimatedEndTime,
        });
      updateIncidentInput.allLanesAffected = modifiedIncident.allLanesAffected;
      updateIncidentInput.multiDirectionLanesAffected = modifiedIncident.multiDirectionLanesAffected;
      typeof modifiedIncident.autoPublish === 'boolean' &&
        (updateIncidentInput.autoPublish = modifiedIncident.autoPublish);
      updateIncidentInput.involvedVehiclesCount = modifiedIncident.involvedVehiclesCount
        ? { value: modifiedIncident.involvedVehiclesCount }
        : null;

      updateIncidentInput.severity = { value: modifiedIncident.severity };
      updateIncidentInput.injurySeverities = modifiedIncident.injurySeverities;
      updateIncidentInput.attributes = modifiedIncident.attributes;

      let input: IncidentInput = {
        incidentId: modifiedIncident.id,
        updateIncidentInput: updateIncidentInput,
      };

      const lanes = modifiedIncident.affectedLanes?.map(incidentLane => ({
        id: incidentLane.id,
        lane: {
          isAffected: incidentLane.isAffected,
          direction: incidentLane.direction,
          isClosed: incidentLane.isClosed,
          positionIndex: incidentLane.positionIndex,
          number: incidentLane.number,
          roadType: incidentLane.roadType,
          type: incidentLane.type,
        },
      }));

      // In case of updating incident with no previous lanes
      // Don't add the default lane from the segment unless the user marked some as Affected
      if (inputs.incident.affectedLanes?.length === 0) {
        if (lanes.some(lane => lane.lane.isAffected)) {
          lanes && (input.lanes = lanes);
        }
      } else {
        lanes && (input.lanes = lanes);
      }

      const cameras = modifiedIncident.cameras?.map(incidentCamera => ({
        cameraExternalId: incidentCamera.camera?.externalId,
        default: incidentCamera.default,
      }));
      cameras && (input.cameras = cameras);

      const additionalInfoInput: IncidentAdditionalInfoInput[] = (
        modifiedIncident.additionalInfos as number[] | AdditionalInfo[]
      )?.map(info => {
        return { additionalInfoId: info?.id ? info.id : info };
      });
      additionalInfoInput && (input.additionalInfoInput = additionalInfoInput);

      const involvedVehicles: IncidentInvolvedVehicleInput[] = [];
      modifiedIncident.involvedVehicles.forEach(vehicle => {
        const involvedVehicleInput: InvolvedVehicleInput = { ...vehicle };
        delete involvedVehicleInput['id'];

        involvedVehicles.push({
          id: vehicle.id || null,
          involvedVehicle: involvedVehicleInput,
        });
      });

      involvedVehicles && (input.involvedVehicles = involvedVehicles);
      let reportSources: IncidentReportSourceInput[] = [];
      if (modifiedIncident.reportSources) {
        if (Array.isArray(modifiedIncident.reportSources)) {
          reportSources = modifiedIncident.reportSources?.map(sources => ({
            reportSourceId: sources.id,
          }));
        } else {
          reportSources = [{ reportSourceId: modifiedIncident.reportSources }];
        }
      }

      reportSources && (input.reportSources = reportSources);

      const notes = modifiedIncident.notes?.map(incidentNote => ({
        id: incidentNote.id,
        note: incidentNote.note,
      }));
      notes && (input.notes = notes);

      // Get the new added mitigation,and FORCE add end time if user didn't fill by himself
      // Verify not to edit default mitigation
      // https://app.shortcut.com/rekor/story/13875/mitigation-end-time-should-automatically-populate-when-saving-the-edit-mitigation
      modifiedIncident.mitigations?.map(newMit => {
        if (
          newMit.mitigationType.id !== environment.defaultMitigationTypeId &&
          !inputs.incident?.mitigations?.some(
            oldMit => oldMit.userId === newMit.userId && oldMit.mitigationType.id === newMit.mitigationType.id
          )
        ) {
          newMit.interval.to = newMit.interval.to ? newMit.interval.to : new Date();
        }
      });

      const mitigations = modifiedIncident.mitigations.map((incidentMitigation: IncidentMitigation) => ({
        id: incidentMitigation.id,
        driverId: incidentMitigation.userId,
        interval: incidentMitigation.interval,
        mitigationType: incidentMitigation.mitigationType,
        unitId: incidentMitigation.unitId,
      }));
      mitigations &&
        (input.mitigations = mitigations.map(mitigation => {
          return <IncidentMitigationInput>{
            driverId: mitigation.driverId,
            interval: mitigation.interval,
            mitigationTypeId: mitigation.mitigationType.id,
            unitId: mitigation.unitId,
            id: mitigation.id,
          };
        }));

      const units =
        modifiedIncident.associatedUnits.map(associatedUnit => ({
          driverId: associatedUnit.driverDetails?.userId,
          unitId: associatedUnit.id,
          response: associatedUnit.unitResponse,
        })) || [];

      let currentUnitStatus;
      let isCurrentUserHasNewMitigations = false;
      let isCurrentUserAssociated;
      if (isTabletMode) {
        // check if current driver added a mitigation IN THIS CURRENT UPDATE regardless of interval?.to
        // https://app.shortcut.com/rekor/story/13875/mitigation-end-time-should-automatically-populate-when-saving-the-edit-mitigation
        mitigations.forEach(incidentMitigation => {
          if (
            incidentMitigation.driverId === this.usersService.authUser?.id && // was done by this user
            incidentMitigation.mitigationType.id !== environment.defaultMitigationTypeId && // not a default mitigation
            !inputs.incident?.mitigations?.some(
              // this mitigation didn't exist on the original incident
              oldMit =>
                oldMit.id === incidentMitigation.id ||
                (oldMit.userId === incidentMitigation.driverId &&
                  oldMit.mitigationType.id === incidentMitigation.mitigationType.id)
            )
          ) {
            isCurrentUserHasNewMitigations = true;
          }
        });

        // check if the current unit is already in associatedUnits
        isCurrentUserAssociated = modifiedIncident.associatedUnits.find(
          _unit => _unit.driverDetails?.userId === this.usersService.authUser?.id
        );

        if (isCurrentUserHasNewMitigations || isFromMitigationModal) {
          currentUnitStatus = UnitResponse.Mitigated;
        } else if (isCurrentUserAssociated) {
          currentUnitStatus =
            isCurrentUserAssociated.unitResponse === UnitResponse.EnRoute
              ? UnitResponse.OnScene
              : isCurrentUserAssociated.unitResponse;
        } else {
          currentUnitStatus = UnitResponse.OnScene;
        }

        // if its the first update for this unit on the incident -> add new unit
        // else update the current unit with new response status
        if (!isCurrentUserAssociated && this.usersService.authUser.unit?.id) {
          units.push({
            driverId: this.usersService.authUser?.id,
            unitId: this.usersService.authUser.unit?.id,
            response: currentUnitStatus,
          });
        } else {
          if (this.usersService.authUser.unit?.id) {
            const currentUnit = units?.find(_unit => _unit.driverId === this.usersService.authUser?.id);
            if (currentUnit) currentUnit.response = currentUnitStatus;
          }
        }
      }

      input.units = units;
      if (isTabletMode && modifiedIncident.responsePlan) {
        const response = modifiedIncident.responsePlan as ResponsePlanInput;
        response.responsePlanId = modifiedIncident.responsePlan.id;
        delete response['id'];
        delete response['isDeleted'];
        delete response['updatedAt'];
        response.actions?.forEach(action => {
          const act = action as ResponsePlanActionInput;
          delete act['responsePlanId'];
        });
        input.responsePlan = response;
      }
      return this.incidentMitigationAndUnitsGQL.fetch({ id: input.incidentId }).pipe(
        map(res => res.data.incident as Partial<Incident>),
        tap((update: Partial<Incident>) => {
          // Tablet edit - case 1: platform added a unit while user was in edit mode
          // Tablet edit - case 2: platform added mitigation while user was in edit mode
          // Tablet edit - ** note: tablet can only edit current user **
          // take only current user + mitigation form local change,the reset from server
          // edit incident / add mitigation from view
          if (isTabletMode) {
            const currentUnitId = this.usersService.authUser.unit?.id;
            const currentUnit: IncidentUnitInput | undefined = units?.find(_unit => _unit.unitId === currentUnitId);
            if (currentUnit) {
              datadogLogs.logger.info('Override incident mitigation and units - incidentService', {
                currentUserInput: JSON.stringify({
                  associatedUnits: input.units,
                  mitigations: input.mitigations,
                }),
                serverLatestUpdate: JSON.stringify(update),
              });

              const otherUnits =
                update.associatedUnits
                  ?.filter(_unit => _unit.id !== currentUnitId)
                  .map(unit => {
                    return {
                      driverId: unit.driverDetails?.userId,
                      response: unit.unitResponse,
                      unitId: unit.id,
                    } as IncidentUnitInput;
                  }) || [];

              const otherMitigations =
                update.mitigations
                  ?.filter(mit => mit.unitId !== currentUnitId)
                  .map(mit => {
                    return {
                      driverId: mit.userId,
                      interval: mit.interval,
                      mitigationTypeId: mit.mitigationType.id,
                      unitId: mit.unitId,
                    } as IncidentMitigationInput;
                  }) || [];
              const currentUsersMitigations = input.mitigations?.filter(mit => mit.unitId === currentUnitId) || [];
              input.units = [...[currentUnit], ...otherUnits];
              input.mitigations = [...currentUsersMitigations, ...otherMitigations];
            }
          } else {
            // Desktop edit - case 1: new tablet user is added while editing
            // Desktop edit - case 2: tablet user remove himself from incident - WILL ADD THE UNIT known issue
            // Desktop edit - case 3: current tablet unit changed status / added mitigations
            // Apply merge only if edit form server arrived while the user was editing
            // for all units or mitigation - compare server data and take the latest update from the server

            const updateAt = new Date(update.updatedAt as string).getTime();
            const startEdit = new Date(
              this.localStorageService.get(LocalStorageKeys.UserLastEdit)?.timestamp as string
            ).getTime();
            if (!startEdit || updateAt > startEdit) {
              datadogLogs.logger.info('Override incident mitigation and units - incidentService', {
                currentUserInput: JSON.stringify({
                  associatedUnits: input.units,
                  mitigations: input.mitigations,
                }),
                serverLatestUpdate: JSON.stringify(update),
              });
              const unitsMap = new Map(
                input.units?.map(unit => {
                  return [unit.unitId, unit];
                })
              );

              const mitigationMap = new Map(
                input.mitigations?.map(mit => {
                  return [mit.id, mit];
                })
              );

              update.associatedUnits?.forEach(unit => {
                unitsMap.set(unit.id, {
                  driverId: unit.driverDetails?.userId,
                  response: unit.unitResponse,
                  unitId: unit.id,
                });
              });

              update.mitigations?.forEach(mit => {
                mitigationMap.set(mit.id, {
                  driverId: mit.userId,
                  interval: mit.interval,
                  mitigationTypeId: mit.mitigationType.id,
                  unitId: mit.unitId,
                });
              });

              input.units = [...unitsMap.values()];
              input.mitigations = [...mitigationMap.values()];
            }
          }
        }),
        catchError(err => {
          console.error('Failed to get incidentMitigationAndUnitsGQL', err);
          return of(err);
        }),
        switchMap(() => {
          return this._updateIncidentOneTime(input);
        })
      );
    } catch (err) {
      console.error(`Incident Service - updateIncidentOneTime error ${err}`);
      return throwError(err);
    }
  }

  _updateIncidentOneTime(input: IncidentInput): Observable<any> {
    return this.updateIncidentOneTimeGQL.mutate({ input }).pipe(
      map(res => res.data?.updateIncidentOneTime),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: OfflineRequests.updateIncident,
            params: [{ ...input, offlineUpdatedAt: moment().toISOString() }],
            onError: (updateRequest: OfflineUpdateRequest) => {
              const updateIncidentInput: IncidentInput = updateRequest.params[0];
              const offlineIncident: OfflineIncident = this.offlineIncident(updateIncidentInput.incidentId.toString());
              offlineIncident.applyOneTimeUpdate(updateIncidentInput, this.usersService?.authUser?.id);
              this.offlineIncidentMap.set(updateIncidentInput.incidentId.toString(), offlineIncident);
              this.storeOfflineCache('offlineIncidentMap', this.offlineIncidentMap);
              return offlineIncident.incident;
            },
            getId: this.getIdFromObject,
            setId: this.setIdToObject,
            type: RequestType.update,
          },
          defaultValue: {},
        })
      )
    );
  }

  updateIncidentMitigationCheck(modifiedIncident: Incident, incident: Incident) {
    if (modifiedIncident.mitigations.length === 0 && incident.mitigations.length === 0) return false;
    const authUserMitigations = incident.mitigations.filter(
      mitigation => mitigation.userId === this.usersService.authUser.id
    );
    const authUserModifiedMitigations = modifiedIncident.mitigations.filter(
      mitigation => mitigation.userId === this.usersService.authUser.id
    );
    const mitigationsDiff = Utils.entitiesArraysDiff(authUserMitigations, authUserModifiedMitigations, {
      entityFiledName: 'mitigation',
    });
    if (
      modifiedIncident.mitigations[0] &&
      modifiedIncident.mitigations[0].interval &&
      incident.mitigations[0] &&
      incident.mitigations[0].interval &&
      JSON.stringify(modifiedIncident.mitigations[0].interval) !== JSON.stringify(incident.mitigations[0].interval)
    ) {
      mitigationsDiff.modified = modifiedIncident.mitigations;
    }
    if (mitigationsDiff.modified.length > 0 || mitigationsDiff.removed.length > 0) {
      mitigationsDiff.modified = mitigationsDiff.modified.map((incidentMitigation: IncidentMitigationInput) => {
        const input: IncidentMitigationInput = {
          interval: incidentMitigation.interval,
          mitigationTypeId: incidentMitigation.mitigationTypeId,
          unitId: incidentMitigation.unitId, // unitId
          driverId: incidentMitigation.driverId,
        };
        return input;
      });
      return mitigationsDiff;
    }
    return false;
  }

  deleteIncidentMedia(input: DeleteIncidentMediaInput) {
    return this.deleteIncidentMediaGQL.mutate({ input }).pipe(
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: this.deleteIncidentMedia,
            params: [{ ...input, offlineUpdatedAt: moment().toISOString() }],
            onError: (updateRequest: OfflineUpdateRequest) => {
              // TODO: unclear how to handle it offline
            },
            getId: this.getIdFromObject,
            setId: this.setIdToObject,
          },
          defaultValue: true,
        })
      )
    );
  }

  renewIncidentMediaUrl(incidentMebiaId: number) {
    return this.renewIncidentMediaUrlGQL.mutate({ id: incidentMebiaId }).pipe(
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: this.renewIncidentMediaUrl,
            params: [incidentMebiaId],
            onError: (updateRequest: OfflineUpdateRequest) => {
              // Do nothing, there is no way to renew while offline and Urls do not work anyway.
            },
          },
          defaultValue: true,
        })
      )
    );
  }

  offlineUnit() {
    if (this.usersService.authUser.unit?.id) {
      return {
        accountId: this.usersService.authUser.account.id,
        displayId: this.usersService.authUser.unit?.displayId as string,
        externalId: 'Offline Unit',
        driverDetails: {
          userId: this.usersService.authUser.id,
          name: this.usersService.authUser.name,
          unitRelationType: this.usersService.authUser.unitRelationType,
        },
        id: this.usersService.authUser.unit.id,
        type: this.usersService.authUser.unit?.type as VehicleType,
        status: Status.Active,
        unitResponse: UnitResponse.OnScene,
        dashCameras: [],
      } as IncidentUnit;
    } else {
      return undefined;
    }
  }

  updateIncidentUnitResponse(input: UpdateIncidentUnitInput) {
    if (!navigator.onLine) {
      input.offlineUpdatedAt = moment().toISOString();
    }

    return this.updateIncidentUnitResponseGQL.mutate({ input }).pipe(
      map(res => res?.data?.updateIncidentUnitResponse),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: OfflineRequests.updateIncidentUnitResponse,
            params: [input],
            onError: async (updateRequest: OfflineUpdateRequest) => {
              const newUnit = {
                ...this.offlineUnit(),
                unitResponse: input.response,
                dashCameras: [],
              };
              const offlineIncident = this.offlineIncident(input.incidentId.toString());
              const associatedUnit = (offlineIncident?.incident?.associatedUnits || []).find(
                (unit: IncidentUnit) => unit.id === input.unitId
              ) as IncidentUnit;
              if (!associatedUnit && offlineIncident?.incident) {
                offlineIncident.incident.associatedUnits = offlineIncident.incident.associatedUnits || [];
                offlineIncident.incident.associatedUnits.push(newUnit as any);
              } else {
                associatedUnit.unitResponse = input.response;
              }
              return newUnit;
            },
            getId: this.getIdFromObject,
            setId: this.setIdToObject,
          },
          defaultValue: true,
        })
      )
    );
  }

  updateIncidentMitigations(input: UpdateIncidentMitigationsInput) {
    return this.updateIncidentMitigationsGQL.mutate({ input }).pipe(
      map(res => res?.data?.updateIncidentMitigations),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: OfflineRequests.updateIncidentMitigations,
            params: [{ ...input, offlineUpdatedAt: moment().toISOString() }],
            onError: async (updateRequest: OfflineUpdateRequest) => {
              const offlineIncident = this.offlineIncident(input.incidentId.toString());
              const _mitigations = (offlineIncident.incident?.mitigations || []).filter(
                mitigation =>
                  mitigation.unitId !== this.usersService.authUser.unit?.id &&
                  mitigation.userId !== this.usersService.authUser.id
              );
              let myMitigations = (offlineIncident.incident?.mitigations || []).filter(
                mitigation =>
                  mitigation.unitId === this.usersService.authUser.unit?.id &&
                  mitigation.userId === this.usersService.authUser.id
              );
              myMitigations = myMitigations.filter(
                _myMitigation =>
                  !input.removed.find(removedMitigation => removedMitigation.id === _myMitigation.mitigationType.id)
              );
              input.modified.forEach(updateIncidentMitigationInput => {
                const findCurrentMitigation = myMitigations.find(
                  myMitigation => updateIncidentMitigationInput.mitigationTypeId === myMitigation.mitigationType.id
                );
                if (findCurrentMitigation) {
                  findCurrentMitigation.interval = updateIncidentMitigationInput.interval;
                } else {
                  myMitigations.push({
                    id: updateIncidentMitigationInput.id,
                    userId: updateIncidentMitigationInput.driverId,
                    interval: updateIncidentMitigationInput.interval,
                    mitigationType: {
                      id: updateIncidentMitigationInput.mitigationTypeId,
                    },
                    unitId: updateIncidentMitigationInput.unitId,
                  } as IncidentMitigation);
                }
              });

              if (offlineIncident.incident) offlineIncident.incident.mitigations = _mitigations.concat(myMitigations);
              this.offlineIncidentMap.set(input.incidentId.toString(), offlineIncident);
              this.storeOfflineCache('offlineIncidentMap', this.offlineIncidentMap);
              return offlineIncident?.incident?.mitigations;
            },
            getId: this.getIdFromObject,
            setId: this.setIdToObject,
          },
          defaultValue: true,
        })
      )
    );
  }

  disassociateUnitFromIncident(input: DeleteIncidentUnitInput) {
    return this.disassociateUnitFromIncidentGQL.mutate({ input }).pipe(
      map(res => res?.data?.disassociateUnitFromIncident),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: this.disassociateUnitFromIncident,
            params: [input],
            onError: (updateRequest: OfflineUpdateRequest) => {
              // TODO: unclear how to handle it offline
            },
            getId: this.getIdFromObject,
            setId: this.setIdToObject,
          },
          defaultValue: true,
        })
      )
    );
  }

  incidentActivityLog(id: number) {
    if (!navigator.onLine || !this.isValidId(id)) return of();
    return this.incidentActivityLogGQL.fetch({ id: id }).pipe(
      map(res => res.data?.incidentActivityLog),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: this.incidentActivityLog,
            params: [id],
            onError: (updateRequest: OfflineUpdateRequest) => {
              // TODO: unclear how to handle it offline
            },
            getId: this.getStringId,
            setId: this.setIdToObject,
          },
          defaultValue: [],
        })
      )
    );
  }

  publishIncident(input: PublishInput) {
    return this.publishIncidentGQL.mutate({ input: input }).pipe(
      map(res => res?.data?.publishIncident),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: this.publishIncident,
            params: [input],
            onError: (updateRequest: OfflineUpdateRequest) => {
              // TODO: unclear how to handle it offline
            },
            getId: this.getIdFromObject,
            setId: this.setIdToObject,
          },
          defaultValue: true,
        })
      )
    );
  }

  _updateMitigationsInput(incident: Incident, mitigationsDiff: MitigationsDiff): UpdateIncidentMitigationsInput {
    const updateMitigationsInput: UpdateIncidentMitigationsInput = {
      ...mitigationsDiff,
      ...{ incidentId: incident.id },
    };

    return updateMitigationsInput;
  }
  _updateMitigations(incident, mitigationsDiff: MitigationsDiff) {
    //////////// update Incident Mitigations /////////////////////
    ////////////////////////////////////////////////////////////

    const updateMitigationsInput: UpdateIncidentMitigationsInput = this._updateMitigationsInput(
      incident,
      mitigationsDiff
    );
    if (updateMitigationsInput.modified.length || updateMitigationsInput.removed.length) {
      return this.updateIncidentMitigations(updateMitigationsInput); //.subscribe();
    } else {
      return of(incident.mitigations || []);
    }
  }

  updateOfflineIncidentWithOnlineRes(incidentId, res: {}, fieldName: keyof Incident) {
    setTimeout(() => {
      const offlineIncident = this.offlineIncident(incidentId);
      if (offlineIncident && offlineIncident.incident) {
        offlineIncident.incident[fieldName as string] = res;
        this.offlineIncidentMap.set(incidentId, offlineIncident);
        this.storeOfflineCache('offlineIncidentMap', this.offlineIncidentMap);
      }
    });
  }

  emitIncidentDiffAndUpdateLiveMap<T extends any>(): MonoTypeOperatorFunction<T> {
    return pipe(
      tap(incident =>
        this.entitiesServiceV2.emitNewEntitiesDiff({
          modified: {
            INCIDENT: [{ ...(incident as object), layerType: LayerType.Incident }],
          },
        })
      ),
      this.liveMapService.toggleWorkspaceVisibilityOnEntityUpdate(),
      this.liveMapService.setEntityLayerAsVisible(LayerType.Incident)
    );
  }

  findIncidentByPointRadius(input: FindIncidentsInPointRadiusInput): Observable<RelatedIncident[]> {
    return this.findRelatedIncidentsByPointRadiusGQL.fetch({ input }).pipe(
      map(res => res.data.findRelatedIncidentsByPointRadius),
      this.customOperators.catchGqlErrors()
    );
  }
}
