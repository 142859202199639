import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { WcPopupCloserModule } from '@wc/wc-common/src';
import { MapEditorModule } from '../../components/map-viewers/map-editor/map-editor.module';
import { StaticMapViewerModule } from '../../components/map-viewers/static-map-viewer/static-map-viewer.module';
import { MapGeometryEditorModalComponent } from './map-geometry-editor-modal/map-geometry-editor-modal.component';
import { MapGeometryControlComponent } from './map-geometry.component';

@NgModule({
  declarations: [MapGeometryControlComponent, MapGeometryEditorModalComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    StaticMapViewerModule,
    MapEditorModule,
    WcPopupCloserModule,
  ],
  exports: [MapGeometryControlComponent],
})
export class WcMapGeometryControlModule {}
