<ng-container *ngIf="data?.isUpdated; else ERROR">
  <div class="data-container extended" *ngIf="mode === SegmentDataViewModes.Extended">
    <div class="content">
      <wc-icon [iconName]="'posted_speed'"></wc-icon>
      <div class="content__title">
        <span>{{ 'roadwayStatus.postedSpeed' | translate }}</span>
      </div>
      <div class="content__value posted-speed">
        {{ maxSpeed | speedFormat : measureUnitType }}

        <span class="speed-units">
          {{ speedUnits | translate }}
        </span>
      </div>
    </div>

    <div class="content">
      <i class="fas fa-tachometer-slow"></i>
      <div class="content__title">
        {{ 'roadwayStatus.avgSpeed' | translate }}
      </div>

      <span class="content__value average-speed" [class]="data.maxSpeedDeviationSeverity | lowercase">
        {{ data.avgSpeedTotal | speedFormat : measureUnitType }}
        <span class="speed-units">
          {{ speedUnits | translate }}
        </span>
      </span>
    </div>
    <div class="content">
      <wc-icon [iconName]="'abandoned_vehicle'"></wc-icon>
      <div class="content__title">
        {{ 'roadwayStatus.vehicleCount' | translate }}
      </div>

      <span class="content__value vehicle-count">
        {{ data.transportCountTotal }}
      </span>
    </div>
  </div>

  <div class="data-container short" *ngIf="mode === SegmentDataViewModes.Short">
    <div class="content posted-speed">
      <div class="title">
        <span>{{ 'roadwayStatus.postedSpeed' | translate }}</span>
      </div>

      <span class="value">
        {{ maxSpeed | speedFormat : measureUnitType }}

        <span class="speed-units">
          {{ speedUnits | translate }}
        </span>
      </span>
    </div>

    <div class="content">
      <div class="title">
        <span>{{ 'roadwayStatus.avgSpeed' | translate }}</span>
      </div>

      <span class="value average-speed" [class]="data.maxSpeedDeviationSeverity | lowercase">
        {{ data.avgSpeedTotal | speedFormat : measureUnitType }}

        <span class="speed-units">
          {{ speedUnits | translate }}
        </span>
      </span>
    </div>
    <div class="content">
      <div class="title">
        <span>{{ 'roadwayStatus.vehicleCount' | translate }}</span>
      </div>
      <span class="value vehicle-count">
        {{ data.transportCountTotal }}
      </span>
    </div>
  </div>
</ng-container>
<ng-template #ERROR>
  <div class="error-container">
    <img class="error-icon" src="assets/icons/analytics-error.svg" alt="error" />
    {{ 'roadwayStatus.error' | translate }}
  </div>
</ng-template>
