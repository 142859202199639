import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AppFeatureEnum,
  RegionalSetting,
  SplitIOService,
  WeatherAlertCertainty,
  WeatherAlertUrgency,
  weatherSortOptionsEnum,
  weatherStatusOptionsEnum,
} from '@wc/core';
import { WeatherEventStatus } from '@wc/core/models/enums';
import { isElInViewInsideContainer } from '@wc/utils';
import { WeatherEventsService } from '@wc/wc-core/src';
import {
  LayerNamesOptionsType,
  LayerPanelStoreItem,
  LayerVisibilityItem,
  WeatherEventsStoreEntity,
  WeatherEventsUIEntity,
} from '@wc/wc-models';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'wc-weather-events-panel',
  templateUrl: './weather-events-panel.component.html',
  styleUrls: ['./weather-events-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeatherEventsPanelComponent implements AfterViewInit, OnDestroy {
  @Output() filterChange = new EventEmitter<weatherStatusOptionsEnum>();
  @Output() selected = new EventEmitter<WeatherEventsStoreEntity>();
  @Output() hidePanel = new EventEmitter<boolean>();
  @Output() layersVisibilityStateChange = new EventEmitter<LayerVisibilityItem[]>();
  selectedMapFilter: weatherStatusOptionsEnum = weatherStatusOptionsEnum.allAlerts;
  selectedSortWeatherEvents =
    this.weatherEventService.getFilterSelectionFromLocalStorage() || weatherSortOptionsEnum.startTime;

  AppFeatureEnum = AppFeatureEnum;
  allWeatherEventsAmount = 0;
  activeWeatherEventId!: number;
  currentlyVisibleLayerNames: string[] = [];
  activeWeatherEventList: {
    entity: WeatherEventsStoreEntity | undefined;
    ui: WeatherEventsUIEntity | undefined;
  }[] = [];
  forecastWeatherEventList: {
    entity: WeatherEventsStoreEntity | undefined;
    ui: WeatherEventsUIEntity | undefined;
  }[] = [];
  currentSelectedWeatherEventId: number | undefined;
  moment = moment;
  refocus = new Subject();
  refocus$ = this.refocus.asObservable();

  @Input() weatherStoreItems: LayerPanelStoreItem<LayerNamesOptionsType>[] | null = [];
  @Input() isPortraitDesktopMode = false;
  @Input() isDirectionRtl = false;
  @Input() regionalSetting!: RegionalSetting;
  @Input() set activeWeatherEvent(weatherEvent) {
    this.activeWeatherEventId = weatherEvent;
  }

  @Input() set weatherEvents(
    weatherEvents: {
      [WeatherEventStatus.Active]: {
        entity: WeatherEventsStoreEntity | undefined;
        ui: WeatherEventsUIEntity | undefined;
      }[];
      [WeatherEventStatus.Forecast]: {
        entity: WeatherEventsStoreEntity | undefined;
        ui: WeatherEventsUIEntity | undefined;
      }[];
    } | null
  ) {
    if (weatherEvents) {
      this.allWeatherEventsAmount =
        weatherEvents[WeatherEventStatus.Active].length + weatherEvents[WeatherEventStatus.Forecast].length;
      this.activeWeatherEventList = weatherEvents[WeatherEventStatus.Active];
      this.forecastWeatherEventList = weatherEvents[WeatherEventStatus.Forecast];

      this.currentlyVisibleLayerNames =
        this.weatherStoreItems?.filter(layer => !!layer.checked).map(layer => layer.name) || [];
    }
  }

  @Input() set currentMapFilters(mapFilter: weatherStatusOptionsEnum | undefined) {
    if (mapFilter) {
      this.selectedMapFilter = mapFilter;
    }
  }

  get WeatherStatusOptionsEnum() {
    return weatherStatusOptionsEnum;
  }

  get weatherStatusOptions() {
    return Object.values(this.WeatherStatusOptionsEnum);
  }

  get weatherSortOptionsArray() {
    return Object.values(weatherSortOptionsEnum);
  }

  get weatherAlertCertainty() {
    return WeatherAlertCertainty;
  }

  get weatherAlertUrgency() {
    return WeatherAlertUrgency;
  }

  get isAllSelected() {
    return this.currentlyVisibleLayerNames?.length === this.weatherStoreItems?.length;
  }

  private contentScrolled$ = new Subject();
  private currentViewedEntityIds: Set<number> = new Set();

  constructor(
    public dialog: MatDialog,
    public splitIoService: SplitIOService,
    private weatherEventService: WeatherEventsService
  ) {
    this.contentScrolled$.pipe(debounceTime(50)).subscribe(e => {
      this.checkAllForViewedElement();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.activeWeatherEventId) {
        const selectedWeatherAlert = document.getElementById(this.activeWeatherEventId.toString());
        if (selectedWeatherAlert) selectedWeatherAlert.scrollIntoView();
      }
    });

    this.contentScrolled$.next();
  }

  onContainerScroll(e) {
    this.contentScrolled$.next();
  }

  checkAllForViewedElement() {
    const all = this.activeWeatherEventList
      .concat(this.forecastWeatherEventList)
      .filter(weatherEvent => !weatherEvent.ui?.isViewed);
    all.forEach(weatherEvent => {
      const el: HTMLElement | null = document.getElementById(`${weatherEvent?.entity?.id}`);
      const parent = el?.parentElement?.parentElement;
      if (el && parent && isElInViewInsideContainer(el, parent, 50)) {
        this.currentViewedEntityIds.add(+el.id);
      }
    });
  }

  closePanel() {
    this.hidePanel.emit();
  }

  eventsStatusFilterChange(e) {
    this.filterChange.emit(e.value);
  }

  clickOnEventInList(e) {
    if (e.id === this.currentSelectedWeatherEventId) {
      this.selected.emit();
      this.currentSelectedWeatherEventId = undefined;
    } else {
      this.selected.emit(e);
      this.currentSelectedWeatherEventId = e.id;
      this.weatherEventService.setWeatherEventAsViewed([e.id]);
    }
  }

  multiSelectChange(e) {
    if (e.value && e.value[0] === 'all') {
      this.toggleSelectAll(e);
      return;
    }

    this.layersVisibilityStateChange.emit(
      this.weatherStoreItems?.map(layer => ({
        checked: e.value.some(value => value === layer.name),
        name: layer.name,
      }))
    );
  }

  toggleSelectAll(e) {
    this.layersVisibilityStateChange.emit(
      this.weatherStoreItems?.map(layer => ({
        checked: !this.isAllSelected,
        name: layer.name,
      }))
    );
  }

  saveFilterSelectionToLocalStorage(selectedOption) {
    this.weatherEventService.saveFilterSelectionToLocalStorage(selectedOption);
  }

  trackById(index, item) {
    if (!item) {
      return null;
    }
    return item.entity.id;
  }

  stopDefaultBehavior($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  ngOnDestroy() {
    this.weatherEventService.setWeatherEventAsViewed(Array.from(this.currentViewedEntityIds));
  }
}
