<form
  class="mitigation-component"
  [formGroup]="form"
  [class.tablet-mode]="isTabletMode"
  [class.language-hebrew]="isHebrew"
  *ngIf="!isDisabledWhenOffline; else offlineEmpty">
  <div class="mitigation-date-time">
    <div data-cy-id="started-at-id" class="form-row start-date">
      <wc-date-time-control [formControl]="$any(form.get('from'))" [fieldData]="fieldsData.from">
      </wc-date-time-control>
      <mat-error
        class="mat-error start-time-err"
        *ngIf="form.get('from')?.errors && form.get('from')?.errors['beforeDateRequired']">
        {{ 'validationErrorMessages.beforeDateRequired' | translate }}
      </mat-error>
    </div>

    <div data-cy-id="ended-at-id" class="form-row end-date">
      <wc-date-time-control [formControl]="$any(form.get('to'))" [fieldData]="fieldsData.to"> </wc-date-time-control>
    </div>
  </div>
  <mat-error
    class="mat-error"
    *ngIf="
      !form.get('to')?.errors && form.get('from')?.errors && form.get('from')?.errors['startDateNeedToBeBeforeEndDate']
    ">
    {{ 'validationErrorMessages.startDateNeedToBeBeforeEndDate' | translate }}
  </mat-error>

  <div
    class="mitigation-chips"
    *ngIf="!isTabletMode"
    [class.mitigation-err-border]="
      form.get('mitigation')?.errors &&
      (form.get('mitigation')?.errors['endTimeMitigationType'] || form.get('mitigation')?.errors['maxlength'])
    ">
    <div class="mitigation-title-wrapper">
      <div class="mitigation-title">{{ 'mitigations' | translate }} ({{ selectedChipsCount }})</div>

      <mat-error
        class="mat-error"
        *ngIf="form.get('mitigation')?.errors && form.get('mitigation')?.errors['maxlength']">
        {{ 'validationErrorMessages.singleMitigationSelection' | translate }}
      </mat-error>
      <mat-error
        class="mat-error"
        *ngIf="form.get('mitigation')?.errors && form.get('mitigation')?.errors['endTimeMitigationType']">
        {{ 'validationErrorMessages.required' | translate }}
      </mat-error>
    </div>

    <wc-multiple-chips
      formControlName="mitigation"
      [formFieldData]="fieldsData.mitigation"
      [hiddenValues]="[defaultMitigationTypeId]">
    </wc-multiple-chips>
  </div>

  <div class="mitigation-list-wrapper" *ngIf="isTabletMode" #mitigationList>
    <div class="mitigation-list-label-wrapper">
      <span class="mitigation-list-label"
        >{{ 'mitigations' | translate }}<span>({{ mitigationNumber }})</span></span
      >
    </div>
    <div class="mitigation-autocomplete">
      <wc-autocomplete-v2
        [uniqueId]="uniqueId"
        multiSelectMaxLengthErrorMessage="singleMitigationSelection"
        [shouldScrollIntoView]="shouldScrollIntoView"
        [formControl]="$any(form.get('mitigation'))"
        [fieldData]="fieldsData.mitigation"
        [optionsList]="mitigationOptions"
        [allowMultiSelect]="true"
        [allowFreeText]="false"
        [floatLabel]="false"
        [isTabletMode]="isTabletMode"
        [dynamicClass]="'mitigation'"></wc-autocomplete-v2>
    </div>
  </div>
</form>
<ng-template #offlineEmpty>
  <div class="empty-state-container" fxLayout="column" fxLayoutAlign="center center">
    <img src="assets/images/empty-offline-involved-vehicles.svg" />
    <span class="caption-mitigations">{{ 'emptyStates.informationNotAvailable' | translate }}</span>
    <span class="caption-mitigations">{{ 'emptyStates.whileOffline' | translate }}</span>
  </div>
</ng-template>
