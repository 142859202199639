import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MeasureUnitType } from '@wc/core/models/gql.models';

@Component({
  selector: 'wc-segment-title',
  templateUrl: './segment-title.component.html',
  styleUrls: ['./segment-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentTitleComponent {
  @Input() addressTitle!: string;
  @Input() direction!: string;
  @Input() range!: number;
  @Input() measureUnitType!: MeasureUnitType;
  @Input() showIcon? = true;

  get measureUnitTypeEnum(): typeof MeasureUnitType {
    return MeasureUnitType;
  }
}
