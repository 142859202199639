import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AccountStore, Store, UiStore, UsersStore, WindowService } from '@wc/core';
import * as Enums from '@wc/core/models/enums';
import { ConfirmModalComponent, ConfirmModalService } from '@wc/wc-ui';
import { pairwise } from 'rxjs/operators';
import { CreateShiftInput, UnitRelationType, UnitVehicle, User } from '../../../../../core/models/gql.models';
import { ShiftStore } from '../../../../../core/stores/shift.store';
import { FormFieldData } from '../../../../../wc-ui/src/lib/base/custom-form-control';

type FormModel = CreateShiftInput;

@Component({
  selector: 'wc-start-shift-form',
  templateUrl: './start-shift-form.component.html',
  styleUrls: ['./start-shift-form.component.scss'],
})
export class StartShiftFormComponent implements OnInit {
  shiftForm!: FormGroup;
  @Output() modalClosed: EventEmitter<any> = new EventEmitter();
  authUser: User;
  unitRelationType: UnitRelationType;
  units!: Partial<UnitVehicle>[];
  selectedUnit?: UnitVehicle;
  disableCreate = false;

  get unitRelationTypeEnum() {
    return UnitRelationType;
  }

  shiftFieldsData: { [filedName in keyof FormModel | any]: FormFieldData } = {
    unitId: {
      label: 'shiftsAndRoutes.unit',
      options: this.accountStore.unitsOptionsByAccount,
      required: true,
    },
    startMileage: {
      label: 'shiftsAndRoutes.mileage',
      required: true,
    },
    shiftTypeId: {
      label: 'shiftsAndRoutes.shift',
      options: this.shiftStore.shiftTypesOptions,
      required: true,
    },
    routeTypesIds: {
      label: 'shiftsAndRoutes.route',
      options: this.shiftStore.routeTypesOptions,
      required: true,
    },
  };

  constructor(
    @Inject(Store) private store: Store,
    private fb: FormBuilder,
    private shiftStore: ShiftStore,
    public usersStore: UsersStore,
    private accountStore: AccountStore,
    public confirmService: ConfirmModalService,
    public dialog: MatDialog,
    private windowService: WindowService,
    public uiStore: UiStore
  ) {
    this.authUser = this.usersStore.authUser;
    this.unitRelationType = this.usersStore.authUser.unitRelationType;
    this.disableCreate = !navigator.onLine;
    this.windowService.online$.pipe(pairwise()).subscribe(([prevStatus, currentStatus]) => {
      this.disableCreate = !currentStatus;
    });
  }

  ngOnInit(): void {
    this.shiftForm = this.createForm({
      unitId: this.usersStore.authUser?.unit?.id,
      startMileage: this.shiftStore.currentShift?.startMileage,
      shiftTypeId: this.shiftStore.currentShift?.shiftType?.id,
      routeTypesIds: [this.shiftStore.currentShift?.routeTypes?.map(route => route.id)],
    });

    this.store.accountStore.loadUnitsByAccount().subscribe(() => {
      this.shiftFieldsData.unitId.options = this.accountStore.unitsOptionsByAccount;

      if (this.shiftStore.unitsOptions?.length > 0 && this.unitRelationType !== UnitRelationType.NoUnit) {
        this.shiftForm.get('unitId')?.setValidators([Validators.required]);
        this.shiftForm
          .get('startMileage')
          ?.setValidators([
            Validators.required,
            Validators.min(0),
            Validators.pattern('[0-9]*'),
            Validators.maxLength(9),
          ]);
        this.shiftForm.updateValueAndValidity();
      }
    });

    if (this.unitRelationType === UnitRelationType.ConstantUnit) {
      this.shiftForm
        .get('startMileage')
        ?.setValidators([Validators.required, Validators.min(0), Validators.pattern('[0-9]*')]);
    }

    if (this.shiftStore.routeTypesOptions?.length > 0) {
      this.shiftForm.get('routeTypesIds')?.setValidators([Validators.required]);
    }

    if (this.shiftStore.shiftTypesOptions?.length > 0) {
      this.shiftForm.get('shiftTypeId')?.setValidators([Validators.required]);
    }

    this.shiftForm.get('unitId')?.valueChanges.subscribe(value => {
      this.unitSelected(this.shiftForm.get('unitId')?.value);
    });
  }

  createForm(model: { [filedName in keyof FormModel]: any }): FormGroup {
    return this.fb.group(model);
  }

  closeShiftForm() {
    this.modalClosed.emit();
  }

  submitStartShiftForm() {
    this.disableCreate = true;
    if (this.shiftForm.valid) {
      if (this.shiftForm.get('routeTypesIds')?.value === null) {
        this.shiftForm.get('routeTypesIds')?.setValue([]);
      }

      this.shiftStore.createShift(this.shiftForm.value).subscribe(response => {
        if (response) {
          this.closeShiftForm();
        } else {
          this.disableCreate = false;
        }
      });
    } else {
      this.disableCreate = false;
    }
  }

  unitSelected(unitId) {
    const unit = this.accountStore.unitsOptionsByAccount.find(unitOption => unitOption.value === unitId);
    if (!unit || !unit.data) {
      return;
    }

    const unitVehicle: UnitVehicle = unit.data as UnitVehicle;
    if (!unitVehicle.userDetails) {
      return;
    }

    this.confirmService.dialog.openDialogs.forEach(dialog => {
      if (dialog.componentInstance instanceof ConfirmModalComponent) {
        dialog.close();
      }
    });

    this.confirmService.showConfirmDialog(
      Enums.ConfirmationModalType.UnassignUser,
      `${unitVehicle.userDetails.name}`,
      confirmed => {
        if (confirmed) {
          this.selectedUnit = unitVehicle;
        } else {
          this.shiftForm.get('unitId')?.setValue(null);
          this.selectedUnit = undefined;
        }
      }
    );
  }
}
