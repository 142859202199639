import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'wc-confirm-edit-changes-modal',
  templateUrl: './confirm-edit-changes-modal.component.html',
  styleUrls: ['./confirm-edit-changes-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmEditChangesModalComponent {
  isLangHe: boolean;

  constructor(
    private dialogRef: MatDialogRef<ConfirmEditChangesModalComponent>,
    private translateService: TranslateService
  ) {
    this.isLangHe = this.translateService.currentLang === 'he';
  }

  leave() {
    this.dialogRef.close(true);
  }

  stay() {
    this.dialogRef.close(false);
  }
}
