import { AuthUserResolverService } from './auth-user.resolver.service';
import { ConstructionResolverService } from './construction.resolver.service';
import { IncidentResolverService } from './incident.resolver.service';
import { RoadClosureResolverService } from './road-closure.resolver.service';
import { SpecialEventResolverService } from './special-event.resolver.service';

export const RESOLVERS = [
  IncidentResolverService,
  RoadClosureResolverService,
  ConstructionResolverService,
  SpecialEventResolverService,
  AuthUserResolverService,
];

export * from './auth-user.resolver.service';
export * from './construction.resolver.service';
export * from './incident.resolver.service';
export * from './road-closure.resolver.service';
export * from './special-event.resolver.service';
