import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonToggleGroupModule,
  OnboardingToursModule,
  SliderToggleControlModule,
  SupportModule,
  WcButtonModule,
  WcIconModule,
} from '@wc/wc-ui/src';
import { ReleaseNotesViewCModule } from '../release-notes-view/release-notes-view.module';
import { SettingsMenuComponent } from './settings-menu.component';

@NgModule({
  declarations: [SettingsMenuComponent],
  imports: [
    CommonModule,
    WcIconModule,
    TranslateModule,
    MatSlideToggleModule,
    WcButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonToggleGroupModule,
    RouterModule,
    OnboardingToursModule,
    SupportModule,
    ReleaseNotesViewCModule,
    MatMenuModule,
    SliderToggleControlModule,
  ],
  exports: [SettingsMenuComponent],
})
export class SettingsMenuModule {}
