/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

type LetContext<T> = {
  wcLet: T;
};

@Directive({
  selector: '[wcLet]',
})
export class WcLetDirective {
  private _context: LetContext<null> = { wcLet: null };

  constructor(_viewContainer: ViewContainerRef, _templateRef: TemplateRef<LetContext<any>>) {
    _viewContainer.createEmbeddedView(_templateRef, this._context);
  }
  @Input() set wcLet(v) {
    this._context.wcLet = v;
  }
}
