<div [class.tablet-mode]="isTablet" [class]="appMode">
  <div [ngClass]="normalizedTooltipStatus">
    <div class="map-unit-tooltip">
      <div class="map-unit-tooltip__header">
        <ng-container [ngTemplateOutlet]="this[templatesByStatus[normalizedTooltipStatus]]"></ng-container>

        <!-- Special statuses can appear along side any template, except on_break -->
        <ng-container
          *ngIf="normalizedTooltipStatus !== 'on_break'"
          [ngTemplateOutlet]="SPECIAL_STATUSES"></ng-container>
      </div>

      <div class="map-unit-tooltip__body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>

<ng-template #TEMPLATE_ONE>
  <div class="template-one">
    <i class="unit-response-icon"></i>
    <div class="template-one__text">
      <span>{{ 'unitResponse.' + (normalizedTooltipStatus || '') | translate }}</span>

      <span>
        {{ templateMainTextByStatus[normalizedTooltipStatus] || '' | translate }}
      </span>
    </div>

    <span class="template-one__incident-id" (click)="incidentIdClick.emit()">
      {{ additionalInfo?.incidentId }}
    </span>
  </div>
</ng-template>

<ng-template #TEMPLATE_TWO>
  <div class="template-two">
    <i class="unit-response-icon"></i>
    <span> {{ templateMainTextByStatus[normalizedTooltipStatus] || '' | translate }}</span>
  </div>
</ng-template>

<ng-template #SPECIAL_STATUSES>
  <div class="special-statuses-chips-container" *ngIf="additionalInfo?.activityStatuses?.length">
    <wc-chip
      *ngFor="let status of additionalInfo?.activityStatuses"
      [text]="status | translate"
      [isTablet]="isTablet"
      [style]="chipStyles.special_status"></wc-chip>
  </div>
</ng-template>

<ng-template #ON_BREAK>
  <div class="on-break">
    <i class="fas fa-pause-circle"></i>
    <div class="on-break__bold-line">
      <span>{{ templateMainTextByStatus[normalizedTooltipStatus] || '' | translate }}</span>
      <span class="break-duration">{{ additionalInfo?.unitBreakDuration }} {{ 'min' | translate }}</span>
    </div>
    <span>
      {{ 'shiftPauseReasons.' + additionalInfo?.unitBreakReason?.toString() | translate }}
    </span>
  </div>
</ng-template>
