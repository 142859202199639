import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Optional, Self } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { HeapAnalyticsService } from '@wc-core';
import { CustomFormControlComponent, FormFieldOption } from '../../lib/base/custom-form-control';
import { WCErrorStateMatcher } from '../../lib/base/error-state-matcher';

@Component({
  selector: 'wc-select-control',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectControlComponent extends CustomFormControlComponent {
  emptyControl = new FormControl(null);
  _showLoadMoreButton = true;
  _isLoadMoreView = false;
  matcher: ErrorStateMatcher = new WCErrorStateMatcher();

  @Input() useGrouping = false;
  @Input() isTabletMode = false;
  @Input() isDirectionRtl = false;
  @Input() dynamicClass = '';

  @Input() set isLoadMoreView(val: boolean) {
    this._isLoadMoreView = val;
  }

  @Input() set disabled(val: boolean) {
    this._disabled = val;
  }

  constructor(
    cdr: ChangeDetectorRef,
    @Self() @Optional() public ngControl: NgControl,
    private heapService: HeapAnalyticsService
  ) {
    super(ngControl, cdr);
  }

  get visibleOptions() {
    return this.formFieldData?.options?.filter(op => !op.hidden && op.value !== this.ngControl.value) || [];
  }

  get hiddenOptions() {
    return this.formFieldData?.options?.filter(op => op.hidden === true && op.value !== this.ngControl.value) || [];
  }

  get displayNameOfControlValue() {
    return this.formFieldData?.options?.find(op => op.value === this.ngControl.value)?.displayName || '';
  }

  showMoreClicked() {
    this._showLoadMoreButton = false;
  }

  sendHeapTouch() {
    this.heapService.trackUserSpecificAction(
      `heap-${this.formFieldData.heapClass ? this.formFieldData.heapClass : 'select'}-opened`
    );
  }

  get groupedOptions() {
    const groupedOptions: Record<string, FormFieldOption<unknown, unknown>[]> = {};

    if (this.useGrouping)
      this.formFieldData.options?.forEach(option => {
        if (option.groupLabel) {
          if (!groupedOptions[option.groupLabel]) {
            groupedOptions[option.groupLabel] = [];
          }
          groupedOptions[option.groupLabel].push(option);
        }
      });

    return { groupedOptions, labels: Object.keys(groupedOptions) };
  }
}
