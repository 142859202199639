import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { WcButtonModule, WcIconModule } from '@wc/wc-ui/src';
import { MediaThumbnailModule } from '@wc/wc-ui/src/components/media-thumbnail/media-thumbnail.module';
import { ReleaseNotesViewComponent } from './release-notes-view.component';

@NgModule({
  declarations: [ReleaseNotesViewComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MediaThumbnailModule,
    MatButtonModule,
    MatMenuModule,
    WcIconModule,
    WcButtonModule,
  ],
  exports: [ReleaseNotesViewComponent],
})
export class ReleaseNotesViewCModule {}
