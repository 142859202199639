import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HeapAnalyticsService, markNestedControlsAsTouched, ResponsePlanService } from '@wc-core';
import { ResponsePlan, ResponsePlanEntityType, ResponsePlanInput, ResponsePlanTemplate } from '@wc/core';
import { ToastrPositionClass, UiMode } from '@wc/wc-models';
import { cloneDeep } from 'lodash';
import { catchError, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { FormFieldData } from '../../lib/base';
import { ToastrAlertsService } from '../../services/toaster-alert.service';

@Component({
  selector: 'wc-response-plan-entity-control',
  templateUrl: './response-plan-entity-control.component.html',
  styleUrls: ['./response-plan-entity-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponsePlanEntityControlComponent implements OnDestroy {
  @Output() goToScheduleClicked = new EventEmitter();

  formGroup?: FormGroup;
  templateControl = new FormControl(null);
  _startTime = '';
  _endTime = '';
  _initialPlanValue?: ResponsePlan;
  uiModeEnum = UiMode;
  responsePlanEntityType = ResponsePlanEntityType;
  isLoadingTemplates = false;
  isFirstLoaded = false;

  formFieldData: FormFieldData<number> = {
    options: [],
    label: 'select',
    floatLabel: 'never',
  };
  @Input() isEdit = false;
  @Input() isFormSubmitted = false;
  @Input() entityType: ResponsePlanEntityType = ResponsePlanEntityType.SpecialEvent;
  @Input() set entityResponsePlan(plan: ResponsePlan | undefined) {
    if (plan) {
      const formPlan = cloneDeep(plan);
      this._initialPlanValue = formPlan;

      this.templateControl.setValue(plan.templateId);
      this.updateDeleteTemplate();
      this.responsePlanService.setPlanBaseTime(this._startTime, this._endTime);
      this.responsePlanService.generateEntityResponsePlanForm(
        formPlan,
        this.entityType,
        formPlan.entityId,
        formPlan.id
      );
      this.formGroup = this.responsePlanService.fullFormRef;
    }
  }
  @Input() set startTime(time: string) {
    this._startTime = time;
    this.updatePlanTimes();
  }
  @Input() set endTime(time: string) {
    this._endTime = time;
    this.updatePlanTimes();
  }

  @Input() set firstLoaded(loaded: boolean) {
    if (!this.isFirstLoaded && loaded) {
      this.initTemplates();
    }
  }
  @Input() isMandatory = false;

  get isInvalid() {
    return this.isFormSubmitted && (this.formGroup ? this.formGroup.invalid : this.isMandatory);
  }

  constructor(
    public parentFormGroupDirective: FormGroupDirective,
    public responsePlanService: ResponsePlanService,
    private heapService: HeapAnalyticsService,
    public cdr: ChangeDetectorRef,
    private alertService: ToastrAlertsService,
    private translateService: TranslateService
  ) {}

  private initTemplates() {
    this.isLoadingTemplates = true;
    let responseTemplates: ResponsePlanTemplate[] = [];
    this.responsePlanService
      .getResponsePlanTemplatesByType(this.entityType)
      .pipe(
        tap(templates => {
          responseTemplates = templates;
          this.formFieldData.options = this.responsePlanService.getTemplateSelectOptions(responseTemplates);
          this.updateDeleteTemplate();
          this.isLoadingTemplates = false;
          this.cdr.markForCheck();
        }),
        catchError(e => {
          console.error(e);
          this.isLoadingTemplates = false;
          this.cdr.markForCheck();
          const isUnauthorized = e && e[0] && (e[0].message as string)?.includes('Unauthorized');
          this.showTemplatesFetchError(isUnauthorized);
          return e;
        }),
        switchMap(() => this.templateControl.valueChanges),
        distinctUntilChanged((prev, curr) => {
          return prev === curr;
        }),
        filter(value => !!value)
      )
      .subscribe(templateId => {
        const responseTemplate = responseTemplates.find(t => templateId === t.id);

        const responsePlanId = this._initialPlanValue?.templateId === templateId ? templateId : undefined;
        if (responseTemplate) {
          this.responsePlanService.setPlanBaseTime(this._startTime, this._endTime);
          this.responsePlanService.generateEntityResponsePlanForm(
            responseTemplate,
            this.entityType,
            this._initialPlanValue?.entityId,
            responsePlanId
          );
          this.formGroup = this.responsePlanService.fullFormRef;
        }
        // In case of delete plan selected - take its _initialPlanValue and not the template value
        else if (templateId === this._initialPlanValue?.templateId && this._initialPlanValue) {
          this.responsePlanService.setPlanBaseTime(this._startTime, this._endTime);
          this.responsePlanService.generateEntityResponsePlanForm(
            this._initialPlanValue,
            this.entityType,
            this._initialPlanValue?.entityId,
            this._initialPlanValue.id
          );
          this.formGroup = this.responsePlanService.fullFormRef;
        }

        this.heapService.trackUserSpecificAction(
          `heap-desktop-${
            this.entityType === ResponsePlanEntityType.SpecialEvent ? 'se' : 'incident'
          }-response-plan-select-template`
        );
      });
  }

  updateDeleteTemplate() {
    const isResponsePlanDeleted = !this.formFieldData.options?.some(
      c => c.value === this._initialPlanValue?.templateId
    );

    // Add delete plan to the options
    if (isResponsePlanDeleted && this._initialPlanValue?.templateId) {
      this.formFieldData.options?.push({
        value: this._initialPlanValue.templateId,
        displayName: this._initialPlanValue.title,
      });
    }
  }

  updatePlanTimes() {
    if ((!!this._startTime && !!this._endTime) || this.entityType === ResponsePlanEntityType.Incident) {
      this.templateControl.enable({ emitEvent: false });
      this.responsePlanService.setPlanBaseTime(this._startTime, this._endTime);
    } else {
      this.templateControl.disable({ emitEvent: false });
    }
  }

  deleteResponsePlan() {
    this.isEdit
      ? this.heapService.trackUserSpecificAction(
          `heap-desktop-${
            this.entityType === ResponsePlanEntityType.SpecialEvent ? 'se' : 'incident'
          }-response-plan-edit-delete-click`
        )
      : this.heapService.trackUserSpecificAction(
          `heap-desktop-${
            this.entityType === ResponsePlanEntityType.SpecialEvent ? 'se' : 'incident'
          }-response-plan-create-delete-click`
        );
    this.formGroup = undefined;
    this.templateControl.setValue(null);
    this.responsePlanService.resetFormAndData();
  }

  goToSchedule() {
    this.goToScheduleClicked.emit();
    this.heapService.trackUserSpecificAction('heap-desktop-se-response-plan-create-update-schedule-click');
  }

  getParsedFormValue() {
    const responsePlan = this.formGroup ? (this.formGroup?.value as ResponsePlanInput) : undefined;
    responsePlan?.actions.map((a, index) => {
      a.index = index;
      a.value = a.value !== '' ? a.value : null;
      return this.responsePlanService.createActionValue(a);
    });
    return responsePlan;
  }

  private showTemplatesFetchError(isUnauthorized = false) {
    const errMsg = isUnauthorized
      ? this.translateService.instant('failedUnauthorizedTemplates')
      : this.translateService.instant('failedLoadResponsePlan');
    this.alertService.error(errMsg, undefined, {
      positionClass: ToastrPositionClass.DesktopBottomPanelLeft,
      timeOut: 10000,
    });
    this.templateControl.disable();
  }

  markAllNestedAsTouched() {
    if (this.formGroup) markNestedControlsAsTouched(this.formGroup);
  }

  ngOnDestroy(): void {
    this.responsePlanService.resetFormAndData();
  }
}
