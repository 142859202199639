import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HeapAnalyticsService } from '@wc-core';
import { Media } from '@wc/core/models/models';
import { MediaApiService } from '@wc/wc-core/src/lib/services/media-api.service';
import { MediaService } from '@wc/wc-core/src/lib/services/media.service';
import { AppFeatureEnum, SplitIOService } from '@wc/wc-core/src/lib/services/split-io.service';
import { IconClassName, SnapshotTypeGroups } from '@wc/wc-models';
import { MediaFilterType, SnapshotType } from '@wc/wc-models/src/lib/enums/media';
import { cloneDeep } from 'lodash';
import { Observable, OperatorFunction } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { CctvSnapshotsFullScreenComponent } from '../cctv-snapshots-full-screen/cctv-snapshots-full-screen.component';

@Component({
  selector: 'wc-cctv-snapshots',
  templateUrl: './cctv-snapshots.component.html',
  styleUrls: ['./cctv-snapshots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CctvSnapshotsComponent implements OnInit {
  maxItemView = 3;
  mediaItemsList: Array<Media | File> = [];
  cctvSnapshotList: Array<Media> = [];
  allSnapshotsList: Record<SnapshotTypeGroups | string, Media[]> = {};
  snapshotTypesSet: Set<SnapshotTypeGroups> = new Set();
  currentTotalFilesSize = 0;
  tooltipIconsNames: IconClassName[] = ['letter-v'];

  @Input() isEditMode = false;
  @Input() viewItemLimit = this.maxItemView;
  @Input() enableHandelError = true;
  @Input() renewMediaUrl!: (args) => Observable<string | undefined>;
  @Output() uploadedFile = new EventEmitter<File>();
  @Output() deletedMedia = new EventEmitter();

  @Input() set media(value: Array<Media | File>) {
    if (!value) return;
    this.mediaItemsList = cloneDeep(value) || [];
    this.mediaItemsList.reverse();
    this.calcCurrentTotalFilesSize(this.mediaItemsList);

    if (this.isSnapshotsFeatureIsOn) {
      this.cctvSnapshotList = this.mediaService.filterMediaBySource(
        this.mediaItemsList,
        MediaFilterType.CctvSnapshots
      ) as Array<Media>;
      this.groupSnapshotsByType();
    }
  }

  get isSnapshotsFeatureIsOn() {
    return (
      this.splitIOService.isActiveFeatureToggle(AppFeatureEnum.FE_AUTO_GENERATED_SNAPSHOTS) ||
      this.splitIOService.isActiveFeatureToggle(AppFeatureEnum.FE_USER_GENERATED_SNAPSHOTS)
    );
  }

  constructor(
    private mediaApiService: MediaApiService,
    private mediaService: MediaService,
    private splitIOService: SplitIOService,
    private heapService: HeapAnalyticsService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {
    navigator.userAgent.includes('Windows')
      ? this.tooltipIconsNames.unshift('ctrl')
      : this.tooltipIconsNames.unshift('mac-command');
  }

  ngOnInit(): void {
    this.renewDocFiles();
  }

  getSnapshotType(path: string): SnapshotTypeGroups | null {
    if (path.includes(SnapshotType.Created)) {
      return SnapshotTypeGroups.Created;
    } else if (path.includes(SnapshotType.Confirmed)) {
      return SnapshotTypeGroups.Confirmed;
    } else if (path.includes(SnapshotType.Completed)) {
      return SnapshotTypeGroups.Completed;
    } else if (path.includes(SnapshotType.Rejected)) {
      return SnapshotTypeGroups.Rejected;
    } else if (path.includes(SnapshotType.UnitArrived)) {
      return SnapshotTypeGroups.UnitArrived;
    } else if (path.includes(SnapshotType.UserCaptured)) {
      return SnapshotTypeGroups.User;
    } else {
      return null;
    }
  }

  filterUserSnapshots() {
    this.cctvSnapshotList = this.cctvSnapshotList.filter(snapshot => {
      const cctvSnapshot = snapshot as Media;
      const snapshotType = this.getSnapshotType(cctvSnapshot.media.key);
      return snapshotType === SnapshotTypeGroups.User;
    });

    if (this.cctvSnapshotList.length) {
      this.snapshotTypesSet.add(SnapshotTypeGroups.User);
      this.allSnapshotsList[SnapshotTypeGroups.User] = this.cctvSnapshotList;
    }
  }

  groupSnapshotsByType() {
    this.snapshotTypesSet.clear();
    this.allSnapshotsList = {};

    if (!this.splitIOService.isActiveFeatureToggle(AppFeatureEnum.FE_AUTO_GENERATED_SNAPSHOTS)) {
      this.filterUserSnapshots();
      return;
    }

    this.cctvSnapshotList?.forEach(snapshot => {
      const mediaSnapshot = snapshot as Media;
      const snapshotPath = mediaSnapshot.media?.key;
      const snapshotType = this.getSnapshotType(snapshotPath);
      if (!snapshotType) { return; }

      this.snapshotTypesSet.add(snapshotType);

      if (!this.allSnapshotsList[snapshotType]) {
        this.allSnapshotsList[snapshotType] = [];
      }

      this.allSnapshotsList[snapshotType].push(mediaSnapshot);
    });
  }

  renewDocFiles() {
    this.mediaItemsList.forEach(mediaItem => {
      if (!(mediaItem instanceof File) && mediaItem.media.isDocument) {
        this.renewMediaUrl = this.renewMediaUrl.bind(this.mediaApiService);
        this.renewMediaUrl(mediaItem.id)
          .pipe(take(1), filter(url => !!url) as OperatorFunction<string | undefined, string>)
          .subscribe(url => {
            mediaItem.media.url = url;
          });
      }
    });
  }

  mediaDeleted(value: Media | File) {
    if (value instanceof File) {
      const indexOfSameFileName = this.mediaApiService.pendingUploadFiles.findIndex(file => file.name === value.name);
      if (indexOfSameFileName !== -1) {
        this.mediaApiService.pendingUploadFiles.splice(indexOfSameFileName, 1);
      }

      this.mediaItemsList.splice(this.mediaItemsList.indexOf(value), 1);
      this.currentTotalFilesSize = this.currentTotalFilesSize - value.size * 0.000001;
    } else {
      this.deletedMedia.emit(value.id);
      this.currentTotalFilesSize = this.currentTotalFilesSize - this.calcMediaFileSize(value.media.fileSize);
      this.mediaItemsList = this.mediaItemsList.filter(file => (file as Media).id !== value.id);
      this.cctvSnapshotList = this.cctvSnapshotList.filter(file => (file as Media).id !== value.id);
    }

    this.groupSnapshotsByType();
    this.cdr.detectChanges();
  }

  calcCurrentTotalFilesSize(mediaList) {
    this.currentTotalFilesSize = 0;
    mediaList.forEach((_media: Media) => {
      this.currentTotalFilesSize += this.calcMediaFileSize(_media.media.fileSize);
    });
  }

  calcMediaFileSize(size) {
    const fileSize = Number(size) || 0;
    const fileSizeInMB = fileSize / 1024 / 1024; // In MB
    return fileSizeInMB;
  }

  openMediaModal(id: number) {
    const dialog = this.dialog.open(CctvSnapshotsFullScreenComponent, {
      panelClass: 'full-screen',
      maxWidth: '100%',
      data: {
        list: [...[], ...this.cctvSnapshotList],
        selectedId: id,
        isEditMode: this.isEditMode,
        renewUrlFunction: this.renewMediaUrl,
      },
    });

    this.heapService.trackUserSpecificAction('heap-snapshots-opened-gallery-mode-view');

    dialog.afterClosed().subscribe((deleted: Array<Media | File>) => {
      deleted?.forEach(media => {
        this.mediaDeleted(media);
      });
    });
  }

  showAllClicked() {
    const snapshotId = this.allSnapshotsList['created']?.[0]?.id || this.allSnapshotsList['confirmed']?.[0]?.id;
    this.openMediaModal(snapshotId);
  }
}
