import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WcChipModule } from '@wc/wc-ui/src/components/wc-chip/wc-chip.module';
import { SelectChipsComponent } from './select-chips.component';

@NgModule({
  declarations: [SelectChipsComponent],
  imports: [CommonModule, WcChipModule],

  exports: [SelectChipsComponent],
})
export class WcSelectChipsModule {}
