import { ChangeDetectorRef, Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';
import { UiStore } from '../../../../core/stores/ui.store';
import { BaseControlFieldComponent } from '../../base/base-control-field.component';
import { DateFieldComponent } from '../../form-fields-controls/date-field/date-field.component';
import { TimeFieldComponent } from '../../form-fields-controls/time-field/time-field.component';

@Component({
  selector: 'wc-date-time-control',
  templateUrl: './date-time.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeComponent),
      multi: true,
    },
  ],
  styleUrls: ['./date-time.component.scss'],
})
export class DateTimeComponent extends BaseControlFieldComponent implements OnInit {
  @ViewChild('date') dateComponent!: DateFieldComponent;
  @ViewChild('time') timeComponent!: TimeFieldComponent;

  form: FormGroup = new FormGroup({
    date: new FormControl(''),
    time: new FormControl(''),
  });

  @Input() required = true;
  @Input() timeReadOnly!: boolean;
  @Input() dateReadOnly!: boolean;
  @Input() minDate: Date | null = null;
  @Input() maxDate: Date | null = null;

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  constructor(private uiStore: UiStore, private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  timeDatefields = {
    date: {
      label: 'date',
    },
    time: {
      label: 'time',
    },
  };

  writeValue(val): void {
    if (val) {
      this.form.get('date')?.setValue(moment(val).toDate(), { emitEvent: false });
      this.form.get('time')?.setValue(moment(val).format('HH:mm'), { emitEvent: false });
    } else {
      this.form.get('date')?.setValue(null, { emitEvent: false });
      this.form.get('time')?.setValue('', { emitEvent: false });
    }
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(val => {
      this.setDate(val);
    });

    // Listen to validation status changes
    this.formControl.statusChanges.subscribe(state => {
      setTimeout(() => {
        // Set child control status based on parent validity (custom/form level validators)
        this.dateComponent.setInvalid(state === 'INVALID');
        this.timeComponent.setInvalid(state === 'INVALID');
        this.changeDetectorRef.detectChanges();
      }, 0);
    });
  }

  setDate(dateTimeValue: { time: string; date: string }) {
    // Handles the case when date-time field is empty, and this is the first change
    // Fills the default current time or date in the empty field
    if (this.formControl.value === null) {
      if (!dateTimeValue.time && dateTimeValue.date) {
        dateTimeValue.time = moment().format('HH:mm');
        this.form.get('time')?.setValue(dateTimeValue.time);
      } else if (dateTimeValue.time && !dateTimeValue.date) {
        dateTimeValue.date = moment().toISOString();
        this.form.get('date')?.setValue(moment(dateTimeValue.date).toDate());
      }
    }

    if (!dateTimeValue.time || !dateTimeValue.date) {
      this.onChanged('');
      this.handleDateTimeErrors(dateTimeValue.time, dateTimeValue.date);
    } else {
      const time = dateTimeValue.time.split(':');
      const date = moment(dateTimeValue.date);
      if (time.length === 2) {
        date.hours(Number(time[0])).minutes(Number(time[1]));
      }

      const formattedDate = date.toISOString();
      this.fieldFormControl.setValue(formattedDate);
      this.onChanged(this.fieldFormControl.value);
    }
  }

  handleDateTimeErrors(time: string, date: string) {
    // Removing both errors if not a required field
    if (!this.required && !time && !date) {
      this.form.get('time')?.setErrors(null);
      this.form.get('date')?.setErrors(null);
      this.formControl.setValue(null);
    } else {
      // Set errors for time and date
      if (!time) {
        this.form.get('time')?.setErrors({ invalid: true }, { emitEvent: true });
      }
      if (!date) {
        if (!this.form.get('date')?.hasError('invalid')) {
          this.form.get('date')?.setErrors({ required: true }, { emitEvent: true });
        }
      }

      // Set error for date time component
      this.formControl.setErrors({ invalid: true }, { emitEvent: true });
    }
  }
}
