import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { WcCheckboxModule } from '@wc/features/ui/form-fields-controls/checkbox/checkbox.module';
import { HeapClassDirectiveModule } from '@wc/wc-common/src';
import { WcTextAreaModule } from '../../../../features/ui/form-fields-controls/text-area/text-area.module';
import { CheckboxModule } from '../../form-components/checkbox/checkbox.module';
import { TextAreaModule } from '../../form-components/text-area/text-area.module';
import { WcSelectModule } from '../../form-components/wc-select/wc-select.module';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { WcLoaderComponentModule } from '../miscellaneous/loader/loader.module';
import { SliderToggleControlModule } from '../slide-toggle-control/slider-toggle-control.module';
import { CompleteRejectFormComponent } from './complete-reject-form.component';

@NgModule({
  declarations: [CompleteRejectFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    WcSelectModule,
    WcTextAreaModule,
    AutocompleteModule,
    SliderToggleControlModule,
    WcLoaderComponentModule,
    MatDialogModule,
    WcCheckboxModule,
    HeapClassDirectiveModule,
    TextAreaModule,
    CheckboxModule,
  ],
  exports: [CompleteRejectFormComponent],
})
export class CompleteRejectFormModule {}
