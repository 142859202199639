import { MapBrowserEvent } from 'ol';
import { Select } from 'ol/interaction';
import RenderFeature from 'ol/render/Feature';
import { wcSelectOptions } from '../../../types';

export class WcSelect extends Select {
  constructor(private options: wcSelectOptions) {
    super(options);
  }

  handleEvent(mapBrowserEvent: MapBrowserEvent<UIEvent>): boolean {
    if (
      mapBrowserEvent.map
        .getFeaturesAtPixel(mapBrowserEvent.pixel)
        .filter(feature => !(feature instanceof RenderFeature)).length
    ) {
      super.handleEvent(mapBrowserEvent);
      if (mapBrowserEvent.type === 'singleclick') {
        this.options.action?.(this.getFeatures().getArray()[0], mapBrowserEvent.pixel);
      }
    }

    return true;
  }
}
