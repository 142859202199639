<div class="single-settings-config-component" [class.grey-theme]="uiTheme === 'grey'">
  <ng-container [ngSwitch]="configType">
    <ng-container *ngSwitchCase="'sliders'">
      <div class="single-settings-config-component__title">
        {{ configTitle | translate }}
      </div>
      <div class="single-settings-config-component__table-col-names">
        <span> {{ 'settings.filed' | translate }} </span>
        <span> {{ 'settings.mandatory' | translate }} </span>
      </div>
      <form [formGroup]="controlFormGroup">
        <div
          data-cy-id="incident-flow-field"
          *ngFor="let controlName of formGroupControlsNames; let i = index"
          class="single-settings-config-component__setting-clickable-row"
          (click)="formGroupUIConfigs[controlName]?.expandArrow ? openSubSettingClicked(controlName) : undefined"
          [class.selected]="_selectFormControlName === controlName">
          <span>{{ 'settings.incidentFlowConfig.' + controlName | translate }} </span>
          <ng-container [ngSwitch]="controlName">
            <ng-container *ngSwitchCase="'mitigationMandatory'">
              <i
                *ngIf="parentFormGroupDirective.form.get('mitigationTypeValues')?.invalid"
                class="fas fa-exclamation-circle"></i>
            </ng-container>
            <ng-container *ngSwitchCase="'additionalInfoMandatory'">
              <i
                *ngIf="parentFormGroupDirective.form.get('additionalInfosValues')?.invalid"
                class="fas fa-exclamation-circle"></i>
            </ng-container>
            <ng-container *ngSwitchCase="'sourceMandatory'">
              <i
                *ngIf="parentFormGroupDirective.form.get('sourceValues')?.invalid"
                class="fas fa-exclamation-circle"></i>
            </ng-container>
            <ng-container *ngSwitchCase="'attributesMandatory'">
              <i
                *ngIf="parentFormGroupDirective.form.get('attributeFreeTextValues')?.invalid"
                class="fas fa-exclamation-circle"></i>
            </ng-container>
          </ng-container>
          <i class="fas fa-solid fa-circle-exclamation"></i>
          <wc-slider-toggle-control
            [tooltipText]="formGroupUIConfigs[controlName]?.tooltipInfo"
            [formControlName]="controlName"></wc-slider-toggle-control>
          <i class="fas fa-solid fa-chevron-right" *ngIf="formGroupUIConfigs[controlName]?.expandArrow"></i>
        </div>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="'checkboxes'">
      <div class="single-settings-config-component__title">
        {{ configTitle | translate }}
        <i *ngIf="configHelpSvgPath" class="fas fa-question-circle">
          <div class="single-settings-config-component__tooltip-container">
            <div class="single-settings-config-component__tooltip-container-title">
              {{ configHelpInfoText | translate }}
            </div>
            <img [src]="configHelpSvgPath" alt="" />
          </div>
        </i>
      </div>
      <div class="single-settings-config-component__table-col-names">
        <span> {{ tableColumnName | translate }} </span>
      </div>
      <form class="single-settings-config-component__checkbox-list-form" [formGroup]="parentFormGroupDirective.form">
        <wc-checkbox-list-control
          data-cy-id="checkbox-list-form"
          [highlightRowStyle]="true"
          [formFieldData]="filedData"
          [formControl]="controlFormControl"
          (arrowClicked)="openSubSettingClicked($event)">
        </wc-checkbox-list-control>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="'itemsAddRemove'">
      <div class="single-settings-config-component__title">
        {{ configTitle | translate }}
      </div>
      <div class="single-settings-config-component__table-col-names">
        <span> {{ tableColumnName | translate }} </span>
      </div>
      <form class="single-settings-config-component__items-list-form" [formGroup]="parentFormGroupDirective.form">
        <wc-free-text-list-control
          [maxLengthValidator]="60"
          [textValueKeyName]="textFiledNameForListControl"
          [subFormGroupName]="subFormGroupName"></wc-free-text-list-control>
      </form>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="single-settings-config-component__title">
        {{ configTitle | translate }}
      </div>
      <div class="single-settings-config-component__table-col-names">
        <span> {{ tableColumnName | translate }} </span>
      </div>
      <form>
        <ng-content></ng-content>
      </form>
    </ng-container>
  </ng-container>
</div>
