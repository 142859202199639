/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { tooltipAdditionalInfo, UnitResponse } from '@wc/core';
import { ActivityStatusName } from '@wc/wc-common/src/lib/utils';
import { APP_TYPE_TOKEN } from '@wc/wc-core/src/lib/injection-tokens';
import { AppTypeUnion, chipStyles, MapUnitTooltipResponses } from '@wc/wc-models';

@Component({
  selector: 'wc-map-unit-tooltip',
  templateUrl: './map-unit-tooltip.component.html',
  styleUrls: ['./map-unit-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapUnitTooltipComponent {
  @Input() isTablet = false;
  @Input() additionalInfo?: tooltipAdditionalInfo;
  @Input() set tooltipState(v: typeof UnitResponse | ActivityStatusName | MapUnitTooltipResponses) {
    this.normalizedTooltipStatus = ((v as string) || '')?.toLowerCase() as MapUnitTooltipResponses;
  }
  @Output() incidentIdClick = new EventEmitter<void>();

  @ViewChild('TEMPLATE_ONE', { static: true }) templateOne!: TemplateRef<void>;
  @ViewChild('TEMPLATE_TWO', { static: true }) templateTwo!: TemplateRef<void>;
  @ViewChild('ON_BREAK', { static: true }) onBreak!: TemplateRef<void>;

  constructor(@Inject(APP_TYPE_TOKEN) private _appMode: AppTypeUnion) {}

  get appMode() {
    return this._appMode;
  }

  readonly chipStyles = chipStyles;
  normalizedTooltipStatus: MapUnitTooltipResponses | '' = '';
  templatesByStatus = {
    [MapUnitTooltipResponses.on_scene]: 'templateOne',
    [MapUnitTooltipResponses.en_route]: 'templateOne',
    [MapUnitTooltipResponses.on_active]: 'templateTwo',
    [MapUnitTooltipResponses.on_responding]: 'templateTwo',
    [MapUnitTooltipResponses.on_break]: 'onBreak',
  };

  templateMainTextByStatus = {
    [MapUnitTooltipResponses.on_scene]: 'entityTypes.incident',
    [MapUnitTooltipResponses.en_route]: 'entityTypes.incident',
    [MapUnitTooltipResponses.on_responding]: 'responding',
    [MapUnitTooltipResponses.on_active]: 'active',
    [MapUnitTooltipResponses.on_break]: 'onBreak',
  };
  tabletMode: 'tablet-mode' | '' = '';
}
