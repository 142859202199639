import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { wcFeatureProperties } from '@wc/wc-map-viewer/src';
import { WcMapViewerService } from '@wc/wc-map-viewer/src/lib/wc-map-viewer.service';
import { wcCoordinateTypes, WcGeometry } from '@wc/wc-map-viewer/src/types';
import { CustomFormControlComponent } from '../../lib/base/custom-form-control';
import { WCErrorStateMatcher } from '../../lib/base/error-state-matcher';
import { MapGeometryEditorModalComponent } from './map-geometry-editor-modal/map-geometry-editor-modal.component';

@Component({
  selector: 'wc-map-geometry-control',
  templateUrl: './map-geometry.component.html',
  styleUrls: ['./map-geometry.component.scss'],
  providers: [WcMapViewerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapGeometryControlComponent extends CustomFormControlComponent {
  matcher: ErrorStateMatcher = new WCErrorStateMatcher();
  dialogRef!: MatDialogRef<MapGeometryEditorModalComponent, WcGeometry>;

  @Input() accountCenterCoords!: wcCoordinateTypes;
  @Input() modalHeaderLabel = '';
  @Input() modalSubmitButtonLabel = '';

  constructor(cdr: ChangeDetectorRef, @Self() public ngControl: NgControl, public dialog: MatDialog) {
    super(ngControl, cdr);
  }

  get geometryAsFeatureProps(): wcFeatureProperties<'draw'>[] {
    const value = this.ngControl.control?.value;
    if (!value) return [];
    return [
      {
        coordinates: value.coordinates,
        geomType: value.type,
        id: '0',
        entitySubType: 'draw',
        entityType: 'draw',
        isVisible: true,
        parentLayerName: 'draw',
        styleKey: '',
        entityStatusForStyle: 'draw',
        status: '',
      },
    ];
  }

  openModal() {
    this.dialogRef = this.dialog.open(MapGeometryEditorModalComponent, {
      maxWidth: '80vw',
      width: '1260px',
      disableClose: true,
      panelClass: 'settings-form-modal',
      data: {
        accountCenterCoords: this.accountCenterCoords,
        geometry: this.ngControl.control?.value,
        modalHeaderLabel: this.modalHeaderLabel,
        modalSubmitButtonLabel: this.modalSubmitButtonLabel,
      },
    });

    this.dialogRef.afterClosed().subscribe(geometry => {
      if (geometry) this.ngControl.control?.setValue(geometry?.coordinates ? geometry : null);
    });
  }
}
