<div class="route-drawing-modal" wcPopupCloser (closed)="closeModal()">
  <div class="modal-header">
    {{ modalHeaderLabel | translate }}

    <button class="close-modal-button" (click)="closeModal()">
      <i class="far fa-times"></i>
    </button>
  </div>

  <div class="modal-content">
    <wc-map-editor
      [geometry]="geometry"
      [accountCenterCoords]="accountCenterCoords"
      (changed)="geometryChanged($event)"></wc-map-editor>
  </div>

  <div class="modal-footer">
    <div class="route-control-buttons">
      <i class="fas fa-trash" (click)="clear()"></i>
    </div>
    <button class="apply-button" (click)="confirmCloseModal()">
      {{ modalSubmitButtonLabel | translate }}
    </button>
  </div>
</div>
