<div
  class="list-container"
  [ngClass]="{
    'tablet-mode': isTabletMode,
    'widget': isWidget,
    hover: context === contextTypes.Hover,
    select: context === contextTypes.Select,
    'remaining-amount': remainingLength > 0,
    'road-asset-tooltip': tooltipType === 'ROAD_ASSET',
    'roadway-status-tooltip': tooltipType === 'ROADWAY_STATUS',
    hebrew: isHebrew
  }">
  <ng-container [ngSwitch]="tooltipType">
    <ng-container *ngSwitchCase="'ROAD_ASSET'">
      <ng-template
        *ngFor="let entity of displayData"
        [ngTemplateOutlet]="ROAD_ASSET"
        [ngTemplateOutletContext]="{ $implicit: this.entity }"></ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'ROADWAY_STATUS'">
      <div class="list-container-title">
        {{ 'roadwayStatus.mainTitle' | translate }}
      </div>
      <wc-segment-details-card
        (openDetailsClicked)="_entityClicked(entity, $event)"
        *ngFor="let entity of displayData"
        [context]="context"
        [mode]="SegmentDataViewMode.Short"
        [isHebrew]="isHebrew"
        [entity]="$any(entity)">
      </wc-segment-details-card>
    </ng-container>
  </ng-container>

  <ng-container
    *ngIf="remainingLength > 0 && context === contextTypes.Select"
    [ngTemplateOutlet]="select_remaining"></ng-container>
</div>
<div
  class="remaining-length hover"
  [ngClass]="{
    'tablet-mode': isTabletMode,
    hebrew: isHebrew,
    'realtime-analytics-tooltip': tooltipType === 'RT_ANALYTICS'
  }"
  *ngIf="remainingLength > 0 && context === 'hover'">
  {{ 'clusteringPopup.remainingItemsOnHover' | translate : { amount: remainingLength } }}
</div>

<ng-template let-data, let-entity #ROAD_ASSET [contextType]="displayData[0]">
  <div class="road-asset" (click)="_entityClicked(entity)">
    <wc-icon [iconName]="entity.type || entity.layerType | lowercase"></wc-icon>
    <span class="road-asset__title"> #{{ entity.title }} </span>
    <wc-chip
      [isClickable]="true"
      [style]="entity.status"
      [text]="entity.status | uppercase"
      [isTablet]="isTabletMode"></wc-chip>
  </div>
</ng-template>

<ng-template #select_remaining>
  <div class="remaining-length select">
    <div>{{ 'clusteringPopup.remainingItemsOnSelect.upperLine' | translate : { amount: remainingLength } }}</div>
    <div>{{ 'clusteringPopup.remainingItemsOnSelect.lowerLine' | translate }}</div>
  </div>
</ng-template>
