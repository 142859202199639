import { Coordinate } from 'ol/coordinate';
import {
  Address,
  BreakDetails,
  IncidentDetails,
  LanesClosureType,
  LayerType,
  UnitActivityStatus,
  UnitDashCamera,
  UserDetails,
} from './gql.models';
import { LineString, MultiLineString, MultiPolygon, Point, Polygon } from './models';

// import {GeoDetails} from './GeoDetails.interface';

// export enum LiveMapEntityTypeEnum {
//     interactive = 'interactive',
//     police = 'police',
//     camera = 'camera',
//     dms = 'dms',
//     congestion = 'congestion'
// }
export enum MainEntityType {
  'interactive' = 'interactive',
  'incident' = 'incident',
  'traffic_disruption' = 'traffic_disruption',
  'work_request' = 'work_request',
  'road_closure' = 'road_closure',
  'asset' = 'asset',
  'unit' = 'unit',
  'congestion' = 'congestion',
  'irregular_congestion' = 'irregular_congestion',
  'construction' = 'construction',
  'weather_alert' = 'weather_alert',
  'crash_risk_area' = 'crash_risk_area',
  'special_event' = 'special_event',
}

export enum FeatureSubType {
  'RoadClosure' = 'ROAD_CLOSURE',
  'CctvMaintenance' = 'CCTV_MAINTENANCE',
  'Concert' = 'CONCERT',
  'Interactive' = 'interactive',
}

export type LiveMapEntityType =
  | 'segment_temp'
  | 'traffic'
  | 'satellite'
  | 'mile_markers'
  | 'workspace'
  | 'interactive'
  | 'draw'
  | 'buses'
  | 'snowplows'
  | 'maintenance'
  | 'camera'
  | 'dms'
  | 'crash'
  | 'debris'
  | 'stalled_vehicle'
  | 'abandoned_vehicle'
  | 'other'
  | 'hazard'
  | 'left_on_arrival'
  | 'vehicle_on_fire'
  | 'police_activity'
  | 'unidentified'
  | 'damage'
  | 'ems'
  | 'traffic_stop'
  | 'wrong_way'
  | 'road_closure'
  | 'construction'
  | 'special_event'
  | 'congestion'
  | 'irregular_congestion'
  | 'crash_risk_area_active'
  | 'crash_risk_area_predicted'
  | 'unknown_incident_type'
  | 'city_police_unit'
  | 'construction_unit'
  | 'ems_unit'
  | 'fire_department_unit'
  | 'fsp_unit'
  | 'highway_police_unit'
  | 'maintenance_unit'
  | 'other_unit'
  | 'snow_plow_unit'
  | 'street_sweep_unit'
  | 'weather_alert'
  | 'traffic_anomaly'

  // Transit
  | 'transit_on_demand_unit'
  | 'transit_on_demand_unit_my_agency'
  | 'transit_demand_response_unit'
  | 'transit_demand_response_unit_my_agency'
  | 'transit_fixed_routes'
  | 'transit_fixed_routes_my_agency'
  | 'transit_fixed_stops'
  | 'transit_fixed_stops_my_agency'
  | 'transit_fixed_route_bus_unit'
  | 'transit_fixed_route_bus_unit_my_agency'

  // Weather alerts
  | 'air'
  | 'fire'
  | 'floods'
  | 'fog'
  | 'marine'
  | 'specific_road_conditions'
  | 'temperature'
  | 'thunderstorms'
  | 'tornado'
  | 'tropical'
  | 'wind'
  | 'winter';

export type LiveMapEntityState =
  | 'default'
  | 'editMode'
  | 'active'
  | 'inactive'
  | 'cluster'
  | 'confirmed'
  | 'unconfirmed'
  | 'rejected'
  | 'completed'
  | 'modify_geometry'
  | 'view_geometry'
  | 'modify_geometry_pointer'
  | 'modify_pointer'
  | 'view_pointer'
  | 'planned'
  | 'onbreak'
  | 'predicted'
  | 'unassigned'
  | 'on_time'
  | 'early'
  | 'late'
  | 'busy'
  | 'my_agency';

export type LiveMapEntityUIState = 'default' | 'disabled' | 'hover' | 'selected';

export interface LiveMapEntity {
  address?: Address;
  id: number;
  accountId?: number;
  nearCameras?: boolean;
  displayId?: string;
  endTime?: string;
  name?: string;
  externalId?: string;
  lanesClosureType?: LanesClosureType;
  location: Point | Polygon | LineString | MultiLineString | MultiPolygon;
  featureType: string; //LiveMapEntityType;
  featureSubTypeOf: FeatureSubType | MainEntityType;
  startTime?: string;
  activityStatuses?: UnitActivityStatus[];
  status: string; //LiveMapEntityState
  title?: string;
  isBusy?: boolean;
  messages?: [][];
  lastUpdated?: Date;
  userDetails?: UserDetails;
  hovered?: boolean;
  selected?: boolean;
  editMode?: boolean;
  geometryEdit?: boolean;
  type?: string;
  otherTypeDescription?: string;
  breakDetails?: BreakDetails;
  startAddress?: Address;
  endAddress?: Address;
  assignedUser?: any;
  appended?: boolean; // for Main cad incident
  number?: string;
  relatedRoutes?: [{ number: string; color: string; routeId: number }];
  timeCheckPoints?: [{ routeId: number; routeName: string }];
  accountName?: string;
  routeNumber?: string;
  patternName?: string;
  nextStopName?: string;
  eta?: number;
  statusInMinutes?: number;
  isMyAgency?: boolean;
  assignedUserName?: string;
  workspaces?: number[];
  associatedUnitsUsersIds?: number[];
  isEmergency?: boolean;
  path?: { coordinates: Coordinate[] };
  geometry?: { type: string; coordinates: Coordinate[]; getType };
  layerType?: LayerType;
  incidentDetails?: IncidentDetails;
  bearing?: number;
  subType?: string;
  routeNames?: string[];
  mitigatedAccounts?: number[];
  mitigatedByMyAccount?: boolean;
  cameras?: Array<Partial<UnitDashCamera>>;
  color?: string;
  rerouted?: boolean;
  venueName?: string;
}

export interface MapEntityFilters {
  key: string;
  value: any;
  include: boolean;
}
