import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { TextFieldControl } from './text-field.component';

const Materials: any[] = [MatInputModule, MatIconModule];

@NgModule({
  declarations: [TextFieldControl],
  imports: [CommonModule, WcBaseControlFieldModule, Materials],
  providers: [],
  exports: [TextFieldControl],
})
export class WcTextFiledModule {}
