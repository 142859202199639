import { Pipe, PipeTransform } from '@angular/core';
import { TrafficDisruptionType } from '@wc/wc-models/src';
import { SortOptions } from '../../../core/models/enums';
import { ConstructionType, SpecialEventType } from '../../../core/models/gql.models';
import { LiveMapEntity } from '../../../core/models/LiveMapEntity.type';
import { ObjSortPipeInterface } from '../pipes/obj-sort.pipe';

@Pipe({
  name: 'trafficDisruptionsSort',
  pure: true,
})
export class TrafficDisruptionsSortPipe implements PipeTransform {
  disruptionsTypeOrder = [
    ConstructionType.AtmsInstallation,
    ConstructionType.BikeLanes,
    ConstructionType.CctvInstallation,
    ConstructionType.CctvMaintenance,
    ConstructionType.DitchCleaning,
    ConstructionType.DmsInstallation,
    ConstructionType.DmsMaintenance,
    ConstructionType.Drainage,
    ConstructionType.Landscaping,
    ConstructionType.Lighting,
    ConstructionType.NewRoad,
    ConstructionType.Other,
    ConstructionType.Paving,
    ConstructionType.Pothole,
    ConstructionType.RoadwaySweeping,
    ConstructionType.RoadMarking,
    ConstructionType.RoadSafetyBarriers,
    ConstructionType.SensorInstallation,
    ConstructionType.SensorMaintenance,
    ConstructionType.ServiceRoads,
    ConstructionType.SewerPipeReplacement,
    ConstructionType.SidewalkRepair,
    ConstructionType.TrafficSignalAllWayFlash,
    ConstructionType.TrafficSignalPowerOutage,
    ConstructionType.TreeTrimming,
    SpecialEventType.Concert,
    SpecialEventType.Conference,
    SpecialEventType.Festival,
    SpecialEventType.Marathon,
    SpecialEventType.Parade,
    SpecialEventType.Protest,
    SpecialEventType.Racing,
    SpecialEventType.Sport,
    TrafficDisruptionType.RoadClosure,
  ];

  transform<T>(trafficDisruptions: LiveMapEntity[], args: ObjSortPipeInterface): T[] {
    let newOrderedDisruptions: LiveMapEntity[] = [];
    switch (args.field as SortOptions) {
      case SortOptions.Type:
        this.disruptionsTypeOrder.forEach(disruptionType => {
          const disruptionsOfType: LiveMapEntity[] = trafficDisruptions.filter((disruption: LiveMapEntity) => {
            if (disruption.type) {
              return disruption.type.toLocaleLowerCase() === disruptionType.toLocaleLowerCase();
            } else {
              return (
                (disruption.featureType || disruption.layerType)?.toLocaleLowerCase() ===
                disruptionType.toLocaleLowerCase()
              );
            }
          });
          newOrderedDisruptions = [...newOrderedDisruptions, ...disruptionsOfType];
        });

        return newOrderedDisruptions as any;

      case SortOptions.TimeOldFirst:
        newOrderedDisruptions = trafficDisruptions.sort((a, b) => {
          return new Date(a.startTime as string).getTime() - new Date(b.startTime as string).getTime();
        });
        return [...[], ...newOrderedDisruptions] as any;

      default:
      case SortOptions.TimeNewFirst:
        newOrderedDisruptions = trafficDisruptions.sort((a, b) => {
          return new Date(b.startTime as string).getTime() - new Date(a.startTime as string).getTime();
        });
        return [...[], ...newOrderedDisruptions] as any;
    }
  }
}
