import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountService, TransitService } from '@wc-core';
import { TrainDelay, TrainRoute } from '@wc/wc-models/src/lib/types/transit';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

const TRAIN_DELAYS = 'train_delays';

interface TrainDelayUpdate {
  type: typeof TRAIN_DELAYS;
  stationName: string;
  trainDelays: TrainDelay[];
}

@Component({
  selector: 'wc-train-delays',
  templateUrl: './train-delays.component.html',
  styleUrls: ['./train-delays.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainDelaysComponent {
  @Output() trainDelaysPanelOpened: EventEmitter<boolean> = new EventEmitter();
  @Input() isTrainDelaysPanelOpened = false;

  get isTrainDelaysEmpty$(): Observable<boolean> {
    return this.trainDelaysCount$.pipe(map(amount => amount === 0));
  }

  get trainDelays$(): Observable<TrainDelay[]> {
    return this.transitService.trainDelays$.pipe(startWith([]));
  }

  get trainDelaysCount$(): Observable<number> {
    return this.trainDelays$.pipe(
      map(trainDelays =>
        trainDelays.reduce((counter, lateArrival) => counter + lateArrival.routes.reduce(prev => ++prev, 0), 0)
      )
    );
  }

  get timeFormat() {
    return this.accountService.account.regionalSetting.timeFormat;
  }

  constructor(
    private readonly translateService: TranslateService,
    public transitService: TransitService,
    private accountService: AccountService
  ) {}

  routeDelay(delay: TrainRoute['delayInMinutes']) {
    const hours = Math.floor(delay / 60);
    const minutes = Math.floor(delay % 60);

    return (
      (hours > 9 ? hours : '0' + hours) +
      ':' +
      (minutes > 9 ? minutes : '0' + minutes) +
      ' ' +
      this.translateService.instant('trainDelays.hours')
    );
  }
}
