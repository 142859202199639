import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WcTooltipModule } from '../tooltip/tooltip.module';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { SelectMultiBoxesComponent } from './select-multi-boxes.component';
@NgModule({
  declarations: [SelectMultiBoxesComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, WcIconModule, WcTooltipModule],
  providers: [],
  exports: [SelectMultiBoxesComponent],
})
export class SelectMultiBoxesComponentModule {}
