import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { WcBaseControlFieldModule } from '../../../../features/ui/base/base-control-field.module';
import { TimeFieldComponent } from './time-field.component';

const Materials: any[] = [MatMenuModule, MatInputModule];

@NgModule({
  declarations: [TimeFieldComponent],
  imports: [CommonModule, WcBaseControlFieldModule, SwiperModule, Materials],
  providers: [FormControl],
  exports: [TimeFieldComponent],
})
export class WcTimeFieldModule {}
