<div class="new-incident-location" [class.tablet-mode]="isTabletMode">
  <div class="address-modal-title bold-title hide-in-landscape">
    {{ 'createNewIncident' | translate }}
  </div>

  <wc-address-control
    [isIncidentEntity]="true"
    (addressError)="onAddressError($event)"
    (addressLoading)="onAddressLoading($event)"
    [showJurisdiction]="true"
    [formControl]="$any(address)"
    [disableFields]="isOutsideJurisdiction"
    [showLoader]="true"
    [showMap]="true">
  </wc-address-control>

  <div fxFlex="auto"></div>

  <div fxLayout="row">
    <button class="mat-flat-button cancel-button" variant="text" icon="fas fa-times" (click)="cancel()">
      <mat-icon>
        <i class="fas fa-times"></i>
      </mat-icon>
      {{ 'confirmModal.cancelButton' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button
      class="mat-stroked-button continue-edit-button"
      [disabled]="this.isOutsideJurisdiction || this.address.invalid || this.disableButtons"
      variant="outlined"
      (click)="continueEdit()">
      {{ 'continueEdit' | translate }}
    </button>
    <button
      [isOneTimeClick]="!(this.isOutsideJurisdiction || this.address.invalid || this.disableButtons)"
      class="primary-button create-as-draft-button min-width"
      (click)="createAsDraft()"
      [disabled]="this.isOutsideJurisdiction || this.address.invalid || this.disableButtons">
      {{ 'quickCreate' | translate }}
    </button>
  </div>
</div>
