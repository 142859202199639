import { InjectionToken } from '@angular/core';
import { ScopeAccessModifier } from '@wc/wc-models/src/lib/types/utility-types';
import Fill from 'ol/style/Fill';
import { Workspace } from '../models/gql.models';
import { AppFeatureEnum } from '../services/split-io.service';
import { LiveMapEntityState, LiveMapEntityType, LiveMapEntityUIState } from './LiveMapEntity.type';
export interface IconStyle {
  src?: string;
  anchor?: Array<number>;
  scale?: number;
  rotation?: number;
  opacity?: number;
  iconName?: string;
  zIndex?: number;
  srcByEntityProperty?: string; // example {iconName: '.hovered' , srcByEntityProperty:'crash'} -> in style iconName will be merged to crash.hovered
  disableRotation?: boolean;
}

export type MapLayer = {
  zIndex?: number;
  hitTolerance?: number;
  clusterDistance?: number;
  clusterIcon?: IconStyle;
  visible?: boolean;
  startPointStatusStyle?: {
    [state in LiveMapEntityState]?: {
      [uiState in LiveMapEntityUIState]?: Array<EntityStatusStyle>;
    };
  };
  endPointStatusStyle?: {
    [state in LiveMapEntityState]?: {
      [uiState in LiveMapEntityUIState]?: Array<EntityStatusStyle>;
    };
  };
  pointStatusStyle?: {
    [state in LiveMapEntityState]?: {
      [uiState in LiveMapEntityUIState]?: Array<EntityStatusStyle>;
    };
  };
  lineStringStatusStyle?: {
    [state in LiveMapEntityState]?: {
      [uiState in LiveMapEntityUIState]?: Array<EntityStatusStyle>;
    };
  };
  multiLineStringStatusStyle?: {
    [state in LiveMapEntityState]?: {
      [uiState in LiveMapEntityUIState]?: Array<EntityStatusStyle>;
    };
  };
  polygonStatusStyle?: {
    [state in LiveMapEntityState]?: {
      [uiState in LiveMapEntityUIState]?: Array<EntityStatusStyle>;
    };
  };
  startPointPolygonStyle?: {
    [state in LiveMapEntityState]?: {
      [uiState in LiveMapEntityUIState]?: Array<EntityStatusStyle>;
    };
  };
};

export type EntityShapeType = 'marker' | 'lineSolid' | 'lineDash' | 'area' | 'circle' | 'circleIcon' | 'label';
// export enum EntityShapeType {
//     'marker' = 'MARKER',
//     'lineSolid' = 'LINE_SOLID',
//     'lineDash' = 'LINE_DASH',
//     'area' = 'AREA',
//     'circle' = 'CIRCLE'
// }
export interface TextStyle {
  textSrcField?: string;
  color?: string;
  darkColor?: string;
  textAlign?: 'left' | 'right' | 'center' | 'end' | 'start';
  textBaseline?: 'bottom' | 'top' | 'middle' | 'alphabetic' | 'hanging' | 'ideographic';
  fontFamily?: string;
  text?: string;
  fill?: Fill;
  offsetX?: number;
  offsetY?: number;
  placement?: 'point' | 'line';
  maxAngle?: number;
  overflow?: boolean;
  rotation?: number;
  width?: number;
  height?: number;
  size?: number;
  weight?: number;
  outlineColor?: string;
  darkOutlineColor?: string;
  outlineWidth?: number;
}
export interface EntityStatusStyle {
  shape: EntityShapeType;
  zIndex?: number;
  icon?: IconStyle;
  color?: string;
  darkColor?: string;
  width?: number;
  radius?: number;
  opacity?: number;
  stroke?: {
    color: string;
    darkColor?: string;
    width: number;
    opacity?: number;
  };
  dash?: [number, number, number, number];
  label?: TextStyle;
}

export const WcMapConfigToken = new InjectionToken<WcMapConfigModel>('WcMapConfigToken');
export type WcMapConfigModel = {
  tilesSrc: string;
  style: { day: string; night: string };
  currentStyle: 'day' | 'night';
  backgroundColor: string;
  backgroundDarkColor: string;
  mapCenter: number[][][];
  centerOptions: {
    duration?: number;
    padding?: Array<number>;
    bufferSize?: number;
    maxZoom?: number;
  };
  defaultIconStyle: IconStyle;
  defaultClusterIconStyle?: IconStyle;
  defaultLayersVisibility: Array<LiveMapEntityType>;
  tablet: { iconScale: number };
  layers: { [layerName in LiveMapEntityType]?: MapLayer };
  defaultLayerZIndex?: number;
  workspaces?: Workspace[];
  isTabletMode?: boolean;
  appendedIconStyle?: EntityStatusStyle;
};

export interface MapCenterOptions {
  dontKeepLast?: boolean;
  duration?: number;
  padding?: Array<number>;
  bufferSize?: number;
  zoomLevel?: number;
  maxZoom?: number;
}

export type DataLayerName = 'traffic' | 'satellite' | any;

export interface MapLayerTreeMenu {
  groupName: string;
  layersGroups: {
    appFeatureName?: AppFeatureEnum | ScopeAccessModifier[];
    name: string;
    layers?: LiveMapEntityType[];
  }[];
}
