<div
  class="affected-lanes-container"
  [class.tablet-mode]="isTabletMode"
  [class.language-hebrew]="uiStore.isHebrew"
  *ngIf="isAffectedLanesFound; else noLanes">
  <div class="affected-lanes-header">
    <div class="details-item affectedDirections">
      <div class="title">{{ 'affectedDirectionsLabel' | translate }}</div>
      <div class="content">
        {{
          (!multiDirectionLanesAffecteds
            ? 'affectedDirections.SINGLE_DIRECTION'
            : 'affectedDirections.MULTIPLE_DIRECTION'
          ) | translate
        }}
      </div>
    </div>

    <div class="details-item status">
      <div class="title">{{ 'status' | translate }}</div>
      <div class="content">
        {{
          allLanesAffected
            ? ('lanesStatuses.allLanesAreAffected' | translate)
            : ('lanesStatuses.partialyAffected' | translate)
        }}
      </div>
    </div>
  </div>
  <div class="affected-lanes-border-line"></div>
  <wc-affected-lanes-diagram
    *ngIf="!multiDirectionLanesAffecteds"
    class="affected-lanes-component"
    [lanes]="affectedLanes"
    [hovered]="hoverLaneIndex"
    [isViewMode]="true"
    (laneHovered)="laneHovered($event)">
  </wc-affected-lanes-diagram>

  <div data-cy-id="affected-lanes-slider" class="affected-lanes-slider">
    <div
      data-cy-id="swiper-affected-lanes-component"
      class="swiper-component swiper-lanes-view-component"
      [class.tablet-mode]="isTabletMode">
      <div
        #swiperId
        class="swiper-container"
        [swiper]="config"
        [class.one-lane-container]="affectedLaneSwiper.length === 1">
        <div class="swiper-wrapper">
          <div
            data-cy-id="affected-lanes-slider-lane"
            class="swiper-slide affected-lanes-slider-lane"
            [class.view-only-user]="!userStore.hasPermission('INCIDENT:CREATE')"
            *ngFor="let swiperLane of affectedLaneSwiper; let i = index"
            [class.hover]="swiperLane.lane.positionIndex === hoverLaneIndex"
            (mouseover)="laneHovered(swiperLane.lane)"
            (mouseleave)="laneHovered(null)">
            <div data-cy-id="affected-lanes-slider-header" class="affected-lanes-slider-header">
              <span
                data-cy-id="affected-lanes-title"
                class="affected-lanes-title"
                *ngIf="!TextualLanes.includes(swiperLane.lane.type); else showType">
                {{ 'Lane' | translate }} {{ swiperLane.lane.number }}
              </span>
              <ng-template #showType>
                <span class="affected-lanes-title">
                  {{ 'laneTypes.' + swiperLane.lane.type | translate }}
                </span>
              </ng-template>
            </div>
            <div class="affected-lanes-content">
              <div class="affected-lanes-type-wrapper">
                <div class="affected-lanes-data type">
                  <div class="title">{{ 'laneType' | translate }}</div>
                  <div class="content">
                    {{ 'laneTypes.' + swiperLane.lane.type | translate }}
                  </div>
                </div>
                <div class="affected-lanes-data direction">
                  <div class="title">{{ 'direction' | translate }}</div>
                  <div class="content">
                    {{ swiperLane.lane.direction || '-' }}
                  </div>
                </div>
                <div class="affected-lanes-data roadType">
                  <div class="title">{{ 'roadType' | translate }}</div>
                  <div class="content">
                    {{ 'roadTypes.' + swiperLane.lane.roadType | translate }}
                  </div>
                </div>
              </div>
              <div class="affected-lanes-slider-toggle">
                <span class="toggle-option" [class.open]="swiperLane.lane.isClosed === false">
                  {{ 'affectedLanesToggleOptions.open' | translate }}
                </span>
                <wc-slider-toggle-control
                  data-cy-id="affected-lanes-slider-toggle-button"
                  [formControl]="lanesToggels?.controls[swiperLane.index]"
                  (click)="updateLaneStatus(swiperLane.index)"></wc-slider-toggle-control>
                <span class="toggle-option" [class.closed]="swiperLane.lane.isClosed">
                  {{ 'affectedLanesToggleOptions.close' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="lanes-swiper-pagination swiper-pagination" *ngIf="affectedLanes?.length > 3"></div>
      </div>

      <div
        data-cy-id="swiper-pagination-affected-lanes-previous"
        class="lanes-swiper-button-prev swiper-button-prev"></div>
      <div data-cy-id="swiper-pagination-affected-lanes-next" class="lanes-swiper-button-next swiper-button-next"></div>
    </div>
  </div>
</div>

<ng-template #noLanes>
  <div
    class="no-lanes-container"
    [class.language-hebrew]="uiStore.isHebrew"
    [class.edit]="mode === 'edit'"
    [class.tablet-mode]="isTabletMode">
    <i class="no-lanes-image"></i>
    <div class="no-lanes-title">
      {{ 'noAffectedLanes' | translate }}
    </div>
    <div class="no-lanes-msg">
      {{ 'noAffectedLanesMsg' | translate }}
    </div>
  </div>
</ng-template>
