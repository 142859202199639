<div *ngIf="units.length > 0; else noUnits">
  <div class="associated-units-slider">
    <div
      class="associated-units-add-button"
      mat-button
      (click)="addUnit(); $event.preventDefault()"
      [disabled]="this.formControl.invalid">
      <i class="fas fa-plus"></i>
      <span class="associated-units-add-button-label">{{ 'add' | translate }}</span>
    </div>

    <div class="swiper-component" [formGroup]="unitsForm" [class.tablet-mode]="isTabletMode">
      <div class="swiper-container" [swiper]="config">
        <div class="swiper-wrapper" formArrayName="units">
          <div
            class="swiper-slide associated-unit-slide"
            *ngFor="let unit of units.controls; let i = index"
            [formGroupName]="i">
            <wc-associated-unit-control
              [formControl]="$any(unit)"
              [unitVehicles]="unitVehicles"
              [index]="i"
              (delete)="deleteUnit($event, unit)">
            </wc-associated-unit-control>
          </div>
        </div>

        <div class="swiper-pagination-associated-units swiper-pagination"></div>
      </div>

      <div class="swiper-button-prev associated-units-prev"></div>
      <div class="swiper-button-next associated-units-next"></div>
    </div>
  </div>
</div>

<ng-template #noUnits>
  <div class="no-associated-units-container">
    <div
      class="associated-units-add-button"
      data-cy-id="associated-units-add-btn"
      (click)="addUnit(); $event.preventDefault()">
      <i class="fas fa-plus"></i>
      <span class="associated-units-add-button-label">{{ 'add' | translate }}</span>
    </div>
    <div class="empty-state-container">
      <i class="empty-units-icon"></i>
      <div class="empty-state-message">
        {{ 'emptyStates.noUnitsTitle' | translate }}
      </div>
      <div class="empty-state-action">
        {{ 'emptyStates.noUnitsMessage' | translate }}
      </div>
    </div>
  </div>
</ng-template>
