<span
  *ngIf="persistenceScoreType"
  [attr.dat-cy-id]="'persistence-score-' + persistenceScoreType"
  matTooltipHideDelay="400"
  matTooltipShowDelay="400"
  [matTooltipDisabled]="disableTooltip || !persistenceScoreType"
  matTooltipPosition="above"
  [matTooltip]="
    (appType === 'widget' ? 'insight.persistenceTooltip.shortTitle' : 'insight.persistenceTooltip.title')
      | translate: { type: 'insight.persistenceChance.' + persistenceScoreType | translate }
  "
  matTooltipClass="persistence-level-tooltip"
  class="wc-persistence-score wc-persistence-score__persistence"
  [class]="size"
  [ngClass]="'wc-persistence-score__persistence--' + persistenceScoreType">
  {{
    (textMode === 'short' ? 'insight.persistence.' : 'insight.persistenceChance.') + persistenceScoreType | translate
  }}
</span>
