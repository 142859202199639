// TODO: Should be imported from GQL when it will be ready
export enum TrafficDisruptionType {
  RoadClosure = 'ROAD_CLOSURE',
  Construction = 'CONSTRUCTION',
  SpecialEvent = 'SPECIAL_EVENT',
}

export const layerTypeToTranslationPath = {
  [TrafficDisruptionType.Construction]: 'construction',
  [TrafficDisruptionType.RoadClosure]: 'roadClosure',
  [TrafficDisruptionType.SpecialEvent]: 'specialEventType',
};
