import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { StreetCamera } from '@wc/core/models/gql.models';
import { cloneDeep, isArray } from 'lodash';

const initialViewAmount = 3;

type GalleryCameraObj = Partial<StreetCamera> & { isMain?: boolean };

@Component({
  selector: 'wc-camera-gallery',
  templateUrl: './camera-gallery.component.html',
  styleUrls: ['./camera-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraGalleryComponent {
  camerasList: GalleryCameraObj[] = [];
  camerasListFiltered: GalleryCameraObj[] = [];

  @Output() cameraSelected: EventEmitter<StreetCamera> = new EventEmitter();
  @Input() selectedCameraId?: number;
  @Input() set galleryData(value: { cameras: StreetCamera[]; defaultCamera: StreetCamera | null }) {
    if (!isArray(value.cameras)) return;
    this.camerasList = cloneDeep(value.cameras);

    if (value.defaultCamera) {
      const mainCam = this.findAndExtractMainCamera({ cameras: this.camerasList, defaultCamera: value.defaultCamera });
      if (mainCam) {
        mainCam.isMain = true;
        this.camerasList = [mainCam, ...this.camerasList];
      }
    }
    this.camerasListFiltered = this.camerasList.slice(0, initialViewAmount);
  }

  toggleDisplayedAmount() {
    if (this.camerasListFiltered.length === initialViewAmount) {
      this.camerasListFiltered = this.camerasList;
    } else {
      this.camerasListFiltered = this.camerasList.slice(0, initialViewAmount);
    }
  }

  selectCamera(camera: GalleryCameraObj) {
    this.selectedCameraId = camera.id;
    this.cameraSelected.emit(camera as StreetCamera);
  }

  private findAndExtractMainCamera(value: {
    cameras: GalleryCameraObj[];
    defaultCamera: StreetCamera | null;
  }): GalleryCameraObj | undefined {
    return value.cameras.splice(
      value.cameras.findIndex(({ id }) => id === value.defaultCamera?.id),
      1
    )?.[0];
  }
}
