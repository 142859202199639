import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AutocompleteV2Component } from '@wc/wc-ui/src/form-components/autocomplete-v2/autocomplete-v2.component';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { FormatsPipesModule } from '../../pipes/formats.pipes.module';
import { SegmentAutocompleteV2Component } from './segment-autocomplete-v2.component';

const Materials: any[] = [MatSelectModule, MatInputModule, MatIconModule, MatButtonModule];

@NgModule({
  declarations: [SegmentAutocompleteV2Component],
  imports: [CommonModule, WcBaseControlFieldModule, Materials, FormatsPipesModule],
  providers: [AutocompleteV2Component],
  exports: [SegmentAutocompleteV2Component],
})
export class SegmentAutocompleteV2Module {}
