import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { EntitiesQuery, LiveMapService } from '@wc-core';
import { AffectingEventDetails, LayerType, TransitAffectingEntityType } from '@wc/core';
import { of } from 'rxjs';
import { SelectOperationsEnum, entityTypeConfig } from '../../../map-viewers/live-map-viewer/live-map-viewer.config';

@Component({
  selector: 'wc-affected-service-cause',
  templateUrl: './affected-service-cause.component.html',
  styleUrls: ['../transit-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AffectedServiceCauseComponent implements OnDestroy {
  @Input() affectingEvents!: (AffectingEventDetails & { additionalInfo: any })[] | undefined;
  selectedCauseId: number | null = null;
  isAlive = true;
  layerType!: LayerType;
  get affectingEntityType() {
    return TransitAffectingEntityType;
  }

  constructor(private entitiesQuery: EntitiesQuery, private liveMapService: LiveMapService) {}

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  clickOnAffectedCause(cause: AffectingEventDetails) {
    if (this.liveMapService.selectedFeatureId) {
      this.liveMapService.unselectFeature();
    }

    this.selectedCauseId = cause.affectingEventId;
    this.layerType = cause.affectingEntityType as unknown as LayerType;

    const liveMapEntity = this.entitiesQuery.getEntityByIDAndEntityType(
      cause.affectingEventId,
      cause.affectingEntityType as unknown as LayerType
    );

    if (liveMapEntity) {
      of(this.liveMapService.setEntityLayerAsVisible(cause.affectingEntityType as unknown as LayerType)).subscribe(
        () => {
          if (entityTypeConfig[this.layerType]?.selectType === SelectOperationsEnum.modal) {
            this.liveMapService.selectFeatureAndOverlay(this.layerType, cause.affectingEventId);
          } else {
            this.liveMapService.selectFeature(this.layerType, cause.affectingEventId);
          }
        }
      );
    } else {
      console.error('Transit affected service clicked - event not exist', cause);
    }
  }
}
