import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { FormatsPipesModule, WcLetModule } from '@wc/wc-common/pipes';
import { SliderToggleControlModule } from '@wc/wc-ui/src/components/slide-toggle-control/slider-toggle-control.module';
import { WcIconModule } from '@wc/wc-ui/src/components/wc-icon/wc-icon.module';
import { AffectedTransitServicesComponent } from './affected-transit-services.component';

@NgModule({
  declarations: [AffectedTransitServicesComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    TranslateModule,
    SliderToggleControlModule,
    MatSlideToggleModule,
    FormatsPipesModule,
    WcLetModule,
    WcIconModule,
  ],
  exports: [AffectedTransitServicesComponent],
})
export class AffectedServicesTransitModule {}
