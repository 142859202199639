<div class="file-drop-component">
  <div class="file-drop-component__container">
    <ngx-file-drop
      *ngIf="!isTabletMode; else tabletMode"
      [accept]="acceptMimeTypes"
      (onFileDrop)="onDroppedFile($event)"
      dropZoneClassName="file-drop-style">
      <ng-container *ngIf="!isOneFileLimit || (isOneFileLimit && files.length === 0); else singleFileMode">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <div class="drop-here-row">
            <span> {{ 'dropImage' | translate }}</span>
            <a id="browse-files-btn" rekor-link (click)="openFileSelector()">{{ 'uploadFile' | translate }}</a>
          </div>
          <div *ngIf="!isOneFileLimit" [class.tablet-mode]="isTabletMode">
            <span class="bold-text">{{ currentFilesSize | number: '1.2-2' }}/{{ maxTotalFileSize }} </span>
            <span> MB {{ 'mediaGallery.left' | translate }} </span>
          </div>
          <div class="supported-file-list" *ngIf="isOneFileLimit">
            {{ supportedFileTypes.join(', ') | uppercase }}
          </div>
          <div>{{ 'maxFileSize' | translate: { max: maxFileSize } }}MB</div>
        </ng-template>
      </ng-container>

      <ng-template #singleFileMode ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div class="file-bold-title">{{ files[0].name | translate }}</div>
        <div class="file-drop-component__drop-remain-space file-drop-component__drop-remain-space--bold">
          {{ currentFilesSize | number: '1.2-2' }} MB
        </div>
        <a rekor-link (click)="openFileSelector()">{{ 'replaceImage' | translate }}</a>
      </ng-template>
    </ngx-file-drop>
  </div>
</div>

<ng-template #tabletMode>
  <div class="file-drop-component__tablet-upload">
    <label for="take-photo-input">
      <i class="fas fa-solid fa-camera"></i>
      <div>{{ 'takePhoto' | translate }}</div>
      <input
        id="take-photo-input"
        type="file"
        style="display: none"
        accept="image/*"
        capture
        (change)="onFileUploaded($event)" />
    </label>

    <label for="upload-file-input">
      <i class="fas fa-file-upload"></i>
      <div>{{ 'mediaGallery.upload' | translate }}</div>
      <input
        id="upload-file-input"
        type="file"
        style="display: none"
        [accept]="acceptMimeTypes"
        multiple
        (change)="onFileUploaded($event)" />
    </label>
  </div>
  <ng-container *ngTemplateOutlet="remainPlace"></ng-container>
</ng-template>

<ng-template #remainPlace>
  <div class="drop-remain-space" [class.tablet-mode]="isTabletMode">
    <div>{{ currentFilesSize | number: '1.2-2' }}/{{ maxTotalFileSize }}MB {{ 'mediaGallery.left' | translate }}</div>
    <div>{{ 'maxFileSize' | translate: { max: maxFileSize } }}MB</div>
  </div>
</ng-template>
