import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WcAutocompleteV2Module } from '@wc/wc-ui/src/form-components/autocomplete-v2/autocomplete-v2.module';
import { IsOneTimeClickModule } from '../../directives/is-one-time-click.module';
import { MitigationComponentModule } from '../../form-controls/mitigation/mitigation-component.module';
import { MitigationFormComponent } from './mitigation-form.component';

@NgModule({
  declarations: [MitigationFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    WcAutocompleteV2Module,
    FormsModule,
    ReactiveFormsModule,
    MitigationComponentModule,
    FlexLayoutModule,
    IsOneTimeClickModule,
  ],
  exports: [MitigationFormComponent],
})
export class MitigationFormModule {}
