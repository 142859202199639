<div class="weather-panel-component" wcPopupCloser (closed)="closePanel()" [refocus]="refocus$">
  <div class="weather-panel-container" [class.portrait-mode]="isPortraitDesktopMode">
    <div class="weather-panel" [class.language-rtl]="isDirectionRtl">
      <div class="weather-panel-tab-content">
        <i class="fas fa-times" (click)="closePanel()"> </i>
        <div class="weather-panel-header">
          <span class="weather-title">
            {{ 'weatherEvents.weather' | translate }}
          </span>
        </div>

        <div class="weather-panel-map-list-filters">
          <div class="filters-grid">
            <mat-select
              [(ngModel)]="selectedMapFilter"
              [panelClass]="{
                'sort-select-dropdown': true,
                'language-rtl': isDirectionRtl
              }"
              (selectionChange)="eventsStatusFilterChange($event)">
              <mat-option
                *ngFor="let filterItem of weatherStatusOptions"
                ngDefaultControl
                [value]="filterItem"
                [ngClass]="'heap-desktop-weather-alerts-filter-' + filterItem">
                {{ 'weatherEvents.alertTypes.' + filterItem | translate }}
              </mat-option>
            </mat-select>

            <mat-select
              panelClass="multi-select-dropdown"
              [placeholder]="'weatherEvents.noTypes' | translate"
              [value]="currentlyVisibleLayerNames"
              (selectionChange)="multiSelectChange($event)"
              multiple>
              <mat-select-trigger *ngIf="currentlyVisibleLayerNames?.length === weatherStoreItems?.length">
                {{ 'allTypes' | translate }}
              </mat-select-trigger>

              <mat-option class="mat-option select-all-option" (click)="stopDefaultBehavior($event)" [value]="'all'">
                <mat-checkbox [checked]="isAllSelected">
                  {{ 'weatherEvents.allTypes' | translate }}
                </mat-checkbox>
              </mat-option>

              <ng-container *ngFor="let layer of weatherStoreItems">
                <mat-option class="mat-option" [value]="layer.name">
                  <span class="select-option">{{ 'liveMapPanelLayerNames.' + layer.name | translate }}</span>
                </mat-option>
              </ng-container>
            </mat-select>
          </div>
        </div>

        <div class="weather-panel-body">
          <div *ngIf="allWeatherEventsAmount > 0" class="weather-sort-container">
            <i class="fas fa-sort-alt"></i>
            <mat-select
              [(ngModel)]="selectedSortWeatherEvents"
              (selectionChange)="saveFilterSelectionToLocalStorage(selectedSortWeatherEvents)"
              [panelClass]="{
                'sort-select-dropdown': true,
                'language-rtl': isDirectionRtl
              }">
              <mat-option
                *ngFor="let sortItem of weatherSortOptionsArray"
                ngDefaultControl
                [value]="sortItem"
                [ngClass]="'heap-desktop-weather-alerts-sort-' + sortItem">
                {{ 'weatherEvents.sortBy.' + sortItem | translate }}
              </mat-option>
            </mat-select>
          </div>

          <div
            *ngIf="allWeatherEventsAmount > 0 && currentlyVisibleLayerNames?.length; else emptyWeather"
            class="weather-lists-container"
            (scroll)="onContainerScroll($event)">
            <div
              *ngIf="
                selectedMapFilter === WeatherStatusOptionsEnum.allAlerts ||
                selectedMapFilter === WeatherStatusOptionsEnum.active
              "
              class="weather-list-active">
              <span class="bold-text type-label-active" style="margin-top: 0px">
                {{ 'active' | translate }}&nbsp;({{ activeWeatherEventList.length }})
              </span>

              <div
                *ngFor="
                  let weatherEvent of activeWeatherEventList
                    | weatherEventsSort: { orderBy: selectedSortWeatherEvents };
                  trackBy: trackById
                "
                class="events-list-by-type-container active"
                id="{{ weatherEvent.entity?.id }}">
                <ng-template
                  *ngTemplateOutlet="eventListItem; context: { weatherEvent: weatherEvent, listType: 'active' }">
                </ng-template>
              </div>
            </div>

            <div
              *ngIf="
                selectedMapFilter === WeatherStatusOptionsEnum.allAlerts ||
                selectedMapFilter === WeatherStatusOptionsEnum.forecast
              "
              class="weather-list-forcast">
              <span class="bold-text type-label-forecast">
                {{ 'weatherEvents.alertTypes.forecast' | translate }}&nbsp;({{ forecastWeatherEventList.length }})
              </span>

              <div
                *ngFor="
                  let weatherEvent of forecastWeatherEventList
                    | weatherEventsSort: { orderBy: selectedSortWeatherEvents };
                  trackBy: trackById
                "
                class="events-list-by-type-container forecast"
                id="{{ weatherEvent.entity?.id }}">
                <ng-template
                  *ngTemplateOutlet="eventListItem; context: { weatherEvent: weatherEvent, listType: 'forecast' }">
                </ng-template>
              </div>
            </div>
          </div>
        </div>

        <ng-template #eventListItem let-weatherEvent="weatherEvent" let-listType="listType">
          <button
            class="weather-events-details-modal"
            (click)="clickOnEventInList(weatherEvent.entity)"
            [class.highlighted-alert-card]="activeWeatherEventId === weatherEvent.entity.id"
            [id]="weatherEvent.entity.id">
            <span *ngIf="!weatherEvent.ui.isViewed" class="new-badge">
              {{ 'new' | translate }}
            </span>
            <div class="status-container">
              <span [ngClass]="'status-text-' + listType">
                {{ 'weatherEvents.alertTypes.' + listType | translate }}
              </span>
              <i class="weather-status-icon" [ngClass]="'icon-' + listType"></i>
            </div>
            <div class="content-details">
              <wc-icon class="weather-icon" [iconName]="weatherEvent.entity.subType?.toLowerCase()"></wc-icon>
              <div class="text-content">
                <span class="bold-text">
                  {{ 'weatherEvents.weatherAlertType.' + weatherEvent.entity.subType?.toLowerCase() | translate }}
                  <span
                    *ngIf="
                      weatherEvent.entity.certainty !== weatherAlertCertainty.UnknownWeatherAlertCertainty &&
                      listType === 'forecast'
                    "
                    class="certainty-text">
                    &nbsp;[{{ 'weatherEvents.certainty.' + weatherEvent.entity.certainty | translate }}]
                  </span>
                </span>
                <span class="address-text">
                  <ng-container [ngSwitch]="weatherEvent.entity.location?.type">
                    <ng-container *ngSwitchCase="'Point'">
                      <ng-container
                        *ngIf="
                          weatherEvent.entity.address.corridor || weatherEvent.entity.address.crossroad;
                          else noAddress
                        ">
                        {{ weatherEvent.entity.address.corridor }}
                        {{ weatherEvent.entity.address.crossroad }}
                      </ng-container>

                      <ng-template #noAddress>
                        {{ 'weatherEvents.noSpecificLocation' | translate }}
                      </ng-template>
                    </ng-container>
                    <ng-container *ngSwitchCase="'LineString'">
                      {{ weatherEvent.entity.address.corridor }}
                      {{ 'weatherEvents.area' | translate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'Polygon'">
                      {{ 'weatherEvents.affectedArea' | translate }}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{ 'weatherEvents.noSpecificLocation' | translate }}
                    </ng-container>
                  </ng-container>
                </span>
                <span class="weather-severity-badge" [ngClass]="weatherEvent.entity.severity">
                  {{ 'weatherEvents.severity.' + weatherEvent.entity.severity | translate }}
                </span>
              </div>
            </div>
            <div class="time-details">
              <div *ngIf="weatherEvent.entity.startTime; else noStartTime" class="time-details-row">
                <span class="grey-text"> {{ 'start' | translate }}:</span>
                <span>
                  {{ weatherEvent.entity.startTime | dateFormat: regionalSetting:'mediumDateNoComma' }}
                </span>
                <span class="grey-text"> | </span>
                <span> {{ weatherEvent.entity.startTime | timeFormatMode: regionalSetting.timeFormat }} </span>
              </div>
              <ng-template #noStartTime>
                <div class="time-details-row">
                  <ng-container [ngSwitch]="weatherEvent.entity.urgency">
                    <ng-container *ngSwitchCase="weatherAlertUrgency.Immediate">
                      <span class="grey-text"> {{ 'start' | translate }}:</span>
                      <span>
                        {{ moment() | dateFormat: regionalSetting:'mediumDateNoComma' }}
                      </span>
                      <span class="grey-text"> | </span>
                      <span> {{ moment() | timeFormatMode: regionalSetting.timeFormat }} </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="weatherAlertUrgency.Expected">
                      <span class="grey-text"> {{ 'start' | translate }}:</span>
                      <span>
                        {{ 'weatherEvents.around' | translate }}
                        {{ weatherEvent.entity.createdAt | dateFormat: regionalSetting:'mediumDateNoComma' }}
                      </span>
                      <span>
                        {{
                          moment(weatherEvent.entity.createdAt).add(1, 'hours')
                            | timeFormatMode: regionalSetting.timeFormat
                        }}
                      </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="weatherAlertUrgency.Future">
                      <span class="grey-text"> {{ 'start' | translate }}:</span>
                      {{ 'weatherEvents.unknown' | translate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="weatherAlertUrgency.UnknownWeatherAlertUrgency">
                      <span class="grey-text"> {{ 'start' | translate }}:</span>
                      {{ 'weatherEvents.unknown' | translate }}
                    </ng-container>
                  </ng-container>
                </div>
              </ng-template>
              <div *ngIf="weatherEvent.entity.endTime">
                <span class="grey-text"> {{ 'end' | translate }}:&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span>
                  {{ weatherEvent.entity.endTime | dateFormat: regionalSetting:'mediumDateNoComma' }}
                </span>
                <span class="grey-text"> | </span>
                <span> {{ weatherEvent.entity.endTime | timeFormatMode: regionalSetting.timeFormat }} </span>
              </div>
            </div>
          </button>
        </ng-template>

        <ng-template #emptyWeather>
          <div class="empty-weather">
            <img class="no-weather-image" src="assets/images/empty-weather-alerts.svg" />
            <span>
              {{
                currentlyVisibleLayerNames.length === 0
                  ? ('weatherEvents.noSelectedTypes' | translate)
                  : ('weatherEvents.noAlerts' | translate)
              }}
            </span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
