<div class="select-chips-component" [class.tablet-mode]="isTabletMode">
  <div [class.invalid-field]="fieldFormControl.invalid && fieldFormControl.touched">
    <div class="boxes-list">
      <span *ngFor="let option of sortOptions(fieldData.options, 'displayName'); let i = index">
        <wc-chip
          [attr.data-cy-id]="'mitigation-type-' + option.displayName"
          [style]="'mitigation'"
          [isClickable]="true"
          [isSelected]="isOptionActive(option)"
          (click)="valueSelected(option)"
          *ngIf="!hiddenValues.includes(option.value)"
          [text]="option.displayName"></wc-chip>
      </span>
    </div>
  </div>
</div>
