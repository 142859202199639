import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'googleDocsPreviewUrl',
})
export class GoogleDocsPreviewUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(s3AssetPresignedUrl: string): SafeResourceUrl {
    const encodedUrl = encodeURIComponent(s3AssetPresignedUrl);
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://docs.google.com/gview?url=${encodedUrl}&embedded=true&hl=En`
    );
  }
}
