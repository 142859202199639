import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import {
  FeaturesPropsByLayerName,
  MapStyleNameOnServer,
  ThemeName,
  WcMapViewerService,
  wcCoordinateTypes,
  wcFeatureProperties,
} from '@wc/wc-map-viewer';
import { AppVersion } from '../../../../../core/app-version';
import { environment } from '../../../../../core/environments/environment';
import { staticMapConfig } from './static-map-viewer.config';

@Component({
  selector: 'wc-static-map-viewer',
  templateUrl: './static-map-viewer.component.html',
  styleUrls: ['./static-map-viewer.component.scss'],
  providers: [WcMapViewerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaticMapViewerComponent implements OnInit {
  private isMapReady = false;
  private featuresPropsByLayerName: FeaturesPropsByLayerName<'draw'> = {};
  private allFeaturesCoordinates: wcCoordinateTypes[] = [];
  private mapCenterPadding: number[] = [20, 20, 20, 20];

  @Input() set accountCenterCoords(coordinates: wcCoordinateTypes) {
    staticMapConfig.centerOptions.mapCenter = coordinates;
    const mapCenter = staticMapConfig.centerOptions.mapCenter;
    if (mapCenter && mapCenter.length > 0 && !this.isMapReady) this.initMap();
  }

  @Input() set zoomFocusPadding(padding: number[]) {
    this.mapCenterPadding = padding;
    this.updateMapDraws();
  }

  @Input() set draws(draws: wcFeatureProperties<'draw'>[]) {
    // Can be change to get all types of entities, No need for now.
    this.allFeaturesCoordinates = draws.map(draw => draw.coordinates);
    this.featuresPropsByLayerName = {
      draw: draws,
    };

    this.updateMapDraws();
  }

  @Input() set maxZoom(zoom: number) {
    staticMapConfig.maxZoom = zoom;
    this.updateMapDraws();
  }

  private get isEmptyMap() {
    return !this.allFeaturesCoordinates.length;
  }

  constructor(private mapViewerService: WcMapViewerService, private el: ElementRef) {}

  ngOnInit(): void {
    this.updateMapConfigStyleByTheme('', '', MapStyleNameOnServer.light, ThemeName.light);
  }

  initMap() {
    setTimeout(() => {
      this.mapViewerService.init(this.el.nativeElement, staticMapConfig);
      this.mapViewerService.ready$.subscribe(ready => {
        this.isMapReady = ready;
        this.updateMapDraws();
      });
    });
  }

  private updateMapDraws() {
    if (!this.isMapReady) return;
    const coordinates = this.isEmptyMap ? staticMapConfig.centerOptions.mapCenter : this.allFeaturesCoordinates;
    this.mapViewerService.setMapCenter(coordinates, {
      padding: this.isEmptyMap ? [0, 0, 0, 0] : this.mapCenterPadding,
    });
    this.mapViewerService.handleRemovedData(this.mapViewerService.allFeatureIds);
    this.mapViewerService.handleModifiedData(this.featuresPropsByLayerName);
  }

  private updateMapConfigStyleByTheme(
    theme,
    iconSrcPath: '' | 'dark_' = '',
    mapSrcPath: MapStyleNameOnServer,
    themeName: ThemeName
  ) {
    staticMapConfig.mapTheme = {
      themeName,
      themeIcons: {
        json: theme,
        path: `assets/map-icons/${iconSrcPath}sprite@1.png?${AppVersion}`,
      },
      mapThemeUrl: `${environment.mapBoxUrl}/${mapSrcPath}`,
    };
  }
}
