import { Component, Input } from '@angular/core';
import { AccountStore, CrashRisk } from '@wc/core';

@Component({
  selector: 'wc-crash-risk-modal',
  templateUrl: './crash-risk-modal.component.html',
  styleUrls: ['./crash-risk-modal.component.scss'],
})
export class CrashRiskModalComponent {
  @Input() crashRisk!: CrashRisk;
  @Input() isHebrew!: boolean;
  @Input() isTabletMode!: boolean;

  constructor(public accountStore: AccountStore) {}
}
