import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MapSatelliteButtonComponent } from './map-satellite-button.component';
import { WcTooltipModule } from "@wc/wc-ui/src/components/tooltip/tooltip.module";

@NgModule({
  declarations: [MapSatelliteButtonComponent],
  imports: [CommonModule, TranslateModule, WcTooltipModule],
  exports: [MapSatelliteButtonComponent],
})
export class MapStelliteButtonModule {}
