import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@wc/core/environments/environment';
import { User } from '@wc/core/models/gql.models';
import { RolesFacadeService } from '@wc/permissions/domain/src';

@Injectable({
  providedIn: 'root',
})
export class HeapAnalyticsService {
  heapAnalyticsTrackingCode: string;

  constructor(public router: Router, private rolesFacadeService: RolesFacadeService) {
    this.heapAnalyticsTrackingCode = environment.heapAnalyticsTrackingCode;
    if (this.heapAnalyticsTrackingCode && this.heapAnalyticsTrackingCode !== '') this.init();
  }

  init() {
    console.log('Init Heap Analytics Service!');
    try {
      const script = document.createElement('script');
      script.innerHTML = `
      window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])};
      heap.load(${this.heapAnalyticsTrackingCode});
      `;
      document.head.appendChild(script);
    } catch (ex) {
      console.error('Error appending heap analytics');
      console.error(ex);
    }
  }

  trackUser(user: User) {
    window['heap'].identify(user.id);
    this.rolesFacadeService.getUsersWithRoles().subscribe(roles => {
      const userRoles: string[] = this.rolesFacadeService.getUserRoles(user.id).map(role => role.name);
      window['heap'].addUserProperties({
        accountId: user.account.id,
        roles: JSON.stringify(userRoles),
        accountName: user.account.name,
        customerName: user.account.customer.name,
        customerId: user.account.customer.id,
      });
    });
  }

  trackUserSpecificAction(action: string, data?: unknown | Record<string, unknown>) {
    window['heap']?.track(action, data ?? null);
  }
  //   public emitEvent(
  //     eventCategory: string,
  //     eventAction: string,
  //     eventLabel: string = null,
  //     eventValue: number = null
  //   ) {
  //     if(this.heapAnalyticsTrackingCode || this.heapAnalyticsTrackingCode == '') return;
  //     if (typeof ga === 'function') {
  //       ga('send', 'event', {
  //         eventCategory: eventCategory,
  //         eventLabel: eventLabel,
  //         eventAction: eventAction,
  //         eventValue: eventValue
  //       });
  //     }
  //   }
}
