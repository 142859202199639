import { Component, forwardRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UiStore } from '@wc/core';
import { AutocompleteV2Component } from '@wc/wc-ui/src/form-components/autocomplete-v2/autocomplete-v2.component';
import { BaseControlFieldComponent } from '../../base/base-control-field.component';
import { FieldData, SelectOption } from '../../form-controls/form-models';

@Component({
  selector: 'wc-editable-list',
  templateUrl: './editable-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableListComponent),
      multi: true,
    },
  ],
  styleUrls: ['./editable-list.component.scss'],
})
export class EditableListComponent extends BaseControlFieldComponent implements OnInit {
  @ViewChildren(AutocompleteV2Component)
  autoCompleteList!: QueryList<AutocompleteV2Component>;

  items: FormArray = this.fb.array([]);
  itemsForm: FormGroup = this.fb.group({ items: this.items });
  selectionControl: FormControl = new FormControl();

  selectionFieldData: FieldData = {
    placeholder: '',
    options: [],
  };

  showNewItem = true;

  autocompleteData = {};

  @Input() hiddenValues: Array<number | string> = [];

  @Input()
  allowOptionValueOnly = false;

  @Input()
  autoCompleteOptions: SelectOption[] = [];

  @Input()
  set placeholder(value: string) {
    this.autocompleteData = { ...{}, placeholder: value };
  }

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  constructor(private fb: FormBuilder, public uiStore: UiStore) {
    super();
  }

  ngOnInit(): void {
    this.itemsForm.valueChanges.subscribe(formValue => {
      const items = formValue.items.filter(item => item.value);
      this.fieldFormControl.setValue(items);
      this.onChanged(items);
      this.disableChosenOptions(this.autoCompleteOptions);

      if (this.itemsForm.invalid) {
        this.formControl?.setErrors({ required: true }, { emitEvent: true });
      }
    });
  }

  writeValue(value: SelectOption[]): void {
    if (value) {
      const visibleItems: SelectOption[] = [];
      this.items.reset();
      value.forEach(item => {
        this.addItem(item);
        if (!this.hiddenValues.includes(item.value)) visibleItems.push(item);
      });

      this.addNewItem();
    }
  }

  addNewItem() {
    this.addItem({ value: null });
  }

  addItem(itemInput: { key?: string | number; value: any } | null) {
    const items: { value: any }[] = this.items.value;
    const isExist = !!items.find(_item => _item.value === itemInput?.value);
    if (isExist) return;

    const item = this.fb.group({
      key: null,
      value: null,
    });

    if (itemInput) item.patchValue(itemInput);
    this.items.insert(0, item);

    // setTimeout( () => {
    //   this.autoCompleteOptionsUpdate(this.autoCompleteOptions);
    // })
  }

  deleteItem(i) {
    this.items.removeAt(i);
    const visibleItems = this.items.value.filter(item => !this.hiddenValues.includes(item.value));
    if (visibleItems.length === 0) this.addNewItem();
  }

  deleteAllItems() {
    this.items.clear();
    this.itemsForm.reset();
  }

  autoCompleteOptionsUpdate(options: SelectOption[]) {
    this.autoCompleteOptions = options;
    this.disableChosenOptions(options);

    // Update options for all view children fo type autocomplete.
    // if (this.autoCompleteList) {
    //     this.autoCompleteList.forEach((element:AutocompleteV2Component) => {
    //       element.updateOptions(options);
    //     });
    // }
  }

  keyUp(e) {
    // console.log(e.keyCode === 13);
  }

  onBlur() {
    // console.log('blur');
  }

  disableChosenOptions(options) {
    options?.forEach(option => {
      if (option) option.disabled = false;
    });
    this.items.value.forEach(item => {
      if (item?.value) {
        options.forEach(option => {
          if (option.value === item?.value) {
            option.disabled = true;
          }
        });
      }
    });
  }

  itemBlurred(fieldControl, index) {
    if (fieldControl.value === '' || fieldControl.value === null || this.fieldFormControl.invalid) {
      if (this.items.length > 1) {
        this.deleteItem(index);
      }
    }
  }
}
