import { LineString, MultiLineString, MultiPolygon, Point, Polygon } from '@wc/core/models';
import { Coordinate } from 'ol/coordinate';
import { fromLonLat } from 'ol/proj';

export function RGBToHex(rgb) {
  // Choose correct separator
  const sep = rgb.indexOf(',') > -1 ? ',' : ' ';
  // Turn "rgb(r,g,b)" into [r,g,b]
  rgb = rgb.substr(4).split(')')[0].split(sep);

  let r = (+rgb[0]).toString(16),
    g = (+rgb[1]).toString(16),
    b = (+rgb[2]).toString(16);

  if (r.length == 1) r = '0' + r;
  if (g.length == 1) g = '0' + g;
  if (b.length == 1) b = '0' + b;

  return '#' + r + g + b;
}

export function hexAToRGBa(h, a) {
  let r = '0',
    g = '0',
    b = '0';

  // 3 digits
  if (h.length == 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits
  } else if (h.length == 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  r = r === '0x00' ? '0' : r;
  g = g === '0x00' ? '0' : g;
  b = b === '0x00' ? '0' : b;
  const prefix = a ? 'rgba' : 'rgb';
  const alpha = a ? a : '';
  return prefix + '(' + +r + ',' + +g + ',' + b + ',' + alpha + ')';
}

export function hexAToRGB(h): number[] {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length == 4) {
    r = parseInt('0x' + h[1] + h[1], 16);
    g = parseInt('0x' + h[2] + h[2], 16);
    b = parseInt('0x' + h[3] + h[3], 16);

    // 6 digits
  } else if (h.length == 7) {
    r = parseInt('0x' + h[1] + h[2], 16);
    g = parseInt('0x' + h[3] + h[4], 16);
    b = parseInt('0x' + h[5] + h[6], 16);
  }

  // r = (r==='0x00')? '0': r;
  // g = (g==='0x00')? '0': g;
  // b = (b==='0x00')? '0': b;
  // return  "rgb("+ +r + "," + +g + "," + b + ")";
  return [r, g, b];
}

export function coordsFormatFromLonLat(
  location: Point | Polygon | LineString | MultiLineString | MultiPolygon
): Coordinate | Coordinate[] | Coordinate[][] | Coordinate[][][] {
  const type = location.type;
  let coords;

  switch (type) {
    case 'Point':
      coords = location.coordinates || [];
      return fromLonLat([coords[0], coords[1]]) as Coordinate;
    case 'LineString':
      coords = location.coordinates || [[]];
      return coords.map(coord => fromLonLat([coord[0], coord[1]])) as Coordinate;
    case 'Polygon':
      coords = location.coordinates || [[[]]];
      return coords.map(poly => poly.map(coord => fromLonLat([coord[0], coord[1]])));
    case 'MultiLineString':
      coords = location.coordinates || [[[]]];
      return coords.map(poly => poly.map(coord => fromLonLat([coord[0], coord[1]])));
    case 'MultiPolygon':
      coords = location.coordinates || [[[[]]]];
      return coords.map(poly => poly.map(poly => poly.map(coord => fromLonLat([coord[0], coord[1]]))));
  }
}

export function isPointEqual(point1: number[], point2: number[]) {
  if (!point1 || !point2) {
    return false;
  } else if (point1[0] === point2[0] && point1[1] === point2[1]) {
    return true;
  }
  return false;
}
