import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as Utils from '../../utils';
import {
  Account,
  AccountMitigationTypesGQL,
  AccountReportSourcesGQL,
  AdditionalInfo,
  IncidentConfiguration,
  IncidentConfigurationGQL,
  MitigationType,
  ReportSource,
  ShiftType,
  Status,
  UnitsByWorkspacesGQL,
  UnitsGQL,
  UnitVehicle,
  UpdateIncidentConfigurationGQL,
  UpdateIncidentConfigurationInput,
  WorkspacesRoadReferencesGQL,
} from '../models';
import { CustomRxOperatorsService } from './custom-rx-operators.service';
import { ShiftsService } from './shifts.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    private accountMitigationTypesGQL: AccountMitigationTypesGQL,
    private accountReportSourcesGQL: AccountReportSourcesGQL,
    private unitsGQL: UnitsGQL,
    private unitsByWorkspacesGQL: UnitsByWorkspacesGQL,
    private workspacesRoadReferencesGQL: WorkspacesRoadReferencesGQL,
    private incidentConfigurationGQL: IncidentConfigurationGQL,
    private updateIncidentConfigurationGQL: UpdateIncidentConfigurationGQL,
    private customOperators: CustomRxOperatorsService,
    private shiftsService: ShiftsService
  ) {}

  updateAccountInfo(account: Account, modifiedShiftTypes: ShiftType[]) {
    const servicesObservables: Observable<{
      operationName: string;
      data: MitigationType | undefined | boolean | AdditionalInfo | ReportSource;
    }>[] = [];
    const regex = /,"__typename":"\w+"/gm; // Remove all the "__typename" properties from the object.
    account.mitigationTypes = JSON.parse(JSON.stringify(account.mitigationTypes).replace(regex, ''));
    account.additionalInfos = JSON.parse(JSON.stringify(account.additionalInfos).replace(regex, ''));

    if (modifiedShiftTypes) {
      const activeAccountShiftTypes = account.shiftTypes.filter(shift => shift.status === Status.Active);
      const shiftTypesDiff = Utils.entitiesArraysDiff(activeAccountShiftTypes, modifiedShiftTypes);
      const createShiftTypes = shiftTypesDiff.modified.filter((shiftType: ShiftType) => !shiftType.id);
      const updateShiftTypes = shiftTypesDiff.modified.filter((shiftType: ShiftType) => !!shiftType.id);
      createShiftTypes.forEach(shiftType => {
        servicesObservables.push(
          this.shiftsService.createShiftType({
            accountId: account.id,
            name: shiftType.name,
          })
        );
      });
      updateShiftTypes.forEach(shiftType => {
        servicesObservables.push(
          this.shiftsService.updateShiftType({
            shiftTypeId: shiftType.id,
            name: shiftType.name,
          })
        );
      });
      console.log(shiftTypesDiff.removed);
      shiftTypesDiff.removed.forEach(id => {
        servicesObservables.push(this.shiftsService.deleteShiftType({ shiftTypeId: id }));
      });
    }

    return forkJoin(servicesObservables);
  }

  accountMitigationTypes(getAll: boolean) {
    return this.accountMitigationTypesGQL.fetch({ getAll: getAll }).pipe(map(res => res?.data?.accountMitigationTypes));
  }

  updateIncidentConfiguration(input: UpdateIncidentConfigurationInput): Observable<IncidentConfiguration> {
    return this.updateIncidentConfigurationGQL.mutate({ input }).pipe(
      map(res => res?.data?.updateIncidentConfiguration as IncidentConfiguration),
      this.customOperators.catchGqlErrors()
    );
  }

  //only by account
  unitsByAccount() {
    return this.unitsGQL.fetch().pipe(map(res => res?.data?.units as Partial<UnitVehicle>[]));
  }

  //by jurisdiction
  unitsByWorkspaces() {
    return this.unitsByWorkspacesGQL.fetch().pipe(map(res => res?.data?.unitsByWorkspaces as UnitVehicle[]));
  }

  workspacesRoadReferences() {
    return this.workspacesRoadReferencesGQL.fetch().pipe(map(res => res?.data?.workspacesRoadReferences));
  }

  loadIncidentConfiguration(): Observable<IncidentConfiguration> {
    return this.incidentConfigurationGQL.fetch().pipe(
      map(res => res.data.incidentConfiguration),
      this.customOperators.catchGqlErrors()
    );
  }
}
