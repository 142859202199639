import { Pointer as PointerInteraction } from 'ol/interaction';
import Feature from 'ol/Feature';
import { toLonLat } from 'ol/proj.js';
import { LiveMapEntity } from '@wc/core/models';
import { Coordinate } from 'ol/coordinate';
import RenderFeature from 'ol/render/Feature';
import { Point } from 'ol/geom';

export class Drag extends PointerInteraction {
  coordinate_!: Coordinate | undefined;
  feature_!: Feature | undefined;
  entity!: LiveMapEntity | undefined;
  // cursor_ = 'pointer';
  // previousCursor_ = undefined;

  constructor(private dragEnd) {
    super();
  }

  handleDownEvent(evt) {
    const map = evt.map;
    const feature = map.forEachFeatureAtPixel(evt.pixel, function (_feature) {
      return _feature;
    });

    if (feature && feature instanceof Feature) {
      const entity: LiveMapEntity = feature.getProperties().features
        ? feature.getProperties().features[0].getProperties()
        : feature.getProperties();
      // console.log(entity);
      if (entity.featureType === 'interactive' && entity.location.type === 'Point') {
        this.entity = entity;
        this.coordinate_ = evt.coordinate;
        this.feature_ = feature;
        return !!feature;
      }
    }
    return false;
  }

  handleDragEvent(evt) {
    if (this.entity && this.coordinate_) {
      const deltaX = evt.coordinate[0] - this.coordinate_[0];
      const deltaY = evt.coordinate[1] - this.coordinate_[1];

      const geometry = this.feature_?.getGeometry();
      geometry?.translate(deltaX, deltaY);

      this.coordinate_[0] = evt.coordinate[0];
      this.coordinate_[1] = evt.coordinate[1];
    }
  }

  // handleMoveEvent(evt) {
  //     if (this.cursor_) {
  //       const map = evt.map;
  //       const feature = map.forEachFeatureAtPixel(evt.pixel,
  //         function(_feature) {
  //           return _feature;
  //         });
  //       const element = evt.map.getTargetElement();
  //       if (feature) {
  //         if (element.style.cursor !== this.cursor_) {
  //           this.previousCursor_ = element.style.cursor;
  //           element.style.cursor = this.cursor_;
  //         }
  //       } else if (this.previousCursor_ !== undefined) {
  //         element.style.cursor = this.previousCursor_;
  //         this.previousCursor_ = undefined;
  //       }
  //     }
  // }

  handleUpEvent() {
    if (this.entity) {
      const feature = this.feature_ as Feature;
      const coordinates = feature.getGeometry() as Point;
      const lonLat = toLonLat(coordinates.getCoordinates());
      this.entity.location.coordinates = lonLat;
      this.dragEnd.emit({ entity: this.entity, coordinates: lonLat });
    }
    this.entity = undefined;
    this.coordinate_ = undefined;
    this.feature_ = undefined;
    return false;
  }
}
