import { LayerType } from '@wc/core';
import { MapLayer } from '@wc/wc-map-viewer/src/types';
import { EntityState, LayerNameStyleConfigKeys } from '@wc/wc-models/src';
import { LayersZIndexConfig } from './layers-z-index.config';

export const _unitsLayersConfig: MapLayer<LayerNameStyleConfigKeys, EntityState> = {
  fsp_unit: {
    zIndex: LayersZIndexConfig.fsp_unit,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: { iconName: 'green_arrow', anchor: [0.5, 2.3], rotation: { sourceField: 'bearing' } },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit.hover', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'fsp_unit_direction_arrow.hover',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit.selected', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'fsp_unit_direction_arrow.selected',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
        ],
      },
      onbreak: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: { iconName: 'blue_arrow', anchor: [0.5, 2.3], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: { iconName: 'unit break', anchor: [0, 1], scale: 0.8 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit_direction_arrow.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'unit break', anchor: [0, 3], scale: 0.5 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit.selected', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit_direction_arrow.selected', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'unit break', anchor: [0, 1], scale: 0.8 },
          },
        ],
      },
    },
  },
  city_police_unit: {
    zIndex: LayersZIndexConfig.city_police_unit,
    pointStatusStyle: {
      busy: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'police_unit_busy_default', anchor: [0.5, 0.585] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'city_police_unit_badge', anchor: [0.5, 0.5] },
          },
        ],
      },
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'police_unit', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'city_police_unit_badge', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'blue_arrow', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'police_unit.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'police_unit_direction_arrow.hover', anchor: [0.5, 0.63] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'city_police_unit_badge', anchor: [0.5, 0.57] },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'police_unit.selected', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'police_unit_direction_arrow.selected', anchor: [0.5, 0.63] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'city_police_unit_badge', anchor: [0.5, 0.57] },
          },
        ],
      },
    },
  },
  highway_police_unit: {
    zIndex: LayersZIndexConfig.highway_police_unit,
    pointStatusStyle: {
      busy: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'police_unit_busy_default', anchor: [0.4, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: { iconName: 'highway_police_unit_badge', anchor: [0.5, 0.5] },
          },
        ],
      },

      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'police_unit', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: { iconName: 'highway_police_unit_badge', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'blue_arrow', anchor: [0.5, 2.3], rotation: { sourceField: 'bearing' } },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'police_unit.hover', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'police_unit_direction_arrow.hover',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
          {
            shape: 'marker',
            icon: { iconName: 'highway_police_unit_badge', anchor: [0.5, 0.57] },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'police_unit.selected', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'police_unit_direction_arrow.selected',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
          {
            shape: 'marker',
            icon: { iconName: 'highway_police_unit_badge', anchor: [0.5, 0.57] },
          },
        ],
      },
    },
  },
  fire_department_unit: {
    zIndex: LayersZIndexConfig.fire_department_unit,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'fire_department_unit', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: { iconName: 'blue_arrow', anchor: [0.5, 2.3], rotation: { sourceField: 'bearing' } },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'fire_department_unit.hover', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'fire_department_unit_direction_arrow.hover',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'fire_department_unit.selected',
              anchor: [0.5, 0.5],
              rotation: { sourceField: 'bearing' },
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'fire_department_unit_direction_arrow.selected',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
        ],
      },
    },
  },
  construction_unit: {
    zIndex: LayersZIndexConfig.construction_unit,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'construction_unit', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: { iconName: 'orange_arrow', anchor: [0.5, 2.3], rotation: { sourceField: 'bearing' } },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'construction_unit.hover', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'construction_unit_direction_arrow.hover',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'construction_unit.selected', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'construction_unit_direction_arrow.selected',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
        ],
      },
    },
  },
  ems_unit: {
    zIndex: LayersZIndexConfig.ems_unit,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'ems_unit', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: { iconName: 'red_arrow', anchor: [0.5, 2.3], rotation: { sourceField: 'bearing' } },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'ems_unit.hover', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'ems_unit_direction_arrow.hover',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'ems_unit.selected', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'ems_unit_direction_arrow.selected',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
        ],
      },
    },
  },
  maintenance_unit: {
    zIndex: LayersZIndexConfig.maintenance_unit,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'maintenance_unit', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: { iconName: 'orange_arrow', anchor: [0.5, 2.3], rotation: { sourceField: 'bearing' } },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'maintenance_unit.hover', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'maintenance_unit_direction_arrow.hover',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'maintenance_unit.selected', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'maintenance_unit_direction_arrow.selected',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
        ],
      },
    },
  },
  street_sweep_unit: {
    zIndex: LayersZIndexConfig.street_sweep_unit,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'street_sweep_unit', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: { iconName: 'orange_arrow', anchor: [0.5, 2.3], rotation: { sourceField: 'bearing' } },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'street_sweep_unit.hover', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'street_sweep_unit_direction_arrow.hover',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'street_sweep_unit.selected', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'street_sweep_unit_direction_arrow.selected',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
        ],
      },
    },
  },
  snow_plow_unit: {
    zIndex: LayersZIndexConfig.snow_plow_unit,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'snowplows_unit', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: { iconName: 'orange_arrow', anchor: [0.5, 2.3], rotation: { sourceField: 'bearing' } },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'snowplows_unit.hover', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'snowplows_unit_direction_arrow.hover',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'snowplows_unit.selected', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'snowplows_unit_direction_arrow.selected',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
        ],
      },
    },
  },
  other_unit: {
    zIndex: LayersZIndexConfig.other_unit,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'other_unit', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: { iconName: 'grey_arrow', anchor: [0.5, 2.3], rotation: { sourceField: 'bearing' } },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'other_unit.hover', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'other_unit_direction_arrow.hover',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'other_unit.selected', anchor: [0.5, 0.5], rotation: { sourceField: 'bearing' } },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'other_unit_direction_arrow.selected',
              anchor: [0.5, 0.63],
              rotation: { sourceField: 'bearing' },
            },
          },
        ],
      },
    },
  },
};

export const unitsLayersConfig = Object.keys(_unitsLayersConfig).reduce((acc, key) => {
  acc[`${LayerType.Unit}-${key}`] = _unitsLayersConfig[key];
  return acc;
}, {});
