import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldData } from '../form-controls/form-models';

@Component({
  selector: 'wc-base-control',
  template: '',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseControlFieldComponent),
      multi: true,
    },
  ],
})
export class BaseControlFieldComponent implements ControlValueAccessor {
  @Input() formControl!: FormControl;
  @Input() fieldData: FieldData = {};
  @Input() set disabled(isDisabled: boolean) {
    if (isDisabled) {
      this.fieldFormControl.disable();
    } else {
      this.fieldFormControl.enable();
    }
  }

  get validationError() {
    if (this.formControl?.errors) this.fieldFormControl.setErrors(this.formControl.errors);
    return this.formControl.errors && this.fieldFormControl.errors
      ? Object.keys(this.fieldFormControl.errors)[0]
      : null;
  }

  fieldFormControl = new FormControl();
  onChanged: any = () => {};
  onTouched: any = () => {};
  onValidationChange: any = () => {};

  writeValue(val: any): void {
    this.fieldFormControl.setValue(val);
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.fieldFormControl.disable();
    } else {
      this.fieldFormControl.enable();
    }
  }

  changed() {
    this.onTouched();
    this.onChanged(this.fieldFormControl.value);
  }

  get visibleOptions() {
    return this.fieldData?.options?.filter(op => op.hidden === false) || [];
  }

  get visibleOptionsIncludeControlValue() {
    return this.fieldData?.options?.filter(op => op.hidden === false || op.value === this.formControl.value) || [];
  }

  get hiddenOptions() {
    return this.fieldData?.options?.filter(op => op.hidden === true) || [];
  }

  get hiddenOptionsWithoutControlValue() {
    return this.fieldData?.options?.filter(op => op.hidden === true && op.value !== this.formControl.value) || [];
  }

  doBlur() {
    this.onTouched();
  }
}
