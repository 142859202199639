import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material//checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxListControlModule } from '../checkbox-list-control/checkbox-list-control.module';
import { FreeTextListControlModule } from '../free-text-list-control/free-text-list-control.module';
import { SliderToggleControlModule } from '../slide-toggle-control/slider-toggle-control.module';
import { SingleConfigFormComponent } from './single-config-form.component';
@NgModule({
  declarations: [SingleConfigFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    SliderToggleControlModule,
    CheckboxListControlModule,
    MatCheckboxModule,
    FreeTextListControlModule,
  ],
  exports: [SingleConfigFormComponent],
})
export class SingleConfigFormModule {}
