<span
  [attr.dat-cy-id]="'priority-score-' + scoreLevel"
  matTooltipHideDelay="400"
  matTooltipShowDelay="400"
  [matTooltipDisabled]="disableTooltip || !scoreLevel"
  matTooltipPosition="above"
  [matTooltip]="'insight.priorityTooltip.title' | translate: { type: 'insight.priority.' + scoreLevel | translate }"
  matTooltipClass="persistence-level-tooltip"
  class="wc-priority-score wc-priority-score__priority"
  [class]="size"
  [ngClass]="'wc-priority-score__priority--' + scoreLevel">
  {{ (textMode === 'short' ? 'insight.priority.' : 'insight.priority.') + scoreLevel | translate }}
</span>
