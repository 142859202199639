import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { HeapAnalyticsService } from '@wc-core';
import { CustomFormControlComponent } from '../../lib/base';

@Component({
  selector: 'wc-slider-toggle-control',
  templateUrl: './slider-toggle-control.component.html',
  styleUrls: ['./slider-toggle-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderToggleControlComponent extends CustomFormControlComponent {
  @Input() tooltipText = '';
  @Input() isTablet = false;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    cdr: ChangeDetectorRef,
    private heapService: HeapAnalyticsService
  ) {
    super(ngControl, cdr);
  }

  changed(value) {
    this.onChanged(value);
    this.onTouched();
    this.heapService.trackUserSpecificAction(
      `heap-${this.formFieldData.heapClass ? this.formFieldData.heapClass : 'slider-changed'}`,
      { value: value }
    );
  }

  get isTabletMode() {
    return this.isTablet;
  }
}
