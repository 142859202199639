export enum weatherSortOptionsEnum {
  'startTime' = 'startTime',
  'severity' = 'severity',
  'certainty' = 'certainty',
  'type' = 'type',
}

export const WeatherSeverityOrder = ['EXTREME', 'SEVERE', 'MODERATE', 'MINOR', 'UNKNOWN_WEATHER_ALERT_SEVERITY'];

export const WeatherCertaintyOrder = ['LIKELY', 'POSSIBLE', 'UNLIKELY', 'UNKNOWN_WEATHER_ALERT_CERTAINTY'];
