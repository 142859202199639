import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { PriorityScoreLevel } from '@wc/core';

@Component({
  selector: 'wc-priority-score',
  templateUrl: './priority-score.component.html',
  styleUrls: ['./priority-score.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PriorityScoreComponent {
  private _priorityScoreLevel: PriorityScoreLevel = PriorityScoreLevel.LowLevel;

  @Input() set priorityScoreLevel(level: PriorityScoreLevel | undefined) {
    if (level) this._priorityScoreLevel = level;
  }
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() textMode: 'short' | 'long' = 'short';
  @Input() disableTooltip = false;

  get scoreLevel() {
    return this._priorityScoreLevel;
  }
}
