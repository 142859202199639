import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Direction, IncidentLane, LaneType, Point, RoadType, TrafficDisruptionLane } from '@wc/core';
import { MenuLaneTypes } from '@wc/core/models/enums';
import { SegmentService } from '@wc/core/services/segment.service';
import { AffectedLanesSliderComponent } from '@wc/features/ui/form-fields-components/affected-lanes-slider/affected-lanes-slider.component';
import * as Utils from '@wc/utils';
import { cloneDeep, isEqual } from 'lodash';
import { UiStore } from '../../../../core/stores/ui.store';
import { BaseControlFieldComponent } from '../../base/base-control-field.component';
import { LaneMenuModalComponent } from '../lane-menu-modal/lane-menu-modal.component';

export type RoadEventLane = IncidentLane | TrafficDisruptionLane;

interface MenuItem {
  type: MenuLaneTypes;
  icon: string;
  isActive: boolean;
}

@Component({
  selector: 'wc-affected-lanes',
  templateUrl: './affected-lanes.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AffectedLanesComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AffectedLanesComponent),
      multi: true,
    },
  ],
  styleUrls: ['./affected-lanes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AffectedLanesComponent extends BaseControlFieldComponent implements OnInit, Validator, OnDestroy {
  MenuLaneTypes = MenuLaneTypes;
  form: FormGroup = this.initLanesFromGroup();

  @Input() newAutocomplete = false; // to be deprecated once no need for old autocomplete
  @Input() mode: 'create' | 'update' = 'create';
  @Input() liveUpdateFromSegmentService: boolean = false; // update lanes quantity after each location change on the map , without waiting for @input() coords
  isInitLocation = true;
  isDirectionAutopopulated = false;
  prevCoords: Array<number> = [];
  coordsInit = 0; // in update mode , in case segment is changed , build lanes only after the user moved the location one time
  lanesQuantity = null;

  @Input()
  set coords(value: Point) {
    if (!value) return;
    const _value = cloneDeep(value);
    this.coordsInit++;
    const coords = _value.coordinates as number[];
    if (coords.length > 0) _value.coordinates = [coords[0], coords[1]];
    if (_value?.coordinates && isEqual(_value.coordinates, this.prevCoords) && this.mode === 'update') return;
    this.prevCoords = _value.coordinates || [];
    if (_value.coordinates) {
      if (this.isInitLocation && this.mode === 'update') {
        return;
      } else {
        this.updateLanesByQuantity();
      }
    } else {
      if (this.isInitLocation && this.mode === 'update') {
        return;
      } else {
        this.setDefaultLanes();
      }
    }
  }

  updateLanesByQuantity() {
    const segment = this.segmentService.lastSegmentDetails$.value;
    this.lanesQuantity = segment?.lanesQuantity;
    if (this.lanesQuantity) {
      if (this.isOneDirection) {
        if (this.lanesQuantity === 0) {
          this.setDefaultLanes();
        } else {
          this.setLanesBylanesQuantity(this.lanesQuantity);
        }
        return;
      } else {
        this.form.setValue({ lanes: this.defaultLaneForMultiDirection() });
        return;
      }
    } else {
      this.setDefaultLanes();
    }
  }
  _allAffected = false;
  @Input()
  set allAffected(value: boolean) {
    this._allAffected = value;
    this.handleAllAffectedLanesClick(value);
  }

  @Input()
  set affectedDirections(value) {
    const isOneDirection = !value;
    if (isOneDirection === this.isOneDirection) return;
    this.isOneDirection = isOneDirection;
    if (this.isOneDirection) {
      this.isDirectionPopulatedForSingleMode();
      this.maxNumberLanes = 9;
      this.prevmultiDirectionLanesAffectedLaneState = this.lanes;
      if (this.prevOneDirectionLanesState?.length) {
        this.lanes = this.prevOneDirectionLanesState;
      } else {
        this.setDefaultLanes();
      }
    } else {
      this.maxNumberLanes = 1000;
      this.activateMenuOptions();
      this.prevOneDirectionLanesState = this.lanes;
      this.lanes = this.prevmultiDirectionLanesAffectedLaneState || [
        {
          direction: this._direction,
          roadType: this._roadType,
          isClosed: true,
          number: null,
          positionIndex: undefined,
          isAffected: true,
          type: undefined,
        },
      ];
    }

    this.form.setValue({ lanes: this.lanes }, { emitEvent: false });
    if (this._allAffected) {
      this.handleAllAffectedLanesClick(this._allAffected);
    }
    this.changeValue();
  }

  _direction?: Direction = undefined;
  @Input() set direction(value: Direction) {
    //if(!this.isDirectionPopulatedForSingleMode()) {
    this._direction = value;
    if (this.isOneDirection) {
      this.updateAffectedLanesDirection(value);
    } else {
      this.prevOneDirectionLanesState?.forEach(lane => {
        lane.direction = this._direction;
      });
    }
    //}
  }

  _roadType?: RoadType = undefined;
  @Input() set roadType(value: RoadType) {
    this._roadType = value;
    if (this.isOneDirection) {
      this.updateAffectedLanesRoadType(value);
    } else {
      this.prevOneDirectionLanesState?.forEach(lane => {
        lane.roadType = this._roadType;
      });
    }
  }

  @ViewChild('affectedLanesSlider', { read: AffectedLanesSliderComponent, static: false })
  affectedLanesSlider!: AffectedLanesSliderComponent;

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  lanes: Array<Partial<RoadEventLane>> = [];
  hoveredLane = null;
  diagramHoveredLane = null;
  maxNumberLanes = 9;
  shouldUpdateForm = false;
  shouldSetDefaultLanes = true;
  segmentSubscription;
  prevOneDirectionLanesState?: Partial<RoadEventLane>[] = undefined;
  prevmultiDirectionLanesAffectedLaneState?: Partial<RoadEventLane>[] = undefined;
  isOneDirection = true;

  contextMenuOptions: Array<MenuItem> = [
    {
      type: this.MenuLaneTypes.LANE,
      icon: '/assets/icons/lane-types/lane.svg',
      isActive: true,
    },
    {
      type: this.MenuLaneTypes.RIGHT_SHOULDER,
      icon: '/assets/icons/lane-types/right-shoulder.svg',
      isActive: true,
    },
    {
      type: this.MenuLaneTypes.LEFT_SHOULDER,
      icon: '/assets/icons/lane-types/left-shoulder.svg',
      isActive: true,
    },
    {
      type: this.MenuLaneTypes.GORE,
      icon: '/assets/icons/lane-types/gore.svg',
      isActive: true,
    },
  ];

  lanesFieldData = {
    lanes: { label: 'lanes' },
  };

  constructor(
    public uiStore: UiStore,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    public segmentService: SegmentService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.liveUpdateFromSegmentService) {
      this.segmentSubscription = this.segmentService.lastSegmentDetails$.subscribe(segment => {
        if (
          (this.coordsInit > 1 &&
            !this.isInitLocation &&
            this.lanesQuantity &&
            this.lanesQuantity !== segment?.lanesQuantity) ||
          this.mode === 'create'
        ) {
          this.lanesQuantity = segment?.lanesQuantity;
          this.updateLanesByQuantity();
        }
      });
    }

    this.form.valueChanges.subscribe(formValue => {
      /*if(this.isInitLocation &&this.shouldSetDefaultLanes && this.isTabletMode && formValue.lanes.length === 0) {
        this.shouldSetDefaultLanes = false;
        this.setDefaultLanes();
      }*/
      this.lanes = formValue.lanes;

      this.recalculateGoreNumbers();
      this.recalculateLaneNumbers();
      this.removeNullIds();
      this.isOneDirection && this.updateMenuOptionTypes();
      this.setPositionIndexes();

      this.changeValue();
      this.cdr.markForCheck();
    });
  }

  initLanesFromGroup() {
    return this.fb.group({
      lanes: null,
    });
  }

  writeValue(val): void {
    if (!val) return;
    const _val = cloneDeep(val);
    this.isInitLocation = false;
    if (_val.length === 0) {
      this.updateLanesByQuantity();
      return;
    }
    this.lanes = Utils.sortAffectedLanes(_val);
    this.isDirectionPopulatedForSingleMode();
    this.isOneDirection && this.updateMenuOptionTypes();
    this.form.setValue({ lanes: this.lanes });
  }

  changeValue() {
    this.fieldFormControl.setValue(this.lanes);
    this.onChanged(this.fieldFormControl.value);
  }

  handleLaneHover($event) {
    this.hoveredLane = $event.index;
  }

  handleLaneClick({ lane, index }) {
    if (this._allAffected) return;
    this.lanes[index].isAffected = !this.lanes[index].isAffected;
    if (!this.lanes[index].isAffected) {
      this.lanes[index].isClosed = false;
    } else {
      this.lanes[index].isClosed = true;
    }

    this.form.patchValue({ lanes: this.lanes }, { emitEvent: true });
  }

  diagramLaneHovered($event) {
    this.diagramHoveredLane = $event.index;
  }

  setDefaultLanes() {
    this.lanes = new Array(
      {
        direction: this._direction,
        roadType: this._roadType,
        isClosed: false,
        number: null,
        positionIndex: 0,
        isAffected: false,
        type: LaneType.LeftShoulder,
      },
      {
        direction: this._direction,
        roadType: this._roadType,
        isClosed: false,
        number: 1,
        positionIndex: 1,
        isAffected: false,
        type: LaneType.LeftLane,
      },
      {
        direction: this._direction,
        roadType: this._roadType,
        isClosed: false,
        number: 2,
        positionIndex: 2,
        isAffected: false,
        type: LaneType.CentralLane,
      },
      {
        direction: this._direction,
        roadType: this._roadType,
        isClosed: false,
        number: 3,
        positionIndex: 3,
        isAffected: false,
        type: LaneType.RightLane,
      },
      {
        direction: this._direction,
        roadType: this._roadType,
        isClosed: false,
        number: null,
        positionIndex: 4,
        isAffected: false,
        type: LaneType.RightShoulder,
      }
    );
    this.form.setValue({ lanes: this.lanes });
    this.handleAllAffectedLanesClick(this._allAffected);
  }

  defaultLaneForMultiDirection() {
    return [
      {
        direction: this._direction,
        roadType: this._roadType,
        isClosed: true,
        number: null,
        positionIndex: 0,
        isAffected: true,
        type: null,
      },
    ];
  }
  setLanesBylanesQuantity(lanesQuantity) {
    this.lanes = [];
    this.lanes.push({
      direction: this._direction,
      roadType: this._roadType,
      type: LaneType.LeftShoulder,
      isClosed: false,
      number: null,
      positionIndex: 0,
      isAffected: false,
    });
    for (let i = 0; i < lanesQuantity; i++) {
      this.lanes.push({
        direction: this._direction,
        roadType: this._roadType,
        type: LaneType.RightLane,
        isClosed: false,
        number: i + 1,
        positionIndex: i + 1,
        isAffected: false,
      });
    }
    this.lanes.push({
      direction: this._direction,
      roadType: this._roadType,
      type: LaneType.RightShoulder,
      isClosed: false,
      number: null,
      positionIndex: lanesQuantity + 1,
      isAffected: false,
    });

    this.recalculateLaneTypes();
    this.updateMenuOptionTypes();
    this.form.setValue({ lanes: this.lanes });
    this.handleAllAffectedLanesClick(this._allAffected);
  }

  addLane(lane: { type?: MenuLaneTypes }, affected = true) {
    const newLane = {
      direction: this.isOneDirection ? this._direction : null,
      roadType: this.isOneDirection ? this._roadType : null,
      type: this.getLaneType(lane.type),
      isClosed: affected,
      number: this.getLaneNumber(lane.type),
      isAffected: affected,
      positionIndex: -1,
    } as Partial<RoadEventLane>;

    if (!this.isOneDirection) {
      this.lanes.push(newLane);
    } else {
      this.lanes.splice(this.positionToInsert(lane.type), 0, newLane);
    }

    if (lane.type === this.MenuLaneTypes.LANE) {
      this.isOneDirection && this.recalculateLaneTypes();
      this.recalculateLaneNumbers();
    }
    if (lane.type === this.MenuLaneTypes.GORE) {
      this.recalculateGoreNumbers();
    }
    this.setPositionIndexes();

    this.form.patchValue({ lanes: this.lanes }, { emitEvent: true });
    this.reloadSwiperConfig();
  }

  setMenuItemStatus(laneType, status: boolean) {
    this.contextMenuOptions.forEach(item => {
      if (item.type === laneType) {
        item.isActive = status;
        return;
      }
    });
  }
  activateMenuOptions() {
    this.contextMenuOptions.forEach(item => {
      item.isActive = true;
    });
  }

  getLaneNumber(laneType) {
    switch (laneType) {
      case this.MenuLaneTypes.LANE: {
        let amountOfLanes = 0;
        this.lanes.forEach(item => {
          if (this.isLaneSubType(item.type)) {
            amountOfLanes++;
          }
        });

        return ++amountOfLanes;
      }
      case this.MenuLaneTypes.GORE: {
        let amountOfGores = 0;
        this.lanes.forEach(item => {
          if (item.type === LaneType.Gore) {
            amountOfGores++;
          }
        });
        return ++amountOfGores;
      }
      default:
        return null;
    }
  }

  getLaneType(type) {
    switch (type) {
      case this.MenuLaneTypes.LANE: {
        const isRightLane = this.form.value.lanes.some(lane => lane.type === LaneType.RightLane);
        const isRightTurn = this.form.value.lanes.some(lane => lane.type === LaneType.TurnRight);
        return this.isOneDirection
          ? isRightTurn
            ? LaneType.TurnRight
            : isRightLane
            ? LaneType.RightLane
            : LaneType.CentralLane
          : null;
      }
      case this.MenuLaneTypes.GORE:
        return LaneType.Gore;
      case this.MenuLaneTypes.LEFT_SHOULDER:
        return LaneType.LeftShoulder;
      case this.MenuLaneTypes.RIGHT_SHOULDER:
        return LaneType.RightShoulder;
      default:
        return LaneType.CentralLane;
    }
  }

  removeNullIds() {
    this.lanes.forEach(lane => {
      if (lane.id === null) {
        delete lane.id;
      }
    });
  }

  setPositionIndexes() {
    this.lanes.forEach((lane, index) => {
      lane.positionIndex = index;
    });
  }

  validate({ value }: FormControl) {
    if (this.form.invalid) {
      return { invalid: true };
    }
    return null;
  }

  positionToInsert(laneType) {
    const lanesNumber = this.lanes.length;
    switch (laneType) {
      case this.MenuLaneTypes.LEFT_SHOULDER: {
        return 0;
      }
      case this.MenuLaneTypes.RIGHT_SHOULDER: {
        return lanesNumber;
      }
      case this.MenuLaneTypes.LANE: {
        if (lanesNumber === 0) {
          return 0;
        } else {
          if (this.lanes[lanesNumber - 1].type === LaneType.RightShoulder) {
            return lanesNumber - 1;
          } else return lanesNumber;
        }
      }
      case this.MenuLaneTypes.GORE: {
        if (lanesNumber === 0) {
          return 0;
        }
        return this.getMaxGoreIndex();
      }
    }
    return 0;
  }

  updateAffectedLanesRoadType(roadType) {
    this.lanes.forEach(lane => {
      lane.roadType = roadType;
    });

    this.form.patchValue({ lanes: this.lanes }, { emitEvent: true });
  }

  updateAffectedLanesDirection(direction) {
    this.lanes.forEach(lane => {
      lane.direction = direction;
    });
    this.form.patchValue({ lanes: this.lanes }, { emitEvent: true });
  }

  updateMenuOptionTypes() {
    const isExistLeftShoulder = this.lanes.find(lane => lane.type === LaneType.LeftShoulder);
    if (isExistLeftShoulder) {
      this.setMenuItemStatus(this.MenuLaneTypes.LEFT_SHOULDER, false);
    } else {
      this.setMenuItemStatus(this.MenuLaneTypes.LEFT_SHOULDER, true);
    }
    const isExistRightShoulder = this.lanes.find(lane => lane.type === LaneType.RightShoulder);
    if (isExistRightShoulder) {
      this.setMenuItemStatus(this.MenuLaneTypes.RIGHT_SHOULDER, false);
    } else {
      this.setMenuItemStatus(this.MenuLaneTypes.RIGHT_SHOULDER, true);
    }
    if (this.canAddGore()) {
      this.setMenuItemStatus(this.MenuLaneTypes.GORE, true);
    } else {
      this.setMenuItemStatus(this.MenuLaneTypes.GORE, false);
    }
  }

  handleAllAffectedLanesClick(isChecked: boolean) {
    this.lanes.forEach(lane => {
      lane.isClosed = isChecked;
      lane.isAffected = isChecked;
    });

    this.form.patchValue({ lanes: this.lanes }, { emitEvent: true });
  }

  recalculateLaneTypes() {
    const laneIndexArray = this.lanes
      .map((lane, index) => {
        if (this.isLaneType(lane.type)) {
          return index;
        } else {
          return undefined;
        }
      })
      .filter(val => val !== undefined) as number[];
    const numberOfLanes = laneIndexArray.length;

    switch (numberOfLanes) {
      case 0:
        return;
      case 1: {
        this.lanes[laneIndexArray[0]].type = LaneType.CentralLane;
        break;
      }
      case 2: {
        if (
          this.lanes[laneIndexArray[0]].type !== LaneType.CentralLane &&
          this.lanes[laneIndexArray[1]].type !== LaneType.CentralLane
        ) {
          this.lanes[laneIndexArray[0]].type = LaneType.CentralLane;
        }
        break;
      }

      default: {
        const lanesIndexes = [...laneIndexArray];
        if (this.lanes[lanesIndexes[0]].type !== LaneType.TurnLeft)
          this.lanes[lanesIndexes[0]].type = LaneType.LeftLane;
        if (this.lanes[lanesIndexes[numberOfLanes - 1]].type !== LaneType.TurnRight)
          this.lanes[lanesIndexes[numberOfLanes - 1]].type = LaneType.RightLane;

        lanesIndexes.shift();
        lanesIndexes.pop();

        lanesIndexes.forEach(index => {
          this.lanes[index as number].type = LaneType.CentralLane;
        });
      }
    }
  }

  recalculateGoreNumbers() {
    const goreIndexArray = this.lanes
      .map((lane, index) => {
        if (lane.type === LaneType.Gore) {
          return index;
        } else {
          return undefined;
        }
      })
      .filter(val => val !== undefined);

    let number = 1;
    goreIndexArray.forEach(index => (this.lanes[index as number].number = number++));
  }

  recalculateLaneNumbers() {
    const laneIndexArray = this.lanes
      .map((lane, index) => {
        if (
          lane.type !== LaneType.Gore &&
          lane.type !== LaneType.LeftShoulder &&
          lane.type !== LaneType.RightShoulder
        ) {
          return index;
        } else {
          return undefined;
        }
      })
      .filter(val => val !== undefined);

    let number = 1;
    laneIndexArray.forEach(index => (this.lanes[index as number].number = number++));
  }

  isLaneType(type) {
    if (
      type === LaneType.LeftLane ||
      type === LaneType.CentralLane ||
      type === LaneType.RightLane ||
      type === LaneType.TurnLeft ||
      type === LaneType.TurnRight
    ) {
      return true;
    }
    return false;
  }

  isLaneSubType(type) {
    if (type !== LaneType.Gore && type !== LaneType.LeftShoulder && type !== LaneType.RightShoulder) {
      return true;
    }
    return false;
  }

  openLaneMenuModal() {
    const dialogRef = this.dialog.open(LaneMenuModalComponent, {
      disableClose: false,
      panelClass: 'lane-menu-modal',
      data: {
        contextMenuOptions: this.contextMenuOptions,
      },
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data && data.type) this.addLane(data);
    });
  }

  canAddGore() {
    if (this.lanes) {
      const simpleLanes = this.lanes.filter(lane => this.isLaneSubType(lane.type));
      return simpleLanes.length > 1 ? true : false;
    }
    return true; //default
  }

  getMaxGoreIndex() {
    if (this.lanes[this.lanes.length - 1].type === LaneType.RightShoulder) {
      return this.lanes.length - 2;
    } else return this.lanes.length - 1;
  }

  isDirectionPopulatedForSingleMode() {
    if (this.isOneDirection && this.lanes && this.lanes[0]?.direction) {
      this._direction = this._direction || this.lanes[0]?.direction;
      this.isDirectionAutopopulated = true;
      return true;
    } else {
      this.isDirectionAutopopulated = false;
      return false;
    }
  }

  reloadSwiperConfig() {
    this.affectedLanesSlider?.initSwiperConfig();
  }

  ngOnDestroy(): void {
    if (this.segmentSubscription) this.segmentSubscription.unsubscribe();
  }
}
