import { coerceArray } from '@wc/utils';
import moment from 'moment';
import { UIIncident } from './extended.models';
import {
  AdditionalInfo,
  AssociatedCamera,
  CreateIncidentMitigationInput,
  CreateIncidentMutationInput,
  CreateIncidentNoteInput,
  CreateLaneInput,
  Incident,
  IncidentAdditionalInfoInput,
  IncidentInput,
  IncidentInvolvedVehicle,
  IncidentLane,
  IncidentMitigation,
  IncidentMitigationInput,
  IncidentNote,
  IncidentReportSourceInput,
  IncidentStatus,
  IncidentUnit,
  ReportSource,
  Status,
  StreetCamera,
  UnitResponse,
  UpdateIncidentInput,
  UserDetails,
} from './gql.models';

export enum OfflineIncidentType {
  create,
  update,
}
export class OfflineIncident {
  type?: OfflineIncidentType;
  incident: UIIncident;
  affectedLanes?: Array<IncidentLane>;
  submitted: boolean;

  constructor(
    accountId: number,
    public input?: CreateIncidentMutationInput,
    tempIncidentId?: string,
    isOffline?: boolean
  ) {
    this.submitted = false;
    this.input =
      this.input ||
      ({
        address: { point: {} },
        // affectedLanes: [],
        allLanesAffected: false,
        multiDirectionLanesAffected: false,
        // location: null,
        // status: null,
        // type: null,
        autoPublish: false,
      } as CreateIncidentMutationInput);
    this.input.offlineUpdatedAt = moment().toISOString();
    this.incident = OfflineIncident.incidentFromInput({ ...this.input }, accountId, isOffline) as Incident;
    this.incident.id = tempIncidentId as any;
  }

  static generateID() {
    return `temp-${Date.now().toString().slice(7, -1)}`;
  }

  applyUpdate(updateIncidentInput: UpdateIncidentInput) {
    for (const key of Object.keys(updateIncidentInput)) {
      if (updateIncidentInput[key]) {
        this.incident[key] = updateIncidentInput[key];
      }
    }
    this.input = OfflineIncident.inputFromIncident(this.incident, this.input?.offlineUpdatedAt);
  }

  applyOneTimeUpdate(incidentInput: IncidentInput, currentUserId?) {
    const {
      updateIncidentInput,
      additionalInfoInput,
      cameras,
      involvedVehicles,
      lanes,
      media,
      mitigations,
      notes,
      reportSources,
      units,
    } = incidentInput;

    //restore incident from update input
    for (const key of Object.keys(updateIncidentInput)) {
      if (updateIncidentInput[key]) {
        this.incident[key] = updateIncidentInput[key];
      }
    }
    this.incident['subType'] =
      typeof updateIncidentInput.subType === 'string'
        ? updateIncidentInput.subType
        : updateIncidentInput.subType?.value;
    this.incident['atmsId'] =
      typeof updateIncidentInput.atmsId === 'string' ? updateIncidentInput.atmsId : updateIncidentInput.atmsId?.value;
    this.incident['cadId'] =
      typeof updateIncidentInput.cadId === 'string' ? updateIncidentInput.cadId : updateIncidentInput.cadId?.value;
    this.incident['estimatedEndTime'] =
      typeof updateIncidentInput.estimatedEndTime === 'string'
        ? updateIncidentInput.estimatedEndTime
        : updateIncidentInput.estimatedEndTime?.value;

    const incidentLanes: Array<IncidentLane> = [];
    lanes?.forEach(incidentLane => {
      incidentLanes.push({
        id: incidentLane.id as any,
        direction: incidentLane.lane.direction,
        isAffected: incidentLane.lane.isAffected,
        isClosed: incidentLane.lane.isClosed,
        number: incidentLane.lane.number,
        positionIndex: incidentLane.lane.positionIndex,
        roadType: incidentLane.lane.roadType,
        type: incidentLane.lane.type,
      });
    });
    lanes && (this.incident.affectedLanes = incidentLanes);

    const incidentCameras: any[] = [];
    cameras?.forEach(_camera => {
      if (_camera.cameraExternalId) {
        incidentCameras.push({
          default: _camera.default,
          positionIndex: _camera.positionIndex,
          camera: {
            externalId: _camera.cameraExternalId,
          } as StreetCamera,
        });
      }
    });
    cameras && (this.incident.cameras = incidentCameras as AssociatedCamera[]);

    const incidentInfos: Array<AdditionalInfo> = [];
    additionalInfoInput?.forEach(incidentInfo => {
      incidentInfos.push({
        id: incidentInfo.additionalInfoId,
      } as AdditionalInfo);
    });
    additionalInfoInput && (this.incident.additionalInfos = incidentInfos);

    const incidentvehicles: Array<IncidentInvolvedVehicle> = [];
    involvedVehicles?.forEach(incidentVehicle => {
      incidentvehicles.push({
        id: incidentVehicle.id as any,
        licensePlateNumber: incidentVehicle.involvedVehicle?.licensePlateNumber,
        color: incidentVehicle.involvedVehicle?.color,
        make: incidentVehicle.involvedVehicle?.make,
        state: incidentVehicle.involvedVehicle?.state,
        type: incidentVehicle.involvedVehicle?.type,
        model: incidentVehicle.involvedVehicle?.model,
      });
    });
    involvedVehicles && (this.incident.involvedVehicles = incidentvehicles);

    const incidentReportSources: Array<ReportSource> = [];
    reportSources?.forEach(source => {
      incidentReportSources.push({
        id: source.reportSourceId,
      } as ReportSource);
    });
    incidentReportSources && (this.incident.reportSources = incidentReportSources);

    const incidentNotes: Array<IncidentNote> = [];
    notes?.forEach(incidentNote => {
      incidentNotes.push({
        id: incidentNote.id,
        note: incidentNote.note,
      } as IncidentNote);
    });
    notes && (this.incident.notes = incidentNotes);

    let currentUnit = units?.find(unit => unit.driverId === currentUserId);

    if (units && currentUnit && currentUserId) {
      this.incident.associatedUnits.forEach(_associatedUnit => {
        if (_associatedUnit.id === currentUnit?.unitId) {
          _associatedUnit = {
            id: currentUnit?.unitId,
            unitResponse: currentUnit?.response,
            driverDetails: {
              userId: currentUnit?.driverId,
            },
          } as IncidentUnit;
        }
      });
    }
    //units && (this.incident.associatedUnits = incidentUnits);

    const inicdentMitigations: Array<IncidentMitigation> = [];
    mitigations?.forEach(mitigation => {
      inicdentMitigations.push({
        id: mitigation.id,
        interval: mitigation.interval,
        unitId: mitigation.unitId,
        userId: mitigation.driverId,
        mitigationType: {
          id: mitigation.mitigationTypeId,
        },
      } as IncidentMitigation);
    });
    mitigations && (this.incident.mitigations = inicdentMitigations);
    this.incident.isOffline = true;

    //this.input = OfflineIncident.inputFromIncident(this.incident, this.input?.offlineUpdatedAt);
  }

  static offlineIncidentFromIncident(incident: UIIncident, offlineUpdatedAt: string, accountId: number) {
    const offlineIncident: OfflineIncident = new OfflineIncident(
      accountId,
      OfflineIncident.inputFromIncident(incident, offlineUpdatedAt)
    );
    offlineIncident.incident.id = incident.id;
    return offlineIncident;
  }

  // tslint:disable-next-line: member-ordering
  static inputFromIncident(incident: UIIncident, offlineUpdatedAt: string) {
    const input: CreateIncidentMutationInput = {
      additionalInfos: coerceArray(incident.additionalInfos).map((info: AdditionalInfo) => ({
        additionalInfoId: info.id,
      })),
      address: incident.address,
      affectedLanes: incident.affectedLanes.map((lane: IncidentLane) => ({
        direction: lane.direction,
        isAffected: lane.isAffected,
        isClosed: lane.isClosed,
        number: lane.number,
        positionIndex: lane.positionIndex,
        roadType: lane.roadType,
        type: lane.type,
      })),
      multiDirectionLanesAffected: incident.multiDirectionLanesAffected,
      allLanesAffected: incident.allLanesAffected,
      associatedUnits: coerceArray(incident.associatedUnits)
        .filter((unit: IncidentUnit) => unit.driverDetails?.userId)
        .map((unit: IncidentUnit) => ({
          driverId: unit.driverDetails?.userId,
          response: incident.mitigations?.length ? UnitResponse.Mitigated : UnitResponse.OnScene,
          unitId: unit.id,
        })),
      atmsId: incident.atmsId || null,
      cadId: incident.cadId || null,
      cameras: coerceArray(incident.cameras).map((camera: AssociatedCamera) => ({
        cameraExternalId: camera.camera?.externalId,
        default: camera.default,
      })),
      endedAt: incident.endedAt,
      estimatedEndTime: incident.estimatedEndTime,
      injuries: incident.injuries,
      involvedVehicles: coerceArray(incident.involvedVehicles).map((involvedVehicle: IncidentInvolvedVehicle) => ({
        licensePlateNumber: involvedVehicle.licensePlateNumber,
        color: involvedVehicle.color,
        model: involvedVehicle.model,
        state: involvedVehicle.state,
        type: involvedVehicle.type,
      })),
      location: incident.location,
      mitigations: coerceArray(incident.mitigations)
        .filter((mitigation: IncidentMitigation) => mitigation.userId)
        .map(
          (mitigation: IncidentMitigation) =>
            ({
              driverId: mitigation.userId,
              interval: mitigation.interval,
              mitigationTypeId: mitigation.mitigationType.id,
              unitId: mitigation.unitId,
            } as CreateIncidentMitigationInput)
        ),
      notes: coerceArray(incident.notes).map((note: IncidentNote) => ({
        note: note.note,
      })),
      offlineUpdatedAt,
      reportSources: coerceArray(incident.reportSources).map((reportSource: ReportSource) => ({
        reportSourceId: reportSource.id,
      })),
      startedAt: incident.startedAt,
      status: incident.status,
      subType: incident.subType,
      type: incident.type,
      typeDescription: incident.typeDescription,
      autoPublish: incident.autoPublish,
    };
    return input;
  }

  // tslint:disable-next-line: member-ordering
  static incidentFromInput(
    input: CreateIncidentMutationInput | UpdateIncidentInput | Incident,
    accountId: number,
    isOffline?: boolean
  ) {
    let additionalInfos: IncidentAdditionalInfoInput[] | AdditionalInfo[] | undefined = [];
    if ('additionalInfos' in input) {
      additionalInfos = coerceArray(input.additionalInfos);
    } else {
      additionalInfos = undefined;
    }
    let affectedLanes: CreateLaneInput[] | IncidentLane[] | undefined = [];
    if ('affectedLanes' in input) {
      affectedLanes = coerceArray(input.affectedLanes);
    }

    let associatedUnits: IncidentUnit[] = [];
    let inputAssociatedUnits = input['associatedUnits'] as any;
    if ('associatedUnits' in input) {
      associatedUnits = coerceArray(inputAssociatedUnits);
      if (inputAssociatedUnits.length && inputAssociatedUnits[0]['id']) {
        associatedUnits = inputAssociatedUnits;
      }
      if (inputAssociatedUnits.length && inputAssociatedUnits[0]['unitId']) {
        associatedUnits = inputAssociatedUnits.map(
          unit =>
            ({
              id: unit.unitId,
              unitResponse: unit.response,
              // accountId: null,
              // displayId: null,
              // externalId: null,
              status: Status.Active,
              // type: null,
              driverDetails: {
                userId: unit.driverId,
              } as UserDetails,
            } as IncidentUnit)
        );
      }
    }

    let cameras: any[] | undefined = [];
    let inputCameras = input['cameras'] as any;
    if ('cameras' in input) {
      cameras = coerceArray(inputCameras);
      cameras =
        inputCameras?.map(camera => ({
          camera: {
            id: camera.cameraId,
          },
          default: camera.default,
        })) || [];
    } else {
      cameras = undefined;
    }

    let involvedVehicles: any[] | undefined = [];
    let inputInvolvedVehicles = input['involvedVehicles'] as any;
    if ('involvedVehicles' in input) {
      involvedVehicles = coerceArray(inputInvolvedVehicles);
      if (inputInvolvedVehicles && inputInvolvedVehicles[0]) {
        if (inputInvolvedVehicles[0]['vehicle']) {
          involvedVehicles = inputInvolvedVehicles.map(incidenVehicle => incidenVehicle);
        } else {
          involvedVehicles = inputInvolvedVehicles.map(incidenVehicle => ({
            //vehicle: {
            ...incidenVehicle,
            //}
          }));
        }
      }
    } else {
      involvedVehicles = undefined;
    }

    let mitigations: IncidentMitigationInput[] | IncidentMitigation[] | undefined = [];
    let inputMitigations = input['mitigations'];
    if ('mitigations' in input) {
      mitigations = coerceArray(input.mitigations);
      if (inputMitigations[0] && (inputMitigations[0].unitDisplayId || inputMitigations[0].id)) {
        mitigations = inputMitigations;
      } else {
        mitigations =
          inputMitigations?.map(
            (mitigation: IncidentMitigationInput) =>
              ({
                mitigationType: {
                  id: mitigation.mitigationTypeId,
                },
                interval: mitigation.interval,
                // unitDisplayId: null,
                // unitType: null,
                userId: mitigation.driverId,
                unitId: mitigation.unitId,
              } as IncidentMitigation)
          ) || [];
      }
    } else {
      mitigations = undefined;
    }

    let notes: any[] | null = [];
    if ('notes' in input) {
      notes = coerceArray(input.notes);
    } else {
      notes = null;
    }

    let reportSources: any[] | null = [];
    if ('reportSources' in input) {
      reportSources = coerceArray(input.reportSources);
    } else {
      reportSources = null;
    }

    let status: IncidentStatus | null = null;
    if ('status' in input) {
      status = input.status || null;
    }

    let isAddressEstimated: boolean = false;
    if ('isAddressEstimated' in input) {
      isAddressEstimated = input.isAddressEstimated;
    }

    const newIncident = Object.assign(
      {
        isOffline: isOffline || false,
        id: (input as Incident)?.id || (input as UpdateIncidentInput)?.incidentId || OfflineIncident.generateID(),
        createdAt: moment().toISOString(),
        updatedAt: moment().toISOString(),
        isConfirmed: false,
        isUnconfirmed: true,
        status,
        isAddressEstimated,
        address: input.address,
        allLanesAffected: input.allLanesAffected,
        location: input.location,
        type: input.type || 'OTHER',
        media: [],
      },
      input,
      {
        additionalInfos:
          additionalInfos?.map(additionalInfo => ({
            id: additionalInfo.additionalInfoId || additionalInfo.id,
            accountId: additionalInfo.accountId,
            info: additionalInfo.info,
          })) || null,
        affectedLanes: affectedLanes.map((affectedLane: IncidentLane | CreateLaneInput) => ({
          id: (affectedLane as Partial<IncidentLane>).id ?? null,
          ...affectedLane,
        })),
        associatedUnits: associatedUnits,
        cameras,
        involvedVehicles: involvedVehicles,
        mitigations,
        notes: notes?.map((note: CreateIncidentNoteInput) => note as IncidentNote) || null,
        reportSources:
          reportSources?.map(
            (reportSource: IncidentReportSourceInput) =>
              ({
                id: reportSource.reportSourceId,
                accountId: accountId,
                status: Status.Active,
                reportSource: '',
                ...reportSource,
              } as ReportSource)
          ) || null,

        subType: typeof input.subType === 'string' ? input.subType : input.subType?.value,
        startedAt: input?.startedAt || moment().toISOString(),
        multiDirectionLanesAffected: input.multiDirectionLanesAffected,
      }
    );

    if ('atmsId' in input) {
      newIncident['atmsId'] = typeof input.atmsId === 'string' ? input.atmsId : input.atmsId?.value;
    }
    if ('cadId' in input) {
      newIncident['cadId'] = typeof input.cadId === 'string' ? input.cadId : input.cadId?.value;
    }
    if ('estimatedEndTime' in input) {
      newIncident['estimatedEndTime'] =
        typeof input.estimatedEndTime === 'string' ? input.estimatedEndTime : input.estimatedEndTime?.value;
    }
    if ('autoPublish' in input) {
      newIncident['autoPublish'] = input.autoPublish;
    }
    return newIncident;
  }
}
