import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CollaborationViewerComponent } from './collaboration-viewer.component';

@NgModule({
  declarations: [CollaborationViewerComponent],
  imports: [CommonModule, TranslateModule],
  exports: [CollaborationViewerComponent],
})
export class CollaborationViewerModule {}
