import { LiveMapEntityType, MapLayer } from './models';

export const UnitsLayersConfig: {
  [layerName in LiveMapEntityType]?: MapLayer;
} = {
  fsp_unit: {
    zIndex: 9,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'green_arrow', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit_direction_arrow.hover', anchor: [0.5, 0.5] },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit.selected', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit_direction_arrow.selected', anchor: [0.5, 0.64] },
          },
        ],
      },
      onbreak: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'blue_arrow', anchor: [0.5, 2.3] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'unit break', anchor: [0, 1], scale: 0.8, disableRotation: true },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit_direction_arrow.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'unit break', anchor: [0, 3], scale: 0.5, disableRotation: true },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit.selected', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'fsp_unit_direction_arrow.selected', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'unit break', anchor: [0, 1], scale: 0.8, disableRotation: true },
          },
        ],
      },
    },
  },
  city_police_unit: {
    pointStatusStyle: {
      busy: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'police_unit_busy_default', anchor: [0.5, 0.585] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'city_police_unit_badge', anchor: [0.5, 0.5], disableRotation: true },
          },
        ],
      },
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'police_unit', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'city_police_unit_badge', anchor: [0.5, 0.5], disableRotation: true },
          },
          {
            shape: 'marker',
            icon: { iconName: 'blue_arrow', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'police_unit.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'police_unit_direction_arrow.hover', anchor: [0.5, 0.63] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'city_police_unit_badge', anchor: [0.5, 0.57], disableRotation: true },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'police_unit.selected', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'police_unit_direction_arrow.selected', anchor: [0.5, 0.63] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'city_police_unit_badge', anchor: [0.5, 0.57], disableRotation: true },
          },
        ],
      },
    },
  },
  highway_police_unit: {
    pointStatusStyle: {
      busy: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'police_unit_busy_default', anchor: [0.4, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'highway_police_unit_badge', anchor: [0.5, 0.5], disableRotation: true },
          },
        ],
      },

      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'police_unit', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'highway_police_unit_badge', anchor: [0.5, 0.5], disableRotation: true },
          },
          {
            shape: 'marker',
            icon: { iconName: 'blue_arrow', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'police_unit.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'police_unit_direction_arrow.hover', anchor: [0.5, 0.63] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'highway_police_unit_badge', anchor: [0.5, 0.57], disableRotation: true },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'police_unit.selected', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'police_unit_direction_arrow.selected', anchor: [0.5, 0.63] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'highway_police_unit_badge', anchor: [0.5, 0.57], disableRotation: true },
          },
        ],
      },
    },
  },
  fire_department_unit: {
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'fire_department_unit', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'blue_arrow', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'fire_department_unit.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'fire_department_unit_direction_arrow.hover', anchor: [0.5, 0.5] },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'fire_department_unit.selected', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'fire_department_unit_direction_arrow.selected', anchor: [0.5, 0.64] },
          },
        ],
      },
    },
  },
  construction_unit: {
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'construction_unit', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'orange_arrow', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'construction_unit.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'construction_unit_direction_arrow.hover', anchor: [0.5, 0.5] },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'construction_unit.selected', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'construction_unit_direction_arrow.selected', anchor: [0.5, 0.64] },
          },
        ],
      },
    },
  },
  ems_unit: {
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'ems_unit', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'red_arrow', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'ems_unit.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'ems_unit_direction_arrow.hover', anchor: [0.5, 0.5] },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'ems_unit.selected', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'ems_unit_direction_arrow.selected', anchor: [0.5, 0.64] },
          },
        ],
      },
    },
  },
  maintenance_unit: {
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'maintenance_unit', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'orange_arrow', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'maintenance_unit.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'maintenance_unit_direction_arrow.hover', anchor: [0.5, 0.5] },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'maintenance_unit.selected', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'maintenance_unit_direction_arrow.selected', anchor: [0.5, 0.64] },
          },
        ],
      },
    },
  },
  street_sweep_unit: {
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'street_sweep_unit', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'orange_arrow', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'street_sweep_unit.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'street_sweep_unit_direction_arrow.hover', anchor: [0.5, 0.5] },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'street_sweep_unit.selected', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'street_sweep_unit_direction_arrow.selected', anchor: [0.5, 0.64] },
          },
        ],
      },
    },
  },
  snow_plow_unit: {
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'snowplows_unit', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'orange_arrow', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'snowplows_unit.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'snowplows_unit_direction_arrow.hover', anchor: [0.5, 0.5] },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'snowplows_unit.selected', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'snowplows_unit_direction_arrow.selected', anchor: [0.5, 0.64] },
          },
        ],
      },
    },
  },
  other_unit: {
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'other_unit', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'grey_arrow', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'other_unit.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'other_unit_direction_arrow.hover', anchor: [0.5, 0.5] },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'other_unit.selected', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'other_unit_direction_arrow.selected', anchor: [0.5, 0.64] },
          },
        ],
      },
    },
  },
};
