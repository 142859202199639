/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { CongestionsStoreEntity, CongestionsUIEntity } from '@wc/wc-models/src';
import { map } from 'rxjs/operators';
import { LayerType } from '../../../../../../core/models/gql.models';
import { EntitiesServiceV2 } from '../../../services/entities.service';

export interface CongestionsState extends EntityState<CongestionsStoreEntity, number> {}
export interface CongestionsUIState extends EntityState<CongestionsUIEntity> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: LayerType.Congestion })
export class CongestionsStore extends EntityStore<CongestionsState> {
  ui!: EntityUIStore<CongestionsUIState>;
  constructor(entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$
      .pipe(map(({ CONGESTION }) => CONGESTION || []))
      .subscribe(UICongestions => this.ui.upsertMany(UICongestions));

    entitiesService.getDiff$
      .pipe(
        map(({ modified, removed }) => ({
          removedCongestions: removed?.CONGESTION,
          modifiedCongestions: modified.CONGESTION,
        }))
      )
      .subscribe(({ removedCongestions: removedCongestions, modifiedCongestions }) => {
        if (modifiedCongestions) this.upsertMany(modifiedCongestions);
        if (removedCongestions) this.remove(removedCongestions);
      });

    entitiesService.activeEntityIds$
      .pipe(map(({ CONGESTION }) => CONGESTION || []))
      .subscribe(id => this.ui.setActive(id));
  }
}
