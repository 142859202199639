import { Injectable } from '@angular/core';
import { AuthUserService } from '@wc/wc-core/src/lib/services/auth-user.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(private authUserService: AuthUserService) {}

  set(key: string, data: any): void {
    try {
      const _key = `${key}-${this.authUserService.user.id}`;
      localStorage.setItem(_key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }

  get(key: string) {
    try {
      key = `${key}-${this.authUserService.user.id}`;
      const value = localStorage.getItem(key) as string;
      return value ? JSON.parse(value) : null;
    } catch (e) {
      console.error('Error getting data from localStorage', e);
      return null;
    }
  }

  remove(key: string) {
    try {
      key = `${key}-${this.authUserService.user.id}`;
      localStorage.removeItem(key);
    } catch (e) {
      console.error('Error removing from localStorage', e);
    }
  }
}
