import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TranslateService } from '@ngx-translate/core';
import { HeapAnalyticsService } from '@wc-core';
import { Construction, RoadClosure, SpecialEvent, TimeFormat, TimeZone, TrafficDisruptionScheduleDto } from '@wc/core';
import { TrafficDisruptionStoryEntity } from '@wc/wc-models/src';
import { day } from '@wc/wc-models/src/lib/types/day-type';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { filter } from 'rxjs/operators';
import { FutureDateValidator } from '../../form-validators';
import { CompareDatesValidator } from '../../form-validators/compere-dates.validator';
import { FormFieldData, FormFieldOption } from '../../lib/base/custom-form-control';
import {
  duplicatedTimeRange,
  formatMinutesToStringTime,
  formatStringTimeToMinutes,
  overlappingTimeRange,
  sameDayEndBeforeStartTime,
  TimeFramesError,
} from './event-scheduler-utils';

export type TDScheduleForm = TrafficDisruptionScheduleDto & {
  startTime: Date;
  endTime?: Date;
  isCustomSchedule: boolean;
  isAllDay: boolean;
};

@Component({
  selector: 'wc-event-scheduler',
  templateUrl: './event-scheduler.component.html',
  styleUrls: ['./event-scheduler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventSchedulerComponent implements OnInit {
  @Input() firstDayOfTheWeek: number = day.Monday;
  @Input() timeFormat: TimeFormat = TimeFormat.TwentyFourHours;
  @Input() timeZone: TimeZone = TimeZone.UsPacific;
  @Input() entity!: Partial<RoadClosure | SpecialEvent | Construction>;
  @Input() isEndTImeMandatory = false;

  form: FormGroup = new FormGroup({});
  timeFramesErrorEnum = TimeFramesError;

  fieldData: { [key: string]: FormFieldData } = {
    toggleModeData: {
      options: [
        { displayName: this.translateService.instant('basic'), value: false },
        { displayName: this.translateService.instant('schedulerType.custom'), value: true },
      ],
    },
    startTime: { label: 'startTime' },
    endTime: { label: 'endTime', required: true },
    days: {
      options: this.daysOptions(),
    },
  };

  get isCustomSchedule() {
    return this.form.get('isCustomSchedule')?.value || false;
  }

  get timeRangesArr() {
    return this.form.get('timeRanges') as FormArray;
  }

  get isAllDayDisabled() {
    return !this.form.get('endTime')?.value;
  }

  get isMaxFramesAdded() {
    return this.timeRangesArr.length === 21;
  }

  constructor(
    private fb: FormBuilder,
    protected cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private heapService: HeapAnalyticsService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form = this.fb.group(
      {
        isCustomSchedule: this.entity.schedule?.timeRanges ? this.entity.schedule?.timeRanges.length > 0 : false,
        startTime: [this.entity.startTime || new Date(), Validators.required],
        endTime: [
          this.entity.endTime,
          this.isEndTImeMandatory ? [FutureDateValidator, Validators.required] : [FutureDateValidator],
        ],
        isAllDay: this.entity.isAllDay || false,
        timeZone: this.timeZone,
        timeRanges: this.fb.array([]),
      },
      { validators: [CompareDatesValidator('startTime', 'endTime'), duplicatedTimeRange(), overlappingTimeRange()] }
    );
    this.entity.schedule?.timeRanges.forEach(range => {
      this.timeRangesArr.push(this.getRangeAsFormGroup(range));
    });

    if (!this.form.get('isCustomSchedule')?.value) this.addDefaultTimeFrame();

    this.form.get('isCustomSchedule')?.valueChanges.subscribe(value => {
      if (this.form.get('endTime')?.value) this.form.get('isAllDay')?.setValue(true, { emitEvent: false });
      this.heapService.trackUserSpecificAction('TrafficDisruptionSetCustomScheduler', { isCustom: value });

      this.allDayChecked(undefined, true);
    });

    this.form
      .get('startTime')
      ?.valueChanges.pipe(filter(() => this.form.get('isCustomSchedule')?.value))
      .subscribe(() => {
        this.allDayChecked(undefined, true);
      });

    this.form
      .get('endTime')
      ?.valueChanges.pipe(filter(() => this.form.get('isCustomSchedule')?.value))
      .subscribe(() => {
        this.allDayChecked(undefined, true);
      });
  }

  private getRangeAsFormGroup(range) {
    return this.fb.group(
      {
        fromDayOfWeekLocal: [range?.fromDayOfWeekLocal || 0],
        toDayOfWeekLocal: [range?.toDayOfWeekLocal] || 0,
        fromTimeMinutesLocal: [formatMinutesToStringTime(range?.fromTimeMinutesLocal || 0, this.timeFormat)],
        toTimeMinutesLocal: [formatMinutesToStringTime(range?.toTimeMinutesLocal || 0, this.timeFormat)],
      },
      { validators: [sameDayEndBeforeStartTime()] }
    );
  }

  private daysOptions(): FormFieldOption<number, string>[] {
    const daysArray = Object.keys(day);
    const orderedByFirstDay =
      this.firstDayOfTheWeek === day.Monday
        ? daysArray
        : [daysArray[daysArray.length - 1], ...daysArray.slice(0, daysArray.length - 1)];
    return orderedByFirstDay.map(dayKey => ({
      value: day[dayKey],
      displayName: this.translateService.instant('dayOfWeek.' + dayKey.toUpperCase()),
    }));
  }

  allDayChecked(event?: MatCheckboxChange, forceAllDay = false) {
    if (event) this.heapService.trackUserSpecificAction('TrafficDisruptionSchedularIsAllDay', { on: event?.checked });
    if (event?.checked || forceAllDay) {
      const endTime = this.form.get('endTime')?.value ? new Date(this.form.get('endTime')?.value) : undefined;
      const startTime = this.form.get('startTime')?.value ? new Date(this.form.get('startTime')?.value) : undefined;
      if (startTime) {
        startTime.setHours(0);
        startTime.setMinutes(0);
        this.form.get('startTime')?.setValue(startTime, { emitEvent: false });
      }

      if (endTime) {
        endTime.setHours(23);
        endTime.setMinutes(59);
        this.form.get('endTime')?.setValue(endTime, { emitEvent: false });
      }
    }
  }

  private addDefaultTimeFrame() {
    const startDayOfRange = new Date(this.form.get('startTime')?.value).getDay(); // JS sunday eq 0, Kotlin sunday eq 7;
    const group = this.getRangeAsFormGroup({
      fromDayOfWeekLocal: startDayOfRange === 0 ? 7 : startDayOfRange,
      toDayOfWeekLocal: startDayOfRange === 0 ? 7 : startDayOfRange,
      fromTimeMinutesLocal: '10:00',
      toTimeMinutesLocal: '18:00',
    });
    this.timeRangesArr.push(group);
  }

  addTimeFrame() {
    const range = cloneDeep(this.timeRangesArr.at(this.timeRangesArr.length - 1)?.value);
    if (range) {
      range.fromDayOfWeekLocal = (range.fromDayOfWeekLocal % 7) + 1;
      range.toDayOfWeekLocal = (range.toDayOfWeekLocal % 7) + 1;
      this.heapService.trackUserSpecificAction('TrafficDisruptionSchedularAddTimeFrame');
    }
    const group = this.getRangeAsFormGroup(range);
    this.timeRangesArr.push(group);
  }

  removeTimeFrame(index: number) {
    if (this.timeRangesArr.length === 1) return;
    this.timeRangesArr.removeAt(index);
    this.heapService.trackUserSpecificAction('TrafficDisruptionSchedularRemoveTimeFrame');
  }

  getParsedValue<FormType extends Partial<TrafficDisruptionStoryEntity>>(): FormType {
    const eventScheduleValue = this.form.value as TDScheduleForm;
    const value = {} as FormType;
    let schedule;
    value.startTime = moment(eventScheduleValue.startTime).toISOString();
    value.endTime = eventScheduleValue.endTime ? moment(eventScheduleValue.endTime).toISOString() : undefined;
    value.isAllDay = eventScheduleValue.isAllDay;

    if (!eventScheduleValue.isCustomSchedule) {
      if (this.entity.schedule && this.entity.schedule?.timeRanges.length !== 0) {
        schedule = { value: null };
      } else {
        schedule = null;
      }
    } else {
      schedule = {
        timeZone: this.timeZone,
        timeRanges: eventScheduleValue.timeRanges.map(t => {
          return {
            ...t,
            toTimeMinutesLocal: formatStringTimeToMinutes(t.toTimeMinutesLocal),
            fromTimeMinutesLocal: formatStringTimeToMinutes(t.fromTimeMinutesLocal),
          };
        }),
      };
    }

    return { ...value, schedule };
  }
}
