import { EventEmitter, Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { TranslateService } from '@ngx-translate/core';
import { EntitiesServiceV2 } from '@wc-core';
import { ConstructionStoreEntity } from '@wc/wc-models/src';
import deepmerge from 'deepmerge';
import * as _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import * as moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import * as Utils from '../../utils';
import {
  LiveMapEntity,
  LiveMapEntityType,
  MainEntityType,
  TrafficDisruptionEntityStore,
  TrafficDisruptionView,
} from '../models';
import {
  Construction,
  ConstructionType,
  ConstructionView,
  CreateConstructionInput,
  LayerType,
  PublishInput,
  TrafficDisruptionStatus,
  UpdateConstructionInput,
  UpdateTrafficDisruptionLanesInput,
} from '../models/gql.models';
import { GeoService, SplitIOService } from '../services';
import { ConstructionService } from '../services/construction.service';
import { TrafficDisruptionService } from '../services/traffic-disruption.service';
import { EntitiesStore } from './entities.store';
import { LiveMapStore } from './live-map.store';
import { TrafficDisruptionStore } from './traffic-disruption.store';
import { UiStore } from './ui.store';

@Injectable({
  providedIn: 'root',
})
export class ConstructionStore implements TrafficDisruptionEntityStore {
  moment: any = moment;

  constructor(
    private entitiesStore: EntitiesStore,
    private translateService: TranslateService,
    private liveMapStore: LiveMapStore,
    private constructionService: ConstructionService,
    private trafficDisruptionService: TrafficDisruptionService,
    private uiStore: UiStore,
    private trafficDisruptionStore: TrafficDisruptionStore,
    private geoService: GeoService,
    private entitiesService2: EntitiesServiceV2,
    private splitIoService: SplitIOService
  ) {
    makeObservable(this);
  }

  @observable selectedConstructionType?: ConstructionType | undefined = undefined;
  @observable selectedTrafficDisruption = {} as TrafficDisruptionView;
  @observable selectedConstructionBeforeEdit = {} as TrafficDisruptionView;
  @observable completedConstructions: (Construction | ConstructionView)[] = [];
  @observable otherTypeDescription: string | undefined = undefined;
  submitFormEvent: EventEmitter<any> = new EventEmitter();

  private readonly _onConstructionEdited = new Subject<number>();
  readonly onConstructionEdited$ = this._onConstructionEdited.asObservable();

  private set onConstructionEdited(constructionId: number) {
    this._onConstructionEdited.next(constructionId);
  }

  @action
  emitSubmitFormEvent() {
    this.submitFormEvent.emit();
  }

  @action
  setConstructionType(type: ConstructionType) {
    runInAction(() => {
      this.selectedConstructionType = type;
    });
  }

  @action
  setOtherTypeDescription(description: string) {
    this.otherTypeDescription = description;
  }

  @action
  getCompletedConstructionList() {
    this.constructionService.fetchCompleted$
      .pipe(
        tap(constructions => {
          this.completedConstructions = constructions;
          this.entitiesService2.emitNewEntitiesDiff({
            modified: {
              CONSTRUCTION: constructions,
            },
          });
        })
      )
      .subscribe(constructions => {
        this.trafficDisruptionStore.completedTrafficDisruptions.construction = constructions;
      });
  }

  @computed
  get constructionList() {
    if (this.entitiesStore.entities && Object.keys(this.entitiesStore.entities).length === 0) return [];

    const constructions = this.entitiesStore.entities.construction;
    let constructionList = Utils.toArray(constructions);
    constructionList = constructionList.filter(construction => {
      let valid =
        !construction.scheduled ||
        (construction.scheduled && this.moment(construction.startTime).isAfter(this.moment().subtract(2, 'hour')));
      if (valid && construction['workspaces'] && construction['workspaces'].length > 0)
        valid = !!Utils.compareTwoArrays(construction['workspaces'] || [], this.liveMapStore.selectedWorkspacesIds)
          .length;
      return valid;
    });

    return constructionList;
  }

  @action
  getConstruction(id: number) {
    return this.constructionService.get(id);
  }

  createConstruction(construction: CreateConstructionInput) {
    construction.otherTypeDescription = this.otherTypeDescription;
    return this.constructionService.create(construction).pipe(
      tap((_construction: Construction) => {
        this.uiStore.setInteractedEntityId(_construction.id, MainEntityType.traffic_disruption);
        this.clearTrafficDisruptionSelection();
        this.liveMapStore.removeInteractiveEntity();
        this.liveMapStore.changeLayersVisibility(true, ['construction']);
        this.updateConstructionMapEntity(_construction);
        this.trafficDisruptionStore.uploadFiles(_construction.id);
      })
    );
  }

  updateConstruction(
    construction: Construction | ConstructionStoreEntity
  ): Observable<Construction | ConstructionStoreEntity | null> {
    return !this.uiStore.isTabletMode
      ? this.trafficDisruptionStore.updateTrafficDisruption(
          LayerType.Construction,
          construction as ConstructionStoreEntity
        )
      : this.oldUpdateConstruction(construction as Construction);
  }

  oldUpdateConstruction(construction: Construction): Observable<Construction> {
    this.uiStore.showLoader('construction');
    this.liveMapStore.removeInteractiveEntity();

    // affected lanes updates
    if (this._shouldUpdateAffectedLanes(construction, this.selectedConstructionBeforeEdit)) {
      const affectedLanesDiff = Utils.affectedLanesArrayDiff(
        this.selectedConstructionBeforeEdit.affectedLanes,
        construction.affectedLanes
      );
      if (
        affectedLanesDiff.modified.length > 0 ||
        affectedLanesDiff.removed.length > 0 ||
        affectedLanesDiff.created.length > 0
      ) {
        this.trafficDisruptionService
          .updateTrafficDisruptionLanes({
            trafficDisruptionId: construction.id,
            modified: affectedLanesDiff.modified,
            removed: affectedLanesDiff.removed,
            created: affectedLanesDiff.created,
          })
          .subscribe();
      }
    }

    delete construction.affectedLanes;
    if ((this.selectedConstructionBeforeEdit as Construction).otherTypeDescription !== this.otherTypeDescription) {
      construction.otherTypeDescription = this.otherTypeDescription;
    }

    // check for update the rest fields
    if (this._shouldUpdateConstruction(construction, this.selectedConstructionBeforeEdit)) {
      const updateConstructionInput: Partial<Construction> = {
        ...{ id: construction.id },
        ...Utils.objectDiff(this.selectedConstructionBeforeEdit, construction),
      };

      const input: UpdateConstructionInput = updateConstructionInput as unknown as UpdateConstructionInput;
      if (Object(updateConstructionInput).hasOwnProperty('media')) delete input.media;
      if (Object(updateConstructionInput).hasOwnProperty('address')) input.address = { value: construction.address };
      if (Object(updateConstructionInput).hasOwnProperty('contactPerson'))
        input.contactPerson = { value: updateConstructionInput.contactPerson };
      if (Object(updateConstructionInput).hasOwnProperty('description'))
        input.description = { value: updateConstructionInput.description };
      if (Object(updateConstructionInput).hasOwnProperty('endTime'))
        input.endTime = { value: updateConstructionInput.endTime };

      return this.constructionService.update(input).pipe(
        tap((_construction: Construction) => {
          this.updateConstructionMapEntity(_construction);
          const constructionIndex = this.trafficDisruptionStore.completedTrafficDisruptions.construction.findIndex(
            construction => _construction.id === construction.id
          );
          if (constructionIndex > -1) {
            const updatedConstruction =
              this.trafficDisruptionStore.completedTrafficDisruptions.construction[constructionIndex];
            this.trafficDisruptionStore.completedTrafficDisruptions.construction[constructionIndex] = deepmerge(
              updatedConstruction,
              _construction
            );
          }
          this.trafficDisruptionStore.uploadFiles(_construction.id);
          this.trafficDisruptionStore.deleteTrafficDisruptionMedia(_construction.id);
        }),
        catchError(() => of({} as Construction)),
        finalize(() => this.uiStore.hideLoader('construction'))
      );
    } else {
      this.uiStore.hideLoader('construction');
      return of({} as Construction);
    }
  }

  updateConstructionMapEntity(construction: Construction) {
    const workspaces = this.geoService.workspacesByLocation(construction.location.coordinates);
    const updatedMapLayer = {
      [MainEntityType.construction]: {
        [construction.id]: {
          ...construction,
          ...{
            featureType: MainEntityType.construction,
            featureSubTypeOf: MainEntityType.construction,
            workspaces,
          },
        },
      },
    };

    this.entitiesStore
      .modifiedEntities(
        updatedMapLayer as {
          [layerName in LiveMapEntityType]?: { [id in string]: LiveMapEntity };
        }
      )
      .then(() => {
        this.onConstructionEdited = construction.id;
        this.liveMapStore.updateModifiedEntities(
          updatedMapLayer as {
            [layerName in LiveMapEntityType]?: {
              [id in string]: LiveMapEntity;
            };
          }
        );
        this.liveMapStore.setSelectedWorkspaces(workspaces, false);
        this.clearTrafficDisruptionSelection();
      });
  }

  @action
  selectTrafficDisruption(construction: TrafficDisruptionView, zoomOn: boolean = true) {
    !this.uiStore.isTabletMode
      ? this.trafficDisruptionStore.selectTrafficDisruption(
          { ...construction, layerType: LayerType.Construction } as ConstructionStoreEntity,
          zoomOn
        )
      : this.oldSelectConstruction(construction as Construction, zoomOn);
  }

  oldSelectConstruction(construction: Construction, zoomOn: boolean) {
    construction.affectedLanes = Utils.sortAffectedLanes(construction.affectedLanes);
    this.selectedTrafficDisruption = { ...{}, ...construction };
    this.selectedConstructionBeforeEdit = { ...{}, ...construction };
    this.uiStore.hideLoader('construction');
    if (zoomOn) this.liveMapStore.zoomOnEntity(construction, 15);
    this.trafficDisruptionStore.selectedTrafficDisruption = construction;
    if (this.isCompleted(construction)) {
      this._updateCompletedConstructionMapEntity(construction);
    } else {
      setTimeout(() => {
        if (!this.uiStore.isTabletMode)
          if ((construction as Construction).type && construction.id) {
            const typeEntities = this.entitiesStore.entities['construction'];
            if (typeEntities) {
              const entityFeature = typeEntities[construction.id];
              if (entityFeature) {
                this.liveMapStore.selectEntityFeature(entityFeature);
              }
            }
          }
      });
    }
    datadogLogs.removeLoggerGlobalContext('wc_construction_details');
    datadogLogs.addLoggerGlobalContext('wc_construction_details', JSON.stringify(this.selectedTrafficDisruption));
  }

  clearTrafficDisruptionSelection() {
    !this.uiStore.isTabletMode
      ? this.trafficDisruptionStore.clearTrafficDisruptionSelection()
      : this.oldClearTrafficDisruption();
  }

  oldClearTrafficDisruption() {
    if (this.isCompleted(this.selectedTrafficDisruption)) {
      this.liveMapStore.updateRemovedEntities({
        [MainEntityType.road_closure]: [this.selectedTrafficDisruption.id],
      });
    }
    this.liveMapStore.clearMapDraw();
    this.liveMapStore.unselectFeature('construction');
    this.selectedConstructionBeforeEdit = {} as Construction;
    this.selectedTrafficDisruption = {} as Construction;
    this.otherTypeDescription = undefined;
    datadogLogs.removeLoggerGlobalContext('wc_construction_details');
  }

  @action
  setConstructionAddressCoords(coords: number[] | number[][] | number[][][], type: 'Point' | 'LineString' | 'Polygon') {
    this.selectedTrafficDisruption.address = null;
    this.selectedTrafficDisruption = {
      ...this.selectedTrafficDisruption,
      ...{
        address:
          type === 'Point'
            ? {
                point: {
                  coordinates: coords,
                  type: 'Point',
                },
              }
            : null,
        location: {
          coordinates: coords,
          type: type,
        },
      },
    };
  }

  setTrafficDisruptionStatus(construction: Construction | LiveMapEntity, status: TrafficDisruptionStatus) {
    const constructionId: number = Number(construction.id);
    if (status === TrafficDisruptionStatus.Completed) {
      const entity = this.entitiesStore.entities.construction
        ? this.entitiesStore.entities.construction[construction.id]
        : undefined;
      this.uiStore.showLoader('construction');
      return this.constructionService.complete(constructionId).pipe(
        tap((bool: Boolean | undefined) => {
          if (bool) {
            if ('affectedLanes' in construction && construction.affectedLanes?.length) {
              this.openAllAffectedLanes(construction);
            } else {
              this.getConstruction(constructionId).subscribe(constructionRes => {
                if (constructionRes && constructionRes.affectedLanes?.length) {
                  this.openAllAffectedLanes(constructionRes);
                }
              });
            }
            entity.status = status;
            if (this.entitiesStore.entities.construction) {
              delete this.entitiesStore.entities.construction[construction.id];
            }
            this.entitiesStore.entities = {
              ...{},
              ...this.entitiesStore.entities,
            };
            this.liveMapStore.updateRemovedEntities({
              construction: [constructionId],
            });
          }
        }),
        finalize(() => this.uiStore.hideLoader('construction'))
      );
    }
    return of(false);
  }

  private isCompleted(construction: TrafficDisruptionView) {
    if (construction.status === TrafficDisruptionStatus.Completed) {
      return true;
    }
    return false;
  }

  _updateCompletedConstructionMapEntity(construction: TrafficDisruptionView) {
    const workspaces = this.geoService.workspacesByLocation(construction.location.coordinates);
    const updatedMapLayer = {
      [MainEntityType.construction]: {
        [construction.id]: {
          ...construction,
          ...{
            featureType: MainEntityType.construction,
            featureSubTypeOf: MainEntityType.construction,
            workspaces,
          },
        },
      },
    };

    this.liveMapStore.updateModifiedEntities(
      updatedMapLayer as {
        [layerName in LiveMapEntityType]?: { [id in string]: LiveMapEntity };
      }
    );
  }

  _shouldUpdateConstruction(modifiedConstruction: Construction, construction: TrafficDisruptionView) {
    modifiedConstruction = JSON.parse(JSON.stringify(modifiedConstruction));
    construction = JSON.parse(JSON.stringify(construction));

    const constructionBeforeEdit: any = { ...{}, ...construction };
    const constructionAfterEdit: any = { ...{}, ...modifiedConstruction };
    delete constructionBeforeEdit.status;
    delete constructionBeforeEdit.externalId;
    delete constructionBeforeEdit.otherTypeDescription;
    delete constructionBeforeEdit.affectedLanes;
    delete constructionAfterEdit.affectedLanes;
    constructionBeforeEdit.otherTypeDescription = (construction as Construction).otherTypeDescription;

    return !_.isEqual(constructionBeforeEdit, constructionAfterEdit);
  }

  _shouldUpdateAffectedLanes(modifiedConstruction: Construction, construction: TrafficDisruptionView) {
    if (!modifiedConstruction.affectedLanes) {
      return false;
    }
    if (!construction.affectedLanes && modifiedConstruction.affectedLanes) {
      return true;
    }
    const modifiedAffectedLanes = JSON.parse(JSON.stringify(modifiedConstruction.affectedLanes));
    const affectedLanes = JSON.parse(JSON.stringify(construction.affectedLanes));
    return !_.isEqual(modifiedAffectedLanes, affectedLanes);
  }

  openAllAffectedLanes(construction: Construction) {
    const affectedLanes = construction.affectedLanes;
    affectedLanes?.forEach(lane => {
      lane.isClosed = false;
    });
    return this.trafficDisruptionService
      .updateTrafficDisruptionLanes({
        trafficDisruptionId: construction.id,
        modified: affectedLanes,
        created: [],
        removed: [],
      } as UpdateTrafficDisruptionLanesInput)
      .subscribe();
  }

  publishTrafficDisruption(publishInput: PublishInput) {
    return this.constructionService.publish(publishInput);
  }
}
