import { mapViewerEnvConfig } from '@wc/wc-map-viewer';
import Layer from 'ol/layer/Layer';
import TileLayer from 'ol/layer/Tile';
import * as olMap from 'ol/Map';
import { XYZ } from 'ol/source';
// import BingMaps from 'ol/source/BingMaps.js';
import { mapVendorName } from '../../enums';
import { mapboxglLayer } from '../layer-utils/mapboxgl-layer';

export function satelliteLayer(
  vendorName: mapVendorName,
  apiKey: string,
  map: olMap.default,
  envConfig: mapViewerEnvConfig
): TileLayer | Layer {
  switch (vendorName) {
    case mapVendorName.MAPBOX:
      if (map && apiKey) {
        return mapboxglLayer(
          map.getTarget() as HTMLElement,
          `https://api.mapbox.com/styles/v1/${envConfig.mapboxAccountNameAndStyleId}?access_token=${apiKey}`,
          apiKey
        );
      } else {
        throw 'Mapbox satellite layer missing parameters!';
      }
    // case mapVendorName.BING:
    //   if (envConfig.bingKey) {
    //     return new TileLayer({
    //       preload: Infinity,
    //       source: new BingMaps({
    //         key: envConfig.bingKey, // 'AntT_iYWUZnC9LGrO_6D-rWTRlFrJKBgxEhsBe9mmvZO8wh_lcHslQCLJkN_X0mE',
    //         imagerySet: 'Aerial', //WithLabelsOnDemand',
    //       }),
    //     });
    //   } else {
    //     throw 'Bing satellite layer missing api key!';
    //   }
    case mapVendorName.HERE:
      if (apiKey) {
        return new TileLayer({
          preload: Infinity,
          source: new XYZ({
            url: `https://{1-4}.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png?apiKey=${apiKey}`, //7_Ba4crxp_AHdNlC3RR0WWJLIu5RUJ-utk6n4Df2A0k
            attributions:
              'Map Tiles &copy; ' + new Date().getFullYear() + ' ' + '<a href="http://developer.here.com">HERE</a>',
          }),
        });
      } else {
        throw 'HERE satellite layer missing api key!';
      }
    case mapVendorName.AWS:
      if (apiKey) {
        const region = 'us-east-1';
        const mapName = 'RekorCommandSatelliteMap';
        return new TileLayer({
          preload: Infinity,
          source: new XYZ({
            url: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/tiles/{z}/{x}/{y}?key=${apiKey}`,
            attributions: '&copy; ' + new Date().getFullYear() + ' HERE',
          }),
        });
      } else {
        throw 'HERE satellite layer missing api key!';
      }

    default:
      throw new Error(`Could not find a support vendor for , ${vendorName}`);
  }
}
