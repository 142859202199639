import { ConstructorProvider } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { mapViewerConfigToken, mapViewerEnvConfigToken, wcMapToken } from './injection-tokens';
import { ControlsService } from './lib/private-services/controls.service';
import { DrawService } from './lib/private-services/draw.service';
import { FeaturesService } from './lib/private-services/features.service';
import { InteractionsService } from './lib/private-services/interactions.service';
import { LayersService } from './lib/private-services/layers.service';
import { LocationService } from './lib/private-services/location.service';
import { OverlayService } from './lib/private-services/overlay.service';
import { StaticLayersService } from './lib/private-services/static-layers.service';
import { StyleService } from './lib/private-services/style.service';

export const SERVICE_PROVIDERS: ConstructorProvider[] = [
  { provide: LayersService, deps: [wcMapToken] },
  { provide: FeaturesService, deps: [wcMapToken] },
  { provide: LocationService, deps: [wcMapToken] },
  { provide: InteractionsService, deps: [wcMapToken, StyleService, DrawService, mapViewerConfigToken, OverlayService] },
  { provide: DrawService, deps: [wcMapToken, StyleService] },
  { provide: ControlsService, deps: [wcMapToken, TranslateService] },
  { provide: StaticLayersService, deps: [wcMapToken, mapViewerEnvConfigToken, mapViewerConfigToken] },
  { provide: StyleService, deps: [mapViewerConfigToken] },
  { provide: OverlayService },
];

export const SERVICE_PROVIDERS_CLASS_NAMES = [
  'LayersService',
  'FeaturesService',
  'LocationService',
  'InteractionsService',
  'DrawService',
  'ControlsService',
  'StaticLayersService',
  'StyleService',
  'OverlayService',
];
