import { Inject, Injectable, Optional } from '@angular/core';
import {
  AssignUnitToUserGQL,
  CreateUserGQL,
  CreateUserInput,
  DeleteUserGQL,
  DeleteUserInput,
  DeleteUserUnitInput,
  GetCurrentShiftGQL,
  LogoutGQL,
  MeGQL,
  OptionalDateTimePeriodInput,
  SetUserRolesGQL,
  SetUserRolesInput,
  SetUserUnitInput,
  UnassignUnitToUserGQL,
  UpdateUnitLocationGQL,
  UpdateUnitLocationInput,
  UpdateUserGQL,
  UpdateUserInput,
  User,
  UserGQL,
  UsersGQL,
} from '@wc/core/models/gql.models';
import { GeoService } from '@wc/core/services/geo.service';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IsTabletMode } from '../injection-tokens';
import { CustomRxOperatorsService } from './custom-rx-operators.service';
import { SmartlookService } from './smartlook.service';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  authUser!: User;
  isTabletMode = false;
  nameSpace = 'UsersService';

  constructor(
    @Optional() @Inject(IsTabletMode) _isTabletMode: boolean,
    private meGQL: MeGQL,
    private userInfoGQL: UserGQL,
    private updateUserGQL: UpdateUserGQL,
    private createUserGQL: CreateUserGQL,
    private deleteUserGQL: DeleteUserGQL,
    private usersGQL: UsersGQL,
    private setUserRolesGQL: SetUserRolesGQL,
    private assignUnitToUserGQL: AssignUnitToUserGQL,
    private unassignUnitToUserGQL: UnassignUnitToUserGQL,
    private logoutGQL: LogoutGQL,
    private getCurrentShiftGQL: GetCurrentShiftGQL,
    private updateUnitLocationGQL: UpdateUnitLocationGQL,
    private smartlook: SmartlookService,
    private geoService: GeoService,
    private customRxOperators: CustomRxOperatorsService
  ) {
    this.isTabletMode = !!_isTabletMode;
  }

  get authUserAccountID() {
    return this.authUser.account.id;
  }

  isValidId(id: any) {
    return true;
  }

  getAuthUser() {
    //TODO: info user api
    const remoteUser$ = this.meGQL.fetch().pipe(
      map(res => res.data.me as User),
      map(res => {
        console.log({ res });
        return res;
      }),
      tap(user => {
        this.smartlook
          .init()
          .then(() => {
            this.smartlook.setIdentify(user);
          })
          .catch(() => {
            console.log('No smartlook!!!');
          });
        localStorage.setItem('user', JSON.stringify(user));
        this.geoService.setWorkspaces(user.account.workspaces.workspaces);
      })
    );
    return (navigator.onLine ? remoteUser$ : of(this.getStoredUser())).pipe(
      tap((user: User) => {
        this.authUser = user;
      }),

      this.customRxOperators.catchGqlErrors()
    );
  }

  getStoredUser(): User {
    let user;
    try {
      const encodedValue = localStorage.getItem('user');
      user = JSON.parse(encodedValue as string);
    } catch (e) {
      console.error(`Unable to load user!`);
    }
    return user;
  }

  getUserByID(userId: number) {
    return this.userInfoGQL.fetch({ id: userId }).pipe(
      map(res => res.data.user as User),
      this.customRxOperators.catchGqlErrors()
    );
  }

  getUsers(optionalDateTimePeriodInput: OptionalDateTimePeriodInput = { value: null }): Observable<User[]> {
    return this.usersGQL
      .watch({
        optionalDateTimePeriodInput,
      } as never)
      .valueChanges.pipe(
        map(res => res?.data?.users as User[]),
        this.customRxOperators.catchGqlErrors()
      );
  }

  updateUser(input: UpdateUserInput) {
    return this.updateUserGQL.mutate({ input: input }).pipe(
      map(res => res?.data?.updateUser as User),
      this.customRxOperators.catchGqlErrors()
    );
  }

  createUser(input: CreateUserInput) {
    return this.createUserGQL.mutate({ input: input }).pipe(
      map(res => res?.data?.createUser as User),
      this.customRxOperators.catchGqlErrors()
    );
  }

  assignUserRoles(input: SetUserRolesInput) {
    return this.setUserRolesGQL.mutate({ input: input }).pipe(
      map(res => res?.data?.setUserRoles as User),
      this.customRxOperators.catchGqlErrors()
    );
  }

  deleteUser(input: DeleteUserInput) {
    return this.deleteUserGQL.mutate({ input: input }).pipe(this.customRxOperators.catchGqlErrors());
  }

  assignUnitToUser(input: SetUserUnitInput) {
    return this.assignUnitToUserGQL.mutate({ input }).pipe(
      map(res => res?.data?.assignUnitToUser),
      tap(res => {
        const userDetails = {
          ...res?.unit?.userDetails,
          unitRelationType: res?.unitRelationType,
        };
        this.authUser.unit = res?.unit;
      }),
      this.customRxOperators.catchGqlErrors()
    );
  }

  unassignUnitToUser(input: DeleteUserUnitInput) {
    return this.unassignUnitToUserGQL.mutate({ input }).pipe(
      map(res => res?.data?.unassignUnitToUser as User),
      this.customRxOperators.catchGqlErrors()
    );
  }

  updateUnitLocation(input: UpdateUnitLocationInput) {
    return this.updateUnitLocationGQL.mutate({ input }).pipe(
      map(res => res?.data?.updateUnitLocation),
      // TODO: do not send offline location updates unless backend supports it
      this.customRxOperators.catchGqlErrors()
    );
  }

  logoutUser() {
    return this.logoutGQL.mutate();
  }
}
