import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
  AccountShiftTypesGQL,
  CreateShiftGQL,
  CreateShiftInput,
  CreateShiftPauseInput,
  CreateShiftTypeGQL,
  CreateShiftTypeInput,
  DeleteShiftTypeGQL,
  DeleteShiftTypeInput,
  EndShiftGQL,
  EndShiftInput,
  EndShiftPauseGQL,
  RouteType,
  ShiftDto,
  ShiftPauseDto,
  ShiftType,
  StartShiftPauseGQL,
  UnitVehicle,
  UpdateShiftGQL,
  UpdateShiftInput,
  UpdateShiftTypeGQL,
  UpdateShiftTypeInput,
} from '../../core/models/gql.models';
import { CacheService, DataBase } from '../../core/services/cache.service';
import { OfflineRequests, OfflineService, OfflineUpdateRequest } from '../../core/services/offline.service';
import { WindowService } from '../../core/services/window.service';
@Injectable({
  providedIn: 'root',
})
export class ShiftsService extends OfflineService {
  public offlineShift!: ShiftDto;
  public offlineShiftPause!: ShiftPauseDto;
  public offlineShiftTypes!: ShiftType[];
  public offlineRouteTypes!: RouteType[];

  nameSpace = 'ShiftsService';

  constructor(
    private createShiftTypeGQL: CreateShiftTypeGQL,
    private updateShiftTypeGQL: UpdateShiftTypeGQL,
    private deleteShiftTypeGQL: DeleteShiftTypeGQL,
    private createShiftGQL: CreateShiftGQL,
    private updateShiftGQL: UpdateShiftGQL,
    private startShiftPauseGQL: StartShiftPauseGQL,
    private endShiftGQL: EndShiftGQL,
    private endShiftPauseGQL: EndShiftPauseGQL,
    private accountShiftTypesGQL: AccountShiftTypesGQL,
    cacheService: CacheService,
    windowService: WindowService
  ) {
    super(windowService, cacheService);
    this.loadLocalCache();
  }

  canUpdate(updateRequest: OfflineUpdateRequest): boolean {
    return true;
  }

  isValidId(id: any) {
    return true;
  }

  getIdFromObject(updateRequest: OfflineUpdateRequest) {
    return null;
  }

  setIdToObject(updateRequest: OfflineUpdateRequest) {
    undefined;
  }

  async loadLocalCache() {
    this.offlineShift = await this.cacheService.get(DataBase.offlineCache, 'offlineShift');
    this.offlineShiftPause = await this.cacheService.get(DataBase.offlineCache, 'offlineShiftPause');
    this.offlineShiftTypes = await this.cacheService.get(DataBase.offlineCache, 'offlineShiftTypes');
    this.offlineRouteTypes = await this.cacheService.get(DataBase.offlineCache, 'offlineRouteTypes');
  }

  updateCurrentShift(shift: ShiftDto) {
    this.offlineShift = shift;
    this.cacheService.put(DataBase.offlineCache, 'offlineShift', this.offlineShift);
  }

  updateCurrentShiftPause(shiftPause: ShiftPauseDto) {
    this.offlineShiftPause = shiftPause;
    this.cacheService.put(DataBase.offlineCache, 'offlineShiftPause', this.offlineShiftPause);
  }

  updateShiftTypes(shiftTypes: ShiftType[]) {
    this.offlineShiftTypes = shiftTypes;
    this.cacheService.put(DataBase.offlineCache, 'offlineShiftTypes', this.offlineShiftTypes);
  }

  updateRouteTypes(routeTypes: RouteType[]) {
    this.offlineRouteTypes = routeTypes;
    this.cacheService.put(DataBase.offlineCache, 'offlineRouteTypes', this.offlineRouteTypes);
  }

  accountShiftTypes(accountId: number) {
    return this.accountShiftTypesGQL.fetch({ accountId: accountId } as never).pipe(
      map(res => res?.data?.accountShiftTypes),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: this.accountShiftTypes,
            params: [accountId],
            onError: (updateRequest: OfflineUpdateRequest) => {
              undefined;
            },
          },
          defaultValue: true,
        })
      )
    );
  }

  createShiftType(input: CreateShiftTypeInput) {
    return this.createShiftTypeGQL.mutate({ input }).pipe(
      map(res => res?.data?.createShiftType),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: this.createShiftType,
            params: [input],
            onError: (updateRequest: OfflineUpdateRequest) => {
              undefined;
            },
          },
          defaultValue: true,
        })
      )
    );
  }

  updateShiftType(input: UpdateShiftTypeInput) {
    return this.updateShiftTypeGQL.mutate({ input }).pipe(
      map(res => res?.data?.updateShiftType),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: this.updateShiftType,
            params: [input],
            onError: (updateRequest: OfflineUpdateRequest) => {
              undefined;
            },
          },
          defaultValue: true,
        })
      )
    );
  }

  deleteShiftType(input: DeleteShiftTypeInput) {
    //Scalars['ShiftTypeId']) {
    return this.deleteShiftTypeGQL.mutate({ input }).pipe(
      map(res => res?.data?.deleteShiftType),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: this.deleteShiftType,
            params: [input],
            onError: (updateRequest: OfflineUpdateRequest) => {
              undefined;
            },
          },
          defaultValue: true,
        })
      )
    );
  }

  createShift(input: CreateShiftInput) {
    return this.createShiftGQL.mutate({ input }).pipe(
      map(res => res?.data?.createShift as ShiftDto),
      tap(shift => this.updateCurrentShift(shift)),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: this.createShift,
            params: [input],
            onError: (updateRequest: OfflineUpdateRequest) => {
              undefined;
            },
          },
          defaultValue: true,
        })
      )
    );
  }

  updateShift(input: UpdateShiftInput) {
    return this.updateShiftGQL.mutate({ input }).pipe(
      map(res => res?.data?.updateShift as ShiftDto),
      tap(shift => this.updateCurrentShift(shift)),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: OfflineRequests.updateShift,
            params: [input],
            onError: (updateRequest: OfflineUpdateRequest) => {
              if (input.shiftTypeId) {
                this.offlineShift.shiftType = {
                  id: input.shiftTypeId.value,
                } as ShiftType;
              }
              if (input.routeTypesIds && input.routeTypesIds.length) {
                this.offlineShift.routeTypes = input.routeTypesIds.map(_routeId => ({
                  id: _routeId,
                })) as Array<RouteType>;
              }

              if (input.startMileage?.value) {
                this.offlineShift.startMileage = input.startMileage?.value;
              }
              if (input.unitId) {
                this.offlineShift.unit = {
                  id: input.unitId,
                } as UnitVehicle;
              }

              return this.offlineShift;
            },
          },
          defaultValue: this.offlineShift,
        })
      )
    );
  }

  startShiftPause(input: CreateShiftPauseInput): Observable<ShiftPauseDto> {
    return this.startShiftPauseGQL.mutate({ input }).pipe(
      map(res => res?.data?.startShiftPause),
      tap(shift => this.updateCurrentShiftPause(shift as ShiftPauseDto)),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: this.startShiftPause,
            params: [input],
            onError: (updateRequest: OfflineUpdateRequest) => {
              this.offlineShiftPause = { ...this.offlineShiftPause, ...input };
              return this.offlineShiftPause;
            },
          },
          defaultValue: this.offlineShiftPause,
        })
      )
    );
  }

  endShift(input: EndShiftInput): Observable<ShiftDto> {
    return this.endShiftGQL.mutate({ input }).pipe(
      map(res => res?.data?.endShift as ShiftDto),
      tap(shift => {
        this.updateCurrentShift(shift);
        this.cacheService.clearOfflineCache();
      }),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: this.endShift,
            params: [input],
            onError: (updateRequest: OfflineUpdateRequest) => {
              undefined;
            },
          },
          defaultValue: true,
        })
      )
    );
  }

  endShiftPause(input: number): Observable<ShiftPauseDto> {
    return this.endShiftPauseGQL.mutate({ id: input }).pipe(
      map(res => res?.data?.endShiftPause),
      tap(shift => this.updateCurrentShiftPause(shift as ShiftPauseDto)),
      catchError(
        this.handleAPIError({
          updateRequest: {
            action: this.endShiftPause,
            params: [input],
            onError: (updateRequest: OfflineUpdateRequest) => {
              undefined;
            },
          },
          defaultValue: true,
        })
      )
    );
  }
}
