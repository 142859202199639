import { EventEmitter, Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { TranslateService } from '@ngx-translate/core';
import { SpecialEventStoreEntity } from '@wc/wc-models/src';
import deepmerge from 'deepmerge';
import _, { isEmpty } from 'lodash';
import { action, makeObservable, observable } from 'mobx';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { EntitiesStore, LiveMapStore } from '.';
import * as Utils from '../../utils';
import {
  LiveMapEntity,
  LiveMapEntityType,
  MainEntityType,
  TrafficDisruptionEntityStore,
  TrafficDisruptionView,
  TrafficDisruptionViewData,
  VenueData,
} from '../models';
import {
  CreateSpecialEventInput,
  LayerType,
  PublishInput,
  SpecialEvent,
  SpecialEventType,
  SpecialEventView,
  TrafficDisruptionStatus,
  UpdateSpecialEventInput,
  UpdateTrafficDisruptionLanesInput,
  VenueDetails,
} from '../models/gql.models';
import { GeoService, SplitIOService, TrafficDisruptionService } from '../services';
import { SpecialEventService } from '../services/special-event.service';
import { TrafficDisruptionStore } from './traffic-disruption.store';
import { UiStore } from './ui.store';

@Injectable({
  providedIn: 'root',
})
export class SpecialEventStore implements TrafficDisruptionEntityStore {
  submitFormEvent: EventEmitter<any> = new EventEmitter();
  constructor(
    private translateService: TranslateService,
    private trafficDisruptionService: TrafficDisruptionService,
    private trafficDisruptionStore: TrafficDisruptionStore,
    private specialEventService: SpecialEventService,
    private uiStore: UiStore,
    private liveMapStore: LiveMapStore,
    private geoService: GeoService,
    private entitiesStore: EntitiesStore,
    private splitIoService: SplitIOService
  ) {
    makeObservable(this);
  }

  @observable selectedTrafficDisruption!: TrafficDisruptionView;
  @observable selectedSpecialEventBeforeEdit!: TrafficDisruptionView;
  @observable selectedSpecialEventType?: SpecialEventType | undefined = undefined;
  @observable otherTypeDescription: string | undefined = undefined;
  @observable specialEventCompleted: SpecialEventView[] = [];

  private readonly _onSpecialEventEdited = new Subject<number>();
  /**
   * @deprecated This changed to be onTrafficDisruptionEdited$ in trafficDisruptionService
   */
  readonly onSpecialEventEdited$ = this._onSpecialEventEdited.asObservable();

  private set onSpecialEventEdited(specialEventId: number) {
    this._onSpecialEventEdited.next(specialEventId);
  }

  private readonly _venuesNearCursor = new BehaviorSubject<VenueDetails[]>([]);
  readonly venuesNearCursor$ = this._venuesNearCursor.asObservable();

  get venuesNearCursor(): VenueDetails[] {
    return this._venuesNearCursor.getValue();
  }

  private set venuesNearCursor(v: VenueDetails[]) {
    this._venuesNearCursor.next(v);
  }

  createSpecialEvent(specialEvent: CreateSpecialEventInput) {
    specialEvent.otherTypeDescription = this.otherTypeDescription;
    return this.specialEventService.create(specialEvent).pipe(
      tap((_specialEvent: SpecialEvent) => {
        this.uiStore.setInteractedEntityId(_specialEvent.id, MainEntityType.traffic_disruption);
        this.clearTrafficDisruptionSelection();
        this.liveMapStore.removeInteractiveEntity();
        this.liveMapStore.changeLayersVisibility(true, ['special_event']);
        this.updateSpecialEventMapEntity(_specialEvent);
        this.trafficDisruptionStore.uploadFiles(_specialEvent.id);
      })
    );
  }

  updateSpecialEvent(specialEvent: SpecialEvent & UpdateSpecialEventInput) {
    this.uiStore.showLoader('specialEvent');
    this.liveMapStore.removeInteractiveEntity();

    // affected lanes updates
    if (this.shouldUpdateAffectedLanes(specialEvent, this.selectedSpecialEventBeforeEdit)) {
      const affectedLanesDiff = Utils.affectedLanesArrayDiff(
        this.selectedSpecialEventBeforeEdit.affectedLanes,
        specialEvent.affectedLanes
      );
      if (
        affectedLanesDiff.modified.length > 0 ||
        affectedLanesDiff.removed.length > 0 ||
        affectedLanesDiff.created.length > 0
      ) {
        this.trafficDisruptionService
          .updateTrafficDisruptionLanes({
            trafficDisruptionId: specialEvent.id,
            modified: affectedLanesDiff.modified,
            removed: affectedLanesDiff.removed,
            created: affectedLanesDiff.created,
          })
          .subscribe();
      }
    }

    delete specialEvent.affectedLanes;
    if ((this.selectedSpecialEventBeforeEdit as SpecialEvent).otherTypeDescription !== this.otherTypeDescription) {
      specialEvent.otherTypeDescription = this.otherTypeDescription;
    }

    // check for update the rest fields
    if (this.shouldUpdateSpecialEvent(specialEvent, this.selectedSpecialEventBeforeEdit as SpecialEvent)) {
      const preUpdateInput = this.selectedSpecialEventBeforeEdit as SpecialEvent & UpdateSpecialEventInput;
      // transform venue object to it's corresponding fields
      const selectedSpecialEventBeforeEdit = {
        ...this.selectedSpecialEventBeforeEdit,
        venueName: preUpdateInput.venue?.name,
        venueAddress: preUpdateInput.venue?.address,
      };

      const specialEventDiff = Utils.objectDiff(selectedSpecialEventBeforeEdit, specialEvent);

      if (isEmpty(specialEventDiff)) {
        this.updateSpecialEventMapEntity({ ...specialEvent, updatedAt: new Date().toISOString() } as any);
        this.trafficDisruptionStore.uploadFiles(specialEvent.id);
        this.trafficDisruptionStore.deleteTrafficDisruptionMedia(specialEvent.id);
        return of({});
      }

      const updateSpecialEventInput: Partial<SpecialEvent> = {
        ...{ id: specialEvent.id },
        ...specialEventDiff,
      };

      const input = updateSpecialEventInput as unknown as UpdateSpecialEventInput;
      if (Object(updateSpecialEventInput).hasOwnProperty('media')) delete input.media;
      if (Object(updateSpecialEventInput).hasOwnProperty('address')) input.address = { value: specialEvent.address };
      if (Object(updateSpecialEventInput).hasOwnProperty('contactPerson'))
        input.contactPerson = { value: updateSpecialEventInput.contactPerson };
      if (Object(updateSpecialEventInput).hasOwnProperty('description'))
        input.description = { value: updateSpecialEventInput.description };
      if (Object(updateSpecialEventInput).hasOwnProperty('endTime'))
        input.endTime = { value: updateSpecialEventInput.endTime };

      return this.specialEventService.update(input).pipe(
        tap((_specialEvent: SpecialEvent) => {
          this.updateSpecialEventMapEntity(_specialEvent);
          const specialEventIndex = this.trafficDisruptionStore.completedTrafficDisruptions.special_event.findIndex(
            specialEvent => _specialEvent.id === specialEvent.id
          );
          if (specialEventIndex > -1) {
            const updatedSpecialEvent =
              this.trafficDisruptionStore.completedTrafficDisruptions.special_event[specialEventIndex];
            this.trafficDisruptionStore.completedTrafficDisruptions.special_event[specialEventIndex] = deepmerge(
              updatedSpecialEvent,
              _specialEvent
            );
          }
          this.trafficDisruptionStore.uploadFiles(_specialEvent.id);
          this.trafficDisruptionStore.deleteTrafficDisruptionMedia(_specialEvent.id);
        }),
        catchError(err => {
          console.log(err);
          return of({});
        }),
        finalize(() => this.uiStore.hideLoader('specialEvent'))
      );
    } else {
      this.uiStore.hideLoader('specialEvent');
      return of({});
    }
  }

  shouldUpdateSpecialEvent(modifiedSpecialEvent: SpecialEvent, specialEvent: SpecialEvent) {
    modifiedSpecialEvent = JSON.parse(JSON.stringify(modifiedSpecialEvent));
    specialEvent = JSON.parse(
      JSON.stringify({
        ...specialEvent,
        venueName: specialEvent.venue?.name,
        venueAddress: specialEvent.venue?.address,
      })
    );

    const specialEventBeforeEdit: any = { ...{}, ...specialEvent };
    const specialEventAfterEdit: any = { ...{}, ...modifiedSpecialEvent };
    delete specialEventBeforeEdit.status;
    delete specialEventBeforeEdit.externalId;
    delete specialEventBeforeEdit.otherTypeDescription;
    delete specialEventBeforeEdit.affectedLanes;
    delete specialEventAfterEdit.affectedLanes;
    specialEventBeforeEdit.otherTypeDescription = (specialEvent as SpecialEvent).otherTypeDescription;

    return !_.isEqual(specialEventBeforeEdit, specialEventAfterEdit);
  }

  shouldUpdateAffectedLanes(modifiedSpecialEvent: SpecialEvent, specialEvent: TrafficDisruptionView) {
    if (!modifiedSpecialEvent.affectedLanes) {
      return false;
    }
    if (!specialEvent.affectedLanes && modifiedSpecialEvent.affectedLanes) {
      return true;
    }
    const modifiedAffectedLanes = JSON.parse(JSON.stringify(modifiedSpecialEvent.affectedLanes));
    const affectedLanes = JSON.parse(JSON.stringify(specialEvent.affectedLanes));
    return !_.isEqual(modifiedAffectedLanes, affectedLanes);
  }

  updateSpecialEventMapEntity(specialEvent: SpecialEvent) {
    const workspaces = this.geoService.workspacesByLocation(specialEvent.location.coordinates);
    const updatedMapLayer = {
      [MainEntityType.special_event]: {
        [specialEvent.id]: {
          ...specialEvent,
          ...{
            featureType: MainEntityType.special_event,
            featureSubTypeOf: MainEntityType.special_event,
            workspaces,
          },
        },
      },
    };

    this.entitiesStore
      .modifiedEntities(
        updatedMapLayer as {
          [layerName in LiveMapEntityType]?: { [id in string]: LiveMapEntity };
        }
      )
      .then(() => {
        this.onSpecialEventEdited = specialEvent.id;
        this.liveMapStore.updateModifiedEntities(
          updatedMapLayer as {
            [layerName in LiveMapEntityType]?: {
              [id in string]: LiveMapEntity;
            };
          }
        );
        this.liveMapStore.setSelectedWorkspaces(workspaces, false);
        this.clearTrafficDisruptionSelection();
      });
  }

  /**
   * @deprecated
   * This function moved to traffic-disruption.service
   */
  getSpecialEvent(id: number) {
    return this.specialEventService.get(id);
  }

  @action
  selectTrafficDisruption(specialEvent: TrafficDisruptionView, zoomOn: boolean | undefined = true) {
    !this.uiStore.isTabletMode
      ? this.trafficDisruptionStore.selectTrafficDisruption(
          { ...specialEvent, layerType: LayerType.SpecialEvent } as SpecialEventStoreEntity,
          zoomOn
        )
      : this.oldSelectSpecialEvent(specialEvent, zoomOn);
  }

  oldSelectSpecialEvent(specialEvent: TrafficDisruptionView, zoomOn: boolean | undefined = true) {
    specialEvent.affectedLanes = Utils.sortAffectedLanes(specialEvent.affectedLanes);
    this.selectedTrafficDisruption = { ...{}, ...specialEvent };
    this.selectedSpecialEventBeforeEdit = { ...{}, ...specialEvent };
    this.uiStore.hideLoader('specialEvent');

    if (zoomOn) this.liveMapStore.zoomOnEntity(specialEvent, 15);

    this.trafficDisruptionStore.selectedTrafficDisruption = specialEvent;

    if (this.isCompleted(specialEvent)) {
      this._updateCompletedSpecialEventMapEntity(specialEvent);
    }
    setTimeout(() => {
      if (!this.uiStore.isTabletMode)
        if ((specialEvent as SpecialEvent).type && specialEvent.id) {
          const typeEntities = this.entitiesStore.entities['special_event'];
          if (typeEntities) {
            const entityFeature = typeEntities[specialEvent.id];
            if (entityFeature) {
              this.liveMapStore.selectEntityFeature(entityFeature);
            }
          }
        }
    });
    datadogLogs.removeLoggerGlobalContext('wc_special_event_details');
    datadogLogs.addLoggerGlobalContext('wc_special_event_details', JSON.stringify(this.selectedTrafficDisruption));
  }

  private isCompleted(specialEvent: TrafficDisruptionView) {
    if (specialEvent.status === TrafficDisruptionStatus.Completed) {
      return true;
    }
    return false;
  }

  _updateCompletedSpecialEventMapEntity(specialEvent: TrafficDisruptionView) {
    const workspaces = this.geoService.workspacesByLocation(specialEvent.location.coordinates);
    const updatedMapLayer = {
      [MainEntityType.special_event]: {
        [specialEvent.id]: {
          ...specialEvent,
          ...{
            featureType: MainEntityType.special_event,
            featureSubTypeOf: MainEntityType.special_event,
            workspaces,
          },
        },
      },
    };

    this.liveMapStore.updateModifiedEntities(
      updatedMapLayer as {
        [layerName in LiveMapEntityType]?: { [id in number]: LiveMapEntity };
      }
    );
  }

  clearTrafficDisruptionSelection() {
    if (this.selectedTrafficDisruption && this.isCompleted(this.selectedTrafficDisruption)) {
      this.liveMapStore.updateRemovedEntities({
        [MainEntityType.special_event]: [this.selectedTrafficDisruption.id],
      });
    }
    this.liveMapStore.clearMapDraw();
    this.liveMapStore.unselectFeature('special_event');
    this.selectedSpecialEventBeforeEdit = {} as SpecialEvent;
    this.selectedTrafficDisruption = {} as SpecialEvent;
    this.otherTypeDescription = undefined;
    datadogLogs.removeLoggerGlobalContext('wc_special_event_details');
  }

  getSpecialEventData(
    specialEvent: SpecialEvent | SpecialEventView
  ): TrafficDisruptionViewData['specialEventViewData'] {
    const { venue, occupancyRange } = specialEvent as SpecialEvent;
    const { venueAddress, venueName } = specialEvent as SpecialEventView;

    let specialEventAttendance: TrafficDisruptionViewData['specialEventViewData']['specialEventAttendance'] =
      this.translateService.instant('NA');
    const venueData = {} as VenueData;

    if (venue) {
      if (venue.name && venue.address) {
        venueData.venueName = venue.name;
        venueData.venueAddress = venue.address;
      }
    } else {
      if (venueAddress && venueName) {
        venueData.venueName = venueName;
        venueData.venueAddress = venueAddress;
      }
    }

    if (occupancyRange) {
      specialEventAttendance = this.translateService.instant('specialEventOccupancyRange.' + occupancyRange);
    }

    return {
      venueData,
      specialEventAttendance,
    };
  }

  setTrafficDisruptionStatus(specialEvent: SpecialEvent | LiveMapEntity, status: TrafficDisruptionStatus) {
    const specialEventId: number = Number(specialEvent.id);

    if (status === TrafficDisruptionStatus.Completed) {
      const entity = this.entitiesStore.entities.special_event
        ? this.entitiesStore.entities.special_event[specialEvent.id]
        : undefined;
      this.uiStore.showLoader('specialEvent');
      return this.specialEventService.complete(specialEventId).pipe(
        tap((bool: Boolean | undefined) => {
          if (bool) {
            if ('affectedLanes' in specialEvent && specialEvent.affectedLanes?.length) {
              this.openAllAffectedLanes(specialEvent);
            } else {
              this.getSpecialEvent(specialEventId).subscribe(SpecialEventRes => {
                if (SpecialEventRes && SpecialEventRes.affectedLanes?.length) {
                  this.openAllAffectedLanes(SpecialEventRes as SpecialEvent);
                }
              });
            }
            entity.status = status;
            if (this.entitiesStore.entities.special_event)
              delete this.entitiesStore.entities.special_event[specialEvent.id];
            this.entitiesStore.entities = {
              ...{},
              ...this.entitiesStore.entities,
            };
            this.liveMapStore.updateRemovedEntities({
              special_event: [specialEvent.id as number],
            });
          }
        }),
        finalize(() => this.uiStore.hideLoader('specialEvent'))
      );
    }
    return of(false);
  }

  openAllAffectedLanes(specialEvent: SpecialEvent) {
    const affectedLanes = specialEvent.affectedLanes;
    affectedLanes?.forEach(lane => {
      lane.isClosed = false;
    });
    this.trafficDisruptionService
      .updateTrafficDisruptionLanes({
        trafficDisruptionId: specialEvent.id,
        modified: affectedLanes,
        created: [],
        removed: [],
      } as UpdateTrafficDisruptionLanesInput)
      .subscribe();
  }

  publishTrafficDisruption(publishInput: PublishInput) {
    return this.specialEventService.publish(publishInput);
  }

  @action
  setSpecialEventType(type: SpecialEventType) {
    this.selectedSpecialEventType = type;
  }

  @action
  setOtherTypeDescription(description: string) {
    this.otherTypeDescription = description;
  }
}
