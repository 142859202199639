import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  CompleteConstructionGQL,
  CompletedConstructionsGQL,
  Construction,
  ConstructionActivityLogGQL,
  ConstructionGQL,
  CreateConstructionGQL,
  CreateConstructionInput,
  PublishConstructionGQL,
  PublishInput,
  UpdateConstructionGQL,
  UpdateConstructionInput,
} from '../models/gql.models';
import { CustomRxOperatorsService } from './custom-rx-operators.service';
@Injectable({
  providedIn: 'root',
})
export class ConstructionService {
  readonly fetchCompleted$ = this.completedConstructionsGQL
    .fetch()
    .pipe(
      map(res =>
        res.data?.completedConstructions.map(construction => ({ ...construction, featureType: 'construction' }))
      )
    );

  constructor(
    private customOperators: CustomRxOperatorsService,
    private constructionGQL: ConstructionGQL,
    private createConstructionGQL: CreateConstructionGQL,
    private updateConstructionGQL: UpdateConstructionGQL,
    private completeConstructionGQL: CompleteConstructionGQL,
    private completedConstructionsGQL: CompletedConstructionsGQL,
    private constructionActivityLogGQL: ConstructionActivityLogGQL,
    private publishConstructionGQL: PublishConstructionGQL
  ) {}

  getCompleted() {
    return this.fetchCompleted$;
  }

  get(id: number) {
    return this.constructionGQL.fetch({ id: id }).pipe(
      map<any, Construction>(res => res.data.construction),
      this.customOperators.catchGqlErrors()
    );
  }

  create(input: CreateConstructionInput) {
    return this.createConstructionGQL.mutate({ input }).pipe(
      map<any, Construction>(res => res?.data?.createConstruction),
      this.customOperators.catchGqlErrors()
    );
  }

  update(input: UpdateConstructionInput) {
    return this.updateConstructionGQL.mutate({ input }).pipe(
      map<any, Construction>(res => res?.data?.updateConstruction),
      this.customOperators.catchGqlErrors()
    );
  }

  complete(id: number) {
    return this.completeConstructionGQL.mutate({ id }).pipe(
      map(res => res?.data?.completeConstruction),
      this.customOperators.catchGqlErrors()
    );
  }

  getActivityLog(id: number) {
    return this.constructionActivityLogGQL.fetch({ id: id }).pipe(
      map(res => res.data.constructionActivityLog),
      this.customOperators.catchGqlErrors()
    );
  }
  publish(publishInput: PublishInput) {
    return this.publishConstructionGQL.mutate({ input: publishInput });
  }
}
