<div class="mitigation-form-component">
  <div class="mitigation-form-body">
    <div class="mitigation-types-container">
      <div class="incident-field-container">
        <span class="incident-type-list-label">{{ 'incidentType' | translate }}</span>
        <wc-autocomplete-v2
          [formControl]="$any(form.get('incidentType'))"
          [fieldData]="fieldsData.incidentType"
          [isTabletMode]="isTabletMode"
          [dynamicClass]="'incident-type'"
          [floatLabel]="false">
        </wc-autocomplete-v2>
      </div>

      <div class="incident-field-container">
        <span class="incident-type-list-label">{{ 'incident.subtype' | translate }}</span>
        <wc-autocomplete-v2
          #subType
          [disabled]="incidentStore.subtypeOptions.length === 0"
          [isTabletMode]="isTabletMode"
          [formControl]="$any(form.get('subType'))"
          [fieldData]="fieldsData.subType"
          [floatLabel]="false">
        </wc-autocomplete-v2>
      </div>
    </div>

    <form class="mitigation-form" [formGroup]="form">
      <wc-mitigation
        #mitigationList
        [mitigationStartTime]="mitigationStartTime"
        [formControl]="$any(form.get('mitigation'))"
        [fieldData]="fieldsData.mitigation"
        [isMitigatedMode]="true">
      </wc-mitigation>
    </form>
  </div>

  <div class="mitigation-form-buttons" fxLayout="row" fxLayoutAlign="space-around center">
    <button class="secondary-button" (click)="close()">
      {{ 'cancel' | translate }}
    </button>
    <button class="primary-button" [disabled]="!isValid" (click)="submit()" [isOneTimeClick]="isValid">
      {{ 'submit' | translate }}
    </button>
  </div>
</div>
