import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { PersistenceScoreComponent } from './persistence-score.component';

@NgModule({
  declarations: [PersistenceScoreComponent],
  exports: [PersistenceScoreComponent],
  imports: [CommonModule, MatTooltipModule, TranslateModule],
})
export class PersistenceScoreModule {}
