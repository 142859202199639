import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type HorizontalBarChartData<T extends string = string> = {
  name: T;
  value: number;
}[];

const ticksValues = {
  upTo40: [0, 10, 20, 30, 40],
  upTo100: [0, 25, 50, 75, 100],
  upTo400: [0, 100, 200, 300, 400],
  upTo1000: [0, 250, 500, 750, 1000],
  upTo2000: [0, 500, 1000, 1500, 2000],
  upTo3000: [0, 750, 1500, 2250, 3000],
  upTo4000: [0, 1000, 2000, 3000, 4000],
};

@Component({
  selector: 'wc-horizontal-bar-charts',
  templateUrl: './horizontal-bar-charts.component.html',
  styleUrls: ['./horizontal-bar-charts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalBarChartsComponent {
  @Input() graphWidthAndHeight: [number, number] = [100, 100];
  @Input() set chartData(values: HorizontalBarChartData) {
    if (Array.isArray(values)) {
      this._chartData = values;
      const maxCount = this.findMaxCount(values);
      this.xAxisTickValues = this.selectTickValuesByMaxCount(maxCount);
      this.xScaleMax = this.xAxisTickValues[this.xAxisTickValues.length - 1];
    }
  }
  @Input() showXAxis = true;
  @Input() barPadding = 15;
  @Input() showYAxis = true;
  @Input() gradient = false;
  @Input() showLegend = false;
  @Input() yAxisLabel = '';
  @Input() xAxisLabel = '';
  @Input() xScaleMin = 0;
  @Input() xScaleMax = 2000;
  @Input() colorScheme = {
    domain: ['#5C97EC'],
  } as any;

  _chartData: HorizontalBarChartData = [];
  xAxisTickValues = [0, 250, 500, 750, 2000];

  labelFormatting(value: number): string | number {
    const thousands = value / 1000;
    return thousands >= 1 ? `${thousands}k` : value;
  }

  private findMaxCount(values: HorizontalBarChartData): number {
    return values.reduce((acc, curr) => (curr.value > acc ? curr.value : acc), 0);
  }

  private selectTickValuesByMaxCount(maxCount: number): number[] {
    if (maxCount <= 40) return ticksValues.upTo40;
    if (maxCount <= 100) return ticksValues.upTo100;
    if (maxCount <= 400) return ticksValues.upTo400;
    if (maxCount <= 1000) return ticksValues.upTo1000;
    if (maxCount <= 2000) return ticksValues.upTo2000;
    if (maxCount <= 3000) return ticksValues.upTo3000;
    if (maxCount <= 4000) return ticksValues.upTo4000;

    return [];
  }
}
