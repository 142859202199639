<form class="shift-form" [formGroup]="shiftForm">
  <div class="shift-modal-row">
    <ng-container
      *ngIf="unitRelationType === unitRelationTypeEnum.ChangingUnit && shiftFieldsData.unitId.options?.length">
      <wc-select-control
        formControlName="unitId"
        [formFieldData]="shiftFieldsData.unitId"
        [isTabletMode]="uiStore.isTabletMode"
        (select)="unitSelected($event)">
      </wc-select-control>

      <wc-number-field-control
        *ngIf="shiftFieldsData.shiftTypeId.options.length"
        [formControl]="$any(shiftForm.get('startMileage'))"
        [fieldData]="shiftFieldsData.startMileage">
      </wc-number-field-control>
    </ng-container>

    <ng-container *ngIf="unitRelationType === unitRelationTypeEnum.ConstantUnit">
      <div class="one-available-unit">
        {{ shiftFieldsData.unitId.label | translate }} #{{ usersStore.authUser?.unit?.displayId }}
      </div>
      <wc-number-field-control
        *ngIf="shiftFieldsData.shiftTypeId.options?.length"
        [formControl]="$any(shiftForm.get('startMileage'))"
        [fieldData]="shiftFieldsData.startMileage">
      </wc-number-field-control>
    </ng-container>
  </div>

  <div class="shift-modal-row">
    <wc-select-control
      *ngIf="shiftFieldsData.shiftTypeId?.options && shiftFieldsData.shiftTypeId?.options?.length"
      formControlName="shiftTypeId"
      [isTabletMode]="uiStore.isTabletMode"
      [formFieldData]="shiftFieldsData.shiftTypeId">
    </wc-select-control>

    <wc-multi-select-control
      *ngIf="shiftFieldsData.routeTypesIds?.options && shiftFieldsData.routeTypesIds?.options?.length"
      [formControl]="$any(shiftForm.get('routeTypesIds'))"
      [fieldData]="shiftFieldsData.routeTypesIds">
    </wc-multi-select-control>
  </div>

  <div class="buttons-container">
    <button class="secondary-button" (click)="closeShiftForm()">
      {{ 'cancel' | translate }}
    </button>

    <button [disabled]="this.disableEdit" class="primary-button" (click)="submitEditShiftForm()">
      {{ 'edit' | translate }}
    </button>
  </div>
</form>
