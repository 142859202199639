import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WcInvalidExpansionCssClassDirective } from './wc-invalid-expansion-css-class.directive';

@NgModule({
  declarations: [WcInvalidExpansionCssClassDirective],
  imports: [CommonModule],
  providers: [],
  exports: [WcInvalidExpansionCssClassDirective],
})
export class WcInvalidExpansionCssClassDirectiveModule {}
