import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateFormatPipe } from './date-format.pipe';
import { DistanceFormatPipe } from './distance-format.pipe';
import { HighlightTextPipe } from './highlight-text.pipe';
import { RoadTypeLabelPipe } from './road-type-label.pipe';
import { TimeFormatPipe } from './time-format.pipe';
@NgModule({
  declarations: [DateFormatPipe, DistanceFormatPipe, RoadTypeLabelPipe, TimeFormatPipe, HighlightTextPipe],
  imports: [CommonModule],
  providers: [],
  exports: [DateFormatPipe, DistanceFormatPipe, RoadTypeLabelPipe, TimeFormatPipe, HighlightTextPipe],
})
export class FormatsPipesModule {}
