<div class="media-section-modal" [class.tablet-mode]="isTabletMode">
  <div class="media-section-modal__header">
    <span> {{ 'files' | translate }} ({{ mediaList.length }})</span>
    <i (click)="cancel()" class="far fa-times"></i>
  </div>

  <div class="media-section-modal-content" [class.edit-mode]="isEditMode">
    <div class="media-section-modal-content__info">
      <span class="media-section-modal-content__title"> {{ this.mediaTitle }} </span>
      <span
        class="media-section-modal-content__download-button"
        [class.heap-desktop-download-media-file]="!isTabletMode"
        [class.heap-tablet-download-media-file]="isTabletMode"
        [class.tablet-mode]="isTabletMode"
        *ngIf="$any(selectedMedia).media"
        (click)="downloadFile($any(selectedMedia).media.url)">
        <i class="fas media-section-modal-content__download-icon"></i
        ><span [class.tablet-mode]="isTabletMode" class="media-section-modal-content__download-button-text"
          >Download File</span
        >
      </span>
    </div>

    <div class="media-section-modal-content__select-media" [class.edit-mode]="isEditMode">
      <ng-container *ngIf="!isEmptyState; else emptyState">
        <div class="media-section-modal-content__media-viewer-container" *ngIf="showDocViewer; else mediaThumbnail">
          <wc-loader-component [delay]="0" [show]="!iFrameFetchedSuccessfully && !showPreviewError">
          </wc-loader-component>
          <div class="media-section-modal-content__doc-preview-error" *ngIf="showPreviewError">
            <i class="fas media-section-modal-content__no-preview-available-icon"></i>
            <span class="media-section-modal-content__no-preview-available">{{
              'noPreviewAvailable' | translate
            }}</span>
          </div>
          <iframe
            #docIframe
            *ngIf="!showPreviewError"
            class="media-section-modal-content__media-viewer"
            (load)="onDocIframeLoaded()"
            [src]="$any(selectedMedia).media.url | googleDocsPreviewUrl"></iframe>
        </div>
        <ng-template #mediaThumbnail>
          <wc-media-thumbnail
            [allowVideoControls]="true"
            [renewMediaUrlFunc]="data.renewUrlFunction"
            [showNewIndication]="false"
            [isTabletMode]="isTabletMode"
            [customObjectFit]="'contain'"
            [mediaObj]="this.selectedMedia"></wc-media-thumbnail>
        </ng-template>
      </ng-container>

      <ng-template #emptyState>
        <div class="empty-state-container">
          <i class="empty-state-icon-media"></i>
          <div class="empty-state-message">
            {{ 'emptyStates.noMediaMessage' | translate }}
          </div>
          <div class="empty-state-action">
            {{ 'emptyStates.noMediaAction' | translate }}
          </div>
        </div>
      </ng-template>
    </div>

    <div
      class="media-section-modal-content__image-swiper-list"
      [class.media-section-modal-content__image-swiper-list--border]="isEditMode"
      *ngIf="mediaList.length">
      <div [swiper]="config" class="swiper-container" (keyPress)="handleSwiperKeyPressed($event)">
        <div class="swiper-wrapper">
          <ng-container *ngFor="let media of mediaList; let i = index">
            <wc-media-thumbnail
              [isInSliderInFileModal]="true"
              [isTabletMode]="isTabletMode"
              [renewMediaUrlFunc]="data.renewUrlFunction"
              [isEditMode]="isEditMode"
              (click)="setMainMedia(i)"
              (mediaDeleted)="mediaDeleted(i)"
              class="swiper-slide"
              [class.active]="i === this.selectedMediaIndex"
              [mediaObj]="media"></wc-media-thumbnail>
          </ng-container>
        </div>
      </div>
      <div class="swiper-pagination-media-modal"></div>
      <div class="swiper-button-prev swiper-button-prev-file"></div>
      <div class="swiper-button-next swiper-button-next-file"></div>
    </div>

    <div *ngIf="isEditMode" class="media-section-modal-content__action-button">
      <button (click)="cancel()" class="secondary-button">{{ 'cancel' | translate }}</button>
      <button (click)="save()" class="primary-button">{{ 'apply' | translate }}</button>
    </div>
  </div>
</div>
