import { Injectable } from '@angular/core';
import { defer, Observable, OperatorFunction, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomRxOperatorsService {
  catchGqlErrors(): OperatorFunction<unknown, any> {
    return catchError(err =>
      throwError(err?.graphQLErrors?.[0] ? { errorCode: err.graphQLErrors[0]?.extensions?.statusCode } : err)
    );
  }

  tapOnce<T>(fn: (value: T) => void): OperatorFunction<T, T> {
    return (source: Observable<T>) => {
      return defer(() => {
        let run = false;
        return source.pipe(
          tap(value => {
            if (!run) {
              fn(value);
              run = true;
            }
          })
        );
      });
    };
  }
}
