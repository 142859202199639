import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { Address, Incident, Point } from '../../../../core/models';
import { LocalStorageService } from '../../../../core/services';
import { UiStore } from '../../../../core/stores';
import { AddressComponent } from '../../form-fields-components/address/address.component';
import { AddressRequiredValidator } from '../../form-validators';
import { AlertsService } from '../../services/alerts.service';

export interface AddressModalData {
  point: Point;
}

@Component({
  selector: 'wc-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent implements OnInit {
  @ViewChild(AddressComponent) addressControl!: AddressComponent;

  _incident!: Incident;
  @Input()
  set incident(incident: Incident) {
    this._incident = incident;
    this.form.get('address')?.setValue(this._incident?.address);
  }

  @Output() addressChanged: EventEmitter<Address> = new EventEmitter<Address>();
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  isOutsideJurisdiction!: boolean;
  disableButtons = false;

  form: FormGroup = new FormGroup({
    address: new FormControl(null, [AddressRequiredValidator]),
  });

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  constructor(
    private alertService: AlertsService,
    private uiStore: UiStore,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.isOutsideJurisdiction = false;
    this.form.valueChanges.subscribe(form => {
      this.localStorageService.set(LocalStorageKeys.EditIncidentStepOne, {
        address: form.address,
        location: form.point,
      });
    });
  }

  close(event?: MouseEvent) {
    if (!event || (event.target instanceof Element && event.target.className === 'backdrop')) {
      this.closed.emit(true);
    }
  }

  cancel() {
    this.close();
  }

  save() {
    if (this.isOutsideJurisdiction) {
      return;
    }

    this.addressControl.markAsTouched();
    if (this.form.valid) {
      const address: Address = this.form.get('address')?.value;
      this._incident.address = address;
      this.addressChanged.emit(address);
      this.closed.emit(true);
    }
  }

  async onAddressError(error: { errorCode: number }) {
    if (error?.errorCode === -1) {
      const errorMessage: string = await this.translateService
        .get(`errorMessages.unableToLocateTheDeviceLocation`)
        .toPromise();
      !this.alertService.isShownAlready(errorMessage) && this.alertService.error(errorMessage);
      return;
    }
    if (error?.errorCode === 400) {
      console.log('outside workspace');
      this.isOutsideJurisdiction = true;
    } else {
      this.isOutsideJurisdiction = false;
    }
    if (error?.errorCode && error?.errorCode !== -1) {
      const errorMessage: string = await this.translateService.get(`errorMessages.${error.errorCode}`).toPromise();
      !this.alertService.isShownAlready(errorMessage) && this.alertService.error(errorMessage);
    }
  }

  onAddressLoading(loading: boolean) {
    loading ? (this.disableButtons = true) : (this.disableButtons = false);
  }
}
