import { Pipe, PipeTransform } from '@angular/core';
import { MeasureUnitType } from '@wc/core/models/gql.models';

@Pipe({
  name: 'rangeFormat',
})
export class RangeFormatPipe implements PipeTransform {
  transform(value: number, unit: MeasureUnitType | undefined): string {
    if (!value) {
      value = 0;
    }

    return unit === MeasureUnitType.Metric ? value.toFixed(0).toString() : this.getFeet(value).toFixed(0).toString();
  }

  private getFeet(meters: number) {
    return meters * 3.2808;
  }
}
