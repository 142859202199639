import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AccountStore, FeatureType, LiveMapStore, UiStore, Workspace } from '@wc/core';
import { FormFieldData } from '@wc/wc-ui/src/lib/base';
import { comparer, reaction } from 'mobx';
import { FieldData } from '../../form-controls/form-models';

@Component({
  selector: 'wc-workspaces-list',
  templateUrl: './workspaces-list.component.html',
  styleUrls: ['./workspaces-list.component.scss'],
})
export class WorkspacesListComponent implements OnInit {
  workspacesForm!: FormGroup;
  fieldDataArray: FieldData[] = [];
  filedFormDate: { [index: number]: FormFieldData } = {};
  workspacesArray!: Workspace[];
  selectedWorkspaceIds!: number[];
  isTabletMode: boolean;
  @Input() submitForm: boolean = false;
  @Output() updateSelectedWorkspaceIds: EventEmitter<number[]> = new EventEmitter<number[]>();
  isFilterNearCamerasWorkspace: { [index: number]: boolean } = {};

  constructor(
    private liveMapStore: LiveMapStore,
    private accountStore: AccountStore,
    private uiStore: UiStore,
    private fb: FormBuilder
  ) {
    this.isTabletMode = this.uiStore.isTabletMode;
    this.setSelectionState(this.liveMapStore.selectedWorkspacesIds);
    reaction(
      () => this.liveMapStore.selectedWorkspacesIds,
      val => {
        const state: boolean[] = [];
        this.fieldDataArray.forEach(option => state.push(val.includes(option.value)));
        this.workspacesForm.get('workspaces')?.setValue(state);
      },
      { equals: comparer.shallow }
    );
  }

  setSelectionState(selectedWorkspaceIds) {
    this.workspacesArray = this.accountStore.account.workspaces.workspaces;
    const controlsArr: FormControl[] = [];
    this.workspacesArray.forEach((workspace, index) => {
      this.isFilterNearCamerasWorkspace[index] = workspace.featureTypes.includes(FeatureType.FilterNearCameras);
      const formControl = new FormControl(selectedWorkspaceIds.includes(workspace.id));
      controlsArr.push(formControl);
      this.fieldDataArray.push({
        label: workspace.title,
        value: workspace.id,
        labelPosition: this.isTabletMode ? 'after' : 'before',
      });
      this.filedFormDate[index] = { label: workspace.title };
    });
    this.workspacesForm = this.fb.group({
      workspaces: this.fb.array(controlsArr),
    });
  }

  get isTablet() {
    return this.uiStore.isTabletMode;
  }

  ngOnInit(): void {
    this.workspacesForm.valueChanges.subscribe(res => {
      this.setSelectedWorkspaces();
    });
  }

  get controls() {
    return this.workspacesForm?.controls.workspaces['controls'];
  }

  setSelectedWorkspaces() {
    this.selectedWorkspaceIds = [];
    for (const workspace in this.workspacesForm?.controls?.workspaces['controls']) {
      if (this.workspacesForm?.controls?.workspaces['controls'][workspace]?.value) {
        const id = Number(this.fieldDataArray[workspace].value);
        this.selectedWorkspaceIds.push(id);
      }
    }
    this.updateSelectedWorkspaceIds.emit(this.selectedWorkspaceIds);
  }
}
