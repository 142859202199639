import { coerceArray } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { datadogLogs } from '@datadog/browser-logs';
import { TranslateService } from '@ngx-translate/core';
import { EnumToOptions } from '@wc/wc-common/src/lib/utils/convertToSelectOptions';
import { EntitiesServiceV2 } from '@wc/wc-core/src/lib/services/entities.service';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { IncidentStoreEntity } from '@wc/wc-models/src';
import { ConfirmModalService } from '@wc/wc-ui/src';
import { CompleteRejectFormComponent } from '@wc/wc-ui/src/components/complete-reject-form/complete-reject-form.component';
import { FormFieldData, FormFieldOption } from '@wc/wc-ui/src/lib/base/custom-form-control';
import { cloneDeep, intersection as _intersection, union as _union } from 'lodash';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import moment from 'moment';
import { BehaviorSubject, forkJoin, merge, noop, Observable, of, Subject, Subscription, throwError, timer } from 'rxjs';
import {
  catchError,
  concatAll,
  filter,
  finalize,
  map,
  mergeAll,
  mergeMap,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { SelectOption } from '../../features/ui/form-controls/form-models';
import { ObjSortPipeInterface } from '../../features/ui/pipes/obj-sort.pipe';
import { AlertsService } from '../../features/ui/services/alerts.service';
import * as Utils from '../../utils';
import {
  AffectedDirectionsToOptions,
  generateDisplayNameForRelatedEventOption,
  selectedOptionsAlphaNumericSort,
} from '../../utils';
import {
  CamerasQuery,
  DmsQuery,
  EntitiesQuery,
  IncidentsQuery,
  LiveMapService,
  MediaApiService,
  ResponsePlanService,
  SharePublicService,
  SoundsService,
} from '../../wc-core/src';
import { environment } from '../environments/environment';
import { MOCK_DATA } from '../mock-data/mock.data';
import {
  asRoadEventCameraInput,
  CollaborationMessageType,
  IncidentCollaboration,
  IncidentCollaborationMessage,
  IncidentIndicationExpiry,
  IncidentTypeOrder,
  IncidentTypeOrderWithUnidentified,
  LiveMapEntity,
  LiveMapEntityType,
  MainEntityType,
  MergeUpdate,
  RoadEventCamera,
  SharePublicResponseUpdate,
  TransitUnitCollaboration,
  UploadEntityType,
} from '../models';
import { AppSounds, ConfirmationModalType, RoadEventsPanelTabIndex, WebSocketType } from '../models/enums';
import { UIIncident } from '../models/extended.models';
import {
  Address,
  AddressInput,
  AssociatedCamera,
  AssociatedCameraInput,
  Color,
  CreateIncidentMutationInput,
  DeleteIncidentMitigationInput,
  DeleteIncidentUnitInput,
  Direction,
  EndIncidentInput,
  EntityType,
  FindIncidentsInPointRadiusInput,
  Incident,
  IncidentAdditionalInfoInput,
  IncidentAttribute,
  IncidentConfiguration,
  IncidentEndReason,
  IncidentIndicationType,
  IncidentMitigation,
  IncidentMitigationInput,
  IncidentReportSourceInput,
  IncidentSeverity,
  IncidentStatus,
  IncidentType,
  IncidentUnit,
  IncidentUnitInput,
  IncidentView,
  InjurySeverity,
  InvolvedVehicleInput,
  LaneType,
  LayerType,
  NotificationDestination,
  Orientation,
  PublishInput,
  RelatedIncident,
  ResponsePlanEntityType,
  RoadType,
  SegmentDetails,
  State,
  UnitResponse,
  UpdateIncidentMitigationInput,
  UpdateIncidentMitigationsInput,
  UpdateIncidentUnitInput,
  VehicleBrand,
  VehicleCategory,
  VehicleType,
} from '../models/gql.models';
import { LocalStorageService, NavigationService } from '../services';
import { CustomRxOperatorsService } from '../services/custom-rx-operators.service';
import { GeoService } from '../services/geo.service';
import { IncidentCompletedUpdateMessage, IncidentService } from '../services/incident.service';
import { Position } from '../services/location.service';
import { OfflineService } from '../services/offline.service';
import { WebsocketService } from '../services/websocketService';
import { AccountStore } from './account.store';
import { ActivityLogStore } from './activity-log.store';
import { EntitiesStore } from './entities.store';
import { LiveMapStore } from './live-map.store';
import { ShiftStore } from './shift.store';
import { OfflineEvent, UiStore } from './ui.store';
import { UploadStore } from './upload.store';
import { UsersStore } from './users.store';

export type LastActiveEditIncident = {
  incidentId: number;
  timestamp: Date;
};

export type IncidentsCollaboration = {
  view: { incidentId?: [string] };
  edit: { incidentId?: [string] };
};

@Injectable({
  providedIn: 'root',
})
export class IncidentStore {
  private newSoundAlertEntities = new Subject<string[]>();
  newSoundAlertEntities$ = this.newSoundAlertEntities.asObservable();
  concatPreventEditTime?: Subscription;
  interactedIncidentId$ = new Subject<number>();
  indicationExpiryListChangeId$ = new Subject<number>();
  indicationExpiryList: IncidentIndicationExpiry[] = this.localStorageService.get(
    LocalStorageKeys.IncidentIndicationsIds
  )
    ? this.localStorageService.get(LocalStorageKeys.IncidentIndicationsIds)
    : [];
  @observable selectedIncidentDMSs: LiveMapEntity[] = [];
  @observable incidentSubtypes: SelectOption[] = [];

  @observable segmentDetails: SegmentDetails = {} as SegmentDetails;
  @observable incidentsCompletedList: IncidentView[] = [];
  @observable isEditMode = false;
  @observable currentPointerLocationOnMap: number[] = [] as number[];
  @observable restoredIncidentesIds: { id; restoreTime: Date }[] = [];
  @observable IncidentsCollaboration: {
    view: { incidentId?: [string] };
    edit: { incidentId?: [string] };
  } = { view: {}, edit: {} };

  private readonly incidentsCollaborationSubject = new BehaviorSubject<IncidentsCollaboration>({
    view: {},
    edit: {},
  });

  incidentsCollaboration$ = this.incidentsCollaborationSubject.asObservable();

  private readonly _onIncidentEdited = new Subject<number>();
  readonly onIncidentEdited$ = this._onIncidentEdited.asObservable();
  toastTapSubscription: Subscription | undefined;

  private set onIncidentEdited(incidentId: number) {
    this._onIncidentEdited.next(incidentId);
  }

  private _currentPendingDelete: number[] = [];
  addressContinueEditMode = false;
  private _cachedInprogressIncidents!: Record<string, boolean>;
  private _cachedNeedActionIncidentIds!: Record<string, boolean>;
  private currentUserLastCollaboration?: IncidentCollaborationMessage;
  private _cachedInprogressIncidentsIdsWhoHadSound: Record<string, boolean> = {};
  modifiedCameras: AssociatedCameraInput[] = [];
  removedCameras: number[] = [];
  lastCompletedIncidents;
  hasInsight = false;

  get cachedNeedActionIncidentIds() {
    return this._cachedNeedActionIncidentIds;
  }

  get cachedInprogressIncidentIdsWhoHadSound(): Record<string, boolean> {
    return this._cachedInprogressIncidentsIdsWhoHadSound;
  }

  get cachedInprogressIncidents(): Record<string, boolean> {
    return this._cachedInprogressIncidents;
  }

  @computed
  get editCollaboration() {
    return this.IncidentsCollaboration.edit;
  }

  @computed
  get viewCollaboration() {
    return this.IncidentsCollaboration.view;
  }

  @computed
  get incidentCollaboration() {
    const allCollaborations = this.IncidentsCollaboration.view;
    for (const incidentId in this.IncidentsCollaboration.edit) {
      allCollaborations[incidentId] = _union(
        this.IncidentsCollaboration.edit[incidentId],
        this.IncidentsCollaboration.view[incidentId]
      );
    }
    return allCollaborations;
  }

  clearCurrentUserCollaboration() {
    if (this.currentUserLastCollaboration) {
      this.updateIncidentCollaboration(
        this.currentUserLastCollaboration.incidentId,
        this.currentUserLastCollaboration.type
      );
    }
  }

  constructor(
    private incidentService: IncidentService,
    private entitiesStore: EntitiesStore,
    private dialog: MatDialog,
    private uiStore: UiStore,
    private usersStore: UsersStore,
    private liveMapStore: LiveMapStore,
    private liveMapService: LiveMapService,
    private uploadStore: UploadStore,
    private accountStore: AccountStore,
    private mediaApiService: MediaApiService,
    private translateService: TranslateService,
    private activityLogStore: ActivityLogStore,
    private alertService: AlertsService,
    private localStorageService: LocalStorageService,
    private shiftStore: ShiftStore,
    private wsService: WebsocketService,
    private geoService: GeoService,
    private sounds: SoundsService,
    private confirmService: ConfirmModalService,
    private router: Router,
    private navigationService: NavigationService,
    private entitiesService: EntitiesServiceV2,
    private entitiesQuery: EntitiesQuery,
    private incidentsQuery: IncidentsQuery,
    private dmsQuery: DmsQuery,
    private route: ActivatedRoute,
    private customRxjsService: CustomRxOperatorsService,
    private camerasQuery: CamerasQuery,
    private responsePlanService: ResponsePlanService,
    private sharePublicService: SharePublicService
  ) {
    makeObservable(this);
    if (this.uiStore.isTabletMode) {
      this.oldMobxReactionListeners();
    }

    const visibleNeedActionIncidents$ = this.incidentsQuery.visibleNeedActionIncidents.pipe(
      takeWhile(() => !this.uiStore.isTabletMode),
      filter(incidents => incidents.length > 0),
      tap(incidents => this.handleNeedActionIncidentsToPlaySoundAlert(incidents))
    );

    const visibleInProgressIncidents$ = this.incidentsQuery.visibleInProgressIncidents.pipe(
      takeWhile(() => this.uiStore.isTabletMode),
      filter(incidents => incidents.length > 0),
      tap(incidents => this.handleInProgressIncidentsToPlaySoundAlert(incidents))
    );

    this.entitiesQuery
      .select()
      .pipe(takeWhile(() => !this.uiStore.isTabletMode))
      .subscribe({
        next: () => this.handleIncidentIndicationList(),
      });

    this.incidentsQuery
      .selectAll()
      .pipe(
        takeWhile(() => !this.uiStore.isTabletMode),
        filter(incidents => incidents.length > 0),
        this.customRxjsService.tapOnce(incidents => {
          const { needActionList, inProgressList } = incidents.reduce<{
            needActionList: IncidentStoreEntity[];
            inProgressList: IncidentStoreEntity[];
          }>(
            (acc, inc) => {
              if (inc.status === IncidentStatus.Unconfirmed) acc.needActionList.push(inc);
              if (inc.status === IncidentStatus.Confirmed) acc.inProgressList.push(inc);
              return acc;
            },
            {
              needActionList: [],
              inProgressList: [],
            }
          );
          this.cacheIncidents(needActionList, (this._cachedNeedActionIncidentIds = {}));
          this.cacheIncidents(inProgressList, (this._cachedInprogressIncidents = {}));
        }),
        switchMap(() => merge([visibleNeedActionIncidents$, visibleInProgressIncidents$]).pipe(mergeAll()))
      )
      .subscribe();

    /** @description Non-tablet incident indications should be expired once the incident is clicked */
    if (!this.uiStore.isTabletMode) {
      this.interactedIncidentId$.subscribe(incidentId => {
        this.handleExpireIncidentIndication(incidentId);
      });
    }

    this.initCompletedIncidentAssociatedUnitNotification();
  }

  private oldMobxReactionListeners() {
    reaction(
      () => this.entitiesStore.entities,
      () => {
        /* @description We are caching the first items the user saw,
         * to be able to play sound only for ones that was added into this list afterwords.
         */
        if (!this._cachedInprogressIncidents && this.uiStore.isTabletMode) {
          this._cachedInprogressIncidents = {};
          this.cacheIncidents(this.incidentsInProgressList, this._cachedInprogressIncidents);
          return;
        }

        if (!this._cachedNeedActionIncidentIds && !this.uiStore.isTabletMode) {
          this._cachedNeedActionIncidentIds = {};
          this.cacheIncidents(
            this.incidentsList.filter(({ status }) => status === IncidentStatus.Unconfirmed),
            this._cachedNeedActionIncidentIds
          );
          return;
        }

        /** @description tablet user need to hear sound for inprogress list, while desktop users hearing
         * for need action.
         */
        this.uiStore.isTabletMode
          ? this.handleInProgressIncidentsToPlaySoundAlert(this.incidentsInProgressList)
          : this.handleNeedActionIncidentsToPlaySoundAlert(this.incidentsNeedActionList);

        /** @description updating incidentIndicationsIds, re-enables indications that are no longer expired */
        if (!this.uiStore.isTabletMode) {
          this.handleIncidentIndicationList();
        }
      }
    );
    reaction(
      () => this.liveMapStore.layersVisibility,
      () => {
        /**@description we cache here so the user wont hear alerts when changing layers */
        if (this.uiStore.isTabletMode) {
          this.cacheIncidents(this.incidentsInProgressList, this._cachedInprogressIncidents);
        } else {
          this.cacheIncidents(this.incidentsNeedActionList, this._cachedNeedActionIncidentIds);
        }
      }
    );
  }

  startCompleteReject(incident: Incident, status: IncidentStatus, closeIncident: boolean = true) {
    const unitResponses = [UnitResponse.OnScene, UnitResponse.UnknownUnitStatus, UnitResponse.EnRoute]; // allowed types for feature
    const associatedUnitsHaveMatchingUnitResponse = incident.associatedUnits.some(
      unit => unit && unitResponses.includes(unit.unitResponse)
    );

    if (
      associatedUnitsHaveMatchingUnitResponse &&
      !this.uiStore.isTabletMode &&
      [IncidentStatus.Completed, IncidentStatus.Rejected].includes(status)
    ) {
      this.confirmService.showConfirmDialog(ConfirmationModalType.CompleteHasUnits, status, confirmed => {
        if (confirmed) {
          this.openCompleteRejectFormDialog(incident, status, closeIncident);
        }
      });
    } else this.openCompleteRejectFormDialog(incident, status, closeIncident);
  }

  private openCompleteRejectFormDialog(incident: Incident, status: IncidentStatus, closeIncident: boolean) {
    const dialogRef = this.dialog.open(CompleteRejectFormComponent, {
      width: this.uiStore.isTabletMode ? '608px' : '440px',
      height: 'auto',
      panelClass: 'mitigation-form-modal',
      data: {
        details: { incident: incident },
        hasActions: status === IncidentStatus.Rejected,
      },
    });

    if (closeIncident)
      dialogRef.afterClosed().subscribe(dialogConfirmed => {
        if (dialogConfirmed)
          setTimeout(() => {
            this.closeIncident();
          }, 1000);
      });
  }

  closeIncident() {
    this.removeLocalStorageUserEditTime();
    const isTabletMode = this.uiStore.isTabletMode;
    if (!isTabletMode) {
      // desktop
      this.liveMapStore.removeInteractiveEntity();
      this.clearIncidentSelection();
      this.uiStore.resetEventLoaders();
      this.router.navigateByUrl('/live-map');
    } else {
      // tablet
      const lastRoute = this.navigationService.getLastRoute();
      if (lastRoute?.includes('/live-map')) {
        this.router.navigate(['/live-map'], {
          fragment: this.route.snapshot.fragment ?? undefined,
        });
      } else {
        this.navigationService.back();
      }
      this.clearIncidentSelection();
    }
  }

  handleNeedActionIncidentsToPlaySoundAlert(incidentsNeedActionList: IncidentStoreEntity[]) {
    const needActionDiff = incidentsNeedActionList.filter(({ id }) => !this._cachedNeedActionIncidentIds[id]);
    if (needActionDiff?.length) {
      this.sounds.playSound(AppSounds.incidentAlert, needActionDiff.length);
      this.cacheIncidents(needActionDiff, this._cachedNeedActionIncidentIds);
    }
  }

  private handleInProgressIncidentsToPlaySoundAlert(incidentsInProgressList: IncidentStoreEntity[]) {
    const diff = incidentsInProgressList.filter(({ id }) => !this._cachedInprogressIncidents[id]);
    this.cacheIncidents(diff, this._cachedInprogressIncidents);
    const diffForSound = diff.filter(
      ({ confirmedBy, createdBy, confirmedAt }) =>
        // If confirmedAt and confirmedBy are missed, it's an incident came back after was rejected,
        // and we don't wont to hear sound due to BE logic problems and limitation
        confirmedAt &&
        confirmedBy &&
        //If confirmedAt is there, it came from need action, or was and not rejected/completed. than, we will apply
        // Sound notifications only for newly confirmed incidents( < 15m)
        moment.duration(moment(Date.now()).diff(confirmedAt)).asMinutes() < 15 &&
        this.usersStore.authUserID !== confirmedBy &&
        this.usersStore.authUserID !== createdBy
    );
    if (diffForSound?.length) {
      this.newSoundAlertEntities.next(diffForSound.map(({ id }) => id.toString()));
      this.sounds.playSound(AppSounds.incidentAlert, diffForSound?.length);
      this.cacheIncidents(diffForSound, this._cachedInprogressIncidentsIdsWhoHadSound);
    }
  }

  handleIncidentIndicationList() {
    const localStorageList: IncidentIndicationExpiry[] = this.localStorageService.get(
      LocalStorageKeys.IncidentIndicationsIds
    );
    const currentTime = new Date().getTime();
    // Find re-enabled incident indication
    const incidentIndicationReEnabled = localStorageList?.find(
      incidentIndication => incidentIndication.expiryTime < currentTime
    );
    // Filter out the re-enabled indication and update lists
    if (incidentIndicationReEnabled) {
      const updatedIndicationsList = this.indicationExpiryList.filter(
        incidentIndication =>
          !(
            incidentIndication.id === incidentIndicationReEnabled?.id &&
            incidentIndication.indicationType === incidentIndicationReEnabled?.indicationType
          )
      );
      this.indicationExpiryList = updatedIndicationsList;
      this.localStorageService.set(LocalStorageKeys.IncidentIndicationsIds, this.indicationExpiryList);

      // Trigger view change in the relevant incident card
      this.indicationExpiryListChangeId$.next(incidentIndicationReEnabled?.id);
    }
  }

  handleExpireIncidentIndication(incidentId: number) {
    // Find incident in incidentsInProgressList
    const { interactedIncident, currentIndication } = this.getInteractedIncidentAndCurrentIndication(`${incidentId}`);
    if (
      interactedIncident?.status === IncidentStatus.Confirmed &&
      interactedIncident?.indications?.length &&
      currentIndication
    ) {
      // Expire the next indication in 12 hours
      const TTL = 43200000;
      const expiryTime = new Date().getTime() + TTL;
      const indicationType = currentIndication;
      const indicationEpiryObj: IncidentIndicationExpiry = { id: +incidentId, expiryTime, indicationType };
      this.indicationExpiryList.push(indicationEpiryObj);
      this.localStorageService.set(LocalStorageKeys.IncidentIndicationsIds, this.indicationExpiryList);
    }
  }

  private getInteractedIncidentAndCurrentIndication(incidentId: string): {
    interactedIncident: Partial<UIIncident & IncidentView> | undefined;
    currentIndication: IncidentIndicationType | undefined;
  } {
    if (!this.uiStore.isTabletMode) {
      return {
        interactedIncident: this.incidentsQuery.getEntity(+incidentId) as UIIncident & IncidentView,
        currentIndication: this.incidentsQuery.ui.getEntity(incidentId)?.currentIndication,
      };
    }
    const interactedIncident = this.incidentsInProgressList.find(
      incident => incident.id.toString() === incidentId
    ) as UIIncident & IncidentView;
    return {
      interactedIncident,
      currentIndication: interactedIncident?.currentIndication as IncidentIndicationType | undefined,
    };
  }

  private cacheIncidents(incidents: Incident[], cacheEntity: Record<string, boolean>): void {
    incidents.forEach(({ id }) => (cacheEntity[id] = true));
  }

  initCollaborationSync() {
    this.wsService.connect(); //environment.ws_url
    this.wsService.subject
      .pipe(
        map((res: IncidentCollaboration[] | TransitUnitCollaboration | MergeUpdate | SharePublicResponseUpdate) => {
          if (Array.isArray(res) && res[0]?.type === WebSocketType.incidentUpdate) {
            const collaborationState = { view: {}, edit: {} };
            const authUserName = this.usersStore.authUser.name;
            res.forEach(item => {
              if (!item.type || item.type === WebSocketType.incidentUpdate) {
                const incident = item;
                if (incident.editing && incident.editing?.length > 0)
                  collaborationState.edit[incident.incidentId] = incident.editing.filter(name => name !== authUserName);
                if (incident.viewing && incident.viewing?.length > 0)
                  collaborationState.view[incident.incidentId] = incident.viewing.filter(name => name !== authUserName);
              }
            });
            return collaborationState;
          } else if (this.uiStore.isTabletMode && (res as MergeUpdate).type === WebSocketType.mergeIncident) {
            const update = res as MergeUpdate;
            const url = this.router.url;
            if (
              url.includes('road-events') &&
              update.mergeIncidentUpdate.unitsUsersInvolved.includes(this.usersStore.authUserID)
            ) {
              this.showIncidentMergedIntoAnotherIncidentToast(update);
            } else if (url.includes(`${update.mergeIncidentUpdate.sourceIncidentId}`)) {
              this.showIncidentMergedIntoAnotherIncidentDialog(update);
            }
          } else if ((res as SharePublicResponseUpdate).type === WebSocketType.sharePublicResponse) {
            const update = res as SharePublicResponseUpdate;
            this.sharePublicService.showWSPublishErrorToast(update);
          }
          return null;
        })
      )
      .subscribe(res => {
        if (res) {
          runInAction(() => {
            this.IncidentsCollaboration = { ...res };
            this.incidentsCollaborationSubject.next(this.IncidentsCollaboration);
          });
        }
      });
  }

  createMergeIncidentUpdateObject(
    targetIncidentId: number,
    sourceIncidentId = 0,
    unitsUsersInvolved: number[] = []
  ): MergeUpdate {
    return {
      type: 'merge_incident',
      mergeIncidentUpdate: {
        targetIncidentId,
        sourceIncidentId,
        unitsUsersInvolved,
      },
    };
  }

  showIncidentMergedIntoAnotherIncidentToast(mergeUpdate: MergeUpdate) {
    const toast = `
      <div>${this.translateService.instant('notifications.incidentMergedIntoAnotherIncident', {
        incidentId: mergeUpdate.mergeIncidentUpdate.sourceIncidentId,
      })}
        <a>${mergeUpdate.mergeIncidentUpdate.targetIncidentId}</a>
      </div>
    `;

    const activeToast = this.alertService.info(toast, undefined, { enableHtml: true, disableTimeOut: true }, false);

    this.toastTapSubscription = activeToast?.onTap.pipe(take(1)).subscribe(() => {
      this.router.navigate(['incidents', mergeUpdate.mergeIncidentUpdate.targetIncidentId]);
    });
  }

  showIncidentMergedIntoAnotherIncidentDialog(mergeUpdate: MergeUpdate) {
    if (mergeUpdate.mergeIncidentUpdate.targetIncidentId) {
      this.confirmService.showConfirmDialog(
        ConfirmationModalType.IncidentMergedIntoAnotherIncident,
        `${mergeUpdate.mergeIncidentUpdate.targetIncidentId}`,
        // res => true = confirm -> view merged incident button, false = dismiss -> back to list button
        res => {
          if (res) {
            this.uiStore.showLoader('incident');
            this.router.navigateByUrl('incidents/' + mergeUpdate.mergeIncidentUpdate.targetIncidentId.toString());
          } else this.router.navigate(['road-events']);
        }
      );
    }
  }

  updateIncidentCollaboration(incidentId: number, collaborationType: CollaborationMessageType, accountId?: number) {
    const msg: IncidentCollaborationMessage = {
      type: collaborationType,
      customerId: accountId || this.accountStore.account.customer.id,
      userName: this.usersStore.authUser.name,
      incidentId: incidentId,
    };
    this.wsService.send(msg);
    if (collaborationType === CollaborationMessageType.START_EDIT) {
      msg.type = CollaborationMessageType.COMPLETE_EDIT;
      this.currentUserLastCollaboration = msg;
    } else if (collaborationType === CollaborationMessageType.START_VIEW) {
      msg.type = CollaborationMessageType.COMPLETE_VIEW;
      this.currentUserLastCollaboration = msg;
    } else {
      this.currentUserLastCollaboration = undefined;
    }
  }

  initCompletedIncidentAssociatedUnitNotification() {
    if (this.uiStore.isTabletMode) {
      this.wsService.connect();
      this.wsService.subject
        .pipe()
        .subscribe(({ type, incidentId, incidentAddress }: IncidentCompletedUpdateMessage) => {
          if (type !== 'incident_completed') return;
          if (incidentId && incidentAddress) {
            const toastTitle = `${this.translateService.instant('entityTypes.incident')} ${incidentId} | ${
              incidentAddress.corridor
            } ${incidentAddress.orientation} ${incidentAddress.crossroad} ${incidentAddress.roadType}`;

            this.alertService.success(
              this.translateService.instant('notifications.incidentMovedToCompletedByAnotherUser'),
              toastTitle,
              {
                // 10 minute toast duration
                timeOut: 1000 /*milliseconds*/ * 60 /*seconds */ * 10 /*minutes*/,
                extendedTimeOut: 1000 /*milliseconds*/ * 60 /*seconds */ * 10 /*minutes*/,
              },
              true
            );
          }
        });
    }
  }

  updateSelectedIncidentFromLivemap(incident: Partial<Incident>) {
    if (incident && incident.type) {
      const entitiesByIncidentType = this.entitiesStore.entities[incident.type.toLowerCase()];
      if (entitiesByIncidentType && incident.id && entitiesByIncidentType[incident.id] && !this.isEditMode) {
        incident.associatedUnits = entitiesByIncidentType[incident.id].associatedUnits;
      }
    }
    return incident;
  }

  uploadeFiles(entityId) {
    if (!entityId) return;
    this.uploadStore.addFilesToQueueAndUpload(UploadEntityType.Incident, entityId).subscribe();
    this.incidentService.updateOfflineMedia(entityId, this.mediaApiService.pendingUploadFiles);
  }

  getIncidentsCompletedList() {
    if (!navigator.onLine) return;
    this.incidentService
      .findLatestIncidents()
      .pipe(
        map(incidents =>
          incidents.map(incident => ({ ...incident, show: incident.type === IncidentType.Unidentified ? true : false }))
        ),
        tap(incidents => {
          this.lastCompletedIncidents = incidents;
          this.entitiesService.emitNewEntitiesDiff({
            modified: {
              INCIDENT: incidents,
            },
          });
        })
      )
      .subscribe(
        incidents => {
          runInAction(() => {
            this.incidentsCompletedList = incidents as IncidentView[];
          });
          this.incidentsCompletedList.forEach((completedIncident: IncidentView) =>
            this.incidentService.incidentCacheMap.set(completedIncident.id.toString(), completedIncident)
          );
          setTimeout(() => {
            this.uiStore.hideLoader('incident');
          }, 100);
        },
        () => {
          this.uiStore.hideLoader('incident');
        }
      );
  }

  clearIncidentSelection() {
    runInAction(() => {
      this.liveMapStore.removeInteractiveEntity();
      this.liveMapStore.unselectFeature('incident');
      this.selectedIncidentDMSs = [];
      datadogLogs.removeLoggerGlobalContext('wc_incident_details');
    });
  }

  removeLocalStorageUserEditTime() {
    this.localStorageService.remove('userLastEdit');
  }

  @computed
  get incidentsCompleted() {
    if (!this.uiStore.isTabletMode) {
      return [];
    }
    return this.incidentsCompletedList.filter(incident => {
      let valid = this.liveMapStore.layersVisibility.includes(incident.type.toLowerCase() as any);
      if (valid) {
        valid = this.liveMapStore.filterByWorkspace(incident);
      }

      return valid;
    });
  }

  @computed
  get incidentsNeedActionList() {
    if (!this.uiStore.isTabletMode) {
      return [];
    }

    return this.incidentsList.filter(incident => {
      let valid =
        !this.liveMapStore.layersVisibility ||
        this.liveMapStore.layersVisibility.includes(incident.type?.toLowerCase() as any);
      if (valid) valid = incident.status === IncidentStatus.Unconfirmed;
      if (valid) {
        valid = this.liveMapStore.filterByWorkspace(incident);
      }
      return valid;
    });
  }

  @computed
  get incidentsInProgressList() {
    if (!this.uiStore.isTabletMode) {
      return [];
    }
    const incidentsInProgressList = this.incidentsList.filter(incident => {
      let valid =
        !this.liveMapStore.layersVisibility ||
        this.liveMapStore.layersVisibility.includes(incident.type?.toLowerCase() as any);
      if (valid) valid = incident.status === IncidentStatus.Confirmed;
      if (valid) {
        valid = this.liveMapStore.filterByWorkspace(incident);
      }

      return valid;
    });
    return incidentsInProgressList;
  }

  @computed
  get incidentsList() {
    let incidents: Array<Incident & IncidentView> = [];
    if (this.entitiesStore.entities && Object.keys(this.entitiesStore.entities).length === 0) return [];
    for (const key in IncidentType) {
      if (IncidentType.hasOwnProperty(key)) {
        const entityType = IncidentType[key].toLowerCase();
        if (this.entitiesStore.entities && this.entitiesStore.entities[entityType]) {
          const incidentsList = Utils.toArray(this.entitiesStore.entities[entityType]);
          incidents = [...incidents, ...incidentsList];
        }
      }
    }
    return incidents;
  }

  @computed
  get vehicleOptions() {
    const types = EnumToOptions(VehicleCategory, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'involvedVehicleProperties.types',
      firstOptionValue: VehicleCategory.UnknownVehicleCategory,
    });
    const brands = EnumToOptions(VehicleBrand, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'involvedVehicleProperties.brands',
      firstOptionValue: VehicleBrand.UnknownVehicleBrand,
    });
    const colors = EnumToOptions(Color, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'involvedVehicleProperties.colors',
      firstOptionValue: Color.UnknownColor,
    });
    const states: SelectOption[] = Object.values(State)
      .filter(state => {
        return state !== State.UnknownState;
      })
      .map(state => ({ value: state, displayName: state }));

    states.unshift({
      value: State.UnknownState,
      displayName: this.translateService.instant(`involvedVehicleProperties.states.${State.UnknownState}`),
    });

    return { types, brands, colors, states };
  }

  @computed
  get accountAdditionalInfosOptionList(): Array<SelectOption> {
    const additionalInfos: Array<SelectOption> = [];
    this.accountStore.incidentConfigs.additionalInfoValues.forEach(info => {
      additionalInfos.push({ value: info.id, displayName: info.info });
    });
    return additionalInfos;
  }

  @computed
  get incidentStatus(): Array<SelectOption> {
    return Utils.EnumToOptions(IncidentStatus, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'statusType',
    });
  }

  @computed
  get incidentTypes(): Array<SelectOption> {
    let types = Utils.EnumToOptions(IncidentTypeOrder, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'incidentTypes',
      removeSort: true,
    });
    types.forEach(type => {
      type.hidden = !this.accountStore.incidentConfigs?.incidentTypeValues.includes(type.value);
    });
    // Temp filter
    types = types.filter(opt => opt.value !== 'UNKNOWN_INCIDENT_TYPE');
    return types;
  }

  @computed
  get severityOptions(): Array<SelectOption> {
    let options = Utils.strArrayToOptions(
      [
        IncidentSeverity.MinorSeverity,
        IncidentSeverity.IntermediateSeverity,
        IncidentSeverity.MajorSeverity,
        IncidentSeverity.CriticalSeverity,
      ],
      {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'incidentSeverity',
        removeSort: true,
      }
    );
    options.forEach(type => {
      type.hidden = !this.accountStore.incidentConfigs?.severityValues.includes(type.value);
    });
    return options;
  }

  get injuriesSeverityOptions(): Array<SelectOption> {
    let options = Utils.strArrayToOptions(
      [
        InjurySeverity.MinorInjurySeverity,
        InjurySeverity.IntermediateInjurySeverity,
        InjurySeverity.MajorInjurySeverity,
        InjurySeverity.FatalInjurySeverity,
        InjurySeverity.UndeterminedInjurySeverity,
      ],
      {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'injurySeverity',
        removeSort: true,
      }
    );
    return options;
  }

  get incidentTypesWithUnidentified(): Array<SelectOption> {
    let types = Utils.EnumToOptions(IncidentTypeOrderWithUnidentified, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'incidentTypes',
    });
    // Temp filter
    types = types.filter(opt => opt.value !== 'UNKNOWN_INCIDENT_TYPE');
    return types;
  }

  @computed
  get roadTypesOptions(): Array<SelectOption> {
    let types = Utils.EnumToOptions(RoadType, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'roadTypes',
    });
    types = types.filter(rt => rt.value !== 'UNKNOWN_ROAD_TYPE');
    types.forEach(r => (r.hidden = !this.accountStore.incidentConfigs?.roadTypeValues.includes(r.value)));

    return types;
  }

  @computed
  get lanesOptions(): Array<SelectOption> {
    return MOCK_DATA.laneNumber;
  }

  @computed
  get laneTypesOptions(): Array<SelectOption> {
    return Utils.EnumToOptions(LaneType, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'laneTypes',
    });
  }

  @computed
  get laneDirectionsOptions(): Array<SelectOption> {
    return Utils.EnumToOptions(Direction).map(option => {
      option.displayName = option?.displayName?.toUpperCase();
      return option;
    });
  }

  @computed
  get orientationOptions(): Array<SelectOption> {
    const options = Utils.EnumToOptions(Orientation);
    options.forEach(
      orientation =>
        (orientation.hidden = !this.accountStore.incidentConfigs.orientationValues.includes(orientation.value))
    );
    return options;
  }

  @computed
  get mileMarkersdOptions(): Array<SelectOption> {
    return Utils.strArrayToOptions(this.segmentDetails.milemarkers);
  }

  @computed
  get attributesOptions(): Array<SelectOption> {
    const freeText = this.accountStore.incidentConfigs.attributeFreeTextValues.map<FormFieldOption>(att => {
      return { displayName: att.value, value: att.value, hidden: !att.checked };
    });
    const fixed = Utils.EnumToOptions(IncidentAttribute, {
      translateService: this.translateService,
      translateBy: 'value',
    });
    fixed.forEach(att => (att.hidden = !this.accountStore.incidentConfigs.attributeFixedValues.includes(att.value)));
    return [...fixed, ...freeText];
  }

  @computed
  get corridorOptions(): Array<SelectOption> {
    if (!this.segmentDetails.refs && !this.segmentDetails.names) return [];
    const corridor = this.segmentDetails.refs.concat(this.segmentDetails.names);
    return Utils.strArrayToOptions(corridor);
  }

  @computed
  get incidentStatusOptions(): Array<SelectOption> {
    return MOCK_DATA.incidentStatusOptions;
  }

  @computed
  get notificationDestinationOptions(): Array<FormFieldOption> {
    const publishChannels = [...[NotificationDestination.Waze], ...this.accountStore.account.publishChannels];

    return Utils.EnumToOptions(publishChannels, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'publishChannels',
    });
  }

  @computed
  get associatedUnitsTypes(): Array<SelectOption> {
    return Utils.EnumToOptions(VehicleType, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'VehicleTypes',
    });
  }

  getUnitResponseOptions(unitResponses): Array<SelectOption> {
    return Utils.EnumToOptions(unitResponses, {
      translateService: this.translateService,
      translateBy: 'key',
      translatePath: 'unitResponse',
    });
  }

  @computed
  get subtypeOptions() {
    return this.incidentSubtypes;
  }

  @computed
  get incidentEndReason() {
    const incidentEndReasons = [
      IncidentEndReason.DuplicateIncident,
      IncidentEndReason.IncidentResolved,
      IncidentEndReason.TrafficNotAffected,
      IncidentEndReason.CausedByMaintenanceWork,
      IncidentEndReason.UnavailableCamera,
      IncidentEndReason.NoVisualConfirmation,
      IncidentEndReason.ResourceConstraints,
      IncidentEndReason.TypeNotRelevant,
      IncidentEndReason.LocationNotRelevant,
    ];

    return Utils.strArrayToOptions(incidentEndReasons, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'IncidentEndReason',
      removeSort: true,
    });
  }

  @computed
  get allIncidentEndReason() {
    let reasons = Utils.EnumToOptions(IncidentEndReason, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'IncidentEndReason',
    });
    reasons = reasons.sort((a, b) => (a.displayName || a.value > (b.displayName || b.value) ? 1 : -1));
    return reasons;
  }

  @action updateMode(isEditMode: boolean) {
    this.isEditMode = isEditMode;
  }

  @action updateIncidentSubtypes(incidentType: IncidentType) {
    this.incidentSubtypes = this.accountStore.incidentSubTypeStructure[incidentType] || [];
  }

  @action
  changeSortOrder(tabName: string, input: ObjSortPipeInterface) {
    this[tabName] = { ...{}, ...input };
  }

  @action
  setIncidentStatus(incident: Incident, status: IncidentStatus, mergedTargetId?: number): Observable<any> {
    this.uiStore.showLoader('incident');
    const newDone = () => {
      this.entitiesService.emitNewEntitiesDiff({
        modified: {
          INCIDENT: [{ id: incident.id, status }],
        },
      });
      this.uiStore.hideLoader('incident');
    };

    const oldDone = () => {
      if (status === IncidentStatus.Confirmed) {
        const updatedMapLayer = {
          [incident.type.toLocaleLowerCase()]: {
            [incident.id]: {
              ...incident,
              ...{
                mitigatedAccounts: this._getIncidentMitigatedAccounts(incident),
                featureType: incident.type.toLocaleLowerCase(),
                featureSubTypeOf: MainEntityType.incident,
                status: IncidentStatus.Confirmed,
              },
            },
          },
        };
        this.uiStore.hideLoader('incident');
        this.entitiesStore.modifiedEntities(updatedMapLayer);
      } else {
        this.liveMapStore.updateRemovedEntities({
          [incident['featureType']]: [incident.id],
        });
      }
    };
    const done = () => (!this.uiStore.isTabletMode ? newDone() : oldDone());

    const id: any = Number(incident.id) || incident.id;
    switch (status) {
      case IncidentStatus.Completed:
        const completeEndIncidentInput: EndIncidentInput = {
          endReason: incident.endReason as IncidentEndReason,
          endReasonComment: incident.endReasonComment,
          incidentId: incident.id,
          targetIncidentId: mergedTargetId,
          hazardRemains: incident.hazardRemains,
        };
        return this.incidentService.completeIncident(completeEndIncidentInput).pipe(
          tap(() => done()),
          finalize(() => this.uiStore.hideLoader('incident'))
        );
      case IncidentStatus.Confirmed:
        return this.incidentService.confirmIIncident({ id }).pipe(
          tap(() => done()),
          finalize(() => this.uiStore.hideLoader('incident'))
        );
      case IncidentStatus.Rejected:
        const rejectEndIncidentInput: EndIncidentInput = {
          endReason: incident.endReason as IncidentEndReason,
          endReasonComment: incident.endReasonComment,
          incidentId: incident.id,
          targetIncidentId: mergedTargetId,
          hazardRemains: incident.hazardRemains,
        };
        return this.incidentService.rejectIncident(rejectEndIncidentInput).pipe(
          tap(() => done()),
          finalize(() => this.uiStore.hideLoader('incident'))
        );
      default:
        return throwError('No incident status was provided');
    }
  }

  responsePlanNotDoneDialogResult(incident: Incident, status: IncidentStatus): Promise<boolean> {
    return new Promise(resolve => {
      this.isResponsePlanDone(incident.id).subscribe(done => {
        if (done === false) {
          this.confirmService.showConfirmDialog(
            ConfirmationModalType.ResponsePlanNotDone,
            EntityType.Incident,
            completeAnyWay => {
              if (completeAnyWay) {
                this.startCompleteReject(incident, status);
              }
            }
          );
        } else {
          this.startCompleteReject(incident, status);
        }
      });
    });
  }

  private isResponsePlanDone(id: number) {
    return this.responsePlanService.isResponsePlanDone(id, ResponsePlanEntityType.Incident);
  }

  private updateIncidentAfterChangeStatus(incident: Incident) {
    const updatedMapLayer = {
      [incident.type.toLocaleLowerCase()]: {
        [incident.id]: {
          ...incident,
          ...{
            mitigatedAccounts: this._getIncidentMitigatedAccounts(incident),
            featureType: incident.type.toLocaleLowerCase(),
            featureSubTypeOf: MainEntityType.incident,
          },
        },
      },
    };
    this.uiStore.hideLoader('incident');
    this.entitiesStore.modifiedEntities(updatedMapLayer);
  }

  @action
  selectIncident(incident: Partial<Incident>, zoomOn: boolean = true) {
    !this.uiStore.isTabletMode ? this.newSelectIncident(incident, zoomOn) : this.oldSelectIncident(incident, zoomOn);
  }

  private oldSelectIncident(incident: Partial<Incident>, zoomOn: boolean = true): void {
    if (this.uiStore.isTabletMode) this.shiftStore.getCurrentShift().subscribe();
    incident.affectedLanes = Utils.sortAffectedLanes(coerceArray(incident.affectedLanes));
    this.updateIncidentSubtypes(incident.type as IncidentType);

    if (zoomOn) {
      this.liveMapStore.zoomOnEntity(incident, 15);
    }
    if (this.isCompleted(incident as Incident)) {
      this.updateCompletedIncidentMapEntity(incident as Incident);
    } else {
      this.updateMapEntityIncident(incident as Incident);
    }

    setTimeout(() => {
      const entitiesInArea = this.liveMapStore.entitiesInArea(incident.location?.coordinates, 1500);
      if (entitiesInArea.dms) {
        runInAction(() => {
          const dmsList = entitiesInArea.dms ? entitiesInArea.dms : [];
          this.selectedIncidentDMSs = [...[], ...dmsList];
        });
      }
      if (incident.type && incident.id) {
        const typeEntities = this.entitiesStore.entities[incident.type.toLowerCase()];
        if (typeEntities) {
          const entityFeature = typeEntities[incident.id];
          if (entityFeature) {
            this.liveMapStore.selectEntityFeature(entityFeature);
          }
        }
      }
    });
    datadogLogs.removeLoggerGlobalContext('wc_incident_details');
    datadogLogs.addLoggerGlobalContext('wc_incident_details', JSON.stringify(incident));
  }

  private newSelectIncident(incident: Partial<Incident>, zoomOn: boolean = true): void {
    if (this.uiStore.isTabletMode) this.shiftStore.getCurrentShift().subscribe();
    if (zoomOn) this.liveMapService.setMapCenter(incident.location.coordinates, { zoomLevel: 15 });
    if (incident.id) {
      this.liveMapService.selectFeature(LayerType.Incident, incident.id);
    }
    datadogLogs.removeLoggerGlobalContext('wc_incident_details');
    datadogLogs.addLoggerGlobalContext('wc_incident_details', JSON.stringify(incident));

    //as unknown as LiveMapEntity[] is to support the old code. should be removed when refactoring this store.
    this.selectedIncidentDMSs = this.dmsQuery.getDmsListByPoint(
      incident.location?.coordinates,
      1500
    ) as unknown as LiveMapEntity[];
  }

  @action
  undoCompleteIncident(incident: Incident) {
    this.uiStore.showLoader('incident');
    this.incidentService.undoCompleteIncident({ id: incident.id }).subscribe(
      res => {
        if (res === true) {
          if (!this.uiStore.isTabletMode) {
            this.entitiesService.emitNewEntitiesDiff({
              modified: {
                INCIDENT: [{ id: incident.id, status: IncidentStatus.Confirmed, show: undefined }],
              },
            });
          } else {
            incident.status = IncidentStatus.Confirmed;
          }
          const workspaces = this.geoService.workspacesByLocation(incident.location.coordinates);
          const updatedMapLayer = {
            [incident.type.toLocaleLowerCase()]: {
              [incident.id]: {
                ...incident,
                ...{
                  mitigatedAccounts: this._getIncidentMitigatedAccounts(incident),
                  featureType: incident.type.toLocaleLowerCase(),
                  featureSubTypeOf: MainEntityType.incident,
                  mergeDetails: undefined,
                },
              },
            },
          };
          this.entitiesStore.modifiedEntities(updatedMapLayer);

          // isInSelectWorkspaces = turn up all of the entity workspaces, only if none if the incident's workspaces is selected in the livemap store
          // https://app.shortcut.com/rekor/story/24562/when-editing-or-creating-an-incident-the-main-workspaced-auto-toggle-on
          const isInSelectWorkspaces = _intersection(workspaces, this.liveMapStore.selectedWorkspacesIds);
          if (isInSelectWorkspaces?.length === 0) this.liveMapStore.setSelectedWorkspaces(workspaces, false);
          this.incidentsCompletedList = this.incidentsCompletedList.filter(
            incidentItem => incidentItem.id !== incident.id
          );
          this.uiStore.hideLoader('incident');
          this.alertService.success(
            this.translateService.instant('notifications.restoreSuccess'),
            undefined,
            undefined,
            true
          );

          this.restoredIncidentesIds.push({
            id: incident.id,
            restoreTime: new Date(),
          });
        } else {
          this.uiStore.hideLoader('incident');
          this.alertService.error(
            this.translateService.instant('notifications.failedToRestoreIncident'),
            undefined,
            undefined,
            true
          );
          throwError('Failed To Restore Incident');
        }
      },
      error => {
        this.uiStore.hideLoader('incident');
        console.log('Error: ', error);
        this.alertService.error(
          this.translateService.instant('notifications.failedToRestoreIncident'),
          undefined,
          undefined,
          true
        );
      }
    );
  }

  @action
  undoRejectIncident(incident) {
    this.uiStore.showLoader('incident');
    this.incidentService.undoRejectIncident({ id: incident.id }).subscribe(
      res => {
        if (res === true) {
          if (!this.uiStore.isTabletMode) {
            this.entitiesService.emitNewEntitiesDiff({
              modified: {
                INCIDENT: [{ id: incident.id, status: IncidentStatus.Confirmed, show: undefined }],
              },
            });
          } else {
            incident.status = IncidentStatus.Confirmed;
          }
          const updatedMapLayer = {
            [incident.type.toLocaleLowerCase()]: {
              [incident.id]: {
                ...incident,
                ...{
                  mitigatedAccounts: this._getIncidentMitigatedAccounts(incident),
                  featureType: incident.type.toLocaleLowerCase(),
                  featureSubTypeOf: MainEntityType.incident,
                  mergeDetails: undefined,
                },
              },
            },
          };
          this.entitiesStore.modifiedEntities(updatedMapLayer);
          this.incidentsCompletedList = this.incidentsCompletedList.filter(
            incidentItem => incidentItem.id !== incident.id
          );
          this.uiStore.hideLoader('incident');
          this.alertService.success(
            this.translateService.instant('notifications.restoreSuccess'),
            undefined,
            undefined,
            true
          );

          this.restoredIncidentesIds.push({
            id: incident.id,
            restoreTime: new Date(),
          });
        } else {
          this.uiStore.hideLoader('incident');
          this.alertService.error(
            this.translateService.instant('notifications.failedToRestoreIncident'),
            undefined,
            undefined,
            true
          );
          throwError('Failed To Restore Incident');
        }
      },
      error => {
        this.uiStore.hideLoader('incident');
        console.log('Error: ', error);
        this.alertService.error(
          this.translateService.instant('notifications.failedToRestoreIncident'),
          undefined,
          undefined,
          true
        );
      }
    );
  }

  @action
  updateIncident(updatedIncident: Incident, originalIncident: Incident, isFromMitigationModal = false) {
    this.uiStore.showLoader('incident');
    return this.incidentService
      .updateIncidentOneTime(
        {
          modifiedIncident: cloneDeep(updatedIncident),
          incident: originalIncident,
        },
        isFromMitigationModal,
        this.uiStore.isTabletMode
      )
      .pipe(
        tap((incident: Incident) => {
          incident.affectedLanes = Utils.sortAffectedLanes(coerceArray(incident.affectedLanes));
          this.uiStore.hideLoader('incident');

          if (incident) {
            if (
              incident.status === IncidentStatus.Confirmed &&
              originalIncident.status === IncidentStatus.Unconfirmed
            ) {
              this.showSuccessNotification('notifications.incidentWasAutoConfirmed');
              this.uiStore.setRoadEventsPanelTabIndex(RoadEventsPanelTabIndex.InProgress);
            } else this.showSuccessNotification('notifications.incidentWasUpdated');

            incident.type = incident.type || IncidentType.Unidentified;

            if (this.isCompleted(incident)) {
              const incidentIndex = this.incidentsCompletedList.findIndex(
                completedIncident => completedIncident.id === incident.id
              );
              if (incidentIndex !== -1) {
                const mergeDetails = this.incidentsCompletedList[incidentIndex].mergeDetails;
                this.incidentsCompletedList[incidentIndex] = {
                  ...incident,
                  mergeDetails,
                } as unknown as IncidentView;
              }
              this.clearIncidentSelection();
            }

            this.activityLogStore.loadIncidentActivityLog(incident.id).subscribe();
            const workspaces = this.geoService.workspacesByLocation(incident.location.coordinates);
            const updatedMapLayer = {
              [incident.type.toLocaleLowerCase()]: {
                [incident.id]: {
                  ...incident,
                  ...{
                    mitigatedAccounts: this._getIncidentMitigatedAccounts(incident),
                    featureType: incident.type.toLocaleLowerCase(),
                    featureSubTypeOf: MainEntityType.incident,
                    workspaces,
                  },
                },
              },
            };
            if (originalIncident.type !== incident.type) {
              this.liveMapStore.updateRemovedEntities({
                [originalIncident.type?.toLocaleLowerCase() || 'other']: [originalIncident.id],
              });
            }

            // removed interaction so highlight shows
            this.onIncidentEdited = incident.id;

            this.liveMapStore.changeLayersVisibility(true, [incident.type.toLocaleLowerCase() as LiveMapEntityType]);
            const isInSelectWorkspaces = _intersection(workspaces, this.liveMapStore.selectedWorkspacesIds);
            if (isInSelectWorkspaces?.length === 0) this.liveMapStore.setSelectedWorkspaces(workspaces, false);
            this.uploadeFiles(incident.id);
            this.deleteIncidentMedia(incident.id);
            this.clearIncidentSelection();

            this.entitiesStore.modifiedEntities(updatedMapLayer).then(() => {
              this.liveMapStore.updateModifiedEntities(updatedMapLayer);
            });

            return incident;
          } else {
            return of({});
          }
        }),
        !this.uiStore.isTabletMode ? this.incidentService.emitIncidentDiffAndUpdateLiveMap() : tap(noop),
        catchError(err => {
          this.uiStore.hideLoader('incident');
          return throwError(err);
        })
      );
  }

  showSuccessNotification(message) {
    this.alertService.success(this.translateService.instant(message), undefined, undefined, true);
  }

  setIncidentHasInsight(hasInsight) {
    this.hasInsight = hasInsight;
  }

  createIncident(incident: Partial<Incident>): Observable<Incident> {
    this.uiStore.showLoader('incident');

    incident = this.updateSelectedIncidentFromLivemap(incident);

    const incidentMitigations: IncidentMitigationInput[] = [];
    incident.mitigations?.forEach(mitigation => {
      incidentMitigations.push({
        interval: mitigation.interval,
        mitigationTypeId: mitigation.mitigationType.id,
        unitId: mitigation.unitId, // What unit Id to use should we add userId also?
        driverId: mitigation.userId,
      });
    });

    const associatedUnits: IncidentUnitInput[] = [] as IncidentUnitInput[];

    if (!this.uiStore.isTabletMode) {
      incident.associatedUnits?.forEach(associatedUnit => {
        associatedUnits.push({
          driverId: associatedUnit.driverDetails?.userId,
          response: associatedUnit.unitResponse,
          unitId: associatedUnit.id,
        });
      });
    } else {
      // check if we need it
      if (this.usersStore.authUser.unit) {
        // https://app.shortcut.com/rekor/story/48223/d1fsp-when-a-tablet-user-create-an-incident-and-add-mitigation-the-unit-still-appears-on-scene
        // Regardless of mitigation end time, if user creates incident with mitigation -> add him as mitigated otherwise add him as on-scene
        const isOnScene = incidentMitigations.length === 0;

        associatedUnits.push({
          driverId: this.usersStore.authUser.id,
          response: isOnScene ? UnitResponse.OnScene : UnitResponse.Mitigated,
          unitId: this.usersStore.authUser.unit.id,
        });
      }
    }

    const address: AddressInput = {
      ...incident.address,
      ...{ point: incident.location },
    };

    const cameras: AssociatedCameraInput[] = [];

    if (incident.cameras) {
      incident.cameras.forEach(_camera => {
        cameras.push(asRoadEventCameraInput(_camera));
      });
    }

    const involvedVehicles: InvolvedVehicleInput[] = [];
    incident.involvedVehicles?.forEach(_vehicale => involvedVehicles.push(_vehicale));

    const additionalInfos: IncidentAdditionalInfoInput[] =
      (incident.additionalInfos as unknown as number[])?.map(info => ({
        additionalInfoId: info,
      })) || [];

    let reportSources: IncidentReportSourceInput[] = [];
    if (incident.reportSources) {
      if (Array.isArray(incident.reportSources)) {
        reportSources = incident.reportSources?.map(sources => ({
          reportSourceId: sources.id,
        }));
      } else {
        reportSources = [{ reportSourceId: incident.reportSources }];
      }
    }

    const newIncident: CreateIncidentMutationInput = {
      startedAt: incident.startedAt,
      address: address,
      affectedLanes: incident.affectedLanes,
      multiDirectionLanesAffected: incident.multiDirectionLanesAffected || false,
      allLanesAffected: incident.allLanesAffected || false,
      type: incident.type as IncidentType,
      subType: incident.subType,
      location: incident.location,
      status: incident.status as IncidentStatus,
      involvedVehicles: involvedVehicles,
      endedAt: incident.endedAt,
      typeDescription: incident.typeDescription,
      cameras: cameras,
      additionalInfos: additionalInfos,
      associatedUnits: associatedUnits,
      mitigations: incidentMitigations,
      notes: incident.notes,
      injuries: incident.injuries,
      estimatedEndTime: incident.estimatedEndTime
        ? {
            value: incident.estimatedEndTime,
          }
        : null,
      reportSources: reportSources,
      atmsId: incident.atmsId || null,
      cadId: incident.cadId || null,
      autoPublish: incident.autoPublish || false,
      responsePlan: incident.responsePlan,
      involvedVehiclesCount: incident.involvedVehiclesCount,
      severity: incident.severity,
      injurySeverities: incident.injurySeverities,
      attributes: incident.attributes,
    };

    const generateOfflineId = OfflineService.generateID();

    return this.incidentService.createIncident(newIncident, generateOfflineId).pipe(
      map(res => {
        const workspaces = this.geoService.workspacesByLocation(incident.location.coordinates);
        const isInSelectWorkspaces = _intersection(workspaces, this.liveMapStore.selectedWorkspacesIds);
        if (isInSelectWorkspaces?.length === 0) this.liveMapStore.setSelectedWorkspaces(workspaces, false);
        return {
          ...res,
          ...{
            mitigatedAccounts: this._getIncidentMitigatedAccounts(res),
            featureType: res.type.toLocaleLowerCase(),
            featureSubTypeOf: MainEntityType.incident,
            workspaces,
          },
        };
      }),
      tap((res: Incident) => {
        this.uiStore.offlineEvent$.next(OfflineEvent.incidentCreated);
        this.uiStore.setInteractedEntityId(res.id, MainEntityType.incident);
        this.uploadeFiles(res.id);
        const mapLayer = {
          [res.type.toLocaleLowerCase()]: {
            [res.id]: res,
          },
        };

        this.liveMapStore.changeLayersVisibility(true, [res.type.toLocaleLowerCase() as LiveMapEntityType]);
        this.clearIncidentSelection();
        this.entitiesStore.modifiedEntities(mapLayer).then(() => {
          this.liveMapStore.updateModifiedEntities(mapLayer);
          this.liveMapStore.removeInteractiveEntity();
          this.stopCreateIncidentFlow();
        });
      }),
      !this.uiStore.isTabletMode ? this.incidentService.emitIncidentDiffAndUpdateLiveMap() : tap(noop),
      catchError(err => {
        datadogLogs.logger.error('CREATE-INCIDENT-FAILED', {
          err: JSON.stringify(err),
          requestInput: JSON.stringify(newIncident),
          editIncidentStepTwoLocalStorage: JSON.stringify(
            this.localStorageService.get(LocalStorageKeys.EditIncidentStepTwo)
          ),
          editIncidentStepOneLocalStorage: JSON.stringify(
            this.localStorageService.get(LocalStorageKeys.EditIncidentStepOne)
          ),
        });
        setTimeout(() => {
          this.alertService.error(
            this.translateService.instant('notifications.failIncidentCreate'),
            undefined,
            {
              timeOut: 5000,
            },
            true
          );
        });
        return throwError(err);
      }),
      finalize(() => {
        this.uiStore.hideLoader('incident');
      })
    );
  }

  camerasByPoint(coords: number[]): Promise<RoadEventCamera[]> {
    return !this.uiStore.isTabletMode ? this.getCamerasByPointNewApi(coords) : this.getCamerasByPointOldApi(coords);
  }

  private getCamerasByPointNewApi(coords: number[]): Promise<RoadEventCamera[]> {
    return new Promise(resolve => {
      resolve(
        this.camerasQuery.getCamerasByPoint(coords, 1000).map<RoadEventCamera>((camera, idx) => ({
          camera: camera,
          default: idx === 0,
          positionIndex: 0,
        }))
      );
    });
  }

  private getCamerasByPointOldApi(coords: number[]): Promise<RoadEventCamera[]> {
    return new Promise(async (resolve, reject) => {
      const camerasSub = this.entitiesStore.getCamerasByPoint(coords, 1000).subscribe(
        _cameras => {
          const cameras = _cameras;
          _cameras.sort(
            (a, b) =>
              this.geoService.getPointsDistance(coords, a.location.coordinates) -
              this.geoService.getPointsDistance(coords, b.location.coordinates)
          );

          camerasSub.unsubscribe();
          const relatedCameras: RoadEventCamera[] = [];
          if (cameras) {
            cameras.forEach((incidentCamera, index) => {
              relatedCameras.push({
                camera: incidentCamera,
                default: index === 0,
                positionIndex: incidentCamera.positionIndex,
              });
            });
          }
          resolve(relatedCameras);
        },
        err => {
          console.log('camerasByPoint Error:', err);
          camerasSub.unsubscribe();
          reject();
        }
      );
    });
  }

  @action
  async updateIncidentLocation(coords: Array<number> | undefined) {
    if (coords) {
      runInAction(() => {
        this.currentPointerLocationOnMap = coords;
      });
    }
  }
  @action
  async newIncidentFromUnit() {
    const mapCenterCoords = this.liveMapStore.wcMap?.getCenterCoords();
    let coords: Array<number> = mapCenterCoords;
    try {
      const position: Position | undefined = await this.usersStore.authUserUnitLocation();
      if (position) {
        coords = [position?.coords.longitude, position?.coords.latitude];
        console.log(`New incident coords: ${JSON.stringify(coords)}`);
      } else {
        console.error(`NO LOCATION WAS FOUND, position=` + position);
      }
    } catch (error) {
      console.log(error);
    }
    return this.newIncident(coords).catch(console.error);
  }

  @action
  newIncident(coords: Array<number>, startFlow = true): Promise<Incident> {
    return !this.uiStore.isTabletMode
      ? this.createNewIncidentNewApi(coords, startFlow)
      : this.createNewIncidentOldApi(coords, startFlow);
  }

  createNewIncidentOldApi(coords: Array<number>, startFlow = true): Promise<Incident> {
    this.liveMapStore.removeInteractiveEntity();
    this.clearIncidentSelection();

    return new Promise((resolve, reject) => {
      if (!this.usersStore.hasPermission('INCIDENT:CREATE')) {
        console.warn("You don't have the right permissions for creating a new incident.");
        reject();
        return;
      }

      this.liveMapStore.allLayersVisibilityDetectionMode(true);
      this.uiStore.showLoader('incident');

      this.entitiesStore.getCamerasByPoint(coords, 1000).subscribe(
        cameras => {
          const relatedCameras: AssociatedCamera[] = [];

          if (cameras) {
            cameras.map((camera, index) => {
              relatedCameras.push({ camera: camera, default: index === 0, positionIndex: camera.positionIndex });
            });
          }

          const newIncident: Partial<Incident> = {
            affectedLanes: [],
            cameras: relatedCameras,
            involvedVehicles: [],
            status: IncidentStatus.Confirmed,
            startedAt: new Date(),
            location: {
              coordinates: coords,
              type: 'Point',
            },
          };

          if (startFlow) {
            this.startCreateIncidentFlow();
          } else {
            newIncident.address = {
              point: {
                coordinates: coords,
                type: 'Point',
              },
            };
          }

          this.uiStore.hideLoader('incident');
          this.selectIncident(newIncident, false);

          if (!this.uiStore.isTabletMode) {
            this.editLocationOnMap(newIncident); // new map doesn't need
          }

          resolve(newIncident as Incident);
        },
        error => {
          this.uiStore.hideLoader('incident');
          console.log('Error: ', error);
          return reject();
        }
      );
    });
  }

  createNewIncidentNewApi(coords: Array<number>, startFlow = true): Promise<Incident> {
    datadogLogs.removeLoggerGlobalContext('wc_incident_details');

    return new Promise((resolve, reject) => {
      if (!this.usersStore.hasPermission('INCIDENT:CREATE')) {
        console.warn("You don't have the right permissions for creating a new incident.");
        reject();
        return;
      }

      this.uiStore.showLoader('incident');
      const newIncident: Partial<Incident> = {
        affectedLanes: [],
        cameras: this.camerasQuery.getCamerasByPoint(coords, 1000).map<AssociatedCamera>((camera, idx) => ({
          camera: camera,
          default: idx === 0,
          positionIndex: 0,
        })),
        involvedVehicles: [],
        status: IncidentStatus.Confirmed,
        startedAt: new Date(),
        location: {
          coordinates: coords,
          type: 'Point',
        },
      };

      if (startFlow) {
        this.startCreateIncidentFlow();
      } else {
        newIncident.address = {
          point: {
            coordinates: coords,
            type: 'Point',
          },
        };
      }

      this.uiStore.hideLoader('incident');
      this.selectIncident(newIncident, false);
      resolve(newIncident as Incident);
    });
  }

  @action
  setSelectedIncidentAddress(address: Address) {
    throw new Error('selectedIncident is deprecated!');
  }

  @action
  startCreateIncidentFlow() {
    if (this.uiStore.isTabletMode) {
      this.uiStore.showNewIncidentLocationPanel = true;
    } else {
      this.uiStore.showCreateIncidentPanel = true;
    }
  }

  @action
  editCurrentIncident(incidnet: Partial<Incident>) {
    this.uiStore.showCreateIncidentPanel = true;
    this.uiStore.showNewIncidentLocationPanel = false;
  }

  @action
  showAddressModal() {
    this.uiStore.showAddressModal = true;
  }

  @action
  hideAddressModal() {
    this.uiStore.showAddressModal = false;
  }

  @action
  stopCreateIncidentFlow() {
    this.uiStore.showCreateIncidentPanel = false;
    this.uiStore.showNewIncidentLocationPanel = false;
  }

  addFilesToPendingDelete(incidentMediaId: number) {
    this._currentPendingDelete.push(incidentMediaId);
  }

  deleteIncidentMedia(incidentId) {
    this.incidentService
      .deleteIncidentMedia({
        incidentId: incidentId,
        removed: this._currentPendingDelete,
      })
      .subscribe();
  }

  hasPendingMediaDeletions() {
    return this._currentPendingDelete.length > 0;
  }

  resetPendingMediaDeletions() {
    this._currentPendingDelete = [];
  }

  editLocationOnMap(incident: Incident | Partial<Incident>) {
    this.liveMapStore.addInteractiveEntity(incident);
  }

  @action
  async updateIncidentMitigations(incident: Incident, mitigations: IncidentMitigation[]) {
    return this.updateUnitIncidentMitigations(incident, mitigations); //.toPromise();
  }

  //////////// Handle Tablet Mitigation Action Button /////////////
  @action
  updateUnitIncidentMitigations(incident: Incident, mitigations: IncidentMitigation[]) {
    const unitMitigations = (incident.mitigations || []).filter(
      mitigation =>
        mitigation.unitId === this.usersStore.authUser.unit?.id && mitigation.userId === this.usersStore.authUser.id
    );

    let associatedUnit = incident.associatedUnits.find(
      _unit =>
        _unit.id === this.usersStore.authUser.unit?.id && _unit.driverDetails?.userId === this.usersStore.authUser.id
    );
    if (!associatedUnit) {
      associatedUnit = incident.associatedUnits.find(
        _unit =>
          _unit.id === this.usersStore.authUser.unit?.id && _unit.driverDetails?.userId === this.usersStore.authUser.id
      );
      if (associatedUnit) {
        associatedUnit.id = associatedUnit.id;
        if (associatedUnit.driverDetails) associatedUnit.driverDetails.userId = associatedUnit?.driverDetails?.userId;
      }
    }
    //add associated unit if it's not associate yet
    let associatedUnitInput = {
      driverDetails: {
        userId: this.usersStore.authUser.id,
        name: this.usersStore.authUser.name,
        unitRelationType: this.usersStore.authUser.unitRelationType,
      },
      id: this.usersStore.authUser.unit?.id,
      unitResponse: associatedUnit?.unitResponse || UnitResponse.OnScene,
    } as IncidentUnit;

    const updateUnitResponseServiceObservable =
      associatedUnit?.unitResponse !== UnitResponse.Mitigated
        ? this.incidentService.updateIncidentUnitResponse({
            driverId: associatedUnitInput.driverDetails?.userId,
            incidentId: incident.id,
            response: UnitResponse.Mitigated,
            unitId: associatedUnitInput.id,
          })
        : of({});
    const mitigationsDiff = Utils.mitigationsDiff(unitMitigations, mitigations, associatedUnitInput);
    const updateMitigationsServiceObservable = this.incidentService._updateMitigations(incident, mitigationsDiff);

    return forkJoin([updateUnitResponseServiceObservable, updateMitigationsServiceObservable]).pipe(
      tap(res => {
        if (res[0]?.id) {
          if (associatedUnit) {
            associatedUnit.unitResponse = UnitResponse.Mitigated;
          } else {
            incident.associatedUnits.push(res[0]);
          }
        }
        incident.mitigations = res[1];
      }),
      map(res => incident)
    );
  }

  //////////// Handle Tablet En Route and On Scene Action Buttons /////////////
  updateIncidentUnitResponse(
    input: UpdateIncidentUnitInput,
    incident: Incident,
    originalIncident: Incident,
    oldStatus?: UnitResponse
  ) {
    this.uiStore.showLoader('incident');
    const mitigations: IncidentMitigation[] = (incident.mitigations || []).filter(
      _mitigation => _mitigation.unitId === input.unitId && _mitigation.userId === input.driverId
    );
    return this.incidentService.updateIncidentUnitResponse(input).pipe(
      mergeMap(unit => {
        const index = originalIncident.associatedUnits.findIndex(incidentUnit => incidentUnit.id === unit.id);
        if (oldStatus) {
          incident.associatedUnits[index] = unit;
        } else {
          incident.associatedUnits.push(unit);
        }

        // reset end time for mitigations if status was changed  to on scene
        const currentAssociatedUnit = incident.associatedUnits.find(incidentUnit => incidentUnit.id === unit.id);
        if (input.response === UnitResponse.OnScene && oldStatus === UnitResponse.Mitigated) {
          const modifiedMitigationInput: UpdateIncidentMitigationInput[] = [];
          if (incident.mitigations[0]?.interval) {
            const interval = incident.mitigations[0]?.interval;
            if (interval) interval.to = null;
            mitigations.forEach(incidentMitigation => {
              if (input.driverId) {
                modifiedMitigationInput.push({
                  id: incidentMitigation.id,
                  driverId: input.driverId,
                  unitId: input.unitId,
                  mitigationTypeId: incidentMitigation.mitigationType.id,
                  interval,
                });
              }
            });
          }

          const updateMitigationsInput: UpdateIncidentMitigationsInput = {
            incidentId: incident.id,
            created: [],
            modified: modifiedMitigationInput,
            removed: [],
          };
          return this.incidentService.updateIncidentMitigations(updateMitigationsInput).pipe(
            map(res => {
              incident.mitigations = res;
              if (incident.id) {
                this.activityLogStore.loadIncidentActivityLog(incident.id).subscribe();
              }
              return incident;
            })
          ); //.subscribe();
          // add Default Mitigation when status changed to onscene and prevoius is not 'mitigated'
        }
        // else if (input.response === UnitResponse.OnScene) {
        //   const createdMitigationInput: CreateIncidentMitigationInput[] = [];
        //   createdMitigationInput.push({
        //     driverId: this.usersStore.authUser.id,
        //     unitId: this.usersStore.authUser.unit?.id,
        //     mitigationTypeId: environment.defaultMitigationTypeId,
        //     interval: {
        //       from: new Date(),
        //     },
        //   });
        //   const updateMitigationsInput: UpdateIncidentMitigationsInput = {
        //     incidentId: incident.id,
        //     created: createdMitigationInput,
        //     modified: [],
        //     removed: [],
        //   };
        //   return this.incidentService.updateIncidentMitigations(updateMitigationsInput).pipe(
        //     map(res => {
        //       incident.mitigations = res;
        //       if (incident.id) {
        //         this.activityLogStore.loadIncidentActivityLog(incident.id).subscribe();
        //       }
        //       return incident;
        //     })
        //   );
        // }

        // when changing status to en route => remove all mitigation data
        else if (unit.unitResponse === UnitResponse.EnRoute && mitigations.length) {
          const deletedMitigationInput: DeleteIncidentMitigationInput[] = [];
          mitigations.forEach(incidentMitigation => {
            if (incidentMitigation.mitigationType.id !== environment.defaultMitigationTypeId && input.driverId) {
              deletedMitigationInput.push({
                driverId: input.driverId,
                id: incidentMitigation.id,
              });
            }
          });

          const updateMitigationsInput: UpdateIncidentMitigationsInput = {
            incidentId: incident.id,
            created: [],
            modified: [],
            removed: deletedMitigationInput,
          };
          return this.incidentService.updateIncidentMitigations(updateMitigationsInput).pipe(
            map(res => {
              incident.mitigations = res;
              if (incident.id) {
                this.activityLogStore.loadIncidentActivityLog(incident.id).subscribe();
              }
              return incident;
            })
          );
        } else {
          this.updateMapEntityIncident(incident);
          return of(incident);
        }
      }),
      finalize(() => this.uiStore.hideLoader('incident'))
    );
  }

  disassociateUnitFromIncident(incident: Incident, unitId, driverId) {
    const index = incident.associatedUnits.findIndex(
      incidentUnit => incidentUnit.id === unitId && incidentUnit.driverDetails?.userId === driverId
    );
    if (index !== -1) {
      const input: DeleteIncidentUnitInput = {
        incidentId: incident.id,
        unitId,
        driverId,
      };
      return this.incidentService.disassociateUnitFromIncident(input).pipe(
        tap(bool => {
          if (bool) {
            incident.associatedUnits.splice(index, 1);
            this.updateMapEntityIncident(incident);
          }
        })
      );
    } else {
      return of();
    }
  }

  _getIncidentMitigatedAccounts(incident) {
    const mitigatedAccountIds: any[] = [];
    incident.mitigations?.map(val => {
      mitigatedAccountIds.push({ value: val.mitigation?.accountId });
    });
    return mitigatedAccountIds;
  }

  updateMapEntityIncident(incident: Incident, isSelected: boolean = true) {
    if (!incident.id) return;
    if (
      incident.status === IncidentStatus.Confirmed &&
      !this.incidentsInProgressList.find(inc => inc.id === incident.id)
    )
      return;
    if (
      incident.status === IncidentStatus.Unconfirmed &&
      !this.incidentsNeedActionList.find(inc => inc.id === incident.id)
    )
      return;
    const type = incident.type?.toLocaleLowerCase() || 'other';
    const previousIncident = this.entitiesStore.entities[type.toLowerCase()][incident.id];
    const updatedMapLayer = {
      [type]: {
        [incident.id]: {
          ...incident,
          ...{
            mitigatedAccounts: this._getIncidentMitigatedAccounts(incident),
            featureType: type,
            featureSubTypeOf: MainEntityType.incident,
            selected: isSelected,
            appended: previousIncident?.appended || false,
            workspaces: previousIncident?.workspaces || [],
            indications: previousIncident?.indications || [],
          },
        },
      },
    };

    this.entitiesStore.modifiedEntities(updatedMapLayer);
  }

  publishIncident(input: PublishInput) {
    return this.incidentService
      .publishIncident(input)
      .pipe(this.sharePublicService.publishEventOperatorFunction(input.destinations))
      .subscribe();
  }

  getFilteredDiffIncidentsByRouteId(filterByRoutes: boolean) {
    if (filterByRoutes) {
      this.entitiesStore.getAllEntities({
        filterRoutesByShiftId: this.shiftStore.currentShift.shiftId,
      });
    } else {
      this.entitiesStore.getAllEntities({ filterRoutesByShiftId: undefined });
    }
  }

  private updateCompletedIncidentMapEntity(incident: Incident) {
    const workspaces = this.geoService.workspacesByLocation(incident.location.coordinates);
    const updatedMapLayer = {
      [incident.type.toLocaleLowerCase()]: {
        [incident.id]: {
          ...incident,
          ...{
            featureType: incident.type.toLocaleLowerCase(),
            featureSubTypeOf: MainEntityType.incident,
            selected: true,
            workspaces,
          },
        },
      },
    };
    this.entitiesStore.modifiedEntities(updatedMapLayer);
    this.liveMapStore.updateModifiedEntities(updatedMapLayer);
  }

  private isCompleted(incident: Incident) {
    if (incident.status === IncidentStatus.Completed || incident.status === IncidentStatus.Rejected) {
      return true;
    }
    return false;
  }

  // In Diff the param called 'appended'
  public isMainIncident(incidentId, incidentType) {
    if (!incidentType) {
      return false;
    }
    const incidentLayer = this.entitiesStore.entities[incidentType.toLowerCase()];
    if (incidentLayer?.hasOwnProperty(incidentId)) {
      return incidentLayer[incidentId].appended;
    }
    return false;
  }

  initEditExpiryTime(id: number) {
    const userLastEdit: LastActiveEditIncident = this.localStorageService.get(LocalStorageKeys.UserLastEdit);

    let waitForExpireWanningTime = Utils.WAIT_TIME_TO_SHOW_EXPIRE_WARNING_IN_MIN;
    let ExpireAfterWarningTime = Utils.EXPIRE_TIME_AFTER_WARNING_SHOW_IN_MIN;

    if (userLastEdit && userLastEdit.incidentId === id) {
      const timeDiffInMin = (new Date().getTime() - new Date(userLastEdit.timestamp).getTime()) / 60000;
      if (waitForExpireWanningTime + ExpireAfterWarningTime <= timeDiffInMin) {
        waitForExpireWanningTime = 0;
        ExpireAfterWarningTime = 0;
      } else if (timeDiffInMin <= waitForExpireWanningTime) {
        waitForExpireWanningTime = waitForExpireWanningTime - timeDiffInMin;
      } else {
        waitForExpireWanningTime = 0;
        ExpireAfterWarningTime =
          ExpireAfterWarningTime - (timeDiffInMin - Utils.WAIT_TIME_TO_SHOW_EXPIRE_WARNING_IN_MIN);
      }
    } else {
      const editTime: LastActiveEditIncident = {
        incidentId: id,
        timestamp: new Date(),
      };
      this.localStorageService.set(LocalStorageKeys.UserLastEdit, editTime);
    }

    const editAboutToExpire = timer(waitForExpireWanningTime * 60 * 1000).pipe(
      take(1),
      tap(() => {
        this.confirmService.showConfirmDialog(ConfirmationModalType.EditAboutToExpire, ExpireAfterWarningTime);
      })
    );
    const editExpired = timer(ExpireAfterWarningTime * 60 * 1000).pipe(
      take(1),
      tap(() => {
        if (this.confirmService.dialogRef) {
          this.confirmService.dialogRef.close();
        }
        datadogLogs.logger.info(`USER EDIT EXPIRED - incident ${id}`);
        this.confirmService.showConfirmDialog(ConfirmationModalType.EditTimeExpired, undefined, undefined, false);
        this.closeIncident();
      })
    );
    const concatEditTime = of(editAboutToExpire, editExpired);

    this.concatPreventEditTime = concatEditTime.pipe(concatAll()).subscribe();
  }

  isIncidentFieldMandatory(filedName: keyof IncidentConfiguration) {
    return this.accountStore.incidentConfigs && this.accountStore.incidentConfigs[filedName] ? true : false;
  }

  relatedIncidentToSelectOptions(relatedIncident: RelatedIncident[], filteredIncidentId?: number) {
    const options: SelectOption[] = [];
    relatedIncident.forEach(incident => {
      if (incident.id !== filteredIncidentId) {
        const currentEditUser =
          this.editCollaboration[incident.id] && this.editCollaboration[incident.id][0]
            ? this.editCollaboration[incident.id][0]
            : undefined;
        options.push({
          value: incident.id,
          displayName: generateDisplayNameForRelatedEventOption(incident, { translateService: this.translateService }),
          icon: incident.type,
          disabled: !!currentEditUser,
          data: {
            address: incident.address,
            id: incident.id,
            status: incident.status,
            type: this.translateService.instant('incidentTypes.' + incident.type),
            driverName: currentEditUser,
          },
        });
      }
    });
    return options;
  }

  findIncidentByPointRadius(input: FindIncidentsInPointRadiusInput): Observable<RelatedIncident[]> {
    return this.incidentService.findIncidentByPointRadius(input);
  }

  getFormFieldData(): { [key: string]: FormFieldData } {
    return {
      type: {
        label: 'incidentType',
        options: this.incidentTypes,
      },
      subType: {
        label: 'incident.subtype',
        options: [],
        floatLabel: 'always',
      },
      typeDescription: {
        placeholder: 'statusOtherInputPlaceholder',
      },
      affectedLanes: {
        label: 'lanes',
        required: this.isIncidentFieldMandatory('affectedLanesMandatory'),
      },
      multiDirectionLanesAffected: {
        label: 'affectedDirectionsLabel',
        options: AffectedDirectionsToOptions({
          translateService: this.translateService,
        }),
      },
      allLanesAffected: {
        heapClass: 'all-lanes-affected',
        label: 'allLanesAreAffected',
      },
      isFullBlock: {
        label: 'roadClosureForm.allLanesAreClosed',
      },
      startedAt: {},
      corridor: {
        label: 'corridor',
      },
      crossroad: {
        label: 'crossroad',
      },
      orientation: {
        label: 'orientation',
        options: this.orientationOptions,
      },
      direction: {
        label: 'direction',
        options: this.laneDirectionsOptions,
      },
      milemarker: {
        label: 'milemarker',
        options: this.mileMarkersdOptions,
      },
      involvedVehicles: {
        label: 'involvedVehicles',
      },
      autoPublish: {
        heapClass: 'auto-publish',
        label: 'incident.activateAutoPublish',
      },
      mitigations: {
        label: 'Mitigation',
      },
      associatedUnits: {
        label: 'associatedUnits',
        required: this.isIncidentFieldMandatory('associatedUnitsMandatory') ? true : false,
      },
      notes: {
        label: 'notes',
      },
      injuries: {
        heapClass: 'injuries-number',
        label: 'incident.injuries',
        placeholder: 'incident.injuriesPlaceholder',
        options: Array.from({ length: 11 }, (_, index) => ({ displayName: index.toString(), value: index })),
      },
      source: {
        label: 'incident.source',
      },
      reportedBy: {
        label: 'incident.reportedBy',
        placeholder: 'incident.selectSource',
        options: this.accountStore.reportSourceOptions,
      },
      atmsId: {
        label: 'incident.atmsId',
        placeholder: 'incident.enterIdNumber',
        floatLabel: 'always',
        heapClass: 'atms-id',
      },
      cadId: {
        label: 'incident.cadId',
        placeholder: 'incident.enterIdNumber',
        floatLabel: 'always',
        heapClass: 'cad-id',
      },
      estimatedEndTime: {
        label: 'incident.estimatedEndTime',
      },
      additionalInfos: {
        heapClass: 'additionalInfo',
        required: this.isIncidentFieldMandatory('additionalInfoMandatory') ? true : false,
        options: selectedOptionsAlphaNumericSort(this.accountAdditionalInfosOptionList),
      },
      media: {
        label: 'mediaGallery.sectionTitle',
      },
      involvedVehiclesCount: {
        label: 'incident.involvedVehiclesCount',
        placeholder: 'incident.involvedVehiclesPlaceholder',
        options: [
          ...Array.from({ length: 6 }, (_, index) => ({ displayName: index.toString(), value: index })),
          ...[{ displayName: '6+', value: 6 }],
        ],
      },
      severity: {
        label: 'incident.severity',
        placeholder: 'selectSeverity',
        options: this.severityOptions,
      },
      injurySeverities: {
        heapClass: 'injuries-severity',
        label: 'incident.injurySeverity',
        placeholder: 'selectSeverity',
        options: this.injuriesSeverityOptions,
      },
      attributes: {
        options: this.attributesOptions,
      },
    };
  }
}
