/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { TransitBusStopStoreEntity, TransitBusStopUIEntity } from '@wc/wc-models/src';
import { map } from 'rxjs/operators';
import { EntitiesServiceV2 } from '../../services/entities.service';

export interface TransitBusStopsState extends EntityState<TransitBusStopStoreEntity, number> {}
export interface TransitBusStopsUIState extends EntityState<TransitBusStopUIEntity> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'transit_bus_stops' })
export class TransitBusStopsStore extends EntityStore<TransitBusStopsState> {
  ui!: EntityUIStore<TransitBusStopsUIState>;
  constructor(entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$.pipe(map(({ TRANSIT_BUS_STOP }) => TRANSIT_BUS_STOP || [])).subscribe(UIEntities => {
      this.ui.upsertMany(UIEntities);
    });

    entitiesService.getDiff$
      .pipe(
        map(({ modified, removed }) => ({
          removedTransitBusStops: removed?.TRANSIT_BUS_STOP,
          modifiedTransitBusStops: modified.TRANSIT_BUS_STOP,
        }))
      )
      .subscribe(({ removedTransitBusStops, modifiedTransitBusStops }) => {
        if (modifiedTransitBusStops) this.upsertMany(modifiedTransitBusStops as TransitBusStopStoreEntity[]);
        if (removedTransitBusStops) this.remove(removedTransitBusStops);
      });

    entitiesService.activeEntityIds$.pipe(map(({ TRANSIT_BUS_STOP }) => TRANSIT_BUS_STOP || [])).subscribe(id => {
      this.ui.setActive(id);
    });
  }
}
