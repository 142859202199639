import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'wc-status-circle',
  templateUrl: './status-circle.component.html',
  styleUrls: ['./status-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusCircleComponent {
  @Input() fillColor!: string;
}
