<ng-container *ngIf="dataView as data">
  <div
    [ngClass]="'heap-' + (isTabletMode ? 'tablet' : 'desktop') + '-td-list-item'"
    class="incident-list-item"
    [class.tablet-mode]="isTabletMode"
    [wcRoadEventHighlight]="{ roadEvent: $any(disruption), type: RoadEventTypeEnum.TrafficDisruption }">
    <div class="incident-data-container">
      <div class="incident-icon-container">
        <i class="incident-icon incident-type-icon" [ngClass]="'list-icon-' + (disruption.layerType | lowercase)"></i>
      </div>

      <div class="traffic-disruption-details">
        <div class="disruption-item-header">
          <span class="disruption-status-active">
            <ng-container *ngIf="disruption.status === disruptionStatus.Active">
              <i class="fas fa-calendar-day"></i>
              <span> {{ 'active' | translate }}</span>
            </ng-container>

            <ng-container *ngIf="disruption.status === disruptionStatus.Planned">
              <i class="fas fa-calendar"></i>
              <span class="planned">{{ 'planned' | translate }}</span>
            </ng-container>

            <ng-container *ngIf="disruption.status === disruptionStatus.Completed">
              <i class="fas fa-calendar-check"></i>
              <span> {{ 'completed' | translate }}</span>
            </ng-container>

            <ng-container *ngIf="disruption.status === disruptionStatus.Inactive">
              <wc-icon iconName="planned-calender"></wc-icon>
              <span> {{ 'statusType.INACTIVE' | translate }}</span>
            </ng-container>
          </span>

          <span
            class="disruption-time-end"
            *ngIf="disruption.status !== disruptionStatus.Planned && disruption.endTime">
            {{ ('ends' | translate) + ' ' + moment(disruption.endTime).fromNow() }}
          </span>

          <span
            class="disruption-time-end"
            *ngIf="disruption.status === disruptionStatus.Planned && disruption.startTime">
            {{ disruption.startTime | dateFormat: regionalSetting }}
            ({{ moment(disruption.startTime).fromNow() }})
          </span>
        </div>

        <div class="disruption-first-line" [class.long-line]="disruption.status === disruptionStatus.Completed">
          <span class="disruption-id" *ngIf="disruption.id">
            {{ disruption.id }}
          </span>

          <span
            class="inline-block"
            [class.special-event-type]="
              data.isSpecialEvent && data.translatedTrafficDisruptionType && disruption.title
            ">
            {{ data.translatedTrafficDisruptionType }}
          </span>

          <span *ngIf="data.isSpecialEvent">{{ disruption.title }} </span>

          <span class="disruption-data-chunk" *ngIf="disruption.otherTypeDescription">
            {{ disruption.otherTypeDescription }}
          </span>
        </div>

        <div class="disruption-title" [class.long-line]="disruption.status === disruptionStatus.Completed">
          <ng-container *ngIf="data.isSpecialEvent; else notSpecialEvent">
            <span>
              {{ data.specialEventViewData?.venueData?.venueName || disruption.title }}
            </span>

            <span class="special-event-venue-address" *ngIf="data.specialEventViewData?.venueData?.venueName">
              {{ data.specialEventViewData?.venueData?.venueAddress }}
            </span>
          </ng-container>

          <ng-template #notSpecialEvent
            ><span>
              {{ disruption.title }}
            </span></ng-template
          >
        </div>

        <div class="disruption-details-row">
          <div class="special-event-attendance" *ngIf="data.isSpecialEvent">
            <i class="special-event-attendance-icon fas fa-user-friends"></i>
            {{ data.specialEventViewData!.specialEventAttendance }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="incident-buttons-container"
      *wcIfPermission="'ROAD_CLOSURE:CREATE'"
      [class.buttons-container-border]="disruption.status !== disruptionStatus.Completed">
      <button
        class="incident-button complete-button"
        type="button"
        [ngClass]="'heap-' + (isTabletMode ? 'tablet' : 'desktop') + '-list-traffic-disruption-complete-button'"
        *ngIf="disruption.status !== disruptionStatus.Completed"
        (click)="_completeTrafficDisruption($event, disruption)">
        {{ 'complete' | translate }}
      </button>
    </div>
  </div>
</ng-container>
