<form [formGroup]="shiftForm" class="shift-form shift-removed-form">
  <div>
    <div class="removed-shift-title">
      {{ 'shiftsAndRoutes.shiftNotAvailableChooseNew' | translate }}
    </div>

    <wc-select-control
      *ngIf="shiftFieldsData.shiftTypeId.options && shiftFieldsData.shiftTypeId.options.length"
      formControlName="shiftTypeId"
      [isTabletMode]="true"
      [formFieldData]="shiftFieldsData.shiftTypeId">
    </wc-select-control>

    <div class="buttons-container">
      <button class="primary-button" (click)="submitEditShiftForm()">
        {{ 'save' | translate }}
      </button>
    </div>
  </div>
</form>
