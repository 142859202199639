<div class="completion-form-component">
  <div class="complete-form-header">
    <span class="title-color" [class.language-rtl]="isLangHe">{{ 'leaveEditing' | translate }}</span>
    <button (click)="stay()" mat-button class="close-modal-button" [disableRipple]="true">
      <i class="far fa-times"></i>
    </button>
  </div>
  <div class="dialog-content">
    {{ 'changesWontBeSaved' | translate }}
  </div>
  <div class="completion-form-actions-btn">
    <mat-divider class="completion-form-divider"></mat-divider>
    <div class="completion-form-actions-btn-content">
      <button (click)="leave()" mat-stroked-button variant="outlined" class="completion-form-cancel-button">
        {{ 'leave' | translate }}
      </button>
      <button (click)="stay()" class="mat-raised-button completion-form-complete-button">
        {{ 'stay' | translate }}
      </button>
    </div>
  </div>
</div>
