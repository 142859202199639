import { UIIncident } from '@wc/core/models/extended.models';
import { FormMode } from '@wc/permissions/feature-roles-form/src/lib/roles-form/roles-form.component';
import { MapUnitTooltipResponses } from '@wc/wc-models/src/index';
import * as Enums from '../../core/models/enums';
import { RoadEventType, WeatherEventStatus } from '../../core/models/enums';
import {
  CreateIncidentMitigationInput,
  DeleteIncidentMitigationInput,
  IncidentIndicationType,
  IncidentMedia,
  IncidentMitigation,
  IncidentType,
  IncidentUnit,
  ResponsePlanEntityType,
  ResponsePlanTemplate,
  TrafficDisruptionMedia,
  UnitActivityStatus,
  UnitDashCamera,
  UnitResponse,
  UpdateIncidentMitigationInput,
  VehicleType,
  WeatherAlertView,
} from '../../core/models/gql.models';
import { Address, TrafficDisruption } from '../../core/models/index';

// export interface Camera {
//   id: string;
//   src: string;
//   type?: string;
//   thumbnail: string;
// }

export interface Alert {
  type: Enums.AlertType;
  message: string;
  title?: string;
}

export interface DialogData {
  templateTitles: string[];
  isClone: boolean;
  template: ResponsePlanTemplate | undefined;
  title: string;
  hasActions: boolean;
  actions: string[];
  formViewMode?: Enums.FormViewMode;
  formMode?: FormMode;
  details: any;
  entityType: ResponsePlanEntityType;
}

export interface ResponsePlanDialogData {
  templateTitles: string[];
  isClone: boolean;
  template: ResponsePlanTemplate | undefined;
  entityType: ResponsePlanEntityType;
}
export interface ConfirmDialogData {
  title: string;
  text?: string;
  additionalText?: string;
  cancelAction: string;
  approveAction: string;
  highlightedText?: string;
  dynamicValues?: { [key: string]: number | string };
}

export interface ShiftDialogData {
  unit: string;
  startMileage: number;
  mileage?: number;
  shiftType: Enums.ShiftType;
  routes: string[];
}

export interface ShiftsAndRoutesDialogData {
  details: ShiftDialogData;
  shiftFormState: Enums.ShiftFormState;
}

export interface RouteDetails {
  id?: number;
  name?: string;
  area: Point | Polygon | LineString;
  accountId: number;
}

export interface RouteDrawingDialogData {
  details: RouteDetails;
  formViewMode: Enums.FormViewMode;
}

export interface DMS {
  id: string;
  externalId: string;
  location: any;
  address: Address;
  title: string;
  editable: boolean;
  status: string;
  messages: Array<Array<string>>;
  lastUpdated: LastUpdated;
}

export interface LastUpdated {
  offset: Offset;
  nano: number;
  year: number;
  monthValue: number;
  dayOfMonth: number;
  hour: number;
  minute: number;
  second: number;
  month: string;
  dayOfYear: number;
  dayOfWeek: string;
}

export interface Offset {
  totalSeconds: number;
  id: string;
  rules: {
    transitions: any[];
    transitionRules: any[];
    fixedOffset: boolean;
  };
}

// export interface Location<T> {
//   coordinates: T
// }

export type Point = {
  coordinates?: number[];
  type: 'Point';
};
// "point":{"coordinates":[-115.21114269204777,36.25821758812759],"type":"Point"}

// data class Point(
//   override val coordinates: Position
// ) : Geometry<Position>("Point", coordinates) {
//   companion object {
//       operator fun invoke(long: Double, lat: Double) = Point(Position(long = long, lat = lat, alt = 0.0))
//   }
// }
export type LineString = {
  coordinates?: number[][];
  type: 'LineString';
  //location":{"coordinates":[[-115.45532226562499,36.38591277287651,0.0], [-115.45532226562499,36.38591277287651,0.0]],"type":"LineString"
};

export type MultiLineString = {
  coordinates?: number[][][];
  type: 'MultiLineString';
};
// data class LineString(
//   override val coordinates: List<Position>
// ) : Geometry<List<Position>>("LineString", coordinates)
export type Polygon = {
  coordinates?: number[][][];
  type: 'Polygon';
};

// data class Polygon(
//   override val coordinates: PolygonCoordinates
// ) : Geometry<PolygonCoordinates>("Polygon", coordinates) {
//   init {
//       PolygonValidator.validate(coordinates)
//   }
// }

export type MultiPolygon = {
  coordinates: [][][][];
  type: 'MultiPolygon';
};
// data class MultiPolygon(
//   override val coordinates: List<PolygonCoordinates>
// ) : Geometry<List<PolygonCoordinates>>("MultiPolygon", coordinates) {
//   init {
//       coordinates.forEach { PolygonValidator.validate(it) }
//   }
// }

export type tooltipAdditionalInfo = {
  incidentId?: string;
  unitBreakDuration?: number;
  unitBreakReason?: string;
  activityStatuses?: UnitActivityStatus[];
  useExtraPadding?: boolean;
};

export const IncidentTypeOrder: IncidentType[] = [
  IncidentType.Crash,
  IncidentType.VehicleOnFire,
  IncidentType.Hazard,
  IncidentType.StalledVehicle,
  IncidentType.Debris,
  IncidentType.Ems,
  IncidentType.PoliceActivity,
  IncidentType.LeftOnArrival,
  IncidentType.AbandonedVehicle,
  IncidentType.Damage,
  IncidentType.TrafficStop,
  IncidentType.WrongWay,
  IncidentType.Other,
];

export const IncidentTypeOrderWithUnidentified: IncidentType[] = [
  IncidentType.Crash,
  IncidentType.VehicleOnFire,
  IncidentType.Hazard,
  IncidentType.StalledVehicle,
  IncidentType.Debris,
  IncidentType.Ems,
  IncidentType.PoliceActivity,
  IncidentType.LeftOnArrival,
  IncidentType.AbandonedVehicle,
  IncidentType.Damage,
  IncidentType.Other,
  IncidentType.TrafficStop,
  IncidentType.WrongWay,
  IncidentType.Unidentified,
  IncidentType.TrafficAnomaly,
];

export interface Congestion {
  id: string;
  status: string; // Status (Active, Inactive),
  isIrregular: boolean;
  distance: number;
  location: {
    coordinates?: number[][];
    type: string;
  };
  startAddress: Address;
  endAddress: Address;
  startPointLocation: {
    coordinates: number[];
    type: string;
  };
  endPointLocation: {
    coordinates: number[];
    type: string;
  };
  startTime: string; // OffsetDateTime(formatToISO)
  updatedAt: string; // OffsetDateTime(formatToISO)
  featureSubTypeOf: string;
  featureType: string;
}
export interface CrashRisk {
  id: string;
  status: string; // Status (Active, Inactive , Predicated),
  location: {
    coordinates?: number[][];
    type: string;
  };
  startAddress: Address;
  endAddress: Address;
  startPointLocation: {
    coordinates: number[];
    type: string;
  };
  endPointLocation: {
    coordinates: number[];
    type: string;
  };
  distance: number;
  startTime: string; // OffsetDateTime(formatToISO)
  endTime: string; // OffsetDateTime(formatToISO)
}

export interface AssociatedUnitForm {
  associatedUnit: Partial<IncidentUnit>;
  mitigations?: Array<IncidentMitigation>;
}

export interface IncidentFilters {
  location?: boolean;
  assignedToMe?: boolean;
}
export interface RoadEventsFilters {
  location?: boolean;
  assignedToMe?: boolean;
  mitigatedByMyAccount?: boolean;
}

export interface DiffParams {
  filterRoutesByShiftId?: number;
}

export interface EntitiesDiff {
  updatedAt: number;
  modified: {};
  removed: {};
}

export enum UploadEntityType {
  'Incident',
  'TrafficDisruption',
  'Publication',
}

export const weatherSortOptions = ['startTime', 'severity', 'certainty', 'type'];

export enum weatherSortOptionsEnum {
  'startTime' = 'startTime',
  'severity' = 'severity',
  'certainty' = 'certainty',
  'type' = 'type',
}

export const weatherStatusOptions = ['allAlerts', 'active', 'forecast'];

export enum weatherStatusOptionsEnum {
  'allAlerts' = 'allAlerts',
  'active' = 'active',
  'forecast' = 'forecast',
}

export const WeatherSeverityOrder = ['EXTREME', 'SEVERE', 'MODERATE', 'MINOR', 'UNKNOWN_WEATHER_ALERT_SEVERITY'];

export const WeatherCertaintyOrder = ['LIKELY', 'POSSIBLE', 'UNLIKELY', 'UNKNOWN_WEATHER_ALERT_CERTAINTY'];

export type RoadEvent = UIIncident | TrafficDisruption;

export interface RoadEventHighlightEntity {
  roadEvent: RoadEvent;
  type: RoadEventType;
}

export type InteractedRoadEventIds = {
  incident: number[];
  trafficDisruption: number[];
};

export type IncidentIndicationExpiry = {
  id: number;
  expiryTime: number;
  indicationType: IncidentIndicationType;
};

export const crashRiskStatusOptions = ['allAlerts', 'active', 'predicted'];

export enum crashRiskStatusOptionsEnum {
  'allAlerts' = 'ALL_ALERTS',
  'active' = 'ACTIVE',
  'predicted' = 'PREDICTED',
}

export interface TooltipInterface {
  [name: string]: string | null | boolean | number | string[] | [{ number: string; color: string }];
}
export interface UnitMapInterface {
  bearing: null;
  breakDetails: {
    reason: '';
    startedAt: '';
  };
  cameras?: Array<UnitDashCamera>;
  displayId: '';
  featureSubTypeOf: 'unit';
  featureType: '';
  geometry: {};
  id: null;
  location: {};
  locationTime: '';
  selected: true;
  status: '';
  type: '';
  userDetails: {
    name: '';
    unitRelationType: '';
    userId: {
      value: null;
    };
  };
  unitRoutes: string[];
}

export interface AssignedUnitToUserResponse {
  errors: [];
  data: {
    assignUnitToUser: {
      email: string;
      id: number;
      name: string;
      phoneNumber: string;
      roles: [
        {
          accountId: number;
          description: string;
          id: number;
          name: string;
          permissions: [];
        }
      ];
      status: string;
      unit: {
        accountId: number;
        displayId: string;
        externalId: string;
        id: number;
        status: string;
        type: string;
        userDetails: {
          name: string;
          userId: number;
        };
      };
      unitRelationType: string;
    };
  };
}

export interface UIRoadEventEntity<T> extends UIEntity<T> {}

export interface UIWeatherEventEntity extends UIEntity<WeatherAlertView> {
  ui: {
    isViewed?: boolean;
    status?: WeatherEventStatus;
    featureType?: string;
    featureSubTypeOf?: string;
  };
}

export type UIEntity<T> = {
  data: T;
  ui?: Record<string, unknown>;
};

export enum FormSectionIncident {
  LOCATION = 'location',
  TIME = 'time',
  INVOLVED_VEHICLES = 'involvedVehicles',
  TYPE = 'type',
  MITIGATION = 'mitigation',
  AFFECTED_LANES = 'affectedLanes',
  CAMERAS = 'cameras',
  AUTO_PUBLISH = 'autoPublish',
  NOTES = 'notes',
  ADDITIONAL_INFO = 'additionalInfo',
  SOURCE = 'source',
  CASUALTIES = 'casualties',
  EXTERNAL_ID = 'externalId',
  MEDIA = 'media',
  ASSOCIATED_UNITS = 'associatedUnits',
  SEVERITY = 'severity',
  ATTRIBUTES = 'attributes',
}

export enum FormSectionTrafficDisruption {
  AFFECTED_LANES = 'affectedLanes',
  DESCRIPTION = 'description',
  CONTACT_PERSON = 'contactPerson',
  SCHEDULED = 'scheduled',
  MEDIA = 'media',
  PERMIT = 'permit',
  CAMERAS = 'cameras',
}

export enum ExpandedSectionType {
  INCIDENT = 'incident',
  SPECIAL_EVENT = 'special_event',
  ROAD_CLOSURE = 'road_closure',
  CONSTRUCTION = 'construction',
}

export interface ExpandedSections {
  incident: Record<FormSectionIncident, boolean>;
  road_closure: Record<FormSectionTrafficDisruption, boolean>;
  construction: Record<FormSectionTrafficDisruption, boolean>;
}

export type DashCamView = UnitDashCamera & {
  unitVehicleType?: VehicleType;
  unitDisplayId?: string;
  assignedUserName?: string;
  index?: number;
  title?: string;
  thumbnail?: string;
};

export enum CameraType {
  STREET_CAM,
  DASH_CAM,
}

export enum CameraTab {
  CCTV,
  Dash,
}

export type Media = IncidentMedia | TrafficDisruptionMedia;

export type MitigationsDiff = {
  created: CreateIncidentMitigationInput[];
  modified: UpdateIncidentMitigationInput[];
  removed: DeleteIncidentMitigationInput[];
};

export const ASSOCIATED_UNIT_RESPONSES = {
  [MapUnitTooltipResponses.en_route]: UnitResponse.EnRoute,
  [MapUnitTooltipResponses.on_scene]: UnitResponse.OnScene,
  [MapUnitTooltipResponses.mitigated]: UnitResponse.Mitigated,
  [MapUnitTooltipResponses.unknown_unit_status]: UnitResponse.UnknownUnitStatus,
};
