import { Injectable } from '@angular/core';
import { map, tap, catchError } from 'rxjs/operators';
import {
  Role,
  DeleteRoleGQL,
  CreateRoleGQL,
  SetRolePermissionsGQL,
  RolesGQL,
  Scalars,
  CreateRoleInput,
  SetRolePermissionsInput,
  DeleteRoleInput,
  UpdateRoleInput,
  UpdateUserGQL,
  UpdateRoleGQL,
} from '../models/gql.models';
import { of, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(
    private createRoleGQL: CreateRoleGQL,
    private deleteRoleGQL: DeleteRoleGQL,
    private setRolePermissionsGQL: SetRolePermissionsGQL,
    private updateRoleGQL: UpdateRoleGQL,
    private rolesGQL: RolesGQL
  ) {}

  rolesByAccount(accountId: number) {
    return this.rolesGQL.fetch({ accountId: accountId } as never).pipe(
      map(res => res.data.roles as Role[]),
      catchError(err => throwError({ errorCode: err.graphQLErrors[0].extensions.statusCode }))
    );
  }

  updateRole(input: UpdateRoleInput) {
    return this.updateRoleGQL
      .mutate({ input: input })
      .pipe(catchError(err => throwError({ errorCode: err.graphQLErrors[0].extensions.statusCode })));
  }

  createRole(input: CreateRoleInput) {
    return this.createRoleGQL.mutate({ input: input }).pipe(
      map(res => res?.data?.createRole as Role),
      catchError(err => throwError({ errorCode: err.graphQLErrors[0].extensions.statusCode }))
    );
  }

  setRolePermissions(input: SetRolePermissionsInput) {
    return this.setRolePermissionsGQL.mutate({ input: input }).pipe(
      map(res => res?.data?.setRolePermissions as Role),
      catchError(err => throwError({ errorCode: err.graphQLErrors[0].extensions.statusCode }))
    );
  }

  deleteRole(input: DeleteRoleInput) {
    return this.deleteRoleGQL
      .mutate({ input: input })
      .pipe(catchError(err => throwError({ errorCode: err.graphQLErrors[0].extensions.statusCode })));
  }
}
