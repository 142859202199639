/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { SpecialEventStoreEntity, SpecialEventUIEntity } from '@wc/wc-models/src';
import { map } from 'rxjs/operators';
import { LayerType } from '../../../../../../core/models/gql.models';
import { EntitiesServiceV2 } from '../../../services/entities.service';

export interface SpecialEventState extends EntityState<SpecialEventStoreEntity, number> {}
export interface SpecialEventUIState extends EntityState<SpecialEventUIEntity> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: LayerType.SpecialEvent })
export class SpecialEventsStore extends EntityStore<SpecialEventState> {
  readonly layerTypeName = LayerType.SpecialEvent;
  ui!: EntityUIStore<SpecialEventUIState>;
  constructor(entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$
      .pipe(map(diff => diff[this.layerTypeName]))
      .subscribe(UISpecialEvent => this.ui.upsertMany(UISpecialEvent || []));

    entitiesService.getDiff$
      .pipe(
        map(({ modified, removed }) => ({
          removed: removed?.[this.layerTypeName],
          modified: modified[this.layerTypeName],
        }))
      )
      .subscribe(({ removed, modified }) => {
        if (modified) this.upsertMany(modified as SpecialEventStoreEntity[]);
        if (removed) this.remove(removed);
      });
  }
}
