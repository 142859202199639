import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartShiftFormComponent } from './start-shift-form/start-shift-form.component';
import { ShiftRemovedFormComponent } from './shift-removed-form/shift-removed-form.component';
import { RouteRemovedFormComponent } from './route-removed-form/route-removed-form.component';
import { PauseStartShiftFormComponent } from './pause-start-shift-form/pause-start-shift-form.component';
import { PauseEndShiftFormComponent } from './pause-end-shift-form/pause-end-shift-form.component';
import { EndShiftFormComponent } from './end-shift-form/end-shift-form.component';
import { EditShiftFormComponent } from './edit-shift-form/edit-shift-form.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WcCheckboxModule } from '../../form-fields-controls/checkbox/checkbox.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { WcTextAreaModule } from '../../form-fields-controls/text-area/text-area.module';
import { WcNumberFieldModule } from '../../form-fields-controls/number-field/number-field.module';
import { MultiSelectComponentModule } from '../../form-fields-controls/multi-select/multi-select.component.module';
import { WcSelectControlModule } from '@wc/wc-ui/src';

const Material: any[] = [MatCheckboxModule];
@NgModule({
  declarations: [
    StartShiftFormComponent,
    ShiftRemovedFormComponent,
    RouteRemovedFormComponent,
    PauseStartShiftFormComponent,
    PauseEndShiftFormComponent,
    EndShiftFormComponent,
    EditShiftFormComponent,
  ],
  imports: [
    CommonModule,
    Material,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    WcCheckboxModule,
    WcSelectControlModule,
    WcTextAreaModule,
    WcNumberFieldModule,
    MultiSelectComponentModule,
  ],
  providers: [FormControl],
  exports: [
    StartShiftFormComponent,
    ShiftRemovedFormComponent,
    RouteRemovedFormComponent,
    PauseStartShiftFormComponent,
    PauseEndShiftFormComponent,
    EndShiftFormComponent,
    EditShiftFormComponent,
  ],
})
export class WcShiftFormsModule {}
