import { Inject, Injectable } from '@angular/core';
import { AdditionalLayerTypesEnum } from '@wc/wc-models';
import { Feature, Map } from 'ol';
import { Geometry, SimpleGeometry } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import { Modify } from 'ol/interaction';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { ModifyEvent } from 'ol/interaction/Modify';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { StyleLike } from 'ol/style/Style';
import { Subject } from 'rxjs';
import { InteractionsEvents } from '../../enums';
import { wcMapToken } from '../../injection-tokens';
import { wcFeatureProperties } from '../../interfaces';
import {
  EditGeometryOptions,
  FeatureEvent,
  wcCoordinateTypes,
  wcFeatureEventTarget,
  WcGeometryType,
} from '../../types';
import { WcFeature } from '../classes/wc-feature.class';
import { coordsToLonLat } from '../utils';
import { StyleService } from './style.service';

@Injectable({
  providedIn: 'root',
})
export class DrawService {
  modifyInteraction: Modify | undefined;
  private drawEvent = new Subject<FeatureEvent>();
  drawEvent$ = this.drawEvent.asObservable();
  drawLayer!: VectorLayer | undefined;
  changes: wcFeatureEventTarget | undefined;
  drawInteraction: Draw | undefined;
  private _featureBeingEdited: undefined | Feature;
  private originalFeatureStyle: StyleLike | undefined;
  private _isCreating = false;

  get featureBeingEdited() {
    return this._featureBeingEdited;
  }

  get isCreatingInProgress() {
    return this._isCreating;
  }

  constructor(@Inject(wcMapToken) private wcMap: Map, private styleService: StyleService) {}

  create(
    type: WcGeometryType,
    coords: wcCoordinateTypes | null = null,
    createStyle?: StyleLike,
    modifyOptions: EditGeometryOptions = {}
  ) {
    if (coords) {
      const feature = new WcFeature({
        coordinates: coords,
        geomType: type,
        entityType: AdditionalLayerTypesEnum.create,
        id: 1,
        parentLayerName: AdditionalLayerTypesEnum.create,
      } as wcFeatureProperties);

      this.emitUpdateGeometry({
        feature: feature,
        target: null,
        type: 'DrawEvent',
        preventDefault: () => true,
        stopPropagation: () => true,
      } as DrawEvent);

      this.modifyFeature(feature, modifyOptions, type);
      return;
    }

    this.draw(type, createStyle, true, modifyOptions);
  }

  stopCreate() {
    this._isCreating = false;

    if (this.drawLayer) {
      this.wcMap.removeLayer(this.drawLayer as VectorLayer);
      this.drawLayer = undefined;
    }

    if (this.drawInteraction) {
      this.wcMap.removeInteraction(this.drawInteraction);
      this.drawInteraction = undefined;
    }
  }

  stopEdit(): wcFeatureEventTarget | undefined {
    this._featureBeingEdited?.setStyle(this.originalFeatureStyle);

    this._featureBeingEdited = undefined;
    this.originalFeatureStyle = undefined;

    if (this.drawLayer) {
      this.wcMap.removeLayer(this.drawLayer as VectorLayer);
      this.drawLayer = undefined;
    }
    if (this.modifyInteraction) {
      this.wcMap.removeInteraction(this.modifyInteraction);
      this.modifyInteraction = undefined;
    }

    return this.changes;
  }

  edit<T extends string>(
    feature: WcFeature<T> | undefined | Feature<Geometry>,
    options: EditGeometryOptions = {},
    type: WcGeometryType
  ) {
    this.modifyFeature(feature, options, type);
  }

  private draw(
    type: WcGeometryType,
    createStyle?: StyleLike,
    editAfterDraw = false,
    modifyOptions: EditGeometryOptions = {}
  ) {
    // Currently we do not support multiple creations at once, only one at a time.
    if (this._isCreating) return;
    this._isCreating = true;
    this.toggleZoomInteraction(false);
    this.drawLayer = new VectorLayer({
      source: new VectorSource(),
      zIndex: 1000,
    });
    this.drawInteraction = new Draw({
      source: this.drawLayer.getSource(),
      type: type as GeometryType,
      style: createStyle,
    });
    this.wcMap.addLayer(this.drawLayer);
    this.wcMap.addInteraction(this.drawInteraction);

    this.drawInteraction.on('drawend', (e: DrawEvent) => {
      this.emitUpdateGeometry(e);
      if (editAfterDraw) this.modifyFeature(e.feature, modifyOptions, type);
      this.wcMap.removeInteraction(this.drawInteraction as Draw);
      setTimeout(() => {
        this.toggleZoomInteraction(true);
      }, 100);
    });
  }

  private modifyFeature<T extends string>(
    feature: WcFeature<T> | undefined | Feature<Geometry>,
    options: EditGeometryOptions = {},
    type: WcGeometryType
  ) {
    this.stopEdit();
    if (!feature || this.drawLayer) return;
    this.changes = undefined;
    // Save original feature for ref. give indication if a feature currently being edited, and helps restore original style when edit completes
    this._featureBeingEdited = feature;
    this.originalFeatureStyle = feature.getStyle();
    // refStyle = [] to make the feature disappear.
    // undefined/null will cause olMap to skip this feature style and search styles in the layer's level, while [] makes olMap to process this style, and get nothing.
    let _refStyle: StyleLike = [],
      _editStyle: StyleLike = [];
    const featureClone = feature.clone(),
      _source = new VectorSource({ features: [featureClone] }),
      fProps = feature.getProperties() as wcFeatureProperties<string>;

    featureClone.setId(feature.getId());

    if (options.referenceStyleArgs) {
      _refStyle = options.referenceStyleArgs[type]?.map(referenceStyle =>
        this.styleService.transformStylePropToStyle(referenceStyle, fProps)
      ) as StyleLike;
    }
    if (options.editStyleArgs) {
      _editStyle = options.editStyleArgs[type]?.map(editStyle =>
        this.styleService.transformStylePropToStyle(editStyle, fProps)
      ) as StyleLike;
    }

    // set the styles for the cloned feature and original one, while being edited.
    feature.setStyle(_refStyle);
    featureClone.setStyle(_editStyle);

    this.drawLayer = new VectorLayer({
      source: _source,
      zIndex: 1000,
    });

    // Modify has a style, but we don't want to see this featureLike property, but only our clone from above ( so we pass [])
    this.modifyInteraction = new Modify({
      source: _source,
      pixelTolerance: 40,
      style: [],
    });

    this.wcMap.addLayer(this.drawLayer);
    this.wcMap.addInteraction(this.modifyInteraction);
    this.modifyInteraction.on('modifyend', (e: ModifyEvent) => this.emitUpdateGeometry(e));
  }

  private emitUpdateGeometry(e: ModifyEvent | DrawEvent) {
    let feature: Feature;
    let eventType: InteractionsEvents;

    if (e instanceof ModifyEvent && e.features) {
      feature = e.features.getArray()[0];
      eventType = InteractionsEvents.modifyend;
    } else {
      feature = (e as DrawEvent).feature;
      eventType = InteractionsEvents.drawend;
    }
    const geometry = feature.getGeometry() as SimpleGeometry;
    const coordinates = geometry.getCoordinates();
    const fProps = feature.getProperties();
    (this.changes = {
      featureId: feature.getId() as string,
      geometry: {
        type: geometry?.getType() as GeometryType,
        coordinates: coordsToLonLat(coordinates),
      },
      fProps: Array.isArray(fProps.features)
        ? fProps.features.map<wcFeatureProperties>(f => f.getProperties())
        : (fProps as wcFeatureProperties),
    }),
      this.drawEvent.next({
        eventType,
        targets: [this.changes],
      });
  }

  private toggleZoomInteraction(status: boolean): void {
    this.wcMap.getInteractions().item(1).setActive(status);
  }
}
