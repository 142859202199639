import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { environment } from '@wc/core/environments/environment';
import { WindowService } from '@wc/core/services/window.service';
import { Observable, Subject } from 'rxjs';
import { delay, map, retry, take } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { AuthUserService } from './auth-user.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  online$: Observable<boolean>;

  ws!: WebSocketSubject<any>;
  subject: Subject<any> = new Subject();
  openObserver: Subject<any> = new Subject();
  closeObserver: Subject<any> = new Subject();
  url!: string;

  constructor(
    private authUserService: AuthUserService,
    private windowService: WindowService,
    private authService: AuthService
  ) {
    this.online$ = this.windowService.online$;
    this.online$.subscribe(isOnline => {
      if (isOnline && this.ws?.closed) {
        this.url = this.url || environment.ws_url;
        this.create(this.url);
      }
    });
  }

  public connect(url?): Subject<any> {
    this.url = url || environment.ws_url;
    if (!this.ws) {
      this.openObserver.subscribe(e => {
        this.wsOpened(e);
      });
      this.closeObserver.subscribe(e => {
        this.wsClosed(e);
      });
      this.create(this.url);
      console.log('Successfully connected: ' + url);
    }
    return this.ws;
  }

  private create(url) {
    this.ws = webSocket({
      url,
      openObserver: this.openObserver,
      closeObserver: this.closeObserver,
    });

    this.ws.pipe(delay(2000), retry(40)).subscribe(
      msg => {
        this.subject.next(msg);
      },
      // Called whenever there is a message from the server
      err => {
        console.log(err);
        datadogLogs.logger.info('websocket', {
          websocket_network: {
            errorType: err.type,
          },
        });
        if (err.type === 'close') {
          console.log('=============');
          console.log('Websocket closed');
        }
      },
      // Called if WebSocket API signals some kind of error
      () => {
        console.log('complete');
        this.create(url);
      }
      // Called when connection is closed (for whatever reason)
    );
  }

  wsSubscribe(token: string) {
    const authUser = this.authUserService.user;
    const msg = {
      idToken: token,
      type: 'Subscribe',
      customerId: authUser.account.customer.id,
      userName: authUser.name,
    };
    this.ws.next(msg);
  }

  wsOpened(e) {
    this.authService
      .getTokenSilently$()
      .pipe(
        take(1),
        map(token => {
          if (!token) {
            throw token;
          } else {
            this.wsSubscribe(token);
          }
        })
      )
      .subscribe();
  }

  wsClosed(e) {
    console.log('Websocket closed');
  }

  send(msg) {
    if (this.ws) {
      this.ws.next(msg);
    }
  }

  onMessage(): Subject<any> {
    return this.subject;
  }

  close() {
    if (this.ws) {
      this.ws.unsubscribe();
    }
  }
}
