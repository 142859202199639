import { Pipe, PipeTransform } from '@angular/core';

const URL_NAME_REGEX = 'upload(-)[0-9]*-[0-9]*(-)';

@Pipe({
  name: 'mediaTitle',
})
export class MediaTitlePipe implements PipeTransform {
  urlNameRegex = URL_NAME_REGEX;

  transform(mediaKey: string, shouldFormat: boolean): string {
    if (!shouldFormat) return mediaKey;
    const result = mediaKey.match(this.urlNameRegex);
    const position = result ? mediaKey.lastIndexOf(result[0]) + result[0].length : 0;
    return mediaKey.substring(position);
  }
}
