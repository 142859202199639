import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AutocompleteModule } from '../../components/autocomplete/autocomplete.module';
import { WcTooltipModule } from '../../components/tooltip/tooltip.module';
import { WcSelectModule } from '../../form-components/wc-select/wc-select.module';
import { MitigationUnitsComponentModule } from '../mitigation/mitigation-component.module';
import { AssociatedUnitComponent } from './associated-unit.component';

const Materials = [MatIconModule, MatButtonModule];

@NgModule({
  declarations: [AssociatedUnitComponent],
  imports: [
    CommonModule,
    TranslateModule,
    Materials,
    WcSelectModule,
    AutocompleteModule,
    MitigationUnitsComponentModule,
    WcTooltipModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [AssociatedUnitComponent],
})
export class AssociatedUnitControlModule {}
