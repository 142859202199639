<div
  class="radio-button-component"
  [class.flex-column]="column"
  [class.small]="size === 'small'"
  [class.disabled]="isDisabled || ngControl.control?.disabled">
  <label class="radio-button-component__label" [class.bold]="boldLabel" *ngIf="formFieldData.label"
    >{{ formFieldData.label | translate }}
    <wc-icon
      class="radio-button-component__label-icon"
      [wcTooltipText]="helpTextTranslationKey | translate"
      wcTooltipPlacement="right"
      [wcTooltipMaxWidthPx]="300"
      *ngIf="helpTextTranslationKey"
      iconName="question-circle"></wc-icon>
  </label>

  <mat-radio-group
    wcFocusStyles
    [focusDirectiveDisabled]="disableFocusStyle"
    [class.flex-column]="column"
    [formControl]="$any(ngControl.control)">
    <mat-radio-button
      *ngFor="let option of formFieldData.options"
      [wcTooltipText]="option.tooltipText"
      [wcTooltipDisabled]="!option.tooltipText"
      wcTooltipPlacement="top"
      [attr.data-cy-id]="option.dataCyId"
      [value]="option.value"
      [disabled]="option.disabled || isDisabled || ngControl.control?.disabled">
      {{ option.displayName || '' | translate }}
    </mat-radio-button>
  </mat-radio-group>
</div>
