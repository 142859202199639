import { AbstractControl } from '@angular/forms';
import moment from 'moment';

export function FutureDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (!control.value) return null;
  if (moment().isSameOrBefore(control.value)) {
    return null;
  }
  return { dateMustBeLaterThanToday: true };
}
