import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import {
  AuthUserService,
  ElectronService,
  HeapAnalyticsService,
  LocalStorageService,
  ReleaseNotesService,
  TourService,
} from '@wc-core';
import { PermissionsFacadeService } from '@wc/permissions/domain/src';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { TourName } from '@wc/wc-core/src/lib/types/tour';
import moment from 'moment';
import { map, takeWhile } from 'rxjs/operators';
import { DesktopUiStateService } from '../../../shared/desktop-ui-state.service';
import { SettingsMenuComponent } from '../../../shared/settings-menu/settings-menu.component';

@Component({
  selector: 'wc-client-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  @ViewChild('settingsMenu') settingsMenu!: SettingsMenuComponent;
  isMenuOpen = false;
  hideButtonFromHeader = false;
  isElectronApp = false;
  isAlive = true;
  onboardingStartDate = '';

  get authUserName() {
    return this.authUserService.authUser$.pipe(map(user => user?.name));
  }

  get isDirectionRtl() {
    return this.desktopUiState.isDirectionRtl;
  }

  get newReleasesCount() {
    return this.releaseNoteService.unseenReleasesCount$;
  }

  get releaseNotes() {
    return this.releaseNoteService.releaseNotes$;
  }

  get hasDataHubPermission() {
    return this.permissionsFacadeService.hasPermission(
      [
        'HISTORICAL_INCIDENT:READ',
        'HISTORICAL_SHIFT:READ',
        'HISTORICAL_TRAFFIC_DISRUPTION:READ',
        'DOMO_FSP_REPORT:READ',
        'DOMO_INCIDENT_REPORT:READ',
        'DOMO_TRANSIT_REPORT:READ',
      ],
      'OR'
    );
  }

  constructor(
    private authUserService: AuthUserService,
    private desktopUiState: DesktopUiStateService,
    private releaseNoteService: ReleaseNotesService,
    private electronService: ElectronService,
    private tourService: TourService,
    private heapService: HeapAnalyticsService,
    private localStorageService: LocalStorageService,
    private permissionsFacadeService: PermissionsFacadeService
  ) {
    this.isElectronApp = this.electronService.isElectronApp;
  }
  ngAfterViewInit(): void {
    this.listenTourCompleted();
    this.setHideOnboardingButton();
    this.releaseNoteService.fetchReleases();
  }

  listenTourCompleted() {
    const isOnboardingTourDone = this.localStorageService.get(LocalStorageKeys.AvailableOnboardingTours).length === 0;
    if (!isOnboardingTourDone) {
      this.tourService.completedOnboarding.pipe(takeWhile(() => this.isAlive)).subscribe(val => {
        if (!this.hideButtonFromHeader && val) {
          this.hideButtonFromHeader = true;
          this.settingsMenu.menuTrigger.openMenu();
          this.heapService.trackUserSpecificAction('heap-desktop-onboarding-complete');

          setTimeout(() => {
            this.tourService.beginTour(TourName.CompletedOnboardingTour);
          }, 250);
        }
      });
    } else {
      this.hideButtonFromHeader = true;
    }

    this.releaseNoteService.fetchReleases();
  }

  setHideOnboardingButton() {
    const onboardingStartDateLocalStorage = this.localStorageService.get(LocalStorageKeys.OnboardingStartDate);
    if (onboardingStartDateLocalStorage === null) {
      this.onboardingStartDate = moment().toISOString();
      this.localStorageService.set(LocalStorageKeys.OnboardingStartDate, this.onboardingStartDate);
    } else {
      this.onboardingStartDate = onboardingStartDateLocalStorage;

      if (moment().isAfter(moment(onboardingStartDateLocalStorage).add(14, 'd'))) {
        this.hideButtonFromHeader = true;
      }
    }
  }

  openWidget() {
    this.electronService.context.openWidgetWindow();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
