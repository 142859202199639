import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CustomFormControlComponent } from '../../lib/base/custom-form-control';

@Component({
  selector: 'wc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxComponent extends CustomFormControlComponent {
  @Input() isDisabled = false;
  @Input() size: 'small' | 'medium' = 'small';
}
