import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Enums from '@wc/core/models/enums';
import { FieldData } from '@wc/features/ui/form-controls/form-models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateShiftPauseInput, ShiftStore, UiStore } from '@wc/core';

import { MatDialog } from '@angular/material/dialog';
import { ShiftPauseReasons } from '@wc/core';
import { datadogLogs } from '@datadog/browser-logs';
import { ComponentType } from '@angular/cdk/portal';
import { ShiftModalComponent } from '@wc/features/ui/components/shift-modal/shift-modal.component';
import { FormFieldData } from '@wc/wc-ui/src/lib/base';

type FormModel = CreateShiftPauseInput;

@Component({
  selector: 'wc-pause-start-shift-form',
  templateUrl: './pause-start-shift-form.component.html',
  styleUrls: ['./pause-start-shift-form.component.scss'],
})
export class PauseStartShiftFormComponent implements OnInit {
  pauseShiftForm!: FormGroup;
  isStartPressed = false;
  // @Input() shiftModalComponent: ComponentType<unknown>;
  @Output() modalClosed: EventEmitter<any> = new EventEmitter();

  // get isUserHasUnit() { return this.shiftStore.currentShift.unit?.id; }

  shiftFieldsData: { [filedName in keyof FormModel | any]: FormFieldData } = {
    reason: {
      label: 'shiftsAndRoutes.reason',
      options: this.shiftStore.shiftPauseReasonsOptions,
      required: true,
    },
    reasonNote: {
      label: 'shiftsAndRoutes.reasonOther',
      required: true,
    },
  };

  constructor(
    private fb: FormBuilder,
    private shiftStore: ShiftStore,
    public dialog: MatDialog,
    public uiStore: UiStore
  ) {}

  ngOnInit(): void {
    this.pauseShiftForm = this.createForm({
      reason: [null, Validators.required],
      reasonNote: [null],
      shiftId: this.shiftStore.currentShift.shiftId,
    });

    this.pauseShiftForm.get('reason')?.valueChanges.subscribe(value => {
      const reasonNote = this.pauseShiftForm.get('reasonNote');
      if (value === ShiftPauseReasons.Other) {
        reasonNote?.setValidators([Validators.required, Validators.maxLength(50)]);
      } else {
        reasonNote?.clearValidators();
        reasonNote?.reset();
      }
      reasonNote?.updateValueAndValidity();
    });
  }

  createForm(model: { [filedName in keyof FormModel]: any }): FormGroup {
    return this.fb.group(model);
  }

  closeShiftForm() {
    this.modalClosed.emit();
  }

  submitPauseShiftForm() {
    this.pauseShiftForm.get('reasonNote')?.markAllAsTouched();

    if (this.pauseShiftForm.valid) {
      this.isStartPressed = true;
      this.shiftStore.startShiftPause(this.pauseShiftForm.value).subscribe(
        response => {
          if (response) {
            this.closeShiftForm();
            datadogLogs.logger.info('shiftPauseStartSuccess', {
              shift: JSON.stringify(this.shiftStore.currentShift),
              userId: this.shiftStore.currentShift.user.id,
            });

            this.openPauseEndShiftModal(Enums.ShiftFormState.PauseEnd, this.shiftStore.currentShiftPause.pauseId);
          }
        },
        error => {
          datadogLogs.logger.error('shiftPauseStartFailed', {
            shift: JSON.stringify(this.shiftStore.currentShift),
            userId: this.shiftStore.currentShift.user.id,
          });
          console.log(error);
        }
      );
    } else {
      this.isStartPressed = false;
    }
  }

  openPauseEndShiftModal(shiftFormState: Enums.ShiftFormState.PauseEnd, pauseId: number) {
    const dialogRef = this.dialog.open(ShiftModalComponent, {
      width: '470px',
      disableClose: true,
      panelClass: 'settings-form-modal',
      data: {
        shiftFormState: shiftFormState,
        details: pauseId,
      },
    });
  }
}
