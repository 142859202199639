<div
  *ngIf="isVisible"
  #badge
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="6px"
  class="network-badge-container"
  [ngClass]="state">
  <i *ngIf="state === 'offline'" class="fas fa-wifi-slash"></i>
  <img *ngIf="state === 'unstable'" src="assets/icons/unstable-network.svg" />
  <span *ngIf="!iconOnly">{{ title }}</span>
</div>

<ng-template #offlineTooltip let-title="title" let-subtitle="subtitle">
  <wc-notification [triangleTip]="true" variant="dark" [showCloseIcon]="true" (hide)="hideTooltip()">
    <div class="tooltip-container">
      <span class="bold">{{ title | translate }}</span>
      <br />
      <span>
        {{ subtitle | translate }}
      </span>
    </div>
  </wc-notification>
</ng-template>
