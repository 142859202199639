<div class="segment-details-tab" [class.portrait-mode]="isPortraitDesktopMode">
  <div class="segment-details-tab__header">
    <div class="segment-details-tab__header__title">
      <button type="button" class="segment-details-tab__header__back-button" (click)="goBackToSegmentsList()">
        <i class="far fa-chevron-left"></i>
      </button>

      <wc-segment-title
        *ngIf="segment"
        [addressTitle]="segment.addressTitle"
        [direction]="segment.direction"
        [range]="segment.length"
        [measureUnitType]="measureUnitType"
        [showIcon]="false">
      </wc-segment-title>
    </div>

    <div class="segment-details-tab__close-button" (click)="closePanel()">
      <i class="far fa-times fa-lg close-button-icon"></i>
    </div>

    <div class="segment-details-tab__header__source">
      <wc-segment-data-source
        *ngIf="segment && selectedMetric"
        (feedSourceChanged)="updateFeedSource($event)"
        [dataSources]="feedSources"
        [regionalSettings]="regionalSettings"
        [selectedFeedSource]="selectedMetric.vendorName"
        [isUpdated]="selectedMetric.isUpdated"
        [timeRange]="{
          startTime: selectedMetric?.startedAt,
          endTime: selectedMetric?.endedAt
        }">
      </wc-segment-data-source>
    </div>
  </div>

  <ng-container *ngIf="segment && selectedMetric?.isDataExist && selectedMetric?.isUpdated; else emptyState">
    <div class="segment-details-tab__body" id="segmentDetailsBody">
      <div class="segment-details-tab__segment-data">
      <wc-segment-data
        *ngIf="segment && selectedMetric"
        [data]="selectedMetric"
        [measureUnitType]="measureUnitType"
        [mode]="mode"
        [maxSpeed]="segment.maxSpeed">
      </wc-segment-data>
    </div>

      <div
        class="segment-details-tab__lanes-table"
        *ngIf="selectedMetric?.lanesData && selectedMetric?.lanesData?.length > 1">
        <h2 class="segment-details-tab__lanes-table__title">
          <i class="fas fa-road"></i>
          {{ 'roadwayStatus.lanesTableTitle' | translate }}
        </h2>

        <div class="segment-details-tab__lanes-table__header">
          <div>{{ 'roadwayStatus.laneType' | translate }}</div>
          <div>{{ 'roadwayStatus.countPerLane' | translate }}</div>
          <div>{{ 'roadwayStatus.avgSpeed' | translate }}</div>
        </div>
        <div class="segment-details-tab__lanes-table__body">
          <div class="segment-details-tab__lanes-table__body__row" *ngFor="let lane of selectedMetric?.lanesData">
            <div>{{ 'laneTypes.' + lane.type | translate }}</div>
            <div>{{ lane.count }}</div>
            <div>{{ lane.avgSpeed }}</div>
          </div>
        </div>
      </div>
      <div class="segment-details-tab__vehicles-graph">
        <h2 class="segment-details-tab__vehicles-graph__title">
          <i class="fas fa-car-alt"></i>
          {{ 'roadwayStatus.vehicleTypesGraphTitle' | translate }}
        </h2>
        <div class="segment-details-tab__vehicles-graph__body">
          <wc-horizontal-bar-charts [graphWidthAndHeight]="[276, 200]" [chartData]="barChartData">
          </wc-horizontal-bar-charts>
        </div>
      </div>

      <div class="segment-details-tab__transport-table" *ngIf="busesData">
        <h2 class="segment-details-tab__transport-table__title">
          <i class="fas fa-car"></i>
          {{ 'roadwayStatus.transportTableTitle' | translate }}
        </h2>
        <div class="segment-details-tab__transport-table__header">
          <div>{{ 'roadwayStatus.vehicleType' | translate }}</div>
          <div>{{ 'roadwayStatus.vehicleCount' | translate }}</div>
          <div>{{ 'roadwayStatus.avgSpeed' | translate }}</div>
        </div>
        <div class="segment-details-tab__transport-table__body">
          <div class="segment-details-tab__transport-table__body__row">
            <div>{{ 'roadwayStatus.transportTypes.BUS' | translate }}</div>
            <div>{{ busesData.count }}</div>
            <div>{{ busesData.avgSpeed }}</div>
          </div>
          <div class="segment-details-tab__transport-table__body__row">
            <div>{{ 'roadwayStatus.transportTypes.OTHER_TRANSPORT' | translate }}</div>
            <div>{{ selectedMetric?.nonBusesCount }}</div>
            <div>{{ selectedMetric?.nonBusesAvgSpeed }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #emptyState>
    <div class="empty-state">
      <img src="assets/images/no_segments_error_state.svg" alt="no segments in the list" />
      <div class="error-message">
        <div>
          {{ 'roadwayStatus.somethingWentWrong' | translate }}
        </div>
        <div>
          {{ 'roadwayStatus.error' | translate }}
        </div>
        <div>
          {{ 'roadwayStatus.tryLater' | translate }}
        </div>
      </div>
    </div>
  </ng-template>
</div>
