import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { WcPopupCloserModule } from '@wc/wc-common/src';
import { HorizontalBarChartsModule } from '../../horizontal-bar-charts/horizontal-bar-charts.module';
import { SegmentFragmentsModule } from '../internal-components/segmant-fragments.module';
import { SegmentDetailsCardModule } from '../segment-details-card/segment-details-card.module';
import { RoadwayStatusPanelComponent } from './roadway-status-panel.component';
import { SegmentDetailsTabComponent } from './segment-details-tab/segment-details-tab.component';
import { SegmentsListTabComponent } from './segments-list-tab/segments-list-tab.component';

@NgModule({
  declarations: [RoadwayStatusPanelComponent, SegmentsListTabComponent, SegmentDetailsTabComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTabsModule,
    SegmentDetailsCardModule,
    SegmentFragmentsModule,
    HorizontalBarChartsModule,
    WcPopupCloserModule,
  ],
  exports: [RoadwayStatusPanelComponent],
})
export class RoadwayStatusPanelModule {}
