<div class="complete-reject-component" [ngClass]="viewMode">
  <div class="complete-reject-component__header">
    <span class="title-color">{{ isReject ? ('rejectIncident' | translate) : ('completeIncident' | translate) }}</span>
    <i (click)="handleClose()" class="close-button far fa-times"></i>
  </div>
  <form [formGroup]="form" class="complete-reject-component__body">
    <div class="complete-reject-component__reasons">
      <wc-select
        [useGrouping]="true"
        dynamicClass="full-width"
        formControlName="endReason"
        data-cy-id="complete-reason-downdown-btn"
        [formFieldData]="formData.endReason"></wc-select>
      <wc-checkbox
        class="complete-reject-component__hazard-remains--checkbox"
        *ngIf="!isTabletMode && isIncidentResolvedSelected"
        formControlName="hazardRemains"
        [formFieldData]="formData.hazardRemains"></wc-checkbox>
      <div
        class="complete-reject-component__subtitle"
        [class.complete-reject-component__hazard-remains--visible]="!isTabletMode && isIncidentResolvedSelected">
        {{ 'comment' | translate }}
      </div>

      <!-- old text area remains in tablet -->
      <wc-text-area-control
        *ngIf="this.isTabletMode"
        [rows]="viewMode === 'widget' ? 3 : 4"
        [formControl]="$any(form.get('endReasonComment'))"></wc-text-area-control>

      <wc-text-area
        *ngIf="!this.isTabletMode"
        [rows]="viewMode === 'widget' ? 3 : 4"
        formControlName="endReasonComment"></wc-text-area>
    </div>

    <div class="complete-reject-component__merge-container" *ngIf="isDuplicatedReasonSelected">
      <wc-loader-component [show]="isLoadingIncidents"></wc-loader-component>
      <div class="complete-reject-component__required">
        {{ 'mergedIntoAnotherIncident' | translate }}
        <div
          class="complete-reject-component__error"
          *ngIf="
            form.get('incidentTargetId')?.touched &&
            form.get('incidentTargetId')?.invalid &&
            !isNearByIncidentsExists &&
            !isLoading
          ">
          {{ (isReject ? 'selectAnotherRejectEndReason' : 'selectAnotherCompleteEndReason') | translate }}
        </div>
      </div>
      <ng-container *ngIf="isNearByIncidentsExists">
        <wc-autocomplete
          data-cy-id="related-events-list-merge-incident-btn"
          [showLabel]="false"
          [formFieldData]="formData.incidentTargetId"
          formControlName="incidentTargetId">
        </wc-autocomplete>
        <div class="complete-reject-component__merge-info">
          <div class="complete-reject-component__merge-info--bold">
            {{ 'mergeWillApply' | translate }}
          </div>
          <div [innerHTML]="'mergeInfoIncident' | translate" class="complete-reject-component__merge-info--grey"></div>
          <div [innerHTML]="'mergeInfoTime' | translate" class="complete-reject-component__merge-info--grey"></div>
          <div [innerHTML]="'mergeInfoOther' | translate" class="complete-reject-component__merge-info--grey"></div>
        </div>
      </ng-container>
      <ng-container *ngIf="!isNearByIncidentsExists && !isLoading">
        <div class="complete-reject-component__merge-info">
          <div class="complete-reject-component__merge-info--grey">
            {{ 'noNearByIncidentDetected' | translate }}
          </div>
        </div>
      </ng-container>
    </div>
  </form>

  <div class="complete-reject-component__action-buttons">
    <a
      data-cy-id="complete-incident-help-link"
      class="complete-reject-component__action-buttons__help-center"
      target="_blank"
      href="https://help.rekor.ai/how-are-incidents-completed">
      <i class="fas fa-question-circle"></i>
      {{ 'reasonTypes' | translate }}</a
    >
    <div class="wc-buttons-container">
      <button class="secondary-button" wcHeapClass="cancel-reject-complete-form" (click)="handleClose()">
        {{ 'cancel' | translate }}
      </button>
      <button
        [disabled]="isLoading"
        wcHeapClass="approve-reject-complete-form"
        data-cy-id="approve-complete-btn"
        class="primary-button"
        (click)="handleComplete()">
        {{ (this.isReject ? 'reject' : 'complete') | translate }}
        {{ isDuplicatedReasonSelected ? ('andMerge' | translate) : '' }}
      </button>
    </div>
  </div>
</div>
