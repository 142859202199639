/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  LayerNamesOptionsType,
  LayerPanelStoreItem,
  LayerTypeToEntityType,
  LiveMapState,
  WorkspaceSelection,
} from '@wc/wc-models/src';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { LiveMapStore } from './live-map.store';

@Injectable({ providedIn: 'root' })
export class LiveMapQuery extends Query<LiveMapState> {
  constructor(protected store: LiveMapStore) {
    super(store);
  }

  getVisibleEntitiesIdsByLayerType(layerType: keyof LayerTypeToEntityType): Observable<any> {
    return this.select(state => state.visibleEntityIdsByLayerType[layerType]).pipe(
      map(visibleIds => visibleIds || []),
      distinctUntilChanged((l, c) => {
        const values = {};
        l.forEach(id => (values[id] = 1));
        c.forEach(id => (values[id] ? (values[id] = 0) : (values[id] = 1)));
        return (
          Object.values(values).reduce((acc: any, curr: any) => {
            acc += +curr;
            return acc;
          }, 0) === 0
        );
      })
    );
  }

  getMapLayerStateByName(name: LayerNamesOptionsType): LayerPanelStoreItem<LayerNamesOptionsType> | undefined {
    return this.getValue().layerPanelStoreItemsMap.get(name);
  }

  get availableWorkspaces$() {
    return this.select(({ availableWorkspaces }) => availableWorkspaces);
  }

  get availableWorkspaces(): WorkspaceSelection[] {
    return this.getValue().availableWorkspaces;
  }

  get isAllWorkspaceUnchecked$(): Observable<boolean> {
    return this.availableWorkspaces$.pipe(
      map(workspaces => {
        return workspaces.every(w => !w.checked);
      })
    );
  }

  get layerPanelStoreItemsMap$() {
    return this.select().pipe(map(state => state.layerPanelStoreItemsMap));
  }

  get storeValue() {
    return this.getValue();
  }

  get transitLayersVisibility$() {
    return this.select(({ visibleTransitLayers }) => visibleTransitLayers);
  }

  get activeWorkspacesIds(): number[] {
    return this.getValue()
      .availableWorkspaces.filter(workspace => !!workspace.checked)
      .map(workspace => workspace.id as number);
  }

  get currentCheckedLayerNames(): {
    asQuery$: Observable<string[]>;
    asValue: string[];
  } {
    const transformValue = (data: Map<LayerNamesOptionsType, LayerPanelStoreItem<LayerNamesOptionsType>>) => {
      return Array.from(data.entries())
        .filter(item => item[1].checked)
        .map(filtered => filtered[0]);
    };
    return {
      asQuery$: this.layerPanelStoreItemsMap$.pipe(debounceTime(20), map(transformValue)),
      asValue: transformValue(this.getValue().layerPanelStoreItemsMap),
    };
  }

  get currentCheckedWorkSpaces() {
    const filterAndMapWorkSpaces = (workSpaces: WorkspaceSelection[]) =>
      workSpaces.filter(({ checked }) => checked).map(({ id }) => id);
    return {
      asQuery$: this.select(state => state.availableWorkspaces).pipe(map(filterAndMapWorkSpaces)),
      asValue: filterAndMapWorkSpaces(this.storeValue.availableWorkspaces),
    };
  }
}
