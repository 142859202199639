import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { Incident, IncidentUnit, IncidentUnitDetails, UiStore, UsersStore } from '@wc/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';

@Component({
  selector: 'wc-unit-cards-list',
  templateUrl: './unit-cards-list.component.html',
  styleUrls: ['./unit-cards-list.component.scss'],
})
export class UnitCardsListComponent implements AfterViewInit {
  @ViewChild(SwiperDirective, { static: true }) swiperElm!: SwiperDirective;
  unitList!: Array<IncidentUnit | IncidentUnitDetails>;
  config!: SwiperConfigInterface;
  _incident!: Incident;

  @Input() analyticsMode!: false;
  @Input() set incident(incident: Incident) {
    this._incident = incident;
    this.unitList = incident?.associatedUnits || [];
    this.swiperElm.update();
  }
  get incident() {
    return this._incident;
  }

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  constructor(public uiStore: UiStore, private usersStore: UsersStore) {}

  ngAfterViewInit() {
    this.initAssociatedUnitsSwiperConfig();
  }

  initAssociatedUnitsSwiperConfig() {
    this.config = {
      direction: 'horizontal',
      slidesPerView: this.isTabletMode ? 1 : 1.46,
      spaceBetween: 40,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination-associated-units',
        clickable: true,
      },
    };
  }

  slideToCurrentUser() {
    const userIndex = this.unitList.findIndex(unit => unit.driverDetails?.userId === this.usersStore.authUser.id);

    if (userIndex) {
      this.swiperElm?.setIndex(userIndex);
    }
  }
}
