<div class="confirm-modal-container" [ngClass]="viewMode" [ngSwitch]="modalType">
  <ng-container *ngSwitchCase="confirmationModalTypes.DiscardChanges">
    <div class="confirm-modal-content">
      <div class="discard-changes-message" data-cy-id="discard-changes-message">
        {{ 'confirmModal.discardChangesMessage' | translate }}
      </div>

      <div class="modal-buttons-container">
        <button class="secondary-button" data-cy-id="cancel-discard-changes-message-button" (click)="onConfirm()">
          {{ 'confirmModal.cancelButton' | translate }}
        </button>
        <button class="primary-button" data-cy-id="discard-changes-message-button" (click)="onDismiss()">
          {{ 'confirmModal.discardChangesButton' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.RemoveUser">
    <div *ngIf="timer$ | async as time">{{ time }}</div>
    <div class="confirm-modal-content">
      <div data-cy-id="delete-user-message" class="delete-user-message">
        <i class="icon-attention"></i>

        <div>
          <div [innerHTML]="'confirmModal.deleteUserMessage' | translate: { userName: data.details }"></div>
          <span>{{ 'confirmModal.cannotBeUndone' | translate }}</span>
        </div>
      </div>

      <div class="modal-buttons-container">
        <button data-cy-id="cancel-delete-user-button" class="secondary-button" (click)="onDismiss()">
          {{ 'confirmModal.cancelButton' | translate }}
        </button>
        <button data-cy-id="delete-user-button" class="primary-button" (click)="onConfirm()">
          {{ 'confirmModal.deleteButton' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.RemoveRole">
    <div class="confirm-modal-content">
      <div class="delete-role-message" [class]="viewMode">
        <i class="icon-attention"></i>
        <div>
          <div [innerHTML]="'confirmModal.deleteRoleMessage' | translate: { roleName: data.details }"></div>
          <span>{{ 'confirmModal.cannotBeUndone' | translate }}</span>
        </div>
      </div>

      <div class="modal-buttons-container">
        <button class="secondary-button" (click)="onDismiss()">
          {{ 'confirmModal.cancelButton' | translate }}
        </button>
        <button class="primary-button" data-cy-id="delete-role-button" (click)="onConfirm()">
          {{ 'confirmModal.deleteButton' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.UnassignUser">
    <div class="confirm-modal-header">
      <i class="fas fa-exclamation-triangle"></i>
      {{ 'confirmModal.areYouSure' | translate }}
    </div>
    <div class="confirm-modal-content">
      <div class="delete-role-message">
        <div>
          <div [innerHTML]="'confirmModal.unassignUserMessage' | translate: { userName: data.details }"></div>
        </div>
      </div>

      <div class="modal-buttons-container">
        <button data-cy-id="cancel-button" class="secondary-button" (click)="onDismiss()">
          {{ 'confirmModal.cancelButton' | translate }}
        </button>
        <button data-cy-id="unassign-button" class="primary-button" (click)="onConfirm()">
          {{ 'confirmModal.unassignButton' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.UnassignUnit">
    <div class="confirm-modal-content">
      <div class="delete-role-message" [class]="viewMode">
        <i class="icon-attention"></i>
        <div>
          <div [innerHTML]="'confirmModal.unassignUnitMessage' | translate: { unitName: data.details }"></div>
        </div>
      </div>

      <div class="modal-buttons-container">
        <button class="secondary-button" (click)="onDismiss()">
          {{ 'confirmModal.cancelButton' | translate }}
        </button>
        <button class="primary-button" (click)="onConfirm()">
          {{ 'confirmModal.unassignButton' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.RemoveUnit">
    <div class="confirm-modal-header">
      {{ 'confirmModal.removeUnitTitle' | translate }}
      <i class="fal fa-times" (click)="onDismiss()"></i>
    </div>
    <div class="confirm-modal-content remove-unit-modal">
      <div class="remove-unit-message" [class]="viewMode">
        <div>
          <div [innerHTML]="'confirmModal.removeUnitMessageOne' | translate"></div>
          <div
            class="second-part"
            [innerHTML]="'confirmModal.removeUnitMessageTwo' | translate: { unitName: data.details }"></div>
        </div>
      </div>

      <div class="modal-buttons-container">
        <button class="primary-button" (click)="onDismiss()">
          {{ 'confirmModal.cancelButton' | translate }}
        </button>
        <button class="secondary-button" (click)="onConfirm()">
          {{ 'remove' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.SoundAlertPopup">
    <div class="sound-alert-header bold-text" data-cy-id="sound-alert-header">
      <div class="sound-alert-bell" alt="sound-alert-bell"></div>

      <div
        [class.tablet-header]="isTabletMode"
        [innerHTML]="
          (isTabletMode ? 'confirmModal.sound_alert.header.tablet' : 'confirmModal.sound_alert.header.desktop')
            | translate
        "></div>
    </div>
    <div class="confirm-modal-content" data-cy-id="sound-modal-content">
      <div class="sound-alert-message" [class]="viewMode">
        <div class="tablet-message" *ngIf="isTabletMode">
          <div [innerHTML]="'confirmModal.sound_alert.tabletMessage' | translate"></div>
        </div>
        <div class="desktop-message" *ngIf="!isTabletMode">
          <div
            class="main-message"
            data-cy-id="sound-modal-main-message"
            [innerHTML]="'confirmModal.sound_alert.main_message' | translate"></div>
          <div
            class="secondary-message"
            data-cy-id="sound-modal-secondary-message"
            [innerHTML]="'confirmModal.sound_alert.secondary_message' | translate"></div>
          <a
            href="https://help.rekor.ai/how-do-i-enable-sound-alerts"
            target="_blank"
            class="heap-desktop-user-confirm-modal-sound">
            {{ 'confirmModal.sound_alert.secondary_message-link' | translate }}</a
          >
        </div>
      </div>
      <div class="sound-alert-button-container">
        <button class="primary-button" data-cy-id="sound-modal-ok-btn" (click)="soundAcknowledgement()">
          {{ 'confirmModal.sound_alert.button' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.DarkModePopup">
    <div class="dark-mode-container">
      <div class="far fa-times fa-lg dark-mode-close-btn" (click)="onDismiss()"></div>
      <div class="dark-mode-icon"></div>
      <div [innerHTML]="'confirmModal.darkMode.header' | translate" class="dark-mode-header bold-text"></div>
      <div class="dark-mode-content" [innerHTML]="'confirmModal.darkMode.mainMessage' | translate"></div>
      <button
        [innerHTML]="'confirmModal.darkMode.button' | translate"
        class="dark-mode-button primary-button"
        (click)="onDismiss()"></button>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.CompleteHasUnits">
    <div
      class="complete-has-units__container"
      [class]="viewMode"
      *ngIf="data.details === incidentStatusEnum.Completed ? 'complete' : 'reject' as status">
      <div class="complete-has-units__close-btn far fa-times fa-lg" (click)="onDismiss()"></div>
      <div
        [innerHTML]="'confirmModal.' + status + 'HasUnits.header' | translate"
        class="complete-has-units__header bold-text"
        [class]="viewMode"></div>
      <div
        class="complete-has-units__content"
        [class]="viewMode"
        [innerHTML]="'confirmModal.' + status + 'HasUnits.mainMessage' | translate"></div>
      <div class="complete-has-units__actions">
        <button
          [innerHTML]="'confirmModal.completeHasUnits.cancelButton' | translate"
          class="complete-has-units__button secondary-button"
          (click)="onDismiss()"></button>
        <button
          [innerHTML]="'confirmModal.' + status + 'HasUnits.actionButton' | translate"
          class="complete-has-units__button primary-button"
          (click)="onConfirm()"></button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.EditAboutToExpire">
    <div class="generic-modal-container">
      <div
        data-cy-id="about-to-expire-edit-modal-icon"
        class="generic-modal-container__icon hourglass-icon"
        alt="hourglass"></div>
      <div data-cy-id="about-to-expire-edit-modal-title" class="generic-modal-container__title">
        {{ 'editAboutToExpire' | translate }}
      </div>
      <div
        data-cy-id="about-to-expire-edit-modal-main-message"
        [innerHTML]="'expireTimeCount' | translate: { time: (timer$ | async) }"
        class="generic-modal-container__main-message"></div>
      <div class="generic-modal-container__button-container">
        <button data-cy-id="about-to-expire-edit-modal-ok-button" class="primary-button" (click)="onConfirm()">
          {{ 'confirmModal.sound_alert.button' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.EditTimeExpired">
    <div class="generic-modal-container">
      <div
        data-cy-id="time-expired-edit-modal-icon"
        class="generic-modal-container__icon hourglass-icon"
        alt="hourglass"></div>
      <div data-cy-id="time-expired-edit-modal-title" class="generic-modal-container__title">
        {{ 'editExpiredTitle' | translate }}
      </div>
      <div data-cy-id="time-expired-edit-modal-main-message" class="generic-modal-container__main-message">
        {{ 'editExpiredDescription' | translate }}
      </div>
      <div class="generic-modal-container__button-container">
        <button data-cy-id="time-expired-edit-modal-ok-button" class="primary-button" (click)="onConfirm()">
          {{ 'confirmModal.sound_alert.button' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.ResponsePlanNotDone">
    <div class="complete-has-units__container">
      <div class="complete-has-units__close-btn far fa-times fa-lg" (click)="onDismiss()"></div>
      <div
        [innerHTML]="
          data.details === entityTypeEnum.Incident
            ? ('completeIncident' | translate)
            : ('confirmModal.completeSpecialEvent' | translate)
        "
        class="complete-has-units__header bold-text"
        [class]="viewMode"></div>
      <div
        class="complete-has-units__content"
        [innerHTML]="
          data.details === entityTypeEnum.Incident
            ? ('confirmModal.completeWithoutUndoneActionsIncident' | translate)
            : ('confirmModal.completeWithUndoneActions' | translate)
        "></div>
      <div class="complete-has-units__actions">
        <button
          [innerHTML]="'confirmModal.completeHasUnits.cancelButton' | translate"
          class="complete-has-units__button secondary-button"
          (click)="onDismiss()"></button>
        <button
          [innerHTML]="'complete' | translate"
          class="complete-has-units__button primary-button"
          (click)="onConfirm()"></button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.IncidentMergedIntoAnotherIncident">
    <div class="incident-merged-into-another-incident__container" [class]="viewMode">
      <div
        [innerHTML]="'confirmModal.incidentMergedIntoAnotherIncident.header' | translate"
        class="incident-merged-into-another-incident__header bold-text"
        [class]="viewMode"></div>
      <div
        class="incident-merged-into-another-incident__content"
        [class]="viewMode"
        [innerHTML]="
          'confirmModal.incidentMergedIntoAnotherIncident.mainMessage' | translate: { mergedInto: data.details }
        "></div>
      <div class="incident-merged-into-another-incident__actions">
        <button
          [innerHTML]="'confirmModal.incidentMergedIntoAnotherIncident.backToListButton' | translate"
          class="incident-merged-into-another-incident__button secondary-button"
          (click)="onDismiss()"></button>
        <button
          [innerHTML]="'confirmModal.incidentMergedIntoAnotherIncident.viewMergedIncident' | translate"
          class="incident-merged-into-another-incident__button primary-button"
          (click)="onConfirm()"></button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.ResetChanges">
    <div class="generic-modal-container leave-without-save">
      <i data-cy-id="reset-changes-close-modal" class="fal fa-times" (click)="onDismiss()"></i>
      <div data-cy-id="reset-changes-title" class="generic-modal-container__title--left">
        {{ 'confirmModal.resetChanges?' | translate }}
      </div>
      <div data-cy-id="reset-changes-message" class="generic-modal-container__main-message">
        {{ 'changesWontBeSaved' | translate }}
      </div>
      <div class="generic-modal-container__button-container--right">
        <button data-cy-id="reset-changes-cancel-btn" class="secondary-button" (click)="onDismiss()">
          {{ 'confirmModal.cancelButton' | translate }}
        </button>
        <button data-cy-id="reset-changes-reset-btn" class="primary-button" (click)="onConfirm()">
          {{ 'reset' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.LeaveWithoutSaving">
    <div class="generic-modal-container leave-without-save">
      <i class="fal fa-times" (click)="onDismiss()"></i>
      <div class="generic-modal-container__title--left">
        {{ 'confirmModal.leaveWithoutSaving?' | translate }}
      </div>
      <div class="generic-modal-container__main-message">
        {{ 'changesWontBeSaved' | translate }}
      </div>
      <div class="generic-modal-container__button-container--right">
        <button class="secondary-button" (click)="onDismiss()">
          {{ 'stay' | translate }}
        </button>
        <button class="primary-button" (click)="onConfirm()">
          {{ 'leave' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.ShiftHistoricalRelatedIncidents">
    <div class="generic-modal-container shift-related-incidents-confirmation">
      <i class="fal fa-times" (click)="onDismiss()"></i>
      <div class="generic-modal-container__title--left">
        {{ 'confirmModal.shiftRelatedIncidentsIdentifiedTitle' | translate }}
      </div>

      <div class="generic-modal-container__related-incidents-message">
        <div class="generic-modal-container__message--left">
          {{ 'confirmModal.shiftRelatedIncidentsFirstParagraph' | translate }}
        </div>
        <div class="generic-modal-container__message--left">
          {{ 'confirmModal.shiftRelatedIncidentsSecondParagraph' | translate }}
        </div>
      </div>

      <div class="generic-modal-container__button-container--right">
        <button class="primary-button" (click)="onConfirm()">
          {{ 'ok' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.LeaveEditMode">
    <div class="generic-modal-container">
      <i class="fal fa-times" (click)="onDismiss()"></i>
      <div class="generic-modal-container__title--left">
        {{ 'confirmModal.leaveEditMode.title' | translate }}
      </div>

      <div class="generic-modal-container__leave-edit-mode">
        <div class="generic-modal-container__message--left" data-cy-id="leave-edit-mode-modal">
          {{ 'confirmModal.leaveEditMode.content' | translate }}
        </div>
      </div>

      <div class="generic-modal-container__button-container--right">
        <button class="secondary-button" data-cy-id="stay-button" (click)="onDismiss()">
          {{ 'stay' | translate }}
        </button>
        <button class="primary-button" (click)="onConfirm()">
          {{ 'leave' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.WrongWayAlert">
    <div class="generic-modal-container wrong-way-alert">
      <i class="fal fa-times" (click)="onDismiss()"></i>

      <div class="wrong-way-alert-icon"></div>

      <div class="generic-modal-container__title">
        {{ 'confirmModal.wrongWayAlert.title' | translate }}
      </div>

      <div class="generic-modal-container__message" data-cy-id="leave-edit-mode-modal">
        {{ 'confirmModal.wrongWayAlert.content' | translate }}
      </div>

      <button class="primary-button wrong-way-alert-button" (click)="onConfirm()">
        {{ 'confirmModal.wrongWayAlert.showDetails' | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="confirmationModalTypes.OnboardingTours">
    <div class="onboarding-tours__container">
      <button (click)="onDismiss()">
        <wc-icon class="onboarding-tours__close-button" iconName="close-new"></wc-icon>
      </button>
      <div class="onboarding-tours-dialog-icon"></div>
      <div class="onboarding-tours__content-wrapper">
        <div class="onboarding-tours__content">
          <div class="onboarding-tours__title">
            {{ 'onboardingTours.dialog.title' | translate }}
          </div>
          <div class="onboarding-tours__text">
            {{ 'onboardingTours.dialog.text' | translate }}
          </div>
        </div>
        <button class="onboarding-tours__button" (click)="onConfirm()">
          {{ 'onboardingTours.dialog.button' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
