import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MapDrawComponent } from './map-draw.component';
import { WcMapModule } from '@wc/wc-map/src';
import { MapDrawingModalModule } from '../../components/map-drawing-modal/map-drawing-modal.module';

const Materials: any[] = [MatCheckboxModule];

@NgModule({
  declarations: [MapDrawComponent],
  imports: [CommonModule, WcBaseControlFieldModule, WcMapModule, MapDrawingModalModule],
  providers: [],
  exports: [MapDrawComponent],
})
export class MapDrawModule {}
