import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationDestination, SharePublicResponseUpdate } from '@wc/core';
import { WebSocketType } from '@wc/core/models/enums';
import { ToastrAlertsService } from '@wc/wc-ui/src/services/toaster-alert.service';
import { MonoTypeOperatorFunction, noop, pipe, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { WebsocketService } from './websocketService';

//https://developer.twitter.com/ja/docs/basics/response-codes
const twitterStatusError: Record<string, string> = {
  401: 'unauthorized',
  403: 'tweetLimitExceeded',
};

const twitterErrorCode: Record<string, string> = {
  185: 'passedDailyLimit',
  403: 'tweet',
};

@Injectable({
  providedIn: 'root',
})
export class SharePublicService {
  constructor(
    private translateService: TranslateService,
    private alertService: ToastrAlertsService,
    private wsService: WebsocketService
  ) {
    this.wsService.connect();
    this.wsService.subject.subscribe(res => {
      if ((res as SharePublicResponseUpdate).type === WebSocketType.sharePublicResponse) {
        const update = res as SharePublicResponseUpdate;
        this.showWSPublishErrorToast(update);
      }
    });
  }

  showWSPublishErrorToast(shareUpdate: SharePublicResponseUpdate) {
    const destinationTranslated = this.translateService.instant(
      'publishChannels.' + shareUpdate.response.destination
    ) as string;
    if (shareUpdate.response.statusCode === 200) {
      this.alertService.success(
        this.translateService.instant(`notifications.publish.shareSuccessToPlatforms`, {
          destinations: [destinationTranslated],
        })
      );
    } else if (shareUpdate.response.destination === NotificationDestination.GovDelivery) {
      this.alertService.error(
        destinationTranslated + ' ' + this.translateService.instant(`notifications.publish.shareFailed`)
      );
    } else {
      let error = twitterStatusError[shareUpdate.response.statusCode]
        ? twitterStatusError[shareUpdate.response.statusCode]
        : 'otherError';
      error = twitterErrorCode[shareUpdate.response.errorCode]
        ? twitterErrorCode[shareUpdate.response.errorCode]
        : error;
      const toast = `
      <div>${this.translateService.instant('twitterCode.' + error)}    
      <a href="https://developer.twitter.com/en/docs/twitter-api/getting-started/about-twitter-api" target="_blank">${this.translateService.instant(
        'policy'
      )}</a>
      </div>
    `;
      this.alertService.error(
        toast,
        undefined,
        {
          enableHtml: true,
          disableTimeOut: true,
          tapToDismiss: false,
        },
        true
      );
    }
  }

  publishEventOperatorFunction(
    publishChannels: NotificationDestination[]
  ): MonoTypeOperatorFunction<boolean | undefined> {
    const destinations = publishChannels
      .filter(p => p !== NotificationDestination.Twitter && p !== NotificationDestination.GovDelivery)
      .map(p => this.translateService.instant('publishChannels.' + p))
      .join(', ');

    if (destinations.length === 0) return tap(noop);

    return pipe(
      tap(() => {
        this.alertService.success(
          this.translateService.instant(`notifications.publish.shareSuccessToPlatforms`, {
            destinations: destinations,
          })
        );
      }),
      catchError(e => {
        this.alertService.error(
          this.translateService.instant(`notifications.publish.shareFailed`, {
            destinations: destinations,
          })
        );
        return throwError(e);
      })
    );
  }
}
