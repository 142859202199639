/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormArray, FormGroupDirective } from '@angular/forms';
import { HeapAnalyticsService } from '@wc-core';
import { ResponsePlanActionField, ResponsePlanEntityType, UiStore } from '@wc/core';
import { scrollElementIntoView, scrollToInvalidPanel } from '@wc/wc-common/src';
import { ResponsePlanService } from '@wc/wc-core/src/lib/services/response-plan-service';
import { UiMode } from '@wc/wc-models';
@Component({
  selector: 'wc-response-plan-form',
  templateUrl: './response-plan-form.component.html',
  styleUrls: ['./response-plan-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponsePlanFormComponent implements OnDestroy {
  @ViewChild('scrollableContainer', { read: ElementRef }) scrollableContainer?: ElementRef;
  @Input() regionalSetting;
  @Input() entityType!: ResponsePlanEntityType;
  @Input() scrollableContainerId?;
  @Input() isEditMode = false;
  @Input() set uiMode(mode: UiMode) {
    this._uiMode = mode;
    this._uiMode === UiMode.Entity
      ? this.responsePlanService.setFloatLabelToFieldData('always')
      : this.responsePlanService.setFloatLabelToFieldData('never');
  }
  _uiMode: UiMode = UiMode.Template;
  uiModeEnum = UiMode;
  selectedAction? = -1;
  newAddedRowIndex = -1;
  load = false;
  actionSub$;
  constructor(
    public parentFormGroupDirective: FormGroupDirective,
    public responsePlanService: ResponsePlanService,
    private uiStore: UiStore,
    private heapService: HeapAnalyticsService
  ) {
    this.actionSub$ = this.responsePlanService.addActionIndex$.subscribe((index: number) => {
      setTimeout(() => {
        scrollElementIntoView(
          'rp-action-item' + Number(index),
          this.scrollableContainerId ? this.scrollableContainerId : 'rp-table-container'
        );
      }, 100);
      this.newAddedRowIndex = index;
    });
  }

  get minInput() {
    return this.responsePlanService.minStepperInput;
  }

  get maxInput() {
    return this.responsePlanService.maxStepperInput;
  }

  get maxActionCount() {
    return this.responsePlanService.maxStepperInput;
  }

  get actions() {
    return this.parentFormGroupDirective.form?.get('actions') as FormArray;
  }

  get actionType() {
    return ResponsePlanActionField;
  }

  clickOnItemOption(itemIndex: number) {
    this.selectedAction === itemIndex ? (this.selectedAction = -1) : (this.selectedAction = itemIndex);
  }

  addRow(index) {
    this.responsePlanService.addActionFormControl(undefined, index, this._uiMode === UiMode.Entity);
    setTimeout(() => {
      scrollElementIntoView(
        'rp-action-item' + Number(index),
        this.scrollableContainerId ? this.scrollableContainerId : 'rp-table-container'
      );
    }, 100);

    this.heapService.trackUserSpecificAction(
      `heap-desktop-${this.entityType === ResponsePlanEntityType.SpecialEvent ? 'se' : 'incident'}-response-plan-${
        this._uiMode === UiMode.Template ? 'template' : 'entity'
      }-add-row`
    );

    this.newAddedRowIndex = index;
    this.selectedAction = -1;
  }

  removeRow(index) {
    (this.parentFormGroupDirective.form?.get('actions') as FormArray).removeAt(index);
    this.newAddedRowIndex = -1;
    this.selectedAction = -1;
    this.heapService.trackUserSpecificAction(
      `heap-desktop-${this.entityType === ResponsePlanEntityType.SpecialEvent ? 'se' : 'incident'}-response-plan-${
        this._uiMode === UiMode.Template ? 'template' : 'entity'
      }-remove-row`
    );
  }

  scrollIntoInvalidPanel() {
    scrollToInvalidPanel(this.scrollableContainer, 10);
  }

  ngOnDestroy(): void {
    this.actionSub$.unsubscribe();
  }
}
