import { Injectable } from '@angular/core';
import { PermissionsFacadeService } from '@wc/permissions/domain/src';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { ScopeAccessModifier } from '@wc/wc-models/src';
import { getCenterOfBounds } from 'geolib';
import { intersection as _intersection, union, union as _union } from 'lodash';
import { action, computed, makeObservable, observable, observe, toJS } from 'mobx';
import { Coordinate } from 'ol/coordinate';
import { BehaviorSubject, interval } from 'rxjs';
import { retry, take } from 'rxjs/operators';
import { WcMapTabletConfig } from '../../core/tablet.livemap.config'; // '@wc/core/tablet.livemap.config';
import { WcMapComponent } from '../../wc-map/src/lib/wc-map.component';
import { MapLayersTreeMenu, WcMapConfig } from '../livemap.config';
import {
  Account,
  DataLayerName,
  FeatureType,
  Incident,
  LineString,
  LiveMapEntity,
  LiveMapEntityType,
  MainEntityType,
  MapCenterOptions,
  MapLayerTreeMenu,
  Point,
  Polygon,
  RoadEventsFilters,
  TrafficDisruptionStatus,
  WcMapConfigModel,
} from '../models';
import { LocalStorageService } from '../services/local-storage.service';
import { Position } from '../services/location.service';
import { AppFeatureEnum } from '../services/split-io.service';
import { AccountStore } from './account.store';
import { EntitiesStore } from './entities.store';
import { UiStore } from './ui.store';
import { UsersStore } from './users.store';

@Injectable({
  providedIn: 'root',
})
export class LiveMapStore {
  @observable currentCenter!: {
    coordinates: Array<Array<Array<number>>>;
    options: MapCenterOptions | undefined;
  };
  @observable lastCenter!: {
    coordinates: Array<Array<Array<number>>>;
    options: MapCenterOptions | undefined;
  };
  @observable doCenterMap!: boolean;
  @observable layersVisibility: LiveMapEntityType[] = ['unidentified', 'traffic_anomaly'];
  @observable roadEventsFilters!: RoadEventsFilters;
  @observable selectedWorkspacesIds: number[] = [];
  @observable mapTabletRightClickLocation: Position | undefined = undefined;
  @observable currentViewedEntity: { type: LiveMapEntityType; id: number } | undefined = undefined;

  wcMap!: WcMapComponent;
  wcMapConfig: WcMapConfigModel = WcMapConfig;
  mapReadyInterval: any;

  @observable openedLayer = 'event';

  trafficDisruptionStatusFilter!: TrafficDisruptionStatus | null;
  isNearCamerasActiveInAllSelectedWorkspace = false;

  filterByWorkspace(entity): boolean {
    if (entity.featureSubTypeOf === MainEntityType.unit || entity.featureSubTypeOf === MainEntityType.interactive)
      return true;
    if (entity.workspaces && entity.workspaces.length > 0) {
      const matchingWorkspace = _intersection(entity.workspaces, this.selectedWorkspacesIds);
      if (matchingWorkspace.length === 0) {
        return false;
      }
    }

    if (entity.featureSubTypeOf == MainEntityType.incident) {
      return this.isNearCamerasActiveInAllSelectedWorkspace ? entity.nearCameras || false : true;
    } else {
      return true;
    }
  }

  filters: { (entity?: LiveMapEntity): boolean } = entity => {
    // return true;
    let valid: boolean = true;
    if (!entity) return false;

    valid = this.filterByWorkspace(entity);

    if (valid)
      valid =
        !this.trafficDisruptionStatusFilter ||
        !(
          (entity?.featureSubTypeOf === 'road_closure' || entity?.featureSubTypeOf === 'construction') &&
          entity.status.toLowerCase() !== this.trafficDisruptionStatusFilter.toLocaleLowerCase()
        );

    if (valid && this.roadEventsFilters.mitigatedByMyAccount && entity.mitigatedAccounts) {
      // Filter for mitigatedNotByMyAccount.
      valid = !entity.mitigatedByMyAccount;
    }

    if (valid && entity.associatedUnitsUsersIds && entity.featureSubTypeOf === MainEntityType.incident) {
      if (valid && this.roadEventsFilters.assignedToMe)
        valid = entity.associatedUnitsUsersIds.indexOf(this.usersStore.authUserID) > -1;
    }

    return valid;
  };

  wcMapServiceInit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  jurisdictionUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  liveMapPoistionUpdate$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  staticVisibleLayers: LiveMapEntityType[] = ['unidentified', 'traffic_anomaly'];

  constructor(
    private entitiesStore: EntitiesStore,
    private localStorageService: LocalStorageService,
    private uiStore: UiStore,
    private usersStore: UsersStore,
    private accountStore: AccountStore,
    private permissionsFacadeService: PermissionsFacadeService
  ) {
    makeObservable(this);
    let layersVisibility = this.localStorageService.get(LocalStorageKeys.LayersVisibility);
    if (layersVisibility) {
      this.layersVisibility = union(this.layersVisibility, this.staticVisibleLayers);
      // TODO: remove the crash_risk_area filter after we are
      this.layersVisibility = layersVisibility.filter(
        (layer: string) => layer !== 'active_crash_risk' && layer !== 'predicted_crash_risk'
      );
    } else {
      layersVisibility = this.wcMapConfig.defaultLayersVisibility;
    }
    this.updateLayersVisibility(layersVisibility);
    observe(this.entitiesStore, change => {
      // console.log(change)
      const entitiesDiff = this.entitiesStore.entitiesDiff;
      if (entitiesDiff) {
        if (entitiesDiff.refreshAll) {
          this.updateModifiedEntities(this.liveEntities, true);
        } else {
          this.updateModifiedEntities(entitiesDiff.diff.modified);
          this.updateRemovedEntities(entitiesDiff.diff.removed);
        }
      }
    });

    observe(this.uiStore, e => {
      if (e.name === 'incidentDesktopSidebarOpen') {
        const seconds = interval(150);
        const anim = seconds.pipe(take(7)).subscribe(() => {
          this.refreshMap();
        });
      }

      if (e.name === 'featureToggles') {
        const keys = Object.keys(AppFeatureEnum);
        keys.forEach(key => {
          this.checkFeatureToggleLayerVisibility(AppFeatureEnum[key]);
        });
      }
    });
  }

  checkFeatureToggleLayerVisibility(appFeatureEnum: AppFeatureEnum) {
    const visibleLayers = JSON.parse(JSON.stringify(this.layersVisibility || []));
    if (!this.uiStore.isActiveFeature(appFeatureEnum)) {
      const visLayerIndex = visibleLayers.findIndex(layer => layer === appFeatureEnum);
      if (visLayerIndex > -1) {
        this.changeLayersVisibility(false, [appFeatureEnum as unknown as LiveMapEntityType]);
      }
    }
  }

  setWCMapConfig() {
    if (this.uiStore.isTabletMode) {
      this.wcMapConfig = WcMapTabletConfig();
      this.wcMapConfig.defaultIconStyle.scale = this.wcMapConfig.tablet.iconScale;
    }

    this.wcMapConfig.isTabletMode = this.uiStore.isTabletMode;
  }

  initMap(wcMap: WcMapComponent, centerFromUrl?: string | null) {
    this.setWCMapConfig();
    this.wcMap = wcMap;
    this.wcMap.init(this.wcMapConfig, {
      mouseEventsMode: 'liveMap',
      centerFromUrl,
      showWorkspaces: true,
    });

    this.wcMap.mapCenterMoved.subscribe(center => {
      this.lastCenter = {
        coordinates: center.coordinates,
        options: { zoomLevel: center.options?.zoomLevel },
      };
      this.setCurrentCenter(center.coordinates, {
        zoomLevel: center.options?.zoomLevel,
      });
    });

    if (centerFromUrl) {
      this.wcMap.setCenterFromUrlFragment(centerFromUrl);
    } else {
      this.applyCurrentMapCenter(this.wcMapConfig.mapCenter);
    }
    this.lastCenter = { ...{}, ...this.currentCenter };
    this.updateModifiedEntities(this.liveEntities);

    setTimeout(() => {
      this.wcMap.map.updateSize();
    });

    this.setSelectedWorkspacesFromCache();
    this.updateLayersVisibility(this.visibleLayers);
    this.wcMapServiceInit$.next(true);
  }

  setSelectedWorkspacesFromCache() {
    const selectedWorkspaceIds =
      this.localStorageService.get(LocalStorageKeys.SelectedWorkspacesIds) ||
      this.wcMapConfig.workspaces?.map(workspace => workspace.id);
    this.setSelectedWorkspaces(selectedWorkspaceIds);
  }

  @action
  setSelectedWorkspaces(workspacesIds: number[], override: boolean = true) {
    this.selectedWorkspacesIds = override
      ? [...[], ...workspacesIds]
      : _union(this.selectedWorkspacesIds, workspacesIds);
    this.localStorageService.set(LocalStorageKeys.SelectedWorkspacesIds, this.selectedWorkspacesIds);
    this.wcMap?.setWorkspacesVisibility(this.selectedWorkspacesIds);
    this.isNearCamerasActiveInAllSelectedWorkspace = this.checkNearCamerasActiveInAllSelectedWorkspace(
      this.selectedWorkspacesIds
    );
    this.refilterMap();
  }

  checkNearCamerasActiveInAllSelectedWorkspace(selectedWorkspacesIds: number[]) {
    return this.accountStore.account.workspaces.workspaces.every(workspace => {
      if (selectedWorkspacesIds.includes(workspace.id)) {
        return workspace.featureTypes.includes(FeatureType.FilterNearCameras);
      } else {
        return true;
      }
    });
  }

  updateTrafficDisruptionStatusFilter(value: TrafficDisruptionStatus | null) {
    this.trafficDisruptionStatusFilter = value;
    this.refilterMap();
  }

  refilterMap() {
    this.updateModifiedEntities(this.liveEntities, true);
  }

  @action
  refreshMap() {
    this.wcMap.map.updateSize();
  }

  @action
  updateLayersVisibility(layersNames: LiveMapEntityType[] | any[]) {
    this.layersVisibility = union(layersNames, ['unidentified', 'traffic_anomaly']);
    this.localStorageService.set(LocalStorageKeys.LayersVisibility, this.layersVisibility);
    if (this.wcMap) {
      this.wcMap.wcMapService.updateLayersVisibility(layersNames);
    }
  }

  @action
  changeLayersVisibility(checked: boolean, layerNames: LiveMapEntityType[]) {
    layerNames.forEach(layerName => {
      const index = this.visibleLayers.findIndex(name => name === layerName);

      if (checked) {
        if (index === -1) {
          this.visibleLayers.push(layerName as LiveMapEntityType);
        }
      } else if (index !== -1) {
        this.visibleLayers.splice(index, 1);
      }
    });
    this.updateLayersVisibility(this.visibleLayers);
  }

  toggleDataLayer(layerName: DataLayerName) {
    if (this.wcMap) {
      this.wcMap.wcMapService.toggleLayerVisibility(layerName);
    }
  }

  @action changeOpenedLayerDisplay(changedLayer) {
    if (this.openedLayer !== changedLayer) {
      this.openedLayer = changedLayer;
    } else {
      this.openedLayer = 'none';
    }
  }

  @computed
  get currentLayerDisplay() {
    return this.openedLayer;
  }

  @computed
  get mapLayersTreeMenu(): MapLayerTreeMenu[] {
    const keys = Object.keys(AppFeatureEnum);
    const layersTreeMenu = JSON.parse(JSON.stringify(MapLayersTreeMenu));
    const tdLayers: string[] = [];

    layersTreeMenu.forEach((typesGroups, typeGroupIndex) => {
      typesGroups.layersGroups.forEach((layerGroup, layerIndex) => {
        layersTreeMenu[typeGroupIndex].layersGroups = layersTreeMenu[typeGroupIndex].layersGroups.filter(
          ({ appFeatureName }) => !appFeatureName || this.permissionsFacadeService.hasPermission(appFeatureName, 'OR')
        );

        keys.forEach(key => {
          if (layerGroup.name === AppFeatureEnum[key] && !this.uiStore.isActiveFeature(AppFeatureEnum[key])) {
            layersTreeMenu[typeGroupIndex].layersGroups = layersTreeMenu[typeGroupIndex].layersGroups.filter(
              group => group.name !== layerGroup.name
            );
          }
        });

        if (layersTreeMenu[typeGroupIndex].layersGroups[layerIndex]) {
          const layers = layersTreeMenu[typeGroupIndex].layersGroups[layerIndex]?.layers;
          layers?.forEach((layer, index) => {
            if (
              layer === AppFeatureEnum[layer.toUpperCase()] &&
              !this.uiStore.isActiveFeature(AppFeatureEnum[layer.toUpperCase()])
            ) {
              layers.splice(index, 1);
            }

            if (['special_event', 'road_closure', 'construction'].includes(layer)) {
              if (this.permissionsFacadeService.hasPermission((layer.toUpperCase() + ':READ') as ScopeAccessModifier))
                tdLayers.push(layer);
            }
          });
        }
      });
      layersTreeMenu[typeGroupIndex].layersGroups.forEach((group, index) => {
        if (group.name === 'traffic_disruption') {
          group.layers = tdLayers;
          layersTreeMenu[typeGroupIndex].layersGroups[index].layers = tdLayers;
        }
      });
    });
    return layersTreeMenu;
  }

  @computed
  get visibleLayers(): LiveMapEntityType[] {
    const visibleLayers = this.layersVisibility || [];
    return visibleLayers;
  }

  @action
  unselectFeature(featureSubTypeOf, id?) {
    if (!this.wcMap) return;
    this.currentViewedEntity = undefined;
    this.wcMap.unselectFeature(featureSubTypeOf, id);
  }

  @computed
  get liveEntities(): { [layerName in LiveMapEntityType]?: { LiveMapEntity } } {
    const entities = toJS(this.entitiesStore.entities) as {
      [layerName in LiveMapEntityType]?: { LiveMapEntity };
    };
    return entities;
  }

  updateMapConfig(account: Account) {
    this.wcMapConfig = WcMapConfig;
    this.wcMapConfig.mapCenter = [account.mapCenter.coordinates];
    this.wcMapConfig.workspaces = account.workspaces.workspaces;
    this.liveMapPoistionUpdate$.next(this.accountLocationCenter());
    this.jurisdictionUpdated$.next(true);
  }

  accountLocationCenter(): number[] {
    const coordinates: { latitude: number; longitude: number }[] = [];
    this.wcMapConfig.mapCenter[0][0].forEach(cord => {
      coordinates.push({ latitude: cord[0], longitude: cord[1] });
    });
    const res = getCenterOfBounds(coordinates);
    return [res.latitude, res.longitude];
  }

  updateModifiedEntities(
    layers: {
      [layerName in LiveMapEntityType]?: { [id in number]: LiveMapEntity };
    },
    refreshAll?: boolean
  ) {
    if (!this.wcMap) return;
    const { transit_demand_response_unit, transit_on_demand_unit, transit_fixed_route_bus_unit, ..._layers } = layers;
    this.wcMap.wcMapService.updateLayers(
      _layers as {
        [layerName in LiveMapEntityType]?: { [id in number]: LiveMapEntity };
      },
      refreshAll,
      this.filters
    );
  }

  updateRemovedEntities(layers: {
    [layerName in LiveMapEntityType]?: [number];
  }) {
    if (this.wcMap) {
      this.wcMap.wcMapService.removeEntities(layers);
    }
    this.entitiesStore.removedEntities(layers);
  }

  applyCurrentMapCenter(coordinates: Coordinate[][], options?: MapCenterOptions) {
    if (!this.wcMap) return;
    options = options || {};

    this.wcMap.setMapLocation(coordinates, options);
    if (!options || !options.dontKeepLast) {
      this.setCurrentCenter(coordinates, options);
    } else {
      this.lastCenter = { ...{}, ...this.currentCenter };
    }
  }

  setCurrentCenter(coordinates: Array<Array<Array<number>>>, options?: MapCenterOptions) {
    if (options && !options.bufferSize) options.bufferSize = 6000;
    this.lastCenter = { ...{}, ...this.currentCenter };
    this.currentCenter = {
      coordinates: coordinates,
      options: options,
    };
  }

  @action
  zoomOnIncident(incident: Incident) {
    const padding = this.mapPadding();
    const camerasCoordinates = [];
    if (camerasCoordinates && incident.location) {
      const coords = [incident.location.coordinates[0], incident.location.coordinates[1]];
      this.wcMap?.moveMapCenterToCoordinatesWithOffset(coords);
    }
  }

  @action
  zoomOnEntity(entity, _zoom?) {
    const padding = this.mapPadding();
    console.log(entity.location.type);
    let coords;
    const options = {
      duration: 1000,
      offsetY: 0,
      offsetX: 0,
      zoom: undefined,
    };
    options.zoom = _zoom;
    switch (entity.location.type) {
      case 'Point':
        coords = [entity.location.coordinates[0], entity.location.coordinates[1]];
        break;
      case 'LineString':
        coords = [entity.location.coordinates[0][0], entity.location.coordinates[0][1]];
        break;
      case 'MultiLineString':
      case 'Polygon':
        this.applyCurrentMapCenter([entity.location.coordinates], {
          dontKeepLast: false,
          bufferSize: 1000,
          duration: 1000,
          padding,
        });
        coords = [entity.location.coordinates[0][0][0], entity.location.coordinates[0][0][1]];
        return;
      case 'MultiPolygon':
        this.applyCurrentMapCenter(entity.location.coordinates, {
          dontKeepLast: false,
          bufferSize: 1000,
          duration: 1000,
          padding,
        });
        coords = [entity.location.coordinates[0][0][0][0], entity.location.coordinates[0][0][0][1]];
        return;
    }
    this.wcMap?.moveMapCenterToCoordinatesWithOffset(coords, options);
  }

  applyLastMapCenter() {
    if (!this.lastCenter) return;
    const padding = this.mapPadding();
    const bufferSize = this.lastCenter.options ? this.lastCenter.options.bufferSize : undefined;
    const zoomLevel = this.lastCenter.options ? this.lastCenter.options.zoomLevel : undefined;
    this.applyCurrentMapCenter(this.lastCenter.coordinates, {
      bufferSize: bufferSize,
      zoomLevel: zoomLevel,
      duration: 1000,
      padding,
    });
  }

  entitiesInArea(coord: Array<number>, distance: number): { [layerName in LiveMapEntityType]?: [LiveMapEntity] } {
    if (!this.wcMap || !coord) return {};
    return this.wcMap.geoCalc.entitiesInArea(coord, distance);
  }

  addInteractiveEntity(entity) {
    const liveMapEntity: LiveMapEntity = {
      id: 0,
      location: toJS(entity.location),
      status: entity.status,
      featureType: 'interactive',
      featureSubTypeOf: MainEntityType.interactive,
    };

    const seconds = interval(500);
    const mapRetry = seconds.pipe(retry(10)).subscribe(
      () => {
        if (this.wcMap) {
          this.updateModifiedEntities({ interactive: { 0: liveMapEntity } });
          if (this.wcMap?.hoverEntityHandler) this.wcMap.hoverEntityHandler.active = false;
          if (this.wcMap?.selectEntityHandler) this.wcMap.selectEntityHandler.active = false;

          mapRetry.unsubscribe();
        }
      },
      err => console.log(err)
    );
  }

  removeInteractiveEntity() {
    this.updateRemovedEntities({ interactive: [0] });
    this.allLayersVisibilityDetectionMode(false);
    if (this.wcMap?.hoverEntityHandler) this.wcMap.hoverEntityHandler.active = true;
    if (this.wcMap?.selectEntityHandler) this.wcMap.selectEntityHandler.active = true;
  }

  startMapDraw(type: 'Point' | 'LineString' | 'Polygon', location?: Point | Polygon | LineString) {
    console.log(type, location);
    this.clearMapDraw();
    this.wcMap.setMouseEvent('draw');
    if (location) this.wcMap.wcMapDrawService.create(type, location);
  }

  mapDrawChanged() {
    return this.wcMap.wcMapDrawService.geometry;
  }

  clearMapDraw() {
    this.wcMap.wcMapDrawService.resetLayer();
    this.wcMap.wcMapDrawService.stopDraw();
    this.wcMap.setMouseEvent('liveMap');
  }

  allLayersVisibilityDetectionMode(vis: boolean) {
    if (this.wcMap) this.wcMap.wcMapService.allLayersVisibilityDetectionMode(vis);
  }

  mapPadding() {
    return [0, 0, 0, 0];
  }

  unselectAllFeaturesInLayer(featureSubTypeOf, id?) {
    if (!this.wcMap) return;
    this.wcMap.unselectAllFeaturesInLayer(featureSubTypeOf, id);
  }

  @action
  selectEntityFeature(entity) {
    if (!this.wcMap) return;
    this.currentViewedEntity = { type: entity.featureType, id: entity.id };

    this.wcMap.selectFeature(entity);
  }

  editEntityFeature(entity, featureType?) {
    if (!this.wcMap) return;
    const entityType = featureType?.toLowerCase() || entity.type.toLowerCase();
    if (entityType && entity.id) {
      const entityFeature = this.entitiesStore.getEntityByLayer(entityType, entity.id);
      if (entityFeature) {
        this.wcMap.editFeature(entityFeature, featureType);
      }
    }
  }

  stopEditEntityFeature(entity, featureType?) {
    if (!this.wcMap) return;
    const entityType = featureType || entity.type;
    if (entityType && entity.id) {
      const entityFeature = this.entitiesStore.getEntityByLayer(entityType, entity.id);
      if (entityFeature) {
        this.wcMap.stopEditFeature(entityFeature);
      }
    }
  }
}
