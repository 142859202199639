import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { TranslateModule } from '@ngx-translate/core';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { TreeNavigationComponent } from './tree-navigation.component';

@NgModule({
  declarations: [TreeNavigationComponent],
  imports: [CommonModule, WcIconModule, MatExpansionModule, TranslateModule, MatIconModule, MatTreeModule],
  exports: [TreeNavigationComponent],
})
export class WcTreeNavigationComponentModule {}
