import { Router } from '@angular/router';
import { LocalStorageService, TourService } from '@wc-core';
import { ScopeAccessModifier } from '@wc/wc-models/src';

type StepAction = () => void;

export interface PartialStepButton {
  classes: string;
  type: string;
  text?: string;
  action?: StepAction;
}

export interface StepButton extends PartialStepButton {
  text: string;
  action: StepAction;
}

export type DefaultStepOptions = Record<string, unknown>;

export type Step = {
  id: string;
  containerElement?: `.${string}` | `#${string}`;
  attachTo: {
    element: `.${string}`;
    on: 'top' | 'right' | 'bottom' | 'left' | 'right-start' | 'bottom-end' | 'bottom-start' | 'left-start' | 'left-end';
  };
  excludeByElements: string[];
  includeByElements: string[];
  buttons?: PartialStepButton[];
  classes?: string;
  title?: string;
  text?: string;
  titleTranslationKey: string;
  textTranslationKey?: string;
  stepDelay?: number;
  scrollTo?: any;
  showOn?: () => boolean;
  beforeShowPromise?: (val: any) => Promise<void>;
  rerouteOnNext?: `/${string}`;
  clickElNext?: `.${string}` | `#${string}`;
  clickElPrev?: `.${string}` | `#${string}`;
  willBeVisible?: boolean;
  permission?: ScopeAccessModifier;
};

export interface Tour {
  name: TourName | OnboardingTourName;
  steps: Step[];
  endDate?: string;
  preTourInitFunction?: PreTourFunctionType;
  availableButtons: {
    next: boolean;
    back: boolean;
    final: boolean;
    cancel: boolean;
    nextTour: boolean;
  };
}

type PreTourFunctionInputType = {
  tourService: TourService;
  router: Router;
  localStorageService: LocalStorageService;
};

export type PreTourFunctionType = (preTourFunctionInput: PreTourFunctionInputType) => Promise<boolean>;

export enum TourName {
  IncidentInsights = 'incidentInsights',
  IncidentResponsePlanView = 'incidentResponsePlanView',
  IncidentResponsePlan = 'incidentResponsePlan',
  IncidentNewFields = 'IncidentNewFields',
  WidgetNewUserTour = 'widgetNewUserTour',
  CompletedOnboardingTour = 'completedOnboardingTour',
  TrafficDisruptionNewScheduler = 'trafficDisruptionNewScheduler',
  IncidentNeedActionPrioritySort = 'incidentNeedActionPrioritySort',
  PriorityInsight = 'priorityInsight',
}

export enum OnboardingTourName {
  LiveMapTour = 'LIVE_MAP_TOUR',
  LayersPanelTour = 'LAYERS_PANEL_TOUR',
  DataHubTour = 'DATA_HUB_TOUR',
  TransitTour = 'TRANSIT_TOUR',
  SettingsTour = 'SETTINGS_TOUR',
}

export const onboardingToursFeedbackMap = {
  [OnboardingTourName.LiveMapTour]: 'livemap',
  [OnboardingTourName.LayersPanelTour]: 'layers',
  [OnboardingTourName.DataHubTour]: 'datahub',
  [OnboardingTourName.TransitTour]: 'transit',
  [OnboardingTourName.SettingsTour]: 'settings',
} as const;

export const allOnboardingTourNames = Object.values(OnboardingTourName) as (TourName | OnboardingTourName)[];

export interface PublicTourApi {
  beginTour: (tourName: TourName) => void;
}
