/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { EntityActions, EntityUIQuery, QueryEntity } from '@datorama/akita';
import {
  CrashRiskListByTimeFrames,
  CrashRiskStoreEntity,
  CrashRiskStoreUIEntity,
  ModifiedEntities,
  RemovedEntities,
} from '@wc/wc-models';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LayerType } from '../../../../../../core/models/gql.models';
import { CrashRisksState, CrashRisksStore, CrashRisksUIState } from './crash_risk.store';

@Injectable({ providedIn: 'root' })
export class CrashRisksQuery extends QueryEntity<CrashRisksState> {
  private layerTypeName = LayerType.CrashRiskArea;
  ui!: EntityUIQuery<CrashRisksUIState>;
  constructor(CrashRisksStore: CrashRisksStore) {
    super(CrashRisksStore);
    this.createUIQuery();
  }

  get modifiedCrashRisks$(): Observable<ModifiedEntities> {
    return this.selectEntityAction([EntityActions.Update, EntityActions.Add]).pipe(
      map(({ ids }) => ids.map(id => this.getEntity(id)).filter(e => !!e) as CrashRiskStoreEntity[]),
      map(CrashRisks => ({
        [this.layerTypeName]: CrashRisks,
      }))
    );
  }

  get removedCrashRisks$(): Observable<RemovedEntities> {
    return this.selectEntityAction([EntityActions.Remove]).pipe(map(({ ids }) => ({ [this.layerTypeName]: ids })));
  }

  get UICrashRisks$(): Observable<CrashRiskStoreUIEntity[]> {
    return this.ui.selectAll();
  }

  get crashRiskListByTimeFrames$() {
    return this.selectAll().pipe(
      map(crashRisks => {
        if (crashRisks.length === 0) {
          return;
        }

        const crashRiskListByTimeFrames: CrashRiskListByTimeFrames = {
          amount: crashRisks.length,
          timeFrameCrashRisks: [],
        };

        const now = Number(moment().utc().format('HH'));
        let start_hour =
          now % 3 === 0
            ? moment().utc()
            : moment()
                .utc()
                .subtract(now % 3, 'hour');
        start_hour = start_hour.set('minute', 0).set('second', 0).set('millisecond', 0);

        for (let index = 0; index < 8; index++) {
          crashRiskListByTimeFrames.timeFrameCrashRisks.push({
            time: start_hour.format('YYYY-MM-DDTHH:mm:ssZ').toString(),
            crashRisks: [],
          });
          start_hour = moment(start_hour).add(3, 'hours');
        }

        crashRisks.forEach((risk: CrashRiskStoreEntity) => {
          const timeSlot = crashRiskListByTimeFrames.timeFrameCrashRisks.find(riskSlot => {
            return (
              moment(risk.startTime).isSameOrAfter(riskSlot.time) &&
              moment(risk.startTime).isBefore(moment(riskSlot.time).add(3, 'hour'))
            );
          });
          if (timeSlot) {
            timeSlot.crashRisks.push(risk);
          }
        });

        return crashRiskListByTimeFrames;
      })
    );
  }
}
