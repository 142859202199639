<div data-cy-id="affected-lanes-slider" class="affected-lanes-slider">
  <div
    data-cy-id="swiper-affected-lanes-component"
    class="swiper-component swiper-affected-lanes-component"
    [formGroup]="parentForm">
    <div #swiperElm class="swiper-container" [class.one-lane-container]="lanes.controls.length === 1" [swiper]="config">
      <div formArrayName="affectedLanes" class="swiper-wrapper">
        <div
          [attr.data-cy-id]="'affected-lanes-slider-lane-' + !lane.get('isAffected')?.value"
          class="swiper-slide affected-lanes-slider-lane"
          [ngClass]="{
            allLanesAffected: allLanesAffected
          }"
          [class.disabled]="!lane.get('isAffected')?.value && !isMultiDirection"
          *ngFor="let lane of lanes.controls; let i = index"
          [formGroupName]="i"
          (mouseover)="laneHovered(lane, i)"
          (mouseleave)="laneHovered(null, -1)"
          [class.hover]="i === hovered">
          <div data-cy-id="affected-lanes-slider-header" class="affected-lanes-slider-header">
            <mat-checkbox
              data-cy-id="affected-lanes-slider-header-checkbox"
              *ngIf="!isMultiDirection"
              (change)="selectLane($event, i)"
              [checked]="lane.get('isAffected')?.value"
              [disabled]="allLanesAffected">
            </mat-checkbox>

            <span
              data-cy-id="affected-lanes-slider-header-title"
              class="affected-lanes-title"
              [class.multi-direction-title]="isMultiDirection">
              {{ lane.value | laneShortLabel }}
            </span>

            <div *ngIf="!isMultiDirection">
              <div #desktopMove class="affected-lanes-gore-actions" *ngIf="lane.get('type')?.value === 'GORE'">
                <wc-icon
                  data-cy-id="affected-lanes-gore-arrow-left"
                  [class.disabled]="i === 0"
                  iconName="arrow-left"
                  (click)="moveGore(i, true)"></wc-icon>
                <wc-icon
                  data-cy-id="affected-lanes-gore-arrow-right"
                  [class.disabled]="i === lanes.length - 1"
                  iconName="arrow-right"
                  (click)="moveGore(i, false)"></wc-icon>
              </div>
            </div>

            <wc-icon
              [attr.data-cy-id]="'affected-lanes-remove-button-' + !lane.get('isAffected')?.value"
              iconName="times-circle"
              [wcTooltipText]="'tooltip.deleteLane' | translate"
              wcTooltipPlacement="bottom"
              (click)="deleteLane(i); $event.preventDefault()"></wc-icon>
          </div>
          <div class="affected-lanes-content" [class.disabled]="!lane.get('isAffected')?.value && !isMultiDirection">
            <div
              *wcLet="
                lane.get('type')?.value === 'GORE' ||
                lane.get('type')?.value === 'LEFT_SHOULDER' ||
                lane.get('type')?.value === 'RIGHT_SHOULDER' as multiType
              "
              class="affected-lanes-type-wrapper">
              <wc-select
                data-cy-id="affected-lanes-type"
                class="affected-lanes-type"
                formControlName="type"
                [formFieldData]="multiType ? formFieldData.typeMultiDirection : formFieldData.typeSingleDirection"
                [disabled]="!lane.get('isAffected')?.value || multiType">
              </wc-select>

              <wc-select
                data-cy-id="affected-lanes-direction"
                class="affected-lanes-direction"
                formControlName="direction"
                [disabled]="!lane.get('isAffected')?.value"
                [formFieldData]="formFieldData.direction"></wc-select>

              <wc-select
                data-cy-id="affected-lanes-road-type"
                class="affected-lanes-roadType"
                formControlName="roadType"
                [isLoadMoreView]="true"
                [disabled]="!lane.get('isAffected')?.value"
                [formFieldData]="formFieldData.roadType"></wc-select>
            </div>
            <div data-cy-id="affected-lanes-slider-toggle" class="affected-lanes-slider-toggle">
              <span
                data-cy-id="affected-lanes-slider-toggle-option"
                class="toggle-option"
                [class.open]="lane.get('isClosed')?.value === false"
                >{{ 'affectedLanesToggleOptions.open' | translate }}</span
              >
              <wc-slider-toggle-control
                formControlName="isClosed"
                data-cy-id="affected-lanes-slider-toggle-button"></wc-slider-toggle-control>
              <span class="toggle-option" [class.closed]="lane?.get('isClosed')?.value">{{
                'affectedLanesToggleOptions.close' | translate
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-pagination-affected-lanes swiper-pagination"></div>
    </div>
    <div
      data-cy-id="swiper-pagination-affected-lanes-previous"
      [wcTooltipText]="'previous' | translate"
      wcTooltipPlacement="bottom"
      [wcTooltipOffsetDistance]="0"
      class="swiper-button-prev swiper-button-prev-affected-lanes"></div>
    <div
      data-cy-id="swiper-pagination-affected-lanes-next"
      [wcTooltipText]="'next' | translate"
      wcTooltipPlacement="bottom"
      [wcTooltipOffsetDistance]="0"
      class="swiper-button-next swiper-button-next-affected-lanes"></div>
  </div>
</div>
