import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@wc/core/environments/environment';

import { AppModule } from './app/app.module';

function loadServiceWorker() {
  if ('serviceWorker' in navigator) {
    //  const wb = new Workbox('/sw.js');
    //  wb.register();
  }
}

if (environment.production || environment.enableProdMode) {
  enableProdMode();
  console.log = () => {
    return;
  };
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => loadServiceWorker())
  .catch(err => console.error(err));
