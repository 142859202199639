import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'wc-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideToggleComponent {
  @ViewChild('toggle') slideToggle!: MatSlideToggle;
  @Input() isWideStyle = true;
  @Input() direction: 'rtl' | 'ltr' = 'ltr';
  @Input() isTabletMode = false;
  @Input() toggleName?: string;
  @Input() checked = false;
  @Input() label?: string;
  @Output() toggleChanged = new EventEmitter<MatSlideToggleChange>();

  toggle(): void {
    this.checked = !this.checked;
    const event = new MatSlideToggleChange(this.slideToggle, this.checked);
    this.changed(event);
  }
  changed(event: MatSlideToggleChange): void {
    this.toggleChanged.emit(event);
  }
}
