import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[wcInvalidExpansionCssClass]',
})
export class WcInvalidExpansionCssClassDirective {
  @Input() set wcInvalidExpansionCssClass(invalid: boolean | undefined) {
    invalid
      ? this.renderer.addClass(this.el.nativeElement, 'invalid-panel')
      : this.renderer.removeClass(this.el.nativeElement, 'invalid-panel');
  }
  constructor(private renderer: Renderer2, private el: ElementRef) {}
}
