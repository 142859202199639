import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CustomFormControlComponent } from '../../lib/base';

@Component({
  selector: 'wc-numeric-increase-decrease',
  templateUrl: './numeric-increase-decrease.component.html',
  styleUrls: ['./numeric-increase-decrease.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumericIncreaseDecreaseComponent extends CustomFormControlComponent {
  @Input() minInput = 0;
  @Input() maxInput = 99;
  @Input() step = 0.5;

  change($event) {
    const newValue = Math.round(Number($event.target.value / this.step)) * this.step;

    if (newValue > this.maxInput) {
      this.onChanged(this.maxInput);
    } else if (newValue < this.minInput) {
      this.onChanged(this.minInput);
    } else {
      this.onChanged(newValue);
    }
  }
}
