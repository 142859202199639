import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { WcCheckboxModule } from '@wc/features/ui/form-fields-controls/checkbox/checkbox.module';
import { DistanceFormatPipeModule, TimeElapsedPipeModule, WcPopupCloserModule } from '@wc/wc-common/pipes';
import { FeedbackModule } from '../feedback/feedback.module';
import { PersistenceScoreModule } from '../persistence-score/persistence-score.module';
import { PriorityScoreModule } from '../priority-score/priority-score.module';
import { StatusCircleModule } from '../status-circle/status-circle.module';
import { WcTooltipModule } from '../tooltip/tooltip.module';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { InsightsPanelComponent } from './insights-panel.component';

@NgModule({
  declarations: [InsightsPanelComponent],
  imports: [
    CommonModule,
    TranslateModule,
    WcCheckboxModule,
    MatCheckboxModule,
    FeedbackModule,
    MatButtonToggleModule,
    MatTooltipModule,
    StatusCircleModule,
    DistanceFormatPipeModule,
    TimeElapsedPipeModule,
    WcPopupCloserModule,
    WcTooltipModule,
    WcIconModule,
    PersistenceScoreModule,
    MatExpansionModule,
    PriorityScoreModule,
  ],
  exports: [InsightsPanelComponent],
})
export class InsightsPanelModule {}
