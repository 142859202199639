import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'wc-map-buttons-container',
  templateUrl: './map-buttons-container.component.html',
  styleUrls: ['./map-buttons-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MapButtonsContainerComponent {
  @Input() layoutDirection: 'vertical' | 'horizontal' = 'vertical';
}
