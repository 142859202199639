import { FormGroup } from '@angular/forms';
import { environment } from '@wc/core';

export function endTimeMitigationTypeValidator(
  endTimeControlName: string,
  mitigationsControlName: string,
  isMitigationFromDifferentAccount: string
) {
  return (formGroup: FormGroup) => {
    const endTimeControl = formGroup.controls[endTimeControlName];
    const mitigations = formGroup.controls[mitigationsControlName];
    const isMitigationOtherAccounts = formGroup.controls[isMitigationFromDifferentAccount];

    if (mitigations.errors && !mitigations.errors.endTimeMitigationType) {
      return;
    }

    if (endTimeControl.value === null) {
      mitigations.setErrors(null);
    } else {
      const mitigationsTypes = mitigations.value.filter(
        value => value !== environment.defaultMitigationTypeId && value.value !== environment.defaultMitigationTypeId
      );

      if (mitigationsTypes.length > 0 || isMitigationOtherAccounts.value) {
        mitigations.setErrors(null);
      } else {
        mitigations.setErrors({ endTimeMitigationType: true });
      }
    }
  };
}
