<div
  class="congestion-modal-container"
  *ngIf="congestion"
  [class.tablet-mode]="isTabletMode"
  [class.language-hebrew]="isHebrew">
  <div class="congestion-modal-header">
    <div class="header-first-line">
      <wc-icon
        class="tooltip-icon"
        [iconName]="congestion?.layerType === LayerTypes.Congestion ? 'congestion' : 'congestion-irregular'"></wc-icon>
      <span *ngIf="congestion && congestion?.layerType === LayerTypes.IrregularCongestion; else regularCongestion">
        {{ 'congestion.' + LayerTypes.IrregularCongestion | translate }}
      </span>

      <ng-template #regularCongestion>
        {{ 'congestion.' + LayerTypes.Congestion | translate }}
      </ng-template>
    </div>

    <div class="header-second-line" *ngIf="congestion.startAddress?.corridor || congestion.startAddress?.crossroad">
      <span class="congestion-modal-title">
        {{ congestion.startAddress.corridor }}
        {{ congestion.startAddress.crossroad }}
        {{ congestion.startAddress.direction }}
      </span>
    </div>

    <div class="header-third-line">
      <span class="congestion-detection">
        <span class="congestion-time-title">{{ 'congestion.detection' | translate }}</span>
        <span class="congestion-time-absolute"> {{ congestion.startTime | timeFormat }}</span>
        <span class="congestion-time-relative">{{ moment(congestion.startTime).fromNow() }}</span>
      </span>

      <span class="congestion-last-update">
        <span class="congestion-time-title">{{ 'congestion.lastUpdate' | translate }}</span>
        <span class="congestion-time-absolute"> {{ congestion.updatedAt | timeFormat }}</span>
        <span class="congestion-time-relative">{{ moment(congestion.updatedAt).fromNow() }}</span>
      </span>
    </div>
  </div>

  <div class="congestion-modal-body">
    <div class="address-container">
      <div class="address-left-panel">
        <span class="from-box top">
          {{ 'congestion.from' | translate }}
        </span>
        <span class="to-box bottom">
          {{ 'congestion.to' | translate }}
        </span>
      </div>
      <div class="address-right-panel">
        <div class="address-line">
          <span
            class="address-line-from"
            *ngIf="congestion.startAddress?.corridor || congestion.startAddress?.crossroad; else missingStartAddress">
            {{ congestion.startAddress.corridor }}
            {{ congestion.startAddress.crossroad }}
            {{ congestion.startAddress.direction }}
          </span>
          <ng-template #missingStartAddress>
            <span class="address-line-from">
              {{ 'missingStartAddress' | translate }}
            </span>
          </ng-template>
        </div>
        <div class="distance-in-miles">
          {{ congestion.distance | distanceFormat }}
        </div>
        <div class="address-line to">
          <span *ngIf="congestion.endAddress?.corridor || congestion.endAddress?.crossroad; else missingEndAddress">
            {{ congestion.endAddress.corridor }}
            {{ congestion.endAddress.crossroad }}
            {{ congestion.endAddress.direction }}
          </span>

          <ng-template #missingEndAddress>
            {{ 'missingEndAddress' | translate }}
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
