import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalType } from '@wc/core/models/enums';
import { PermissionsFacadeService } from '@wc/permissions/domain/src';
import * as Utils from '@wc/utils';
import { binarySearch } from '@wc/wc-common/src/lib/utils/utils-functions';
import { ConfirmModalService } from '@wc/wc-ui/src';
import { noWhitespaceValidator } from '@wc/wc-ui/src/form-validators/whitespace.validator';
import { FormFieldData, FormFieldOption } from '@wc/wc-ui/src/lib/base';
import { Observable, of, pipe, Subject } from 'rxjs';
import { distinctUntilChanged, map, pairwise, retry, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import {
  Action,
  CreateResponsePlanTemplateGQL,
  CreateResponsePlanTemplateInput,
  DeleteResponsePlanTemplateGQL,
  DmssByAccountGQL,
  EntityType,
  Incident,
  IncidentStatus,
  IsResponsePlanDoneGQL,
  NotificationDestination,
  ResponsePlan,
  ResponsePlanAction,
  ResponsePlanActionField,
  ResponsePlanActionInput,
  ResponsePlanEntityType,
  ResponsePlansByEntityTypeGQL,
  ResponsePlanTemplate,
  ResponsePlanUnits,
  Scalars,
  TemplateAction,
  TemplateActionInput,
  UpdateActionStatusGQL,
  UpdateResponsePlanTemplateGQL,
  UpdateResponsePlanTemplateInput,
  VehicleType,
} from '../../../../core/models/gql.models';
import { FieldData, SelectOption } from '../../../../features/ui/form-controls/form-models';
import { AccountService } from './account.service';
import { CustomRxOperatorsService } from './custom-rx-operators.service';
import { IncidentsService } from './incidents.service';

export const timeToValueInMinutes: Record<ResponsePlanUnits, number> = {
  [ResponsePlanUnits.DaysBeforeStart]: -1440,
  [ResponsePlanUnits.HoursBeforeStart]: -60,
  [ResponsePlanUnits.HoursAfterStart]: 60,
  [ResponsePlanUnits.HoursBeforeEnd]: -60,
  [ResponsePlanUnits.HoursAfterEnd]: 60,
  [ResponsePlanUnits.DaysAfterStart]: 1440,
  [ResponsePlanUnits.MinAfterStart]: 1,
};

export const ActionValueType: Record<ResponsePlanActionField, string> = {
  [ResponsePlanActionField.ChangeDms]: 'DMSActionValue',
  [ResponsePlanActionField.RoadClosure]: 'RoadClosureActionValue',
  [ResponsePlanActionField.OpenRoadClosure]: 'RoadClosureActionValue',
  [ResponsePlanActionField.ShareToPublic]: 'ShareToPublicActionValue',
  [ResponsePlanActionField.Other]: 'StringActionValue',
  [ResponsePlanActionField.ChangeTrafficSignal]: 'StringActionValue',
  [ResponsePlanActionField.SendEmail]: 'StringActionValue',
  [ResponsePlanActionField.OpenParkingLots]: 'StringActionValue',
  [ResponsePlanActionField.ConfirmIncidentDetails]: 'StringActionValue',
  [ResponsePlanActionField.Contacts]: 'StringListActionValue',
  [ResponsePlanActionField.UpdateIncidentDetails]: 'StringListActionValue',
  [ResponsePlanActionField.UpdateIncidentDetailsExternalPlatform]: 'StringActionValue',
};

enum UpdateIncidentDetails {
  Location = 'Location',
  LiveMedia = 'LiveMedia',
  Files = 'Files',
  AffectedLanes = 'AffectedLanes',
  AssociatedUnits = 'AssociatedUnits',
  Notes = 'Notes',
}

const SpecialEventActionTypes = [
  ResponsePlanActionField.ChangeDms,
  ResponsePlanActionField.ChangeTrafficSignal,
  ResponsePlanActionField.OpenParkingLots,
  ResponsePlanActionField.OpenRoadClosure,
  ResponsePlanActionField.Other,
  ResponsePlanActionField.RoadClosure,
  ResponsePlanActionField.SendEmail,
  ResponsePlanActionField.ShareToPublic,
];

const IncidentActionTypes = [
  ResponsePlanActionField.ChangeDms,
  ResponsePlanActionField.ChangeTrafficSignal,
  ResponsePlanActionField.OpenParkingLots,
  ResponsePlanActionField.OpenRoadClosure,
  ResponsePlanActionField.Other,
  ResponsePlanActionField.RoadClosure,
  ResponsePlanActionField.SendEmail,
  ResponsePlanActionField.ShareToPublic,
  ResponsePlanActionField.ConfirmIncidentDetails,
  ResponsePlanActionField.UpdateIncidentDetails,
  ResponsePlanActionField.UpdateIncidentDetailsExternalPlatform,
  ResponsePlanActionField.Contacts,
];

const SpecialEventUnitType = [
  ResponsePlanUnits.DaysBeforeStart,
  ResponsePlanUnits.HoursBeforeStart,
  ResponsePlanUnits.HoursAfterStart,
  ResponsePlanUnits.HoursBeforeEnd,
  ResponsePlanUnits.HoursAfterEnd,
];

const IncidentUnitType = [
  ResponsePlanUnits.DaysAfterStart,
  ResponsePlanUnits.HoursAfterStart,
  ResponsePlanUnits.MinAfterStart,
];
const pipeAction = <T extends { id: number; title: string }[]>() =>
  pipe(
    map<T, SelectOption<T[0]>[]>((val: T) => val.map<SelectOption>(v => ({ value: v.id, displayName: v.title }))),
    shareReplay(1),
    retry(3)
  );

@Injectable({
  providedIn: 'root',
})
export class ResponsePlanService {
  formAlive$ = new Subject();
  fullFormRef = new FormGroup({});
  planStartTime = new Date(1);
  planEndTime = new Date(1);
  addActionIndex$ = new Subject<number>();
  minStepperInput = 0;
  maxStepperInput = 99;
  maxActionCount = 100;
  actionTextFields = [
    ResponsePlanActionField.ChangeTrafficSignal,
    ResponsePlanActionField.OpenParkingLots,
    ResponsePlanActionField.Other,
    ResponsePlanActionField.SendEmail,
    ResponsePlanActionField.UpdateIncidentDetailsExternalPlatform,
    ResponsePlanActionField.ConfirmIncidentDetails,
  ];

  responseFormFieldData!: { [key: string]: FormFieldData | { [key: string]: FormFieldData } };

  actionFieldsFieldData!: Record<ResponsePlanActionField, FieldData>;

  get reginalSettings() {
    return this.accountService.account.regionalSetting;
  }

  getTemplateSelectOptions(responsePlanTemplate: ResponsePlanTemplate[]): FormFieldOption<number>[] {
    const options: FormFieldOption<number>[] = [];
    responsePlanTemplate.forEach(plan =>
      options.push({
        displayName: plan.title,
        value: plan.id,
      })
    );
    return options;
  }

  constructor(
    private accountService: AccountService,
    private responsePlansByEntityTypeGQL: ResponsePlansByEntityTypeGQL,
    private dmssByAccountGQL: DmssByAccountGQL,
    private createResponsePlanTemplateGQL: CreateResponsePlanTemplateGQL,
    private updateResponsePlanTemplateGQL: UpdateResponsePlanTemplateGQL,
    private deleteResponsePlanTemplateGQL: DeleteResponsePlanTemplateGQL,
    private isResponsePlanDoneGQL: IsResponsePlanDoneGQL,
    private updateActionStatusGQL: UpdateActionStatusGQL,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private customOperators: CustomRxOperatorsService,
    private incidentService: IncidentsService,
    private confirmService: ConfirmModalService,
    private permissionsFacadeService: PermissionsFacadeService
  ) {}

  private initializeFieldData() {
    if (!this.actionFieldsFieldData) {
      this.actionFieldsFieldData = {
        [ResponsePlanActionField.ShareToPublic]: {
          options: Utils.strArrayToOptions(
            [...this.accountService.account.publishChannels, ...[NotificationDestination.Waze]],
            {
              translateService: this.translateService,
              translateBy: 'value',
              translatePath: 'publishChannels',
            }
          ),
        },
        [ResponsePlanActionField.RoadClosure]: {},
        [ResponsePlanActionField.ChangeDms]: {
          asyncOptions: this.getDmsOptions(),
        },
        [ResponsePlanActionField.SendEmail]: {
          label: 'Email',
          placeholder: 'Enter email...',
        },
        [ResponsePlanActionField.Other]: {},
        [ResponsePlanActionField.OpenParkingLots]: {},
        [ResponsePlanActionField.ChangeTrafficSignal]: {},
        [ResponsePlanActionField.OpenRoadClosure]: {},
        [ResponsePlanActionField.Contacts]: {
          options: Utils.EnumToOptions(VehicleType, {
            translateService: this.translateService,
            translateBy: 'value',
            translatePath: 'VehicleTypes',
          }).filter(
            option =>
              option.value !== VehicleType.TransitDemandResponseUnit && option.value !== VehicleType.TransitOnDemandUnit
          ),
        },
        [ResponsePlanActionField.ConfirmIncidentDetails]: {},
        [ResponsePlanActionField.UpdateIncidentDetails]: {
          options: Utils.EnumToOptions(UpdateIncidentDetails, {
            translateService: this.translateService,
            translateBy: 'value',
            translatePath: 'responsePlanIncidentDetailsField',
          }),
        },
        [ResponsePlanActionField.UpdateIncidentDetailsExternalPlatform]: {},
      };

      this.actionFieldsFieldData[ResponsePlanActionField.OpenRoadClosure] =
        this.actionFieldsFieldData[ResponsePlanActionField.RoadClosure];
    }

    if (!this.responseFormFieldData) {
      this.responseFormFieldData = {
        unit: {
          [ResponsePlanEntityType.SpecialEvent]: {
            options: Utils.EnumToOptions(SpecialEventUnitType, {
              translateService: this.translateService,
              translateBy: 'value',
              translatePath: 'responsePlanUnits',
              removeSort: true,
            }),
            placeholder: 'selectUnits',
            label: 'timeUnit',
          },
          [ResponsePlanEntityType.Incident]: {
            options: Utils.EnumToOptions(IncidentUnitType, {
              translateService: this.translateService,
              translateBy: 'value',
              translatePath: 'responsePlanUnits',
              removeSort: true,
            }),
            placeholder: 'selectUnits',
            label: 'timeUnit',
          },
        },
        time: { placeholder: 'select', label: 'time', floatLabel: 'always', options: [] },
        actionField: {
          [ResponsePlanEntityType.Incident]: {
            options: Utils.strArrayToOptions(IncidentActionTypes, {
              translateService: this.translateService,
              translateBy: 'value',
              translatePath: 'responsePlanActionField',
            }),
            label: 'action',
            placeholder: 'selectAction',
          },
          [ResponsePlanEntityType.SpecialEvent]: {
            options: Utils.EnumToOptions(SpecialEventActionTypes, {
              translateService: this.translateService,
              translateBy: 'value',
              translatePath: 'responsePlanActionField',
            }),
            label: 'action',
            placeholder: 'selectAction',
          },
        },
        text: { label: 'text', placeholder: 'enterNotes' },
      };

      console.log(this.responseFormFieldData);
    }
  }

  generateEntityResponsePlanForm(
    responseTemplate: ResponsePlanTemplate | ResponsePlan,
    entityType: ResponsePlanEntityType,
    entityId?: number,
    responsePlanId?: number
  ) {
    this.initializeFieldData();
    this.formAlive$ = new Subject();

    const form = this.fb.group({
      title: responseTemplate.title,
      entityId: [{ value: entityId, disabled: !entityId }],
      actions: this.fb.array([]),
      templateId: (responseTemplate as ResponsePlan).templateId
        ? (responseTemplate as ResponsePlan).templateId
        : responseTemplate.id,
      responsePlanId: [{ value: responsePlanId, disabled: !responsePlanId }],
      entityType: entityType,
    });

    this.fullFormRef = form;
    if (responseTemplate?.actions.length) {
      responseTemplate.actions
        .sort((a, b) => a.index - b.index)
        .forEach((action, index) => this.addActionFormControl(action, index, true));
    }

    return form;
  }

  generateResponsePlanForm(
    entityType: ResponsePlanEntityType,
    responseTemplate?: ResponsePlanTemplate,
    isClone?: boolean
  ): FormGroup {
    this.initializeFieldData();
    this.formAlive$ = new Subject();

    const form = this.fb.group({
      title: this.fb.control(responseTemplate?.title ?? '', [
        Validators.required,
        noWhitespaceValidator(),
        Validators.maxLength(50),
      ]),
      actions: this.fb.array([]),
      id: [{ value: responseTemplate?.id, disabled: !responseTemplate?.id || isClone }],
      entityType: entityType,
    });
    this.fullFormRef = form;

    // edit mode and template has actions
    if (responseTemplate?.actions.length) {
      responseTemplate.actions
        .sort((a, b) => a.index - b.index)
        .forEach((action, index) => this.addActionFormControl(action, index));
    } else {
      this.addActionFormControl(undefined, 0);
    }

    return form;
  }

  addActionFormControl(actionData?: TemplateAction | ResponsePlanAction, index?, isEntityAction = false) {
    const actionsFormArray = this.fullFormRef.get('actions') as FormArray;
    const group = this.fb.group({
      templateActionId: [{ value: (actionData as TemplateAction)?.templateActionId, disabled: isEntityAction }],
      templateId: [{ value: (actionData as TemplateAction)?.templateActionId, disabled: isEntityAction }],
      time: this.fb.control(actionData?.time ?? 0, [
        Validators.required,
        Validators.minLength(this.minStepperInput),
        Validators.maxLength(this.maxStepperInput),
      ]),
      unit: this.fb.control(actionData?.unit || null, Validators.required),
      isDone: [{ value: (actionData as ResponsePlanAction)?.isDone || false, disabled: !isEntityAction }],
      actionField: this.fb.control(actionData?.actionField || null, Validators.required),
      value: this.fb.control(actionData?.value?.value || null),
      text: this.fb.control(actionData?.text || null),
      responsePlanActionId: [
        { value: (actionData as ResponsePlanAction)?.responsePlanActionId, disabled: !isEntityAction },
      ],
      index: this.fb.control(actionData?.index ?? index),
      timestamp: [
        {
          value: this.calcTimeReference(actionData?.unit, actionData?.time),
          disabled: true,
        },
      ],
    });

    actionsFormArray.insert(index >= 0 ? index : actionsFormArray.length, group);
    actionsFormArray.controls.forEach((control, index) => {
      control.patchValue({ index: index });
    });

    group.valueChanges
      .pipe(
        takeUntil(this.formAlive$),
        distinctUntilChanged((curr, prev) => {
          return curr.time === prev.time && curr.unit === prev.unit && curr.actionField === prev.actionField;
        }),
        startWith(group.value),
        pairwise()
      )
      .subscribe(([prev, curr]) => {
        this.sortResponsePlanByTime(actionsFormArray, group, prev, curr, index);
        this.setActionValue(actionsFormArray, prev, curr, group.controls['index'].value);
        this.setActionValueValidations(actionsFormArray, group.controls['index'].value, curr);
      });
  }

  private setActionValueValidations(actionsFormArray, index, curr) {
    const actionValue = actionsFormArray.at(index)?.get('value');
    if (this.actionTextFields.includes(curr?.actionField)) {
      actionValue?.setValidators([Validators.maxLength(50)]);
    } else actionValue?.clearValidators();
  }

  private setActionValue(actionsFormArray: FormArray, prev, curr, index) {
    if (curr?.actionField !== prev?.actionField) {
      actionsFormArray.at(index)?.get('value')?.setValue(null);
    }
  }

  setFloatLabelToFieldData(floatLabel: FloatLabelType) {
    Object.values(this.responseFormFieldData).forEach(x => (x.floatLabel = floatLabel));
  }

  getActionByTimeUnitIndex(timestampArray: number[], timestamp: number): number {
    const sortedTimestampArray = timestampArray.sort((a, b) => a - b);
    const newIndex = binarySearch(sortedTimestampArray, timestamp);
    return newIndex;
  }

  calcTimeReference(unit?: ResponsePlanUnits, time?: number) {
    if (unit && time !== undefined) {
      if (
        unit === ResponsePlanUnits.DaysBeforeStart ||
        unit === ResponsePlanUnits.HoursBeforeStart ||
        unit === ResponsePlanUnits.HoursAfterStart ||
        unit === ResponsePlanUnits.DaysAfterStart ||
        unit === ResponsePlanUnits.MinAfterStart
      ) {
        const newDate = new Date(this.planStartTime);
        return newDate.setMinutes(this.planStartTime?.getMinutes() + timeToValueInMinutes[unit] * time);
      } else {
        const newDate = new Date(this.planEndTime);
        return newDate.setMinutes(this.planEndTime.getMinutes() + timeToValueInMinutes[unit] * time);
      }
    }

    return undefined;
  }

  createActionValue(
    action: Action | ResponsePlanAction | ResponsePlanActionInput | TemplateAction
  ): Scalars['ActionValue'] {
    const actionType = ActionValueType[action.actionField];
    if (action.value && actionType) action.value = { value: action.value, type: actionType };
    return action;
  }

  private sortResponsePlanByTime(actionsFormArray: FormArray, group: FormGroup, prev, curr, newIndex) {
    const index = actionsFormArray.controls.findIndex(c => c === group);
    if (curr?.time !== prev?.time || curr?.unit !== prev?.unit) {
      const newTimestamp = this.calcTimeReference(curr.unit, curr.time);
      if (newTimestamp !== undefined) {
        const control = actionsFormArray.at(index);
        control.get('timestamp')?.setValue(newTimestamp);
        actionsFormArray.removeAt(index);
        const timestampArray = [...actionsFormArray.controls.map(c => c.get('timestamp')?.value), newTimestamp];
        const newIndex = this.getActionByTimeUnitIndex(timestampArray, newTimestamp);
        actionsFormArray.insert(newIndex, control);
        if (newIndex !== index) this.addActionIndex$.next(newIndex);
        actionsFormArray.controls.forEach((control, index) => control.get('index')?.setValue(index));
      }
    }
  }

  createUpdateResponsePlanTemplateInput(responsePlanTemplate: ResponsePlanTemplate): UpdateResponsePlanTemplateInput {
    return {
      entityType: responsePlanTemplate.entityType,
      templateId: responsePlanTemplate.id,
      title: responsePlanTemplate.title,
      actions: responsePlanTemplate.actions.map(
        (action, index) =>
          ({
            index,
            actionField: action.actionField,
            time: action.time,
            unit: action.unit,
            templateActionId: action.templateActionId,
            text: action.text,
            value: action.value !== '' ? action.value : null,
          } as TemplateActionInput)
      ),
    };
  }

  setPlanBaseTime(startTime: string, endTime?: string) {
    this.planStartTime = new Date(startTime);
    if (endTime) {
      this.planEndTime = new Date(endTime);
    }
    this.updateActionTimestamps();
  }

  private updateActionTimestamps() {
    const actions = (this.fullFormRef.get('actions') || []) as FormArray;
    actions.controls?.forEach(action => {
      action.get('timestamp')?.setValue(this.calcTimeReference(action.get('unit')?.value, action.get('time')?.value));
    });
  }

  resetFormAndData() {
    if (this.actionFieldsFieldData) {
      this.actionFieldsFieldData.CHANGE_DMS.asyncOptions = this.getDmsOptions();
    }
    this.formAlive$.next();
    this.formAlive$.complete();
    this.fullFormRef.reset();
    this.planEndTime = new Date(1);
    this.planStartTime = new Date(1);
  }

  getResponsePlanTemplatesByType(entityType: ResponsePlanEntityType): Observable<ResponsePlanTemplate[]> {
    return this.responsePlansByEntityTypeGQL.fetch({ entityType: entityType }).pipe(
      map(res => {
        if (res.errors) {
          throw res.errors;
        }
        return res.data?.responsePlansByEntityType;
      })
    );
  }

  createResponsePlanTemplate(input: CreateResponsePlanTemplateInput) {
    return this.createResponsePlanTemplateGQL.mutate({ input }).pipe(map(res => res.data?.createResponsePlanTemplate));
  }

  updateResponsePlanTemplate(input: UpdateResponsePlanTemplateInput) {
    return this.updateResponsePlanTemplateGQL.mutate({ input }).pipe(map(res => res.data?.updateResponsePlanTemplate));
  }

  deleteResponsePlanTemplate(templateId: number) {
    return this.deleteResponsePlanTemplateGQL
      .mutate({ templateId })
      .pipe(map(res => res.data?.deleteResponsePlanTemplate));
  }

  isResponsePlanDone(entityId: number, entityType: ResponsePlanEntityType) {
    if (!this.permissionsFacadeService.hasPermission('RESPONSE_PLAN:READ')) {
      return of(true);
    }

    return this.isResponsePlanDoneGQL.fetch({ entityId: entityId, entityType: entityType }).pipe(
      map(res => res.data.isResponsePlanDone),
      this.customOperators.catchGqlErrors()
    );
  }

  updateActionStatus(actionId: number, isDone: boolean) {
    return this.updateActionStatusGQL.mutate({ actionId: actionId, status: isDone }).pipe(
      map(res => res.data?.updateActionStatus),
      this.customOperators.catchGqlErrors()
    );
  }

  getDmsOptions() {
    return this.dmssByAccountGQL.fetch().pipe(
      map(res => res.data.dmssByAccount),
      pipeAction(),
      this.customOperators.catchGqlErrors()
    );
  }

  responsePlanNotDoneDialogResult(incident: Incident, status: IncidentStatus): Promise<boolean> {
    return new Promise(() => {
      this.isResponsePlanDone(incident.id, ResponsePlanEntityType.Incident).subscribe(done => {
        if (done === false) {
          this.confirmService.showConfirmDialog(
            ConfirmationModalType.ResponsePlanNotDone,
            EntityType.Incident,
            completeAnyWay => {
              if (completeAnyWay) {
                this.incidentService.startCompleteReject(incident, status);
              }
            }
          );
        } else {
          this.incidentService.startCompleteReject(incident, status);
        }
      });
    });
  }
}
