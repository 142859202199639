import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { environment } from '@wc/core';
import { Incident, IncidentMitigation, IncidentType, UnitResponse } from '@wc/core/models/gql.models';
import { IncidentStore, UsersStore } from '@wc/core/stores';
import { AutocompleteV2Component } from '@wc/wc-ui/src/form-components/autocomplete-v2/autocomplete-v2.component';
import { interval } from 'rxjs';
import { retry } from 'rxjs/operators';
import { MitigationComponent } from '../../form-controls/mitigation/mitigation.component';

function validateMitigations(c: AbstractControl): ValidationErrors | null {
  return c.value[0] && c.value[0].interval.from
    ? null
    : {
        validateMitigations: {
          valid: false,
        },
      };
}

@Component({
  selector: 'wc-mitigation-form',
  templateUrl: './mitigation-form.component.html',
  styleUrls: ['./mitigation-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MitigationFormComponent implements OnInit, OnDestroy {
  @Input() incident!: Incident;
  @Input() isTabletMode = false;
  @Output() submited: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @ViewChild('mitigationList') mitigationList!: MitigationComponent;
  @ViewChild('subType', { read: AutocompleteV2Component })
  subType!: AutocompleteV2Component;
  p;
  mitigationStartTime: Date | null = null;

  incidentMitigation!: IncidentMitigation;
  selectedIncident!: Partial<Incident>;
  defaultMitigationTypeId: number = environment.defaultMitigationTypeId;
  differentUsersMitigations: IncidentMitigation[] = [];
  formSub;
  formTypeSub;

  form: FormGroup = this.fb.group({
    mitigation: new FormControl('', [validateMitigations]),
    incidentType: new FormControl(null, Validators.required),
    subType: new FormControl(''),
  });

  fieldsData = {
    mitigation: {
      label: 'Mitigation',
      value: [],
    },
    incidentType: {
      validators: [Validators.required],
      options: this.incidentStore.incidentTypes,
      label: 'selectIncident',
      placeholder: 'selectIncident',
    },
    subType: {
      label: 'incident.subtype',
      options: this.incidentStore.subtypeOptions,
    },
  };

  // mitigation is not empty + type is not empty or unknown
  get isValid(): boolean {
    const mitigations =
      this.form.value.mitigation.filter(
        (incidentMitigation: IncidentMitigation) =>
          incidentMitigation?.mitigationType.id !== this.defaultMitigationTypeId
      ) || [];
    const isIncidentTypeValid =
      this.form.value.incidentType !== null && this.form.value.incidentType !== IncidentType.Unidentified;
    return mitigations.length > 0 && this.mitigationList?.form.valid && isIncidentTypeValid;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private usersStore: UsersStore,
    public incidentStore: IncidentStore
  ) {}

  ngOnInit(): void {
    const userUnit = this.incident.associatedUnits?.find(
      unit =>
        !!unit.updatedAt &&
        unit.driverDetails?.userId === this.usersStore.authUserID &&
        unit.unitResponse === UnitResponse.OnScene
    );
    if (userUnit) {
      this.mitigationStartTime = new Date(userUnit.updatedAt);
    }

    if (!this.incident) {
      throw new Error('Incident must be supplied to mitigation-form.component');
    }

    this.form.get('subType')?.setValue(this.incident.subType);
    this.differentUsersMitigations = (this.incident.mitigations || []).filter(
      incidentMitigation => incidentMitigation.userId !== this.usersStore.authUserID
    );
    const currentUsersMitigations: IncidentMitigation[] = (this.incident.mitigations || []).filter(
      incidentMitigation =>
        incidentMitigation.userId === this.usersStore.authUserID &&
        incidentMitigation.unitId === this.usersStore.authUser.unit?.id
    );

    this.form.patchValue({
      mitigation: currentUsersMitigations,
      incidentType: this.incident.type === IncidentType.Unidentified ? null : this.incident.type,
    });
    this.incidentStore.updateIncidentSubtypes(this.incident.type);

    this.formTypeSub = this.form.get('incidentType')?.valueChanges.subscribe(value => {
      this.incidentStore.updateIncidentSubtypes(value);

      if (this.form.get('incidentType')?.dirty && this.form.get('subType')?.value !== null) {
        this.form.get('subType')?.setValue(null);
      }

      // TODO: Improve it and try do not use it inside valueChanges subscription
      if (!this.subType) {
        const seconds = interval(500);
        const subTypeRetry = seconds.pipe(retry(10)).subscribe(
          () => {
            this.subType?.updateOptions(this.incidentStore.subtypeOptions);
            subTypeRetry.unsubscribe();
          },
          err => console.log(err)
        );
      } else {
        this.subType?.updateOptions(this.incidentStore.subtypeOptions);
      }
    });
  }

  close() {
    this.cancel.emit();
  }

  submit() {
    const added_mitigation = this.form.get('mitigation')?.value;
    const all_mitigations = added_mitigation.concat(this.differentUsersMitigations);

    // https://app.shortcut.com/rekor/story/27695/tablet-end-time-is-not-added-automatically-after-selecting-mitigated
    // In case user mitigate -> go to be on-scene -> mitigated again -> add end time for those mitigations end time was override by null
    all_mitigations.map((mitigation: IncidentMitigation) => {
      if (mitigation.userId === this.usersStore.authUserID && !mitigation.interval.to) {
        mitigation.interval.to = new Date();
      }
    });
    if (this.isValid) {
      this.submited.emit({
        incidentType: this.form.get('incidentType')?.value,
        mitigation: all_mitigations,
        subType: this.form.get('subType')?.value,
      });
    }
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe();
    this.formTypeSub?.unsubscribe();
  }
}
