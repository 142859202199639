import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { MobxAngularModule } from 'mobx-angular';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { WcLoaderComponentModule } from '../miscellaneous/loader/loader.module';
import { CameraModalComponent } from './camera-modal/camera-modal.component';
import { CameraSectionComponent } from './camera-section/camera-section.component';
import { CameraSectionModule } from './camera-section/camera-section.module';
import { CameraSliderComponent } from './camera-slider/camera-slider.component';
import { CameraSliderModule } from './camera-slider/camera-slider.module';
import { CameraViewerModule } from './camera-viewer/camera-viewer.module';
import { UnitDashcamsModule } from './unit-dashcams/unit-dashcams.module';
const Material = [
  MatAutocompleteModule,
  MatSelectModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatTabsModule,
];
@NgModule({
  declarations: [CameraModalComponent],
  imports: [
    CommonModule,
    MobxAngularModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    WcLoaderComponentModule,
    Material,
    CameraViewerModule,
    UnitDashcamsModule,
    CameraSliderModule,
    CameraSectionModule,
  ],
  exports: [CameraModalComponent, CameraSliderComponent, CameraSectionComponent],
})
export class CameraComponentsModule {}
