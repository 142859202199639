<mat-form-field
  class="time-field-control"
  appearance="none"
  floatLabel="never"
  [class.invalid-field]="fieldFormControl.invalid">
  <div
    class="time-input-wrapper"
    [ngClass]="{
      open: isPickerOpen && !isInFocus,
      invalid: validationError || isParentInvalid,
      focus: isInFocus && !readOnly,
      'read-only': readOnly
    }">
    <input
      data-cy-id="time-field-input"
      #timeField
      class="time-field"
      matInput
      [formControl]="$any(fieldFormControl)"
      [matMenuTriggerFor]="menu"
      #timeMenuTrigger="matMenuTrigger"
      [readonly]="readOnly"
      autocomplete="off"
      (change)="valueChanged()"
      (paste)="onPaste($event)"
      (menuOpened)="handleMenuOpened()"
      (menuClosed)="handleMenuClosed()"
      (focus)="onFocus()"
      (blur)="onBlur()" />
    <i class="fas fa-clock"></i>
  </div>

  <mat-error *ngIf="validationError">
    {{ 'validationErrorMessages.' + $any(validationError) | translate }}
  </mat-error>
</mat-form-field>

<mat-menu #menu="matMenu">
  <div class="menu-container" (click)="$event.stopPropagation()">
    <div class="swiper-component">
      <div
        class="swiper-container hours"
        [swiper]="hourSwiperConfig"
        [(index)]="hourIndex"
        (indexChange)="hourUpdate($event)">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let hour of hours; let i = index" [class.active]="selectedHourIndex === i">
            <div>{{ hour }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="swiper-component">
      <div
        class="swiper-container minutes"
        [swiper]="minuteSwiperConfig"
        [(index)]="minuteIndex"
        (indexChange)="minuteUpdate($event)"
        [disabled]="isRoundedHours">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            *ngFor="let minute of minutes; let i = index"
            [class.active]="selectedMinuteIndex === i"
            [class.disabled]="isRoundedHours">
            <div>{{ minute }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="swiper-component" *ngIf="show12Hours">
      <div
        class="swiper-container timePeriods"
        [swiper]="periodSwiperConfig"
        [(index)]="periodIndex"
        (indexChange)="periodUpdate($event)">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            *ngFor="let timePeriod of timePeriods; let i = index"
            [class.active]="selectedPeriodIndex === i">
            <div>{{ timePeriod }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="static-selection-container" [class.two-columns]="!show12Hours">
      <div class="static-slide first"></div>
      <div class="static-slide middle" [class.two-columns]="!show12Hours"></div>
      <div *ngIf="show12Hours" class="static-slide last"></div>
    </div>
  </div>
</mat-menu>
