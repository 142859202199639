import { Inject, Injectable } from '@angular/core';
import { Attribution, Control, Rotate, Zoom } from 'ol/control';
import * as olMap from 'ol/Map';
import { Subject } from 'rxjs';
import { wcMapToken } from '../../injection-tokens';
import { MapControls } from '../../types';
import { wcControl } from '../classes/abstracts/wc-control';
import { WcControlEvent } from '../classes/custom-controls/wc-control-event';

@Injectable({
  providedIn: 'root',
})
export class ControlsService {
  private _controlsMap = new Map<MapControls, Control>();
  private eventsSubject = new Subject<WcControlEvent>();
  eventSubscriber$ = this.eventsSubject.asObservable();

  constructor(@Inject(wcMapToken) private wcMap: olMap.default) {
    this.mapBasicControls();
  }

  getControlByName(name: MapControls) {
    return this._controlsMap.get(name);
  }

  removeAllControls() {
    this.wcMap
      .getControls()
      .getArray()
      .forEach(control => {
        this.wcMap.removeControl(control);
      });
  }

  setControlState(name: MapControls, isVisible: boolean): void {
    const control = this._controlsMap.get(name);
    if (!control) {
      console.error('could not find control with id:' + name);
      return;
    }
    isVisible ? this.wcMap.addControl(control) : this.wcMap.removeControl(control);
  }

  private mapBasicControls() {
    let controlId: MapControls;
    this.wcMap
      .getControls()
      .getArray()
      .forEach(control => {
        switch (control.constructor) {
          case Zoom:
            controlId = 'zoom';
            break;
          case Rotate:
            controlId = 'rotate';
            break;
          case Attribution:
            controlId = 'attribution';
            break;
          default:
            console.error(`could not find ${control.constructor} proper type`);
            break;
        }
        this._controlsMap.set(controlId, control);
      });
  }

  async addControls(controls: MapControls[]) {
    controls.forEach(async name => {
      let control = this._controlsMap.get(name);
      if (control) return;

      control = await this[name]();
      if (!control) return;
      this._controlsMap.set(name, control);
      this.wcMap.addControl(control);
      const controlEvent = this[`${name}Event`];
      const eventName = (<wcControl>control).eventName;
      if (eventName) {
        control.on(eventName, controlEvent?.bind(this));
      }
    });
  }
}
