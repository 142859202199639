import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AffectedRouteServicesQuery } from '@wc-core';
import { AffectedServiceUI, BaseUIEntity } from '@wc/wc-models';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  FixedBusRouteDetails,
  FixedRouteBusStopDetails,
  TransitAffectingEntityType,
} from '../../../../../../../core/models/gql.models';
import { TransitAffectedServicesService } from '../route-list-tab/affected-services-transit/transit-affected-services.service';

@Component({
  selector: 'wc-selected-route-tab',
  templateUrl: './selected-route-tab.component.html',
  styleUrls: ['../../transit-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedRouteTabComponent {
  selectedAffectedServiceUIEntity$?: Observable<Partial<BaseUIEntity<AffectedServiceUI>> | undefined>;
  _routeDetails?: FixedBusRouteDetails | null;

  @Input() set routeDetails(routeDetails: FixedBusRouteDetails | null) {
    if (!routeDetails) return;
    this._routeDetails = routeDetails;
    this.selectedAffectedServiceUIEntity$ = this.affectedRouteServicesQuery.getAffectedServiceUIByRouteId$(
      routeDetails.id
    );
  }
  @Input() busStopsList!: FixedRouteBusStopDetails[] | null;
  @Input() routeTabIndex = 0;
  @Input() isPortraitDesktopMode = false;
  @Output() clickOnStopId = new EventEmitter<number>();
  @Output() clickToGetPatternId = new EventEmitter<number>();
  @Output() routeTabIndexChanged = new EventEmitter<number>();
  @Output() clickBack = new EventEmitter<0>();

  readonly timerForClearance$ = timer(0, 60000).pipe(map(() => new Date()));

  get affectingEntityType() {
    return TransitAffectingEntityType;
  }

  constructor(
    private affectedServices: TransitAffectedServicesService,
    private affectedRouteServicesQuery: AffectedRouteServicesQuery
  ) {}

  scrollOnCollapseOpened(el: HTMLElement) {
    if (this.isPortraitDesktopMode) return;
    el.scrollIntoView({ behavior: 'smooth' });
  }

  convertToStringWithSign(number: number) {
    const sign = number > 0 ? '+' : '-';
    return sign + Math.abs(number);
  }

  getPatternNameById(id) {
    return this.routeDetails?.patterns.filter(pattern => pattern.id === id)[0]?.name;
  }

  markReroute($event, routeId) {
    this.affectedServices.updateRerouteStatus($event.checked, routeId);
  }
}
