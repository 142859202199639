import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderToggleControlComponent } from './slider-toggle-control.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [SliderToggleControlComponent],
  imports: [CommonModule, MatSlideToggleModule, TranslateModule, ReactiveFormsModule, MatTooltipModule],
  exports: [SliderToggleControlComponent],
})
export class SliderToggleControlModule {}
