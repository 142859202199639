import { Injectable } from '@angular/core';
import { IncidentsQuery } from '@wc-core';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { newBadgeManagement } from '@wc/wc-models/src';
import { action, makeObservable, observable, reaction } from 'mobx';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { ObjSortPipeInterface } from '../../features/ui/pipes/obj-sort.pipe';
import { InteractedRoadEventIds, RoadEventsFilters } from '../models';
import { assigneeGroups, SortOptions } from '../models/enums';
import { Incident } from '../models/gql.models';
import { LocalStorageService } from '../services/local-storage.service';
import { ConstructionStore } from './construction.store';
import { EntitiesStore } from './entities.store';
import { IncidentStore } from './incident.store';
import { LiveMapStore } from './live-map.store';
import { RoadClosureStore } from './road-closure.store';
import { ShiftStore } from './shift.store';
import { SpecialEventStore } from './special-event.store';
import { TrafficDisruptionStore } from './traffic-disruption.store';
import { UiStore } from './ui.store';

@Injectable({
  providedIn: 'root',
})
export class RoadEventsStore {
  @observable sortTypeForNeedActionList: ObjSortPipeInterface = {
    field: SortOptions.TimeNewFirst,
  };

  @observable sortTypeForInProgressList: ObjSortPipeInterface = {
    field: SortOptions.TimeNewFirst,
  };

  @observable sortTypeForCompletedList: ObjSortPipeInterface = {
    field: SortOptions.TimeNewFirst,
  };

  @observable sortTypeForTrafficDisruptionList: ObjSortPipeInterface = {
    field: SortOptions.TimeOldFirst,
  };

  // tablet
  @observable sortTabletAllLists: ObjSortPipeInterface = {
    field: SortOptions.TimeNewFirst,
  };

  @observable selectedFilters: RoadEventsFilters = {
    location: false,
    assignedToMe: false,

    mitigatedByMyAccount: false,
  };

  @observable completedItems: Incident[] = []; // for tablet completed list
  needActionItems$ = new BehaviorSubject<Partial<Incident>[]>([]);

  soundAlertIds$!: Observable<string[]>;
  interactedRoadEventIds: InteractedRoadEventIds = {
    incident: [],

    trafficDisruption: [],
  };

  currentAssignedOpenExpansion: assigneeGroups = 'assignedToMe'; // For tablet

  newBadgeManagement: Record<assigneeGroups, newBadgeManagement> = {
    assignedToMe: {
      isPending: false,
      shouldDisplay$: new ReplaySubject<boolean>(1),
      timeout: null,
    },
    assignedToOther: {
      isPending: false,
      shouldDisplay$: new ReplaySubject<boolean>(1),
      timeout: null,
    },
    unassigned: {
      isPending: false,
      shouldDisplay$: new ReplaySubject<boolean>(1),
      timeout: null,
    },
  };

  private visibleIncidentInProgress!: Observable<Partial<Incident>[]>;
  readonly cachedIncidentIds = this.incidentStore.cachedInprogressIncidents;
  readonly cachedIncidentIdsWhoHadSound = this.incidentStore.cachedInprogressIncidentIdsWhoHadSound;

  constructor(
    private entitiesStore: EntitiesStore,
    public incidentStore: IncidentStore,
    public roadClosureStore: RoadClosureStore,
    public constructionStore: ConstructionStore,
    public specialEventStore: SpecialEventStore,
    public shiftStore: ShiftStore,
    private localStorageService: LocalStorageService,
    public liveMapStore: LiveMapStore,
    public uiStore: UiStore,
    private incidentsQuery: IncidentsQuery,
    private trafficDisruptionStore: TrafficDisruptionStore
  ) {
    makeObservable(this);
    if (!this.uiStore.isTabletMode) {
      this.handleVisibleNeedActionIncidents();
      this.visibleIncidentInProgress = this.incidentsQuery.visibleInProgressIncidents;
      this.trafficDisruptionStore.onTrafficDisruptionEdited$.subscribe(id => {
        this.interactedRoadEventIds['trafficDisruption'] = this.interactedRoadEventIds['trafficDisruption']?.filter(
          interactedEventId => interactedEventId !== id
        );
      });
    } else this.oldApiSupport();
  }

  private handleVisibleNeedActionIncidents() {
    combineLatest([this.incidentsQuery.visibleNeedActionIncidents]).subscribe(([needActionIncidents]) => {
      this.needActionItems$.next([...needActionIncidents]);
    });
  }

  oldApiSupport() {
    this.soundAlertIds$ = this.incidentStore.newSoundAlertEntities$;
    reaction(
      () => this.incidentStore.incidentsNeedActionList,
      () => {
        this.needActionItems$.next([...this.incidentStore.incidentsNeedActionList]);
      }
    );

    if (this.localStorageService.get(LocalStorageKeys.RoadEventFilters)) {
      this.selectedFilters = this.localStorageService.get(LocalStorageKeys.RoadEventFilters);
    }

    this.getFilteredDiffEventsByRouteId(this.selectedFilters.location);
    this.setRoadEventsFilters(this.selectedFilters);
    const shiftObserve = reaction(
      () => this.shiftStore.currentShift,
      () => {
        this.setRoadEventsFilters(this.selectedFilters);
        shiftObserve();
      }
    );

    const isInteractedRoadEventsExist = this.localStorageService.get(LocalStorageKeys.InteractedRoadEventIds);
    if (isInteractedRoadEventsExist) this.interactedRoadEventIds = isInteractedRoadEventsExist;

    this.incidentStore.onIncidentEdited$.subscribe(incidentId => {
      this.interactedRoadEventIds['incident'] = this.interactedRoadEventIds['incident']?.filter(
        interactedEventId => interactedEventId !== incidentId
      );
    });

    this.roadClosureStore.onRoadClosureEdited$.subscribe(roadClosureId => {
      this.interactedRoadEventIds['trafficDisruption'] = this.interactedRoadEventIds['trafficDisruption']?.filter(
        interactedEventId => interactedEventId !== roadClosureId
      );
    });

    // this.constructionStore.onConstructionEdited$.subscribe(constructionId => {
    //   // this.interactedRoadEventIds['trafficDisruption'] = this.interactedRoadEventIds['trafficDisruption']?.filter(
    //   //   interactedEventId => interactedEventId !== constructionId
    //   // );
    // });

    this.specialEventStore.onSpecialEventEdited$.subscribe(specialEventId => {
      this.interactedRoadEventIds['trafficDisruption'] = this.interactedRoadEventIds['trafficDisruption']?.filter(
        interactedEventId => interactedEventId !== specialEventId
      );
    });
  }

  @action
  changeSortOrder(tabName: string, input: ObjSortPipeInterface) {
    this[tabName] = { ...{}, ...input };
  }

  //Set filter fot TABLET
  setRoadEventsFilters(roadEventsFilters: RoadEventsFilters) {
    if (this.selectedFilters.location !== roadEventsFilters.location) {
      this.getFilteredDiffEventsByRouteId(roadEventsFilters.location);
    }
    this.selectedFilters = roadEventsFilters;

    this.liveMapStore.roadEventsFilters = { ...{}, ...roadEventsFilters };
    this.localStorageService.set(LocalStorageKeys.RoadEventFilters, roadEventsFilters);
    this.liveMapStore.refilterMap();
    this.uiStore.hideLoader('tabletFilters');
  }

  getFilteredDiffEventsByRouteId(byShift?) {
    this.entitiesStore.getAllEntities({
      filterRoutesByShiftId: byShift ? this.shiftStore.currentShift.shiftId : undefined,
    });
  }
}
