import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { AssociatedUnitControlModule } from '../associated-unit/associated-unit.module';
import { AssociatedUnitsSectionComponent } from './associated-units.component';

@NgModule({
  declarations: [AssociatedUnitsSectionComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, AssociatedUnitControlModule, SwiperModule],
  providers: [],
  exports: [AssociatedUnitsSectionComponent],
})
export class AssociatedUnitSectionModule {}
