import { FormControl, NgControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { BehaviorSubject } from 'rxjs';

export class AutocompleteErrorStateMatcher implements ErrorStateMatcher {
  constructor(private parentControl: NgControl, private isPanelOpenedSubject: BehaviorSubject<boolean>) {}

  isErrorState(control: FormControl | null): boolean {
    return !!(
      control &&
      this.parentControl &&
      this.parentControl.invalid &&
      !this.isPanelOpenedSubject.getValue() &&
      (this.parentControl.dirty || this.parentControl.touched || control.dirty || control.touched)
    );
  }
}
