import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WcIconComponent } from './wc-icon.component';

@NgModule({
  declarations: [WcIconComponent],
  imports: [CommonModule],
  exports: [WcIconComponent],
})
export class WcIconModule {}
