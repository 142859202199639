import { Injectable } from '@angular/core';
import {
  Account,
  AccountMitigationTypesGQL,
  AccountPermit,
  AccountPermitInput,
  AccountShiftTypesGQL,
  AdditionalInfo,
  CreateShiftTypeGQL,
  CreateShiftTypeInput,
  DeleteShiftTypeGQL,
  DeleteShiftTypeInput,
  IncidentConfiguration,
  IncidentConfigurationGQL,
  IncidentType,
  IncidentTypePriorityGQL,
  MitigationType,
  PermitsByAccountIdGQL,
  ReportSource,
  ShiftType,
  Status,
  UnitsByWorkspacesGQL,
  UnitsGQL,
  UnitVehicle,
  UpdateAccountPermitsGQL,
  UpdateIncidentConfigurationGQL,
  UpdateIncidentConfigurationInput,
  UpdateIncidentTypePriorityGQL,
  UpdateShiftTypeGQL,
  UpdateShiftTypeInput,
  WorkspacesRoadReferencesGQL,
} from '@wc/core';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as Utils from '../../../../utils';
import { CustomRxOperatorsService } from './custom-rx-operators.service';

@Injectable({
  providedIn: 'root',
})
export class AccountApiService {
  constructor(
    private accountMitigationTypesGQL: AccountMitigationTypesGQL,
    private unitsGQL: UnitsGQL,
    private unitsByWorkspacesGQL: UnitsByWorkspacesGQL,
    private workspacesRoadReferencesGQL: WorkspacesRoadReferencesGQL,
    private incidentConfigurationGQL: IncidentConfigurationGQL,
    private updateIncidentConfigurationGQL: UpdateIncidentConfigurationGQL,
    private customOperators: CustomRxOperatorsService,
    private accountShiftTypesGQL: AccountShiftTypesGQL,
    private createShiftTypeGQL: CreateShiftTypeGQL,
    private updateShiftTypeGQL: UpdateShiftTypeGQL,
    private deleteShiftTypeGQL: DeleteShiftTypeGQL,
    private incidentTypePriorityGQL: IncidentTypePriorityGQL,
    private updateIncidentTypePriorityGQL: UpdateIncidentTypePriorityGQL,
    private permitsByAccountIdGQL: PermitsByAccountIdGQL,
    private updateAccountPermitsGQL: UpdateAccountPermitsGQL
  ) {}

  updateAccountInfo(account: Account, modifiedShiftTypes: ShiftType[]) {
    const servicesObservables: Observable<{
      operationName: string;
      data: MitigationType | undefined | boolean | AdditionalInfo | ReportSource;
    }>[] = [];
    const regex = /,"__typename":"\w+"/gm; // Remove all the "__typename" properties from the object.
    account.mitigationTypes = JSON.parse(JSON.stringify(account.mitigationTypes).replace(regex, ''));
    account.additionalInfos = JSON.parse(JSON.stringify(account.additionalInfos).replace(regex, ''));

    if (modifiedShiftTypes) {
      const activeAccountShiftTypes = account.shiftTypes.filter(shift => shift.status === Status.Active);
      const shiftTypesDiff = Utils.entitiesArraysDiff(activeAccountShiftTypes, modifiedShiftTypes);
      const createShiftTypes = shiftTypesDiff.modified.filter((shiftType: ShiftType) => !shiftType.id);
      const updateShiftTypes = shiftTypesDiff.modified.filter((shiftType: ShiftType) => !!shiftType.id);
      createShiftTypes.forEach(shiftType => {
        servicesObservables.push(
          this.createShiftType({
            accountId: account.id,
            name: shiftType.name,
          })
        );
      });
      updateShiftTypes.forEach(shiftType => {
        servicesObservables.push(
          this.updateShiftType({
            shiftTypeId: shiftType.id,
            name: shiftType.name,
          })
        );
      });
      console.log(shiftTypesDiff.removed);
      shiftTypesDiff.removed.forEach(id => {
        servicesObservables.push(this.deleteShiftType({ shiftTypeId: id }));
      });
    }

    return forkJoin(servicesObservables);
  }

  accountMitigationTypes(getAll: boolean) {
    return this.accountMitigationTypesGQL.fetch({ getAll: getAll }).pipe(map(res => res?.data?.accountMitigationTypes));
  }

  updateIncidentConfiguration(input: UpdateIncidentConfigurationInput): Observable<IncidentConfiguration> {
    return this.updateIncidentConfigurationGQL.mutate({ input }).pipe(
      map(res => res?.data?.updateIncidentConfiguration as IncidentConfiguration),
      this.customOperators.catchGqlErrors()
    );
  }

  unitsByAccount(): Observable<UnitVehicle[]> {
    return this.unitsGQL.fetch().pipe(map(res => res?.data?.units as UnitVehicle[]));
  }

  permitsByAccountId(): Observable<AccountPermit[]> {
    return this.permitsByAccountIdGQL.fetch().pipe(map(res => res?.data?.permitsByAccountId as AccountPermit[]));
  }

  updatePermitsByAccountId(input: AccountPermitInput[]): Observable<AccountPermit[]> {
    return this.updateAccountPermitsGQL
      .mutate({ input })
      .pipe(map(res => res?.data?.updateAccountPermits as AccountPermit[]));
  }

  //by jurisdiction
  unitsByWorkspaces() {
    return this.unitsByWorkspacesGQL.fetch().pipe(map(res => res?.data?.unitsByWorkspaces as UnitVehicle[]));
  }

  workspacesRoadReferences() {
    return this.workspacesRoadReferencesGQL.fetch().pipe(map(res => res?.data?.workspacesRoadReferences));
  }

  loadIncidentConfiguration(): Observable<IncidentConfiguration> {
    return this.incidentConfigurationGQL.fetch().pipe(
      map(res => res.data.incidentConfiguration),
      this.customOperators.catchGqlErrors()
    );
  }

  accountShiftTypes(accountId: number) {
    return this.accountShiftTypesGQL.fetch({ accountId: accountId } as never).pipe(
      map(res => res?.data?.accountShiftTypes),
      this.customOperators.catchGqlErrors()
    );
  }

  createShiftType(input: CreateShiftTypeInput) {
    return this.createShiftTypeGQL.mutate({ input }).pipe(
      map(res => res?.data?.createShiftType),
      this.customOperators.catchGqlErrors()
    );
  }

  updateShiftType(input: UpdateShiftTypeInput) {
    return this.updateShiftTypeGQL.mutate({ input }).pipe(
      map(res => res?.data?.updateShiftType),
      this.customOperators.catchGqlErrors()
    );
  }

  deleteShiftType(input: DeleteShiftTypeInput) {
    return this.deleteShiftTypeGQL.mutate({ input }).pipe(
      map(res => res?.data?.deleteShiftType),
      this.customOperators.catchGqlErrors()
    );
  }

  updateIncidentTypePriority(input: IncidentType[]) {
    return this.updateIncidentTypePriorityGQL.mutate({ input: input }).pipe(
      map(res => {
        if (res.errors && res.errors.length > 0) throw res.errors;
        return res?.data?.updateIncidentTypePriority;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getIncidentTypePriority() {
    return this.incidentTypePriorityGQL.fetch().pipe(
      this.customOperators.catchGqlErrors(),
      map(res => res?.data.incidentTypePriority)
    );
  }
}
