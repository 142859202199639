<mat-form-field [class.invalid-field]="formControl.invalid && formControl.touched">
  <mat-label class="field-label">
    <span class="required-section" *ngIf="fieldData.required">*</span>
    {{ fieldData.label | translate }}
  </mat-label>
  <input
    type="number"
    matInput
    [formControl]="$any(fieldFormControl)"
    [placeholder]="fieldData.placeholder"
    (input)="changed()"
    (blur)="onBlur()"
    data-focus />

  <mat-error *ngIf="validationError">
    {{
      'numberValidationErrorMessages.' + $any(validationError)
        | translate
          : {
              min: fieldFormControl.errors?.min?.min,
              max: fieldFormControl.errors?.max?.max,
              minLength: fieldFormControl.errors?.minlength?.requiredLength,
              maxLength: fieldFormControl.errors?.maxlength?.requiredLength
            }
    }}
  </mat-error>
</mat-form-field>
