<div class="camera-gallery-container">
  <div
    data-cy-id="camera-gallery-container-content"
    class="camera-gallery-container__content"
    wcHeapClass="select-camera-from-gallery"
    (click)="selectCamera(camera)"
    [class.selected]="camera.id === selectedCameraId"
    *ngFor="let camera of camerasListFiltered; let idx = index">
    <wc-camera-thumbnail
      data-cy-id="camera-thumbnail"
      [isStreetCamera]="true"
      [isReadMode]="true"
      [isTabletMode]="false"
      [cameraData]="camera">
    </wc-camera-thumbnail>
    <div
      [wcTooltipText]="camera.title"
      wcTooltipPlacement="right"
      class="camera-gallery-container__content__info"
      *wcLet="camera.title | titlePipe: 120 as titleObj">
      <div class="id">{{ 'ID' | translate }}: #{{ titleObj.id }}</div>
      <div class="title">
        {{ titleObj.titleToDisplay }}
      </div>
    </div>
    <div data-cy-id="main-camera" class="camera-gallery-container__content__main-camera" *ngIf="camera.isMain">
      {{ 'mainCamera' | translate }}
    </div>
  </div>
</div>

<button
  data-cy-id="camera-gallery-load-more"
  wcHeapClass="camera-gallery-load-more"
  rekor-basic
  [rightSideIcon]="camerasListFiltered.length === 3 ? 'chevron-down' : 'chevron-up'"
  *ngIf="camerasList.length > 3"
  (click)="toggleDisplayedAmount()">
  {{ (camerasListFiltered.length === 3 ? 'showMore' : 'showLess') | translate }}
</button>
