import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WcIconModule } from '@wc/wc-ui/src';
import { FormatsPipesModule } from '../../pipes/formats.pipes.module';
import { CongestionModalComponent } from './congestion-modal.component';

@NgModule({
  declarations: [CongestionModalComponent],
  imports: [CommonModule, TranslateModule, FormatsPipesModule, WcIconModule],
  exports: [CongestionModalComponent],
})
export class CongestionModalComponentModule {}
