<div
  [wcInvalidExpansionCssClass]="!action.valid && action.touched"
  [id]="'rp-action-item' + actionIndex"
  [class.highlighted-action]="newAddedRowIndex === actionIndex"
  class="response-plan-form__action-list-container"
  [formGroup]="action">
  <div class="response-plan-form__item-field--time">
    <wc-numeric-increase-decrease
      data-cy-id="entity-edit-response-plan-time"
      [minInput]="minInput"
      [maxInput]="maxInput"
      formControlName="time"
      [formFieldData]="responsePlanFormFiledData['time']"></wc-numeric-increase-decrease>
  </div>
  <div class="response-plan-form__item-field--time-unit" data-cy-id="entity-edit-response-plan-unit">
    <wc-select [formFieldData]="responsePlanFormFiledData['unit'][entityType]" formControlName="unit"></wc-select>
  </div>
  <div *ngIf="uiMode === uiModeEnum.Entity" class="response-plan-form__item-field--time-badge">
    {{ (action.get('timestamp')?.value | dateFormat: regionalSetting) || ('noTimePreview' | translate) }}
  </div>
  <div class="response-plan-form__item-field--actionField" data-cy-id="entity-edit-response-plan-action-field">
    <wc-select
      [formFieldData]="responsePlanFormFiledData['actionField'][entityType]"
      formControlName="actionField"></wc-select>
  </div>
  <div class="response-plan-form__item-field--action-values" data-cy-id="entity-edit-response-plan-value">
    <ng-container [ngSwitch]="action.get('actionField')?.value">
      <ng-container *ngSwitchCase="actionType.ChangeDms">
        <ng-container *ngIf="responsePlanService.actionFieldsFieldData.CHANGE_DMS?.asyncOptions | async">
          <wc-autocomplete-v2
            [selectedChipsVisible]="true"
            [allowMultiSelect]="true"
            [fieldData]="{
              label: 'responsePlanFields.dms.label' | translate,
              placeholder: 'responsePlanFields.dms.placeholder' | translate,
              options: (responsePlanService.actionFieldsFieldData.CHANGE_DMS.asyncOptions | async)!
            }"
            [formControl]="$any(action.get('value'))"></wc-autocomplete-v2>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="actionType.RoadClosure">
        <wc-autocomplete-v2
          [selectedChipsVisible]="true"
          queryDataKey="roadNamesByAccount"
          [optionsQuery]="$any(roadNamesByAccountGQL)"
          [allowMultiSelect]="true"
          [fieldData]="{
            label: 'responsePlanFields.roadClosure.label' | translate,
            placeholder: 'responsePlanFields.roadClosure.placeholder' | translate
          }"
          [formControl]="$any(action.get('value'))"></wc-autocomplete-v2>
      </ng-container>
      <ng-container *ngSwitchCase="actionType.OpenRoadClosure">
        <wc-autocomplete-v2
          [selectedChipsVisible]="true"
          queryDataKey="roadNamesByAccount"
          [optionsQuery]="$any(roadNamesByAccountGQL)"
          [allowMultiSelect]="true"
          [fieldData]="{
            label: 'responsePlanFields.roadClosure.label' | translate,
            placeholder: 'responsePlanFields.roadClosure.placeholder' | translate
          }"
          [formControl]="$any(action.get('value'))"></wc-autocomplete-v2>
      </ng-container>
      <ng-container *ngSwitchCase="actionType.ShareToPublic">
        <wc-autocomplete-v2
          [selectedChipsVisible]="true"
          [allowMultiSelect]="true"
          [fieldData]="{
            label: 'responsePlanFields.publishChannels.label' | translate,
            placeholder: 'responsePlanFields.publishChannels.placeholder' | translate,
            options: responsePlanService.actionFieldsFieldData.SHARE_TO_PUBLIC.options
          }"
          [formControl]="$any(action.get('value'))"></wc-autocomplete-v2>
      </ng-container>
      <ng-container *ngSwitchCase="actionType.UpdateIncidentDetails">
        <wc-autocomplete-v2
          [selectedChipsVisible]="true"
          [allowMultiSelect]="true"
          [fieldData]="{
            label: 'responsePlanFields.incidentUpdateDetails.label' | translate,
            placeholder: 'responsePlanFields.incidentUpdateDetails.placeholder' | translate,
            options: responsePlanService.actionFieldsFieldData.UPDATE_INCIDENT_DETAILS.options
          }"
          [formControl]="$any(action.get('value'))"></wc-autocomplete-v2>
      </ng-container>

      <ng-container *ngSwitchCase="actionType.Contacts">
        <wc-autocomplete-v2
          [selectedChipsVisible]="true"
          [allowMultiSelect]="true"
          [fieldData]="{
            label: 'responsePlanFields.contacts.label' | translate,
            placeholder: 'responsePlanFields.contacts.placeholder' | translate,
            options: responsePlanService.actionFieldsFieldData.CONTACTS.options
          }"
          [formControl]="$any(action.get('value'))"></wc-autocomplete-v2>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <wc-text-field
          [formFieldData]="{
            label: 'responsePlanFields.default.label' | translate,
            placeholder: 'responsePlanFields.default.placeholder' | translate
          }"
          formControlName="value"></wc-text-field>
      </ng-container>
    </ng-container>
  </div>
  <div class="response-plan-form__item-field--free-text" data-cy-id="entity-edit-response-plan-text-field">
    <wc-text-field [formFieldData]="responsePlanFormFiledData['text']" formControlName="text"></wc-text-field>
  </div>

  <div class="response-plan-form__edit-icon-container" (wcClickOutside)="clickedOutside(actionIndex)">
    <i
      (click)="clickOnItemOption(actionIndex)"
      data-cy-id="entity-edit-response-plan-burger-btn"
      class="response-plan-form__edit-icon fas fa-ellipsis-v"></i>
    <div *ngIf="selectedAction === actionIndex" class="response-plan-form__action-pop-up">
      <div
        (click)="addRow(actionIndex)"
        data-cy-id="entity-edit-response-plan-add-action-above-btn"
        class="response-plan-form__popup-row"
        [class.response-plan-form__popup-row--disabled]="actionsLength >= maxActionCount">
        <i class="fas fa-solid fa-plus"></i>
        <span>{{ 'addNewActionAbove' | translate }}</span>
      </div>
      <div
        (click)="addRow(actionIndex + 1)"
        data-cy-id="entity-edit-response-plan-add-action-below-btn"
        class="response-plan-form__popup-row">
        <i class="fas fa-solid fa-plus"></i>
        <span>{{ 'addNewActionBelow' | translate }}</span>
      </div>
      <div
        data-cy-id="entity-edit-response-plan-delete-btn"
        (click)="removeRow(actionIndex)"
        class="response-plan-form__popup-row"
        [class.response-plan-form__popup-row--disabled]="actionsLength === 1">
        <i class="fas fa-solid fa-trash"></i>
        <span>{{ 'confirmModal.deleteButton' | translate }}</span>
      </div>
    </div>
  </div>
</div>
