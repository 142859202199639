import { Injectable } from '@angular/core';
import { AffectedRouteServicesQuery, AffectedUnitServicesQuery, EntitiesServiceV2, TransitService } from '@wc-core';
import { LocalStorageService, SplitIOService, WebsocketService } from '@wc/core';
import { WebSocketType } from '@wc/core/models/enums';
import { CustomRxOperatorsService } from '@wc/core/services/custom-rx-operators.service';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { AffectedServiceIUEntity, AffectedServicesStoreNames, AllAffectedServiceUpdate } from '@wc/wc-models';
import { ReplaySubject, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AffectedTransitEntities, AffectedTransitServicesGQL } from '../../../../../../../../core/models/gql.models';

@Injectable({
  providedIn: 'root',
})
export class TransitAffectedServicesService {
  private _subscriptions: Subscription[] = [];

  constructor(
    private affectedTransitServicesGQL: AffectedTransitServicesGQL,
    private customRxOperators: CustomRxOperatorsService,
    private wsService: WebsocketService,
    private localStorageService: LocalStorageService,
    private entitiesService: EntitiesServiceV2,
    private splitIOService: SplitIOService,
    private transitService: TransitService,
    private affectedRouteServicesQuery: AffectedRouteServicesQuery,
    private affectedUnitServicesQuery: AffectedUnitServicesQuery
  ) {}
  selectedAffectedServiceUIEntity = new ReplaySubject<AffectedServiceIUEntity | null>(1);
  viewedAffectedServiceIds =
    new Set(this.localStorageService.get(LocalStorageKeys.ViewedAffectedServiceIds)) || new Set();
  viewedTrainDelayIds = new Set(this.localStorageService.get(LocalStorageKeys.ViewedTrainDelayIds)) || new Set();

  clearSubscriptions() {
    this._subscriptions.forEach(sub => sub.unsubscribe());
    this._subscriptions = [];
  }

  private startsListenToAffectedServicesUpdates(): void {
    const sub = this.wsService
      .onMessage()
      .pipe(filter(res => res.type === WebSocketType.affectedTransitServiceUpdate))
      .subscribe((updates: AllAffectedServiceUpdate) => {
        this.entitiesService.emitNewEntitiesDiff({
          modified: {
            [AffectedServicesStoreNames.AffectedRouteServices]: updates.affectedTransitEntities.affectedFixedBusRoutes,
            [AffectedServicesStoreNames.AffectedUnitServices]: updates.affectedTransitEntities.affectedTransitUnits,
          },
          removed: {
            [AffectedServicesStoreNames.AffectedRouteServices]: updates.clearedFixedBusRoutes,
            [AffectedServicesStoreNames.AffectedUnitServices]: updates.clearedTransitUnits,
          },
        });
      });

    this._subscriptions.push(sub);
  }

  fetchAffectedServicesAndEmitDiff() {
    return this.affectedTransitServicesGQL.fetch().pipe(
      this.customRxOperators.catchGqlErrors(),
      this.customRxOperators.tapOnce(() => this.startsListenToAffectedServicesUpdates()),
      map<any, AffectedTransitEntities>(res => res.data.affectedTransitServices),
      tap(({ affectedFixedBusRoutes, affectedTransitUnits }) => {
        this.entitiesService.emitNewEntitiesDiff({
          modified: {
            [AffectedServicesStoreNames.AffectedRouteServices]: affectedFixedBusRoutes,
            [AffectedServicesStoreNames.AffectedUnitServices]: affectedTransitUnits,
          },
        });
      })
    );
  }

  updateRerouteStatus(isRerouted: boolean, routeId) {
    this.transitService.rerouteFixedBusRoute(routeId, isRerouted).subscribe({
      next: () => {
        // this.entitiesService.emitNewEntitiesDiff({
        //   modified: {
        //     [AffectedServicesStoreNames.AffectedRouteServices]: [{ routeId, isRerouted }],
        //   },
        // });
        this.entitiesService.emitNewUIDiff({
          [AffectedServicesStoreNames.AffectedRouteServices]: [{ routeId, isRerouted }],
        });
      },
      error: err => console.error('Failed to Reroute affected service', err),
    });
  }

  setAffectedServicesAsViewed(affectedServiceIUEntities: AffectedServiceIUEntity[]) {
    const modified = affectedServiceIUEntities.reduce(
      (pre, curr) => {
        pre[
          curr.routeId
            ? AffectedServicesStoreNames.AffectedRouteServices
            : AffectedServicesStoreNames.AffectedUnitServices
        ].push({ ...curr, isViewed: true });
        return pre;
      },
      {
        [AffectedServicesStoreNames.AffectedRouteServices]: [] as AffectedServiceIUEntity[],
        [AffectedServicesStoreNames.AffectedUnitServices]: [] as AffectedServiceIUEntity[],
      }
    );
    this.entitiesService.emitNewUIDiff(modified);

    const viewedAffectedServices: AffectedTransitEntities = {
      affectedFixedBusRoutes: this.affectedRouteServicesQuery.ui
        .getAll({ filterBy: affectedRouteUI => !!affectedRouteUI.isViewed })
        .map(affectedRouteUI => affectedRouteUI.id) as any,
      affectedTransitUnits: this.affectedUnitServicesQuery.ui
        .getAll({ filterBy: affectedRouteUI => !!affectedRouteUI.isViewed })
        .map(affectedRouteUI => affectedRouteUI.id) as any,
    };
    this.localStorageService.set(LocalStorageKeys.ViewedAffectedServices, viewedAffectedServices);
  }
}
