import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Optional,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { HeapAnalyticsService } from '@wc-core';
import { IconClassName } from '../../../../wc-models/src/lib/types/icon-class-names-type';
import { CustomFormControlComponent, FormFieldOption } from '../../lib/base/custom-form-control';
import { WCErrorStateMatcher } from '../../lib/base/error-state-matcher';

@Component({
  selector: 'wc-select',
  templateUrl: './wc-select.component.html',
  styleUrls: ['./wc-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent extends CustomFormControlComponent {
  @ViewChild(MatSelect, { static: false }) matSelect!: MatSelect;
  emptyControl = new FormControl(null);
  _showLoadMoreButton = true;
  _isLoadMoreView = false;

  matcher: ErrorStateMatcher = new WCErrorStateMatcher();

  @Input() showLabel = true;
  @Input() prefixIcon: IconClassName = '';
  @Input() useGrouping = false;
  @Input() isDirectionRtl = false;
  @Input() dynamicClass = '';
  @Input() showErrorMsg = true;
  @Input() isMultiple = false;

  @Input() set isLoadMoreView(val: boolean) {
    this._isLoadMoreView = val;
  }

  @Input() set disabled(val: boolean) {
    this._disabled = val;
  }

  constructor(
    cdr: ChangeDetectorRef,
    @Self() @Optional() public ngControl: NgControl,
    private heapService: HeapAnalyticsService
  ) {
    super(ngControl, cdr);
  }

  get visibleOptions() {
    return this.formFieldData?.options?.filter(op => !op.hidden && op.value !== this.ngControl.value) || [];
  }

  get hiddenOptions() {
    return this.formFieldData?.options?.filter(op => op.hidden === true && op.value !== this.ngControl.value) || [];
  }

  get displayNameOfControlValue() {
    return this.formFieldData?.options?.find(op => op.value === this.ngControl.value)?.displayName || '';
  }

  showMoreClicked(event: MouseEvent) {
    event.stopPropagation();
    this._showLoadMoreButton = false;
  }

  get groupedOptions() {
    const groupedOptions: Record<string, FormFieldOption<unknown, unknown>[]> = {};

    if (this.useGrouping)
      this.formFieldData.options?.forEach(option => {
        if (option.groupLabel) {
          if (!groupedOptions[option.groupLabel]) {
            groupedOptions[option.groupLabel] = [];
          }
          groupedOptions[option.groupLabel].push(option);
        }
      });

    return { groupedOptions, labels: Object.keys(groupedOptions) };
  }

  onBlur() {
    this.onTouched();
    this.heapService.trackUserSpecificAction(
      `heap-${this.formFieldData.heapClass ? this.formFieldData.heapClass : 'select'}-opened`
    );
  }
  /**
   * Mat select requires to set active item manually. it also automatically
   * scrolls to active item, so we need to restore scroll
   * position after setting active item
   * @param value the value of the option to be set as active
   */
  setActiveOption(value: unknown) {
    const panel = this.matSelect.panel.nativeElement;
    const currentScroll = panel.scrollTop;

    const index = !this._isLoadMoreView
      ? this.getIndexInOptions(value)
      : this.getValues().findIndex(_value => _value === value);

    if (index !== undefined && index !== -1) {
      this.matSelect._keyManager.setActiveItem(index);
    } else if (index === -1 && value === 'LOAD_MORE') {
      const index = Array.from(this.matSelect.options).findIndex(option => option.value === 'LOAD_MORE');
      this.matSelect._keyManager.setActiveItem(index);
    }

    // Restore scroll position
    panel.scrollTop = currentScroll;
  }

  openPanel() {
    this.matSelect.open();
  }

  private getIndexInOptions(value: unknown): number {
    return this.formFieldData.options?.findIndex(_option => _option.value === value) ?? -1;
  }

  private getValues(): unknown[] {
    return [this.ngControl.value]
      .concat([...this.visibleOptions, ...this.hiddenOptions].map(op => op.value))
      .filter(value => !!value);
  }
}
