import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TimeFormat } from '@wc/core/models';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'minutesDurationToTime',
})
export class MinutesDurationToTimePipe implements PipeTransform {
  transform(minutes: number, timeFormat: TimeFormat): string {
    const midnight = moment().utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    return timeFormat === TimeFormat.TwelveHours
      ? midnight.add(minutes, 'minutes').format('hh:mm A')
      : midnight.add(minutes, 'minutes').format('HH:mm');
  }
}
