import { Observable } from 'rxjs';
import { IconClassName } from './icon-class-names-type';

export abstract class AbstractLayerTreePanelService {
  abstract handleUITreePanelStateUpdates(data: LayerVisibilityItem[]): void;
  abstract getTreeData(): Observable<LayerTreePanelNode[]>;
  abstract getNodesUpdate(): Observable<LayerVisibilityItem[]>;
}

export type LayerTreePanelNode = {
  checked: boolean;
  isDisabled?: boolean;
  name: string;
  hasParent?: boolean;
  children: LayerTreePanelNode[] | null;
  cssClasses?: Array<'main-title' | 'stand-alone-layer' | 'root-level-node' | 'border-bottom' | 'display-none'>;
  iconClass?: IconClassName;
  action?: undefined | ((componentInstance: any, node: LayerTreePanelNode) => any);
};

export type LayerVisibilityItem = Pick<LayerTreePanelNode, 'name' | 'checked' | 'isDisabled'>;
