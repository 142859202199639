import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { WcIconModule } from '@wc/wc-ui/src/components/wc-icon/wc-icon.module';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { RelatedEventsAutocompleteV2Component } from './related-events-autocomplete-v2.component';

const Materials: any[] = [MatSelectModule, MatInputModule, MatIconModule, MatButtonModule];

@NgModule({
  declarations: [RelatedEventsAutocompleteV2Component],
  imports: [CommonModule, WcBaseControlFieldModule, TranslateModule, Materials, WcIconModule],
  providers: [],
  exports: [RelatedEventsAutocompleteV2Component],
})
export class RelatedEventsAutocompleteV2Module {}
