import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MediaThumbnailModule } from '../media-thumbnail/media-thumbnail.module';
import { WcTooltipModule } from '../tooltip/tooltip.module';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { CctvSnapshotsComponent } from "./cctv-snapshots.component";
import { CctvSnapshotsFullScreenModule } from "../cctv-snapshots-full-screen/cctv-snapshots-full-screen.module";

@NgModule({
	declarations: [CctvSnapshotsComponent],
	imports: [
		CommonModule,
		MediaThumbnailModule,
		TranslateModule,
		CctvSnapshotsFullScreenModule,
		WcIconModule,
		WcTooltipModule
	],
	exports: [CctvSnapshotsComponent],
})
export class CctvSnapshotsModule {}