import { Injectable } from '@angular/core';
import { isFunction } from '@wc/wc-common/src';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { distinctUntilKeyChanged, map, mergeMap } from 'rxjs/operators';

const initialState = Object.freeze({
  routeChange: false,
  roadClosure: false,
  construction: false,
  specialEvent: false,
  incident: false,
  shift: false,
  editShift: false,
  analytics: false,
  address: false,
  venues: false,
  shiftDetails: false,
  transitPanel: false,
  transitMap: false,
  trafficDisruptions: false,
  tabletFilters: false,
  incidentConfigurations: false,
  roadwayStatusPanel: false,
  eventViewPanel: false,
});

export type LoaderOptions = {
  routeChange: boolean;
  roadClosure: boolean;
  construction: boolean;
  specialEvent: boolean;
  incident: boolean;
  shift: boolean;
  editShift: boolean;
  analytics: boolean;
  address: boolean;
  shiftDetails: boolean;
  transitPanel: boolean;
  transitMap: boolean;
  trafficDisruptions: boolean;
  tabletFilters: boolean;
  incidentConfigurations: boolean;
  venues: boolean;
  roadwayStatusPanel: boolean;
  eventViewPanel: boolean;
};

type TState = Record<keyof LoaderOptions, boolean>;

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _loaders$ = new BehaviorSubject<TState>(initialState);

  update(state: Partial<TState>);
  update(fn: (state: TState) => TState);
  update(stateOrFunction: ((state: TState) => TState) | Partial<TState>) {
    const _newState = isFunction(stateOrFunction) ? stateOrFunction(cloneDeep(this._loaders$.value)) : stateOrFunction;
    this._loaders$.next(Object.freeze({ ...this.getValue(), ..._newState }));
  }

  select<T extends keyof TState | undefined = undefined>(
    loaderOption?: T
  ): Observable<T extends keyof LoaderOptions ? boolean : TState> {
    return this._loaders$.asObservable().pipe(
      mergeMap(state =>
        iif(
          () => loaderOption !== undefined && state[loaderOption as keyof LoaderOptions],
          of(state).pipe(distinctUntilKeyChanged(loaderOption as keyof LoaderOptions)),
          of(state)
        )
      ),
      map(state => (loaderOption !== undefined ? state[loaderOption as keyof LoaderOptions] : state))
    ) as Observable<any>;
  }

  getValue(): TState {
    return this._loaders$.value;
  }

  /**
   * @description Sets back the initial state of the store
   */
  reset() {
    this._loaders$.next(initialState);
  }
}
