/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/ban-types */
import { Inject, Injectable } from '@angular/core';
import * as olColor from 'ol/color';
import { FeatureLike } from 'ol/Feature';
import { LineString, MultiPoint, Point, SimpleGeometry } from 'ol/geom';
import { circular as circularPolygon } from 'ol/geom/Polygon.js';
import { Fill, Icon, RegularShape, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { ThemeName, WcGeometryEnum } from '../../enums';
import { mapViewerConfigToken } from '../../injection-tokens';
import { wcFeatureProperties, WcMapConfigModel } from '../../interfaces';
import {
  AreaStyleArgs,
  AttributeSourceField,
  BaseCircleArgs,
  CircleOptions,
  CircleStyleArgs,
  DashLineStyleArgs,
  EntityUIState,
  GeometryStyle,
  IconOptions,
  IconStyle,
  IconStyleArgs,
  RegularShapeStyleArgs,
  SolidLineStyleArgs,
  strokeStyleArgs,
  StyleArgs,
  TextStyle,
  TextStyleArgs,
  wcCoordinate,
  wcCoordinateTypes,
  WcLayerOptions,
} from '../../types';
import { WcFeature } from '../classes/wc-feature.class';
import { calculateAngle, hexAToRGB } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class StyleService {
  private _currLayer!: string;
  geomTypeToStyleKey: {
    Point: string;
    LineString: string;
    Polygon: string;
    MultiPolygon: string;
    MultiLineString: string;
    startPointStatusStyle: string;
    endPointStatusStyle: string;
  };
  set currentLayer(value: string) {
    this._currLayer = value;
  }

  private readonly _styleCache = new Map<string, (Style | ((...args) => Style))[]>();
  private readonly styleFunctionsMap = new Map<string, any>();
  private readonly featuresWithCustomContextMap = new Map<string, WcFeature<string>[] | null>();

  constructor(@Inject(mapViewerConfigToken) private mapConfig: WcMapConfigModel<string, wcCoordinateTypes, string>) {
    this.geomTypeToStyleKey = {
      [WcGeometryEnum.POINT]: 'pointStatusStyle',
      [WcGeometryEnum.LINE_STRING]: 'lineStringStatusStyle',
      [WcGeometryEnum.POLYGON]: 'polygonStatusStyle',
      [WcGeometryEnum.MULTI_POLYGON]: 'polygonStatusStyle',
      [WcGeometryEnum.MULTI_LINE_STRING]: 'multiLineStringStatusStyle',
      startPointStatusStyle: 'startPointStatusStyle',
      endPointStatusStyle: 'endPointStatusStyle',
    };
    if (mapConfig?.customStyleContexts?.length) {
      mapConfig.customStyleContexts.forEach(context =>
        this.styleFunctionsMap.set(
          context,
          this.styleFunction.bind({
            styleService: this,
            context,
          })
        )
      );
    }
  }

  setFeaturesWithStyleContext(features: WcFeature<string>[], context: string) {
    const prevFeatures = this.featuresWithCustomContextMap.get(context);
    if (prevFeatures) {
      prevFeatures
        //We don't want to remove the style function if another context was given by a third-party
        .filter(f => (f.getStyleFunction() as Function)?.(null as any, null as any, true) === context)
        .forEach(f => f.setStyle());
    }
    const styleFunction = this.styleFunctionsMap.get(context);
    if (features.length && styleFunction) {
      features.forEach(f => f.setStyle(styleFunction));
      this.featuresWithCustomContextMap.set(context, features);
    } else this.featuresWithCustomContextMap.set(context, null);
  }

  styleFunction(f: FeatureLike, _resolution: number, getContext = false): Style[] {
    if (getContext) {
      return this['context'];
    }
    // If feature has children, its a Cluster, so we take the 0 element. if not, it's a WcFeature, and we just take it.
    const _feature: WcFeature<string> = f.get('features')?.[0] || f;

    //Usually the layerName is provided if the style is from the function in the layer level
    //That not the case when interacting with a specific feature (e.g hover, select).
    //In that case, we will take the feature's layer from 'parentLayerName' property.
    //-------------------------------------------------------------------------
    const layerName = this['layerName'] || _feature.get('parentLayerName');
    const styleService: StyleService = this['styleService'];
    const fProps = _feature.getProperties() as wcFeatureProperties<string>;
    let styleKeyWithContext;
    //-------------------------------------------------------------------------

    if (!_feature.get('isVisible')) {
      return [];
    }

    if (!styleService?.mapConfig?.layers?.[layerName]) {
      console.error(`Style was not found for layer ${layerName}`);
      return [];
    }

    const startAndEndPointStyles = styleService
      .getStartAndEndPointStyles(
        _feature,
        fProps.entityStatusForStyle,
        styleService?.mapConfig.layers[layerName] as WcLayerOptions<string>,
        this['context']
      )
      ?.map(args => styleService.transformStylePropToStyle(args, fProps, f));

    // We don't want to take cached style if the feature being interacted.
    //--------------------------------------------------------------------------
    if (fProps.styleKey && !this['featureBeingEdited']) {
      //------------------------------------------------------------------------
      styleKeyWithContext = fProps.styleKey + `${this['context']}_`;
      const cachedStyle = styleKeyWithContext && styleService._styleCache.get(styleKeyWithContext);
      if (cachedStyle) {
        return [...cachedStyle, ...startAndEndPointStyles].map(style => styleService.mapToStyle(style, fProps, f));
      }
    }

    //If cached style was not found, we will generate new style based on properties provided
    // -------------------------------------------------------------------------------------
    const stylesAndStyleFunctions = styleService
      .getStylePropsArray(
        fProps.geomType,
        fProps.entityStatusForStyle,
        styleService?.mapConfig.layers?.[layerName] as WcLayerOptions<string>,
        this['context'],
        layerName
      )
      ?.map(args => styleService.transformStylePropToStyle(args, fProps, f));
    // -------------------------------------------------------------------------------------

    if (!stylesAndStyleFunctions) {
      console.warn('Returning default style for:', fProps.entitySubType, fProps.id);
    }

    return [...stylesAndStyleFunctions, ...startAndEndPointStyles].map(style =>
      styleService.mapToStyle(style, fProps, f)
    );
  }

  mapToStyle = (style, fProps: wcFeatureProperties<string>, f) =>
    style instanceof Function ? style(fProps, { originalFeature: f }) : style;

  getStartAndEndPointStyles(
    f: WcFeature<string>,
    entityState: string,
    layerStyleOptions: WcLayerOptions<string>,
    context: EntityUIState
  ): StyleArgs[] | [] {
    const { firstCoordinate, lastCoordinate } = f;
    let firstStyle: StyleArgs[] = [];
    let lastStyle: StyleArgs[] = [];
    let entityStatusStyleByState;

    if (firstCoordinate) {
      if (layerStyleOptions.startPointStatusStyle?.[entityState]) {
        entityStatusStyleByState = layerStyleOptions.startPointStatusStyle?.[entityState];
      } else {
        console.warn('Could not find startPointStatusStyle style for status:', entityState);
        entityStatusStyleByState = layerStyleOptions.startPointStatusStyle?.default;
      }

      firstStyle =
        entityStatusStyleByState?.[context]?.map(styleArg => ({
          ...styleArg,
          coordinates: firstCoordinate,
        })) || [];
    }

    if (lastCoordinate) {
      if (layerStyleOptions.endPointStatusStyle?.[entityState]) {
        entityStatusStyleByState = layerStyleOptions.endPointStatusStyle?.[entityState];
      } else {
        console.warn('Could not find endPointStatusStyle style for status:', entityState);
        entityStatusStyleByState = layerStyleOptions.endPointStatusStyle?.default;
      }
      lastStyle =
        entityStatusStyleByState?.[context]?.map(styleArg => ({
          ...styleArg,
          coordinates: lastCoordinate,
        })) || [];
    }
    return [...lastStyle, ...firstStyle];
  }

  getStylePropsArray(
    geometryType: WcGeometryEnum,
    entityState: string,
    layerOptions: WcLayerOptions<string>,
    uiState: string,
    layerName: string
  ): StyleArgs[] {
    if (!(geometryType || layerOptions)) {
      console.error(
        `Please provide valid layer options and geometry type. provided: ${layerOptions} and ${geometryType}`
      );
      return [];
    }
    const geomStyle: GeometryStyle<string> = layerOptions?.[this.geomTypeToStyleKey[geometryType]];
    let entityStatusStyleByState;
    if (!geomStyle) {
      console.error(`Could not find style with geometryType: ${this.geomTypeToStyleKey[geometryType]}`);
      return [];
    }

    if (geomStyle[entityState]) {
      entityStatusStyleByState = geomStyle[entityState];
    } else {
      console.warn('Could not find style for status:', entityState);
      entityStatusStyleByState = geomStyle['default'];
    }

    if (!entityStatusStyleByState) {
      console.error(`Could not find style with entityState: '${entityState}' or 'default'`);
      return [];
    }
    const styleProps = entityStatusStyleByState[uiState];
    if (!styleProps) {
      console.warn(
        `Could not find style in path: ${layerName} -> ${this.geomTypeToStyleKey[geometryType]} -> ${entityState} -> ${uiState}. returning default interaction.`
      );
      return entityStatusStyleByState['default'] as StyleArgs[];
    }
    return styleProps as StyleArgs[];
  }

  transformStylePropToStyle(
    styleProps: StyleArgs,
    fProps?: wcFeatureProperties<string>,
    originalFeature?: FeatureLike
  ): Style | ((fProps: wcFeatureProperties<any>, extras: { originalFeature: FeatureLike | undefined }) => Style) {
    let iconStyle: IconStyle;
    let originalIconName: string | AttributeSourceField | undefined;
    let originalRotation: number | AttributeSourceField | undefined;

    const color =
      (this.mapConfig.mapTheme.themeName === ThemeName.dark ? styleProps.darkColor : styleProps.color) || '';
    const radius = styleProps.radius || 1;
    const opacity = styleProps.opacity || 1;

    if (!this.isVisibleStyle(styleProps, fProps)) return new Style();

    switch (styleProps.shape) {
      case 'marker':
        iconStyle = { ...this.mapConfig.defaultIconStyle, ...styleProps.icon } as IconStyle;
        originalRotation = iconStyle.rotation;
        originalIconName = iconStyle.iconName;

        const markerStyleFunction = (
          fProps: wcFeatureProperties<string> | undefined,
          extras: { originalFeature: FeatureLike | undefined }
        ) => {
          let clusterLength = extras?.originalFeature?.get?.('features')?.length || 0;
          clusterLength = clusterLength > 99 ? 99 : clusterLength;

          /**
           * @todo improve this functionality
           */

          if (
            typeof styleProps.icon?.iconName === 'string' &&
            (styleProps.icon?.iconName?.includes('counter') ||
              styleProps.icon?.iconName?.includes('select_highlight_circle')) &&
            clusterLength <= 1
          ) {
            return new Style();
          }

          iconStyle.iconName = this.getPropSrc(originalIconName, fProps) as string;
          const _rotation = this.getPropSrc(originalRotation, fProps) as number;
          iconStyle.rotation = _rotation === -1 ? 90 : _rotation;

          if (!(iconStyle.iconName || iconStyle.src)) return new Style();
          return this.marker({
            imageConfig: iconStyle,
            coordinates: styleProps.coordinates,
          });
        };
        return typeof originalRotation === 'object' || originalIconName === 'object' || styleProps.isStyleFn
          ? markerStyleFunction
          : markerStyleFunction(fProps, { originalFeature });
      case 'circle':
        /**
         * @todo:Fix: circle is not shown on the map.
         */

        return this.circle({
          color: fProps ? (this.getPropSrc(color, fProps) as string) : (styleProps.color as string),
          radius,
          opacity,
          zIndex: styleProps.zIndex,
        });
      case 'circleIcon':
        return this.circleIcon({
          radius,
          color: fProps ? (this.getPropSrc(color, fProps) as string) : (styleProps.color as string),
          stroke: styleProps.stroke as strokeStyleArgs,
          opacity: styleProps.opacity,
          zIndex: styleProps.zIndex,
        });

      case 'area':
        return this.area({
          color: fProps ? (this.getPropSrc(color, fProps) as string) : (styleProps.color as string),
          opacity,
          zIndex: styleProps.zIndex,
        });
      case 'lineDash':
        return this.dashLine({
          color: fProps ? (this.getPropSrc(color, fProps) as string) : (styleProps.color as string),
          width: styleProps.width,
          opacity: styleProps.opacity,
          zIndex: styleProps.zIndex,
          dash: styleProps.dash,
        });
      case 'lineSolid':
        const _textStyle: TextStyle | undefined = styleProps.label;

        const solidLineFunction = (
          _fProps: wcFeatureProperties<string> | undefined,
          extras: { originalFeature: FeatureLike | undefined }
        ) => {
          const _style = this.solidLine({
            color: _fProps ? (this.getPropSrc(color, _fProps) as string) : (styleProps.color as string),
            width: styleProps.width,
            opacity: styleProps.opacity,
            zIndex: styleProps.zIndex,
          });
          if (_textStyle) {
            let clusterLength = extras?.originalFeature?.get?.('features')?.length || 0;
            clusterLength = clusterLength > 99 ? 99 : clusterLength;
            const textProps = {
              text:
                _textStyle.text === 'length'
                  ? clusterLength > 1
                    ? clusterLength.toString()
                    : ''
                  : (this.getPropSrc(_textStyle.text, _fProps) as string),
              color:
                (this.mapConfig.mapTheme.themeName === ThemeName.dark ? _textStyle.darkColor : _textStyle.color) || '',
              textAlign: _textStyle.textAlign,
              textBaseline: _textStyle.textBaseline,
              size: _textStyle.size,
              height: _textStyle.height,
              offsetX: _textStyle.offsetX,
              offsetY: _textStyle.offsetY,
              weight: _textStyle.weight,
              placement: _textStyle.placement,
              maxAngle: _textStyle.maxAngle,
              fontFamily: _textStyle.fontFamily,
              overflow: _textStyle.overflow,
              rotation: _textStyle.rotation,
              outlineColor:
                this.mapConfig.mapTheme.themeName === ThemeName.dark && _textStyle.darkOutlineColor
                  ? _textStyle.darkOutlineColor
                  : _textStyle.outlineColor,
              outlineWidth: _textStyle.outlineWidth,
            };
            if (textProps) {
              _style.setText(this.getTextStyle(textProps));
            }
          }

          return _style;
        };

        return typeof _textStyle?.text === 'object' ||
          _textStyle?.text === 'length' ||
          typeof _textStyle?.color === 'object'
          ? solidLineFunction
          : solidLineFunction(fProps, { originalFeature });
      case 'regularShape':
        if (!styleProps.shapeType) {
          console.error('must provide points for regularShape', fProps ? `${fProps.id}` : '');
          return new Style();
        }
        let rotation = 0;
        const coordinatesLen = fProps?.coordinates.length || 0;
        if (fProps?.geomType === WcGeometryEnum.LINE_STRING && coordinatesLen > 1) {
          rotation = calculateAngle(
            fProps.coordinates[coordinatesLen - 1] as number[],
            fProps.coordinates[coordinatesLen - 2] as number[]
          );
        }

        return this.regularShapeMarker({
          imageConfig: { points: styleProps.shapeType },
          color: fProps ? (this.getPropSrc(color, fProps) as string) : (styleProps.color as string),
          stroke: styleProps.stroke as strokeStyleArgs,
          coordinates: styleProps.coordinates,
          rotation: rotation,
          radius: styleProps.radius,
          zIndex: styleProps.zIndex,
        });

      default:
        console.error('could not find style for:', fProps ? `${fProps.id} with shape:` : '', styleProps.shape);
        return new Style();
    }
  }

  solidLine(args: SolidLineStyleArgs): Style {
    return new Style({
      zIndex: args.zIndex || 0,
      stroke: new Stroke({
        color: olColor.asString([...hexAToRGB(args.color || '#000000'), args.opacity || 1]),
        width: args.width || 1,
      }),
    });
  }

  dashLine(args: DashLineStyleArgs): Style {
    return new Style({
      zIndex: args.zIndex || 1,
      stroke: new Stroke({
        color: this.getColor(args.color, args.opacity),
        width: args.width || 1,
        lineDash: args.dash || [5, 0, 0, 10],
        lineCap: 'square',
      }),
    });
  }

  area(args: AreaStyleArgs): Style {
    return new Style({
      zIndex: args.zIndex || Infinity,
      fill: new Fill({
        color: this.getColor(args.color, args.opacity),
      }),
    });
  }

  circleIcon(args: CircleStyleArgs): Style {
    const { stroke, color, opacity, zIndex, radius } = args;
    const circleStyle: CircleOptions = {
      fill: new Fill({
        color: this.getColor(color, opacity),
      }),
      radius,
    };

    if (stroke) {
      circleStyle.stroke = new Stroke({
        color: this.getColor(stroke.color || color, stroke.opacity || opacity || 1),
        width: stroke.width,
      });
    }

    return new Style({
      zIndex: zIndex || Infinity,
      image: new CircleStyle(circleStyle),
      geometry: function (feature) {
        if (feature.getGeometry() instanceof Point) return feature.getGeometry();
        if (feature.getGeometry() instanceof LineString)
          return new MultiPoint((feature.getGeometry() as SimpleGeometry).getCoordinates());
        return new MultiPoint((feature.getGeometry() as SimpleGeometry).getCoordinates().flat());
      },
    });
  }

  circle(args: BaseCircleArgs) {
    return new Style({
      zIndex: args.zIndex || Infinity,
      fill: new Fill({
        color: this.getColor(args.color, args.opacity),
      }),
      geometry: feature =>
        circularPolygon(feature.get('coordinates'), args.radius, 64).transform('EPSG:4326', 'EPSG:3857'),
    });
  }

  marker(args: IconStyleArgs): Style {
    return new Style({
      image: this.icon(args.imageConfig),
      geometry: args.coordinates ? () => new Point(args.coordinates as wcCoordinate) : undefined,
      zIndex: args.imageConfig.zIndex || 0,
    });
  }

  regularShapeMarker(args: RegularShapeStyleArgs) {
    return new Style({
      image: new RegularShape({
        fill: new Fill({ color: args.color }),
        stroke: args.stroke?.color ? new Stroke({ color: args.stroke.color, width: 2 }) : undefined,
        points: args.imageConfig.points,
        radius: args.radius ? args.radius : 9,
        rotation: args.rotation,
      }),
      geometry: args.coordinates ? () => new Point(args.coordinates as wcCoordinate) : undefined,
      zIndex: args.zIndex || 0,
    });
  }

  icon(imageConfig: IconStyle): Icon | undefined {
    const { src, anchor, scale, opacity, rotation } = imageConfig;
    const icons = (<Record<string, unknown>>this.mapConfig.mapTheme.themeIcons?.json).default as string[];

    const iconOptions: IconOptions = {
      anchor: anchor || [0.5, 0.5],
      scale: scale || 1,
      opacity: opacity || 1,
      rotateWithView: false,
      rotation: rotation ? (Math.PI / 180) * (rotation as number) : 0.0,
      crossOrigin: 'anonymous',
      src,
    };

    if (imageConfig.iconName) {
      const _icon = icons[imageConfig.iconName as string];
      if (!_icon) {
        console.error(`${imageConfig.iconName} has no iconName value.`);
        return undefined;
      }
      iconOptions.src = this.mapConfig.mapTheme.themeIcons?.path;
      iconOptions.offset = [_icon.x, _icon.y];
      iconOptions.size = [_icon.width, _icon.height];
    }

    return new Icon(iconOptions);
  }

  private getColor(color: string, opacity?: number): string {
    return olColor.asString([...hexAToRGB(color), opacity || 1]);
  }

  private getTextStyle(args: TextStyleArgs): Text {
    const {
      weight,
      height,
      fontFamily,
      textAlign,
      textBaseline,
      text,
      color,
      outlineColor,
      outlineWidth,
      offsetX,
      offsetY,
      placement,
      maxAngle,
      overflow,
      rotation,
      size,
    } = args;
    const font = weight || 'normal' + ' ' + 1 + '/' + height || 1 + ' ' + fontFamily || "'Open Sans'";

    return new Text({
      textAlign: textAlign || 'center',
      textBaseline: textBaseline || 'middle',
      font: font,
      text,
      fill: new Fill({ color }),
      stroke: new Stroke({ color: outlineColor, width: outlineWidth || 1 }),
      offsetX,
      offsetY,
      placement,
      maxAngle: maxAngle || 120,
      overflow,
      rotation: rotation || 0,
      scale: size * 0.1,
    });
  }

  getPropSrc<T>(arg: T, fProps: wcFeatureProperties<any> | undefined) {
    if (!fProps) return '';
    if (arg && typeof arg === 'object' && arg['sourceField']) {
      return fProps?.[arg['sourceField']] as T extends keyof wcFeatureProperties ? wcFeatureProperties[T] : undefined;
    }
    return arg;
  }

  private isVisibleStyle(styleProps: StyleArgs, fProps?: wcFeatureProperties<string>) {
    const isVisibleAttribute = styleProps.visible || true;
    const isVisible = !fProps ? true : this.getPropSrc(isVisibleAttribute, fProps);
    return isVisible === '' || isVisible === undefined ? true : isVisible;
  }
}

/**
 * continue with styling.
 * edit/create - fix functionality + styling(interaction styling).
 * integrate popup on map.
 * Writing tests.
 */

/**
 * 1) create - cover with ofir.
 * 2) what is selectable, styles for hover (for construction layer. maybe should be ignored?)
 * 3) we are not supposed to show unconfirmed incidents on the map?
 *
 * TODO:

 */
