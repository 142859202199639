<div
  class="segment-details-card"
  *ngIf="segment && mode && context"
  [ngClass]="[mode] + '-mode'"
  [class.selected-segment]="isSelected">
  <div
    class="default-vendor-card"
    (click)="_openDetailsClicked({ segmentId: segment.id, selectedVendorId: segment.defaultVendorId })">
    <div class="upper-section-wrapper">
      <wc-segment-title
        *ngIf="segment"
        [addressTitle]="segment.addressTitle"
        [direction]="segment.direction"
        [range]="segment.length"
        [measureUnitType]="measureUnitType">
      </wc-segment-title>
    </div>

    <div class="segment-details-card__vendor-container" [ngClass]="'context-' + context">
      <wc-segment-data-source
        *ngIf="segment && selectedMetric"
        (feedSourceChanged)="updateFeedSource($event)"
        [dataSources]="dataSources"
        [context]="context"
        [regionalSettings]="regionalSettings"
        [selectedFeedSource]="selectedMetric.vendorName"
        [isUpdated]="selectedMetric.isUpdated"
        [timeRange]="{
          startTime: selectedMetric.startedAt,
          endTime: selectedMetric.endedAt
        }">
      </wc-segment-data-source>

      <wc-segment-data
        [data]="selectedMetric"
        [measureUnitType]="measureUnitType"
        [maxSpeed]="segment.maxSpeed"></wc-segment-data>
    </div>
  </div>

  <mat-accordion class="show-more" *ngIf="context === contextTypes.Select && segment.vendorMetrics?.length > 1">
    <mat-expansion-panel class="show-more__panel" #showMorePanel [class.expanded]="isShowMoreExpanded">
      <mat-expansion-panel-header
        class="show-more__panel__header"
        expandedHeight="36px"
        (click)="showMoreToggled(showMorePanel)">
        <mat-panel-title class="show-more__panel__header__title" *ngIf="!isShowMoreExpanded; else showMoreExpanded">
          {{ 'roadwayStatus.showMoreSources' | translate: { numberOfSources: segment.vendorMetrics?.length - 1 } }}
        </mat-panel-title>

        <ng-template #showMoreExpanded>
          <mat-panel-title class="show-more__panel__header__title">
            {{ 'roadwayStatus.showLess' | translate }}
          </mat-panel-title>
        </ng-template>
      </mat-expansion-panel-header>

      <div
        class="segment-details-card__vendor-container additional-vendor-source"
        *ngFor="let vendorSource of filteredVendors; trackBy: trackById"
        (click)="_openDetailsClicked({ segmentId: segment.id, selectedVendorId: vendorSource.vendorId })">
        <wc-segment-data-source
          [dataSources]="[vendorSource.vendorName]"
          [regionalSettings]="regionalSettings"
          [selectedFeedSource]="vendorSource.vendorName"
          [isUpdated]="vendorSource.isUpdated"
          [timeRange]="{
            startTime: vendorSource?.startedAt,
            endTime: vendorSource?.endedAt
          }">
        </wc-segment-data-source>

        <wc-segment-data [data]="vendorSource" [measureUnitType]="measureUnitType" [maxSpeed]="segment.maxSpeed">
        </wc-segment-data>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="hover-more-sources" [class.direction-rtl]="isHebrew"
       *ngIf="context === contextTypes.Hover && segment.vendorMetrics?.length > 1">
    {{ 'roadwayStatus.moreSource' | translate: { numberOfSources: segment.vendorMetrics?.length - 1 } }}
  </div>
</div>
