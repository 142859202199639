import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Resolve,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SpecialEvent } from '../models';
import { SpecialEventService } from '../services/special-event.service';
import { UiStore, UsersStore } from '../stores';

@Injectable({
  providedIn: 'root',
})
export class SpecialEventResolverService implements Resolve<SpecialEvent>, CanLoad, CanActivate {
  specialEventLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private usersStore: UsersStore,
    private specialEventService: SpecialEventService,
    private router: Router,
    private uiStore: UiStore
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.usersStore.hasPermission('SPECIAL_EVENT:READ')) {
      this.router.navigate(['/live-map']);
      return false;
    } else {
      return true;
    }
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.specialEventLoaded;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SpecialEvent> | Observable<never> {
    const id = route.paramMap.get('id');

    return this.specialEventService.get(Number(id)).pipe(
      tap((specialEvent: SpecialEvent) => {
        // id not found
        if (!specialEvent) {
          this.router.navigate(['/live-map']);
          throw new Error('404 - id not found');
        }
      })
    );
  }
}
