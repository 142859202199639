import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MapMilemarkerButtonComponent } from './map-milemarker-button.component';
import { WcTooltipModule } from "@wc/wc-ui/src/components/tooltip/tooltip.module";

@NgModule({
  declarations: [MapMilemarkerButtonComponent],
  imports: [CommonModule, TranslateModule, WcTooltipModule],
  exports: [MapMilemarkerButtonComponent],
})
export class MapMilemarkerButtonModule {}
