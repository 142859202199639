import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NetworkState, OfflineEvent, ShiftStore, UiStore, UsersStore, WindowService } from '@wc/core';
import { preloadImage } from '@wc/utils';
import { pairwise, startWith } from 'rxjs/operators';

enum TooltipType {
  offline,
  incidentCreated,
  startShift,
}

@Component({
  selector: 'wc-network-badge',
  templateUrl: './network-badge.component.html',
  styleUrls: ['./network-badge.component.scss'],
})
export class NetworkBadgeComponent {
  @Input() disabled!: boolean;
  @Input() staticState!: string;
  @Input() iconOnly!: boolean;
  @ViewChild('badge') badge!: ElementRef<HTMLElement>;
  @ViewChild('offlineTooltip') offlineTooltip!: TemplateRef<HTMLElement>;

  _state!: string;
  private overlayRef?: OverlayRef;

  constructor(
    private windowService: WindowService,
    private cd: ChangeDetectorRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private translateService: TranslateService,
    private uiStore: UiStore,
    private shiftStore: ShiftStore,
    private usersStore: UsersStore
  ) {
    // need to preload the icon so it is cached when the network turns unstable
    preloadImage('assets/icons/unstable-network.svg');

    this.windowService.networkState$.pipe(startWith(null), pairwise()).subscribe(([prevState, state]) => {
      if (state !== undefined) this._state = NetworkState[state ? state : 0];
      this.cd.markForCheck();

      if (prevState !== NetworkState.offline && state === NetworkState.offline) {
        // Allow the badge element to be rendered
        if (!this.shiftStore.currentShift?.shiftId || !this.usersStore.authUser?.unit) {
          setTimeout(() => {
            this.showTooltip(TooltipType.startShift);
          });
        } else {
          setTimeout(() => {
            this.showTooltip();
          });
        }
      }
      if (state !== NetworkState.offline) {
        this.hideTooltip();
      }
    });

    this.uiStore.offlineEvent$.subscribe((event: OfflineEvent | null) => {
      switch (event) {
        case OfflineEvent.incidentCreated: {
          this.showTooltip(TooltipType.incidentCreated);
          break;
        }
      }
    });
  }

  get state(): string {
    return this.staticState || this._state;
  }

  hideTooltip() {
    // Cleanup any tooltip that is already attached
    this.overlayRef?.detach();
    delete this.overlayRef;
  }

  showTooltip(type: TooltipType = TooltipType.offline) {
    // Do not show a badge without an origin
    if (this.disabled || !this.badge?.nativeElement) {
      return;
    }

    if (this.overlayRef) {
      this.hideTooltip();
    }

    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.badge).withPositions([
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
      },
    ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
    });

    const portal: CdkPortal = new CdkPortal(this.offlineTooltip, this.viewContainerRef);
    let context = {
      title: '',
      subtitle: '',
    };
    switch (type) {
      case TooltipType.offline: {
        context = {
          title: 'network.offlineButCanKeepWorking',
          subtitle: 'network.offlineActionsWillBeSaved',
        };
        break;
      }
      case TooltipType.incidentCreated: {
        context = {
          title: 'network.offlineIncidentCreatedTitle',
          subtitle: 'network.offlineIncidentCreatedSubtitle',
        };
        break;
      }
      case TooltipType.startShift: {
        context = {
          title: 'network.offlineStartShiftTitle',
          subtitle: 'network.checkNetworkAndTryAgain',
        };
        break;
      }
    }
    portal.context = context;
    this.overlayRef.attach(portal);
  }

  get isVisible(): boolean {
    return this.state === 'offline' || this.state === 'unstable';
  }

  get title(): string {
    let title = '';
    switch (this.state) {
      case 'offline': {
        title = this.translateService.instant('network.offline');
        break;
      }
      case 'unstable': {
        title = this.translateService.instant('network.unstable');
        break;
      }
    }
    return title;
  }
}
