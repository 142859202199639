import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IncidentsSortPipe } from './incidents-sort.pipe';
import { IncidentAssignToFilter } from './incident-assign-to-filter.pipe';

@NgModule({
  declarations: [IncidentsSortPipe, IncidentAssignToFilter],
  imports: [CommonModule, TranslateModule],
  providers: [],
  exports: [IncidentsSortPipe, IncidentAssignToFilter],
})
export class IncidentPipesModule {}
