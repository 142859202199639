<ng-container *ngIf="activitiesLog && activitiesLog.length > 0; else OFFLINE">
  <mat-accordion
    class="activity-log-container"
    [class.tablet-mode]="isTabletMode"
    [class.language-hebrew]="isHebrew"
    [multi]="true">
    <mat-expansion-panel
      #matExpansion
      class="incident-section"
      *ngFor="let day of activitiesLog; first as isFirst"
      [expanded]="true">
      <mat-expansion-panel-header
        [wcTooltipText]="(matExpansion.expanded ? 'collapse' : 'expand') | translate"
        wcTooltipPlacement="bottom-end"
        [wcTooltipOffsetDistance]="-5"
        [wcTooltipOffsetSkidding]="3">
        <mat-panel-title class="activity-log-title zz">
          {{ day.timeByDay | dateFormat: accountStore.account.regionalSetting:'mediumDate' }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-divider></mat-divider>

      <ng-container *ngFor="let minute of day.activitiesPerDay">
        <div *ngIf="minute.activitiesPerMinute.length > 0" class="activity-log-item">
          <div class="activity-property">
            <div class="activity-log-time">
              {{ minute.timeByMinute.toString() | timeFormatMode: accountStore.account.regionalSetting.timeFormat }}
            </div>
            <div class="activity-log-user-name">
              <div>{{ minute.accountName }}</div>
              <div>{{ minute.userName }}</div>
            </div>
          </div>

          <div class="item-main-content">
            <div *ngFor="let activitiesPerMinute of minute.activitiesPerMinute">
              <ng-container [ngSwitch]="activitiesPerMinute.activityLogSectionType">
                <ng-container *ngIf="activitiesPerMinute?.maxOfflineUpdatedAt">
                  <ng-container
                    *ngTemplateOutlet="
                      OFFLINE_TIME_INDICATION;
                      context: {
                        offlineTime: activitiesPerMinute?.maxOfflineUpdatedAt
                      }
                    "></ng-container>
                </ng-container>
                <span *ngSwitchCase="'CREATION'">
                  <ng-container *ngTemplateOutlet="CREATION; context: { value: activitiesPerMinute }"></ng-container>
                </span>
                <span *ngSwitchCase="'LANES'">
                  <ng-container *ngTemplateOutlet="LANES; context: activitiesPerMinute"></ng-container>
                </span>
                <span *ngSwitchCase="'MISC'">
                  <span class="activity-log-subtitle">
                    {{ 'activitiesLog.' + activitiesPerMinute.activityLogEntityType | translate }}
                    {{ 'activitiesLog.sectionTitle.MISC' | translate }}
                  </span>
                  <ng-container *ngTemplateOutlet="MISC; context: { value: activitiesPerMinute }"></ng-container>
                </span>
                <span *ngSwitchCase="'NOTES'">
                  <ng-container *ngTemplateOutlet="NOTES; context: { value: activitiesPerMinute }"></ng-container>
                </span>
                <span *ngSwitchCase="'STATUS_CHANGED'">
                  <ng-container
                    *ngTemplateOutlet="
                      STATUS_CHANGED;
                      context: {
                        value: activitiesPerMinute
                      }
                    "></ng-container>
                </span>
                <span *ngSwitchCase="'MITIGATION'">
                  <ng-container *ngTemplateOutlet="MITIGATION; context: { value: activitiesPerMinute }"></ng-container>
                </span>
                <span *ngSwitchCase="'UNIT_RESPONSE'">
                  <ng-container
                    *ngTemplateOutlet="UNIT_RESPONSE; context: { value: activitiesPerMinute }"></ng-container>
                </span>
                <span *ngSwitchCase="'STATUS_RESTORED'">
                  <ng-container
                    *ngTemplateOutlet="STATUS_RESTORED; context: { value: activitiesPerMinute }"></ng-container>
                </span>
                <span *ngSwitchCase="'USER_ASSIGNMENT'">
                  <ng-container
                    *ngTemplateOutlet="USER_ASSIGNMENT; context: { value: activitiesPerMinute }"></ng-container>
                </span>
                <span *ngSwitchCase="'RELATED_ENTITY'">
                  <ng-container
                    *ngTemplateOutlet="RELATED_ENTITY; context: { value: activitiesPerMinute }"></ng-container>
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>

<!-- ------------------ -->
<!-- SECTIONS TEMPLATES -->
<!-- ------------------ -->
<ng-template #CREATION let-value="value">
  <!-- {{ value | json }} -->
  <span class="activity-log-subtitle">
    {{ 'activitiesLog.' + value.activityLogEntityType | translate }}
    {{ 'activitiesLog.sectionTitle.CREATION' | translate }}
  </span>
  <span class="activity-log-subtitle" *ngIf="value.ActivityLogEntityType === 'ROAD_CLOSURE'">
    {{ 'withStatus' | translate }} {{ value.status }}
  </span>
  <ng-container *ngTemplateOutlet="MISC; context: { value: value }"></ng-container>
  <ng-container *ngTemplateOutlet="UNIT_RESPONSE; context: { value: value }"></ng-container>
</ng-template>

<ng-template #UNIT_RESPONSE let-value="value">
  <ng-container class="status-row" [ngSwitch]="activity.action" *ngFor="let activity of value.activities">
    <div *ngSwitchCase="'FIELD_MODIFIED'">
      <span class="activity-log-subtitle">
        {{ 'activitiesLog.unitReported' | translate: { unitId: activity.value.displayId } }}
      </span>
      <span
        *ngIf="activity.value.response"
        class="status-badge"
        [ngClass]="'status-' + activity.value.response?.toLowerCase()">
        {{ 'unitResponse.' + activity.value.response?.toLowerCase() | translate }}
      </span>
    </div>
    <div *ngSwitchCase="'FIELD_CREATED'">
      <span *ngIf="activity.value.displayId">
        <span class="activity-log-subtitle">
          {{ 'activitiesLog.unitReported' | translate: { unitId: activity.value.displayId } }}
        </span>
        <span
          *ngIf="activity.value.response"
          class="status-badge"
          [ngClass]="'status-' + activity.value.response?.toLowerCase()">
          {{ 'unitResponse.' + activity.value.response?.toLowerCase() | translate }}
        </span>
      </span>
    </div>
    <div *ngSwitchCase="'FIELD_REMOVED'">
      <span class="activity-log-subtitle">
        {{ 'activitiesLog.unitWasRemovedFromThe' | translate: { unitId: activity.value.displayId } }}
        {{ 'activitiesLog.' + value.activityLogEntityType | translate }}
      </span>
    </div>
  </ng-container>
</ng-template>

<ng-template #STATUS_RESTORED let-value="value">
  <span *ngIf="value.activityLogEntityType === activityLogEntityType.Incident" class="activity-log-subtitle">
    {{ 'activitiesLog.incidentRestored' | translate }}
  </span>
</ng-template>

<ng-template #RELATED_ENTITY let-value="value">
  <ng-container *ngFor="let activity of value.activities">
    <div [ngSwitch]="activity.action">
      <span *ngSwitchCase="'FIELD_CREATED'">
        <ng-container *ngIf="activity.field === 'RELATED_WORK_REQUEST'">
          <span class="activity-log-subtitle">
            {{ 'activitiesLog.' + value.activityLogEntityType | translate }}
            {{ 'activitiesLog.newRelated' | translate }}
          </span>
          <ul>
            <li>
              {{ activity.value.workRequestId }} - {{ 'entityTypes.workRequest' | translate }},
              {{ activity.value.address?.corridor }}
              {{ activity.value.address?.direction | translate }}
              {{ activity.value.address?.orientation | translate }}
              {{ activity.value.address?.crossroad }},
              {{ 'workRequestTypes.' + activity.value.workRequestType | translate }}
            </li>
          </ul>
        </ng-container>
        <ng-container *ngIf="activity.field === 'RELATED_INCIDENT'">
          <span class="activity-log-subtitle">
            {{ 'activitiesLog.' + value.activityLogEntityType | translate }}
            {{ 'activitiesLog.newRelated' | translate }}
          </span>
          <ul>
            <li>
              {{ activity.value.incidentId }} - {{ 'entityTypes.incident' | translate }},
              {{ activity.value.address?.corridor }}
              {{ activity.value.address?.direction | translate }}
              {{ activity.value.address?.orientation | translate }}
              {{ activity.value.address?.crossroad }},
              {{ 'incidentTypes.' + activity.value.incidentType | translate }}
              <span *ngIf="activity.value.subType">
                - {{ 'incidentSubTypes.' + activity.value.subType | translate }}
              </span>
            </li>
          </ul>
        </ng-container>
      </span>
      <span *ngSwitchCase="'FIELD_REMOVED'">
        <!-- TODO: -->
      </span>
    </div>
  </ng-container>
</ng-template>

<ng-template #STATUS_CHANGED let-value="value" class="status-row">
  <div [ngSwitch]="activity.field" *ngFor="let activity of value.activities">
    <span *ngSwitchCase="'STATUS'">
      <ng-container
        *ngTemplateOutlet="
          STATUS;
          context: {
            activity: activity,
            activityLogEntityType: value.activityLogEntityType
          }
        "></ng-container>
    </span>
    <span *ngSwitchCase="'END_TIME'">
      <ng-container *ngTemplateOutlet="END_TIME; context: activity"></ng-container>
    </span>
  </div>
</ng-template>

<ng-template #LANES let-activities="activities">
  <!-- Hanlde Lanes Activity Types -->
  <span class="activity-log-subtitle">
    {{ 'activitiesLog.sectionTitle.LANES' | translate }}
  </span>
  <ul class="activities-list">
    <div [ngSwitch]="activity.action" *ngFor="let activity of activities">
      <!-- 1. Lanes was created -->
      <ng-container *ngSwitchCase="activityType.FieldCreated">
        <li>
          <span>{{ 'laneTypes.' + activity.value.incidentLane.type | translate }} &nbsp;</span>
          <span *ngIf="activity.value.incidentLane.number"
            >{{ 'activitiesLog.laneOnThe' | translate: { laneNumber: activity.value.incidentLane.number } }}&nbsp;</span
          >
          <span>{{ 'roadTypes.' + activity.value.incidentLane.roadType | translate }}&nbsp;</span>
          <span>{{ activity.value.incidentLane.direction }}&nbsp;</span>
          <span>{{
            'activitiesLog.laneStatus.' + (activity.value.incidentLane.isClosed ? 'closed' : 'open') | translate
          }}</span>
          <span class="lane-action-text">&nbsp; {{ 'activitiesLog.laneActions.added' | translate }}</span>
        </li>
      </ng-container>
      <!-- 2. Lane was modified -->
      <ng-container *ngSwitchCase="activityType.FieldModified">
        <li>
          <span>{{ 'laneTypes.' + activity.value.incidentLane.type | translate }} &nbsp;</span>
          <span *ngIf="activity.value.incidentLane.number"
            >{{ 'activitiesLog.laneOnThe' | translate: { laneNumber: activity.value.incidentLane.number } }}&nbsp;</span
          >
          <span>{{ 'roadTypes.' + activity.value.incidentLane.roadType | translate }}&nbsp;</span>
          <span>{{ activity.value.incidentLane.direction }}&nbsp;</span>
          <span>{{
            'activitiesLog.laneStatus.' + (activity.value.incidentLane.isClosed ? 'closed' : 'open') | translate
          }}</span>
          <span class="lane-action-text">&nbsp;{{ 'activitiesLog.laneActions.modified' | translate }}</span>
        </li>
      </ng-container>
      <!-- 3. Lane was removed -->
      <ng-container *ngSwitchCase="activityType.FieldRemoved">
        <li>
          <span>{{ 'laneTypes.' + activity.value.incidentLane.type | translate }} &nbsp;</span>
          <span *ngIf="activity.value.incidentLane.number"
            >{{ 'activitiesLog.laneOnThe' | translate: { laneNumber: activity.value.incidentLane.number } }}&nbsp;</span
          >
          <span>{{ 'roadTypes.' + activity.value.incidentLane.roadType | translate }}&nbsp;</span>
          <span>{{ activity.value.incidentLane.direction }}</span>
          <span class="lane-action-text">&nbsp;{{ 'activitiesLog.laneActions.removed' | translate }}</span>
        </li>
      </ng-container>
    </div>
  </ul>
  <!--<ul class="activities-list">
        <div [ngSwitch]="activity.action" *ngFor="let activity of activities;"> -->
  <!-- {{activity | json}} -->
  <!-- <ng-container *ngSwitchCase="activityType.FieldCreated && activity.value.incidentLane.isAffected "> -->
  <!-- <ng-container *ngTemplateOutlet="LANE_CREATED;context:activity"></ng-container> -->
  <!-- </ng-container>
            <ng-container *ngIf="activity.value.incidentLane.isAffected" >
                <div class="lane-row" >
                    <li class="activity-log-subtitle"> {{'activitiesLog.laneWasMarkedAs' | translate:{laneAction: (activity.value.incidentLane.isClosed)? 'closed' : 'opened'} | translate}} &nbsp;</li>
                    <div class="lane-row-details">
                        <span>{{ 'laneTypes.'+activity.value.incidentLane.type | translate}}  {{'activitiesLog.onThe' | translate }}&nbsp;</span>
                        <span *ngIf="activity.value.incidentLane.number">{{ 'activitiesLog.laneOnThe' | translate:{laneNumber: activity.value.incidentLane.number} }}&nbsp;</span>
                        <span>{{ 'roadTypes.'+activity.value.incidentLane.roadType | translate}}&nbsp;</span>
                        <span>{{activity.value.incidentLane.direction}}</span>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="activityType.FieldRemoved"> -->
  <!-- Lane [lane type] #[lane number] on the [road type] [direction] was removed -->
  <!-- </ng-container>
        </div>
    </ul> -->
</ng-template>

<ng-template #MISC let-value="value">
  <ul class="activities-list">
    <li *ngIf="concatCameras(value.activities).length > 0">
      <ng-container *ngTemplateOutlet="CAMERAS; context: { cameras: concatCameras(value.activities) }"></ng-container>
    </li>
    <li *ngIf="!!defaultCameraChanged(value.activities)">
      <ng-container *ngTemplateOutlet="MAIN_CAMERA; context: defaultCameraChanged(value.activities)"></ng-container>
    </li>
    <ng-container *ngIf="concatInvolvedVehicle(value.activities).length > 0"
      ><ng-container
        *ngTemplateOutlet="
          INVOLVED_VEHICLE;
          context: { involvedVehicles: concatInvolvedVehicle(value.activities) }
        "></ng-container
    ></ng-container>
    <ng-container *ngIf="concatAdditionalInfo(value.activities).length > 0"
      ><ng-container
        *ngTemplateOutlet="
          ADDITIONAL_INFO;
          context: { additionalInfo: concatAdditionalInfo(value.activities) }
        "></ng-container
    ></ng-container>
    <ng-container *ngIf="concatInjurySeverities(value.activities).length > 0"
      ><ng-container
        *ngTemplateOutlet="
          INJURY_SEVERITIES;
          context: { severities: concatInjurySeverities(value.activities) }
        "></ng-container
    ></ng-container>
    <ng-container *ngIf="concatAttributes(value.activities).length > 0"
      ><ng-container
        *ngTemplateOutlet="ATTRIBUTES; context: { attributes: concatAttributes(value.activities) }"></ng-container
    ></ng-container>
    <ng-container *ngIf="concatMedia(value.activities)"
      ><ng-container *ngTemplateOutlet="MEDIA; context: { media: concatMedia(value.activities) }"></ng-container
    ></ng-container>
    <ng-container *ngFor="let activity of value.activities; let elm = activity">
      <li *ngIf="activity.field === 'VENUE'">
        <ng-container *ngTemplateOutlet="VENUE; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'ADDRESS'">
        <ng-container *ngTemplateOutlet="ADDRESS; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'LOCATION'">
        <ng-container *ngTemplateOutlet="LOCATION; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'TYPE'">
        <ng-container *ngTemplateOutlet="TYPE; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'START_TIME'">
        <ng-container *ngTemplateOutlet="START_TIME; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'END_TIME'">
        <ng-container *ngTemplateOutlet="END_TIME; context: activity"></ng-container>
      </li>
      <ng-container *ngIf="activity.action === 'FIELD_MODIFIED' && activity.field === 'SCHEDULE' && value">
        <ng-container *ngTemplateOutlet="SCHEDULE; context: activity"></ng-container>
      </ng-container>

      <li *ngIf="activity.field === 'TITLE' && activity.value.text">
        <ng-container *ngTemplateOutlet="TITLE; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'DESCRIPTION' && activity.value.text">
        <ng-container *ngTemplateOutlet="DESCRIPTION; context: activity"></ng-container>
      </li>
      <li
        *ngIf="
          activity.field === 'CONTACT_PERSON' &&
          (activity.value.name || activity.value.phoneNumber || activity.value.email)
        ">
        <ng-container *ngTemplateOutlet="CONTACT_PERSON; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'OCCUPANCY'">
        <ng-container *ngTemplateOutlet="OCCUPANCY; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'INJURIES'">
        <ng-container *ngTemplateOutlet="INJURIES; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'FATALITIES'">
        <ng-container *ngTemplateOutlet="FATALITIES; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'ESTIMATED_END_TIME'">
        <ng-container *ngTemplateOutlet="ESTIMATED_END_TIME; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'REPORT_SOURCE'">
        <ng-container *ngTemplateOutlet="REPORT_SOURCE; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'CAD_ID'">
        <ng-container *ngTemplateOutlet="CAD_ID; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'ATMS_ID'">
        <ng-container *ngTemplateOutlet="ATMS_ID; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'CONSTRUCTION_TYPE'">
        <ng-container *ngTemplateOutlet="CONSTRUCTION_TYPE; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'PUBLISH_INCIDENT'">
        <ng-container *ngTemplateOutlet="PUBLISH_INCIDENT; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'ARRIVAL_TIME'">
        <ng-container *ngTemplateOutlet="ARRIVAL_TIME; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'CONTACT'">
        <ng-container *ngTemplateOutlet="CONTACT_PERSON_ARRAY; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'AUTO_PUBLISH'">
        <ng-container *ngTemplateOutlet="AUTO_PUBLISH; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.value?.subType === 'INCIDENT_SUB_TYPE'">
        {{ 'activitiesLog.subtype' | translate }}: {{ 'incidentSubTypes.' + activity.value.subType | translate }}
      </li>
      <li *ngIf="activity.field === 'RESPONSE_PLAN' && activity.action === 'FIELD_CREATED'">
        {{ 'activitiesLog.responsePlanCreated' | translate }}
      </li>
      <li *ngIf="activity.field === 'RESPONSE_PLAN' && activity.action === 'FIELD_REMOVED'">
        {{ 'activitiesLog.responsePlanRemoved' | translate }}
      </li>
      <li *ngIf="activity.field === 'SCHEDULE' && activity.action === 'FIELD_REMOVED'">
        {{ 'activitiesLog.scheduleRemoved' | translate }}
      </li>
      <li *ngIf="activity.field === 'RESPONSE_PLAN' && activity.action === 'FIELD_MODIFIED'">
        {{ 'activitiesLog.responsePlanModified' | translate }}
      </li>
      <li *ngIf="activity.field === 'INVOLVED_VEHICLES_COUNT'">
        <ng-container *ngTemplateOutlet="INVOLVED_VEHICLES_COUNT; context: activity"></ng-container>
      </li>
      <li *ngIf="activity.field === 'SEVERITY' && activity.action === 'FIELD_MODIFIED'">
        {{ 'activitiesLog.IncidentSeverityModified' | translate }}:
        {{ 'incidentSeverity.' + activity.value.incidentSeverity | translate }}
      </li>
      <li *ngIf="activity.field === 'SEVERITY' && activity.action === 'FIELD_CREATED'">
        {{ 'activitiesLog.IncidentSeverityCreated' | translate }}:
        {{ 'incidentSeverity.' + activity.value.incidentSeverity | translate }}
      </li>
    </ng-container>
  </ul>
</ng-template>

<ng-template #AUTO_PUBLISH let-value="value">
  <span *ngIf="value" class="activity-log-subtitle">{{
    value.autoPublish ? ('activitiesLog.automaticTweetOn' | translate) : ('activitiesLog.automaticTweetOff' | translate)
  }}</span>
</ng-template>

<ng-template #NOTES let-value="value">
  <span class="activity-log-subtitle">{{ 'activitiesLog.notesUpdates' | translate }}</span>
  <br />
  <ul class="activities-list">
    <li *ngFor="let noteItem of value.activities">{{ noteItem.value.note }}</li>
  </ul>
</ng-template>

<ng-template #MITIGATION let-value="value">
  <div *ngFor="let activity of value.activities">
    <span class="activity-log-subtitle">
      {{ 'activitiesLog.unitMitigationUpdate' | translate: { unitId: activity.value.unitDisplayId } }}
    </span>
    <ul class="activities-list">
      <li>
        {{ 'activitiesLog.startTime' | translate }}

        {{ activity.value.interval.from | dateFormat: accountStore.account.regionalSetting }}
      </li>
      <li>
        {{ 'activitiesLog.endTime' | translate }}
        {{ activity.value.interval.to | dateFormat: accountStore.account.regionalSetting }}
      </li>
      <li>
        {{ 'activitiesLog.description' | translate }}
        {{ activity.value.description }}
      </li>
    </ul>
  </div>
</ng-template>

<!---------- ---------------- ---------->
<!---------- FILEDS TEMPLATES ---------->
<!---------- ---------------- ---------->

<ng-template #OFFLINE_TIME_INDICATION let-offlineTime="offlineTime">
  <span class="offline-time-indication">
    {{ 'activitiesLog.doneWhileOffline' | translate }}
    {{ offlineTime | dateFormat: accountStore.account.regionalSetting }}
  </span>
</ng-template>

<ng-template #USER_ASSIGNMENT let-value="value">
  <ng-container *ngFor="let activity of value.activities">
    <span class="activity-log-subtitle">{{ 'activitiesLog.requestAssignment' | translate }}</span>
    <br />
    <ul class="activities-list">
      <li *ngIf="activity.value.userName">
        {{ 'activitiesLog.assignedUser' | translate }}
        {{ activity.value.userName }}
      </li>
      <li *ngIf="!activity.value.userName">
        {{ 'activitiesLog.unassignedUser' | translate }}
        {{ activity.value.userName }}
      </li>
    </ul>
  </ng-container>
</ng-template>

<ng-template #ADDITIONAL_INFO let-additionalInfo="additionalInfo">
  <div>
    <li>
      {{ 'activitiesLog.additionalInfo' | translate }}
      <button class="details-button" (click)="involvedVehiclesList.hidden = !involvedVehiclesList.hidden">
        <i class="fas fa-sort-down"></i>
        {{ 'details' | translate }}
      </button>
    </li>
    <ul #involvedVehiclesList [hidden]="true">
      <li *ngFor="let activity of additionalInfo; let last = last">
        {{ activity.value?.info }}
        <span class="lane-action-text">{{
          activity?.action === 'FIELD_REMOVED'
            ? ('activitiesLog.laneActions.removed' | translate)
            : ('activitiesLog.laneActions.added' | translate)
        }}</span>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #INJURY_SEVERITIES let-severities="severities">
  <li>
    {{ 'activitiesLog.injurySeverities' | translate }}:
    <span>
      {{ severities.join(', ') }}
    </span>
  </li>
</ng-template>

<ng-template #ATTRIBUTES let-attributes="attributes">
  <li>
    {{ 'incident.attributes' | translate }}:
    <span>
      {{ attributes.join(', ') }}
    </span>
  </li>
</ng-template>

<ng-template #VENUE let-value="value">
  <p>
    {{ 'activitiesLog.venue' | translate }}
    {{ value.venueAddress }} | {{ value.venueName }}
  </p>
</ng-template>

<ng-template #ADDRESS let-value="value.address">
  <p>
    {{ 'activitiesLog.address' | translate }}
    {{ value.corridor }} {{ value.orientation }} {{ value.crossroad }}
    {{ value.direction }}
  </p>
</ng-template>

<ng-template #ASSOCIATED_UNIT let-value="value">
  {{ value | json }}
</ng-template>

<ng-template #CAMERAS let-cameras="cameras">
  <p>
    {{ 'activitiesLog.camera' | translate }}
    <span *ngFor="let camera of cameras; let last = last">
      {{ camera.title }}
      <span *ngIf="!last && camera.title">,</span>
    </span>
  </p>
</ng-template>

<ng-template #MAIN_CAMERA let-value="value">
  <!-- <p *ngIf="!value.default">
      {{ 'activitiesLog.camera' | translate }} {{value.title}}
    </p> -->
  <p *ngIf="value.default">{{ 'activitiesLog.mainCamera' | translate }} {{ value.title }}</p>
</ng-template>

<ng-template #INVOLVED_VEHICLE let-involvedVehicles="involvedVehicles">
  <div>
    <li>
      {{ 'activitiesLog.involvedVehicles' | translate }}
      <button class="details-button" (click)="involvedVehiclesList.hidden = !involvedVehiclesList.hidden">
        <i class="fas fa-sort-down"></i>
        {{ 'details' | translate }}
      </button>
    </li>
    <ul #involvedVehiclesList [hidden]="true">
      <li *ngFor="let action of involvedVehicles; let last = last">
        <!-- {{ action | json}} -->
        <span *ngIf="action.action === 'FIELD_REMOVED'">{{ 'activitiesLog.vehicleRemoved' | translate }} </span>
        &nbsp;
        {{ action.value?.vehicle?.licensePlateNumber }}
        <span *ngIf="action.value?.vehicle?.state"
          >,
          {{
            action.value?.vehicle?.state === vehicleState.UnknownState
              ? ('involvedVehicleProperties.states.' + action.value?.vehicle?.state | translate)
              : action.value?.vehicle?.state
          }}</span
        >
        <span *ngIf="action.value?.vehicle?.make"
          >, {{ 'involvedVehicleProperties.brands.' + action.value?.vehicle?.make | translate }}</span
        >
        <span *ngIf="action.value?.vehicle?.type"
          >, {{ 'involvedVehicleProperties.types.' + action.value?.vehicle?.type | translate }}</span
        >
        <span *ngIf="action.value?.vehicle?.color"
          >, {{ 'involvedVehicleProperties.colors.' + action.value?.vehicle?.color | translate }}</span
        >
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #INVOLVED_VEHICLES_COUNT let-value="value">
  <span> {{ 'activitiesLog.involvedVehiclesCount' | translate }}: {{ value.n }}</span>
</ng-template>

<ng-template #LOCATION let-value="value">
  <span>{{ 'activitiesLog.locationWasUpdated' | translate }}</span>
</ng-template>

<ng-template #MEDIA let-media="media">
  <li *ngIf="media.addedAmount === 1">
    {{ 'activitiesLog.mediaFileWas' | translate }}
    {{ 'activitiesLog.added' | translate }}
  </li>
  <li *ngIf="media.addedAmount > 1">
    {{ 'activitiesLog.mediaFilesWere' | translate: { amount: media.addedAmount } }}
    {{ 'activitiesLog.added' | translate }}
  </li>
  <li *ngIf="media.removedAmount === 1">
    {{ 'activitiesLog.mediaFileWas' | translate }}
    {{ 'activitiesLog.activityType.FIELD_REMOVED' | translate }}
  </li>
  <li *ngIf="media.removedAmount > 1">
    {{ 'activitiesLog.mediaFilesWere' | translate: { amount: media.addedAmount } }}
    {{ 'activitiesLog.activityType.FIELD_REMOVED' | translate }}
  </li>
</ng-template>

<ng-template #PUBLISH_INCIDENT let-value="value">
  <p>
    <span class="lane-action-text"> {{ 'activitiesLog.notifyPublicUpdated' | translate }}</span>
    {{ value.description }}
    (<ng-container *ngFor="let des of value.destinations"> {{ 'publishChannels.' + des | translate }} </ng-container>)
  </p>
</ng-template>

<ng-template #START_TIME let-value="value">
  <p>
    {{ 'activitiesLog.startTime' | translate }}
    {{ value.dateTime | dateFormat: accountStore.account.regionalSetting }}
  </p>
</ng-template>

<ng-template #CAD_ID let-value="value">
  <p *ngIf="value.text">{{ 'activitiesLog.cadId' | translate }} {{ value.text }}</p>
  <p *ngIf="value.nullableText">{{ 'activitiesLog.cadId' | translate }} {{ value.nullableText }}</p>
</ng-template>

<ng-template #ATMS_ID let-value="value">
  <p *ngIf="value.text">{{ 'activitiesLog.atmsId' | translate }} {{ value.text }}</p>
  <p *ngIf="value.nullableText">{{ 'activitiesLog.atmsId' | translate }} {{ value.nullableText }}</p>
</ng-template>

<ng-template #CONSTRUCTION_TYPE let-value="value">
  <p>
    {{ 'activitiesLog.subtype' | translate }}:
    {{ 'constructionType.' + value.constructionType | translate }}
  </p>
</ng-template>

<ng-template #ARRIVAL_TIME let-value="value">
  <p>
    {{ 'activitiesLog.arrivalTime' | translate }}
    {{ value | dateFormat: accountStore.account.regionalSetting }}
  </p>
</ng-template>

<ng-template #STATUS let-activity="activity" let-activityLogEntityType="activityLogEntityType">
  <!-- {{activity | json}} {{activityLogEntityType | json}} -->

  <span class="activity-log-subtitle">
    {{ 'activitiesLog.' + activityLogEntityType | translate }}
    <ng-container *ngIf="!isHebrew">
      {{ 'activitiesLog.was' | translate }}
    </ng-container>
    {{ activity.value?.automaticAction ? ('activitiesLog.automatically' | translate) : '' }}
  </span>

  <span
    *ngIf="activity.value.status"
    class="status-badge"
    [ngClass]="'status-' + activity.value.status?.toLowerCase()"
    >{{ 'activitiesLog.statusType.' + activity.value.status | translate }}</span
  >
  <span
    *ngIf="activity.value.trafficDisruptionStatus"
    class="status-badge"
    [ngClass]="'status-' + activity.value.trafficDisruptionStatus?.toLowerCase()"
    >{{ 'activitiesLog.statusType.' + activity.value.trafficDisruptionStatus | translate }}</span
  >

  <ul class="activities-list">
    <li *ngIf="activity.value.endReason">
      {{ 'shiftsAndRoutes.reason' | translate }}:
      {{ 'IncidentEndReason.' + activity.value.endReason | translate }}
    </li>
    <li *ngIf="activity.value.endReasonComment">
      {{ 'workRequest.comment' | translate }}:
      {{ activity.value.endReasonComment | translate }}
    </li>
  </ul>
</ng-template>

<ng-template #END_TIME let-value="value">
  <p>
    {{ 'activitiesLog.endTime' | translate }}
    {{ value.dateTime | dateFormat: accountStore.account.regionalSetting }}
  </p>
</ng-template>

<ng-template #TYPE let-value="value">
  <p>
    {{ 'activitiesLog.type' | translate }}:
    {{ 'incidentTypes.' + value.incidentType | translate }}
  </p>
</ng-template>

<ng-template #SCHEDULE let-value="value">
  <p>
    <li>
      {{ 'activitiesLog.startTime' | translate }}
      {{ value.startTime | dateFormat: accountStore.account.regionalSetting }}
    </li>
    <li>
      {{ 'activitiesLog.endTime' | translate }}
      {{ value.endTime | dateFormat: accountStore.account.regionalSetting }}
    </li>
  </p>

  <span class="activity-log-subtitle no-indent"> {{ 'activitiesLog.scheduleUpdated' | translate }}</span>

  <li *ngFor="let frame of value?.schedule?.timeRanges">
    <span> {{ frame.fromDayOfWeekLocal | translateDayOfWeek: true }} </span>
    <span>
      {{ frame.fromTimeMinutesLocal | minutesDurationToTime: accountStore.account.regionalSetting.timeFormat }}
    </span>
    <span>-</span>
    <span> {{ frame.toDayOfWeekLocal | translateDayOfWeek: true }} </span>
    <span>
      {{ frame.toTimeMinutesLocal | minutesDurationToTime: accountStore.account.regionalSetting.timeFormat }}
    </span>
  </li>
</ng-template>

<ng-template #TITLE let-value="value">
  <p>{{ 'activitiesLog.title' | translate }} {{ value.text }}</p>
</ng-template>

<ng-template #DESCRIPTION let-value="value">
  <p>{{ 'activitiesLog.description' | translate }} {{ value.text }}</p>
</ng-template>

<ng-template #CONTACT_PERSON let-value="value">
  <p>
    {{ 'activitiesLog.contacts' | translate }}
    <span *ngIf="value.name">{{ value.name }}</span>
    <span *ngIf="value.phoneNumber">, {{ value.phoneNumber }}</span>
    <span *ngIf="value.email">, {{ value.email }}</span>
  </p>
</ng-template>

<ng-template #CONTACT_PERSON_ARRAY let-value="value">
  <span class="activity-log-subtitle"> {{ 'activitiesLog.contacts' | translate }}</span>
  <div>
    <ul>
      <li *ngFor="let contact of value.contactPersons">
        <span *ngIf="contact.name">{{ contact.name }}</span>
        <span *ngIf="contact.phoneNumber">, {{ contact.phoneNumber }}</span>
        <span *ngIf="contact.email">, {{ contact.email }}</span>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #OCCUPANCY let-value="value">
  <!-- {{value | json}} -->
  <p>{{ 'activitiesLog.occupancy' | translate }}</p>
</ng-template>

<ng-template #INJURIES let-value="value">
  <p>{{ 'activitiesLog.injuries' | translate }} {{ value.n }}</p>
</ng-template>

<ng-template #FATALITIES let-value="value">
  <p>{{ 'activitiesLog.fatalities' | translate }} {{ value.n }}</p>
</ng-template>

<ng-template #ESTIMATED_END_TIME let-value="value">
  <p>
    {{ 'activitiesLog.estimatedEndTime' | translate }}
    {{ value.dateTime | dateFormat: accountStore.account.regionalSetting }}
  </p>
</ng-template>

<ng-template #REPORT_SOURCE let-value="value">
  <p>{{ 'activitiesLog.reportedBy' | translate }} {{ value.reportSource }}</p>
</ng-template>

<ng-template #OFFLINE>
  <div *ngIf="!(isOnline$ | async)" class="offline-empty-state" fxLayout="column" fxLayoutAlign="center center">
    <img class="offline-image" src="assets/images/offline-activity-log.svg" />
    <span>{{ 'emptyStates.offlineActivityLogTitle' | translate }}</span>
    <span>{{ 'emptyStates.offlineActivityLogMessage' | translate }}</span>
  </div>
</ng-template>
