import { TranslateService } from '@ngx-translate/core';
import { Incident, RelatedIncident } from '../core/models';

export const generateDisplayNameForRelatedEventOption = function (
  relatedEvent: Incident | RelatedIncident,
  _options: {
    translateService: TranslateService;
  }
): string {
  const corridor = relatedEvent.address?.corridor || '';
  const direction = relatedEvent.address?.direction
    ? _options.translateService.instant(relatedEvent.address.direction)
    : '';
  const orientation = relatedEvent.address?.orientation
    ? _options.translateService.instant(relatedEvent.address.orientation)
    : '';
  const crossroad = relatedEvent.address?.crossroad || '';
  const subType = relatedEvent['subType']
    ? `| ${_options.translateService.instant('incidentSubTypes.' + relatedEvent['subType'])}`
    : '';
  let type = _options.translateService.instant('incidentTypes.' + relatedEvent.type);

  if (type === 'incidentTypes.' + relatedEvent.type) {
    type = _options.translateService.instant('workRequestTypes.' + relatedEvent.type);
  }

  return `${corridor} ${direction} ${orientation} ${crossroad}`;
};
