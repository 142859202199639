import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { CustomFormControlComponent } from './custom-form-control';
import { WCErrorStateMatcher } from './error-state-matcher';

const Materials: any[] = [MatFormFieldModule, MatInputModule];

@NgModule({
  declarations: [CustomFormControlComponent],
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, Materials],
  providers: [FormControl, WCErrorStateMatcher],
  exports: [CustomFormControlComponent, TranslateModule, FormsModule, ReactiveFormsModule, Materials],
})
export class WcBaseControlFieldModule {}
