<mat-button-toggle-group
  [value]="ngControl.value"
  class="button-toggle-group-component"
  [class.read-only]="readOnly"
  [class.large]="size === 'large'"
  (change)="onValueChange($event.value)">
  <mat-button-toggle
    [tabIndex]="readOnly ? -1 : 0"
    (click)="$event.stopPropagation()"
    [disableRipple]="true"
    *ngFor="let option of formFieldData.options"
    [value]="option.value">
    <ng-container *ngIf="option.startIcon">
      <wc-icon class="button-toggle-group-component__start-icon" [iconName]="option.startIcon"></wc-icon>
    </ng-container>
    <span>{{ option.displayName }}</span>
  </mat-button-toggle>
</mat-button-toggle-group>
