import { Injectable } from '@angular/core';
import {
  CreateUserInput,
  DeleteUserUnitInput,
  OptionalDateTimePeriodInput,
  SetUserRolesInput,
  SetUserUnitInput,
  UpdateUserInput,
  User,
} from '@wc/core/models/gql.models';
import { PermissionsFacadeService, RolesFacadeService } from '@wc/permissions/domain/src';
import * as Utils from '@wc/utils';
import { ScopeAccessModifier } from '@wc/wc-models/src';
import { getCenterOfBounds } from 'geolib';
import { cloneDeep } from 'lodash';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { AuthUserService } from './auth-user.service';
import { AuthService } from './auth.service';
import { UsersApiService } from './users-api.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  users: { [key: string]: User } = {};

  get roles() {
    return this.rolesFacadeService.roles;
  }

  constructor(
    private usersApiService: UsersApiService,
    private authService: AuthService,
    private rolesFacadeService: RolesFacadeService,
    private permissionsFacadeService: PermissionsFacadeService,
    private authUserService: AuthUserService
  ) {}

  /**
   *  @deprecated support backwards compatibility in file structure epics
   */
  get authUser() {
    return this.authUserService.user;
  }

  /**
   *  @deprecated support backwards compatibility in file structure epics
   */
  setAuthUser(user: User) {
    this.users[user.id] = user;
  }
  /**
   *  @deprecated support backwards compatibility in file structure epics
   */
  hasPermission(permissionStr: ScopeAccessModifier | ScopeAccessModifier[]) {
    return this.permissionsFacadeService.hasPermission(permissionStr);
  }

  accountLocationCenterFromPolygon(mapCenterPolygon: { coordinates: number[][]; type: string }): number[] {
    const coordinates: { latitude; longitude }[] = [];
    mapCenterPolygon.coordinates[0].forEach(cord => {
      coordinates.push({ latitude: cord[0], longitude: cord[1] });
    });
    const res = getCenterOfBounds(coordinates);
    return [res.latitude, res.longitude];
  }

  get usersList(): User[] {
    return Utils.toArray(this.users);
  }

  createUser(inputs: { userInput: CreateUserInput; userUnit?: SetUserUnitInput }) {
    return this.usersApiService.createUser(inputs.userInput).pipe(
      mergeMap(user => {
        if (inputs.userUnit) {
          const setUserUnitInput: SetUserUnitInput = inputs.userUnit;
          setUserUnitInput.userId = user.id;
          return this.usersApiService.assignUnitToUser(setUserUnitInput);
        } else {
          return of(user);
        }
      }),
      tap((user: User) => {
        console.log(user);
        this.users[user.id] = user;
        this.users = { ...{}, ...this.users };
      })
    );
  }

  getUser(userId: number) {
    return this.usersApiService.getUserByID(userId).pipe(
      tap((user: User) => {
        this.users[user.id] = user;
        this.users = { ...{}, ...this.users };
      })
    );
  }

  getAllUsers(): Observable<User[]> {
    if (!this.permissionsFacadeService.hasPermission('USER:READ')) {
      console.error('getUsers is called without USER:READ ');
      return of();
    }
    return this.usersApiService.getUsers().pipe(
      tap(users => {
        this.users = { ...{}, ...Utils.toObjectWithId(users) };
      })
    );
  }

  getUsersByPeriod(optionalDateTimePeriodInput: OptionalDateTimePeriodInput = { value: null }) {
    if (!this.permissionsFacadeService.hasPermission('USER:READ')) {
      console.error('getUsers is called without USER:READ ');
      return;
    }
    return this.usersApiService.getUsers(optionalDateTimePeriodInput);
  }

  updateUser(inputs: {
    userInput: UpdateUserInput;
    rolesInput?: SetUserRolesInput;
    userUnit?: SetUserUnitInput;
    unassignUserUnit?: DeleteUserUnitInput;
  }): Observable<string | User> {
    const servicesObservables = {};
    if (inputs.userInput) servicesObservables['updateUser'] = this.usersApiService.updateUser(inputs.userInput);
    if (inputs.rolesInput)
      servicesObservables['assignUserRoles'] = this.usersApiService.assignUserRoles(inputs.rolesInput);
    if (inputs.userUnit)
      servicesObservables['assignUnitToUser'] = this.usersApiService.assignUnitToUser(inputs.userUnit);
    if (inputs.unassignUserUnit)
      servicesObservables['unassignUnitToUser'] = this.usersApiService.unassignUnitToUser(inputs.unassignUserUnit);
    if (Utils.isEmptyObj(servicesObservables)) return of('');

    return forkJoin(servicesObservables).pipe(
      map(args => {
        console.log(args);
        let user: User = args['updateUser'] || {};
        if (args['assignUserRoles']) {
          user = { ...user, ...(args['assignUserRoles'] as User) };
        }
        if (args['assignUnitToUser']) {
          user = { ...user, ...(args['assignUnitToUser'] as User) };
        }
        if (args['unassignUnitToUser']) {
          user = { ...user, ...(args['unassignUnitToUser'] as User) };
        }
        return user;
      }),
      tap((user: User) => {
        if (user) {
          if (!user.account) {
            user.account = cloneDeep(this.users[user.id].account);
          }
          this.users[user.id] = user;
          this.users = { ...{}, ...this.users };
        }
      }),
      catchError(err => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          return throwError({
            errorCode: err.graphQLErrors[0].extensions.statusCode,
          });
        } else {
          return throwError(err);
        }
      })
    );
  }

  deleteUser(user: User) {
    const userId = user.id;
    const servicesObservables = {};
    if (user.unit)
      servicesObservables['unassignUnit'] = this.usersApiService.unassignUnitToUser({
        userId: userId,
        unitId: user.unit?.id,
      });
    servicesObservables['deleteUser'] = this.usersApiService.deleteUser({
      userId: userId,
    });

    return forkJoin(servicesObservables).pipe(
      tap(() => {
        delete this.users[userId];
        this.users = { ...{}, ...this.users };
      })
    );
  }

  logoutUser() {
    this.usersApiService.logoutUser().subscribe(() => {
      this.authService.logout();
    });
  }
}
