/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { UnitsStoreEntity, UnitsUIEntity } from '@wc/wc-models/src';
import { map } from 'rxjs/operators';
import { LayerType } from '../../../../../../core/models/gql.models';
import { EntitiesServiceV2 } from '../../../services/entities.service';

export interface UnitsState extends EntityState<UnitsStoreEntity, number> {}
export interface UnitsUIState extends EntityState<UnitsUIEntity> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: LayerType.Unit })
export class UnitsStore extends EntityStore<UnitsState> {
  ui!: EntityUIStore<UnitsUIState>;
  constructor(entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$.pipe(map(({ UNIT }) => UNIT || [])).subscribe(UIUnits => this.ui.upsertMany(UIUnits));

    entitiesService.getDiff$
      .pipe(map(({ modified, removed }) => ({ removedUnits: removed?.UNIT, modifiedUnits: modified.UNIT })))
      .subscribe(({ removedUnits, modifiedUnits }) => {
        if (modifiedUnits) this.upsertMany(modifiedUnits as UnitsStoreEntity[]);
        if (removedUnits) this.remove(removedUnits);
      });

    entitiesService.activeEntityIds$.pipe(map(({ UNIT }) => UNIT || [])).subscribe(id => {
      this.ui.setActive(id);
    });
  }
}
