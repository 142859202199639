<div
  data-cy-id="autocomplete-result-container"
  class="autocomplete-component__option--container"
  [class.indented]="indented"
  (click)="optionClicked.emit({ selectedOption: option, clickEvent: $event })"
  [class.multi]="isMultiSelect">
  <div class="autocomplete-component__option--container-flex" [class.free-text]="isFreeText">
    <mat-checkbox
      *ngIf="isMultiSelect"
      class="autocomplete-component__checkbox"
      [checked]="filledCheckbox"
      [indeterminate]="indeterminateCheckbox"></mat-checkbox>
    <div *ngIf="option.startIcon && !isMultiSelect" class="autocomplete-component__option--icon">
      <wc-icon [iconName]="option.historical ? 'history' : option.startIcon"></wc-icon>
    </div>
    <span
      data-cy-id="autocomplete-component-option-text"
      *ngIf="!isFreeText; else freeTextOption"
      class="autocomplete-component__option--text"
      >{{ option.displayName }}</span
    >
    <div *ngIf="option.endIcon" class="autocomplete-component__option--icon right">
      <wc-icon [iconName]="option.endIcon + ''"></wc-icon>
    </div>
  </div>
  <div
    data-cy-id="autocomplete-component-second-line"
    class="autocomplete-component__option--second-line"
    [class.indented]="option.startIcon"
    [class]="option.extraLineField.cssClass"
    *ngIf="option.extraLineField?.value as value">
    <wc-icon *ngIf="option.extraLineField.startIcon as icon" [iconName]="icon"></wc-icon>
    {{ value }}
  </div>
</div>

<ng-template #freeTextOption>
  <span
    *ngIf="isFreeText"
    class="autocomplete-component__option--text autocomplete-component__option__free-text-wrapper">
    {{ 'add' | translate }}
    <span data-cy-id="autocomplete-component-free-text" class="autocomplete-component__option--text__free-text">
      "{{ option.displayName }}"</span
    ></span
  >
</ng-template>
