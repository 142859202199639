import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { DateFieldComponent } from './date-field.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';

const Materials: any[] = [MatCheckboxModule, MatInputModule];

@NgModule({
  declarations: [DateFieldComponent],
  imports: [
    CommonModule,
    WcBaseControlFieldModule,
    TranslateModule,
    Materials,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  providers: [],
  exports: [DateFieldComponent],
})
export class WcDateFieldModule {}
