import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Enums from '@wc/core/models/enums';
import { action, computed, makeObservable, observable } from 'mobx';
import { forkJoin, iif, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMapTo, tap } from 'rxjs/operators';
import {
  ActivitySourceType,
  CreateShiftInput,
  CreateShiftPauseInput,
  EndShiftInput,
  ShiftDto,
  ShiftEndReasons,
  ShiftPauseDto,
  ShiftPauseReasons,
  Status,
  UnitRelationType,
  UnitVehicle,
  UpdateShiftInput,
  User,
} from '../../core/models/gql.models';
import { SelectOption } from '../../features/ui/form-controls/form-models';
import * as Utils from '../../utils';
import { selectedOptionsAlphaNumericSort } from '../../utils';
import { ShiftsService } from '../services/shifts.service';
import { UsersService } from '../services/users.service';
import { UiStore } from '../stores/ui.store';
import { UsersStore } from '../stores/users.store';
import { AccountStore } from './account.store';

@Injectable({
  providedIn: 'root',
})
export class ShiftStore {
  constructor(
    private accountStore: AccountStore,
    private shiftsService: ShiftsService,
    private translateService: TranslateService,
    private usersService: UsersService,
    private usersStore: UsersStore,
    private uiStore: UiStore
  ) {
    makeObservable(this);
  }

  @observable currentShift: ShiftDto = {} as ShiftDto;
  @observable currentShiftPause: ShiftPauseDto = {} as ShiftPauseDto;
  @observable driversWithoutUnits: Array<number> = [];

  @computed
  get shiftTypesOptions(): Array<SelectOption> {
    const shiftTypes: Array<SelectOption> = [];
    this.accountStore.account.shiftTypes.forEach(shift => {
      if (shift.status === 'Active') {
        shiftTypes.push({ value: shift.id, displayName: shift.name });
      }
    });
    return shiftTypes;
  }

  @computed
  get shiftTypesOptionsIncludeInactive(): Array<SelectOption> {
    const shiftTypes: Array<SelectOption> = [];
    this.accountStore.account.shiftTypes.forEach(shift => {
      shiftTypes.push({
        value: shift.id,
        displayName:
          shift.status === Status.Inactive
            ? shift.name + ' (' + this.translateService.instant('Del.') + ')'
            : shift.name,
      });
    });

    return shiftTypes.sort((a, b) => {
      return (a.displayName as string).localeCompare(b?.displayName as string, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });
  }

  @computed
  get routeTypesOptions(): Array<SelectOption> {
    const routeTypes: Array<SelectOption> = [];
    this.accountStore.routeTypes.forEach(route => {
      if (route.status === 'Active') {
        routeTypes.push({ value: route.id, displayName: route.name });
      }
    });

    return routeTypes.sort((a, b) => {
      return (a.displayName as string).localeCompare(b?.displayName as string, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });
  }

  get routeTypesOptionsIncludeInactive(): Array<SelectOption> {
    const routeTypes: Array<SelectOption> = [];
    this.accountStore.account.routeTypes.forEach(route => {
      routeTypes.push({
        value: route.id,
        displayName:
          route.status === Status.Inactive
            ? route.name + ' (' + this.translateService.instant('Del.') + ')'
            : route.name,
      });
    });

    return routeTypes.sort((a, b) => {
      return (a.displayName as string).localeCompare(b?.displayName as string, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });
  }

  @computed
  get unitsOptions(): Array<SelectOption> {
    return this.accountStore.unitsOptionsByAccount;
  }

  @computed
  get driversListOptions(): SelectOption[] {
    const driversList: Array<SelectOption> = [];
    const driversWithoutUnits: Array<number> = [];

    this.usersStore.usersList.forEach((user: User) => {
      if (user.status === Status.Active) {
        driversList.push({
          value: user.id,
          displayName: user.name,
        });
      }

      if (user.unitRelationType === UnitRelationType.NoUnit) {
        driversWithoutUnits.push(user!.id);
      }
    });

    this.driversWithoutUnits = driversWithoutUnits;
    return selectedOptionsAlphaNumericSort(driversList);
  }

  @computed
  get shiftPauseReasonsOptions(): Array<SelectOption> {
    return Utils.EnumToOptions(ShiftPauseReasons, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'shiftPauseReasons',
    });
  }

  @computed
  get shiftEndReasonsOptions(): Array<SelectOption> {
    return Utils.EnumToOptions(ShiftEndReasons, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'shiftEndReasons',
    });
  }

  @action
  updateCurrentShift(updatedData: UpdateShiftInput) {
    Object.assign(this.currentShift, updatedData);
  }

  @action
  createShift(shiftInput: CreateShiftInput) {
    shiftInput.startMileage = Number(shiftInput.startMileage);
    this.uiStore.showLoader('shift');

    // It should be possible to create a shift without a unit
    // If the user entered a unit, it should not be possible to continue creating shift if 'assignUnitToUser has failed

    return iif(
      () => !!shiftInput.unitId,
      this.usersService.assignUnitToUser({
        userId: this.usersStore.authUser.id,
        unitId: shiftInput.unitId as number,
        forceOverride: true,
      }),
      of(null)
    ).pipe(
      switchMapTo(this.shiftsService.createShift(shiftInput)),
      tap((shift: ShiftDto) => {
        this.currentShift = { ...{}, ...shift };
        this.usersStore.updateAuthUserUnitData(shift.unit as UnitVehicle);
      }),
      catchError(err => {
        return throwError(err);
      }),
      finalize(() => this.uiStore.hideLoader('shift'))
    );
  }

  @action
  updateShift(shiftInput: {
    unitId?: number;
    startMileage?: number;
    shiftId: number;
    shiftTypeId?: number;
    routeTypesIds?: number[];
  }) {
    this.uiStore.showLoader('shift');
    const servicesObservables = {};
    const formatShiftInput: UpdateShiftInput = {
      shiftId: shiftInput.shiftId,
      activitySource: ActivitySourceType.Api,
    };

    if (shiftInput.unitId) {
      servicesObservables['assignUnitUser'] = this.usersService.assignUnitToUser({
        userId: this.usersStore.authUser.id,
        unitId: shiftInput.unitId as number,
        forceOverride: true,
      });
    }
    if (shiftInput.startMileage)
      formatShiftInput.startMileage = {
        value: Number(shiftInput.startMileage),
      };
    if (shiftInput.unitId) formatShiftInput.unitId = { value: shiftInput.unitId };
    if (shiftInput.shiftTypeId) formatShiftInput.shiftTypeId = { value: shiftInput.shiftTypeId };
    if (shiftInput.routeTypesIds) formatShiftInput.routeTypesIds = shiftInput.routeTypesIds;

    servicesObservables['updateShift'] = this.shiftsService.updateShift(formatShiftInput);

    return forkJoin(servicesObservables).pipe(
      map(args => {
        const shift: ShiftDto = args['updateShift'];
        return shift;
      }),
      tap(shift => {
        this.currentShift = { ...{}, ...shift };
        this.usersStore.updateAuthUserUnitData(shift.unit as UnitVehicle);
      }),
      catchError(err => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          return throwError({
            errorCode: err.graphQLErrors[0].extensions.statusCode,
          });
        } else {
          if (err?.id) {
            this.currentShift = { ...{}, ...err };
          }
          return throwError(err);
        }
      }),
      finalize(() => {
        this.uiStore.hideLoader('shift');
        this.currentShift = this.restoreShiftAfterUpdateOffline(this.currentShift);
        // for offline mode
        //this.usersStore.authUser.unit = this.restoreUnitAfterShiftUpdatOffline(this.currentShift.unit?.id) as UnitVehicle;
        //this.shiftsService.updateCurrentShift(this.currentShift);
      })
    );
  }

  @action
  startShiftPause(shiftInput: CreateShiftPauseInput) {
    return this.shiftsService.startShiftPause(shiftInput).pipe(
      tap(shift => {
        this.currentShiftPause = { ...{}, ...shift };
      })
    );
  }

  @action
  pauseEndShift(pauseId: number): Observable<ShiftPauseDto> {
    return this.shiftsService.endShiftPause(pauseId).pipe(
      tap(shift => {
        this.currentShiftPause = { ...{}, ...shift };
      })
    );
  }

  @action
  endShift(shiftInput: EndShiftInput): Observable<ShiftDto> {
    return this.shiftsService.endShift(shiftInput).pipe(
      tap(shift => {
        this.currentShift = { ...{}, ...shift };
      })
    );
  }

  getCurrentShift() {
    if (!navigator.onLine) return of();
    return this.usersService.getCurrentShift().pipe(
      tap((shift: ShiftDto) => {
        this.currentShift = { ...{}, ...shift };
        this.shiftsService.updateCurrentShift(shift);
        const authUser = this.usersStore.authUser;

        if (!shift) {
          if (this.shiftTypesOptions.length > 0 || this.routeTypesOptions.length > 0) {
            const event = new CustomEvent('openShiftModal', {
              detail: {
                shiftFormState: Enums.ShiftFormState.Start,
                data: {
                  unitId: null,
                  startMileage: null,
                  shiftTypeId: null,
                  routeTypesIds: null,
                },
              },
            });
            document.dispatchEvent(event);
          } else {
            // No Shift types & no Route types.
            this.showAssignUnitUserModal();
          }

          return of({});
        }

        // Unit was taken from ChangingUnit user
        if (
          (authUser.unitRelationType === UnitRelationType.ChangingUnit ||
            authUser.unitRelationType === UnitRelationType.ConstantUnit) &&
          !authUser.unit?.id
        ) {
          this.showAssignUnitUserModal();
          return of({});
        }

        // Unit was taken from CONSTANT_UNIT user
        // if (authUser.unitRelationType === UnitRelationType.ConstantUnit && !authUser.unit) {
        //   TODO
        //   return;
        // }

        // Return to Shift Pause
        const currentPauseIndex = shift.shiftPauses.findIndex(pause => pause.endedAt === null);
        if (shift.shiftPauses.length && currentPauseIndex !== -1) {
          this.currentShiftPause = shift.shiftPauses[currentPauseIndex];
          // Return to Shift Pause
          const event = new CustomEvent('openShiftModal', {
            detail: {
              shiftFormState: Enums.ShiftFormState.PauseEnd,
              data: this.currentShiftPause,
            },
          });
          document.dispatchEvent(event);
          return of({});
          // Assigned ShiftType was removed from account
        } else if (!this.isCurrentShiftExist()) {
          // Your current shift was removed.
          if (this.shiftTypesOptions?.length === 0) {
            return of({});
          }
          const event = new CustomEvent('openShiftModal', {
            detail: {
              shiftFormState: Enums.ShiftFormState.ShiftRemoved,
              data: {},
            },
          });
          document.dispatchEvent(event);
          return of({});

          // Assigned Routes were removed from account
        } else if (!this.isCurrentRoutesExists()) {
          if (this.routeTypesOptions?.length === 0) {
            return of({});
          }

          const event = new CustomEvent('openShiftModal', {
            detail: {
              shiftFormState: Enums.ShiftFormState.RouteRemoved,
              data: {},
            },
          });
          document.dispatchEvent(event);
          // Routes were removed from the account.
          // Return to opened Shift
        } else if (!shift.endedAt) {
          // Return to Shift
        }
        return of(shift);
      }),
      catchError(() => {
        if (!navigator.onLine) {
          return of(this.shiftsService.offlineShift);
        } else {
          return of({});
        }
      })
    );
  }

  showAssignUnitUserModal() {
    if (this.usersStore.authUser.unitRelationType !== UnitRelationType.NoUnit && !this.usersStore.authUser.unit?.id) {
      const event = new CustomEvent('openAssignUnitUserModal', {});
      document.dispatchEvent(event);
    }
  }

  isCurrentRoutesExists() {
    if (this.currentShift.routeTypes?.length === 0) {
      return true;
    }

    const existingRoutes: number[] = [];
    this.routeTypesOptions?.forEach(routeType => {
      this.currentShift.routeTypes?.forEach(route => {
        if (route.id === routeType.value) {
          existingRoutes.push(route.id);
        }
      });
    });

    return existingRoutes.length > 0;
  }

  isCurrentShiftExist() {
    return this.shiftTypesOptions?.filter(shiftType => shiftType.value === this.currentShift?.shiftType?.id).length > 0;
  }

  isShiftCreateAllowed() {
    return this.usersStore.hasPermission('SHIFT:CREATE') && this.usersStore.hasPermission('SHIFT_TYPE:READ');
  }

  restoreShiftAfterUpdateOffline(currentShift) {
    if (currentShift.shiftType?.id) {
      currentShift = {
        ...currentShift,
        shiftType: {
          id: currentShift.shiftType?.id,
          ...this.accountStore.account.shiftTypes.find(shift => shift.id === currentShift.shiftType.id),
        },
      };
    }
    if (currentShift.routeTypes?.length) {
      currentShift = {
        ...currentShift,
        routeTypes: currentShift.routeTypes.map(_route => {
          return this.accountStore.account.routeTypes.find(accountRoute => accountRoute.id === _route.id);
        }),
      };
    }

    if (currentShift.unit?.id) {
      currentShift = {
        ...currentShift,
        unit: this.restoreUnitAfterShiftUpdatOffline(currentShift.unit?.id),
      };
    }

    return currentShift;
  }

  restoreUnitAfterShiftUpdatOffline(unitId) {
    return this.accountStore.unitsByAccount.find(_unitByAccount => (_unitByAccount.id = unitId));
  }
}
