import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  DataLayerName,
  LiveMapEntity,
  LiveMapEntityType,
  MapLayer,
  WcMapConfigModel,
  WcMapConfigToken,
  Workspace,
} from '@wc/core';
import { CacheService } from '@wc/core/services/cache.service';
import { ToastrAlertsService } from '@wc/wc-ui/src/services/toaster-alert.service';
import { QueueObject } from 'async';
import olms from 'ol-mapbox-style';
import { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import MVT from 'ol/format/MVT';
import { LineString, MultiLineString, MultiPolygon, Point } from 'ol/geom';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';
import Map from 'ol/Map';
import { fromLonLat } from 'ol/proj';
import { Cluster, Vector as VectorSource } from 'ol/source';
import VectorTileSource from 'ol/source/VectorTile';
import XYZ from 'ol/source/XYZ';
import { Stroke, Style } from 'ol/style';
import { creditText } from './config';
import { mapboxglLayer } from './mapboxgl-layer';
import * as Styles from './styles/styles';
import { coordsFormatFromLonLat } from './utils';
interface CustomWindow extends Window {
  requestIdlecallback;
}
declare let window: CustomWindow;

export let mapPrecached: boolean;
@Injectable({
  providedIn: 'root',
})
export class WcMapService {
  precacheQueue!: QueueObject<string>;
  map: Map;
  // pulses: Array<Pulse> = [];
  config: WcMapConfigModel;
  // mapLayers: any;
  visibleLayersNames: LiveMapEntityType[] = [];
  mapboxKey = 'pk.eyJ1Ijoid2F5Y2FyZSIsImEiOiJjanp1eTd4aXAwMGt0M2NueHh3dXI0dWpiIn0.v27dO5Fkf58A8s2WzJi34A';
  excludedLayersNames: LiveMapEntityType[] = [
    'traffic',
    'satellite',
    'mile_markers',
    'interactive',
    'draw',
    'unidentified',
    'workspace',
    'transit_on_demand_unit',
    'transit_on_demand_unit_my_agency',
    'transit_demand_response_unit',
    'transit_demand_response_unit_my_agency',
    'transit_fixed_route_bus_unit',
    'transit_fixed_route_bus_unit_my_agency',
    'transit_fixed_routes',
    'transit_fixed_routes_my_agency',
    'transit_fixed_stops',
    'transit_fixed_stops_my_agency',
    'transit_fixed_stops',
    'traffic_anomaly',
    // 'weather_alert'
  ];
  entityFilter: { (entity?: LiveMapEntity): boolean } = () => true;
  satelliteLayerLoaded!: boolean;
  mileMarkersLayerLoaded!: boolean;

  constructor(
    map: Map,
    @Inject(WcMapConfigToken) config: WcMapConfigModel,
    private http: HttpClient,
    private alertService: ToastrAlertsService,
    private translateService: TranslateService,
    private cacheService: CacheService
  ) {
    window['wcMap'] = map;
    this.map = map;
    this.config = config;
    // this.map.addLayer(this.trafficLayer());
    // this.satelliteLayer();
    // this.map.addLayer(this.satelliteLayer());
    // const sat = olms(this.map, `https://api.mapbox.com/styles/v1/ofir3005/ckqasjg6x1j3y17o750bhk7w0?access_token=pk.eyJ1Ijoib2ZpcjMwMDUiLCJhIjoiY2p6czA2ZnVxMWE0djNscjFwMGF2MndyZyJ9.PLlmRDFrzFsce1u5qYfpJg`);
    // const satLayer = olms.getLayer(sat);
    // console.log(sat);
    // (window as any).invalidateMapTileCache = this.invalidateMapTileCache;
  }

  public multiPolygonConvertFromLatLong(multiPolygon) {
    return multiPolygon.map(mPolygon => mPolygon.map(polygon => polygon.map(point => fromLonLat(point))));
  }

  public jurisdictionFromConfig(config: WcMapConfigModel, convertFromLatLong = true): number[][][] {
    const mapperFn = convertFromLatLong ? fromLonLat : x => x;
    // TODO:
    const workspace: Workspace | undefined = config.workspaces ? config.workspaces[0] : undefined;
    const jurisdiction = workspace?.area.coordinates.map(multiPolygon =>
      multiPolygon.map(polygon => polygon.map(point => mapperFn(point)))
    );
    return jurisdiction?.length > 0 ? jurisdiction : null;
  }

  public tileSource(config: WcMapConfigModel, mapStyleUrl?: string, tileSize = 512) {
    const mapStyle = config.style[config.currentStyle];
    const urlTemplate = mapStyleUrl || `${config.tilesSrc}/styles/${mapStyle}/{z}/{x}/{y}.jpeg`;
    return new XYZ({
      attributions: [creditText],
      attributionsCollapsible: false,
      tileSize,
      tileUrlFunction: tileCoord => {
        const url = urlTemplate
          .replace('{z}', tileCoord[0].toString())
          .replace('{x}', tileCoord[1].toString())
          .replace('{y}', tileCoord[2].toString())
          .replace(
            '{a-d}',
            'abcd'.substr(
              // tslint:disable-next-line: no-bitwise
              ((tileCoord[1] << tileCoord[0]) + tileCoord[2]) % 4,
              1
            )
          );
        return url;
      },
    });
  }

  toggleLayerVisibility(layerName: DataLayerName) {
    this.map.getLayers().forEach(layer => {
      const _layerName = layer.get('name');
      if (_layerName === layerName) {
        layer.setVisible(!layer.getVisible());
      }
      if (layerName === 'mile_markers' && !this.mileMarkersLayerLoaded) this.mileMarkersLayer();
      if (layerName === 'satellite' && !this.satelliteLayerLoaded) this.satelliteLayer();
    });
  }

  updateLayersVisibility(layersNames: LiveMapEntityType[]) {
    this.visibleLayersNames = layersNames;
    this.map.getLayers().forEach(layer => {
      const layerName = layer.get('name');
      if (layerName && layersNames) {
        if (this.excludedLayersNames.find(_name => _name === layerName)) return;
        const showLayer = layersNames.find(name => name.toLowerCase() === layerName); //
        // layer.setVisible(showLayer);// || layersNames.length === 0
        layer.setOpacity(showLayer ? 1 : 0);
      }
    });
    this.allLayersVisibilityDetectionMode(false);
  }

  allLayersVisibilityDetectionMode(visible: boolean) {
    this.map.getLayers().forEach(layer => {
      if (!(layer instanceof VectorLayer)) return;
      if (this.excludedLayersNames.find(_name => _name === layer.get('name'))) return;

      if (visible) {
        layer.setVisible(true);
      } else {
        layer.setVisible(!!layer.getOpacity());
      }
      // console.log(layer.getVisible());
    });
  }

  updateLayers(
    layersData: {
      [layerName in LiveMapEntityType]?: { [id in number]: LiveMapEntity };
    },
    refreshAll?: boolean,
    entityFilter?: { (entity?: LiveMapEntity): boolean }
  ) {
    this.entityFilter = entityFilter || this.entityFilter; //() => { return true };
    if (!this.map) return;

    if (refreshAll) {
      for (let i = 0; i < Object.keys(layersData).length; i++) {
        this.map.getLayers().forEach(layer => {
          if (layer && layer.get('name') && !this.excludedLayersNames.includes(layer.get('name'))) {
            this.map.removeLayer(layer);
          }
        });
      }
    }

    for (const name in layersData) {
      let isNewLayer = true;
      this.map.getLayers().forEach(layer => {
        if (layer.get('name') === name) {
          isNewLayer = false;
          this.updateFeatures(layer as VectorLayer, layersData[name]);
        }
      });
      if (isNewLayer) {
        this.addLayer(layersData[name], name as LiveMapEntityType);
        this.updateLayersVisibility(this.visibleLayersNames);
      }
    }
    this.map.render();
  }

  removeEntities(layersData) {
    for (const name in layersData) {
      this.map.getLayers().forEach(layer => {
        this.removeFeatures(layer as VectorLayer, layersData[name]);
      });
    }
  }

  addLayer(layerData: MapLayer, name: string): Promise<VectorLayer | Cluster> | void {
    if (!this.config.layers[name]) {
      console.warn(`${name} layer not found in livemap config file.`);
      return;
      // reject();
    }
    const clusterDistance = this.config.layers[name].clusterDistance || 0;
    let activeLayer: VectorLayer;

    // console.log(clusterDistance);
    if (clusterDistance === 0) {
      activeLayer = new VectorLayer({
        // name: name,
        zIndex: layerData.zIndex || this.config.layers[name].zIndex || this.config.defaultLayerZIndex || Infinity,
        source: this.source(layerData),
        style: feature => {
          return Styles.styleType(feature.getProperties() as LiveMapEntity, this.config, feature);
        },
      });
      activeLayer.set('name', name);
      // console.log('layer name: ', name, ' z-index: ', layerData.zIndex , this.config.layers[name].zIndex, this.config.defaultLayerZIndex , Infinity)
      this.map.getLayers().push(activeLayer);
      // return;
      // setTimeout( () => {
      // resolve(activeLayer);
      return;
      // }, 1000)
    }
    ////else {

    const clusterSource = new Cluster({
      distance: clusterDistance,
      source: this.source(layerData),
      // geometryFunction: (feature) => {
      //   return feature.getGeometry();
      // }
    });
    // if (name === 'road_closure') {
    //   console.log('addLayer ', name);
    //   // console.log(this.source(layerData))
    // }
    const styleCache = {};
    activeLayer = new VectorLayer({
      // name: name,
      zIndex: layerData.zIndex || this.config.layers[name].zIndex || this.config.defaultLayerZIndex || Infinity,
      source: clusterSource,
      style: feature => {
        if (feature.getProperties().features) {
          const _entity = feature.getProperties().features[0].getProperties();
          _entity.status = feature.getProperties().features.length > 1 ? 'active' : _entity.status;
          return Styles.styleType(_entity, this.config, feature);
        } else {
          if (feature.getProperties().featureType === 'road_closure') {
            console.log(feature.getProperties().features[0]);
          }
          return Styles.styleType(feature.getProperties() as LiveMapEntity, this.config, feature);
        }
      },
    });
    // }
    activeLayer.set('name', name);
    this.map.addLayer(activeLayer);
    // resolve(activeLayer);
    return;
  }

  removeUnactiveLayers(layersData) {
    const currentLayers = this.map.getLayers().getArray(); //.array_
    currentLayers.forEach(layer => {
      if (
        layer.get('name') !== 'map' &&
        (!layersData[layer.get('name')] || layersData[layer.get('name')].length === 0)
      ) {
        this.map.removeLayer(layer);
      }
    });
    return this.map.getLayers().getArray();
  }

  removeFeatures(layer: VectorLayer, entitiesIds: Array<string | number>) {
    if (!(layer instanceof VectorLayer)) return;
    const source = layer.getSource() instanceof Cluster ? layer.getSource().get('source') : layer.getSource();
    const currentFeatures = source?.getFeatures() || [];
    // console.log('++++++++++ ', layer.getProperties().name)
    // if(layer.getProperties().name === 'interactive') {
    //   console.log('Is cluster: ', layer.getSource() instanceof Cluster);
    //   console.log(layer.getSource());
    //   console.log(entitiesIds);
    //   console.log(currentFeatures);
    //   // console.log(currentFeatures[0].getId());
    //   // if (currentFeatures[0] && entitiesIds[0]) source.removeFeature(currentFeatures[0]);
    // }

    entitiesIds.forEach(entityId => {
      const currentFeature = currentFeatures.find(
        feature =>
          feature.getId() === String(entityId) ||
          feature.getProperties().id === String(entityId) ||
          feature.getProperties().id === entityId
      );
      // const pulses = feature.getProperties().pulses;
      // if (pulses) pulses.forEach(pulse => { pulse.stop() });
      // console.log(currentFeature);
      if (currentFeature) source.removeFeature(currentFeature);
    });
  }

  updateFeatures(layer: VectorLayer, entities: LiveMapEntity[]) {
    // const layerName = layer.get('name');
    // console.log(entities);
    const source =
      layer.getSource() instanceof Cluster ? (layer.getSource() as Cluster).getSource() : layer.getSource();
    const _entities = entities;
    const currentFeatures = source?.getFeatures() || [];
    // console.log(layer.getSource());
    // console.log(currentFeatures);

    for (const id in _entities) {
      const entity = _entities[id];
      // if (!this.entityFilter(entity)) return
      // console.log(entity)
      const currentFeature = currentFeatures.find(feature => {
        // if (layer.get('name') ==='road_closure') {
        //   console.log(feature);
        //   console.log(feature.getId(), id);
        //   console.log(feature.getProperties())
        //   console.log((feature.getProperties()) ? String(feature.getProperties().id) === id : false)
        // }
        return String(feature.get('id')) === String(id);
        // return feature.getId() === id || (feature.getProperties()) ? String(feature.getProperties().id) === id : false;
      });
      // console.log(currentFeature);
      if (currentFeature) {
        //Update existing feature.
        if (this.entityFilter(entity)) {
          this.updateFeature(currentFeature, entity);
        } else {
          this.removeFeatures(layer, [entity.id]);
        }
      } else {
        //Add new feature.
        if (this.entityFilter(entity)) {
          const feature = this.asFeature(entity as LiveMapEntity);
          feature.setProperties(entity);
          source?.addFeature(feature);
        }
        // if (entity.selected) layerName
      }
    }
  }

  source(data) {
    // console.log(data)
    const source = new VectorSource();
    if (!data) return source;
    // if (data.length > 0) {
    for (const key in data) {
      const entity = data[key];
      if (entity && this.entityFilter(entity)) {
        // console.log(entity)
        const feature = this.asFeature(entity);
        // const style = Styles.type(entity, this.config);
        // if (style) feature.setStyle(style);
        if (feature) {
          feature.setProperties(entity);
          source.addFeature(feature);
        }
      }
    }
    // console.log(source.getFeatures());
    // data.forEach((entity: LiveMapEntity) => {
    // })
    // }
    return source;
  }

  updateFeature(feature, entityNewData) {
    const entity = entityNewData;
    const location = entity.location;
    if (!location) {
      console.warn('Entity has no location');
      console.warn(JSON.parse(JSON.stringify(entity)));
      return;
    }

    const coordinates = coordsFormatFromLonLat(location);
    let geometry;
    switch (location.type) {
      case 'Point':
        geometry = new Point(coordinates as Coordinate);
        break;
      case 'LineString':
        geometry = new LineString(coordinates as Coordinate[]);
        break;
      case 'MultiLineString':
        geometry = new MultiLineString(coordinates as Coordinate[][]);
        break;
      case 'Polygon':
        geometry = new Polygon(coordinates as Coordinate[][]);
        break;
      case 'MultiPolygon':
        geometry = new MultiPolygon(coordinates as Coordinate[][][]);
    }
    feature.setGeometry(geometry);
    feature.setProperties(entityNewData);
    // const style = Styles.styleType(feature.getProperties(), this.config);
    // if (style) feature.setStyle(style);
    this.updateFeatureStyle(feature);
    feature.setProperties(entityNewData);
  }

  updateFeatureStyle(feature) {
    const style = Styles.styleType(feature.getProperties(), this.config);
    if (style) feature.setStyle(style);
  }

  asFeature(entity: LiveMapEntity) {
    let feature: Feature;
    if (!entity || !entity.location) {
      console.error(`entity: ${entity} has no location`);
      return new Feature();
    }
    const location = entity.location;
    const coordinates = coordsFormatFromLonLat(entity.location);
    // console.log(coordinates);
    // if (!Array.isArray(coordinates[0])) coordinates = [coordinates as Coordinates];
    // coordinates = (coordinates as Array<Coordinates>).map( (coord: Coordinates) => fromLonLat(coord));
    // const coords = fromLonLat([coordinates[0], coordinates[1]]);
    // if (location.type !== 'Point') return new Feature();
    // console.log(location.type)
    switch (location.type) {
      case 'Point':
        feature = new Feature({
          geometry: new Point(coordinates as Coordinate),
          id: entity.id,
        });
        break;
      case 'LineString':
        feature = new Feature({
          geometry: new LineString(coordinates as Coordinate[]),
          // labelPoint: new Point(coordinates[0]),
          id: entity.id,
        });
        break;
      case 'Polygon':
        feature = new Feature({
          geometry: new Polygon(coordinates as Coordinate[][]),
          id: entity.id,
        });
        break;
      case 'MultiLineString':
        feature = new Feature({
          geometry: new MultiLineString(coordinates as Coordinate[][]),
          id: entity.id,
        });
        break;
      case 'MultiPolygon':
        feature = new Feature({
          geometry: new MultiPolygon(coordinates as Coordinate[][][]),
          id: entity.id,
        });
    }

    // feature.setId(String(entity.id));
    // if (entity.featureType === 'road_closure') console.log(feature);
    return feature;
  }

  trafficLayer() {
    const urlTemplate = `https://{a-d}.tiles.mapbox.com/v4/mapbox.mapbox-traffic-v1/{z}/{x}/{y}.mvt?access_token=${this.mapboxKey}`;
    const layer = new VectorTileLayer({
      zIndex: 3,
      declutter: true,
      source: new VectorTileSource({
        cacheSize: 0,
        format: new MVT(),
        // attributions: ['© <a href="https://www.mapbox.com/about/maps" target="_blank">Mapbox</a>'],
        url: urlTemplate,
      }),
      style: feature => {
        let color = '#e0e4dd';
        let width = 1;

        const congestion = feature.get('congestion');

        switch (congestion) {
          case 'low':
            color = '#63d668';
            width = 2;
            break;
          case 'moderate':
            color = '#ffe500';
            width = 3;
            break;
          case 'heavy':
            color = '#ff8300';
            width = 4;
            break;
          case 'severe':
            color = '#ff0000';
            width = 5;
        }

        const line: Style = new Style({
          stroke: new Stroke({
            color: color,
            width: width,
          }),
        });

        return line;
      },
    });

    layer.set('name', 'traffic');
    layer.setVisible(false);
    return layer;
  }

  mileMarkersLayer() {
    this.mileMarkersLayerLoaded = true;
    const _map = new Map({});
    olms(_map, `${this.config.tilesSrc}/styles/mile_markers/style.json`).then(() => {
      // olms(_map, `http://localhost:8080/styles/mile_markers/style.json`).then(() => {
      _map.getLayers().forEach(layer => {
        layer.setZIndex(3);
        layer.set('name', 'mile_markers');
        this.map.addLayer(layer);
      });
      console.log(_map.getLayers());
    });
  }

  satelliteLayer() {
    this.satelliteLayerLoaded = true;
    const layer = mapboxglLayer(
      this.map,
      this.config,
      `https://api.mapbox.com/styles/v1/waycare/ckqeyhnka2mq918o4ec0gepyb?access_token=${this.mapboxKey}`
    );
    layer.setZIndex(2);
    layer.set('name', 'satellite');
    this.map.addLayer(layer);
  }
}
