<div
  class="roadway-status-panel-component"
  wcPopupCloser
  (closed)="closePanel()"
  [class.language-hebrew]="isHebrew"
  [class.portrait-mode]="isPortraitDesktopMode">
  <div class="roadway-status-panel__container">
    <div class="roadway-status-panel" [class.language-rtl]="isDirectionRtl">
      <div class="roadway-status-panel__body">
        <mat-tab-group class="roadway-status-panel-tabs" [selectedIndex]="tabIndex">
          <mat-tab class="roadway-status-panel-tab">
            <wc-segments-list-tab
              (hidePanel)="closePanel()"
              (openDetailsClicked)="selectSegmentDetails($event)"
              (layersVisibilityStateChange)="emitLayerVisibilityChange($event)"
              (sortSelectionChanged)="saveSortSelectionToLocalStorage($event)"
              [context]="contextTypesEnum.Select"
              [regionalSettings]="regionalSetting"
              [isDirectionRtl]="isDirectionRtl"
              [isShowOnMap]="isShowOnMap"
              [isPortraitDesktopMode]="isPortraitDesktopMode"
              [measureUnitType]="measureUnitType"
              [mode]="segmentDataViewMode.Short"
              [segments]="segments"
              [selectedSortSegments]="selectedSortSegments">
            </wc-segments-list-tab>
          </mat-tab>

          <mat-tab class="transit-panel-tab">
            <wc-segment-details-tab
              (backButtonClicked)="goBackToTheSegmentsList()"
              (hidePanel)="closePanel()"
              [context]="contextTypesEnum.Select"
              [regionalSettings]="regionalSetting"
              [isPortraitDesktopMode]="isPortraitDesktopMode"
              [selectedVendorId]="selectedSegmentDetails?.selectedVendorId"
              [measureUnitType]="measureUnitType"
              [mode]="segmentDataViewMode.Extended"
              [segmentDetails]="selectedSegmentFullDetails$ | async">
            </wc-segment-details-tab>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
