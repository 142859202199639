import { AccessModifier } from '.';
import { permissionsGroupsEnum } from './enums';

export const PermissionsGroup = [
  {
    type: permissionsGroupsEnum.Manager,
    modifiers: [AccessModifier.Create, AccessModifier.Update, AccessModifier.Delete],
  },
  { type: permissionsGroupsEnum.Viewer, modifiers: [AccessModifier.Read] },
  { type: permissionsGroupsEnum.NoAccess, modifiers: [] },
];
