import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { TransitAffectedServicesService } from '../route-list-tab/affected-services-transit/transit-affected-services.service';

@Component({
  selector: 'wc-selected-unit-tab',
  templateUrl: './selected-unit-tab.component.html',
  styleUrls: ['../../transit-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedUnitTabComponent {
  @Output() clickBack = new EventEmitter<0>();

  selectedAffectedServiceUIEntity$ = this.affectedServices.selectedAffectedServiceUIEntity;

  constructor(private affectedServices: TransitAffectedServicesService) {}
}
