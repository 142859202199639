import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { WcLetModule } from '@wc/wc-common/src/lib/directives';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { WcSelectModule } from '../../form-components/wc-select/wc-select.module';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { WcLoaderComponentModule } from '../miscellaneous/loader/loader.module';
import { SliderToggleControlModule } from '../slide-toggle-control/slider-toggle-control.module';
import { WcTooltipModule } from '../tooltip/tooltip.module';
import { WcButtonModule } from '../wc-button/wc-button.module';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { AffectedLanesContainerComponent } from './affected-lanes-container/affected-lanes-container.component';
import { AffectedLanesDiagramComponent } from './affected-lanes-diagram/affected-lanes-diagram.component';
import { AffectedLanesSliderComponent } from './affected-lanes-slider/affected-lanes-slider.component';
import { AffectedLaneViewComponent } from './affected-lanes-view/affected-lanes-view.component';
import { LaneShortLabelPipe } from './pipes/lane-label.pipe';

@NgModule({
  declarations: [
    AffectedLanesSliderComponent,
    AffectedLanesDiagramComponent,
    AffectedLanesContainerComponent,
    LaneShortLabelPipe,
    AffectedLaneViewComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SwiperModule,
    WcIconModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    WcTooltipModule,
    WcSelectModule,
    SliderToggleControlModule,
    AutocompleteModule,
    MatMenuModule,
    WcButtonModule,
    MatSlideToggleModule,
    WcLetModule,
    WcLoaderComponentModule,
  ],
  exports: [AffectedLanesContainerComponent, AffectedLaneViewComponent],
})
export class AffectedLanesModule {}
