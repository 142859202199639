import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UnitedCamera } from '@wc/wc-models/src/lib/types/camera';
import { Status, StreetCamera } from 'libs/core/models/gql.models';
import { CamerasService } from 'libs/wc-core/src/lib/services/cameras.service';
import { fromEvent, interval, Subscription } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'wc-camera-thumbnail',
  templateUrl: './camera-thumbnail.component.html',
  styleUrls: ['./camera-thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraThumbnailComponent implements OnInit, OnDestroy {
  @ViewChild('iFrameSecurityPixel', { static: true }) iFrameSecurityPixel!: ElementRef;

  camera!: UnitedCamera;
  cameraTitle: string | undefined;
  securedIframeUrl!: SafeUrl;
  thumbnailUrl: string | SafeUrl = './assets/icons/missing-camera.svg';
  reloadCamerasSnapshotsInterval!: Subscription;
  @Input() isReadMode = true;
  @Input() isStreetCamera = true;
  @Input() isTabletMode!: boolean;
  @Input() set cameraData(camera: UnitedCamera) {
    this.camera = camera;
    this.cameraTitle = camera['title'] ?? '';
    if (this.camera.externalId) {
      this._cameraName = this.camera.externalId.substring(this.camera.externalId.lastIndexOf('_') + 1).toUpperCase();
    }
    this.buildThumbnail();
  }
  private _cameraName!: string;
  private _isAlive = true;

  constructor(
    private cdr: ChangeDetectorRef,
    private camerasService: CamerasService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.startReloadCamerasSnapshotsInterval();
    
    fromEvent(window, 'message')
      .pipe(
        takeWhile(() => this._isAlive),
        filter(
          (event: any): event is MessageEvent<{ iframeID: string; imageSrc: string }> =>
            event?.data?.imageSrc && event.data.iframeID && this._cameraName
        ),
        filter(({ data: { iframeID } }) => iframeID.includes(this._cameraName))
      )
      .subscribe(({ data: { imageSrc } }) => {
        this.updateIFrameSnapshot(imageSrc);
      });
  }

  buildThumbnail(): void {
    if (!this.assertIsStreetCamera(this.camera) || this.camera.status === Status.Active) {
      const imageUrl = this.camerasService.buildImageUrl(this.camera);
      const url = `${imageUrl}&type=thumbnail`;

      this.camerasService.getCameraImage(url).subscribe(
        blob => {
          this.thumbnailUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
          this.cdr.markForCheck();
        },
        error => {
          console.warn('getCameraImage error: ', error);
          this.thumbnailUrl = './assets/icons/missing-camera.svg';
          this.cdr.markForCheck();
        }
      );
    }
  }

  startReloadCamerasSnapshotsInterval() {
    this.reloadCamerasSnapshotsInterval?.unsubscribe();
    const reloadInterval = this.isTabletMode ? 30000 : 10000;

    this.reloadCamerasSnapshotsInterval = interval(reloadInterval).subscribe(() => {
      if (!this.thumbnailUrl) return;
      this.buildThumbnail();
    });
  }

  updateIFrameSnapshot(imageSource: string) {
    this.thumbnailUrl = imageSource;
    this.cdr.detectChanges();
  }

  private assertIsStreetCamera(camera: UnitedCamera): camera is StreetCamera {
    return !!camera['status'];
  }

  ngOnDestroy() {
    this.reloadCamerasSnapshotsInterval?.unsubscribe();
    this._isAlive = false;
  }
}
