<mat-label class="field-label">
  <span class="required-section" *ngIf="fieldData.required">*</span>
  <span [attr.data-cy-id]="fieldData?.label + '-title'" class="multi-checkbox-title"
    >{{ fieldData?.label | translate }} ({{ selectedItemsCount }})</span
  >
</mat-label>

<form
  [ngClass]="displayStyle"
  [formGroup]="checkboxsForm"
  [class.invalid-field]="fieldFormControl.invalid && fieldFormControl.touched">
  <ng-container *ngFor="let checkbox of checkboxs.controls; let i = index">
    <mat-checkbox
      [attr.data-cy-id]="
        checkbox.get('displayName')?.value?.toString().replaceAll(' ', '_').toLowerCase() + '-checkbox'
      "
      [ngClass]="
        'heap-' + checkbox.get('displayName')?.value?.toString().replaceAll(' ', '').toLowerCase() + '-checkbox'
      "
      class="checkbox-item checkbox-wrapper"
      *ngIf="!(i >= this.showMoreLength && hideOption)"
      [checked]="checkbox.get('checked')?.value"
      (change)="checkboxChanged($event.checked, checkbox)">
      <span class="checkbox-label" [title]="checkbox.get('displayName')?.value | translate">
        {{ checkbox.get('displayName')?.value | translate }}
      </span>
    </mat-checkbox>
  </ng-container>
</form>
<div>
  <a [hidden]="!(checkboxs.controls.length > this.showMoreLength)" (click)="hideOption = !hideOption">
    <div *ngIf="hideOption" class="show-more-div">
      <span data-cy-id="show-more-btn" class="show-more-span"> {{ 'showMore' | translate }} </span>
      <i class="fas fa-angle-right"></i>
    </div>
    <div *ngIf="!hideOption" class="show-more-div">
      <span data-cy-id="show-less-btn" class="show-more-span"> {{ 'showLess' | translate }} </span>
      <i class="fas fa-angle-up"></i>
    </div>
  </a>
</div>
