import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { MultiSelectControl } from './multi-select.component';
import { MatSelectModule } from '@angular/material/select';

const Materials: any[] = [MatSelectModule];

@NgModule({
  declarations: [MultiSelectControl],
  imports: [CommonModule, WcBaseControlFieldModule, Materials],
  providers: [],
  exports: [MultiSelectControl],
})
export class MultiSelectComponentModule {}
