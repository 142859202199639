import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WcLoaderComponentModule } from '../../miscellaneous/loader/loader.module';
import { CameraViewerComponent } from './camera-viewer.component';

@NgModule({
  declarations: [CameraViewerComponent],
  imports: [CommonModule, WcLoaderComponentModule, TranslateModule, HttpClientModule],
  exports: [CameraViewerComponent],
})
export class CameraViewerModule {}
