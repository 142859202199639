import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { FormatsPipesModule, WcLetModule } from '@wc/wc-common/pipes';
import { MobxAngularModule } from 'mobx-angular';
import { WcTooltipModule } from '../tooltip/tooltip.module';
import { ActivityLogComponent } from './activity-log.component';

const Materials = [CdkAccordionModule, MatExpansionModule, MatDividerModule];
@NgModule({
  declarations: [ActivityLogComponent],
  imports: [
    CommonModule,
    Materials,
    TranslateModule,
    MobxAngularModule,
    FormatsPipesModule,
    WcTooltipModule,
    WcLetModule,
  ],
  exports: [ActivityLogComponent],
})
export class ActivityLogComponentModule {}
