import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RoadType } from '../../../core/models/gql.models';

@Pipe({
  name: 'roadTypeLabel',
})
export class RoadTypeLabelPipe implements PipeTransform {
  constructor(public translateService: TranslateService) {}
  transform(roadType: RoadType | undefined | null): string {
    if (!roadType) return '';
    let roadTypeLabel = '';
    switch (roadType) {
      case RoadType.UnknownRoadType:
      case RoadType.Other:
        return '';
      case RoadType.Primary:
      case RoadType.Secondary:
        roadTypeLabel = this.translateService.instant(`roadTypes.${roadType}`);
        return `(${roadTypeLabel} ${this.translateService.instant('road')})`;
      default:
        roadTypeLabel = this.translateService.instant(`roadTypes.${roadType}`);
        return `(${roadTypeLabel})`;
    }
  }
}
