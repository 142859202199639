<div class="swiper-component" [class.tablet-mode]="isTabletMode" *ngIf="appType !== 'widget'">
  <div
    class="swiper-container camera-swiper-container"
    [swiper]="camerasSwiperConfig"
    [(index)]="index"
    [class.read-mode]="readMode">
    <div class="swiper-wrapper">
      <ng-container *ngIf="selectedCameraTab === cameraTabEnum.CCTV; else NOT_STREET">
        <div
          class="swiper-slide camera-slide"
          [class.active]="selectedIndex === i"
          *ngFor="let streetCamera of camerasList; let i = index"
          (click)="slideClick(streetCamera, i)">
          <ng-container *ngIf="streetCamera">
            <ng-container *ngTemplateOutlet="STREET; context: { value: streetCamera }"></ng-container>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #NOT_STREET>
        <ng-container *ngIf="dashCamList.length">
          <div
            class="swiper-slide camera-slide"
            [class.active]="selectedIndex === i"
            *ngFor="let dashCam of dashCamList; let i = index"
            (click)="slideClick(dashCam, i)">
            <ng-container *ngIf="dashCam">
              <ng-container *ngTemplateOutlet="DASH; context: { value: dashCam }"></ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>

  <div
    [ngClass]="isTabletMode ? 'heap-tablet-fe-scroll-camera-gallery' : 'heap-desktop-fe-scroll-camera-gallery'"
    [wcTooltipText]="'previous' | translate"
    wcTooltipPlacement="bottom"
    [wcTooltipOffsetDistance]="0"
    class="swiper-button-prev swiper-button-prev-camera"></div>
  <div
    [ngClass]="isTabletMode ? 'heap-tablet-fe-scroll-camera-gallery' : 'heap-desktop-fe-scroll-camera-gallery'"
    [wcTooltipText]="'next' | translate"
    wcTooltipPlacement="bottom"
    [wcTooltipOffsetDistance]="0"
    class="swiper-button-next swiper-button-next-camera"></div>
</div>

<ng-template #STREET let-value="value">
  <ng-container *ngIf="value">
    <div data-cy-id="camera-stream-container" class="camera-stream-container">
      <wc-icon
        data-cy-id="remove-camera"
        iconName="times-circle"
        [wcTooltipText]="'tooltip.removeCamera' | translate"
        wcTooltipPlacement="top"
        class="icon-remove"
        (click)="removeCamera(value?.id)"
        *ngIf="!readMode"></wc-icon>
      <wc-camera-thumbnail
        [isStreetCamera]="true"
        [isReadMode]="readMode"
        [isTabletMode]="isTabletMode"
        [cameraData]="value">
      </wc-camera-thumbnail>
    </div>
  </ng-container>

  <div data-cy-id="camera-info" class="camera-info">
    <div class="camera-info-line">
      <span data-cy-id="main-camera-badge" class="main-camera-badge" *ngIf="value?.id === defaultCamera?.id">{{
        'mainCamera' | translate
      }}</span>
    </div>
    <div class="camera-slide-title">{{ value?.title }}</div>
  </div>
</ng-template>

<ng-template #DASH let-value="value">
  <ng-container *ngIf="value">
    <div class="camera-stream-container">
      <wc-camera-thumbnail
        [isStreetCamera]="false"
        [isReadMode]="readMode"
        [isTabletMode]="isTabletMode"
        [cameraData]="value">
      </wc-camera-thumbnail>
    </div>
  </ng-container>

  <div class="camera-info">
    <div class="camera-info-line"></div>
    <div data-cy-id="unit-details" class="camera-slide-title">{{ value?.title }}</div>
  </div>
</ng-template>
