import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '@wc-core';
import { IncidentMitigation, IncidentUnit, UnitResponse, UnitVehicle, VehicleType } from '@wc/core/models/gql.models';
import { EnumToOptions } from '@wc/wc-common/src/lib/utils';
import { APP_TYPE_TOKEN } from '@wc/wc-core/src/lib/injection-tokens';
import { AppTypeUnion, MapUnitTooltipResponses, SelectOption, UserDetails } from '@wc/wc-models/src';
import { FormFieldData, FormFieldOption } from '@wc/wc-ui/src/lib/base';
import { clone } from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AutocompleteComponent } from '../../components/autocomplete/autocomplete.component';
import { AutocompleteV2Component } from '../../form-components/autocomplete-v2/autocomplete-v2.component';
import { BaseControlFieldComponent } from '../base-control-field.component';
import { MitigationComponent } from '../mitigation/mitigation.component';

const ASSOCIATED_UNIT_RESPONSES = {
  [MapUnitTooltipResponses.en_route]: UnitResponse.EnRoute,
  [MapUnitTooltipResponses.on_scene]: UnitResponse.OnScene,
  [MapUnitTooltipResponses.mitigated]: UnitResponse.Mitigated,
  [MapUnitTooltipResponses.unknown_unit_status]: UnitResponse.UnknownUnitStatus,
};

interface AssociatedUnitForm {
  associatedUnit: Partial<IncidentUnit>;
  mitigations?: Array<IncidentMitigation>;
}

@Component({
  selector: 'wc-associated-unit-control',
  templateUrl: './associated-unit.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssociatedUnitComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AssociatedUnitComponent),
      multi: true,
    },
  ],
  styleUrls: ['./associated-unit.component.scss'],
})
export class AssociatedUnitComponent extends BaseControlFieldComponent implements AfterViewInit, OnInit, Validator {
  @ViewChild('unitIdDesktop', { read: AutocompleteComponent })
  unitIdDesktop?: AutocompleteComponent<FormFieldOption>;
  @ViewChild('unitId', { read: AutocompleteV2Component })
  unitId?: AutocompleteV2Component;
  @ViewChild('driverName', { read: AutocompleteV2Component })
  driverName?: AutocompleteV2Component;
  @ViewChild('driverNameDesktop', { read: AutocompleteComponent })
  driverNameDesktop?: AutocompleteComponent<FormFieldOption>;
  @ViewChild('mitigationField', { read: MitigationComponent })
  mitigationField!: MitigationComponent;
  associatedUnitForm: FormGroup = {} as FormGroup;

  form: FormGroup = {} as FormGroup;
  mitigations = new FormControl();
  unitTypeControl = new FormControl(null, [Validators.required]);
  associatedUnit: IncidentUnit = {} as IncidentUnit;
  //mitigations: Array<Partial<IncidentMitigation>> = [] as IncidentMitigation[];
  showMitigationForm = false;
  units: UnitVehicle[] = []; // all available unit to select in the form
  initAssociatedUnit: IncidentUnit = {} as IncidentUnit;
  // defaultMitigationTypeId: number = environment.defaultMitigationTypeId;
  // defaultMitigationTypeAccountId: number = environment.defaultMitigationTypeAccountId;

  @Input()
  set unitVehicles(value: UnitVehicle[]) {
    if (value) {
      this.units = value;
      if (this.unitTypeControl.value) {
        const unitIdsOptions = this.getUnitIdsOptions(this.unitTypeControl.value);
        const driverNameOptions = this.getDriverNameOptions(this.unitTypeControl.value);
        this.unitId?.updateOptions(unitIdsOptions);
        this.unitIdDesktop?.updateOptions(unitIdsOptions);
        this.driverName?.updateOptions(driverNameOptions);
        this.driverNameDesktop?.updateOptions(driverNameOptions);
      }
      if (value && !this.associatedUnitFieldData.unitType.options?.length) {
        (this.accountService.vehicleTypesOptions as unknown as Observable<FormFieldData>).subscribe(types => {
          this.associatedUnitFieldData.unitType.options = types as [];
          this.updateTypeOptions(this.initAssociatedUnit.type);
          if (this.associatedUnit.type) {
            this.unitTypeControl.setValue(this.associatedUnit.type, {
              emitEvent: false,
            });
          }
        });
      }
    }
  }
  @Input()
  index!: number;

  @Output() delete = new EventEmitter<number>();

  get isTabletMode() {
    return this._appType === 'tablet';
  }

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private translateService: TranslateService,
    @Inject(APP_TYPE_TOKEN) private _appType: AppTypeUnion
  ) {
    super();
  }

  associatedUnitFieldData = {
    unitType: {
      label: 'type',
      placeholder: 'Select',
      options: [] as SelectOption[],
      validators: [Validators.required],
      required: true,
    },
    description: {
      label: 'description',
      placeholder: 'Description',
    },
    unitId: {
      label: 'unitId',
      placeholder: 'unitIdPlaceholder',
      required: true,
    },
    driverName: {
      label: 'driverName',
      placeholder: 'driverNamePlaceholder',
      required: true,
    },
    status: {
      label: 'status',
      placeholder: 'Select',
      options: this.getUnitResponseOptions(),
      required: true,
    },
    from: {
      label: 'mitigationStartDate',
      value: null,
    },
    to: {
      label: 'mitigationEndDate',
      value: null,
    },
  };

  ngAfterViewInit() {
    if (this.initAssociatedUnit && this.initAssociatedUnit.id) {
      this.unitTypeControl.setValue(this.initAssociatedUnit.type, {
        emitEvent: false,
      });
      this.form.setValue(
        {
          unitId: this.initAssociatedUnit.id,
          driverName: this.initAssociatedUnit.driverDetails?.name || null,
          unitResponse: this.initAssociatedUnit.unitResponse,
        },
        { emitEvent: false }
      );

      const unitIdsOptions = this.getUnitIdsOptions(this.unitTypeControl.value);
      const driverNameOptions = this.getDriverNameOptions(this.unitTypeControl.value);
      this.unitId?.updateOptions(unitIdsOptions);
      this.unitIdDesktop?.updateOptions(unitIdsOptions);
      this.driverName?.updateOptions(driverNameOptions);
      this.driverNameDesktop?.updateOptions(driverNameOptions);
      this.unitId?.setInitValue(this.initAssociatedUnit.displayId.toString());
      this.driverName?.setInitValue(
        this.initAssociatedUnit.driverDetails?.name ? this.initAssociatedUnit.driverDetails.name : ''
      );

      if (!!this.initAssociatedUnit.updatedAt && this.initAssociatedUnit.unitResponse === UnitResponse.OnScene)
        this.mitigationField.form
          .get('from')
          ?.setValue(new Date(this.initAssociatedUnit.updatedAt), { emitEvent: false });
    }

    this.unitTypeControl.valueChanges.pipe(distinctUntilChanged()).subscribe(unitType => {
      if (unitType) {
        // reset unit id control
        if (this.form.get('unitId')?.value) {
          this.unitId?.reset();
          this.unitIdDesktop?.clear();
        }
        this.unitId?.updateOptions(this.getUnitIdsOptions(unitType));
        this.unitIdDesktop?.updateOptions(this.getUnitIdsOptions(unitType));

        // reset drive name control
        if (this.form.get('driverName')?.value) {
          this.driverName?.reset();
          this.driverNameDesktop?.clear();
        }
        this.driverName?.updateOptions(this.getDriverNameOptions(unitType));
        this.driverNameDesktop?.updateOptions(this.getDriverNameOptions(unitType));
      }
    });

    this.form.get('unitId')?.valueChanges.subscribe(unitId => {
      const unit = this.units.find(_unit => _unit.id == unitId);
      if (
        !unitId &&
        (this.driverName?.fieldFormControl.value || this.driverNameDesktop?.autocompleteInputFormControl.value)
      ) {
        this.driverName?.clear();
        this.driverNameDesktop?.clear();
      }

      if (unit) {
        this.associatedUnit = {
          ...this.associatedUnit,
          ...{
            accountId: unit.accountId,
            displayId: unit.displayId,
            externalId: unit.externalId,
            id: unit.id,
            status: unit.status,
            type: unit.type,
          },
        };

        this.unitId?.fieldFormControl.updateValueAndValidity();
      }

      if (unit && unit.id && !this.isInitIdMatchDriver(this.associatedUnit.id, this.form.get('driverName')?.value)) {
        this.selectDriverNameByUnitId(unit.id);
      }
    });

    this.form
      .get('driverName')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe(driverName => {
        const driverDetails = this.getDriverDetailsByDriverName(driverName);
        this.associatedUnit = { ...this.associatedUnit, ...{ driverDetails } };

        if (!driverDetails && this.unitId?.fieldFormControl.value) {
          this.unitId.clear();
          this.unitIdDesktop?.clear();
        }

        if (
          driverDetails &&
          !this.isInitIdMatchDriver(this.form.get('unitId')?.value, this.associatedUnit.driverDetails?.userId)
        ) {
          this.selectUnitIdByDriverName(driverDetails.userId);
        }
      });

    this.form.get('unitResponse')?.valueChanges.subscribe(unitResponse => {
      const _mitigations = this.mitigations.value || [];

      if (unitResponse === UnitResponse.Mitigated) {
        this.showMitigationForm = true;
      } else {
        // _mitigations = _mitigations.filter(
        //   _mitigation =>
        //     _mitigation?.mitigation.id === this.defaultMitigationTypeId &&
        //     _mitigation?.mitigation.accountId === this.defaultMitigationTypeAccountId
        // );

        this.showMitigationForm = false;
        // this.mitigations.setValue(_mitigations);
      }

      if (this.associatedUnit.unitResponse !== unitResponse) {
        if (unitResponse === UnitResponse.Mitigated) this.mitigationField.setEndDate();
        // if (unitResponse === UnitResponse.OnScene) this.mitigationField.setStartDate(); // this row add default mitigation
      }

      this.associatedUnit.unitResponse = unitResponse;
      this.changeValue({
        associatedUnit: this.associatedUnit,
        mitigations: _mitigations,
      });
    });

    this.form.valueChanges.subscribe(formValue => {
      const _mitigations = this.mitigations.value || [];
      this.associatedUnit.unitResponse = formValue.unitResponse;

      _mitigations.forEach(mit => {
        mit.unitId = this.associatedUnit.id;
        mit.userId = this.associatedUnit.driverDetails?.userId;
      });

      this.changeValue({
        associatedUnit: this.associatedUnit,
        mitigations: _mitigations,
      });
    });

    this.mitigations.valueChanges.subscribe(mitigations => {
      mitigations.forEach(mit => {
        mit.unitId = this.associatedUnit.id;
        mit.userId = this.associatedUnit.driverDetails?.userId;
      });

      this.changeValue({ mitigations, associatedUnit: this.associatedUnit });
    });
  }

  ngOnInit(): void {
    this.initForms();
  }
  initForms() {
    this.associatedUnitForm = this.fb.group({
      associatedUnit: [null, Validators.required],
      mitigations: [],
    });

    this.form = this.fb.group({
      unitId: [null, Validators.required],
      driverName: [null, Validators.required],
      unitResponse: [null, Validators.required],
    });
  }

  writeValue(val: AssociatedUnitForm) {
    if (val && val.associatedUnit.id) {
      this.initAssociatedUnit = val.associatedUnit as IncidentUnit;

      this.associatedUnit = clone(val.associatedUnit) as IncidentUnit;
      if (this.associatedUnit.unitResponse === UnitResponse.Mitigated) {
        this.showMitigationForm = true;
      }

      // Add the associated unit in the formControl to be a part of the from options
      const unitAlreadyInOptions = this.units.some(unit => unit.id === this.associatedUnit.id);

      if (!unitAlreadyInOptions) {
        this.units.push({
          accountId: this.associatedUnit.accountId,
          dashCameras: [],
          displayId: this.associatedUnit.displayId,
          externalId: this.associatedUnit.externalId,
          id: this.associatedUnit.id,
          status: this.associatedUnit.status,
          type: this.associatedUnit.type,
          isBusy: false,
          userDetails: this.associatedUnit.driverDetails,
          activityStatuses: [],
        });
      }

      this.mitigations.setValue(clone(val.mitigations), { emitEvent: false });
    }
  }

  validate() {
    if (
      this.form.invalid ||
      (this.mitigations.invalid && this.form.get('unitResponse')?.value === UnitResponse.Mitigated)
    ) {
      return { invalid: true };
    }
    return null;
  }

  getUnitIdsOptions(unitType: VehicleType): SelectOption[] {
    const _unitsByType = [...[], ...this.units.filter(unit => unit.type === unitType)];
    const _options: SelectOption[] = [] as SelectOption[];

    _unitsByType.forEach(unit => {
      const _option: SelectOption = {} as SelectOption;
      if (!unit.userDetails?.userId) {
        _option.disabled = true;
        _option.displayName = `${unit.displayId} (Not assigned to a driver)`;
        _option.value = unit.id;
      } else {
        _option.disabled = false;
        _option.displayName = `${unit.displayId}`;
        _option.value = unit.id;
      }
      _options.push(_option);
    });

    return _options;
  }

  getDriverNameOptions(unitType: VehicleType): SelectOption[] {
    const driverUsers = [
      ...[],
      ...this.units.filter(user => user.type === unitType && user.userDetails?.userId).map(unit => unit.userDetails),
    ] as [];
    return this.userDetailsToOptions(driverUsers);
  }

  private userDetailsToOptions(userDetails: UserDetails[]): SelectOption[] {
    const _options: SelectOption[] = [];

    userDetails.forEach(details => {
      _options.push({
        value: details.name,
        displayName: details.name,
        data: details.unitRelationType,
      });
    });

    return _options;
  }

  selectDriverNameByUnitId(unitId) {
    const driverName = this.units.find(unit => unit.id === unitId)?.userDetails?.name;
    this.form.get('driverName')?.setValue(driverName);
    this.driverNameDesktop?.setAutocompleteInputValue(driverName);
  }

  selectUnitIdByDriverName(userId) {
    const unit = this.units.find(unit => unit.userDetails?.userId === userId);
    this.form.get('unitId')?.setValue(unit?.id);
    this.unitIdDesktop?.setAutocompleteInputValue(unit?.displayId);
  }

  isInitIdMatchDriver(unitId, userId) {
    const unit = this.units.find(unit => unit.id === unitId);
    if (!unit) return false;
    return unit.userDetails?.userId === userId ? true : false;
  }

  getUnitResponseOptions() {
    return EnumToOptions(ASSOCIATED_UNIT_RESPONSES, {
      translateService: this.translateService,
      translateBy: 'key',
      translatePath: 'unitResponse',
    });
  }

  getDriverDetailsByDriverName(driverName: string) {
    const unit = this.units.find(_unit => _unit.userDetails?.name === driverName);
    return unit ? unit.userDetails : null;
  }

  deleteAssociatedUnit() {
    this.delete.emit(this.index);
  }

  disableAssociatedUnitFormField() {
    if (!this.unitTypeControl.value || this.unitTypeControl.invalid) return true;
    return false;
  }

  changeValue(val) {
    this.fieldFormControl.patchValue(val);
    this.onChanged(this.fieldFormControl.value);
  }

  updateTypeOptions(unitVehicle) {
    if (!unitVehicle) return;
    const unitTypeOptions = this.associatedUnitFieldData.unitType.options;

    if (!unitTypeOptions || unitTypeOptions.length === 0) {
      this.associatedUnitFieldData.unitType.label = 'noActiveUnits';
      this.unitTypeControl.disable();
    }

    const validOption = unitTypeOptions?.find(_option => _option.value === unitVehicle);
    if (unitTypeOptions && !validOption) {
      this.associatedUnitFieldData.unitType.options = [
        ...unitTypeOptions,
        ...[this.accountService.getVehicleTypeOption(unitVehicle)],
      ];
    }
  }
}
