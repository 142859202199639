import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { WcLetModule } from '@wc/wc-common/pipes';
import {
  MapButtonsContainerModule,
  MapCenterButtonModule,
  MapMilemarkerButtonModule,
  MapStelliteButtonModule,
  MapTrafficButtonModule,
} from '@wc/wc-map-viewer/src';
import { ToastrModule } from 'ngx-toastr';
import { CrashRiskPanelModule } from '../../crash-risk/crash-risk-panel/crash-risk-panel.module';
import { LayerTreePanelModule } from '../../layer-tree-panel/layer-tree-panel.module';
import { RoadwayStatusPanelModule } from '../../roadway-status/roadway-status-panel/roadway-status-panel.module';
import { WcTooltipModule } from '../../tooltip/tooltip.module';
import { TransitPanelModule } from '../../transit/transit-panel/transit-panel.module';
import { WcIconModule } from '../../wc-icon/wc-icon.module';
import { WeatherEventsPanelModule } from '../../weather-events/weather-events-panel/weather-events-panel.module';
import { WorkSpacesMenuModule } from '../../work-spaces-menu/work-spaces-menu.module';
import { LiveMapViewerComponent } from './live-map-viewer.component';

@NgModule({
  declarations: [LiveMapViewerComponent],
  exports: [LiveMapViewerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MapCenterButtonModule,
    MapMilemarkerButtonModule,
    MapStelliteButtonModule,
    MapTrafficButtonModule,
    MapButtonsContainerModule,
    WorkSpacesMenuModule,
    ToastrModule.forRoot(),
    MatButtonToggleModule,
    WeatherEventsPanelModule,
    WcLetModule,
    RoadwayStatusPanelModule,
    CrashRiskPanelModule,
    TransitPanelModule,
    LayerTreePanelModule,
    WcTooltipModule,
    WcIconModule,
  ],
})
export class LiveMapViewerModule {}
