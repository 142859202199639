import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WcIconModule } from '../../wc-icon/wc-icon.module';
import { TransitUnitModalComponent } from './transit-unit-modal.component';

@NgModule({
  declarations: [TransitUnitModalComponent],
  exports: [TransitUnitModalComponent],
  imports: [CommonModule, TranslateModule, WcIconModule],
})
export class TransitUnitModalModule {}
