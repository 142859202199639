<div class="segment-data-source-container">
  <div class="source">
    <ng-container *ngIf="dataSources.length > 1; else singleSource">
      <span>
        {{ 'clusteringPopup.segment_details.select.source.drop_down' | translate }}
      </span>

      <mat-select
        [panelClass]="'source-select-dropdown'"
        [(ngModel)]="segmentSource"
        (selectionChange)="segmentSourceChanged(segmentSource)">
        <mat-option *ngFor="let source of dataSources" [value]="source">
          {{ 'segmentDataSource.' + source | translate }}
        </mat-option>
      </mat-select>
    </ng-container>

    <ng-template #singleSource>
      <div class="single-source">
        {{ 'segmentDataSource.' + dataSources[0] | translate }}
      </div>
    </ng-template>
  </div>

  <div class="time-range" *ngIf="timeRange.startTime && timeRange.endTime && isUpdated">
    <span class="time-range-title">
      {{ 'clusteringPopup.segment_details.select.source.time_range' | translate }}
    </span>

    <span class="time-range-start-time">
      {{ timeRange.startTime | timeFormatMode: regionalSettings.timeFormat }}
    </span>
    -
    <span class="time-range-end-time">
      {{ timeRange.endTime | timeFormatMode: regionalSettings.timeFormat }}
    </span>
  </div>
</div>
