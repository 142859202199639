import { CollaborationMessageType } from '@wc/core/models/collaboration';
import { AffectedTransitEntities, AffectingEventDetails, VehicleType } from '@wc/core/models/gql.models';

export enum TransitLayerType {
  TransitRoute = 'TRANSIT_ROUTE',
  TransitFixedRouteBus = 'TRANSIT_FIXED_ROUTE_BUS',
  TransitBusStop = 'TRANSIT_BUS_STOP',
}

export type UpdateTrackedBusRoutes = {
  trackedBusRoutes: number[];
  type: CollaborationMessageType;
};

export type AllBuses = {
  transit_on_demand_unit?: Record<string, {}>;
  transit_demand_response_unit?: Record<string, {}>;
  transit_fixed_route_bus_unit?: FixedRouteBus;
};

export type FixedRouteBus = {
  accountId: number;
  accountName: string;
  displayId: string;
  eta: number;
  id: number;
  location: {
    coordinates: number[];
    type: string;
  };
  nextStopId: number;
  nextStopName: string;
  patternId: number;
  patternName: string;
  routeNumber: string;
  status: string;
  statusInMinutes: number;
  routeId: number;

  //   isMyAgency: boolean;
  //   routeId: number;
  //   type: string;
};

export type BusStopLoad = {
  busStopId: number;
  externalId?: string;
  avgWaitingTime?: number;
  peopleScale?: number;
  accountIds?: [];
};

export type BusStopsLoadUpdate = {
  load: BusStopLoad[];
};
export type AffectedServiceUI = {
  iconName: string;
  parsedDuration?: string;
  isViewed?: boolean;
  color?: string;
  affectedServiceType: 'unit' | 'route';
  affectedServiceId?: number;
  displayId: string;
  affectingEvents: AffectingEventDetails[];
  isRerouted?: boolean;
  estimatedEndTime?: string | undefined;
  routeId?: number;
  unitId?: number;
  type?: VehicleType;
  affectedBusStopsCount?: number;
};

export type AllAffectedServiceUpdate = {
  affectedTransitEntities: AffectedTransitEntities;
  clearedFixedBusRoutes?: number[];
  clearedTransitUnits?: number[];
  type?: string;
};

export interface TrainRoute {
  routeId: number;
  name: string;
  delayInMinutes: number; // minutes
  plannedArrivalTime: number;
}
export interface TrainDelay {
  stationName: string;
  routes: TrainRoute[];
}
