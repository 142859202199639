export const DOC_SUPPORTED_FORMATS = ['pdf', 'xls', 'doc', 'docx', 'csv'];
export const DOC_SUPPORTED_MIME_TYPES = [
  'image/*',
  'video/*',
  'application/pdf', // (.pdf)
  'application/msword', // Microsoft Word (.doc)
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Microsoft Word (.docx)
  'application/vnd.ms-excel', // Microsoft Excel (.xls)
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Microsoft Excel (.xlsx),
  'text/csv', // (.csv)
];
