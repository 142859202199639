type AnyString = string & { _?: any };
export type IconClassName = 'vehicle_on_fire'
| 'police_activity'
| 'left_on_arrival'
| 'hazard'
| 'debris'
| 'planned-calender'
| 'abandoned_vehicle'
| 'dms'
| 'road_closure'
| 'units'
| 'traffic_disruption'
| 'posted_speed'
| 'affected-lanes'
| 'source'
| 'damage'
| 'congestion'
| 'congestion-irregular'
| 'crash-risk'
| 'overtime'
| 'concrete_damage'
| 'fence'
| 'pothole_pavement'
| 'related_incidents'
| 'attenuator'
| 'related_work_request'
| 'auto-publish'
| 'gov-delivery'
| 'transit_fixed_route_bus_unit'
| 'transit_on_demand_unit'
| 'transit_demand_response_unit'
| 'traffic_stop'
| 'guard_rail'
| 'cable_rail'
| 'dust'
| 'tsunami'
| 'wrong_way'
| 'city_police_unit'
| 'fsp_unit'
| 'highway_police_unit'
| 'ems_unit'
| 'fire_department_unit'
| 'maintenance_unit'
| 'snow_blower_unit'
| 'portrait-mode'
| 'snow_plow_unit'
| 'street_sweep_unit'
| 'other_unit'
| 'other'
| 'light_pole'
| 'flooding_drains'
| 'tag'
| 'roadway-status'
| 'bar-chart'
| 'crash'
| 'transit_fixed_routes'
| 'graffiti'
| 'special_event'
| 'homeless_issue'
| 'construction'
| 'stalled_vehicle'
| 'camera'
| 'unidentified'
| 'traffic_anomaly'
| 'landscape'
| 'dead_animal'
| 'ems'
| 'bridge'
| 'rainfall'
| 'black_ice'
| 'snow_on_road'
| 'ice_on_road'
| 'flood'
| 'coastal_flood'
| 'flash_flood'
| 'areal_flood'
| 'tornado'
| 'tropical_storm'
| 'hurricane'
| 'storm_surge'
| 'tropical_weather'
| 'typhoon'
| 'freezing_rain'
| 'freezing_drizzle'
| 'sleet'
| 'heavy_sleet'
| 'blowing_snow'
| 'heavy_snow'
| 'snow_squall'
| 'blizzard'
| 'ice_storm'
| 'lake_effect_snow'
| 'dense_fog'
| 'freezing_fog'
| 'excessive_heat'
| 'severe_thunderstorm'
| 'near_severe_thunderstorm'
| 'winter_storm'
| 'winter_weather'
| 'wind'
| 'high_wind'
| 'extreme_wind'
| 'dust_storm_or_high_wind'
| 'wind_chill'
| 'weather'
| 'bus'
| 'layers'
| 'sign'
| 'support'
| 'fire'
| 'fire_weather'
| 'red_flag_fire'
| 'forest_fire'
| 'rangeland_fire_danger'
| 'work-requests'
| 'snow_icon'
| 'snow'
| 'fuel_oil'
| 'major_hazmat'
| 'traffic_control'
| 'pavement_marking'
| 'restore'
| 'check'
| 'crutches'
| 'trash'
| 'trash-alt'
| 'trash-undo'
| 'plus'
| 'chevron-right'
| 'history'
| 'street'
| 'highway'
| 'close'
| 'close-new'
| 'open-full-window'
| 'compress'
| 'user'
| 'chevron-left'
| 'chevron-down'
| 'chevron-up'
| 'caret-down'
| 'open-widget'
| 'times-circle'
| 'cloud-arrow-up'
| 'lightbulb-on'
| 'x-twitter'
| 'waze'
| 'question-circle'
| 'badge-check'
| 'location-info'
| 'letter-v'
| 'ctrl'
| 'refresh'
| 'mac-command'
| 'thumbtack'
| 'bell-on'
| 'share-alt'
| 'bell'
| 'bell-slash'
| 'building'
| 'sliders'
| 'level-down'
| 'check-list'
| 'map-marked-alt'
| 'data-hub'
| 'play'
| 'pause'
| 'play-circle'
| 'pause-circle'
| 'stop-circle'
| 'repeat'
| 'cog'
| 'rocket-launch'
| 'stars'
| 'download'
| 'support-help'
| 'logout'
| 'qrcode'
| 'arrow-right'
| 'arrow-left'
| 'link'
| 'starts'
| 'pen'
| 'angle-up'
| 'angle-right'
| 'snapshot'
| 'pdf'
| 'grip'
| 'permit'
| 'external-link'
| 'info-circle'| AnyString;

export const IconNames: Record<IconClassName, IconClassName> = {
  'vehicle_on_fire': 'vehicle_on_fire',
  'police_activity': 'police_activity',
  'left_on_arrival': 'left_on_arrival',
  'hazard': 'hazard',
  'debris': 'debris',
  'planned-calender': 'planned-calender',
  'abandoned_vehicle': 'abandoned_vehicle',
  'dms': 'dms',
  'road_closure': 'road_closure',
  'units': 'units',
  'traffic_disruption': 'traffic_disruption',
  'posted_speed': 'posted_speed',
  'affected-lanes': 'affected-lanes',
  'source': 'source',
  'damage': 'damage',
  'congestion': 'congestion',
  'congestion-irregular': 'congestion-irregular',
  'crash-risk': 'crash-risk',
  'overtime': 'overtime',
  'concrete_damage': 'concrete_damage',
  'fence': 'fence',
  'pothole_pavement': 'pothole_pavement',
  'related_incidents': 'related_incidents',
  'attenuator': 'attenuator',
  'related_work_request': 'related_work_request',
  'auto-publish': 'auto-publish',
  'gov-delivery': 'gov-delivery',
  'transit_fixed_route_bus_unit': 'transit_fixed_route_bus_unit',
  'transit_on_demand_unit': 'transit_on_demand_unit',
  'transit_demand_response_unit': 'transit_demand_response_unit',
  'traffic_stop': 'traffic_stop',
  'guard_rail': 'guard_rail',
  'cable_rail': 'cable_rail',
  'dust': 'dust',
  'tsunami': 'tsunami',
  'wrong_way': 'wrong_way',
  'city_police_unit': 'city_police_unit',
  'fsp_unit': 'fsp_unit',
  'highway_police_unit': 'highway_police_unit',
  'ems_unit': 'ems_unit',
  'fire_department_unit': 'fire_department_unit',
  'maintenance_unit': 'maintenance_unit',
  'snow_blower_unit': 'snow_blower_unit',
  'portrait-mode': 'portrait-mode',
  'snow_plow_unit': 'snow_plow_unit',
  'street_sweep_unit': 'street_sweep_unit',
  'other_unit': 'other_unit',
  'other': 'other',
  'light_pole': 'light_pole',
  'flooding_drains': 'flooding_drains',
  'tag': 'tag',
  'roadway-status': 'roadway-status',
  'bar-chart': 'bar-chart',
  'crash': 'crash',
  'transit_fixed_routes': 'transit_fixed_routes',
  'graffiti': 'graffiti',
  'special_event': 'special_event',
  'homeless_issue': 'homeless_issue',
  'construction': 'construction',
  'stalled_vehicle': 'stalled_vehicle',
  'camera': 'camera',
  'unidentified': 'unidentified',
  'traffic_anomaly': 'traffic_anomaly',
  'landscape': 'landscape',
  'dead_animal': 'dead_animal',
  'ems': 'ems',
  'bridge': 'bridge',
  'rainfall': 'rainfall',
  'black_ice': 'black_ice',
  'snow_on_road': 'snow_on_road',
  'ice_on_road': 'ice_on_road',
  'flood': 'flood',
  'coastal_flood': 'coastal_flood',
  'flash_flood': 'flash_flood',
  'areal_flood': 'areal_flood',
  'tornado': 'tornado',
  'tropical_storm': 'tropical_storm',
  'hurricane': 'hurricane',
  'storm_surge': 'storm_surge',
  'tropical_weather': 'tropical_weather',
  'typhoon': 'typhoon',
  'freezing_rain': 'freezing_rain',
  'freezing_drizzle': 'freezing_drizzle',
  'sleet': 'sleet',
  'heavy_sleet': 'heavy_sleet',
  'blowing_snow': 'blowing_snow',
  'heavy_snow': 'heavy_snow',
  'snow_squall': 'snow_squall',
  'blizzard': 'blizzard',
  'ice_storm': 'ice_storm',
  'lake_effect_snow': 'lake_effect_snow',
  'dense_fog': 'dense_fog',
  'freezing_fog': 'freezing_fog',
  'excessive_heat': 'excessive_heat',
  'severe_thunderstorm': 'severe_thunderstorm',
  'near_severe_thunderstorm': 'near_severe_thunderstorm',
  'winter_storm': 'winter_storm',
  'winter_weather': 'winter_weather',
  'wind': 'wind',
  'high_wind': 'high_wind',
  'extreme_wind': 'extreme_wind',
  'dust_storm_or_high_wind': 'dust_storm_or_high_wind',
  'wind_chill': 'wind_chill',
  'weather': 'weather',
  'bus': 'bus',
  'layers': 'layers',
  'sign': 'sign',
  'support': 'support',
  'fire': 'fire',
  'fire_weather': 'fire_weather',
  'red_flag_fire': 'red_flag_fire',
  'forest_fire': 'forest_fire',
  'rangeland_fire_danger': 'rangeland_fire_danger',
  'work-requests': 'work-requests',
  'snow_icon': 'snow_icon',
  'snow': 'snow',
  'fuel_oil': 'fuel_oil',
  'major_hazmat': 'major_hazmat',
  'traffic_control': 'traffic_control',
  'pavement_marking': 'pavement_marking',
  'restore': 'restore',
  'check': 'check',
  'crutches': 'crutches',
  'trash': 'trash',
  'trash-alt': 'trash-alt',
  'trash-undo': 'trash-undo',
  'plus': 'plus',
  'chevron-right': 'chevron-right',
  'history': 'history',
  'street': 'street',
  'highway': 'highway',
  'close': 'close',
  'close-new': 'close-new',
  'open-full-window': 'open-full-window',
  'compress': 'compress',
  'user': 'user',
  'chevron-left': 'chevron-left',
  'chevron-down': 'chevron-down',
  'chevron-up': 'chevron-up',
  'caret-down': 'caret-down',
  'open-widget': 'open-widget',
  'times-circle': 'times-circle',
  'cloud-arrow-up': 'cloud-arrow-up',
  'lightbulb-on': 'lightbulb-on',
  'x-twitter': 'x-twitter',
  'waze': 'waze',
  'question-circle': 'question-circle',
  'badge-check': 'badge-check',
  'location-info': 'location-info',
  'letter-v': 'letter-v',
  'ctrl': 'ctrl',
  'refresh': 'refresh',
  'mac-command': 'mac-command',
  'thumbtack': 'thumbtack',
  'bell-on': 'bell-on',
  'share-alt': 'share-alt',
  'bell': 'bell',
  'bell-slash': 'bell-slash',
  'building': 'building',
  'sliders': 'sliders',
  'level-down': 'level-down',
  'check-list': 'check-list',
  'map-marked-alt': 'map-marked-alt',
  'data-hub': 'data-hub',
  'play': 'play',
  'pause': 'pause',
  'play-circle': 'play-circle',
  'pause-circle': 'pause-circle',
  'stop-circle': 'stop-circle',
  'repeat': 'repeat',
  'cog': 'cog',
  'rocket-launch': 'rocket-launch',
  'stars': 'stars',
  'download': 'download',
  'support-help': 'support-help',
  'logout': 'logout',
  'qrcode': 'qrcode',
  'arrow-right': 'arrow-right',
  'arrow-left': 'arrow-left',
  'link': 'link',
  'starts': 'starts',
  'pen': 'pen',
  'angle-up': 'angle-up',
  'angle-right': 'angle-right',
  'snapshot': 'snapshot',
  'pdf': 'pdf',
  'grip': 'grip',
  'permit': 'permit',
  'external-link': 'external-link',
  'info-circle': 'info-circle',
};
