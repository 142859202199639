import {
  EntityStatusStyle,
  IconStyle,
  LiveMapEntity,
  LiveMapEntityUIState,
  MainEntityType,
  TextStyle,
  WcMapConfigModel,
} from '@wc/core';
import { AppVersion } from '@wc/core/app-version';
import * as darkJsonIcons from 'assets/map-icons/dark_sprite@1.json';
import * as jsonIcons from 'assets/map-icons/sprite@1.json';
import * as olColor from 'ol/color';
import { Coordinate } from 'ol/coordinate';
import { FeatureLike } from 'ol/Feature';
import { LineString, MultiLineString, Point, SimpleGeometry } from 'ol/geom';
import MultiPoint from 'ol/geom/MultiPoint';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Polygon from 'ol/geom/Polygon';
import { circular as circularPolygon } from 'ol/geom/Polygon.js';
import { fromLonLat, toLonLat } from 'ol/proj';
import RenderFeature from 'ol/render/Feature';
import { Circle as CircleStyle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import * as Utils from '../utils';

const cachedStyles: Map<string, Style[]> = new Map();
export function styleType(entity: LiveMapEntity, config: WcMapConfigModel, feature?: FeatureLike) {
  if (!entity) return;

  let entityStatus = entity.status ? entity.status.toLocaleLowerCase() : 'default';
  if (entity.featureSubTypeOf === MainEntityType.work_request && entityStatus !== 'completed') {
    entityStatus = entity.assignedUser === null ? 'unassigned' : 'default';
  }
  // weather alert that wasn't filtered yet to active / forecast
  else if (
    entity.featureType === MainEntityType.weather_alert ||
    entity.featureType === MainEntityType.crash_risk_area
  ) {
    return;
  }
  let entityUIState: LiveMapEntityUIState = 'default';
  const isHovered = entity.hovered;
  const isSelected = entity.selected;
  const isUnit = entity?.featureSubTypeOf?.includes('unit');
  const configLayers = JSON.parse(JSON.stringify(config.layers));

  if (!entity.featureType) return [];
  if (entity.editMode) {
    entityStatus = 'editMode';
  } else {
    if (entityStatus !== 'completed' && entityStatus !== 'rejected') {
      if (entity.featureType === 'interactive') entityUIState = 'default';
      if (entity.featureType !== 'interactive' && isHovered) entityUIState = 'hover';
      if (entity.featureType !== 'interactive' && isSelected) entityUIState = 'selected';
    }
  }

  let styleKey: string | undefined = `
                        ${config.currentStyle}_
                        ${entity.featureType}_
                        ${entityStatus}_
                        ${entityUIState}_
                        ${entity['geometry']?.getType()}_
                        ${entity['color']}_
                        ${configLayers[entity.featureType.toLocaleLowerCase()].startPointStatusStyle ? entity.id : '_'}_
                        ${configLayers[entity.featureType.toLocaleLowerCase()].endPointStatusStyle ? entity.id : '_'}_
                        ${
                          configLayers[entity.featureType.toLocaleLowerCase()].startPointPolygonStyle ? entity.id : '_'
                        }_
                        `;
  if (entity.featureType === 'workspace' || isUnit || entity.featureType === 'camera' || entity.featureType === 'dms') {
    styleKey = undefined;
  }
  if (styleKey && cachedStyles.get(styleKey)) {
    return cachedStyles.get(styleKey);
  }

  let styleSet!: Array<EntityStatusStyle>;
  if (entity['geometry'] instanceof Point) {
    const pointStyle = configLayers[entity.featureType.toLocaleLowerCase()].pointStatusStyle;
    if (pointStyle) {
      pointStyle[entityStatus] = pointStyle[entityStatus] || pointStyle['default'];
      styleSet =
        pointStyle[entityStatus][entityUIState] ||
        pointStyle['default'][entityUIState] ||
        pointStyle['default']['default'];
    }
  }
  if (entity['geometry'] instanceof LineString) {
    const lineStringStyle = configLayers[entity.featureType.toLocaleLowerCase()].lineStringStatusStyle;
    if (lineStringStyle) {
      lineStringStyle[entityStatus] = lineStringStyle[entityStatus] || lineStringStyle['default'];
      styleSet =
        lineStringStyle[entityStatus][entityUIState] ||
        lineStringStyle['default'][entityUIState] ||
        lineStringStyle['default']['default'];
    }
  }
  if (entity['geometry'] instanceof MultiLineString) {
    const multiLineStringStyle =
      configLayers[entity.featureType.toLocaleLowerCase()]?.multiLineStringStatusStyle ||
      configLayers[entity.featureSubTypeOf.toLocaleLowerCase()]?.multiLineStringStatusStyle;
    if (multiLineStringStyle) {
      multiLineStringStyle[entityStatus] = multiLineStringStyle[entityStatus] || multiLineStringStyle['default'];
      styleSet =
        multiLineStringStyle[entityStatus][entityUIState] ||
        multiLineStringStyle['default'][entityUIState] ||
        multiLineStringStyle['default']['default'];
    }
  }
  if (entity['geometry'] instanceof Polygon || entity['geometry'] instanceof MultiPolygon) {
    const polygonStyle = configLayers[entity.featureType.toLocaleLowerCase()].polygonStatusStyle;
    if (polygonStyle) {
      polygonStyle[entityStatus] = polygonStyle[entityStatus] || polygonStyle['default'];
      styleSet =
        polygonStyle[entityStatus][entityUIState] ||
        polygonStyle['default'][entityUIState] ||
        polygonStyle['default']['default'];
    }
  }
  if (!styleSet) {
    console.error(
      `'${
        entity.status
      }' statusStyle not found for the '${entity.featureType.toLocaleLowerCase()}' layer. ${entityStatus}`
    );
    return new Style({});
  }
  if (entity.appended) {
    if (!styleSet.find(s => s.icon?.iconName === 'main_event_mark')) {
      styleSet.push(config.appendedIconStyle as EntityStatusStyle);
    }
  }

  // For unit with no bearing in `livemap.unitsLayers.config` remove the direction_arrow style
  // For unit with bearing remove the first style (style with no arrow)
  if (isUnit) {
    if (!entity.bearing || entity.bearing < 0) {
      entity.bearing = -1;
      styleSet = styleSet.filter(style => !style.icon?.iconName?.includes('arrow'));
    } else if (entityUIState === 'selected') {
      styleSet.shift();
    }
  }

  const style = getStyle(styleSet, config, entity, feature);

  if (
    entity.startAddress?.point ||
    ((entity['geometry'] instanceof LineString || entity['geometry'] instanceof Polygon) &&
      entity.featureType !== 'draw')
  ) {
    let coords = entity.startAddress?.point
      ? entity.startAddress.point.coordinates
      : entity.location.coordinates
      ? entity.location.coordinates[0]
      : undefined;
    const pointStyle = configLayers[entity.featureType.toLocaleLowerCase()].startPointStatusStyle;
    let startPointStylesSet = [];
    if (pointStyle && coords) {
      pointStyle[entityStatus] = pointStyle[entityStatus] || pointStyle['default'];
      startPointStylesSet =
        pointStyle[entityStatus][entityUIState] ||
        pointStyle['default'][entityUIState] ||
        pointStyle['default']['default'];
      coords = fromLonLat([coords[0], coords[1]]);
      startPointStylesSet.map((styleLayer: EntityStatusStyle) => {
        const defaultIconStyle: IconStyle = config.defaultIconStyle;
        const iconStyle: IconStyle = {
          ...defaultIconStyle,
          ...styleLayer.icon,
        };
        if (iconStyle.srcByEntityProperty && entity[iconStyle.srcByEntityProperty]) {
          iconStyle.iconName = entity[iconStyle.srcByEntityProperty].toLowerCase() + iconStyle.iconName;
        }

        const _style = new Style({
          image: icon(iconStyle, config.currentStyle === 'night' ? 'dark_' : ''),
          geometry: new Point(coords),
          zIndex: styleLayer.zIndex || 0,
        });
        style.push(_style);
      });
    }
  }
  // Add start point for polygon only if layer is defined
  if (
    (entity['geometry'] instanceof Polygon || entity['geometry'] instanceof MultiPolygon) &&
    entity.featureType !== 'draw' &&
    configLayers[entity.featureType.toLocaleLowerCase()].startPointPolygonStyle
  ) {
    const entityCoords = entity.location.coordinates || undefined;
    let coords = entityCoords
      ? entity.location.type === 'Polygon'
        ? [entityCoords[0][0][0], entityCoords[0][0][1]]
        : [entityCoords[0][0][0][0], entityCoords[0][0][0][1]]
      : undefined;
    const pointStyle = configLayers[entity.featureType.toLocaleLowerCase()].startPointPolygonStyle;
    let startPointStylesSet = [];
    if (pointStyle && coords) {
      pointStyle[entityStatus] = pointStyle[entityStatus] || pointStyle['default'];
      startPointStylesSet =
        pointStyle[entityStatus][entityUIState] ||
        pointStyle['default'][entityUIState] ||
        pointStyle['default']['default'];
      coords = fromLonLat([coords[0], coords[1]]);
      startPointStylesSet.map((styleLayer: EntityStatusStyle) => {
        const defaultIconStyle: IconStyle = config.defaultIconStyle;
        const iconStyle: IconStyle = {
          ...defaultIconStyle,
          ...styleLayer.icon,
        };
        if (iconStyle.srcByEntityProperty) {
          iconStyle.iconName = entity[iconStyle.srcByEntityProperty].toLowerCase() + iconStyle.iconName;
        }
        const _style = new Style({
          image: icon(iconStyle, config.currentStyle === 'night' ? 'dark_' : ''),
          geometry: new Point(coords as Coordinate),
          zIndex: styleLayer.zIndex || 0,
        });
        style.push(_style);
      });
    }
  }

  if (entity.endAddress?.point || (entity['geometry'] instanceof LineString && entity.featureType !== 'draw')) {
    let coords = entity.endAddress
      ? entity.endAddress.point.coordinates
      : entity.location.coordinates
      ? entity.location.coordinates[entity.location.coordinates.length - 1]
      : undefined;
    const pointStyle = configLayers[entity.featureType.toLocaleLowerCase()].endPointStatusStyle;
    let startPointStylesSet = [];
    if (pointStyle && coords) {
      pointStyle[entityStatus] = pointStyle[entityStatus] || pointStyle['default'];
      startPointStylesSet =
        pointStyle[entityStatus][entityUIState] ||
        pointStyle['default'][entityUIState] ||
        pointStyle['default']['default'];
      coords = fromLonLat([coords[0], coords[1]]);
      startPointStylesSet.map((styleLayer: EntityStatusStyle) => {
        const defaultIconStyle: IconStyle = config.defaultIconStyle;
        const iconStyle: IconStyle = {
          ...defaultIconStyle,
          ...styleLayer.icon,
        };
        if (iconStyle.srcByEntityProperty) {
          iconStyle.iconName = entity[iconStyle.srcByEntityProperty].toLowerCase() + iconStyle.iconName;
        }
        const _style = new Style({
          image: icon(iconStyle, config.currentStyle === 'night' ? 'dark_' : ''),
          geometry: new Point(coords),
          zIndex: styleLayer.zIndex || 0,
        });
        style.push(_style);
      });
    }
  }

  if (styleKey && style?.length) cachedStyles.set(styleKey, style);
  return style;
}

function getStyle(styleSet, config: WcMapConfigModel, entity: LiveMapEntity, feature: FeatureLike | undefined) {
  const style = styleSet.map((styleLayer: EntityStatusStyle) => {
    const textStyle: TextStyle | undefined = styleLayer.label;
    let _text;
    let clusterLength = feature?.getProperties().features?.length || 0;
    clusterLength = clusterLength > 99 ? 99 : clusterLength;

    if (textStyle) {
      const textValue = textStyle.textSrcField
        ? textStyle.textSrcField === 'length' && clusterLength > 1
          ? clusterLength.toString()
          : entity[textStyle.textSrcField]
        : textStyle.text || '';

      _text = text(
        textValue,
        config.currentStyle === 'night' && textStyle.darkColor ? textStyle.darkColor : textStyle.color,
        textStyle.textAlign,
        textStyle.textBaseline,
        textStyle.size,
        textStyle.height,
        textStyle.offsetX,
        textStyle.offsetY,
        textStyle.weight,
        textStyle.placement,
        textStyle.maxAngle,
        textStyle.fontFamily,
        textStyle.overflow,
        textStyle.rotation,
        config.currentStyle === 'night' && textStyle.darkOutlineColor
          ? textStyle.darkOutlineColor
          : textStyle.outlineColor,
        textStyle.outlineWidth
      );
    }
    let _style = new Style();
    let defaultIconStyle: IconStyle;
    let iconStyle: IconStyle;

    switch (styleLayer.shape) {
      case 'marker':
        if (
          (styleLayer.icon?.iconName?.includes('counter') ||
            styleLayer.icon?.iconName?.includes('select_highlight_circle')) &&
          clusterLength <= 1
        ) {
          return _style;
        }
        defaultIconStyle = config.defaultIconStyle;
        iconStyle = { ...defaultIconStyle, ...styleLayer.icon };

        if (iconStyle.srcByEntityProperty && entity[iconStyle.srcByEntityProperty]) {
          iconStyle.iconName = entity[iconStyle.srcByEntityProperty].toLowerCase() + iconStyle.iconName;
        }
        iconStyle.rotation = entity.bearing === -1 ? 90 : entity.bearing; // For unit (0-360)deg of the unit direction default 90
        //const iconModeStyle: '' | 'dark_' = 'dark_';// config.currentStyle === 'night'? 'dark_': '';
        return marker(config.currentStyle === 'night' ? 'dark_' : '', iconStyle);
      case 'circle':
        _style = circle(
          styleLayer.radius,
          config.currentStyle === 'night' && styleLayer.darkColor ? styleLayer.darkColor : styleLayer.color,
          styleLayer.opacity,
          styleLayer.zIndex
        );
        return _style;
      case 'circleIcon':
        _style = circleIcon(
          styleLayer.radius,
          config.currentStyle === 'night' && styleLayer.darkColor ? styleLayer.darkColor : styleLayer.color,
          styleLayer.stroke,
          styleLayer.opacity,
          styleLayer.zIndex
        );
        return _style;
      case 'lineSolid':
        // const color = (config.currentStyle === 'night' && styleLayer.darkColor)? styleLayer.darkColor : styleLayer.color;
        _style = lineSolid(
          getColor(
            config.currentStyle === 'night' && styleLayer.darkColor ? styleLayer.darkColor : styleLayer.color,
            entity
          ),
          styleLayer.width,
          styleLayer.opacity,
          styleLayer.zIndex
        );
        _style.setText(_text);
        return _style;
      case 'lineDash':
        _style = lineDash(
          getColor(
            config.currentStyle === 'night' && styleLayer.darkColor ? styleLayer.darkColor : styleLayer.color,
            entity
          ),
          styleLayer.width,
          styleLayer.opacity,
          styleLayer.zIndex,
          styleLayer.dash
        );
        return _style;
      case 'area':
        _style = area(
          config.currentStyle === 'night' && styleLayer.darkColor ? styleLayer.darkColor : styleLayer.color,
          styleLayer.opacity,
          styleLayer.zIndex
        );
        return _style;
      default:
        return {};
    }
  });
  return style;
}

function getColor(color, entity) {
  return color === 'dynamic' && entity.color ? entity.color : color;
}

function text(
  text: string,
  color?: string,
  textAlign?,
  textBaseline?,
  size?,
  height?,
  offsetX?,
  offsetY?,
  weight?,
  placement?,
  maxAngle?,
  fontFamily?,
  overflow?,
  rotation?,
  outlineColor?,
  outlineWidth?
) {
  const font = weight || 'normal' + ' ' + 1 + '/' + height || 1 + ' ' + fontFamily || "'Open Sans'";

  return new Text({
    textAlign: textAlign || 'center',
    textBaseline: textBaseline || 'middle',
    font: font,
    text: text,
    fill: new Fill({ color }),
    stroke: new Stroke({ color: outlineColor, width: outlineWidth || 1 }),
    offsetX: offsetX,
    offsetY: offsetY,
    placement: placement,
    maxAngle: maxAngle || 120,
    overflow: overflow || true,
    rotation: rotation || 0,
    scale: size * 0.1,
  });
}

function lineSolid(color: string, width?: number, opacity?: number, zIndex?: number) {
  return new Style({
    zIndex: zIndex || 0,
    stroke: new Stroke({
      color: olColor.asString([...Utils.hexAToRGB(color), opacity || 1]),
      width: width || 1,
    }),
  });
}
function lineDash(
  color: string,
  width?: number,
  opacity?: number,
  zIndex?: number,
  dash?: [number, number, number, number]
) {
  dash = dash || [5, 0, 0, 10];
  return new Style({
    zIndex: zIndex || 0,
    stroke: new Stroke({
      color: olColor.asString([...Utils.hexAToRGB(color), opacity || 1]),
      width: width || 1,
      lineDash: dash, //[5, 0, 0, 10],
      lineCap: 'square',
    }),
  });
}

function area(color, opacity, zIndex?: number) {
  return new Style({
    zIndex: zIndex || Infinity,
    fill: new Fill({
      color: olColor.asString([...Utils.hexAToRGB(color), opacity]),
    }),
    // geometry: (feature) => {
    //     return feature.getGeometry();
    // }
  });
}

function circleIcon(
  radius,
  color,
  stroke?: { color; width: number; opacity?: number },
  opacity?: number,
  zIndex?: number
) {
  const circleStyle = {
    fill: new Fill({
      color: olColor.asString([...Utils.hexAToRGB(color), opacity || 1]),
    }),
    // stroke: new Stroke(stroke) || null,
    // points: 4,
    radius: radius,
    // radius2: 0,
    // angle: 0
  };

  if (stroke) {
    circleStyle['stroke'] = new Stroke({
      color: olColor.asString([...Utils.hexAToRGB(stroke.color || color), stroke.opacity || opacity || 1]),
      width: stroke.width,
    });
  }

  const style = new Style({
    zIndex: zIndex || Infinity,
    image: new CircleStyle(circleStyle),
    geometry: function (feature) {
      if (feature.getGeometry() instanceof Point) return feature.getGeometry();
      if (feature.getGeometry() instanceof LineString)
        return new MultiPoint((feature.getGeometry() as SimpleGeometry).getCoordinates());
      return new MultiPoint((feature.getGeometry() as SimpleGeometry).getCoordinates().flat());
    },
  });

  return style;
}

function circle(radius, color, opacity?: number, zIndex?: number) {
  return new Style({
    zIndex: zIndex || Infinity,
    fill: new Fill({
      color: olColor.asString([...Utils.hexAToRGB(color), opacity || 1]),
    }),
    geometry: feature => {
      feature = feature as RenderFeature;
      const coordinates = toLonLat(feature.getGeometry().getOrientedFlatCoordinates());
      return circularPolygon(coordinates, radius, 64).transform('EPSG:4326', 'EPSG:3857');
    },
  });
}

function marker(
  iconStyle: '' | 'dark_' = '',
  imageConfig: IconStyle,
  zIndex?: number,
  location?: { lng: number; lat: number }
) {
  if (location) {
    return new Style({
      image: icon(imageConfig, iconStyle),
      geometry: () => {
        const coords = fromLonLat([location['lng'], location['lat']]);
        return new Point(coords);
      },
      zIndex: zIndex || 0, //Infinity
    });
  }

  return new Style({ image: icon(imageConfig, iconStyle), zIndex: zIndex || 0 });
}

function icon(imageConfig: IconStyle, iconStyle: '' | 'dark_' = '') {
  const { src, anchor, scale, opacity, rotation, disableRotation } = imageConfig;
  const _rotation_radian = rotation && !disableRotation ? (Math.PI / 180) * rotation : 0.0;
  if (imageConfig.iconName) {
    const spriteJson = iconStyle === 'dark_' ? darkJsonIcons : jsonIcons;
    const icons: string[] = (spriteJson as Record<string, unknown>).default as string[];
    const _icon = icons[imageConfig.iconName];
    if (!_icon) {
      console.log(`${imageConfig.iconName} has no iconName value.`);
      return undefined;
    }

    return new Icon({
      anchor: anchor || [0.5, 0.5],
      scale: scale || 1,
      opacity: opacity || 1,
      rotateWithView: false,
      rotation: _rotation_radian, // convert to radian
      crossOrigin: 'anonymous',
      src: `assets/map-icons/${iconStyle}sprite@1.png?${AppVersion}`,
      offset: [_icon.x, _icon.y],
      size: [_icon.width, _icon.height],
    });
  } else {
    // let anchor = (map.style === 'pin') ? [0.5, 1] : [0.5, 0.5];
    return new Icon({
      anchor: anchor || [0.5, 0.5],
      scale: scale || 1,
      opacity: opacity || 1,
      rotateWithView: false,
      rotation: _rotation_radian || 0.0,
      crossOrigin: 'anonymous',
      src: src,
    });
  }
}
