import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { IncidentLane } from '@wc/core';
import { AccountStore } from '@wc/core/stores/account.store';
import { formatNumberToDate } from '@wc/utils';
import { SwiperDirective } from 'ngx-swiper-wrapper';

@Component({
  selector: 'wc-affected-lanes-expention',
  templateUrl: './affected-lanes-expention.component.html',
  styleUrls: ['./affected-lanes-expention.component.scss'],
})
export class AffectedLanesExpentionComponent {
  @ViewChildren(SwiperDirective) swiperElements?: QueryList<SwiperDirective>;
  @Input() relatedLanes!: IncidentLane[];
  @Input() direction;
  @Input() roadType;
  @Input() allLanesAffected!: boolean;
  @Input() affectedLanes;
  @Input() affectedLanesConfig;

  constructor(public accountStore: AccountStore) {}

  /**
   *  @todo transform @link formatNumberToDate into pipe
   */
  formatNumberToDate(number: number) {
    return formatNumberToDate(number);
  }
}
