/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { RoadClosureStoreEntity, RoadClosureUIEntity } from '@wc/wc-models/src';
import { map } from 'rxjs/operators';
import { LayerType } from '../../../../../../core/models/gql.models';
import { EntitiesServiceV2 } from '../../../services/entities.service';

export interface RoadClosureState extends EntityState<RoadClosureStoreEntity, number> {}
export interface RoadClosureUIState extends EntityState<RoadClosureUIEntity> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: LayerType.RoadClosure })
export class RoadClosureStore extends EntityStore<RoadClosureState> {
  ui!: EntityUIStore<RoadClosureUIState>;
  constructor(entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$
      .pipe(map(({ ROAD_CLOSURE }) => ROAD_CLOSURE || []))
      .subscribe(UIRoadClosures => this.ui.upsertMany(UIRoadClosures));

    entitiesService.getDiff$
      .pipe(
        map(({ modified, removed }) => ({
          removedRoadClosures: removed?.ROAD_CLOSURE,
          modifiedRoadClosures: modified.ROAD_CLOSURE,
        }))
      )
      .subscribe(({ removedRoadClosures, modifiedRoadClosures }) => {
        if (modifiedRoadClosures) this.upsertMany(modifiedRoadClosures as RoadClosureStoreEntity[]);
        if (removedRoadClosures) this.remove(removedRoadClosures);
      });

    entitiesService.activeEntityIds$.pipe(map(({ ROAD_CLOSURE }) => ROAD_CLOSURE || [])).subscribe(id => {
      this.ui.setActive(id);
    });
  }
}
