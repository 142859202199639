import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ElectronService } from '@wc-core';
import { ConfirmationModalType } from '../../../../../core/models/enums';
import { ConfirmModalComponent } from './confirm-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmModalService {
  result = '';
  dialogRef: MatDialogRef<ConfirmModalComponent> | undefined;

  constructor(public dialog: MatDialog, private electronService: ElectronService) {}

  showConfirmDialog<T extends ConfirmationModalType>(
    type: T,
    details?: T extends ConfirmationModalType.EditAboutToExpire ? number : string,
    callBackFunction?: (res) => void,
    closeOnNavigation = true
  ): void {
    this.dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: 'auto',
      panelClass: 'confirm-dialog-modal',
      data: { type, details, isWidget: this.electronService.isElectronApp },
      closeOnNavigation: closeOnNavigation,
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== undefined && callBackFunction) {
        callBackFunction(result);
      }
    });
  }

  closeDialog() {
    this.dialogRef?.close();
  }
}
