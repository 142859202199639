import { Injectable } from '@angular/core';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { comparer, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import moment from 'moment';
import { LiveMapStore } from '.';
import { LocalStorageService } from '..';
import * as Utils from '../../utils';
import { CrashRisk } from '../models';
import { EntitiesStore } from './entities.store';

@Injectable({
  providedIn: 'root',
})
export class CrashRiskStore {
  @observable allCrashRisks = {};
  @observable crashRiskLength = 0;
  storedViewedCrashRiskIds: string[] = []; // counter for live map panel badge
  @observable newEventsCounter = 0; // counter for live map panel badge
  @observable previousLayer: string | null = null;

  @observable crashRiskListByDays: {
    time: any;
    crashRisks: CrashRisk[];
  }[] = [];

  constructor(
    private entitiesStore: EntitiesStore,
    private liveMapStore: LiveMapStore,
    private localStorageService: LocalStorageService
  ) {
    makeObservable(this);
    this.storedViewedCrashRiskIds = this.localStorageService.get(LocalStorageKeys.ViewedCrashRiskIds) || [];

    reaction(
      () => liveMapStore.openedLayer,
      (openedLayer: string) => {
        if (this.previousLayer === 'crash_risk_area' && openedLayer !== 'crash_risk_area')
          this.updateViewdCrashRisksByUser(true);
        this.previousLayer = openedLayer;
      }
    );

    reaction(
      () => entitiesStore.entities['crash_risk_area_predicted'],
      crashRisks => {
        this.preprocessCrashRiskLayers();
        this.updateViewdCrashRisksByUser();
      },
      { equals: comparer.structural }
    );
    reaction(
      () => entitiesStore.entities['crash_risk_area_active'],
      crashRisks => {
        this.preprocessCrashRiskLayers();
        this.updateViewdCrashRisksByUser();
      },
      { equals: comparer.structural }
    );
  }

  isCrashRiskNew(crashRisk: CrashRisk) {
    return !this.storedViewedCrashRiskIds.includes(String(crashRisk.id));
  }

  updateViewdCrashRisksByUser(isPanelOpenedOrClosed?: boolean): void {
    runInAction(() => {
      if (Object.keys(this.allCrashRisks).length === 0) return;
      if (isPanelOpenedOrClosed) {
        this.storedViewedCrashRiskIds = Object.keys(this.allCrashRisks);
        this.localStorageService.set(LocalStorageKeys.ViewedCrashRiskIds, this.storedViewedCrashRiskIds);
        this.newEventsCounter = 0;
        return;
      }
      this.storedViewedCrashRiskIds = this.storedViewedCrashRiskIds.filter(id =>
        Object.keys(this.allCrashRisks).includes(id)
      );
      this.localStorageService.set(LocalStorageKeys.ViewedCrashRiskIds, this.storedViewedCrashRiskIds);

      // Update notification badge
      this.newEventsCounter = Object.keys(this.allCrashRisks).length - this.storedViewedCrashRiskIds.length;
    });
  }

  preprocessCrashRiskLayers() {
    this.allCrashRisks = {
      ...this.entitiesStore.entities['crash_risk_area_predicted'],
      ...this.entitiesStore.entities['crash_risk_area_active'],
    };
    this.crashRiskLength = Object.keys(this.allCrashRisks).length;

    this.crashRiskListByDays = [];

    const crashRisksList: CrashRisk[] = Utils.toArray(this.allCrashRisks);

    let now = Number(moment().utc().format('HH'));
    let start_hour =
      now % 3 === 0
        ? moment().utc()
        : moment()
            .utc()
            .subtract(now % 3, 'hour');
    start_hour = start_hour.set('minute', 0).set('second', 0).set('millisecond', 0);

    for (let index = 0; index < 8; index++) {
      this.crashRiskListByDays.push({
        time: start_hour.format('YYYY-MM-DDTHH:mm:ssZ').toString(),
        crashRisks: [],
      });
      start_hour = moment(start_hour).add(3, 'hours');
    }

    if (crashRisksList.length === 0) {
      return;
    }

    runInAction(() => (this.crashRiskLength = crashRisksList.length));

    crashRisksList.forEach((risk: any, i: number) => {
      let timeSlot = this.crashRiskListByDays.find(riskSlot => {
        return (
          moment(risk.startTime).isSameOrAfter(riskSlot.time) &&
          moment(risk.startTime).isBefore(moment(riskSlot.time).add(3, 'hour'))
        );
      });
      if (timeSlot) {
        timeSlot.crashRisks.push(risk);
      }
    });
  }

  @computed
  get totalCurrent() {
    return this.newEventsCounter;
  }
}
