import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MapButtonsContainerComponent } from './map-buttons-container.component';

@NgModule({
  declarations: [MapButtonsContainerComponent],
  imports: [CommonModule],
  exports: [MapButtonsContainerComponent],
})
export class MapButtonsContainerModule {}
