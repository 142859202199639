<ng-container *ngIf="isCollaborationExist">
  <div
    *ngIf="appType !== 'tablet'; else tablet"
    [ngClass]="{
      'list-item': viewMode === 'item',
      'view-collaboration': viewMode === 'view'
    }">
    <div
      [class]="appType"
      class="collaboration-viewer"
      [class.item-mode]="viewMode === 'item'"
      [class.disable-hover]="amountOfCollaborators <= 1">
      <i *ngIf="isBeingEdited" class="fas fa-pen"></i>
      <i *ngIf="!isBeingEdited" class="fas fa-eye"></i>
      {{ displayName }}
      <ng-container *ngIf="amountOfCollaborators > 1"> + {{ amountOfCollaborators - 1 }} </ng-container>
    </div>
    <div class="collaboration-viewer-hover-popup">
      <span class="colab-title">
        {{ 'currentlyViewing' | translate }}
      </span>
      <span class="driver-name" *ngFor="let driver of editDrivers">
        <i class="fas fa-pen"></i>
        {{ driver }}
      </span>
      <span class="driver-name" *ngFor="let driver of viewerDrivers">
        <i class="fas fa-eye"></i>
        {{ driver }}
      </span>
    </div>
  </div>

  <ng-template #tablet>
    <div
      *ngIf="!showCollaborationUsers"
      [class.disable-click]="amountOfCollaborators <= 1"
      (click)="showCollaborationUsers = !showCollaborationUsers"
      class="collaboration-viewer-view-tablet"
      [ngClass]="{
        'list-item': viewMode === 'item',
        'view-collaboration': viewMode === 'view'
      }">
      <i *ngIf="isBeingEdited" class="fas fa-pen"></i>
      <i *ngIf="!isBeingEdited" class="fas fa-eye"></i>
      <span class="tablet-driver-name">
        {{ displayName }}
      </span>
      <ng-container *ngIf="amountOfCollaborators > 1"> + {{ amountOfCollaborators }} </ng-container>
    </div>
    <div *ngIf="showCollaborationUsers" class="collaboration-viewer-hover-popup-tablet">
      <span
        type="button"
        class="sidebar-toggle-button"
        mat-button
        (click)="showCollaborationUsers = !showCollaborationUsers">
        <i style="font-size: 1.6em" class="far fa-times fa-lg"></i>
      </span>
      <span class="colab-title">
        {{ 'currentlyViewing' | translate }}
      </span>
      <span class="driver-name" *ngFor="let driver of editDrivers">
        <i class="fas fa-pen"></i>
        {{ driver }}
      </span>
      <span class="driver-name" *ngFor="let driver of viewerDrivers">
        <i class="fas fa-eye"></i>
        {{ driver }}
      </span>
    </div>
  </ng-template>
</ng-container>
