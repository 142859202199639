/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { CrashRiskStoreEntity, CrashRiskStoreUIEntity } from '@wc/wc-models/src';
import { map } from 'rxjs/operators';
import { LayerType } from '../../../../../../core/models/gql.models';
import { EntitiesServiceV2 } from '../../../services/entities.service';

export interface CrashRisksState extends EntityState<CrashRiskStoreEntity, number> {}
export interface CrashRisksUIState extends EntityState<CrashRiskStoreUIEntity> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: LayerType.CrashRiskArea })
export class CrashRisksStore extends EntityStore<CrashRisksState> {
  ui!: EntityUIStore<CrashRisksUIState>;
  constructor(entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$
      .pipe(map(({ CRASH_RISK_AREA }) => CRASH_RISK_AREA || []))
      .subscribe(UICrashRisks => this.ui.upsertMany(UICrashRisks));

    entitiesService.getDiff$
      .pipe(
        map(({ modified, removed }) => ({
          removedCrashRisks: removed?.CRASH_RISK_AREA,
          modifiedCrashRisk: modified.CRASH_RISK_AREA,
        }))
      )
      .subscribe(({ removedCrashRisks, modifiedCrashRisk }) => {
        if (modifiedCrashRisk) this.upsertMany(modifiedCrashRisk as CrashRiskStoreEntity[]);
        if (removedCrashRisks) this.remove(removedCrashRisks);
      });

    entitiesService.activeEntityIds$
      .pipe(map(({ CRASH_RISK_AREA }) => CRASH_RISK_AREA || []))
      .subscribe(id => this.ui.setActive(id));
  }
}
