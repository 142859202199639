import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IsOneTimeClickModule } from '../../directives/is-one-time-click.module';
import { AddressModule } from '../../form-fields-components/address/address.module';
import { TabletVerticalStepperComponent } from './tablet-vertical-stepper.component';

const Materials: any[] = [CdkStepperModule, ScrollingModule];

@NgModule({
  declarations: [TabletVerticalStepperComponent],
  imports: [
    CommonModule,
    TranslateModule,
    AddressModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    Materials,
    IsOneTimeClickModule,
  ],
  providers: [
    // UsersStore,
    // IncidentStore
  ],
  exports: [TabletVerticalStepperComponent],
})
export class TabletVerticalStepperModule {}
