import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UnitDashCamera } from '@wc/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';

@Component({
  selector: 'wc-unit-dashcams',
  templateUrl: './unit-dashcams.component.html',
  styleUrls: ['./unit-dashcams.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitDashcamsComponent implements OnInit {
  @ViewChild(SwiperDirective, { static: true }) swiperElm?: SwiperDirective;

  @Input() set dashCams(dashCams: Array<Partial<UnitDashCamera>>) {
    this.dashCameras = dashCams;
    this.dashCamsSwiperConfig = {};
    this.initDashCamsSwiperConfig();
    this.swiperElm?.setIndex(0);
  }
  @Input() isTabletMode = false;

  get showPagination() {
    return this.dashCameras?.length > 1;
  }

  imageReloadInterval = 30000;
  dashCameras: Array<Partial<UnitDashCamera>> = [];
  dashCamsSwiperConfig!: SwiperConfigInterface;

  ngOnInit(): void {
    this.initDashCamsSwiperConfig();
  }

  initDashCamsSwiperConfig() {
    this.dashCamsSwiperConfig = {
      direction: 'horizontal',
      slidesPerView: 1,
      navigation: {
        nextEl: '.dashcam-swiper-button-next',
        prevEl: '.dashcam-swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination-dashcams',
        clickable: true,
      },
    };
  }
}
