import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { DateFormatPipeModule } from '@wc/wc-common/src/lib/pipes/format-pipes/date-format.module';
import { WcLoaderComponentModule } from '../miscellaneous/loader/loader.module';
import { WcTooltipModule } from '../tooltip/tooltip.module';
import { ResponsePlanViewComponent } from './response-plan-view.component';

@NgModule({
  declarations: [ResponsePlanViewComponent],
  exports: [ResponsePlanViewComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    TranslateModule,
    MatTooltipModule,
    DateFormatPipeModule,
    WcLoaderComponentModule,
    WcTooltipModule,
  ],
})
export class ResponsePlanViewModule {}
