import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators,
} from '@angular/forms';
import { AccountService, AppFeatureEnum, AuthUserService, SplitIOService } from '@wc-core';
import { environment } from '@wc/core';
import { IncidentMitigation, Language, MitigationType } from '@wc/core/models/gql.models';
import { selectedOptionsAlphaNumericSort } from '@wc/wc-common/src/lib/utils';
import moment from 'moment';
import { BeforeDateValidator, CompareDatesValidator, endTimeMitigationTypeValidator } from '../../form-validators';
import { BaseControlComponent } from '../base-control/base-control.component';

@Component({
  selector: 'wc-mitigation',
  templateUrl: './mitigation.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MitigationComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MitigationComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./mitigation.component.scss'],
})
export class MitigationComponent extends BaseControlComponent implements AfterViewInit, Validator {
  // @ViewChild(SelectChipsComponent) selectChipsComponent!: SelectChipsComponent;
  // @ViewChild('editableList') editableListElm!: EditableListComponent;

  accountMitigationTypes?: MitigationType[];
  moment: any = moment;
  isAddBtnDisabled = true;
  isDisabledWhenOffline = false;
  defaultIncidentMitigation?: IncidentMitigation;
  defaultMitigationTypeId: number = environment.defaultMitigationTypeId;
  defaultMitigationTypeAccountId: number = environment.defaultMitigationTypeAccountId;
  isDefaultMitigationOnly = false;
  isMitigationFromDifferentAccount = false;
  limitOneMitigation = false;

  @Input() isMitigatedMode;

  @Input() mitigationStartTime: Date | null = null;

  get isTabletMode() {
    return false;
  }

  get selectedChipsCount() {
    return this.form.get('mitigation')?.value ? this.form.get('mitigation')?.value.length : 0;
  }

  get isHebrew() {
    return this.accountService.account.regionalSetting.language === Language.He;
  }

  form: FormGroup = this.fb.group(
    {
      from: [], //new Date()],//this.moment(moment.now()).subtract(3, 'minute')],
      to: [],
      mitigation: [[]],
      isMitigationFromDifferentAccount: [{ value: false, disabled: true }],
    },
    {
      validators: [
        BeforeDateValidator('from', 'to'),
        CompareDatesValidator('from', 'to'),
        endTimeMitigationTypeValidator('to', 'mitigation', 'isMitigationFromDifferentAccount'),
      ],
    }
  );

  fieldsData: { [key in string]: any } = {
    to: {
      label: 'mitigationEndAt',
      // validators: [Validators.required]
      value: null,
    },
    from: {
      label: 'mitigationStartAt',
      validators: [Validators.required],
      value: null,
    },
    mitigation: {
      label: 'selectMitigation',
      options: this.accountService.mitigationsOptions,
      value: '',
      placeholder: 'searchMitigation',
    },
  };

  constructor(
    formControlHost: FormGroupDirective,
    private fb: FormBuilder,
    private accountService: AccountService,
    private authUserService: AuthUserService,
    public splitIOService: SplitIOService,
    private cdr: ChangeDetectorRef
  ) {
    super(formControlHost);

    if (splitIOService.isActiveFeatureToggle(AppFeatureEnum.FE_LIMIT_TO_SINGLE_MITIGATION)) {
      this.form.get('mitigation')?.addValidators([Validators.maxLength(1)]);
      this.limitOneMitigation = true;
    }
  }

  get mitigationOptions() {
    const options = this.accountService.mitigationsOptions.filter(
      option => option.value !== this.defaultMitigationTypeId
    );
    return selectedOptionsAlphaNumericSort(options, 'displayName');
  }

  get mitigationNumber() {
    const mitigations = this.form.value['mitigation']?.filter(option => option !== this.defaultMitigationTypeId);
    return mitigations?.length || 0;
  }

  ngAfterViewInit() {
    if (this.mitigationStartTime) this.form.controls.from.setValue(this.mitigationStartTime);

    if (!this.isTabletMode) {
      // this.selectChipsComponent.updateOptions(this.accountService.mitigationsOptions); // TODO:
      this.cdr.markForCheck();
    }

    this.accountMitigationTypes = this.accountService.incidentConfigs.mitigationTypeValues;

    this.form.valueChanges.subscribe(val => {
      let incidentMitigations: IncidentMitigation[] = [];
      // this.checkForEmptyItem();

      incidentMitigations = val.mitigation.map((id: { value: any }) => {
        const _id = id.value || id;
        const mitigationType: MitigationType | undefined = this.accountMitigationTypes?.find(
          _mitigation => _mitigation.id === _id
        );
        if (mitigationType?.id !== this.defaultMitigationTypeId) {
          this.setEndDate();
        }

        if (mitigationType) {
          const incidentMitigation: Partial<IncidentMitigation> = {
            interval: {
              from: moment(val.from).toISOString(),
              to: moment(this.form.controls.to.value).toISOString(),
            },
            mitigationType: mitigationType,
            unitType: this.authUserService.user.unit?.type || undefined,
            unitDisplayId: this.authUserService.user.unit?.displayId || undefined,
            unitId: this.authUserService.user.unit?.id || undefined, //applicable only for tablet mode
            userId: this.authUserService.user.id, //applicable only for tablet mode
          };

          return incidentMitigation;
        }

        return undefined;
      });

      if (incidentMitigations?.length === this.accountMitigationTypes?.length) {
        this.isAddBtnDisabled = true;
      }

      this.fieldFormControl.setValue(incidentMitigations);
      this.propagateChange(incidentMitigations);
    });
    if (this.isTabletMode && this.isMitigatedMode && !this.form.get('to')) {
      this.form.controls.to.setValue(new Date());
    }
  }

  writeValue(value: IncidentMitigation[]) {
    // console.log('writeValue IncidentMitigation: ', toJS(value));
    if (value) {
      const mitigationFromDifferentAccount: IncidentMitigation | null =
        value.find(
          mitigation =>
            mitigation.mitigationType.accountId !== this.accountService.account.id &&
            mitigation.mitigationType.accountId !== this.defaultMitigationTypeAccountId
        ) || null;
      if (mitigationFromDifferentAccount) {
        this.form.controls.isMitigationFromDifferentAccount.setValue(true);
      }
      this.defaultIncidentMitigation = value.find(
        incidentMitigation => incidentMitigation.mitigationType.id === this.defaultMitigationTypeId
      );
      let incidentMitigations = value.filter(
        incidentMitigation =>
          incidentMitigation.mitigationType.accountId === this.accountService.account.id ||
          (incidentMitigation.mitigationType.accountId === this.defaultMitigationTypeAccountId &&
            incidentMitigation.mitigationType.description === 'default')
      );
      this.isDefaultMitigationOnly = !value.filter(
        incidentMitigation =>
          incidentMitigation.mitigationType.accountId === this.accountService.account.id &&
          incidentMitigation.mitigationType.id !== this.defaultMitigationTypeId
      ).length;
      if (this.isTabletMode) {
        incidentMitigations = incidentMitigations.filter(
          mitigation => mitigation.userId === this.authUserService.user.id
        );
        this.isDefaultMitigationOnly = !incidentMitigations.filter(
          incidentMitigation => incidentMitigation.mitigationType.id !== this.defaultMitigationTypeId
        ).length;
      }

      if (incidentMitigations[0] && incidentMitigations[0].interval) {
        this.form.controls.from.setValue(incidentMitigations[0].interval.from);
        if (!this.isDefaultMitigationOnly) this.form.controls.to.setValue(incidentMitigations[0].interval.to);
      } else {
        if (this.isMitigatedMode) {
          if (mitigationFromDifferentAccount) {
            this.form.controls.to.setValue(mitigationFromDifferentAccount?.interval.to);
            this.form.controls.from.setValue(mitigationFromDifferentAccount?.interval.from);
          } else {
            this.form.controls.to.setValue(new Date());
          }
        } else {
          this.form.controls.from.setValue(mitigationFromDifferentAccount?.interval.from || new Date());
        }
      }

      incidentMitigations = incidentMitigations.filter(incidentMitigation => !!incidentMitigation.mitigationType);
      let ids: number[] = [];
      if (this.isTabletMode) {
        ids = incidentMitigations.map(incidentMitigation => incidentMitigation.mitigationType.id);
        if (ids.length === this.accountMitigationTypes?.length) {
          this.isAddBtnDisabled = true;
        }
      } else {
        ids = incidentMitigations.map(incidentMitigation => incidentMitigation.mitigationType.id);
      }
      this.form.controls.mitigation.setValue(ids);
    }
  }

  validate() {
    if (this.form.invalid) {
      return { invalid: true };
    }
    return null;
  }

  setStartDate() {
    if (!this.form.controls.from.value) this.form.controls.from.setValue(new Date());
    this.form.controls.to.setValue(null);
  }

  setEndDate() {
    if (
      !this.form.controls.to.value &&
      !this.form.controls.from.value &&
      !(this.form.controls['from'] as AbstractControl).errors
    ) {
      this.form.controls.to.setValue(new Date());
    }
  }

  setDisabledState() {
    this.isDisabledWhenOffline = true;
  }
}
