<div
  class="rf-map-layers-panel heap-desktop-map-layer-panel-btn"
  [class]="appType"
  [class.portrait-mode]="isPortraitDesktopMode"
  wcPopupCloser
  (closed)="closePanel()">
  <button (click)="closePanel()" class="close-button navigatable-button"><i class="fas fa-times"> </i></button>

  <div class="rf-map-layers-panel__wrapper">
    <div class="rf-map-layers-panel__header">
      <div class="rf-map-layers-panel__layers-container">
        <div class="rf-map-layers-panel__layers">
          {{ 'mapLayers.layers' | translate | uppercase }}
        </div>
        <ng-content></ng-content>
      </div>
      <div data-cy-id="no-workspace-alert" class="rf-map-layers-panel__no-workspaces" *ngIf="isAllWorkspacesUnchecked">
        <span [innerHTML]="'noWorkspaceSelected' | translate"></span>
      </div>
    </div>

    <div>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree">
        <mat-tree-node *matTreeNodeDef="let node" wc-role="ignore"> </mat-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let node; when: childrenEqualNull" matTreeNodeToggle>
          <wc-icon class="fa-fw" *ngIf="node.iconClass" [class]="appType" [iconName]="node.iconClass"></wc-icon>
          <div class="tree-node without-children" [ngClass]="node.cssClasses">
            <mat-checkbox
              wcFocusStyles
              [offset]="2"
              [attr.data-cy-id]="'layer-type-' + node.name"
              [disabled]="node.isDisabled"
              (change)="updateNode(node, $event.checked)"
              [checked]="node.checked">
              {{ 'liveMapPanelLayerNames.' + node.name | translate }}</mat-checkbox
            >
          </div>
        </mat-nested-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChildWithParent">
          <wc-icon class="fa-fw" *ngIf="node.iconClass" [class]="appType" [iconName]="node.iconClass"></wc-icon>
          <div class="tree-node with-children" matTreeNodeToggle [ngClass]="node.cssClasses" role="with-children">
            <div class="with-children__title">
              <button class="navigatable-button">
                <mat-icon (click)="setCollapseState(node)" class="mat-icon-rtl-mirror">
                  {{ treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right' }}
                </mat-icon>
              </button>
              <!-- DO NOT CHANGE THE ORDER OF INPUTS, IT BREAKS ANIMATION - GONNA BE FIXED ON V15 OF MATERIAL -->
              <mat-checkbox
                wcFocusStyles
                [offset]="2"
                [attr.data-cy-id]="'layer-type-' + node.name"
                [disabled]="node.isDisabled"
                (click)="onCheckboxClick($event)"
                [checked]="descendantsAllSelected(node)"
                [indeterminate]="descendantsPartiallySelected(node)"
                (change)="updateNode(node, $event.checked)">
                {{ 'liveMapPanelLayerNames.' + node.name | translate }}</mat-checkbox
              >
            </div>
            <div class="children-padding" [class.hide-tree]="!treeControl.isExpanded(node)">
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          </div>
        </mat-nested-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let node; when: isRootLevelNode" wc-role="root">
          <ng-container *ngIf="node.children.length > 0">
            <wc-icon class="fa-fw" *ngIf="node.iconClass" [class]="appType" [iconName]="node.iconClass"></wc-icon>
            <h3 class="root-level-node" [ngClass]="node.cssClasses">
              {{ 'liveMapPanelLayerNames.' + node.name | translate }}
            </h3>
            <div role="group">
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          </ng-container>
        </mat-nested-tree-node>
      </mat-tree>
    </div>
  </div>
</div>
