import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { FocusDirectiveModule } from '@wc/wc-common/src';
import { RadioButtonModule } from '@wc/wc-ui/src';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { MultiCheckboxComponentModule } from '../../form-fields-controls/multi-checkbox/multi-checkbox.component.module';
import { WcDateTimeModule } from '../date-time/date-time.module';
import { SchedulerComponent } from './scheduler.component';

const Material: any[] = [MatRadioModule, MatCheckboxModule];
@NgModule({
  declarations: [SchedulerComponent],
  imports: [
    CommonModule,
    Material,
    TranslateModule,
    WcBaseControlFieldModule,
    MultiCheckboxComponentModule,
    WcDateTimeModule,
    FocusDirectiveModule,
    RadioButtonModule,
  ],
  providers: [],
  exports: [SchedulerComponent],
})
export class SchedulerComponentModule {}
