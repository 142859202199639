import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BusStopDetails } from '../../../../../../../core/models/gql.models';

@Component({
  selector: 'wc-selected-bus-stop-tab',
  templateUrl: './selected-bus-stop-tab.component.html',
  styleUrls: ['../../transit-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedBusStopTabComponent {
  @Input() accountId!: number;
  @Input() busStopDetails!: BusStopDetails | null;
  @Output() clickBack = new EventEmitter<1>();

  isMyAgency(accountId: number) {
    return accountId === this.accountId;
  }

  convertToStringWithSign(number) {
    const sign = number > 0 ? '+' : '-';
    return sign + Math.abs(number);
  }
}
