import { Injectable } from '@angular/core';
import { FormFieldOption } from '@wc/wc-ui/src/lib/base/custom-form-control';

@Injectable()
export class AutocompleteFreeTextService<T extends FormFieldOption<unknown, unknown>> {
  insertValue(options: T[], inputValue: string): T {
    const displayName = this.getDisplayName(inputValue);
    const foundOption = this.findOptionByDisplayName(options, displayName);
    const option = {
      displayName: foundOption ? foundOption.displayName : displayName,
      value: foundOption ? foundOption.value : displayName,
    } as T;

    return option;
  }

  getFreeTextVisible(options: T[], inputValue: string) {
    const inputValueLowercase = this.getDisplayName(inputValue).trim();
    return inputValueLowercase.length > 0 && !this.findOptionByDisplayName(options, inputValueLowercase);
  }

  private findOptionByDisplayName(options: T[], displayName: string): T | undefined {
    return options.find(option => option.displayName?.toLocaleLowerCase() === displayName.toLocaleLowerCase().trim());
  }

  private getDisplayName(option: T | string) {
    if (!option) {
      return '';
    }

    return (typeof option === 'string' ? option : option.displayName) ?? '';
  }
}
