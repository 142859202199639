/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';

export type RekorButtonTypes = 'stroked' | 'flat' | 'icon' | 'basic' | 'link';

const HOST_SELECTOR_CLASS_PAIR: {
  selector: `rekor-${RekorButtonTypes}`;
  classes: `rekor-${RekorButtonTypes}-button`[];
}[] = [
  {
    selector: 'rekor-stroked',
    classes: ['rekor-stroked-button'],
  },
  {
    selector: 'rekor-basic',
    classes: ['rekor-basic-button'],
  },
  {
    selector: 'rekor-icon',
    classes: ['rekor-icon-button'],
  },
  {
    selector: 'rekor-flat',
    classes: ['rekor-flat-button'],
  },
  {
    selector: 'rekor-link',
    classes: ['rekor-link-button'],
  },
];

@Directive({
  selector: '[wcBaseButton]',
  host: {
    '[attr.disabled]': 'disabled || null',
    '[class]': 'buttonSize || null',
    '[class.disabled]': 'disabled || null',
  },
})
export class BaseButtonDirective implements OnInit, OnDestroy {
  @Output() btnClick = new EventEmitter();
  private _disabled = false;

  @Input() buttonSize: 'small' | 'medium' | 'large' = 'small';

  @Input() set disabled(value: boolean) {
    this._disabled = !!value;
  }

  get disabled(): boolean {
    return this._disabled;
  }
  constructor(private _elementRef: ElementRef, private _ngZone: NgZone) {
    const classList = (_elementRef.nativeElement as HTMLElement).classList;
    for (const pair of HOST_SELECTOR_CLASS_PAIR) {
      if (this._hasHostAttributes(pair.selector)) {
        pair.classes.forEach(className => classList.add(className));
      }
    }
  }

  ngOnInit(): void {
    this._ngZone.runOutsideAngular(() => {
      this._elementRef.nativeElement.addEventListener('click', this._haltDisabledEvents);
    });
  }

  ngOnDestroy(): void {
    this._elementRef.nativeElement.removeEventListener('click', this._haltDisabledEvents);
  }

  private _hasHostAttributes(...attributes: string[]) {
    return attributes.some(attribute => this._elementRef.nativeElement.hasAttribute(attribute));
  }

  _haltDisabledEvents = (event: Event): void => {
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  };
}
