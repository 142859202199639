import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import {
  FeedSource,
  MeasureUnitType,
  MetricVendorData,
  RegionalSetting,
  RoadwayStatusSegmentView
} from '@wc/core/models/gql.models';
import { ContextTypesEnum } from '../../clustering-tooltip-container/clustering-tooltip-container.component';
import { SegmentDataViewMode } from '../internal-components/segment-data/segment-data.component';

@Component({
  selector: 'wc-segment-details-card',
  templateUrl: './segment-details-card.component.html',
  styleUrls: ['./segment-details-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentDetailsCardComponent {
  readonly contextTypes = ContextTypesEnum;
  @Input() context: ContextTypesEnum | null = null;
  @Input() isSelected = false;
  @Input() measureUnitType: MeasureUnitType = MeasureUnitType.Metric;
  @Input() mode: SegmentDataViewMode | null = null;
  @Input() isHebrew = false;
  @Input() regionalSettings!: RegionalSetting;
  @Input() set entity(segment: RoadwayStatusSegmentView) {
    this.segment = segment;
    this.setSelectedMetricAndDataSources(segment.defaultVendorId as number);
    this.filteredVendors = segment.vendorMetrics.filter(vendor => vendor.vendorId !== segment.defaultVendorId);
  }

  @Output() openDetailsClicked = new EventEmitter<{ segmentId: number; selectedVendorId: number }>();
  dataSources: Array<FeedSource> | undefined = [];
  segment: Partial<RoadwayStatusSegmentView> = [];
  filteredVendors: MetricVendorData[] = [];
  selectedMetric: MetricVendorData | undefined;
  isShowMoreExpanded = false;

  get segmentDataViewModeEnum(): typeof SegmentDataViewMode {
    return SegmentDataViewMode;
  }

  setSelectedMetricAndDataSources(vendorId: number | undefined) {
    this.selectedMetric = this.segment.vendorMetrics?.find(metric => metric.vendorId === vendorId);

    if (this.mode === this.segmentDataViewModeEnum.Extended) {
      this.dataSources = this.segment?.vendorMetrics?.map(vendor => vendor.vendorName);
    } else {
      this.selectedMetric ? (this.dataSources = [this.selectedMetric.vendorName]) : (this.dataSources = []);
    }
  }

  _openDetailsClicked(segmentVendorData: { segmentId: number; selectedVendorId: number }) {
    this.openDetailsClicked.emit(segmentVendorData);
  }

  updateFeedSource(vendorName: FeedSource) {
    this.selectedMetric = this.segment.vendorMetrics?.find(vendor => vendor.vendorName === vendorName);
  }

  showMoreToggled($event: MatExpansionPanel) {
    this.isShowMoreExpanded = $event.expanded;
  }

  trackById(index, item) {
    if (!item) {
      return null;
    }
    return item.id;
  }
}
