import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { MediaService } from "@wc/wc-core/src/lib/services/media.service";
import { MediaFilterType, SnapshotType, SnapshotTypeLabel } from "@wc/wc-models/src/lib/enums/media";
import { Media } from "@wc/core";
import Swiper, { SwiperOptions } from "swiper";
import { KeyCodes } from "@wc/wc-models";
import { downloadFile as downloadFileUtil } from "@wc/utils";


@Component({
  selector: 'wc-cctv-snapshots-full-screen',
  templateUrl: './cctv-snapshots-full-screen.component.html',
  styleUrls: ['./cctv-snapshots-full-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CctvSnapshotsFullScreenComponent implements OnInit {
  mediaList: Array<Media | File> = [];
  config!: SwiperOptions;
  deletedMedia: Array<Media | File> = [];
  downloadFile = downloadFileUtil;
  isEditMode = false;
  isEmptyState = false;
  mediaTitle = '';
  selectedMedia!: Media;
  selectedMediaIndex = -1;
  subscription?: Subscription;
  timeOut: ReturnType<typeof setTimeout> | undefined;

  get isSelectedMedia() :boolean {
    return this.selectedMedia !== undefined
      && this.selectedMedia !== null
      && Boolean((this.selectedMedia as Media).media);
  }

  get selectedMediaUrl(): string {
    return this.isSelectedMedia ? (this.selectedMedia as Media).media.url : '';
  }

  constructor(
    private mediaService: MediaService,
    private dialogRef: MatDialogRef<CctvSnapshotsFullScreenComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      list: Array<Media | File>;
      selectedId: number;
      isEditMode: boolean;
      renewUrlFunction: (args) => Observable<string | undefined>;
    }
  ) {
    const cctvSnapshots = this.mediaService.filterMediaBySource([...data.list], MediaFilterType.CctvSnapshots) || [];
    this.mediaList = this.sortCctvSnapshotsList(cctvSnapshots);
    this.isEditMode = data.isEditMode;
    this.setSwiperConfig();
  }

  ngOnInit() {
    const selectedIndex = this.mediaList.findIndex((snapshot: Media | File) =>
      (snapshot as Media).id == this.data.selectedId);
    this.config.initialSlide = selectedIndex;
    this.setMainMedia(selectedIndex);
  }

  sortCctvSnapshotsList(snapshotsList: Array<Media | File>) {
    const sortingOrder = [
      SnapshotType.Created,
      SnapshotType.Confirmed,
      SnapshotType.Completed,
      SnapshotType.UserCaptured,
    ];

    const filteredByTimeSnapshots = snapshotsList.sort((a: Media | File, b: Media | File) => {
      const expirationA = (a as Media)?.media?.expiration || '';
      const expirationB = (b as Media)?.media?.expiration || '';
      return new Date(expirationA).getTime() - new Date(expirationB).getTime();
    });

    return filteredByTimeSnapshots.sort((a: Media | File, b: Media | File) => {
      const keyA = (a as Media)?.media?.key || '';
      const keyB = (b as Media)?.media?.key || '';

      const orderIndexA = sortingOrder.findIndex(order => keyA.includes(order));
      const orderIndexB = sortingOrder.findIndex(order => keyB.includes(order));

      if (orderIndexA === -1 && orderIndexB === -1) {
        return 0; // Both keys are not in sorting order, maintain original order
      } else if (orderIndexA === -1) {
        return 1; // Key A is not in sorting order, push it to the end
      } else if (orderIndexB === -1) {
        return -1; // Key B is not in sorting order, push it to the end
      } else {
        return orderIndexA - orderIndexB;
      }
    });
  }

  mediaDeleted(index: number) {
    this.deletedMedia.push(this.mediaList[index]);
    this.mediaList.splice(index, 1);
    this.setSwiperConfig();

    if (this.mediaList.length === 0) {
      this.isEmptyState = true;
    } else if (this.mediaList.length === 1 || index === 0) {
      this.setMainMedia(0);
    } else {
      this.setMainMedia(index - 1);
    }
  }

  save() {
    this.dialogRef.close(this.deletedMedia);
  }

  cancel() {
    this.dialogRef.close();
  }

  setSwiperConfig() {
    this.config = {
      spaceBetween: 12,
      speed: 100,
      mousewheel: {
        sensitivity: 0.1,
        thresholdTime: 200,
      },
      scrollbar: {
        el: '.swiper-pagination-cctv-snapshots-carousel',
        draggable: true,
      },
      autoHeight: false,
      keyboard: {
        enabled: true,
      },
      slideClass: 'swiper-slide',
      threshold: 5,
      breakpoints: {
        0: {
          slidesPerView: 6,
        },
        1920: {
          slidesPerView: 6,
        },
      },
      slidesPerView: 6,
      navigation: {
        nextEl: '.swiper-button-next-file',
        prevEl: '.swiper-button-prev-file',
      },
    };

    this.cdr.markForCheck();
  }

  setMainMedia(index: number): void {
    if (this.selectedMediaIndex !== index) {
      this.selectedMedia = this.mediaList[index] as Media;
      this.selectedMediaIndex = index;
    }
  }

  handleSwiperKeyPressed([, keyCode]: [Partial<typeof Swiper>, number]) {
    let newIndex = -1;

    if (keyCode === KeyCodes.RIGHT_ARROW) {
      const consideredIndex = this.selectedMediaIndex + 1;
      newIndex = consideredIndex === this.mediaList.length ? this.mediaList.length - 1 : consideredIndex;
    } else if (keyCode === KeyCodes.LEFT_ARROW) {
      const consideredIndex = this.selectedMediaIndex - 1;
      newIndex = consideredIndex < 0 ? 0 : consideredIndex;
    }

    if (newIndex !== -1) this.setMainMedia(newIndex);
  }

  clearTimeout() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = undefined;
    }
  }

  getSnapshotTypeFromImageUrl(url: string): SnapshotTypeLabel | undefined {
    if (!url) return undefined;

    if (url.includes(SnapshotType.Created)) return SnapshotTypeLabel.Created;
    if (url.includes(SnapshotType.Confirmed)) return SnapshotTypeLabel.Confirmed;
    if (url.includes(SnapshotType.Completed)) return SnapshotTypeLabel.Completed;
    if (url.includes(SnapshotType.Rejected)) return SnapshotTypeLabel.Rejected;
    if (url.includes(SnapshotType.UnitArrived)) return SnapshotTypeLabel.UnitArrived;
    if (url.includes(SnapshotType.UserCaptured)) return SnapshotTypeLabel.User;
    return undefined;
  }
}
