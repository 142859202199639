import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LayerVisibility, LiveMapEntityType, TransitRouteStoreEntity, TransitSubTypeNames } from '@wc/wc-models';
import { AutocompleteComponent } from '@wc/wc-ui/src/components/autocomplete/autocomplete.component';
import { FormFieldData, FormFieldOption } from '@wc/wc-ui/src/lib/base';
import { throttle } from 'lodash';
import { SelectOption } from '../../../../../../../features/ui/form-controls/form-models';

@Component({
  selector: 'wc-route-list-tab',
  templateUrl: './route-list-tab.component.html',
  styleUrls: ['../../transit-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteListTabComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('panelContainer') panelContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('routesAutocomplete', { read: AutocompleteComponent })
  routesAutocomplete!: AutocompleteComponent<FormFieldOption>;
  @Input() myAgencyOnly!: FormControl;
  @Input() selectedRoutes!: FormControl;
  @Input() showBuses!: FormControl;
  @Input() isActiveServicesFeatureActive = false;
  @Input() isAffectedServicePanelOpened = false;
  @Input() isTrainDelaysPanelOpened = false;
  @Input() set layersVisibility(layersVisibility: LayerVisibility[] | undefined | null) {
    if (layersVisibility?.length) {
      this.layerNames = [
        this.transitLayersNames.transit_on_demand_unit,
        this.transitLayersNames.transit_demand_response_unit,
      ].map<{ name: LiveMapEntityType; isVisible: boolean }>(name => ({
        name,
        isVisible: !!layersVisibility?.find(layer => layer.layerName === name && layer.checked),
      }));

      this.isFixedRoutesSelected = !!layersVisibility?.find(
        layer => layer.layerName === this.transitLayersNames.transit_fixed_routes
      )?.checked;
    }

    this.cdr.markForCheck();
  }
  @Input() fixedRoutesAsOptions: SelectOption[] | null = [];
  @Input() transitRoutes: TransitRouteStoreEntity[] | null = [];

  @Output() getUnitId = new EventEmitter<number>();
  @Output() getRouteId = new EventEmitter<number>();
  @Output() layerCheckboxClicked = new EventEmitter<{ name: LiveMapEntityType; checked: boolean }>();

  resizeObserver?: ResizeObserver;

  isFixedRoutesSelected = false;

  transitFieldsData = {
    myAgencyOnly: {
      label: 'transit.myAgencyOnly',
    },
    showBuses: {
      label: 'transit.showBuses',
    },
    routeSelector: {
      label: 'transit.fixedRoutes',
      placeholder: 'transit.searchRoute',
    },
  } as Record<string, FormFieldData>;

  layerNames: { name: LiveMapEntityType; isVisible: boolean }[] = [];
  get transitLayersNames() {
    return TransitSubTypeNames;
  }

  formFieldData!: FormFieldData;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.transitFieldsData['routeSelector'].options = this.fixedRoutesAsOptions as FormFieldOption[];
  }

  ngAfterViewInit(): void {
    this.observePanelResize();
  }

  observePanelResize() {
    this.resizeObserver = new ResizeObserver(
      throttle(() => {
        this.routesAutocomplete.updateTriggerPosition();
      }, 250)
    );

    this.resizeObserver.observe(this.panelContainer.nativeElement);
  }

  trackById(index, item) {
    if (!item) {
      return null;
    }
    return item.id;
  }

  affectedServiceClicked(event: { id: number; entityType: 'route' | 'unit' }) {
    event.entityType === 'route' ? this.getRouteId.emit(event.id) : this.getUnitId.emit(event.id);
  }

  ngOnDestroy(): void {
    this.resizeObserver?.disconnect();
  }
}
