<div class="affected-lanes-container" [class.language-hebrew]="false" *ngIf="affectedLanes.length > 0; else noLanes">
  <div class="affected-lanes-header">
    <div class="details-item affectedDirections">
      <div class="title">{{ 'affectedDirectionsLabel' | translate }}</div>
      <div class="content">
        {{
          (!isMultiDirection ? 'affectedDirections.SINGLE_DIRECTION' : 'affectedDirections.MULTIPLE_DIRECTION')
            | translate
        }}
      </div>
    </div>

    <div class="details-item status">
      <div class="title">{{ 'status' | translate }}</div>
      <div class="content">
        {{
          allLanesAffected
            ? ('lanesStatuses.allLanesAreAffected' | translate)
            : ('lanesStatuses.partialyAffected' | translate)
        }}
      </div>
    </div>
  </div>
  <div class="affected-lanes-border-line"></div>
  <wc-affected-lanes-diagram
    *ngIf="!isMultiDirection"
    class="affected-lanes-component"
    [lanes]="allLanes"
    [hovered]="hoverLaneIndex"
    [isViewMode]="true"
    (laneHovered)="laneHovered($event)">
  </wc-affected-lanes-diagram>

  <div data-cy-id="affected-lanes-slider" class="affected-lanes-slider">
    <div data-cy-id="swiper-affected-lanes-component" class="swiper-component swiper-lanes-view-component">
      <div #swiperId class="swiper-container" [swiper]="config" [class.one-lane-container]="affectedLanes.length === 1">
        <div class="swiper-wrapper">
          <div
            data-cy-id="affected-lanes-slider-lane"
            class="swiper-slide affected-lanes-slider-lane"
            [class.view-only-user]="viewOnly"
            *ngFor="let lane of affectedLanes; let i = index"
            [class.hover]="i === hoverLaneIndex"
            (mouseover)="laneHovered(lane)"
            (mouseleave)="laneHovered(null)">
            <div data-cy-id="affected-lanes-slider-header" class="affected-lanes-slider-header">
              <span
                data-cy-id="affected-lanes-title"
                class="affected-lanes-title"
                *ngIf="!TextualLanes.includes(lane.type); else showType">
                {{ 'Lane' | translate }} {{ lane.number }}
              </span>
              <ng-template #showType>
                <span class="affected-lanes-title">
                  {{ 'laneTypes.' + lane.type | translate }}
                </span>
              </ng-template>
            </div>
            <div class="affected-lanes-content">
              <div class="affected-lanes-type-wrapper">
                <div class="affected-lanes-data type">
                  <div class="title">{{ 'laneType' | translate }}</div>
                  <div class="content">
                    {{ 'laneTypes.' + lane.type | translate }}
                  </div>
                </div>
                <div class="affected-lanes-data direction">
                  <div class="title">{{ 'direction' | translate }}</div>
                  <div class="content">
                    {{ lane.direction || '-' }}
                  </div>
                </div>
                <div class="affected-lanes-data roadType">
                  <div class="title">{{ 'roadType' | translate }}</div>
                  <div class="content">
                    {{ 'roadTypes.' + lane.roadType | translate }}
                  </div>
                </div>
              </div>
              <div class="affected-lanes-slider-toggle">
                <span class="toggle-option" [class.open]="lane.isClosed === false">
                  {{ 'affectedLanesToggleOptions.open' | translate }}
                </span>
                <mat-slide-toggle
                  data-cy-id="affected-lanes-slider-toggle-button"
                  [checked]="lane.isClosed"
                  (change)="updateLaneStatus($event, lane)"></mat-slide-toggle>

                <span class="toggle-option" [class.closed]="lane.isClosed">
                  {{ 'affectedLanesToggleOptions.close' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="lanes-swiper-pagination swiper-pagination" *ngIf="affectedLanes.length > 3"></div>
      </div>

      <div
        data-cy-id="swiper-pagination-affected-lanes-previous"
        class="lanes-swiper-button-prev swiper-button-prev"></div>
      <div data-cy-id="swiper-pagination-affected-lanes-next" class="lanes-swiper-button-next swiper-button-next"></div>
    </div>
  </div>
</div>

<ng-template #noLanes>
  <div class="no-lanes-container" [class.language-hebrew]="false">
    <i class="no-lanes-image"></i>
    <div class="no-lanes-title">
      {{ 'noAffectedLanes' | translate }}
    </div>
    <div class="no-lanes-msg">
      {{ 'noAffectedLanesMsg' | translate }}
    </div>
  </div>
</ng-template>
