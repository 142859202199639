import { Component, Input, OnDestroy, Inject, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'wc-spinner',
  template: `<div class="preloader" [ngStyle]="{ backgroundColor: backgroundColor }" *ngIf="isSpinnerVisible">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>`,
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent implements OnDestroy {
  @Input() isSpinnerVisible = false;

  @Input()
  public backgroundColor = 'rgba(255, 255, 255, 1)';

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document) {}

  ngOnDestroy(): void {
    this.isSpinnerVisible = false;
  }
}
