/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { ConstructionStoreEntity, ConstructionUIEntity } from '@wc/wc-models/src';
import { map } from 'rxjs/operators';
import { LayerType } from '../../../../../../core/models/gql.models';
import { EntitiesServiceV2 } from '../../../services/entities.service';

export interface ConstructionState extends EntityState<ConstructionStoreEntity, number> {}
export interface ConstructionUIState extends EntityState<ConstructionUIEntity> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: LayerType.Construction })
export class ConstructionStore extends EntityStore<ConstructionState> {
  ui!: EntityUIStore<ConstructionUIState>;
  constructor(entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$
      .pipe(map(({ CONSTRUCTION }) => CONSTRUCTION || []))
      .subscribe(UIConstructions => this.ui.upsertMany(UIConstructions));

    entitiesService.getDiff$
      .pipe(
        map(({ modified, removed }) => ({
          removedConstructions: removed?.CONSTRUCTION,
          modifiedConstructions: modified.CONSTRUCTION,
        }))
      )
      .subscribe(({ removedConstructions, modifiedConstructions }) => {
        if (modifiedConstructions) this.upsertMany(modifiedConstructions as ConstructionStoreEntity[]);
        if (removedConstructions) this.remove(removedConstructions);
      });

    entitiesService.activeEntityIds$.pipe(map(({ CONSTRUCTION }) => CONSTRUCTION || [])).subscribe(id => {
      this.ui.setActive(id);
    });
  }
}
