import { LiveMapEntityType, MapLayer } from './models';

export const TransitLayersConfig: {
  [layerName in LiveMapEntityType]?: MapLayer;
} = {
  // Transit Static layers
  transit_fixed_routes: {
    multiLineStringStatusStyle: {
      default: {
        default: [
          {
            shape: 'lineSolid',
            color: 'dynamic',
            width: 3,
            opacity: 1,
            label: {
              // textSrcField: 'number', placement: 'line', color: "#9A684C", outlineColor: "#FDF1EA", size: 16, maxAngle: 0.4, textAlign: 'center', overflow: false
            },
          },
        ],
        // hover: [
        //   { shape: 'lineSolid', color: "dynamic", width: 3}
        // ],
        selected: [{ shape: 'lineSolid', color: 'dynamic', width: 6 }],
      },
    },
  },

  transit_fixed_routes_my_agency: {
    multiLineStringStatusStyle: {
      default: {
        default: [
          {
            shape: 'lineSolid',
            color: 'dynamic',
            width: 3,
            opacity: 1,
            label: {
              // textSrcField: 'number', placement: 'line', color: "#9A684C", outlineColor: "#FDF1EA", size: 16, maxAngle: 0.4, textAlign: 'center', overflow: false
            },
          },
        ],
        // hover: [
        //   { shape: 'lineSolid', color: "dynamic", width: 3}
        // ],
        selected: [{ shape: 'lineSolid', color: 'dynamic', width: 6 }],
      },
    },
  },

  transit_fixed_stops: {
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'bus_stop' } }],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'bus_stop.hover', anchor: [0.5, 0.9] },
          },
        ],
        selected: [
          { shape: 'marker', icon: { iconName: 'bus_stop.selected' } },
          { shape: 'marker', icon: { iconName: 'bus_stop.selected' } },
        ],
      },
    },
  },

  transit_fixed_stops_my_agency: {
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'bus_stop' } }],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'bus_stop.hover', anchor: [0.5, 0.9] },
          },
        ],
        selected: [
          { shape: 'marker', icon: { iconName: 'bus_stop.selected' } },
          { shape: 'marker', icon: { iconName: 'bus_stop.selected' } },
        ],
      },
    },
  },

  // Transit Units
  transit_fixed_route_bus_unit: {
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time',
              anchor: [0.5, 0.5],
            },
          },
          {
            shape: 'marker',
            icon: { iconName: 'green_arrow_4', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time.hover',
              anchor: [0.5, 0.5],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time_direction_arrow.hover',
              anchor: [0.5, 0.5],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time.selected',
              anchor: [0.5, 0.5],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time_direction_arrow.selected',
              anchor: [0.5, 0.5],
            },
          },
        ],
      },
      on_time: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time',
              srcByEntityProperty: 'rerouted',
              anchor: [0.5, 0.5],
            },
          },
          {
            shape: 'marker',
            icon: { iconName: 'green_arrow_4', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time.hover',
              anchor: [0.5, 0.5],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time_direction_arrow.hover',
              anchor: [0.5, 0.5],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time.selected',
              anchor: [0.5, 0.5],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time_direction_arrow.selected',
              anchor: [0.5, 0.5],
            },
          },
        ],
      },
      early: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_early',
              srcByEntityProperty: 'rerouted',
              anchor: [0.5, 0.5],
            },
          },
          {
            shape: 'marker',
            icon: { iconName: 'light_red_arrow', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_early.hover',
              anchor: [0.5, 0.5],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_early_direction_arrow.hover',
              anchor: [0.5, 0.5],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_early.selected',
              anchor: [0.5, 0.5],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_early_direction_arrow.selected',
              anchor: [0.5, 0.5],
            },
          },
        ],
      },
      late: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'transit_fixed_routes_late', anchor: [0.5, 0.5], srcByEntityProperty: 'rerouted' },
          },
          {
            shape: 'marker',
            icon: { iconName: 'dark_blue_arrow', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_late.hover',
              anchor: [0.5, 0.5],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_late_direction_arrow.hover',
              anchor: [0.5, 0.5],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_late.selected',
              anchor: [0.5, 0.5],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_late_direction_arrow.selected',
              anchor: [0.5, 0.5],
            },
          },
        ],
      },
    },
  },

  transit_on_demand_unit: {
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'transit_on_demand', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'pink_arrow', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'transit_on_demand.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'transit_on_demand_direction_arrow.hover', anchor: [0.5, 0.64] },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_on_demand.selected',
              anchor: [0.5, 0.5],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_on_demand_direction_arrow.selected',
              anchor: [0.5, 0.64],
            },
          },
        ],
      },
    },
  },

  transit_on_demand_unit_my_agency: {
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'transit_on_demand', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'my_agency', anchor: [0, 1.15], scale: 0.6 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'transit_on_demand.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'my_agency', anchor: [0, 1.15], scale: 0.6 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_on_demand.selected',
              anchor: [0.5, 0.5],
            },
          },
          {
            shape: 'marker',
            icon: { iconName: 'my_agency', anchor: [0, 1.15], scale: 0.6 },
          },
        ],
      },
    },
  },

  transit_demand_response_unit: {
    zIndex: 9,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'transit_demand_response', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'purple_arrow', anchor: [0.5, 2.3] },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'transit_demand_response.hover', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'transit_demand_response_direction_arrow.hover', anchor: [0.5, 0.64] },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'transit_demand_response.selected', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'transit_demand_response_direction_arrow.selected', anchor: [0.5, 0.64] },
          },
        ],
      },
    },
  },

  transit_demand_response_unit_my_agency: {
    zIndex: 9,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'transit_demand_response', anchor: [0.5, 0.5] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'my_agency', anchor: [0, 1.15], scale: 0.6 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_demand_response.hover',
              anchor: [0.5, 0.5],
            },
          },
          {
            shape: 'marker',
            icon: { iconName: 'my_agency', anchor: [0, 1.15], scale: 0.6 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_demand_response.selected',
              anchor: [0.5, 0.5],
            },
          },
          {
            shape: 'marker',
            icon: { iconName: 'my_agency', anchor: [0, 1.15], scale: 0.6 },
          },
        ],
      },
    },
  },
};
