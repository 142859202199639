import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { PriorityScoreComponent } from './priority-score.component';

@NgModule({
  declarations: [PriorityScoreComponent],
  exports: [PriorityScoreComponent],
  imports: [CommonModule, MatTooltipModule, TranslateModule],
})
export class PriorityScoreModule {}
