import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomFormControlComponent } from '@wc/wc-ui/src/lib/base/custom-form-control';

const slideDownAnimation = trigger('slideDownAnimation', [
  state('void', style({ transform: 'translateY(-4px)', opacity: 0 })),
  state('*', style({ transform: 'translateY(0)', opacity: 1 })),
  transition(':enter', [animate('0.25s ease-out')]),
]);

@Component({
  selector: 'wc-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [slideDownAnimation],
})
export class TextAreaComponent extends CustomFormControlComponent implements OnInit, AfterViewInit {
  @ViewChild('textAreaReference') textAreaRef!: ElementRef<HTMLTextAreaElement>;

  @Input() customValidationMessage: string | null = null;
  @Input() viewCharacterCounter = false;
  @Input() isLabelVisible = true;
  @Input() autoFocus = false;
  @Input() readOnly = false;
  @Input() isFullWidth = true;
  @Input() hideClearButton = false;
  @Input() rows = 4;

  maxLength = 0;
  isFocused = false;

  ngOnInit(): void {
    if (!this.ngControl.value) {
      this.ngControl.control?.setValue('');
    }

    if (this.viewCharacterCounter) {
      const err = this.ngControl.control?.validator
        ? this.ngControl.control.validator(new FormControl('.'.repeat(Math.pow(2, 10))))
        : null;

      if (err && err.maxlength && err.maxlength.requiredLength && err.maxlength.requiredLength > 0)
        this.maxLength = err.maxlength.requiredLength;
    }
  }

  ngAfterViewInit(): void {
    if (this.autoFocus) {
      this.textAreaRef.nativeElement.focus();
    }
  }

  setIsFocused(val: boolean) {
    this.isFocused = val;
  }

  clearTextArea(event: MouseEvent) {
    event.preventDefault();

    this.ngControl.control?.setValue('');

    this.textAreaRef.nativeElement.focus();
  }
}
