import { LiveMapEntity, WcMapConfigModel } from '@wc/core/models';
import { throttle } from 'lodash';
import Feature from 'ol/Feature';
import { MultiLineString, Point } from 'ol/geom';
import { Pointer as PointerInteraction } from 'ol/interaction';
import VectorTileLayer from 'ol/layer/VectorTile';
import Map from 'ol/Map';
import RenderFeature from 'ol/render/Feature';
import * as Styles from '../styles/styles';
export class HoverEntity extends PointerInteraction {
  map!: Map;
  entity: LiveMapEntity | undefined;
  lastHovered: Feature | undefined;
  lastHoveredEntity!: LiveMapEntity;
  active = true;
  // lastSelected: { [layerName in LiveMapEntityType]?: Feature } = {};
  // cursor_ = 'pointer';
  // previousCursor_ = undefined;

  constructor(private over, private tooltipOverlay, private mapConfig: WcMapConfigModel) {
    super();
  }

  handleMoveEventFunction = throttle(e => {
    this.map = e.map;
    if (this.lastHovered) {
      this.lastHovered.set('hovered', false);
      this.lastHoveredEntity['hovered'] = false;
      const style = Styles.styleType(this.lastHoveredEntity, this.mapConfig);
      this.lastHovered.setStyle(style);
    }

    const pixel = this.map.getEventPixel(e.originalEvent);
    const hit = this.map.hasFeatureAtPixel(pixel, {
      layerFilter: layer => {
        const isHit = !(layer instanceof VectorTileLayer) && (layer.getOpacity() > 0 || layer.getVisible());
        return isHit;
      },
    });

    if (hit && this.active) {
      const { entities, position, features } = this.entitiesAtPixel(e.pixel);
      let _entities = entities;
      const hitTolerance = this.mapConfig.layers[entities[0].featureType.toLocaleLowerCase()].hitTolerance;
      if (hitTolerance) {
        _entities = this.entitiesAtPixel(e.pixel, hitTolerance).entities.filter(
          ({ featureType }) => featureType === entities[0].featureType
        );
      }
      if (_entities[0]?.editMode) return;
      if (position) {
        let currentFeature = features[0];
        if (features[0] && features[0].getProperties().features) {
          currentFeature = features[0].getProperties().features[0];
        }
        this.lastHovered = new Feature(currentFeature);
        this.lastHovered.set('hovered', true);
        this.lastHoveredEntity = _entities[0];
        this.lastHoveredEntity['hovered'] = true;
        const style = Styles.styleType(this.lastHoveredEntity, this.mapConfig);
        this.lastHovered.setStyle(style);

        if (
          _entities[0].location?.type === 'Polygon' ||
          _entities[0].location?.type === 'MultiLineString' ||
          _entities[0].location?.type === 'MultiPolygon' ||
          _entities[0].location instanceof MultiLineString ||
          _entities[0].path !== undefined
        ) {
          const mapHoverCoordinates = e.coordinate;
          this.tooltipOverlay?.setPosition([mapHoverCoordinates[0], mapHoverCoordinates[1]]);
        } else {
          const point = currentFeature.getGeometry() as Point;
          this.tooltipOverlay?.setPosition(point.getFlatCoordinates());
        }
        this.over.emit(_entities);
      }
    } else {
      this.tooltipOverlay?.setPosition(undefined);

      if (this.lastHovered) {
        this.lastHovered.set('hovered', false);
        this.lastHoveredEntity['hovered'] = false;
        const style = Styles.styleType(this.lastHoveredEntity, this.mapConfig);
        this.lastHovered.setStyle(style);
        this.lastHovered = undefined;
      }
    }
  }, 0);

  handleMoveEvent(e) {
    this.handleMoveEventFunction(e);
  }

  entitiesAtPixel(pixel, hitTolerance?: number) {
    const features = this.map
      .getFeaturesAtPixel(pixel, { hitTolerance })
      .filter(feature => !(feature instanceof RenderFeature)); //.filter( feature => !(feature instanceof RenderFeature));//, function(ft, layer){return ft.getProperties()});
    // features = features.filter( feature => feature instanceof RenderFeature);
    const entities: LiveMapEntity[] = [];

    // const feature = features.find( feature => {
    //   feature = feature as Feature;
    //   const _point = feature.getGeometry() as Point;
    //   return _point.getCoordinates();
    // }) as RenderFeature;
    features.forEach(_feature => {
      if (_feature.get('features')) {
        _feature.get('features').forEach(feature => {
          entities.push(feature.getProperties() as LiveMapEntity);
        });
      } else {
        entities.push(_feature.getProperties() as LiveMapEntity);
      }
    });

    // const coordinate = (features[0] && features[0].getGeometry()) ? features[0].getGeometry().getOrientedFlatCoordinates() : null;
    // console.log(features[0])
    // console.log(entities)
    // if(features[0]) {
    //   return features[0];
    // }
    // const entity = feature?.getProperties() as LiveMapEntity;
    // const coordinate = feature?.getGeometry()? feature.getGeometry().getOrientedFlatCoordinates() : null;
    const coordinates = features[0].getGeometry() as Point;
    const position = this.map.getPixelFromCoordinate(coordinates.getCoordinates());
    return { entities: entities, position: position, features: features };
    // return features.map( feature => feature.getProperties().features[0].getProperties());
  }
}
