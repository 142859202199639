<ngx-charts-bar-horizontal
  [view]="graphWidthAndHeight"
  [scheme]="colorScheme"
  [results]="_chartData"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [showXAxisLabel]="!!xAxisLabel"
  [showYAxisLabel]="!!yAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [xScaleMax]="xScaleMax"
  [xScaleMin]="xScaleMin"
  [xAxisTicks]="xAxisTickValues"
  [barPadding]="barPadding"
  [xAxisTickFormatting]="labelFormatting"
  [roundEdges]="false"
  [yAxisLabel]="yAxisLabel">
</ngx-charts-bar-horizontal>
