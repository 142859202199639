<div class="rp-entity-control">
  <div
    *ngIf="templateControl.value"
    data-cy-id="entity-edit-response-plan-delete-btn"
    class="rp-entity-control__delete"
    (click)="deleteResponsePlan()">
    <i class="fas fa-trash"></i>
    <span>{{ 'confirmModal.deleteButton' | translate }}</span>
  </div>
  <wc-loader-component [show]="isLoadingTemplates"></wc-loader-component>
  <mat-expansion-panel
    #matExpansion
    [wcInvalidExpansionCssClass]="isInvalid"
    class="incident-section"
    [expanded]="true">
    <mat-expansion-panel-header
      [wcTooltipText]="(matExpansion.expanded ? 'collapse' : 'expand') | translate"
      wcTooltipPlacement="bottom-end"
      [wcTooltipOffsetDistance]="-5"
      [wcTooltipOffsetSkidding]="3">
      <mat-panel-title>
        <span *ngIf="isMandatory" class="required-section">*</span>
        <i class="section-icon fas fa-list-ul"></i>
        {{ 'responsePlan' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="rp-entity-control__template">
      <span> {{ 'template' | translate }} </span>

      <wc-select [formControl]="templateControl" [formFieldData]="formFieldData"></wc-select>
    </div>

    <form *ngIf="formGroup" [formGroup]="formGroup">
      <wc-response-plan-form
        [scrollableContainerId]="'panel-id'"
        [isEditMode]="isEdit"
        [uiMode]="uiModeEnum.Entity"
        [entityType]="entityType"
        [regionalSetting]="responsePlanService.reginalSettings"></wc-response-plan-form>
    </form>
    <ng-container
      *ngIf="
        (!!_startTime && !!_endTime) || entityType === responsePlanEntityType.Incident;
        else noTime
      "></ng-container>
    <ng-template #noTime>
      <div class="rp-entity-control__no-schedule">
        <span class="rp-entity-control__no-schedule--text">
          {{ 'noSchedule' | translate }}
        </span>
        <span (click)="goToSchedule()" class="rp-entity-control__no-schedule--link">
          {{ 'goToSchedule' | translate }}
        </span>
      </div>
    </ng-template>
    <mat-error *ngIf="isMandatory && !templateControl.value">
      {{ 'responsePlanIsMandatory' | translate }}
    </mat-error>
  </mat-expansion-panel>
</div>
