import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LaneInput, LaneType } from '@wc/core';

@Pipe({
  name: 'laneShortLabel',
})
export class LaneShortLabelPipe implements PipeTransform {
  constructor(public translateService: TranslateService) {}

  transform(lane: LaneInput): string {
    const laneType = lane.type;
    const laneNumber = lane.number;

    switch (laneType) {
      case LaneType.Gore: {
        const goreTranslated = this.translateService.instant('Gore');
        return laneNumber === 1 ? goreTranslated : `${goreTranslated} ${laneNumber}`;
      }
      case LaneType.LeftShoulder: {
        return this.translateService.instant('LS');
      }
      case LaneType.RightShoulder: {
        return this.translateService.instant('RS');
      }
      default: {
        const laneTranslated = this.translateService.instant('lane');
        return laneNumber ? `${laneTranslated} ${laneNumber}` : laneTranslated;
      }
    }
  }
}
