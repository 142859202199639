/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { LayerType } from '@wc/core';
import { TransitBusStoreEntity, TransitBusUIEntity } from '@wc/wc-models/src';
import { map } from 'rxjs/operators';
import { EntitiesServiceV2 } from '../../services/entities.service';

export interface TransitBusState extends EntityState<TransitBusStoreEntity, number> {}
export interface TransitBusUIState extends EntityState<TransitBusUIEntity> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: LayerType.Unit })
export class TransitFixedRouteBusStore extends EntityStore<TransitBusState> {
  ui!: EntityUIStore<TransitBusUIState>;
  constructor(entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$
      .pipe(map(({ TRANSIT_FIXED_ROUTE_BUS }) => TRANSIT_FIXED_ROUTE_BUS || []))
      .subscribe(UIEntities => this.ui.upsertMany(UIEntities));

    entitiesService.getDiff$
      .pipe(
        map(({ modified, removed }) => ({
          modifiedBuses: modified.TRANSIT_FIXED_ROUTE_BUS,
          removedBuses: removed?.TRANSIT_FIXED_ROUTE_BUS,
        }))
      )
      .subscribe(({ removedBuses, modifiedBuses }) => {
        if (modifiedBuses) {
          this.upsertMany(modifiedBuses);
        }
      });

    entitiesService.activeEntityIds$
      .pipe(map(({ TRANSIT_FIXED_ROUTE_BUS }) => TRANSIT_FIXED_ROUTE_BUS || []))
      .subscribe(id => {
        this.ui.setActive(id);
      });
  }
}
