import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { ElectronService } from '../services/electron.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorElectronAuthService implements HttpInterceptor {
  retryCount = 0;
  constructor(private electronService: ElectronService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let handler: Observable<HttpEvent<any>> = next.handle(req);

    if (this.electronService.isElectronApp) {
      handler = from<string>(this.electronService.context.getAuthToken()).pipe(
        take(1),
        map(token => {
          if (!token) {
            throw token;
          } else {
            return req.clone({
              setHeaders: { Authorization: `Bearer ${token}` },
            });
          }
        }),
        mergeMap(_req => {
          return next.handle(_req).pipe(
            catchError((error: HttpErrorResponse) => {
              // datadogLogs.logger.info('InterceptorService - Http error', {
              //   network_error: JSON.stringify(error),
              // });
              if (error.status === 401) {
                this.electronService.context.authLogout();
                console.log('401 error');
              }
              return throwError(error);
            })
          );
        })
      );
    }

    return handler;
  }
}
