import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { WcNotificationModule } from '../notification/notification.module';
import { NetworkBadgeComponent } from './network-badge.component';

@NgModule({
  declarations: [NetworkBadgeComponent],
  imports: [CommonModule, WcNotificationModule, TranslateModule, FlexLayoutModule],
  exports: [NetworkBadgeComponent],
})
export class WcNetworkBadgeModule {}
