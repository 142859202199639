import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  InjectionToken,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { HeapAnalyticsService } from '@wc-core';
import * as Apollo from 'apollo-angular';
import * as _ from 'lodash';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, map, pairwise, startWith, switchMap, tap } from 'rxjs/operators';
import { BaseControlFieldComponent } from '../../../../features/ui/base/base-control-field.component';
import { SelectOption } from '../../../../features/ui/form-controls/form-models';
import { sortObjectArrayByKey } from '../../../../utils/sortObjectArrayByKey';
import { chipStyles } from '../../../../wc-models/src';
import { WCErrorStateMatcher } from '../../../../wc-ui/src/lib/base/error-state-matcher';

export const GQL_QUERY_TOKEN = new InjectionToken('GQL_QUERY_TOKEN');

@Component({
  selector: 'wc-autocomplete-v2',
  templateUrl: './autocomplete-v2.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteV2Component),
      multi: true,
    },
  ],
  styleUrls: ['./autocomplete-v2.component.scss'],
})
export class AutocompleteV2Component extends BaseControlFieldComponent implements AfterViewInit, OnDestroy {
  @ViewChild('autocompleteSelectPanel') autocompleteSelectPanel!: MatSelect;
  @ViewChild('autocompleteInputField') autocompleteInputField!: ElementRef;
  @ViewChild('inputField') inputField!: ElementRef;
  @Input() selectedChipsVisible = false;
  @Input() optionsQuery?: Apollo.Query<any>;
  @Input() minSubstringLength = 3;
  @Input() queryDataKey = 'options';
  @Input() checkForNoResult = true;
  @Input() noResultFoundLabel = 'noResultsFound';
  @Input() showStartTypingLabel = 'startTyping';
  @Input() allowMultiSelect = false;
  @Input() allowFreeText = false;
  @Input() shouldScrollIntoView = false;
  @Input() set uniqueId(value: string) {
    this.matSelectId = `wc-mat-select-${value}`;
  }
  @Input() floatLabel = true;
  @Input() dynamicClass?: string;
  @Input() supportLoadMoreOption = false;
  @Input() emitChangeOnPanelClose = true;
  @Input() set forceListPositionBelow(value: boolean) {
    this.yPosition = value ? 'above' : 'below';
  }
  @Input() isTabletMode = false;
  @Input() customValidationMessage: string | null = null;

  @Input() set optionsList(options: SelectOption[]) {
    this.updateOptions(options);
  }

  @Output() inputBlurred: EventEmitter<FormControl> = new EventEmitter();
  matcher: ErrorStateMatcher = new WCErrorStateMatcher();
  initialOptionList: SelectOption[] = [];
  matSelectId = '';
  loadmore = true;

  get panelClass() {
    return this.isTabletMode
      ? 'autocomplete-select-panel tablet-mode' + ' ' + this.dynamicClass
      : 'autocomplete-select-panel' + ' ' + this.dynamicClass;
  }

  get areOptionsLoading() {
    return this.optionsQuery && this.isLoadingOptionsFromQuery;
  }

  yPosition: 'above' | 'below' = this.isTabletMode ? 'above' : 'below';
  filteredOptions?: Observable<any>;
  filteredWithHidden?: Observable<any>;
  filterVisible?: Observable<any>;
  initValue = '';
  showNoResultFound = false;
  showStartTyping = false;
  autocompleteInput = new FormControl();
  inputFieldWidth?: string;
  autocompleteSub?: Subscription;
  fieldFormControlSub?: Subscription;
  lastValue: any[] = [];
  isSelectPanelOpened = false;
  selectedOptions: Observable<SelectOption[]> | undefined = undefined;
  isLoadingOptionsFromQuery = false;
  readonly chipStyles = chipStyles;

  constructor(
    protected elm: ElementRef,
    protected cdr: ChangeDetectorRef,
    protected heapService: HeapAnalyticsService
  ) {
    super();
  }

  ngAfterViewInit() {
    const baseValueChanges = this.autocompleteInput.valueChanges.pipe(startWith(''), debounceTime(200));
    if (this.shouldScrollIntoView) {
      if (!this.matSelectId) {
        console.error('if shouldScrollIntoView set to true, uniqueId must be prvided');
        return;
      }
      if (this.autocompleteSelectPanel) {
        this.autocompleteSelectPanel['wcOpen'] = this.autocompleteSelectPanel.open;
        this.autocompleteSelectPanel.open = () => null;
      }
    }

    if (!this.optionsQuery) this.updateOptions(this.fieldData.options || []);

    if (this.allowFreeText) {
      this.autocompleteSub = this.autocompleteInput.valueChanges
        .pipe(debounceTime(100), pairwise())
        .subscribe(([prevValue, value]) => {
          if (value?.length < prevValue?.length) {
            this.fieldData.options = [...[], ...this.initialOptionList];
          }
          this.addFreeTextToOptions(value);
        });
    }

    if (this.optionsQuery) {
      this.updateFromOptionsQuery(baseValueChanges);
    } else {
      this.updateFromLocalOptions(baseValueChanges);
    }
    // FOR CONFIGURABLE WITH LOAD MORE ONLY
    this.filteredWithHidden = baseValueChanges.pipe(
      map(displayName => {
        if (displayName) {
          return !this.loadmore ? [] : this._filter(displayName, this.fieldData.options);
        } else {
          return this.visibleOptionsIncludeControlValue;
        }
      })
    );

    // FOR CONFIGURABLE WITH LOAD MORE ONLY
    this.filterVisible = baseValueChanges.pipe(
      map(displayName => {
        return displayName ? this._filter(displayName, this.fieldData.options) : this.hiddenOptionsWithoutControlValue;
      })
    );

    this.selectedOptions = this.fieldFormControl.valueChanges.pipe(
      map((selected: number[]) => this.mapSelectedOptionsForChipSection(selected))
    );

    this.fieldFormControlSub = this.fieldFormControl.valueChanges.subscribe(val => {
      if (this.autocompleteInput.value === '' || this.autocompleteInput.value === null) {
        this.lastValue = val;
      }
    });
  }

  isOptionVisible(option: SelectOption) {
    return (
      !option.hidden ||
      option.value === this.fieldFormControl.value ||
      option.value === this.autocompleteInput.value ||
      option.value !== '' ||
      this.areOptionsLoading
    );
  }

  mapSelectedOptionsForChipSection(selectedValues: number[]) {
    if (this.optionsQuery) {
      return this.lastValue.map(val => ({ displayName: val, value: val }));
    }
    const options = this.fieldData.options;
    return options?.length && this.allowMultiSelect && this.selectedChipsVisible
      ? (selectedValues.map(id => {
          const selected = options?.find(option => option.value === id);
          return selected;
        }) as SelectOption[])
      : [];
  }

  updateFromOptionsQuery(baseValueChanges: Observable<any>): void {
    this.filteredOptions = baseValueChanges.pipe(
      tap(() => this.setLoadingState(true)),
      switchMap(subString => {
        return this.optionsQuery && subString?.length >= this.minSubstringLength
          ? this.optionsQuery.fetch({ subString })
          : of({
              data: {
                [this.queryDataKey]: this.fieldFormControl.value?.length
                  ? _.union(this.fieldFormControl.value, this.lastValue)
                  : _.union([''], this.lastValue ?? []),
              },
            });
      }),
      // make generic options name on tha backend
      map(result => {
        return result.data[this.queryDataKey]?.map(val => ({ displayName: val, value: val }));
      }),
      tap(results => {
        this.setLoadingState(false, results);
        if (this.allowMultiSelect) {
          this.fieldFormControl.setValue(_.union(this.fieldFormControl.value, this.lastValue));
        }
      })
    );
  }

  setLoadingState(loading: boolean, results?: SelectOption[]) {
    if (this.optionsQuery) {
      this.showStartTyping = loading ? false : results?.length === 1 && !results[0].value;
      this.isLoadingOptionsFromQuery = loading;
      this.showNoResultFound = results?.length === 0;
      this.cdr.markForCheck();
    }
  }

  updateFromLocalOptions(baseValueChanges: Observable<any>): void {
    this.filteredOptions = baseValueChanges.pipe(
      map(displayName => {
        let options = this.fieldData.options;
        if (this.allowFreeText && !this.fieldData.options?.length) {
          options = [{ displayName: '', value: '', data: {} }];
        }

        return displayName ? this._filter(displayName, this.fieldData.options) : options;
      }),
      tap(val => {
        if (this.allowMultiSelect) this.fieldFormControl.setValue(_.union(this.fieldFormControl.value, this.lastValue));
      })
    );
  }

  clickOnLoadMore() {
    this.loadmore = false;
    this.autocompleteInput.updateValueAndValidity();
  }

  protected _filter(input: string, optionList?: SelectOption[]) {
    if (typeof input !== 'string') return;

    const result = optionList?.filter(option => option['displayName']?.toLowerCase().includes(input?.toLowerCase()));
    result?.length === 0 && this.checkForNoResult ? (this.showNoResultFound = true) : (this.showNoResultFound = false);
    return result;
  }

  optionClick(val, emitChange: boolean = true, setFieldFormControlValue = false) {
    if (this.allowMultiSelect && this.lastValue?.includes(val)) {
      this.lastValue = _.without(this.lastValue, val);

      if (setFieldFormControlValue) {
        this.fieldFormControl.setValue(this.lastValue);
      }
    }

    if (this.allowMultiSelect) {
      this.autocompleteInput.setValue(null);
    } else if (emitChange) {
      this.onChanged(val);
    }

    if (this.allowFreeText) {
      this.autocompleteInput.setValue(val);
    }
  }

  addFreeTextToOptions(value) {
    this.fieldData.options = this.fieldData.options || [];
    const isIncludesEmptyOption = this.fieldData.options?.filter(option => option.displayName === '').length > 0;

    if (value && !this.fieldData.options?.find(option => option.displayName === value)) {
      this.fieldData.options.unshift({
        value: value,
        displayName: value,
      });

      // Remove empty option after adding a free text
      this.fieldData.options = this.fieldData.options.filter(option => option.value !== '');
    }
  }

  setFreeTextValue(value) {
    this.fieldData.options = this.fieldData.options || [];

    // Prevents doubled values
    if (!this.fieldData.options.length || !this.fieldData.options.filter(option => option.value === value)) {
      this.fieldData.options.push({
        value: value,
        displayName: value,
        data: {},
      });
    }

    setTimeout(() => {
      this.fieldFormControl.setValue(value);
    });
  }

  /**
   * @param options
   * @returns void
   * @description NOTE if `allowFreeText` is true , the old filed value wont be removed
   */
  updateOptions(options: SelectOption[]) {
    // For externally change the options;
    this.initialOptionList = [...[], ...options];
    if (JSON.stringify(this.fieldData.options) === JSON.stringify(options)) return;

    // if all options have displayName then sort by it and set options
    this.fieldData.options = options.some(value => !value.displayName)
      ? options
      : sortObjectArrayByKey(options, 'displayName');

    if (this.allowFreeText && this.fieldFormControl.value) {
      this.addFreeTextToOptions(this.fieldFormControl.value);
    }

    if (!this.allowMultiSelect) {
      this.autocompleteInput.setValue(null);
    }
    this.loadmore = true;
  }

  clear() {
    this.autocompleteInput.setValue(null);
    this.fieldFormControl.setValue(null, { emitEvent: false });
    this.fieldData.options = [...[], ...this.initialOptionList];
    this.onChanged(null);
    this.showNoResultFound = false;
  }

  reset() {
    this.clear();
    this.fieldData.options = [];
  }

  resetValue() {
    this.clear();
  }

  writeValue(value) {
    if (value) {
      this.lastValue = value;
      this.fieldFormControl.setValue(value);

      if (this.allowFreeText && !this.fieldData.options?.find(option => option.displayName === value)) {
        this.setFreeTextValue(value);
      }
    }
  }

  setInitValue(value: string) {
    this.initValue = value;
  }

  selectOption(option: SelectOption, event) {
    if (option.disabled) {
      event.stopPropagation();
      return;
    }

    this.autocompleteInput.setValue(option.displayName);
    this.fieldFormControl.setValue(option.value);
    this.onChanged(option.value);
  }

  inputFieldBlur() {
    this.inputBlurred.emit(this.fieldFormControl);
  }

  selectPanelOpened() {
    this.isSelectPanelOpened = true;

    setTimeout(() => {
      this.autocompleteInputField.nativeElement.focus();
    });

    this.heapService.trackUserSpecificAction(
      `heap-${this.fieldData.heapClass ? this.fieldData.heapClass : 'autocomplete'}-opened`
    );
  }

  selectPanelClosed() {
    this.isSelectPanelOpened = false;
    if (this.allowFreeText) {
      if (
        (this.autocompleteInput.value === '' || this.autocompleteInput.value === null) &&
        this.autocompleteInput.dirty
      ) {
        this.fieldFormControl.setValue(null);
        this.formControl.setValue(null);
      } else {
        if (
          this.autocompleteInput.value &&
          !this.fieldData.options?.find(option => option.value === this.autocompleteInput.value)
        ) {
          this.addFreeTextToOptions(this.autocompleteInput.value);
        }

        this.fieldFormControl.setValue(this.autocompleteInput.value);
        this.formControl.setValue(this.autocompleteInput.value);
      }
    } else {
      if (this.allowMultiSelect && !this.optionsQuery) {
        this.formControl.setValue(this.fieldFormControl.value);
      }
      // Currently we can clear the search input only for non free text and multi select
      this.autocompleteInput.setValue(null);
    }

    if (this.emitChangeOnPanelClose) this.onChanged(this.fieldFormControl.value);

    this.cdr.detectChanges();
  }

  closeSelectPanel() {
    this.autocompleteSelectPanel.close();
  }

  scrollToView(id: string) {
    if (!this.shouldScrollIntoView) return;
    document?.getElementById(id)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    //let scrolling to be finished:
    setTimeout(() => {
      this.autocompleteSelectPanel['wcOpen']();
      //waiting for rendering to be done for catching panel
      setTimeout(() => {
        const panel = document?.getElementsByClassName('cdk-overlay-pane')[0] as HTMLElement;
        if (panel) panel.style.width = '501px';
      });
    }, 500);
  }

  ngOnDestroy(): void {
    this.autocompleteSub?.unsubscribe();
    this.fieldFormControlSub?.unsubscribe();
  }
}
