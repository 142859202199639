<div class="onboarding-tours-component">
  <div
    data-cy-id="onboarding-tour-button"
    #menuTrigger="matMenuTrigger"
    class="onboarding-tours-component__main-button"
    [class.heap-desktop-onboarding-tours-button-header]="!buttonInsideUserMenu"
    [class.heap-desktop-onboarding-tours-button-user-menu]="buttonInsideUserMenu"
    [class.menu-button]="buttonInsideUserMenu"
    [class.menu-open]="isMenuOpen"
    (menuOpened)="isMenuOpen = true"
    (menuClosed)="isMenuOpen = false"
    [matMenuTriggerFor]="menu"
    [attr.aria-label]="'onboardingTours.mainButton.ariaLabel' | translate">
    <ng-container *ngIf="buttonInsideUserMenu; else regular">
      <wc-icon class="onboarding-tours-component__menu-button-icon" iconName="rocket-launch"></wc-icon>
      <span>{{ 'onboardingTours.title' | translate }}</span>
    </ng-container>

    <ng-template #regular>
      <button rekor-basic>
        <span class="onboarding-tours-component__button-icon">🎓</span>
        <div class="onboarding-tours-component__button-text">
          <span class="first-line line">
            {{ 'onboardingTours.mainButton.firstLine' | translate }}
          </span>
          <span class="second-line line">
            {{ 'onboardingTours.mainButton.secondLine' | translate: { amount: availableOnboardingTours.length } }}
          </span>
        </div>
      </button>
    </ng-template>
  </div>

  <mat-menu
    [class]="
      'onboarding-tours-component__menu' +
      (buttonInsideUserMenu && isMenuOpen ? ' onboarding-tours-component__menu-absolute' : '')
    "
    backdropClass="onboarding-tours-component__menu-backdrop"
    #menu="matMenu"
    yPosition="below"
    xPosition="before">
    <div class="onboarding-tours-component__menu-content" (click)="$event.stopPropagation()">
      <div class="onboarding-tours-component__menu-header">
        <button data-cy-id="onboarding-tour-panel-close-button" class="close-button" (click)="close()">
          <wc-icon iconName="close-new"></wc-icon>
        </button>
        <div data-cy-id="onboarding-tour-panel-title" class="title">
          {{ 'onboardingTours.welcome' | translate }}
        </div>
        <div data-cy-id="onboarding-tour-panel-text" class="text">
          {{ 'onboardingTours.text' | translate }}
        </div>
        <div data-cy-id="onboarding-tour-panel-img" class="quick-start-header-icon"></div>
      </div>

      <div class="onboarding-tours-component__content">
        <div data-cy-id="onboarding-tour-panel-completed-percent" class="onboarding-tours-component__scale-container">
          <span>{{ onboardingDonePercent | number: '1.0-0' }}% {{ 'onboardingTours.completed' | translate }}</span>

          <div class="scale-background">
            <div class="scale-percentage" [style.width]="onboardingDonePercent + '%'"></div>
          </div>
        </div>
        <ul class="onboarding-tours-component__tour-list">
          <ng-container *ngFor="let tourName of sortedOnboardingTours">
            <li class="onboarding-tours-component__tour-list-item">
              <div class="onboarding-tours-component__tour-details-container">
                <wc-icon class="onboarding-tours-component__tour-icon" [iconName]="icons[tourName]"></wc-icon>
                <div class="onboarding-tours-component__tour-details">
                  <span [attr.data-cy-id]="tourName | lowercase">
                    {{ 'onboardingTours.onboardingTours.' + tourName | translate }}
                  </span>
                  <span
                    data-cy-id="tour-done-mark"
                    *ngIf="!availableOnboardingTours.includes(tourName); else duration"
                    class="onboarding-tours-component__done-mark">
                    <wc-icon iconName="check"></wc-icon>
                    {{ 'done' | translate }}
                  </span>
                  <ng-template #duration>
                    <span data-cy-id="tour-duration" class="onboarding-tours-component__duration">
                      {{ 'onboardingTours.duration' | translate: { amount: 2 } }}
                    </span>
                  </ng-template>
                </div>
              </div>
              <button
                [attr.data-cy-id]="tourName + '-open-button' | lowercase"
                mat-menu-item
                [class.play-button]="availableOnboardingTours.includes(tourName)"
                [class.replay-button]="!availableOnboardingTours.includes(tourName)"
                (click)="startOnboardingTour(tourName)"
                class="start-button">
                <wc-icon [iconName]="availableOnboardingTours.includes(tourName) ? 'play' : 'repeat'"></wc-icon>
                {{ (availableOnboardingTours.includes(tourName) ? 'start' : 'onboardingTours.replay') | translate }}
              </button>
            </li>
            <div
              class="onboarding-tours-component__feedback"
              *ngIf="sentFeedbacks[tourName] === null && !availableOnboardingTours.includes(tourName)">
              <wc-feedback
                [attr.data-cy-id]="tourName + '-feedback' | lowercase"
                [feedbackFeatureType]="$any(tourName)"
                [received]="sentFeedbacks[tourName]"
                [clientSideFeedback]="true"
                [serverSideFeedback]="false"
                (feedbackSend)="onFeedbackSent($event, tourName)"></wc-feedback>
            </div>
          </ng-container>
        </ul>
      </div>
    </div>
  </mat-menu>
</div>
