import { Inject, Injectable } from '@angular/core';
import olms from 'ol-mapbox-style';
import { Layer } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import VectorTileLayer from 'ol/layer/VectorTile';
import * as olMap from 'ol/Map';
import { Source } from 'ol/source';
import { concat, defer, from, Observable, of, ReplaySubject } from 'rxjs';
import { map, reduce, shareReplay, tap } from 'rxjs/operators';
import {
  mapVendorName,
  mapViewerEnvConfig,
  StaticLayerName,
  StaticLayerOptionNames,
  StaticLayerOptions,
  wcCoordinateTypes,
  WcMapConfigModel,
} from '../..';
import { defaultStaticLayerConfigs } from '../../config';
import { mapViewerConfigToken, mapViewerEnvConfigToken, wcMapToken } from '../../injection-tokens';
import { mapboxglLayer } from '../layer-utils/mapboxgl-layer';
import { satelliteLayer } from '../layer-utils/satellite-layer';
import { trafficLayer } from '../layer-utils/trafiic-layer';

type LayerOptionToFunctionCall = {
  [K in StaticLayerName]?: (...args: any) => any;
};

@Injectable({
  providedIn: 'root',
})
export class StaticLayersService {
  getOrCreateMilemarkerLayers$!: Observable<BaseLayer[]>;
  private layerBeingLoaded = new ReplaySubject<StaticLayerOptionNames>(1);
  readonly layerBeingLoaded$ = this.layerBeingLoaded.asObservable();

  vectorMapBaseLayer$!: Observable<Layer | BaseLayer>;
  getOrCreateSatelliteLayers$!: Observable<Layer | BaseLayer | Layer[]>;
  getOrCreateTrafficLayer$!: Observable<Layer | BaseLayer>;
  private readonly _staticLayersMap = new Map<string, Layer | BaseLayer>();
  private readonly _staticLayerFactories = new Map<
    StaticLayerOptionNames,
    Observable<BaseLayer | Layer<Source> | Layer<Source>[]>
  >();
  private readonly layerOptionToFunctionCall: LayerOptionToFunctionCall = {
    satelliteMap: v => this.createSatelliteLayerFactory(v),
    mapBoxTraffic: v => this.createTrafficLayerFactory(v),
    mileMarkers: v => this.createMileMarkerLayersFactory(v),
    vectorMap: v => this.createVectorBaseLayerFactory(v),
  };

  constructor(
    @Inject(wcMapToken) private _map: olMap.default,
    @Inject(mapViewerEnvConfigToken) private envConfig: mapViewerEnvConfig,
    @Inject(mapViewerConfigToken) private mapConfig: WcMapConfigModel<string, wcCoordinateTypes, string>
  ) {
    this.createStaticLayerFactories();
  }

  getStaticLayerFactory$<TName extends StaticLayerOptionNames>(name: TName) {
    const layerFactory$ = this._staticLayerFactories.get(name);
    if (layerFactory$) {
      return layerFactory$;
    }
    throw new Error(`could not find observable with name:${name}`);
  }

  initStaticLayersFactory(staticLayerNames: StaticLayerOptionNames[] | undefined | null): void {
    // sometimes we want to fetch data, and add the layer to the map, not by click of a button.
    // thats the way to do it:
    // when subscribe, the lazy observable will kick in, and run the defer function
    const _staticLayerConfigs = staticLayerNames || defaultStaticLayerConfigs;
    _staticLayerConfigs.forEach(name => {
      if (this._staticLayerFactories.has(name)) {
        this._staticLayerFactories.get(name)?.subscribe();
      }
    });
  }

  private createStaticLayerFactories() {
    this.envConfig.staticLayersPerVendor?.forEach(staticLayerOption => {
      this._staticLayerFactories.set(
        staticLayerOption.name,
        this.layerOptionToFunctionCall[staticLayerOption.type]?.(staticLayerOption)
      );
    });
  }

  private createVectorBaseLayerFactory(
    staticLayerOption: Extract<StaticLayerOptions, { type: StaticLayerName.vectorMap }>
  ) {
    return defer(() =>
      of(
        mapboxglLayer(
          this._map.getTarget() as HTMLElement,
          this.mapConfig.mapTheme.mapThemeUrl,
          staticLayerOption.mapboxKey
        )
      )
    ).pipe(this.handleNewLayers(-1, staticLayerOption), shareReplay(1));
  }

  private createMileMarkerLayersFactory(
    staticLayerOption: Extract<StaticLayerOptions, { type: StaticLayerName.mileMarkers }>
  ) {
    return from<Observable<olMap.default>>(olms(new olMap.default({}), staticLayerOption.url) as any).pipe(
      map(map => map.getLayers().getArray()),
      this.handleNewLayers(4, staticLayerOption),
      shareReplay(1)
    );
  }

  private createTrafficLayerFactory(
    staticLayerOption: Extract<StaticLayerOptions, { type: StaticLayerName.mapBoxTraffic }>
  ) {
    return defer(() => of(trafficLayer(staticLayerOption.mapboxKey))).pipe(
      this.handleNewLayers(3, staticLayerOption),
      shareReplay(1)
    );
  }

  private createSatelliteLayerFactory(
    staticLayerOption: Extract<StaticLayerOptions, { type: StaticLayerName.satelliteMap }>
  ) {
    return defer(() => concat(this.getSatelliteLayers(staticLayerOption))).pipe(
      reduce((acc, curr) => {
        acc.push(curr);
        return acc;
      }, [] as Layer<Source>[]),
      this.handleNewLayers(2, staticLayerOption),
      shareReplay(1)
    );
  }

  private handleNewLayers(zIndex: number, staticLayerOption: StaticLayerOptions) {
    return tap((layers: Layer<Source>[] | Layer<Source> | VectorTileLayer | BaseLayer[]) => {
      if (!Array.isArray(layers)) {
        layers = [layers];
      }
      layers.forEach((layer, idx) => {
        layer.setZIndex(zIndex);
        this.removeStaticLayerFromMap(staticLayerOption.name + idx);
        this.addStaticLayerToMap(layer, staticLayerOption.name + idx, 2);
      });
      this.layerBeingLoaded.next(staticLayerOption.name);
    });
  }

  private addStaticLayerToMap(layer: Layer | BaseLayer, name: string, index?: number) {
    if (layer) {
      layer.set('name', name);
      this._map.getLayers().insertAt(index || 0, layer);
      this._staticLayersMap.set(name, layer);
    }
  }

  private removeStaticLayerFromMap(name: string): void {
    const layer = this._staticLayersMap.get(name);
    if (layer) {
      this._map.removeLayer(layer);
    }
  }

  private getSatelliteLayers(staticLayerOption: Extract<StaticLayerOptions, { type: StaticLayerName.satelliteMap }>) {
    const isAwsType = (option: StaticLayerOptions): option is Extract<StaticLayerOptions, { name: 'satelliteAWS' }> => {
      return option.type === StaticLayerName.satelliteMap && option.vendorName === mapVendorName.AWS;
    };
    const observables = [
      satelliteLayer(staticLayerOption.vendorName, staticLayerOption.satelliteApiKey, this._map, this.envConfig),
    ];
    if (isAwsType(staticLayerOption)) {
      //when layers have the same zIndex, the first who is added to the map, will be always on top of the rest, and so fourth.
      observables.unshift(
        mapboxglLayer(this._map.getTarget() as HTMLElement, staticLayerOption.styleUrl, staticLayerOption.mapboxKey)
      );
    }
    return observables;
  }
}
