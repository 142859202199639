import { ChangeDetectorRef, Injectable } from '@angular/core';
import { CustomRxOperatorsService } from '@wc/core/services/custom-rx-operators.service';
import { Query } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class AutocompleteFetchService {
  isBelowThreshold = true;
  isFetching = false;
  constructor(private readonly customOperators: CustomRxOperatorsService) {}

  getFilteredOptionsObservable(
    autocompleteValueChanges: Observable<string>,
    dataKey: string,
    minSubstringLength: number,
    cdr: ChangeDetectorRef,
    query?: Query
  ) {
    if (!query) {
      throw new Error('query is required');
    }
    if (!dataKey) {
      throw new Error('dataKey is required');
    }

    return autocompleteValueChanges.pipe(
      tap(value => {
        this.isBelowThreshold = value.length < minSubstringLength;
        this.setIsFetching(true);
        cdr.markForCheck();
      }),
      switchMap(value =>
        this.isBelowThreshold
          ? of([])
          : query.fetch({ subString: value }).pipe(
              this.customOperators.catchGqlErrors(),
              map(results => results.data[dataKey])
            )
      ),
      tap(() => {
        this.setIsFetching(false);
        cdr.markForCheck();
      })
    );
  }

  private setIsFetching(isFetching: boolean) {
    this.isFetching = isFetching;
  }
}
