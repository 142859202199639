import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { WcIconModule } from '@wc/wc-ui/src';
import { AutocompleteModule } from '@wc/wc-ui/src/components/autocomplete/autocomplete.module';
import { SliderToggleControlModule } from '@wc/wc-ui/src/components/slide-toggle-control/slider-toggle-control.module';
import { WcTooltipModule } from '@wc/wc-ui/src/components/tooltip/tooltip.module';
import { WcSelectControlModule as WcSelectControlModuleOld } from '@wc/wc-ui/src/form-components/select/select.module';
import { WcSelectModule } from '@wc/wc-ui/src/form-components/wc-select/wc-select.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { WcBaseControlFieldModule } from '../base/base-control-field.module';
import { AffectedLanesPipesModule } from '../pipes/affected-lanes.pipes.module';
import { AffectedLanesDiagramComponent } from './affected-lanes-diagram/affected-lanes-diagram.component';
import { AffectedLanesSliderComponent } from './affected-lanes-slider/affected-lanes-slider.component';
import { AffectedLanesViewComponent } from './affected-lanes-view/affected-lanes-view.component';
import { AffectedLanesComponent } from './affected-lanes/affected-lanes.component';

const Materials: any[] = [MatIconModule, MatMenuModule, MatCheckboxModule, MatButtonModule];

@NgModule({
  declarations: [
    AffectedLanesComponent,
    AffectedLanesDiagramComponent,
    AffectedLanesSliderComponent,
    AffectedLanesViewComponent,
  ],
  imports: [
    CommonModule,
    SwiperModule,
    WcBaseControlFieldModule,
    TranslateModule,
    Materials,
    WcSelectControlModuleOld,
    SliderToggleControlModule,
    AffectedLanesPipesModule,
    AutocompleteModule,
    WcTooltipModule,
    WcIconModule,
    WcSelectModule,
  ],
  providers: [],
  exports: [
    AffectedLanesComponent,
    AffectedLanesDiagramComponent,
    AffectedLanesSliderComponent,
    AffectedLanesViewComponent,
  ],
})
export class AffectedLanesFormFieldComponentsModule {}
