/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { EntityActions, EntityUIQuery, QueryEntity } from '@datorama/akita';
import { ModifiedEntities, TransitBusStoreEntity, TransitLayerType } from '@wc/wc-models/src';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { TransitBusStopsState, TransitBusStopsStore, TransitBusStopsUIState } from './transit_bus_stops.store';
import { TransitRoutesQuery } from './transit_routes.query';

@Injectable({ providedIn: 'root' })
export class TransitBusStopsQuery extends QueryEntity<TransitBusStopsState> {
  ui!: EntityUIQuery<TransitBusStopsUIState>;
  constructor(store: TransitBusStopsStore, private transitRoutesQuery: TransitRoutesQuery) {
    super(store);
    this.createUIQuery();
  }

  get modifiedBusStops$(): Observable<ModifiedEntities> {
    return this.selectEntityAction([EntityActions.Update, EntityActions.Add]).pipe(
      map(({ ids }) => ids.map(id => this.getEntity(id)).filter(e => !!e) as TransitBusStoreEntity[]),
      map(busStops => ({
        [TransitLayerType.TransitBusStop]: busStops,
      }))
    );
  }

  get busStopsAddedQuery$() {
    return this.selectEntityAction([EntityActions.Add]).pipe(debounceTime(100));
  }
}
