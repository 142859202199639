import { Component, Input, OnInit } from '@angular/core';
import {
  DateFormat,
  DMS,
  Language,
  MeasureUnitType,
  RegionalSetting,
  TimeFormat,
  TimeZone,
  WindowService,
} from '@wc/core';
import { UiStore } from '@wc/core/stores/ui.store';
import { Day } from '@wc/work-schedule-configuration/domain/src';
import { Observable } from 'rxjs';
import { AccountService } from "@wc-core";

@Component({
  selector: 'wc-dms-modal',
  templateUrl: './dms-modal.component.html',
  styleUrls: ['./dms-modal.component.scss'],
})
export class DmsModalComponent implements OnInit {
  @Input() set dms(dmsData: DMS) {
    this.dmsData = dmsData;
    this.checkDmsMessagesLength();
  }

  isTabletMode!: boolean;
  online$: Observable<boolean>;
  showEmptyState!: boolean;
  dmsData!: DMS;
  regionalSettings: RegionalSetting = {
    firstDayOfWeek: Day.Sunday,
    dateFormat: DateFormat.DdMmmYyyy,
    timeFormat: TimeFormat.TwentyFourHours,
    language: Language.En,
    measureUnitType: MeasureUnitType.Imperial,
    timeZone: TimeZone.UsCentral
  };

  constructor(
    private uiStore: UiStore,
    private accountService: AccountService,
    private windowService: WindowService
  ) {
    this.online$ = this.windowService.online$;
  }

  ngOnInit(): void {
    this.isTabletMode = this.uiStore.isTabletMode;
    this.checkDmsMessagesLength();
    this.regionalSettings = this.accountService.account?.regionalSetting;
  }

  checkDmsMessagesLength() {
    for (let i = 0; i < this.dmsData.messages?.length; i++) {
      for (let j = 0; j < this.dmsData.messages[i].length; j++) {
        if (this.dmsData.messages[i][j].length) {
          this.showEmptyState = false;
          return;
        }
      }
    }
    this.showEmptyState = true;
  }
}
