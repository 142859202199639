import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { HeapAnalyticsService } from '@wc-core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { WCErrorStateMatcher } from '../../../../features/ui/base/error-state-matcher';
import { IconClassName } from '../../../../wc-models/src/lib/types/icon-class-names-type';
import { CustomFormControlComponent } from '../../lib/base/custom-form-control';

@Component({
  selector: 'wc-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TextFieldComponent extends CustomFormControlComponent implements OnInit {
  @Input() clearButton = false;
  @Input() viewCharacterCounter = false;
  @Input() prefixIcon: IconClassName = '';
  @Input() suffixIcon: IconClassName = '';
  @Input() suffixTooltipText: string | null = null;
  @Input() disableSuffixButton = false;
  @Input() customValidationMessage: string | null = null;
  @Input() showLabel = true;
  @Input() inputType: 'text' | 'number' = 'text';
  @Input() transformToUpperCase = false;
  @Input() readOnly = false;
  @Input() rtlDirection = false;

  @Output() suffixIconButtonClicked = new EventEmitter<MouseEvent>();

  matcher = new WCErrorStateMatcher();
  maxLength = 0;
  readonly heapSubject$ = new Subject<string>();

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    cdr: ChangeDetectorRef,
    private heapService: HeapAnalyticsService
  ) {
    super(ngControl, cdr);
    this.heapSubject$
      .pipe(debounceTime(100))
      .subscribe((value: string) =>
        this.heapService.trackUserSpecificAction(
          `heap-${this.formFieldData?.heapClass ? this.formFieldData?.heapClass : 'text-field'}-changed`,
          { value: value }
        )
      );
  }

  ngOnInit(): void {
    if (!this.ngControl.value) {
      this.ngControl.control?.setValue(null);
    }

    if (this.viewCharacterCounter) {
      const err = this.ngControl.control?.validator
        ? this.ngControl.control.validator(new FormControl('.'.repeat(1000)))
        : null;

      if (err && err.maxlength && err.maxlength.requiredLength && err.maxlength.requiredLength > 0)
        this.maxLength = err.maxlength.requiredLength;
    }
  }

  checkValue(e: KeyboardEvent) {
    if (this.inputType === 'number' && (e.key === '+' || e.key === '-' || e.key === 'e' || e.key === 'E')) {
      e.preventDefault();
    }
  }

  inputChanged(value) {
    this.onChanged(this.transformToUpperCase ? value.toUpperCase() : value);
    this.heapSubject$.next(value);
  }
}
