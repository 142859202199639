<div class="insights-panel-component">
  <mat-expansion-panel
    id="insightExpansionPanel"
    class="incident-section"
    [expanded]="
      ((incidentInsightValue?.persistenceScore || incidentInsightValue?.priorityScore) && showPersistenceAndPriority) ||
      !noImpactData
    ">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <wc-icon class="section-icon purple" iconName="stars"></wc-icon
        ><span class="purple"> {{ 'insight.insightsLowerCase' | translate }} ({{ visibleCorridors.length }}) </span>
        <span *ngIf="incidentInsightValue?.persistenceScore || incidentInsightValue?.impact" class="xs-font">
          {{ 'insight.updateEveryTwoMin' | translate }}
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container *ngIf="incidentInsightValue">
      <ng-container *ngIf="priorityFeatureOn | async">
        <div *ngIf="incidentInsightValue.priorityScore" class="insights-panel-component__section-container">
          <mat-expansion-panel class="insight-section insight-section__priority" [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="dark-title"> {{ 'insight.priorityLevel' | translate }}</span>
                <wc-priority-score
                  [textMode]="'short'"
                  [priorityScoreLevel]="incidentInsightValue.priorityScore.level"
                  [disableTooltip]="true"></wc-priority-score>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="insights-panel-component__priority-body">
              <span> {{ 'insight.heavilyInfluencedBy' | translate }}</span>
              <wc-icon
                wcTooltipPlacement="top"
                [wcTooltipText]="'insight.influencedByTooltip' | translate"
                iconName="info-circle"></wc-icon>

              <div class="insights-panel-component__priority-chips-container">
                <span *ngFor="let level of incidentInsightValue.priorityScore.heavyInfluencedBy">
                  {{ 'insight.priorityInfluence.' + level | translate }}
                </span>
              </div>
            </div>
            <div
              *ngIf="feedbackPriorityScoreDone !== true"
              class="insights-panel-component__feedback-container insights-panel-component__feedback-container--mb">
              <wc-feedback
                class="persistence-feedback"
                [received]="null"
                [feedbackFeatureType]="feedbackTypeEnum.IncidentPriorityScore"
                [externalId]="externalId"
                [entityId]="_entityId"
                [clientSideFeedback]="true"
                (feedbackSend)="updateFeedbackPriority()"
                [entityType]="entityType"></wc-feedback>
            </div>
          </mat-expansion-panel>
        </div>
      </ng-container>
      <div
        *ngIf="incidentInsightValue.persistenceScore && showPersistenceAndPriority"
        class="insights-panel-component__section-container">
        <mat-expansion-panel class="insight-section" [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="dark-title"> {{ 'insight.persistenceScore' | translate }}</span>
              <wc-persistence-score
                [textMode]="'short'"
                [persistenceScoreType]="incidentInsightValue.persistenceScore.type"
                [disableTooltip]="true"></wc-persistence-score>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="insights-panel-component__persistence-info-body">
            {{ 'insight.persistenceCard.body' | translate }}
          </div>
          <div class="insights-panel-component__persistence-scale">
            <div
              [class]="
                'insights-panel-component__persistence-scale__color insights-panel-component__persistence-scale__color--' +
                  incidentInsightValue.persistenceScore.type | lowercase
              "></div>
          </div>
          <div class="insights-panel-component__persistence-scale__range-names">
            <div>{{ 'insight.persistence.VERY_LOW' | translate }}</div>
            <div>{{ 'insight.persistence.HIGH' | translate }}</div>
          </div>

          <div
            *ngIf="hasIncidentUpdatePermission"
            class="insights-panel-component__feedback-container insights-panel-component__feedback-container--mb">
            <wc-feedback
              class="persistence-feedback"
              [received]="incidentInsightValue.persistenceScore.userFeedback"
              [feedbackFeatureType]="feedbackTypeEnum.IncidentInsightPersistenceScore"
              [externalId]="externalId"
              [entityId]="_entityId"
              (feedbackSend)="updateFeedbackPersistenceScore($event)"
              [entityType]="entityType"></wc-feedback>
          </div>
        </mat-expansion-panel>
      </div>

      <div *ngIf="showPanel" class="insights-panel-component__section-container">
        <mat-expansion-panel
          #insightPanel
          (opened)="toggleShowImpactOnMap(true)"
          (closed)="toggleShowImpactOnMap(false)"
          class="insight-section"
          [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="dark-title"> {{ 'insight.trafficImpact' | translate }}</span>

              <div *ngIf="!noImpactData" class="insights-panel-component__impact-info">
                <div class="insight-badge" [ngClass]="'insight-badge--' + incidentInsightValue.impact?.level">
                  {{ 'insight.' + incidentInsightValue.impact?.level | translate }}
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div
            [wcTooltipText]="'insight.noRealTimeDataTooltip' | translate"
            [wcTooltipDisabled]="(incidentInsightValue.impact?.updatedAt | timeElapsed) < 7"
            wcTooltipPlacement="right"
            class="insights-panel-component__segment-updated-time-ago">
            <i class="icon-last-update"></i>
            {{ 'lastUpdate' | translate }}: {{ incidentInsightValue.impact?.updatedAt | timeElapsed }}
            {{ 'timeUnits.minutesAgo' | translate }}
          </div>
          <ng-container *ngIf="(hoveredMapSegmentId$ | async) || 'none' as hoveredName">
            <ng-container *ngFor="let corridor of visibleCorridors; let i = index">
              <div
                (mouseover)="corridorHovered(corridor)"
                (mouseleave)="corridorHovered(undefined)"
                [class.hover]="corridor.name === hoveredName"
                class="insights-panel-component__segment-container">
                <div class="insights-panel-component__segment-title">
                  {{ corridor.name }}
                </div>

                <div class="insights-panel-component__segment-impact-list">
                  <div class="insights-panel-component__impact-prop">
                    <span class="insights-panel-component__impact-prop-title">
                      {{ 'insight.expectedSpeed' | translate }}</span
                    >
                    <span>
                      <i class="fa-regular fa-rotate-exclamation"></i>
                      {{ corridor.expectedSpd * 1000 | distanceFormat: true:0 }}
                    </span>
                  </div>
                  <div class="insights-panel-component__impact-prop--divider"></div>
                  <div class="insights-panel-component__impact-prop">
                    <span class="insights-panel-component__impact-prop-title">
                      {{ 'insight.currentSpeed' | translate }}</span
                    >
                    <span>
                      <wc-status-circle [fillColor]="corridorColors[i]"></wc-status-circle>
                      {{ corridor.currentSpd * 1000 | distanceFormat: true:0 }}
                      <span class="insights-panel-component__impact-prop__below-expected">
                        ({{ 100 - (corridor.currentSpd / corridor.expectedSpd) * 100 | number: '1.0-0' }}%
                        {{ 'insight.belowExpected' | translate }})
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <div class="insights-panel-component__feedback-container">
            <wc-feedback
              [received]="incidentInsightValue.impact ? incidentInsightValue.impact.usersFeedback : null"
              [feedbackFeatureType]="feedbackTypeEnum.IncidentInsightImpact"
              [externalId]="externalId"
              [entityId]="_entityId"
              (feedbackSend)="updateFeedbackImpact($event)"
              [entityType]="entityType"></wc-feedback>
          </div>
        </mat-expansion-panel>
      </div>
    </ng-container>
    <ng-container *ngIf="isShowEmptyState">
      <div class="insights-panel-component__empty-state">
        <i class="empty-incident-insights-icon"></i>
        <div class="insights-panel-component__empty-state--title">{{ 'insight.empty.title' | translate }}</div>
        <div class="insights-panel-component__empty-state--content">{{ 'insight.empty.content' | translate }}</div>
      </div>
    </ng-container>
  </mat-expansion-panel>
</div>
