<div
  *wcLet="activeCrashRisk | async as activeCrashRisk"
  wcPopupCloser
  (closed)="closePanel()"
  class="rf-crash-risk-panel heap-desktop-map-layer-crash-risk-btn"
  [class.language-hebrew]="isHebrew"
  [class.portrait-mode]="isPortraitDesktopMode">
  <div class="rf-crash-risk-panel__container" [class.language-rtl]="isDirectionRtl">
    <div class="rf-crash-risk-panel__header">
      <span class="rf-crash-risk-panel__title">
        {{ 'crashRisk' | translate }}
      </span>

      <div class="rf-crash-risk-panel__close" (click)="closePanel()">
        <i class="far fa-times fa-lg close-btn"></i>
      </div>
    </div>

    <div class="rf-crash-risk-panel__filters">
      <mat-checkbox class="rf-checkbox-label" [checked]="isShowOnMap" (change)="toggleShowOnMap($event)">
      </mat-checkbox>
      <span class="cp-checkbox-label">{{ 'showOnMap' | translate }}:</span>

      <mat-select
        wcFocusStyles
        [disabled]="!isShowOnMap"
        [(ngModel)]="selectedMapFilter"
        [panelClass]="{
          'sort-select-dropdown': true,
          'language-rtl': isDirectionRtl
        }"
        (selectionChange)="eventsStatusFilterChange($event)">
        <mat-option *ngFor="let filterItem of crashRiskOptions" ngDefaultControl [value]="filterItem">
          {{ 'crashRisks.alertTypes.' + filterItem | translate }}
        </mat-option>
      </mat-select>
    </div>

    <div class="rf-crash-risk-panel__body" #container>
      <div class="rf-crash-risk-panel__timeline-container" *ngIf="crashRiskAmount > 0; else emptyCrashRisks">
        <div
          *ngFor="let timeFrameRisk of timeFrameCrashRisks; trackBy: trackById; let i = index"
          class="rf-crash-risk-panel__timeline-block timeline-block-right"
          [class.rf-crash-risk-panel__current]="i === 0">
          <div class="rf-crash-risk-panel__timeline-content">
            <div class="rf-crash-risk-panel__datetime">
              <div
                *ngIf="
                  i === 0 ||
                  moment(timeFrameRisk.time).hours() === 00 ||
                  moment(timeFrameRisk.time).hours() === 01 ||
                  moment(timeFrameRisk.time).hours() === 02
                "
                class="rf-crash-risk-panel__timeline-date">
                {{ timeFrameRisk.time | dateFormat: regionalSetting:'mediumDateNoComma' }}
              </div>
              <div *ngIf="i === 0" class="rf-crash-risk-panel__prediction-hour">
                {{ timeFrameRisk.time | timeFormatMode: regionalSetting.timeFormat }} ({{ 'current' | translate }})
              </div>
              <div
                *ngIf="i !== 0"
                class="rf-crash-risk-panel__prediction-hour-grey"
                [class.non-absolute-hour]="
                  moment(timeFrameRisk.time).hours() === 00 ||
                  moment(timeFrameRisk.time).hours() === 01 ||
                  moment(timeFrameRisk.time).hours() === 02
                ">
                {{ timeFrameRisk.time | timeFormatMode: regionalSetting.timeFormat }}
              </div>
            </div>

            <ng-container *ngIf="uiCrashRisksViewedStateObj$ | async as viewedObj">
              <button
                *ngFor="let risk of timeFrameRisk.crashRisks"
                class="rf-crash-risk-panel__prediction-card"
                [class.highlighted-alert-card]="activeCrashRisk === risk.id"
                [id]="risk.id"
                (click)="clickOnCrashRisk(risk)">
                <span *ngIf="!viewedObj[risk.id]" class="rf-crash-risk-panel__new-badge">New</span>

                <div class="rf-crash-risk-panel__prediction-data">
                  <span>
                    <wc-icon class="rf-crash-risk-panel__crash_risk_icon" [iconName]="'crash-risk'"></wc-icon>
                  </span>
                  <div class="rf-crash-risk-panel__prediction-data-right">
                    <div class="rf-crash-risk-panel__prediction-title-container">
                      <div class="prediction-crash-risk-title">
                        {{ 'crashRisk' | translate }}
                      </div>

                      <span class="prediction-status">
                        <span class="crash-risk-status" [ngClass]="'status-' + risk.status | lowercase">
                          {{ risk.status | lowercase | translate }}
                          <i class="crash-risk-status-icon" [ngClass]="'icon-' + risk.status | lowercase"></i>
                        </span>
                      </span>
                    </div>
                    <div
                      *ngIf="risk.startAddress?.corridor || risk.startAddress?.crossroad; else missingAddress"
                      class="rf-crash-risk-panel__prediction-address">
                      {{ risk.startAddress?.corridor }} {{ risk.startAddress?.direction }}
                      {{ risk.startAddress?.orientation | lowercase }}
                      {{ risk.startAddress?.crossroad }}
                    </div>

                    <ng-template #missingAddress>
                      <div class="rf-crash-risk-panel__prediction-address">
                        {{ 'missingStartAddress' | translate }}
                      </div>
                    </ng-template>

                    <span class="rf-crash-risk-panel__prediction-time">
                      {{ risk.startTime | timeFormatMode: regionalSetting.timeFormat }} -
                      {{ risk.endTime | timeFormatMode: regionalSetting.timeFormat }},
                      {{ risk.startTime | dateFormat: regionalSetting:'mediumDateNoComma' }}
                    </span>
                  </div>
                </div>
              </button>
            </ng-container>
          </div>
        </div>
      </div>

      <ng-template #emptyCrashRisks>
        <div class="rf-crash-risk-panel__empty-crash-risks">
          <img src="assets/images/media_empty_state.svg" />
          <span class="rf-crash-risk-panel__empty-state-title">
            {{ 'crashRisks.noAlerts' | translate }}
          </span>
        </div>
      </ng-template>
    </div>
  </div>
</div>
