import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UISharedModule } from '@wc/features';
import { MobxAngularModule } from 'mobx-angular';
import { MaterialModule } from '../../material-module';
import { AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective } from './accordion';
import { AlertComponent } from './alert/alert.component';
import { SettingsMenuModule } from './settings-menu/settings-menu.module';
import { SpinnerComponent } from './spinner.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MobxAngularModule,
    FlexLayoutModule,
    UISharedModule,
    SettingsMenuModule,
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent,
    AlertComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MobxAngularModule,
    FlexLayoutModule,
    UISharedModule,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent,
    AlertComponent,
    SettingsMenuModule,
  ],
  entryComponents: [AlertComponent],
})
export class SharedModule {}
