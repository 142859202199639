import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  FeedSource,
  MeasureUnitType,
  RegionalSetting,
  RoadwayStatusLaneMetricDto,
  RoadwayStatusTransportMetricsDto,
  TransportType,
} from '@wc/core/models/gql.models';
import { cloneDeep } from 'lodash';
import { RoadwayStatusStoreEntity } from '../../../../../../wc-models/src/lib/types/entities';
import { MergedMetricVendorData } from '../../../../../../wc-models/src/lib/types/roadway-status';
import { ContextTypesEnum } from '../../../clustering-tooltip-container/clustering-tooltip-container.component';
import { HorizontalBarChartData } from '../../../horizontal-bar-charts/horizontal-bar-charts.component';
import { SegmentDataViewMode } from '../../internal-components/segment-data/segment-data.component';

type HorizontalVehicleCountBarChart = HorizontalBarChartData<TransportType>;

@Component({
  selector: 'wc-segment-details-tab',
  templateUrl: './segment-details-tab.component.html',
  styleUrls: ['./segment-details-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentDetailsTabComponent {
  @Input() measureUnitType: MeasureUnitType = MeasureUnitType.Metric;
  @Input() mode: SegmentDataViewMode | null = SegmentDataViewMode.Extended;
  @Input() context: ContextTypesEnum | null = ContextTypesEnum.Select;
  @Input() regionalSettings!: RegionalSetting;
  @Input() isPortraitDesktopMode = false;
  @Input() selectedVendorId: number | undefined;
  @Input() set segmentDetails(segmentDetails: RoadwayStatusStoreEntity | undefined | null) {
    this.segment = segmentDetails;
    this.setSelectedMetricDataById(this.selectedVendorId);
    this.feedSources = this.segment?.vendorMetrics?.map(vendor => vendor.vendorName);
    this.scrollToTop();
  }

  @Output() backButtonClicked: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() hidePanel = new EventEmitter<boolean>();
  barChartData: HorizontalVehicleCountBarChart = [];
  segment: RoadwayStatusStoreEntity | undefined | null;
  feedSources: Array<FeedSource> | undefined = [];
  selectedMetric: Partial<MergedMetricVendorData> | undefined;
  busesData: RoadwayStatusTransportMetricsDto | undefined;

  constructor(private translateService: TranslateService) {}

  setSelectedMetricDataById(vendorId?: number) {
    if (!vendorId) vendorId = this.segment?.defaultVendorId as number;
    this.selectedMetric = cloneDeep(this.segment?.vendorMetrics?.find(metric => metric.vendorId === vendorId));
    if (this.selectedMetric && Array.isArray(this.selectedMetric.lanesData)) {
      this.selectedMetric.lanesData = this.filterOutEmptyValues(this.selectedMetric.lanesData).sort(
        (a, b) => a.type.length - b.type.length
      );
    }
    this.busesData = this.selectedMetric?.transportsData?.find(data => data.type === TransportType.Bus);
    if (this.selectedMetric && Array.isArray(this.selectedMetric?.transportsData)) {
      this.barChartData = this.createBarChartData(this.selectedMetric.transportsData);
    }
  }

  goBackToSegmentsList() {
    this.backButtonClicked.emit();
    this.selectedMetric = undefined;
  }

  closePanel() {
    this.hidePanel.emit(true);
  }

  updateFeedSource(vendorName: FeedSource) {
    const selectedVendorId = this.segment?.vendorMetrics?.find(vendor => vendor.vendorName === vendorName)?.vendorId;
    this.setSelectedMetricDataById(selectedVendorId);
  }

  // Empty values are values who have only id and type, which will never be null
  private filterOutEmptyValues(values: RoadwayStatusLaneMetricDto[]): RoadwayStatusLaneMetricDto[] {
    return values.filter(item => {
      return Object.values(item).filter(v => v !== null).length > 2;
    });
  }

  private createBarChartData(values: RoadwayStatusTransportMetricsDto[]): HorizontalVehicleCountBarChart {
    return values
      .map(({ type, count }) => ({
        name: this.translateService.instant(`roadwayStatus.transportTypes.${type}`),
        value: count || 0,
      }))
      .sort((a, b) => {
        if (a.name === 'Other') {
          return 1;
        }
        if (b.name === 'Other') {
          return -1;
        }
        return b.name.length - a.name.length;
      });
  }

  scrollToTop() {
    const segmentDetailsBody = document.getElementById('segmentDetailsBody');
    if (segmentDetailsBody) {
      segmentDetailsBody.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }
}
