import { AbstractControl } from '@angular/forms';
import { Point, LineString, Polygon } from '../../../core/models';

export function LocationRequiredValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const location: Point | LineString | Polygon = control.value;
  const coords = location ? !!location.coordinates : false;
  if (control.value !== null && location && location.type && coords) {
    return null;
  }
  return { locationValidator: true };
}
