import { Injectable } from '@angular/core';
import { CustomRxOperatorsService } from '@wc-core';
import { GetWorkingHoursGQL, UpdateWorkingHoursConfigurationGQL, UpdateWorkingHoursInput } from '@wc/core';

@Injectable({
  providedIn: 'root',
})
export class WorkScheduleConfigurationApiService {
  constructor(
    private readonly getWorkingHoursGql: GetWorkingHoursGQL,
    private readonly updateWorkingHoursGql: UpdateWorkingHoursConfigurationGQL,
    private readonly customOperators: CustomRxOperatorsService
  ) {}

  getWorkingHours() {
    return this.getWorkingHoursGql.fetch().pipe(this.customOperators.catchGqlErrors());
  }

  updateWorkingHours(input: UpdateWorkingHoursInput) {
    return this.updateWorkingHoursGql.mutate({ input }).pipe(this.customOperators.catchGqlErrors());
  }
}
