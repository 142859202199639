import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  CreateMediaAccessedLogInput,
  CreateMediaAccessLogInput,
  LogMediaAccessedGQL,
  LogMediaAccessGQL,
  RenewIncidentMediaUrlGQL,
  RenewTrafficDisruptionMediaUrlGQL,
} from '../models/gql.models';
@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(
    private logMediaAccessGQL: LogMediaAccessGQL,
    private renewIncidentMediaUrlGQL: RenewIncidentMediaUrlGQL,
    private renewTrafficDisruptionUrlGQL: RenewTrafficDisruptionMediaUrlGQL,
    private logMediaAccessedGQL: LogMediaAccessedGQL
  ) {}

  // deprecated
  logMediaAccess(input: CreateMediaAccessLogInput) {
    return this.logMediaAccessGQL.mutate({ input: input }).pipe(
      map(res => res?.data?.logMediaAccess),
      catchError(err => throwError({ errorCode: err.graphQLErrors[0].extensions.statusCode }))
    );
  }

  logMediaAccessed(input: CreateMediaAccessedLogInput) {
    return this.logMediaAccessedGQL.mutate({ input }).pipe(
      map(res => res?.data?.logMediaAccessed),
      catchError(err => throwError({ errorCode: err.graphQLErrors[0]?.extensions.statusCode }))
    );
  }

  renewIncidentMediaUrl(incidentMebiaId: number) {
    return this.renewIncidentMediaUrlGQL.mutate({ id: incidentMebiaId }).pipe(
      map(res => res.data?.renewIncidentMediaUrl),
      catchError(err =>
        throwError({
          errors: err.graphQLErrors,
          errorCode: err.graphQLErrors[0]?.extensions.statusCode,
        })
      )
    );
  }

  renewTrafficDisruptionMediaUrl(trafficDisruptionMediaId: number) {
    return this.renewTrafficDisruptionUrlGQL.mutate({ id: trafficDisruptionMediaId }).pipe(
      map(res => res.data?.renewTrafficDisruptionMediaUrl),
      catchError(err =>
        throwError({
          errors: err.graphQLErrors,
          errorCode: err.graphQLErrors[0]?.extensions.statusCode,
        })
      )
    );
  }
}
