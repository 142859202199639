import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { StaticMapViewerComponent } from './static-map-viewer.component';

@NgModule({
  declarations: [StaticMapViewerComponent],
  imports: [CommonModule, TranslateModule],
  exports: [StaticMapViewerComponent],
})
export class StaticMapViewerModule {}
