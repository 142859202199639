/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ContextTypeDirective, TemplateRefNameDirective } from '@wc/wc-common/src/lib/directives';
import { SegmentDetailsCardModule } from '../roadway-status/segment-details-card/segment-details-card.module';
import { WcChipModule } from '../wc-chip/wc-chip.module';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { ClusteringTooltipContainerComponent } from './clustering-tooltip-container.component';

@NgModule({
  declarations: [ClusteringTooltipContainerComponent, TemplateRefNameDirective, ContextTypeDirective],
  imports: [CommonModule, WcChipModule, TranslateModule, SegmentDetailsCardModule, WcIconModule],
  exports: [ClusteringTooltipContainerComponent],
})
export class ClusteringTooltipContainerModule {}
