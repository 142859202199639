import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { TextAreaComponent } from './text-area.component';
import { FormControl } from '@angular/forms';

const Materials: any[] = [MatInputModule];

@NgModule({
  declarations: [TextAreaComponent],
  imports: [CommonModule, WcBaseControlFieldModule, Materials],
  providers: [FormControl],
  exports: [TextAreaComponent],
})
export class WcTextAreaModule {}
