import { AbstractControl, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export function PastDateValidator(dayOnly?: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value) {
      return null;
    }
    if (!moment().isSameOrAfter(control.value, dayOnly ? 'day' : null)) {
      return { dateMustNotBeLaterThanToday: true };
    }
    return null;
  };
}
