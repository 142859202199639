import { LocaleSpecification } from 'moment';

export const momentLanguageConfig = {
  hebrew: 'he',
  english: 'en',
  englishGB: 'en-gb',
};

export const momentLocaleSpecificationConfig: Record<string, LocaleSpecification> = {
  [momentLanguageConfig.hebrew]: {
    relativeTime: {
      s: 'מס` שניות',
    },
  },
  [momentLanguageConfig.english]: {
    relativeTime: {
      s: 'a few sec',
    },
  },
  [momentLanguageConfig.englishGB]: {
    relativeTime: {
      s: 'a few sec',
    },
  },
};
