// angular
import { Inject, Injectable, Optional } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import moment from 'moment';
import { fromEvent, merge, of, Subject } from 'rxjs';
import { bufferTime, map, tap } from 'rxjs/operators';
// app
import { isNativeScript } from '../../utils/platform';
import { isObject } from '../../utils/utils';
import { XPlatWindow } from '../models/xplat-window.interface';
import { PlatformWindowToken } from './tokens';

export enum NetworkState {
  'online',
  'unstable',
  'offline',
}

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  moment: any = moment;
  startOfflineDate?: Date;
  endOfflineDate!: Date;

  online$ = merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline')).pipe(
    map(() => navigator.onLine)
  );

  renewIncidentId$ = new Subject();
  networkState$ = this.online$.pipe(
    bufferTime(5000),
    map(stateBuffer => {
      let networkState: NetworkState = NetworkState.online;
      const stateSet = new Set(stateBuffer);
      switch (stateSet.size) {
        case 0:
        case 1: {
          const isOnline = stateBuffer.length > 0 ? stateBuffer[0] : navigator.onLine;
          if (isOnline === true) {
            networkState = NetworkState.online;
          } else {
            networkState = NetworkState.offline;
          }
          break;
        }
        case 2: {
          networkState = NetworkState.unstable;
          break;
        }
      }
      return networkState;
    })
  );

  logOfflinePeriod$ = this.online$.pipe(
    tap(stateBuffer => {
      this.logOfflinePeriod(stateBuffer);
    })
  );

  constructor(@Inject(PlatformWindowToken) @Optional() private _platformWindow: XPlatWindow) {}

  public get navigator() {
    return this._platformWindow?.navigator;
  }

  public get location() {
    return this._platformWindow?.location;
  }

  public get process() {
    return this._platformWindow?.process;
  }

  public get require() {
    return this._platformWindow?.require;
  }

  public alert(msg: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const result: any = this._platformWindow.alert(msg);
      if (isObject(result) && result.then) {
        // console.log('WindowService -- using result.then promise');
        result.then(resolve, reject);
      } else {
        resolve();
      }
    });
  }

  public confirm(msg: any, action?: Function /* used for fancyalerts on mobile*/): Promise<void> {
    return new Promise((resolve, reject) => {
      const result: any = (<any>this._platformWindow).confirm(msg, isNativeScript() ? action : undefined);
      if (isObject(result) && result.then) {
        result.then(resolve, reject);
      } else if (result) {
        resolve();
      } else {
        reject();
      }
    });
  }

  public setTimeout(handler: (...args: any[]) => void, timeout?: number): number {
    return this._platformWindow.setTimeout(handler, timeout);
  }

  public clearTimeout(timeoutId: number): void {
    return this._platformWindow.clearTimeout(timeoutId);
  }

  public setInterval(handler: (...args: any[]) => void, ms?: number, ...args: any[]): number {
    return this._platformWindow.setInterval(handler, ms, args);
  }

  public clearInterval(intervalId: number): void {
    return this._platformWindow.clearInterval(intervalId);
  }

  logOfflinePeriod(stateBuffer) {
    if (!stateBuffer && !this.startOfflineDate) this.startOfflineDate = new Date();
    if (stateBuffer && this.startOfflineDate) {
      this.endOfflineDate = new Date();

      const timePeriod = this.moment
        .duration(this.moment(this.endOfflineDate).diff(this.startOfflineDate))
        .as('seconds');
      datadogLogs.logger.info('Offline period', {
        wc_network: {
          startTime: JSON.stringify(this.startOfflineDate),
          endTime: JSON.stringify(this.endOfflineDate),
          timePeriodInSec: `${timePeriod} sec`,
        },
      });
      this.startOfflineDate = undefined;
    }
  }
}
