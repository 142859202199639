import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormFieldOption } from '@wc/wc-ui/src/lib/base/custom-form-control';
import { ExtraOptionValue } from '../autocomplete.component';
import { AutocompleteGroupingService } from './autocomplete-grouping.service';

export const LoadMoreState = {
  On: 'ON',
  Off: 'OFF',
  Loaded: 'LOADED',
} as const;

export type LoadMoreStateType = (typeof LoadMoreState)[keyof typeof LoadMoreState];

@Injectable()
export class AutocompleteLoadMoreService<T extends FormFieldOption<unknown, unknown>> {
  loadMoreOption: T = { displayName: 'loadMore', value: ExtraOptionValue.LoadMore } as T;
  hiddenOptions: Record<string, boolean> = {};
  optionKeys: string[] = [];
  hiddenGroups: Record<string, boolean> = {};

  constructor(
    private readonly translateService: TranslateService,
    private readonly groupingService: AutocompleteGroupingService<T>
  ) {
    this.translateService.get('loadMore').subscribe(val => (this.loadMoreOption.displayName = val));
  }

  initializeHiddenOptions(options: T[]) {
    this.hiddenOptions = options.reduce((acc, option) => {
      const value = `${option.value}`;
      acc[value] = !!option.hidden;
      this.optionKeys.push(value);
      return acc;
    }, {});
  }

  initializeHiddenGroups(options: T[]) {
    const groups = this.groupingService.groupOptions(options);

    this.hiddenGroups = groups.reduce((acc, curr) => {
      const hidden = curr.options.every(option => this.hiddenOptions[`${option.value}`]);
      acc[curr.label] = hidden;
      return acc;
    }, {});
  }

  showAllOptions(options: T[]) {
    this.optionKeys.forEach(key => {
      this.hiddenOptions[key] = false;
      const groupLabel = options.find(option => option.value === key)?.groupLabel;
      if (groupLabel) {
        const groupHidden = this.hiddenGroups[groupLabel];
        if (groupHidden) {
          this.showGroup(groupLabel);
        }
      }
    });
  }

  hideOptions(options: T[]) {
    this.optionKeys.forEach(key => {
      this.hiddenOptions[key] = !!options.find(option => option.value == key)?.hidden;
    });
  }

  showGroup(groupLabel: string) {
    this.hiddenGroups[groupLabel] = false;
  }

  hideGroup(groupLabel: string) {
    this.hiddenGroups[groupLabel] = true;
  }
}
