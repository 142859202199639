import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { State } from '@wc/core/models/gql.models';
import { Lambda, observe } from 'mobx';
import { Observable, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import {
  ActivityLogEntityType,
  ActivityStructure,
  ActivityType,
  CameraField,
  InjurySeveritiesField,
  InjurySeverity,
  TextField,
} from '../../../..//core/models';
import { AccountStore, WindowService } from '../../../../core';
import { ActivityLogStore, UiStore } from '../../../../core/stores';
@Component({
  selector: 'wc-activity-log',
  templateUrl: './activity-log.component.html',

  styleUrls: ['./activity-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityLogComponent implements OnInit, OnDestroy {
  entityType!: 'incident' | 'roadClosure' | 'construction' | 'specialEvent';
  entityId!: number;
  activitiesLog = [];
  isTabletMode!: boolean;
  activityType = ActivityType;
  loaderSub!: Subscription;
  disposer: Lambda;
  vehicleState = State;

  @Input() set incidentId(entityId: number) {
    this.loadActivityLog(entityId, 'incident');
  }

  @Input() isHebrew = false;

  @Input() set roadClosureId(entityId: number) {
    this.loadActivityLog(entityId, 'roadClosure');
  }

  @Input() set constructionId(entityId: number) {
    this.loadActivityLog(entityId, 'construction');
  }

  @Input() set specialEventId(entityId: number) {
    this.loadActivityLog(entityId, 'specialEvent');
  }

  get activityLogEntityType() {
    return ActivityLogEntityType;
  }

  isOnline$: Observable<boolean>;

  constructor(
    private cdr: ChangeDetectorRef,
    private uiStore: UiStore,
    public activityLogStore: ActivityLogStore,
    public translateService: TranslateService,
    private windowService: WindowService,
    public accountStore: AccountStore
  ) {
    this.isOnline$ = this.windowService.online$;
    this.disposer = observe(this.activityLogStore, change => {
      if (change.name === 'activityLogs') {
        this.setActivityLog(this.entityId, this.entityType);
        this.cdr.markForCheck();
      }
    });

    this.isOnline$.pipe(skip(1)).subscribe(change => {
      if (change && this.entityType === 'incident') {
        this.loadActivityLog(this.entityId, 'incident');
      }
    });
  }

  ngOnInit(): void {
    this.isTabletMode = this.uiStore.isTabletMode;
  }

  setActivityLog(entityId: number, entityType: 'incident' | 'roadClosure' | 'construction' | 'specialEvent') {
    this.entityType = entityType;
    this.entityId = entityId;

    const entityActivityLog = this.activityLogStore.activityLogs[entityType];
    if (entityActivityLog && entityActivityLog[entityId]) {
      this.activitiesLog = entityActivityLog[entityId];
      JSON.parse(JSON.stringify(this.activitiesLog));
    }
  }

  loadActivityLog(entityId: number, entityType: 'incident' | 'roadClosure' | 'construction' | 'specialEvent') {
    this.setActivityLog(entityId, entityType);
    if (!entityId) return;

    switch (entityType) {
      case 'incident':
        this.loaderSub = this.activityLogStore.loadIncidentActivityLog(entityId).subscribe();
        break;

      case 'roadClosure':
        this.loaderSub = this.activityLogStore.loadRoadClosureActivityLog(entityId).subscribe();
        break;

      case 'construction':
        this.loaderSub = this.activityLogStore.loadConstructionActivityLog(entityId).subscribe();
        break;

      case 'specialEvent':
        this.loaderSub = this.activityLogStore.loadSpecialEventActivityLog(entityId).subscribe();
        break;
    }
  }

  concatMedia(activities: ActivityStructure[]) {
    let addedAmount = 0;
    let removedAmount = 0;
    activities.forEach((activity: ActivityStructure) => {
      if (activity.field === 'MEDIA') {
        if (activity.action === ActivityType.FieldModified) ++addedAmount;
        if (activity.action === ActivityType.FieldCreated) ++addedAmount;
        if (activity.action === ActivityType.FieldRemoved) ++removedAmount;
      }
    });
    return addedAmount > 0 || removedAmount > 0 ? { addedAmount, removedAmount } : false;
  }

  concatAdditionalInfo(activities: ActivityStructure[]) {
    const additionalInfos: ActivityStructure[] = [];
    activities.forEach((activity: ActivityStructure) => {
      if (activity.field === 'ADDITIONAL_INFO') {
        additionalInfos.push(activity);
      }
    });
    return additionalInfos;
  }

  concatInjurySeverities(activities: ActivityStructure[]) {
    const injurySeverities: InjurySeverity[] = [];
    activities.forEach((activity: ActivityStructure) => {
      if (activity.field === 'INJURY_SEVERITIES') {
        injurySeverities.push(
          this.translateService.instant('injurySeverity.' + (activity.value as InjurySeveritiesField).injurySeverity)
        );
      }
    });
    return injurySeverities;
  }

  concatAttributes(activities: ActivityStructure[]) {
    const injurySeverities: string[] = [];
    activities.forEach((activity: ActivityStructure) => {
      if (activity.field === 'ATTRIBUTES') {
        injurySeverities.push(this.translateService.instant((activity.value as TextField).text));
      }
    });
    return injurySeverities;
  }

  concatInvolvedVehicle(activities: ActivityStructure[]) {
    const involvedVehicles: ActivityStructure[] = [];
    activities.forEach((activity: ActivityStructure) => {
      if (activity.field === 'INVOLVED_VEHICLE') {
        involvedVehicles.push(activity);
      }
    });
    return involvedVehicles;
  }

  concatCameras(activities: ActivityStructure[]) {
    const cameras: CameraField[] = [];
    activities.forEach((activity: ActivityStructure) => {
      if (activity.field !== 'CAMERA') return;
      const camera: CameraField = activity.value as CameraField;
      if (!camera.default) cameras.push(camera);
    });
    return cameras;
  }

  defaultCameraChanged(activities: ActivityStructure[]) {
    const camera = activities.find((activity: ActivityStructure) => {
      if (activity.field !== 'CAMERA') return false;
      const value: CameraField = activity.value as CameraField;
      return value['default'];
    });
    return camera;
  }

  ngOnDestroy(): void {
    if (this.loaderSub) {
      this.loaderSub.unsubscribe();
    }
    this.disposer();
  }

  _formatDate(date: string | number | Date) {
    return formatDate(date, 'fullTime', this.translateService.currentLang);
  }
}
