import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WcClickOutsideDirective } from './wc-click-outside.directive';

@NgModule({
  declarations: [WcClickOutsideDirective],
  imports: [CommonModule],
  providers: [],
  exports: [WcClickOutsideDirective],
})
export class WcClickOutsideDirectiveModule {}
