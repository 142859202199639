import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DayOfWeekPipe } from './day-of-week.pipe';

@NgModule({
  declarations: [DayOfWeekPipe],
  imports: [CommonModule],
  exports: [DayOfWeekPipe],
})
export class DayOfWeekPipeModule {}
