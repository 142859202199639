import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WcClickOutsideDirectiveModule } from '@wc/wc-common/src/lib/directives/wc-click-outside.directive.module';
import { WcInvalidExpansionCssClassDirectiveModule } from '@wc/wc-common/src/lib/directives/wc-invalid-expansion-css-class.directive.module';
import { DateFormatPipeModule } from '@wc/wc-common/src/lib/pipes/format-pipes/date-format.module';
import { WcAutocompleteV2Module } from '@wc/wc-ui/src/form-components/autocomplete-v2/autocomplete-v2.module';
import { TextFieldModule } from '@wc/wc-ui/src/form-components/text-field/text-field.module';
import { NumericIncreaseDecreaseModule } from '../../../form-components/numeric-increase-decrease/numeric-increase-decrease.module';
import { WcSelectModule } from '../../../form-components/wc-select/wc-select.module';
import { WcLoaderComponentModule } from '../../miscellaneous/loader/loader.module';
import { ResponsePlanFormActionComponent } from './response-plan-form-action.component';
@NgModule({
  declarations: [ResponsePlanFormActionComponent],
  imports: [
    CommonModule,
    NumericIncreaseDecreaseModule,
    FormsModule,
    ReactiveFormsModule,
    WcInvalidExpansionCssClassDirectiveModule,
    WcClickOutsideDirectiveModule,
    WcSelectModule,
    WcLoaderComponentModule,
    WcAutocompleteV2Module,
    TextFieldModule,
    TranslateModule,
    DateFormatPipeModule,
  ],
  exports: [ResponsePlanFormActionComponent],
})
export class ResponsePlanFormActionModule {}
