import { UserDetails } from '@wc/core';
import { SelectOption } from '@wc/features/ui/form-controls/form-models';

export function userDetailsToOptions(userDetails: UserDetails[]): SelectOption[] {
  const _options: SelectOption[] = [];

  userDetails.forEach(details => {
    _options.push({
      value: details.name,
      displayName: details.name,
      data: details.unitRelationType,
    });
  });

  return _options;
}
