import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TextFieldModule } from '../../form-components/text-field/text-field.module';
import { WcLoaderComponentModule } from '../miscellaneous/loader/loader.module';
import { ResponsePlanFormModule } from '../response-plan-form/response-plan-form.module';
import { ResponsePlanTemplatePageComponent } from './response-plan-template-page.component';

@NgModule({
  declarations: [ResponsePlanTemplatePageComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ResponsePlanFormModule,
    ReactiveFormsModule,
    FormsModule,
    TextFieldModule,
    WcLoaderComponentModule,
  ],
  exports: [ResponsePlanTemplatePageComponent],
})
export class ResponsePlanTemplatePageModule {}
