<form [formGroup]="shiftForm" class="shift-form route-removed-form">
  <div>
    <div class="removed-route-title">
      {{ 'shiftsAndRoutes.routeNotAvailable' | translate }}
    </div>

    <wc-select-control
      *ngIf="shiftFieldsData.routeTypesIds.options && shiftFieldsData.routeTypesIds.options.length"
      formControlName="routeTypesIds"
      [isTabletMode]="isTabletMode"
      [formFieldData]="shiftFieldsData.routeTypesIds">
    </wc-select-control>

    <div class="buttons-container">
      <button class="primary-button" (click)="submitEditShiftForm()">
        {{ 'save' | translate }}
      </button>
    </div>
  </div>
</form>
