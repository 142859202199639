import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DropFileComponent } from './drop-file.component';
import { WcButtonModule } from '../wc-button/wc-button.module';

@NgModule({
  declarations: [DropFileComponent],
  imports: [CommonModule, NgxFileDropModule, TranslateModule,WcButtonModule],
  exports: [DropFileComponent],
})
export class DropFileModule {}
