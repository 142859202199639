import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Layer } from 'ol/layer';
import { Source } from 'ol/source';
import { StaticLayerName } from '../../../enums';
import { StaticLayerOptionNames } from '../../../types';
import { StaticMapLayerButton } from '../../classes/static-map-layer-button';
import { WcMapViewerService } from '../../wc-map-viewer.service';

type trafficLayerNameOptions = StaticLayerOptionNames<StaticLayerName.mapBoxTraffic>;

@Component({
  selector: 'wc-map-traffic-button',
  templateUrl: './map-traffic-button.component.html',
  styleUrls: ['../../../styles/_base-map-buttons-style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MapTrafficButtonComponent extends StaticMapLayerButton<Layer<Source>> implements OnInit, OnDestroy {
  @Output() statusChanged = new EventEmitter<{ status: boolean; name: trafficLayerNameOptions }>();
  @Input() staticLayerOptionName!: trafficLayerNameOptions;
  @Input() set activeState(isActive: boolean) {
    if (isActive !== this.isActive) {
      setTimeout(() => this.buttonClick());
    }
  }

  isActive = false;

  constructor(mapViewerService: WcMapViewerService) {
    super(mapViewerService);

    this.visibilityChanged$.subscribe(status => {
      this.isActive = status;
      this.statusChanged.emit({ status: this.isActive, name: this.staticLayerOptionName });
    });
  }

  ngOnInit() {
    if (this.staticLayerOptionName) {
      this.initiateStaticLayerObservableByName(this.staticLayerOptionName);
    } else console.error('staticLayerOptionName was not provided');
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  buttonClick(): void {
    if (this.staticMapLayers) {
      this.toggleLayerVisibility(this.staticMapLayers);
    }
    this.staticMapLayers$?.subscribe(layers => (this.staticMapLayers = layers));
  }
}
