<div
  class="transit-panel-component"
  [class.language-hebrew]="isHebrew"
  [class.portrait-mode]="isPortraitDesktopMode"></div>

<div
  class="transit-panel-container"
  wcPopupCloser
  (closed)="closePanel()"
  [refocus]="refocus$"
  [class.portrait-mode]="isPortraitDesktopMode">
  <wc-loader-component [show]="!!(showLoader$ | async)"></wc-loader-component>

  <div class="transit-panel" [class.language-rtl]="isHebrew">
    <i class="fas fa-times" (click)="closePanel()"> </i>
    <mat-tab-group
      class="transit-panel-tabs"
      [(selectedIndex)]="mainTabIndex"
      (selectedIndexChange)="setTabIndex($event)">
      <mat-tab class="transit-panel-tab">
        <wc-route-list-tab
          [myAgencyOnly]="myAgencyOnly"
          [selectedRoutes]="selectedRoutes"
          [showBuses]="showBuses"
          [isActiveServicesFeatureActive]="hasPermission('TRANSIT:READ')"
          [isAffectedServicePanelOpened]="isAffectedServicePanelOpened"
          [isTrainDelaysPanelOpened]="isTrainDelaysPanelOpened"
          [layersVisibility]="layersVisibility$ | async"
          [fixedRoutesAsOptions]="fixedRoutesAsOptions$ | async"
          [transitRoutes]="transitRoutes$ | async"
          (getUnitId)="getTransitUnitDetails($event)"
          (getRouteId)="selectRouteFromPanel($event)"
          (layerCheckboxClicked)="layerCheckboxClicked($event.checked, $event.name)"></wc-route-list-tab>
      </mat-tab>

      <mat-tab class="transit-panel-tab">
        <wc-selected-route-tab
          [routeDetails]="selectedRoute$ | async"
          (clickBack)="setTabIndex($event)"
          (routeTabIndexChanged)="setRouteTabIdx($event)"
          (clickOnStopId)="this.getBusStopDetails($event)"
          [busStopsList]="busStopsList$ | async"
          (clickToGetPatternId)="setPatternId($event)"
          [routeTabIndex]="routeTabIndex"></wc-selected-route-tab>
      </mat-tab>

      <mat-tab class="transit-panel-tab">
        <wc-selected-bus-stop-tab
          [accountId]="accountId"
          [busStopDetails]="busStopDetails$ | async"
          (clickBack)="setTabIndex($event)">
        </wc-selected-bus-stop-tab>
      </mat-tab>

      <mat-tab class="transit-panel-tab">
        <wc-selected-unit-tab (clickBack)="setTabIndex($event)"></wc-selected-unit-tab>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
