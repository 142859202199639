import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ReleaseNotesService } from '@wc-core';
@Component({
  selector: 'wc-release-notes-view',
  templateUrl: './release-notes-view.component.html',
  styleUrls: ['./release-notes-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ReleaseNotesViewComponent implements AfterViewInit {
  @ViewChild('menuTrigger', { read: MatMenuTrigger }) menuTrigger!: MatMenuTrigger;
  @Output() closeParent = new EventEmitter<void>();

  get releaseNotes() {
    return this.releaseNoteService.releaseNotes$;
  }

  get newReleasesCount() {
    return this.releaseNoteService.unseenReleasesCount$;
  }

  constructor(private releaseNoteService: ReleaseNotesService) {}

  ngAfterViewInit(): void {
    this.menuTrigger.menuClosed.subscribe(() => this.releaseNoteService.updateViewedReleaseIds());
  }

  imageError($event) {
    $event.target.src = '/assets/icons/media-loading.svg';
  }

  closeMenu() {
    this.menuTrigger.closeMenu();
    this.releaseNoteService.updateViewedReleaseIds();
    this.closeParent.emit();
  }
}
