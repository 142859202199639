import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export function CompareDatesValidator(beforeControlName: string, afterControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
    const beforeControl = (formGroup as FormGroup).controls[beforeControlName];
    const afterControl = (formGroup as FormGroup).controls[afterControlName];

    // Removes the validator error when afterControl is invalid
    if (afterControl.value === '' || afterControl.invalid) {
      if (beforeControl.errors?.startDateNeedToBeBeforeEndDate) {
        beforeControl.setErrors(null);
      }
      return null;
    }

    if (beforeControl.disabled || (beforeControl.errors && !beforeControl.errors.startDateNeedToBeBeforeEndDate)) {
      return null;
    }

    if (
      afterControl.value === null ||
      beforeControl.value === null ||
      moment(beforeControl.value).isBefore(afterControl.value)
    ) {
      beforeControl.setErrors(null);
      return null;
    } else {
      beforeControl.setErrors({ startDateNeedToBeBeforeEndDate: true });
      return { startDateNeedToBeBeforeEndDate: true };
    }
  };
}
