import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IncidentsService, TrafficDisruptionService } from '@wc-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ActivityLogAggregated,
  ActivityLogEntityType,
  ActivityStructure,
  ActivityType,
  CameraField,
  InjurySeveritiesField,
  InjurySeverity,
  RegionalSetting,
  State,
  TextField,
} from '../../../..//core/models';

type DmsField = any;

@Component({
  selector: 'wc-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityLogComponent implements OnInit {
  entityType!: ActivityLogEntityType;
  entityId!: number;
  activitiesLogValue$: BehaviorSubject<ActivityLogAggregated[]> = new BehaviorSubject<ActivityLogAggregated[]>([]);
  activityLogApiCall$!: Observable<ActivityLogAggregated[]>;
  activityType = ActivityType;
  activityLogEntityType = ActivityLogEntityType;
  vehicleState = State;

  @Input() isHebrew = false;
  @Input() regionalSetting!: RegionalSetting;

  @Input() set id(id: number) {
    if (this.entityId) {
      this.entityId = id;
      this.initGetLogCall();
      this.fetchActivityLog();
    } else {
      this.entityId = id;
    }
  }

  @Input() set logType(type: ActivityLogEntityType) {
    this.entityType = type;
    this.initGetLogCall();
  }

  constructor(
    public translateService: TranslateService,
    private incidentService: IncidentsService,
    private trafficDisruptionService: TrafficDisruptionService
  ) {}

  ngOnInit(): void {
    this.fetchActivityLog();
  }

  private initGetLogCall() {
    switch (this.entityType) {
      case ActivityLogEntityType.Incident:
        this.activityLogApiCall$ = this.incidentService.getActivityLog(this.entityId);
        break;
      default:
        this.activityLogApiCall$ = this.trafficDisruptionService.getActivityLog(this.entityId, this.entityType);
        break;
    }
  }

  fetchActivityLog() {
    this.activityLogApiCall$.pipe(take(1)).subscribe(res => {
      this.activitiesLogValue$.next(res);
    });
  }

  concatMedia(activities: ActivityStructure[]) {
    let addedAmount = 0;
    let removedAmount = 0;
    activities.forEach((activity: ActivityStructure) => {
      if (activity.field === 'MEDIA') {
        if (activity.action === ActivityType.FieldModified) ++addedAmount;
        if (activity.action === ActivityType.FieldCreated) ++addedAmount;
        if (activity.action === ActivityType.FieldRemoved) ++removedAmount;
      }
    });
    return addedAmount > 0 || removedAmount > 0 ? { addedAmount, removedAmount } : false;
  }

  concatAdditionalInfo(activities: ActivityStructure[]) {
    const additionalInfos: ActivityStructure[] = [];
    activities.forEach((activity: ActivityStructure) => {
      if (activity.field === 'ADDITIONAL_INFO') {
        additionalInfos.push(activity);
      }
    });
    return additionalInfos;
  }

  concatInjurySeverities(activities: ActivityStructure[]) {
    const injurySeverities: InjurySeverity[] = [];
    activities.forEach((activity: ActivityStructure) => {
      if (activity.field === 'INJURY_SEVERITIES') {
        injurySeverities.push(
          this.translateService.instant('injurySeverity.' + (activity.value as InjurySeveritiesField).injurySeverity)
        );
      }
    });
    return injurySeverities;
  }

  concatAttributes(activities: ActivityStructure[]) {
    const injurySeverities: string[] = [];
    activities.forEach((activity: ActivityStructure) => {
      if (activity.field === 'ATTRIBUTES') {
        injurySeverities.push(this.translateService.instant((activity.value as TextField).text));
      }
    });
    return injurySeverities;
  }

  concatInvolvedVehicle(activities: ActivityStructure[]) {
    const involvedVehicles: ActivityStructure[] = [];
    activities.forEach((activity: ActivityStructure) => {
      if (activity.field === 'INVOLVED_VEHICLE') {
        involvedVehicles.push(activity);
      }
    });
    return involvedVehicles;
  }

  concatCameras(activities: ActivityStructure[]) {
    const cameras: CameraField[] = [];
    activities.forEach((activity: ActivityStructure) => {
      if (activity.field !== 'CAMERA') return;
      const camera: CameraField = activity.value as CameraField;
      if (!camera.default) cameras.push(camera);
    });
    return cameras;
  }

  concatDms(activities: ActivityStructure[]) {
    const dms: DmsField[] = [];
    activities.forEach((activity: ActivityStructure) => {
      if (activity.field !== 'DMS') return;
      const dmsItem: DmsField = activity.value as DmsField;
      if (!dmsItem.default) dms.push(dmsItem);
    });
    return dms;
  }

  defaultCameraChanged(activities: ActivityStructure[]) {
    const camera = activities.find((activity: ActivityStructure) => {
      if (activity.field !== 'CAMERA') return false;
      const value: CameraField = activity.value as CameraField;
      return value['default'];
    });
    return camera;
  }

  _formatDate(date: string | number | Date) {
    return formatDate(date, 'fullTime', this.translateService.currentLang);
  }
}
