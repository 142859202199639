import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateFormatPipeModule } from '@wc/wc-common/src/lib/pipes/format-pipes/date-format.module';
import { TimeFormatPipeModule } from '@wc/wc-common/src/lib/pipes/format-pipes/time-format.module';
import { WcIconModule } from '../../wc-icon/wc-icon.module';
import { WeatherMapModalComponent } from './weather-map-modal.component';

@NgModule({
  declarations: [WeatherMapModalComponent],
  imports: [CommonModule, DateFormatPipeModule, TimeFormatPipeModule, TranslateModule, WcIconModule],
  exports: [WeatherMapModalComponent],
})
export class WeatherMapModalModule {}
