import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountStore, MeasureUnitType } from '@wc/core';
import { get } from 'lodash';

@Pipe({
  name: 'distanceFormat',
})
export class DistanceFormatPipe implements PipeTransform {
  constructor(private accountStore: AccountStore, private translateService: TranslateService) {}

  transform(value: number, perHour: boolean = false, toFixed: number = 2): string {
    if (typeof value === 'string' || isNaN(value)) {
      return value as any;
    }
    const unit = this.getUnit();

    if (!value) {
      value = 0;
    }

    if (unit === MeasureUnitType.Metric) {
      const kilometers = this.getKilometers(value).toFixed(toFixed);
      return `${kilometers.toString()} ${
        perHour ? this.translateService.instant('speedUnits.kmh') : this.translateService.instant('speedUnits.km')
      }`;
    } else {
      const miles = this.getMiles(value).toFixed(toFixed);
      return `${miles.toString()} ${
        perHour ? this.translateService.instant('speedUnits.mph') : this.translateService.instant('speedUnits.mile')
      }`;
    }
  }

  private getMiles(meters: number) {
    return meters * 0.000621371192;
  }

  private getKilometers(meters: number) {
    return meters / 1000;
  }

  private getUnit() {
    return get(this.accountStore, 'account.regionalSetting.measureUnitType', MeasureUnitType.Metric);
  }
}
