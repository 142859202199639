import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
// import {InMemoryCache} from 'apollo-cache-inmemory';
import { environment } from './environments/environment';
import { DefaultOptions } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/core';

const uri = environment.api_url;
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export function createApollo(httpLink: HttpLink) {
  return {
    link: httpLink.create({ uri }),
    cache: new InMemoryCache({
      // TODO IMPROVEMENTS: Think about changing it to true
      addTypename: false,
      resultCaching: false,
    }),
    defaultOptions: defaultOptions,
  };
}

@NgModule({
  exports: [HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
