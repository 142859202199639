import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'wc-loader-component',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  showLoader!: boolean;
  isDelay = true;

  @Input()
  delay = 0;

  @Input()
  set show(value: boolean) {
    this.showLoader = value;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  showAfterDelay() {
    if (this.delay) {
      setTimeout(() => {
        this.isDelay = true;
        this.cdr.markForCheck();
      }, this.delay * 1000);
    } else {
      this.isDelay = true;
      this.cdr.markForCheck();
    }
  }

  displayed() {
    console.log('displayed');
  }
}
