import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { CustomRxOperatorsService, LocalStorageService } from '@wc-core';
import {
  ActivityLogAggregated,
  CompleteIncidentGQL,
  ConfirmIncidentGQL,
  CreateIncidentGQL,
  CreateIncidentMutationInput,
  DeleteIncidentMediaGQL,
  DeleteIncidentMediaInput,
  EndIncidentInput,
  FindIncidentsInPointRadiusInput,
  FindLatestIncidentsGQL,
  FindRelatedIncidentsByPointRadiusGQL,
  Incident,
  IncidentActivityLogGQL,
  IncidentGQL,
  IncidentInput,
  IncidentMitigationAndUnitsGQL,
  IncidentStatus,
  RejectIncidentGQL,
  RelatedIncident,
  RenewIncidentMediaUrlGQL,
  UndoCompleteGQL,
  UndoRejectGQL,
  UpdateIncidentOneTimeGQL,
} from '@wc/core/models/gql.models';
import { removeUpdatedAtFromAffectedLanes, sortAffectedLanes } from '@wc/utils';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { LocalStorageKeys } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class IncidentsApiService {
  constructor(
    private createIncidentGQL: CreateIncidentGQL,
    private incidentGQL: IncidentGQL,
    private completeIncidentGQL: CompleteIncidentGQL,
    private confirmIncidentGQL: ConfirmIncidentGQL,
    private rejectIncidentGQL: RejectIncidentGQL,
    private deleteIncidentMediaGQL: DeleteIncidentMediaGQL,
    private renewIncidentMediaUrlGQL: RenewIncidentMediaUrlGQL,
    private incidentActivityLogGQL: IncidentActivityLogGQL,
    private undoCompleteGQL: UndoCompleteGQL,
    private undoRejectGQL: UndoRejectGQL,
    private findRelatedIncidentsByPointRadiusGQL: FindRelatedIncidentsByPointRadiusGQL,
    private updateIncidentOneTimeGQL: UpdateIncidentOneTimeGQL,
    private localStorageService: LocalStorageService,
    private customOperators: CustomRxOperatorsService,
    private incidentMitigationAndUnitsGQL: IncidentMitigationAndUnitsGQL,
    private findLatestIncidentsGQL: FindLatestIncidentsGQL
  ) {}

  getIncident(id: number) {
    return this.incidentGQL.fetch({ id: id }).pipe(
      map(res => res.data.incident as Incident),
      tap(incident => {
        if (incident.affectedLanes) {
          incident.affectedLanes = sortAffectedLanes(incident.affectedLanes);
          incident.affectedLanes = removeUpdatedAtFromAffectedLanes(incident.affectedLanes);
        }
      }),
      this.customOperators.catchGqlErrors()
    );
  }

  completeIncident(inputData: EndIncidentInput) {
    return this.completeIncidentGQL.mutate({ input: inputData }).pipe(
      map(res => res?.data?.completeIncident),
      this.customOperators.catchGqlErrors()
    );
  }

  confirmIIncident(input) {
    return this.confirmIncidentGQL.mutate(input).pipe(
      map(res => res?.data?.confirmIncident),
      this.customOperators.catchGqlErrors()
    );
  }

  rejectIncident(inputData: EndIncidentInput) {
    return this.rejectIncidentGQL.mutate({ input: inputData }).pipe(
      map(res => res?.data?.rejectIncident),
      this.customOperators.catchGqlErrors()
    );
  }

  undoCompleteIncident(input) {
    return this.undoCompleteGQL.mutate(input).pipe(
      map(res => res?.data?.undoComplete),
      this.customOperators.catchGqlErrors()
    );
  }
  undoRejectIncident(input) {
    return this.undoRejectGQL.mutate(input).pipe(
      map(res => res?.data?.undoReject),
      this.customOperators.catchGqlErrors()
    );
  }

  createIncident(input: CreateIncidentMutationInput): Observable<Incident> {
    return this.createIncidentGQL.mutate({ input: input }).pipe(
      map(res => res?.data?.createIncident as Incident),
      this.customOperators.catchGqlErrors()
    );
  }

  updateIncidentOneTime(incidentInput: IncidentInput) {
    return this.incidentMitigationAndUnitsGQL.fetch({ id: incidentInput.incidentId }).pipe(
      map(res => res.data.incident as Partial<Incident>),
      this.updateUnitsAndMitigationBasedOnUpdatedData(incidentInput),
      switchMap(() => this.updateIncidentOneTimeCall(incidentInput)),
      this.customOperators.catchGqlErrors()
    );
  }

  deleteIncidentMedia(input: DeleteIncidentMediaInput) {
    return this.deleteIncidentMediaGQL.mutate({ input }).pipe(this.customOperators.catchGqlErrors());
  }

  renewIncidentMediaUrl(incidentMebiaId: number) {
    return this.renewIncidentMediaUrlGQL.mutate({ id: incidentMebiaId }).pipe(this.customOperators.catchGqlErrors());
  }

  incidentActivityLog(id: number): Observable<ActivityLogAggregated[]> {
    return this.incidentActivityLogGQL.fetch({ id: id }).pipe(
      map(res => res.data?.incidentActivityLog as ActivityLogAggregated[]),
      this.customOperators.catchGqlErrors()
    );
  }

  findIncidentByPointRadius(input: FindIncidentsInPointRadiusInput): Observable<RelatedIncident[]> {
    return this.findRelatedIncidentsByPointRadiusGQL.fetch({ input }).pipe(
      map(res => res.data.findRelatedIncidentsByPointRadius),
      this.customOperators.catchGqlErrors()
    );
  }

  findLatestIncidents() {
    return this.findLatestIncidentsGQL
      .fetch({
        statuses: [IncidentStatus.Completed, IncidentStatus.Rejected],
      } as never)
      .pipe(map(res => res.data.findLatestIncidents));
  }

  updateIncidentOneTimeCall(input: IncidentInput): Observable<any> {
    return this.updateIncidentOneTimeGQL.mutate({ input }).pipe(map(res => res.data?.updateIncidentOneTime));
  }

  //TODO: Check if we need this.
  private updateUnitsAndMitigationBasedOnUpdatedData(incidentInput: IncidentInput) {
    return tap((update: Partial<Incident>) => {
      // Desktop edit - case 1: new tablet user is added while editing
      // Desktop edit - case 2: tablet user remove himself from incident - WILL ADD THE UNIT known issue
      // Desktop edit - case 3: current tablet unit changed status / added mitigations
      // Apply merge only if edit form server arrived while the user was editing
      // for all units or mitigation - compare server data and take the latest update from the server

      const updateAt = new Date(update.updatedAt as string).getTime();
      const startEdit = new Date(
        this.localStorageService.get(LocalStorageKeys.UserLastEdit)?.timestamp as string
      ).getTime();
      if (!startEdit || updateAt > startEdit) {
        datadogLogs.logger.info('Override incident mitigation and units - incidentService', {
          currentUserInput: JSON.stringify({
            associatedUnits: incidentInput.units,
            mitigations: incidentInput.mitigations,
          }),
          serverLatestUpdate: JSON.stringify(update),
        });
        const unitsMap = new Map(
          incidentInput.units?.map(unit => {
            return [unit.unitId, unit];
          })
        );

        const mitigationMap = new Map(
          incidentInput.mitigations?.map(mit => {
            return [mit.id, mit];
          })
        );

        update.associatedUnits?.forEach(unit => {
          unitsMap.set(unit.id, {
            driverId: unit.driverDetails?.userId,
            response: unit.unitResponse,
            unitId: unit.id,
          });
        });

        update.mitigations?.forEach(mit => {
          mitigationMap.set(mit.id, {
            driverId: mit.userId,
            interval: mit.interval,
            mitigationTypeId: mit.mitigationType.id,
            unitId: mit.unitId,
          });
        });

        incidentInput.units = [...unitsMap.values()];
        incidentInput.mitigations = [...mitigationMap.values()];
      }
    });
  }
}

/**
 * Very suspicious code: 
 * 
 *    //   updateIncidentInput.title =
      //     isEqual(incident.location, modifiedIncident.location) && isEqual(incident.address, modifiedIncident.address)
      //       ? null // location did not change, keeps title as is
      //       : { value: null }; // actually sets the title to null
 */
