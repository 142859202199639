import { Injectable } from '@angular/core';
import { AffectedUnitServicesQuery } from '@wc-core';
import { AffectedServiceIUEntity } from '@wc/wc-models/src/lib/types/entities';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AffectedRouteServicesQuery } from './transit_affected_route_services.query';

@Injectable({ providedIn: 'root' })
export class TransitQuery {
  constructor(
    private affectedRouteServicesQuery: AffectedRouteServicesQuery,
    private affectedUnitServicesQuery: AffectedUnitServicesQuery
  ) {}

  get notViewedAffectedServices$(): Observable<AffectedServiceIUEntity[]> {
    return combineLatest([
      this.affectedRouteServicesQuery.filteredServicesUIEntities$,
      this.affectedUnitServicesQuery.filteredServicesUIEntities$,
    ]).pipe(
      map(args => args.flat(1) as AffectedServiceIUEntity[]),
      map(affectedServices => affectedServices.filter(affectedService => !affectedService.isViewed))
    );
  }
}
