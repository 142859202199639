<div class="map-draw-field-cont">
  <div class="map-draw-cont">
    <wc-map #wcMapStaticView [forceTileLayer]="true"></wc-map>
  </div>
  <div class="map-draw-button heap-desktop-map-draw-button" tabindex="0" (click)="openRouteModal()">
    <span>
      hi
      {{ 'analyticsModule.filterByPolygon' | translate }}
      <i class="fas fa-chevron-right"></i>
    </span>
  </div>
</div>
