<div
  class="start-shift-modal"
  [class.tablet-mode]="true"
  [class.language-rtl]="uiStore.isDirectionRtl"
  [class.language-hebrew]="this.isHebrew">
  <ng-container *ngIf="shiftFormState === shiftFormStateEnum.Start">
    <h2 class="modal-header">{{ 'shiftsAndRoutes.startShift' | translate }}</h2>

    <div class="modal-content">
      <wc-loader *mobxAutorun="{ dontDetach: true }" [show]="uiStore.loaderVisibility.shift"></wc-loader>

      <div class="modal-content-padding">
        <wc-start-shift-form (modalClosed)="closeShiftForm()"></wc-start-shift-form>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="shiftFormState === shiftFormStateEnum.Edit">
    <h2 class="modal-header">{{ 'shiftsAndRoutes.editShift' | translate }}</h2>

    <div class="modal-content">
      <wc-loader *mobxAutorun="{ dontDetach: true }" [show]="uiStore.loaderVisibility.shift"></wc-loader>

      <div class="modal-content-padding">
        <wc-edit-shift-form (modalClosed)="closeShiftForm()"></wc-edit-shift-form>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="shiftFormState === shiftFormStateEnum.PauseStart">
    <h2 class="modal-header">
      {{ 'shiftsAndRoutes.pauseShift' | translate }}
    </h2>

    <div class="modal-content">
      <div class="modal-content-padding">
        <wc-pause-start-shift-form (modalClosed)="closeShiftForm()"></wc-pause-start-shift-form>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="shiftFormState === shiftFormStateEnum.PauseEnd">
    <h2 class="modal-header">
      <span class="shift-title">{{ 'shiftsAndRoutes.shiftPaused' | translate }}</span>

      <ng-container *ngIf="breakTime">
        |
        <span class="shift-break-timer"> {{ breakTime }} {{ 'timeUnits.min' | translate }} </span>
      </ng-container>
    </h2>

    <div class="modal-content">
      <div class="modal-content-padding">
        <wc-pause-end-shift-form (modalClosed)="closeShiftForm()"></wc-pause-end-shift-form>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="shiftFormState === shiftFormStateEnum.End">
    <h2 class="modal-header">{{ 'shiftsAndRoutes.endShift' | translate }}</h2>

    <div class="modal-content">
      <div class="modal-content-padding">
        <wc-end-shift-form (modalClosed)="closeShiftForm()"></wc-end-shift-form>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="shiftFormState === shiftFormStateEnum.ShiftRemoved">
    <h2 class="modal-header">
      {{ 'shiftsAndRoutes.shiftRemoved' | translate }}
    </h2>

    <div class="modal-content">
      <div class="modal-content-padding">
        <wc-shift-removed-form (modalClosed)="closeShiftForm()"></wc-shift-removed-form>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="shiftFormState === shiftFormStateEnum.RouteRemoved">
    <h2 class="modal-header">
      {{ 'shiftsAndRoutes.routeRemoved' | translate }}
    </h2>

    <div class="modal-content">
      <div class="modal-content-padding">
        <wc-route-removed-form (modalClosed)="closeShiftForm()"></wc-route-removed-form>
      </div>
    </div>
  </ng-container>
</div>
