import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MeasureUnitType } from '@wc/core/models/gql.models';
import { MergedMetricVendorData } from '../../../../../../wc-models/src/lib/types/roadway-status';

export enum SegmentDataViewMode {
  Short = 'short',
  Extended = 'extended',
}

@Component({
  selector: 'wc-segment-data',
  templateUrl: './segment-data.component.html',
  styleUrls: ['./segment-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentDataComponent {
  @Input() data!: Partial<MergedMetricVendorData>;
  @Input() mode: SegmentDataViewMode = SegmentDataViewMode.Short;
  @Input() measureUnitType!: MeasureUnitType;
  @Input() maxSpeed: number | null = null;
  readonly SegmentDataViewModes = SegmentDataViewMode;

  get speedUnits() {
    return this.measureUnitType === MeasureUnitType.Metric
      ? 'speedUnits.kmh'
      : 'speedUnits.mph';
  }
}
