import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SliderToggleControlModule } from '@wc/wc-ui/src';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { WorkspacesListComponent } from './workspaces-list.component';

@NgModule({
  imports: [CommonModule, WcBaseControlFieldModule, SliderToggleControlModule],
  declarations: [WorkspacesListComponent],
  exports: [WorkspacesListComponent],
})
export class WorkspacesListModule {}
