import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UsersStore } from '@wc/core';

@Directive({
  selector: '[wcIfPermission]',
})
export class PermissionDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private usersStore: UsersStore
  ) {}

  @Input()
  set wcIfPermission(val) {
    const isAuthorized = this.usersStore.hasPermission(val);

    if (isAuthorized) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
