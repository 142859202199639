import { mapVendorName, StaticLayerName, StaticLayerOptions } from '../../wc-map-viewer/src';
import { StaticLayersKeysAndUrls } from './types';

export const createStaticLayersPerVendorList = (params: StaticLayersKeysAndUrls): StaticLayerOptions[] => [
  {
    type: StaticLayerName.satelliteMap,
    name: 'satelliteAWS',
    satelliteApiKey: params.AWSKey,
    vendorName: mapVendorName.AWS,
    mapboxKey: params.MAPBOXKey,
    styleUrl: `${params.MAPBOXAccountUrl}/${params.MAPBOXLabelLayerStyleId}`,
  },
  {
    type: StaticLayerName.satelliteMap,
    name: 'satelliteMAPBOX',
    satelliteApiKey: params.MAPBOXKey,
    vendorName: mapVendorName.MAPBOX,
  },
  {
    type: StaticLayerName.mapBoxTraffic,
    name: 'trafficMAPBOX',
    mapboxKey: params.MAPBOXKey,
    styleUrl: '',
  },
  {
    type: StaticLayerName.mileMarkers,
    name: 'mileMarkerREKOR',
    url: `${params.REKORUrl}/styles/mile_markers/style.json`,
  },
  {
    type: StaticLayerName.vectorMap,
    name: 'vectorMapMAPBOX',
    mapboxKey: params.MAPBOXKey,
  },
];
