import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseControlComponent } from './base-control.component';

@NgModule({
  declarations: [BaseControlComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  providers: [],
  exports: [BaseControlComponent],
})
export class BaseControlModule {}
