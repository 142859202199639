import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleDocPreviewPipeModule } from '@wc/wc-common/src';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { MediaThumbnailModule } from '../media-thumbnail/media-thumbnail.module';
import { WcLoaderComponentModule } from '../miscellaneous/loader/loader.module';
import { MediaFullScreenComponent } from './media-full-screen.component';

@NgModule({
  declarations: [MediaFullScreenComponent],
  imports: [
    CommonModule,
    WcLoaderComponentModule,
    SwiperModule,
    MediaThumbnailModule,
    TranslateModule,
    GoogleDocPreviewPipeModule,
  ],
  exports: [MediaFullScreenComponent],
})
export class MediaFullScreenModule {}
