import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AccountService, IncidentsUtilsService, SegmentAliasType, SegmentService } from '@wc-core';
import { Direction, EntityType, IncidentConfiguration, Orientation, Point, RoadType, SegmentDetails } from '@wc/core';
import { EnumToOptions, isHighway, segmentDetailsToOptions } from '@wc/utils';
import { strArrayToOptions } from '@wc/wc-common/src';
import { DistanceFormatPipe } from '@wc/wc-common/src/lib/pipes/format-pipes/distance-format.pipe';
import { cloneDeep } from 'lodash';
import { combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { IconNames } from '../../../../wc-models/src/lib/types/icon-class-names-type';
import { FormFieldOption } from '../../lib/base';
import { OptionsArray } from '../autocomplete/autocomplete.component';
import { AutoCompleteOption } from '../autocomplete/components/autocomplete-option/autocomplete-option.component';

export type AddressComponentEntityType = EntityType.Incident | EntityType.Construction | EntityType.RoadClosure;
export const ADDRESS_ENTITY_TYPE_TOKEN = new InjectionToken<AddressComponentEntityType>('addressEntityTypeToken');

const GroupNames = {
  highway: 'HIGHWAYS',
  street: 'STREETS',
  alias: 'ALIASES',
} as const;

const entityTypeToConfigurationMapper: Record<AddressComponentEntityType, string> = {
  [EntityType.Incident]: 'incidentConfigs',
  [EntityType.Construction]: '',
  [EntityType.RoadClosure]: '',
};

@Injectable({
  providedIn: 'root',
})
export class AddressUtilsService {
  protected MAX_OPTIONS = 20;
  readonly segmentNamesAlias: { crossroad?: string | null; corridor?: string | null } = {};
  firstCorridorCrossroadFormValues?: [string, string];
  optionsWithGroupLabels: OptionsArray<FormFieldOption> = [];
  private isEntityTypeIsIncident = this.entityType === EntityType.Incident;

  getAddressFieldData() {
    return {
      crossroad: {
        heapClass: 'crossroad',
        label: 'crossroad',
        placeholder: 'enterCrossroad',
      },
      orientation: {
        heapClass: 'orientation',
        label: 'orientation',
        required: this.isFieldMandatory('orientationMandatory'),
        options: this.isEntityTypeIsIncident
          ? this.incidentUtilsService.incidentOrientationOptions
          : EnumToOptions(Orientation),
        placeholder: 'orientation',
      },
      direction: {
        heapClass: 'direction',
        label: 'direction',
        required: this.isFieldMandatory('directionMandatory'),
        options: EnumToOptions(Direction).map(option => {
          option.displayName = option.displayName?.toUpperCase();
          return option;
        }),
        placeholder: 'direction',
      },
      corridor: {
        heapClass: 'corridor',
        required: true,
        label: 'corridor',
        placeholder: 'enterCorridor',
      },
      roadType: {
        heapClass: 'roadType',
        required: true,
        label: 'roadType',
        options: this.incidentUtilsService.incidentRoadTypesOptions,
        placeholder: 'roadType',
      },
      milemarker: {
        heapClass: 'milemarker',
        label: 'milemarker',
        placeholder: 'incident.enterMilemarker',
      },
    };
  }

  isCorridorOrCrossroadChanged(form: FormGroup, ctrName: 'corridor' | 'crossroad') {
    if (ctrName === 'corridor') {
      return form.get(ctrName)?.value !== this.firstCorridorCrossroadFormValues?.[0];
    }
    return form.get(ctrName)?.value !== this.firstCorridorCrossroadFormValues?.[1];
  }

  constructor(
    private translateService: TranslateService,
    private incidentUtilsService: IncidentsUtilsService,
    private distanceFormatPipe: DistanceFormatPipe,
    private segmentService: SegmentService,
    private accountService: AccountService,
    @Inject(ADDRESS_ENTITY_TYPE_TOKEN) protected readonly entityType: AddressComponentEntityType
  ) {}

  getSegmentDetails(point: Point) {
    return this.segmentService.getSegmentDetails(point).pipe(
      tap(segmentDetails => {
        this.firstCorridorCrossroadFormValues = undefined;
        this.segmentNamesAlias.corridor = segmentDetails?.overridingCorridorAlias;
        this.segmentNamesAlias.crossroad = segmentDetails?.overridingCrossroadAlias;
      }),
      this.transformSegmentNamesDistanceToString()
    );
  }

  getIsCorridorOrCrossroadChanged$(form: FormGroup) {
    return combineLatest([
      form.get('corridor')?.valueChanges || of(null),
      form.get('crossroad')?.valueChanges.pipe(startWith(null)) || of(null),
    ]).pipe(
      debounceTime(100),
      distinctUntilChanged(),
      tap(firstValues => {
        if (!this.firstCorridorCrossroadFormValues) {
          this.firstCorridorCrossroadFormValues = firstValues;
        }
      }),
      map(
        ([v1, v2]) =>
          (v1 !== null && v1 !== this.firstCorridorCrossroadFormValues?.[0]) ||
          (v2 !== null && v2 !== this.firstCorridorCrossroadFormValues?.[1])
      ),
      this.updateSegmentServiceAboutChangedAliases(form)
    );
  }

  getSlicedFormFieldOptionsByMaxAmount(options: FormFieldOption[]): FormFieldOption[] {
    return options.length > this.MAX_OPTIONS ? options.slice(0, this.MAX_OPTIONS) : options;
  }

  getUniqueFormFieldOptions(options: FormFieldOption[]): FormFieldOption[] {
    const uniqueOptions: FormFieldOption[] = [];
    options.forEach(option => {
      const isFoundOption = uniqueOptions.some(uniqueOption => uniqueOption.value === option.value);
      if (!isFoundOption) {
        uniqueOptions.push(option);
      }
    });

    return uniqueOptions;
  }

  getUpdatedAliasType(form: FormGroup): SegmentAliasType {
    const isCorridorChanged = this.isCorridorOrCrossroadChanged(form, 'corridor'),
      isCrossroadChanged = this.isCorridorOrCrossroadChanged(form, 'crossroad');
    if (
      this.segmentNamesAlias.crossroad &&
      this.segmentNamesAlias.corridor &&
      isCorridorChanged &&
      isCrossroadChanged
    ) {
      return SegmentAliasType.Both;
    } else if (this.segmentNamesAlias.corridor && isCorridorChanged) {
      return SegmentAliasType.Corridor;
    } else if (this.segmentNamesAlias.crossroad && isCrossroadChanged) {
      return SegmentAliasType.Crossroad;
    }

    return SegmentAliasType.None;
  }

  getCreatedAliasType(form: FormGroup): SegmentAliasType {
    const isCorridorChanged = this.isCorridorOrCrossroadChanged(form, 'corridor'),
      isCrossroadChanged = this.isCorridorOrCrossroadChanged(form, 'crossroad');

    if (isCorridorChanged && isCrossroadChanged) {
      return SegmentAliasType.Both;
    } else if (isCorridorChanged) {
      return SegmentAliasType.Corridor;
    } else if (isCrossroadChanged) {
      return SegmentAliasType.Crossroad;
    }

    return SegmentAliasType.None;
  }

  convertOptionsToUniqueGroupedOptions(
    options: FormFieldOption[],
    field: 'corridor' | 'crossroad' | 'milemarker',
    form: FormGroup,
    segmentRoadType?: RoadType
  ) {
    const control = form?.get(field);
    if (!control) {
      console.error('Form is not provided or it does not have control with name:', field);
      return [];
    }

    const selectedOption: FormFieldOption = {
      value: control.value,
      displayName: control.value,
      groupLabel: segmentRoadType ? (isHighway(segmentRoadType) ? GroupNames.highway : GroupNames.street) : undefined,
      startIcon: segmentRoadType ? (isHighway(segmentRoadType) ? IconNames.highway : IconNames.street) : undefined,
      data: {
        distance: this.distanceFormatPipe.transform(0),
      },
    };
    options.push(selectedOption);
    const uniqueOptions = this.getUniqueFormFieldOptions(cloneDeep(this._sortByData(options)));
    return this.convertToGroupOptions(uniqueOptions, field);
  }

  getAddressAutoCompleteOptionsFromSegmentDetails(segmentDetails: SegmentDetails) {
    return {
      corridorOptions: [
        ...this.createAliasOptionAndPlaceFirst(
          segmentDetailsToOptions(segmentDetails.segmentsNames),
          segmentDetails.overridingCorridorAlias
        ),
      ],
      crossroadsOptions: [
        ...this.createAliasOptionAndPlaceFirst(
          segmentDetailsToOptions(segmentDetails.segmentsNames),
          segmentDetails.overridingCrossroadAlias
        ),
      ],
      milemarkersOptions: strArrayToOptions(segmentDetails.milemarkers),
    };
  }

  verifyValidPoint(val: Point): val is Point & { coordinates: number[] } {
    return !!(val && val.coordinates && val.coordinates[0] && val.coordinates[1] && val.type === 'Point');
  }

  updateSegmentServiceAboutChangedAliases(form: FormGroup) {
    return tap((value: boolean) => {
      const createdAliasType: SegmentAliasType = this.getCreatedAliasType(form);
      const updatedAliasType: SegmentAliasType = this.getUpdatedAliasType(form);
      this.segmentService.isAddedOrEditedCustomAlias$.next({
        isAlias: value,
        createdAliasType: createdAliasType,
        updatedAliasType: updatedAliasType,
      });
    });
  }

  isFieldMandatory(field: keyof IncidentConfiguration) {
    {
      const config: IncidentConfiguration | undefined =
        this.accountService[entityTypeToConfigurationMapper[this.entityType]];
      return !!config?.[field];
    }
  }

  requiredFromConfiguration(field: keyof IncidentConfiguration): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (
        this.isFieldMandatory(field) &&
        (control?.value === '' || control?.value === undefined || control?.value === null)
      ) {
        return { required: true };
      }

      return null;
    };
  }

  private createAliasOptionAndPlaceFirst(
    options: AutoCompleteOption<unknown, unknown>[],
    aliasName?: string | null
  ): AutoCompleteOption<unknown, unknown>[] {
    if (typeof aliasName !== 'string') {
      return options;
    }

    const foundIdx = options.findIndex(elm => elm.value === aliasName);
    const extraLineField = {
      value: this.translateService.instant('verifiedAlias'),
      cssClass: 'verified-info',
      startIcon: 'badge-check',
    } as const;

    // foundIdx === -1 if the aliasName is a free text
    if (foundIdx === -1) {
      return [
        {
          value: aliasName,
          displayName: aliasName,
          forceAsFirstOption: true,
          extraLineField,
        },
        ...options,
      ];
    }

    const foundAliasOption = options.splice(
      options.findIndex(elm => elm.value === aliasName),
      1
    )[0];

    foundAliasOption.extraLineField = extraLineField;
    foundAliasOption.forceAsFirstOption = true;

    options.unshift(foundAliasOption);

    return options;
  }

  private transformSegmentNamesDistanceToString() {
    return map((segmentDetails: SegmentDetails | undefined) => {
      if (!segmentDetails?.segmentsNames) return segmentDetails;
      segmentDetails.segmentsNames = segmentDetails.segmentsNames?.map(name => ({
        ...name,
        distance:
          typeof name.distance === 'number' ? this.distanceFormatPipe.transform(name.distance) : (name.distance as any),
      }));
      return segmentDetails;
    });
  }

  private _sortByData(options: AutoCompleteOption<unknown>[]): FormFieldOption[] {
    return options.sort((option1, option2) => {
      return (option1.data?.distance as number) > (option2.data?.distance as number) || option2.forceAsFirstOption
        ? 1
        : -1;
    });
  }

  private convertToGroupOptions(
    options: FormFieldOption[],
    field: 'corridor' | 'crossroad' | 'milemarker'
  ): FormFieldOption[] {
    return this.getSlicedFormFieldOptionsByMaxAmount(options).map(option => {
      if (option.data?.distance) option.data.distance = this.distanceFormatPipe.transform(option.data.distance);

      if (field === 'milemarker') {
        return option;
      }

      if (option.data?.roadType) {
        if (isHighway(option.data?.roadType)) {
          option.groupLabel = GroupNames.highway;
          option.startIcon = IconNames.highway;
        } else {
          option.groupLabel = GroupNames.street;
          option.startIcon = IconNames.street;
        }
      }
      const refAliasString = field === 'corridor' ? this.segmentNamesAlias.corridor : this.segmentNamesAlias.crossroad;
      if (option.value !== null && option.value === refAliasString) {
        option.groupLabel = GroupNames.alias;
        option.startIcon = IconNames['location-info'];
      }

      return option;
    });
  }
}
