<div
  class="crash-risk-modal-container"
  *ngIf="crashRisk"
  [class.tablet-mode]="isTabletMode"
  [class.language-hebrew]="this.isHebrew">
  <wc-icon [iconName]="'crash-risk'"></wc-icon>
  <div class="crash-risk-modal-header-container">
    <div class="crash-risk-modal-header">
      <div class="header-first-line">
        <span>
          {{ 'crashRisk' | translate }}
        </span>
        <span class="prediction-status">
          <span class="crash-risk-status" [ngClass]="'status-' + crashRisk.status | lowercase">
            {{ crashRisk.status | lowercase | translate }}
            <i class="crash-risk-status-icon" [ngClass]="'icon-' + crashRisk.status | lowercase"></i>
          </span>
        </span>
      </div>

      <div class="header-second-line" *ngIf="crashRisk.startAddress?.corridor || crashRisk.startAddress?.crossroad">
        <span class="crash-risk-modal-title">
          {{ crashRisk.startAddress.corridor }} {{ crashRisk.startAddress.direction }}
          {{ crashRisk.startAddress.orientation | lowercase }}
          {{ crashRisk.startAddress.crossroad }}
        </span>
      </div>

      <div class="header-third-line">
        <span class="prediction-status">
          <span class="prediction-time">
            {{ crashRisk.startTime | timeFormatMode: accountStore.account.regionalSetting.timeFormat }} -
            {{ crashRisk.endTime | timeFormatMode: accountStore.account.regionalSetting.timeFormat }},
            {{ crashRisk.startTime | dateFormat: accountStore.account.regionalSetting:'mediumDateNoComma' }}
          </span>
        </span>
      </div>
    </div>
  </div>

  <div class="crash-risk-modal-body">
    <div class="address-container">
      <div class="address-left-panel">
        <span class="from-box top">
          {{ 'congestion.from' | translate }}
        </span>
        <span class="to-box bottom">
          {{ 'congestion.to' | translate }}
        </span>
      </div>
      <div class="address-right-panel">
        <div class="address-line">
          <span
            class="address-line-from"
            *ngIf="crashRisk.startAddress?.corridor || crashRisk.startAddress?.crossroad; else missingStartAddress">
            {{ crashRisk.startAddress.corridor }}
            {{ crashRisk.startAddress.crossroad }}
            {{ crashRisk.startAddress.direction }}
          </span>
          <ng-template #missingStartAddress>
            <span class="address-line-from">
              {{ 'missingStartAddress' | translate }}
            </span>
          </ng-template>
        </div>
        <div class="distance-in-miles">
          {{ crashRisk.distance | distanceFormat }}
        </div>
        <div class="address-line-to">
          <span *ngIf="crashRisk.endAddress?.corridor || crashRisk.endAddress?.crossroad; else missingEndAddress">
            {{ crashRisk.endAddress.corridor }}
            {{ crashRisk.endAddress.crossroad }}
            {{ crashRisk.endAddress.direction }}
          </span>

          <ng-template #missingEndAddress>
            {{ 'missingEndAddress' | translate }}
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
