import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { TextFieldModule } from '../../form-components/text-field/text-field.module';
import { CheckboxListControlModule } from '../checkbox-list-control/checkbox-list-control.module';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { CheckboxFreeTextListComponent } from './checkbox-free-text-list.component';

@NgModule({
  declarations: [CheckboxFreeTextListComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatCheckboxModule,
    WcIconModule,
    FormsModule,
    ReactiveFormsModule,
    TextFieldModule,
    CheckboxListControlModule,
  ],
  exports: [CheckboxFreeTextListComponent],
})
export class CheckboxFreeTextListModuleModule {}
