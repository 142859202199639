import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WcChipModule, WcIconModule } from '@wc/wc-ui/src';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FormatsPipesModule } from '../pipes/formats.pipes.module';
import { UnitCardComponent } from './unit-card/unit-card.component';
import { UnitCardsListComponent } from './unit-cards-list/unit-cards-list.component';

@NgModule({
  declarations: [UnitCardComponent, UnitCardsListComponent],
  imports: [CommonModule, TranslateModule, SwiperModule, FormatsPipesModule, WcChipModule, WcIconModule],
  exports: [UnitCardComponent, UnitCardsListComponent],
})
export class UnitCardComponentsModule {}
