import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { isEqual } from 'lodash';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { PaginationOptions } from 'swiper/types/components/pagination';
import { DefaultSwiperConfig, WeatherEventStatus } from '../core/models/enums';
import {
  AssociatedCameraInput,
  CreateIncidentMitigationInput,
  DeleteIncidentMitigationInput,
  EntityType,
  Incident,
  IncidentCrashSubType,
  IncidentDamageSubType,
  IncidentDebrisSubType,
  IncidentHazardSubType,
  IncidentInvolvedVehicle,
  IncidentLaneDetails,
  IncidentMitigation,
  IncidentPoliceActivitySubType,
  IncidentStalledVehicleSubType,
  IncidentType,
  IncidentUnit,
  UnitResponse,
  UpdateIncidentMitigationInput,
  VehicleType,
  WeatherAlertView,
} from '../core/models/gql.models';
import { MainEntityType } from '../core/models/LiveMapEntity.type';
import { MitigationsDiff, UIEntity, UIWeatherEventEntity } from '../core/models/models';
import { SelectOption } from '../features/ui/form-controls/form-models';
import { EnumToLowercaseValuesArray } from './enumToLowercaseValuesArray';

export const incidentTypeTree = {
  [IncidentType.AbandonedVehicle]: null,
  [IncidentType.Crash]: IncidentCrashSubType,
  [IncidentType.Damage]: IncidentDamageSubType,
  [IncidentType.Debris]: IncidentDebrisSubType,
  [IncidentType.Ems]: null,
  [IncidentType.Hazard]: IncidentHazardSubType,
  [IncidentType.LeftOnArrival]: null,
  [IncidentType.PoliceActivity]: IncidentPoliceActivitySubType,
  [IncidentType.StalledVehicle]: IncidentStalledVehicleSubType,
  [IncidentType.TrafficStop]: null,
  [IncidentType.VehicleOnFire]: null,
  [IncidentType.Unidentified]: null,
  [IncidentType.TrafficAnomaly]: null,
  [IncidentType.WrongWay]: null,
  [IncidentType.Other]: null,
};

export function flat(arr: any[]) {
  return arr.reduce((acc, val) => acc.concat(val), []);
}

// (Function as any).deserialise = function(key, data) {
//   return (data instanceof Array && data[0] == 'window.Function') ?
//       new (Function.bind.apply(Function, [Function].concat(data[1], [data[2]]))) :
//       data
//   ;
// };

(Function as any).prototype.toJSON = function () {
  var whitespace = /\s/;
  var pair = /\(\)|\[\]|\{\}/;

  var args = new Array();
  var string = this.toString();

  var fat = new RegExp('^s*(' + (this.name ? this.name + '|' : '') + 'function' + ')[^)]*\\(').test(string);

  var state = 'start';
  var depth = new Array();
  var tmp;

  for (var index = 0; index < string.length; ++index) {
    var ch = string[index];

    switch (state) {
      case 'start':
        if (whitespace.test(ch) || (fat && ch != '(')) continue;

        if (ch == '(') {
          state = 'arg';
          tmp = index + 1;
        } else {
          state = 'singleArg';
          tmp = index;
        }
        break;

      case 'arg':
      case 'singleArg':
        var escaped = depth.length > 0 && depth[depth.length - 1] == '\\';
        if (escaped) {
          depth.pop();
          continue;
        }
        if (whitespace.test(ch)) continue;

        switch (ch) {
          case '\\':
            depth.push(ch);
            break;

          case ']':
          case '}':
          case ')':
            if (depth.length > 0) {
              if (pair.test(depth[depth.length - 1] + ch)) depth.pop();
              continue;
            }
            if (state == 'singleArg') throw '';
            args.push(string.substring(tmp, index).trim());
            state = fat ? 'body' : 'arrow';
            break;

          case ',':
            if (depth.length > 0) continue;
            if (state == 'singleArg') throw '';
            args.push(string.substring(tmp, index).trim());
            tmp = index + 1;
            break;

          case '>':
            if (depth.length > 0) continue;
            if (string[index - 1] != '=') continue;
            if (state == 'arg') throw '';
            args.push(string.substring(tmp, index - 1).trim());
            state = 'body';
            break;

          case '{':
          case '[':
          case '(':
            if (depth.length < 1 || !(depth[depth.length - 1] == '"' || depth[depth.length - 1] == "'")) depth.push(ch);
            break;

          case '"':
            if (depth.length < 1) depth.push(ch);
            else if (depth[depth.length - 1] == '"') depth.pop();
            break;
          case "'":
            if (depth.length < 1) depth.push(ch);
            else if (depth[depth.length - 1] == "'") depth.pop();
            break;
        }
        break;

      case 'arrow':
        if (whitespace.test(ch)) continue;
        if (ch != '=') throw '';
        if (string[++index] != '>') throw '';
        state = 'body';
        break;

      case 'body':
        if (whitespace.test(ch)) continue;
        string = string.substring(index);

        if (ch == '{') string = string.replace(/^{\s*(.*)\s*}\s*$/, '$1');
        else string = 'return ' + string.trim();

        index = string.length;
        break;

      default:
        throw '';
    }
  }

  return ['window.Function', args, string];
};

export const isString = function (arg: any) {
  return typeof arg === 'string';
};

export const isObject = function (arg: any) {
  return arg && typeof arg === 'object';
};

export const isPlainObj = (o: any): boolean => {
  return typeof o == 'object' && o.constructor == Object;
};

export const coerceArray = (value: any) => {
  return Array.isArray(value) ? value : [];
};

export const isEmptyObj = function (obj) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
};

export const sortObjectKeys = function (obj: object) {
  const ordered = {};
  Object.keys(obj)
    .sort()
    .forEach(function (key) {
      ordered[key] = obj[key];
    });
  return ordered;
};

export function toObjectWithId(arr: any[]) {
  const rv = {};
  for (let i = 0; i < arr.length; ++i) if (arr[i] !== undefined) rv[arr[i].id] = arr[i];
  return rv;
}

export function toArray(obj) {
  if (obj) {
    return Object.keys(obj).map(function (key) {
      return obj[key];
    });
  } else {
    return [];
  }
}

export function arraysEqual(a1: Array<any>, a2: Array<any>) {
  return JSON.stringify(a1?.sort()) === JSON.stringify(a2?.sort());
}

export function clearCamerasSrcUrl(cameras: any[]) {
  cameras = cameras.map(incidentCamera => {
    if (incidentCamera.camera) incidentCamera.camera.imageUrl = incidentCamera.camera.imageUrl?.split('?')[0];
    return incidentCamera;
  });
  return cameras;
}

export function camerasArraysDiff(srcCameras, modifiedCameras) {
  let modified: AssociatedCameraInput[] = [];
  modifiedCameras = clearCamerasSrcUrl(modifiedCameras);
  // modifiedCameras.map( incidentCamera => {
  //     incidentCamera.camera.imageUrl = incidentCamera.camera.imageUrl.split('?')[0];
  //     return incidentCamera;
  //   });
  const diff = entitiesArraysDiff(srcCameras, modifiedCameras, {
    entityFiledName: 'camera',
  });
  const removed = diff.removed;

  diff.modified.forEach(_cam => {
    modified.push({ cameraExternalId: _cam.camera?.externalId, default: _cam.default });
  });

  //Checking for default camera change.
  const defaultCamChanges: AssociatedCameraInput[] = [];
  modifiedCameras.forEach(cam => {
    const srcCam = srcCameras.find(modCam => modCam.camera?.id === cam.camera?.id && modCam.default !== cam.default);
    let incidentCamera: AssociatedCameraInput;

    if (srcCam) {
      incidentCamera = { cameraExternalId: cam.camera?.externalId, default: cam.default };
      const camIndex = modified.findIndex(_cam => _cam.cameraExternalId === incidentCamera.cameraExternalId);
      if (camIndex !== -1) {
        modified[camIndex].default = incidentCamera.default;
      } else {
        defaultCamChanges.push(incidentCamera);
      }
    }
  });

  modified = [...defaultCamChanges, ...modified];
  return { modified, removed };
}

export function affectedLanesArrayDiff(srcLanes, modifiedLanes) {
  const removed: any[] = [];
  let modified: any[] = [];

  const created = modifiedLanes.filter(lane => !lane.hasOwnProperty('id') || lane.id === null);
  created.forEach(lane => {
    delete lane.id;
  });

  const restLanes = modifiedLanes.filter(lane => lane.hasOwnProperty('id') && lane.id !== null);

  srcLanes.forEach(srcLane => {
    const find = restLanes.find(lane => lane.id === srcLane.id);
    if (find === undefined) {
      if (srcLane.id) removed.push(srcLane.id);
    } else {
      modified.push(find);
    }
  });

  modified = getObjectsModifiedFromArrayById(srcLanes, modified);

  return {
    created,
    modified,
    removed,
  };
}

export function sortAffectedLanes(affectedLanes) {
  affectedLanes.sort((a, b) => {
    return a.positionIndex > b.positionIndex ? 1 : -1;
  });
  return affectedLanes;
}
/**
 * @deprecated please use @name sortAnalyticsAffectedLanes
 */
export function sortIncidentsAnalyticsAffectedLanes(affectedLanes: Array<IncidentLaneDetails>) {
  affectedLanes.sort((a, b) => {
    return a.incidentLane.positionIndex > b.incidentLane.positionIndex ? 1 : -1;
  });
  return affectedLanes;
}

export function sortAnalyticsAffectedLanes<T>(affectedLanes: T[], key: keyof T): T[] {
  affectedLanes?.sort((a, b) => {
    return a[key as string]?.positionIndex > b[key as string]?.positionIndex ? 1 : -1;
  });
  return affectedLanes;
}

export function removeUpdatedAtFromAffectedLanes(affectedLanes: Array<any>) {
  if (affectedLanes) {
    affectedLanes.forEach(lane => {
      delete lane.updatedAt;
    });
    return affectedLanes;
  } else return [];
}

export function refactorAffectedLanesIds(affectedLanes: Array<any>) {
  if (affectedLanes) {
    affectedLanes.forEach(lane => {
      const idValue = lane.id?.value;
      if (idValue) {
        delete lane.id;
        lane['id'] = idValue;
      }
    });
    return affectedLanes;
  } else return [];
}

export function refactorAssociatedUnitsIds(associatedUnits: Array<IncidentUnit>) {
  if (associatedUnits) {
    associatedUnits.forEach(unit => {
      if (unit?.id) {
        unit.id = unit.id;
      }
      if (unit?.accountId) {
        unit.accountId = unit.accountId;
      }
      if (unit?.driverDetails?.userId) {
        unit.driverDetails.userId = unit.driverDetails.userId;
      }
    });
    return associatedUnits;
  } else return [];
}

export function associatedUnitsDiff(incidentUnits: Array<IncidentUnit>, modifiedUnits: Array<IncidentUnit>) {
  const created: Array<IncidentUnit> = [];
  const updated: Array<IncidentUnit> = [];
  const deleted: Array<IncidentUnit> = [];
  const notChanged: Array<IncidentUnit> = [];

  incidentUnits.forEach(incidentUnit => {
    const unit = modifiedUnits.find(
      _modifiedUnit =>
        _modifiedUnit.id === incidentUnit.id &&
        _modifiedUnit.driverDetails?.userId === incidentUnit.driverDetails?.userId
    );

    if (!unit) {
      deleted.push(incidentUnit);
    } else {
      if (incidentUnit.unitResponse !== unit.unitResponse) {
        updated.push(unit);
      } else {
        notChanged.push(incidentUnit);
      }
    }
  });

  modifiedUnits.forEach(modifiedUnit => {
    const unit = incidentUnits.find(
      _unit => modifiedUnit.id === _unit.id && modifiedUnit.driverDetails?.userId === _unit.driverDetails?.userId
    );
    if (!unit) created.push(modifiedUnit);
  });

  return {
    created,
    updated,
    deleted,
    notChanged,
  };
}

export function mitigationsDiff(
  incidentMitigations: IncidentMitigation[],
  modifiedMitigations: IncidentMitigation[],
  associatedUnit: Partial<IncidentUnit>
): MitigationsDiff {
  const created: CreateIncidentMitigationInput[] = [];
  const modified: UpdateIncidentMitigationInput[] = [];
  const removed: DeleteIncidentMitigationInput[] = [];

  const authUserCurrentMitigations = incidentMitigations.filter(
    incidentMitigation =>
      incidentMitigation.unitId === associatedUnit.id &&
      incidentMitigation.userId === associatedUnit.driverDetails?.userId
  );
  const authUserModifiedMitigations = modifiedMitigations.filter(
    _modifiedMitigations =>
      _modifiedMitigations.unitId === associatedUnit.id &&
      _modifiedMitigations.userId === associatedUnit.driverDetails?.userId
  );

  authUserCurrentMitigations.forEach(authUserCurrentMitigation => {
    const modifiedMitigation = authUserModifiedMitigations.find(
      _modifiedMitigation => _modifiedMitigation.mitigationType.id === authUserCurrentMitigation.mitigationType.id
    );

    if (modifiedMitigation) {
      if (!isEqual(modifiedMitigation.interval, authUserCurrentMitigation.interval)) {
        modified.push({
          driverId: modifiedMitigation.userId,
          id: modifiedMitigation.id,
          interval: modifiedMitigation.interval,
          mitigationTypeId: modifiedMitigation.mitigationType.id,
          unitId: modifiedMitigation.unitId,
        });
      }
    } else {
      removed.push({
        driverId: authUserCurrentMitigation.userId,
        id: authUserCurrentMitigation.id,
      });
    }
  });

  authUserModifiedMitigations.forEach(authUserModifiedMitigation => {
    const mitigation = authUserCurrentMitigations.find(
      _mitigation => authUserModifiedMitigation.mitigationType.id === _mitigation.mitigationType.id
    );
    if (!mitigation) {
      created.push({
        driverId: authUserModifiedMitigation.userId,
        interval: authUserModifiedMitigation.interval,
        mitigationTypeId: authUserModifiedMitigation.mitigationType.id,
        unitId: authUserModifiedMitigation.unitId,
      });
    }
  });

  return {
    created,
    modified,
    removed,
  };
}

export function unitMitigationsDiff(
  mitigations: Array<IncidentMitigation>,
  modifiedMitigations: Array<IncidentMitigation>,
  associatedUnitsDiff: {
    created?: Array<IncidentUnit>;
    updated?: Array<IncidentUnit>;
    deleted?: Array<IncidentUnit>;
    notChanged?: Array<IncidentUnit>;
  }
) {
  let created: Array<IncidentMitigation> = [];
  let deleted: Array<IncidentMitigation> = [];
  const updated: Array<IncidentMitigation> = [];

  /**
   * if associatedUnit was added => move those mitigations to created
   * */
  associatedUnitsDiff.created?.forEach(_unit => {
    created = created.concat(
      modifiedMitigations.filter(
        mitigation => mitigation.unitId === _unit.id && mitigation.userId === _unit.driverDetails?.userId
      )
    );
  });

  /**
   * if associatedUnit was deleted  => move those mitigations to delete
   * */
  associatedUnitsDiff.deleted?.forEach(_unit => {
    deleted = deleted.concat(
      mitigations.filter(
        mitigation => mitigation.unitId === _unit.id && mitigation.userId === _unit.driverDetails?.userId
      )
    );
  });

  /**
   * if unit changed status from mitigated to another one => remove mitigations
   * if unit changed status from on_scene or on_route to mitigated => add mitigations
   */
  associatedUnitsDiff.updated?.forEach(_unit => {
    if (_unit.unitResponse === UnitResponse.Mitigated) {
      created = created.concat(
        modifiedMitigations.filter(
          _mitigation => _mitigation.unitId === _unit.id && _mitigation.userId === _unit.driverDetails?.userId
        )
      );
    } else {
      deleted = deleted.concat(
        mitigations.filter(
          mitigation => mitigation.unitId === _unit.id && mitigation.userId === _unit.driverDetails?.userId
        )
      );
    }
  });

  /**
   * check mitigations for users that were not changed
   */
  associatedUnitsDiff.notChanged?.forEach(_unit => {
    let unitMitigations = modifiedMitigations.filter(
      _mitigation => _mitigation.unitId === _unit.id && _mitigation.userId === _unit.driverDetails?.userId
    );

    unitMitigations.forEach(_unitMitigation => {
      const isMitigation = mitigations.find(
        _mitigation =>
          _unitMitigation.mitigationType.id === _mitigation.mitigationType.id &&
          _mitigation.userId === _unit.driverDetails?.userId &&
          _mitigation.unitId === _unit.id
      );

      if (!isMitigation) {
        created.push(_unitMitigation);
      } else {
        if (!_.isEqual(isMitigation.interval, _unitMitigation.interval)) {
          updated.push(_unitMitigation);
        }
      }
    });

    unitMitigations = mitigations.filter(
      _mitigation => _mitigation.unitId === _unit.id && _mitigation.userId === _unit.driverDetails?.userId
    );
    unitMitigations.forEach(_unitMitigation => {
      const isMitigation = modifiedMitigations.find(
        _mitigation =>
          _unitMitigation.mitigationType.id === _mitigation.mitigationType.id &&
          _mitigation.userId === _unit.driverDetails?.userId &&
          _mitigation.unitId === _unit.id
      );
      if (!isMitigation) deleted.push(_unitMitigation);
    });
  });

  return {
    created,
    updated,
    deleted,
  };
}

export function involvedVehicleDiff(
  srcArray: Array<IncidentInvolvedVehicle>,
  modifiedArray: Array<IncidentInvolvedVehicle>
) {
  let created: any[] = [];
  const modified: any[] = [];
  const removed: any[] = [];
  created = modifiedArray.filter(vehicle => !vehicle.id).map(_vehicle => _vehicle);
  srcArray.forEach(srcVehicle => {
    const modifiedVehicle = modifiedArray.find(modifiedVehicle => modifiedVehicle.id === srcVehicle.id);
    if (!modifiedVehicle) {
      removed.push(srcVehicle.id);
    } else {
      if (!isEqual(modifiedVehicle, srcVehicle)) {
        modified.push(modifiedVehicle);
      }
    }
  });

  return {
    created,
    modified,
    removed,
  };
}

export function entitiesArraysDiff<T = any>(
  srcArray,
  modifiedArray,
  options?: { entityFiledName?: string; key?: string }
) {
  const { entityFiledName, key } = options || {};
  const modified: T[] = [];
  const removed: number[] = [];
  srcArray?.forEach(entity => {
    const exist = modifiedArray.find(_entity => {
      if (entityFiledName) {
        return entity[entityFiledName][key || 'id'] === _entity[entityFiledName][key || 'id'];
      } else {
        return entity[key || 'id'] === _entity[key || 'id'];
      }
    });
    if (!exist) {
      if (entityFiledName) {
        removed.push(entity[entityFiledName][key || 'id']);
      } else {
        removed.push(entity[key || 'id']);
      }
    }
  });

  modifiedArray?.forEach(entity => {
    let exist, modifiedEntity;
    if (entityFiledName) {
      exist = srcArray.find(_entity => {
        exist = entity[entityFiledName][key || 'id'] === _entity[entityFiledName][key || 'id'];
        if (exist)
          modifiedEntity =
            JSON.stringify(sortObjectKeys(entity[entityFiledName])) !==
            JSON.stringify(sortObjectKeys(_entity[entityFiledName]));
        return exist;
      });
    } else {
      exist = srcArray.find(_entity => {
        exist = entity[key || 'id'] === _entity[key || 'id'];
        if (exist) modifiedEntity = JSON.stringify(sortObjectKeys(entity)) !== JSON.stringify(sortObjectKeys(_entity));
        return exist;
      });
    }
    if (!exist || modifiedEntity) {
      modified.push(entity);
    }
  });

  return { modified, removed };
}

export function getObjectsRemovedFromArrayById(srcArray, modifiedArray) {
  const key = 'id';
  const removed: any[] = [];
  srcArray.forEach(entity => {
    const exist = modifiedArray.find(_entity => entity[key] === _entity[key]);
    if (!exist) {
      removed.push(entity[key]);
    }
  });
  return removed;
}

export function getObjectsModifiedFromArrayById(srcArray, modifiedArray) {
  const key = 'id';
  const modified: any[] = [];
  srcArray.forEach(entity => {
    const item = modifiedArray.find(_entity => entity[key] === _entity[key]);
    if (item && !_.isEqual(entity, item)) {
      modified.push(item);
    }
  });

  return modified;
}

export function getNewObjectsInArrayById(srcArray, modifiedArray) {
  const key = 'id';
  const created: any[] = [];
  modifiedArray.forEach(item => {
    const exist = srcArray.find(_item => item[key] === _item[key]);
    if (!exist) {
      created.push(item);
    }
  });
  return created;
}

export function UUID(parts: number): string {
  const stringArr: string[] = [];
  for (let i = 0; i < parts; i++) {
    // tslint:disable-next-line:no-bitwise
    const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    stringArr.push(S4);
  }
  return stringArr.join('-');
}

export function randomString(length: number): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let result = '';
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function toStringNotNull(value) {
  return value ? value : '';
}

// export function fromMobx<T>( expression: () => T ) : Observable<T> {
//   return new Observable(observer => {
//     const computedValue = computed(expression);
//     const disposer = computedValue.observe(changes => {
//       observer.next(changes.newValue);
//     }, true);

//     return () => {
//       if (disposer) {
//         disposer();
//       }
//     }
//   });
// }

export function unregisterServiceWorker() {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}

export function preloadImage(src: string) {
  const img = new Image();
  img.src = src;
}

export function objectDiff(obj, modifiedObj) {
  const objDiff = {};
  for (let key of Object.keys(obj)) {
    if (!isEqual(obj[key], modifiedObj[key]) && obj[key] != modifiedObj[key] && modifiedObj[key] !== undefined) {
      objDiff[key] = modifiedObj[key];
    }
  }
  return objDiff;
}

export const alphaNumericSort = function (arr: Array<any>, key: any, order: string = 'asc'): Array<any> {
  const lettersReg = /[^a-zA-Z\u0590-\u05fe]/g;
  const numbersReg = /[^0-9]/g;

  if (!arr) return [];
  arr = arr.sort((a, b) => {
    var _a = a[key]?.replace(lettersReg, '');
    var _b = b[key]?.replace(lettersReg, '');
    if (_a === _b) {
      var _numberA = parseInt(a[key]?.replace(numbersReg, ''), 10);
      var _numberB = parseInt(b[key]?.replace(numbersReg, ''), 10);
      return _numberA === _numberB ? 0 : _numberA > _numberB ? 1 : -1;
    } else {
      return _a > _b ? 1 : -1;
    }
  });
  return arr;
};

export const selectedOptionsAlphaNumericSort = function (
  arr: Array<SelectOption>,
  order: string = 'asc'
): Array<SelectOption> {
  return alphaNumericSort(arr, 'displayName', order);
};

/**
 * Compare two arrays and check if there is the same values in both of them. If yes, it will return array with
 * such equal values. Boolean operation between two arrays.
 * @returns Array with equal cells that exists in both array
 */
export function compareTwoArrays(arrA: any[], arrB: any[]): any[] {
  if (!arrA.length || !arrB.length) return [];
  const arrAObj = arrA.reduce((acc, curr) => ((acc[curr] = ''), acc), {});
  const res: any = [];
  arrB.forEach(item => {
    if (arrAObj.hasOwnProperty(item)) res.push(item);
  });
  return res;
}

export function isValidId(id: any) {
  return !(typeof id === 'string' && id.indexOf('temp') === 0);
}

export function getAffectedLanesShorts(incident: any, translateService: TranslateService): any[] {
  const affectedLanesArray: string[] = [];
  let closedAffectedLanes = incident?.affectedLanes?.filter(affectedLane => {
    return affectedLane.isClosed;
  });
  closedAffectedLanes?.forEach((affectedLane, index) => {
    let affectedLaneItem = translateService.instant('incidentTitleAffectedLanesShort.' + affectedLane.type);
    if (
      affectedLane.type === 'RIGHT_LANE' ||
      affectedLane.type === 'LEFT_LANE' ||
      affectedLane.type === 'CENTRAL_LANE'
    ) {
      affectedLaneItem += affectedLane.number;
    }
    if (index !== closedAffectedLanes?.length - 1) {
      affectedLaneItem += ',';
    }
    affectedLanesArray.push(affectedLaneItem);
  });

  return affectedLanesArray;
}

export function clickElByTagWhenReady(tagName = 'body'): void {
  document.readyState === 'complete'
    ? (document.getElementsByTagName(tagName)[0] as HTMLElement).click()
    : clickElByTagWhenReady();
}

export interface BaseObjectKeyInterFace {
  [key: string]: any;
}

export function featureSubTypeOf(entityType) {
  if (entityType === 'transit_demand_response_unit') return 'transit_demand_response_unit';

  const incidentTypes = EnumToLowercaseValuesArray(IncidentType);
  if (incidentTypes.includes(entityType)) return MainEntityType.incident;

  const unitTypes = EnumToLowercaseValuesArray(VehicleType);
  if (unitTypes.includes(entityType)) return MainEntityType.unit;

  if (entityType.includes('wr_')) return MainEntityType.work_request;
  if (entityType === 'camera' || entityType === 'dms') return MainEntityType.asset;
  if (entityType === 'road_closure' || entityType === 'construction') return 'traffic_disruption';
  // TODO: add types.
  return undefined;
}

export function removeRgxSpecialCharsFromString(string: string): string {
  return string.replace(/[(|)|[|]|]/g, '');
}

export function downloadFile(url) {
  window.open(url, '_self');
}

export function formatNumberToDate(number: number) {
  return new Date(0, 0, 0, 0, 0, number);
}

export function getSwipeConfigeObj(
  numberOfEntities: number,
  uniqueIdentifier: string,
  obj?: Partial<SwiperConfigInterface>
): SwiperConfigInterface {
  return {
    direction: obj?.direction || DefaultSwiperConfig.direction,
    slidesPerView: obj?.slidesPerView || DefaultSwiperConfig.slidesPerView,
    spaceBetween: obj?.spaceBetween || DefaultSwiperConfig.spaceBetween,
    threshold: obj?.threshold || DefaultSwiperConfig.threshold,
    autoHeight: obj?.autoHeight || false,
    navigation: {
      prevEl: `.${uniqueIdentifier}-${DefaultSwiperConfig.prevEl}`,
      nextEl: `.${uniqueIdentifier}-${DefaultSwiperConfig.nextEl}`,
    },
    pagination: {
      el:
        numberOfEntities > (obj?.slidesPerView || DefaultSwiperConfig.slidesPerView)
          ? `.swiper-pagination-${uniqueIdentifier}`
          : null,
      clickable: (obj?.pagination as PaginationOptions)?.clickable ?? true,
    },
  };
}

/**
 * Calculates number of affected lanes slides per view and spacing between them by the current screen resolution.
 * @param innerWidth: number - Current screen width
 * @param isTabletMode: boolean - Is it Tablet mode
 *
 * @returns {
 *  slidesPerView: number - how many slides should be showed in current screen resolution,
 *  spaceBetweenSlides: number - how much space in pixels should be between slides
 * }
 */
export function getAffectedLanesSlidesByScreenWidth(
  innerWidth: number,
  isTabletMode: boolean
): {
  slidesPerView: number;
  spaceBetweenSlides: number;
} {
  switch (true) {
    case !isTabletMode:
      return {
        slidesPerView: 3,
        spaceBetweenSlides: 10,
      };

    case innerWidth >= 1360:
      return {
        slidesPerView: 3,
        spaceBetweenSlides: 60,
      };

    case innerWidth >= 996:
      return {
        slidesPerView: 3,
        spaceBetweenSlides: 20,
      };

    case innerWidth >= 768:
      return {
        slidesPerView: 2,
        spaceBetweenSlides: 10,
      };

    default:
      return {
        slidesPerView: 1,
        spaceBetweenSlides: 40,
      };
  }
}

/**
 * Util function for tablet with subheader.ts sort set to SortOption.Assignee
 * split the income list to 3 separate arrays [assigned to me , assigned to others , not assigned]
 * if `entityType` is undefined first split the entities:[] to incident and work request and preform the filter on each list separately
 * @see NOTE : if theres no entityType means we are filtering the needAction list and all work request in this list are UNASSIGNED by default
 * so no point of flittering them
 * @returns -  {assigned to me:[] , assigned to others:[] , not assigned:[]}
 */

export interface assignedByStatusArraysObj {
  assignedToMe: any[];
  assignedToOther: any[];
  unassigned: any[];
}
export function splitListByAssigneeStatus(
  entities: any[],
  currentUserId?: number,
  entityType?: EntityType,
  currentUserName?: string
): assignedByStatusArraysObj {
  const assignedByStatusObj: assignedByStatusArraysObj = { assignedToMe: [], assignedToOther: [], unassigned: [] };

  let incidents: Incident[] = [];

  // For need action list split to arrays by types
  if (!entityType) {
    entities.forEach(entity => {
      if (entity.featureSubTypeOf === MainEntityType.incident) {
        incidents.push(entity);
      }
    });
  } else if (entityType === EntityType.Incident) {
    incidents = entities;
  }

  if (incidents) {
    incidents.forEach((incident: Incident) => {
      const associatedUsersIds =
        incident.associatedUnits.map(unit => unit.driverDetails?.userId || unit.driverDetails?.userId) || [];
      if (!associatedUsersIds.length) {
        assignedByStatusObj.unassigned.push(incident);
      } else if (associatedUsersIds.includes(currentUserId)) {
        assignedByStatusObj.assignedToMe.push(incident);
      } else {
        assignedByStatusObj.assignedToOther.push(incident);
      }
    });
  }

  return assignedByStatusObj;
}

export function asUIEntity<T>(entity): UIEntity<T> {
  return {
    data: entity,
    ui: {
      featureType: entity.subType as string,
      featureSubTypeOf: entity.type as string,
    },
  };
}

export function asWeatherEventUIEntity(entity: WeatherAlertView): UIWeatherEventEntity {
  return {
    data: entity,
    ui: {
      isViewed: false,
      status: WeatherEventStatus.Active,
      featureType: entity.subType as string,
      featureSubTypeOf: entity.type as string,
    },
  };
}

// 24 hours format - example 15:37
export function formatDuration(minutes: number = 0) {
  const min = minutes % 60;
  const hours = Math.floor(minutes / 60);

  return (hours < 10 ? '0' + hours : hours) + ':' + (min < 10 ? '0' + min : min);
}

export const WAIT_TIME_TO_SHOW_EXPIRE_WARNING_IN_MIN = 10;

export const EXPIRE_TIME_AFTER_WARNING_SHOW_IN_MIN = 5;

export const WAIT_TIME_TO_RELOAD_PAGE_IN_MIN = 360;

export function isElInViewInsideContainer(
  el: HTMLElement | null | undefined,
  container: HTMLElement | null | undefined,
  effectedElementHeightPercentage: number
): boolean {
  if (!(el && container)) return false;

  const { height, top } = el.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  const cardCenter = top + height / (100 / effectedElementHeightPercentage);
  const containerInView = containerRect?.top + containerRect?.height;
  return cardCenter < containerInView && cardCenter > containerRect?.top;
}
