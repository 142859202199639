import { Injectable } from '@angular/core';
import { wcOverlayOptions } from '../../types';
import { WcOverlay } from '../classes/wc-overlay.class';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  createMapOverlay(options: wcOverlayOptions): WcOverlay {
    return new WcOverlay(options);
  }
}
