import { LiveMapEntity, WcMapConfigModel } from '@wc/core/models';
import Feature from 'ol/Feature';
import { Pointer as PointerInteraction } from 'ol/interaction';
import VectorTileLayer from 'ol/layer/VectorTile';
import Map from 'ol/Map';
import * as Styles from '../styles/styles';

export class SelectEntity extends PointerInteraction {
  map!: Map;
  coordinate_ = null;
  feature_ = null;
  lastSelectedFeature!: Feature;
  lastSelectedEntity!: LiveMapEntity;
  active = true;

  constructor(private selectFeature, private mapConfig: WcMapConfigModel) {
    super();
  }

  handleEvent(e) {
    switch (e.type) {
      case 'click':
        this.click(e);
        break;
      case 'pointermove':
        this.moveEvent(e);
        break;
      default:
        return e;
    }
  }

  unselect() {
    if (this.lastSelectedEntity && this.lastSelectedFeature) {
      this.lastSelectedEntity['selected'] = false;
      this.lastSelectedEntity['hovered'] = false;
      this.lastSelectedFeature.setProperties({ selected: false });
      const lastFeatureStyle = Styles.styleType(this.lastSelectedEntity, this.mapConfig);
      this.lastSelectedFeature.setStyle(lastFeatureStyle);
      this.lastSelectedFeature.changed();
    }
  }

  click(e) {
    if (!this.active) return;
    this.map = e.map;
    const { entities, features } = this.entitiesAtPixel(e.pixel);

    this.unselect();

    if (entities[0]) {
      let feature: Feature = features[0] as Feature;
      const entity: LiveMapEntity = entities[0] as LiveMapEntity;
      this.lastSelectedFeature = feature;
      this.lastSelectedEntity = entity;
      /** Checking if its a cluster */
      const clusteredFeature = features[0].getProperties().features;
      if (clusteredFeature) {
        feature = clusteredFeature[0];
        if (clusteredFeature.length > 1) {
          this.selectFeature(entities, clusteredFeature);
        } else {
          this.selectFeature(entity, clusteredFeature[0]);
        }
      } else this.selectFeature(entity, feature);

      feature.setProperties({ selected: true });
      entity['selected'] = true;
      const style = Styles.styleType(entity, this.mapConfig);
      if (!style || !style.length) return;
      feature.setStyle(style);
    }
  }

  moveEvent(e) {
    if (this.mapConfig.isTabletMode) return;
    this.map = e.map;
    const pixel = this.map.getEventPixel(e.originalEvent);
    const hit = this.map.hasFeatureAtPixel(pixel, {
      layerFilter: layer => {
        return !(layer instanceof VectorTileLayer) && (layer.getOpacity() > 0 || layer.getVisible());
      },
    });
    const elm = this.map.getTarget() as HTMLElement;
    elm.style.cursor = hit ? 'pointer' : '';
  }

  entitiesAtPixel(pixel) {
    let features = this.map.getFeaturesAtPixel(pixel); //, (ft: Layer) => {return ft.getProperties()}
    features = features.filter(feature => feature instanceof Feature);
    const entities: LiveMapEntity[] = [];
    /**
     * TODO: refactor
     */
    features.forEach(_feature => {
      if (_feature.get('features')) {
        _feature.get('features').forEach(feature => {
          entities.push(feature.getProperties() as LiveMapEntity);
        });
      } else {
        entities.push(_feature.getProperties() as LiveMapEntity);
      }
    });

    return { entities: entities, features: features };
  }
}
