import { Injectable } from '@angular/core';
import { action, makeObservable } from 'mobx';
import { Subject } from 'rxjs/internal/Subject';
import { tap } from 'rxjs/operators';
import { WcMapComponent } from '../../wc-map/src/lib/wc-map.component';
import { WcMapConfig } from '../livemap.config';
import {
  CreateRouteTypeInput,
  DeleteRouteTypeInput,
  LineString,
  Point,
  Polygon,
  RouteType,
  UpdateRouteTypeInput,
} from '../models';
import { MapCenterOptions, WcMapConfigModel } from '../models/WCMap.interface';
import { RoutesService } from '../services';
import { AccountStore } from './account.store';
@Injectable({
  providedIn: 'root',
})
export class RoutesStore {
  wcMap!: WcMapComponent;
  wcMapConfig: WcMapConfigModel = WcMapConfig;

  constructor(private routesService: RoutesService, private accountStore: AccountStore) {
    makeObservable(this);
  }

  initMap(wcMap: WcMapComponent) {
    this.wcMap = wcMap;
    this.wcMap.init(this.wcMapConfig, { mouseEventsMode: 'draw' });
    this.applyCurrentMapCenter(this.wcMapConfig.mapCenter);
  }

  applyCurrentMapCenter(coordinates: Array<Array<Array<number>>>, options?: MapCenterOptions) {
    if (!this.wcMap) return;
    options = options || {};
    this.wcMap.setMapLocation(coordinates, options);
  }

  newRoute() {
    if (!this.wcMap) return;
    this.wcMap.wcMapDrawService.create('Polygon');
  }

  editRoute(location: Point | Polygon | LineString) {
    if (!this.wcMap) return;
    this.wcMap.wcMapDrawService.edit(location);
  }

  routeChange(): Subject<Point | LineString | Polygon> | null {
    if (!this.wcMap) return null;
    // this.wcMap.wcMapDrawService.resetLayer();
    return this.wcMap.wcMapDrawService.geometry;
  }

  createRouteType(input: CreateRouteTypeInput) {
    return this.routesService
      .createRouteType(input)
      .pipe(tap((route: RouteType) => this.accountStore.routeTypes.push(route)));
  }

  @action
  updateRouteType(input: UpdateRouteTypeInput) {
    return this.routesService.updateRouteType(input).pipe(
      tap((route: RouteType) => {
        if (this.accountStore.routeTypes) {
          const index = this.accountStore.routeTypes.findIndex(_route => _route.id === route.id);
          this.accountStore.routeTypes[index] = route;
          this.accountStore.routeTypes = [...[], ...this.accountStore.routeTypes];
        }
      })
    );
  }

  @action
  deleteRouteType(input: DeleteRouteTypeInput) {
    return this.routesService.deleteRouteType(input).pipe(
      tap((route: RouteType) => {
        const index = this.accountStore.routeTypes?.findIndex(_route => _route.id === route.id);
        this.accountStore.routeTypes.splice(index, 1);
        this.accountStore.routeTypes = [...[], ...this.accountStore.routeTypes];
      })
    );
  }
}
