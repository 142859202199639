<div class="release-notes-view-component">
  <div
    #menuTrigger="matMenuTrigger"
    class="release-notes-view-component__main-button heap-desktop-show-release-notes"
    [matMenuTriggerFor]="menu"
    [attr.aria-label]="'onboardingTours.mainButton.ariaLabel' | translate">
    <wc-icon iconName="stars"></wc-icon>
    <span>{{ 'releaseNotes.whatsNew' | translate }}</span>
    <span *ngIf="(newReleasesCount | async) !== 0" class="release-notes-view-component__badge">
      {{ newReleasesCount | async }}</span
    >
  </div>

  <mat-menu
    class="release-notes-view-component__menu"
    backdropClass="release-notes-view-component__menu-backdrop"
    #menu="matMenu"
    yPosition="below"
    xPosition="before">
    <div (click)="$event.stopPropagation()">
      <div class="release-notes-view-component__header">
        {{ 'releaseNotes.whatsNewOnCommand' | translate }}
        <button class="release-notes-view-component__close-btn" rekor-icon (click)="closeMenu()">
          <wc-icon iconName="close-new"></wc-icon>
        </button>
      </div>

      <div class="release-notes-view-component__scroll-body">
        <div *ngFor="let release of releaseNotes | async" class="release-notes-view-component__release-container">
          <div class="release-notes-view-component__chip-container">
            <span *ngFor="let tag of release.tags" class="release-notes-view-component__tag-chip">
              {{ tag }}
            </span>
          </div>
          <div class="release-notes-view-component__release-title">
            {{ release.releaseTitle }}
          </div>
          <div class="release-notes-view-component__date">
            {{ release.scheduleReleaseDate | date: 'longDate' }}
          </div>

          <div class="release-notes-view-component__feature-container" *ngFor="let feature of release.features">
            <div class="release-notes-view-component__feature-title">
              {{ feature.title }}
            </div>
            <div class="release-notes-view-component__feature-desc">
              {{ feature.description }}
            </div>
            <img class="release-notes-view-component__image" [src]="feature.imageUrl" alt="new-version-icon" />
          </div>
        </div>
      </div>
    </div>
  </mat-menu>
</div>
