// const moment = require("moment").default || require("moment");
import {
  Direction,
  FeedPriorityType,
  LanesClosureType,
  LaneType,
  Orientation,
  RoadClosure,
  RoadLevelType,
  RoadType,
  TrafficDisruptionLane,
  TrafficDisruptionStatus,
} from '../models';
export const RoadClosureLaneMock: TrafficDisruptionLane = {
  id: 1,
  number: 1,
  roadType: RoadType.Bridge,
  type: LaneType.Express,
  direction: Direction.Nb,
  isClosed: true,
  isAffected: true,
  positionIndex: 0,
};

export const RoadClosureLaneHighwayMock: TrafficDisruptionLane = {
  id: 2,
  number: 1,
  roadType: RoadType.Highway,
  type: LaneType.Express,
  direction: Direction.Nb,
  isClosed: true,
  isAffected: true,
  positionIndex: 1,
};

const contactPerson = {
  email: 'a@a.com',
  name: 'tester',
  phoneNumber: '+9721234567890',
};
const date = new Date();
const mockDate = date.setDate(date.getDate() + 15);

export const RoadClosureMock: RoadClosure = {
  dmses: [],
  cameras: [],
  schedule: {} as any,
  address: {
    point: {
      coordinates: [-115.29602542928855, 36.15913927669703],
      type: 'Point',
    },
  },
  feedPriorityType: FeedPriorityType.PrimaryFeed,
  id: 1,
  status: TrafficDisruptionStatus.Active,
  externalId: 'externalId_1',
  contactPerson: contactPerson,
  createdAt: '2020-09-07T14:49:26.439Z',
  description: 'Road closure description...',
  endTime: mockDate,
  affectedLanes: [RoadClosureLaneMock],
  multiDirectionLanesAffected: false,
  allLanesAffected: false,
  startTime: new Date(),

  title: 'Test road closure',
  location: {
    coordinates: [[-90.28374515796534, 38.66394025325994]],
    type: 'LaneString',
  },
  lanesClosureType: LanesClosureType.Full,
  isAllDay: false,
  createdBy: 2,
  media: [],
  updatedAt: '2020-09-07T14:49:26.439Z',
  updatedBy: 2,
};

export const RoadClosureMockB: RoadClosure = {
  id: 1,
  dmses: [],
  cameras: [],
  feedPriorityType: FeedPriorityType.PrimaryFeed,
  status: TrafficDisruptionStatus.Active,
  externalId: 'externalId_1',
  contactPerson: contactPerson,
  createdAt: '2020-09-07T14:49:26.439Z',
  description: 'Road closure description...',
  endTime: mockDate,
  affectedLanes: [RoadClosureLaneMock],
  allLanesAffected: true,
  multiDirectionLanesAffected: false,
  startTime: new Date(),
  title: 'Test road closure',
  address: {
    corridor: 'Charleston Boulevard',
    crossroad: 'Windemere Drive',
    direction: null,
    orientation: Orientation.After,
    point: {
      coordinates: [-115.29602542928855, 36.15913927669703],
      type: 'Point',
    },
    roadLevelType: null,
    roadType: null,
    segmentId: '5ae20971-f000-5dba-8a53-d635e15813ae',
  },
  location: {
    coordinates: [[-90.28374515796534, 38.66394025325994]],
    type: 'Point',
  },
  media: [],
  lanesClosureType: LanesClosureType.Full,
  isAllDay: false,
  createdBy: 2,
  updatedAt: '2020-09-07T14:49:26.439Z',
  updatedBy: 2,
};

export enum TrafficDisruptionTypes {
  Construction = 'CONSTRUCTION',
  Event = 'SPECIAL_EVENT',
  RoadClosure = 'ROAD_CLOSURE',
}

export const RoadClosureAffectedLanesMock: RoadClosure = {
  dmses: [],
  cameras: [],
  allLanesAffected: false,
  multiDirectionLanesAffected: false,
  feedPriorityType: FeedPriorityType.PrimaryFeed,
  address: {
    city: 'North Las Vegas',
    corridor: '215',
    county: 'Clark County',
    crossroad: 'Decatur Boulevard',
    direction: Direction.Sb,
    milemarker: null,
    orientation: Orientation.Before,

    point: {
      coordinates: [-115.199421567464, 36.2760953294909, 0],
      type: 'Point',
    },
    roadLevelType: RoadLevelType.Standard,
    roadType: RoadType.Freeway,
    segmentId: '47b66008-f0ee-55c6-b04a-5c95d029b226',
    state: 'Nevada',
  },
  media: [],
  affectedLanes: [],
  contactPerson: { email: null, name: null, phoneNumber: null },
  createdAt: '2020-09-07T14:49:26.439Z',
  description: null,
  endTime: null,
  externalId: null,
  id: 13098,
  lanesClosureType: LanesClosureType.Partial,
  location: {
    coordinates: [-115.199421567464, 36.2760953294909, 0],
    type: 'Point',
  },
  isAllDay: false,
  startTime: '2020-10-22T11:40:15.437Z',
  status: TrafficDisruptionStatus.Active,
  title: '215 SB before Decatur Boulevard',
  createdBy: 2,
  updatedAt: '2020-09-07T14:49:26.439Z',
  updatedBy: 2,
};
