import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FeatureSubType, LanesClosureType, RegionalSetting, SpecialEventView, TrafficDisruptionStatus } from '@wc/core';
import { RoadEventType } from '@wc/core/models/enums';
import { SpecialEventStore } from '@wc/core/stores/special-event.store';
import { UiStore } from '@wc/core/stores/ui.store';
import {
  layerTypeToTranslationPath,
  SpecialEventStoreEntity,
  TrafficDisruptionStoryEntity,
  TrafficDisruptionType,
  TrafficDisruptionViewData,
} from '@wc/wc-models/src';
import * as moment from 'moment';

export type TrafficDisruption = {
  endTime: string;
  externalId: string;
  id: string;
  isActive: boolean;
  isFull: boolean;
  startTime: string;
  status: TrafficDisruptionStatus;
  subtype?: string;
  subsubtype?: string;
  title: string;
  type: string;
};

@Component({
  selector: 'wc-traffic-disruption-list-item',
  templateUrl: './traffic-disruption-list-item.component.html',
  styleUrls: ['./traffic-disruption-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrafficDisruptionListItemComponent implements OnInit {
  @Input() disruption!: TrafficDisruptionStoryEntity;
  @Input() regionalSetting!: RegionalSetting;
  @Output() completeTrafficDisruption = new EventEmitter<TrafficDisruptionStoryEntity>();
  moment: any = moment;
  isTabletMode!: boolean;
  RoadEventTypeEnum = RoadEventType;
  dataView!: Omit<TrafficDisruptionViewData, 'loaderName'>;

  get disruptionStatus() {
    return TrafficDisruptionStatus;
  }
  get featureSubtype() {
    return FeatureSubType;
  }

  get isFullBlock(): boolean {
    return this.disruption.lanesClosureType === LanesClosureType.Full;
  }

  constructor(
    public uiStore: UiStore,
    public specialEventStore: SpecialEventStore,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.isTabletMode = this.uiStore.isTabletMode;
    this.initTrafficDisruptionData();
  }

  initTrafficDisruptionData() {
    const trafficDisruptionType = this.disruption.layerType as unknown as TrafficDisruptionType;
    this.dataView = {
      isSpecialEvent: trafficDisruptionType === TrafficDisruptionType.SpecialEvent,
      translatedTrafficDisruptionType: this.translateService.instant(
        this.getTrafficDisruptionTranslationPath(trafficDisruptionType)
      ),
    };
    if (this.dataView.isSpecialEvent) {
      this.dataView.specialEventViewData = this.specialEventStore.getSpecialEventData(
        this.disruption as unknown as SpecialEventView
      );
    }
  }

  getTrafficDisruptionTranslationPath<T extends TrafficDisruptionType>(
    trafficDisruptionType: T
  ): typeof layerTypeToTranslationPath[T] | '' {
    return !layerTypeToTranslationPath[trafficDisruptionType]
      ? ''
      : layerTypeToTranslationPath[trafficDisruptionType] === layerTypeToTranslationPath.SPECIAL_EVENT
      ? layerTypeToTranslationPath[trafficDisruptionType] + `.${(this.disruption as SpecialEventStoreEntity).type}`
      : layerTypeToTranslationPath[trafficDisruptionType];
  }

  _completeTrafficDisruption(event: Event, trafficDisruption: TrafficDisruptionStoryEntity) {
    event.stopPropagation();
    this.completeTrafficDisruption.emit(trafficDisruption);
  }
}
