import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlFieldComponent } from '../../base';

@Component({
  selector: 'wc-numbers-only-field',
  templateUrl: './numbers-only-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumbersOnlyFieldComponent),
      multi: true,
    },
  ],
  styleUrls: ['./numbers-only-field.component.scss'],
})
export class NumbersOnlyFieldComponent extends BaseControlFieldComponent {
  constructor() {
    super();
  }

  onBlur() {
    if (this.fieldFormControl.value?.toString().length === 1) {
      this.fieldFormControl.setValue('0' + this.fieldFormControl.value);
      this.onTouched();
      this.onChanged(this.fieldFormControl.value);
    }
  }
}
