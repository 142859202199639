import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { WeatherEventsPipesModule } from '@wc/features/ui/pipes/weather-events.pipe.module';
import {
  CrashRiskPipesModule,
  DistanceFormatPipeModule,
  FocusDirectiveModule,
  WcLetModule,
  WcPopupCloserModule,
} from '@wc/wc-common/pipes';
import { DateFormatPipeModule } from '@wc/wc-common/src/lib/pipes/format-pipes/date-format.module';
import { TimeFormatPipeModule } from '@wc/wc-common/src/lib/pipes/format-pipes/time-format.module';
import { WcIconModule } from '../../wc-icon/wc-icon.module';
import { CrashRiskPanelComponent } from './crash-risk-panel.component';

@NgModule({
  declarations: [CrashRiskPanelComponent],
  imports: [
    CommonModule,
    TranslateModule,
    WeatherEventsPipesModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TimeFormatPipeModule,
    DateFormatPipeModule,
    DistanceFormatPipeModule,
    CrashRiskPipesModule,
    WcLetModule,
    WcIconModule,
    WcPopupCloserModule,
    FocusDirectiveModule,
  ],
  exports: [CrashRiskPanelComponent],
})
export class CrashRiskPanelModule {}
