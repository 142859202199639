import { Injectable } from '@angular/core';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LayerType } from '../models';
import { ConstructionService, IncidentService, RoadClosureService } from '../services';
import { SpecialEventService } from '../services/special-event.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityLogStore {
  @observable activityLogs: {
    incident?: any;
    roadClosure?: any;
    construction?: any;
  } = {};

  constructor(
    private incidentService: IncidentService,
    private roadClosureService: RoadClosureService,
    private constructionService: ConstructionService,
    private specialEventService: SpecialEventService
  ) {
    makeObservable(this);
  }

  @action
  loadIncidentActivityLog(incidentId: number) {
    return this.incidentService.incidentActivityLog(incidentId).pipe(
      tap(activityLog => {
        if (activityLog) {
          runInAction(() => {
            const formatedActivityLog = activityLog;
            this.activityLogs['incident'] = this.activityLogs['incident'] || {};
            this.activityLogs['incident'][incidentId] = formatedActivityLog;
            this.activityLogs = { ...{}, ...this.activityLogs };
          });
        }
      })
    );
  }

  @action
  loadTrafficDisruptionActivityLog(trafficDisruptionId: number, trafficDisruptionType: LayerType) {
    switch (trafficDisruptionType) {
      case LayerType.Construction:
        return this.loadConstructionActivityLog(trafficDisruptionId);
      case LayerType.RoadClosure:
        return this.loadRoadClosureActivityLog(trafficDisruptionId);
      case LayerType.SpecialEvent:
        return this.loadSpecialEventActivityLog(trafficDisruptionId);
      default:
        return of([]);
    }
  }

  @action
  loadRoadClosureActivityLog(roadClosureId: number) {
    return this.roadClosureService.getActivityLog(roadClosureId).pipe(
      tap(activityLog => {
        runInAction(() => {
          this.activityLogs['roadClosure'] = this.activityLogs['roadClosure'] || {};
          this.activityLogs['roadClosure'][roadClosureId] = activityLog;
          this.activityLogs = { ...{}, ...this.activityLogs };
        });
      })
    );
  }

  @action
  loadConstructionActivityLog(constructionId: number) {
    return this.constructionService.getActivityLog(constructionId).pipe(
      tap(activityLog => {
        runInAction(() => {
          this.activityLogs['construction'] = this.activityLogs['construction'] || {};
          this.activityLogs['construction'][constructionId] = activityLog;
          this.activityLogs = { ...{}, ...this.activityLogs };
        });
      })
    );
  }

  @action
  loadSpecialEventActivityLog(specialEventId: number) {
    return this.specialEventService.getActivityLog(specialEventId).pipe(
      tap(activityLog => {
        runInAction(() => {
          this.activityLogs['specialEvent'] = this.activityLogs['specialEvent'] || {};
          this.activityLogs['specialEvent'][specialEventId] = activityLog;
          this.activityLogs = { ...{}, ...this.activityLogs };
        });
      })
    );
  }
}
