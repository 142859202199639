import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TextFieldModule } from '../../form-components/text-field/text-field.module';
import { WcSelectModule } from '../../form-components/wc-select/wc-select.module';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { WcNotificationModule } from '../notification/notification.module';
import { WcTooltipModule } from '../tooltip/tooltip.module';
import { WcButtonModule } from '../wc-button/wc-button.module';
import { IncidentInvolvedVehiclesComponent } from './incident-involved-vehicles.component';

@NgModule({
  declarations: [IncidentInvolvedVehiclesComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    WcSelectModule,
    WcNotificationModule,
    AutocompleteModule,
    TextFieldModule,
    WcTooltipModule,
    WcButtonModule,
  ],
  providers: [],
  exports: [IncidentInvolvedVehiclesComponent],
})
export class InvolvedVehiclesModule {}
