import { Injectable } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import Shepherd from 'shepherd.js';

@Injectable({ providedIn: 'root' })
export class CustomShepherdService extends ShepherdService {
  private initialize() {
    const tourObject = new Shepherd.Tour({
      confirmCancel: this.confirmCancel,
      confirmCancelMessage: this.confirmCancelMessage,
      defaultStepOptions: this.defaultStepOptions,
      tourName: this.tourName,
      useModalOverlay: this.modal,
      keyboardNavigation: false,
    });
    tourObject.on('complete', this.onTourFinish.bind(this, 'complete'));
    tourObject.on('cancel', this.onTourFinish.bind(this, 'cancel'));
    this.tourObject = tourObject;
  }

  addSteps(steps) {
    this.initialize();
    const tour = this.tourObject;
    // Return nothing if there are no steps
    if (!steps || !Array.isArray(steps) || steps.length === 0) {
      return;
    }
    if (!this['requiredElementsPresent']()) {
      tour.addStep({
        buttons: [
          {
            text: 'Exit',
            action: tour.cancel,
          },
        ],
        id: 'error',
        title: this.errorTitle,
        text: [this.messageForUser],
      });
      return;
    }
    steps.forEach(step => {
      if (step.buttons) {
        step.buttons = step.buttons.map(this.makeButton, this);
      }
      tour.addStep(step);
    });
  }

  private makeButton(button) {
    const { classes, disabled, label, secondary, type, text } = button;
    const builtInButtonTypes = ['back', 'cancel', 'next'];
    if (!type) {
      return button;
    }
    if (builtInButtonTypes.indexOf(type) === -1) {
      throw new Error(`'type' property must be one of 'back', 'cancel', or 'next'`);
    }
    return {
      action: this[type].bind(this),
      classes,
      disabled,
      label,
      secondary,
      text,
    };
  }
}
