import { Component, Input, OnInit } from '@angular/core';
import {
  AccountStore,
  EntitiesStore,
  environment,
  Incident,
  IncidentMitigation,
  IncidentUnit,
  IncidentUnitDetails,
  Store,
  UiStore,
  UnitResponse,
  UsersStore,
} from '@wc/core';
import { chipStyles } from '@wc/wc-models/src/lib/enums/general';

@Component({
  selector: 'wc-unit-card',
  templateUrl: './unit-card.component.html',
  styleUrls: ['./unit-card.component.scss'],
})
export class UnitCardComponent implements OnInit {
  @Input() associatedUnit!: IncidentUnit; // live incident
  @Input() incidentUnitDetails!: IncidentUnitDetails; //analytics incident
  @Input() analyticsMode!: false;
  unitMitigation!: Array<IncidentMitigation>;
  @Input()
  set incident(incident: Incident) {
    this._incident = incident;
    this.setUnitMitigation();
  }

  _incident!: Incident;
  unitRoutes?: string[] = [];
  unitId: string | undefined;
  userName: string | undefined;

  readonly chipStyles = chipStyles;

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  get isHebrew() {
    return this.uiStore.isHebrew;
  }

  get unitResponse() {
    return UnitResponse;
  }

  public usersStore: UsersStore = this.store.usersStore;
  private uiStore: UiStore = this.store.uiStore;
  private entitiesStore: EntitiesStore = this.store.entitiesStore;
  public accountStore: AccountStore = this.store.accountStore;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.setUnitMitigation();
    this.getCurrentDriverShift();

    this.unitId = this.analyticsMode ? this.incidentUnitDetails.displayId : this.associatedUnit.displayId;
    this.userName = this.analyticsMode
      ? this.incidentUnitDetails.driverDetails?.userName
      : this.associatedUnit.driverDetails?.name;
  }

  setUnitMitigation() {
    if (!this.analyticsMode) {
      this.unitMitigation =
        this._incident?.mitigations?.filter((incidentMitigation: IncidentMitigation) => {
          return (
            incidentMitigation.unitId === this.associatedUnit?.id &&
            incidentMitigation.mitigationType?.id !== environment.defaultMitigationTypeId
          );
        }) || [];
    }
  }

  getCurrentDriverShift() {
    if (this.analyticsMode) {
      this.unitRoutes = this.incidentUnitDetails.driverDetails?.routeNames;
    } else {
      if (this.associatedUnit.type) {
        this.unitRoutes = this.entitiesStore.getUnitRouteFromDiff(this.associatedUnit.type, this.associatedUnit.id);
      }
    }
  }
}
