<div class="completion-form-component">
  <button (click)="cancel()" mat-button class="close-modal-button" [disableRipple]="true">
    <i class="far fa-times"></i>
  </button>
  <div class="dialog-content" data-cy-id="delete-shift-title">
    {{ data.title | translate }}
  </div>
  <div class="completion-form-actions-btn">
    <button (click)="cancel()" mat-stroked-button variant="outlined" class="completion-form-cancel-button">
      {{ data.cancelAction | translate }}
    </button>
    <button
      (click)="approve()"
      data-cy-id="delete-shift-modal-button"
      class="primary-button completion-form-complete-button">
      {{ data.approveAction | translate }}
    </button>
  </div>
</div>
