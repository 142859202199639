import { Injectable } from '@angular/core';
import { UpdateWorkingHoursInput } from '@wc/core';
import { map } from 'rxjs/operators';
import { DayType, WorkScheduleConfiguration } from '../../types';
import { WorkScheduleConfigurationApiService } from '../infrastructure/services/work-schedule-configuration-api.service';
import { WorkScheduleConfigurationService } from '../infrastructure/services/work-schedule-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class WorkScheduleConfigurationFacadeService {
  constructor(
    private readonly workScheduleService: WorkScheduleConfigurationService,
    private readonly workScheduleApiService: WorkScheduleConfigurationApiService
  ) {}

  getWorkingHours() {
    return this.workScheduleApiService.getWorkingHours().pipe(
      map(res => res.data.getWorkingHours),
      map(workingHoursDto => ({
        id: workingHoursDto.workingHoursId,
        isHideEnabled: workingHoursDto.isHideEnabled,
        workingDays: workingHoursDto.workingHoursItems.map(item => item.dayOfWeek as DayType),
        workingHours: {
          isCustom: workingHoursDto.isCustom,
          ranges: workingHoursDto.workingHoursItems.map(item => ({
            day: item.dayOfWeek as DayType,
            dailyRanges: item.dailyRanges,
          })),
        },
      }))
    );
  }

  updateWorkingHours(input: UpdateWorkingHoursInput) {
    return this.workScheduleApiService
      .updateWorkingHours(input)
      .pipe(map(res => res.data?.updateWorkingHoursConfiguration));
  }

  createUpdateWorkingHoursInput(formValue: WorkScheduleConfiguration): UpdateWorkingHoursInput {
    return this.workScheduleService.createUpdateWorkingHoursInput(formValue);
  }
}
