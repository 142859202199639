import { Injectable } from '@angular/core';
import { SplitIOService } from '@wc/core';
import { environment } from '@wc/core/environments/environment';
import { User } from '@wc/core/models';
import { skipWhile } from 'rxjs/operators';
import { AppFeatureEnum } from './split-io.service';

declare let smartlook;

@Injectable({
  providedIn: 'root',
})
export class SmartlookService {
  smartlookKey!: string;

  constructor(private splitIoService: SplitIOService) {}

  init(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.splitIoService.featureToggles
        .pipe(skipWhile(ft => ft[AppFeatureEnum.FE_SMARTLOOK] === undefined))
        .subscribe({
          next: v => {
            console.log(v[AppFeatureEnum.FE_SMARTLOOK]);
            if (v[AppFeatureEnum.FE_SMARTLOOK] === 'on') {
              this.smartlookKey = environment.smartlook.key;

              console.log('Init SmartLook Service!');
              try {
                const script = document.createElement('script');

                script.innerHTML = `
                                      window.smartlook||(function(d) {
                                      var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
                                      var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
                                      c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
                                      })(document);
                                      smartlook('init', '${this.smartlookKey}', { region: 'eu' });
                                    `;
                if (v[AppFeatureEnum.FE_SMARTLOOK_CANVAS] === 'on') {
                  script.innerHTML += 'document.body.dataset.sl = "canvas-mq"';
                }
                document.head.appendChild(script);
                this.setupRecords();
                resolve(true);
              } catch (ex) {
                console.error('Error SmartLook');
                console.error(ex);
                reject();
              }
            } else {
              console.log('smartlook rejected');
              reject();
              return;
            }
          },
        });
    });
  }

  setupRecords() {
    smartlook('record', { forms: true, numbers: true, emails: true, ips: true });
  }

  setIdentify(user: User) {
    smartlook('identify', user.id, {
      name: user.name,
      email: user.email,
      accountId: user.account.id,
      accountName: user.account.name,
    });
  }
}
