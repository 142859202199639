import { HttpClient, HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@wc/core/environments/environment';
import { ToastrAlertsService } from '@wc/wc-ui/src/services/toaster-alert.service';
import { merge, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { MediaApiService } from './media-api.service';
import { MediaSource } from 'libs/core/models/gql.models';

export interface FileDescriptor {
  data: File;
  inProgress: boolean;
  progress: number;
  entityId: string;
  entityType: UrlUploadEntityEnum;
  source: MediaSource;
}

export enum UrlUploadEntityEnum {
  INCIDENT = 'incidents',
  TRAFFIC_DISRUPTION = 'traffic-disruptions',
  PUBLICATION = 'publication',
}

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private SERVER_URL: string = environment.root_api_url;

  constructor(
    private httpClient: HttpClient,
    private alertService: ToastrAlertsService,
    private translateService: TranslateService,
    private mediaApiService: MediaApiService
  ) {}

  get isPendingFiles() {
    return this.mediaApiService.pendingUploadFiles.length > 0;
  }

  addFilesToQueueAndUpload(entityType: UrlUploadEntityEnum, entityId?, source: MediaSource = MediaSource.Upload) {
    const files = this.mediaApiService.pendingUploadFiles;
    const fileDescriptors: FileDescriptor[] = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      fileDescriptors.push({
        data: file,
        inProgress: false,
        progress: 0,
        entityId,
        entityType,
        source
      });
    }

    this.mediaApiService.pendingUploadFiles = [];
    const updateObservable = fileDescriptors.map(f => this.performUpload(f));
    return merge(...updateObservable).pipe(filter(res => res instanceof HttpResponse));
  }

  private performUpload(fileDescriptor: FileDescriptor) {
    const { entityId, entityType } = fileDescriptor;
    const fileToUpload: File = fileDescriptor.data;
    const formData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('source', fileDescriptor.source);
    fileDescriptor.inProgress = true;
    
    const entityIdUrl = entityType === UrlUploadEntityEnum.PUBLICATION ? 'media' : `${entityId}`;
    return this.httpClient
      .post<any>(`${this.SERVER_URL}/${entityType}/${entityIdUrl}/upload`, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              fileDescriptor.progress = Math.round((event.loaded * 100) / Number(event.total));
              return HttpEventType.UploadProgress;
            case HttpEventType.Response:
              return event;
            case HttpEventType.Sent:
              return HttpEventType.Sent;
            case HttpEventType.DownloadProgress:
              return HttpEventType.DownloadProgress;
          }
          console.warn(event);
          return event.type;
        }),
        catchError((error: HttpErrorResponse) => {
          fileDescriptor.inProgress = false;
          //DOTO  throw error upload failed

          if (error.status !== 0) {
            this.alertService.error(
              `${fileDescriptor.data.name} ${this.translateService.instant('errorMessages.uploadFailed')}`
            );
          }
          return throwError(`${fileDescriptor.data.name} upload failed.`);
        })
      );
  }
}
