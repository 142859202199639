import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { DesktopUiStateService } from 'apps/desktop/src/app/shared/desktop-ui-state.service';

const DEFAULT_OFFSET = 4;

@Directive({
  selector: '[wcFocusStyles]',
})
export class FocusDirective {
  @Input() negativeOutlineOffset = false;
  @Input() focusDirectiveDisabled = false;
  @Input() offset = DEFAULT_OFFSET;

  constructor(
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private desktopUiStateService: DesktopUiStateService
  ) {}

  @HostListener('focusin') onFocusIn() {
    if (!this.focusDirectiveDisabled && this.desktopUiStateService.keyboardInteraction) {
      this.renderer.addClass(this.el.nativeElement, 'focus-styles');
      if (this.negativeOutlineOffset) {
        this.renderer.addClass(this.el.nativeElement, 'negative-outline-offset');
      } else if (this.offset !== DEFAULT_OFFSET) {
        this.renderer.setStyle(this.el.nativeElement, 'outline-offset', `${this.offset}px`);
      }
    }
  }

  @HostListener('focusout') onFocusOut() {
    this.renderer.removeClass(this.el.nativeElement, 'focus-styles');
    if (this.negativeOutlineOffset) {
      this.renderer.removeClass(this.el.nativeElement, 'negative-outline-offset');
    } else if (this.offset !== DEFAULT_OFFSET) {
      this.renderer.removeStyle(this.el.nativeElement, 'outline-offset');
    }
  }
}
