/* eslint-disable @angular-eslint/directive-selector */
import { Directive, Input, TemplateRef } from '@angular/core';

type context<T> = {
  $implicit: T;
};

@Directive({
  selector: 'ng-template[contextType]',
})
export class ContextTypeDirective<T extends any = any> {
  @Input() contextType!: T;

  constructor(public template: TemplateRef<any>) {}

  static ngTemplateContextGuard<T>(_dir: ContextTypeDirective<T>, ctx: unknown): ctx is context<T> {
    return true;
  }
}
