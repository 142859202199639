import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivitySourceType, ShiftStore, UpdateShiftInput } from '@wc/core';
import { FormFieldData } from '@wc/wc-ui/src/lib/base';

type FormModel = UpdateShiftInput;

@Component({
  selector: 'wc-shift-removed-form',
  templateUrl: './shift-removed-form.component.html',
  styleUrls: ['./shift-removed-form.component.scss'],
})
export class ShiftRemovedFormComponent implements OnInit {
  shiftForm!: FormGroup;
  @Output() modalClosed: EventEmitter<any> = new EventEmitter();

  shiftFieldsData: { [filedName in keyof FormModel | any]: FormFieldData } = {
    unitId: {
      label: 'shiftsAndRoutes.unit',
      options: this.shiftStore.unitsOptions,
      required: true,
    },
    startMileage: {
      label: 'shiftsAndRoutes.mileage',
      required: true,
    },
    shiftTypeId: {
      label: 'shiftsAndRoutes.shift',
      options: this.shiftStore.shiftTypesOptions,
      required: true,
    },
    routeTypes: {
      label: 'shiftsAndRoutes.route',
      options: this.shiftStore.routeTypesOptions,
      required: true,
    },
  };

  constructor(public shiftStore: ShiftStore, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.shiftForm = this.createForm({
      activitySource: ActivitySourceType.Api,
      unitId: this.shiftStore.currentShift.unit?.id,
      startMileage: this.shiftStore.currentShift.startMileage,
      shiftId: this.shiftStore.currentShift.shiftId,
      shiftTypeId: this.shiftStore.currentShift.shiftType?.id,
      routeTypesIds: [this.shiftStore.currentShift.routeTypes.map(route => route.id)],
    });

    if (this.shiftStore.shiftTypesOptions?.length > 0) {
      this.shiftForm.get('shiftTypeId')?.setValidators([Validators.required]);
    }
  }

  createForm(model: { [filedName in keyof FormModel]: any }): FormGroup {
    return this.fb.group(model);
  }

  closeShiftForm() {
    this.modalClosed.emit();
  }

  submitEditShiftForm() {
    if (this.shiftForm.valid) {
      //console.log(this.shiftForm.value);
      //console.log(this.shiftForm);

      this.shiftStore.updateShift(this.shiftForm.value).subscribe(response => {
        if (response) {
          this.closeShiftForm();
        }
      });
    }
  }
}
