import { Pipe, PipeTransform } from '@angular/core';
import { RoadwayStatusSegmentsSortOptions } from '@wc/wc-models/src/lib/enums/realtime_analytics';
import { RoadwayStatusState } from '../../../../wc-core/src/lib/stores/entities/roadway_status/roadway_status.store';

@Pipe({
  name: 'roadwayStatusSort',
  pure: true,
})
export class RoadwayStatusSortPipe implements PipeTransform {
  transform(segments: RoadwayStatusState[], { orderBy }): any[] {
    let sortedSegments: RoadwayStatusState[] = [];

    switch (orderBy) {
      case RoadwayStatusSegmentsSortOptions.TrafficVolume:
        sortedSegments = segments.sort(
          (a, b) => b.vendorMetrics[0].transportCountTotal - a.vendorMetrics[0].transportCountTotal
        );
        break;

      case RoadwayStatusSegmentsSortOptions.Deviation:
      default:
        sortedSegments = segments.sort(
          (a, b) => a.vendorMetrics[0].maxSpeedDeviationPercent - b.vendorMetrics[0].maxSpeedDeviationPercent
        );
        break;
    }

    return sortedSegments;
  }
}
