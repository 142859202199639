import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { TimeFormatPipeModule } from '@wc/wc-common/src/lib/pipes/format-pipes/time-format.module';
import { WcIconModule } from '../../wc-icon/wc-icon.module';
import { RangeFormatPipe } from '../range-format.pipe';
import { RoadwayStatusSortPipe } from '../roadway-status-sort.pipe';
import { SpeedFormatPipe } from '../speed-format.pipe';
import { SegmentDataSourceComponent } from './segment-data-source/segment-data-source.component';
import { SegmentDataComponent } from './segment-data/segment-data.component';
import { SegmentTitleComponent } from './segment-title/segment-title.component';

@NgModule({
  declarations: [
    SegmentTitleComponent,
    SegmentDataSourceComponent,
    SegmentDataComponent,
    RangeFormatPipe,
    SpeedFormatPipe,
    RoadwayStatusSortPipe,
  ],
  exports: [
    SegmentTitleComponent,
    SegmentDataSourceComponent,
    SegmentDataComponent,
    RangeFormatPipe,
    RoadwayStatusSortPipe,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatOptionModule,
    MatSelectModule,
    FormsModule,
    TimeFormatPipeModule,
    WcIconModule,
  ],
})
export class SegmentFragmentsModule {}
