export enum chipStyles {
  special_status = 'special_status',
  mitigation = 'mitigation',
  units = 'units',
  new = 'new',
  inactive = 'inactive',
  active = 'active',
  removable = 'removable',
  day_initials = 'day_initials',
}

export enum MapUnitTooltipResponses {
  on_active = 'on_active',
  on_break = 'on_break',
  en_route = 'en_route',
  on_responding = 'on_responding',
  on_scene = 'on_scene',
  on_special_status = 'on_special_status',
  mitigated = 'mitigated',
  unknown_unit_status = 'unknown_unit_status',
}

export enum CameraViewerContextType {
  IncidentCameras = 'INCIDENT_CAMERAS',
  LiveMapModal = 'LIVE_MAP_MODAL',
}

export enum CameraRekorStatuses {
  failedToConnectToVideoUrl = 1,
  failedToGrabFrameFromVideoStream = 2,
  failedToConvertFrameToImage = 3,
  failedToResolveImageFromVideoStream = 4,
  cameraNotFound = 5,
  missingFeedUrlForCamera = 6,
  timeOutWhenTryingToResolveImageFromVideo = 7,
  failedToFetchImageFromUrl = 8,
  failedToConnectToCameraUrl = 9,
  failedToFetchJsession = 10,
  failedToResizeImage = 11,
  backoffError = 12,
}

export enum ToastrPositionClass {
  DesktopTopCenter = 'toast-desktop-center-top',
  DesktopBottomPanelLeft = 'toast-desktop-bottom-left-panel',
  DesktopBottomPanelRight = 'toast-desktop-bottom-right-panel',
  DesktopBottomAuxiliaryPanel = 'toast-desktop-auxiliary-panel',
  TabletTopCenter = 'toast-tablet-center-top',
  TabletBottomCenter = 'toast-tablet-center-bottom',
  WidgetTopCenter = 'toast-widget-center-top',
  TopLeft = 'toast-top-left', // ngx-toastr
  TopCenter = 'toast-top-center', // ngx-toastr
  TopRight = 'toast-top-right', // ngx-toastr
  TopFullWidth = 'toast-top-full-width', // ngx-toastr
  BottomLeft = 'toast-bottom-left', // ngx-toastr
  BottomCenter = 'toast-bottom-center', // ngx-toastr
  BottomRight = 'toast-bottom-right', // ngx-toastr
  BottomFullWidth = 'toast-bottom-full-width', // ngx-toastr
}

export enum EventListSortType {
  Type = 'type',
  TimeNewFirst = 'timeNewFirst',
  TimeOldFirst = 'timeOldFirst',
  Priority = 'priority',
}

export enum RoadEventsPanelTabIndex {
  NeedAction = 0,
  InProgress = 1,
  Completed = 2,
}

export enum RoadEventsPanelSubTabIndexDesktop {
  Incidents = 0,
  TrafficDisruptions = 1,
}

export enum RoadEventsListTypes {
  NeedActionIncident,
  InProgressIncidents,
  InProgressTD,
  CompletedIncidents,
  CompletedTD,
}

export enum RoadEventsHighlighEntityType {
  Incident = 'incident',
  TrafficDisruptions = 'trafficDisruption',
}

export enum AppFeatureEnum {
  FE_CONGESTION = 'fe_congestion',
  FE_SHOW_UNIDENTIFIED_TYPE = 'fe_show_unidentified_type',
  FE_AVL_LOCATION_SRC = 'fe_avl_location_src',
  FE_WEATHER_EVENTS = 'fe_weather_events',
  FE_TRANSIT = 'fe_transit',
  FE_TRANSIT_AFFECTED_SERVICES = 'fe_transit_affected_services',
  FE_TRANSIT_AFFECTED_SERVICES_TRAIN_DELAYS = 'fe_transit_affected_services_train_delays',
  FE_TRANSIT_SHOW_BUS_LOAD_ON_STOP = 'fe_transit_show_bus_load_on_stop',
  FE_SMARTLOOK = 'fe_smartlook',
  FE_REAL_TIME_ANALYTICS = 'fe_real_time_analytics',
  FE_SMARTLOOK_CANVAS = 'fe_smartlook_canvas',
  FE_LIMIT_TO_SINGLE_MITIGATION = 'fe_limit_to_single_mitigation',
  FE_USE_AWS_SATELLITE_MAP = 'fe_use_aws_satellite_map',
  FE_INCIDENT_INSIGHTS_IMPACT = 'fe_incident_insights_impact',
  FE_DOWNLOAD_WIDGET = 'fe_download_widget',
  FE_WRONG_WAY_ALERTS = 'fe_wrong_way_alerts',
  FE_AUTO_GENERATED_SNAPSHOTS = 'fe_auto_generated_snapshots',
  FE_USER_GENERATED_SNAPSHOTS = 'fe_user_generated_snapshots',
}

export enum ConfirmationModalType {
  DiscardChanges = 'DISCARD_CHANGES',
  RemoveUser = 'REMOVE_USER',
  RemoveRole = 'REMOVE_ROLE',
  UnassignUser = 'UNASSIGN_USER',
  UnassignUnit = 'UNASSIGN_UNIT',
  RemoveUnit = 'REMOVE_UNIT',
  SoundAlertPopup = 'SOUND_ALERT_POPUP',
  DarkModePopup = 'DARK_MODE_POPUP',
  CompleteHasUnits = 'COMPLETE_HAS_UNITS',
  EditTimeExpired = 'EDIT_TIME_EXPIRED',
  EditAboutToExpire = 'EDIT_ABOUT_TO_EXPIRE',
  LeaveWithoutSaving = 'LEAVE_WITHOUT_SAVING',
  ResetChanges = 'RESET_CHANGES',
  ShiftHistoricalRelatedIncidents = 'SHIFT_HISTORICAL_RELATED_INCIDENTS',
  IncidentMergedIntoAnotherIncident = 'INCIDENT_MERGED_INTO_ANOTHER_INCIDENT',
  ResponsePlanNotDone = 'RESPONSE_PLAN_NOT_DONE',
  LeaveEditMode = 'LEAVE_EDIT_MODE',
  WrongWayAlert = 'WRONG_WAY_ALERT',
  OnboardingTours = 'ONBOARDING_TOURS',
}
