import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroupDirective } from '@angular/forms';
import { Direction, LaneInput, LaneType, RoadType, SegmentDetails } from '@wc/core';
import * as Utils from '@wc/utils';
import { Subject } from 'rxjs';
import { first, skip, takeWhile } from 'rxjs/operators';
import { AffectedLanesSliderComponent } from '../affected-lanes-slider/affected-lanes-slider.component';
import {
  AffectedLanesUtilsService,
  contextMenuOptions,
  getLanesByLanesQuantity,
  isLaneSubType,
  isLaneType,
  maxMultiDirectionLanes,
  maxSingleDirectionLanes,
  MenuLaneTypes,
  MoveGoreAction,
  RoadEventLane,
} from '../affected-lanes-utils-service';

@Component({
  selector: 'wc-affected-lanes-container',
  templateUrl: './affected-lanes-container.component.html',
  styleUrls: ['./affected-lanes-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AffectedLanesContainerComponent implements OnInit, OnDestroy {
  @ViewChild('affectedLanesSlider', { read: AffectedLanesSliderComponent, static: false })
  affectedLanesSlider!: AffectedLanesSliderComponent;
  MenuLaneTypes = MenuLaneTypes;
  lanesForm: FormArray = this.fb.array([]);
  hoveredLane = null;
  diagramHoveredLane?: number;
  showLoader = true;

  private _allAffected = false;
  private _direction?: Direction = undefined;
  private _roadType!: RoadType;
  private segmentUpdate$ = new Subject<SegmentDetails>();
  private maxNumberLanes = maxSingleDirectionLanes;
  private _isMultiDirection?;
  private _contextMenuOptions = contextMenuOptions;
  private isAlive = true;

  @Input()
  set affectedLanes(lanes: RoadEventLane[]) {
    const sorted = Utils.sortAffectedLanes([...(lanes || [])]).map((lane: LaneInput) =>
      this.lanesUtilService.getLaneFormGroup(
        lane.positionIndex,
        lane.number ? lane.number : undefined,
        lane.roadType,
        lane.type,
        lane.direction ? lane.direction : undefined,
        lane.isClosed,
        lane.isAffected
      )
    );
    this.formGroupDirective.form.setControl('affectedLanes', this.fb.array(sorted));
    this.lanesForm = this.formGroupDirective.form.get('affectedLanes') as FormArray;
    this.lanesForm.clear();
    sorted.forEach((control: AbstractControl) => this.lanesForm.push(control));

    if (!this._isMultiDirection) {
      this.updateMenuOptionTypes();
    }
  }

  @Input() set segmentData(segment: SegmentDetails | undefined) {
    this.segmentUpdate$.next(segment);
  }

  get allAffected() {
    return this._allAffected;
  }

  @Input()
  set allAffected(value: boolean) {
    if (this._allAffected === value) return;
    this.handleAllAffectedLanesClick(value);
  }

  get isMultiDirection() {
    return this._isMultiDirection;
  }

  @Input()
  set isMultiDirection(value: boolean) {
    if (this._isMultiDirection === undefined) {
      this._isMultiDirection = value;
      return;
    }

    this._isMultiDirection = value;
    if (this._isMultiDirection) {
      this.maxNumberLanes = maxMultiDirectionLanes;
      this.lanesForm.clear();
      this.lanesForm.push(this.lanesUtilService.getLaneFormGroup(0, 1), { emitEvent: true });
      this.activateMenuOptions();
    } else {
      this.maxNumberLanes = maxSingleDirectionLanes;
      this.updateLanesByQuantity(3);
    }

    if (this._allAffected) {
      this.handleAllAffectedLanesClick(this._allAffected);
    }
  }

  @Input() set direction(value: Direction) {
    this._direction = value;
    if (this.showLoader && this.lanesForm.length !== 0) return;
    if (!this._isMultiDirection) {
      this.lanesForm.controls.forEach(c => c.get('direction')?.setValue(value, { emitEvent: false }));
    }
  }

  @Input() set roadType(value: RoadType) {
    this._roadType = value;
    if (this.showLoader && this.lanesForm.length !== 0) return;
    if (!this._isMultiDirection) {
      this.lanesForm.controls.forEach(c => c.get('roadType')?.setValue(value, { emitEvent: false }));
    }
  }

  get contextMenuOptions() {
    return this._contextMenuOptions;
  }

  get disableAddLane() {
    return this.lanesForm.length === this.maxNumberLanes;
  }

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private lanesUtilService: AffectedLanesUtilsService,
    @Optional() private formGroupDirective: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.segmentUpdate$.pipe(first()).subscribe(segment => {
      this.showLoader = false;
      if (this.lanesForm.value.length === 0) {
        this.updateLanesByQuantity(segment.lanesQuantity || 3);
      }
    });

    this.segmentUpdate$.pipe(skip(1)).subscribe(segment => {
      this.updateLanesByQuantity(segment.lanesQuantity || 3);
    });

    this.lanesForm.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((lanesValue: LaneInput[]) => {
      if (this._isMultiDirection) return;

      const newDirection = lanesValue.find(lane => !!lane.direction && lane.direction !== this._direction)?.direction;

      if (newDirection) {
        this._direction = newDirection;
        this.lanesForm.controls.forEach(lane => {
          lane.get('direction')?.setValue(newDirection, { emitEvent: false });
        });
      }
    });
  }

  private updateLanesByQuantity(lanesQuantity: number) {
    const lanes = getLanesByLanesQuantity(lanesQuantity, this._roadType, this._direction).map(lane =>
      this.lanesUtilService.getLaneFormGroup(
        lane.positionIndex,
        lane.number ? lane.number : undefined,
        this._roadType,
        lane.type,
        lane.direction ? lane.direction : undefined
      )
    );
    this.lanesForm.clear();
    lanes.forEach(control => this.lanesForm.push(control));
    this.recalculateLaneTypes();
    this.updateMenuOptionTypes();
    this.handleAllAffectedLanesClick(this._allAffected);
    this.affectedLanesSlider?.initSwiperConfig();
  }

  handleLaneHover($event) {
    this.hoveredLane = $event.index;
  }

  handleLaneClick(index: number) {
    this.affectedLanesSlider.laneClicked(index);
  }

  diagramLaneHovered(index: number) {
    this.diagramHoveredLane = index;
  }

  addLane(lane: { type: MenuLaneTypes }) {
    let newIndex = 0;
    const laneType = this.getLaneType(lane.type);
    const newLane = this.lanesUtilService.getLaneFormGroup(
      -1,
      -1,
      this._isMultiDirection ? undefined : this._roadType,
      isLaneType(laneType) && this._isMultiDirection ? undefined : laneType
    );

    if (this._isMultiDirection) {
      this.lanesForm.push(newLane);
      newIndex = this.lanesForm.length - 1;
    } else {
      newIndex = this.positionToInsert(lane.type);
      this.lanesForm.insert(newIndex, newLane);
    }
    this.recalculateLaneTypes();
    this.recalculateLaneNumbers();
    this.setPositionIndexes();
    this.updateMenuOptionTypes();
    this.cdr.markForCheck();
    this.affectedLanesSlider.updateSwiper();
    setTimeout(() => {
      this.affectedLanesSlider.swiperElm.setIndex(newIndex);
    });
  }

  removeLane(index: number) {
    this.lanesForm.removeAt(index);
    this.recalculateLaneNumbers();
    this.setPositionIndexes();
    this.updateMenuOptionTypes();
    this.reloadSwiperConfig();
  }

  moveGore(moveGoreAction: MoveGoreAction) {
    const { index, moveLeft } = moveGoreAction;
    if (!moveLeft && index + 1 < this.lanesForm.length) {
      const goreItem = this.lanesForm.at(index);
      this.lanesForm.removeAt(index);
      this.lanesForm.insert(index + 1, goreItem);
      this.affectedLanesSlider.swiperElm.nextSlide();
    } else if (moveLeft && index - 1 >= 0) {
      const goreItem = this.lanesForm.at(index);
      this.lanesForm.removeAt(index);
      this.lanesForm.insert(index - 1, goreItem);
      this.affectedLanesSlider.swiperElm.prevSlide();
    }
    this.setPositionIndexes();
    this.affectedLanesSlider.swiperElm.update();
  }

  private setMenuItemStatus(laneType, status: boolean) {
    this._contextMenuOptions.forEach(item => {
      if (item.type === laneType) {
        item.isActive = status;
        return;
      }
    });
  }
  private activateMenuOptions() {
    this._contextMenuOptions.forEach(item => {
      item.isActive = true;
    });
  }

  getLaneType(type: MenuLaneTypes) {
    switch (type) {
      case this.MenuLaneTypes.LANE:
        return LaneType.CentralLane;
      case this.MenuLaneTypes.GORE:
        return LaneType.Gore;
      case this.MenuLaneTypes.LEFT_SHOULDER:
        return LaneType.LeftShoulder;
      case this.MenuLaneTypes.RIGHT_SHOULDER:
        return LaneType.RightShoulder;
      default:
        return LaneType.CentralLane;
    }
  }

  private setPositionIndexes() {
    this.lanesForm.controls.forEach((lane, index) => {
      lane.get('positionIndex')?.setValue(index);
    });
  }

  private positionToInsert(laneType): number {
    const lanesNumber = this.lanesForm.value.length;
    switch (laneType) {
      case this.MenuLaneTypes.RIGHT_SHOULDER: {
        return lanesNumber;
      }
      case this.MenuLaneTypes.LANE: {
        if (this.lanesForm.value.some(lane => lane.type === LaneType.RightShoulder)) {
          return lanesNumber - 1;
        }
        return 0;
      }
      case this.MenuLaneTypes.GORE: {
        return this.getMaxGoreIndex();
      }
      case this.MenuLaneTypes.LEFT_SHOULDER:
      default:
        return 0;
    }
  }

  updateMenuOptionTypes() {
    if (this._isMultiDirection) return;
    this.setMenuItemStatus(
      this.MenuLaneTypes.LEFT_SHOULDER,
      !this.lanesForm.value.some(lane => lane.type === LaneType.LeftShoulder)
    );
    this.setMenuItemStatus(
      this.MenuLaneTypes.RIGHT_SHOULDER,
      !this.lanesForm.value.some(lane => lane.type === LaneType.RightShoulder)
    );

    this.setMenuItemStatus(this.MenuLaneTypes.GORE, this.canAddGore());
  }

  private handleAllAffectedLanesClick(isChecked: boolean) {
    this._allAffected = isChecked;
    this.lanesForm.controls.forEach(lane => {
      lane.get('isClosed')?.setValue(isChecked);
      lane.get('isAffected')?.setValue(isChecked);
    });
  }

  private recalculateLaneTypes() {
    const regularLanesIndexes: number[] = (this.lanesForm.value as LaneInput[]).reduce((acc: number[], lane, index) => {
      if (isLaneType(lane.type)) {
        acc.push(index);
      }
      return acc;
    }, []);

    if (regularLanesIndexes.length < 3) return;

    const isTurnLeftExists = this.lanesForm.value.some((lane: LaneInput) => lane.type === LaneType.TurnLeft);
    const isTurnRightExists = this.lanesForm.value.some((lane: LaneInput) => lane.type === LaneType.TurnRight);

    regularLanesIndexes.forEach(index => {
      this.lanesForm.at(index).get('type')?.setValue(LaneType.CentralLane);
    });

    this.lanesForm
      .at(regularLanesIndexes[0])
      .get('type')
      ?.setValue(isTurnLeftExists ? LaneType.TurnLeft : LaneType.LeftLane);

    this.lanesForm
      .at(regularLanesIndexes[regularLanesIndexes.length - 1])
      .get('type')
      ?.setValue(isTurnRightExists ? LaneType.TurnRight : LaneType.RightLane);
  }

  recalculateLaneNumbers() {
    let regularNumber = 0;
    let goreNumber = 0;
    this.lanesForm.controls.forEach(lane => {
      if (isLaneSubType(lane.get('type')?.value) || !lane.get('type')?.value) {
        lane.get('number')?.setValue(++regularNumber);
      }
      if (lane.get('type')?.value === LaneType.Gore) {
        lane.get('number')?.setValue(++goreNumber);
      }
    });
  }

  canAddGore() {
    return this.lanesForm.value.filter(lane => lane.type && isLaneSubType(lane.type)).length > 1;
  }

  getMaxGoreIndex() {
    if (this.lanesForm.value.some(lane => lane.type === LaneType.RightShoulder)) {
      return this.lanesForm.value.length - 2;
    } else return this.lanesForm.value.length - 1;
  }

  reloadSwiperConfig() {
    this.affectedLanesSlider?.initSwiperConfig();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
