import { Directive, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RegionalSetting } from '@wc/core';
import { TrafficDisruptionStoryEntity } from '@wc/wc-models';
import { EventSchedulerComponent } from '@wc/wc-ui/src/form-components/scheduler/event-scheduler.component';

@Directive({})
export abstract class TrafficDisruptionBaseClass {
  @Input() abstract entity?: Partial<TrafficDisruptionStoryEntity>;
  @ViewChild('eventSchedulerComponent') abstract eventSchedulerComponent: EventSchedulerComponent;

  abstract formGroup: FormGroup;
  abstract submitEvent(): any;
  abstract get schedularInvalid(): boolean;
  abstract get regionalSetting(): RegionalSetting;
}
