<div
  class="support-container"
  [class]="'direction-' + textDirection"
  wcPopupCloser
  (closed)="closeButtonClicked($event)">
  <wc-icon data-cy-id="close-support-modal" (click)="closeButtonClicked($event)" iconName="close-new"></wc-icon>
  <section class="support-container__first-section">
    <img class="main-image" src="assets/images/support.svg" />
    <div class="title">{{ 'supportComponent.firstSection.title' | translate }}</div>
    <div class="content" [innerHTML]="'supportComponent.firstSection.content' | translate"></div>
    <button class="help-center-btn" rekor-flat data-cy-id="help-center-btn" (click)="openHelpCenter()">
      {{ 'supportComponent.firstSection.exploreSupport' | translate }}
    </button>
  </section>
  <section class="support-container__second-section">
    <div class="title" [innerHTML]="'supportComponent.secondSection.title' | translate"></div>
    <a
      class="support-link"
      rekor-link
      buttonSize="medium"
      data-cy-id="support-link"
      href="https://help.rekor.ai/kb-tickets/new"
      target="_blank"
      >{{ 'supportComponent.secondSection.link' | translate }}</a
    >
    <div class="link-follow-up-text">{{ 'supportComponent.secondSection.linkFollowUpText' | translate }}</div>
    <div class="emergency-text">{{ 'supportComponent.secondSection.emergency' | translate }}</div>
    <div data-cy-id="support-modal-phone-row" class="emergency-number">{{ 'supportPhoneNumber' | translate }}</div>
    <div data-cy-id="support-modal-version-row" class="app-version">
      {{ 'currentVersion' | translate }} {{ appVersion }}
    </div>
  </section>
</div>
