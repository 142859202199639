import { Injectable } from '@angular/core';
import { FindLatestReleaseNotesGQL, ReleaseNote } from '@wc/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReleaseNotesApiService {
  constructor(private findLatestReleaseNotesGQL: FindLatestReleaseNotesGQL) {}

  getLatestReleaseNotes(input: number): Observable<ReleaseNote[]> {
    return this.findLatestReleaseNotesGQL
      .fetch({ pastMonths: input })
      .pipe(map(res => res.data.findLatestReleaseNotes));
  }
}
