export const Day = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
} as const;

export type DayType = typeof Day[keyof typeof Day];
export type Days = DayType[];

export type TimeRange = {
  from: string;
  fromTime?: string;
  to: string;
  toTime?: string;
};

export type RangesOfDay = {
  day: DayType;
  dailyRanges: TimeRange[]; // handles multiple times per day
};

export type RangeOfWeek = RangesOfDay[];

export type WorkingHours = {
  isCustom: boolean;
  ranges: RangeOfWeek;
};

export type WorkScheduleConfiguration = {
  id: number | null;
  isHideEnabled?: boolean;
  workingDays: Days;
  workingHours: WorkingHours;
};
