<ng-container *ngIf="_routeDetails">
  <div class="transit-panel-tab-content">
    <div class="transit-panel-header transit-panel-header--route-view">
      <span class="transit-route-view-title" (click)="clickBack.emit(0)">
        <i class="fas fa-chevron-left"></i>
        {{ 'transit.route' | translate }}
        {{ _routeDetails.number || '0' | translate }}
      </span>
      <mat-slide-toggle
        *ngIf="selectedAffectedServiceUIEntity$ | async as selectedRoute"
        class="heap-desktop-toggle-reRoute-from-route-view-panel"
        (change)="markReroute($event, selectedRoute?.id)"
        [checked]="selectedRoute?.isRerouted">
        {{ 'transit.rerouted' | translate }}
      </mat-slide-toggle>
    </div>

    <div class="transit-secondary-panel">
      <!-- Sub Tabs -->
      <div *ngIf="selectedAffectedServiceUIEntity$ | async as activeService" class="affected-service-route-content">
        <div *ngIf="timerForClearance$ | async as newStartTime" class="affected-service-route-content__avg-delay-badge">
          <i class="fas fa-exclamation-circle"></i>
          {{ 'transit.clearanceTime' | translate }}:
          {{ newStartTime | durationCountDown : activeService.estimatedEndTime }}
          {{ 'analyticsModule.hours' | translate }}
        </div>
        <mat-expansion-panel
          [expanded]="true"
          class="affected-service-mat-expansion affected-service-mat-expansion--route-view">
          <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
            <mat-panel-title>
              <div class="affected-route-header-title">
                {{ 'transit.eventAffectingThisRoute' | translate }} ({{ activeService.affectingEvents?.length }})
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <wc-affected-service-cause
            [affectingEvents]="activeService.affectingEvents || []"></wc-affected-service-cause>
        </mat-expansion-panel>
      </div>
      <mat-tab-group
        class="transit-panel-tabs transit-panel-sub-tabs"
        #routeTabsGroup
        [(selectedIndex)]="routeTabIndex"
        (selectedIndexChange)="routeTabIndexChanged.emit($event)">
        <mat-tab class="transit-panel-tab" [label]="'transit.patternsAndBuses' | translate">
          <div class="transit-sub-tab-content">
            <mat-accordion [multi]="true">
              <div
                class="pattern-card-container"
                #activeBusesScrollAnchor
                *ngFor="let pattern of _routeDetails.patterns">
                <mat-expansion-panel
                  class="pattern-card"
                  (afterExpand)="scrollOnCollapseOpened(activeBusesScrollAnchor)">
                  <mat-expansion-panel-header class="pattern-header" collapsedHeight="*" expandedHeight="*">
                    <mat-panel-title>
                      <div>
                        <div class="pattern-name">{{ pattern.name }}</div>
                        <div class="pattern-direction">
                          {{ pattern.direction | translate }}
                        </div>
                        <div class="pattern-stops-and-buses">
                          <div
                            class="pattern-bus-stops"
                            (click)="clickToGetPatternId.emit(pattern.id); $event.stopPropagation()">
                            {{ pattern.stops.length }}
                            {{ 'transit.stops' | translate }}
                          </div>
                          <div class="pattern-active-buses">
                            {{ pattern.buses.length }}
                            {{ 'transit.activeBuses' | translate }}
                          </div>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <ul class="active-buses-list">
                    <li class="active-bus-list-item" *ngFor="let bus of pattern.buses">
                      <div class="active-bus-title">
                        <wc-icon [iconName]="'transit_fixed_route_bus_unit'"></wc-icon>
                        <div class="active-bus-id">#{{ bus.displayId }}</div>
                      </div>
                      <div *ngIf="bus.status" class="active-bus-status">
                        <div class="active-bus-status-title" [ngClass]="'status-' + bus.status | lowercase">
                          {{ 'busStatuses.' + bus.status | translate }}
                          {{
                            bus.statusInMinutes !== 0
                              ? convertToStringWithSign(bus.statusInMinutes || 0)
                              : bus.statusInMinutes
                          }}
                        </div>
                        <i class="active-bus-status-icon" [ngClass]="'icon-' + bus.status | lowercase"></i>
                      </div>
                    </li>
                  </ul>
                </mat-expansion-panel>
              </div>
            </mat-accordion>
          </div>
        </mat-tab>

        <mat-tab class="transit-panel-tab" [label]="'transit.busStops' | translate">
          <div class="transit-sub-tab-content" *wcLet="busStopsList">
            <div class="bus-stops-counter" *ngIf="busStopsList?.length; else noBusStops">
              {{ busStopsList?.length }}
              {{ 'transit.stops' | translate }}
            </div>

            <div class="bus-stops-list">
              <mat-accordion [multi]="true">
                <div #stopActiveBusesScrollAnchor class="bust-stop-card-container" *ngFor="let stop of busStopsList">
                  <mat-expansion-panel
                    (afterExpand)="scrollOnCollapseOpened(stopActiveBusesScrollAnchor)"
                    class="bus-stop-card">
                    <mat-expansion-panel-header class="bus-stop-header" collapsedHeight="*" expandedHeight="*">
                      <mat-panel-title class="bus-stop-title">
                        <div class="bus-stop-card-first-row">
                          <a class="bus-stop-number" (click)="clickOnStopId.emit(stop.id); $event.stopPropagation()">
                            {{ stop.id }}
                          </a>
                          <div class="bus-stop-name">
                            {{ stop.name }}
                          </div>
                        </div>

                        <div class="bus-stop-next-eta">
                          {{ 'transit.nextEta' | translate }}

                          <ng-container *ngIf="stop.nextEtaInMinutes; else noStopEta">
                            {{ stop.nextEtaInMinutes }}
                            {{ 'timeUnits.min' | translate }}
                          </ng-container>

                          <ng-template #noStopEta>
                            {{ 'NA' | translate }}
                          </ng-template>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ul class="stop-active-buses-list">
                      <li class="stop-active-bus-item" *ngFor="let bus of stop.buses">
                        <div class="stop-active-bus-data">
                          <span class="stop-active-bus-eta">
                            {{ bus.nextEtaInMinutes }}
                            {{ 'timeUnits.min' | translate }}
                          </span>

                          <div class="stop-active-bus-title">
                            <span class="stop-active-bus-id"> #{{ bus.id }} </span>
                            <span class="stop-active-bus-pattern" [title]="getPatternNameById(bus.patternId)">
                              {{ getPatternNameById(bus.patternId) }}
                            </span>
                          </div>
                        </div>

                        <div
                          *ngIf="bus.status"
                          class="stop-active-bus-status active-bus-status-title"
                          [ngClass]="'status-' + bus.status.toLowerCase()">
                          {{ 'busStatuses.' + bus.status | translate }}
                          {{
                            bus.statusInMinutes !== 0
                              ? convertToStringWithSign(bus.statusInMinutes)
                              : bus.statusInMinutes
                          }}
                          <i class="active-bus-status-icon" [ngClass]="'icon-' + bus.status.toLowerCase()"></i>
                        </div>
                      </li>
                    </ul>
                  </mat-expansion-panel>
                </div>
              </mat-accordion>
            </div>

            <ng-template #noBusStops>
              <span class="no-bus-stops">
                {{ 'NA' | translate }}
              </span>
            </ng-template>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</ng-container>
