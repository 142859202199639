import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import {
  FocusDirectiveModule,
  FormatsPipesModule,
  WcPopupCloserModule,
  WeatherEventSortPipeModule,
} from '@wc/wc-common/pipes';
import { WcIconModule } from '../../wc-icon/wc-icon.module';
import { WeatherEventsPanelComponent } from './weather-events-panel.component';

@NgModule({
  declarations: [WeatherEventsPanelComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSelectModule,
    FormatsPipesModule,
    MatExpansionModule,
    WcIconModule,
    WcPopupCloserModule,
    FocusDirectiveModule,
    WeatherEventSortPipeModule,
  ],
  exports: [WeatherEventsPanelComponent],
})
export class WeatherEventsPanelModule {}
