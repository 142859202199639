import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WcMapViewerService } from '@wc/wc-map-viewer/src';
import { wcCoordinateTypes, WcGeometry } from '@wc/wc-map-viewer/src/types';
import GeometryType from 'ol/geom/GeometryType';
import { MapEditorComponent } from '../../../components/map-viewers/map-editor/map-editor.component';

export type MapGeometryEditorDialogData = {
  geometry: WcGeometry;
  accountCenterCoords: wcCoordinateTypes;
  modalHeaderLabel: string;
  modalSubmitButtonLabel: string;
};

@Component({
  selector: 'wc-map-geometry-editor-modal',
  templateUrl: './map-geometry-editor-modal.component.html',
  styleUrls: ['./map-geometry-editor-modal.component.scss'],
  providers: [WcMapViewerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapGeometryEditorModalComponent implements OnInit {
  @ViewChild(MapEditorComponent) mapEditor?: MapEditorComponent;
  private emptyGeometry: WcGeometry = {
    type: GeometryType.POLYGON,
    coordinates: undefined,
  };

  dialogRef: MatDialogRef<MapGeometryEditorModalComponent> | null;
  dialogData: MapGeometryEditorDialogData;

  modalHeaderLabel = '';
  modalSubmitButtonLabel = '';

  @Input() accountCenterCoords!: wcCoordinateTypes;

  @Input() geometryBoundingZoomPadding!: number[];

  @Input() geometry: WcGeometry = this.emptyGeometry;

  constructor(private injector: Injector, public cdr: ChangeDetectorRef) {
    // inject MatDialogRef this way to support storybook story for that component
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
  }

  ngOnInit(): void {
    this.dialogRef?.afterOpened().subscribe(() => {
      this.geometry = this.dialogData.geometry || this.emptyGeometry;
      this.accountCenterCoords = this.dialogData.accountCenterCoords;
      this.modalHeaderLabel = this.dialogData.modalHeaderLabel;
      this.modalSubmitButtonLabel = this.dialogData.modalSubmitButtonLabel;
      this.cdr.detectChanges();
    });
  }

  geometryChanged(geometry: WcGeometry) {
    this.geometry = geometry;
  }

  clear() {
    this.geometry = this.emptyGeometry;
    this.mapEditor?.reset(this.emptyGeometry);
    this.cdr.detectChanges();
  }

  confirmCloseModal() {
    this.dialogRef?.close(this.geometry);
  }

  closeModal() {
    this.dialogRef?.close();
  }
}
