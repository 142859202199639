<div class="dms-content-component" [class.tablet-mode]="isTablet">
  <div class="dms-content-header">
    <span class="dms-messages-counter">
      <span class="dms-counter-value">{{ activeSlideIndex }} / {{ dmsMessages.length }}</span>
      {{ 'messages' | translate }}
    </span>

    <span class="dms-last-updated" *ngIf="lastUpdated">
      <span data-cy-id="dms-last-updated-title" class="last-updated-title"> {{ 'lastUpdated' | translate }} </span>:
      <span class="last-updated-value">
        {{ lastUpdated | dateFormat: accountRegionalSettings }}
      </span>
    </span>
  </div>

  <div class="swiper-component">
    <div class="swiper-container" [swiper]="dmsListSwiperConfig" (slideChange)="onSlideChange($event)">
      <div class="swiper-wrapper">
        <div data-cy-id="dms-message" class="swiper-slide" *ngFor="let dmsMessage of dmsMessages">
          <wc-dms-viewer [message]="dmsMessage" [isTablet]="isTablet"></wc-dms-viewer>
        </div>
      </div>

      <div class="swiper-pagination-dms swiper-pagination"></div>
    </div>

    <div class="swiper-button-prev dms-swiper-button-prev"></div>
    <div class="swiper-button-next dms-swiper-button-next"></div>
  </div>
</div>
