import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { APP_TYPE_TOKEN } from '@wc/wc-core/src/lib/injection-tokens';
import { AppTypeUnion } from '@wc/wc-models/src';

@Component({
  selector: 'wc-collaboration-viewer',
  templateUrl: './collaboration-viewer.component.html',
  styleUrls: ['./collaboration-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollaborationViewerComponent {
  _viewerDrivers: string[] = [];
  _editDrivers: string[] = [];

  constructor(@Inject(APP_TYPE_TOKEN) private _appType: AppTypeUnion) {}

  @Input() viewMode: 'item' | 'view' = 'view';

  @Input() set viewerDrivers(viewers) {
    this._viewerDrivers = viewers || [];
  }
  @Input() set editDrivers(editing) {
    this._editDrivers = editing || [];
  }

  showCollaborationUsers = false;

  get appType(): AppTypeUnion {
    return this._appType;
  }

  get isCollaborationExist() {
    return this._viewerDrivers?.length > 0 || this._editDrivers?.length > 0;
  }

  get amountOfCollaborators() {
    return this._viewerDrivers?.length + this._editDrivers?.length;
  }

  get isBeingEdited() {
    return this._editDrivers.length > 0 ? true : false;
  }

  get displayName() {
    if (this._editDrivers.length > 0) {
      return this._editDrivers[0];
    } else if (this._viewerDrivers.length > 0) {
      return this._viewerDrivers[0];
    }
    return '';
  }
}
