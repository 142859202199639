import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { WcIconModule } from '../../components/wc-icon/wc-icon.module';
import { DragDropTextAreaComponent } from './drag-drop-text-area.component';

@NgModule({
  declarations: [DragDropTextAreaComponent],
  imports: [CommonModule, TranslateModule, WcIconModule, MatFormFieldModule],
  exports: [DragDropTextAreaComponent],
})
export class WcDragDropTextAreaModule {}
