import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import deepmerge from 'deepmerge';
import { Observable, of, Subject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { SplitIOService } from '.';
import { featureSubTypeOf } from '../../utils';
import { environment } from '../environments/environment';
import { DiffParams, EntitiesDiff, Incident } from '../models';
import { UsersService } from './users.service';

export type Entity = Incident; // Add more types as a union
export type DiffProvider = () => any;
@Injectable({
  providedIn: 'root',
})
export class EntitiesService {
  lastUpdatedAt = 0;
  isWaitingForResponse = false;
  params: DiffParams = {};
  offlineDiffProviders: Array<DiffProvider> = [];
  entityUpdated$: Subject<Entity> = new Subject();
  constructor(private http: HttpClient, private usersService: UsersService, private splitIOService: SplitIOService) {}

  async getAll(params?: DiffParams): Promise<any> {
    this.params = { ...this.params, ...params } || {};
    this.lastUpdatedAt = 0;

    const res = await this.getDiff().toPromise();
    console.log({ res });
    return res?.modified;
  }

  registerDiffProvider(provider: DiffProvider) {
    if (this.offlineDiffProviders.indexOf(provider) === -1) {
      this.offlineDiffProviders.push(provider);
    }
  }

  offlineDiff() {
    let diff: any = {};
    for (const provider of this.offlineDiffProviders) {
      diff = deepmerge(diff, provider());
    }
    return diff;
  }

  getDiff(): Observable<{ updatedAt: number; modified: {}; removed: {} }> {
    let diffResult: Observable<any>;
    // To avoid creating more calls when waiting for a response
    if (this.isWaitingForResponse && this.lastUpdatedAt !== 0) {
      return of();
    }
    if (navigator.onLine) {
      this.isWaitingForResponse = true;
      let url = `${environment.live_map_api_url}/diff?lastUpdatedAt=${this.lastUpdatedAt}`;
      url = this.params.filterRoutesByShiftId
        ? url + '&filterRoutesByShiftId=' + this.params.filterRoutesByShiftId
        : url;
      diffResult = this.http.get<EntitiesDiff>(url);
    } else {
      diffResult = of({});
    }

    return diffResult.pipe(
      map(res => {
        // TEMP MOCK SEGMENT DATA:
        const mockSegmentData = {
          segment_temp: {
            1123581321: {
              id: 1123581321,
              title: 'fist segment',

              location: {
                type: 'LineString',
                coordinates: [
                  [-115.2790582180023, 36.06614231629902],
                  [-115.26628017425537, 36.06608160860076],
                ],
              },
            },
            112358132133: {
              id: 112358132133,
              title: 'second segment',
              location: {
                type: 'LineString',
                coordinates: [
                  [-115.2738332748413, 36.06633311161714],
                  [-115.26169896125792, 36.06628974908546],
                ],
              },
            },
          },
        };

        //------------------------

        res['modified'] = { ...res['modified'], ...mockSegmentData };

        let layers, layer, layerEntityById, _entity;
        ///// Add mock units
        // res.modified = { ...res.modified, ...TransitUnitsMock };

        if (this.offlineDiff) res = deepmerge(this.offlineDiff(), { ...res });

        layers = res['modified'];

        for (const entityType in layers) {
          if (layers.hasOwnProperty(entityType)) {
            layer = layers[entityType];
            for (const entity in layer) {
              if (layer.hasOwnProperty(entity)) {
                _entity = layer[entity];

                this.entityUpdated$.next(_entity);

                layerEntityById = layers[entityType][_entity.id];

                layerEntityById['id'] = _entity.id;
                layerEntityById['type'] = entityType;
                layerEntityById['featureType'] = entityType;
                layerEntityById['featureSubTypeOf'] = featureSubTypeOf(entityType) || entityType;
                if (_entity.associatedUnits) {
                  const associatedUnitsUsersIds = _entity.associatedUnits.map(unit => unit.driverDetails?.userId.value);
                  layerEntityById['associatedUnitsUsersIds'] = associatedUnitsUsersIds;
                }

                if (_entity.mitigatedAccounts) {
                  layerEntityById['mitigatedByMyAccount'] = false;
                  _entity.mitigatedAccounts.forEach(val => {
                    if (val['value'] === this.usersService.authUser.account.id) {
                      layerEntityById['mitigatedByMyAccount'] = true;
                    }
                  });
                }
              }
            }
          }
        }
        return res;
      }),
      tap(res => {
        if (res.updatedAt) {
          this.lastUpdatedAt = res.updatedAt;
        }
        this.isWaitingForResponse = false;
      }),
      finalize(() => {
        this.isWaitingForResponse = false;
      })
    );
  }
}
