import { Injectable } from '@angular/core';
import { Media, MediaSource } from '@wc/core';
import { MediaFilterType } from '@wc/wc-models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  totalFilesSize = new BehaviorSubject(0);

  calculateMediaFileSize(size) {
    const fileSize = Number(size) || 0;
    const fileSizeInMB = fileSize / 1024 / 1024; // In MB
    return fileSizeInMB;
  }

  updateTotalFilesSize(files: Array<Media>): void {
    let currentTotalFilesSize = 0;
    files.forEach((file: Media) => {
      currentTotalFilesSize += this.calculateMediaFileSize(file.media.fileSize);
    });

    this.totalFilesSize.next(currentTotalFilesSize);
  }

  filterMediaBySource(mediaList: Array<Media | File>, filterBy: MediaFilterType): Array<Media | File> {
    return mediaList.filter(mediaItem => {
      const source = (mediaItem as Media).media.source;

      if (filterBy === MediaFilterType.CctvSnapshots) {
        return source === MediaSource.PlatformSnapshot || source === MediaSource.UserSnapshot;
      } else {
        return source === MediaSource.Upload || source === MediaSource.ExternalFeed || !source;
      }
    });
  }
}
