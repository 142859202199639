import { Pipe, PipeTransform } from '@angular/core';
import { UsersStore } from '@wc/core/stores/users.store';

@Pipe({
  name: 'permission',
  pure: true,
})
export class PermissionPipe implements PipeTransform {
  constructor(private usersStore: UsersStore) {}

  transform(...args: any[]): any {
    return this.usersStore.hasPermission(args);
  }
}
