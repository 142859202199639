/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { LayerType } from '@wc/core/models';
import { RoadwayStatusStoreEntity, RoadwayStatusUIEntity } from '@wc/wc-models/src/lib/types/entities';
import { map } from 'rxjs/operators';
import { EntitiesServiceV2 } from '../../../services/entities.service';

export interface RoadwayStatusState extends EntityState<RoadwayStatusStoreEntity, number> {}
export interface RoadwayStatusUIState extends EntityState<RoadwayStatusUIEntity> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: LayerType.RoadwayStatus })
export class RoadwayStatusStore extends EntityStore<RoadwayStatusState> {
  private layerTypeName = LayerType.RoadwayStatus;
  ui!: EntityUIStore<RoadwayStatusUIState>;

  constructor(entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$
      .pipe(map(({ ROADWAY_STATUS }) => ROADWAY_STATUS || []))
      .subscribe(uiRoadwayStatusSegments => this.ui.upsertMany(uiRoadwayStatusSegments));

    entitiesService.getDiff$
      .pipe(
        map(({ modified, removed }) => ({
          removed: removed?.[this.layerTypeName],
          modified: modified[this.layerTypeName],
        }))
      )
      .subscribe(({ removed, modified }) => {
        if (modified) this.upsertMany(modified as RoadwayStatusStoreEntity[]);
        if (removed) this.remove(removed);
      });

    entitiesService.activeEntityIds$.pipe(map(({ ROADWAY_STATUS }) => ROADWAY_STATUS || [])).subscribe(id => {
      this.ui.setActive(id);
    });
  }
}
