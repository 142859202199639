import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Resolve,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { IncidentsQuery, IncidentsService } from '@wc-core';
import { noop } from 'lodash';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { Incident } from '../models';
import { IncidentService, SplitIOService } from '../services';
import { UiStore } from '../stores//ui.store';
import { EntitiesStore } from '../stores/entities.store';
import { UsersStore } from '../stores/users.store';

@Injectable({
  providedIn: 'root',
})
export class IncidentResolverService implements Resolve<Incident>, CanLoad, CanActivate {
  incidentLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private usersStore: UsersStore,
    private incidentService: IncidentService,
    private incidentsService: IncidentsService,
    private router: Router,
    private uiStore: UiStore,
    private entityStore: EntitiesStore,
    private splitIoService: SplitIOService,
    private incidentQuery: IncidentsQuery
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.usersStore.hasPermission('INCIDENT:READ')) {
      return true;
    } else {
      this.router.navigate(['/live-map']);
      return false;
    }
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.incidentLoaded;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const id = Number(route.paramMap.get('id'));
    return this.incidentService.getIncident(id).pipe(
      map(incident => {
        if (!incident && !navigator.onLine) {
          incident = !this.uiStore.isTabletMode
            ? this.incidentQuery.getEntity(id)
            : this.entityStore.getEntity(String(id));
        }
        if (incident) {
          return incident;
        } else if (navigator.onLine) {
          // id not found
          this.router.navigate(['/live-map']);
          this.uiStore.hideLoader('incident');
          return null;
        } else {
          this.router.navigate(['/incidents']);
          this.uiStore.hideLoader('incident');
          return null;
        }
      }),
      !this.uiStore.isTabletMode ? this.incidentsService.emitIncidentDiffAndUpdateLiveMap() : tap(noop),
      first(),
      catchError(err => {
        this.uiStore.hideLoader('incident');
        return throwError(err);
      })
    );
  }
}
