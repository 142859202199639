<div class="unit-cards-list" [class.tablet-mode]="isTabletMode">
  <div class="swiper-component">
    <div class="swiper-container" [swiper]="config">
      <div class="swiper-wrapper">
        <div class="swiper-slide associated-unit-slide" *ngFor="let unit of unitList">
          <wc-unit-card
            [analyticsMode]="analyticsMode"
            [incidentUnitDetails]="unit"
            [incident]="incident"
            [associatedUnit]="unit"></wc-unit-card>
        </div>
      </div>

      <div class="swiper-pagination-associated-units swiper-pagination" *ngIf="unitList?.length > 1"></div>
    </div>

    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</div>
