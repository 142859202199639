<div
  class="unit-card-component"
  [class.tablet-mode]="isTabletMode"
  [class.analytics-mode]="analyticsMode"
  [class.language-hebrew]="this.isHebrew">
  <div class="unit-card-header-with-status">
    <div
      class="unit-card-header"
      [ngClass]="
        'color-' + (analyticsMode ? incidentUnitDetails.type?.toLowerCase() : associatedUnit.type?.toLowerCase())
      ">
      <div class="unit-header-row">
        <wc-icon
          class="unit-type-icon"
          [iconName]="
            analyticsMode ? incidentUnitDetails.type?.toLowerCase() : associatedUnit.type?.toLowerCase()
          "></wc-icon>
        <!-- <i
          class="unit-type-icon"
          [ngClass]="analyticsMode ? incidentUnitDetails.type?.toLowerCase() : associatedUnit.type?.toLowerCase()"></i> -->

        <div class="unit-type">
          {{
            'VehicleTypes.' +
              (analyticsMode ? incidentUnitDetails.type?.toUpperCase() : associatedUnit.type?.toUpperCase()) | translate
          }}
        </div>

        <div class="unit-id" *ngIf="unitId">
          {{ unitId }}
        </div>

        <wc-chip
          *ngIf="usersStore.authUser.id === associatedUnit.driverDetails?.userId"
          [isMeStyle]="true"
          [text]="'me' | translate"
          class="me-badge hide-in-landscape"></wc-chip>
      </div>

      <div class="unit-header-row">
        <div class="driver-name" *ngIf="userName">
          {{ userName }}
        </div>

        <wc-chip
          *ngIf="usersStore.authUser.id === associatedUnit.driverDetails?.userId"
          [isMeStyle]="true"
          [text]="'me' | translate"
          class="me-badge hide-in-portrait"></wc-chip>

        <div class="unit-routes" *ngIf="unitRoutes?.length">
          <span class="unit-popup-badge" *ngFor="let route of unitRoutes">
            {{ route }}
          </span>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!analyticsMode; else responseAnalytics">
      <div class="unit-status-line" [ngClass]="associatedUnit.unitResponse?.toLowerCase()">
        <i class="unit-response-icon" [ngClass]="associatedUnit.unitResponse?.toLowerCase()"></i>
        {{ 'unitResponse.' + associatedUnit.unitResponse?.toLowerCase() | translate }}
      </div>
    </ng-container>
  </div>

  <ng-template #responseAnalytics>
    <div
      class="unit-status-line"
      [ngClass]="
        incidentUnitDetails.responses[0]
          ? incidentUnitDetails.responses[0].unitResponse?.toLowerCase()
          : unitResponse.UnknownUnitStatus?.toLowerCase()
      ">
      <i
        class="unit-response-icon"
        [ngClass]="
          incidentUnitDetails.responses[0]
            ? incidentUnitDetails.responses[0].unitResponse?.toLowerCase()
            : unitResponse.UnknownUnitStatus?.toLowerCase()
        "></i>
      {{
        'unitResponse.' +
          (incidentUnitDetails.responses[0]
            ? incidentUnitDetails.responses[0].unitResponse?.toLowerCase()
            : (unitResponse.UnknownUnitStatus | lowercase)) | translate
      }}
    </div>
  </ng-template>

  <div *ngIf="!analyticsMode; else mitigationAnalytics" class="unit-card-body">
    <ng-container *ngIf="unitMitigation?.length; else emptyMitigation">
      <div class="start-end-time-container">
        <div class="start-time">
          <div class="time-label">
            {{ 'startTime' | translate }}
          </div>
          <div class="time-value">
            {{ unitMitigation[0].interval.from | dateFormat : accountStore.account.regionalSetting }}
          </div>
        </div>

        <div class="end-time">
          <div class="time-label">
            {{ 'endTime' | translate }}
          </div>
          <div class="time-value">
            <ng-container *ngIf="unitMitigation[0].interval.to; else emptyTimeTo">
              {{ unitMitigation[0].interval.to | dateFormat : accountStore.account.regionalSetting }}
            </ng-container>

            <ng-template #emptyTimeTo> --- </ng-template>
          </div>
        </div>
      </div>

      <div class="mitigation-title">{{ 'mitigations' | translate }} ({{ unitMitigation.length }})</div>

      <div class="unit-mitigation">
        <wc-chip
          [style]="chipStyles.mitigation"
          [isTablet]="isTabletMode"
          [text]="mitigation.mitigationType?.description"
          *ngFor="let mitigation of unitMitigation"></wc-chip>
      </div>
    </ng-container>

    <ng-template #emptyMitigation>
      <div class="empty-state-container">
        <i class="empty-mitigation-icon"></i>
        <div class="empty-state-message">
          {{ 'emptyStates.noMitigationMessage' | translate }}
        </div>
        <div class="empty-state-action" *ngIf="usersStore.authUser.id === associatedUnit.driverDetails?.userId">
          {{ 'emptyStates.noMitigationAction' | translate }}
        </div>
      </div>
    </ng-template>
  </div>

  <ng-template #mitigationAnalytics>
    <div class="unit-card-body">
      <ng-container *ngIf="incidentUnitDetails.mitigations?.length">
        <div class="mitigation-title">
          {{ 'mitigations' | translate }} ({{ incidentUnitDetails.mitigations?.length }})
        </div>

        <div class="unit-mitigation unit-mitigation-analytics">
          <wc-chip
            [style]="chipStyles.mitigation"
            [isTablet]="isTabletMode"
            [text]="mitigation?.mitigationDescription"
            *ngFor="let mitigation of incidentUnitDetails.mitigations"></wc-chip>
        </div>
      </ng-container>
      <div class="associated-units-details-scroll">
        <div *ngIf="incidentUnitDetails.responses?.length > 0; else noResponse" class="unit-response-time-container">
          <div class="container-of-timeline">
            <div
              *ngFor="let response of incidentUnitDetails.responses; let i = index"
              class="timeline-block timeline-block-right">
              <div class="marker"></div>
              <div class="timeline-content">
                <div *ngIf="i === 0" class="timeline-date">
                  {{ response.responseTime | date : 'dd MMM yyyy' }}
                </div>
                <span> {{ response.responseTime | date : 'HH:mm' }}</span>
                <ng-container [ngSwitch]="response.unitResponse">
                  <p *ngSwitchCase="unitResponse.Accepted">
                    <span>{{ 'unitResponse.' + response.unitResponse.toLocaleLowerCase() | translate }}</span>
                    {{ 'activitiesLog.INCIDENT' | translate }}
                  </p>
                  <p *ngSwitchCase="unitResponse.Completed">
                    <span>{{ 'unitResponse.' + response.unitResponse.toLocaleLowerCase() | translate }}</span>
                    {{ 'activitiesLog.INCIDENT' | translate }}
                  </p>
                  <p *ngSwitchCase="unitResponse.Rejected">
                    <span> {{ 'unitResponse.' + response.unitResponse.toLocaleLowerCase() | translate }}</span>
                    {{ 'activitiesLog.INCIDENT' | translate }}
                  </p>
                  <p *ngSwitchDefault>
                    {{ 'wasMarked' | translate }}
                    <span>
                      {{ 'unitResponse.' + response.unitResponse.toLocaleLowerCase() | translate }}
                    </span>
                  </p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #noResponse>
        <div class="empty-state-container">
          <img class="mitigation-empty-state-icon" src="/assets/images/empty-unit-response-info.svg" />
          <div class="empty-state-message">
            {{ 'emptyStates.noInformation' | translate }}
          </div>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
