import { Injectable } from '@angular/core';
import { Scalars, SegmentDetails, SegmentGQL } from '@wc/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import { CustomRxOperatorsService } from './custom-rx-operators.service';

export enum SegmentAliasType {
  Corridor = 'CORRIDOR',
  Crossroad = 'CROSSROAD',
  Both = 'BOTH',
  None = 'NONE',
}

type AddedOrEditedCustomAlias = {
  isAlias: boolean;
  createdAliasType: SegmentAliasType;
  updatedAliasType: SegmentAliasType;
};

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  lastSegmentDetails$: BehaviorSubject<SegmentDetails | null> = new BehaviorSubject(<SegmentDetails | null>null);
  isAddedOrEditedCustomAlias$: BehaviorSubject<AddedOrEditedCustomAlias> =
    new BehaviorSubject<AddedOrEditedCustomAlias>({
      isAlias: false,
      createdAliasType: SegmentAliasType.None,
      updatedAliasType: SegmentAliasType.None,
    });

  constructor(private segmentGQL: SegmentGQL, private customOperators: CustomRxOperatorsService) {}

  getSegmentDetails(point: Scalars['Point']): Observable<SegmentDetails | undefined> {
    if (point.type !== 'Point' || (point.coordinates[0] && point.coordinates[0] instanceof Array)) {
      return throwError({
        error: `Point type should be Point but got: ${JSON.stringify(point)}`,
      });
    }
    return this.segmentGQL.fetch({ point: point, radiusMeters: 3000 }).pipe(
      map(res => {
        if (res && res.errors) throw { errorCode: res.errors[0].extensions?.statusCode };
        if (res.data !== null) {
          this.lastSegmentDetails$.next(res.data.segment);
          return res.data.segment as SegmentDetails;
        } else {
          return undefined;
        }
      }),
      catchError(err => {
        this.lastSegmentDetails$.next(null);
        throw err;
      }),
      this.customOperators.catchGqlErrors()
    );
  }
}
