import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumericIncreaseDecreaseComponent } from './numeric-increase-decrease.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [NumericIncreaseDecreaseComponent],
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, MatInputModule],
  exports: [NumericIncreaseDecreaseComponent],
})
export class NumericIncreaseDecreaseModule {}
