import { LayerType } from '@wc/core';
import { MapLayer } from '@wc/wc-map-viewer/src/types';
import { EntityState, LayerNameStyleConfigKeys } from '@wc/wc-models/src';
import { LayersZIndexConfig } from './layers-z-index.config';

const _incidentsLayersConfig: MapLayer<LayerNameStyleConfigKeys, EntityState> = {
  debris: {
    zIndex: LayersZIndexConfig.debris,
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'debris' }, zIndex: 10 }],
        hover: [{ shape: 'marker', icon: { iconName: 'debris.hover' }, zIndex: 10 }],
        selected: [{ shape: 'marker', icon: { iconName: 'debris.selected' }, zIndex: 10 }],
      },
      unconfirmed: {
        default: [{ shape: 'marker', icon: { iconName: 'debris.unconfirmed' }, zIndex: 10 }],
        hover: [{ shape: 'marker', icon: { iconName: 'debris.unconfirmed_hover' }, zIndex: 10 }],
        selected: [{ shape: 'marker', icon: { iconName: 'debris.unconfirmed_selected' }, zIndex: 10 }],
      },
      completed: { default: [{ shape: 'marker', icon: { iconName: 'debris.completed' }, zIndex: 10 }] },
      rejected: { default: [{ shape: 'marker', icon: { iconName: 'debris.rejected' }, zIndex: 10 }] },
    },
  },
  stalled_vehicle: {
    zIndex: LayersZIndexConfig.stalled_vehicle,
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'disabled_vehicle' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'disabled_vehicle.hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'disabled_vehicle.selected' } }],
      },
      unconfirmed: {
        default: [{ shape: 'marker', icon: { iconName: 'disabled_vehicle.unconfirmed' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'disabled_vehicle.unconfirmed_hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'disabled_vehicle.unconfirmed_selected' } }],
      },
      completed: { default: [{ shape: 'marker', icon: { iconName: 'disabled_vehicle.completed' } }] },
      rejected: { default: [{ shape: 'marker', icon: { iconName: 'disabled_vehicle.rejected' } }] },
    },
  },
  abandoned_vehicle: {
    zIndex: LayersZIndexConfig.abandoned_vehicle,
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'abandoned_vehicle' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'abandoned_vehicle.hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'abandoned_vehicle.selected' } }],
      },
      unconfirmed: {
        default: [{ shape: 'marker', icon: { iconName: 'abandoned_vehicle.unconfirmed' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'abandoned_vehicle.unconfirmed_hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'abandoned_vehicle.unconfirmed_selected' } }],
      },
      completed: {
        default: [{ shape: 'marker', icon: { iconName: 'abandoned_vehicle.completed' } }],
      },
      rejected: {
        default: [{ shape: 'marker', icon: { iconName: 'abandoned_vehicle.rejected' } }],
      },
    },
  },
  crash: {
    zIndex: LayersZIndexConfig.crash,
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'crash' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'crash.hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'crash.selected' } }],
      },
      unconfirmed: {
        default: [{ shape: 'marker', icon: { iconName: 'crash.unconfirmed' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'crash.unconfirmed_hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'crash.unconfirmed_selected' } }],
      },
      completed: { default: [{ shape: 'marker', icon: { iconName: 'crash.completed' } }] },
      rejected: { default: [{ shape: 'marker', icon: { iconName: 'crash.rejected' } }] },
    },
  },
  left_on_arrival: {
    zIndex: LayersZIndexConfig.left_on_arrival,
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'left_on_arrival' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'left_on_arrival.hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'left_on_arrival.selected' } }],
      },
      unconfirmed: {
        default: [{ shape: 'marker', icon: { iconName: 'left_on_arrival.unconfirmed' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'left_on_arrival.unconfirmed_hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'left_on_arrival.unconfirmed_selected' } }],
      },
      completed: { default: [{ shape: 'marker', icon: { iconName: 'left_on_arrival.completed' } }] },
      rejected: { default: [{ shape: 'marker', icon: { iconName: 'left_on_arrival.rejected' } }] },
    },
  },
  hazard: {
    zIndex: LayersZIndexConfig.hazard,
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'hazard' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'hazard.hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'hazard.selected' } }],
      },
      unconfirmed: {
        default: [{ shape: 'marker', icon: { iconName: 'hazard.unconfirmed' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'hazard.unconfirmed_hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'hazard.unconfirmed_selected' } }],
      },
      completed: { default: [{ shape: 'marker', icon: { iconName: 'hazard.completed' } }] },
      rejected: { default: [{ shape: 'marker', icon: { iconName: 'hazard.rejected' } }] },
    },
  },
  vehicle_on_fire: {
    zIndex: LayersZIndexConfig.vehicle_on_fire,
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'vehicle_on_fire' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'vehicle_on_fire.hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'vehicle_on_fire.selected' } }],
      },
      unconfirmed: {
        default: [{ shape: 'marker', icon: { iconName: 'vehicle_on_fire.unconfirmed' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'vehicle_on_fire.unconfirmed_hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'vehicle_on_fire.unconfirmed_selected' } }],
      },
      completed: { default: [{ shape: 'marker', icon: { iconName: 'vehicle_on_fire.completed' } }] },
      rejected: { default: [{ shape: 'marker', icon: { iconName: 'vehicle_on_fire.rejected' } }] },
    },
  },
  police_activity: {
    zIndex: LayersZIndexConfig.police_activity,
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'police_activity' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'police_activity.hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'police_activity.selected' } }],
      },
      unconfirmed: {
        default: [{ shape: 'marker', icon: { iconName: 'police_activity.unconfirmed' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'police_activity.unconfirmed_hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'police_activity.unconfirmed_selected' } }],
      },
      completed: { default: [{ shape: 'marker', icon: { iconName: 'police_activity.completed' } }] },
      rejected: { default: [{ shape: 'marker', icon: { iconName: 'police_activity.rejected' } }] },
    },
  },
  traffic_stop: {
    zIndex: LayersZIndexConfig.traffic_stop,
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'traffic_stop' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'traffic_stop.hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'traffic_stop.selected' } }],
      },
      unconfirmed: {
        default: [{ shape: 'marker', icon: { iconName: 'traffic_stop.unconfirmed' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'traffic_stop.unconfirmed_hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'traffic_stop.unconfirmed_selected' } }],
      },
      completed: { default: [{ shape: 'marker', icon: { iconName: 'traffic_stop.completed' } }] },
      rejected: { default: [{ shape: 'marker', icon: { iconName: 'traffic_stop.rejected' } }] },
    },
  },
  ems: {
    zIndex: LayersZIndexConfig.ems,
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'ems' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'ems.hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'ems.selected' } }],
      },
      unconfirmed: {
        default: [{ shape: 'marker', icon: { iconName: 'ems.unconfirmed' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'ems.unconfirmed_hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'ems.unconfirmed_selected' } }],
      },
      completed: { default: [{ shape: 'marker', icon: { iconName: 'ems.completed' } }] },
      rejected: { default: [{ shape: 'marker', icon: { iconName: 'ems.rejected' } }] },
    },
  },
  damage: {
    zIndex: LayersZIndexConfig.damage,
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'damage' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'damage.hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'damage.selected' } }],
      },
      unconfirmed: {
        default: [{ shape: 'marker', icon: { iconName: 'damage.unconfirmed' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'damage.unconfirmed_hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'damage.unconfirmed_selected' } }],
      },
      completed: { default: [{ shape: 'marker', icon: { iconName: 'damage.completed' } }] },
      rejected: { default: [{ shape: 'marker', icon: { iconName: 'damage.rejected' } }] },
    },
  },
  wrong_way: {
    zIndex: LayersZIndexConfig.wrong_way,
    pointStatusStyle: {
      default: {
        default: [
          { shape: 'marker', icon: { iconName: 'wrong_way' } },
          {
            shape: 'marker',
            zIndex: 2,
            visible: { sourceField: 'wrongWayAlert' },
            icon: { iconName: { sourceField: 'wrongWayAlertTranslatedIcon' }, scale: 1, anchor: [-0.1125, 2.65] },
          },
        ],
        hover: [
          { shape: 'marker', icon: { iconName: 'wrong_way.hover' } },
          {
            shape: 'marker',
            zIndex: 2,
            visible: { sourceField: 'wrongWayAlert' },
            icon: { iconName: { sourceField: 'wrongWayAlertTranslatedIcon' }, scale: 1, anchor: [-0.1125, 2.725] },
          },
          {
            shape: 'marker',
            visible: { sourceField: 'wrongWayAlert' },
            icon: {
              iconName: 'wrong_way_alert_hover_background',
              scale: 1,
              zIndex: -1,
              anchor: [0, 1.875],
            },
          },
        ],
        selected: [
          { shape: 'marker', icon: { iconName: 'wrong_way.selected' } },
          {
            shape: 'marker',
            zIndex: 2,
            visible: { sourceField: 'wrongWayAlert' },
            icon: { iconName: { sourceField: 'wrongWayAlertTranslatedIcon' }, scale: 1, anchor: [-0.1125, 2.725] },
          },
          {
            shape: 'marker',
            visible: { sourceField: 'wrongWayAlert' },
            icon: {
              iconName: 'wrong_way_alert_selected_background',
              scale: 1,
              zIndex: -1,
              anchor: [0, 1.85],
            },
          },
        ],
      },

      unconfirmed: {
        default: [
          { shape: 'marker', icon: { iconName: 'wrong_way.unconfirmed' } },
          {
            shape: 'marker',
            zIndex: 2,
            visible: { sourceField: 'wrongWayAlert' },
            icon: { iconName: { sourceField: 'wrongWayAlertTranslatedIcon' }, scale: 1, anchor: [-0.1125, 2.65] },
          },
        ],
        hover: [
          { shape: 'marker', icon: { iconName: 'wrong_way.unconfirmed_hover' } },
          {
            shape: 'marker',
            zIndex: 2,
            visible: { sourceField: 'wrongWayAlert' },
            icon: { iconName: { sourceField: 'wrongWayAlertTranslatedIcon' }, scale: 1, anchor: [-0.1125, 2.725] },
          },
          {
            shape: 'marker',
            visible: { sourceField: 'wrongWayAlert' },
            icon: {
              iconName: 'wrong_way_alert_unconfirmed_hover_background',
              scale: 1,
              zIndex: -1,
              anchor: [0, 1.83],
            },
          },
        ],
        selected: [
          { shape: 'marker', icon: { iconName: 'wrong_way.unconfirmed_selected' } },
          {
            shape: 'marker',
            zIndex: 2,
            visible: { sourceField: 'wrongWayAlert' },
            icon: { iconName: { sourceField: 'wrongWayAlertTranslatedIcon' }, scale: 1, anchor: [-0.1125, 2.725] },
          },
          {
            shape: 'marker',
            visible: { sourceField: 'wrongWayAlert' },
            icon: {
              iconName: 'wrong_way_alert_unconfirmed_selected_background',
              scale: 1,
              zIndex: -1,
              anchor: [0, 1.83],
            },
          },
        ],
      },
      completed: { default: [{ shape: 'marker', icon: { iconName: 'wrong_way.completed' } }] },
      rejected: { default: [{ shape: 'marker', icon: { iconName: 'wrong_way.rejected' } }] },
    },
  },
  other: {
    zIndex: LayersZIndexConfig.other,
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'other' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'other.hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'other.selected' } }],
      },
      unconfirmed: {
        default: [{ shape: 'marker', icon: { iconName: 'other.unconfirmed' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'other.unconfirmed_hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'other.unconfirmed_selected' } }],
      },
      completed: { default: [{ shape: 'marker', icon: { iconName: 'other.completed' } }] },
      rejected: { default: [{ shape: 'marker', icon: { iconName: 'other.rejected' } }] },
    },
  },
  unidentified: {
    zIndex: LayersZIndexConfig.unidentified,
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'unknown' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'unknown.hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'unknown.selected' } }],
      },
      unconfirmed: {
        default: [{ shape: 'marker', icon: { iconName: 'unknown.unconfirmed' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'unknown.unconfirmed_hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'unknown.unconfirmed_selected' } }],
      },
      completed: {
        default: [{ shape: 'marker', icon: { iconName: 'unknown.completed' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'unknown.hover' } }],
      },
      rejected: {
        default: [{ shape: 'marker', icon: { iconName: 'unknown.rejected' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'unknown.hover' } }],
      },
    },
  },
  traffic_anomaly: {
    zIndex: LayersZIndexConfig.unidentified,
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'unknown' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'unknown.hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'unknown.selected' } }],
      },
      unconfirmed: {
        default: [{ shape: 'marker', icon: { iconName: 'unknown.unconfirmed' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'unknown.unconfirmed_hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'unknown.unconfirmed_selected' } }],
      },
      completed: {
        default: [{ shape: 'marker', icon: { iconName: 'unknown.completed' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'unknown.hover' } }],
      },
      rejected: {
        default: [{ shape: 'marker', icon: { iconName: 'unknown.rejected' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'unknown.hover' } }],
      },
    },
  },
};

export const incidentsLayersConfig = Object.keys(_incidentsLayersConfig).reduce((acc, key) => {
  acc[`${LayerType.Incident}-${key}`] = _incidentsLayersConfig[key];
  return acc;
}, {});
