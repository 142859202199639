import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DistanceFormatPipeModule, WcPopupCloserModule } from '@wc/wc-common/src';
import { DateFormatPipeModule } from '@wc/wc-common/src/lib/pipes/format-pipes/date-format.module';
import { TimeFormatPipeModule } from '@wc/wc-common/src/lib/pipes/format-pipes/time-format.module';
import { WcIconModule } from '../../wc-icon/wc-icon.module';
import { CrashRiskModalComponent } from './crash-risk-modal.component';

@NgModule({
  declarations: [CrashRiskModalComponent],
  imports: [
    CommonModule,
    TranslateModule,
    WcIconModule,
    WcPopupCloserModule,
    TimeFormatPipeModule,
    DateFormatPipeModule,
    DistanceFormatPipeModule,
  ],
  exports: [CrashRiskModalComponent],
})
export class CrashRiskModalComponentModule {}
