<mat-selection-list
  wcFocusStyles
  [class.tablet-mode]="isTabletMode"
  class="checkbox-list-component"
  [class.checkbox-list-component__display-as-grid]="enableShowMoreButton"
  (selectionChange)="selectionChanged($event.source._value)">
  <div
    class="checkbox-list-component__container"
    [class.checkbox-list-component__container]="highlightRowStyle"
    [class.checkbox-list-component__container--padding]="isPadding"
    [class.checkbox-list-component__container--checked]="selectedArrowIndex === i"
    *ngFor="let option of formFieldData?.options; let i = index">
    <ng-container *ngIf="!enableShowMoreButton || !(!showAllOptions && i > maxShowMoreLength)">
      <mat-list-option
        tabindex="-1"
        [disabled]="ngControl.control?.disabled"
        checkboxPosition="before"
        [value]="option.value"
        [selected]="isSelected(option)">
        <span> {{ option.displayName || '' | translate }}</span>
      </mat-list-option>
      <i
        *ngIf="option.data?.isExpanded"
        class="fas fa-solid fa-chevron-right"
        [class.expanded]="selectedArrowIndex === i"
        (click)="arrowClicked.emit(option.value); selectedArrowIndex = i; $event.stopPropagation()">
      </i>
    </ng-container>
  </div>
</mat-selection-list>
<ng-container *ngIf="enableShowMoreButton && formFieldData.options && formFieldData.options.length > maxShowMoreLength">
  <button
    rekor-basic
    [rightSideIcon]="showAllOptions ? 'angle-up' : 'angle-right'"
    (click)="showAllOptions = !showAllOptions">
    {{ (showAllOptions ? 'showLess' : 'showMore') | translate }}
  </button>
</ng-container>
