import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CssIconsClassType, TrafficDisruptionType, layerTypeToTranslationPath } from '../../wc-models/src';
import {
  DeleteTrafficDisruptionMediaGQL,
  DeleteTrafficDisruptionMediaInput,
  RenewTrafficDisruptionMediaUrlGQL,
  SpecialEvent,
  TrafficDisruption,
  UpdateTrafficDisruptionLanesGQL,
  UpdateTrafficDisruptionLanesInput,
} from '../models/gql.models';
import { CustomRxOperatorsService } from './custom-rx-operators.service';

@Injectable({
  providedIn: 'root',
})
export class TrafficDisruptionService {
  constructor(
    private customOperators: CustomRxOperatorsService,
    private UpdateTrafficDisruptionLanesGQL: UpdateTrafficDisruptionLanesGQL,
    private renewTrafficDisruptionMediaUrlGQL: RenewTrafficDisruptionMediaUrlGQL,
    private deleteTrafficDisruptionMediaGQL: DeleteTrafficDisruptionMediaGQL
  ) {}

  updateTrafficDisruptionLanes(input: UpdateTrafficDisruptionLanesInput) {
    return this.UpdateTrafficDisruptionLanesGQL.mutate({ input }).pipe(
      map(res => res?.data?.updateTrafficDisruptionLanes),
      catchError(err =>
        err.graphQLErrors && err.graphQLErrors[0].extensions
          ? throwError({
              errorCode: err.graphQLErrors[0].extensions.statusCode,
            })
          : throwError(err)
      )
    );
  }

  getCssIconClass(trafficDisruptionType: TrafficDisruptionType): CssIconsClassType | '' {
    switch (trafficDisruptionType) {
      case TrafficDisruptionType.Construction:
        return 'list-icon-construction';
      case TrafficDisruptionType.SpecialEvent:
        return 'list-icon-special_event';
      case TrafficDisruptionType.RoadClosure:
        return 'list-icon-road_closure';

      default:
        return '';
    }
  }

  getTrafficDisruptionTranslationPath<T extends TrafficDisruptionType>(
    trafficDisruption: TrafficDisruption,
    trafficDisruptionType: T
  ): typeof layerTypeToTranslationPath[T] | '' {
    return !layerTypeToTranslationPath[trafficDisruptionType]
      ? ''
      : layerTypeToTranslationPath[trafficDisruptionType] === layerTypeToTranslationPath.SPECIAL_EVENT
      ? layerTypeToTranslationPath[trafficDisruptionType] + `.${(trafficDisruption as SpecialEvent).type}`
      : layerTypeToTranslationPath[trafficDisruptionType];
  }

  getLoaderNames() {
    return {
      [TrafficDisruptionType.RoadClosure]: 'roadClouser',
      [TrafficDisruptionType.Construction]: 'construction',
      [TrafficDisruptionType.SpecialEvent]: 'specialEvent',
    };
  }

  deleteMedia(input: DeleteTrafficDisruptionMediaInput) {
    return this.deleteTrafficDisruptionMediaGQL.mutate({ input }).pipe(this.customOperators.catchGqlErrors());
  }

  renewTrafficDisruptionMediaUrl(trafficDisruptionMediaId: number) {
    return this.renewTrafficDisruptionMediaUrlGQL
      .mutate({ id: trafficDisruptionMediaId })
      .pipe(this.customOperators.catchGqlErrors());
  }
}
