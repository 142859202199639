<div [ngClass]="appType" class="multiple-chips-component">
  <mat-chip-list *ngIf="!viewMode && !isEditableList" #chipList [selectable]="true" [multiple]="true">
    <ng-container *ngFor="let option of visibleOptions">
      <mat-chip
        data-cy-id="text-chips"
        (click)="chipSelected(option)"
        [value]="option.value"
        [selected]="option.selected">
        {{ option.displayName || '' | translate }}
      </mat-chip>
    </ng-container>

    <ng-container *ngIf="hiddenOptions.length > 0">
      <hr />
      <div *ngIf="!showHiddenOptions" (click)="showHiddenOptions = true" class="multiple-chips-component__load-more">
        {{ 'loadMore' | translate }}
      </div>
    </ng-container>
    <ng-container *ngIf="showHiddenOptions">
      <ng-container *ngFor="let option of hiddenOptions">
        <mat-chip (click)="chipSelected(option)" [value]="option.value" [selected]="option.selected">
          {{ option.displayName || '' | translate }}
        </mat-chip>
      </ng-container>
    </ng-container>
  </mat-chip-list>

  <mat-chip-list [selectable]="false" *ngIf="viewMode && !isEditableList" #chipList>
    <ng-container *ngFor="let value of viewChipListAsStrings">
      <mat-chip class="view-chip-mode" [value]="value">
        {{ value || '' | translate }}
      </mat-chip>
    </ng-container>
  </mat-chip-list>

  <ng-container *ngIf="isEditableList">
    <ng-container *ngIf="viewMode; else editMode">
      <mat-form-field class="disable-mode" appearance="outline">
        <mat-chip-list [disabled]="true" #chipList>
          <mat-chip
            *ngFor="let chip of this._formFieldData?.options"
            [class]="'editable-list-chip'"
            [selectable]="false"
            [removable]="false">
            {{ chip.displayName }}
          </mat-chip>
        </mat-chip-list>
      </mat-form-field>
    </ng-container>

    <ng-template #editMode>
      <div class="multiple-chips-component__editable-list-label">
        {{ this._formFieldData.label || '' | translate }} ({{ 'upTo' | translate: { length: maxChipsAllowed } }})
      </div>

      <mat-form-field appearance="outline">
        <mat-chip-list [placeholder]="_formFieldData.placeholder || ''" #chipList>
          <mat-chip
            *ngFor="let chip of this._formFieldData?.options"
            [class]="'editable-list-chip'"
            [selectable]="false"
            [removable]="true"
            (removed)="removeChip(chip)">
            {{ chip.displayName }}

            <wc-icon matChipRemove iconName="close-new"></wc-icon>
          </mat-chip>
          <input
            *ngIf="!isBlockingAddChips"
            autocomplete="off"
            [maxLength]="maxChipTextLength"
            [placeholder]="_formFieldData.placeholder || '' | translate"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addChip($event)" />
          <div class="multiple-chips-component__chip-count">
            {{ this._formFieldData.options?.length }}/{{ maxChipsAllowed }}
          </div>
        </mat-chip-list>
      </mat-form-field>
      <div class="multiple-chips-component__editable-list-help-text">
        {{ 'chipLIstHelpText' | translate }} <wc-icon iconName="level-down"></wc-icon>
        {{ 'enterKey' | translate }}
      </div>
    </ng-template>
  </ng-container>
</div>
