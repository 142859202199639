import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import {
  AppSounds,
  AuthService,
  AuthUserService,
  BrowserSoundSettingsLink,
  ElectronService,
  LocalStorageService,
  ReleaseNotesService,
  SoundsService,
} from '@wc-core';
import { AppFeatureEnum, environment } from '@wc/core';
import { ConfirmationModalType, SoundAlertMode } from '@wc/core/models/enums';
import { PermissionsFacadeService } from '@wc/permissions/domain/src';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { ScopeAccessModifier } from '@wc/wc-models/src';
import { ConfirmModalService } from '@wc/wc-ui';
import { OnboardingToursComponent } from '@wc/wc-ui/src/components/onboarding-tours/onboarding-tours.component';
import { FormFieldData, FormFieldOption } from '@wc/wc-ui/src/lib/base';
import { map, take } from 'rxjs/operators';
import { DesktopUiStateService } from '../desktop-ui-state.service';
import { ReleaseNotesViewComponent } from '../release-notes-view/release-notes-view.component';

@Component({
  selector: 'wc-settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsMenuComponent implements OnDestroy {
  @ViewChild('onboardingTours', { read: OnboardingToursComponent }) onboardingTours!: OnboardingToursComponent;
  @ViewChild('matToggle', { read: MatSlideToggle }) matToggle!: MatSlideToggle;
  @ViewChild('releaseView', { read: ReleaseNotesViewComponent }) releaseView!: ReleaseNotesViewComponent;
  @ViewChild('menuTrigger', { read: MatMenuTrigger }) menuTrigger!: MatMenuTrigger;
  @Input() showOnBoarding = false;
  @Output() closeMenu = new EventEmitter();
  @Output() openMenu = new EventEmitter();
  isMenuOpen = false;
  soundsFormGroup = new FormGroup({
    state: new FormControl(this.translateService.instant('soundsToggleButtonGroup.off')),
  });
  toggleAudioFormFieldData: FormFieldData = {
    options: [],
  };
  isElectronApp = false;
  toggleOption: Record<string, string> = {};
  toggleOptions: FormFieldOption<string>[] = [];
  wrongWayAlertSounds = false;
  isAlive = true;
  showSupportModal = false;

  readonly appFeatureEnum = AppFeatureEnum;
  readonly alertSound = new FormControl();
  readonly portraitMode = new FormControl();

  get authUserName() {
    return this.authUserService.authUser$.pipe(map(user => user?.name));
  }

  get isDirectionRtl() {
    return this.desktopUiState.isDirectionRtl;
  }

  get releaseNotes() {
    return this.releaseNoteService.releaseNotes$;
  }

  constructor(
    private authUserService: AuthUserService,
    private permissionsFacadeService: PermissionsFacadeService,
    public authService: AuthService,
    private soundsService: SoundsService,
    private localStorageService: LocalStorageService,
    private confirmationService: ConfirmModalService,
    private translateService: TranslateService,
    private electronService: ElectronService,
    private desktopUiState: DesktopUiStateService,
    private releaseNoteService: ReleaseNotesService
  ) {
    this.isElectronApp = this.electronService.isElectronApp;
    this.wrongWayAlertSounds = this.permissionsFacadeService.hasPermission('WRONG_WAY_ALERT:READ');

    // audio toggle buttons
    const parentKey = 'soundsToggleButtonGroup';
    this.translateService
      .get(['all', 'wrongWayOnly', 'off'].map(subKey => `${parentKey}.${subKey}`))
      .subscribe(translations => {
        this.toggleOption = {
          All: translations[`${parentKey}.all`],
          WrongWayOnly: translations[`${parentKey}.wrongWayOnly`],
          Off: translations[`${parentKey}.off`],
        } as const;

        this.toggleOptions = Object.values(this.toggleOption).map(optionValue => ({
          value: optionValue,
          displayName: optionValue,
        }));

        this.initializeSoundAlerts();
      });

    this.soundsService.isSoundNotAllowed$.subscribe(() => {
      if (!this.wrongWayAlertSounds) {
        this.soundsService.soundOptions[AppSounds.incidentAlert].isActive = true;
        this.alertSound.setValue(this.soundsService.soundOptions[AppSounds.incidentAlert].isActive, {
          emitEvent: false,
        });
        this.showPopup();
      } else {
        const formValue = this.soundsFormGroup.get('state');
        if (formValue && formValue.value !== this.toggleOption['Off']) {
          this.showPopup();
        }
      }
    });
  }

  initializeSoundAlerts() {
    if (Object.keys(this.toggleOption).length) {
      this.toggleAudioFormFieldData = {
        options: this.toggleOptions,
      };
    }

    if (!this.wrongWayAlertSounds) {
      this.alertSound.valueChanges.subscribe(v => {
        this.soundsService.soundOptions[AppSounds.incidentAlert].isActive = v;
        if (this.localStorageService.get(LocalStorageKeys.AllowAlert) === null && v === true) {
          this.showPopup();
        }

        this.localStorageService.set(
          LocalStorageKeys.AllowAlert,
          this.soundsService.soundOptions[AppSounds.incidentAlert].isActive
        );
      });
      const localStorageState = this.localStorageService.get(LocalStorageKeys.AllowAlert);
      this.setInitialSingleSoundToggleState(!!localStorageState);
      if (localStorageState === true) {
        this.soundsService.checkIfSoundAllowedInSettings();
      }
    } else {
      const optionValues = this.toggleOptions.map(option => option.value);
      const localStorageState = this.localStorageService.get(LocalStorageKeys.AllowSoundAlerts);
      const selectedValue =
        ![undefined, null].includes(localStorageState) && Number(localStorageState) >= 0
          ? optionValues[Number(localStorageState)]
          : optionValues[SoundAlertMode.Off];

      this.setMultipleSoundsState(selectedValue, optionValues);
      this.soundsFormGroup.get('state')?.setValue(selectedValue, {
        emitEvent: false,
      });
      if (localStorageState !== optionValues[SoundAlertMode.Off]) {
        this.soundsService.checkIfSoundAllowedInSettings();
      }

      this.soundsFormGroup.get('state')?.valueChanges.subscribe(val => {
        const index = optionValues.indexOf(val);
        this.setMultipleSoundsState(val, optionValues);
        this.shouldShowPopup();
        this.localStorageService.set(LocalStorageKeys.AllowSoundAlerts, index);
      });
    }

    this.desktopUiState.isPortraitDesktopMode.query$.pipe(take(1)).subscribe(v => {
      this.portraitMode.setValue(v);
    });
    this.portraitMode.valueChanges.pipe(take(1)).subscribe(v => {
      this.localStorageService.set(LocalStorageKeys.IsPortraitDesktopMode, v);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    });
  }

  togglePortraitMode(event?) {
    this.matToggle.toggle();
    event?.stopPropagation();
    this.portraitMode.setValue(this.matToggle.checked);
  }

  showPopup() {
    this.confirmationService.showConfirmDialog(ConfirmationModalType.SoundAlertPopup, BrowserSoundSettingsLink.Chrome);
  }

  setMultipleSoundsState(selectedValue: string, optionValues: string[]) {
    if (selectedValue === optionValues[SoundAlertMode.All]) {
      this.soundsService.soundOptions[AppSounds.incidentAlert].isActive = true;
      this.soundsService.soundOptions[AppSounds.wrongWayAlert].isActive = true;
    } else if (selectedValue === optionValues[SoundAlertMode.WrongWayOnly]) {
      this.soundsService.soundOptions[AppSounds.wrongWayAlert].isActive = true;
      this.soundsService.soundOptions[AppSounds.incidentAlert].isActive = false;
    } else {
      this.soundsService.soundOptions[AppSounds.incidentAlert].isActive = false;
      this.soundsService.soundOptions[AppSounds.wrongWayAlert].isActive = false;
    }
  }

  setInitialSingleSoundToggleState(state: boolean) {
    this.soundsService.soundOptions[AppSounds.incidentAlert].isActive = !!state;
    this.alertSound.setValue(this.soundsService.soundOptions[AppSounds.incidentAlert].isActive, {
      emitEvent: false,
    });
  }

  toggleAlertSounds(event) {
    event?.stopPropagation();
    event?.preventDefault();
    this.alertSound.setValue(!this.soundsService.soundOptions[AppSounds.incidentAlert].isActive);
  }

  downloadWidget() {
    const fileType = navigator.platform.toLowerCase().includes('mac') ? 'dmg' : 'exe';
    const link = document.createElement('a');
    link.href = `${environment.downloadsDomain}/rekor-command.${fileType}`;
    link.download = `rekor-command.${fileType}`;
    link.click();
  }

  hasPermission(permission: ScopeAccessModifier) {
    return this.permissionsFacadeService.hasPermission(permission);
  }

  logout() {
    this.authService.logout();
  }

  openSupportModal(e) {
    e.stopPropagation();
    this.menuTrigger.closeMenu();
    this.closeMenu.emit();
    this.showSupportModal = true;
  }

  openReleaseNotes(e) {
    e.stopPropagation();
    this.releaseView.menuTrigger.openMenu();
  }

  openOnboarding(e) {
    e.stopPropagation();
    this.onboardingTours.menuTrigger.openMenu();
  }

  onboardingStarted() {
    this.menuTrigger.closeMenu();
    this.closeMenu.emit();
  }

  private shouldShowPopup() {
    if (!this.localStorageService.get(LocalStorageKeys.AllowSound)) {
      this.showPopup();
      this.localStorageService.set(LocalStorageKeys.AllowSound, true);
    }
  }

  closeSupportModal() {
    this.showSupportModal = false;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
