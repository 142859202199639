import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { TimeFormat } from '../../../../../core/models/gql.models';

@Pipe({
  name: 'timeFormatMode',
})
export class TimeFormatPipe implements PipeTransform {
  transform(time, timeFormat: TimeFormat): string | void {
    if (!time) {
      return;
    }

    if (timeFormat === TimeFormat.TwelveHours) {
      return moment(time).format('HH:mm A');
    } else {
      return moment(time).format('HH:mm');
    }
  }
}
