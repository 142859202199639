import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { MultiCheckboxComponent } from './multi-checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

const Materials: any[] = [MatCheckboxModule];

@NgModule({
  declarations: [MultiCheckboxComponent],
  imports: [CommonModule, WcBaseControlFieldModule, Materials],
  providers: [],
  exports: [MultiCheckboxComponent],
})
export class MultiCheckboxComponentModule {}
