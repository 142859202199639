import { Component } from '@angular/core';
import { IconClassName } from '../../../../wc-models/src/lib/types/icon-class-names-type';

@Component({
  selector: 'wc-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  maxWidth!: number;
  tooltipText? = '';
  tooltipTitle? = '';
  tooltipsIcons: IconClassName[] = [];
}
