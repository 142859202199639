<form class="shift-form" [formGroup]="shiftForm" [class.tablet-mode]="true">
  <div class="shift-modal-row">
    <ng-container
      *ngIf="unitRelationType === unitRelationTypeEnum.ChangingUnit && shiftFieldsData.unitId.options?.length">
      <wc-select-control
        data-cy-id="unit-id"
        formControlName="unitId"
        [formFieldData]="shiftFieldsData.unitId"
        [isTabletMode]="uiStore.isTabletMode"
        (select)="unitSelected($event)"></wc-select-control>
      <wc-number-field-control
        *ngIf="shiftFieldsData.shiftTypeId.options?.length"
        [formControl]="$any(shiftForm.get('startMileage'))"
        [fieldData]="shiftFieldsData.startMileage">
      </wc-number-field-control>
    </ng-container>

    <ng-container *ngIf="unitRelationType === unitRelationTypeEnum.ConstantUnit">
      <div class="one-available-unit">
        {{ shiftFieldsData.unitId?.label | translate }} #{{ usersStore.authUser?.unit?.displayId }}
      </div>
      <wc-number-field-control
        data-cy-id="start-mileage"
        *ngIf="shiftFieldsData.shiftTypeId.options.length"
        [formControl]="$any(shiftForm.get('startMileage'))"
        [fieldData]="shiftFieldsData.startMileage">
      </wc-number-field-control>
    </ng-container>
  </div>

  <div class="shift-modal-row">
    <wc-select-control
      data-cy-id="shift-name"
      formControlName="shiftTypeId"
      [isTabletMode]="uiStore.isTabletMode"
      [formFieldData]="shiftFieldsData.shiftTypeId"
      *ngIf="shiftFieldsData.shiftTypeId.options && shiftFieldsData.shiftTypeId.options.length">
    </wc-select-control>
    <wc-multi-select-control
      data-cy-id="route-names"
      [formControl]="$any(shiftForm.get('routeTypesIds'))"
      [fieldData]="shiftFieldsData.routeTypesIds"
      *ngIf="shiftFieldsData.routeTypesIds.options && shiftFieldsData.routeTypesIds.options?.length">
    </wc-multi-select-control>
  </div>

  <div class="buttons-container">
    <button [disabled]="this.disableCreate" class="primary-button" (click)="submitStartShiftForm()">
      {{ 'start' | translate }}
    </button>
  </div>
</form>
