<div class="cctv-snapshot-component">
  <ng-container *ngIf="cctvSnapshotList.length === 0">
    <ng-template [ngTemplateOutlet]="emptyMedia"></ng-template>
  </ng-container>

  <!-- View mode  -->
  <div class="cctv-snapshot-component__groups-view" *ngIf="!isEditMode">
    <div *ngFor="let list of allSnapshotsList | keyvalue">
      <div class="cctv-snapshot-component__media-group">
        <div
          [attr.data-cy-id]="'cctv-snapshot-thumbnail-' + list.key"
          class="cctv-snapshot-component__snapshot-group"
          (click)="openMediaModal(list.value[0].id)">
          <wc-media-thumbnail
            [mediaObj]="list.value[0]"
            [renewMediaUrlFunc]="renewMediaUrl"
            (mediaDeleted)="mediaDeleted(list.value[0])">
          </wc-media-thumbnail>
          <span class="cctv-snapshot-component__snapshot-group__second-image-border"></span>
          <span class="cctv-snapshot-component__snapshot-group__third-image-border"></span>
        </div>

        <h2 data-cy-id="snapshot-thumbnails-type-title" class="cctv-snapshot-component__snapshot-group__title">
          {{ 'snapshotsType.' + list.key | translate }} ({{ list.value.length }})
        </h2>
      </div>
    </div>
  </div>

  <!-- Edit mode  -->
  <ng-container *ngIf="isEditMode">
    <div class="cctv-snapshot-component__snapshot-group-edit" *ngFor="let list of allSnapshotsList | keyvalue">
      <h2 data-cy-id="snapshot-thumbnails-type-title" class="cctv-snapshot-component__snapshot-group-edit__title">
        {{ 'snapshotsType.' + list.key | translate }} ({{ list.value.length }})
      </h2>
      <div class="cctv-snapshot-component__snapshot-list">
        <div
          *ngFor="let snapshot of list.value | slice: 0:viewItemLimit; let i = index"
          class="cctv-snapshot-component__snapshot-list__item"
          (click)="openMediaModal(snapshot.id)">
          <wc-media-thumbnail
            [isEditMode]="true"
            [mediaObj]="snapshot"
            [renewMediaUrlFunc]="renewMediaUrl"
            (mediaDeleted)="mediaDeleted(snapshot)">
          </wc-media-thumbnail>
        </div>
      </div>
    </div>

    <div
      data-cy-id="show-all-button"
      class="cctv-snapshot-component__show-all"
      *ngIf="cctvSnapshotList.length > maxItemView"
      (click)="showAllClicked()">
      {{ 'showAll' | translate }}
      <i class="fas fa-solid fa-chevron-right"></i>
    </div>
  </ng-container>

  <ng-template #emptyMedia>
    <div class="empty-state-container">
      <i data-cy-id="cctv-snapshot-empty-state-icon" class="empty-state-icon-media"></i>
      <ng-container *ngIf="isSnapshotsFeatureIsOn; else comingSoon">
        <div data-cy-id="cctv-snapshot-empty-state-title" class="empty-state-message">
          {{ 'emptyStates.noSnapshotsTitle' | translate }}
        </div>
        <div data-cy-id="cctv-snapshot-empty-state-text" class="empty-state-action">
          {{ 'emptyStates.noSnapshotsText' | translate }}
        </div>
      </ng-container>

      <ng-template #comingSoon>
        <div class="empty-state-message">
          {{ 'comingSoon' | translate }}
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
