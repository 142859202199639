import { IncidentsListComponent } from '../../../apps/desktop/src/app/pages/live-map-viewer/panels/incidents/incidents-list/incidents-list.component';

export const SpecialKeys = {
  Tab: 'Tab',
} as const;

export const HTMLTags = {
  a: 'A',
} as const;

export const TabIndexValues = {
  navigatable: '0',
} as const;

export type TabKeyEventContext = ReturnType<IncidentsListComponent['getTabKeyEventContext']>;

export const ACTIVE_MAT_TAB_LABEL_SELECTOR = '.mat-tab-label.mat-tab-label-active';

export const FOCUSABLE_SELECTORS = {
  button: 'button',
  a: 'a',
  matSelect: 'mat-select',
  activeMatTabLabel: '.mat-tab-label.mat-tab-label-active',
} as const;

export type FocusableElementsType = keyof typeof FOCUSABLE_SELECTORS;

export const focusableElementsQuerySelector = Object.values(FOCUSABLE_SELECTORS).join(', ');
