import { InjectionToken } from '@angular/core';
import { ExpandedSections } from '@wc/core';
import { AppTypeUnion } from '@wc/wc-models';
import * as moment from 'moment';
import { LocalStorageKeys } from './services/local-storage.service';
import { OnboardingTourName, PreTourFunctionType, Tour, TourName } from './types/tour';

const PLATFORM_INIT_TOUR_FUNCTIONS: Partial<{ [key in TourName | OnboardingTourName]: PreTourFunctionType }> = {
  [TourName.IncidentNewFields]: async ({ localStorageService }) => {
    const currentSections = localStorageService.get(LocalStorageKeys.ExpandedSectionsStorage) as ExpandedSections;
    const elementsToCheck = ['casualties', 'involvedVehicles', 'severity', 'attributes'];

    for (const section of elementsToCheck) {
      if (!currentSections.incident[section]) {
        const element = document.getElementById(`incident-form-${section}-expansion-title`);
        if (!element) {
          console.error(
            `${TourName.IncidentNewFields} pre function tour: element missing incident-form-${section}-expansion-title`
          );
          return false;
        }
        element.click();
      }
    }
    return true;
  },
  [OnboardingTourName.SettingsTour]: async ({ router }) => {
    console.log('onboarding tour pre-init');
    await router.navigate(['/settings/account/general']);
    return true;
  },

  [OnboardingTourName.LiveMapTour]: async ({ router }) => {
    console.log('onboarding tour pre-init');
    await router.navigate(['/live-map']);
    return true;
  },
  [OnboardingTourName.LayersPanelTour]: async ({ router }) => {
    await router.navigate(['/live-map']);

    const panelOpenElementValidator = document.getElementById('layer-type-INCIDENT-stalled_vehicle');
    if (!panelOpenElementValidator) {
      console.error(
        `${OnboardingTourName.LayersPanelTour} pre function tour: element missing: livemap-layer-menu-enabled`
      );
    } else {
      const openPanelElement = document.getElementById('mat-button-toggle-5-button');
      openPanelElement?.click();
    }
    console.log('onboarding tour pre-init');
    await router.navigate(['/live-map']);
    return true;
  },
  [OnboardingTourName.TransitTour]: async ({ router }) => {
    await router.navigate(['/live-map']);

    await new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      });
    });

    const panelOpenElementValidator = document.querySelector('.transit-panel-tab-content');
    if (!panelOpenElementValidator) {
      const openPanelElement = document.querySelector('.transit button') as HTMLButtonElement;
      console.log(openPanelElement);
      console.log(document.querySelector('.transit button'));
      if (!openPanelElement) {
        console.error(`${OnboardingTourName.TransitTour} pre function tour: element missing: .transit button`);
        return false;
      }
      openPanelElement.click();

      console.log('onboarding tour pre-init');
    }

    return true;
  },
  [OnboardingTourName.DataHubTour]: async ({ router }) => {
    console.log('onboarding tour pre-init');

    await router.navigate(['analytics/incidents']);

    return true;
  },
  [TourName.PriorityInsight]: async () => {
    const element = document.getElementById('insightExpansionPanel');
    return await new Promise(resolve => {
      if (!element?.classList.contains('mat-expanded')) {
        (element?.firstChild as HTMLElement).click();
        resolve(true);
      } else {
        resolve(true);
      }
    });
  },
  [TourName.IncidentNeedActionPrioritySort]: async () => {
    return await new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, 3000);
    });
  },
};

export interface IXPlatWindow {
  navigator: any;
  location: any;
  localStorage: any;
  process?: any;
  require?: any;
  alert(msg: any): Promise<any>;
  confirm(msg: any): Promise<any>;
  setTimeout(handler: (...args: any[]) => void, timeout?: number): number;
  clearTimeout(timeoutId: number): void;
  setInterval(handler: (...args: any[]) => void, ms?: number, ...args: any[]): number;
  clearInterval(intervalId: number): void;

  // ...expand support for more window methods as you needed here...
}

export type XPlatWindow = Partial<Pick<Window, Exclude<keyof Window, keyof IXPlatWindow>>> & IXPlatWindow;

/**
 * Various InjectionTokens shared across all platforms
 * Always suffix with 'Token' for clarity and consistency
 */

export const PlatformLanguageToken = new InjectionToken<string>('PlatformLanguageToken');
export const PlatformWindowToken = new InjectionToken<IXPlatWindow>('PlatformWindowToken');
export const Auth0Token = new InjectionToken<string>('Auth0Token');
export const IsTabletMode = new InjectionToken<boolean>('IsTabletMode');

export const PLATFORM_TOURS_TOKEN = new InjectionToken<Record<TourName, Tour>>('PLATFORM_TOURS');

export const PLATFORM_TOURS: Partial<Record<TourName | OnboardingTourName, Tour>> = {
  [OnboardingTourName.SettingsTour]: {
    name: OnboardingTourName.SettingsTour,
    preTourInitFunction: PLATFORM_INIT_TOUR_FUNCTIONS[OnboardingTourName.SettingsTour],
    steps: [
      {
        id: 'settings-account-details',
        containerElement: '.main-container',
        attachTo: {
          element: '.settings-accountDetails',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'AccountDetails',
        textTranslationKey: 'AccountDetailsText',
      },
      {
        id: 'settings-roles',
        containerElement: '.main-container',
        attachTo: {
          element: '.settings-roles',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'Roles',
        textTranslationKey: 'RolesText',
        permission: 'ROLE:READ',
      },
      {
        id: 'settings-incidents',
        containerElement: '.main-container',
        attachTo: {
          element: '.incidents',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'Incidents',
        textTranslationKey: 'IncidentsText',
      },
    ],
    availableButtons: {
      next: true,
      back: true,
      cancel: true,
      final: true,
      nextTour: false,
    },
  },
  [OnboardingTourName.TransitTour]: {
    name: OnboardingTourName.TransitTour,
    preTourInitFunction: PLATFORM_INIT_TOUR_FUNCTIONS[OnboardingTourName.TransitTour],
    steps: [
      {
        id: 'transit-panel-button',
        containerElement: '#map-viewer-container',
        attachTo: {
          element: '.transit button',
          on: 'left',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'TransitPanel',
        textTranslationKey: 'TransitPanelText',
        clickElNext: '#affected-tour-toggle-button',
        stepDelay: 250,
      },
      {
        id: 'affected-services',
        containerElement: '#map-viewer-container',
        attachTo: {
          element: '.affected-service-mat-expansion',
          on: 'left',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'AffectedServices',
        textTranslationKey: 'AffectedServicesText',
        clickElNext: '#affected-tour-toggle-button',
        stepDelay: 250,
      },
      {
        id: 'route-details',
        containerElement: '#map-viewer-container',
        attachTo: {
          element: '.fixed-route-mat-expansion',
          on: 'left',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'RouteDetails',
        textTranslationKey: 'RouteDetailsText',
      },
    ],
    availableButtons: {
      next: true,
      back: true,
      cancel: true,
      final: false,
      nextTour: true,
    },
  },
  [OnboardingTourName.DataHubTour]: {
    name: OnboardingTourName.DataHubTour,
    steps: [
      {
        id: 'datahub-sections',
        containerElement: '.main-container',
        attachTo: {
          element: '.analytics-sidebar-nav',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'DataHubSections',
        textTranslationKey: 'DataHubSectionsText',
      },
      {
        id: 'datahub-filters',
        containerElement: '.main-container',
        attachTo: {
          element: '.filters-sidebar-nav',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'AdjustFilers',
        textTranslationKey: 'AdjustFilersText',
      },
      {
        id: 'datahub-column-sort',
        containerElement: '.main-container',
        attachTo: {
          element: '.datatable-header-cell',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'ColumnSorting',
        textTranslationKey: 'ColumnSortingText',
      },
      {
        id: 'datahub-export',
        containerElement: '.main-container',
        attachTo: {
          element: '.export-to-csv-button',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'ExportCsv',
        textTranslationKey: '',
      },
    ],
    availableButtons: {
      next: true,
      back: true,
      cancel: true,
      final: false,
      nextTour: true,
    },
    preTourInitFunction: PLATFORM_INIT_TOUR_FUNCTIONS[OnboardingTourName.DataHubTour],
  },
  [OnboardingTourName.LiveMapTour]: {
    name: OnboardingTourName.LiveMapTour,
    endDate: moment('2024-06-12T12:00:00Z').add(1, 'month').toISOString(),

    preTourInitFunction: PLATFORM_INIT_TOUR_FUNCTIONS[OnboardingTourName.LiveMapTour],
    steps: [
      {
        id: 'event-list',
        containerElement: '#map-viewer-container',
        attachTo: {
          element: '.incidents-list-component',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'EventsList',
        textTranslationKey: 'EventsListText',
      },
      {
        id: 'live-map',
        containerElement: '#map-viewer-container',
        attachTo: {
          element: '.map-sidenav-content',
          on: 'left',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'LiveMap',
        textTranslationKey: 'LiveMapText',
      },
      {
        id: 'additional-info-buttons',
        containerElement: '#map-viewer-container',
        attachTo: {
          element: '.map-modules-button-container',
          on: 'left',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'UncoverAdditionalFeatures',
        textTranslationKey: 'UncoverAdditionalFeaturesText',
      },
      {
        id: 'map-additions',
        containerElement: '#map-viewer-container',
        attachTo: {
          element: '.static-layers-buttons-container',
          on: 'left',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'MapAdditions',
        textTranslationKey: 'MapAdditionsText',
      },
      {
        id: 'settings-menu__button',
        containerElement: '#map-viewer-container',
        attachTo: {
          element: '.nav-menu-wrapper',
          on: 'left',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'LearnMore',
        textTranslationKey: 'LearnMoreText',
      },
    ],
    availableButtons: {
      next: true,
      back: true,
      cancel: true,
      final: false,
      nextTour: true,
    },
  },
  [OnboardingTourName.LayersPanelTour]: {
    name: OnboardingTourName.LayersPanelTour,
    preTourInitFunction: PLATFORM_INIT_TOUR_FUNCTIONS[OnboardingTourName.LayersPanelTour],
    steps: [
      {
        id: 'intro',
        containerElement: '#map-viewer-container',
        attachTo: {
          element: '.mat-button-toggle.basicLayers',
          on: 'left',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'ControlMapLayers',
        textTranslationKey: 'ControlMapLayersText',
        clickElNext: '.mat-button-toggle.basicLayers button',
      },
      {
        id: 'workspace-layers',
        containerElement: '#map-viewer-container',
        attachTo: {
          element: '.rf-map-layers-panel__header',
          on: 'left',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'ManageWorkspaces',
        textTranslationKey: 'ManageWorkspacesText',
        willBeVisible: true,
        clickElPrev: '.mat-button-toggle.basicLayers button',
      },
    ],
    availableButtons: {
      next: true,
      back: true,
      cancel: true,
      final: false,
      nextTour: true,
    },
  },
  [TourName.IncidentInsights]: {
    availableButtons: {
      next: true,
      back: true,
      cancel: true,
      final: true,
      nextTour: false,
    },
    name: TourName.IncidentInsights,
    endDate: moment('2023-06-12T12:00:00Z').add(1, 'month').toISOString(),
    steps: [
      {
        id: 'intro',
        containerElement: '.incidents-panel',
        attachTo: {
          element: '.insights-panel-component',
          on: 'right',
        },
        excludeByElements: ['.insights-panel-component__empty-state'],
        includeByElements: [],
        titleTranslationKey: 'welcome',
        textTranslationKey: 'welcomeText',
      },
      {
        id: 'persistence',
        attachTo: {
          element: '.persistence-section',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'simplifiedProcess',
        textTranslationKey: 'simplifiedProcessText',
      },
      {
        id: 'impact',
        attachTo: {
          element: '.impact-section',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'fasterVerification',
        textTranslationKey: 'fasterVerificationText',
      },
      {
        id: 'persistence-feedback',
        attachTo: {
          element: '.persistence-feedback',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'feedback',
        textTranslationKey: 'feedbackText',
      },
    ],
  },
  [TourName.IncidentResponsePlanView]: {
    availableButtons: {
      next: true,
      back: true,
      cancel: true,
      final: true,
      nextTour: false,
    },
    name: TourName.IncidentResponsePlanView,
    endDate: moment('2023-08-01T12:00:00Z').add(1, 'month').toISOString(),
    steps: [
      {
        id: 'intro',
        containerElement: '.view-incident',
        attachTo: {
          element: '.mat-tab-label:nth-child(2)',
          on: 'bottom-end',
        },
        excludeByElements: [],
        includeByElements: ['.rp-incident-view-container'],
        titleTranslationKey: 'thingsHaveMoved',
        textTranslationKey: 'associatedUnitsMovedToMoreDetailsTab',
        stepDelay: 250,
      },
    ],
  },
  [TourName.IncidentResponsePlan]: {
    availableButtons: {
      next: true,
      back: true,
      cancel: true,
      final: true,
      nextTour: false,
    },
    name: TourName.IncidentResponsePlan,
    endDate: moment('2023-08-01T12:00:00Z').add(1, 'month').toISOString(),
    steps: [
      {
        id: 'intro',
        containerElement: '.incident-component',
        attachTo: {
          element: '.mat-tab-label:has(.response-plan-tab-label)',
          on: 'bottom-end',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'createResponsePlans',
        textTranslationKey: 'responsePlanIncidentText',
        stepDelay: 250,
      },
    ],
  },

  [TourName.IncidentNewFields]: {
    availableButtons: {
      next: true,
      back: true,
      cancel: true,
      final: true,
      nextTour: false,
    },
    name: TourName.IncidentNewFields,
    endDate: moment('2023-10-01T12:00:00Z').toISOString(),
    preTourInitFunction: PLATFORM_INIT_TOUR_FUNCTIONS[TourName.IncidentNewFields],
    steps: [
      {
        id: 'incidentCategorization',
        containerElement: '.incident-component',
        attachTo: {
          element: '.incident-subtype-row',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'moreSpecificIncidentCategorization',
        textTranslationKey: 'moreSpecificIncidentCategorizationText',
        scrollTo: { behavior: 'smooth', block: 'center' },
      },
      {
        id: 'incidentSeverity',
        containerElement: '.incident-component',
        attachTo: {
          element: '.incident-severity',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'addIncidentSeverity',
        textTranslationKey: '',
        scrollTo: { behavior: 'smooth', block: 'center' },
      },
      {
        id: 'incidentAttributes',
        containerElement: '.incident-component',
        attachTo: {
          element: '.incident-attribute-title',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'addAdditionalDetails',
        textTranslationKey: 'addAdditionalDetailsText',
        scrollTo: { behavior: 'smooth', block: 'center' },
      },
      {
        id: 'incidentVehicles',
        containerElement: '.incident-component',
        attachTo: {
          element: '.incident-involved-vehicles-number',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'addNumberOfVehicles',
        textTranslationKey: '',
        scrollTo: { behavior: 'smooth', block: 'center' },
      },
      {
        id: 'incidentCasualties',
        containerElement: '.incident-component',
        attachTo: {
          element: '.incident-field__injury-title',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'recordNumberOfCasualties',
        textTranslationKey: '',
        scrollTo: { behavior: 'smooth', block: 'center' },
      },
    ],
  },
  [TourName.CompletedOnboardingTour]: {
    name: TourName.CompletedOnboardingTour,
    availableButtons: {
      next: false,
      back: false,
      cancel: false,
      final: true,
      nextTour: false,
    },
    steps: [
      {
        id: 'completedOnboardingTour',
        containerElement: '.header-container',
        attachTo: {
          element: '.onboarding-tours',
          on: 'left-start',
        },
        classes: 'narrow',
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'completedOnboarding',
        textTranslationKey: 'completedOnboardingText',
      },
    ],
  },
  [TourName.TrafficDisruptionNewScheduler]: {
    availableButtons: {
      next: true,
      back: true,
      cancel: true,
      final: true,
      nextTour: false,
    },
    name: TourName.IncidentNewFields,
    endDate: moment('2024-10-10T12:00:00Z').toISOString(),
    steps: [
      {
        id: 'incidentCategorization',
        containerElement: '.event-scheduler-component',
        attachTo: {
          element: '.event-scheduler-component__scheduler-type',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: [],
        titleTranslationKey: 'newUpdatedScheduler',
        textTranslationKey: 'newUpdatedSchedulerText',
        scrollTo: { behavior: 'smooth', block: 'center' },
      },
    ],
  },
  [TourName.IncidentNeedActionPrioritySort]: {
    availableButtons: {
      next: true,
      back: true,
      cancel: true,
      final: true,
      nextTour: false,
    },
    name: TourName.IncidentNeedActionPrioritySort,
    endDate: moment('2025-10-10T12:00:00Z').toISOString(),
    preTourInitFunction: PLATFORM_INIT_TOUR_FUNCTIONS[TourName.IncidentNeedActionPrioritySort],

    steps: [
      {
        id: 'showNewSort',
        containerElement: '.incidents-list-component',
        attachTo: {
          element: '.incident-list-item-container__review-badge--top',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: ['.incident-list-item-container__review-badge--top'],
        titleTranslationKey: 'prioritizeEventList',
        textTranslationKey: 'prioritizeEventListText',
        scrollTo: { behavior: 'smooth', block: 'center' },
      },
    ],
  },
  [TourName.PriorityInsight]: {
    availableButtons: {
      next: true,
      back: true,
      cancel: true,
      final: true,
      nextTour: false,
    },
    name: TourName.PriorityInsight,
    endDate: moment('2025-10-10T12:00:00Z').toISOString(),
    preTourInitFunction: PLATFORM_INIT_TOUR_FUNCTIONS[TourName.PriorityInsight],
    steps: [
      {
        id: 'priorityInsightTour',
        containerElement: '.insights-panel-component',
        attachTo: {
          element: '.insight-section__priority',
          on: 'right',
        },
        excludeByElements: [],
        includeByElements: ['.insights-panel-component__section-container'],
        titleTranslationKey: 'priorityInsight',
        textTranslationKey: 'priorityInsightText',
        scrollTo: { behavior: 'smooth', block: 'center' },
      },
    ],
  },
};

export const APP_TYPE_TOKEN = new InjectionToken<AppTypeUnion>('APP_MODE');
