/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from '@angular/core';
import { isArray } from 'lodash';
import { MapBrowserEvent } from 'ol';
import Feature from 'ol/Feature';
import * as olMap from 'ol/Map';
import { never } from 'ol/events/condition';
import { Geometry } from 'ol/geom';
import {
  DoubleClickZoom,
  DragPan,
  DragRotate,
  DragZoom,
  Interaction,
  KeyboardPan,
  KeyboardZoom,
  MouseWheelZoom,
  PinchRotate,
  PinchZoom,
  Select,
} from 'ol/interaction';
import PointerInteraction from 'ol/interaction/Pointer';
import { SelectEvent } from 'ol/interaction/Select';
import { Pixel } from 'ol/pixel';
import { toLonLat } from 'ol/proj';
import { Subject } from 'rxjs';
import { InteractionsEnum, InteractionsEvents } from '../../enums';
import { mapViewerConfigToken, wcMapToken } from '../../injection-tokens';
import { WcMapConfigModel, wcFeatureProperties, wcMapInteraction } from '../../interfaces';
import { FeatureEvent, MapBasicInteractionNames, wcCoordinateTypes, wcFeatureEventTarget } from '../../types';
import { Hover, HoverEvent } from '../classes/custom-interactions/hover';
import { RightClick, RightClickEvent } from '../classes/custom-interactions/right-click';
import { WcSelect } from '../classes/custom-interactions/wc-select';
import { WcFeature } from '../classes/wc-feature.class';
import { WcOverlay } from '../classes/wc-overlay.class';
import { DrawService } from './draw.service';
import { OverlayService } from './overlay.service';
import { StyleService } from './style.service';

@Injectable({
  providedIn: 'root',
})
export class InteractionsService {
  private readonly _interactionsMap = new Map<MapBasicInteractionNames, Interaction | PointerInteraction>();
  private readonly _interactionsOverlays = new Map<wcMapInteraction, WcOverlay>();

  constructor(
    @Inject(wcMapToken) private wcMap: olMap.default,
    private styleService: StyleService,
    private drawService: DrawService,
    @Inject(mapViewerConfigToken) private mapConfig: WcMapConfigModel<string, wcCoordinateTypes, string>,
    private overlayService: OverlayService
  ) {
    this.activateBasicInteractions();
  }
  private readonly eventsSubject = new Subject<FeatureEvent | RightClickEvent>();
  readonly eventSubscriber$ = this.eventsSubject.asObservable();

  getInteraction(interactionName: MapBasicInteractionNames): Interaction | PointerInteraction | undefined {
    return this._interactionsMap.get(interactionName);
  }

  removeAllInteractions(): void {
    this.wcMap.getInteractions().forEach(interaction => {
      interaction.setProperties({ active: false });
    });
    this.wcMap.getControls().forEach(mapControl => {
      mapControl.setProperties({ active: false });
    });
  }

  setInteractionState(id: MapBasicInteractionNames, state: boolean): void {
    const interaction = this._interactionsMap.get(id);
    if (!interaction) {
      console.error('could not find interaction with id:' + id);
      return;
    }
    interaction.setProperties({ active: state });
  }

  private mapBasicMapInteractions(interaction: Interaction): Interaction | PointerInteraction | undefined {
    let interactionId!: MapBasicInteractionNames;

    switch (interaction.constructor) {
      case DragRotate:
        interactionId = 'dragRotate';
        break;
      case DoubleClickZoom:
        interactionId = 'doubleClickZoom';
        break;
      case DragPan:
        interactionId = 'dragPan';
        break;
      case PinchRotate:
        interactionId = 'pinchRotate';
        break;
      case PinchZoom:
        interactionId = 'pinchZoom';
        break;
      case KeyboardPan:
        interactionId = 'keyboardPan';
        break;
      case KeyboardZoom:
        interactionId = 'keyboardZoom';
        break;
      case MouseWheelZoom:
        interactionId = 'mouseWheelZoom';
        break;
      case DragZoom:
        interactionId = 'dragZoom';
        break;

      default:
        console.error(`could not find ${interaction.constructor} proper type`);
        break;
    }
    if (interactionId) {
      this._interactionsMap.set(interactionId, interaction);
    }

    return this._interactionsMap.get(interactionId);
  }

  removeInteractions(interactions: InteractionsEnum[]): void {
    interactions.forEach(interactionName => {
      this._interactionsMap.get(interactionName)?.setProperties({ active: false });
    });
  }

  addInteractions(interactions: wcMapInteraction[]): void {
    interactions.forEach(interactionName => {
      let interaction = this._interactionsMap.get(interactionName);
      if (interaction) return;

      interaction = this[`${interactionName}Interaction`]?.();
      if (!interaction) return;

      this.wcMap.addInteraction(interaction);
      interaction.on(InteractionsEvents[interactionName], this[`${interactionName}Event`].bind(this) || {});
      this._interactionsMap.set(interactionName, interaction);
    });
  }

  addOverlayToInteraction(interactionName: wcMapInteraction, elm: HTMLElement): void {
    if (!elm) {
      console.error(`Tried to create overly for ${interactionName} interaction, with invalid element (${elm})`);
      return;
    }

    const overlay = this.overlayService.createMapOverlay({ element: elm });
    this._interactionsOverlays.set(interactionName, overlay);
    this.wcMap.addOverlay(overlay);
  }

  overlayHandler(interactionName: InteractionsEnum, feature: Feature | undefined, pixel?: Pixel): void {
    const overlay = this._interactionsOverlays.get(interactionName) as WcOverlay;
    let coordinates = undefined;
    if (!overlay) return;
    if (feature) {
      const wcFeature = feature instanceof WcFeature ? feature : (feature.get('features')?.[0] as WcFeature);
      const neededProp = this.mapConfig.excludedLayerNamesForPolygonOverlays?.includes(
        wcFeature.getWcFeatureProp('entityType')
      )
        ? 'firstCoordinateLonLat'
        : 'coordinates';
      coordinates = wcFeature.getWcFeatureProp('parentClusterCoords') || wcFeature.getWcFeatureProp(neededProp);
    }

    if (coordinates && typeof coordinates[0] !== 'number' && pixel) {
      // we are moving the overlay to be closer to the mouse pointer
      pixel[1] += 50;
      overlay.setPosition(this.wcMap.getCoordinateFromPixel(pixel));
    } else overlay.setPositionFromCoords(coordinates);
  }

  private activateBasicInteractions() {
    this.wcMap
      .getInteractions()
      .getArray()
      .forEach(interaction => {
        this.mapBasicMapInteractions(interaction);
      });
    this.listenToBasicInteractionsEvent();
  }

  private listenToBasicInteractionsEvent() {
    this.wcMap.on('moveend', this.moveEndEvent.bind(this));
    this.wcMap.on('movestart', this.moveStartEvent.bind(this));
    this.wcMap.on('pointerdrag', this.pointerDragEvent.bind(this));
    this.wcMap.getView().on('change:resolution', this.resolution.bind(this));
  }

  private selectInteraction(): Select {
    return new WcSelect({
      action: this.overlayHandler.bind(this, InteractionsEnum.select),
      toggleCondition: never,
      filter: feature => {
        const res =
          this.mapConfig.excludedLayerNamesPerInteraction?.select?.includes(feature?.get('parentLayerName')) ||
          this.drawService.featureBeingEdited ||
          this.drawService.isCreatingInProgress;
        return !res;
      },
      //Layer might be undefined (feature is not associated with layer) if it's being created.
      // So we check that first:
      //-------------------------------------------------------------

      style: this.styleService.styleFunction.bind({
        styleService: this.styleService,
        drawService: this.drawService,
        context: 'selected',
      }),
    });
  }

  getStyleFunctionWithCustomContext(context: string) {
    return this.styleService.styleFunction.bind({
      styleService: this.styleService,
      context,
    });
  }

  private hoverInteraction(): Hover<string> {
    return new Hover({
      action: this.overlayHandler.bind(this, InteractionsEnum.hover),
      selectInteraction: this.getInteraction('select') as Select,
      style: this.styleService.styleFunction.bind({
        styleService: this.styleService,
        context: 'hover',
      }),
      config: this.mapConfig,
      filter: feature => {
        const res =
          this.mapConfig.excludedLayerNamesPerInteraction?.hover?.includes(feature?.get('parentLayerName')) ||
          this.drawService.featureBeingEdited ||
          this.drawService.isCreatingInProgress;
        return !res;
      },
    });
  }

  private rightClickInteraction(): RightClick {
    return new RightClick();
  }

  selectEvent(event: SelectEvent): void {
    if (event.selected.length) {
      const selectedFeature = event.selected[0];
      const hitTolerance = this.mapConfig.layers?.[event.selected[0]?.getProperties()['entitySubType']]?.hitTolerance;

      if (hitTolerance) {
        const originalEntityType = selectedFeature.getProperties()['entityType'];
        event.selected = (
          this.wcMap.getFeaturesAtPixel(event.mapBrowserEvent.pixel, { hitTolerance }) as Feature[]
        ).filter(f => f.getProperties()['entityType'] === originalEntityType);
      }

      this.emitNewEvent(InteractionsEvents.select, event.selected, event.mapBrowserEvent);
    } else this.emitNewEvent(InteractionsEvents.unselect, event.deselected[0]);
  }

  resolution() {
    this.emitNewEvent(InteractionsEvents.resolution, undefined);
  }

  moveEndEvent() {
    this.emitNewEvent(InteractionsEvents.moveEnd, undefined);
  }

  moveStartEvent() {
    this.emitNewEvent(InteractionsEvents.moveStart, undefined);
  }

  pointerDragEvent() {
    this.emitNewEvent(InteractionsEvents.pointerDrag, undefined);
  }

  hoverEvent<T extends string>(event: HoverEvent<T>): void {
    this.emitNewEvent(event.type as InteractionsEvents, event.target?.hovered);
  }

  rightClickEvent(event: RightClickEvent): void {
    this.eventsSubject.next(event);
  }

  emitNewEvent<T extends string>(
    eventType: InteractionsEvents,
    f: WcFeature<T> | Feature<Geometry> | Feature<Geometry>[] | undefined,
    mapBrowserEvent?: MapBrowserEvent
  ): void {
    let targets: wcFeatureEventTarget[] | undefined;
    const features = !f || isArray(f) ? f : [f];

    if (features?.filter(f => !!f).length) {
      targets = features.map(f => {
        const fProps = f.getProperties();
        return {
          featureId: f.getId() as string | undefined,
          geometry: { type: f.get('geomType'), coordinates: f.get('coordinates') },
          fProps: Array.isArray(fProps.features)
            ? fProps.features.map<wcFeatureProperties>(f => f.getProperties())
            : (fProps as wcFeatureProperties),
        };
      });
    }

    this.eventsSubject.next({
      eventType,
      targets,
      additionalInfo: {
        clickedCoordinates: mapBrowserEvent?.coordinate ? toLonLat(mapBrowserEvent?.coordinate) : undefined,
      },
    });
  }
}
