import { InteractionsEvents } from '@wc/wc-map-viewer/src/enums';
import { wcCoordinate } from '@wc/wc-map-viewer/src/types';
import BaseEvent from 'ol/events/Event';
import PointerInteraction from 'ol/interaction/Pointer';
import { Pixel } from 'ol/pixel';
import { transform } from 'ol/proj';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

type RightClickTarget = { coords: wcCoordinate; pixel: Pixel };

export class RightClick extends PointerInteraction {
  private eventDispatcher = new Subject<RightClickEvent>();

  constructor() {
    super();
    this.eventDispatcher.pipe(debounceTime(150)).subscribe(rightClickEvent => this.dispatchEvent(rightClickEvent));

    setTimeout(() => {
      this.getMap().getViewport().addEventListener('contextmenu', this.rightClickHandler.bind(this));
    });
  }

  rightClickHandler(e) {
    e.preventDefault();
    e.stopPropagation();
    const map = this.getMap();
    const coords = map.getEventCoordinate(e);
    const pixel = map.getPixelFromCoordinate(coords);
    const transCoords = transform(coords, 'EPSG:3857', 'EPSG:4326');
    this.eventDispatcher.next(new RightClickEvent(InteractionsEvents.rightClick, { coords: transCoords, pixel }));
  }
}

export class RightClickEvent extends BaseEvent {
  target: any;
  constructor(type: string, target: RightClickTarget) {
    super(type);
    this.target = {
      rightClick: target,
    };
  }
}
