import { SelectOption } from '@wc/wc-models/src/lib/interfaces/general';

export const alphaNumericSort = function (arr: Array<any>, key: any, order: string = 'asc'): Array<any> {
  const lettersReg = /[^a-zA-Z\u0590-\u05fe]/g;
  const numbersReg = /[^0-9]/g;

  if (!arr) return [];
  arr = arr.sort((a, b) => {
    const _a = a[key]?.replace(lettersReg, '');
    const _b = b[key]?.replace(lettersReg, '');
    if (_a === _b) {
      const _numberA = parseInt(a[key]?.replace(numbersReg, ''), 10);
      const _numberB = parseInt(b[key]?.replace(numbersReg, ''), 10);
      return _numberA === _numberB ? 0 : _numberA > _numberB ? 1 : -1;
    } else {
      return _a > _b ? 1 : -1;
    }
  });
  return arr;
};

export const selectedOptionsAlphaNumericSort = function (
  arr: Array<SelectOption>,
  order: string = 'asc'
): Array<SelectOption> {
  return alphaNumericSort(arr, 'displayName', order);
};
