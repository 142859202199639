import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroupDirective, ValidatorFn, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { noWhitespaceValidator } from '@wc/features/ui/form-validators';

@Component({
  selector: 'wc-free-text-list-control',
  templateUrl: './free-text-list-control.component.html',
  styleUrls: ['./free-text-list-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreeTextListControlComponent implements AfterViewInit {
  @Input() textValueKeyName = '';
  @Input() subFormGroupName = '';
  @Input() maxLengthValidator?: number;

  constructor(
    private parentGroupDirective: FormGroupDirective,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.parentGroupDirective.form.get(this.subFormGroupName)?.valueChanges.subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  get group() {
    return this.parentGroupDirective.form;
  }

  get formArray() {
    return this.parentGroupDirective.form.get(this.subFormGroupName) as FormArray;
  }

  addControl() {
    const validatorOrOpts: ValidatorFn[] = [Validators.required, RxwebValidators.unique(), noWhitespaceValidator()];
    this.maxLengthValidator ? validatorOrOpts.push(Validators.maxLength(this.maxLengthValidator)) : null;
    this.formArray.push(
      this.fb.group({
        id: null,
        [this.textValueKeyName]: this.fb.control(null, validatorOrOpts),
      })
    );
    this.formArray.markAllAsTouched();
  }

  removeControl(index) {
    this.formArray.removeAt(index);
    this.parentGroupDirective.form.markAsTouched();
    this.formArray.markAllAsTouched();
    this.formArray.controls.forEach(c => {
      c.get(this.textValueKeyName)?.updateValueAndValidity();
    });
  }
}
