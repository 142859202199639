import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { HeapClassDirectiveModule } from '@wc/wc-common/src';
import { DistanceFormatPipe } from '@wc/wc-common/src/lib/pipes/format-pipes/distance-format.pipe';
import { MobxAngularModule } from 'mobx-angular';
import { WcAutocompleteV2Module } from '../../form-components/autocomplete-v2/autocomplete-v2.module';
import { WcSelectModule } from '../../form-components/wc-select/wc-select.module';
import { WcBaseControlFieldModule } from '../../lib/base/base-control-field.module';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { WcLoaderComponentModule } from '../miscellaneous/loader/loader.module';
import { WcNotificationModule } from '../notification/notification.module';
import { AddressUtilsService } from './address-utils.service';
import { AddressComponent } from './address.component';

@NgModule({
  declarations: [AddressComponent],
  providers: [DistanceFormatPipe, AddressUtilsService],
  imports: [
    CommonModule,
    MobxAngularModule,
    FlexLayoutModule,
    WcBaseControlFieldModule,
    WcSelectModule,
    WcNotificationModule,
    TranslateModule,
    WcAutocompleteV2Module,
    WcLoaderComponentModule,
    AutocompleteModule,
    HeapClassDirectiveModule,
    MatIconModule,
  ],
  exports: [AddressComponent],
})
export class AddressModule {}
