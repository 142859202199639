import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TimeFormatPipeModule } from '@wc/wc-common/src/lib/pipes/format-pipes/time-format.module';
import { TrainDelaysComponent } from './train-delays.component';

@NgModule({
  declarations: [TrainDelaysComponent],
  imports: [CommonModule, MatExpansionModule, TranslateModule, TimeFormatPipeModule, MatTooltipModule],
  exports: [TrainDelaysComponent],
})
export class TrainDelaysModule {}
