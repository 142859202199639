import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy } from '@angular/core';
import { DesktopUiStateService } from '../../shared/desktop-ui-state.service';

@Component({
  selector: 'wc-client-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullComponent implements OnDestroy {
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  get disablePageScroll$() {
    return this.desktopUiStateService.disablePageScroll$;
  }

  constructor(
    public el: ElementRef,
    cd: ChangeDetectorRef,
    media: MediaMatcher,
    private desktopUiStateService: DesktopUiStateService
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }
}
