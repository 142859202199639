<div class="select-boxes-component">
  <div [class.invalid-field]="ngControl.control?.invalid && ngControl.control?.touched">
    <div class="select-boxes-component__boxes-list">
      <div class="select-boxes-component__box-item" *ngFor="let option of formFieldData?.options; let i = index">
        <div
          [attr.data-cy-id]="'share-to-public-' + option.value | lowercase"
          [class.select-boxes-component__icon]="true"
          [class]="'heap_publish_channel_click_' + option.value | lowercase"
          [class.active]="isOptionActive(option)"
          [class.disabled]="option.disabled"
          wcTooltipPlacement="top"
          [wcTooltipDisabled]="!option.tooltipText"
          [wcTooltipText]="option.tooltipText || '' | translate"
          (click)="valueSelected(option)">
          <wc-icon *ngIf="option.value === 'GOV_DELIVERY'" [iconName]="'gov-delivery'"></wc-icon>
          <wc-icon *ngIf="option.value === 'TWITTER'" iconName="x-twitter"></wc-icon>
          <wc-icon *ngIf="option.value === 'WAZE'" iconName="waze"></wc-icon>
        </div>
        <span [class.disabled]="option.disabled" class="select-boxes-component__box-title">{{
          option.displayName
        }}</span>
      </div>
    </div>
  </div>
</div>
