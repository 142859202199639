import { Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { FullComponent } from './layouts/full/full.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'live-map',
        loadChildren: () =>
          import('./pages/live-map-viewer/live-map-viewer.module').then(m => m.DesktopLiveMapViewerModule),
      },
      {
        path: 'analytics',
        loadChildren: () => import('./pages/analytics/analytics.module').then(m => m.AnalyticsModule),
      },
      {
        path: '',
        redirectTo: '/live-map',
        pathMatch: 'full',
      },
    ],
  },
];
