import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HeapAnalyticsService } from '@wc-core';
import {
  RegionalSetting,
  ResponsePlanActionField,
  ResponsePlanEntityType,
  RoadNamesByAccountGQL,
} from '../../../../../core/models/gql.models';
import { ResponsePlanService } from '../../../../../wc-core/src/lib/services/response-plan-service';
import { UiMode } from '../../../../../wc-models/src/lib/enums/response_plan';

@Component({
  selector: 'wc-response-plan-form-action',
  templateUrl: './response-plan-form-action.component.html',
  styleUrls: ['./response-plan-form-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponsePlanFormActionComponent {
  @Input() action!: FormGroup;
  @Input() entityType!: ResponsePlanEntityType;
  @Input() newAddedRowIndex!: number;
  @Input() actionIndex!: number;
  @Input() actionsLength!: number;
  @Input() selectedAction?: number;
  @Input() uiMode!: UiMode;
  @Input() regionalSetting!: RegionalSetting;
  @Output() rowAdded = new EventEmitter<number>();
  @Output() rowRemoved = new EventEmitter<number>();
  @Output() optionsClicked = new EventEmitter<number>();
  uiModeEnum = UiMode;
  responsePlanFormFiledData;

  constructor(
    public responsePlanService: ResponsePlanService,
    public roadNamesByAccountGQL: RoadNamesByAccountGQL,
    private heapService: HeapAnalyticsService
  ) {
    this.responsePlanFormFiledData = this.responsePlanService.responseFormFieldData;
  }

  get minInput() {
    return this.responsePlanService.minStepperInput;
  }

  get maxInput() {
    return this.responsePlanService.maxStepperInput;
  }

  get actionType() {
    return ResponsePlanActionField;
  }

  get maxActionCount() {
    return this.responsePlanService.maxStepperInput;
  }

  clickOnItemOption(index: number) {
    this.optionsClicked.emit(index);
    this.heapService.trackUserSpecificAction(
      `heap-desktop-${
        this.entityType === ResponsePlanEntityType.SpecialEvent ? 'se' : 'incident'
      }-response-plan-3dot-menu-click`
    );
  }

  addRow(index: number) {
    this.rowAdded.emit(index);
  }

  removeRow(index: number) {
    this.rowRemoved.emit(index);
  }

  clickedOutside(index: number) {
    if (this.selectedAction === index) {
      this.clickOnItemOption(index);
    }
  }
}
