export const EnumToLowercaseValuesArray = function (enumObj): Array<string> {
  const options: Array<string> = [];
  for (const key in enumObj) {
    if (enumObj.hasOwnProperty(key)) {
      const value = enumObj[key];
      options.push(value.toLowerCase());
    }
  }
  return options;
};
