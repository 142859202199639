<div class="version-alert-wrapper" *ngIf="!!(isLatestVersion$ | async) === false">
  <img class="glass" src="/assets/images/glass.svg" alt="new-version-icon" />
  <div class="text">{{ 'newVersion' | translate }}</div>
  <div class="message">{{ 'refreshMessage' | translate }}</div>
  <button class="reload-btn" (click)="refreshPage()">
    <i class="fas fa-undo-alt reload-icon"></i>
    <div class="reload">{{ 'refresh' | translate }}</div>
  </button>
</div>
<router-outlet>
  <wc-spinner></wc-spinner>
</router-outlet>
