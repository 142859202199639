import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MapCenterButtonComponent } from './map-center-button.component';

@NgModule({
  declarations: [MapCenterButtonComponent],
  imports: [CommonModule, TranslateModule],
  exports: [MapCenterButtonComponent],
})
export class MapCenterButtonModule {}
