<form class="shift-form" [formGroup]="pauseShiftForm">
  <wc-select-control
    *ngIf="shiftFieldsData.reason.options && shiftFieldsData.reason.options.length"
    formControlName="reason"
    [isTabletMode]="uiStore.isTabletMode"
    [formFieldData]="shiftFieldsData.reason">
  </wc-select-control>

  <div class="overtime-note-container" *ngIf="pauseShiftForm.controls.reason.value === 'OTHER'">
    <wc-text-area-control
      [formControl]="$any(pauseShiftForm.get('reasonNote'))"
      [fieldData]="shiftFieldsData.reasonNote">
    </wc-text-area-control>
  </div>

  <div class="buttons-container">
    <button class="secondary-button" (click)="closeShiftForm()">
      {{ 'cancel' | translate }}
    </button>

    <button class="primary-button" [disabled]="isStartPressed" (click)="submitPauseShiftForm()">
      {{ 'shiftsAndRoutes.pause' | translate }}
    </button>
  </div>
</form>
