export enum MediaType {
  IMAGE = 'image',
  DOCUMENT = 'doc',
  VIDEO = 'video',
}

export enum FILE_SUPPORTED_FORMATS {
  mp4 = 'mp4',
  jpg = 'jpg',
  jpeg = 'jpeg',
  png = 'png',
  gif = 'gif',
  pdf = 'pdf',
  xls = 'xls',
  doc = 'doc',
  docx = 'docx',
  csv = 'csv',
}

export enum MediaFilterType {
  CctvSnapshots = 'CCTV_SNAPSHOTS',
  UploadedMedia = 'UPLOADED_MEDIA',
}

export enum SnapshotType {
  Created = '_create',
  Confirmed = '_confirmed',
  Completed = '_completed',
  Rejected = '_rejected',
  UnitArrived = '_unit_arrived',
  UserCaptured = '_user_'
}

export enum SnapshotTypeLabel {
  Created = 'created',
  Confirmed = 'confirmed',
  Completed = 'completed',
  Rejected = 'rejected',
  UnitArrived = 'unitArrived',
  User = 'snapshot',
}

export enum SnapshotTypeGroups {
  Created = 'created',
  Confirmed = 'confirmed',
  Completed = 'completed',
  Rejected = 'rejected',
  UnitArrived = 'unitArrived',
  User = 'user',
}