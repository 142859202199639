import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { of } from 'rxjs';

@Directive({
  selector: '[wcPopupCloser]',
})
export class WcPopupCloserDirective implements AfterViewInit {
  @Input() refocus = of();
  @Output() closed = new EventEmitter();
  constructor(private el: ElementRef<HTMLElement>) {}

  ngAfterViewInit() {
    this.setupFocus();
    this.refocus.subscribe(() => {
      this.setupFocus();
    });
  }

  setupFocus() {
    if (this.el.nativeElement.tagName === 'DIV') {
      this.el.nativeElement.setAttribute('tabindex', '0');
    }

    setTimeout(() => {
      this.el.nativeElement.focus();
    });
  }

  @HostListener('keydown.escape')
  close() {
    setTimeout(() => {
      this.closed.emit();
    });
  }
}
