import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IncidentsQuery } from '@wc-core';
import { GeoService } from '@wc/core/services/geo.service';
import { SegmentService } from '@wc/core/services/segment.service';
import { ThemeService } from '@wc/core/services/theme.service';
import * as Utils from '@wc/utils';
import { toStringNotNull } from '@wc/utils';
import { WcMapComponent } from '@wc/wc-map/src';
import { IconNames } from '@wc/wc-models/src/lib/types/icon-class-names-type';
import { AutocompleteComponent, OptionsArray } from '@wc/wc-ui/src/components/autocomplete/autocomplete.component';
import { AutoCompleteOption } from '@wc/wc-ui/src/components/autocomplete/components/autocomplete-option/autocomplete-option.component';
import { AutocompleteV2Component } from '@wc/wc-ui/src/form-components/autocomplete-v2/autocomplete-v2.component';
import { FormFieldOption } from '@wc/wc-ui/src/lib/base';
import { diff } from 'deep-diff';
import { cloneDeep } from 'lodash';
import { toJS } from 'mobx';
import { toLonLat } from 'ol/proj.js';
import { combineLatest, forkJoin, merge, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  skip,
  startWith,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';
import {
  AccountStore,
  Address,
  Direction,
  IncidentStatus,
  IncidentStore,
  LiveMapStore,
  LocationService,
  Orientation,
  Point,
  Position,
  RoadType,
  SegmentDetails,
  SplitIOService,
  UiStore,
  UsersStore,
} from '../../../../core';
import { WcMapConfig } from '../../../../core/livemap.config';
import { BaseControlFieldComponent } from '../../base/base-control-field.component';
import { SegmentAutocompleteV2Component } from '../../form-fields-controls/segment-autocomplete-v2/segment-autocomplete-v2.component';
import { DistanceFormatPipe } from '../../pipes';

const GroupNames = {
  highway: 'HIGHWAYS',
  street: 'STREETS',
  alias: 'ALIASES',
} as const;

export type GroupNamesType = typeof GroupNames[keyof typeof GroupNames];
export enum SegmentAliasType {
  Corridor = 'CORRIDOR',
  Crossroad = 'CROSSROAD',
  Both = 'BOTH',
  None = 'NONE',
}

@Component({
  selector: 'wc-address-control',
  templateUrl: './address.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressComponent),
      multi: true,
    },
  ],
  styleUrls: ['./address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent extends BaseControlFieldComponent implements OnInit, AfterViewInit, DoCheck {
  @Input() showMap = false;
  @Input() allowZoomOnMap = true;
  @Input() inlineEdit = true;
  @Input() showJurisdiction = true;
  @Input() showLoader = false;
  @Input() updateMapCenter = false;
  isEditMode = false;
  shouldMoveMap = true;
  shouldUpdateLocation = true;
  corridorCrossroadSwap = false;
  isOutsideJurisdiction = false;
  optionsWithGroupLabels: OptionsArray<FormFieldOption> = [];
  MAX_OPTIONS = 20;
  private segmentNamesAlias: { crossroad?: string | null; corridor?: string | null } = {};
  private firstCorridorCrossroadFormValues?: [string, string];
  private segmentCounter = 0;
  @Input() newAutocomplete = false; // to be deprecated once no need for old autocomplete

  @Input()
  set disableFields(value: boolean) {
    this._disableFields = value;
  }
  @Input() set isIncidentEntity(isIncidentEntity: boolean) {
    this._isIncidentEntity = isIncidentEntity;
  }

  /**
   * @description The following code it due to a problem with {emitEvent:false}.
   * For further info, please look at @link https://github.com/angular/components/issues/20218 or https://github.com/angular/angular/issues/42237
   */

  @Output() addressError: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() addressLoading: EventEmitter<any> = new EventEmitter();
  @Output() mapReady: EventEmitter<any> = new EventEmitter();

  @ViewChild('addressMap') addressMap!: WcMapComponent;

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  get isAliasAvailable() {
    return (
      this.userStore.hasPermission('ALIAS:UPDATE') &&
      this.router.url.includes('/incident') &&
      !this.isIncidentAndInactive()
    );
  }

  @ViewChild('centerMarker', { static: false })
  centerMarker!: ElementRef<HTMLElement>;

  // Old
  @ViewChild('corridorFieldOld', { read: SegmentAutocompleteV2Component })
  corridorFieldOld?: SegmentAutocompleteV2Component;
  @ViewChild('crossroadFieldOld', { read: SegmentAutocompleteV2Component })
  crossroadFieldOld?: SegmentAutocompleteV2Component;
  @ViewChild('milemarkerFieldOld', { read: AutocompleteV2Component })
  milemarkerFieldOld?: AutocompleteV2Component;

  // New
  @ViewChild('corridorField', { read: AutocompleteComponent })
  corridorField?: AutocompleteComponent<FormFieldOption>;
  @ViewChild('crossroadField', { read: AutocompleteComponent })
  crossroadField!: AutocompleteComponent<FormFieldOption>;
  @ViewChild('milemarkerField', { read: AutocompleteComponent })
  milemarkerField!: AutocompleteComponent<FormFieldOption>;

  form!: FormGroup;
  newPoint$: Subject<Point> = new Subject();
  _addressMap!: WcMapComponent;
  _disableFields = false;
  _isIncidentEntity = false;

  addressFieldData: { [filedName in keyof Partial<Address>]: any } = {};

  lastAddressPoint!: Point;
  initMapCenterPoint!: Point;

  mapStyle: Partial<CSSStyleDeclaration> = {
    width: '400px',
    height: '100px',
  };
  centerMarkStyle: Partial<CSSStyleDeclaration> = {};
  mapInitialized!: boolean;
  currentLocation!: number[];
  isCorridorOrCrossroadChanged$!: Observable<boolean>;
  mapStyleMode: 'night' | 'day' = 'day';

  constructor(
    private elRef: ElementRef<HTMLElement>,
    private fb: FormBuilder,
    public segmentService: SegmentService,
    public incidentStore: IncidentStore,
    private cdr: ChangeDetectorRef,
    public uiStore: UiStore,
    public accountStore: AccountStore,
    private liveMapStore: LiveMapStore,
    private locationService: LocationService,
    private geoService: GeoService,
    private themeService: ThemeService,
    private router: Router,
    private splitIOService: SplitIOService,
    private distanceFormatPipe: DistanceFormatPipe,
    private translateService: TranslateService,
    private userStore: UsersStore,
    private incidentsQuery: IncidentsQuery
  ) {
    super();

    this.newPoint$
      .pipe(
        filter(point => !!point?.coordinates),
        map(p => cloneDeep(p)),
        tap(point => {
          if (this.geoService.isOutOfWorkspaces(point.coordinates || [])) {
            const error = { errorCode: 400 };
            this.isOutsideJurisdiction = true;
            if (!this.isEditMode || this.segmentCounter > 0) this.addressError.emit(error);
            this.uiStore.hideLoader('address');
          } else {
            this.isOutsideJurisdiction = false;
            this.addressError.emit();
          }
        }),
        filter(() => !this.isTabletMode || this.segmentCounter < 1),
        tap(point => {
          this.currentLocation = point.coordinates || [];
          if (this.showMap && point.coordinates && this.updateMapCenter) this.setMapCenter(point.coordinates);
          const coords = point.coordinates as number[];
          point.coordinates = [coords[0], coords[1]];
          this.lastAddressPoint = point;
        }),
        filter(
          point =>
            !!(
              point.coordinates &&
              point.coordinates.length === 2 &&
              point.coordinates.map(val => typeof val).filter(type => type === 'number').length === 2 &&
              point?.type === 'Point'
            )
        ),
        switchMap(point =>
          forkJoin([this.segmentDetailsApiCall(point, !this.isEditMode || this.segmentCounter > 0), of(point)])
        ),
        tap(([segmentDetails]) => segmentDetails && this.segmentCounter++)
      )
      .subscribe(([_segmentDetails, point]) => {
        let segmentDetails: SegmentDetails | null | undefined = _segmentDetails;

        if (segmentDetails === null) {
          if (!this.isEditMode || this.segmentCounter !== 1) {
            this.resetFieldsData();
            this.form.patchValue({ point });
            this.cdr.markForCheck();
          }
        }

        if (this.firstCorridorCrossroadFormValues) {
          this.firstCorridorCrossroadFormValues = undefined;
        }

        if (!segmentDetails || (!segmentDetails.refs && !segmentDetails.names)) {
          this.isOutsideJurisdiction ? this.addressError.next({ errorCode: 400 }) : this.addressError.next();
          return;
        }
        segmentDetails.segmentsNames = segmentDetails.segmentsNames.map(name => ({
          ...name,
          distance:
            typeof name.distance === 'number' && !this.isTabletMode
              ? this.distanceFormatPipe.transform(name.distance)
              : (name.distance as any),
        }));

        this.segmentNamesAlias.corridor = segmentDetails.overridingCorridorAlias;
        this.segmentNamesAlias.crossroad = segmentDetails.overridingCrossroadAlias;

        const corridorOptions = [
          ...this.createAliasOptionAndPlaceFirst(
            Utils.segmentDetailsToOptions(segmentDetails.segmentsNames),
            segmentDetails.overridingCorridorAlias
          ),
        ];

        const crossroadsOptions = [
          ...this.createAliasOptionAndPlaceFirst(
            Utils.segmentDetailsToOptions(segmentDetails.segmentsNames),
            segmentDetails.overridingCrossroadAlias
          ),
        ];

        const milemarkers = segmentDetails.milemarkers;
        const segmentRoadType = segmentDetails.roadTypes[0] || null;
        const milemarkersOptions = Utils.strArrayToOptions(milemarkers);

        // remove the free text value from the milemarkers so it wont be included as option
        if (milemarkers.length === 0 && this.segmentCounter > 1) {
          this.milemarkerField?.resetFormControl();
          this.milemarkerFieldOld?.reset();
          this.form.get('milemarker')?.setValue(null);
        }

        const updateFields = () => {
          this.updateOptions(corridorOptions, 'corridor', this.corridorField, segmentRoadType);
          this.updateOptions(crossroadsOptions, 'crossroad', this.crossroadField);
          this.updateOptions(milemarkersOptions, 'milemarker', this.milemarkerField);
        };

        this.corridorFieldOld?.updateOptions(corridorOptions);
        this.crossroadFieldOld?.updateOptions(crossroadsOptions);
        this.milemarkerFieldOld?.updateOptions(milemarkersOptions);

        if (!this.isEditMode && this.shouldMoveMap) {
          this.addressMap?.moveMapCenterToCoordinates(segmentDetails.segmentsNames[0]?.nearestPoint?.coordinates, 400);
        }

        if (!this.isEditMode || this.segmentCounter > 1) {
          this.form.patchValue({
            ...segmentDetails,
            ...(<Address>{
              corridor: segmentDetails.overridingCorridorAlias || segmentDetails.alias || null,
              crossroad: segmentDetails.overridingCrossroadAlias || segmentDetails.crossRoads[0]?.name || null,
              milemarker: milemarkers[0] || null,
              roadLevelType: segmentDetails.roadLevelTypes[0],
              roadType: segmentRoadType,
              county: segmentDetails.county,
              point,
            }),
          });

          this.isOutsideJurisdiction ? this.addressError.next({ errorCode: 400 }) : this.addressError.next();
          this.onChanged(this.fieldFormControl.value);
          this.cdr.markForCheck();
        }

        if (!this.isTabletMode) {
          updateFields();
        }
      });

    this.themeService.isDarkMode$.subscribe((isDarkMode: boolean) => {
      this.mapStyleMode = isDarkMode ? 'night' : 'day';
      this.cdr.markForCheck();
    });
  }

  ngOnInit(): void {
    this.form = this.addressFormGroup({
      point: null,
      city: null,
      corridor: [null, [Validators.required, Validators.maxLength(100)]],
      county: null,
      crossroad: [null, Validators.maxLength(100)],
      direction: [
        null,
        this._isIncidentEntity && this.incidentStore.isIncidentFieldMandatory('directionMandatory')
          ? Validators.required
          : [],
      ],
      orientation: [
        null,
        this._isIncidentEntity && this.incidentStore.isIncidentFieldMandatory('orientationMandatory')
          ? Validators.required
          : [],
      ],
      roadLevelType: null,
      roadType: [null, Validators.required],
      segmentId: null,
      state: null,
      milemarker: [null, Validators.maxLength(15)],
    });

    this.isCorridorOrCrossroadChanged$ = combineLatest([
      this.form.get('corridor')?.valueChanges || of(null),
      this.form.get('crossroad')?.valueChanges.pipe(startWith(null)) || of(null),
    ]).pipe(
      takeWhile(() => this.isAliasAvailable),
      debounceTime(100),
      distinctUntilChanged(),
      tap(firstValues => {
        if (!this.firstCorridorCrossroadFormValues) {
          this.firstCorridorCrossroadFormValues = firstValues;
        }
      }),
      map(
        ([v1, v2]) =>
          (v1 !== null && v1 !== this.firstCorridorCrossroadFormValues?.[0]) ||
          (v2 !== null && v2 !== this.firstCorridorCrossroadFormValues?.[1])
      )
    );

    this.addressFieldData = {
      crossroad: {
        heapClass: 'crossroad',
        label: 'crossroad',
        placeholder: 'enterCrossroad',
      },
      orientation: {
        heapClass: 'orientation',
        label: 'orientation',
        required:
          this._isIncidentEntity && this.incidentStore.isIncidentFieldMandatory('orientationMandatory') ? true : false,
        options: this._isIncidentEntity ? this.incidentStore.orientationOptions : Utils.EnumToOptions(Orientation),
        placeholder: 'orientation',
      },
      direction: {
        heapClass: 'direction',
        label: 'direction',
        required:
          this._isIncidentEntity && this.incidentStore.isIncidentFieldMandatory('directionMandatory') ? true : false,
        options: Utils.EnumToOptions(Direction).map(option => {
          option.displayName = option.displayName?.toUpperCase();
          return option;
        }),
        placeholder: 'direction',
      },
      corridor: {
        heapClass: 'corridor',
        required: true,
        label: 'corridor',
        placeholder: 'enterCorridor',
      },
      roadType: {
        heapClass: 'roadType',
        required: true,
        label: 'roadType',
        options: this.incidentStore.roadTypesOptions,
        placeholder: 'roadType',
      },
      milemarker: {
        heapClass: 'milemarker',
        label: 'milemarker',
        placeholder: 'incident.enterMilemarker',
      },
    };

    this.form.valueChanges
      .pipe(
        take(1),
        startWith(this.form.value),
        map(val => toJS(val)),
        pairwise()
      )
      .subscribe(([prevAddress, address]: Address[]) => {
        if (this.addressMap && address.point['coordinates'].includes(undefined)) {
          this._disableFields = true;
          console.error('Unable to locate the device location');
          const point: Point = {
            coordinates: this.addressMap?.getCenterCoords() as number[],
            type: 'Point',
          };
          this.newPoint$.next(point);
          return;
        }

        if (this.updateMapCenter) this.setMapCenter(address.point.coordinates);

        this._disableFields = false;
        const addressChanged = diff(prevAddress, address, (path, key) => path.length === 0 && key === 'point');
        const pointChanged =
          JSON.stringify(prevAddress.point?.coordinates) !== JSON.stringify(address.point?.coordinates);
        const emptyAddress = !(
          address.city ||
          address.corridor ||
          address.county ||
          address.crossroad ||
          address.direction ||
          address.milemarker ||
          address.orientation ||
          address.roadLevelType ||
          address.roadType ||
          address.segmentId ||
          address.state
        );

        if ((!addressChanged || emptyAddress) && pointChanged && address) {
          this.newPoint$.next(address.point);
        }

        this.cdr.markForCheck();
      });

    this.form.valueChanges.subscribe(() => this.cdr.markForCheck());

    this.startAddressMapAndFormCorrelation();
    if (!this.isTabletMode) {
      this.liveMapStore.liveMapPoistionUpdate$
        .pipe(filter(() => !!this.form.value.point))
        .subscribe((coordinates: number[]) => this.updateAddressFromLocation(coordinates));
    } else this.updateAddressFromLocation();

    // edit mode if we have a corridor on init
    if (this.router.url.includes('/edit') || this.incidentStore.addressContinueEditMode) {
      this.isEditMode = true;
      if (this.formControl.value?.point) this.newPoint$.next(this.formControl.value.point);
    }
  }

  ngDoCheck() {
    if (this.showMap && !this.mapInitialized && this.addressMap && this.addressMap?.map) {
      this.initMap();
      this.mapReady.emit();
      this.mapInitialized = true;
      this.cdr.detectChanges();
    }
  }

  async initMap() {
    this.liveMapStore.setWCMapConfig();
    const mapConfig = this.liveMapStore.wcMapConfig;

    if (this.addressMap) {
      this.addressMap?.init(mapConfig, { mouseEventsMode: 'mapView' });
      this.updateMapSize();
      if (this.initMapCenterPoint && this.initMapCenterPoint.coordinates) {
        this.setMapCenter(this.initMapCenterPoint.coordinates);
      } else {
        const position: Position | undefined = await this.locationService.getCurrentPosition();
        if (position) {
          this.currentLocation = [position.coords.longitude, position.coords.latitude];
          this.setMapCenter(this.currentLocation);
        } else {
          this.addressMap?.setMapLocation(WcMapConfig.mapCenter);
        }
      }

      this.addressMap?.setZoom(15);

      if (this.inlineEdit && this.isTabletMode) {
        this.addressMap?.moveend$.pipe(skip(this.isEditMode ? 0 : 1), debounceTime(100)).subscribe(() => {
          /**
           * @description commented out for hotfix: commit #: 6ecf558f1b21da12a8e9458b08585f9e914ffd8c
           */
          // if (this.shouldUpdateLocation) {
          this.updateAddressFromLocation();
          // }
          this.shouldUpdateLocation = true;
        });
      }

      if (this.showJurisdiction) {
        this.addressMap?.addWorkspacesBorders();
        this.addressMap?.setWorkspacesVisibility(this.liveMapStore.selectedWorkspacesIds);
      }
      this.adjustZoomBtnStyleIfNoSatelliteBtns();

      setTimeout(() => {
        if (!this.form?.value?.point) {
          this.updateAddressFromLocation();
        }
      });
    }
  }

  startAddressMapAndFormCorrelation() {
    merge(this.form.get('corridor')?.valueChanges || of(null), this.form.get('crossroad')?.valueChanges || of(null))
      .pipe(
        debounceTime(200),
        tap(() =>
          setTimeout(() => {
            this.shouldMoveMap = true;
          }, 500)
        ),
        distinctUntilChanged(),
        skip(1),
        filter(() => {
          if (this.corridorCrossroadSwap) {
            this.corridorCrossroadSwap = false;
            return false;
          }
          return true;
        }),
        map(
          v =>
            (
              [
                ...(this.corridorFieldOld?.fieldData.options || []),
                ...(this.corridorField?.formFieldData.options || []),
              ].find(({ value }) => value === v) as FormFieldOption
            )?.data?.nearestPoint?.coordinates as number[]
        ),
        filter(coords => !!coords?.length)
      )
      .subscribe(coordinates => {
        this.form.get('point')?.setValue(
          {
            coordinates,
            type: 'Point',
          },
          { emitEvent: false }
        );
        if (this.uiStore.isTabletMode && this.shouldMoveMap) {
          this.shouldUpdateLocation = false;
          this.addressMap?.moveMapCenterToCoordinates(coordinates, 400);
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateMapSize();
  }

  setMapCenter(coordinates) {
    if (!coordinates || !this.addressMap) return;
    this.addressMap?.setCenter({ coordinates });
  }

  updateAddressFromLocation(center?: number[]) {
    // need to offset the element's actual center with the marker's pointer
    if (this.addressMap && !center) {
      center = this.addressMap?.getCenter();
      center = toLonLat(center);
    }

    if (!center) {
      return;
    }
    const point: Point = {
      type: 'Point',
      coordinates: center,
    };
    this.shouldMoveMap = false;
    this.form.get('point')?.setValue(point);
    this.newPoint$.next(point);
  }

  updateMapSize() {
    if (this.addressMap) {
      const computedStyle: CSSStyleDeclaration = window.getComputedStyle(this.elRef.nativeElement);
      const netWidth =
        this.elRef.nativeElement.clientWidth -
        (parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight));
      const netHeight = window.innerHeight - 350 - 140 - 100; // 250-> max height of location edit in step-one , 350-180 is for new incident top/button margins
      const transform = this.inlineEdit === false ? `translateY(-30%)` : 'none';
      this.mapStyle = {
        width: netWidth + 'px',
        height: netHeight + 'px',
        transform: transform,
      };

      /** Please use matchMedia, as screen.orientation is not supported by Safari browsers. */
      const isLandscape = window.matchMedia('(orientation: landscape)').matches;

      if (isLandscape) {
        const mapWidth = this.inlineEdit ? '50vw' : '100%';

        /** Full height - Main nav - Modal padding - Buttons panel */
        const dynamicHeight = window.innerHeight - 60 - 60 - 80;
        const mapHeight = this.inlineEdit ? dynamicHeight + 'px' : '157px';

        this.mapStyle = {
          width: mapWidth,
          height: mapHeight,
        };

        this.addressMap?.updateMapSize();
        return;
      }

      this.addressMap?.map.setSize([netWidth, netHeight]);
    }
  }

  ngAfterViewInit() {
    this.form.valueChanges.subscribe(val => {
      this.fieldFormControl.setValue(val);
      this.onChanged(this.fieldFormControl.value);
      if (!this.form.valid) {
        this.formControl?.setErrors({ required: true }, { emitEvent: true });
      }
    });
    if (!this.form.valid) {
      this.formControl?.setErrors({ required: true }, { emitEvent: true });
    }
  }

  adjustZoomBtnStyleIfNoSatelliteBtns() {
    if (!document.getElementsByClassName('map-data-buttons').length) {
      const el = document.getElementsByClassName('ol-zoom')[0] as HTMLElement;
      if (!el) return;
      el.style.bottom = '27px';
      el.style.height = '110px';
      el.style.width = '64px';
    }
  }

  addressFormGroup(model: { [filedName in keyof Address]: any }): FormGroup {
    return this.fb.group(model);
  }

  writeValue(val): void {
    if (val) {
      const addressValue = cloneDeep(val);
      this.initMapCenterPoint = addressValue.point;
      if (addressValue.roadType && [RoadType.UnknownRoadType].includes(addressValue.roadType)) {
        addressValue.roadType = null;
      }
      this.form.patchValue(addressValue);
    }
  }

  addressString() {
    const address = this.form.value;
    const directionString = address.direction ? `(${toStringNotNull(address.direction)})` : '';
    let partTwo = '';
    if (address.crossroad || address.milemarker) {
      partTwo = `, ${[address.crossroad, address.milemarker].map(val => toStringNotNull(val)).join(' ')}`;
    }
    return `${toStringNotNull(address.corridor)} ${directionString} ${toStringNotNull(address.orientation)}${partTwo}`;
  }

  resetFieldsData() {
    this.form.patchValue(<Address>{
      corridor: null,
      crossroad: null,
      roadLevelType: null,
      roadType: null,
      direction: null,
      orientation: null,
      milemarker: null,
    });
    this.corridorFieldOld?.reset();
    this.crossroadFieldOld?.reset();
    this.milemarkerFieldOld?.reset();

    this.corridorField?.resetFormControl();
    this.crossroadField?.resetFormControl();
    this.milemarkerField?.resetFormControl();
    this.onChanged(this.fieldFormControl.value);
  }

  triggerEdit() {
    this.edit.emit();
  }

  markAsTouched() {
    // this.corridorField.focus();
    this.fieldFormControl.markAsTouched();
    // this.corridorField.markAsTouched();
    this.markFormGroupTouched(this.form);
    this.cdr.markForCheck();
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) {
        // control is a FormGroup
        this.markFormGroupTouched(control);
      } else {
        // control is a FormControl
        control.markAsTouched();
      }
    });
  }

  async segmentDetailsApiCall(point: Point, showError = true) {
    const coords = point?.coordinates;
    this.lastAddressPoint = point;
    if (this.showLoader) {
      this.uiStore.showLoader('address');
      this.addressLoading.emit(true);
      this._disableFields = true;
    }
    if (point.type === 'Point' && coords) {
      if (this.geoService.isOutOfWorkspaces(coords)) {
        const error = { errorCode: 400 };
        if (showError) this.addressError.emit(error);
        this.uiStore.hideLoader('address');
        return null;
      }

      try {
        const segmentDetailsResponses = await this.segmentService
          .segmentDetails({ coordinates: coords, type: 'Point' })
          .toPromise();
        this._disableFields = false;
        return segmentDetailsResponses;
      } catch (error: any) {
        if (error && error.errorCode !== 400) {
          // if not out side of workspace
          this._disableFields = false;
        }

        if (showError) this.addressError.emit(error);
        return null;
      } finally {
        this.addressLoading.emit(false);
        this.uiStore.hideLoader('address');
      }
    }
    return null;
  }

  swapAddresses() {
    const corridorCtrl = this.form.get('corridor'),
      crossroadCtrl = this.form.get('crossroad');
    const corridorVal = corridorCtrl?.value;
    const crossroadVal = crossroadCtrl?.value;
    if (!corridorVal && !crossroadVal) {
      return;
    }

    if (!corridorVal) {
      this.crossroadFieldOld?.clear();
      this.crossroadField?.clear();
    }

    if (!crossroadVal) {
      this.corridorFieldOld?.clear();
      this.corridorField?.clear();
    }

    corridorCtrl?.setValue(crossroadVal, { emitEvent: false, onlySelf: true });
    crossroadCtrl?.setValue(corridorVal, { emitEvent: false, onlySelf: true });
    this.corridorCrossroadSwap = true;
  }

  updateOptions(
    options: FormFieldOption[],
    field: 'corridor' | 'crossroad' | 'milemarker',
    autocomplete?: AutocompleteComponent<FormFieldOption>,
    segmentRoadType?: RoadType
  ) {
    if (this.form.get(field)?.value) {
      const selectedOption: FormFieldOption = {
        value: this.form.get(field)?.value,
        displayName: this.form.get(field)?.value,
        groupLabel: segmentRoadType
          ? Utils.isHighway(segmentRoadType)
            ? GroupNames.highway
            : GroupNames.street
          : undefined,
        startIcon: segmentRoadType
          ? Utils.isHighway(segmentRoadType)
            ? IconNames.highway
            : IconNames.street
          : undefined,
        data: {
          distance: this.distanceFormatPipe.transform(0),
        },
      };
      options.push(selectedOption);
    }

    const uniqueOptions = this.getUniqueOptions(cloneDeep(this._sortByData(options)));
    this.optionsWithGroupLabels = this.convertToGroupOptions(uniqueOptions, field);
    autocomplete?.updateOptions(this.optionsWithGroupLabels, this.form.get(field)?.value);
    this.changed();
  }

  private convertToGroupOptions(
    options: FormFieldOption[],
    field: 'corridor' | 'crossroad' | 'milemarker'
  ): FormFieldOption[] {
    return this.getMaxRowsOptions(options).map(option => {
      if (option.data?.distance) option.data.distance = this.distanceFormatPipe.transform(option.data.distance);

      if (field === 'milemarker') {
        return option;
      }

      if (option.data?.roadType) {
        if (Utils.isHighway(option.data?.roadType)) {
          option.groupLabel = GroupNames.highway;
          option.startIcon = IconNames.highway;
        } else {
          option.groupLabel = GroupNames.street;
          option.startIcon = IconNames.street;
        }
      }
      const refAliasString = field === 'corridor' ? this.segmentNamesAlias.corridor : this.segmentNamesAlias.crossroad;
      if (option.value === refAliasString) {
        option.groupLabel = GroupNames.alias;
        option.startIcon = IconNames['location-info'];
      }

      return option;
    });
  }

  getMaxRowsOptions(options: FormFieldOption[]): FormFieldOption[] {
    return options.length > this.MAX_OPTIONS ? options.slice(0, this.MAX_OPTIONS) : options;
  }

  getUniqueOptions(options: FormFieldOption[]): FormFieldOption[] {
    const uniqueOptions: FormFieldOption[] = [];
    options.forEach(option => {
      const isFoundOption = uniqueOptions.some(uniqueOption => uniqueOption.value === option.value);
      if (!isFoundOption) {
        uniqueOptions.push(option);
      }
    });

    return uniqueOptions;
  }

  private _sortByData(options: AutoCompleteOption<any>[]): FormFieldOption[] {
    return options.sort((option1, option2) => {
      return (option1.data?.distance as number) > (option2.data?.distance as number) || option2.forceAsFirstOption
        ? 1
        : -1;
    });
  }

  private createAliasOptionAndPlaceFirst(
    options: AutoCompleteOption<any, any>[],
    aliasName?: string | null
  ): AutoCompleteOption<any, any>[] {
    if (typeof aliasName !== 'string' || this.isTabletMode) {
      return options;
    }

    const foundIdx = options.findIndex(elm => elm.value === aliasName);
    const extraLineField = {
      value: this.translateService.instant('verifiedAlias'),
      cssClass: 'verified-info',
      startIcon: 'badge-check',
    } as const;

    // foundIdx === -1 if the aliasName is a free text
    if (foundIdx === -1) {
      return [
        {
          value: aliasName,
          displayName: aliasName,
          forceAsFirstOption: true,
          extraLineField,
        },
        ...options,
      ];
    }

    const foundAliasOption = options.splice(
      options.findIndex(elm => elm.value === aliasName),
      1
    )[0];

    foundAliasOption.extraLineField = extraLineField;
    foundAliasOption.forceAsFirstOption = true;

    options.unshift(foundAliasOption);

    return options;
  }

  private isIncidentAndInactive(): boolean {
    const id = this.router.url.split('/').pop();
    if (!id || isNaN(+id)) return false;
    const incident = this.incidentsQuery.getEntity(+id);
    if (!incident) return false;
    return incident.status === IncidentStatus.Completed || incident.status === IncidentStatus.Rejected;
  }

  get isCorridorHasChanged(): boolean {
    return this.form.get('corridor')?.value !== this.firstCorridorCrossroadFormValues?.[0];
  }

  get isCrossroadHasChanged(): boolean {
    return this.form.get('crossroad')?.value !== this.firstCorridorCrossroadFormValues?.[1];
  }

  getCreatedAliasType(): SegmentAliasType {
    if (this.isCorridorHasChanged && this.isCrossroadHasChanged) {
      return SegmentAliasType.Both;
    } else if (this.isCorridorHasChanged) {
      return SegmentAliasType.Corridor;
    } else if (this.isCrossroadHasChanged) {
      return SegmentAliasType.Crossroad;
    }

    return SegmentAliasType.None;
  }

  getUpdatedAliasType(): SegmentAliasType {
    if (
      this.segmentNamesAlias.crossroad &&
      this.segmentNamesAlias.corridor &&
      this.isCorridorHasChanged &&
      this.isCrossroadHasChanged
    ) {
      return SegmentAliasType.Both;
    } else if (this.segmentNamesAlias.corridor && this.isCorridorHasChanged) {
      return SegmentAliasType.Corridor;
    } else if (this.segmentNamesAlias.crossroad && this.isCrossroadHasChanged) {
      return SegmentAliasType.Crossroad;
    }

    return SegmentAliasType.None;
  }
}
