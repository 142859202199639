<div class="free-text-list-control-component">
  <button data-cy-id="add-button" *ngIf="formArray.enabled" (click)="addControl()" class="button-add">
    <i class="button-add-icon fas fa-plus"></i>
    <span>{{ 'add' | translate }}</span>
  </button>
  <form [formGroup]="group">
    <div [formArrayName]="subFormGroupName">
      <div [formGroupName]="i" *ngFor="let control of formArray.controls; let i = index">
        <div data-cy-id="free-text-field" class="free-text-list-control-component__row">
          <span class="free-text-list-control-component__index-badge"> {{ i + 1 }}</span>
          <wc-text-field
            [attr.data-cy-id]="'free-text-value-' + control.value[textValueKeyName]?.replaceAll(' ', '-')"
            [viewCharacterCounter]="true"
            [formControlName]="textValueKeyName"></wc-text-field>
          <i
            [attr.data-cy-id]="'delete-icon-' + control.value[textValueKeyName]?.replaceAll(' ', '-')"
            *ngIf="control.enabled"
            class="fas fa-solid fa-trash free-text-list-control-component__delete-icon"
            (click)="removeControl(i)"></i>
        </div>
      </div>
    </div>
  </form>
</div>
