<ng-container *ngIf="fullAffectedServicesUIEntities$ | async as fullAffectedServiceList">
  <ng-container *ngIf="fullAffectedServiceList?.length !== 0">
    <ng-container *wcLet="filteredAffectedServicesUIEntities$ | async as filteredAffectedServiceList">
      <mat-expansion-panel
        #affectServicePanel
        [expanded]="isAffectedServicePanelOpened"
        (opened)="onPanelOpened()"
        (closed)="affectedPanelOpened.emit(false)"
        class="affected-service-mat-expansion"
        [class.no-affected-service]="fullAffectedServiceList.length === 0">
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title id="affected-tour-toggle-button">
            <div>{{ 'transit.affectedService' | translate }} ({{ filteredAffectedServiceList.length }})</div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="filteredAffectedServiceList.length > 0" class="affected-service-table-header">
          <span>{{ 'transit.route' | translate | uppercase }}</span>
          <span>{{ 'transit.stops' | translate | uppercase }}</span>
          <span>{{ 'transit.cause' | translate | uppercase }}</span>
          <span>{{ 'transit.clearance' | translate | uppercase }} ~</span>
        </div>

        <div
          class="affected-service-list-container"
          *ngIf="timerForClearance$ | async as newStartTime"
          (scroll)="onContainerScroll($event)">
          <div
            [ngStyle]="{ 'border-left-color': service.color }"
            class="affected-service-list-item heap-desktop-affected-bus-row-click"
            *ngFor="let service of filteredAffectedServiceList"
            (click)="affectedServiceClicked(service.id, service.affectedServiceType, $event, service)"
            (mouseenter)="highlightMapEntity(service.id, service.affectedServiceType)"
            (mouseleave)="highlightMapEntity(undefined, service.affectedServiceType)"
            id="{{ service.id }}">
            <span class="affected-service-list-item__route-column">{{ service.displayId }} </span>
            <ng-container *ngIf="currentHoverAffectedService !== service.id">
              <span *ngIf="service.isRerouted; else noRerouted" class="affected-service-new-badge orange-bg">
                {{ 'transit.rerouted' | translate }}
              </span>
              <ng-template #noRerouted>
                <span *ngIf="!service.isViewed" class="affected-service-new-badge">
                  {{ 'new' | translate }}
                </span>
              </ng-template>
            </ng-container>

            <span class="affected-service-list-item__stops-column">
              <wc-icon [iconName]="service.iconName"></wc-icon>
              {{ service.affectedBusStopsCount || '--' }}</span
            >
            <span class="affected-service-list-item__icon-column">
              <ng-container *ngIf="service.affectingEvents?.length > 0">
                <wc-icon [iconName]="getParsedAffectedServiceIconName(service.affectingEvents[0])"></wc-icon>

                <wc-icon
                  *ngIf="service.affectingEvents?.length === 2"
                  [iconName]="getParsedAffectedServiceIconName(service.affectingEvents[1])"></wc-icon>
                <span *ngIf="service.affectingEvents?.length > 2"> +{{ service.affectingEvents?.length - 1 }} </span>
              </ng-container>
            </span>

            <span
              *ngIf="
                currentHoverAffectedService === service.id && service.affectedServiceType === 'route';
                else hoverState
              ">
              <mat-slide-toggle
                class="heap-desktop-toggle-reRoute-from-affected-row"
                (click)="$event.stopPropagation()"
                (change)="markReroute($event, service.id)"
                [checked]="service.isRerouted">
                {{ 'transit.rerouted' | translate }}
              </mat-slide-toggle>
            </span>
            <ng-template #hoverState>
              <span class="affected-service-list-item__clearance-column">
                {{ newStartTime | durationCountDown: service.estimatedEndTime }}
                <span class="affected-service-list-item__minute-badge">
                  {{ 'analyticsModule.hours' | translate }}
                </span>
              </span>
            </ng-template>
          </div>
        </div>

        <div *ngIf="notAllServiceVisibleMsg$ | async as msg" class="fixed-route-not-selected">
          <i class="fas fa-exclamation-circle"></i>
          {{ msg | translate }}
        </div>
      </mat-expansion-panel>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #emptyState>
  <mat-expansion-panel class="fixed-route-mat-expansion">
    <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
      <div class="empty-services-title">{{ 'transit.affectedService' | translate }} (0)</div>
    </mat-expansion-panel-header>
    <div class="empty-state-affected-service">
      <i class="empty-transit-affected-service"> </i>
      {{ 'transit.noAffectedServices' | translate }}
    </div>
  </mat-expansion-panel>
</ng-template>
