<form class="datetime-input-wrapper" [formGroup]="form" [class.tablet-mode]="isTabletMode">
  <div class="field-label">{{ fieldData.label || '' | translate }}</div>
  <div class="datetime-fields-row">
    <wc-date-field-control
      #date
      [minDate]="minDate"
      [maxDate]="maxDate"
      [formControl]="$any(form.get('date'))"
      [fieldData]="timeDatefields.date"
      [readOnly]="dateReadOnly">
    </wc-date-field-control>
    <wc-time-field-control
      #time
      [formControl]="$any(form.get('time'))"
      [fieldData]="timeDatefields.time"
      [readOnly]="timeReadOnly">
    </wc-time-field-control>
  </div>
</form>
