import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionDirectiveModule } from '@wc/features/ui/directives/permission.directive.module';
import { AutocompleteModule } from '../../autocomplete/autocomplete.module';
import { CameraSliderModule } from '../camera-slider/camera-slider.module';
import { CameraViewerModule } from '../camera-viewer/camera-viewer.module';
import { CameraGalleryModule } from './camera-gallery/camera-gallery.module';
import { CameraSectionComponent } from './camera-section.component';
import { WcIconModule } from '@wc/wc-ui/src/components/wc-icon/wc-icon.module';
import { WcTooltipModule } from "@wc/wc-ui/src/components/tooltip/tooltip.module";
import { WcButtonModule } from "@wc/wc-ui/src/components/wc-button/wc-button.module";

const Material = [
  MatAutocompleteModule,
  MatSelectModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatTabsModule,
  MatTooltipModule,
];

@NgModule({
  imports: [
    CameraViewerModule,
    CameraSliderModule,
    CommonModule,
    Material,
    TranslateModule,
    ReactiveFormsModule,
    CameraGalleryModule,
    PermissionDirectiveModule,
    AutocompleteModule,
    WcIconModule,
    WcTooltipModule,
    WcButtonModule,
  ],
  exports: [CameraSectionComponent],
  declarations: [CameraSectionComponent],
})
export class CameraSectionModule {}
