<div class="event-scheduler-component">
  <form [formGroup]="form">
    <wc-button-toggle-group
      data-cy-id="select-event-scheduler"
      class="event-scheduler-component__scheduler-type"
      [size]="'large'"
      formControlName="isCustomSchedule"
      [formFieldData]="fieldData.toggleModeData"></wc-button-toggle-group>
    <div data-cy-id="select-frames-title" class="event-scheduler-component__select-frames">
      {{ 'selectTimeFrame' | translate }}
    </div>

    <div *ngIf="isCustomSchedule === false" class="event-scheduler-component__schedule-container">
      <wc-date-time-control
        [timeReadOnly]="isAllDayCheckbox.checked"
        [formControl]="$any(form.get('startTime'))"
        [fieldData]="fieldData.startTime">
      </wc-date-time-control>

      <div class="event-scheduler-component__date-time-continer">
        <wc-date-time-control
          [timeReadOnly]="isAllDayCheckbox.checked"
          [formControl]="$any(form.get('endTime'))"
          [fieldData]="fieldData.endTime">
        </wc-date-time-control>
        <mat-checkbox
          data-cy-id="all-day-checkbox"
          #isAllDayCheckbox
          [disabled]="isAllDayDisabled"
          (change)="allDayChecked($event)"
          formControlName="isAllDay">
          {{ 'schedulerForm.allDay' | translate }}</mat-checkbox
        >
      </div>

      <ng-container
        [ngTemplateOutlet]="errorMsg"
        [ngTemplateOutletContext]="{
          error: timeFramesErrorEnum.StartDateBeforeEnd,
          control: form
        }"></ng-container>
      <ng-container
        [ngTemplateOutlet]="errorMsg"
        [ngTemplateOutletContext]="{
          error: timeFramesErrorEnum.DateMustAfterToday,
          control: form.get('endTime')
        }"></ng-container>
      <ng-container
        [ngTemplateOutlet]="errorMsg"
        [ngTemplateOutletContext]="{
          error: timeFramesErrorEnum.required,
          control: form.get('endTime')
        }"></ng-container>
    </div>

    <div *ngIf="isCustomSchedule" class="event-scheduler-component__schedule-container">
      <div class="event-scheduler-component__custom-time-container">
        <wc-date-field-control
          [showErrorMsg]="false"
          [formControl]="$any(form.get('startTime'))"
          [fieldData]="fieldData.startTime"></wc-date-field-control>
        <span> - </span>
        <wc-date-field-control
          [formControl]="$any(form.get('endTime'))"
          [fieldData]="fieldData.endTime"></wc-date-field-control>
        <ng-container
          [ngTemplateOutlet]="errorMsg"
          [ngTemplateOutletContext]="{
            error: timeFramesErrorEnum.StartDateBeforeEnd,
            control: form.get('startTime')
          }"></ng-container>
      </div>
      <div data-cy-id="set-daily-schedule-title" class="event-scheduler-component__select-frames">
        {{ 'setDailySchedule' | translate }}
      </div>

      <div class="event-scheduler-component__time-frames-container" formArrayName="timeRanges">
        <div
          class="event-scheduler-component__frame-container"
          [class.event-scheduler-component__frame-container--invalid]="
            frame.invalid && !frame.get('toTimeMinutesLocal')?.hasError(timeFramesErrorEnum.StartTimeBeforeEnd)
          "
          [class.event-scheduler-component__frame-container--invalid-time]="
            frame.get('toTimeMinutesLocal')?.hasError(timeFramesErrorEnum.StartTimeBeforeEnd)
          "
          *ngFor="let frame of timeRangesArr.controls; let i = index"
          [formGroupName]="i">
          <wc-select
            data-cy-id="select-start-day-input"
            [showErrorMsg]="false"
            formControlName="fromDayOfWeekLocal"
            [formFieldData]="fieldData.days"></wc-select>
          <wc-time-field-control
            [showErrorMsg]="false"
            [formControl]="$any(frame.get('fromTimeMinutesLocal'))"></wc-time-field-control>
          <span> - </span>
          <wc-select
            data-cy-id="select-end-day-input"
            [showErrorMsg]="false"
            formControlName="toDayOfWeekLocal"
            [formFieldData]="fieldData.days"></wc-select>
          <wc-time-field-control
            [showErrorMsg]="frame.get('toTimeMinutesLocal')?.hasError(timeFramesErrorEnum.StartTimeBeforeEnd) || false"
            [class.event-scheduler-component__error-field]="
              frame.get('toTimeMinutesLocal')?.hasError(timeFramesErrorEnum.StartTimeBeforeEnd)
            "
            [formControl]="$any(frame.get('toTimeMinutesLocal'))"></wc-time-field-control>

          <button
            data-cy-id="remove-time-frame-btn"
            [wcTooltipText]="'tooltip.trafficSchedulerAtLeastOneTimeSlot' | translate"
            [wcTooltipDisabled]="timeRangesArr.controls.length !== 1"
            wcTooltipPlacement="top"
            rekor-icon
            [class.event-scheduler-component__disabled-icon]="timeRangesArr.controls.length === 1"
            (click)="removeTimeFrame(i)">
            <wc-icon iconName="trash"></wc-icon>
          </button>

          <ng-container
            [ngTemplateOutlet]="errorMsg"
            [ngTemplateOutletContext]="{
              error: timeFramesErrorEnum.OverlappingRanges,
              control: frame
            }"></ng-container>
          <ng-container
            [ngTemplateOutlet]="errorMsg"
            [ngTemplateOutletContext]="{
              error: timeFramesErrorEnum.DuplicatedRanges,
              control: frame
            }"></ng-container>
        </div>
      </div>
      <button
        data-cy-id="add-hours-btn"
        class="event-scheduler-component__add-btn"
        [wcTooltipText]="'tooltip.reachedTimeSlots' | translate"
        [wcTooltipDisabled]="!isMaxFramesAdded"
        wcTooltipPlacement="top-end"
        [disabled]="isMaxFramesAdded || form.invalid"
        (click)="addTimeFrame()"
        rekor-basic
        leftSideIcon="plus">
        {{ 'addHours' | translate }}
      </button>
    </div>
  </form>
</div>

<ng-template #errorMsg let-control="control" let-error="error">
  <div data-cy-id="schedule-error-messages" *ngIf="control.hasError(error)" class="event-scheduler-component__error">
    {{ 'validationErrorMessages.' + error || ' ' | translate }}
  </div>
</ng-template>
