import { Injectable } from '@angular/core';
import {
  CreateRoleGQL,
  CreateRoleInput,
  DeleteRoleGQL,
  DeleteRoleInput,
  FindUsersRolesGQL,
  Role,
  RolesGQL,
  SetRolePermissionsGQL,
  SetRolePermissionsInput,
  UpdateRoleGQL,
  UpdateRoleInput,
} from '@wc/core/models/gql.models';
import { CustomRxOperatorsService } from '@wc/core/services/custom-rx-operators.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RolesApiService {
  constructor(
    private readonly createRoleGQL: CreateRoleGQL,
    private readonly deleteRoleGQL: DeleteRoleGQL,
    private readonly setRolePermissionsGQL: SetRolePermissionsGQL,
    private readonly updateRoleGQL: UpdateRoleGQL,
    private readonly rolesGQL: RolesGQL,
    private readonly usersRolesGQL: FindUsersRolesGQL,
    private readonly customRxOperators: CustomRxOperatorsService
  ) {}

  rolesByAccount(accountId: number) {
    return this.rolesGQL.fetch({ accountId } as never).pipe(
      map(res => res.data.roles as Role[]),
      this.customRxOperators.catchGqlErrors()
    );
  }

  updateRole(input: UpdateRoleInput) {
    return this.updateRoleGQL.mutate({ input }).pipe(this.customRxOperators.catchGqlErrors());
  }

  createRole(input: CreateRoleInput) {
    return this.createRoleGQL.mutate({ input: input }).pipe(
      map(res => res?.data?.createRole as Role),
      this.customRxOperators.catchGqlErrors()
    );
  }

  setRolePermissions(input: SetRolePermissionsInput) {
    return this.setRolePermissionsGQL.mutate({ input: input }).pipe(
      map(res => res?.data?.setRolePermissions as Role),
      this.customRxOperators.catchGqlErrors()
    );
  }

  deleteRole(input: DeleteRoleInput) {
    return this.deleteRoleGQL.mutate({ input: input }).pipe(this.customRxOperators.catchGqlErrors());
  }

  getUsersWithRoles() {
    return this.usersRolesGQL.fetch().pipe(map(res => res?.data?.findUsersRoles));
  }
}
