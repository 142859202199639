import { Injectable } from '@angular/core';
import { SplitFactory } from '@splitsoftware/splitio';
import { environment } from '@wc/core/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export enum AppFeatureEnum {
  FE_SHOW_UNIDENTIFIED_TYPE = 'fe_show_unidentified_type',
  FE_AVL_LOCATION_SRC = 'fe_avl_location_src',
  FE_SMARTLOOK = 'fe_smartlook',
  FE_SMARTLOOK_CANVAS = 'fe_smartlook_canvas',
  FE_LIMIT_TO_SINGLE_MITIGATION = 'fe_limit_to_single_mitigation',
  FE_USE_AWS_SATELLITE_MAP = 'fe_use_aws_satellite_map',
  FE_AUTO_GENERATED_SNAPSHOTS = 'fe_auto_generated_snapshots',
  FE_USER_GENERATED_SNAPSHOTS = 'fe_user_generated_snapshots',
  FE_PRIORITY_LIST_AND_FILTER = 'fe_priority_list_and_filter',
  FE_PERMITS_SETTINGS_PAGE = 'fe_permits_settings_page',
  IS_QUICKSIGHT_ANALYTICS = 'is-quicksight-analytics-enabled'
}

@Injectable({
  providedIn: 'root',
})
export class SplitIOService {
  splitio!: SplitIO.ISDK;
  splitClient!: SplitIO.IClient;
  isReady = false;
  treatments!: SplitIO.Treatments;
  features: string[] = Object.values(AppFeatureEnum);
  isNewLiveMap = true;
  attributes!: Record<string, any>;
  featureToggles: BehaviorSubject<SplitIO.Treatments> = new BehaviorSubject({});

  initSdk(user) {
    // Running the SDK in 'off-the-grid' Mode since authorizationKey : 'localhost'
    // To bind a non 'off-the-grid' client, inject the real API Key
    this.splitio = SplitFactory({
      core: {
        authorizationKey: environment.splitio.authorizationKey,
        key: user.account.id,
      },

      // scheduler: {
      //     featuresRefreshRate:    30, // 30 sec
      //     segmentsRefreshRate:    60, // 60 sec
      //     metricsRefreshRate:     60, // 60 sec
      //     impressionsRefreshRate: 60, // 60 sec
      //     eventsPushRate:         60, // 60 sec
      //     eventsQueueSize:       500  // 500 items
      // },
      // In non-localhost mode, this map is ignored.
      features: {
        [AppFeatureEnum.FE_SHOW_UNIDENTIFIED_TYPE]: 'on',
        [AppFeatureEnum.FE_AVL_LOCATION_SRC]: 'on',
        [AppFeatureEnum.FE_SMARTLOOK]: 'off',
        [AppFeatureEnum.FE_SMARTLOOK_CANVAS]: 'on',
        [AppFeatureEnum.FE_LIMIT_TO_SINGLE_MITIGATION]: 'on',
        [AppFeatureEnum.FE_USE_AWS_SATELLITE_MAP]: 'off',
        [AppFeatureEnum.FE_AUTO_GENERATED_SNAPSHOTS]: 'on',
        [AppFeatureEnum.FE_USER_GENERATED_SNAPSHOTS]: 'on',
        [AppFeatureEnum.FE_PRIORITY_LIST_AND_FILTER]: 'on',
        [AppFeatureEnum.FE_PERMITS_SETTINGS_PAGE]: 'on',
        [AppFeatureEnum.IS_QUICKSIGHT_ANALYTICS]: 'on'
      },
    });

    this.attributes = { user: user, accountId: user.account.id };
    this.splitClient = this.splitio.client();

    this.splitClient.once(this.splitClient.Event.SDK_READY, () => {
      this.featureToggles.next(this.splitClient.getTreatments(this.features, this.attributes));
      this.splitClient.on(this.splitClient.Event.SDK_UPDATE, () => {
        this.featureToggles.next(this.splitClient.getTreatments(this.features, this.attributes));
      });
    });
  }

  isActiveFeatureToggle$(featureName: AppFeatureEnum) {
    return this.featureToggles.pipe(map(featureToggles => featureToggles[featureName] === 'on'));
  }

  isActiveFeatureToggle(featureName: AppFeatureEnum) {
    return this.featureToggles.getValue()[featureName] === 'on';
  }
}

//https://github.com/splitio/angular-sdk-examples
