<mat-expansion-panel
  *ngIf="(isTrainDelaysEmpty$ | async) === false"
  (opened)="trainDelaysPanelOpened.emit(true)"
  (closed)="trainDelaysPanelOpened.emit(false)"
  [expanded]="isTrainDelaysPanelOpened && !!!(isTrainDelaysEmpty$ | async)"
  [ngClass]="{ empty: isTrainDelaysEmpty$ | async }"
  class="mat-elevation-z0 train-delays">
  <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*" class="train-delays__header">
    <mat-panel-title class="train-delays__title"
      >{{ 'transit.lateTrainArrivals' | translate }} ({{ trainDelaysCount$ | async }})</mat-panel-title
    >
  </mat-expansion-panel-header>
  <ng-container>
    <div class="train-delays__list">
      <div *ngFor="let lateArrival of trainDelays$ | async" class="train-delays__single-station">
        <ng-container *ngIf="lateArrival.routes.length">
          <div class="train-delays__single-station-header">
            {{ lateArrival.stationName }}
          </div>
          <div *ngFor="let route of lateArrival.routes" class="train-delays__route">
            <div>{{ route.routeId }}</div>
            <div class="train-delays__tooltip">
              <div class="train-delays__route-name">
                {{ route.name }}
                <span class="tooltip-text">{{ route.name }}</span>
              </div>
            </div>
            <div class="train-delays__route-planned-arrival">
              {{ route.plannedArrivalTime * 1000 | timeFormatMode: timeFormat }}
            </div>
            <div class="train-delays__route-delay">{{ routeDelay(route.delayInMinutes) }}</div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</mat-expansion-panel>
