import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

export function CompareTimesValidator(beforeControlName: string, afterControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
    const beforeControl = (formGroup as FormGroup).controls[beforeControlName];
    const afterControl = (formGroup as FormGroup).controls[afterControlName];
    const [beforeHours, beforeMinutes] = beforeControl.value.split(':').map(val => Number(val));
    const [afterHours, afterMinutes] = afterControl.value.split(':').map(val => Number(val));

    if (beforeHours < afterHours || (beforeHours === afterHours && beforeMinutes < afterMinutes)) {
      if (afterControl.errors?.startTimeNeedToBeBeforeEndTime) {
        afterControl.setErrors(null);
      }
      return null;
    }

    afterControl.setErrors({ startTimeNeedToBeBeforeEndTime: true });
    return { startTimeNeedToBeBeforeEndTime: true };
  };
}
