import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoadEventHighlightDirective } from './road-event-highlight.directive';

@NgModule({
  declarations: [RoadEventHighlightDirective],
  imports: [CommonModule],
  providers: [],
  exports: [RoadEventHighlightDirective],
})
export class RoadEventHighlightModule {}
