import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';
import { TimeFormat, TimeRangeSchedule } from '@wc/core';
import { cloneDeep } from 'lodash';

export enum TimeFramesError {
  OverlappingRanges = 'overlappingTimeFrames',
  DuplicatedRanges = 'duplicatedTimeFrames',
  StartDateBeforeEnd = 'startDateNeedToBeBeforeEndDate',
  StartTimeBeforeEnd = 'startTimeNeedToBeBeforeEndTime',
  DateMustAfterToday = 'dateMustBeLaterThanToday',
  required = 'required',
}

export function sameDayEndBeforeStartTime(): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
    const fromDayOfWeekLocal = formGroup.get('fromDayOfWeekLocal')?.value;
    const toDayOfWeekLocal = formGroup.get('toDayOfWeekLocal')?.value;
    const toTimeMinutesLocal = formGroup.get('toTimeMinutesLocal');

    if (fromDayOfWeekLocal !== toDayOfWeekLocal) {
      toTimeMinutesLocal?.setErrors(null, { emitEvent: true });
      return null;
    }

    const fromTimeMinutesString = formGroup.get('fromTimeMinutesLocal')?.value;
    const toTimeMinutesString = toTimeMinutesLocal?.value;
    const fromMinutes = formatStringTimeToMinutes(fromTimeMinutesString);
    const toMinutes = formatStringTimeToMinutes(toTimeMinutesString);
    toTimeMinutesLocal?.markAsTouched();

    if (toMinutes > fromMinutes) {
      toTimeMinutesLocal?.setErrors(null, { emitEvent: true });
      return null;
    }

    toTimeMinutesLocal?.setErrors({ [TimeFramesError.StartTimeBeforeEnd]: true }, { emitEvent: true });
    return { [TimeFramesError.StartTimeBeforeEnd]: true };
  };
}

export function overlappingTimeRange(): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
    const timeRangesList = formGroup.get('timeRanges') as FormArray;
    if (timeRangesList.invalid) return null;

    timeRangesList.controls.forEach(control => control.setErrors(null));
    const overlappingIndex = findOverlappingRangeIndex(timeRangesList.value);

    if (overlappingIndex !== -1) {
      timeRangesList.at(overlappingIndex).setErrors({ [TimeFramesError.OverlappingRanges]: true });
      return { [TimeFramesError.OverlappingRanges]: true };
    }

    return null;
  };
}

export function duplicatedTimeRange(): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
    const duplicatedIndex = findDuplicatedTimeFrames(formGroup.get('timeRanges')?.value);
    const arr = formGroup.get('timeRanges') as FormArray;
    Object.values(arr.controls).forEach(control => control.setErrors(null));
    if (duplicatedIndex !== -1) {
      arr.at(duplicatedIndex).setErrors({ [TimeFramesError.DuplicatedRanges]: true });
    }
    return null;
  };
}

export function findOverlappingRangeIndex(dateRanges: TimeRangeSchedule[]): number {
  const overOneWeek: Array<TimeRangeSchedule & { index: number }> = [];
  const splittedRanges = cloneDeep(dateRanges).map<TimeRangeSchedule & { index: number }>((r, i) => {
    if (r.toDayOfWeekLocal < r.fromDayOfWeekLocal) {
      overOneWeek.push({ ...r, fromDayOfWeekLocal: 1, index: i, fromTimeMinutesLocal: '00:00' as unknown as never });
      r.toDayOfWeekLocal = 7;
      r.toTimeMinutesLocal = '23:59' as unknown as never;
    }
    return { ...r, index: i };
  });

  const ranges = splittedRanges
    .concat(overOneWeek)
    .map(r => ({
      from: r.fromDayOfWeekLocal * 24 * 60 + formatStringTimeToMinutes(r.fromTimeMinutesLocal),
      to: r.toDayOfWeekLocal * 24 * 60 + formatStringTimeToMinutes(r.toTimeMinutesLocal),
      index: r.index,
    }))
    .sort(sortByRanges);

  const res = ranges.find(
    (_, i) =>
      i < ranges.length - 1 && Math.max(ranges[i].from, ranges[i + 1].from) <= Math.min(ranges[i].to, ranges[i + 1].to)
  );
  return res ? res.index : -1;
}

export function findDuplicatedTimeFrames(dateRanges: TimeRangeSchedule[]): number {
  const ranges = dateRanges
    .map((r, i) => ({
      from: r.fromDayOfWeekLocal * 24 * 60 + formatStringTimeToMinutes(r.fromTimeMinutesLocal),
      to: r.toDayOfWeekLocal * 24 * 60 + formatStringTimeToMinutes(r.toTimeMinutesLocal),
      index: i,
    }))
    .sort(sortByRanges);

  const res = ranges.find(
    (_, i) => i < ranges.length - 1 && ranges[i].from === ranges[i + 1].from && ranges[i].to === ranges[i + 1].to
  );
  return res ? res.index : -1;
}

export function sortByRanges(a: { from: number; to: number }, b: { from: number; to: number }): number {
  if (a.from === b.from) {
    return b.to - a.to;
  }
  return a.from - b.from;
}

export function formatMinutesToStringTime(minutes: number | string, timeFormat: TimeFormat) {
  if (typeof minutes === 'string') return minutes;
  const hours = Math.floor(minutes / 60);
  const min = minutes % 60;
  const stringHours = String(timeFormat === TimeFormat.TwelveHours ? hours % 12 || 12 : hours).padStart(2, '0');
  const stringMinutes = String(min).padStart(2, '0');
  return `${stringHours}:${stringMinutes}`;
}

export function formatStringTimeToMinutes(timeString: string | number) {
  if (typeof timeString === 'number') {
    console.error('cannot parse number as string time format');
    return 0;
  } else {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  }
}
