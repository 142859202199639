import { Injectable } from '@angular/core';
import { User } from '@wc/core/models/gql.models';
import { tap } from 'rxjs/operators';
import { SplitIOService } from './split-io.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  authUser!: User;

  constructor(private usersService: UsersService, private splitIOService: SplitIOService) {}

  load(): Promise<User> {
    /**
     * TODO: remove after live-map refactor release!!!
     */

    localStorage.removeItem('layerPanelTree');
    console.log('Clear local storage for new version.');

    return new Promise<User>((resolve, reject) => {
      this.usersService
        .getAuthUser()
        .pipe(
          tap((user: User) => {
            this.splitIOService.initSdk(user);
            this.authUser = user;
          })
        )
        .subscribe((user: User) => {
          this.splitIOService.splitClient.once(this.splitIOService.splitClient.Event.SDK_READY, () => {
            resolve(user as User);
          });
          this.splitIOService.splitClient.once(this.splitIOService.splitClient.Event.SDK_READY_TIMED_OUT, () => {
            resolve(user as User);
          });
        });
    });
  }
}
