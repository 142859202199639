import { Layer } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import VectorLayer from 'ol/layer/Vector';
import { Source } from 'ol/source';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, first, switchMap, takeWhile } from 'rxjs/operators';
import { StaticLayerOptionNames, WcMapViewerService } from '../..';

export abstract class StaticMapLayerButton<T extends (BaseLayer | Layer<Source>)[] | BaseLayer | Layer<Source>> {
  staticMapLayers$!: Observable<T>;
  isAlive = true;
  staticMapLayers!: T;

  constructor(private mapViewerService: WcMapViewerService) {}

  readonly visibilityChanged = new Subject<boolean>();
  readonly visibilityChanged$ = this.visibilityChanged.asObservable().pipe(distinctUntilChanged());
  isButtonLayerLoaded$;

  initiateStaticLayerObservableByName(name: StaticLayerOptionNames) {
    this.staticMapLayers$ = this.mapViewerService.ready$.pipe(
      filter(ready => ready),
      takeWhile(() => this.isAlive),
      switchMap(() => this.mapViewerService.getStaticLayerFactoryObservable(name) as Observable<T>)
    );

    this.isButtonLayerLoaded$ = this.mapViewerService.ready$.pipe(
      filter(ready => ready),
      switchMap(() => this.mapViewerService.layerBeingLoaded$),
      first(_name => _name === name),
      switchMap(() => this.staticMapLayers$),
      takeWhile(() => this.isAlive)
    );

    this.isButtonLayerLoaded$?.subscribe(layers => this.initStaticLayer(layers));
  }

  private listenToVisibilityChanges(layer: Layer | BaseLayer): void {
    layer.addEventListener('change:visible', ({ target }) => {
      this.visibilityChanged.next((<VectorLayer>target).getVisible());
      return true;
    });
  }

  initStaticLayer(layers: T) {
    this.staticMapLayers = layers;
    const _layers = Array.isArray(layers) ? layers : [layers];
    _layers.forEach(layer => {
      this.listenToVisibilityChanges(layer);
    });
    this.visibilityChanged.next(_layers[0].getVisible());
  }

  toggleLayerVisibility(layer: BaseLayer | Layer): void {
    layer.setVisible(!layer.getVisible());
  }

  abstract buttonClick(): void;
}
