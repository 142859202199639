import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { WcSelectModule } from '@wc/wc-ui/src/form-components/wc-select/wc-select.module';
import { SegmentFragmentsModule } from '../internal-components/segmant-fragments.module';
import { SegmentDetailsCardComponent } from './segment-details-card.component';

@NgModule({
  declarations: [SegmentDetailsCardComponent],
  imports: [CommonModule, MatExpansionModule, TranslateModule, WcSelectModule, SegmentFragmentsModule],
  exports: [SegmentDetailsCardComponent],
})
export class SegmentDetailsCardModule {}
