import { Injectable } from '@angular/core';
import { AuthUserService } from './auth-user.service';

export enum LocalStorageKeys {
  AllowAlert = 'allow-alert',
  AllowSound = 'allow-sound',
  AllowSoundAlerts = 'allow-sound-alerts',
  AvailableOnboardingTours = 'availableOnboardingTours',
  ClientSideFeedbackMap = 'clientSideFeedbackMap',
  CompletedOnboarding = 'completedOnboarding',
  CrashRiskFilterOption = 'crashRiskFilterOption',
  CreateIncidentLocation = 'create-incident-location',
  DarkModeAutomation = 'darkModeAutomation',
  DarkModeIntroduced = 'darkModeIntroduced',
  DarkModeToggle = 'darkModeToggle',
  DriverShiftAnalyticsColumns = 'driverShiftAnalyticsColumns',
  EditIncidentStepOne = 'editIncidentStepOne',
  EditIncidentStepTwo = 'editIncidentStepTwo',
  EventListSortingPreferences = 'event-list-sorting-preferences',
  ExpandedLayerPanelNodeNames = 'expanded-layer-panel-node-names',
  ExpandedSectionsStorage = 'expandedSectionsStorage',
  FirstTimeOverridePrioryFilter = 'firstTimeOverridePrioryFilter',
  IncidentAnalyticsColumns = 'incidentAnalyticsColumns',
  IncidentBackup = 'incidentBackup',
  IncidentConfigs = 'incidentConfigs',
  IncidentIndicationsIds = 'incidentIndicationsIds',
  InteractedRoadEventIds = 'interactedRoadEventIds',
  IsPortraitDesktopMode = 'isPortraitDesktopMode',
  LayerPanelStoreItemsMap = 'layerPanelStoreItemsMap',
  LayersVisibility = 'layersVisibility',
  LiveMapFilters = 'liveMapFilters',
  MapHistoricalSearches = 'mapHistoricalSearches',
  OnboardingStartDate = 'onboardingStartDate',
  RoadEventFilters = 'roadEventFilters',
  RoadwayStatusSortType = 'roadwayStatusSortType',
  SelectedTransitFilters = 'selectedTransitFilters',
  SelectedTransitLayers = 'selectedTransitLayers',
  SelectedWorkspacesIds = 'selectedWorkspacesIds',
  ShouldShowTransitAlerts = 'shouldShowTransitAlerts',
  ShowMileMarkersLayer = 'showMileMarkersLayer',
  ShowTrafficLayer = 'showTrafficLayer',
  SortBySelectedSorting = 'sortBySelectedSorting',
  StaticMapLayersState = 'staticMapLayersState',
  TdDatahubSelectedTableColumns = 'td-datahub-selected-table-columns',
  TrafficDisruptionStatusFilter = 'trafficDisruptionStatusFilter',
  TransitData = 'transitData',
  TransitDataLastUpdate = 'transitDataLastUpdate',
  TransitFixeRoutes = 'transitFixeRoutes',
  TransitLayersVisibility = 'transitLayersVisibility',
  TransitRouteIdsVisibility = 'transitRouteIdsVisibility',
  UserLastEdit = 'userLastEdit',
  ViewedReleaseNoteIds = 'viewed-release-note-ids',
  ViewedAffectedServiceIds = 'viewedAffectedServiceIds',
  ViewedAffectedServices = 'viewedAffectedServices',
  ViewedCrashRiskIds = 'viewedCrashRiskIds',
  ViewedCrashRisksIds = 'viewedCrashRisksIds',
  ViewedTrainDelayIds = 'viewedTrainDelayIds',
  ViewedWeatherEventsIds = 'viewedWeatherEventsIds',
  WeatherSortOption = 'weatherSortOption',
  WelcomeTourModalViewed = 'welcome-tour-modal-viewed',
  WidgetPosition = 'widgetPosition',
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(private authUserService: AuthUserService) {}

  set(key: string, data: any): void {
    try {
      const _key = `${key}-${this.authUserService.user.id}`;
      localStorage.setItem(_key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }

  get(key: string) {
    try {
      key = `${key}-${this.authUserService.user.id}`;
      const value = localStorage.getItem(key) as string;
      return value ? JSON.parse(value) : null;
    } catch (e) {
      console.error('Error getting data from localStorage', e);
      return null;
    }
  }

  remove(key: string) {
    try {
      key = `${key}-${this.authUserService.user.id}`;
      localStorage.removeItem(key);
    } catch (e) {
      console.error('Error removing from localStorage', e);
    }
  }
}
