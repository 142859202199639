import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { EntitiesServiceV2, LiveMapService, RoadwayStatusQuery, RoadwayStatusService } from '@wc-core';
import { LayerType, MeasureUnitType, RegionalSetting, RoadwayStatusSegmentView } from '@wc/core/models/gql.models';
import {
  LayerPanelStoreItem,
  LayerVisibilityItem,
  LiveMapLayerNames,
  RoadwayStatusSegmentsSortOptions,
  RoadwayStatusStoreEntity,
} from '@wc/wc-models';
import { filter, map } from 'rxjs/operators';
import { ContextTypesEnum } from '../../clustering-tooltip-container/clustering-tooltip-container.component';
import { SegmentDataViewMode } from '../internal-components/segment-data/segment-data.component';

export enum RoadwayStatusTabIndex {
  SegmentsList = 0,
  SegmentDetails = 1,
}

export type SelectedSegmentData = {
  selectedVendorId: number;
  segmentId: number;
};

@Component({
  selector: 'wc-roadway-status-panel',
  templateUrl: './roadway-status-panel.component.html',
  styleUrls: ['./roadway-status-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoadwayStatusPanelComponent implements OnDestroy {
  @Output() selected = new EventEmitter<RoadwayStatusStoreEntity>();
  @Output() layersVisibilityStateChange = new EventEmitter<LayerVisibilityItem>();
  @Output() hidePanel = new EventEmitter<boolean>();
  @Input() isPortraitDesktopMode = false;
  @Input() measureUnitType: MeasureUnitType = MeasureUnitType.Metric;
  @Input() isDirectionRtl = false;
  @Input() isHebrew = false;
  @Input() tabIndex: RoadwayStatusTabIndex = RoadwayStatusTabIndex.SegmentsList;
  @Input() regionalSetting!: RegionalSetting;
  @Input() set roadwayStatusItem(layerPanelStoreItem: LayerPanelStoreItem | null) {
    if (!layerPanelStoreItem) return;
    this.updateShowOnMapFlag(layerPanelStoreItem.checked);
  }

  @Input() segments: RoadwayStatusSegmentView[] = [];
  @Input() set setSelectedSegmentDetails(data: SelectedSegmentData) {
    this.selectedSegmentDetails = data;
    if (
      this.selectedSegmentDetails &&
      this.selectedSegmentDetails.segmentId &&
      this.selectedSegmentDetails.selectedVendorId
    ) {
      this.showSegmentDetails(this.selectedSegmentDetails);
    }
  }

  contextTypesEnum = ContextTypesEnum || null;
  selectedSortSegments =
    this.roadwayStatusService.getSortTypeFromLocalStorage() || RoadwayStatusSegmentsSortOptions.Deviation;

  isShowOnMap = true;
  selectedSegmentDetails!: SelectedSegmentData | null | undefined;
  readonly selectedSegmentFullDetails$ = this.roadwayStatusQuery.ui.selectActiveId().pipe(
    filter(id => !!id?.length),
    map(id => ({
      ...this.roadwayStatusQuery.getEntity(id),
      vendorMetrics: this.roadwayStatusQuery.ui.getEntity(id)?.detailedMetrics,
    }))
  );

  get RoadwayStatusTabIndex(): typeof RoadwayStatusTabIndex {
    return RoadwayStatusTabIndex;
  }

  get segmentDataViewMode(): typeof SegmentDataViewMode {
    return SegmentDataViewMode;
  }

  constructor(
    private roadwayStatusQuery: RoadwayStatusQuery,
    private roadwayStatusService: RoadwayStatusService,
    private entitiesService: EntitiesServiceV2,
    private liveMapService: LiveMapService
  ) {}

  closePanel() {
    this.hidePanel.emit();
  }

  selectSegmentDetails(segmentData: SelectedSegmentData): void {
    this.selectedSegmentDetails = segmentData;
    this.showSegmentDetails(segmentData);
  }

  private showSegmentDetails(segmentData: SelectedSegmentData) {
    this.roadwayStatusService.getRoadwayStatusSegmentDetails(segmentData.segmentId).subscribe(() => {
      this.entitiesService.setUiEntitiesAsActive({ [LayerType.RoadwayStatus]: [segmentData.segmentId] });
      this.tabIndex = this.RoadwayStatusTabIndex.SegmentDetails;
    });

    this.emitLayerVisibilityChange({
      name: LiveMapLayerNames.ROADWAY_STATUS,
      checked: true,
    });

    const selectedSegment = this.segments.find(segment => segment.id === segmentData.segmentId);
    if (selectedSegment) {
      this.selected.emit(selectedSegment);
    }
  }

  saveSortSelectionToLocalStorage(selectedSortType: RoadwayStatusSegmentsSortOptions) {
    this.roadwayStatusService.saveSortTypeToLocalStorage(selectedSortType);
  }

  goBackToTheSegmentsList() {
    this.tabIndex = this.RoadwayStatusTabIndex.SegmentsList;
    this.unselectIfRoadwayStatusFeature();
  }

  emitLayerVisibilityChange(layerDataAndState: LayerVisibilityItem) {
    this.layersVisibilityStateChange.emit(layerDataAndState);
    this.updateShowOnMapFlag(layerDataAndState.checked);
  }

  private updateShowOnMapFlag(status: boolean) {
    this.isShowOnMap = status;
  }

  private unselectIfRoadwayStatusFeature() {
    if (
      typeof this.liveMapService.selectedFeatureId === 'string' &&
      this.liveMapService.selectedFeatureId.includes(LayerType.RoadwayStatus)
    ) {
      this.liveMapService.unselectFeature();
    }
  }

  ngOnDestroy(): void {
    this.unselectIfRoadwayStatusFeature();
  }
}
