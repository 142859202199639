import { TranslateService } from '@ngx-translate/core';
import { SelectOption } from '@wc/wc-models/src/lib/interfaces/general';
import { sortObjectArrayByKey } from './sortObjectArrayByKey';

export const EnumToOptions = function (
  enumObj,
  _options?: {
    translateService?: TranslateService;
    translateBy?: 'value' | 'key';
    translatePath?: string;
    removeSort?: boolean;
    visibleValues?: any[];
    firstOptionValue?: string;
  }
): Array<SelectOption> {
  let options: Array<SelectOption> = [];
  // boolean flag to indicate whether or not all options have display names
  let sortableByDisplayname = true;
  for (const key in enumObj) {
    const value = enumObj[key];
    let displayName = key;
    if (_options?.translateService) {
      const translateName = _options.translateBy === 'key' ? key : value;
      if (_options.translatePath) {
        displayName = _options.translateService.instant(`${_options.translatePath}.${translateName}`);
      } else {
        displayName = _options.translateService.instant(`${translateName}`);
      }
    }
    if (!displayName) sortableByDisplayname = false;

    options.push({
      displayName,
      value: value,
      hidden: !_options?.visibleValues?.includes(value),
    });
  }

  // returns sorted options if possible
  if (sortableByDisplayname && (!_options || !_options?.removeSort))
    options = sortObjectArrayByKey(options, 'displayName');

  // Place the firstOptionValue at the 0 index if provided and translate it
  if (_options?.firstOptionValue) {
    const firstOptionIndex = options.findIndex(option => option.value === _options.firstOptionValue);
    if (firstOptionIndex > -1) {
      const [firstOption] = options.splice(firstOptionIndex, 1);

      options.unshift(firstOption);
    }
  }

  return options;
};

export const strArrayToOptions = function (
  arr: Array<string>,
  _options?: {
    translateService: TranslateService;
    translateBy: 'value' | 'key';
    translatePath?: string;
    removeSort?: boolean;
  }
): SelectOption[] {
  const options: SelectOption[] = [];
  // boolean flag to indicate whether or not all options have display names
  let sortableByDisplayname = true;
  for (const key in arr) {
    const value = arr[key];
    let displayName: string = arr[key];
    if (_options) {
      const translateName = _options.translateBy === 'key' ? key : value;
      const translateKey = _options.translatePath ? `${_options.translatePath}.${translateName}` : `${translateName}`;
      displayName = _options.translateService.instant(translateKey);
    }
    if (!displayName) sortableByDisplayname = false;
    options.push({ displayName: displayName, value: value });
  }

  // returns sorted options if possible
  if (sortableByDisplayname && (!_options || !_options?.removeSort))
    return sortObjectArrayByKey(options, 'displayName');
  return options;
};
