import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { HeapAnalyticsService } from '@wc-core';
import { AccountStore } from '@wc/core';
import { UiStore } from '@wc/core/stores/ui.store';
import moment from 'moment';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { TimeFormat } from '../../../../core/models';
import { BaseControlFieldComponent } from '../../base/base-control-field.component';

@Component({
  selector: 'wc-time-field-control',
  templateUrl: './time-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeFieldComponent),
      multi: true,
    },
  ],
  styleUrls: ['./time-field.component.scss'],
})
export class TimeFieldComponent extends BaseControlFieldComponent implements OnInit, AfterViewInit {
  show12Hours: boolean;
  isRoundedHours: boolean;
  previousTime: any;
  time: any;
  formattedTime: any;
  isPickerOpen = false;
  isInFocus = false;
  hourSwiperConfig!: SwiperConfigInterface;
  minuteSwiperConfig!: SwiperConfigInterface;
  periodSwiperConfig!: SwiperConfigInterface;
  hourIndex!: number;
  minuteIndex!: number;
  periodIndex!: number;
  hours!: string[];
  minutes!: string[];
  timePeriods!: string[];
  selectedHourIndex!: number;
  selectedMinuteIndex!: number;
  selectedPeriodIndex!: number;
  timeFormats = [
    'h:mm',
    'h:mm A',
    'h:mm a',
    'hh:mm A',
    'hh:mm a',
    'hh:mm',
    'HH:mm A',
    'HH:mm a',
    'HH:mm',
    'hhmm',
    'HHmm',
    'hmm',
    'Hmm',
    'hh',
    'HH',
    'h',
    'H',
  ];

  @Input() readOnly!: boolean;
  @Input() roundedHours!: boolean;
  @Input() showErrorMsg = true;
  @ViewChildren(SwiperDirective) swiperElements?: QueryList<SwiperDirective>;
  @ViewChild('timeField') timeField!: ElementRef;
  @ViewChild('timeMenuTrigger') timeMenuTrigger!: MatMenuTrigger;
  moment: any = moment;
  isParentInvalid = false;

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  constructor(private uiStore: UiStore, private accountStore: AccountStore, private heapService: HeapAnalyticsService) {
    super();
    const timeFormat = this.accountStore?.account?.regionalSetting?.timeFormat;
    this.show12Hours = timeFormat === TimeFormat.TwelveHours;

    // @todo: check how to fetch rounded hours prop
    this.isRoundedHours = false;
  }

  ngOnInit() {
    this.hours = this.fillTimeConstants(this.show12Hours ? 13 : 24);
    if (this.show12Hours) {
      this.hours.shift(); // Removes 00 from hours
    }
    this.minutes = this.isRoundedHours ? ['00'] : this.fillTimeConstants(60);
    this.timePeriods = ['AM', 'PM'];

    this.formControl.valueChanges.subscribe(val => {
      this.time = val;
      this.formatTime();
    });

    this.initHourPickerSwiperConfig();
    this.initMinutePickerSwiperConfig();
    this.initPeriodSwiperConfig();
  }

  ngAfterViewInit() {
    this.formatTime();
  }

  initHourPickerSwiperConfig() {
    this.hourSwiperConfig = {
      freeModeSticky: true,
      slidesPerView: 5,
      mousewheel: true,
      spaceBetween: 5,
      slidesOffsetAfter: 175,
      threshold: this.isTabletMode ? 0 : 20,
      direction: 'vertical',
      slideToClickedSlide: true,
      centeredSlides: true,
    };
  }

  initMinutePickerSwiperConfig() {
    this.minuteSwiperConfig = {
      freeModeSticky: true,
      slidesPerView: 5,
      mousewheel: true,
      spaceBetween: 5,
      slidesOffsetAfter: 175,
      threshold: this.isTabletMode ? 0 : 20,
      direction: 'vertical',
      slideToClickedSlide: true,
      centeredSlides: true,
    };
  }

  initPeriodSwiperConfig() {
    this.periodSwiperConfig = {
      slidesPerView: 5,
      mousewheel: true,
      spaceBetween: 5,
      slidesOffsetAfter: 60,
      threshold: this.isTabletMode ? 0 : 20,
      direction: 'vertical',
      slideToClickedSlide: true,
      centeredSlides: true,
    };
  }

  writeValue(val): void {
    this.time = val;
    this.formatTime();
    // this.fieldFormControl.setValue(val);
  }

  valueChanged() {
    // When input field is empty using previous time value
    if (this.fieldFormControl.value === '' && this.previousTime) {
      this.fieldFormControl.setValue(this.previousTime);
    }

    const isValid = this.isTimeString(this.fieldFormControl.value);
    if (isValid) {
      this.onChanged(
        this.moment(
          this.fieldFormControl.value.length === 3 ? '0' + this.fieldFormControl.value : this.fieldFormControl.value,
          this.show12Hours ? ['hh:mm A'] : ['HH:mm']
        ).format('HH:mm')
      );
    } else {
      this.timeMenuTrigger.closeMenu();
      this.onChanged('');
    }
  }

  formatTime() {
    if (this.time) {
      const hours = this.time?.slice(0, 2);
      const minutes = this.time?.slice(3, 5);
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      this.formattedTime = this.moment(date).format(this.show12Hours ? 'hh:mm A' : 'HH:mm');
      if (this.isPickerOpen) {
        this.setSelectedIndexes(this.formattedTime);
      }
      this.fieldFormControl.setValue(this.formattedTime);
      this.previousTime = this.formattedTime;
    } else if (this.time === '' && this.fieldFormControl.value) {
      // To avoid showing invalid values
      this.fieldFormControl.setValue('');
    }
  }

  handleMenuOpened() {
    if (!this.formattedTime || !this.time) {
      this.formattedTime = this.show12Hours ? '12:00 AM' : '00:00';
    }
    this.setSelectedIndexes(this.formattedTime);

    // Set freemode in a delay to avoid selected indexes mess
    setTimeout(() => {
      if (this.isTabletMode) {
        this.timeField.nativeElement.blur();
      } else {
        this.timeField.nativeElement.setSelectionRange(0, this.timeField.nativeElement.value.length);
      }
      this.hourSwiperConfig.freeMode = true;
      this.minuteSwiperConfig.freeMode = true;
      this.isPickerOpen = true;
    }, 0);
    this.heapService.trackUserSpecificAction('heap-time-picker-opened');
  }

  handleMenuClosed() {
    this.isPickerOpen = false;

    // Undo freemode in a delay to avoid selected indexes mess
    setTimeout(() => {
      this.hourSwiperConfig.freeMode = false;
      this.minuteSwiperConfig.freeMode = false;
    }, 0);

    this.valueChanged();
    this.timeField.nativeElement.blur();
  }

  onFocus() {
    // Disabling input typing but still handling the menu events
    if (this.isTabletMode) {
      this.isPickerOpen ? this.timeMenuTrigger.closeMenu() : this.timeMenuTrigger.openMenu();
      setTimeout(() => {
        this.timeField.nativeElement.blur();
      }, 0);
    } else {
      this.isInFocus = true;
    }
  }

  onBlur() {
    this.isInFocus = false;
  }

  onPaste(event) {
    event.preventDefault();
    const text = event.clipboardData.getData('text/plain').trim();

    const isValidDate = this.isDateString(text);
    const isValidTime = this.isTimeString(text);

    if (isValidDate || isValidTime) {
      this.formattedTime = (
        isValidDate ? this.moment(new Date(text)) : moment().hours(text.slice(0, 2)).minutes(text.slice(3, 5))
      ).format(this.show12Hours ? 'hh:mm A' : 'HH:mm');
      if (this.isPickerOpen) {
        this.setSelectedIndexes(this.formattedTime);
      }
      this.fieldFormControl.setValue(this.formattedTime);
      this.onChanged(
        this.moment(this.fieldFormControl.value, this.show12Hours ? ['hh:mm A'] : ['HH:mm']).format('HH:mm')
      );
    } else {
      this.fieldFormControl.setValue('');
      this.onChanged('');
      this.fieldFormControl.setErrors({ invalid: true }, { emitEvent: true });
    }
  }

  isDateString(input: string) {
    let isDate = false;
    const newDate = new Date(input);
    if (Object.prototype.toString.call(newDate) === '[object Date]') {
      isDate =
        !isNaN(newDate.getTime()) &&
        this.moment(newDate, this.timeFormats, true).isValid() &&
        this.moment(input, ['hh:mm A']).isValid();
    }
    return isDate;
  }

  isTimeString(input: string) {
    const isTime = this.moment(input, this.timeFormats, true).isValid();
    return isTime;
  }

  hourUpdate(index: number) {
    if (index !== null) {
      const hourIndex = this.show12Hours ? index + 1 : index;
      const updatedHourStr = hourIndex < 10 ? '0' + hourIndex : hourIndex;
      this.formattedTime = updatedHourStr + this.formattedTime.slice(2);
      this.fieldFormControl.setValue(this.formattedTime);
    }
  }

  minuteUpdate(index: number) {
    if (index !== null) {
      const updatedMinutesStr = index < 10 ? '0' + index : index;
      this.formattedTime = this.formattedTime.slice(0, 3) + updatedMinutesStr + this.formattedTime.slice(5);
      this.fieldFormControl.setValue(this.formattedTime);
    }
  }

  periodUpdate(index: number) {
    if (index !== null) {
      const periodStr = index === 0 ? 'AM' : 'PM';
      this.formattedTime = this.formattedTime.slice(0, this.formattedTime.length - 2) + periodStr;
      this.fieldFormControl.setValue(this.formattedTime);
    }
  }

  setSelectedIndexes(formattedTime: string) {
    if (formattedTime !== null) {
      const hours = formattedTime.slice(0, 2);
      const hourIndex = this.show12Hours ? +hours - 1 : +hours;
      const minutes = formattedTime.slice(3, 5);
      const resultArray: SwiperDirective[] = this.swiperElements?.toArray() as [];
      resultArray[0].setIndex(hourIndex);
      resultArray[0].update();
      resultArray[1].setIndex(+minutes);
      resultArray[1].update();

      if (this.show12Hours && resultArray.length > 2) {
        const period = formattedTime.slice(6);
        const periodIndex = period === 'AM' ? 0 : 1;
        resultArray[2].setIndex(periodIndex);
        resultArray[2].update();
      }
    }
  }

  fillTimeConstants(length: number): string[] {
    return Array.from(Array(length)).map((e, i) => {
      return i < 10 ? '0' + i : i.toString();
    });
  }

  setInvalid(invalidState: boolean) {
    this.isParentInvalid = invalidState;
  }
}
