import { Injectable } from '@angular/core';
import {
  CreateRoleInput,
  DeleteRoleInput,
  Role,
  SetRolePermissionsInput,
  UpdateRoleInput,
  User,
} from '@wc/core/models/gql.models';
import { RolesService } from '../infrastructure/roles.service';

@Injectable({
  providedIn: 'root',
})
export class RolesFacadeService {
  constructor(private readonly rolesService: RolesService) {}

  get roles(): Role[] {
    return this.rolesService.roles;
  }

  get roles$() {
    return this.rolesService.roles$;
  }

  get rolesMap() {
    return this.rolesService.rolesMap;
  }

  get usersWithRoles$() {
    return this.rolesService.usersWithRoles$;
  }

  get usersWithRoles() {
    return this.rolesService.usersWithRoles;
  }

  get usersRoles(): Record<number, Role[]> {
    return this.rolesService.usersRoles;
  }

  get usersRoles$() {
    return this.rolesService.usersRoles$;
  }

  get rolesTableFormat() {
    return this.rolesService.rolesTableFormat;
  }

  loadRoles() {
    return this.rolesService.loadRoles();
  }

  createRole(input: CreateRoleInput) {
    return this.rolesService.createRole(input);
  }

  updateRole(input: UpdateRoleInput) {
    return this.rolesService.updateRole(input);
  }

  deleteRole(input: DeleteRoleInput) {
    return this.rolesService.deleteRole(input);
  }

  getRolesOptions() {
    return this.rolesService.getRolesOptions();
  }

  getUsersWithRoles() {
    return this.rolesService.getUsersWithRoles();
  }

  getUserRoleIds(userId: number) {
    return this.rolesService.getUserRoleIds(userId);
  }

  getUserRoleNames(userId: number) {
    return this.rolesService.getUserRoleNames(userId);
  }

  getUserRoles(userId: number) {
    return this.rolesService.getUserRoles(userId);
  }

  setUserRoles(user: User, roleIds: number[]) {
    return this.rolesService.setUserRoles(user, roleIds);
  }

  removeUserRoles(userId: number) {
    return this.rolesService.removeUserRoles(userId);
  }

  setRolePermissions(input: SetRolePermissionsInput, updateRoleInput: UpdateRoleInput) {
    return this.rolesService.setRolePermissions(input, updateRoleInput);
  }

  rolePermissionsControllersGroups(role: Role) {
    return this.rolesService.rolePermissionsControllersGroups(role);
  }
}
