import { FixedRouteBusStatus, NotificationDestination, Point } from '@wc/core';

export enum CollaborationMessageType {
  SUBSCRIBE = 'Subscribe',
  START_EDIT = 'StartEdit',
  COMPLETE_EDIT = 'CompleteEdit',
  START_VIEW = 'StartView',
  COMPLETE_VIEW = 'CompleteView',
  UPDATE_TRACKED_BUS_ROUTES = 'UpdateTrackedBusRoutes',
}

export type IncidentCollaborationMessage = {
  type: CollaborationMessageType;
  customerId: number;
  userName: string;
  incidentId: number;
};

export type IncidentCollaboration = {
  type: 'incident_update';
  incidentId: number;
  editing?: string[];
  viewing?: string[];
  mergedInto?: number;
};

export type ActiveBusUpdate = {
  id: number;
  displayId: string;
  patternId: number;
  accountId: number;
  accountName: string;
  location: Point;
  nextStopId: number;
  status: FixedRouteBusStatus;
  statusInMinutes: number;
  eta: number;
};

export type FixedRouteBusesUpdate = {
  routeId: number;
  activeBuses: Array<ActiveBusUpdate>;
  removedBuses: Array<number>;
};

export type TransitUnitCollaboration = {
  type: string;
  updates: FixedRouteBusesUpdate[];
};

export type MergeUpdate = {
  type: string;
  mergeIncidentUpdate: {
    sourceIncidentId: number;
    targetIncidentId: number;
    unitsUsersInvolved: number[];
  };
};

export type SharePublicResponseUpdate = {
  type: string;
  response: {
    destination: NotificationDestination;
    statusCode: number;
    errorCode: number;
    message?: string;
    userId: number;
  };
};
