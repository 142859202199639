import { Injectable } from '@angular/core';
import { MeGQL, UnitVehicle, User } from '@wc/core/models/gql.models';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthUserService {
  private _authUser: BehaviorSubject<User> = new BehaviorSubject({} as User);
  public authUser$ = this._authUser.asObservable();

  get user() {
    return this._authUser.getValue();
  }

  constructor(private meGQL: MeGQL) {}

  getAuthUser() {
    return this.meGQL.fetch().pipe(
      map(res => res.data.me as User),
      tap((user: User) => {
        this._authUser.next(user);
      })
    );
  }

  updateAuthUserUnitData(unitVehicle: UnitVehicle) {
    const authUser = this._authUser.getValue();
    const userDetails = authUser.unit?.userDetails;

    const updatedAuthUser = userDetails
      ? { ...authUser, unit: { ...unitVehicle, userDetails } }
      : { ...authUser, unitVehicle };

    this._authUser.next(updatedAuthUser);
  }
}

//TODO: need to init the smart look service on user load:

// tap(user => {
//   this.smartlook
//     .init()
//     .then(() => {
//       this.smartlook.setIdentify(user);
//     })
//     .catch(() => {
//       console.log('No smartlook!!!');
//     });
//   localStorage.setItem('user', JSON.stringify(user));
//   this.geoService.setWorkspaces(user.account.workspaces.workspaces);
// })
