import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HeapClassDirectiveModule } from '@wc/wc-common/src';
import { WcTooltipModule } from '@wc/wc-ui/src/components/tooltip/tooltip.module';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { WcTextAreaModule } from '../../form-fields-controls/text-area/text-area.module';
import { NotesFiledComponent } from './notes.component';

@NgModule({
  declarations: [NotesFiledComponent],
  imports: [
    CommonModule,
    TranslateModule,
    WcBaseControlFieldModule,
    WcTextAreaModule,
    HeapClassDirectiveModule,
    WcTooltipModule,
  ],
  providers: [],
  exports: [NotesFiledComponent],
})
export class NotesComponentModule {}
