<div class="unit-dashcams-modal tablet-mode">
  <wc-map-unit-tooltip
    class="tooltip-container"
    [tooltipState]="unitResponse"
    [isTablet]="true"
    [additionalInfo]="additionalInfo"
    (incidentIdClick)="openIncidentEmit()">
    <div class="unit-dashcams-modal__content-header">
      <div class="tooltip-content" [class.one-line-header]="!unitDetails?.userDetails?.name">
        <div class="tooltip-unit-line">
          <wc-icon class="tooltip-icon" [iconName]="unitDetails.type"></wc-icon>
          <span class="incident-type">
            {{ 'mapLayers.' + unitDetails?.type | translate }}
          </span>

          <span class="entity-id"> #{{ unitDetails.displayId }} </span>
        </div>

        <div class="content-header-second-line">
          <div class="tooltip-title tooltip-second-line" *ngIf="unitDetails && unitDetails?.userDetails">
            {{ unitDetails?.userDetails?.name }}
          </div>

          <div class="tooltip-title tooltip-second-line units" *ngIf="unitDetails.unitRoutes?.length > 0">
            <wc-chip
              [style]="ChipStyles.units"
              [isTablet]="true"
              [text]="route"
              *ngFor="let route of unitDetails.unitRoutes"></wc-chip>
          </div>
        </div>
      </div>
    </div>

    <div
      class="unit-dashcams-modal__dashcams-container"
      [class.unit-on-break]="additionalInfo.unitBreakDuration"
      [class.unit-with-status-panel]="unitResponse && unitResponse !== 'on_break'">
      <wc-unit-dashcams [dashCams]="unitDetails.cameras || []" [isTabletMode]="true"> </wc-unit-dashcams>
    </div>
  </wc-map-unit-tooltip>
</div>
