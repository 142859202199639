import { AfterViewInit, Component, ElementRef, forwardRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UiStore } from '../../../../core/stores';
import { BaseControlFieldComponent } from '../../base/base-control-field.component';

@Component({
  selector: 'wc-text-area-control',
  templateUrl: './text-area.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
    FormControl,
  ],
  styleUrls: ['./text-area.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextAreaComponent extends BaseControlFieldComponent implements AfterViewInit {
  @ViewChild('textarea') textAreas!: ElementRef;
  @Input() readonly!: boolean;
  @Input() autoFocus!: boolean;
  @Input() rows = 4;
  @Input() cols = 66;
  hasFocus!: boolean;

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  constructor(public uiStore: UiStore) {
    super();
  }

  ngAfterViewInit() {
    if (this.autoFocus) {
      this.textAreas.nativeElement.focus();
    }
  }

  _focused() {
    this.hasFocus = true;
  }

  _onTouched() {
    console.log('blur');
    this.formControl.markAsTouched();
    this.hasFocus = false;
  }
}
