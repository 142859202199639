<div
  [formGroup]="form"
  class="affected-lanes-container"
  [class.tablet-mode]="isTabletMode"
  [class.multiple-directions]="!isOneDirection">
  <div
    class="affected-lanes-border-line"
    [class.new-autocomplete]="newAutocomplete"
    [class.create]="mode === 'create'"></div>
  <wc-affected-lanes-diagram
    *ngIf="isOneDirection"
    class="affected-lanes-diagram"
    [hovered]="hoveredLane"
    [lanes]="lanes"
    (laneHovered)="diagramLaneHovered($event)"
    (laneClick)="handleLaneClick($event)">
  </wc-affected-lanes-diagram>
  <div class="affected-lanes-add-lane">
    <mat-label class="affected-lanes-add-label" *ngIf="isOneDirection">
      <span data-cy-id="affected-lanes-add-label-content" class="lanes-label-content">{{
        'upTo9Lanes' | translate
      }}</span>
    </mat-label>
    <button
      data-cy-id="affected-lanes-add-button"
      class="affected-lanes-add-button"
      *ngIf="!isTabletMode"
      mat-button
      [disableRipple]="true"
      [matMenuTriggerFor]="menu"
      (click)="$event.preventDefault()"
      [disabled]="lanes.length >= maxNumberLanes">
      <i class="fas fa-plus"></i>
      <span class="affected-lanes-add-button-label">{{ 'add' | translate }}</span>
    </button>
    <button
      class="affected-lanes-add-button"
      *ngIf="isTabletMode"
      mat-button
      [disableRipple]="true"
      [disabled]="lanes.length >= maxNumberLanes"
      (click)="openLaneMenuModal()">
      <i class="fas fa-plus"></i>
      <span class="affected-lanes-add-button-label">{{ 'add' | translate }}</span>
    </button>
    <mat-menu #menu="matMenu" class="add-nav-menu">
      <button
        class="affected-lanes-menu-button"
        [disabled]="!menuitem.isActive"
        mat-menu-item
        [disableRipple]="true"
        *ngFor="let menuitem of contextMenuOptions"
        (click)="addLane(menuitem)">
        <img class="lane-icon" src="{{ menuitem.icon }}" />
        <span [attr.data-cy-id]="'affected-lanes-menu-type-' + menuitem.type.toLowerCase()">
          {{ menuitem.type | translate }}</span
        >
      </button>
    </mat-menu>
  </div>
  <wc-affected-lanes-slider
    #affectedLanesSlider
    [newAutocomplete]="newAutocomplete"
    class="affected-lanes-slider"
    [isOneDirection]="isOneDirection"
    [isDirectionAutopopulated]="isDirectionAutopopulated"
    [formControl]="$any(form.get('lanes'))"
    [fieldData]="lanesFieldData.lanes"
    [hovered]="diagramHoveredLane"
    [allLanesAffected]="_allAffected"
    (select)="handleLaneClick($event)"
    (laneHover)="handleLaneHover($event)">
  </wc-affected-lanes-slider>
</div>
