import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UiStore } from '@wc/core';

@Component({
  selector: 'wc-lane-menu-modal',
  templateUrl: './lane-menu-modal.component.html',
  styleUrls: ['./lane-menu-modal.component.scss'],
})
export class LaneMenuModalComponent implements OnInit {
  contextMenuOptions: any;

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  constructor(
    public dialogRef: MatDialogRef<LaneMenuModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private uiStore: UiStore
  ) {
    this.contextMenuOptions = data.contextMenuOptions;
    this.cdr.markForCheck();
  }

  ngOnInit(): void {}

  handleMenuItemClick(option) {
    this.dialogRef.close(option);
  }
}
