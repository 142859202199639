import { ChangeDetectionStrategy, Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { Maybe, PersistenceScoreType, PriorityScoreLevel } from '@wc/core';
import { APP_TYPE_TOKEN } from '@wc/wc-core/src/lib/injection-tokens';
import { AppTypeUnion } from '@wc/wc-models/src';

@Component({
  selector: 'wc-persistence-score',
  templateUrl: './persistence-score.component.html',
  styleUrls: ['./persistence-score.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PersistenceScoreComponent {
  @Input() persistenceScoreType: Maybe<PersistenceScoreType> | PriorityScoreLevel | undefined;
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() textMode: 'short' | 'long' = 'short';
  @Input() disableTooltip = false;

  get appType() {
    return this._appType;
  }

  constructor(@Inject(APP_TYPE_TOKEN) private _appType: AppTypeUnion) {}
}
