import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { EventEmitter, InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { mapViewerEnvConfigToken } from '../injection-tokens';
import { mapViewerEnvConfig } from '../interfaces';
// test

export const languageChange = new InjectionToken<EventEmitter<LangChangeEvent>>('languageChange');

@NgModule({
  imports: [CommonModule, HttpClientModule, TranslateModule],
})
export class WcMapViewerModule {
  static forRoot(config: mapViewerEnvConfig): ModuleWithProviders<WcMapViewerModule> {
    return {
      ngModule: WcMapViewerModule,
      providers: [
        TranslateService,
        {
          provide: mapViewerEnvConfigToken,
          useValue: config,
        },
      ],
    };
  }
}
