<mat-expansion-panel class="incident-section" [expanded]="true">
  <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
    <mat-panel-title>
      <i class="section-icon icon-affected-lanes"></i>
      {{ 'affectedLanes' | translate }}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="affected-lanes-container" *ngIf="relatedLanes?.length && affectedLanes?.length; else noLanes">
    <div class="affected-lanes-header">
      <div class="details-item road-type">
        <div class="title">{{ 'roadType' | translate }}</div>
        <div class="content">
          {{ 'roadTypes.' + roadType | translate }}
        </div>
      </div>
      <div class="details-item direction">
        <div class="title">{{ 'direction' | translate }}</div>
        <div class="content">
          {{ direction | translate }}
        </div>
      </div>
      <div class="details-item stauts">
        <div class="title">{{ 'status' | translate }}</div>
        <div class="content">
          {{
            allLanesAffected
              ? ('lanesStatuses.allLanesAreAffected' | translate)
              : ('lanesStatuses.partialyAffected' | translate)
          }}
        </div>
      </div>
    </div>
    <div class="affected-lanes-border-line"></div>
    <wc-affected-lanes-diagram
      class="affected-lanes-component"
      [isAnalyticsMode]="true"
      [analyticsLanes]="affectedLanes"
      [isViewMode]="true">
    </wc-affected-lanes-diagram>

    <div class="swiper-component swiper-lanes-component">
      <div class="swiper-container swiper-container-affected-lanes" [swiper]="affectedLanesConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide affected-lane-box" *ngFor="let lane of affectedLanes">
            <div class="title-row-lane">
              <span *ngIf="lane.affectedLane.number" class="lane-name"
                >{{ 'laneTypesShort.' + lane.affectedLane.type | translate }} {{ lane.affectedLane.number }}</span
              >
              <span class="lane-name-full">{{ 'laneTypes.' + lane.affectedLane.type | translate }}</span>
              <br />
              <span class="lane-time-filed">
                {{ 'total' | translate }}
              </span>
              <span class="lane-time-filed">
                :
                {{ formatNumberToDate(lane.closureTimeSec) | timeFormat }}
              </span>
            </div>
            <div class="container-of-timeline-lanes">
              <div
                class="lane-timeline-row"
                *ngFor="let update of lane.closureUpdates; let last = last"
                [class.show-border]="!last">
                <span>
                  {{ (update.isClosed ? 'closed' : 'opened') | translate }}
                </span>
                <span class="lane-time-filed">
                  <i class="fas fa-clock"></i>
                  {{ update.updateTime | dateFormat : accountStore.account.regionalSetting }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination-lanes swiper-pagination" *ngIf="affectedLanes?.length > 2"></div>
      </div>
      <div class="swiper-button-prev lanes-swiper-button-prev"></div>
      <div class="swiper-button-next lanes-swiper-button-next"></div>
    </div>
  </div>
  <ng-template #noLanes>
    <div class="no-lanes-container">
      <i class="no-lanes-image"></i>
      <div class="no-lanes-title">
        {{ 'noAffectedLanes' | translate }}
      </div>
    </div>
  </ng-template>
</mat-expansion-panel>
