import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IncidentLaneDetails, LaneType } from '@wc/core';
import { RoadEventLane } from '../affected-lanes-utils-service';

@Component({
  selector: 'wc-affected-lanes-diagram',
  templateUrl: './affected-lanes-diagram.component.html',
  styleUrls: ['./affected-lanes-diagram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AffectedLanesDiagramComponent {
  @Input() isViewMode = false;
  @Input() lanes!: Partial<RoadEventLane>[];
  @Input() analyticsLanes!: Partial<IncidentLaneDetails>[];

  @Input() isAnalyticsMode = false;
  @Input() hovered = null;
  @Output() laneClick = new EventEmitter<number>();
  @Output() laneHovered = new EventEmitter<number>();

  TextualLanes = [LaneType.Gore, LaneType.LeftShoulder, LaneType.RightShoulder];

  laneClicked(index: number) {
    this.laneClick.emit(index);
  }

  laneHover(index: number) {
    this.laneHovered.emit(index);
  }
}
