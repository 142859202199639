import { ElementRef, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EntitiesServiceV2, LiveMapService, ResponsePlanService, SharePublicService } from '@wc-core';
import { SelectOption } from '@wc/features/ui/form-controls/form-models';
import { AlertsService } from '@wc/features/ui/services/alerts.service';
import { scrollToInvalidPanel } from '@wc/wc-common/src';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { wcCoordinate, wcCoordinateTypes, WcGeometry, WcGeometryType } from '@wc/wc-map-viewer/src';
import {
  LayerTypeToInputUpdate,
  OccupancyRangeSortedByAmount,
  ToastrPositionClass,
  TrafficDisruptionStoryEntity as TrafficDisruptionStoreEntity,
} from '@wc/wc-models/src';
import { ConfirmModalService } from '@wc/wc-ui';
import { liveMapConfig } from '@wc/wc-ui/src/components/map-viewers/live-map-viewer/live-map-viewer.config';
import { FormFieldData } from '@wc/wc-ui/src/lib/base';
import { cloneDeep } from 'lodash';
import { action, makeObservable, observable, reaction } from 'mobx';
import moment from 'moment';
import { merge, MonoTypeOperatorFunction, Observable, of, pipe, Subject } from 'rxjs';
import { finalize, map, mergeAll, switchMap, take, tap } from 'rxjs/operators';
import * as Utils from '../../utils';
import { AffectedDirectionsToOptions, EnumToOptions } from '../../utils';
import {
  Construction,
  ConstructionType,
  ConstructionView,
  CreateConstructionInput,
  CreateRoadClosureInput,
  CreateSpecialEventInput,
  EntityType,
  LayerType,
  LineString,
  Point,
  Polygon,
  ResponsePlanEntityType,
  RoadClosureView,
  SpecialEvent,
  SpecialEventType,
  SpecialEventView,
  TrafficDisruption,
  TrafficDisruptionStatus,
  TrafficDisruptionView,
  UpdateConstructionInput,
  UpdateRoadClosureInput,
  UpdateSpecialEventInput,
  UpdateTrafficDisruptionLaneInput,
  UpdateTrafficDisruptionLanesInput,
  UploadEntityType,
  VenueDetails,
} from '../models';
import { ConfirmationModalType, RoadEventsPanelSubTabIndexDesktop, RoadEventsPanelTabIndex } from '../models/enums';
import { ConstructionService, GeoService, LocalStorageService, RoadClosureService } from '../services';
import { SpecialEventService } from '../services/special-event.service';
import { TrafficDisruptionService } from '../services/traffic-disruption.service';
import { LiveMapStore } from './live-map.store';
import { UiStore } from './ui.store';
import { UploadStore } from './upload.store';

type ServiceByLayerType = {
  [LayerType.Construction]: ConstructionService;
  [LayerType.RoadClosure]: RoadClosureService;
  [LayerType.SpecialEvent]: SpecialEventService;
};

type TrafficDisruptionCreateInput = CreateConstructionInput | CreateRoadClosureInput | CreateSpecialEventInput;

type OperationFunction = {
  create: {
    [LayerType.Construction]: CreateConstructionInput;
    [LayerType.RoadClosure]: CreateRoadClosureInput;
    [LayerType.SpecialEvent]: CreateSpecialEventInput;
  };
  update: {
    [LayerType.Construction]: UpdateConstructionInput;
    [LayerType.RoadClosure]: UpdateRoadClosureInput;
    [LayerType.SpecialEvent]: UpdateSpecialEventInput;
  };
};

export type TrafficDisruptionLayerType = LayerType.Construction | LayerType.RoadClosure | LayerType.SpecialEvent;

export enum AlertPrefixEnum {
  Construction = 'construction',
  RoadClosure = 'roadClosure',
  SpecialEvent = 'specialEvent',
}

export enum OperationsEnum {
  Create = 'create',
  Update = 'update',
}

@Injectable({
  providedIn: 'root',
})
export class TrafficDisruptionStore {
  private _currentPendingDelete: number[] = [];
  moment = moment;
  isFormSubmitted$ = new Subject<boolean>();

  startLocation!: Point;
  selectedTrafficDisruption!: TrafficDisruptionView;

  completedTrafficDisruptions: {
    road_closure: RoadClosureView[];
    construction: ConstructionView[];
    special_event: SpecialEventView[];
  } = {
    road_closure: [],
    construction: [],
    special_event: [],
  };
  selectedTrafficDisruptionBeforeEdit: any;
  selectedTrafficDisruptionType: ConstructionType | SpecialEventType | undefined;
  selectedOtherTypeDescription: string | undefined;
  newlyCreatedTrafficDisruptionId: number | undefined = undefined;

  private readonly _onTrafficDisruptionEdited = new Subject<number>();
  readonly onTrafficDisruptionEdited$ = this._onTrafficDisruptionEdited.asObservable();
  @observable trafficDisruptionStatusFilter!: TrafficDisruptionStatus | null;

  //TODO: Removing fieldData to a dedicated service, that will serve all places in the app that uses fieldData.
  fieldData: { [filedName in (keyof SpecialEvent & CreateSpecialEventInput & Construction) | any]: FormFieldData } = {};

  constructor(
    private translateService: TranslateService,
    private trafficDisruptionService: TrafficDisruptionService,
    private uiStore: UiStore,
    private entitiesService: EntitiesServiceV2,
    private liveMapService: LiveMapService,
    private constructionService: ConstructionService,
    private roadClosureService: RoadClosureService,
    private specialEventService: SpecialEventService,
    private alertService: AlertsService,
    private geoService: GeoService,
    private router: Router,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private uploadStore: UploadStore,
    private liveMapStore: LiveMapStore,
    private confirmService: ConfirmModalService,
    private responsePlanService: ResponsePlanService,
    private sharePublicService: SharePublicService
  ) {
    makeObservable(this);
    reaction(
      () => this.trafficDisruptionStatusFilter,
      trafficDisruptionStatusFilter => {
        this.localStorageService.set(LocalStorageKeys.TrafficDisruptionStatusFilter, trafficDisruptionStatusFilter);
        this.liveMapStore.updateTrafficDisruptionStatusFilter(this.trafficDisruptionStatusFilter);
      }
    );
    this.initFieldData();
    const langChange$ = this.translateService.onLangChange.subscribe(() => {
      this.initFieldData();
      langChange$.unsubscribe();
    });
  }

  initFieldData() {
    this.fieldData = {
      address: { label: 'roadClosureForm.address' },
      title: {
        label: 'roadClosureForm.title',
        required: true,
      },
      eventName: {
        label: 'specialEventForm.eventName',
        required: true,
      },
      occupancyRange: {
        label: 'specialEventForm.occupancy',
        options: EnumToOptions(OccupancyRangeSortedByAmount, {
          translateBy: 'value',
          translateService: this.translateService,
          translatePath: 'specialEventOccupancyRange',
          removeSort: true,
        }),
      },
      venueLocation: {
        label: 'specialEventForm.location',
      },
      venueName: {
        label: 'specialEventForm.venueName',
        options: [],
      },
      venueAddress: {
        label: 'specialEventForm.venueAddress',
        options: [],
      },
      startTime: { label: 'roadClosureForm.startTime', required: true },
      endTime: { label: 'roadClosureForm.endTime', required: true },
      estimatedEndTime: { label: 'roadClosureForm.estimatedEndTime' },
      location: {},
      contactPerson: { label: 'roadClosureForm.contactPerson' },
      contactPersonEmail: { label: 'roadClosureForm.email' },
      contactPersonName: { label: 'roadClosureForm.name' },
      contactPersonPhone: { label: 'roadClosureForm.phoneNumber' },
      description: { label: 'roadClosureForm.description' },
      specialEventType: {
        label: 'subtype',
        options: this.eventTypeOptions(LayerType.SpecialEvent),
        required: false,
      },
      constructionType: {
        label: 'subtype',
        options: this.eventTypeOptions(LayerType.Construction),
        required: false,
      },
      otherTypeDescription: { label: 'description' },

      multiDirectionLanesAffected: {
        label: 'affectedDirectionsLabel',
        options: AffectedDirectionsToOptions({
          translateService: this.translateService,
        }),
      },
      allLanesAffected: {
        label: 'allLanesAreAffected',
      },
      affectedLanes: { label: 'roadClosureForm.lanesDetails' },
      lanesClosureType: {
        label: 'roadClosureForm.allLanesAreClosed',
      },
      isNotifyPublicFormData: {
        label: this.translateService.instant('shareAfterCreation'),
      },
    };
  }

  /**
   * @deprecated
   */
  @action
  updateTrafficDisruptionLane(trafficDisruption: TrafficDisruptionView, input: UpdateTrafficDisruptionLaneInput) {
    this.trafficDisruptionService
      .updateTrafficDisruptionLanes({
        trafficDisruptionId: trafficDisruption.id,
        modified: [input],
        removed: [],
        created: [],
      } as UpdateTrafficDisruptionLanesInput)
      .subscribe();
  }

  isTrafficDisruptionEditable(trafficDisruption: TrafficDisruption) {
    return !['MultiPolygon', 'MultiLineString'].includes(trafficDisruption?.location.type);
  }

  get selectedFeatureId() {
    return this.liveMapService.selectedFeatureId;
  }

  get mapCenterPoint() {
    return this.liveMapService.mapCenterPoint;
  }

  get isMapReady$() {
    return this.liveMapService.isMapReady$;
  }

  eventTypeOptions(layerType: TrafficDisruptionLayerType): SelectOption[] {
    const enumType = layerType === LayerType.SpecialEvent ? SpecialEventType : ConstructionType;
    return Utils.EnumToOptions(enumType, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: layerType === LayerType.SpecialEvent ? 'specialEventType' : 'constructionType',
    });
  }

  fetchAndUpdateCompletedTrafficDisruptions(layerType: TrafficDisruptionLayerType) {
    (
      this.getServiceByLayerType(layerType).fetchCompleted$ as unknown as Observable<
        (ConstructionView | RoadClosureView | SpecialEventView)[]
      >
    )
      .pipe(map(entities => entities.map(entity => ({ ...entity, show: false }))))
      .subscribe({
        next: completedEntities =>
          this.entitiesService.emitNewEntitiesDiff({
            modified: {
              [layerType]: completedEntities,
            },
          }),
      });
  }

  createOrUpdateTrafficDisruption<T extends TrafficDisruptionStoreEntity>(
    layerType: TrafficDisruptionLayerType,
    entity: T,
    operation: OperationsEnum
  ) {
    return (
      operation === 'update'
        ? this.updateTrafficDisruption(layerType, { ...entity, relatedImages: [] })
        : this.createTrafficDisruption(layerType, { ...entity, relatedImages: [] })
    ).pipe(
      finalize(() => this.finishSubmissionProcess(operation)),
      switchMap(res =>
        res
          ? of(res).pipe(
              this.addLayerTypeAndWorkSpaceToEntity(layerType),
              this.emitTrafficDisruptionUpdate(),
              this.liveMapService.toggleWorkspaceVisibilityOnEntityUpdate(),
              this.liveMapService.setEntityLayerAsVisible(layerType)
            )
          : of(null)
      )
    );
  }

  scrollToInvalidPanel() {
    const el = document.getElementById('panel-id');
    const elRef: ElementRef = new ElementRef(el);
    scrollToInvalidPanel(elRef, 10);
  }

  handleInvalidSubmit() {
    setTimeout(() => {
      this.scrollToInvalidPanel();
    }, 10);
    this.alertService.error(this.translateService.instant('incidentFormValidationMessage'), undefined, {
      positionClass: ToastrPositionClass.DesktopBottomPanelLeft,
    });
  }

  // -----------------------new API------------------------:
  selectTrafficDisruption<T extends TrafficDisruptionStoreEntity>(
    trafficDisruption: T,
    zoomOn: boolean,
    emitEvent = true
  ): Observable<string | undefined> {
    if (!trafficDisruption.layerType) {
      throw new Error("can't select traffic disruption with layerType:" + trafficDisruption.layerType);
    }
    this.entitiesService.emitNewEntitiesDiff({
      modified: {
        [trafficDisruption.layerType]: [trafficDisruption],
      },
    });

    this.selectedTrafficDisruption = trafficDisruption;
    this.selectedTrafficDisruptionBeforeEdit = cloneDeep(trafficDisruption);
    if (zoomOn)
      this.liveMapService.setMapCenter(trafficDisruption.location.coordinates, {
        zoomLevel: 15,
        duration: 1000,
        padding: [100, 100, 100, 100],
      });
    this.selectedTrafficDisruption = trafficDisruption;
    return this.liveMapService.selectFeature(trafficDisruption.layerType, trafficDisruption.id, emitEvent);
  }

  clearTrafficDisruptionSelection() {
    this.stopModifyGeometry(false);
    this.stopCreateGeometry();
    this.liveMapService.unselectFeature();
    this.selectedTrafficDisruption = {} as TrafficDisruptionView;
    this.selectedTrafficDisruptionBeforeEdit = null;
  }

  selectFeature(layerType: TrafficDisruptionLayerType, id: number, emitEvent: boolean) {
    return this.liveMapService.selectFeature(layerType, id, emitEvent);
  }

  unselectFeature() {
    this.liveMapService.unselectFeature();
  }

  updateTrafficDisruption<T extends TrafficDisruptionStoreEntity>(layerType: TrafficDisruptionLayerType, entity: T) {
    this.newlyCreatedTrafficDisruptionId = entity.id;
    this.uiStore.showLoader('trafficDisruptions');
    return of(
      Utils.affectedLanesArrayDiff(this.selectedTrafficDisruptionBeforeEdit.affectedLanes, entity.affectedLanes)
    ).pipe(
      switchMap(({ modified, removed, created }) =>
        modified.length || removed.length || created.length
          ? this.trafficDisruptionService
              .updateTrafficDisruptionLanes({
                trafficDisruptionId: entity.id,
                modified,
                removed,
                created,
              })
              .pipe(
                //Should be removed when switching to update lanes by the update entity API
                tap(
                  modifiedLanes =>
                    (this.selectedTrafficDisruption.affectedLanes = this.selectedTrafficDisruption.affectedLanes?.map(
                      l => modifiedLanes?.find(({ id }) => id === l.id) || l
                    ))
                )
              )
          : of(true)
      ),

      map(() => this.getEntityDiff(entity)),
      switchMap(diff =>
        Object.keys(diff).length
          ? this.createOrUpdateEntity(
              layerType,
              OperationsEnum.Update,
              this.getAsTrafficDisruptionInput(diff, entity.id)
            )
          : of(null)
      )
    );
  }

  emitTrafficDisruptionUpdate<T extends TrafficDisruptionStoreEntity>(): MonoTypeOperatorFunction<T> {
    return pipe(
      tap((data: T) => {
        if (!data?.layerType) {
          throw new Error('entity does not have layer type');
        }
        if (typeof data === 'object' && data !== null)
          this.entitiesService.emitNewEntitiesDiff({
            modified: {
              [data.layerType]: [data],
            },
          });
      }),
      tap(data => this._onTrafficDisruptionEdited.next(data.id))
    );
  }

  addLayerTypeAndWorkSpaceToEntity<T extends TrafficDisruptionStoreEntity>(layerType: TrafficDisruptionLayerType) {
    return map<T, T>((entity: T) => ({
      ...entity,
      layerType,
      workspaces: this.geoService.workspacesByLocation(entity.location.coordinates),
    }));
  }

  createTrafficDisruption<T extends TrafficDisruptionStoreEntity>(
    layerType: TrafficDisruptionLayerType,
    entity: T
  ): Observable<T | null> {
    delete (<Partial<T>>entity)['id'];
    return this.createOrUpdateEntity(layerType, OperationsEnum.Create, {
      ...entity,
      relatedImages: [],
    } as TrafficDisruptionCreateInput);
  }

  createOrUpdateEntity<T extends TrafficDisruptionLayerType, O extends OperationsEnum>(
    layerType: T,
    operation: O,
    input: OperationFunction[O][T]
  ): Observable<any> {
    const service = this.getServiceByLayerType(layerType);
    if (!service) {
      throw new Error(`could not find service with LayerType:${layerType}`);
    }
    switch (operation) {
      case 'create':
        return service.create(input as any);
      case 'update':
        return service.update(input as any);
      default:
        throw new Error(`Could not find operation for: ${operation} `);
    }
  }

  getVenuesNearPoint(point: Point): Observable<VenueDetails[]> {
    return this.getServiceByLayerType(LayerType.SpecialEvent).getVenuesNearPoint(point);
  }

  setMapCenter(coords: wcCoordinate): void {
    this.liveMapService.setMapCenter(coords);
  }

  private getAsTrafficDisruptionInput<T extends TrafficDisruptionStoreEntity>(
    entity: Partial<T>,
    id: number
  ): LayerTypeToInputUpdate<T> {
    return {
      ...entity,
      id,
      address: entity.address ? { value: entity.address } : null,
      contactPerson: entity.contactPerson ? { value: entity.contactPerson } : null,
      description: entity.description ? { value: entity.description } : null,
      endTime: entity.endTime ? { value: entity.endTime } : null,
    } as unknown as LayerTypeToInputUpdate<T>;
  }

  private getEntityDiff<T extends TrafficDisruptionStoreEntity>(entity: T): Partial<T> {
    const diff = Utils.objectDiff(this.selectedTrafficDisruptionBeforeEdit, entity) as Partial<T>;
    delete diff.affectedLanes;
    return diff;
  }

  // -----------------------new API------------------------:
  alertOnOperationEnd(isSuccess: boolean, operation: OperationsEnum, prefix: string) {
    if (isSuccess) {
      this.alertService.success(
        this.translateService.instant(`notifications.${prefix + (operation === 'update' ? 'Updated' : 'Created')}`),
        undefined,
        undefined,
        true
      );
    } else {
      this.alertService.error(
        this.translateService.instant(
          `notifications.${prefix + (operation === 'update' ? 'UpdateFailed' : 'CreationFailed')}`
        ),
        undefined,
        undefined,
        true
      );
    }
  }

  get modifyAndDrawEndEvents$() {
    return merge([this.liveMapService.modifyEndEvent$, this.liveMapService.drawEndEvent$]).pipe(mergeAll());
  }

  onAddressError(error: { errorCode: number }) {
    if (error?.errorCode) {
      this.translateService
        .get(`errorMessages.${error.errorCode}`)
        .pipe(take(1))
        .subscribe(errorMessage => {
          this.alertService.error(errorMessage);
        });
    }
  }

  createNewGeometry(
    location: Point | LineString | Polygon
  ): Observable<WcGeometry | undefined | Point | LineString | Polygon> {
    return this.liveMapService
      .createGeometry(
        location.type,
        location.coordinates || (null as unknown as wcCoordinateTypes),
        liveMapConfig.editFeatureOption?.editStyleArgs,
        {
          editStyleArgs: liveMapConfig.editFeatureOption?.editStyleArgs,
        }
      )
      .pipe(map(drawEvent => drawEvent.targets?.[0]?.geometry));
  }

  private getServiceByLayerType<T extends TrafficDisruptionLayerType>(TrafficDisruptionType: T): ServiceByLayerType[T] {
    switch (TrafficDisruptionType) {
      case LayerType.Construction:
        return this.constructionService as ServiceByLayerType[T];
      case LayerType.RoadClosure:
        return this.roadClosureService as ServiceByLayerType[T];
      case LayerType.SpecialEvent:
        return this.specialEventService as ServiceByLayerType[T];
      default:
        throw new Error(`LayerType: ${TrafficDisruptionType} is not a valid TrafficDisruptionType`);
    }
  }

  finishSubmissionProcess(operation: OperationsEnum) {
    this.uiStore.hideLoader('trafficDisruptions');
    this.router.navigateByUrl('/live-map');
    if (operation === 'create') {
      this.uiStore.setRoadEventsPanelTabIndex(RoadEventsPanelTabIndex.InProgress);
      this.uiStore.setRoadEventsInProgressSubTabIndex(RoadEventsPanelSubTabIndexDesktop.TrafficDisruptions);
    }
    this.uploadFiles(this.newlyCreatedTrafficDisruptionId);
    this.deleteTrafficDisruptionMedia(this.newlyCreatedTrafficDisruptionId);
    this.newlyCreatedTrafficDisruptionId = undefined;
  }

  editFeatureGeometry(featureId: string, geometryType: WcGeometryType) {
    return this.liveMapService.editFeatureGeometry(
      featureId,
      {
        showReference: true,
        editStyleArgs: liveMapConfig.editFeatureOption?.editStyleArgs,
        referenceStyleArgs: liveMapConfig.editFeatureOption?.referenceStyleArgs,
      },
      geometryType
    );
  }

  stopModifyGeometry(keepChanges = true) {
    this.liveMapService.stopEditGeometry(keepChanges);
  }

  stopCreateGeometry() {
    this.liveMapService.stopCreateGeometry();
  }

  completeTrafficDisruption(trafficDisruption: TrafficDisruptionStoreEntity, layerType?: LayerType) {
    const _layerType = trafficDisruption.layerType || layerType;
    const loaderName = this.trafficDisruptionService.getLoaderNames()[_layerType];
    if (!_layerType) {
      throw new Error(`invalid parameters for completing traffic disruption: layerType: ${_layerType}, `);
    }
    this.uiStore.showLoader(loaderName);

    return new Promise<boolean>(resolve => {
      this.isResponsePlanDone(trafficDisruption.id, _layerType)
        .pipe()
        .subscribe({
          next: done => {
            if (done === false) {
              this.confirmService.showConfirmDialog(
                ConfirmationModalType.ResponsePlanNotDone,
                EntityType.SpecialEvent,
                completeAnyWay => {
                  if (completeAnyWay) {
                    this._completeTD(trafficDisruption, _layerType).subscribe(() => {
                      this.uiStore.hideLoader(loaderName);
                      resolve(true);
                    });
                  } else {
                    this.uiStore.hideLoader(loaderName);
                    resolve(false);
                  }
                }
              );
            } else {
              this._completeTD(trafficDisruption, _layerType).subscribe(() => {
                this.uiStore.hideLoader(loaderName);
                resolve(true);
              });
            }
          },
          error: err => console.error('Failed to Reroute affected service', err),
        });
    });
  }

  _completeTD(trafficDisruption: TrafficDisruptionStoreEntity, layerType: LayerType) {
    return this.getServiceByLayerType(layerType as TrafficDisruptionLayerType)
      .complete(+trafficDisruption.id)
      .pipe(
        tap(isSuccess => {
          if (!isSuccess) {
            throw new Error('fail');
          }
        }),
        tap(() => {
          if (this.selectedTrafficDisruption) {
            this.unselectFeature();
          }
          //TODO: Currently not updating the affected lanes. should be from the backend.
          this.entitiesService.emitNewEntitiesDiff({
            modified: {
              [layerType]: [
                {
                  id: trafficDisruption.id,
                  status: TrafficDisruptionStatus.Completed,
                  layerType: layerType,
                },
              ],
            },
            removed: {
              [layerType]: [trafficDisruption.id],
            },
          });
          this.alertService.success(
            this.translateService.instant('trafficDisruptionNotifications.' + TrafficDisruptionStatus.Completed, {
              type: this.translateService.instant('trafficDisruptionTypes.' + layerType).toLowerCase(),
            })
          );
        })
      );
  }

  addFilesToPendingDelete(trafficDisruptionMediaId: number) {
    this._currentPendingDelete.push(trafficDisruptionMediaId);
  }

  hasPendingMediaDeletions() {
    return this._currentPendingDelete.length > 0;
  }

  resetPendingMediaDeletions() {
    this._currentPendingDelete = [];
  }

  uploadFiles(entityId?: number) {
    if (!entityId) throw new Error('Entity ID must exist');
    this.uploadStore.addFilesToQueueAndUpload(UploadEntityType.TrafficDisruption, entityId).subscribe();
  }

  deleteTrafficDisruptionMedia(trafficDisruptionId) {
    if (this._currentPendingDelete.length)
      this.trafficDisruptionService
        .deleteMedia({
          trafficDisruptionId: trafficDisruptionId,
          removed: this._currentPendingDelete,
        })
        .subscribe(() => {
          this._currentPendingDelete = [];
        });
  }

  isResponsePlanDone(id: number, layerType: LayerType) {
    return this.responsePlanService.isResponsePlanDone(id, layerType as unknown as ResponsePlanEntityType);
  }
}
