<div class="map-draw-field-cont">
  <div class="map-draw-cont">
    <wc-static-map-viewer
      [accountCenterCoords]="accountCenterCoords"
      [draws]="geometryAsFeatureProps"></wc-static-map-viewer>
  </div>
  <a class="map-draw-button heap-desktop-map-draw-button" tabindex="0" (click)="openModal()">
    <span>
      {{ formFieldData?.label || '' | translate }}
      <i class="fas fa-chevron-right"></i>
    </span>
  </a>
</div>
