import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WcMapModule } from '@wc/wc-map/src/lib/wc-map.module';
import { MapDrawingModalComponent } from './map-drawing-modal.component';

@NgModule({
  declarations: [MapDrawingModalComponent],
  imports: [CommonModule, TranslateModule, WcMapModule],
  exports: [MapDrawingModalComponent],
})
export class MapDrawingModalModule {}
