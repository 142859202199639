import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { HeapAnalyticsService, LocalStorageService, TourService } from '@wc-core';
import { UsersStore } from '@wc/core';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { OnboardingTourName, TourName } from '@wc/wc-core/src/lib/types/tour';

@Component({
  selector: 'wc-onboarding-tours',
  templateUrl: './onboarding-tours.component.html',
  styleUrls: ['./onboarding-tours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingToursComponent implements OnInit {
  @ViewChild('menuTrigger', { read: MatMenuTrigger }) menuTrigger!: MatMenuTrigger;
  @Input() buttonInsideUserMenu = false;
  @Output() onboardingStarted = new EventEmitter<void>();
  @Output() closeParent = new EventEmitter<void>();

  isMenuOpen = false;
  icons: Record<OnboardingTourName, string> = {
    [OnboardingTourName.LiveMapTour]: 'map-marked-alt',
    [OnboardingTourName.LayersPanelTour]: 'layers',
    [OnboardingTourName.DataHubTour]: 'data-hub',
    [OnboardingTourName.TransitTour]: 'bus',
    [OnboardingTourName.SettingsTour]: 'cog',
  };
  sentFeedbacks: Partial<Record<OnboardingTourName, boolean | null>> = {
    [OnboardingTourName.LiveMapTour]: false,
    [OnboardingTourName.LayersPanelTour]: false,
    [OnboardingTourName.DataHubTour]: false,
    [OnboardingTourName.TransitTour]: false,
    [OnboardingTourName.SettingsTour]: false,
  };
  availableOnboardingTours: (OnboardingTourName | TourName)[] = [];
  clientSideFeedbackMap: Partial<Record<OnboardingTourName, boolean | null>> = {
    [OnboardingTourName.DataHubTour]: null,
    [OnboardingTourName.LiveMapTour]: null,
    [OnboardingTourName.LayersPanelTour]: null,
    [OnboardingTourName.TransitTour]: null,
    [OnboardingTourName.SettingsTour]: null,
  };

  get onboardingTourNames() {
    return [...this.tourService.onboardingTourNames];
  }

  get sortedOnboardingTours() {
    return [...this.onboardingTourNames].sort((a, b) => {
      if (this.availableOnboardingTours.includes(a) && !this.availableOnboardingTours.includes(b)) {
        return -1;
      }

      if (!this.availableOnboardingTours.includes(a) && this.availableOnboardingTours.includes(b)) {
        return 1;
      }

      return 0;
    });
  }

  get authUser() {
    return this.usersStore.authUser;
  }

  get onboardingDonePercent() {
    return (1 - this.availableOnboardingTours.length / this.onboardingTourNames.length) * 100;
  }

  constructor(
    private readonly tourService: TourService,
    private readonly usersStore: UsersStore,
    private readonly localStorageService: LocalStorageService,
    private readonly heapService: HeapAnalyticsService,
    private readonly cdr: ChangeDetectorRef
  ) {
    const clientSideFeedbackMapLocalStorage = this.localStorageService.get(LocalStorageKeys.ClientSideFeedbackMap);
    if (clientSideFeedbackMapLocalStorage) {
      this.clientSideFeedbackMap = clientSideFeedbackMapLocalStorage;
    } else {
      this.localStorageService.set(LocalStorageKeys.ClientSideFeedbackMap, this.clientSideFeedbackMap);
    }

    this.sentFeedbacks = this.clientSideFeedbackMap;
  }

  ngOnInit(): void {
    this.setAvailableOnboardingTours();

    this.tourService.calledNextOnboardingTour.subscribe(() => {
      this.setAvailableOnboardingTours();
    });

    this.updateAvailableOnboardingTours();
  }

  setAvailableOnboardingTours() {
    this.availableOnboardingTours = [...this.tourService.availableOnboardingTours];
    this.cdr.markForCheck();
  }

  startOnboardingTour(tourName: TourName | OnboardingTourName) {
    this.menuTrigger.closeMenu();
    this.onboardingStarted.emit();
    this.tourService.beginTourAgain(tourName);
    this.heapService.trackUserSpecificAction('heap-desktop-onboarding-tour-started', { tourName });
  }

  openMenu() {
    this.menuTrigger.openMenu();
  }

  onFeedbackSent(feedback: boolean, tourName: TourName | OnboardingTourName) {
    this.sentFeedbacks[tourName] = feedback;
  }

  private updateAvailableOnboardingTours() {
    const localStorageOnboardingTours = this.localStorageService.get(LocalStorageKeys.AvailableOnboardingTours);
    if (localStorageOnboardingTours) {
      this.availableOnboardingTours = this.availableOnboardingTours.filter(tourName =>
        localStorageOnboardingTours.includes(tourName)
      );
      this.tourService.availableOnboardingTours = [...this.availableOnboardingTours];
    } else {
      this.tourService.availableOnboardingTours = [...this.onboardingTourNames];
      this.localStorageService.set(
        LocalStorageKeys.AvailableOnboardingTours,
        this.tourService.availableOnboardingTours
      );
    }

    this.cdr.markForCheck();
  }

  close() {
    this.menuTrigger.closeMenu();
    this.closeParent.emit();
  }
}
