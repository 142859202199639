import { LayerNameStyleConfigKeys } from '@wc/wc-models';

export const LayersZIndexConfig: Record<LayerNameStyleConfigKeys, number> = {
  /** Common */
  satellite: 2,
  traffic: 3,
  mile_markers: 4,
  workspace: 5,

  /** Weather */
  air: 6,
  floods: 6,
  fog: 6,
  marine: 6,
  specific_road_conditions: 6,
  temperature: 6,
  thunderstorms: 6,
  tornado: 6,
  tropical: 6,
  wind: 6,
  winter: 6,
  fire: 6,
  'WEATHER_ALERT-weather_alert': 6, // Not in use

  /** Traffic Disruption */
  'CONSTRUCTION-construction': 7,
  'ROAD_CLOSURE-road_closure': 7,
  'SPECIAL_EVENT-special_event': 7,

  /** Cameras and DMSs */
  'CAMERA-camera': 8,
  'DMS-dms': 8,

  /** Transit */
  transit_fixed_routes: 7,
  transit_fixed_stops: 8,
  transit_fixed_route_bus_unit: 9,
  transit_demand_response_unit: 9,
  transit_on_demand_unit: 9,

  /** Units */
  fsp_unit: 9,
  city_police_unit: 9,
  highway_police_unit: 9,
  fire_department_unit: 9,
  construction_unit: 9,
  ems_unit: 9,
  maintenance_unit: 9,
  street_sweep_unit: 9,
  snow_plow_unit: 9,
  other_unit: 9,

  /** Congestions, Crash Risk, Roadway Status */
  'CONGESTION-congestion': 9,
  'IRREGULAR_CONGESTION-irregular_congestion': 9,
  'ROADWAY_STATUS-roadway_status': 9,
  'CRASH_RISK_AREA-crash_risk_area': 9,

  /** Incidents */
  debris: 10,
  crash: 10,
  vehicle_on_fire: 10,
  hazard: 10,
  stalled_vehicle: 10,
  ems: 10,
  police_activity: 10,
  left_on_arrival: 10,
  abandoned_vehicle: 10,
  damage: 10,
  traffic_stop: 10,
  wrong_way: 11,
  other: 10,
  unidentified: 10,
  unknown_incident_type: 10,
  traffic_anomaly: 10,

  /** Other */
  draw: 99999,
  interactive: 999999,
  segment_line: 10,
};
