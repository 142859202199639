/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { TransitRouteStoreEntity, TransitRouteUIEntity } from '@wc/wc-models/src';
import { map } from 'rxjs/operators';
import { EntitiesServiceV2 } from '../../services/entities.service';

export interface TransitRoutesState extends EntityState<TransitRouteStoreEntity, number> {}
export interface TransitRoutesUIState extends EntityState<TransitRouteUIEntity> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'transit_routes' })
export class TransitRoutesStore extends EntityStore<TransitRoutesState> {
  ui!: EntityUIStore<TransitRoutesUIState>;
  constructor(entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$.pipe(map(({ TRANSIT_ROUTE }) => TRANSIT_ROUTE || [])).subscribe(UIEntities => {
      UIEntities.forEach(routeUI => {
        this.ui.update(routeUI.id, route => {
          return {
            ...route,
            ...routeUI,
            busStopIds: Array.from(new Set([...(route.busStopIds || []), ...(routeUI.busStopIds || [])])),
          };
        });
      });
    });

    entitiesService.getDiff$
      .pipe(
        map(({ modified, removed }) => ({
          removedTransitRoutes: removed?.TRANSIT_ROUTE,
          modifiedTransitRoutes: modified.TRANSIT_ROUTE,
        }))
      )
      .subscribe(({ removedTransitRoutes, modifiedTransitRoutes }) => {
        if (modifiedTransitRoutes) this.upsertMany(modifiedTransitRoutes as TransitRouteStoreEntity[]);
        if (removedTransitRoutes) this.remove(removedTransitRoutes);
      });

    entitiesService.activeEntityIds$.pipe(map(({ TRANSIT_ROUTE }) => TRANSIT_ROUTE || [])).subscribe(id => {
      this.ui.setActive(id);
      this.setActive(id);
    });
  }
}
