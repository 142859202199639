import { Injectable } from '@angular/core';
import { getDistance, isPointInPolygon, isPointWithinRadius } from 'geolib';
import { UUID } from '../../utils/utils';
import { Workspace } from '../models/gql.models';

export class MockGeoService {
  workspaces: Map<number, { latitude: number; longitude: number }[]> = new Map();

  setWorkspaces() {}
  latLngToCoordinatesArray() {
    return null;
  }
  coordinatesArrayToLatLng() {
    return null;
  }
  isOutOfWorkspaces() {
    return null;
  }
  isPointsWithinRadius() {
    return null;
  }
  workspacesByLocation() {
    return [];
  }
}

@Injectable({
  providedIn: 'root',
})
export class GeoService {
  workspaces: Map<string, { latitude: number; longitude: number }[]> = new Map();
  busStops: Map<number, { routesIds: number[] }[]> = new Map();
  fixedRoutes: Map<number, { busStopsIds: number[] }[]> = new Map();
  // TODO: Add mapping method for transit routes and bus stops

  setWorkspaces(workspaces: Workspace[]) {
    workspaces.forEach(workspace => {
      workspace.area.coordinates.forEach(multiPolygon => {
        multiPolygon.forEach(polygon => {
          const poly = polygon.map(coords => this.coordinatesArrayToLatLng(coords));
          const _id = `${workspace.id}_${UUID(1)}`;
          this.workspaces.set(_id, poly);
        });
      });
    });
  }

  latLngToCoordinatesArray(coords: { latitude: number; longitude: number }) {
    return [coords.longitude, coords.latitude];
  }

  coordinatesArrayToLatLng(coords: number[]) {
    return { latitude: coords[1], longitude: coords[0] };
  }

  isOutOfWorkspaces(coords: number[]) {
    const pointCoords = this.coordinatesArrayToLatLng(coords);
    let isOut = true;
    this.workspaces.forEach((polygon, id) => {
      if (isPointInPolygon(pointCoords, polygon)) isOut = false;
    });
    return isOut;
  }

  isPointsWithinRadius(point: number[], centerPoint: number[], radius: number) {
    return isPointWithinRadius(
      this.coordinatesArrayToLatLng(point),
      this.coordinatesArrayToLatLng(centerPoint),
      radius
    );
  }

  getPointsDistance(point1: number[], point2: number[]) {
    return getDistance(this.coordinatesArrayToLatLng(point1), this.coordinatesArrayToLatLng(point2));
  }

  workspacesByLocation(coords: number[]) {
    const pointCoords = this.coordinatesArrayToLatLng(coords);
    const workspacesIds: number[] = [];
    this.workspaces.forEach((polygon, id) => {
      if (isPointInPolygon(pointCoords, polygon)) workspacesIds.push(Number(id.split('_')[0]));
    });
    return workspacesIds;
  }
}
