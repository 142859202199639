import { Injectable } from '@angular/core';
import { CollaborationMessageType } from '@wc/core/models/collaboration';
import { WebSocketType } from '@wc/core/models/enums';
import {
  BusStopDetails,
  BusStopDetailsGQL,
  BusStopsGQL,
  FixedBusRouteDetailsGQL,
  FixedBusRoutesGQL,
  RerouteFixedBusRouteGQL,
} from '@wc/core/models/gql.models';
import { WebsocketService } from '@wc/core/services/websocketService';
import { formatDuration } from '@wc/utils';
import { wcCoordinate } from '@wc/wc-map-viewer/src';
import {
  BusStopLoad,
  EntitiesDiff,
  TrainDelay,
  TransitBusStopStoreEntity,
  TransitBusStopUIEntity,
  TransitBusStoreEntity,
  TransitLayerType,
  TransitRouteStoreEntity,
  TransitRouteUIEntity,
  UpdateTrackedBusRoutes,
} from '@wc/wc-models/src';
import { intersection as _intersection } from 'lodash';
import moment from 'moment';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AffectedRouteServicesQuery } from '../stores/transit/transit_affected_route_services.query';
import { TransitFixedRouteBusQuery } from '../stores/transit/transit_fixed_route_bus.query';
import { TransitRoutesQuery } from '../stores/transit/transit_routes.query';
import { AccountService } from './account.service';
import { AuthUserService } from './auth-user.service';
import { CustomRxOperatorsService } from './custom-rx-operators.service';
import { EntitiesServiceV2 } from './entities.service';
import { LiveMapFiltersService } from './live-map-filters.service';
import { LocalStorageKeys, LocalStorageService } from './local-storage.service';
@Injectable({
  providedIn: 'root',
})
export class TransitService {
  private trainDelays = new ReplaySubject<TrainDelay[]>();
  private _subscriptions: Subscription[] = [];
  public trainDelays$ = this.trainDelays.asObservable();
  private busLoadMap: Map<number, BusStopLoad> = new Map();

  constructor(
    private accountService: AccountService,
    private fixedBusRouteDetailsGQL: FixedBusRouteDetailsGQL,
    private fixedBusRoutesGQL: FixedBusRoutesGQL,
    private busStopsGQL: BusStopsGQL,
    private busStopDetailsGQL: BusStopDetailsGQL,
    private localStorageService: LocalStorageService,
    private rerouteFixedBusRouteGQL: RerouteFixedBusRouteGQL,
    private wsService: WebsocketService,
    private entitiesService: EntitiesServiceV2,
    private authUserService: AuthUserService,
    private customRxOperators: CustomRxOperatorsService,
    private affectedRouteServicesQuery: AffectedRouteServicesQuery,
    private transitRoutesQuery: TransitRoutesQuery,
    private liveMapFilters: LiveMapFiltersService,
    private transitFixedRouteBusQuery: TransitFixedRouteBusQuery
  ) {}

  get initLayersVisibility() {
    return this.localStorageService.get(LocalStorageKeys.TransitLayersVisibility);
  }

  get initRouteIdsVisibility() {
    return this.localStorageService.get(LocalStorageKeys.TransitRouteIdsVisibility);
  }

  init() {
    this.initWSSync();
    return this.loadRoutes();
  }

  updateStorEntitiesByLayerType<T>(layerType: TransitLayerType, entities: T) {
    this.entitiesService.emitNewEntitiesDiff({
      modified: {
        [layerType]: entities,
      },
    });
  }

  updateUIEntitiesByLayerType<T>(layerType: TransitLayerType, entities: T) {
    this.entitiesService.emitNewUIDiff({ [TransitLayerType[layerType]]: entities });
  }

  loadRoutes() {
    return this.getBusRoutes().pipe(
      tap(res => {
        this.updateStorEntitiesByLayerType<Partial<TransitRouteStoreEntity>[]>(TransitLayerType.TransitRoute, res);
      })
    );
  }

  setVisibleRoutes(routeIds: number[]) {
    if (!routeIds) return;
    const allShownRouteIds = this.transitRoutesQuery.getAllVisibleRoutesIdsFilteredByIsMyAccount(false);

    this.updateStorEntitiesByLayerType<TransitRouteStoreEntity[]>(
      TransitLayerType.TransitRoute,
      allShownRouteIds.map(id => ({ id, show: false })) as TransitRouteStoreEntity[]
    );

    this.updateStorEntitiesByLayerType<TransitRouteStoreEntity[]>(
      TransitLayerType.TransitRoute,
      routeIds.map(id => ({ id, show: true })) as TransitRouteStoreEntity[]
    );
    /*
      Need to remember that shown routes are getting filtered in the live-map-filters.service by 'isMyAccount'.
     */

    const visibleRouteIds = this.transitRoutesQuery.getAllVisibleRoutesIdsFilteredByIsMyAccount(
      !!this.liveMapFilters.currentFilters.transitIsMyAgency
    );

    this.updateFixRouteBusesVisibility(
      false,
      this.transitFixedRouteBusQuery.getAll().map(bus => bus.id)
    );
    const visibleBusesIds = this.transitRoutesQuery.getAllVisibleRoutesBusIdsFilteredByIsMyAccount(
      !!this.liveMapFilters.currentFilters.transitIsMyAgency
    );
    this.updateFixRouteBusesVisibility(true, visibleBusesIds);
    this.localStorageService.set(LocalStorageKeys.TransitRouteIdsVisibility, routeIds);
    this.wsUpdateSelectedRoutes(visibleRouteIds);
  }

  setRoutesAsActive(routeIds: number[]) {
    this.entitiesService.setUiEntitiesAsActive({ [TransitLayerType.TransitRoute]: routeIds });
  }

  clearRoutesActiveState() {
    this.entitiesService.setUiEntitiesAsActive({ [TransitLayerType.TransitRoute]: [] });
  }

  updateAffectedRouteParsedDuration(route: TransitRouteStoreEntity) {
    this.entitiesService.emitNewUIDiff({
      [TransitLayerType.TransitRoute]: [
        {
          id: route.id,
          parsedDuration: formatDuration(route.durationMinutes),
        },
      ],
    });
  }

  updateBusStopsVisibility(show: boolean, busStopIds: number[]) {
    this.updateStorEntitiesByLayerType<Partial<TransitBusStopStoreEntity>[]>(
      TransitLayerType.TransitBusStop,
      busStopIds.map(busStopId => ({
        id: busStopId,
        show,
      }))
    );
  }

  updateFixRouteBusesVisibility(show: boolean, busIds: number[]) {
    this.updateStorEntitiesByLayerType<Partial<TransitBusStoreEntity>[]>(
      TransitLayerType.TransitFixedRouteBus,
      busIds.map(busId => ({
        id: busId,
        show,
      }))
    );
  }

  updateTrainDelays(updatedTrainDelays: TrainDelay[]) {
    this.trainDelays.next(updatedTrainDelays);
  }

  //////////////////       WS       /////////////////

  initWSSync() {
    const sub = this.wsService
      .onMessage()
      .pipe(
        tap(res => {
          if (res.type === WebSocketType.routesActiveBusesUpdate) {
            const entitiesDiff: EntitiesDiff = res.updates.reduce(
              (prev, curr) => {
                return {
                  modified: {
                    [TransitLayerType.TransitFixedRouteBus]: prev.modified[
                      TransitLayerType.TransitFixedRouteBus
                    ].concat(
                      curr.activeBuses.map(
                        bus =>
                          ({
                            ...bus,
                            type: WebSocketType.transitFixedRouteButUnit,
                            layerType: TransitLayerType.TransitFixedRouteBus,
                            isMyAccount: bus.accountId === this.accountService.account.id,
                            routeId: curr.routeId,
                            show: this.liveMapFilters.currentFilters.transitIsMyAgency
                              ? this.transitRoutesQuery.getEntity(curr.routeId)?.isMyAccount
                              : true && !!this.transitRoutesQuery.getEntity(curr.routeId)?.show,
                            /**The WS response can be received after a route changed his "show" status so we still need to test if we need to show this bus*/

                            isRerouted: !!this.affectedRouteServicesQuery.getEntity(curr.routeId)?.isRerouted,
                          } as TransitBusStoreEntity)
                      )
                    ),
                  },
                  removed: {
                    [TransitLayerType.TransitFixedRouteBus]: prev.removed[TransitLayerType.TransitFixedRouteBus].concat(
                      curr.removedBuses
                    ),
                  },
                };
              },
              {
                modified: { [TransitLayerType.TransitFixedRouteBus]: [] },
                removed: { [TransitLayerType.TransitFixedRouteBus]: [] },
              } as EntitiesDiff
            );
            this.entitiesService.emitNewEntitiesDiff({
              modified: {
                [TransitLayerType.TransitFixedRouteBus]: entitiesDiff.modified.TRANSIT_FIXED_ROUTE_BUS?.filter(
                  (bus, index, buses) => {
                    /**
                     * This filter bypasses the duplicated active buses received by the WS,
                     * and filters the buses by the current active pattern using the ETA.
                     */
                    const nextBus = buses[index + 1];
                    if (nextBus?.id === bus.id) {
                      if (nextBus.eta < bus.eta) {
                        return false;
                      } else {
                        buses.splice(index + 1, 1);
                        return true;
                      }
                    }
                    return true;
                  }
                ),
              },
            });

            this.entitiesService.emitNewUIDiff({
              [TransitLayerType.TransitRoute]: res.updates.map(route => ({
                id: route.routeId,
                activeBusIds: route.activeBuses.map(bus => bus.id),
              })),
            });
          }
          // ) {
          //   this.updateAffectedServicesData(res as AllAffectedServiceUpdate);
          //   return undefined;
          // } else
          if (res.type === WebSocketType.busStopsLoadUpdate) {
            res.loads.forEach((load: BusStopLoad) => this.busLoadMap.set(load.busStopId, load));

            const stopsLoadDiff: { id: number; load: BusStopLoad }[] = [];
            this.busLoadMap.forEach((load, busStopId) => {
              stopsLoadDiff.push({
                id: busStopId,
                load: load,
              });
            });

            this.entitiesService.emitNewUIDiff({
              [TransitLayerType.TransitBusStop]: stopsLoadDiff,
            });
          }
          if (res.type === WebSocketType.trainDelays) {
            this.updateTrainDelays(res.trainDelays as TrainDelay[]);
          }
        })
      )
      .subscribe();

    this._subscriptions.push(sub);
  }

  clearSubscriptions() {
    this._subscriptions.forEach(sub => sub.unsubscribe());
    this._subscriptions = [];
  }

  /**
   * Updates list of the selected routes to receive back relevant buses every 15 sec.
   * All related data send and received via WebSockets (WS).
   * @param {Array<number>} routeIds List of routes (by IDs) which buses we want to follow.
   */
  private wsUpdateSelectedRoutes(routeIds: number[]) {
    if (routeIds) {
      const msg: UpdateTrackedBusRoutes = {
        trackedBusRoutes: routeIds,
        type: CollaborationMessageType.UPDATE_TRACKED_BUS_ROUTES,
      };

      this.wsService.send(msg);
    }
  }

  // <------------  API  ------------>

  getBusRoutes() {
    // used to be - this.localStorageService.get(LocalStorageKeys.TransitFixeRoutes)
    // but it caused LocalStorage to exceed Quota
    const transitData = undefined;
    if (transitData) {
      const transitDataLastUpdate = this.localStorageService.get(LocalStorageKeys.TransitDataLastUpdate);
      this.updateVisibilityRouteIds((transitData as TransitRouteStoreEntity[]).map(route => route.id));
      if (moment().isBefore(moment(transitDataLastUpdate).add(12, 'h'))) return of(transitData);
    }

    return this.fixedBusRoutesGQL.fetch().pipe(
      map<any, TransitRouteStoreEntity[]>(res =>
        res.data.fixedBusRoutes.map(route => ({
          ...route,
          type: 'transit_fixed_routes',
          layerType: TransitLayerType.TransitRoute,
          location: route.path,
          isMyAccount: route.accountId === this.accountService.account.id,
          show: false,
        }))
      ),
      tap(res => {
        // Caused LocalStorage to exceed Quota - disabled
        // this.localStorageService.set(LocalStorageKeys.TransitFixeRoutes, res);
        // this.localStorageService.set(LocalStorageKeys.TransitDataLastUpdate, new Date());
        this.updateVisibilityRouteIds(res.map(route => route.id));
      })
    );
  }

  updateVisibilityRouteIds(ids: number[]) {
    const currentId = this.localStorageService.get(LocalStorageKeys.TransitRouteIdsVisibility);
    this.localStorageService.set(LocalStorageKeys.TransitRouteIdsVisibility, _intersection(currentId, ids));
  }

  loadBusStopsByViewExtent(coordinates: wcCoordinate[], excludeStopIds: number[]) {
    this.getBusStops(coordinates, excludeStopIds).subscribe(busStops => {
      this.updateStorEntitiesByLayerType<Partial<TransitBusStopStoreEntity>[]>(
        TransitLayerType.TransitBusStop,
        busStops.map(busStop => ({
          ...busStop,
          id: busStop.busStopId,
          layerType: TransitLayerType.TransitBusStop,
          type: 'transit_fixed_stops',
          show: false,
        }))
      );

      this.entitiesService.emitNewUIDiff({
        [TransitLayerType.TransitBusStop]: busStops.map(stop => {
          const load = this.busLoadMap.get(stop.id);
          this.busLoadMap.delete(stop.id);
          return {
            id: stop.id,
            load: load,
            routeIds: stop.routes.map(route => route.routeId),
          } as TransitBusStopUIEntity;
        }),
        [TransitLayerType.TransitRoute]: busStops
          .reduce<TransitRouteUIEntity[]>((prev, curr) => {
            curr.routes.forEach(route => {
              prev[route.routeId]?.busStopIds
                ? prev[route.routeId].busStopIds?.push(curr.busStopId)
                : (prev[route.routeId] = {
                    id: route.routeId,
                    busStopIds: [curr.busStopId],
                  });
            });
            return prev;
          }, [])
          .filter(f => f),
      });
    });
  }

  // should be called on dragEnd, if zoomLevel is X
  getBusStops(coordinates, excludeStopIds) {
    return this.busStopsGQL
      .fetch({
        input: {
          area: {
            coordinates: [coordinates],
            type: 'Polygon',
          },
          excluded: excludeStopIds,
        },
      })
      .pipe(
        map(res =>
          res.data.busStops.map(busStop => ({
            ...busStop,
            id: busStop.busStopId,
            type: 'transit_bus_stops',
            layerType: TransitLayerType.TransitBusStop,
            show: true,
          }))
        )
      );
  }

  getRouteDetails(routeId: number) {
    return this.fixedBusRouteDetailsGQL.fetch({ id: routeId }).pipe(
      map(res => res.data.fixedBusRouteDetails),
      this.customRxOperators.catchGqlErrors()
    );
  }

  getBusStopDetails(busStopId: number) {
    return this.busStopDetailsGQL.fetch({ id: busStopId }).pipe(
      map<any, BusStopDetails[]>(res => res.data.busStopDetails),
      this.customRxOperators.catchGqlErrors()
    );
  }

  rerouteFixedBusRoute(id: number, reroute: boolean): Observable<boolean> {
    return this.rerouteFixedBusRouteGQL.mutate({ id: id, reroute: reroute }).pipe(
      map<any, boolean>(res => res.data?.rerouteFixedBusRoute),
      this.customRxOperators.catchGqlErrors()
    );
  }
}
