/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import BaseLayer from 'ol/layer/Base';
import { StaticLayerName } from '../../../enums';
import { StaticLayerOptionNames } from '../../../types';
import { StaticMapLayerButton } from '../../classes/static-map-layer-button';
import { WcMapViewerService } from '../../wc-map-viewer.service';

type mileMarkerNameOptions = StaticLayerOptionNames<StaticLayerName.mileMarkers>;
@Component({
  selector: 'wc-map-milemarker-button',
  templateUrl: './map-milemarker-button.component.html',
  styleUrls: ['../../../styles/_base-map-buttons-style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MapMilemarkerButtonComponent extends StaticMapLayerButton<BaseLayer[]> implements OnInit, OnDestroy {
  @Output() statusChanged = new EventEmitter<{ status: boolean; name: mileMarkerNameOptions }>();
  @Input() staticLayerOptionName!: mileMarkerNameOptions;
  @Input() set activeState(isActive: boolean) {
    if (isActive !== this.isActive) {
      setTimeout(() => this.buttonClick());
    }
  }

  isActive = false;

  constructor(mapViewerService: WcMapViewerService) {
    super(mapViewerService);
    this.visibilityChanged$.subscribe(status => {
      this.isActive = status;
      this.statusChanged.emit({ status: this.isActive, name: this.staticLayerOptionName });
    });
  }

  ngOnInit() {
    if (this.staticLayerOptionName) {
      this.initiateStaticLayerObservableByName(this.staticLayerOptionName);
    } else console.error('staticLayerOptionName was not provided');
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  buttonClick(): void {
    if (this.staticMapLayers) {
      this.staticMapLayers.forEach(layer => this.toggleLayerVisibility(layer));
    }
    this.staticMapLayers$.subscribe(layers => (this.staticMapLayers = layers));
  }
}
