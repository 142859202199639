<mat-slide-toggle
  [disabled]="ngControl?.control?.disabled || false"
  [checked]="ngControl?.value"
  matTooltipShowDelay="300"
  matTooltip="{{ tooltipText | translate }}"
  matTooltipClass="slide-toggle-tooltip-class"
  (click)="$event.stopPropagation()"
  [labelPosition]="formFieldData?.labelPosition || 'before'"
  (change)="changed($event.checked)">
  {{ formFieldData?.label || '' | translate }}
</mat-slide-toggle>
