import { LayerType } from '../../../../core/models/gql.models';

export enum LayerTreeEntities {
  layer = 'layer',
  group = 'group',
}

export enum EntityStyleStatusEnum {
  default = 'default',
  highlight = 'highlight',
  unassigned = 'unassigned',
  active = 'active',
  inactive = 'inactive',
  cluster = 'cluster',
  confirmed = 'confirmed',
  unconfirmed = 'unconfirmed',
  rejected = 'rejected',
  completed = 'completed',
  modify_geometry = 'modify_geometry',
  view_geometry = 'view_geometry',
  modify_geometry_pointer = 'modify_geometry_pointer',
  modify_pointer = 'modify_pointer',
  view_pointer = 'view_pointer',
  planned = 'planned',
  onbreak = 'onbreak',
  predicted = 'predicted',
  on_time = 'on_time',
  early = 'early',
  late = 'late',
  busy = 'busy',
  my_agency = 'my_agency',
  isRerouted = 'isRerouted',
  on_time_re_routed = 'on_time_re_routed',
  late_re_routed = 'on_time_re_routed',
  early_re_routed = 'on_time_re_routed',
  segment_line = 'segment_line',
}

export enum TooltipTypes {
  Incident = 'INCIDENT',
  RoadAsset = 'ROAD_ASSET',
  Unit = 'UNIT',
  Bus = 'BUS',
  Snowplow = 'SNOWPLOW',
  RoadClosure = 'ROAD_CLOSURE',
  Congestion = 'CONGESTION',
  IrregularCongestion = 'IRREGULAR_CONGESTION',
  CrashRiskArea = 'CRASH_RISK_AREA',
  WeatherEvent = 'WeatherAlert', //Support old live-map
  WeatherAlert = 'WeatherAlert',
  TransitUnit = 'TransitUnit',
  TransitFixedRoute = 'TransitFixedRoute',
  TransitFixedStop = 'TransitFixedStop',
  RoadwayStatus = 'ROADWAY_STATUS',
  SegmentLine = 'SEGMENT_LINE',
}

export enum AdditionalLayerTypesEnum {
  workSpace = 'workspace',
  segment_line = 'segment_line',
  unIdentified = 'unidentified',
  interactive = 'interactive',
  draw = 'draw',
  create = 'create',
}

export enum TransitSubTypeNames {
  transit_on_demand_unit = 'transit_on_demand_unit',
  transit_demand_response_unit = 'transit_demand_response_unit',
  transit_fixed_routes = 'transit_fixed_routes',
}

export const LiveMapLayerNames = {
  [LayerType.Incident]: {
    stalled_vehicle: 'INCIDENT-stalled_vehicle',
    abandoned_vehicle: 'INCIDENT-abandoned_vehicle',
    crash: 'INCIDENT-crash',
    left_on_arrival: 'INCIDENT-left_on_arrival',
    debris: 'INCIDENT-debris',
    hazard: 'INCIDENT-hazard',
    vehicle_on_fire: 'INCIDENT-vehicle_on_fire',
    police_activity: 'INCIDENT-police_activity',
    traffic_stop: 'INCIDENT-traffic_stop',
    ems: 'INCIDENT-ems',
    damage: 'INCIDENT-damage',
    wrong_way: 'INCIDENT-wrong_way',
    other: 'INCIDENT-other',
    unidentified: 'INCIDENT-unidentified',
    traffic_anomaly: 'INCIDENT-traffic_anomaly',
  },
  [LayerType.Unit]: {
    fsp_unit: 'UNIT-fsp_unit',
    highway_police_unit: 'UNIT-highway_police_unit',
    city_police_unit: 'UNIT-city_police_unit',
    fire_department_unit: 'UNIT-fire_department_unit',
    construction_unit: 'UNIT-construction_unit',
    ems_unit: 'UNIT-ems_unit',
    maintenance_unit: 'UNIT-maintenance_unit',
    street_sweep_unit: 'UNIT-street_sweep_unit',
    snow_plow_unit: 'UNIT-snow_plow_unit',
    other_unit: 'UNIT-other_unit',
  },
  congestions: {
    irregular_congestion: 'IRREGULAR_CONGESTION-irregular_congestion',
    congestion: 'CONGESTION-congestion',
  },
  trafficDisruptions: {
    [LayerType.RoadClosure]: 'ROAD_CLOSURE-road_closure',
    [LayerType.Construction]: 'CONSTRUCTION-construction',
    [LayerType.SpecialEvent]: 'SPECIAL_EVENT-special_event',
  },
  [LayerType.Congestion]: 'CONGESTION-congestion',
  [LayerType.IrregularCongestion]: 'IRREGULAR_CONGESTION-irregular_congestion',
  [LayerType.SpecialEvent]: 'SPECIAL_EVENT-special_event',
  [LayerType.Construction]: 'CONSTRUCTION-construction',
  [LayerType.RoadClosure]: 'ROAD_CLOSURE-road_closure',
  [LayerType.WeatherAlert]: {
    air: 'WEATHER_ALERT-air',
    fire: 'WEATHER_ALERT-fire',
    floods: 'WEATHER_ALERT-floods',
    fog: 'WEATHER_ALERT-fog',
    marine: 'WEATHER_ALERT-marine',
    specific_road_conditions: 'WEATHER_ALERT-specific_road_conditions',
    temperature: 'WEATHER_ALERT-temperature',
    thunderstorms: 'WEATHER_ALERT-thunderstorms',
    tornado: 'WEATHER_ALERT-tornado',
    tropical: 'WEATHER_ALERT-tropical',
    wind: 'WEATHER_ALERT-wind',
    winter: 'WEATHER_ALERT-winter',
  },
  [LayerType.Camera]: 'CAMERA-camera',
  [LayerType.Dms]: 'DMS-dms',
  [LayerType.RoadwayStatus]: 'ROADWAY_STATUS-roadway_status',
  [LayerType.CrashRiskArea]: 'CRASH_RISK_AREA-crash_risk_area',
  transit: {
    transit_fixed_route_bus_unit: 'transit_fixed_route_bus_unit',
    transit_fixed_stops: 'transit_fixed_stops',
    transit_demand_response_unit: 'transit_demand_response_unit',
    transit_on_demand_unit: 'transit_on_demand_unit',
    transit_fixed_routes: 'transit_fixed_routes',
  },
  miscellanies: {
    workspace: 'workspace',
    unidentified: 'INCIDENT-unidentified',
    segment_line: 'segment_line',
  },
} as const;
