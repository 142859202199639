import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HeapAnalyticsService } from '@wc-core';
import { PermissionsFacadeService } from '@wc/permissions/domain/src';
import { AppTypeUnion } from '@wc/wc-models';
import { cloneDeep, throttle } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { APP_TYPE_TOKEN, PLATFORM_TOURS_TOKEN } from '../injection-tokens';
import {
  allOnboardingTourNames,
  DefaultStepOptions,
  OnboardingTourName,
  PartialStepButton,
  PublicTourApi,
  Step,
  StepButton,
  Tour,
  TourName,
} from '../types/tour';
import { LocalStorageKeys, LocalStorageService } from './local-storage.service';
import { CustomShepherdService } from './shepherd.service';

type tourButtonOptions = 'back' | 'cancel' | 'next' | 'final' | 'nextTour';

export const STEP_BUTTONS: Record<tourButtonOptions, PartialStepButton> = {
  back: {
    classes: 'back-button shepherd-left-arrow',
    type: 'back',
  },
  cancel: {
    classes: 'cancel-button',
    type: 'cancel',
  },
  next: {
    classes: 'next-button shepherd-right-arrow',
    type: 'next',
  },
  nextTour: {
    classes: 'cancel-button',
    type: 'complete',
  },
  final: {
    classes: 'final-button',
    type: 'finish',
  },
};

const DEFAULT_STEP_OPTIONS: DefaultStepOptions = {
  canClickTarget: false,
  classes: 'default-shepherd',
  modalOverlayOpeningPadding: 5,
  modalOverlayOpeningRadius: 10,
  useModalOverlay: true,
  scrollTo: true,
  cancelIcon: {
    enabled: true,
  },
  popperOptions: {
    modifiers: [
      { name: 'offset', options: { offset: [0, 20] } },
      {
        name: 'hide',
        enabled: false,
      },
    ],
  },
};

@Injectable({ providedIn: 'root' })
export class TourService implements PublicTourApi, OnDestroy {
  private routingEventSub: Subscription = new Subscription();
  private readonly renderer: Renderer2;
  private buttonsText: Record<tourButtonOptions, string> | null = null;
  private observers: Record<string, MutationObserver> = {};
  private isTourInProgress = false;
  private manuallyTriggeredTours: Partial<Record<TourName, boolean>> = {
    [TourName.WidgetNewUserTour]: true,
    [TourName.CompletedOnboardingTour]: true,
  };
  private currentStep: Step | null = null;
  private isLastStep = false;
  private visibleStepsByTour: Partial<Record<TourName | OnboardingTourName, Step[]>> = {};
  private buttonToActionMapper: Record<tourButtonOptions, () => void> = {
    next: () => this.nextAction(),
    back: () => this.backAction(),
    cancel: () => this.shepherdService.cancel(),
    final: () => this.shepherdService.complete(),
    nextTour: () => this.nextOnboardingTourAction(),
  };

  onboardingTourPermissionMap: Record<OnboardingTourName, boolean> = {
    [OnboardingTourName.LiveMapTour]: true,
    [OnboardingTourName.LayersPanelTour]: true,
    [OnboardingTourName.DataHubTour]: true,
    [OnboardingTourName.TransitTour]: false,
    [OnboardingTourName.SettingsTour]: false,
  };
  onboardingTourNames = [...allOnboardingTourNames];
  availableOnboardingTours = [...allOnboardingTourNames];
  calledNextOnboardingTour = new Subject();
  completedOnboarding = new BehaviorSubject(false);

  get visibleOnboardingTours() {
    return this.onboardingTourNames.filter(tourName => this.onboardingTourPermissionMap[tourName]);
  }

  get currentOnboardingTourIndex() {
    return this.visibleOnboardingTours.findIndex(tourName => tourName === this.shepherdService.tourName);
  }

  get isLastOnboardingTour() {
    return (
      (this.availableOnboardingTours.length === 1 &&
        this.visibleOnboardingTours.indexOf(this.availableOnboardingTours[0]) === this.currentOnboardingTourIndex) ||
      this.currentOnboardingTourIndex === this.visibleOnboardingTours.length - 1
    );
  }

  constructor(
    @Inject(PLATFORM_TOURS_TOKEN) private readonly platformTours: Record<TourName | OnboardingTourName, Tour>,
    private readonly shepherdService: CustomShepherdService,
    public readonly localStorageService: LocalStorageService,
    private readonly translateService: TranslateService,
    private readonly rendererFactory: RendererFactory2,
    private readonly heapService: HeapAnalyticsService,
    private readonly router: Router,
    private readonly permissionsFacadeService: PermissionsFacadeService,
    @Inject(APP_TYPE_TOKEN) private appType: AppTypeUnion
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.setCompletedOnboarding();
    this.setUserOnboardingPermissions();
    this.updateOnboardingTours();
    this.observeTours();
  }

  async beginTour(tourName: TourName | OnboardingTourName, activateIfCompleted = false) {
    if (activateIfCompleted || !this.isTourComplete(tourName)) {
      this.setup(tourName);

      try {
        this.isTourInProgress = true;
        const result = await this.runPreInitFunction(tourName);
        if (!result) return;
      } catch (e) {
        console.log(e);
      }

      this.start();
      this.isTourInProgress = true;
      this.trackTourAction(tourName, 'began');
      this.releaseObserverByTourName(tourName);
      this.onTourFinish(tourName, true);
    }
  }

  beginTourAgain(tourName: TourName | OnboardingTourName) {
    this.localStorageService.remove(`${tourName}-complete`);
    this.beginTour(tourName);
  }

  private updateOnboardingTours() {
    this.onboardingTourNames = this.onboardingTourNames.filter(tourName => this.onboardingTourPermissionMap[tourName]);
    this.availableOnboardingTours = [...this.onboardingTourNames];
  }

  private trackTourAction(tourName: TourName | OnboardingTourName, action: 'began' | 'cancelled' | 'completed') {
    this.heapService.trackUserSpecificAction(`heap-desktop-tour-${tourName}-${action}`);
  }

  private setButtonTranslations() {
    this.buttonsText = {
      back: this.translateService.instant('guidedTours.buttons.back'),
      next: this.translateService.instant('guidedTours.buttons.next'),
      cancel: this.translateService.instant('guidedTours.buttons.cancel'),
      nextTour: this.translateService.instant('guidedTours.buttons.nextOnboardingTour'),
      final: this.translateService.instant('guidedTours.buttons.final'),
    };
  }

  private setup(tourName: TourName | OnboardingTourName) {
    const steps: Step[] = this.platformTours[tourName].steps;
    this.visibleStepsByTour[tourName] = this.getAllVisibleSteps(steps, tourName);
    const stepsWithTranslations: Step[] = steps.map(step => ({
      ...step,
      title: this.translateService.instant(`guidedTours.${tourName}.${step.titleTranslationKey}`),
      text: step.textTranslationKey
        ? this.translateService.instant(`guidedTours.${tourName}.${step.textTranslationKey}`)
        : undefined,
    }));
    this.setButtonTranslations();
    this.initializeShepherdService(tourName, stepsWithTranslations);
  }

  private getAllVisibleSteps(steps: Step[], tourName: TourName | OnboardingTourName): Step[] {
    return (this.isOnboardingTour(tourName) ? steps : steps.filter(step => this.isVisibleStep(step))).filter(
      step => !step.permission || this.permissionsFacadeService.hasPermission(step.permission)
    );
  }

  private start() {
    setTimeout(() => {
      window.addEventListener('keydown', this.handleArrowKeys);
      this.shepherdService.start();
    });
  }

  private async runPreInitFunction(tourName: TourName | OnboardingTourName): Promise<boolean> {
    const initFn = this.platformTours[tourName]?.preTourInitFunction;
    return (
      !initFn ||
      (await initFn({ tourService: this, router: this.router, localStorageService: this.localStorageService }))
    );
  }

  isTourComplete(tourName: TourName | OnboardingTourName): boolean {
    return (
      !this.isOnboardingTour(tourName) &&
      (this.localStorageService.get(`${tourName}-complete`) ||
        moment(this.platformTours[tourName].endDate).isBefore(moment()))
    );
  }

  private isOnboardingTour(tourName: TourName | OnboardingTourName): boolean {
    return allOnboardingTourNames.includes(tourName as OnboardingTourName);
  }

  private initializeShepherdService(tourName: TourName | OnboardingTourName, steps: Step[]) {
    this.shepherdService.modal = true;
    this.shepherdService.tourName = tourName;

    this.shepherdService.defaultStepOptions = this.getFinalConfiguration(tourName);
    this.shepherdService.onTourFinish = () => this.onTourFinish(tourName);
    this.setStepsShowOn(tourName, steps);
    this.shepherdService.addSteps(steps);
    this.shepherdService.steps = steps;
  }

  private onTourFinish(tourName: TourName | OnboardingTourName, manual?: boolean) {
    if (!tourName) return;

    if (!manual) {
      this.isTourInProgress = false;
      this.localStorageService.set(`${tourName}-complete`, true);
      if (this.isLastStep) {
        this.trackTourAction(tourName, 'completed');
        this.isLastStep = false;
      } else {
        this.trackTourAction(tourName, 'cancelled');
      }

      const viewedSteps = this.localStorageService.get(`${tourName}-viewedSteps`);
      if (viewedSteps) {
        const index = this.availableOnboardingTours.indexOf(tourName);
        if (index !== -1) {
          this.availableOnboardingTours.splice(this.availableOnboardingTours.indexOf(tourName), 1);
        }
        this.localStorageService.set(LocalStorageKeys.AvailableOnboardingTours, this.availableOnboardingTours);
      }

      this.trackAbandonedTour(tourName);
    }

    this.localStorageService.set(`${tourName}-complete`, true);
    window.removeEventListener('keydown', this.handleArrowKeys);
    this.setPlatformInteractions(true);

    if (this.isOnboardingTour(tourName)) {
      if (this.availableOnboardingTours.length === 0) {
        this.completedOnboarding.next(true);
        this.localStorageService.set(LocalStorageKeys.CompletedOnboarding, true);

        return;
      }
      this.calledNextOnboardingTour.next();
      this.localStorageService.set(LocalStorageKeys.AvailableOnboardingTours, this.availableOnboardingTours);
    }
  }

  // Returns true if the step should be shown (based of query selector)
  private setStepsShowOn(tourName: TourName | OnboardingTourName, steps: Step[]) {
    const list: Set<number> = new Set();

    steps.forEach((step, i) => {
      step.showOn = () => {
        const applicableButtons: tourButtonOptions[] = [];
        if (step.excludeByElements.some(selector => document.querySelector(selector))) {
          return false;
        }
        const isVisibleStep = this.isVisibleStep(step);
        if (isVisibleStep) {
          if (i === 0) this.setPlatformInteractions(false); // only for first visible step
          list.add(i);
          this.localStorageService.set(`${tourName}-viewedSteps`, Array.from(list));
        }
        // the order of pushing the buttons matter - it will determine the order on the DOM
        if (this.hasVisiblePastStep(i, steps)) applicableButtons.push('back');
        const showNext = this.hasVisibleFutureStep(i, steps);
        if (showNext) applicableButtons.push('next');

        // make sure that we only set last step once
        // so we do not override it when pressing the back button
        if (!this.isLastStep && !showNext) this.isLastStep = true;

        if (this.isLastStep) {
          applicableButtons.push('final');

          if (this.isOnboardingTour(tourName)) {
            if (!this.isLastOnboardingTour) {
              applicableButtons.push('nextTour');
              this.platformTours[tourName].availableButtons['final'] = false;
              this.platformTours[tourName].availableButtons['nextTour'] = true;
            } else {
              this.platformTours[tourName].availableButtons['final'] = true;
              this.platformTours[tourName].availableButtons['nextTour'] = false;
            }
          }
        }

        this.setStepButtons(tourName, step, applicableButtons);

        return isVisibleStep;
      };
    });
  }

  private hasVisibleFutureStep(currentIndex: number, steps: Step[]): boolean {
    for (let i = currentIndex + 1; i < steps.length; i++) {
      if (this.isVisibleStep(steps[i])) {
        return true;
      }
    }
    return false;
  }

  private hasVisiblePastStep(currentIndex: number, steps: Step[]): boolean {
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (this.isVisibleStep(steps[i])) {
        return true;
      }
    }
    return false;
  }

  private isVisibleStep(step: Step): boolean {
    const doesElementExist = !!document.querySelector(step.attachTo.element);
    const allIncludedElementVisible = this.areAllIncludedElementsInStepVisible(step);
    const someExcludedElementVisible = this.areSomeExcludedElementsInStepVisible(step);
    const visible = step.willBeVisible;

    return visible || (doesElementExist && allIncludedElementVisible && !someExcludedElementVisible);
  }

  private areAllIncludedElementsInStepVisible(step: Step): boolean {
    if (step.includeByElements.length > 0) {
      const includedElementVisible = step.includeByElements.every(selector => document.querySelector(selector));
      return includedElementVisible;
    }
    return true;
  }

  private areSomeExcludedElementsInStepVisible(step: Step): boolean {
    if (step.excludeByElements.length > 0) {
      const excludedElementVisible = step.excludeByElements.some(selector => document.querySelector(selector));
      return excludedElementVisible;
    }
    return false;
  }

  private setPlatformInteractions(isInteractive: boolean) {
    const targetElement = document.querySelector('wc-root') as HTMLElement;
    if (targetElement) {
      if (isInteractive) {
        this.renderer.removeStyle(targetElement, 'pointer-events');
      } else {
        this.renderer.setStyle(targetElement, 'pointer-events', 'none');
      }
    }
  }

  private getFinalConfiguration(tourName: TourName | OnboardingTourName) {
    const stepConfiguration = cloneDeep(DEFAULT_STEP_OPTIONS);
    stepConfiguration.when = {
      show: () => {
        if (this.visibleStepsByTour[tourName]?.length && this.visibleStepsByTour[tourName]!.length > 1)
          this.createAndAddStepper(tourName);
        this.addDataCyAttributes();
      },
    };
    return stepConfiguration;
  }

  private addDataCyAttributes() {
    const attributeName = 'data-cy-id';
    const stepElement = this.shepherdService.tourObject.getCurrentStep()?.getElement();
    if (!stepElement) return;
    stepElement.querySelector('.shepherd-title')?.setAttribute(attributeName, `${this.appType}-tour-title`);
    stepElement.querySelector('.shepherd-text')?.setAttribute(attributeName, `${this.appType}-tour-text`);
    stepElement.querySelector('.tour-stepper')?.setAttribute(attributeName, `${this.appType}-tour-stepper`);
    stepElement.querySelector('.next-button')?.setAttribute(attributeName, `${this.appType}-tour-next-btn`);
    stepElement.querySelector('.back-button')?.setAttribute(attributeName, `${this.appType}-tour-back-btn`);
    stepElement.querySelector('.shepherd-cancel-icon')?.setAttribute(attributeName, `${this.appType}-tour-close-btn`);
    stepElement.querySelector('.final-button')?.setAttribute(attributeName, `${this.appType}-tour-final-step-btn`);
    stepElement.querySelector('.cancel-button')?.setAttribute(attributeName, `${this.appType}-tour-complete-step-btn`);
  }

  private createAndAddStepper(tourName: TourName | OnboardingTourName) {
    const currentStep = this.shepherdService.tourObject.getCurrentStep();
    if (!currentStep) return;
    const currentStepElement = currentStep.getElement();
    if (!currentStepElement) return;
    const footer = currentStepElement?.querySelector('.shepherd-footer');
    const stepper = document.createElement('span');
    stepper.style['color'] = '#ffffffb3';
    stepper.style['position'] = 'absolute';
    stepper.style['left'] = '15px';
    stepper.style['bottom'] = '15px';
    stepper.style['fontFamily'] = 'Nunito Regular';
    stepper.style['font-size'] = '12px';
    stepper.classList.add('tour-stepper');

    stepper.innerText = `${
      (this.visibleStepsByTour[tourName]?.findIndex(
        ({ id }) => this.shepherdService.tourObject.getCurrentStep()?.id === id
      ) || 0) + 1
    } ${this.translateService.instant('of')} ${this.visibleStepsByTour[tourName]?.length}`;
    footer?.insertBefore(stepper, currentStepElement.querySelector('.shepherd-button'));
  }

  private createButton(
    button: PartialStepButton,
    buttonText: string,
    action: () => void,
    classes?: string
  ): StepButton {
    return {
      ...button,
      classes: classes || button.classes,
      text: buttonText,
      action,
    };
  }

  private setStepButtons(tourName: TourName | OnboardingTourName, step: Step, applicableButtons: tourButtonOptions[]) {
    if (!this.buttonsText) {
      console.error('Button translations must be provided');
      return;
    }
    this.currentStep = step;
    const _step = this.shepherdService.tourObject.getById(step.id);

    if (!_step) {
      throw new Error(`Something went wrong, step with id: ${step.id} could not be found`);
    }

    const buttons: StepButton[] = applicableButtons.reduce((acc: StepButton[], curr) => {
      if (this.platformTours[tourName].availableButtons[curr]) {
        acc.push(
          this.createButton(STEP_BUTTONS[curr], this.buttonsText?.[curr] as string, this.buttonToActionMapper[curr])
        );
      }
      return acc;
    }, [] as StepButton[]);
    _step.updateStepOptions({ buttons });
  }

  private nextOnboardingTourAction = () => {
    this.setPlatformInteractions(true);
    this.shepherdService.complete();
    const nextTourName = this.visibleOnboardingTours[this.currentOnboardingTourIndex + 1];
    if (nextTourName) {
      this.beginTourAgain(nextTourName);
    }
  };

  private backAction = () => {
    if (this.isLastStep) this.isLastStep = false;

    if (this.currentStep?.willBeVisible && this.currentStep.clickElPrev) {
      const el: HTMLButtonElement | null = document.querySelector(this.currentStep.clickElPrev);
      if (el) {
        el.click();
      }
    }
    setTimeout(() => {
      this.shepherdService.back();
    }, this.currentStep?.stepDelay || 0);
  };

  private nextAction = () => {
    if (this.currentStep?.rerouteOnNext) {
      this.router.navigate([this.currentStep.rerouteOnNext]).then(() => {
        setTimeout(() => {
          this.shepherdService.next();
        }, 500);
      });
    } else if (this.currentStep?.clickElNext) {
      const el: HTMLButtonElement | null = document.querySelector(this.currentStep.clickElNext);
      if (el) {
        el.click();
      }
    }

    setTimeout(() => {
      if (!this.isLastOnboardingTour && this.isLastStep && this.isOnboardingTour(this.shepherdService.tourName)) {
        this.nextOnboardingTourAction();
      } else if (this.isLastOnboardingTour && this.isLastStep) {
        this.shepherdService.complete();
        this.onTourFinish(this.shepherdService.tourName);
      } else {
        this.shepherdService.next();
      }
    }, this.currentStep?.stepDelay || 0);
  };

  private handleArrowKeys = throttle((e: KeyboardEvent) => {
    if (e.key === 'ArrowRight') {
      this.nextAction();
    } else if (e.key === 'ArrowLeft') {
      this.backAction();
    }
  }, 250);

  private observeTours() {
    if (Object.keys(this.platformTours).length > 0) {
      this.routingEventSub = this.router.events.subscribe(val => {
        if (val instanceof NavigationEnd) {
          this.releaseObservers();
          const availableTours = Object.keys(this.platformTours).filter(
            tourName =>
              !this.isTourComplete(tourName as TourName) &&
              !this.manuallyTriggeredTours[tourName] &&
              !this.isOnboardingTour(tourName as OnboardingTourName)
          ) as TourName[];

          if (availableTours.length === 0) {
            this.routingEventSub.unsubscribe();
          } else {
            availableTours.forEach(tourName => {
              const tourSteps = this.platformTours[tourName].steps;
              const initialStep = tourSteps[0];
              if (initialStep && initialStep.containerElement) {
                const containerElement = initialStep.containerElement;
                setTimeout(() => {
                  this.observeTourContainer(containerElement, tourName);
                }, 0);
              }
            });
          }
        }
      });
    }
  }

  private observeTourContainer(containerSelector: string, tourName: TourName | OnboardingTourName) {
    const targetElement = document.querySelector(containerSelector);
    if (!targetElement || this.isTourInProgress) {
      return; // No target element found for the container class, no need to observe
    }

    const initialStep = this.platformTours[tourName].steps[0];
    if (
      targetElement.querySelector(initialStep.attachTo.element) &&
      this.areAllIncludedElementsInStepVisible(initialStep)
    ) {
      // If the target element already has the desired attached-to class, initiate the tour directly
      this.beginTour(tourName as TourName | OnboardingTourName);
      return;
    }

    // Target container doesn't have the attached-to class - start observing
    const observer = this.createObserver(tourName);
    observer.observe(targetElement, { childList: true, subtree: true });
    this.observers[tourName] = observer;
  }

  private createObserver(tourName: TourName | OnboardingTourName) {
    return new MutationObserver(async mutationsList => {
      for (const mutation of mutationsList) {
        if (mutation.addedNodes?.length > 0) {
          const addedNodes = Array.from(mutation.addedNodes);
          const initialStep = this.platformTours[tourName].steps[0];
          const shouldBegin = await this.isMatchingStep(addedNodes, initialStep);
          if (shouldBegin) {
            this.beginTour(tourName as TourName | OnboardingTourName);
            break;
          }
        }
      }
    });
  }

  private async isMatchingStep(addedNodes: Node[], step: Step): Promise<boolean> {
    const attachToElement = step.attachTo.element;
    const isNode = addedNodes.some(node => node instanceof Element && node.matches(attachToElement));
    const stepDelay = step.stepDelay ? step.stepDelay : 0;
    let allIncludeElementsVisible = true;

    return new Promise(resolve => {
      setTimeout(() => {
        if (step.includeByElements.length > 0) {
          allIncludeElementsVisible = this.isVisibleStep(step);
        }

        return resolve(isNode || allIncludeElementsVisible);
      }, stepDelay);
    });
  }

  private releaseObservers() {
    for (const tourName in this.observers) {
      this.releaseObserverByTourName(tourName as TourName | OnboardingTourName);
    }
    this.observers = {};
  }

  private releaseObserverByTourName(tourName: TourName | OnboardingTourName) {
    const observer = this.observers[tourName];
    if (observer) {
      observer.disconnect();
      delete this.observers[tourName as TourName | OnboardingTourName];
    }
  }

  private setCompletedOnboarding() {
    const completedOnboarding = this.localStorageService.get(LocalStorageKeys.CompletedOnboarding);
    if (completedOnboarding) {
      this.completedOnboarding.next(completedOnboarding);
    }
  }

  private setUserOnboardingPermissions() {
    this.onboardingTourPermissionMap.DATA_HUB_TOUR = this.permissionsFacadeService.hasPermission(
      [
        'HISTORICAL_INCIDENT:READ',
        'HISTORICAL_SHIFT:READ',
        'HISTORICAL_TRAFFIC_DISRUPTION:READ',
        'DOMO_FSP_REPORT:READ',
        'DOMO_INCIDENT_REPORT:READ',
        'DOMO_TRANSIT_REPORT:READ',
      ],
      'OR'
    );
    this.onboardingTourPermissionMap.TRANSIT_TOUR = this.permissionsFacadeService.hasPermission('TRANSIT:READ');
    this.onboardingTourPermissionMap.SETTINGS_TOUR = this.permissionsFacadeService.hasPermission('ACCOUNT:UPDATE');
  }

  private trackAbandonedTour(tourName: TourName | OnboardingTourName) {
    const viewedSteps = this.localStorageService.get(`${tourName}-viewedSteps`);
    if (viewedSteps?.length && this.visibleStepsByTour[tourName]?.length) {
      if (viewedSteps.length < this.visibleStepsByTour[tourName]!.length) {
        const tour = this.platformTours[tourName];
        this.heapService.trackUserSpecificAction(`heap-desktop-tour-abandoned`, {
          tourName,
          stepTitle: this.translateService.instant(
            `guidedTours.${tourName}.${tour.steps[viewedSteps[viewedSteps.length - 1]].titleTranslationKey}`
          ),
          stepText: this.translateService.instant(
            `guidedTours.${tourName}.${tour.steps[viewedSteps[viewedSteps.length - 1]].textTranslationKey}`
          ),
          viewedStepsLength: viewedSteps.length,
          tourStepTotalCount: this.visibleStepsByTour[tourName]!.length,
        });
      }
    }
  }

  ngOnDestroy() {
    this.releaseObservers();
    this.routingEventSub.unsubscribe();
  }
}
