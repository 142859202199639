import { ElementRef, Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { FormFieldOption } from '../../../../../wc-ui/src/lib/base';
import { ExtraOptionValue } from '../autocomplete.component';

@Injectable()
export class AutocompleteMultiSelectionService<T extends FormFieldOption<unknown, unknown>> {
  inputRef?: ElementRef<HTMLInputElement>;
  selectedOptions: T[] = [];
  defaultViewModeCommaSeperated = true;
  allOptions: T = { displayName: 'all', value: 'ALL' } as T;

  constructor(private readonly translateService: TranslateService) {
    translateService.get('all').subscribe(val => (this.allOptions.displayName = val));
  }

  isSelected(option: T) {
    return this.selectedOptions.findIndex(_option => _option.displayName === option.displayName) !== -1;
  }

  onOptionSelected(
    { selectedEvent, selectedOption }: { selectedOption?: T; selectedEvent?: MatAutocompleteSelectedEvent },
    formControl: FormControl,
    clickEvent?: MouseEvent,
    options: T[] = [],
    loadedMore = false
  ) {
    clickEvent?.stopPropagation();
    this.inputRef?.nativeElement.focus();
    const shouldStopExecution = this.handleAllOptionSelected(
      selectedOption,
      formControl,
      clickEvent,
      options,
      loadedMore
    );
    if (shouldStopExecution) return;

    const selected = selectedEvent ? selectedEvent.option.value : selectedOption;
    const option = this.selectedOptions.find(option => option.value === selected.value);
    if (!option) {
      this.selectedOptions.push(selected);
      this.selectedOptions.sort((a, b) => (a.displayName || '').localeCompare(b.displayName || ''));
    } else this.removeOption(option);

    if (!clickEvent) {
      formControl.setValue('');
      this.resetMultiSelectInput();
    }
  }

  removeOption(option: T): void {
    const index = this.selectedOptions.indexOf(option);

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
    }
  }

  setInitialValues(values: string[], options: T[]) {
    const optionValues: string[] = values ?? [];
    const mappedOptions: T[] = optionValues.reduce((acc: T[], value) => {
      const option = options.find(opt => opt.value === value);
      if (option) {
        acc.push(option);
      }
      return acc;
    }, []);

    this.selectedOptions = mappedOptions;
  }

  focusMultiSelectionInput() {
    this.inputRef?.nativeElement.focus();
  }

  resetSelections() {
    this.selectedOptions = [];
    this.resetMultiSelectInput();
  }

  toggleAllOptions(allSelected: boolean, options: T[], event?: MouseEvent, loadedMore = false) {
    event?.stopPropagation();
    this.selectedOptions = allSelected
      ? []
      : options
          .map(option => ({ ...option, displayName: option.displayName ?? '' }))
          .filter(option => !!option.displayName)
          .filter(option => loadedMore || (!loadedMore && !option.hidden))
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
  }

  displayFn() {
    return this.selectedOptions.map(option => option.displayName).join(', ');
  }

  private handleAllOptionSelected(
    selectedOption: T | undefined,
    formControl: FormControl,
    clickEvent?: MouseEvent,
    options: T[] = [],
    loadedMore = false
  ): boolean {
    if (selectedOption?.value === ExtraOptionValue.All) {
      this.toggleAllOptions(
        loadedMore
          ? this.selectedOptions.length === options.length
          : this.selectedOptions.length === options.filter(option => !option.hidden).length,
        options,
        clickEvent,
        loadedMore
      );
      formControl.setValue('');
      this.resetMultiSelectInput();
      return true;
    } else return false;
  }

  private resetMultiSelectInput() {
    if (this.inputRef) {
      this.inputRef.nativeElement.value = '';
    }
  }
}
