/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconClassName } from '@wc/wc-models/src/lib/types/icon-class-names-type';
import { FormFieldOption } from '@wc/wc-ui/src/lib/base';

export type AutoCompleteOption<T extends any, D = Record<string, any>> = FormFieldOption<T, D> & {
  extraLineField?: { value: string; startIcon?: IconClassName; cssClass?: 'verified-info' };
  forceAsFirstOption?: boolean;
};

export type OptionSelectionEvent<T extends FormFieldOption<unknown, unknown>> = {
  clickEvent?: MouseEvent;
  selectedOption: T;
};

@Component({
  selector: 'wc-autocomplete-option',
  templateUrl: './autocomplete-option.component.html',
  styleUrls: ['./autocomplete-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteOptionComponent<T extends AutoCompleteOption<any, Record<string, any>>> {
  @Input() option = {} as T;
  @Input() indented = false;
  @Input() isMultiSelect = false;
  @Input() filledCheckbox = false;
  @Input() indeterminateCheckbox = false;
  @Input() isFreeText = false;
  @Output() optionClicked = new EventEmitter<OptionSelectionEvent<T>>();
}
