import { LayerType } from '@wc/core';
import { MapLayer } from '@wc/wc-map-viewer/src';
import { EntityState, LayerNameStyleConfigKeys, TransitLayerType } from '@wc/wc-models/src';
import { LayersZIndexConfig } from './layers-z-index.config';

const _transitLayersConfig: MapLayer<LayerNameStyleConfigKeys, EntityState> = {
  transit_fixed_routes: {
    zIndex: LayersZIndexConfig.transit_fixed_routes,
    isVisible: true,
    multiLineStringStatusStyle: {
      default: {
        default: [
          {
            shape: 'lineSolid',
            color: { sourceField: 'color' },
            width: 3,
            opacity: 1,
            label: {},
          },
        ],
        highlight: [{ shape: 'lineSolid', color: { sourceField: 'color' }, width: 6 }],
        hover: [{ shape: 'lineSolid', color: { sourceField: 'color' }, width: 6 }],

        selected: [{ shape: 'lineSolid', color: { sourceField: 'color' }, width: 6 }],
      },
    },
  },
};

export const TransitLayersConfig = Object.keys(_transitLayersConfig).reduce((acc, key) => {
  acc[`${TransitLayerType.TransitRoute}-${key}`] = _transitLayersConfig[key];
  return acc;
}, {});

const _transitBusStopsLayersConfig: MapLayer<LayerNameStyleConfigKeys, EntityState> = {
  transit_fixed_stops: {
    zIndex: LayersZIndexConfig.transit_fixed_stops,
    isVisible: true,
    minZoom: 14,
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'bus_stop' } }],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'bus_stop.hover', anchor: [0.5, 0.9] },
          },
        ],
        selected: [
          { shape: 'marker', icon: { iconName: 'bus_stop.selected' } },
          { shape: 'marker', icon: { iconName: 'bus_stop.selected' } },
        ],
      },
    },
  },
};

export const TransitBusStopsLayersConfig = Object.keys(_transitBusStopsLayersConfig).reduce((acc, key) => {
  acc[`${TransitLayerType.TransitBusStop}-${key}`] = _transitBusStopsLayersConfig[key];
  return acc;
}, {});

const _transitBusesLayersConfig: MapLayer<LayerNameStyleConfigKeys, EntityState> = {
  transit_fixed_route_bus_unit: {
    zIndex: LayersZIndexConfig.transit_fixed_route_bus_unit,
    isVisible: false,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: { iconName: 'green_arrow_4', anchor: [0.5, 2.3] },
          // },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time.hover',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: {
          //     iconName: 'transit_fixed_routes_on_time_direction_arrow.hover',
          //     anchor: [0.5, 0.5],
          //   },
          // },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time.selected',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: {
          //     iconName: 'transit_fixed_routes_on_time_direction_arrow.selected',
          //     anchor: [0.5, 0.5],
          //   },
          // },
        ],
      },
      on_time: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time',
              dynamicNamePrefixSrcFiled: 'rerouted',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: { iconName: 'green_arrow_4', anchor: [0.5, 2.3] },
          // },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time.hover',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: {
          //     iconName: 'transit_fixed_routes_on_time_direction_arrow.hover',
          //     anchor: [0.5, 0.5],
          //   },
          // },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time.selected',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: {
          //     iconName: 'transit_fixed_routes_on_time_direction_arrow.selected',
          //     anchor: [0.5, 0.5],
          //   },
          // },
        ],
      },
      early: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_early',
              dynamicNamePrefixSrcFiled: 'rerouted',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: { iconName: 'light_red_arrow', anchor: [0.5, 2.3] },
          // },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_early.hover',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: {
          //     iconName: 'transit_fixed_routes_early_direction_arrow.hover',
          //     anchor: [0.5, 0.5],
          //   },
          // },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_early.selected',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: {
          //     iconName: 'transit_fixed_routes_early_direction_arrow.selected',
          //     anchor: [0.5, 0.5],
          //   },
          // },
        ],
      },
      late: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_late',
              anchor: [0.5, 0.5],
              rotation: 90,
              dynamicNamePrefixSrcFiled: 'rerouted',
            },
          },
          // {
          //   shape: 'marker',
          //   icon: { iconName: 'dark_blue_arrow', anchor: [0.5, 2.3] },
          // },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_late.hover',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: {
          //     iconName: 'transit_fixed_routes_late_direction_arrow.hover',
          //     anchor: [0.5, 0.5],
          //   },
          // },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_late.selected',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: {
          //     iconName: 'transit_fixed_routes_late_direction_arrow.selected',
          //     anchor: [0.5, 0.5],
          //   },
          // },
        ],
      },
      on_time_re_routed: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'rerouted.transit_fixed_routes_on_time', anchor: [0.5, 0.5], rotation: 90 },
          },
          // {
          //   shape: 'marker',
          //   icon: { iconName: 'green_arrow_4', anchor: [0.5, 2.3] },
          // },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time.hover',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: {
          //     iconName: 'transit_fixed_routes_on_time_direction_arrow.hover',
          //     anchor: [0.5, 0.5],
          //   },
          // },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_on_time.selected',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: {
          //     iconName: 'transit_fixed_routes_on_time_direction_arrow.selected',
          //     anchor: [0.5, 0.5],
          //   },
          // },
        ],
      },
      early_re_routed: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'rerouted.transit_fixed_routes_early', anchor: [0.5, 0.5], rotation: 90 },
          },
          // {
          //   shape: 'marker',
          //   icon: { iconName: 'green_arrow_4', anchor: [0.5, 2.3] },
          // },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_early.hover',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: {
          //     iconName: 'transit_fixed_routes_early_direction_arrow.hover',
          //     anchor: [0.5, 0.5],
          //   },
          // },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_early.selected',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: {
          //     iconName: 'transit_fixed_routes_early_direction_arrow.selected',
          //     anchor: [0.5, 0.5],
          //   },
          // },
        ],
      },
      late_re_routed: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'rerouted.transit_fixed_routes_late', anchor: [0.5, 0.5], rotation: 90 },
          },
          // {
          //   shape: 'marker',
          //   icon: { iconName: 'green_arrow_4', anchor: [0.5, 2.3] },
          // },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_late.hover',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: {
          //     iconName: 'transit_fixed_routes_late_direction_arrow.hover',
          //     anchor: [0.5, 0.5],
          //   },
          // },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_fixed_routes_late.selected',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: {
          //     iconName: 'transit_fixed_routes_late_direction_arrow.selected',
          //     anchor: [0.5, 0.5],
          //   },
          // },
        ],
      },
    },
  },
};

export const TransitBusesLayersConfig = Object.keys(_transitBusesLayersConfig).reduce((acc, key) => {
  acc[`${TransitLayerType.TransitFixedRouteBus}-${key}`] = _transitBusesLayersConfig[key];
  return acc;
}, {});

const _transitUnitsConfig = {
  transit_demand_response_unit: {
    zIndex: LayersZIndexConfig.transit_demand_response_unit,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'transit_demand_response', anchor: [0.5, 0.5], rotation: 90 },
          },
          // {
          //   shape: 'marker',
          //   icon: { iconName: 'purple_arrow', anchor: [0.5, 2.3] },
          // },
        ],
        highlight: [
          {
            shape: 'marker',
            icon: { iconName: 'transit_demand_response.hover', anchor: [0.5, 0.5], rotation: 90 },
          },
          // {
          //   shape: 'marker',
          //   icon: { iconName: 'transit_demand_response_direction_arrow.hover', anchor: [0.5, 0.64] },
          // },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'transit_demand_response.hover', anchor: [0.5, 0.5], rotation: 90 },
          },
          // {
          //   shape: 'marker',
          //   icon: { iconName: 'transit_demand_response_direction_arrow.hover', anchor: [0.5, 0.64] },
          // },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'transit_demand_response.selected', anchor: [0.5, 0.5], rotation: 90 },
          },
          // {
          //   shape: 'marker',
          //   icon: { iconName: 'transit_demand_response_direction_arrow.selected', anchor: [0.5, 0.64] },
          // },
        ],
      },
    },
  },
  transit_on_demand_unit: {
    zIndex: LayersZIndexConfig.transit_on_demand_unit,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'transit_on_demand', anchor: [0.5, 0.5], rotation: 90 },
          },
          // {
          //   shape: 'marker',
          //   icon: { iconName: 'pink_arrow', anchor: [0.5, 2.3]},
          // },
        ],
        highlight: [
          {
            shape: 'marker',
            icon: { iconName: 'transit_on_demand.hover', anchor: [0.5, 0.5], rotation: 90 },
          },
          // {
          //   shape: 'marker',
          //   icon: { iconName: 'transit_on_demand_direction_arrow.hover', anchor: [0.5, 0.64] },
          // },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'transit_on_demand.hover', anchor: [0.5, 0.5], rotation: 90 },
          },
          // {
          //   shape: 'marker',
          //   icon: { iconName: 'transit_on_demand_direction_arrow.hover', anchor: [0.5, 0.64]},
          // },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'transit_on_demand.selected',
              anchor: [0.5, 0.5],
              rotation: 90,
            },
          },
          // {
          //   shape: 'marker',
          //   icon: {
          //     iconName: 'transit_on_demand_direction_arrow.selected',
          //     anchor: [0.5, 0.64],
          //   },
          // },
        ],
      },
    },
  },
};

export const TransitUnitsConfig = Object.keys(_transitUnitsConfig).reduce((acc, key) => {
  acc[`${LayerType.Unit}-${key}`] = _transitUnitsConfig[key];
  return acc;
}, {});
