<div class="duration-component" [class.tablet-mode]="isTabletMode">
  <mat-label class="field-label">{{ fieldData.label | translate }}</mat-label>
  <form [formGroup]="form" class="duration-row">
    <wc-numbers-only-field [formControl]="$any(form.get('hours'))" [fieldData]="durationFields.hours">
    </wc-numbers-only-field>
    <span class="duration-divider">:</span>
    <wc-numbers-only-field [formControl]="$any(form.get('minutes'))" [fieldData]="durationFields.minutes">
    </wc-numbers-only-field>
  </form>
</div>
