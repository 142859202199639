<div class="transit-panel-tab-content" *ngIf="busStopDetails as selectedBusStop">
  <div class="transit-panel-header">
    <span class="transit-route-view-title">
      <i class="fas fa-chevron-left" (click)="clickBack.emit(1)"></i>
      {{ selectedBusStop.displayId }} | {{ selectedBusStop.name }} |
      {{ selectedBusStop.id }}
    </span>
  </div>

  <div class="transit-secondary-panel">
    <div class="transit-sub-tab-content">
      <div class="bus-stop-section-title">
        {{ 'transit.routes' | translate }}
      </div>
      <div class="bus-stop-routes">
        <span class="bus-stop-route" *ngFor="let route of selectedBusStop?.routes">
          <i class="route-color-badge" [style.backgroundColor]="route.color"></i>
          <span>{{ route.number }}</span>
        </span>
      </div>

      <div class="bus-stop-section-title">
        {{ 'transit.nextArrivals' | translate }}
      </div>
      <ul class="bus-stop-active-buses-list" *ngIf="selectedBusStop?.activeBuses?.length; else noActiveBuses">
        <li class="bus-stop-active-bus-item" *ngFor="let bus of selectedBusStop?.activeBuses">
          <div class="stop-active-bus-data">
            <span class="bus-stop-active-bus-eta"> {{ bus.eta }} {{ 'timeUnits.min' | translate }} </span>

            <div class="bus-data-details">
              <div class="bus-stop-active-bus-title">
                <span class="bus-stop-route">
                  <i class="route-color-badge" [style.backgroundColor]="bus.route.color"></i>
                  <span>{{ bus.route.number }}</span>
                </span>

                <div *ngIf="isMyAgency(bus.accountId)">
                  <div class="my-agency-badge">
                    <i class="fas fa-briefcase"></i>
                    {{ 'transit.myAgency' | translate }}
                  </div>
                </div>
              </div>

              <div class="bus-status-and-agency">
                <span class="bus-stop-active-bus-id"> {{ 'types.Bus' | translate }} #{{ bus.displayId }} </span>

                <div
                  *ngIf="bus.status"
                  class="stop-active-bus-status active-bus-status-title"
                  [ngClass]="bus.status ? 'status-' + (bus.status | lowercase) : ''">
                  {{ 'busStatuses.' + bus.status | translate }}
                  {{ bus.statusInMinutes !== 0 ? convertToStringWithSign(bus.statusInMinutes) : bus.statusInMinutes }}
                  <i class="active-bus-status-icon" [ngClass]="'icon-' + bus.status | lowercase"></i>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <ng-template #noActiveBuses>
        <div class="no-active-buses">{{ 'NA' | translate }}</div>
      </ng-template>
    </div>
  </div>
</div>
