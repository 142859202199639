/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { EntityActions, EntityUIQuery, QueryEntity } from '@datorama/akita';
import { ModifiedEntities, RemovedEntities, UnitsStoreEntity } from '@wc/wc-models/src';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LayerType } from '../../../../../../core/models/gql.models';
import { UnitsState, UnitsStore, UnitsUIState } from './units.store';

@Injectable({ providedIn: 'root' })
export class UnitsQuery extends QueryEntity<UnitsState> {
  ui!: EntityUIQuery<UnitsUIState>;
  constructor(store: UnitsStore) {
    super(store);
    this.createUIQuery();
  }

  get modifiedUnits$(): Observable<ModifiedEntities> {
    return this.selectEntityAction([EntityActions.Update, EntityActions.Add]).pipe(
      map(({ ids }) => ids.map(id => this.getEntity(id)).filter(e => !!e) as UnitsStoreEntity[]),
      map(units => ({
        [LayerType.Unit]: units,
      }))
    );
  }

  get removedUnits$(): Observable<RemovedEntities> {
    return this.selectEntityAction([EntityActions.Remove]).pipe(map(({ ids }) => ({ [LayerType.Unit]: ids })));
  }
}
