import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'libs/core/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnitedCamera } from '@wc/wc-models/src/lib/types/camera';
import { UploadService, UrlUploadEntityEnum } from '@wc/wc-core/src/lib/services/upload.service';
import { MediaApiService } from '@wc/wc-core/src/lib/services/media-api.service';
import { MediaSource } from "@wc/core";

@Injectable({
  providedIn: 'root',
})
export class CamerasService {
  constructor(
    private http: HttpClient,
    private uploadService: UploadService,
    private mediaApiService: MediaApiService,
  ) {}

  getCameraImage(url: string) {
    return this.http.get(url, { responseType: 'blob' }).pipe(map(response => response));
  }

  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const observable = new Observable((observer: any) => {
      reader.onloadend = () => {
        observer.next(JSON.parse((reader as any).result));
        observer.complete();
      };
    });

    reader.readAsText(err.error);
    return observable;
  }

  buildImageUrl(camera: UnitedCamera): string {
    let imageUrl = camera.imageUrl;
    if (camera.externalId) {
      imageUrl = `${environment.imageServiceUrl}/street/image/${camera.externalId}`;
    }
    return `${imageUrl}?${new Date().getTime()}&quality=low`;
  }

  convertBase64ToFile(base64: string, filename: string) {
    const base64Data = base64.split(',')[1];
    const binaryString = window.atob(base64Data);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: 'image/png' });
    return new File([blob], filename, { type: 'image/png' });
  }

  uploadSnapshot(snapshot: File, entityType: UrlUploadEntityEnum, entityId: number) {
    this.mediaApiService.pendingUploadFiles.push(snapshot);
    return this.uploadService.addFilesToQueueAndUpload(entityType, entityId, MediaSource.UserSnapshot);
  }
}
