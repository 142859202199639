import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { WcTooltipModule } from '../../tooltip/tooltip.module';
import { CameraThumbnailModule } from '../camera-thumbnail/camera-thumbnail.module';
import { CameraSliderComponent } from './camera-slider.component';
import { WcIconModule } from '../../wc-icon/wc-icon.module';

@NgModule({
  imports: [FormsModule, CommonModule, CameraThumbnailModule, SwiperModule, TranslateModule, WcTooltipModule ,WcIconModule],
  exports: [CameraSliderComponent],
  declarations: [CameraSliderComponent],
  providers: [],
})
export class CameraSliderModule {}
