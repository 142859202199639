import { LayerType } from '@wc/core';
import {
  CustomStyleContextEnum,
  InteractionsEnum,
  LocationOptions,
  mapVendorName,
  StaticLayerName,
  wcCoordinateTypes,
  WcGeometryEnum,
  WcMapConfigModel,
} from '@wc/wc-map-viewer/src';
import {
  AdditionalLayerTypesEnum,
  EntityState,
  LayerNamesOptionsType,
  LayerPanelStoreItem,
  LayerPanelUIStoreItem,
  LiveMapLayerNames,
  TooltipTypes,
  TransitLayerType,
  TransitSubTypeNames,
} from '@wc/wc-models';
import { incidentsLayersConfig } from './incidents-style.config';
import { LayersZIndexConfig } from './layers-z-index.config';
import {
  createIncidentsLayerStoreItems,
  createLayerPanelStoreItems,
  createTDLayerStoreItems,
  getFlatTreeData,
} from './live-map-utils';
import { miscellaneousLayersConfig } from './miscellaneous-style.config';
import {
  TransitBusesLayersConfig,
  TransitBusStopsLayersConfig,
  TransitLayersConfig,
  TransitUnitsConfig,
} from './transit-style.config';
import { unitsLayersConfig } from './units-style.config';
import { WeatherLayersConfig } from './weather-style.config';

export const liveMapConfig: WcMapConfigModel<LayerNamesOptionsType, wcCoordinateTypes, EntityState> = {
  editFeatureOption: {
    showReference: true,
    editStyleArgs: {
      Point: [
        {
          shape: 'marker',
          icon: {
            src: 'assets/icons/map-cursor-icon.svg',
            scale: 1,
            anchor: [0.5, 0.5],
          },
        },
      ],
      LineString: [
        { shape: 'lineSolid', color: '#359edc', width: 3 },
        {
          shape: 'circleIcon',
          color: '#fff',
          radius: 6,
          stroke: { color: '#359edc', width: 2 },
        },
      ],
      Polygon: [
        { shape: 'area', color: '#359edc', opacity: 0.25 },
        { shape: 'lineSolid', color: '#359edc', width: 3 },
        {
          shape: 'circleIcon',
          color: '#fff',
          radius: 6,
          stroke: { color: '#359edc', width: 2 },
        },
      ],
    },
    referenceStyleArgs: {
      [WcGeometryEnum.POINT]: [
        {
          shape: 'circleIcon',
          color: '#0F1832',
          radius: 7,
          opacity: 0.4,
          stroke: { color: '#0F1832', width: 10, opacity: 0.2 },
        },
      ],
    },
  },
  customStyleContexts: [CustomStyleContextEnum.highlight],

  excludedLayerNamesPerInteraction: {
    select: ['workspace', 'segment_line'],
    hover: ['workspace'],
  },
  excludedLayerNamesForPolygonOverlays: ['WEATHER_ALERT', 'CRASH_RISK_AREA', 'CONGESTION', 'IRREGULAR_CONGESTION'],

  mapTheme: {
    themeName: 'light' as any,
    themeIcons: {
      path: '',
      json: '',
    },
    mapThemeUrl: '',
  },

  staticLayersZIndex: {
    [StaticLayerName.mapBoxTraffic]: LayersZIndexConfig.traffic,
    [StaticLayerName.mileMarkers]: LayersZIndexConfig.mile_markers,
    [StaticLayerName.satelliteMap]: LayersZIndexConfig.satellite,
  },

  defaultIconStyle: {
    anchor: [0.5, 1],
    scale: 0.6,
  },
  centerOptions: {
    zoomLevel: 10,
    duration: 1000,
    mapCenter: [-115.16660779512634, 36.123192863687095],
  },
  maxZoom: 21,
  interactions: [
    InteractionsEnum.select,
    InteractionsEnum.hover,
    InteractionsEnum.draw,
    InteractionsEnum.rightClick,
  ] as any, // Object.values(InteractionsEnum),
  layers: {
    ...unitsLayersConfig,
    ...TransitLayersConfig,
    ...TransitUnitsConfig,
    ...TransitBusStopsLayersConfig,
    ...TransitBusesLayersConfig,
    ...WeatherLayersConfig,
    ...incidentsLayersConfig,
    ...miscellaneousLayersConfig,
  },
  satelliteVendor: mapVendorName.MAPBOX,
};

export const liveMapTreePanelUIRepresentation: LayerPanelUIStoreItem[] = [
  {
    name: 'events',
    isMapLayer: false,
    cssClasses: ['root-level-node'],
    children: [
      {
        name: 'incidents',
        isMapLayer: false,
        children: createIncidentsLayerStoreItems(LiveMapLayerNames.INCIDENT),
        checked: false,
        cssClasses: ['border-bottom'],
        iconClass: 'other',
      },
      {
        name: 'traffic_disruptions',
        appFeatureName: ['ROAD_CLOSURE:READ', 'CONSTRUCTION:READ', 'SPECIAL_EVENT:READ'],
        checked: false,
        isMapLayer: false,
        children: createTDLayerStoreItems(),
        cssClasses: ['border-bottom'],
        iconClass: 'traffic_disruption',
      },
      {
        name: 'weather_events',
        appFeatureName: ['WEATHER:READ'],
        checked: false,
        isMapLayer: false,
        children: createLayerPanelStoreItems(LiveMapLayerNames.WEATHER_ALERT),
        cssClasses: ['border-bottom'],
        iconClass: 'weather',
      },
      {
        name: 'congestions',
        checked: false,
        isMapLayer: false,
        appFeatureName: ['CONGESTION:READ'],
        children: createLayerPanelStoreItems(LiveMapLayerNames.congestions),
        cssClasses: ['border-bottom'],
        iconClass: 'congestion',
      },
      {
        name: 'CRASH_RISK_AREA-crash_risk_area',
        checked: false,
        isMapLayer: true,
        children: null,
        appFeatureName: ['CRASH_RISK:READ'],
        cssClasses: ['stand-alone-layer'],
        iconClass: 'crash-risk',
      },
    ],
    checked: false,
  },

  {
    name: 'assets',
    checked: false,
    isMapLayer: false,
    cssClasses: ['root-level-node'],
    children: [
      {
        name: 'CAMERA-camera',
        checked: false,
        isMapLayer: true,
        children: null,
        appFeatureName: ['CAMERA:READ'],
        cssClasses: ['stand-alone-layer', 'border-bottom'],
        iconClass: 'camera',
      },
      {
        checked: false,
        name: 'ROADWAY_STATUS-roadway_status',
        appFeatureName: ['ROADWAY_STATUS_METRIC:READ'],
        children: null,
        isMapLayer: true,
        cssClasses: ['stand-alone-layer', 'border-bottom'],
        iconClass: 'roadway-status',
      },
      {
        name: 'DMS-dms',
        checked: false,
        isMapLayer: true,
        children: null,
        appFeatureName: ['DMS:READ'],
        cssClasses: ['stand-alone-layer', 'border-bottom'],
        iconClass: 'dms',
      },
      {
        name: 'units',
        isMapLayer: false,
        appFeatureName: ['UNIT:READ'],
        checked: false,
        children: createLayerPanelStoreItems(LiveMapLayerNames.UNIT),
        iconClass: 'units',
      },
    ],
  },
];

export const liveMapLayerStoreItems: LayerPanelStoreItem<LayerNamesOptionsType>[] = [
  ...getFlatTreeData(liveMapTreePanelUIRepresentation),
  ...createLayerPanelStoreItems(LiveMapLayerNames.transit),
  {
    name: 'workspace',
    checked: true,
    isMapLayer: true,
    isExcludedFromFilters: true,
    children: null,
  },
  {
    name: 'segment_line',
    checked: true,
    isMapLayer: true,
    isExcludedFromFilters: true,
    children: null,
  },
];

export enum SelectOperationsEnum {
  navigation = 'navigation',
  modal = 'modal',
  panel = 'panel',
}

type NavigationEntities =
  | `${LayerType.Incident}`
  | `${LayerType.RoadClosure}`
  | `${LayerType.Construction}`
  | `${LayerType.SpecialEvent}`;

type PanelEntities =
  | `${TransitSubTypeNames.transit_fixed_routes}`
  | TransitLayerType.TransitRoute
  | TransitLayerType.TransitBusStop
  | LayerType.RoadwayStatus;

export const entityTypeConfig: {
  [key in
    | `${LayerType}`
    | 'transit_units'
    | 'transit_buses'
    | AdditionalLayerTypesEnum
    | 'transit_stops'
    | TransitLayerType.TransitRoute
    | TransitLayerType.TransitFixedRouteBus
    | TransitLayerType.TransitBusStop]?: {
    url?: string;
    selectType: key extends NavigationEntities
      ? SelectOperationsEnum.navigation
      : key extends PanelEntities
      ? SelectOperationsEnum.panel
      : SelectOperationsEnum.modal;
    hoverType?: TooltipTypes;
  } & (key extends NavigationEntities
    ? { options?: never; mapCenterOnClick?: never }
    : { options: LocationOptions; mapCenterOnClick: 'firstCoordinate' | 'clickCoordinate' });
} = {
  [LayerType.Congestion]: {
    hoverType: TooltipTypes.Congestion,
    selectType: SelectOperationsEnum.modal,
    mapCenterOnClick: 'firstCoordinate',
    options: {
      offset: {
        y: -100,
      },
    },
  },
  [LayerType.IrregularCongestion]: {
    hoverType: TooltipTypes.IrregularCongestion,
    selectType: SelectOperationsEnum.modal,
    mapCenterOnClick: 'firstCoordinate',
    options: {
      offset: {
        y: -100,
      },
    },
  },
  [LayerType.Incident]: {
    url: `${LayerType.Incident}`,
    selectType: SelectOperationsEnum.navigation,
    hoverType: TooltipTypes.Incident,
  },
  [LayerType.RoadClosure]: {
    url: `traffic-disruption/${LayerType.RoadClosure}`,
    selectType: SelectOperationsEnum.navigation,
    hoverType: TooltipTypes.RoadClosure,
  },
  [LayerType.Construction]: {
    url: `traffic-disruption/${LayerType.Construction}`,
    selectType: SelectOperationsEnum.navigation,
    hoverType: TooltipTypes.RoadClosure,
  },
  [LayerType.SpecialEvent]: {
    url: `traffic-disruption/${LayerType.SpecialEvent}`,
    selectType: SelectOperationsEnum.navigation,
    hoverType: TooltipTypes.RoadClosure,
  },
  [LayerType.WeatherAlert]: {
    selectType: SelectOperationsEnum.modal,
    mapCenterOnClick: 'firstCoordinate',
    hoverType: TooltipTypes.WeatherAlert,
    options: {
      offset: { x: 0, y: 0 },
      duration: 1000,
    },
  },
  [LayerType.Unit]: {
    selectType: SelectOperationsEnum.modal,
    mapCenterOnClick: 'firstCoordinate',
    hoverType: TooltipTypes.Unit,
    options: {
      offset: { y: 0 },
    },
  },
  [LayerType.Camera]: {
    selectType: SelectOperationsEnum.modal,
    mapCenterOnClick: 'firstCoordinate',
    hoverType: TooltipTypes.RoadAsset,
    options: {
      offset: { y: -300 },
    },
  },
  [LayerType.Dms]: {
    selectType: SelectOperationsEnum.modal,
    mapCenterOnClick: 'firstCoordinate',
    hoverType: TooltipTypes.RoadAsset,
    options: {
      offset: { y: -100 },
    },
  },
  [LayerType.RoadwayStatus]: {
    selectType: SelectOperationsEnum.modal,
    mapCenterOnClick: 'clickCoordinate',
    hoverType: TooltipTypes.RoadwayStatus,
    options: {
      offset: {
        y: -200,
      },
      padding: [100, 500, 100, 100],
      duration: 800,
    },
  },
  [LayerType.CrashRiskArea]: {
    selectType: SelectOperationsEnum.modal,
    mapCenterOnClick: 'firstCoordinate',
    hoverType: TooltipTypes.CrashRiskArea,
    options: {
      offset: { y: -100 },
    },
  },
  [TransitLayerType.TransitRoute]: {
    selectType: SelectOperationsEnum.panel,
    mapCenterOnClick: 'firstCoordinate',
    options: {
      padding: [100, 500, 100, 100],
      duration: 800,
    },
    hoverType: TooltipTypes.TransitFixedRoute,
  },
  [TransitLayerType.TransitFixedRouteBus]: {
    selectType: SelectOperationsEnum.modal,
    mapCenterOnClick: 'firstCoordinate',
    hoverType: TooltipTypes.Bus,
    options: {
      offset: { y: 0 },
    },
  },
  [TransitLayerType.TransitBusStop]: {
    selectType: SelectOperationsEnum.panel,
    hoverType: TooltipTypes.TransitFixedStop,
    mapCenterOnClick: 'firstCoordinate',
    options: {
      offset: { y: 0 },
    },
  },
  [AdditionalLayerTypesEnum.segment_line]: {
    hoverType: TooltipTypes.SegmentLine,
    selectType: SelectOperationsEnum.modal,
    mapCenterOnClick: 'firstCoordinate',
    options: {},
  },
};
