import { Injectable } from '@angular/core';
import { Mutex } from 'async-mutex';
import PouchDB from 'pouchdb';

export enum DataBase {
  mapTiles,
  offlineCache,
  offlineUploads,
}

export interface CachedObject {
  object: any;
}

// For use in tests
export class MockCacheService {
  databaseMap: any;
  databaseAccessMutex: any;
  enabled!: boolean;

  constructor() {}

  disable() {}
  clearMapTilesCache() {}
  clearOfflineCache() {}
  async find(): Promise<PouchDB.Core.AllDocsResponse<CachedObject> | undefined> {
    return undefined;
  }
  async get() {}
  async put() {}
  async remove(): Promise<PouchDB.Core.Response | undefined> {
    return undefined;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  databaseMap: Map<string, PouchDB.Database>;
  databaseAccessMutex: Map<string, Mutex>;
  enabled: boolean = true;

  constructor() {
    this.databaseMap = new Map();
    this.databaseAccessMutex = new Map();
    for (const db in DataBase) {
      this.databaseMap.set(db, new PouchDB(db, { revs_limit: 1, auto_compaction: true }));
      this.databaseAccessMutex.set(db, new Mutex());
    }
  }

  disable() {
    this.enabled = false;
    for (const [dbName, db] of this.databaseMap) {
      db.destroy();
    }
  }

  clearMapTilesCache() {
    const mapTilesDB = this.databaseMap.get(DataBase[DataBase.mapTiles]);
    if (mapTilesDB) mapTilesDB.destroy();
  }

  clearOfflineCache() {
    this.databaseMap?.get(DataBase[DataBase.offlineCache])?.destroy();
    this.databaseMap?.get(DataBase[DataBase.offlineUploads])?.destroy();
  }

  async find(dbType: DataBase, keyPrefix: string) {
    if (!this.enabled) {
      return undefined;
    }

    const db = this.databaseMap.get(DataBase[dbType]);
    return db?.allDocs<CachedObject>({
      include_docs: true,
      attachments: true,
      binary: true,
      startkey: keyPrefix,
      endkey: keyPrefix + '\ufff0',
    });
  }

  async get(dbType: DataBase, key: string, getOptions?: PouchDB.Core.GetOptions) {
    if (!this.enabled) {
      return;
    }

    await this.databaseAccessMutex?.get(DataBase[dbType])?.acquire();

    const db = this.databaseMap.get(DataBase[dbType]);
    let cacheResponse;
    try {
      if (getOptions) {
        cacheResponse = await db?.get<CachedObject>(key, getOptions);
      } else {
        cacheResponse = await db?.get<CachedObject>(key);
      }
    } catch (e) {}

    this.databaseAccessMutex?.get(DataBase[dbType])?.release();

    return getOptions?.attachments ? cacheResponse : cacheResponse?.object;
  }

  async put(dbType: DataBase, key: string, object: any, attachments?: PouchDB.Core.Attachments) {
    return;
  }

  async remove(dbType: DataBase, _id: string, _rev: string): Promise<PouchDB.Core.Response | undefined> {
    const db = this.databaseMap.get(DataBase[dbType]);
    return db?.remove(_id, _rev);
  }
}
