<div class="container">
  <div class="text">
    <div class="text_title" [title]="addressTitle">{{ addressTitle }} ({{ direction | translate }})</div>
    <div class="text_content">
      {{ 'roadwayStatus.range' | translate }}:
      {{ range | rangeFormat: measureUnitType }}
      <span class="range-units">
        {{
          measureUnitType === measureUnitTypeEnum.Metric
            ? ('rangeUnits.metersShort' | translate)
            : ('rangeUnits.feetShort' | translate)
        }}
      </span>
    </div>
  </div>

  <i class="fas fa-chart-bar" *ngIf="showIcon"></i>
</div>
