<div
  class="textarea-control"
  [class.invalid-field]="formControl.invalid && formControl.touched && !hasFocus"
  [class.textarea-with-label]="fieldData.label"
  [class.tablet-mode]="isTabletMode">
  <label class="field-label" [for]="textarea" *ngIf="fieldData.label">
    {{ fieldData.label | translate }}
  </label>

  <div class="textarea-container">
    <span class="required-section" *ngIf="fieldData.required">*</span>
    <textarea
      class="textarea-field"
      data-cy-id="description-text-box"
      [class.tablet-mode]="isTabletMode"
      matInput
      #textarea
      [cols]="cols"
      [rows]="rows"
      [formControl]="$any(fieldFormControl)"
      (keyup)="changed()"
      (change)="changed()"
      [placeholder]="fieldData.placeholder | translate"
      data-focus
      [class.error-field-border]="validationError && formControl.touched"
      (blur)="_onTouched()"
      (focus)="_focused()"
      [readonly]="readonly">
    </textarea>
  </div>
  <mat-error *ngIf="validationError && formControl.touched">
    {{
      'validationErrorMessages.' + $any(validationError)
        | translate
          : {
              minLength: formControl.errors?.minlength?.requiredLength,
              maxLength: formControl.errors?.maxlength?.requiredLength
            }
    }}
  </mat-error>
</div>
