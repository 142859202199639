<mat-form-field
  [floatLabel]="formFieldData?.floatLabel || 'always'"
  [class.invalid-field]="ngControl && ngControl.invalid && ngControl.touched"
  [class.language-rtl]="isDirectionRtl"
  [class.tablet-mode]="isTabletMode">
  <mat-label class="field-label">
    <span class="required-section" *ngIf="formFieldData?.required">*</span>
    {{ formFieldData?.label || '' | translate }}
  </mat-label>
  <mat-select
    [errorStateMatcher]="matcher"
    [value]="ngControl?.value"
    [formControl]="$any(ngControl?.control || emptyControl)"
    (selectionChange)="onChanged($event.value)"
    (blur)="onTouched(); sendHeapTouch()"
    disableOptionCentering=""
    [placeholder]="formFieldData.placeholder || '' | translate"
    [panelClass]="[
      isTabletMode ? 'fixed-select-dropdown tablet-mode ' + dynamicClass : 'fixed-select-dropdown ' + dynamicClass,
      isDirectionRtl ? 'language-rtl' : ''
    ]"
    data-focus>
    <ng-container *ngIf="!_isLoadMoreView; else loadMode">
      <ng-container *ngIf="!useGrouping; else groupedOptionsTemplate">
        <mat-option *ngFor="let option of formFieldData?.options" [value]="option.value" [disabled]="option.disabled">
          {{ option.displayName || '' | translate }}
        </mat-option>
      </ng-container>

      <ng-template #groupedOptionsTemplate>
        <mat-optgroup *ngFor="let groupLabel of groupedOptions.labels" [label]="groupLabel | translate">
          <mat-option
            *ngFor="let option of groupedOptions.groupedOptions[groupLabel]"
            [value]="option.value"
            [disabled]="option.disabled">
            {{ option.displayName || '' | translate }}
          </mat-option>
        </mat-optgroup>
      </ng-template>
    </ng-container>

    <ng-template #loadMode>
      <mat-option *ngIf="ngControl?.value" [value]="ngControl?.value">
        {{ displayNameOfControlValue | translate }}
      </mat-option>
      <mat-option *ngFor="let option of visibleOptions" [value]="option.value" [disabled]="option.disabled">
        {{ option.displayName || '' | translate }}
      </mat-option>
      <div
        *ngIf="_showLoadMoreButton && hiddenOptions.length > 0"
        (click)="showMoreClicked()"
        class="select-load-more-row"
        [class.tablet-mode]="isTabletMode">
        {{ 'loadMore' | translate }}
      </div>
      <ng-container *ngIf="!_showLoadMoreButton">
        <hr class="select-component-hr" />
        <mat-option *ngFor="let option of hiddenOptions" [value]="option.value" [disabled]="option.disabled">
          {{ option.displayName || '' | translate }}
        </mat-option>
      </ng-container>
    </ng-template>
  </mat-select>
  <mat-error>
    {{ 'validationErrorMessages.' + errorMessage | translate }}
  </mat-error>
</mat-form-field>
