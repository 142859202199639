import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroupDirective, ValidatorFn, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { noWhitespaceValidator } from '@wc/features/ui/form-validators';
import { isValueInArray } from '@wc/wc-common/src/lib/validators';
import { FormFieldData } from '../../lib/base/custom-form-control';

@Component({
  selector: 'wc-checkbox-free-text-list',
  templateUrl: './checkbox-free-text-list.component.html',
  styleUrls: ['./checkbox-free-text-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxFreeTextListComponent {
  maxLengthValidator = 60;
  @Input() formFieldData: FormFieldData<unknown> = {};
  @Input() fixedControlName = '';
  @Input() freeTextControlName = '';

  constructor(private parentGroupDirective: FormGroupDirective, private fb: FormBuilder) {}

  get group() {
    return this.parentGroupDirective.form;
  }
  get formArray() {
    return this.parentGroupDirective.form.get(this.freeTextControlName) as FormArray;
  }

  addControl() {
    const validatorOrOpts: ValidatorFn[] = [
      Validators.required,
      RxwebValidators.unique(),
      noWhitespaceValidator(),
      isValueInArray(
        this.formFieldData.options?.map(x => {
          return x.displayName ? x.displayName.toLocaleLowerCase() : '_';
        })
      ),
    ];
    this.maxLengthValidator ? validatorOrOpts.push(Validators.maxLength(this.maxLengthValidator)) : null;
    this.formArray.push(
      this.fb.group({
        checked: true,
        value: this.fb.control(null, validatorOrOpts),
      })
    );
    this.formArray.markAllAsTouched();
  }

  removeControl(index) {
    this.formArray.removeAt(index);
    this.parentGroupDirective.form.markAsTouched();
    this.formArray.markAllAsTouched();
    this.formArray.controls.forEach(c => {
      c.get('value')?.updateValueAndValidity();
    });
  }
}
