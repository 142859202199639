import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MapTrafficButtonComponent } from './map-traffic-button.component';
import { WcTooltipModule } from "@wc/wc-ui/src/components/tooltip/tooltip.module";

@NgModule({
  declarations: [MapTrafficButtonComponent],
  imports: [CommonModule, TranslateModule, WcTooltipModule],
  exports: [MapTrafficButtonComponent],
})
export class MapTrafficButtonModule {}
