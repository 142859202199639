import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MediaThumbnailComponent } from './media-thumbnail.component';
import { MediaTitlePipe } from './media-title.pipe';

@NgModule({
  declarations: [MediaThumbnailComponent, MediaTitlePipe],
  imports: [CommonModule, TranslateModule],
  exports: [MediaThumbnailComponent],
})
export class MediaThumbnailModule {}
