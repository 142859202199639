<div class="publish-component">
  <div class="publish-component__header">
    <wc-icon data-cy-id="close-btn" iconName="close-new" (click)="close()"></wc-icon>
    <div data-cy-id="share-to-public-title" class="publish-component__header-title">
      {{ 'shareToPublic' | translate }}
    </div>
  </div>
  <from [formGroup]="form" class="publish-component__body">
    <div class="publish-component__source">
      <div class="publish-component__bold-title">
        <span class="required-section">*</span>
        <span> {{ 'selectPlatforms' | translate }}</span>
      </div>
      <wc-select-multi-boxes formControlName="destinations" [formFieldData]="fieldsData.destinations">
      </wc-select-multi-boxes>
    </div>
    <div class="publish-component__message">
      <div class="publish-component__bold-title">
        {{ 'messageDetails' | translate }}
      </div>
      <wc-multiple-chips [formFieldData]="fieldsData.messageLabels" formControlName="messageLabels">
      </wc-multiple-chips>

      <wc-text-area-control class="publish-text-area" [formControl]="$any(form.get('description'))">
      </wc-text-area-control>
      <div class="publish-component__description-len">
        {{ form.get('description')?.value.length || 0 }} {{ 'of' | translate }} {{ maxValue }}
        {{ 'characters' | translate }}
      </div>
    </div>
    <div *ngIf="showImageSection" class="publish-component__image-container">
      <div class="publish-component__image-title">
        <span> {{ 'image' | translate }} </span>
        <wc-icon [wcTooltipText]="'tooltip.publishImage' | translate" iconName="question-circle"></wc-icon>
      </div>
      <wc-media-section
        #mediaSection
        [supportedFileTypes]="supportedMediaTypes"
        [maxFileSize]="5"
        [isEditMode]="true"
        [enableHandelError]="false"
        [isOneFileLimit]="true"></wc-media-section>
    </div>
  </from>
  <div class="publish-component__footer">
    <button
      data-cy-id="share-to-public-publish-btn"
      [disabled]="form.invalid"
      rekor-flat
      buttonSize="medium"
      (click)="submit()">
      {{ 'send' | translate }}
    </button>
  </div>
</div>
