import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DmsViewerComponent } from './dms-viewer.component';


@NgModule({
  declarations: [DmsViewerComponent],
  imports: [CommonModule, TranslateModule],
  exports: [DmsViewerComponent],
})
export class DmsViewerModule {}
