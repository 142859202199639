<div
  [class.disabled]="(options.length === 0 && !optionsQuery && !_isFreeText) || isDisabled"
  [class.multi]="isMultiSelect"
  class="autocomplete-component">
  <mat-label *ngIf="showLabel && formFieldData?.label" class="autocomplete-component__label">
    <span class="required-section" *ngIf="formFieldData?.required">*</span>
    {{ formFieldData.label || '' | translate }}
  </mat-label>
  <mat-form-field
    (click)="triggerPanel()"
    appearance="outline"
    [floatLabel]="'never'"
    [class.clickable]="!isPanelOpened"
    class="autocomplete-component__form-field">
    <input
      [errorStateMatcher]="matcher"
      matInput
      #autocompleteInput
      [matAutocomplete]="auto"
      [class.clickable]="!isPanelOpened"
      class="autocomplete-component__input"
      [required]="!!formFieldData.required"
      [formControl]="autocompleteInputFormControl"
      [placeholder]="formFieldData.placeholder ?? '' | translate" />

    <!-- Selections Overlay -->
    <div
      class="autocomplete-component__multi-select-overlay-text"
      *ngIf="
        !isPanelOpened &&
        isMultiSelect &&
        formFieldData.label &&
        (!commaSeparatedMultiSelect || multiSelectOverlayText.length > 0)
      ">
      <span *ngIf="!commaSeparatedMultiSelect; else commaSeperated"
        >{{ formFieldData.label | translate }}
        <span
          *ngIf="options.length > 0"
          class="autocomplete-component__multi-select-overlay-selection-amount"
          data-cy-id="fixed-routes-number-textbox">
          ({{ selectedCount }} {{ 'of' | translate }} {{ displayedOptionsLength }})
        </span></span
      >
      <ng-template #commaSeperated
        ><span *ngIf="multiSelectOverlayText" class="comma-seperated-list">{{
          multiSelectOverlayText
        }}</span></ng-template
      >
    </div>

    <button
      data-cy-id="autocomplete-clear-button"
      [wcTooltipText]="'clear' | translate"
      [wcTooltipPlacement]="'bottom'"
      type="button"
      matSuffix
      mat-icon-button
      *ngIf="autocompleteInputFormControl.value && isPanelOpened"
      (click)="clearSelection($event)">
      <mat-icon>close</mat-icon>
    </button>

    <button type="button" matSuffix mat-icon-button matSuffix *ngIf="!isPanelOpened">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>

    <mat-autocomplete
      #auto="matAutocomplete"
      class="wc-autocomplete-panel"
      autoActiveFirstOption
      (opened)="setPanelAsOpened(true)"
      (closed)="setPanelAsOpened(false)"
      (optionSelected)="selectOption({ selectedOption: $event.option.value })"
      [displayWith]="displayFn">
      <!-- Free Text Option -->
      <div *ngIf="noResultsFound && _isFreeText && isFreeTextOptionVisible && !isMultiSelect">
        <div
          *wcLet="{
            displayName: autocompleteInputFormControl.value,
            value: autocompleteInputFormControl.value
          } as freeTextOption">
          <mat-option class="autocomplete-component__option" [value]="freeTextOption">
            <wc-autocomplete-option
              [isFreeText]="true"
              [option]="freeTextOption"
              (optionClicked)="optionClicked($event)"></wc-autocomplete-option>
          </mat-option>
        </div>
      </div>

      <!-- Show Select All Option -->
      <mat-option
        *ngIf="allOptions && isMultiSelect && !isFetchMode && showSelectAllOption && showExtraOptions"
        (mouseenter)="setActiveOption(allOptions)"
        [class.mat-selected]="false"
        class="autocomplete-component__option"
        [value]="allOptions">
        <wc-autocomplete-option
          [option]="allOptions"
          [isMultiSelect]="true"
          (optionClicked)="selectOption($event)"
          [filledCheckbox]="selectedCount === displayedOptionsLength"
          [indeterminateCheckbox]="
            isMultiSelect && selectedCount > 0 && selectedCount < displayedOptionsLength
          "></wc-autocomplete-option>
      </mat-option>

      <!-- Autocomplete Options -->
      <ng-container *ngIf="!isGrouped">
        <ng-container *ngFor="let option of regularOptions$ | async">
          <mat-option
            (mouseenter)="setActiveOption(option)"
            *ngIf="!hiddenOptions[option.value + ''] && !isFetching"
            [disabled]="!!option.disabled"
            class="autocomplete-component__option"
            [class.mat-selected]="
              (isMultiSelect && ngControl.value?.includes(option.value)) ||
              (!isMultiSelect && ngControl.value === option.value)
            "
            [value]="option">
            <wc-autocomplete-option
              [option]="option"
              [indented]="isMultiSelect && showSelectAllOption && showExtraOptions && !isFetchMode"
              [isMultiSelect]="isMultiSelect"
              (optionClicked)="optionClicked($event)"
              [filledCheckbox]="isMultiSelect && ngControl.value?.includes(option.value)"
              [isMultiSelect]="isMultiSelect"></wc-autocomplete-option>
          </mat-option>
        </ng-container>
      </ng-container>
      <!-- Selected fetched options -->
      <ng-container *ngIf="isFetchMode && !isFetching && isBelowThreshold && !filteredOptionsLength">
        <mat-option
          *ngFor="let option of multiSelectOptions"
          (mouseenter)="setActiveOption(option)"
          [disabled]="!!option.disabled"
          class="autocomplete-component__option"
          [class.mat-selected]="
            (isMultiSelect && ngControl.value?.includes(option.value)) ||
            (!isMultiSelect && ngControl.value === option.value)
          "
          [value]="option">
          <wc-autocomplete-option
            [option]="option"
            [isMultiSelect]="isMultiSelect"
            (optionClicked)="optionClicked($event)"
            [filledCheckbox]="isMultiSelect && ngControl.value?.includes(option.value)"
            [isMultiSelect]="isMultiSelect"></wc-autocomplete-option>
        </mat-option>
      </ng-container>

      <!-- Options with grouping -->
      <ng-container *ngIf="isGrouped">
        <ng-container *ngFor="let group of groupedOptions$ | async">
          <mat-optgroup
            *ngIf="!hiddenGroups[group.label] && !isFetching"
            class="autocomplete-component__option-group"
            [label]="group.label || 'OTHER' | translate">
            <ng-container *ngFor="let option of group.options">
              <mat-option
                (mouseenter)="setActiveOption(option)"
                *ngIf="!hiddenOptions[option.value + '']"
                [disabled]="!!option.disabled"
                class="autocomplete-component__option"
                [class.mat-selected]="
                  (isMultiSelect && ngControl.value?.includes(option.value)) ||
                  (!isMultiSelect && ngControl.value === option.value)
                "
                [class.multi]="isMultiSelect"
                [value]="option">
                <wc-autocomplete-option
                  [option]="option"
                  [isMultiSelect]="isMultiSelect"
                  [filledCheckbox]="isMultiSelect && ngControl.value?.includes(option.value)"
                  (optionClicked)="optionClicked($event)">
                </wc-autocomplete-option>
              </mat-option>
            </ng-container>
          </mat-optgroup>
        </ng-container>
      </ng-container>

      <!-- isBelowCharacterThreshold -->
      <mat-option
        [disabled]="true"
        class="autocomplete-component__option"
        *ngIf="isFetchMode && isBelowThreshold && !isFetching && (!isMultiSelect || multiSelectOptions.length === 0)">
        {{ 'optionEmptyState.threeCharacters' | translate: { amount: minSubstringLength } }}
      </mat-option>

      <!-- Loading state -->
      <mat-option [disabled]="true" class="autocomplete-component__option" *ngIf="isFetching">
        {{ 'loading' | translate }}
      </mat-option>

      <!-- No results found -->
      <mat-option
        [disabled]="true"
        class="autocomplete-component__option"
        *ngIf="
          noResultsFound && (!_isFreeText || isMultiSelect) && (!isFetchMode || (!isBelowThreshold && !isFetching))
        ">
        {{ 'noResultsFound' | translate }}
      </mat-option>

      <mat-option
        *ngIf="loadMoreOption && loadMoreState === 'ON' && !isFetchMode && showExtraOptions && showLoadMoreButton"
        (mouseenter)="setActiveOption(loadMoreOption)"
        class="autocomplete-component__option load-more"
        [value]="loadMoreOption">
        <button
          rekor-basic
          buttonSize="medium"
          class="autocomplete-component__load-more-button"
          (click)="selectOption({ selectedOption: loadMoreOption, clickEvent: $event })">
          {{ loadMoreOption.displayName }}
        </button>
      </mat-option>
    </mat-autocomplete>
    <mat-error>{{
      'validationErrorMessages.' + errorMessage
        | translate
          : {
              minLength: ngControl.errors?.minlength?.requiredLength,
              maxLength: ngControl.errors?.maxlength?.requiredLength
            }
    }}</mat-error>
    <mat-hint
      [attr.data-cy-id]="'alias-' + formFieldData.label + '-hint'"
      *ngIf="hint && !autocompleteInputFormControl?.touched">
      {{ hint }}</mat-hint
    >
  </mat-form-field>
</div>
