import { Pipe, PipeTransform } from '@angular/core';
import { MeasureUnitType } from '@wc/core/models/gql.models';

@Pipe({
  name: 'speedFormat',
})
export class SpeedFormatPipe implements PipeTransform {
  transform(value: number, unit: MeasureUnitType | undefined): string {
    if (!value) {
      value = 0;
    }

    return unit === MeasureUnitType.Metric
      ? value.toFixed(0).toString()
      : this.getMilesPerHour(value).toFixed(0).toString();
  }

  private getMilesPerHour(kmh: number) {
    return kmh / 1.609344;
  }
}
