import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Resolve,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RoadClosure } from '../models';
import { RoadClosureService } from '../services';
import { UiStore, UsersStore } from '../stores';

@Injectable({
  providedIn: 'root',
})
export class RoadClosureResolverService implements Resolve<RoadClosure>, CanLoad, CanActivate {
  roadClosureLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private usersStore: UsersStore,
    private roadClosureService: RoadClosureService,
    private router: Router,
    private uiStore: UiStore
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.usersStore.hasPermission('ROAD_CLOSURE:READ')) {
      this.router.navigate(['/live-map']);
      return false;
    } else {
      return true;
    }
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.roadClosureLoaded;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const id = route.paramMap.get('id');
    return this.roadClosureService.get(Number(id)).pipe(
      tap(roadClosure => {
        if (roadClosure) {
          return of(roadClosure);
        } else {
          // id not found
          this.router.navigate(['/live-map']);
          return EMPTY;
        }
      }),
      catchError(err => {
        this.uiStore.hideLoader('roadClosure');
        return throwError(err);
      })
    );
  }
}
