// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { LayerType } from '@wc/core';
import { LayerNamesOptionsType, LiveMapLayerNames } from '@wc/wc-models';
import { buffer, createEmpty, createOrUpdateFromCoordinate, extend, isEmpty } from 'ol/extent';
import Map from 'ol/Map';
import { fromLonLat, toLonLat, transform } from 'ol/proj';
import { Offset, wcCoordinate, wcCoordinateTypes } from '../types';

const [EPSG_1, EPSG_2] = ['EPSG:4326', 'EPSG:3857'];

export function RGBToHex(rgb) {
  // Choose correct separator
  const sep = rgb.indexOf(',') > -1 ? ',' : ' ';
  // Turn "rgb(r,g,b)" into [r,g,b]
  rgb = rgb.substr(4).split(')')[0].split(sep);

  let r = (+rgb[0]).toString(16),
    g = (+rgb[1]).toString(16),
    b = (+rgb[2]).toString(16);

  if (r.length == 1) r = '0' + r;
  if (g.length == 1) g = '0' + g;
  if (b.length == 1) b = '0' + b;

  return '#' + r + g + b;
}

export function hexAToRGBa(h, a) {
  let r = '0',
    g = '0',
    b = '0';

  // 3 digits
  if (h.length == 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits
  } else if (h.length == 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  r = r === '0x00' ? '0' : r;
  g = g === '0x00' ? '0' : g;
  b = b === '0x00' ? '0' : b;
  const prefix = a ? 'rgba' : 'rgb';
  const alpha = a ? a : '';
  return prefix + '(' + +r + ',' + +g + ',' + b + ',' + alpha + ')';
}

export function hexAToRGB(h): number[] {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length == 4) {
    r = parseInt('0x' + h[1] + h[1], 16);
    g = parseInt('0x' + h[2] + h[2], 16);
    b = parseInt('0x' + h[3] + h[3], 16);

    // 6 digits
  } else if (h.length == 7) {
    r = parseInt('0x' + h[1] + h[2], 16);
    g = parseInt('0x' + h[3] + h[4], 16);
    b = parseInt('0x' + h[5] + h[6], 16);
  }

  return [r, g, b];
}

export function coordsFormatFromLonLat(
  coordinates,
  type: string
): wcCoordinate | wcCoordinate[] | wcCoordinate[][] | wcCoordinate[][][] | undefined {
  let coords;

  switch (type) {
    case 'Point':
      coords = coordinates || [];
      return fromLonLat([coords[0], coords[1]]) as wcCoordinate;
    case 'LineString':
      coords = coordinates || [[]];
      return coords.map(coord => fromLonLat([coord[0], coord[1]])) as wcCoordinate;
    case 'Polygon':
      coords = coordinates || [[[]]];
      return coords.map(poly => poly.map(coord => fromLonLat([coord[0], coord[1]])));
    case 'MultiLineString':
      coords = coordinates || [[[]]];
      return coords.map(poly => poly.map(coord => fromLonLat([coord[0], coord[1]])));
    case 'MultiPolygon':
      coords = coordinates || [[[[]]]];
      return coords.map(poly => poly.map(poly => poly.map(coord => fromLonLat([coord[0], coord[1]]))));

    default:
      return undefined;
  }
}

export function isPointEqual(point1: number[], point2: number[]) {
  if (!point1 || !point2) {
    return false;
  } else if (point1[0] === point2[0] && point1[1] === point2[1]) {
    return true;
  }
  return false;
}

export function isValidLatLong(coords: unknown[]) {
  return coords.length === 2 && typeof coords[0] === 'number' && typeof coords[1] === 'number';
}

export function flatCoordinates(coordinates: unknown[]): wcCoordinate[] {
  const depth = calculateCoordinateDepth(coordinates);
  const flatCoords = depth === -1 ? [coordinates] : coordinates['flat'](depth);
  return flatCoords.some(coordinate => !isValidLatLong(coordinate as unknown[])) ? [] : (flatCoords as wcCoordinate[]);
}

export function createExtentFromCoordinate(coords: wcCoordinate[], bufferSize = 0) {
  const extent = createEmpty();

  coords.forEach((_coords: wcCoordinate) => {
    const flatCoord = fromLonLat(_coords);
    if (isEmpty(extent)) {
      createOrUpdateFromCoordinate(flatCoord, extent);
    } else {
      const newExtent = createEmpty();
      createOrUpdateFromCoordinate(flatCoord, newExtent);
      extend(extent, newExtent);
    }
  });
  return buffer(extent, bufferSize, extent);
}

export function getCoordinatesWithOffset(
  coordinates: wcCoordinate,
  offset: Offset,
  map: Map
): wcCoordinate | undefined {
  const transCoords = transform(coordinates, EPSG_1, EPSG_2);
  const pixel = map.getPixelFromCoordinate(transCoords);
  if (pixel) {
    const _offset = map.getCoordinateFromPixel([pixel[0] + (offset.x ?? 0), pixel[1] + (offset.y ?? 0)]);
    return transform(_offset, EPSG_2, EPSG_1);
  }

  return undefined;
}

export function coordsToLonLat<T extends wcCoordinateTypes>(coords: T) {
  if (!coords || !Array.isArray(coords)) return;
  const _map = array => array.map(value => (typeof value[0] === 'number' ? toLonLat(value) : _map(value)));
  return typeof coords[0] === 'number' ? toLonLat(coords as wcCoordinate) : _map(coords);
}

function calculateCoordinateDepth(coords: unknown[]): number {
  return getDepth(coords, 0);

  function getDepth(coords, depth: number) {
    if (Array.isArray(coords[0])) {
      return getDepth(coords[0], ++depth);
    }

    return depth - 1;
  }
}

export function isLayerMapNameValid(name: string): name is LayerNamesOptionsType {
  if (typeof name !== 'string') {
    throw new Error(`name must be of type string, type provided: ${typeof name}`);
  }

  if ((<string[]>Object.values(LiveMapLayerNames.miscellanies)).includes(name)) {
    return true;
  }

  const key = name.split('-')[0];
  if (key && LiveMapLayerNames[key]) {
    return LiveMapLayerNames[key] === name || Object.values(LiveMapLayerNames[key]).includes(name);
  }

  return false;
}

export function getColorFromScaleToHex(
  percentage: number,
  rgb1: number[] = [255, 0, 0],
  rgb2: number[] = [0, 255, 0],
  rgb3: number[] = [0, 0, 255]
) {
  if (percentage < 50) {
    return getColorBetweenRgb(percentage * 2, rgb1, rgb2);
  } else {
    return getColorBetweenRgb((percentage - 50) * 2, rgb2, rgb3);
  }
}

export function getColorBetweenRgb(percentage: number, rgb1: number[] = [255, 0, 0], rgb2: number[] = [0, 255, 0]) {
  const value = percentage / 100; // Convert percentage to a value between 0 and 1

  // Interpolate between the min and max colors
  const r = Math.round(rgb1[0] * (1 - value) + rgb2[0] * value);
  const g = Math.round(rgb1[1] * (1 - value) + rgb2[1] * value);
  const b = Math.round(rgb1[2] * (1 - value) + rgb2[2] * value);

  const hex = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1); // Convert RGB values to hex format

  return hex;
}

export function calculateAngle(startNode?: number[], nextNode?: number[], alwaysUp = false) {
  if (startNode && nextNode) {
    const x = startNode[0] - nextNode[0];
    const y = startNode[1] - nextNode[1];
    return alwaysUp ? Math.atan(x / y) : Math.atan2(x, y);
  }

  return 0;
}

export function getIdAndLayerTypeFromFeatureId(featureId: string): { layerType: LayerType; entityId: string } {
  const [layerType, entityId] = featureId.split('-') as [LayerType, string];
  return {
    layerType,
    entityId,
  };
}
