import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AffectedRouteServicesQuery, AffectedUnitServicesQuery, LiveMapService } from '@wc-core';
import {
  AffectingEventDetails,
  IncidentAffectingTransitAdditionalInfo,
  LayerType,
  LiveMapStore,
  TransitAffectingEntityType,
  UsersStore,
  WeatherAlertAffectingTransitAdditionalInfo,
} from '@wc/core';
import { isElInViewInsideContainer } from '@wc/utils';
import { CustomStyleContextEnum } from '@wc/wc-map-viewer';
import { AffectedServiceIUEntity, TransitLayerType } from '@wc/wc-models';
import { Observable, Subject, Subscription, combineLatest, timer } from 'rxjs';
import { debounceTime, map, take, tap } from 'rxjs/operators';
import { TransitAffectedServicesService } from './transit-affected-services.service';

@Component({
  selector: 'wc-affected-transit-services',
  templateUrl: './affected-transit-services.component.html',
  styleUrls: ['./affected-transit-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AffectedTransitServicesComponent implements OnDestroy {
  @Output() clickOnAffectedService = new EventEmitter<{ id: number; entityType: 'route' | 'unit' }>();
  @Output() clickOnRoute: EventEmitter<number> = new EventEmitter();
  @Output() clickOnUnit: EventEmitter<number> = new EventEmitter();
  @Output() affectedPanelOpened: EventEmitter<boolean> = new EventEmitter();
  @Input() isAffectedServicePanelOpened = false;

  currentHoverAffectedBus: number | undefined = undefined;
  currentHoverAffectedService: number | undefined = undefined;
  timerForClearance$ = timer(0, 60000).pipe(map(() => new Date()));

  notAllServiceVisibleMsg$ = new Subject<string | null>();
  fullAffectedServicesUIEntities$: Observable<AffectedServiceIUEntity[]> = combineLatest([
    this.affectedRouteServicesQuery.allAffectedServicesUIEntities$.asQuery,
    this.affectedUnitServicesQuery.allAffectedServicesUIEntities$.asQuery,
  ]).pipe(map(args => args.flat(1)));

  filteredAffectedServicesUIEntities$: Observable<AffectedServiceIUEntity[]> = combineLatest([
    this.affectedRouteServicesQuery.filteredServicesUIEntities$,
    this.affectedUnitServicesQuery.filteredServicesUIEntities$,
  ]).pipe(
    map(args => args.flat(1)),
    tap(filteredList => {
      this.fullAffectedServicesUIEntities$.pipe(take(1)).subscribe({
        next: lengthAllEntities => {
          // note: We  have a missing unit that is being filtered out, not sure why. id: 2046. need to check
          this.notAllServiceVisibleMsg$.next(
            lengthAllEntities.length > filteredList.length
              ? filteredList.length === 0
                ? 'transit.selectFixedRoutesMsgAll'
                : 'transit.selectFixedRoutesMsg'
              : null
          );
        },
      });
    })
  );

  private contentScrolled$ = new Subject();
  private currentViewedAffectedServices: Set<AffectedServiceIUEntity> = new Set();
  private subs: Subscription[] = [];

  constructor(
    public liveMapStore: LiveMapStore,
    public usersStore: UsersStore,
    private transitAffectedServices: TransitAffectedServicesService,
    private affectedRouteServicesQuery: AffectedRouteServicesQuery,
    private affectedUnitServicesQuery: AffectedUnitServicesQuery,
    private liveMapService: LiveMapService
  ) {
    this.subs.push(
      this.contentScrolled$.pipe(debounceTime(50)).subscribe(e => {
        this.checkAllForViewedElement();
      })
    );
  }

  checkAllForViewedElement() {
    this.filteredAffectedServicesUIEntities$
      .pipe(
        take(1),
        map(affectedServicesUI => affectedServicesUI.filter(affectedServiceUI => !affectedServiceUI.isViewed))
      )
      .subscribe(affectedServicesUI => {
        affectedServicesUI.forEach(affectedServiceUI => {
          const el: HTMLElement | null = document.getElementById(`${affectedServiceUI.id}`);
          const parent = el?.parentElement?.parentElement;
          if (el && parent && isElInViewInsideContainer(el, parent, 100)) {
            this.currentViewedAffectedServices.add(affectedServiceUI);
          }
        });
      });
  }

  onContainerScroll(e) {
    this.contentScrolled$.next();
  }

  affectedServiceClicked(id: number, entityType: 'route' | 'unit', event, entity): void {
    this.transitAffectedServices.selectedAffectedServiceUIEntity.next(entity);
    this.clickOnAffectedService.emit({ id, entityType });
    event?.stopPropagation();
  }

  getParsedAffectedServiceIconName(event: AffectingEventDetails) {
    switch (event.affectingEntityType) {
      case TransitAffectingEntityType.Incident:
        return (event.additionalInfo as IncidentAffectingTransitAdditionalInfo).incidentType.toLowerCase();
      case TransitAffectingEntityType.Construction:
        return 'construction';
      case TransitAffectingEntityType.IrregularCongestion:
        return 'congestion-irregular';
      case TransitAffectingEntityType.RoadClosure:
        return 'road_closure';
      case TransitAffectingEntityType.WeatherAlert:
        return `${(
          event.additionalInfo as WeatherAlertAffectingTransitAdditionalInfo
        ).weatherAlertSubtype.toLowerCase()}`;

      default:
        return 'other';
    }
  }

  highlightMapEntity(id, type?: 'route' | 'unit') {
    type === 'route' ? this.highlightRoute(id) : this.highlightBusIcon(id);
  }

  private highlightBusIcon(id: number | undefined) {
    this.currentHoverAffectedBus === id;
    this.liveMapService.setFeaturesWithStyleContext(LayerType.Unit, id ? [id] : [], CustomStyleContextEnum.highlight);
  }

  private highlightRoute(id: number | undefined) {
    this.liveMapService.setFeaturesWithStyleContext(
      TransitLayerType.TransitRoute,
      id ? [id] : [],
      CustomStyleContextEnum.highlight
    );
    this.currentHoverAffectedService = id;
  }

  markReroute($event, routeId) {
    this.transitAffectedServices.updateRerouteStatus($event.checked, routeId);
  }

  onPanelOpened() {
    this.affectedPanelOpened.emit(true);
    this.contentScrolled$.next();
  }

  ngOnDestroy() {
    this.transitAffectedServices.setAffectedServicesAsViewed(Array.from(this.currentViewedAffectedServices));
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
