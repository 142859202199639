import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { tooltipAdditionalInfo, UiStore, UnitMapInterface } from '@wc/core';
import { chipStyles } from '@wc/wc-models';

@Component({
  selector: 'wc-unit-dashcams-modal',
  templateUrl: './unit-dashcams-modal.component.html',
  styleUrls: ['./unit-dashcams-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitDashcamsModalComponent {
  @Input() unitDetails!: UnitMapInterface;
  @Input() unitResponse!: string;
  @Input() additionalInfo!: tooltipAdditionalInfo;
  @Output() openIncident: EventEmitter<string> = new EventEmitter();

  get ChipStyles(): typeof chipStyles {
    return chipStyles;
  }

  openIncidentEmit() {
    this.openIncident.emit(this.additionalInfo.incidentId);
  }
}
