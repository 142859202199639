import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { IconClassName, IconNames } from '@wc/wc-models/src/lib/types/icon-class-names-type';
@Component({
  selector: 'wc-icon',
  templateUrl: './wc-icon.component.html',
  styleUrls: ['./wc-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class WcIconComponent {
  name: IconClassName | 'no-icon-found' = 'no-icon-found';
  @Input() set iconName(name: IconClassName) {
    if (name && IconNames[name]) {
      this.name = name as IconClassName;
    } else {
      console.error('no icon was found with class: ', name);
    }
  }
}
