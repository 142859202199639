import { Inject, Injectable } from '@angular/core';
import { Coordinate } from 'ol/coordinate';
import { easeOut } from 'ol/easing';
import { Extent } from 'ol/extent';
import Map from 'ol/Map';
import { Pixel } from 'ol/pixel';
import { toLonLat } from 'ol/proj';
import { BehaviorSubject } from 'rxjs';
import { LocationOptions, MapCenterOptions, wcCoordinateTypes } from '../..';
import { wcMapToken } from '../../injection-tokens';
import { FitMapViewToExtentOptions, wcCoordinate } from '../../types';
import { createExtentFromCoordinate, getCoordinatesWithOffset } from '../utils';

@Injectable()
export class LocationService {
  private nextTargetMapCenter = new BehaviorSubject<wcCoordinateTypes | null>(null);
  public nextTargetMapCenter$ = this.nextTargetMapCenter.asObservable();
  constructor(@Inject(wcMapToken) private wcMap: Map) {}

  get nextTargetMapCenterValue() {
    return this.nextTargetMapCenter.getValue();
  }

  updateNextTargetMapCenter(target: wcCoordinateTypes) {
    this.nextTargetMapCenter.next(target);
  }
  setCenter(
    coords: wcCoordinate,
    mapCenterOptions: MapCenterOptions<wcCoordinateTypes>,
    options?: LocationOptions
  ): void {
    const currentZoom = this.wcMap.getView().getZoom();
    if (options?.offset) {
      this.wcMap.updateSize();
      coords = getCoordinatesWithOffset(coords, options.offset, this.wcMap) || coords;
    }

    this.wcMap.getView().fit(createExtentFromCoordinate([coords]), {
      size: this.wcMap.getSize(),
      padding: options?.padding,
      duration: options?.duration || mapCenterOptions.duration,
      maxZoom: options?.zoomLevel || currentZoom,
    });
  }

  fitMapViewToExtent(extent: Extent, options?: FitMapViewToExtentOptions): void {
    this.wcMap.getView().fit(extent, {
      size: this.wcMap.getSize(),
      padding: options?.padding,
      duration: options?.duration,
      easing: easeOut,
    });
  }

  getMapCenter(): wcCoordinate {
    return toLonLat(this.wcMap.getView().getCenter() || []);
  }

  getMapZoomLevel(): number {
    return this.wcMap?.getView().getZoom() || 0;
  }

  getCoordinateFromPixel(pixel: Pixel): Coordinate {
    return this.wcMap.getCoordinateFromPixel(pixel);
  }

  getPixelFromCoordinate(coordinate: wcCoordinate): Pixel {
    return this.wcMap.getPixelFromCoordinate(coordinate);
  }

  updateMapExtentWithItsDefaultBoundaries() {
    this.wcMap.getView().fit(this.wcMap.getView().getProjection().getExtent());
  }
}
