import { AbstractControl, ValidatorFn } from '@angular/forms';

export function ValidateAGreaterThanNumB(a: AbstractControl, b: AbstractControl, errorMsg: string): ValidatorFn {
  const errorObj = {};
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!a || !b) {
      return null;
    }

    if (a.value === null || a.value === '' || b.value === null || b.value === '') {
      return null;
    } else if (Number(b.value) > Number(a.value)) {
      errorObj[errorMsg] = true;
      return errorObj;
    }

    return null;
  };
}

/**
 * @returns ValidationError | null
 * @description checks if control value is not `null` | `[]`
 */
export function requiredArrayValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control && (control.value?.length === 0 || !control.value)) {
      return { required: true };
    }
    return null;
  };
}

/**
 *  checks if control is `array` with at least one item with none null `[key:value]`
 * @returns ValidationError | null
 * @params {string} [key] ,{any} validation type
 * @example [{name: null}] -> { required: true };
 * @example [{name: 'bob'}] -> null
 */
export function requiredArrayKeyValueValidator(key: string, validValue: true | 'length'): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control && control.value) {
      if (control.value?.length === 0) {
        return { required: true };
      }
      switch (validValue) {
        case 'length':
          return control.value.some(item => item[key] && item[key].length > 0) ? null : { required: true };

        case true:
          return control.value.some(item => item[key] === true) ? null : { required: true };

        default:
          break;
      }
    }

    return null;
  };
}
