import { Pipe, PipeTransform } from '@angular/core';
import { AccountStore } from '../../../core/stores/account.store';
import { TimeFormat } from '../../../core/models/gql.models';
import moment from 'moment';

@Pipe({
  name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
  constructor(
    private accountStore: AccountStore // private datePipe: DatePipe
  ) {}

  transform(time: any, ...args: string[]): string | void {
    if (!time) {
      return;
    }

    const timeFormat = this.accountStore.account.regionalSetting.timeFormat;

    if (timeFormat === TimeFormat.TwelveHours) {
      return moment(time).format('HH:mm A');
    } else {
      return moment(time).format('HH:mm');
    }
  }

  /*   transform(time: any, ...args: string[]): string {
    if (!time) { return; }

    const timeFormat = this.accountStore.account.regionalSetting.timeFormat;

    if (timeFormat === TimeFormat.TwelveHours) {
      const convertedTime = this.datePipe.transform(time, 'HH:mm');

      let hour: string | number = Number((convertedTime.split(':'))[0]);
      let min = (convertedTime.split(':'))[1];
      const part = hour > 12 ? 'PM' : 'AM';
      min = (min+'').length === 1 ? `0${min}` : min;
      hour = hour > 12 ? hour - 12 : hour;
      hour = (hour+'').length === 1 ? `0${hour}` : hour;

      return `${hour}:${min} ${part}`
    } else {
      return this.datePipe.transform(time, 'HH:mm');
    }
  } */
}
