import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WcChipModule } from '../wc-chip/wc-chip.module';
import { MapUnitTooltipComponent } from './map-unit-tooltip.component';

@NgModule({
  declarations: [MapUnitTooltipComponent],
  imports: [CommonModule, TranslateModule, WcChipModule],
  exports: [MapUnitTooltipComponent],
})
export class MapUnitTooltipModule {}
