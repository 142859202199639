<mat-form-field
  class="wc-autocomplete-wrapper"
  (click)="scrollToView(matSelectId)"
  [class.invalid-field]="formControl.invalid && formControl.touched"
  [floatLabel]="floatLabel ? 'auto' : 'never'"
  [class.tablet-mode]="isTabletMode">
  <mat-label class="field-label">
    <span class="required-section" *ngIf="fieldData.required">*</span>
    {{ fieldData.label || '' | translate }}
  </mat-label>

  <mat-select
    #autocompleteSelectPanel
    [id]="matSelectId"
    [formControl]="$any(fieldFormControl)"
    [errorStateMatcher]="matcher"
    [multiple]="allowMultiSelect"
    (opened)="selectPanelOpened()"
    (closed)="selectPanelClosed()"
    [disableOptionCentering]="true"
    [panelClass]="panelClass">
    <mat-form-field class="autocomplete-input-container" floatLabel="never">
      <input
        data-cy-id="old-autocomplete-input"
        #autocompleteInputField
        matInput
        type="text"
        class="autocomplete-search-input"
        autocomplete="off"
        [formControl]="$any(autocompleteInput)"
        (keydown)="$event.stopPropagation()" />
      <mat-placeholder class="placeholder">{{ fieldData.placeholder || '' | translate }}</mat-placeholder>

      <button
        class="clear-autocomplete-button"
        *ngIf="autocompleteInput.value"
        mat-icon-button
        (click)="clear(); $event.preventDefault()">
        <i class="far fa-times"></i>
      </button>
    </mat-form-field>

    <div *ngIf="selectedOptions | async as options">
      <div *ngIf="allowMultiSelect && selectedChipsVisible && options.length" class="autocomplete-selected-chips">
        <div class="autocomplete-selected-chips__title">{{ 'selected' | translate | uppercase }}</div>
        <wc-chip
          *ngFor="let option of options"
          (click)="optionClick(option.value, true, true)"
          [text]="option.displayName || ''"
          [style]="chipStyles.removable"
          [isClickable]="true"
          [disableTextSelection]="true"
          class="heap-removable-autocomplete-option-chip"></wc-chip>
      </div>
    </div>

    <mat-option
      *ngIf="areOptionsLoading"
      disabled
      class="loading-options option-no-results-found-autocomplete"
      [class.tablet-mode]="isTabletMode">
      <wc-loader [show]="true"></wc-loader>
    </mat-option>

    <ng-container *ngIf="supportLoadMoreOption; else defaultView">
      <ng-container *ngFor="let option of filteredWithHidden | async">
        <mat-option
          *ngIf="
            option.value === fieldFormControl.value || option.value === autocompleteInput.value || option.value !== ''
          "
          [value]="option.value"
          [disabled]="option.disabled"
          (click)="optionClick(option.value)">
          {{ option.displayName }}
        </mat-option>
      </ng-container>
      <div
        *ngIf="
          loadmore && hiddenOptionsWithoutControlValue?.length !== 0 && autocompleteInputField?.value?.length === 0
        "
        (click)="clickOnLoadMore()"
        class="autocomplete-load-more-option"
        [class.tablet-mode]="isTabletMode">
        {{ 'loadMore' | translate }}
      </div>

      <ng-container *ngIf="!loadmore">
        <hr *ngIf="(filterVisible | async)?.length > 0" class="autocomplete-load-more-hr" />
        <ng-container *ngFor="let option of filterVisible | async">
          <mat-option
            *ngIf="
              option.value === fieldFormControl.value || option.value === autocompleteInput.value || option.value !== ''
            "
            [value]="option.value"
            [disabled]="option.disabled"
            (click)="optionClick(option.value)">
            {{ option.displayName }}
          </mat-option>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #defaultView>
      <ng-container *ngFor="let option of filteredOptions | async">
        <mat-option
          [class.hidden-option]="!option.value || areOptionsLoading"
          *ngIf="isOptionVisible(option)"
          [value]="option.value"
          [disabled]="option.disabled"
          (click)="optionClick(option.value)">
          {{ option.displayName }}
        </mat-option>
      </ng-container>
    </ng-template>

    <mat-option
      *ngIf="showNoResultFound && autocompleteInput.value?.length"
      disabled
      class="option-no-results-found-autocomplete"
      [class.tablet-mode]="isTabletMode">
      {{ 'optionEmptyState.' + noResultFoundLabel | translate }}
    </mat-option>

    <mat-option
      *ngIf="showStartTyping"
      disabled
      class="option-no-results-found-autocomplete"
      [class.tablet-mode]="isTabletMode">
      {{ 'optionEmptyState.' + showStartTypingLabel | translate }}
    </mat-option>

    <button
      class="multi-select-submit-button primary-button"
      *ngIf="allowMultiSelect && isTabletMode"
      (click)="closeSelectPanel()">
      {{ 'done' | translate }}
    </button>
  </mat-select>

  <mat-error
    [class.tablet-mode]="isTabletMode"
    class="autocomplete-v2-validation-error"
    *ngIf="!isSelectPanelOpened && formControl.invalid && validationError">
    {{
      'validationErrorMessages.' +
        (allowMultiSelect && customValidationMessage && validationError === 'maxlength'
          ? customValidationMessage
          : $any(validationError))
        | translate
          : {
              min: fieldFormControl.errors?.min?.min,
              max: fieldFormControl.errors?.max?.max,
              minLength: fieldFormControl.errors?.minlength?.requiredLength,
              maxLength: fieldFormControl.errors?.maxlength?.requiredLength
            }
    }}
  </mat-error>
</mat-form-field>
