import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { HeapAnalyticsService } from '@wc-core';
import { LocalStorageService, RoadEventHighlightEntity, RoadEventsStore, UsersStore } from '@wc/core';
import { RoadEventType } from '@wc/core/models/enums';
import { UIIncident } from '@wc/core/models/extended.models';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import moment from 'moment';
import { IncidentStore } from '../../../core';

@Directive({
  selector: '[wcRoadEventHighlight]',
})

/**
 * @description
 * RoadEventHighlight directive add 'highlighted-card' class to current html tag to show users last created road events.
 * - Road events created in the Waycare app will get highlighted-card class for 6 seconds from the `createdAt` time.
 * - Incidents received from other platforms (like CAD, etc.) and appears in 'Need Action' tab should be highlighted for
 * 15 minutes (900 sec) and then class removed by using `removeHighlight()` method.
 *
 * If user entered to one of the highlighted cards or clicked on buttons to change its status, the 'highlighted-card'
 * should be removed immediately. New incidents created on other platforms after interactions are added to the
 * `this.roadEventsStore.interactedRoadEventIds` and saved in localStorage (interactedRoadEventIds), it allows to
 * restore interacted cards after moving between panel tabs, pages and after app reload.
 *
 *  EXPLANATION OF HIGHLIGHT DURATIONS:
 *  10 = incident created by Auth0 current user) and 900 seconds for all the rest
 */
export class RoadEventHighlightDirective {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private roadEventsStore: RoadEventsStore,
    private localStorageService: LocalStorageService,
    private userStore: UsersStore,
    private incidentStore: IncidentStore,
    private heapService: HeapAnalyticsService
  ) {}

  id?: number;
  entityType = 'incident';
  timeDiffInSeconds = 0;
  private highLightDuration = 900;
  private effectDurationInSeconds = 0;

  @Input()
  set wcRoadEventHighlight(roadEventEntity: RoadEventHighlightEntity) {
    const { roadEvent, type } = roadEventEntity;
    const roadEventAsIncident = roadEvent as UIIncident;

    this.entityType = type;
    this.id = roadEvent.id;

    let roadEventUpdatedAt = roadEventAsIncident.updatedAt
      ? typeof roadEventAsIncident.updatedAt === 'string'
        ? roadEventAsIncident.updatedAt
        : new Date(roadEventAsIncident.updatedAt * 1000).toISOString()
      : undefined;

    let startTime =
      roadEventUpdatedAt || roadEventAsIncident.confirmedAt || roadEvent.createdAt || roadEventAsIncident.startedAt;

    const isIncidentAndUpdatedByUser =
      this.entityType === RoadEventType.Incident &&
      (this.userStore.authUserID === roadEventAsIncident.confirmedBy ||
        this.userStore.authUserID === roadEventAsIncident.confirmedBy ||
        this.userStore.authUserID === roadEventAsIncident.updatedBy ||
        this.userStore.authUserID === roadEventAsIncident.updatedBy ||
        roadEventAsIncident.createdBy === this.userStore.authUserID ||
        roadEventAsIncident.createdBy === this.userStore.authUserID);

    const isNotIncident = this.entityType !== RoadEventType.Incident;
    /**
     * If the roadEvent is not Incident, it's created by user for sure, else if it is Incident, than
     * If createdBy/confirmedBy is not == to authUserID it means that the Incident was created outside of the Waycare app and imported
     * from third party platform. In that case we push those incidents to the 'Need Action' tab and set highlight time
     * to 15 minutes (900 sec) or till user interact with the incident.
     */
    if (isNotIncident || isIncidentAndUpdatedByUser) {
      this.highLightDuration = 4;
    }

    this.timeDiffInSeconds = moment.duration(moment(Date.now()).diff(startTime)).asSeconds();
    const isRoadEventInteracted = this.roadEventsStore?.interactedRoadEventIds[this.entityType]?.includes(this.id);

    if (this.timeDiffInSeconds <= this.highLightDuration && !isRoadEventInteracted) {
      this.effectDurationInSeconds = this.highLightDuration - this.timeDiffInSeconds;
      if (this.highLightDuration === 4) {
        this.startHighlight();
      } else {
        setTimeout(() => {
          if (
            this.roadEventsStore.cachedIncidentIdsWhoHadSound?.[roadEventAsIncident.id] ||
            this.incidentStore.cachedNeedActionIncidentIds?.[roadEventAsIncident.id] ||
            this.incidentStore.cachedInprogressIncidents?.[roadEventAsIncident.id]
          ) {
            this.startHighlight();
          }
        });
      }
    } else {
      this.removeHighlight();
    }
  }

  startHighlight() {
    const eventData = { entityType: this.entityType, id: this.id };
    this.heapService.trackUserSpecificAction('heap-fe-highlighted-new-event', eventData);

    this.renderer.addClass(this.el.nativeElement, 'highlighted-card');
    setTimeout(() => {
      this.removeHighlight();
    }, this.effectDurationInSeconds * 1000);
  }

  removeHighlight() {
    this.effectDurationInSeconds = 0;
    this.highLightDuration = 900;
    const hasHighlight = this.el.nativeElement.classList.contains('highlighted-card');

    if (hasHighlight) {
      this.renderer.removeClass(this.el.nativeElement, 'highlighted-card');
      this.roadEventsStore.interactedRoadEventIds[this.entityType] = this.roadEventsStore.interactedRoadEventIds[
        this.entityType
      ]?.filter(roadEvent => roadEvent !== this.id);
    }
  }

  /**
   * When user interacts with the road event card the `highlight-card` class should be removed. We doing it by saving
   * road event id to the roadEventsStore.interactedRoadEventIds and to the localStorage. It allows us to avoid
   * highlighting already interacted road events after navigation between pages or after app reload.
   */

  @HostListener('click', ['$event'])
  click() {
    if (this.timeDiffInSeconds <= this.highLightDuration) {
      const isRoadEventInteracted = this.roadEventsStore.interactedRoadEventIds[this.entityType]?.includes(this.id);
      this.removeHighlight();

      if (!isRoadEventInteracted) {
        this.roadEventsStore.interactedRoadEventIds[this.entityType]?.push(this.id);
        this.localStorageService.set(
          LocalStorageKeys.InteractedRoadEventIds,
          this.roadEventsStore.interactedRoadEventIds
        );
      }
    }
  }
}
