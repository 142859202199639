import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { SelectControlComponent } from './select.component';

@NgModule({
  declarations: [SelectControlComponent],
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, MatSelectModule],
  exports: [SelectControlComponent],
})
export class WcSelectControlModule {}
