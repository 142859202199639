import { Injectable } from '@angular/core';
import { ScopeAccessModifier } from '@wc/wc-models/src';
import { getCenterOfBounds } from 'geolib';
import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { SelectOption } from '../../features/ui/form-controls/form-models';
import * as Utils from '../../utils';
import {
  CreateUserInput,
  DeleteUserUnitInput,
  OptionalDateTimePeriodInput,
  Permission,
  SetUserRolesInput,
  SetUserUnitInput,
  UnitLocationInput,
  UnitVehicle,
  UpdateUnitLocationInput,
  UpdateUserInput,
  User,
} from '../models';
import { AppConfigService, LocationService, Position } from '../services';
import { AuthService } from '../services/auth.service';
import { ThemeService } from '../services/theme.service';
import { UsersService } from '../services/users.service';

@Injectable({
  providedIn: 'root',
})
export class UsersStore {
  @observable users: { [key: string]: User } = {};
  @observable permissions: Array<string> = [];

  authUserID!: number;
  currentAuthUserUnitLocation!: number[];
  accountLocationCenter!: number[];

  constructor(
    private usersService: UsersService,
    private authService: AuthService,
    private locationService: LocationService,
    private appConfigService: AppConfigService,
    private themeService: ThemeService
  ) {
    makeObservable(this);
    if (this.appConfigService.authUser) this.setAuthUser(this.appConfigService.authUser);
  }

  setAuthUser(user: User) {
    this.authUserID = user.id;
    this.users[user.id] = user;
    this.setPermissions(user.permissions);
    this.accountLocationCenter = this.accountLocationCenterFromPolygon(user.account.mapCenter);
    this.themeService.setInitialPositionAndTimes(this.accountLocationCenter);
  }

  accountLocationCenterFromPolygon(mapCenterPolygon: { coordinates: number[][]; type: string }): number[] {
    const coordinates: { latitude; longitude }[] = [];
    mapCenterPolygon.coordinates[0].forEach(cord => {
      coordinates.push({ latitude: cord[0], longitude: cord[1] });
    });
    const res = getCenterOfBounds(coordinates);
    return [res.latitude, res.longitude];
  }

  authUserUnitLocation(fallbackPosition?: Position): Promise<Position | undefined> {
    const accountCenter = this.accountLocationCenter;
    if (accountCenter) {
      fallbackPosition = fallbackPosition || {
        coords: {
          longitude: accountCenter[0],
          latitude: accountCenter[1],
        },
      };
    }
    if (fallbackPosition) {
      return this.locationService.getCurrentPosition(fallbackPosition);
    } else {
      return this.locationService.getCurrentPosition();
    }
  }

  // GeolocationPosition is not currently exported in @types
  async updateUnitLocation(position: any | null): Promise<boolean | undefined> {
    const user: User = this.users[this.authUserID];
    const unit: UnitVehicle | undefined = user.unit || undefined;
    if (!position) {
      return undefined;
    }

    const coords = [position.coords.longitude, position.coords.latitude];
    this.currentAuthUserUnitLocation = coords;

    if (!unit || !unit.id) return undefined;
    const unitLocation: UnitLocationInput = {
      point: {
        type: 'Point',
        coordinates: coords,
      },
    };
    const input: UpdateUnitLocationInput = {
      unitId: unit.id,
      unitLocation: unitLocation,
    };

    let res: boolean | undefined;
    try {
      res = await this.usersService.updateUnitLocation(input).toPromise();
    } catch (err) {
      if (err && (err as any).errorCode === 456) {
        // 456 - lost unit
        this.users[this.authUserID].unit = null;
      }

      throw err;
    }
    return res;
  }

  @computed
  get authUser(): User {
    return this.users[this.authUserID];
  }

  @action
  updateAuthUserUnitData(unit: UnitVehicle) {
    const userDetails = this.authUser.unit?.userDetails;
    if (userDetails) {
      this.users[this.authUserID].unit = { ...unit, ...{ userDetails } };
    } else {
      this.users[this.authUserID].unit = unit;
    }
  }

  @computed
  get usersList(): User[] {
    return Utils.toArray(this.users);
  }

  @computed
  get usersListOptions(): SelectOption[] {
    return Utils.strArrayToOptions(this.usersList.map(user => user.name)).sort((a, b) =>
      (a.displayName as string).toLocaleLowerCase().localeCompare((b.displayName as string).toLocaleLowerCase())
    );
  }

  @action
  setPermissions(permissions: Permission[]) {
    const groupByEntity = {};
    permissions.forEach((permission: Permission) => {
      try {
        const { entity, modifier } = permission.scope;
        this.permissions.push(`${entity}:${modifier}`);
        groupByEntity[entity] = groupByEntity[entity] || [];
        groupByEntity[entity].push(modifier);
      } catch (error) {
        console.error(error);
      }
    });
  }

  hasPermission(permissionStr: ScopeAccessModifier | ScopeAccessModifier[], op: 'AND' | 'OR' = 'OR') {
    const _permissions = Array.isArray(permissionStr) ? permissionStr : [permissionStr];
    return op === 'OR'
      ? _permissions.some(permission => this.permissions.some(p => p === permission))
      : _permissions.every(permission => this.permissions.some(p => p === permission));
  }

  hasTrafficDisruptionPermission() {
    return this.hasPermission(['CONSTRUCTION:READ', 'ROAD_CLOSURE:READ', 'SPECIAL_EVENT:READ']);
  }

  createUser(inputs: { userInput: CreateUserInput; userUnit?: SetUserUnitInput }) {
    return this.usersService.createUser(inputs.userInput).pipe(
      mergeMap(user => {
        if (inputs.userUnit) {
          const setUserUnitInput: SetUserUnitInput = inputs.userUnit;
          setUserUnitInput.userId = user.id;
          return this.usersService.assignUnitToUser(setUserUnitInput);
        } else {
          return of(user);
        }
      }),
      tap((user: User) => {
        console.log(user);
        this.users[user.id] = user;
        this.users = { ...{}, ...this.users };
      })
    );
  }

  getUser(userId: number) {
    return this.usersService.getUserByID(userId).pipe(
      tap((user: User) => {
        this.users[user.id] = user;
        this.users = { ...{}, ...this.users };
      })
    );
  }

  getUsers(optionalDateTimePeriodInput: OptionalDateTimePeriodInput = { value: null }) {
    this.usersService.getUsers(optionalDateTimePeriodInput).subscribe((users: User[]) => {
      this.users = { ...{}, ...Utils.toObjectWithId(users) };
    });
  }

  @action
  updateUser(inputs: {
    userInput: UpdateUserInput;
    rolesInput?: SetUserRolesInput;
    userUnit?: SetUserUnitInput;
    unassignUserUnit?: DeleteUserUnitInput;
  }): Observable<string | User> {
    const servicesObservables = {};
    if (inputs.userInput) servicesObservables['updateUser'] = this.usersService.updateUser(inputs.userInput);
    if (inputs.rolesInput)
      servicesObservables['assignUserRoles'] = this.usersService.assignUserRoles(inputs.rolesInput);
    if (inputs.userUnit) servicesObservables['assignUnitToUser'] = this.usersService.assignUnitToUser(inputs.userUnit);
    if (inputs.unassignUserUnit)
      servicesObservables['unassignUnitToUser'] = this.usersService.unassignUnitToUser(inputs.unassignUserUnit);
    if (Utils.isEmptyObj(servicesObservables)) return of('');

    return forkJoin(servicesObservables).pipe(
      map(args => {
        console.log(args);
        let user: User = args['updateUser'] || {};
        if (args['assignUserRoles']) {
          user = { ...user, ...(args['assignUserRoles'] as User) };
        }
        if (args['assignUnitToUser']) {
          user = { ...user, ...(args['assignUnitToUser'] as User) };
        }
        if (args['unassignUnitToUser']) {
          user = { ...user, ...(args['unassignUnitToUser'] as User) };
        }
        return user;
      }),
      tap((user: User) => {
        if (user) {
          if (!user.account) {
            user.account = toJS(this.users[user.id].account);
          }
          this.users[user.id] = user;
          this.users = { ...{}, ...this.users };
        }
      }),
      catchError(err => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          return throwError({
            errorCode: err.graphQLErrors[0].extensions.statusCode,
          });
        } else {
          return throwError(err);
        }
      })
    );
  }

  deleteUser(user: User) {
    const userId = user.id;
    const servicesObservables = {};
    if (user.unit)
      servicesObservables['unassignUnit'] = this.usersService.unassignUnitToUser({
        userId: userId,
        unitId: user.unit?.id,
      });
    servicesObservables['deleteUser'] = this.usersService.deleteUser({
      userId: userId,
    });

    return forkJoin(servicesObservables).pipe(
      tap(res => {
        delete this.users[userId];
        this.users = { ...{}, ...this.users };
        if (this.authUserID === userId) {
          this.logoutUser();
        }
      })
    );
  }

  logoutUser() {
    this.usersService.logoutUser().subscribe(() => {
      this.authService.logout();
    });
  }
}
