import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { NumberFieldComponent } from './number-field.component';

const Materials: any[] = [MatInputModule];

@NgModule({
  declarations: [NumberFieldComponent],
  imports: [CommonModule, WcBaseControlFieldModule, Materials],
  providers: [],
  exports: [NumberFieldComponent],
})
export class WcNumberFieldModule {}
