import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { FocusDirectiveModule } from '@wc/wc-common/src/lib/directives/focus-directive.module';
import { WcTooltipModule } from '../../components/tooltip/tooltip.module';
import { WcIconModule } from '../../components/wc-icon/wc-icon.module';
import { RadioButtonComponent } from './radio-button.component';

@NgModule({
  declarations: [RadioButtonComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    WcTooltipModule,
    WcIconModule,
    FocusDirectiveModule,
  ],
  exports: [RadioButtonComponent],
})
export class RadioButtonModule {}
