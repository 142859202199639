import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HeapAnalyticsService } from '@wc-core';
import { WeatherEventsStore } from '@wc/core/stores/weather-events.store';
import { RolesFacadeService } from '@wc/permissions/domain/src';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { Day } from '@wc/work-schedule-configuration/domain/src';
import moment from 'moment';
import { DateFormat, Language, MeasureUnitType, RegionalSetting, TimeFormat, TimeZone, User } from '../models';
import { AppFeatureEnum, LocalStorageService, LocationService, SplitIOService } from '../services'; 
import { UsersService } from '../services/users.service';
import { AccountStore } from './account.store';
import { ActivityLogStore } from './activity-log.store';
import { CrashRiskStore } from './crash-risk.store';
import { EntitiesStore } from './entities.store';
import { IncidentStore } from './incident.store';
import { LiveMapStore } from './live-map.store';
import { RoadClosureStore } from './road-closure.store';
import { RoadEventsStore } from './road-events.store';
import { RoutesStore } from './routes.store';
import { ShiftStore } from './shift.store';
import { SpecialEventStore } from './special-event.store';
import { TrafficDisruptionStore } from './traffic-disruption.store';
import { UiStore } from './ui.store';
import { UploadStore } from './upload.store';
import { UsersStore } from './users.store';

@Injectable({
  providedIn: 'root',
})
export class Store {
  featureToggles!: SplitIO.Treatments;

  constructor(
    private usersService: UsersService,
    private heapAnalyticsService: HeapAnalyticsService,
    private splitIOService: SplitIOService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private locationService: LocationService,
    private rolesFacadeService: RolesFacadeService,
    @Inject(UiStore) public uiStore: UiStore,
    @Inject(AccountStore) public accountStore: AccountStore,
    @Inject(EntitiesStore) public entitiesStore: EntitiesStore,
    @Inject(UsersStore) public usersStore: UsersStore,
    @Inject(LiveMapStore) public liveMapStore: LiveMapStore,
    @Inject(IncidentStore) public incidentStore: IncidentStore,
    @Inject(UploadStore) public uploadStore: UploadStore,
    @Inject(TrafficDisruptionStore)
    public trafficDisruptionStore: TrafficDisruptionStore,
    @Inject(RoadClosureStore) public roadClosureStore: RoadClosureStore,
    @Inject(SpecialEventStore) public specialEventStore: SpecialEventStore,
    @Inject(ShiftStore) public shiftStore: ShiftStore,
    @Inject(RoutesStore) public routesStore: RoutesStore,
    @Inject(ActivityLogStore) public activityLogStore: ActivityLogStore,
    @Inject(CrashRiskStore) public crashRiskStore: CrashRiskStore,
    @Inject(RoadEventsStore) public roadEventsStore: RoadEventsStore,
    @Inject(WeatherEventsStore) public weatherEventsStore: WeatherEventsStore
  ) {
    this.loadAuthUser();
  }

  loadAuthUser() {
    const user: User = this.usersService.authUser;
    this.rolesFacadeService.getUsersWithRoles().subscribe(() => {
      this.heapAnalyticsService.trackUser(user);
    });
    this.liveMapStore.updateMapConfig(user.account); // Important to init the map first.
    this.usersStore.setAuthUser(user);
    this.accountStore.setAccount = user.account;
    this.accountStore.routeTypes = user.account.routeTypes;

    this.splitIOService.featureToggles.subscribe(featureToggles => {
      this.featureToggles = featureToggles;
      this.initLocalization();
      this.liveMapStore.wcMap?.addWorkspacesBorders();
      this.liveMapStore.setSelectedWorkspacesFromCache();

      if (featureToggles[AppFeatureEnum.FE_AVL_LOCATION_SRC] === 'on') this.locationService.setLocationSrc('AVL');

      if (featureToggles[AppFeatureEnum.FE_SHOW_UNIDENTIFIED_TYPE] === 'on') {
        this.liveMapStore.staticVisibleLayers = ['unidentified'];
        this.liveMapStore.changeLayersVisibility(true, ['unidentified']);
      } else {
        this.liveMapStore.staticVisibleLayers = [];
        this.liveMapStore.changeLayersVisibility(false, ['unidentified']);
      }
      this.uiStore.isPortraitDesktopMode =
        JSON.parse(this.localStorageService.get(LocalStorageKeys.IsPortraitDesktopMode) as string) || false;
    });
  }

  initLocalization() {
    this.translateService.use(this.accountStore.account.regionalSetting.language.toLowerCase());

    // TODO HEBREW: Currently decided not to change direction of elements. Uncomment it when the product team will want to move forward with it
    // if (this.accountStore.account.regionalSetting.language === 'HE') {
    //   //change UI state here
    // }

    // TODO HEBREW: This is temp variable. Remove it when we will ready to move forward with RTL
    if (this.accountStore.account.regionalSetting.language === 'HE') {
      this.uiStore.isHebrew = true;
      moment.updateLocale('he', {
        relativeTime: {
          s: 'מס` שניות',
        },
      });
    } else {
      // Global locale settings for moment.js
      if (this.accountStore.account.regionalSetting.dateFormat === DateFormat.MmmDdYyyy) {
        moment.updateLocale('en', {
          relativeTime: {
            s: 'a few sec',
          },
        });
      } else {
        moment.updateLocale('en-gb', {
          relativeTime: {
            s: 'a few sec',
          },
        });
      }
    }

    const regionalSetting: RegionalSetting = this.accountStore.account.regionalSetting;
    this.accountStore.account.regionalSetting = {
      firstDayOfWeek: Day.Sunday,
      dateFormat: regionalSetting.dateFormat || DateFormat.MmmDdYyyy,
      language: regionalSetting.language || Language.En,
      measureUnitType: regionalSetting.measureUnitType || MeasureUnitType.Imperial,
      timeFormat: regionalSetting.timeFormat || TimeFormat.TwelveHours,
      timeZone: regionalSetting.timeZone || TimeZone.UsPacific,
    };
  }
}
