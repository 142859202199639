/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { WeatherEventsStoreEntity, WeatherEventsUIEntity } from '@wc/wc-models/src';
import { map } from 'rxjs/operators';
import { LayerType } from '../../../../../../core/models/gql.models';
import { EntitiesServiceV2 } from '../../../services/entities.service';

export interface WeatherEventsState extends EntityState<WeatherEventsStoreEntity, number> {}
export interface WeatherEventsUIState extends EntityState<WeatherEventsUIEntity> {}
export interface WeatherEventsAllStates {
  entity: WeatherEventsStoreEntity | undefined;
  ui: WeatherEventsUIEntity | undefined;
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: LayerType.WeatherAlert })
export class WeatherEventsStore extends EntityStore<WeatherEventsState> {
  ui!: EntityUIStore<WeatherEventsUIState>;
  constructor(entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$.pipe(map(({ WEATHER_ALERT }) => WEATHER_ALERT || [])).subscribe(UIWeatherAlerts => {
      this.ui.upsertMany(UIWeatherAlerts);
    });

    entitiesService.getDiff$
      .pipe(
        map(({ modified, removed }) => ({
          removedWeatherEvents: removed?.WEATHER_ALERT,
          modifiedWeatherEvents: modified.WEATHER_ALERT,
        }))
      )
      .subscribe(({ removedWeatherEvents, modifiedWeatherEvents }) => {
        if (modifiedWeatherEvents) this.upsertMany(modifiedWeatherEvents as WeatherEventsStoreEntity[]);
        if (removedWeatherEvents) this.remove(removedWeatherEvents);
      });

    entitiesService.activeEntityIds$.pipe(map(({ WEATHER_ALERT }) => WEATHER_ALERT || [])).subscribe(id => {
      this.ui.setActive(id);
    });
  }
}
