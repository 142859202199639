import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AppTypeUnion, WorkspaceSelection } from '@wc/wc-models/src';
import { SlideToggleComponent } from '../slide-toggle/slide-toggle.component';
// import { Workspace } from '@wc/core/models/gql.models';

@Component({
  selector: 'wc-work-spaces-menu',
  templateUrl: './work-spaces-menu.component.html',
  styleUrls: ['./work-spaces-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkSpacesMenuComponent {
  @Input() workspacesArray: WorkspaceSelection[] | null = [];
  @Input() appType: AppTypeUnion = 'desktop';
  selectedWorkspaceIds!: number[];
  isTabletMode = false;
  @Output() workspaceSelection = new EventEmitter<WorkspaceSelection>();

  updateSelectedWorkspaces(event) {
    const ws = this.workspacesArray?.find(ws => ws.title === event.source.name);
    if (ws) {
      ws.checked = event.checked;
    }

    this.workspaceSelection.emit(ws);
  }

  handleClick(event: MouseEvent, toggleComponent: SlideToggleComponent, workspaceId: number | string): void {
    event.preventDefault();
    event.stopPropagation();
    toggleComponent.toggle();
    setTimeout(() => {
      const el = document.querySelector(`#workspace-${workspaceId}`) as HTMLButtonElement | undefined;
      el?.focus();
    });
  }
}
