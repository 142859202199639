/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AffectedRouteServicesQuery, LiveMapQuery, LiveMapService, Utils } from '@wc-core';
import { LocalStorageService } from '@wc/core';
import { TransitUnitLayers } from '@wc/core/models/enums';
import { SelectOption } from '@wc/features/ui/form-controls/form-models';
import { PermissionsFacadeService } from '@wc/permissions/domain/src';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { TransitService } from '@wc/wc-core/src/lib/services/transit.service';
import { TransitRoutesQuery } from '@wc/wc-core/src/lib/stores/transit/transit_routes.query';
import { InteractionsEnum } from '@wc/wc-map-viewer';
import { ScopeAccessModifier, TransitLayerType, TransitRouteStoreEntity, TransitSubTypeNames } from '@wc/wc-models';
import { LayerVisibility, LiveMapEntityType } from '@wc/wc-models/src/lib/types/live-map';
import { unionBy } from 'lodash';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import {
  BusStopDetails,
  FixedBusRouteDetails,
  FixedRouteBusStopDetails,
  LayerType,
  TransitAffectingEntityType,
} from '../../../../../core/models/gql.models';
import { AppFeatureEnum } from '../../../../../core/services/split-io.service';
import { TransitConfig } from '../transit.config';
import { TransitAffectedServicesService } from './transit-panel-main-tabs/route-list-tab/affected-services-transit/transit-affected-services.service';

@Component({
  selector: 'wc-transit-panel',
  templateUrl: './transit-panel.component.html',
  styleUrls: ['./transit-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitPanelComponent implements AfterViewInit, OnInit, OnDestroy {
  @Output() layersVisibilityStateChange = new EventEmitter<LayerVisibility[]>();
  @Output() filterChange = new EventEmitter<boolean>();
  @Output() hidePanel = new EventEmitter<boolean>();

  // @Input() mapReady$!: Observable<boolean>;
  @Input() isPortraitDesktopMode = false;
  @Input() accountId!: number;
  @Input() isHebrew = false;

  private readonly areTransitRoutesAreOn$ = new Subject<boolean>();
  readonly layersVisibility$ = new ReplaySubject<LayerVisibility[]>(1);
  readonly selectedRoute$ = new BehaviorSubject<FixedBusRouteDetails | null>(null);
  readonly busStopsList$ = new ReplaySubject<FixedRouteBusStopDetails[]>(1);
  readonly busStopDetails$ = new Subject<BusStopDetails>();
  readonly showLoader$ = new BehaviorSubject<boolean>(false);

  get affectingEntityType() {
    return TransitAffectingEntityType;
  }

  layersVisibility: LayerVisibility[] | undefined;
  AppFeatureEnum = AppFeatureEnum;
  mainTabIndex = 0;
  routeTabIndex = 0;
  selectedPatternId: number | null = null;
  isAffectedServicePanelOpened = false;
  isTrainDelaysPanelOpened = false;
  selectedRoutes = new FormControl([]);
  myAgencyOnly = new FormControl(!!this.localStorageService.get(LocalStorageKeys.LiveMapFilters)?.transitIsMyAgency);
  showBuses = new FormControl();
  refocus$ = combineLatest([this.liveMapService.unselectEvent$, this.showLoader$]);

  readonly filteredTransitRoutes$ = this.myAgencyOnly.valueChanges.pipe(
    startWith(this.myAgencyOnly.value),
    switchMap(isChecked =>
      this.transitRoutesQuery.allTransitRoutes$.pipe(
        map(routes => routes.filter(route => (isChecked ? route.isMyAccount : true)))
      )
    )
  );

  readonly transitRoutes$ = this.filteredTransitRoutes$.pipe(map(routes => routes.filter(route => route.show)));

  readonly fixedRoutesAsOptions$ = this.filteredTransitRoutes$.pipe(
    map(routes =>
      routes.map(
        route =>
          ({ value: route.id, displayName: route.name, data: { accountName: route.accountName } } as SelectOption)
      )
    )
  );

  get selectedRoute(): TransitRouteStoreEntity | undefined {
    const activeRoutes = this.transitRoutesQuery.getActive();
    if (activeRoutes instanceof Array) return activeRoutes[0];
    return activeRoutes;
  }

  constructor(
    private transitService: TransitService,
    private transitRoutesQuery: TransitRoutesQuery,
    private affectedServices: TransitAffectedServicesService,
    private liveMapService: LiveMapService,
    private localStorageService: LocalStorageService,
    private affectedRouteServicesQuery: AffectedRouteServicesQuery,
    private permissionsFacadeService: PermissionsFacadeService,
    private liveMapStore: LiveMapQuery
  ) {
    this.initRouteVisibility();
  }

  ngOnInit() {
    this.layersVisibility$.next(this.transitService.initLayersVisibility || TransitConfig.LayersVisibility);
  }

  ngAfterViewInit(): void {
    this.myAgencyOnly.valueChanges.subscribe(isChecked => {
      this.filterChange.emit(isChecked);
      this.setVisibleRoutes(this.selectedRoutes.value);
    });

    this.selectedRoutes.valueChanges.subscribe(selectedRoutes => {
      this.setVisibleRoutes(selectedRoutes);
    });
    this.initLayerVisibility();

    this.liveMapService.selectEvent$.subscribe(() => this.checkForSelectedFeaturesAndNavigateAccordingly());
  }

  hasPermission(permission: ScopeAccessModifier) {
    return this.permissionsFacadeService.hasPermission(permission);
  }

  setVisibleRoutes(routesIds: number[]) {
    this.transitService.setVisibleRoutes(routesIds);
  }

  checkForSelectedFeaturesAndNavigateAccordingly() {
    if (this.liveMapService.selectedFeatureId?.includes(TransitLayerType.TransitRoute)) {
      const entityId = this.liveMapService.getSelectedEntityId();
      if (entityId) {
        this.getRouteDetails(+entityId);
      }
    }

    if (this.liveMapService.selectedFeatureId?.includes(TransitLayerType.TransitBusStop)) {
      const entityId = this.liveMapService.getSelectedEntityId();
      if (entityId) {
        this.getBusStopDetails(+entityId, true);
      }
    }
  }

  initLayerVisibility() {
    this.layersVisibility$
      .pipe(tap(layersVisibility => (this.layersVisibility = layersVisibility)))
      .subscribe(layersVisibility => {
        combineLatest([this.showBuses.valueChanges, this.areTransitRoutesAreOn$])
          .pipe(map(([showBussesOn, routesOn]) => showBussesOn && routesOn))
          .subscribe(checked => this.layerCheckboxClicked(checked, TransitUnitLayers.transit_fixed_route_bus_unit));
        this.showBuses.setValue(
          layersVisibility?.find(layer => layer.layerName === TransitUnitLayers.transit_fixed_route_bus_unit)?.checked
        );
        this.areTransitRoutesAreOn$.next(
          layersVisibility?.find(layer => layer.layerName === TransitSubTypeNames.transit_fixed_routes)?.checked
        );
      });
  }

  initRouteVisibility() {
    this.selectedRoutes.setValue(this.transitService.initRouteIdsVisibility || []);
  }

  layerCheckboxClicked(checked: boolean, layerName: LiveMapEntityType) {
    this.updateLayerCheckedProperty(checked, layerName);
    if (layerName === TransitSubTypeNames.transit_fixed_routes) {
      this.areTransitRoutesAreOn$.next(checked);
    }
    this.layersVisibilityStateChange.emit(this.layersVisibility);
  }

  updateLayerCheckedProperty(checked: boolean, layerName: LiveMapEntityType) {
    const layersVisibilityObj = Utils.convertArrayToObject(this.layersVisibility, 'layerName');
    layersVisibilityObj[layerName].checked = checked;
    layersVisibilityObj['transit_fixed_stops'].checked = layersVisibilityObj['transit_fixed_routes'].checked;
    this.layersVisibility = Object.values(layersVisibilityObj);
  }

  selectRouteFromPanel(routeId: number) {
    this.liveMapService.selectFeature(TransitLayerType.TransitRoute, routeId);
  }

  getRouteDetails(routeId: number, navigate = true) {
    if (this.selectedRoute$.getValue() !== null && this.selectedRoute$.getValue()?.id === routeId) return;

    this.transitService.setRoutesAsActive([routeId]);
    this.showLoader$.next(true);

    this.transitService
      .getRouteDetails(routeId)
      .pipe(
        finalize(() => {
          this.showLoader$.next(false);
        })
      )
      .subscribe({
        next: routeDetails => {
          const affectedRoute = this.affectedRouteServicesQuery.getAffectedServiceUIByRouteId(routeId);
          this.affectedServices.selectedAffectedServiceUIEntity.next(affectedRoute);
          this.selectedRoute$.next(routeDetails);
          if (navigate && this.mainTabIndex !== 1) {
            this.setTabIndex(1);
          }
        },
        error: error => {
          console.error('Error in getRouteDetails call:', error);
        },
      });
  }

  getBusStopDetails(stopId, featureIsSelected = false) {
    this.showLoader$.next(true);
    this.transitService
      .getBusStopDetails(stopId)
      .pipe(
        finalize(() => {
          this.showLoader$.next(false);
        })
      )
      .subscribe({
        next: details => {
          this.setTabIndex(2);
          this.busStopDetails$.next(details);
        },
        error: error => {
          console.error('Error in getBusStopDetails call:', error);
        },
      });
  }

  setRouteTabIdx(idx: number): void {
    this.routeTabIndex = idx;
    if (idx === 0) {
      this.selectedPatternId = null;
    } else this.emitRouteBusStops();
  }

  setPatternId(id: number) {
    this.setRouteTabIdx(1);
    this.selectedPatternId = id;
  }

  setTabIndex(index: number) {
    if (index === 0) {
      this.selectedRoute$.next(null);
      this.liveMapService.unselectFeature();
      this.affectedServices.selectedAffectedServiceUIEntity.next(null);
      this.setRouteTabIdx(0);
    } else if (index === 1 && !this.selectedRoute$.value) {
      index = 0;
    }
    this.mainTabIndex = index;
  }

  emitRouteBusStops() {
    this.selectedRoute$
      .pipe(
        map(data =>
          this.selectedPatternId
            ? data?.patterns.find(({ id }) => this.selectedPatternId === id)?.stops
            : data?.patterns.reduce<FixedRouteBusStopDetails[]>((acc, curr) => unionBy(acc, curr.stops, 'id'), [])
        )
      )
      .subscribe({
        next: stops => {
          this.busStopsList$.next(stops);
        },
      });
  }

  ngOnDestroy(): void {
    this.transitService.setRoutesAsActive([]);
    if (
      this.liveMapService.selectedFeatureId?.includes(TransitLayerType.TransitRoute) ||
      this.liveMapService.selectedFeatureId?.includes(TransitLayerType.TransitBusStop)
    ) {
      this.liveMapService.unselectFeature();
    }
  }

  getTransitUnitDetails(unitId: number) {
    this.liveMapService.selectFeature(LayerType.Unit, unitId, true).subscribe(() => {
      this.liveMapService.setOverlayToFeaturePosition(InteractionsEnum.select, LayerType.Unit, unitId);
      this.setTabIndex(3);
    });
  }

  closePanel() {
    this.hidePanel.emit();
  }
}
