import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { FormFieldData, FormFieldOption } from '@wc/wc-ui/src/lib/base';
import { UiStore } from 'libs/core/stores/ui.store';
import { indexOf, isEqual, sortBy, uniqWith } from 'lodash';
import { action, computed, makeObservable, observable, set, toJS } from 'mobx';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { environment } from '../../core/environments/environment';
import { SelectOption } from '../../features/ui/form-controls/form-models';
import * as Utils from '../../utils';
import * as Enums from '../models/enums';
import {
  AccessModifier,
  Account,
  AdditionalInfo,
  CreateRoleInput,
  DeleteRoleInput,
  IncidentAbandonedVehicleSubType,
  IncidentConfiguration,
  IncidentCrashSubType,
  IncidentDamageSubType,
  IncidentDebrisSubType,
  IncidentHazardSubType,
  IncidentPoliceActivitySubType,
  IncidentStalledVehicleSubType,
  IncidentTrafficStopSubType,
  IncidentType,
  MitigationType,
  Permission,
  PermissionGroup,
  ReportSource,
  RoadReferences,
  Role,
  RouteType,
  SetRolePermissionsInput,
  ShiftType,
  Status,
  UnitVehicle,
  UpdateIncidentConfigurationInput,
  UpdateRoleInput,
  VehicleType,
} from '../models/gql.models';
import { PermissionsGroup } from '../models/PermissionsGroup';
import { AccountService } from '../services/account.service';
import { LocalStorageService } from '../services/local-storage.service';
import { RolesService } from '../services/roles.service';
import { RoutesService } from '../services/routes.service';
import { ShiftsService } from '../services/shifts.service';
import { AppFeatureEnum, SplitIOService } from '../services/split-io.service';

interface PartCreateRoleInput extends Omit<CreateRoleInput, 'accountId'> {}

@Injectable({
  providedIn: 'root',
})
export class AccountStore {
  @observable account!: Account;
  @observable roles: Role[] = [];
  @observable mitigationTypes: MitigationType[] = [];
  @observable modifiedMitigation: MitigationType[] = [];
  @observable modifiedAdditionalInfo: AdditionalInfo[] = [];
  @observable modifiedReportedBy: ReportSource[] = [];
  @observable modifiedShiftTypes!: ShiftType[];
  @observable unitsByAccount: Partial<UnitVehicle>[] = [];
  @observable unitsByWorkspaces: UnitVehicle[] = [];
  @observable routeTypes: RouteType[] = [];
  @observable roadReferences: RoadReferences = { refs: [] };
  @observable allMitigationTypes: MitigationType[] = [];
  incidentConfigs: IncidentConfiguration = {
    additionalInfoMandatory: false,
    additionalInfoValues: [],
    affectedLanesMandatory: false,
    associatedUnitsMandatory: false,
    directionMandatory: false,
    casualtiesMandatory: false,
    incidentSubTypes: {
      abandonedVehicleSubTypeValues: [],
      crashSubTypeValues: [],
      damageSubTypeValues: [],
      debrisSubTypeValues: [],
      hazardSubTypeValues: [],
      policeActivitySubTypeValues: [],
      stalledVehicleSubTypeValues: [],
      trafficStopSubTypeValues: [],
    },
    incidentTypeValues: [],
    mitigationTypeValues: [],
    notesMandatory: false,
    orientationMandatory: false,
    orientationValues: [],
    roadTypeValues: [],
    sourceMandatory: false,
    sourceValues: [],
    responsePlanMandatory: false,
    severityMandatory: false,
    severityValues: [],
    attributesMandatory: false,
    attributeFixedValues: [],
    attributeFreeTextValues: [],
  };

  set setAccount(account: Account) {
    this.account = account;
  }

  constructor(
    private rolesService: RolesService,
    private accountService: AccountService,
    private uiStore: UiStore,
    private shiftsService: ShiftsService,
    private routesService: RoutesService,
    private translateService: TranslateService,
    private localStorage: LocalStorageService,
    private splitIoService: SplitIOService
  ) {
    makeObservable(this);
    this.uiStore.showLoader('incidentConfigurations');
  }

  @computed
  get rolesTableFormat() {
    return this.roles.map(role => {
      role['permissionsNames'] = [];
      const controllersGroups = this.rolePermissionsControllersGroups(role);
      for (const entity in controllersGroups) {
        if (controllersGroups.hasOwnProperty(entity)) {
          const group = controllersGroups[entity];
          const permissionStr = ` ${entity}(${group?.value})`;
          role['permissionsNames'].push(permissionStr.toLowerCase());
        }
      }
      return role;
    });
  }

  @computed
  get permissionsGroupControls() {
    const enm = Enums.permissionsGroupsEnum;
    const permissions: {
      [entityName in string]?: {
        label: string;
        entity: string;
        value: Enums.permissionsGroupsEnum;
        permissions?: { [permissionId in string]: Permission };
        options: string[];
        [enm.Manager]?: Permission[];
        [enm.Viewer]?: Permission[];
        [enm.NoAccess]?: null;
      };
    } = {};

    this.account.groups.forEach((permissionGroup: PermissionGroup) => {
      permissionGroup.permissions.forEach((permission: Permission) => {
        const newPermission: {
          label: string;
          entity: string;
          value: Enums.permissionsGroupsEnum;
          permissions?: { [permissionId in string]: Permission };
          options: string[];
          [enm.Manager]?: Permission[];
          [enm.Viewer]?: Permission[];
          [enm.NoAccess]?: null;
        } = {
          label: 'permissions.' + permission.scope.entity,
          entity: permission.scope.entity,
          value: enm.NoAccess,
          options: [enm.Manager, enm.Viewer, enm.NoAccess],
          [enm.Manager]: [] as Permission[],
          [enm.Viewer]: [] as Permission[],
          [enm.NoAccess]: null,
        };
        const _permission = permissions[permission.scope.entity] || newPermission;
        _permission[permission.scope.modifier] = permission;
        if (permission.scope.modifier === AccessModifier.Read) _permission.VIEWER?.push(permission);
        _permission.MANAGER?.push(permission);
        permissions[permission.scope.entity] = _permission;
      });
    });
    delete permissions.CAMERA;

    Object.keys(AppFeatureEnum).forEach(key => {
      if (!this.splitIoService.isActiveFeatureToggle(AppFeatureEnum[key])) {
        if (permissions[key]) {
          delete permissions[key];
        } else {
          key = key.replace('FE_', '');
          delete permissions[key];
        }
      }
    });
    return permissions;
  }

  @computed
  get accountModulesTypes() {
    return this.account.groups.map(accountModule => accountModule.type);
  }

  @computed
  get rolesOptions() {
    if (!this.roles) this.loadRoles();
    const roles: SelectOption[] = [];
    this.roles.forEach((role: Role) => {
      roles.push({ value: role.id, displayName: role.name });
    });
    return roles;
  }

  @computed
  get reportSourceOptions() {
    return this.incidentConfigs?.sourceValues
      .filter(({ status }) => status === Status.Active)
      .map(s => ({ value: s.id, displayName: s.reportSource }));
  }

  @computed
  get vehicleTypesOptions(): SelectOption[] {
    const unitVehiclesTypes = Utils.toArray(this.unitsByWorkspaces.map(unit => unit.type));
    const optionsOrder = [
      VehicleType.CityPoliceUnit,
      VehicleType.HighwayPoliceUnit,
      VehicleType.FspUnit,
      VehicleType.EmsUnit,
      VehicleType.FireDepartmentUnit,
      VehicleType.ConstructionUnit,
      VehicleType.MaintenanceUnit,
      VehicleType.SnowPlowUnit,
      VehicleType.StreetSweepUnit,
      VehicleType.OtherUnit,
    ];
    let _options: SelectOption[] = Utils.strArrayToOptions(uniqWith(unitVehiclesTypes, isEqual), {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'VehicleTypes',
    });
    _options = sortBy(_options, item => indexOf(optionsOrder, item.value));
    return _options;
  }

  getVehicleTypeOption(vehicleType: VehicleType): SelectOption {
    return {
      value: vehicleType,
      displayName: this.translateService.instant(`VehicleTypes.${vehicleType}`),
    };
  }

  @computed
  get unitsOptionsByWorkspaces() {
    const units: SelectOption[] = [];
    this.unitsByWorkspaces.forEach(unit => {
      units.push({
        value: unit.id,
        displayName: unit.displayId,
        data: unit,
      });
    });
    units.sort((unit1, unit2) => {
      return (unit1.displayName as string)
        .toLocaleLowerCase()
        .localeCompare((unit2.displayName as string).toLocaleLowerCase());
    });

    return units;
  }

  @computed
  get unitsOptionsByAccount() {
    const units: SelectOption[] = [];
    this.unitsByAccount.forEach(unit => {
      units.push({
        value: unit.id,
        displayName: unit.displayId,
        data: unit,
      });
    });
    units.sort((unit1, unit2) => {
      return (unit1.displayName as string)
        .toLocaleLowerCase()
        .localeCompare((unit2.displayName as string).toLocaleLowerCase());
    });

    return units;
  }

  @computed
  get mitigationsOptions(): SelectOption[] {
    return (
      this.incidentConfigs?.mitigationTypeValues?.map<SelectOption>(({ id, description }) => ({
        value: id,
        displayName: description,
      })) || []
    );
  }

  @computed
  get allMitigationOptions(): SelectOption[] {
    const sortedMitigationTypes = toJS(this.allMitigationTypes).sort((a, b) => {
      return a.description.localeCompare(b?.description, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });

    const activeFirstMitigationTypes = sortedMitigationTypes.sort(function (a, b) {
      return Number(a.isDeleted) - Number(b.isDeleted);
    });

    return (
      activeFirstMitigationTypes?.map<SelectOption>(({ id, description, isDeleted }) => ({
        value: id,
        displayName: isDeleted ? description + ' (' + this.translateService.instant('Del.') + ')' : description,
        disabled: isDeleted,
      })) || []
    );
  }

  @computed
  get accountMitigations(): MitigationType[] {
    const filteredDefaultMitigation = this.account.mitigationTypes.filter(mitigation => {
      return mitigation.id !== environment.defaultMitigationTypeId;
    });

    return Utils.alphaNumericSort(filteredDefaultMitigation, 'description');
  }

  @computed
  get additionalInfosOptions(): SelectOption[] {
    return (
      this.incidentConfigs?.additionalInfoValues?.map<SelectOption>(({ id, info }) => ({
        value: id,
        displayName: info,
      })) || []
    );
  }

  @computed
  get accountAdditionalInfos(): AdditionalInfo[] {
    return Utils.alphaNumericSort(this.account.additionalInfos, 'info');
  }

  @computed
  get activeRouteTypes(): RouteType[] {
    return this.routeTypes.filter(route => route.status === Status.Active);
  }

  @computed
  get RoutesOptions(): SelectOption[] {
    const options: SelectOption[] = this.routeTypes.map(route => {
      const option: SelectOption = {
        value: route.id,
        displayName:
          route.status === Status.Inactive
            ? route.name + ' (' + this.translateService.instant('Del.') + ')'
            : route.name,
      };
      return option;
    });
    return options;
  }

  @computed
  get workspacesOptions(): SelectOption[] {
    const options = this.account.workspaces.workspaces
      .map<SelectOption>(({ id, title }) => ({
        value: id,
        displayName: title,
      }))
      .sort((a, b) => (a.displayName as string).localeCompare(b.displayName as string));

    options.unshift(
      options.splice(
        options.findIndex(o => o.value === 1),
        1
      )[0]
    );
    return options;
  }

  @computed
  get ShiftsOptions(): SelectOption[] {
    const options: SelectOption[] = this.account.shiftTypes.map(shift => {
      const option: SelectOption = {
        value: shift.id,
        displayName:
          shift.status === Status.Inactive
            ? shift.name + ' (' + this.translateService.instant('Del.') + ')'
            : shift.name,
      };
      return option;
    });
    return options;
  }

  rolePermissionsControllersGroups(role: Role) {
    const permissionsControllers: {
      [entityName in string]?: {
        value?: string;
        modifiers: Array<AccessModifier>;
      };
    } = {};

    role.permissions.forEach((permission: Permission) => {
      let controller = permissionsControllers[permission.scope.entity];
      controller = controller || { modifiers: [] };
      controller.modifiers.push(permission.scope.modifier);
      permissionsControllers[permission.scope.entity] = controller;
    });

    for (const key in permissionsControllers) {
      if (permissionsControllers.hasOwnProperty(key)) {
        const permissionControler = permissionsControllers[key];
        if (key === 'ACCOUNT' && permissionControler?.modifiers.includes(AccessModifier.Update)) {
          permissionControler.modifiers.push(AccessModifier.Create);
          if (!permissionControler.modifiers.includes(AccessModifier.Delete)) {
            permissionControler.modifiers.push(AccessModifier.Delete);
          }
        }

        if (permissionControler) {
          const grp = PermissionsGroup.find(group => {
            return Utils.arraysEqual(group.modifiers, permissionControler.modifiers);
          });
          permissionControler.value = grp ? grp.type : Enums.permissionsGroupsEnum.NoAccess;
        }
      }
    }
    return permissionsControllers;
  }

  @computed
  get roadsOptions(): SelectOption[] {
    const options: SelectOption[] = this.roadReferences.refs.map(road => {
      const option: SelectOption = {
        value: road,
        displayName: road,
      };
      return option;
    });
    return options;
  }

  @computed
  get incidentSubTypeStructure() {
    return {
      ABANDONED_VEHICLE: Utils.EnumToOptions(IncidentAbandonedVehicleSubType, {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'incidentSubTypes',
        visibleValues: this.incidentConfigs.incidentSubTypes.abandonedVehicleSubTypeValues as string[],
      }),

      CRASH: Utils.selectedOptionsAlphaNumericSort(
        Utils.EnumToOptions(IncidentCrashSubType, {
          translateService: this.translateService,
          translateBy: 'value',
          translatePath: 'incidentSubTypes',
          visibleValues: this.incidentConfigs.incidentSubTypes.crashSubTypeValues as string[],
        })
      ),

      DAMAGE: Utils.EnumToOptions(IncidentDamageSubType, {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'incidentSubTypes',
        visibleValues: this.incidentConfigs.incidentSubTypes.damageSubTypeValues as string[],
      }),

      DEBRIS: Utils.EnumToOptions(IncidentDebrisSubType, {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'incidentSubTypes',
        visibleValues: this.incidentConfigs.incidentSubTypes.debrisSubTypeValues as string[],
      }),

      HAZARD: Utils.EnumToOptions(IncidentHazardSubType, {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'incidentSubTypes',
        visibleValues: this.incidentConfigs.incidentSubTypes.hazardSubTypeValues as string[],
      }),

      POLICE_ACTIVITY: Utils.EnumToOptions(IncidentPoliceActivitySubType, {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'incidentSubTypes',
        visibleValues: this.incidentConfigs.incidentSubTypes.policeActivitySubTypeValues as string[],
      }),

      STALLED_VEHICLE: Utils.EnumToOptions(IncidentStalledVehicleSubType, {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'incidentSubTypes',
        visibleValues: this.incidentConfigs.incidentSubTypes.stalledVehicleSubTypeValues as string[],
      }),

      TRAFFIC_STOP: Utils.EnumToOptions(IncidentTrafficStopSubType, {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'incidentSubTypes',
        visibleValues: this.incidentConfigs.incidentSubTypes.trafficStopSubTypeValues as string[],
      }),
    };
  }

  @action
  loadRoles() {
    return this.rolesService.rolesByAccount(this.account.id).pipe(
      tap(roles => {
        this.roles = [...roles];
      })
    );
  }

  @action
  loadUnitsByAccount() {
    return this.accountService.unitsByAccount().pipe(
      tap(unitsVehicles => {
        this.unitsByAccount = [...unitsVehicles];
      })
    );
  }

  @action
  loadUnitsByWorkspaces() {
    return this.accountService.unitsByWorkspaces().pipe(
      tap(unitsVehicles => {
        this.unitsByWorkspaces = [...unitsVehicles];
      })
    );
  }

  @action
  loadShiftTypes() {
    return this.shiftsService.accountShiftTypes(this.account.id).pipe(
      tap(shiftTypes => {
        this.account.shiftTypes = shiftTypes;
      })
    );
  }

  @action
  loadRouteTypes() {
    return this.routesService.accountRouteTypes(this.account.id).pipe(
      tap(routeTypes => {
        this.account.routeTypes = routeTypes;
      })
    );
  }

  @action
  updateRoles(role: Role) {
    const roleIndex = this.roles.findIndex((_role: Role) => _role.id === role.id);
    this.roles[roleIndex] = role;
    this.roles = [...this.roles];
  }

  @action
  updateRole(inputSetPremission: SetRolePermissionsInput, input: UpdateRoleInput) {
    return this.rolesService.setRolePermissions(inputSetPremission).pipe(
      tap((role: Role) => {
        this.rolesService.updateRole(input).subscribe(_ => {
          role.name = input.name as string;
          role.description = input.description as string;
          this.updateRoles(role);
        });
      })
    );
  }

  @action
  createRole(roleInput: PartCreateRoleInput) {
    let input: CreateRoleInput = {} as CreateRoleInput;
    input = { ...input, ...roleInput };
    return this.rolesService.createRole(input).pipe(
      tap((role: Role) => {
        this.roles.push(role);
        this.roles = [...this.roles];
      })
    );
  }

  @action
  deleteRole(input: DeleteRoleInput) {
    return this.rolesService.deleteRole(input).pipe(
      tap(() =>
        this.roles.forEach((_role, index) => {
          if (input.roleId === _role.id) this.roles.splice(index, 1);
          this.roles = [...this.roles];
        })
      )
    );
  }

  @action
  loadAllMitigationsTypes() {
    return this.accountService.accountMitigationTypes(true).pipe(
      tap(mitigationTypes => {
        this.allMitigationTypes = mitigationTypes;
      })
    );
  }

  @action
  loadAccountMitigations(getAll: boolean = false) {
    return this.accountService.accountMitigationTypes(getAll).pipe(
      tap(mitigationTypes => {
        this.account.mitigationTypes = mitigationTypes;
        this.mitigationTypes = mitigationTypes;
        this.modifiedMitigation = [];
      })
    );
  }

  @action
  loadAccountShiftTypes(accountId) {
    this.shiftsService.accountShiftTypes(accountId).subscribe(shiftTypes => {
      this.account.shiftTypes = shiftTypes;
      this.modifiedShiftTypes = [];
    });
  }

  @action
  accountInfoSubmit() {
    return this.accountService
      .updateAccountInfo(this.account, this.modifiedShiftTypes)
      .pipe(tap(() => this.loadAccountShiftTypes(this.account.id)));
  }

  @action
  loadWorkspacesRoadReferences() {
    return this.accountService.workspacesRoadReferences().pipe(tap(res => set(this.roadReferences, res)));
  }

  updateIncidentConfiguration(input: UpdateIncidentConfigurationInput) {
    return this.accountService.updateIncidentConfiguration(input).pipe(
      tap(val => (this.incidentConfigs = val)),
      catchError(e => throwError(e))
    );
  }

  get incidentTypeConfigsData(): FormFieldData<IncidentType, { isExpanded: boolean }> {
    let options: FormFieldOption<IncidentType, { isExpanded: boolean }>[] = [];

    Object.values(IncidentType).forEach(filed => {
      if (filed !== IncidentType.UnknownIncidentType && filed !== IncidentType.Unidentified) {
        options.push({
          selected: this.incidentConfigs?.incidentTypeValues.includes(filed),
          value: filed,
          data: { isExpanded: this.incidentSubTypeStructure[filed] ? true : false },
          displayName: this.translateService.instant('incidentTypes.' + filed),
        });
      }
    });
    return { options: options };
  }

  // Called from IncidentConfigInitService
  loadIncidentConfiguration(): Observable<IncidentConfiguration> {
    return this.accountService.loadIncidentConfiguration().pipe(
      tap(configs => {
        this.incidentConfigs = configs;
        this.localStorage.set(LocalStorageKeys.IncidentConfigs, this.incidentConfigs);
      }),
      catchError(err => {
        const configs = this.localStorage.get(LocalStorageKeys.IncidentConfigs);
        if (configs) {
          console.error('Get incident config failed - loading incidentConfig from localStorage');
          this.incidentConfigs = configs;
          return of(err);
        } else {
          console.error('Get incident config failed - no incidentConfig in localStorage -> refresh page');
          this.localStorage.set(LocalStorageKeys.IncidentConfigs, this.incidentConfigs);
          window.location.reload();
          return throwError(err);
        }
      }),
      finalize(() =>
        setTimeout(() => {
          this.uiStore.hideLoader('incidentConfigurations');
        })
      )
    );
  }
}
