<div class="route-drawing-modal">
  <div class="modal-header">
    {{ this.dialogTitle | translate }}

    <button class="close-modal-button" (click)="closeModal()">
      <i class="far fa-times"></i>
    </button>
  </div>

  <div class="modal-content">
    <wc-map #wcMapComp [forceTileLayer]="true"></wc-map>
  </div>

  <div class="modal-footer">
    <ng-container *ngIf="formViewMode === formViewModeEnum.Edit">
      <div class="route-control-buttons">
        <i class="fas fa-trash" (click)="clear()"></i>
      </div>
      <button class="apply-button" (click)="confirmCloseModal()">
        {{ 'applyPolygonFilter' | translate }}
      </button>
    </ng-container>
  </div>
</div>
