import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { TranslateModule } from '@ngx-translate/core';

import { RoadsComponent } from './roads.component';
import { EditableListComponentModule } from '../../form-fields-controls/editable-list/editable-list.component.module';

// const Material: any[] = [
// ]
@NgModule({
  declarations: [RoadsComponent],
  imports: [
    CommonModule,
    // Material,
    TranslateModule,
    WcBaseControlFieldModule,
    EditableListComponentModule,
  ],
  providers: [],
  exports: [RoadsComponent],
})
export class RoadsComponentModule {}
