import { WcMapConfigModel } from '@wc/core/models';
import * as mapboxgl from 'mapbox-gl';
import Layer from 'ol/layer/Layer';
import Map from 'ol/Map';
import { toLonLat } from 'ol/proj';
import { XYZ } from 'ol/source';
import { creditText } from './config';

const token = 'pk.eyJ1Ijoid2F5Y2FyZSIsImEiOiJjanp1eTd4aXAwMGt0M2NueHh3dXI0dWpiIn0.v27dO5Fkf58A8s2WzJi34A';

export function mapboxglLayer(map: Map, mapConfig: WcMapConfigModel, mapStyle?: string): Layer {
  const mapStyleName = mapConfig.style[mapConfig.currentStyle];
  mapStyle = mapStyle || `${mapConfig.tilesSrc}/styles/${mapStyleName}/style.json`;
  mapboxgl.accessToken = token;

  if (mapboxgl.getRTLTextPluginStatus() !== 'loaded') {
    try {
      mapboxgl.setRTLTextPlugin(
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
        null,
        true // Lazy load the plugin
      );
    } catch (err) {
      console.log(err);
    }
  }

  const mbMap = new mapboxgl.Map({
    container: map.getTargetElement(),
    style: mapStyle,
    // style: `${mapConfig.tilesSrc}/styles/${mapStyle}/style.json`,
    // style: `https://api.mapbox.com/styles/v1/waycare/ckqeyhnka2mq918o4ec0gepyb?access_token=${token}`,
    attributionControl: false,
    boxZoom: false,
    doubleClickZoom: false,
    dragPan: false,
    dragRotate: false,
    interactive: false,
    keyboard: false,
    pitchWithRotate: false,
    scrollZoom: false,
    touchZoomRotate: false,
  });

  const _layer = new Layer({
    render: function (frameState) {
      mbMap.resize();
      const canvas = mbMap.getCanvas();
      const viewState = frameState.viewState;

      const visible = _layer.getVisible();
      canvas.style.display = visible ? 'block' : 'none';
      canvas.style.position = 'absolute';

      const opacity = _layer.getOpacity();
      canvas.style.opacity = opacity;

      // // adjust view parameters in mapbox
      const rotation = viewState.rotation;
      mbMap.jumpTo({
        center: toLonLat(viewState.center),
        zoom: viewState.zoom - 1,
        bearing: (-rotation * 180) / Math.PI,
        animate: false,
      });

      // cancel the scheduled update & trigger synchronous redraw
      // see https://github.com/mapbox/mapbox-gl-js/issues/7893#issue-408992184
      // NOTE: THIS MIGHT BREAK IF UPDATING THE MAPBOX VERSION
      if (mbMap._frame) {
        mbMap._frame.cancel();
        mbMap._frame = null;
      }
      mbMap._render();

      return canvas;
    },

    source: new XYZ({
      attributions: [creditText],
      attributionsCollapsible: false,
    }),
  });

  return _layer;
}
