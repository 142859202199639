import { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import * as selectOptions from 'ol/interaction/Select';
import * as overlayOptions from 'ol/Overlay';
import { Pixel } from 'ol/pixel';
import * as circleOptions from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import * as iconOptions from 'ol/style/Icon';
import { Options } from 'ol/style/RegularShape';
import * as textOptions from 'ol/style/Text';
import { CustomStyleContextEnum, InteractionsEvents, mapVendorName, StaticLayerName } from './enums';
import { StyleOptionsPerGeometryType, wcFeatureProperties } from './interfaces';
import { WcFeature } from './lib/classes/wc-feature.class';

export type EntityUIState = 'default' | 'disabled' | 'hover' | 'selected' | `${CustomStyleContextEnum}`;

export type GeometryStyle<FStatuses extends string | number | symbol> = {
  [state in FStatuses]?: {
    [uiState in EntityUIState]?: StyleArgs[];
  };
};

export type GeometryTypeName =
  | 'startPointStatusStyle'
  | 'endPointStatusStyle'
  | 'pointStatusStyle'
  | 'lineStringStatusStyle'
  | 'multiLineStringStatusStyle'
  | 'polygonStatusStyle'
  | 'startPointPolygonStyle';

export type layerVisibility<layerNames extends string> = {
  layerName: layerNames;
  checked: boolean;
  label: string;
};

export type RequireAtLeastOne<T, Keys extends keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export type AddType<T extends Record<string, unknown>, U extends Record<string, unknown>> = {
  [key in keyof T]: T[key] | U;
};

export type normalizedRemovedData = string[];

export type normalizedModifiedData<T extends string> = {
  featuresPropsFlat: wcFeatureProperties<T>[];
  featuresPropsByLayerName: FeaturesPropsByLayerName<T>;
};

export interface FeaturesPropsByLayerName<T = null> {
  [layerName: string]: wcFeatureProperties<T>[];
}

export type StyleArgs = {
  isStyleFn?: boolean;
  shape: EntityShapeType;
  zIndex?: number;
  icon?: IconStyle;
  color?: string | AttributeSourceField;
  darkColor?: string;
  width?: number;
  radius?: number;
  visible?: boolean | AttributeSourceField;
  opacity?: number;
  stroke?: {
    color: string;
    darkColor?: string;
    width: number;
    opacity?: number;
  };
  dash?: [number, number, number, number];
  label?: TextStyle;
  coordinates?: wcCoordinate;
  shapeType?: ShapeType;
};

// & {[K in 'shapeType' as T extends 'regularShape' infer R as ShapeType  ? Required<K> : K ]: T extends 'regularShape' ? ShapeType : ShapeType | undefined;};

export enum ShapeType {
  triangle = 3,
  square = 4,
}

export type wcCoordinate = Coordinate;
export type WcGeometryType = `${GeometryType}`;
export type EntityShapeType =
  | 'marker'
  | 'lineSolid'
  | 'lineDash'
  | 'area'
  | 'circle'
  | 'circleIcon'
  | 'label'
  | 'regularShape';
export type MapBasicInteractionNames =
  | 'dragRotate'
  | 'doubleClickZoom'
  | 'dragPan'
  | 'pinchRotate'
  | 'pinchZoom'
  | 'keyboardPan'
  | 'keyboardZoom'
  | 'mouseWheelZoom'
  | 'dragZoom'
  | 'select'
  | 'hover'
  | 'draw'
  | 'rightClick';
export type CustomMapControls = 'staticLayerControl';
export type DefaultMapControls = 'zoom' | 'rotate' | 'attribution';
export type MapControls = DefaultMapControls | CustomMapControls;
export type DataLayerName = 'traffic' | 'satellite';

export type MapLayer<T extends string, FStatuses extends string | number | symbol> = {
  [layerName in T]?: WcLayerOptions<FStatuses>;
};

export type MapCenterOptions<T extends wcCoordinateTypes> = T extends wcCoordinate
  ? {
      padding?: number[];
      bufferSize?: number;
      zoomLevel: number;
      mapCenter: T;
      duration?: number;
    }
  : {
      padding?: number[];
      bufferSize?: number;
      zoomLevel: number;
      mapCenter: T;
      duration?: number;
    };

export type LocationUpdate = {
  prev?: undefined | wcCoordinateTypes;
  curr?: undefined | wcCoordinateTypes;
};

export type LocationOptions = {
  duration?: number;
  padding?: number[];
  zoomLevel?: number;
  offset?: Offset;
};

export type WcLayerOptions<FStatuses extends string | number | symbol> = {
  clusterDistance?: number;
  zIndex?: number;
  isVisible?: boolean;
  hitTolerance?: number;
  minZoom?: number; //The minimum view zoom level (exclusive) above which this layer will be visible.
  maxZoom?: number; //The maximum view zoom level (inclusive) at which this layer will be visible.
} & Partial<LayerStyle<FStatuses>>;

export type LayerStyle<FStatuses extends string | number | symbol> = {
  [key in GeometryTypeName]: GeometryStyle<FStatuses>;
};

export type SetCenterOptions = {
  zoomLevel?: number;
  duration?: number;
  offset?: Offset;
};

export type FeatureEvent<T extends Record<string, any> | undefined = undefined> = T extends Record<string, any>
  ? T
  : {
      eventType: InteractionsEvents;
      targets: wcFeatureEventTarget[] | undefined;
      additionalInfo?: {
        clickedCoordinates?: wcCoordinate;
      };
    };

export type ControlEvent = {
  eventType: MapControls;
  target?: unknown;
};

export type WcGeometry = {
  type: GeometryType;
  coordinates: wcCoordinateTypes | undefined;
};

export type wcFeatureEventTarget = {
  feature?: Feature;
  featureId: string | undefined;
  geometry: WcGeometry | undefined;
  fProps: wcFeatureProperties | wcFeatureProperties[];
};

export type EditGeometryOptions = {
  showReference?: boolean;
  referenceStyleArgs?: StyleOptionsPerGeometryType;
  editStyleArgs?: StyleOptionsPerGeometryType;
};

export type StaticLayerConfig = {
  name: StaticLayerName;
  isVisible?: boolean;
};

export type FeaturePropChanges = {
  featureId: string;
  preV: unknown;
  curV: unknown;
  propName: keyof wcFeatureProperties | 'all';
  parentLayerName: string;
};

export type LayerPropChanges = {
  isVisible: boolean;
  layerName: string;
};

export type FeatureStyleArgs = {
  shape: EntityShapeType;
  zIndex?: number;
  icon?: IconStyle;
  color?: string;
  darkColor?: string;
  width?: number;
  radius?: number;
  opacity?: number;
  stroke?: {
    color: string;
    darkColor?: string;
    width: number;
    opacity?: number;
  };
  dash?: [number, number, number, number];
  label?: TextStyle;
};

export type TextStyle = {
  color?: string;
  darkColor?: string;
  textAlign?: 'left' | 'right' | 'center' | 'end' | 'start';
  textBaseline?: 'bottom' | 'top' | 'middle' | 'alphabetic' | 'hanging' | 'ideographic';
  fontFamily?: string;
  text?: string | AttributeSourceField;
  fill?: Fill;
  offsetX?: number;
  offsetY?: number;
  placement?: 'point' | 'line';
  maxAngle?: number;
  overflow?: boolean;
  rotation?: number;
  width?: number;
  height?: number;
  size?: number;
  weight?: number;
  outlineColor?: string;
  darkOutlineColor?: string;
  outlineWidth?: number;
};
export type StaticLayerNameUnion = typeof StaticLayerName;

export type staticLayerLoadEvent = 'pending' | 'ready' | null;

export type FitMapViewToExtentOptions = Omit<LocationOptions, 'zoomLevel'>;

export type wcCoordinateTypes = wcCoordinate | wcCoordinate[] | wcCoordinate[][];

export type AttributeSourceField = {
  sourceField: keyof wcFeatureProperties<string>;
};

export type Offset = RequireAtLeastOne<{ x: number; y: number }, 'x' | 'y'>;

export type baseStyleProps = {
  color: string;
  width?: number;
  opacity?: number;
  zIndex?: number;
};

export interface IconStyle {
  src?: string;
  anchor?: Array<number>;
  scale?: number;
  rotation?: number | AttributeSourceField;
  opacity?: number;
  iconName?: string | AttributeSourceField;
  zIndex?: number;
  dynamicNamePrefixSrcFiled?: string; // example {iconName: '.hovered' , dynamicNamePrefixSrcFiled:'crash'} -> in style iconName will be merged to crash.hovered
}

export type IconStyleArgs = {
  imageConfig: IconStyle;
  zIndex?: number;
  coordinates?: wcCoordinate;
};

export type RegularShapeStyleArgs = {
  imageConfig: Options;
  rotation: number;
  zIndex?: number;
  coordinates?: wcCoordinateTypes;
  color: string;
  stroke: strokeStyleArgs;
  radius?: number;
};

export type TextStyleArgs = {
  color?: string;
  size?;
  height?;
  weight?;
  fontFamily?;
  outlineColor?;
  outlineWidth?;
} & textOptions.Options;

export type BaseCircleArgs = Omit<baseStyleProps, 'width'> & { radius: number };
export type SolidLineStyleArgs = baseStyleProps;
export type DashLineStyleArgs = baseStyleProps & { dash?: [number, number, number, number] };
export type AreaStyleArgs = Omit<baseStyleProps, 'width'> & Required<Pick<baseStyleProps, 'opacity'>>;
export type strokeStyleArgs = Omit<baseStyleProps, 'zIndex'> & Required<Pick<baseStyleProps, 'width'>>;
export type CircleStyleArgs = BaseCircleArgs & { stroke: strokeStyleArgs };
export type CircleOptions = circleOptions.Options;
export type IconOptions = iconOptions.Options;
export type wcOverlayOptions = overlayOptions.Options;
export type wcSelectOptions = selectOptions.Options & {
  action?: (feature: WcFeature | Feature<Geometry> | undefined, pixel: Pixel) => void;
};
export type StaticLayersUserSelection = Record<
  StaticLayerName.satelliteMap | StaticLayerName.mapBoxTraffic | StaticLayerName.mileMarkers,
  boolean
>;

type mapBoxStaticLayer = {
  styleUrl?: string;
  mapboxKey: string;
};

type rekorStaticLayer = {
  url: string;
  styleUrl?: string;
};

type SatelliteLayer = {
  satelliteApiKey: string;
  vendorName: mapVendorName;
};

// TODO: in ${string}${Uppercase<mapVendorName>} replace string with StaticLayerName constrains. update StaticLayerName to new consistent names
type BaseStaticLayerOption<TName extends `${string}${Uppercase<mapVendorName>}`, TType extends StaticLayerName> = {
  name: TName;
  type: TType;
};

export type StaticLayerOptions =
  | (BaseStaticLayerOption<'satelliteAWS', StaticLayerName.satelliteMap> & SatelliteLayer & Required<mapBoxStaticLayer>)
  | (BaseStaticLayerOption<'satelliteMAPBOX', StaticLayerName.satelliteMap> & SatelliteLayer)
  | (BaseStaticLayerOption<'trafficMAPBOX', StaticLayerName.mapBoxTraffic> & mapBoxStaticLayer)
  | (BaseStaticLayerOption<'mileMarkerREKOR', StaticLayerName.mileMarkers> & rekorStaticLayer)
  | (BaseStaticLayerOption<'vectorMapMAPBOX', StaticLayerName.vectorMap> & mapBoxStaticLayer);

export type StaticLayerOptionNames<T extends StaticLayerName | null = null> = (T extends null
  ? StaticLayerOptions
  : Extract<StaticLayerOptions, { type: T }>)['name'];
