import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormatsPipesModule } from '@wc/wc-common/pipes';
import { DmssListComponent } from './dmss-list.component';


@NgModule({
  declarations: [DmssListComponent],
  imports: [CommonModule, TranslateModule, FormatsPipesModule],
  exports: [DmssListComponent],
})

export class DMSsListComponentsModule {}
