import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormatsPipesModule, HighlightTextPipeModule } from '@wc/wc-common/pipes';
import { WcBaseControlFieldModule } from '../../lib/base/base-control-field.module';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { MapSearchAutocompleteComponent } from './map-search-autocomplete.component';

const Materials: any[] = [
  MatSelectModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatAutocompleteModule,
  MatOptionModule,
  MatInputModule,
];

@NgModule({
  declarations: [MapSearchAutocompleteComponent],
  imports: [
    CommonModule,
    WcBaseControlFieldModule,
    Materials,
    FormatsPipesModule,
    WcIconModule,
    HighlightTextPipeModule,
  ],
  providers: [],
  exports: [MapSearchAutocompleteComponent],
})
export class MapSearchAutocompleteComponentModule {}
