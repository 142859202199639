import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wc-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  @Output() hide = new EventEmitter<void>();
  @Input() triangleTip!: boolean;
  @Input() triangleLocation: 'top-right' | 'bottom' = 'top-right';
  @Input() showCloseIcon = false;
  @Input() variant: 'light' | 'dark' = 'light';

  hideNotification() {
    this.hide.emit();
  }
}
