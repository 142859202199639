import { SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { HeapAnalyticsService, LocalStorageService } from '@wc-core';
import { APP_TYPE_TOKEN } from '@wc/wc-core/src/lib/injection-tokens';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import {
  AbstractLayerTreePanelService,
  AppTypeUnion,
  LayerTreePanelNode,
  LayerVisibilityItem,
} from '@wc/wc-models/src';
import { takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'wc-layer-tree-panel',
  templateUrl: './layer-tree-panel.component.html',
  styleUrls: ['./layer-tree-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayerTreePanelComponent implements OnInit, OnDestroy {
  @Output() hidePanel = new EventEmitter<boolean>();
  @Input() showEventTree = false;
  @Input() isAllWorkspacesUnchecked = false;
  @Input() isPortraitDesktopMode = false;

  isAlive = true;
  private nodesMap!: Map<string, LayerTreePanelNode>;
  treeControl: NestedTreeControl<LayerTreePanelNode, LayerTreePanelNode>;
  dataSource: MatTreeNestedDataSource<LayerTreePanelNode>;
  selectedNodeModel = new SelectionModel<LayerTreePanelNode>(true);

  get appType() {
    return this._appType;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private layerPanelService: AbstractLayerTreePanelService,
    private heapService: HeapAnalyticsService,
    private localStorageService: LocalStorageService,
    @Inject(APP_TYPE_TOKEN) private _appType: AppTypeUnion
  ) {
    this.treeControl = new NestedTreeControl<LayerTreePanelNode>(node => node.children);
    this.dataSource = new MatTreeNestedDataSource<LayerTreePanelNode>();
  }

  ngOnInit(): void {
    this.receiveTreeData();
    this.receiveNodeUpdates();
    this.restoreExpandedState();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  hasChildWithParent = (_, node: LayerTreePanelNode) => node.children && node.children.length > 0 && node.hasParent;
  isRootLevelNode = (_, node: LayerTreePanelNode) => !node.hasParent && node.children !== null;
  childrenEqualNull = (_, node: LayerTreePanelNode) => node.children === null;

  onCheckboxClick(event) {
    event.stopPropagation();
  }

  getNodeRef(name: string): LayerTreePanelNode | undefined {
    return this.nodesMap.get(name);
  }

  updateNode(node: LayerTreePanelNode, checked: boolean): void {
    node.checked = checked;

    this.heapService.trackUserSpecificAction(`heap-desktop-${node.name}-checkbox`);
    if (node.children?.length) {
      this.updateNodeChildrenCheckedStatus(node);
    }

    // It's important to keep this here, so the "action" func will get final state after all updates:
    node.action?.(this, node);

    this.layerPanelService.handleUITreePanelStateUpdates(
      this.getShallowFlatTreeData([...this.treeControl.getDescendants(node), node])
    );
  }

  descendantsAllSelected(node: LayerTreePanelNode): boolean {
    return !!this.treeControl.getDescendants(node).every(child => child.checked);
  }

  descendantsPartiallySelected(node: LayerTreePanelNode): boolean {
    return !this.descendantsAllSelected(node) && !!this.treeControl.getDescendants(node).some(child => child.checked);
  }

  setCollapseState(node: LayerTreePanelNode) {
    const isExpanded = !this.treeControl.isExpanded(node);
    const expandedState = this.localStorageService.get(LocalStorageKeys.ExpandedLayerPanelNodeNames) || [];
    this.localStorageService.set(
      LocalStorageKeys.ExpandedLayerPanelNodeNames,
      isExpanded ? [...expandedState, node.name] : expandedState.filter(n => n !== node.name)
    );
  }

  private restoreExpandedState() {
    const expandedNodes = this.localStorageService.get(LocalStorageKeys.ExpandedLayerPanelNodeNames) || [];
    expandedNodes.forEach(name => {
      const node = this.nodesMap.get(name);
      if (node) {
        this.treeControl.expand(node);
      }
    });
  }

  private receiveNodeUpdates() {
    this.layerPanelService
      .getNodesUpdate()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(shallowNodesUpdate => shallowNodesUpdate.forEach(node => this.handleNodeUpdate(node)));
  }

  private handleNodeUpdate(shallowNode: LayerVisibilityItem) {
    {
      const nodeRef = this.getNodeRef(shallowNode.name);
      if (nodeRef) {
        this.updateNode(nodeRef, shallowNode.checked);
        this.cd.markForCheck();
      } else {
        console.error(`node with name: ${shallowNode.name} was not found`);
      }
    }
  }

  private receiveTreeData() {
    this.layerPanelService
      .getTreeData()
      .pipe(
        takeWhile(() => this.isAlive),
        tap(tree => {
          this.nodesMap = this.getFlatTreeData(tree).asMap;
        })
      )
      .subscribe(tree => {
        this.dataSource.data = tree;
        this.treeControl.dataNodes = tree;
      });
  }

  private updateNodeChildrenCheckedStatus(node: LayerTreePanelNode) {
    const traverse = (_node: LayerTreePanelNode) => {
      _node.checked = node.checked;
      if (!_node.children) return;
      _node.children?.forEach(child => {
        child.checked = node.checked;
        traverse(child);
      });
    };

    traverse(node);
  }

  private getFlatTreeData(tree: LayerTreePanelNode[]): {
    asArray: LayerTreePanelNode[];
    asMap: Map<string, LayerTreePanelNode>;
  } {
    const arrayResults: LayerTreePanelNode[] = [];
    const mapResults = new Map<string, LayerTreePanelNode>();

    const traverse = (node: LayerTreePanelNode) => {
      mapResults.set(node.name, node);
      arrayResults.push(node);
      if (node.children?.length) {
        node.children.forEach(traverse);
      }
    };
    tree.forEach(traverse);
    return {
      asArray: arrayResults,
      asMap: mapResults,
    };
  }

  private getShallowFlatTreeData(tree: LayerTreePanelNode[]): LayerVisibilityItem[] {
    return tree.map(({ checked, name }) => ({ name, checked }));
  }

  closePanel() {
    this.hidePanel.emit();
  }
}
