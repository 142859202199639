import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FeedSource, RegionalSetting } from '@wc/core/models/gql.models';
import { ContextTypesEnum } from '../../../clustering-tooltip-container/clustering-tooltip-container.component';

@Component({
  selector: 'wc-segment-data-source',
  templateUrl: './segment-data-source.component.html',
  styleUrls: ['./segment-data-source.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SegmentDataSourceComponent {
  @Input() dataSources: FeedSource[] = [];
  @Input() set selectedFeedSource(vendorName: FeedSource | undefined) {
    this.segmentSource = vendorName;
  }
  @Input() timeRange!: { startTime: string; endTime: string };
  @Input() context: ContextTypesEnum = ContextTypesEnum.Select;
  @Input() isUpdated = true;
  @Input() regionalSettings!: RegionalSetting;
  @Output() feedSourceChanged: EventEmitter<FeedSource | undefined> = new EventEmitter<FeedSource | undefined>();
  segmentSource: FeedSource | undefined = undefined;

  segmentSourceChanged(feedSource: FeedSource | undefined) {
    this.segmentSource = feedSource;
    this.feedSourceChanged.emit(feedSource);
  }
}
