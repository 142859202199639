import { HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AccountStore, Incident, IncidentStore, NotificationDestination, SplitIOService, UploadStore } from '@wc/core';
import { SUPPORTED_FILE_FORMATS } from '@wc/core/constantes';
import { sortAffectedLanes } from '@wc/utils';
import { UrlUploadEntityEnum } from '@wc/wc-core/src/lib/services/upload.service';
import { FormFieldData, FormFieldOption } from '@wc/wc-ui/src/lib/base';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { takeWhile } from 'rxjs/operators';
import { BaseControlComponent } from '../..';
import {
  Construction,
  DialogData,
  IncidentLane,
  IncidentType,
  LaneType,
  PublicationEntityType,
  PublishInput,
  RoadClosure,
  SpecialEvent,
  TrafficDisruptionLane,
} from '../../../../core/models';
import { FormViewMode } from '../../../../core/models/enums';
import { noWhitespaceValidator } from '../../form-validators/whitespace.validator';
import { DateFormatPipe } from '../../pipes';
/** We should leave it here to save the BaseControlComponent import to avoid build issues. */
let temp!: BaseControlComponent;

const BLOCKED_INCIDENT_TYPES = new Set([IncidentType.Ems, IncidentType.LeftOnArrival, , IncidentType.TrafficStop]);

@Component({
  selector: 'wc-publish-incident-form',
  templateUrl: './publish-incident-form.component.html',
  providers: [DateFormatPipe],
  styleUrls: ['./publish-incident-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublishIncidentFormComponent implements OnInit, OnDestroy {
  private isAlive = true;
  cancel: EventEmitter<void> = new EventEmitter();
  entity!: Incident | RoadClosure | Construction;
  entityType!: PublicationEntityType;
  formViewMode: string | undefined; // Enums.FormViewMode
  allLabels: string[] = [];
  currentDescriptionLength!: number;
  maxValue = 280;
  supportedMediaTypes = [SUPPORTED_FILE_FORMATS.jpg, SUPPORTED_FILE_FORMATS.png];

  form: FormGroup = this.fb.group({
    description: [null, [Validators.maxLength(this.maxValue), Validators.required, noWhitespaceValidator()]],
    destinations: [[], [Validators.required]],
    messageLabels: [],
  });

  fieldsData: { [key in string]: FormFieldData } = {
    messageLabels: {
      options: [
        { displayName: 'expectDelays', value: this.translateService.instant('expectDelays') },
        { displayName: 'useOtherRoutes', value: this.translateService.instant('useOtherRoutes') },
      ],
    },
  };
  affectedLanes: any;

  get showImageSection() {
    return (
      this.form.get('destinations')?.value.includes(NotificationDestination.Twitter) ||
      this.form.get('destinations')?.value.includes(NotificationDestination.GovDelivery) ||
      false
    );
  }

  constructor(
    private dialogRef: MatDialogRef<PublishIncidentFormComponent>,
    private fb: FormBuilder,
    public incidentStore: IncidentStore,
    private translateService: TranslateService,
    public dateFormatPipe: DateFormatPipe,
    private accountStore: AccountStore,
    private splitService: SplitIOService,
    private uploadStore: UploadStore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.formViewMode = data.formViewMode;
    this.entity = cloneDeep(data.details.entity);
    this.entityType = data.details.entityType;
    this.initDestinationOption();
  }

  initDestinationOption() {
    let options: FormFieldOption[] = this.incidentStore.notificationDestinationOptions;

    // need to disable publish for specific source in create and edit , only from view its ok to allow all
    const indexTwitter = options.findIndex(option => option.value === NotificationDestination.Twitter);
    const indexWaze = options.findIndex(option => option.value === NotificationDestination.Waze);
    if (
      (indexTwitter !== -1 &&
        this.entityType === PublicationEntityType.Incident &&
        (this.entity as Incident).autoPublish) ||
      this.formViewMode === FormViewMode.New
    ) {
      options[indexTwitter].disabled = true;
    }

    if (indexWaze !== -1) {
      switch (this.entityType) {
        case PublicationEntityType.Construction:
        case PublicationEntityType.SpecialEvent:
          if (this.entity.location.type === 'Polygon') {
            options[indexWaze].disabled = true;
            options[indexWaze].tooltipText = 'polygonCannotBeAddedToWaze';
          }
          break;
        case PublicationEntityType.Incident:
          if (BLOCKED_INCIDENT_TYPES.has((this.entity as Incident).type)) {
            options[indexWaze].disabled = true;
            options[indexWaze].tooltipText = 'tooltip.incidentTypeCannotBeShare';
          }
          break;
        case PublicationEntityType.RoadClosure:
          if (this.entity.location.type !== 'LineString') {
            options[indexWaze].disabled = true;
            options[indexWaze].tooltipText = 'cannotSharePointAndPolygon';
          }
          break;
      }
    }

    this.fieldsData.destinations = {
      options: options,
    };

    if (this.fieldsData.destinations.options?.length === 1 && !this.fieldsData.destinations.options[0].disabled) {
      this.form.get('destinations')?.setValue([this.fieldsData.destinations.options[0].value]);
    }
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe(val => {
      let description = val.description;
      description = description.replace(/\*/g, '');
      this.currentDescriptionLength = description.length;
    });
    this.form.controls.description.setValue(this.autoDescription(this.entity));
    if (this.entityType !== PublicationEntityType.Incident) {
      this.fieldsData.messageLabels.options?.concat([
        { displayName: 'trafficAdvisory', value: this.translateService.instant('trafficAdvisory') },
        { displayName: 'roadWorks', value: this.translateService.instant('roadWorks') },
      ]);
    }
    this.allLabels = (this.fieldsData.messageLabels.options?.map(o => o.value) as string[]) || [];

    this.form
      .get('messageLabels')
      ?.valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe(label => {
        this.updateMessageLabels(label);
      });

    this.form
      .get('destinations')
      ?.valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((destinations: NotificationDestination[]) => {
        this.maxValue = destinations.includes(NotificationDestination.GovDelivery) ? 150 : 280;
        this.form.get('description')?.setValidators(Validators.maxLength(this.maxValue));
        this.form.get('description')?.updateValueAndValidity();
      });
  }

  autoDescription(entity: Incident | Construction | RoadClosure | SpecialEvent) {
    let txt = '';
    let time = '';
    let address = '';
    let type = '';

    // Address
    if (
      (this.entityType === PublicationEntityType.Construction ||
        this.entityType === PublicationEntityType.RoadClosure ||
        this.entityType === PublicationEntityType.SpecialEvent) &&
      entity.location.type !== 'Point'
    ) {
      address = (entity as RoadClosure | Construction).title ? (entity as RoadClosure | Construction).title : '';
    } else if (entity.address) {
      if (entity.address.direction) address += `${this.translateService.instant(entity.address.direction)} `;
      if (entity.address.corridor) address += `${entity.address.corridor} `;
      if (entity.address.orientation) address += `${this.translateService.instant(entity.address.orientation)} `;
      if (entity.address.crossroad) address += `${entity.address.crossroad}`;
    }

    // Type + Time
    if (this.entityType === PublicationEntityType.Incident) {
      time = `${this.dateFormatPipe.transform(moment.now(), this.accountStore.account.regionalSetting)}`;
      type = `${this.translateService.instant('incidentTypes.' + (entity as Incident).type)}`;
    } else {
      if (this.entityType === PublicationEntityType.Construction) {
        type = `${this.translateService.instant('constructionType.' + (entity as Construction).type)}`;
      } else if (this.entityType === PublicationEntityType.SpecialEvent) {
        type = `${this.translateService.instant('specialEventType.' + (entity as SpecialEvent).type)}`;
      } else {
        type = `${this.translateService.instant('trafficDisruptionTypes.road_closure')}`;
      }

      // future end time
      if (moment(entity['startTime']).isBefore(moment.now()) && entity['endTime']) {
        time = `${
          this.translateService.instant('UNTIL') +
          ' ' +
          this.dateFormatPipe.transform(entity['endTime'], this.accountStore.account.regionalSetting)
        }`;
      }
      // future end time and start time
      else if (entity['endTime'] && moment(entity['startTime']).isAfter(moment.now())) {
        time =
          `${
            this.translateService.instant('FROM') +
            ' ' +
            this.dateFormatPipe.transform(entity['startTime'], this.accountStore.account.regionalSetting)
          }` +
          ` ${
            this.translateService.instant('TO').toLowerCase() +
            ' ' +
            this.dateFormatPipe.transform(entity['endTime'], this.accountStore.account.regionalSetting)
          }`;
      }
      // only start time
      else {
        time = `${
          this.translateService.instant('FROM') +
          ' ' +
          this.dateFormatPipe.transform(moment.now(), this.accountStore.account.regionalSetting)
        }`;
      }
    }

    address = this.parseToShortAddress(address);

    txt += `${time + `\n` + type + ' ' + this.translateService.instant('on') + ' ' + address}`;
    txt += `\n`;
    txt += this.addLanesDescription(entity);

    return txt;
  }

  addLanesDescription(entity: Incident | Construction | RoadClosure | SpecialEvent): string {
    let leftMostLaneBlockedCount = 0;
    let rightMostLaneBlockedCount = 0;
    let centerLaneBlockedCount = 0;
    let leftShoulderBlocked = false;
    let rightShoulderBlocked = false;
    let affectedLanesFullDescription: string[] = [];
    let nearLaneIsClosed = true; // If the all closed lanes are close to each other without open lane between

    //No affected lanes
    if (!entity.affectedLanes?.some(lane => lane.isClosed === true)) {
      return '';
    }

    //All affected lanes
    if (entity.allLanesAffected) {
      return this.translateService.instant('publish.allLanesBlocked');
    }

    //Partial affected lanes
    else {
      const sortedLanesByPosition: IncidentLane[] | TrafficDisruptionLane[] = sortAffectedLanes(
        cloneDeep(entity.affectedLanes)
      );

      // Check blocked shoulders and remove them from the array
      if (sortedLanesByPosition[0].type === LaneType.LeftShoulder) {
        if (sortedLanesByPosition[0].isClosed) leftShoulderBlocked = true;
        sortedLanesByPosition.splice(0, 1);
      }
      if (sortedLanesByPosition[sortedLanesByPosition.length - 1].type === LaneType.RightShoulder) {
        if (sortedLanesByPosition[sortedLanesByPosition.length - 1].isClosed) rightShoulderBlocked = true;
        sortedLanesByPosition.splice(sortedLanesByPosition.length - 1, 1);
      }

      // Check if all center lanes (no shoulders) are blocked
      if (!sortedLanesByPosition.some(lane => lane.isClosed === false)) {
        return this.translateService.instant('publish.allLanesBlocked');
      }

      sortedLanesByPosition.forEach((lane, index) => {
        if (lane.isClosed) {
          nearLaneIsClosed = true;

          if (index === 0) {
            // Left most lane
            leftMostLaneBlockedCount += 1;
          } else if (index === sortedLanesByPosition.length - 1) {
            // Right most lane
            rightMostLaneBlockedCount = centerLaneBlockedCount + 1;
            centerLaneBlockedCount = 0;
          } else {
            // Center lanes
            if (leftMostLaneBlockedCount === 0) {
              centerLaneBlockedCount += 1;
            } else {
              leftMostLaneBlockedCount += 1;
            }
          }
        } else {
          nearLaneIsClosed = false;
        }

        if (!nearLaneIsClosed || index === sortedLanesByPosition.length - 1) {
          let text = '';
          if (leftMostLaneBlockedCount > 0) {
            if (leftMostLaneBlockedCount > 1) {
              text = leftMostLaneBlockedCount + ' ';
              text += this.translateService.instant('publish.leftMost');
              affectedLanesFullDescription.push(text);
            } else {
              text += this.translateService.instant('publish.leftMostSingle');
              affectedLanesFullDescription.push(text);
            }
          } else if (centerLaneBlockedCount > 0) {
            if (centerLaneBlockedCount > 1) {
              text = centerLaneBlockedCount + ' ';
              text += this.translateService.instant('publish.center');
              affectedLanesFullDescription.push(text);
            } else {
              affectedLanesFullDescription.push(this.translateService.instant('publish.centerSingle'));
            }
          } else if (rightMostLaneBlockedCount > 0) {
            if (rightMostLaneBlockedCount > 1) {
              text = rightMostLaneBlockedCount + ' ';
              text += this.translateService.instant('publish.rightMost');
              affectedLanesFullDescription.push(text);
            } else {
              affectedLanesFullDescription.push(this.translateService.instant('publish.rightMostSingle'));
            }
          }
          leftMostLaneBlockedCount = 0;
          rightMostLaneBlockedCount = 0;
          centerLaneBlockedCount = 0;
          nearLaneIsClosed = false;
        }
      });
    }
    if (
      affectedLanesFullDescription.length > 1 &&
      !affectedLanesFullDescription.some(val => val.toLowerCase().includes('left')) &&
      leftShoulderBlocked
    ) {
      affectedLanesFullDescription.unshift(this.translateService.instant('publish.leftShoulder'));
    }
    if (
      affectedLanesFullDescription.length > 1 &&
      !affectedLanesFullDescription.some(val => val.toLowerCase().includes('right')) &&
      rightShoulderBlocked
    ) {
      affectedLanesFullDescription.push(this.translateService.instant('publish.rightShoulder'));
    }
    return affectedLanesFullDescription.join('\n');
  }

  parseToShortAddress(address) {
    address = address.replace('Avenue', 'Ave');
    address = address.replace('Highway', 'Hwy');
    address = address.replace('Road', 'Rd');
    address = address.replace('Street', 'St');
    address = address.replace('Freeway', 'Fwy');
    address = address.replace('Drive', 'Dr');
    address = address.replace('Boulevard', 'Blvd');
    address = address.replace('Court', 'Ct');
    address = address.replace('Causeway', 'Cswy');
    return address;
  }

  private updateMessageLabels(newLabels: string[]) {
    const regex = new RegExp(this.allLabels.join('|'), 'g');
    let currentMsg: string = this.form.get('description')?.value || '';
    currentMsg = currentMsg.replace(regex, '').replace(/\n*$/, '');
    currentMsg = currentMsg + '\n' + newLabels.join('\n');
    this.form.get('description')?.setValue(currentMsg);
  }

  close() {
    this.dialogRef.close(false);
  }

  submit() {
    if (this.uploadStore.isPendingFiles) {
      this.uploadStore
        .addFilesToQueueAndUpload(UrlUploadEntityEnum.PUBLICATION)
        .pipe(takeWhile(() => this.isAlive))
        .subscribe(res => {
          const imageUrl = (res as HttpResponse<any>).body[0];
          if (typeof imageUrl === 'string') {
            this._publishForm(imageUrl);
          } else {
            console.error('share media failed - publish without media');
            this._publishForm();
          }
        });
    } else {
      this._publishForm();
    }
  }

  private _publishForm(mediaUrl?: string) {
    let description = this.form.value.description;
    const publishInput: PublishInput = {
      id: this.entity.id,
      description: description,
      destinations: this.form.value.destinations,
      entityType: this.entityType,
      mediaS3Key: mediaUrl,
    };
    this.dialogRef.close(publishInput);
  }

  _translate(value) {
    if (!value) return '';
    return this.translateService.instant(value) + ' ';
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
