<div class="select-component" [class.disabled]="_disabled" (click)="openPanel()">
  <mat-label *ngIf="showLabel && formFieldData?.label" class="field-label">
    <span class="required-section" *ngIf="formFieldData?.required">*</span>
    {{ formFieldData.label || '' | translate }} </mat-label
  ><mat-form-field
    appearance="outline"
    [floatLabel]="'never'"
    [class.invalid-field]="ngControl && ngControl.invalid && ngControl.touched"
    [class.language-rtl]="isDirectionRtl">
    <div *ngIf="prefixIcon" matPrefix><wc-icon [iconName]="prefixIcon"></wc-icon></div>
    <mat-select
      #select
      class="outline-select"
      [errorStateMatcher]="matcher"
      [value]="ngControl?.value"
      [formControl]="$any(ngControl?.control || emptyControl)"
      (selectionChange)="onChanged($event.value)"
      (blur)="onBlur()"
      disableOptionCentering=""
      [multiple]="isMultiple"
      [placeholder]="formFieldData.placeholder || '' | translate"
      [panelClass]="['select-panel', prefixIcon ? 'has-prefix' : '']"
      data-focus>
      <ng-container *ngIf="!_isLoadMoreView; else loadMode">
        <ng-container *ngIf="!useGrouping; else groupedOptionsTemplate">
          <mat-option
            *ngFor="let option of formFieldData?.options"
            (mouseenter)="setActiveOption(option.value)"
            [class.mat-selected]="option.value === ngControl.value"
            [value]="option.value"
            [disabled]="option.disabled">
            {{ option.displayName || '' | translate }}
          </mat-option>
        </ng-container>

        <ng-template #groupedOptionsTemplate>
          <mat-optgroup *ngFor="let groupLabel of groupedOptions.labels" [label]="groupLabel | translate">
            <mat-option
              *ngFor="let option of groupedOptions.groupedOptions[groupLabel]"
              (mouseenter)="setActiveOption(option.value)"
              [class.mat-selected]="option.value === ngControl.value"
              [value]="option.value"
              [disabled]="option.disabled">
              {{ option.displayName || '' | translate }}
            </mat-option>
          </mat-optgroup>
        </ng-template>
      </ng-container>

      <ng-template #loadMode>
        <mat-option
          (mouseenter)="setActiveOption(ngControl.value)"
          [class.mat-selected]="true"
          *ngIf="ngControl?.value"
          [value]="ngControl?.value">
          {{ displayNameOfControlValue | translate }}
        </mat-option>
        <mat-option
          (mouseenter)="setActiveOption(option.value)"
          [class.mat-selected]="option.value === ngControl.value"
          *ngFor="let option of visibleOptions"
          [value]="option.value"
          [disabled]="option.disabled">
          {{ option.displayName || '' | translate }}
        </mat-option>
        <mat-option
          class="load-more-option"
          value="LOAD_MORE"
          (mouseenter)="setActiveOption('LOAD_MORE')"
          *ngIf="_showLoadMoreButton && hiddenOptions.length > 0">
          <div data-cy-id="load-more-btn" class="select-load-more-row" (click)="showMoreClicked($event)">
            {{ 'loadMore' | translate }}
          </div>
        </mat-option>
        <ng-container *ngIf="!_showLoadMoreButton">
          <hr class="select-component-hr" />
          <mat-option
            (mouseenter)="setActiveOption(option.value)"
            [class.mat-selected]="option.value === ngControl.value"
            *ngFor="let option of hiddenOptions"
            [value]="option.value"
            [disabled]="option.disabled">
            {{ option.displayName || '' | translate }}
          </mat-option>
        </ng-container>
      </ng-template>
    </mat-select>
    <button type="button" matSuffix mat-icon-button>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>

    <mat-error *ngIf="showErrorMsg">
      {{ 'validationErrorMessages.' + errorMessage | translate }}
    </mat-error>
  </mat-form-field>
</div>
