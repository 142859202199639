import { LanesDirections } from '@wc/core/models/enums';
import { SelectOption } from '@wc/features/ui/form-controls/form-models';
import { TranslateService } from '@ngx-translate/core';

export const AffectedDirectionsToOptions = function (_options?: {
  translateService: TranslateService;
  translateBy?: 'value' | 'key';
  translatePath?: string;
}) {
  const options: Array<SelectOption> = [];
  options.push({
    value: false,
    displayName: _options?.translateService.instant(`affectedDirections.${LanesDirections.One}`),
  });
  options.push({
    value: true,
    displayName: _options?.translateService.instant(`affectedDirections.${LanesDirections.Multi}`),
  });
  return options;
};
