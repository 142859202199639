import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Direction,
  environment,
  Incident,
  IncidentAdditionalInfoInput,
  IncidentAttribute,
  IncidentConfiguration,
  IncidentInput,
  IncidentInvolvedVehicleInput,
  IncidentLaneInput,
  IncidentMitigationInput,
  IncidentNoteInput,
  IncidentReportSourceInput,
  IncidentSeverity,
  IncidentStatus,
  IncidentTypeOrder,
  IncidentUnitInput,
  InjurySeverity,
  InvolvedVehicleInput,
  LaneType,
  NotificationDestination,
  Orientation,
  RoadType,
  UpdateIncidentInput,
} from '@wc/core';
import {
  AffectedDirectionsToOptions,
  EnumToOptions,
  selectedOptionsAlphaNumericSort,
  strArrayToOptions,
} from '@wc/utils';
import { Brand, SelectOption } from '@wc/wc-models/src';
import { FormFieldData, FormFieldOption } from '@wc/wc-ui/src/lib/base';
import { clone, cloneDeep } from 'lodash';
import { AccountService } from './account.service';

export type ForcedMitigationEndIncident = Brand<Incident, 'ForcedMitigationEndIncident'>;

@Injectable({
  providedIn: 'root',
})
export class IncidentsUtilsService {
  private readonly _incidentConfig = this.accountService.incidentConfigs;
  constructor(private translateService: TranslateService, private accountService: AccountService) {}

  get incidentStatusOptions(): Array<SelectOption> {
    return EnumToOptions(IncidentStatus, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'statusType',
    });
  }

  get incidentTypesOptions(): Array<SelectOption> {
    return EnumToOptions(IncidentTypeOrder, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'incidentTypes',
      removeSort: true,
    })
      .filter(opt => opt.value !== 'UNKNOWN_INCIDENT_TYPE')
      .map(type => ({
        ...type,
        hidden: !this._incidentConfig?.incidentTypeValues.includes(type.value),
      }));
  }

  get incidentSeverityOptions(): Array<SelectOption> {
    return strArrayToOptions(
      [
        IncidentSeverity.MinorSeverity,
        IncidentSeverity.IntermediateSeverity,
        IncidentSeverity.MajorSeverity,
        IncidentSeverity.CriticalSeverity,
      ],
      {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'incidentSeverity',
        removeSort: true,
      }
    ).map(opt => ({ ...opt, hidden: !this._incidentConfig?.severityValues.includes(opt.value) }));
  }

  get incidentRoadTypesOptions(): Array<SelectOption> {
    return EnumToOptions(RoadType, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'roadTypes',
    })
      .filter(rt => rt.value !== 'UNKNOWN_ROAD_TYPE')
      .map(opt => ({ ...opt, hidden: !this._incidentConfig?.roadTypeValues.includes(opt.value) }));
  }

  get incidentLaneTypesOptions(): Array<SelectOption> {
    return EnumToOptions(LaneType, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'laneTypes',
    });
  }

  get incidentLaneDirectionsOptions(): Array<SelectOption> {
    return EnumToOptions(Direction).map(option => ({
      ...option,
      displayName: option?.displayName?.toUpperCase(),
    }));
  }

  get incidentOrientationOptions(): Array<SelectOption> {
    return EnumToOptions(Orientation).map(opt => ({
      ...opt,
      hidden: !this.accountService.incidentConfigs.orientationValues.includes(opt.value),
    }));
  }

  get incidentAttributesOptions(): Array<SelectOption> {
    const freeText = this.accountService.incidentConfigs.attributeFreeTextValues.map<FormFieldOption>(att => {
      return { displayName: att.value, value: att.value, hidden: !att.checked };
    });
    const fixed = EnumToOptions(IncidentAttribute, {
      translateService: this.translateService,
      translateBy: 'value',
    });
    fixed.forEach(att => (att.hidden = !this.accountService.incidentConfigs.attributeFixedValues.includes(att.value)));
    return [...fixed, ...freeText];
  }

  get incidentNotificationDestinationOptions(): Array<FormFieldOption> {
    const publishChannels = [NotificationDestination.Waze, ...this.accountService.account.publishChannels];

    return EnumToOptions(publishChannels, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: 'publishChannels',
    });
  }

  createIncidentInput(modifiedIncident: Incident, originalIncident: Incident): IncidentInput {
    const forcedMitigationEndIncident = this.forceEndTimeForNewMitigationWhichAreNotDefault(
      modifiedIncident,
      originalIncident
    );
    return {
      incidentId: modifiedIncident.id,
      updateIncidentInput: this.transformIncidentToUpdateIncidentInput(forcedMitigationEndIncident),
      lanes: this.createIncidentInputLanes(originalIncident, forcedMitigationEndIncident),
      cameras: this.getIncidentCameraInput(forcedMitigationEndIncident),
      additionalInfoInput: this.getIncidentAdditionalInfoInput(forcedMitigationEndIncident),
      responsePlan: forcedMitigationEndIncident.responsePlan,
      involvedVehicles: this.getInvolvedVehiclesInput(forcedMitigationEndIncident) || null,
      reportSources: this.getIncidentReportSourceInput(forcedMitigationEndIncident) || null,
      notes: this.getIncidentNote(forcedMitigationEndIncident) || null,
      mitigations: this.getIncidentMitigationInput(forcedMitigationEndIncident),
      units: this.getIncidentUnitInput(forcedMitigationEndIncident),
    };
  }

  createIncidentInputLanes(
    originalIncident: Incident,
    modifiedIncident: ForcedMitigationEndIncident
  ): IncidentLaneInput[] | null {
    const _lanes = modifiedIncident.affectedLanes?.map(incidentLane => ({
      id: incidentLane.id,
      lane: {
        isAffected: incidentLane.isAffected,
        direction: incidentLane.direction,
        isClosed: incidentLane.isClosed,
        positionIndex: incidentLane.positionIndex,
        number: incidentLane.number,
        roadType: incidentLane.roadType,
        type: incidentLane.type,
      },
    }));
    if (originalIncident.affectedLanes.length > 0) {
      return _lanes;
    }

    if (_lanes.some(lane => lane.lane.isAffected)) {
      return _lanes;
    }

    return null;
  }

  getInvolvedVehiclesInput(modifiedIncident: ForcedMitigationEndIncident): IncidentInvolvedVehicleInput[] {
    return modifiedIncident.involvedVehicles?.map(vehicle => {
      const involvedVehicle: InvolvedVehicleInput = clone(vehicle);
      delete involvedVehicle['id'];
      return {
        id: vehicle.id || null,
        involvedVehicle,
      };
    });
  }

  getIncidentReportSourceInput(modifiedIncident: ForcedMitigationEndIncident): IncidentReportSourceInput[] | null {
    if (!modifiedIncident.reportSources) return null;
    return Array.isArray(modifiedIncident.reportSources)
      ? modifiedIncident.reportSources.map(({ id }) => ({ reportSourceId: id }))
      : [{ reportSourceId: modifiedIncident.reportSources }];
  }

  getIncidentNote(modifiedIncident: ForcedMitigationEndIncident): IncidentNoteInput[] {
    return modifiedIncident.notes?.map(incidentNote => ({
      id: incidentNote.id,
      note: incidentNote.note,
    }));
  }

  forceEndTimeForNewMitigationWhichAreNotDefault(
    modifiedIncident: Incident,
    originalIncident: Incident
  ): ForcedMitigationEndIncident {
    // Get the new added mitigation,and FORCE add end time if user didn't fill by himself
    // Verify not to edit default mitigation
    // https://app.shortcut.com/rekor/story/13875/mitigation-end-time-should-automatically-populate-when-saving-the-edit-mitigation
    const _incident = cloneDeep(modifiedIncident);
    _incident.mitigations.forEach(newMitigation => {
      if (
        newMitigation.interval.to ||
        newMitigation.mitigationType.id === environment.defaultMitigationTypeId ||
        originalIncident.mitigations.some(
          ({ userId, mitigationType: { id } }) =>
            userId === newMitigation.userId && id === newMitigation.mitigationType.id
        )
      )
        return;

      newMitigation.interval.to = new Date();
    });
    return _incident as ForcedMitigationEndIncident;
  }

  getIncidentMitigationInput(modifiedIncident: ForcedMitigationEndIncident) {
    return modifiedIncident.mitigations.map<IncidentMitigationInput>(mitigation => {
      return {
        driverId: mitigation.userId,
        interval: mitigation.interval,
        mitigationTypeId: mitigation.mitigationType.id,
        unitId: mitigation.unitId,
        id: mitigation.id,
      };
    });
  }

  getIncidentUnitInput(modifiedIncident: ForcedMitigationEndIncident): IncidentUnitInput[] {
    return (
      modifiedIncident.associatedUnits.map(associatedUnit => ({
        driverId: associatedUnit.driverDetails?.userId,
        unitId: associatedUnit.id,
        response: associatedUnit.unitResponse,
      })) || []
    );
  }

  getIncidentAdditionalInfoInput(
    modifiedIncident: ForcedMitigationEndIncident
  ): IncidentAdditionalInfoInput[] | undefined {
    return modifiedIncident.additionalInfos?.map<IncidentAdditionalInfoInput>(info => ({
      additionalInfoId: info?.id ? info.id : (info as any as number),
    }));
  }

  getIncidentCameraInput(modifiedIncident: ForcedMitigationEndIncident) {
    return (
      modifiedIncident.cameras?.map(incidentCamera => ({
        cameraExternalId: incidentCamera.camera?.externalId,
        default: incidentCamera.default,
      })) || null
    );
  }

  transformIncidentToUpdateIncidentInput(modifiedIncident: ForcedMitigationEndIncident): UpdateIncidentInput {
    return {
      address: modifiedIncident.address,
      type: modifiedIncident.type,
      startedAt: modifiedIncident.startedAt,
      allLanesAffected: modifiedIncident.allLanesAffected,
      multiDirectionLanesAffected: modifiedIncident.multiDirectionLanesAffected,
      autoPublish: modifiedIncident.autoPublish,
      involvedVehiclesCount: { value: modifiedIncident.involvedVehiclesCount },
      injurySeverities: modifiedIncident.injurySeverities,
      attributes: modifiedIncident.attributes,
      incidentId: modifiedIncident.id,
      nearCameras: !!modifiedIncident.cameras?.length,
      location: modifiedIncident.location || modifiedIncident.address.point,
      subType: { value: modifiedIncident.subType },
      atmsId: { value: modifiedIncident.atmsId || null },
      cadId: { value: modifiedIncident.cadId || null },
      typeDescription: { value: modifiedIncident.typeDescription },
      severity: { value: modifiedIncident.severity },
      injuries: { value: modifiedIncident.injuries ?? null },
      estimatedEndTime: modifiedIncident.estimatedEndTime ? { value: modifiedIncident.estimatedEndTime } : null,
    };
  }

  getFormFieldData(): { [key: string]: FormFieldData } {
    return {
      type: {
        label: 'incidentType',
        options: this.incidentTypesOptions,
      },
      subType: {
        label: 'incident.subtype',
        options: [],
        floatLabel: 'always',
      },
      typeDescription: {
        placeholder: 'statusOtherInputPlaceholder',
      },
      affectedLanes: {
        label: 'lanes',
        required: this.isIncidentFieldMandatory('affectedLanesMandatory'),
      },
      multiDirectionLanesAffected: {
        label: 'affectedDirectionsLabel',
        options: AffectedDirectionsToOptions({
          translateService: this.translateService,
        }),
      },
      allLanesAffected: {
        heapClass: 'all-lanes-affected',
        label: 'allLanesAreAffected',
      },
      isFullBlock: {
        label: 'roadClosureForm.allLanesAreClosed',
      },
      startedAt: {},
      corridor: {
        label: 'corridor',
      },
      crossroad: {
        label: 'crossroad',
      },
      orientation: {
        label: 'orientation',
        options: this.incidentOrientationOptions,
      },
      direction: {
        label: 'direction',
        options: this.incidentLaneDirectionsOptions,
      },
      milemarker: {
        label: 'milemarker',
        options: [],
      },
      involvedVehicles: {
        label: 'involvedVehicles',
      },
      autoPublish: {
        heapClass: 'auto-publish',
        label: 'incident.activateAutoPublish',
      },
      mitigations: {
        label: 'Mitigation',
      },
      associatedUnits: {
        label: 'associatedUnits',
        required: this.isIncidentFieldMandatory('associatedUnitsMandatory') ? true : false,
      },
      notes: {
        label: 'notes',
      },
      injuries: {
        heapClass: 'injuries-number',
        label: 'incident.injuries',
        placeholder: 'incident.injuriesPlaceholder',
        options: Array.from({ length: 11 }, (_, index) => ({ displayName: index.toString(), value: index })),
      },
      source: {
        label: 'incident.source',
      },
      reportedBy: {
        label: 'incident.reportedBy',
        placeholder: 'incident.selectSource',
        options: this.accountService.reportSourceOptions,
      },
      atmsId: {
        label: 'incident.atmsId',
        placeholder: 'incident.enterIdNumber',
        floatLabel: 'always',
        heapClass: 'atms-id',
      },
      cadId: {
        label: 'incident.cadId',
        placeholder: 'incident.enterIdNumber',
        floatLabel: 'always',
        heapClass: 'cad-id',
      },
      estimatedEndTime: {
        label: 'incident.estimatedEndTime',
      },
      additionalInfos: {
        heapClass: 'additionalInfo',
        required: this.isIncidentFieldMandatory('additionalInfoMandatory') ? true : false,
        options: selectedOptionsAlphaNumericSort(
          this.accountService.incidentConfigs.additionalInfoValues.map(info => ({
            value: info.id,
            displayName: info.info,
          }))
        ),
      },
      media: {
        label: 'mediaGallery.sectionTitle',
      },
      involvedVehiclesCount: {
        label: 'incident.involvedVehiclesCount',
        placeholder: 'incident.involvedVehiclesPlaceholder',
        options: [
          ...Array.from({ length: 6 }, (_, index) => ({ displayName: index.toString(), value: index })),
          ...[{ displayName: '6+', value: 6 }],
        ],
      },
      severity: {
        label: 'incident.severity',
        placeholder: 'selectSeverity',
        options: this.incidentSeverityOptions,
      },
      injurySeverities: {
        heapClass: 'injuries-severity',
        label: 'incident.injurySeverity',
        placeholder: 'selectSeverity',
        options: strArrayToOptions(
          [
            InjurySeverity.MinorInjurySeverity,
            InjurySeverity.IntermediateInjurySeverity,
            InjurySeverity.MajorInjurySeverity,
            InjurySeverity.FatalInjurySeverity,
            InjurySeverity.UndeterminedInjurySeverity,
          ],
          {
            translateService: this.translateService,
            translateBy: 'value',
            translatePath: 'injurySeverity',
            removeSort: true,
          }
        ),
      },
      attributes: {
        options: this.incidentAttributesOptions,
      },
    };
  }

  isIncidentFieldMandatory(filedName: keyof IncidentConfiguration) {
    return !!this.accountService.incidentConfigs?.[filedName];
  }
}
