<div data-cy-id="affected-lanes-slider" class="affected-lanes-slider">
  <div
    data-cy-id="swiper-affected-lanes-component"
    class="swiper-component swiper-affected-lanes-component"
    [formGroup]="lanesForm"
    [class.tablet-mode]="isTabletMode">
    <div class="swiper-container" [class.one-lane-container]="lanes.controls.length === 1" [swiper]="config">
      <div formArrayName="lanes" class="swiper-wrapper">
        <div
          [attr.data-cy-id]="'affected-lanes-slider-lane-' + !lane.get('isAffected')?.value"
          class="swiper-slide affected-lanes-slider-lane"
          [ngClass]="{
            allLanesAffected: allLanesAffected
          }"
          [class.disabled]="!lane.get('isAffected')?.value"
          *ngFor="let lane of lanes.controls; let i = index; trackBy: trackByFn"
          [formGroupName]="i"
          (mouseover)="laneHovered(lane, i)"
          (mouseleave)="laneHovered(null, -1)"
          [class.hover]="i === hovered">
          <div data-cy-id="affected-lanes-slider-header" class="affected-lanes-slider-header">
            <mat-checkbox
              data-cy-id="affected-lanes-slider-header-checkbox"
              *ngIf="_isOneDirection"
              (change)="selectLane(i)"
              [checked]="lane.get('isAffected')?.value"
              [disabled]="allLanesAffected">
            </mat-checkbox>

            <span
              data-cy-id="affected-lanes-slider-header-title"
              class="affected-lanes-title"
              [class.multi-direction-title]="_isOneDirection === false">
              {{ lane | laneLabel }}
            </span>

            <div *ngIf="_isOneDirection">
              <div
                class="affected-lanes-gore-actions"
                *ngIf="isTabletMode && lane.get('type')?.value === 'GORE'; else desktopMove"
                (click)="openLaneMoveModal(lane)">
                <i class="fas fa-caret-left"></i>
                <i class="fas fa-caret-right"></i>
              </div>

              <div
                #desktopMove
                class="affected-lanes-gore-actions"
                *ngIf="!isTabletMode && lane.get('type')?.value === 'GORE'">
                <i
                  data-cy-id="affected-lanes-gore-arrow-left"
                  [class.disabled]="i === 0"
                  class="fas fa-caret-left"
                  (click)="moveGore(i, 0)"></i>
                <i
                  data-cy-id="affected-lanes-gore-arrow-right"
                  [class.disabled]="i === lanes.length - 1"
                  class="fas fa-caret-right"
                  (click)="moveGore(i, 1)"></i>
              </div>
            </div>

            <wc-icon
              [attr.data-cy-id]="'affected-lanes-remove-button-' + !lane.get('isAffected')?.value"
              iconName="times-circle"
              [wcTooltipText]="'tooltip.deleteLane' | translate"
              wcTooltipPlacement="bottom"
              (click)="deleteLane(i); $event.preventDefault()"></wc-icon>
          </div>
          <div class="affected-lanes-content" [class.disabled]="!lane.get('isAffected')?.value">
            <div class="affected-lanes-type-wrapper">
              <wc-select-control
                *ngIf="isTabletMode || !newAutocomplete; else newAutocompleteType"
                class="affected-lanes-type"
                [disabled]="
                  !lane.get('isAffected')?.value ||
                  lane.get('type')?.value === 'GORE' ||
                  lane.get('type')?.value === 'LEFT_SHOULDER' ||
                  lane.get('type')?.value === 'RIGHT_SHOULDER'
                "
                [formControl]="$any(lane.get('type'))"
                [isTabletMode]="isTabletMode"
                [formFieldData]="lane.value | laneOptions: lanes:_isOneDirection">
              </wc-select-control>
              <ng-template #newAutocompleteType>
                <wc-select
                  data-cy-id="affected-lanes-type"
                  class="affected-lanes-type"
                  [disabled]="
                    !lane.get('isAffected')?.value ||
                    lane.get('type')?.value === 'GORE' ||
                    lane.get('type')?.value === 'LEFT_SHOULDER' ||
                    lane.get('type')?.value === 'RIGHT_SHOULDER'
                  "
                  [formControl]="$any(lane.get('type'))"
                  [formFieldData]="lane.value | laneOptions: lanes:_isOneDirection">
                </wc-select>
              </ng-template>

              <wc-select-control
                *ngIf="isTabletMode || !newAutocomplete; else newAutocompleteDirection"
                class="affected-lanes-direction"
                [formControl]="$any(lane.get('direction'))"
                [isTabletMode]="isTabletMode"
                [disabled]="!lane.get('isAffected')?.value || (isDirectionAutopopulated && _isOneDirection)"
                [formFieldData]="laneFieldData.direction"></wc-select-control>
              <ng-template #newAutocompleteDirection>
                <wc-select
                  data-cy-id="affected-lanes-direction"
                  class="affected-lanes-direction"
                  [formControl]="$any(lane.get('direction'))"
                  [disabled]="!lane.get('isAffected')?.value || (isDirectionAutopopulated && _isOneDirection)"
                  [formFieldData]="laneFieldData.direction"></wc-select>
              </ng-template>

              <wc-select-control
                *ngIf="isTabletMode || !newAutocomplete; else newAutocompleteRoadType"
                class="affected-lanes-roadType"
                [formControl]="$any(lane.get('roadType'))"
                [isLoadMoreView]="true"
                [isTabletMode]="isTabletMode"
                [disabled]="!lane.get('isAffected')?.value"
                [formFieldData]="laneFieldData.roadType"></wc-select-control>
              <ng-template #newAutocompleteRoadType>
                <wc-select
                  data-cy-id="affected-lanes-road-type"
                  class="affected-lanes-roadType"
                  [formControl]="$any(lane.get('roadType'))"
                  [isLoadMoreView]="true"
                  [disabled]="!lane.get('isAffected')?.value"
                  [formFieldData]="laneFieldData.roadType"></wc-select>
              </ng-template>
            </div>
            <div data-cy-id="affected-lanes-slider-toggle" class="affected-lanes-slider-toggle">
              <span
                data-cy-id="affected-lanes-slider-toggle-option"
                class="toggle-option"
                [class.open]="lane.get('isClosed')?.value === false"
                >{{ 'affectedLanesToggleOptions.open' | translate }}</span
              >
              <wc-slider-toggle-control
                data-cy-id="affected-lanes-slider-toggle-button"
                [formControl]="lane?.get('isClosed')"></wc-slider-toggle-control>
              <span class="toggle-option" [class.closed]="lane?.get('isClosed')?.value">{{
                'affectedLanesToggleOptions.close' | translate
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-pagination-affected-lanes swiper-pagination"></div>
    </div>
    <div
      data-cy-id="swiper-pagination-affected-lanes-previous"
      [wcTooltipText]="'previous' | translate"
      wcTooltipPlacement="bottom"
      [wcTooltipOffsetDistance]="0"
      class="swiper-button-prev swiper-button-prev-affected-lanes"></div>
    <div
      data-cy-id="swiper-pagination-affected-lanes-next"
      [wcTooltipText]="'next' | translate"
      wcTooltipPlacement="bottom"
      [wcTooltipOffsetDistance]="0"
      class="swiper-button-next swiper-button-next-affected-lanes"></div>
  </div>
</div>
