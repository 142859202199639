/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { AffectedFixedBusRoute } from '@wc/core';
import {
  AffectedRouteServicesStoreEntity,
  AffectedServiceIUEntity,
  AffectedServicesStoreNames,
} from '@wc/wc-models/src';
import { map } from 'rxjs/operators';
import { EntitiesServiceV2 } from '../../services/entities.service';

export interface AffectedRouteServiceState extends EntityState<AffectedRouteServicesStoreEntity, number> {}
export interface AffectedRouteServiceUIState extends EntityState<AffectedServiceIUEntity> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: AffectedServicesStoreNames.AffectedRouteServices, idKey: 'routeId' })
export class AffectedRouteServicesStore extends EntityStore<AffectedRouteServiceState> {
  ui!: EntityUIStore<AffectedRouteServiceUIState>;
  constructor(private entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$
      .pipe(map(({ AFFECTED_ROUTE_SERVICES }) => AFFECTED_ROUTE_SERVICES || []))
      .subscribe(UIEntities => this.ui.upsertMany(UIEntities));

    entitiesService.getDiff$
      .pipe(
        map(({ modified, removed }) => ({
          removed: removed?.AFFECTED_ROUTE_SERVICES,
          modified: modified.AFFECTED_ROUTE_SERVICES,
        }))
      )
      .subscribe(({ removed, modified }) => {
        if (modified) {
          this.upsertMany(modified);
          this.ui.upsertMany(modified?.map(route => this.getUIEntity(route)));
        }
        if (removed) {
          console.log({ removed });
          this.remove(removed);
        }
      });

    entitiesService.activeEntityIds$
      .pipe(map(({ AFFECTED_ROUTE_SERVICES }) => AFFECTED_ROUTE_SERVICES || []))
      .subscribe(id => {
        this.ui.setActive(id);
      });
  }

  private getUIEntity(route: Partial<AffectedFixedBusRoute>): AffectedServiceIUEntity {
    return {
      isViewed: this.entitiesService
        .getSavedViewedAffectedServicesID('affectedFixedBusRoutes')
        .some(id => id === route.routeId),
      affectedServiceType: 'route',
      id: route.routeId,
      routeId: route.routeId,
      color: route.color,
      isRerouted: route.isRerouted,
      estimatedEndTime: route.estimatedEndTime,
      affectedBusStopsCount: route.affectedBusStopsCount,
      iconName: `transit_fixed_route_bus_unit`,
      displayId: route.routeNumber,
      affectingEvents: route.affectingEvents,
    };
  }
}
