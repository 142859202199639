import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IsOneTimeClickModule } from '../../directives/is-one-time-click.module';
import { NewIncidentLocationComponent } from './new-incident-location.component';
import { AddressModule } from '../../form-fields-components/address/address.module';
import { MatIconModule } from '@angular/material/icon';

const Materials: any[] = [
  MatIconModule,
  // MatButtonModule
];

@NgModule({
  declarations: [NewIncidentLocationComponent],
  imports: [
    CommonModule,
    TranslateModule,
    AddressModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    Materials,
    IsOneTimeClickModule,
  ],
  providers: [],
  exports: [NewIncidentLocationComponent],
})
export class NewIncidentLocationModule {}
