import { ChangeDetectorRef, Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';
import { IconClassName } from '@wc/wc-models/src/lib/types/icon-class-names-type';

export type FormFieldData<T = unknown, D = unknown> = {
  label?: string;
  labelPosition?: 'before' | 'after';
  options?: FormFieldOption<T, D>[];
  placeholder?: string;
  required?: boolean;
  floatLabel?: FloatLabelType;
  heapClass?: string;
};

export interface FormFieldOption<T = unknown, D = any> {
  value: T;
  displayName?: string;
  startIcon?: IconClassName;
  endIcon?: IconClassName;
  selected?: boolean;
  data?: D;
  disabled?: boolean;
  hidden?: boolean;
  index?: number;
  groupLabel?: string;
  tooltipText?: string;
  historical?: boolean;
  dataCyId?: string;
}

@Component({
  selector: 'wc-custom-form-control',
  template: '',
})
export class CustomFormControlComponent implements ControlValueAccessor {
  protected _formFieldData = {} as FormFieldData;
  @Input() set formFieldData(data: FormFieldData) {
    this._formFieldData = data;
  }
  get formFieldData() {
    return this._formFieldData;
  }
  _disabled = false;

  constructor(@Optional() @Self() public ngControl: NgControl, protected cdr: ChangeDetectorRef) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  onChanged = (value: any) => undefined;
  onTouched = () => undefined;

  writeValue(obj: any): void {
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  get errorMessage() {
    if (this.ngControl?.errors) {
      return Object.keys(this.ngControl.errors)[0];
    }
    return '';
  }
}
