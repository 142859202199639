import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { WcChipModule } from '../../../../wc-ui/src/components/wc-chip/wc-chip.module';
import { WcLoaderComponentModule } from '../../components/miscellaneous/loader/loader.module';
import { WcBaseControlFieldModule } from '../../lib/base/base-control-field.module';
import { AutocompleteV2Component } from './autocomplete-v2.component';

const Materials: any[] = [MatSelectModule, MatInputModule, MatIconModule, MatButtonModule];

@NgModule({
  declarations: [AutocompleteV2Component],
  imports: [
    CommonModule,
    WcChipModule,
    WcBaseControlFieldModule,
    TranslateModule,
    Materials,
    TranslateModule,
    WcLoaderComponentModule,
  ],
  providers: [],
  exports: [AutocompleteV2Component],
})
export class WcAutocompleteV2Module {}
