import { Injectable } from '@angular/core';
import { ThemeName, WcLayerOptions } from '@wc/wc-map-viewer/src';
import { EntityStyleStatusEnum, NewLiveMapEntity } from '@wc/wc-models/src';
import { LiveMapEntityState, MainEntityType } from '../../../../core/models';

@Injectable({
  providedIn: 'root',
})
export class LiveMapStylesService {
  getEntityStyleStatus(entity: NewLiveMapEntity): EntityStyleStatusEnum {
    const lowerCasedStatus = entity.status?.toLocaleLowerCase();
    const entityStyleStatus =
      EntityStyleStatusEnum[entity.isRerouted ? `${lowerCasedStatus}_re_routed` : lowerCasedStatus] ||
      EntityStyleStatusEnum.default;

    if (entity.type === MainEntityType.work_request && entityStyleStatus !== EntityStyleStatusEnum.completed) {
      return entity.assignedUser === null ? EntityStyleStatusEnum.unassigned : EntityStyleStatusEnum.default;
    }
    return entityStyleStatus as EntityStyleStatusEnum;
  }

  getStyleKey(
    styleState: EntityStyleStatusEnum,
    entity: NewLiveMapEntity,
    ThemeName: ThemeName,
    layerOptions: WcLayerOptions<LiveMapEntityState> | undefined
  ): string | null {
    if (!layerOptions) return 'no layer options';
    return `
    ${ThemeName}_
    ${entity.layerType}_
    ${entity.type}_
    ${styleState}_
    ${entity.location.type}_
    ${entity['color']}_
    ${layerOptions.startPointStatusStyle && 'startPointStatusStyle'}_
    ${layerOptions.endPointStatusStyle && 'endPointStatusStyle'}_
    ${layerOptions.startPointPolygonStyle && 'startPointPolygonStyle'}_
    `;
  }
}
