import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CustomFormControlComponent } from '../../lib/base/custom-form-control';

@Component({
  selector: 'wc-button-toggle-group',
  templateUrl: './button-toggle-group.component.html',
  styleUrls: ['./button-toggle-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ButtonToggleGroupComponent extends CustomFormControlComponent {
  @Input() readOnly = false;
  @Input() size: 'medium' | 'large' = 'medium';
  @Output() selectionChange = new EventEmitter<string>();

  onValueChange(value: string) {
    this.onChanged(value);
    this.selectionChange.emit(value);
  }
}
