import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LaneLabelPipe } from './lane-label.pipe';
import { LaneOptionsPipe } from './lane-options.pipe';

@NgModule({
  declarations: [LaneLabelPipe, LaneOptionsPipe],
  imports: [CommonModule, TranslateModule],
  providers: [],
  exports: [LaneLabelPipe, LaneOptionsPipe],
})
export class AffectedLanesPipesModule {}
