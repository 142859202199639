import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService, HeapAnalyticsService } from '@wc-core';
import { IncidentMitigation, IncidentUnit, UnitVehicle } from '@wc/core/models/gql.models';
import { ConfirmationModalType } from '@wc/wc-models/src/lib/enums/general';
import { ConfirmModalService } from '@wc/wc-ui';
import { clone } from 'lodash';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { Observable } from 'rxjs';
import { BaseControlFieldComponent } from '../base-control-field.component';

interface AssociatedUnitForm {
  associatedUnit: Partial<IncidentUnit>;
  mitigations?: Array<IncidentMitigation>;
}

@Component({
  selector: 'wc-associated-units-section',
  templateUrl: './associated-units.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssociatedUnitsSectionComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AssociatedUnitsSectionComponent),
      multi: true,
    },
  ],
  styleUrls: ['./associated-units.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociatedUnitsSectionComponent
  extends BaseControlFieldComponent
  implements OnInit, AfterViewInit, Validator
{
  @ViewChild(SwiperDirective) swiperElm!: SwiperDirective;
  @Input() incident;
  @Output() change: EventEmitter<any> = new EventEmitter();
  //swiperElm = null;
  units: FormArray = this.fb.array([]);
  unitVehicles!: Array<UnitVehicle>;
  unitsForm: FormGroup = this.fb.group({ units: this.units });
  associatedUnitFormDataArray: Array<Partial<AssociatedUnitForm>> = [];

  get isTabletMode() {
    return false;
  }

  config!: SwiperConfigInterface;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private confirmService: ConfirmModalService,
    private dialogRef: MatDialogRef<AssociatedUnitsSectionComponent>,
    private cdr: ChangeDetectorRef,
    private heapService: HeapAnalyticsService
  ) {
    super();
  }

  ngOnInit(): void {
    (this.accountService.unitsByWorkspaces as unknown as Observable<UnitVehicle[]>).subscribe(units => {
      this.unitVehicles = units || [];
      this.associatedUnitFormDataArray.forEach(_unitForm => {
        const newUnit = this.unitFormGroup(_unitForm);
        newUnit.patchValue(_unitForm);
        this.units.push(newUnit);
      });
      this.cdr.markForCheck();
      this.swiperElm?.update();
    });
  }

  ngAfterViewInit() {
    this.initSwiperConfig();
    this.cdr.markForCheck();

    this.unitsForm.valueChanges.subscribe(changes => {
      const unitFormValue = this.collectUnitsAndMitigations(changes.units);
      this.fieldFormControl.setValue(unitFormValue.associatedUnits);
      this.onChanged(this.fieldFormControl.value);
      this.change.emit(unitFormValue.mitigations);
      this.swiperElm?.update();
    });
  }

  initSwiperConfig() {
    this.config = {
      direction: 'horizontal',
      slidesPerView: this.isTabletMode ? 1 : 1.46,
      spaceBetween: 30,
      threshold: this.isTabletMode ? 0 : 20,
      navigation: {
        nextEl: '.associated-units-next',
        prevEl: '.associated-units-prev',
      },
      pagination: {
        // el: '.swiper-pagination-associated-units',
        el: this.units && this.units.length > 1 ? '.swiper-pagination-associated-units' : null,
        clickable: true,
      },
    };
  }

  writeValue(associatedUnits: Array<IncidentUnit>) {
    if (associatedUnits) {
      this.units.clear();
      const mitigations = clone(this.incident.mitigations);

      this.divideUnitsAndMitigations(associatedUnits, mitigations);
    }
  }

  unitFormGroup(model): FormGroup {
    return this.fb.group(model);
  }

  deleteUnit(unitIndex, unit) {
    const showModal = !!unit.value.associatedUnit.displayId;

    if (showModal) {
      this.confirmService.showConfirmDialog(
        ConfirmationModalType.RemoveUnit,
        unit.value.associatedUnit.displayId,
        confirmedPositive => {
          if (!confirmedPositive) {
            this.dialogRef.close();
          } else {
            this.units.removeAt(unitIndex);
            this.swiperElm?.update();
            this.dialogRef.close();
          }
        }
      );
    } else {
      this.units.removeAt(unitIndex);
      this.swiperElm?.update();
    }

    this.initSwiperConfig();
  }

  closeModal() {
    this.dialogRef.close();
  }

  addUnit() {
    const newUnit: Partial<AssociatedUnitForm> = {
      associatedUnit: {
        displayId: undefined,
        externalId: undefined,
        unitResponse: undefined,
        driverDetails: null,
        status: undefined,
        type: undefined,
      },
      mitigations: [],
    };

    this.associatedUnitFormDataArray.push(newUnit);
    //this.units.push(this.fb.control(false));

    const newUnitControl = this.unitFormGroup(newUnit);
    newUnitControl.patchValue(newUnit);
    this.swiperElm?.update();
    this.units.push(newUnitControl);
    this.formControl.setErrors({ invalid: true }, { emitEvent: true });
    this.cdr.markForCheck();

    this.initSwiperConfig();
    this.heapService.trackUserSpecificAction('heap-add-unit');
    setTimeout(() => {
      this.swiperElm?.setIndex(this.units.length);
    });
  }

  divideUnitsAndMitigations(associatedUnits: Array<IncidentUnit>, mitigations: Array<IncidentMitigation>) {
    associatedUnits.forEach(_unit => {
      const associatedUnit = clone(_unit);
      const unitMitigations: Array<IncidentMitigation> = [];

      mitigations.forEach(_mitigation => {
        if (_mitigation.unitId === associatedUnit.id) {
          unitMitigations.push(clone(_mitigation));
        }
      });

      this.associatedUnitFormDataArray.push({
        associatedUnit,
        mitigations: unitMitigations,
      });
    });
  }

  collectUnitsAndMitigations(changes) {
    const associatedUnits: Array<IncidentUnit> = [];
    const mitigations: Array<IncidentMitigation> = [];

    changes.forEach(_form => {
      if (_form.associatedUnit) associatedUnits.push(_form.associatedUnit);
      if (_form.mitigations) mitigations.push(..._form.mitigations);
    });

    return {
      associatedUnits,
      mitigations,
    };
  }

  validate() {
    if (this.unitsForm.invalid) {
      return { invalid: true };
    }
    return null;
  }
}
