<div class="lane-move-container">
  <span class="lane-move-title">Gore on the right to lane</span>
  <mat-form-field appearance="legacy">
    <mat-select>
      <mat-option
        class="lane-move-option"
        [class.tablet-mode]="isTabletMode"
        [value]="laneNumber"
        *ngFor="let laneNumber of laneNumbers"
        (click)="handleOptionClick(laneNumber.index)">
        {{ laneNumber.displayValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
