import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { LoaderService } from '@wc-core';
import { Address, GeoService, Point, SegmentDetails } from '@wc/core';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { FormFieldOption } from '../../lib/base';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { AddressUtilsService } from './address-utils.service';

/**
 * @description The following code it due to a problem with {emitEvent:false}.
 * For further info, please look at @link https://github.com/angular/components/issues/20218 or https://github.com/angular/angular/issues/42237
 */

const outOfJurisdictionError = { errorCode: 400 };

@Component({
  selector: 'wc-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent implements OnInit {
  @Input() showJurisdiction = true;
  @Input() controlName = 'address';
  @Input() showLoader = true;
  @Input() fillFormWithSegmentDetails = true;
  @Input() set address(val: Address) {
    this._address = val || undefined;
  }

  @Input() isAliasAvailable = false;
  @Input() set point(val: Point | null) {
    if (val === null || !this.addressUtilsService.verifyValidPoint(val)) return;
    this._point = val;
    this.geoService.isOutOfWorkspaces(val.coordinates)
      ? this.handlePointOutsideJurisdiction()
      : this.handlePointInsideJurisdiction(val);
  }

  @Output() jurisdictionErrorState: EventEmitter<null | typeof outOfJurisdictionError> = new EventEmitter();
  @Output() addressIsLoading: EventEmitter<boolean> = new EventEmitter();
  @Output() segmentDetailsUpdate: EventEmitter<SegmentDetails> = new EventEmitter();

  @ViewChild('centerMarker', { static: false })
  centerMarker!: ElementRef<HTMLElement>;
  @ViewChild('corridorField', { read: AutocompleteComponent, static: true })
  corridorField?: AutocompleteComponent<FormFieldOption>;
  @ViewChild('crossroadField', { read: AutocompleteComponent })
  crossroadField!: AutocompleteComponent<FormFieldOption>;
  @ViewChild('milemarkerField', { read: AutocompleteComponent })
  milemarkerField!: AutocompleteComponent<FormFieldOption>;

  addressForm!: FormGroup;
  _disableFields = false;
  isCorridorOrCrossroadChanged$!: Observable<boolean>;
  readonly addressFieldData = this.addressUtilsService.getAddressFieldData();

  get addressLoader$() {
    return this.loaderService.select('address');
  }

  get isEditMode() {
    return !!this._address?.corridor;
  }

  private _point!: Point;
  private _address?: Address;

  constructor(
    private fb: FormBuilder,
    private geoService: GeoService,
    private addressUtilsService: AddressUtilsService,
    private loaderService: LoaderService,
    @Optional() private formGroupDirective: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.initiateAddressForm();
    if (this._address) {
      this.addressForm.patchValue(this._address);
      if (!this._point) {
        this.point = this._address.point;
      }
    }
    this.isCorridorOrCrossroadChanged$ = this.addressUtilsService.getIsCorridorOrCrossroadChanged$(this.addressForm);
  }

  private initiateAddressForm() {
    if (this.formGroupDirective?.form) {
      this.formGroupDirective.form.setControl(this.controlName, this.getAddressFormGroup());
      this.addressForm = this.formGroupDirective.form.get(this.controlName) as FormGroup;
    } else this.addressForm = this.getAddressFormGroup();
  }

  private handlePointOutsideJurisdiction() {
    this.jurisdictionErrorState.emit(outOfJurisdictionError);
    this.addressLoadingProcess(false);
    this._disableFields = true;
  }

  private handlePointInsideJurisdiction(val: Point) {
    this.jurisdictionErrorState.emit(null);
    if (this.showLoader) {
      this.addressLoadingProcess(true);
    }
    this.addressUtilsService
      .getSegmentDetails(val)
      .pipe(
        filter((segmentDetails): segmentDetails is SegmentDetails => !!segmentDetails),
        tap(segmentDetails => this.segmentDetailsUpdate.emit(segmentDetails))
      )
      .subscribe(
        segmentDetails => {
          const segmentRoadType = segmentDetails.roadTypes[0] || null;
          const { corridorOptions, crossroadsOptions, milemarkersOptions } =
            this.addressUtilsService.getAddressAutoCompleteOptionsFromSegmentDetails(segmentDetails);

          // remove the free text value from the milemarkers so it wont be included as option
          if (milemarkersOptions.length === 0 && this.fillFormWithSegmentDetails) {
            this.milemarkerField?.resetFormControl();
            this.addressForm.get('milemarker')?.setValue(null);
          }

          if (this.fillFormWithSegmentDetails) {
            this.addressForm.patchValue({
              ...segmentDetails,
              ...(<Address>{
                corridor: segmentDetails.overridingCorridorAlias || segmentDetails.alias || null,
                crossroad: segmentDetails.overridingCrossroadAlias || segmentDetails.crossRoads[0]?.name || null,
                milemarker: segmentDetails.milemarkers[0] || null,
                roadLevelType: segmentDetails.roadLevelTypes[0],
                roadType: segmentRoadType,
                county: segmentDetails.county,
                point: this._point,
              }),
            });
          }

          this.corridorField?.updateOptions(
            this.addressUtilsService.convertOptionsToUniqueGroupedOptions(
              corridorOptions,
              'corridor',
              this.addressForm
            ),
            this.addressForm.get('corridor')?.value
          );
          this.crossroadField?.updateOptions(
            this.addressUtilsService.convertOptionsToUniqueGroupedOptions(
              crossroadsOptions,
              'crossroad',
              this.addressForm
            ),
            this.addressForm.get('crossroad')?.value
          );
          this.milemarkerField?.updateOptions(milemarkersOptions, this.addressForm.get('milemarker')?.value);
          this.addressLoadingProcess(false);
        },
        err => {
          if (err.error === 400) {
            this.handlePointOutsideJurisdiction();
          }
          this.addressLoadingProcess(false);
          this.resetFieldsData();
        }
      );
  }

  private addressLoadingProcess(state: boolean) {
    this.loaderService.update({ address: state });
    this.addressIsLoading.emit(state);
    this._disableFields = state;
  }

  private resetFieldsData() {
    this.addressForm.patchValue(<Address>{
      corridor: null,
      crossroad: null,
      roadLevelType: null,
      roadType: null,
      direction: null,
      orientation: null,
      milemarker: null,
    });

    this.corridorField?.resetFormControl();
    this.crossroadField?.resetFormControl();
    this.milemarkerField?.resetFormControl();
  }

  private getAddressFormGroup() {
    return this.fb.group({
      point: null,
      city: null,
      corridor: [null, [Validators.required, Validators.maxLength(100)]],
      county: null,
      crossroad: [null, Validators.maxLength(100)],
      direction: [null, [this.addressUtilsService.requiredFromConfiguration('directionMandatory')]],
      orientation: [null, [this.addressUtilsService.requiredFromConfiguration('orientationMandatory')]],
      roadLevelType: null,
      roadType: [null, Validators.required],
      segmentId: null,
      state: null,
      milemarker: [null, Validators.maxLength(15)],
    });
  }
}
