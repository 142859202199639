import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WeatherEventSortPipe } from './weather-event-sort.pipe';

@NgModule({
  declarations: [WeatherEventSortPipe],
  imports: [CommonModule],
  exports: [WeatherEventSortPipe],
})
export class WeatherEventSortPipeModule {}
