import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { WcIconModule } from '../../../wc-icon/wc-icon.module';
import { AutocompleteOptionComponent } from './autocomplete-option.component';

@NgModule({
  declarations: [AutocompleteOptionComponent],
  imports: [CommonModule, MatCheckboxModule, TranslateModule, WcIconModule],
  exports: [AutocompleteOptionComponent],
})
export class AutocompleteOptionModule {}
