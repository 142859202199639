import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { LayerNamesOptionsType, LayerPanelStoreItem, LiveMapState } from '@wc/wc-models';

function createInitialState(): LiveMapState {
  return {
    availableWorkspaces: [],
    visibleFeatureIds: [],
    visibleEntityIdsByLayerType: {},
    visibleTransitLayers: [],
    layerPanelStoreItemsMap: new Map<LayerNamesOptionsType, LayerPanelStoreItem<LayerNamesOptionsType>>(),
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'liveMap' })
export class LiveMapStore extends Store<LiveMapState> {
  constructor() {
    super(createInitialState());
  }
}
