import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { GroupData } from '@wc/wc-models/src';
import { FormFieldData } from '../../lib/base';

@Component({
  selector: 'wc-single-config-form',
  templateUrl: './single-config-form.component.html',
  styleUrls: ['./single-config-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleConfigFormComponent implements AfterViewInit {
  @Output() subSettingClicked: EventEmitter<string> = new EventEmitter<string>();

  @Input() subFormGroupName = '';
  @Input() filedData: FormFieldData = {};
  @Input() formGroupUIConfigs: GroupData = {};
  @Input() configType?: 'sliders' | 'checkboxes' | 'itemsAddRemove';
  @Input() uiTheme: 'light' | 'grey' = 'grey';
  @Input() configTitle = '';
  @Input() tableColumnName = '';
  @Input() configHelpSvgPath = undefined;
  @Input() configHelpInfoText = '';
  @Input() textFiledNameForListControl = ''; // controlName in the formGroup of itemsAddRemove that holds the text value

  _selectFormControlName = ''; // highlight selected row

  // Get sub from of parentFormGroupDirective
  get controlFormGroup() {
    return this.parentFormGroupDirective.form?.get(this.subFormGroupName) as FormGroup;
  }

  // Get control form of parentFormGroupDirective
  get controlFormControl() {
    return this.parentFormGroupDirective.form?.get(this.subFormGroupName) as FormControl;
  }

  get controlFormArray() {
    return this.parentFormGroupDirective.form?.get(this.subFormGroupName) as FormArray;
  }

  get formGroupControlsNames() {
    return Object.keys(this.controlFormGroup?.controls);
  }

  constructor(public parentFormGroupDirective: FormGroupDirective, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.parentFormGroupDirective.form.valueChanges.subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  openSubSettingClicked(controlName: string) {
    this._selectFormControlName = controlName;
    this.subSettingClicked.emit(controlName);
  }
}
