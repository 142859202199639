<div
  [ngClass]="'heap-' + (isTabletMode ? 'tablet' : 'desktop') + '-incident-list-item'"
  class="incident-list-item"
  [class.tablet-mode]="isTabletMode"
  [class.language-rtl]="uiStore.isDirectionRtl"
  [class.language-hebrew]="uiStore.isHebrew"
  *mobxAutorun="{ dontDetach: true }"
  [wcRoadEventHighlight]="{ roadEvent: incident, type: RoadEventTypeEnum.Incident }">
  <div class="incident-data-container">
    <div class="incident-icon-container">
      <div
        *ngIf="incident.persistenceScoreType && !isTabletMode"
        matTooltipHideDelay="500"
        matTooltipShowDelay="500"
        [matTooltipDisabled]="!incident.persistenceScoreType"
        matTooltipPosition="above"
        [matTooltip]="
          'insight.persistenceTooltip.title'
            | translate: { type: 'insight.persistenceChance.' + incident.persistenceScoreType | translate }
        "
        matTooltipClass="persistence-level-tooltip"
        class="insight-badge insight-badge__persistence"
        [ngClass]="'insight-badge__persistence--' + incident.persistenceScoreType">
        {{ 'insight.persistence.' + incident.persistenceScoreType | translate }}
      </div>
      <wc-network-badge
        *ngIf="incident.isOffline"
        [disabled]="true"
        [iconOnly]="true"
        staticState="offline"></wc-network-badge>
      <i
        class="incident-icon incident-type-icon"
        [ngClass]="
          'list-icon-' + typeOf(incident.type?.toLowerCase(), 'incidentType') + ifUnconfirmed(incident.status)
        "></i>
    </div>

    <div class="incident-details" [class.in-progress]="incident.status === incidentStatus.Confirmed">
      <div class="incident-time">
        <span class="incident-time-absolute">
          {{ incident.startedAt | dateFormat: accountStore.account.regionalSetting }}
        </span>
        <ng-container *ngIf="incident?.creationSource === creationSource.Atms || !!incident?.atmsId; else cadLabel">
          <span class="incident-data-source">{{ 'atms' | translate }}</span>
        </ng-container>
        <ng-template #cadLabel>
          <span
            *ngIf="incident?.creationSource === creationSource.Cad || !!incident?.cadId"
            class="incident-data-source"
            >{{ 'cad' | translate }}</span
          >
        </ng-template>
        <div
          matTooltipHideDelay="500"
          matTooltipShowDelay="500"
          [matTooltip]="'tooltips.unconfirmedOverTimeIndication' | translate"
          matTooltipClass="reported-some-time-ago-tooltip"
          [matTooltipDisabled]="incidentIndicationTxt !== UNCONFIRMED_OVER_TIME"
          [matTooltipPosition]="'above'"
          *ngIf="!isTabletMode && incidentIndicationTxt"
          class="incident-status-indication"
          [class.reported-some-time-ago-indication]="incidentIndicationTxt === UNCONFIRMED_OVER_TIME">
          <div *ngIf="incidentIndicationTxt === UNCONFIRMED_OVER_TIME" class="clock-rotate-right-icon"></div>
          {{ incidentIndicationTxt | translate }}
        </div>
      </div>

      <div class="lines-wrapper">
        <div class="incident-id-type-subtype-row">
          <div class="incident-id">{{ incident.id }}</div>

          <div>{{ 'incidentTypes.' + incident.type?.toUpperCase() | translate }}</div>
          <div
            wcTooltipPlacement="top"
            [wcTooltipText]="'incidentSubTypes.' + typeOf(incident.subType, 'subType') | translate"
            class="incident-subtype"
            *ngIf="incident.subType && typeOf(incident.subType, 'subType')">
            {{ 'incidentSubTypes.' + typeOf(incident.subType, 'subType') | translate }}
          </div>
          <div
            wcTooltipPlacement="top"
            [wcTooltipText]="'incident.severity' | translate"
            class="border-left"
            *ngIf="incident.severity">
            <ng-container
              *ngIf="incident.severity === IncidentSeverity.Critical || incident.severity === IncidentSeverity.Major"
              ><i class="fas fa-bell-on"></i>
            </ng-container>
            {{ 'incidentSeverity.' + incident.severity | translate }}
          </div>
          <div class="incident-other-desc" *ngIf="incident.type === incidentTypes.Other && incident.typeDescription">
            {{ incident.typeDescription }}
          </div>
          <div class="subtype-tooltip" *ngIf="incident.typeDescription">
            {{ incident.typeDescription }}
          </div>
        </div>

        <div class="incident-title-container">
          <div class="tooltip">
            <div class="incident-title" [class.in-progress]="incident.status === incidentStatus.Confirmed">
              <span
                class="estimated"
                *ngIf="
                  incident.type?.toLowerCase() === incidentTypes.Unidentified.toLowerCase() &&
                  incident.creationSource === 'PLATFORM'
                ">
                {{ 'estimated' | translate }}
              </span>
              <ng-container *ngIf="incident.title; else address">{{ incident.title }} </ng-container>
              <ng-template #address>
                <span class="incident-title-corridor">{{ incident.address.corridor }}&nbsp;</span>
                <span class="incident-title-direction">{{ incident.address.direction | translate }}&nbsp;</span>
                <span *ngIf="incident.address?.crossroad" class="incident-title-orientation"
                  >{{ incident.address.orientation | translate }}&nbsp;</span
                >
                <span class="incident-title-crossroad">{{ incident.address.crossroad }}&nbsp;</span>
                <span class="incident-title-roadType">{{ incident.address.roadType | roadTypeLabel }}</span>
                <span class="tooltiptext">
                  {{ incident.address.corridor }}
                  {{ incident.address.crossroad }}
                  {{ incident.address.roadType | roadTypeLabel }}</span
                >
              </ng-template>
              <div class="affected-lanes-shorts">
                <span *ngFor="let affectedLane of affectedLanesShorts"> {{ affectedLane }}&nbsp; </span>
              </div>
              <div class="incident-last-line">
                <div class="full-closure" *ngIf="incident.allLanesAffected">
                  <i class="full-closure-icon fas fa-do-not-enter"></i>
                  {{ 'fullClosure' | translate }}
                </div>

                <span
                  *ngIf="incident.associatedUnits?.length > 0"
                  class="incident-associated-units"
                  [class.border-unit]="incident.allLanesAffected">
                  <span class="unit-icon-contianer">
                    <div class="phone-unit-icon"></div>
                  </span>
                  <span
                    [class.three-units-or-more]="associatedUnitsNames.length > 1"
                    class="unit-badge"
                    *ngFor="let unitName of associatedUnitsNames.splice(0, showUnitAmount); let i = index">
                    <span class="bold-text" *ngIf="unitName?.routes && unitName.routes.length > 0">
                      {{ unitName?.routes }} |
                    </span>
                    <wc-chip
                      [isMeStyle]="unitName.isMe"
                      [isTablet]="isTabletMode"
                      [style]="ChipStyles.units"
                      [text]="unitName?.dispalyId">
                    </wc-chip>
                  </span>
                  <span class="plus-three" *ngIf="associatedUnitsNames.length > showUnitAmount">
                    &nbsp;+{{ associatedUnitsNames.length - showUnitAmount }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="incident.mergeDetails?.targetId" class="merged-into">
          {{ 'mergedIntoIncident' | translate }}
          <span class="merged-into-link" (click)="clickedMergedInto($event, incident.mergeDetails?.targetId)">{{
            incident.mergeDetails?.targetId
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="incident-buttons-container"
    *wcIfPermission="'INCIDENT:CREATE'"
    [class.buttons-container-border]="
      incident.status !== incidentStatus.Completed && incident.status !== incidentStatus.Rejected
    "
    [matTooltipDisabled]="!isEditBlocked"
    matTooltip="{{ 'editBlockedTooltipMsg' | translate }}"
    [matTooltipClass]="'edit-block-tooltip'"
    [class.hidden-complete-container]="isTabletMode && incident.status === incidentStatus.Confirmed">
    <ng-container *ngIf="incident.status === incidentStatus.Unconfirmed">
      <button
        class="incident-button confirm-button"
        type="button"
        [disabled]="isEditBlocked"
        wcHeapClass="confirm-incident-from-card"
        (click)="changeIncidentStatus($event, incident, incidentStatus.Confirmed)">
        {{ 'confirm' | translate }}
      </button>
      <button
        [disabled]="isEditBlocked"
        class="incident-button reject-button heap-reject-click-incident-item-incident"
        type="button"
        wcHeapClass="reject-incident-from-card"
        (click)="changeIncidentStatus($event, incident, incidentStatus.Rejected)">
        {{ 'reject' | translate }}
      </button>
    </ng-container>

    <div
      *ngIf="incident.status === incidentStatus.Completed || incident.status === incidentStatus.Rejected"
      [matTooltipDisabled]="!incident.mergeDetails?.isBlocked"
      matTooltip="{{ 'incidentCannotBeRestored' | translate }}"
      [matTooltipPositionAtOrigin]="true"
      [matTooltipClass]="'edit-block-tooltip'"
      class="incident-buttons-container__restore">
      <button
        [disabled]="incident.mergeDetails?.isBlocked || (isTabletMode && incident.mergeDetails?.targetId)"
        data-cy-id="incident-restore-button"
        class="incident-button restore-button"
        type="button"
        wcHeapClass="restore-incident-from-card"
        (click)="restoreIncident($event, incident)">
        <div class="action-icon-div">
          <i class="fas fa-undo"></i>
          {{ 'restore' | translate }}
        </div>
      </button>
    </div>

    <ng-container *ngIf="!isTabletMode">
      <button
        class="incident-button complete-button"
        type="button"
        [disabled]="isEditBlocked"
        wcHeapClass="complete-incident-from-card"
        *ngIf="incident.status === incidentStatus.Confirmed"
        (click)="changeIncidentStatus($event, incident, incidentStatus.Completed); $event.stopPropagation()">
        <div class="action-icon-div">
          <i class="fas fa-check"></i>
          {{ 'complete' | translate }}
        </div>
      </button>
    </ng-container>
  </div>
  <div *mobxAutorun="{ dontDetach: true }">
    <wc-collaboration-viewer
      [viewMode]="'item'"
      [editDrivers]="incidentStore.editCollaboration[incident.id]"
      [viewerDrivers]="incidentStore.viewCollaboration[incident.id]"></wc-collaboration-viewer>
  </div>
</div>
