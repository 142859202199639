import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  retryCount = 0;
  //@Inject(AuthService)
  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let handler: Observable<HttpEvent<any>>;

    // Only add authorization information for waycare API requests
    if (
      req.url.indexOf('waycare2cloud.net') === -1 &&
      req.url.indexOf('waycare.io') === -1 &&
      req.url.indexOf('localhost') === -1
    ) {
      handler = next.handle(req);
    } else {
      handler = this.auth.getTokenSilently$().pipe(
        map(token => {
          if (!token) {
            throw token;
          } else {
            return req.clone({
              setHeaders: { Authorization: `Bearer ${token}` },
            });
          }
        }),
        mergeMap(_req => {
          return next.handle(_req).pipe(
            catchError((error: HttpErrorResponse) => {
              datadogLogs.logger.info('InterceptorService - Http error', {
                network_error: JSON.stringify(error),
              });
              if (error.status === 401) {
                this.auth.login();
              }
              return throwError(error);
            })
          );
        })
      );
    }
    return handler;
  }
}
