<ng-container [ngSwitch]="streamType">
  <iframe id="securityPixelIFrame" class="security-pixel" *ngIf="securedIframeUrl" [src]="securedIframeUrl"></iframe>

  <div
    data-cy-id="camera-stream-container"
    class="camera-stream-container video-stream-container"
    [class]="appType"
    [class.tablet-mode]="isTabletMode"
    [class.incident-cameras-container]="contextType === cameraContextType.IncidentCameras"
    [class.inactive-camera-container]="showInactiveCameraError"
    *ngSwitchCase="cameraStreamType.VideoStream">
    <div
      class="camera-stream-inner-container"
      [class.livemap-stream-inner-container]="contextType === cameraContextType.LiveMapModal"
      *ngIf="!showInactiveCameraError; else inactiveCameraError">
      <ng-container *ngIf="!isCameraLoading; else loading">
        <video #video class="camera-stream-player video-js vjs-default-skin vjs-fill" autoplay muted></video>
      </ng-container>
    </div>
  </div>

  <div
    data-cy-id="camera-stream-container"
    class="camera-stream-container image-stream-container"
    *ngSwitchDefault
    [class.camera-error-image]="showCameraStreamError"
    [class.incident-cameras-container]="contextType === cameraContextType.IncidentCameras"
    [class.tablet-mode]="isTabletMode">
    <ng-container *ngIf="online$; else offline">
      <ng-container *ngIf="!showInactiveCameraError; else inactiveCameraError">
        <div class="camera-image-container" [class.dash-cam-container]="_camera.position">
          <wc-loader-component class="camera-loader" [delay]="0" [show]="isCameraImageLoading"></wc-loader-component>

          <div class="camera-inner-container" *ngIf="_camera['thumbnail'] !== 'error'; else showError">
            <div data-cy-id="dashcam-badge" class="dashcam-badge" *ngIf="_camera.position">
              {{ _camera.position }}
            </div>

            <img class="camera-stream-image" [src]="_camera['thumbnail']" [alt]="_camera.title" />
          </div>

          <ng-template #showError>
            <i class="camera-error-icon"></i>
          </ng-template>
        </div>

        <div data-cy-id="camera-error-container" class="camera-error-container" *ngIf="showCameraStreamError">
          <div data-cy-id="camera-error-title" class="camera-error-message">
            {{ 'cameraLoadingError' | translate }}
          </div>
          <div class="camera-error-action">
            {{ 'cameraLoadingErrorActionPart1' | translate }}
          </div>
          <div class="camera-error-action">
            {{ 'cameraLoadingErrorActionPart2' | translate }}
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #offline>
      <div class="camera-offline-container">
        <img class="camera-stream-image" src="assets/images/offline-camera.svg" [alt]="_camera.title" />
        <div class="camera-error-container">
          <div class="camera-offline-message">
            {{ 'emptyStates.offlineCameraTitle' | translate }}
          </div>
          <div class="camera-offline-message">
            {{ 'emptyStates.offlineCameraMessage' | translate }}
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>

<ng-template #inactiveCameraError>
  <div class="camera-inactive-container" [class.tablet-mode]="isTabletMode">
    <img class="camera-inactive-icon" src="assets/images/empty-camera-error.svg" />

    <div class="camera-error-container">
      <div data-cy-id="camera-error-title" class="camera-error-message">
        {{ 'cameraVendorErrorTitle' | translate }}
      </div>
      <div data-cy-id="camera-error-message" class="camera-error-action">
        {{ 'cameraVendorErrorText' | translate }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <wc-loader class="camera-loader" [delay]="0" [show]="true"></wc-loader>
</ng-template>
