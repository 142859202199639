import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { WcAutocompleteV2Module } from '@wc/wc-ui/src/form-components/autocomplete-v2/autocomplete-v2.module';
import { BaseControlModule } from '../../base/base-control/base-control.module';
import { WcDateTimeModule } from '../../form-fields-components/date-time/date-time.module';
import { WcSelectChipsModule } from '../../form-fields-controls/select-chips/select-ships.module';
import { MitigationComponent } from './mitigation.component';

// const Materials: any[] = [
// ]

@NgModule({
  declarations: [MitigationComponent],
  imports: [
    CommonModule,
    BaseControlModule,
    // Materials,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    WcAutocompleteV2Module,
    WcSelectChipsModule,
    WcDateTimeModule,
  ],
  providers: [FormControl],
  exports: [MitigationComponent],
})
export class MitigationComponentModule {}
