import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { BaseControlFieldComponent } from '../../../features/ui/base/base-control-field.component';
import { WCErrorStateMatcher } from './error-state-matcher';

const Materials: any[] = [MatFormFieldModule, MatInputModule];

@NgModule({
  declarations: [BaseControlFieldComponent],
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, Materials],
  providers: [FormControl, WCErrorStateMatcher],
  exports: [BaseControlFieldComponent, TranslateModule, FormsModule, ReactiveFormsModule, Materials],
})
export class WcBaseControlFieldModule {}
