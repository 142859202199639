import { ConstructionType, LayerType, SpecialEventType } from './gql.models';

export enum AppSounds {
  incidentAlert = 'incident-alert',
  wrongWayAlert = 'wrong-way-alert',
  empty = 'empty',
}

export enum AppSoundFileTypes {
  wav = 'wav',
  mp3 = 'mp3',
}

export enum BrowserSoundSettingsLink {
  Chrome = 'chrome://settings/content/sound',
}

export enum AlertType {
  Success = 'SUCCESS',
  Error = 'ERROR',
  Info = 'INFO',
  Warning = 'WARNING',
}

export enum FormViewMode {
  Edit = 'EDIT',
  Read = 'READ',
  New = 'NEW',
}

export enum ShiftFormState {
  Start = 'START',
  Edit = 'EDIT',
  PauseStart = 'PAUSE_START',
  PauseEnd = 'PAUSE_END',
  End = 'END',
  ShiftRemoved = 'SHIFT_REMOVED',
  RouteRemoved = 'ROUTE_REMOVED',
}

export enum MenuLaneTypes {
  LANE = 'Lane',
  RIGHT_SHOULDER = 'Right shoulder',
  LEFT_SHOULDER = 'Left shoulder',
  GORE = 'Gore',
}

export enum AnalyticsReportsTypes {
  fspReport = 'fspReport',
  speedsReport = 'speedsReport',
  unitsPerformanceReport = 'unitsPerformanceReport',
  generalReport = 'generalReport',
  incidentAnalysisReport = 'incidentAnalysisReport',
  mitigationsReport = 'mitigationsReport',
  speedMeasurementReport = 'speedMeasurementReport',
  timPerformanceReport = 'timPerformanceReport',
}

export enum ShiftType {
  AM = 'AM',
  PM = 'PM',
}

export enum ShiftEndReason {
  EndOfShift = 'END_OF_SHIFT',
  VehicleOutOfOrder = 'VEHICLE_OUT_OF_ORDER',
  DriverInjury = 'DRIVER_INJURY',
  Other = 'OTHER',
}

export enum ShiftPauseReason {
  Refueling = 'REFUELING',
  LunchBreak = 'LUNCH_BREAK',
  BathroomBrake = 'BATHROOM_BRAKE',
  VehicleOutOfOrder = 'VEHICLE_OUT_OF_ORDER',
  VehicleDamaged = 'VEHICLE_DAMAGED',
  DriverInjury = 'DRIVER_INJURY',
  Other = 'OTHER',
}

export enum permissionsGroupsEnum {
  Manager = 'MANAGER',
  Viewer = 'VIEWER',
  NoAccess = 'NO_ACCESS',
}

export enum ConfirmationModalType {
  DiscardChanges = 'DISCARD_CHANGES',
  RemoveUser = 'REMOVE_USER',
  RemoveRole = 'REMOVE_ROLE',
  UnassignUser = 'UNASSIGN_USER',
  UnassignUnit = 'UNASSIGN_UNIT',
  RemoveUnit = 'REMOVE_UNIT',
  SoundAlertPopup = 'SOUND_ALERT_POPUP',
  DarkModePopup = 'DARK_MODE_POPUP',
  CompleteHasUnits = 'COMPLETE_HAS_UNITS',
  EditTimeExpired = 'EDIT_TIME_EXPIRED',
  EditAboutToExpire = 'EDIT_ABOUT_TO_EXPIRE',
  LeaveWithoutSaving = 'LEAVE_WITHOUT_SAVING',
  ResetChanges = 'RESET_CHANGES',
  ShiftHistoricalRelatedIncidents = 'SHIFT_HISTORICAL_RELATED_INCIDENTS',
  IncidentMergedIntoAnotherIncident = 'INCIDENT_MERGED_INTO_ANOTHER_INCIDENT',
  ResponsePlanNotDone = 'RESPONSE_PLAN_NOT_DONE',
  LeaveEditMode = 'LEAVE_EDIT_MODE',
  WrongWayAlert = 'WRONG_WAY_ALERT',
  OnboardingTours = 'ONBOARDING_TOURS',
}

export enum Stepper {
  Step1 = 0,
  Step2 = 1,
  Step3 = 2,
  Step4 = 3,
}

export enum MediaGalleryValidationErrors {
  FileMaxSize = 'mediaGallery.validationFileMaxSize',
  TotalSize = 'mediaGallery.validationTotalSize',
  UnsupportedFormat = 'mediaGallery.validationUnsupportedFormat',
  UploadFailed = 'mediaGallery.validationUploadFailed',
}

export enum SortOptions {
  Type = 'type',
  Time = 'time',
  TimeNewFirst = 'timeNewFirst',
  TimeOldFirst = 'timeOldFirst',
  Assignee = 'assignee',
}

export enum SortName {
  // desktop
  SortTypeForInProgressList = 'sortTypeForInProgressList',
  SortTypeForCompletedList = 'sortTypeForCompletedList',
  SortTypeForNeedActionList = 'sortTypeForNeedActionList',
  SortTypeForTrafficDisruptionList = 'sortTypeForTrafficDisruptionList',

  // tablet
  SortTabletAllLists = 'sortTabletAllLists',
}

export enum LanesDirections {
  One = 'SINGLE_DIRECTION',
  Multi = 'MULTIPLE_DIRECTION',
}

export enum TransitUnitLayers {
  transit_on_demand_unit = 'transit_on_demand_unit',
  transit_on_demand_unit_my_agency = 'transit_on_demand_unit_my_agency',
  transit_demand_response_unit = 'transit_demand_response_unit',
  transit_demand_response_unit_my_agency = 'transit_demand_response_unit_my_agency',
  transit_fixed_route_bus_unit = 'transit_fixed_route_bus_unit',
  transit_fixed_route_bus_unit_my_agency = 'transit_fixed_route_bus_unit_my_agency',
}

export enum RoadEventsPanelTabIndex {
  NeedAction = 0,
  InProgress = 1,
  Completed = 2,
}

export enum RoadEventsPanelSubTabIndexDesktop {
  Incidents = 0,
  TrafficDisruptions = 1,
}

export enum RoadEventsPanelSubTabIndexTablet {
  Incidents = 0,
}

export enum RoadEventType {
  Incident = 'incident',

  TrafficDisruption = 'trafficDisruption',
}

export enum DataHubTablesByIndex {
  incident,
  driverShift,
}

export enum DefaultSwiperConfig {
  direction = 'horizontal',
  slidesPerView = 3,
  spaceBetween = 15,
  prevEl = 'swiper-button-prev',
  nextEl = 'swiper-button-next',
  threshold = 0,
}

export type assigneeGroups = 'assignedToMe' | 'assignedToOther' | 'unassigned';

export enum WeatherEventStatus {
  Active = 'active',
  Forecast = 'forecast',
  AllAlerts = 'allAlerts',
}

export enum Miscellaneous {
  'CAMERA' = 'camera',
  'CONGESTION' = 'congestion',
  'DMS' = 'dms',
}

export const entitySubType = {
  ...Miscellaneous,
  ...uniteLayerTypes(ConstructionType, LayerType.Construction),
  ...uniteLayerTypes(SpecialEventType, LayerType.SpecialEvent),
};

function uniteLayerTypes(values: Record<string, any>, layerType: LayerType) {
  return Object.values(values).reduce((acc, curr) => {
    acc[curr] = layerType.toLowerCase();
    return acc;
  }, {});
}

export enum WebSocketType {
  incidentUpdate = 'incident_update',
  mergeIncident = 'merge_incident',
  sharePublicResponse = 'share_public_response',
  locationUpdate = 'location_update',
  affectedTransitServiceUpdate = 'affected_transit_services_update',
  routesActiveBusesUpdate = 'routes_active_buses_update',
  transitFixedRouteButUnit = 'transit_fixed_route_bus_unit',
  busStopsLoadUpdate = 'bus_stops_load_update',
  trainDelays = 'train_delays',
}

export const SoundAlertMode = {
  All: 0,
  WrongWayOnly: 1,
  Off: 2,
} as const;

export enum SettingsUrl {
  GENERAL = 'account/general',
  WSC = 'account/general/work-schedule-configuration',
  RESOURCES = 'account/general/resources',
  INCIDENT_FORM = 'incident/form',
  INCIDENT_SHARE_TO_PUBLIC = 'incident/share-to-public',
  ALIASES_MANAGEMENT = 'live-map/aliases-management',
  ROLES = 'account/roles',
  USERS = 'account/users',
  ROAD_CLOSURE_SHARE_TO_PUBLIC = 'road-closure/share-to-public',
  CONSTRUCTION_SHARE_TO_PUBLIC = 'construction/share-to-public',
  SPECIAL_EVENT_RESPONSE_PLAN_TEMPLATES = 'special-event/response-plan-templates',
  ROUTES = 'field-operations/routes',
  SHIFTS = 'field-operations/shifts',
  INCIDENT_TYPE_PRIORITY = 'incident/type-priority',
}
