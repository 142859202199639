import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { HeapAnalyticsService } from '@wc-core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { FormFieldData } from '../../../lib/base/custom-form-control';
import { AffectedLanesUtilsService, MoveGoreAction } from '../affected-lanes-utils-service';

@Component({
  selector: 'wc-affected-lanes-slider',
  templateUrl: './affected-lanes-slider.component.html',
  styleUrls: ['./affected-lanes-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AffectedLanesSliderComponent implements OnInit {
  @ViewChild(SwiperDirective, { static: true }) swiperElm!: SwiperDirective;
  lanes: FormArray = this.fb.array([]);
  parentForm: FormGroup = this.fb.group({ affectedLanes: this.lanes });

  config!: SwiperConfigInterface;
  formFieldData: { [key: string]: FormFieldData } = {};

  @Input() hovered: any;
  @Input() allLanesAffected = false;
  private _isMultiDirection = true;

  get isMultiDirection() {
    return this._isMultiDirection;
  }

  @Input() set isMultiDirection(value: boolean) {
    this._isMultiDirection = value;

    this.initSwiperConfig();
    this.cdr.detectChanges();
  }

  @Output() laneHover = new EventEmitter();
  @Output() deleteLaneByIndex = new EventEmitter<number>();
  @Output() moveGoreAction = new EventEmitter<MoveGoreAction>();

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private heapService: HeapAnalyticsService,
    private affectedLanesUtilsService: AffectedLanesUtilsService,
    @Optional() private formGroupDirective: FormGroupDirective
  ) {
    this.formFieldData = this.affectedLanesUtilsService.getAffectedLanesFormData(true);
  }

  updateSwiper() {
    this.swiperElm.update();
    this.cdr.markForCheck();
  }
  ngOnInit(): void {
    this.lanes = this.formGroupDirective.form.get('affectedLanes') as FormArray;
    this.parentForm = this.formGroupDirective.form;
    this.initSwiperConfig();
  }

  initSwiperConfig() {
    this.config = {
      slideClass: 'affected-lanes-slider-lane',
      direction: 'horizontal',
      slidesPerView: 3,
      spaceBetween: 10,
      threshold: 5,
      navigation: {
        nextEl: '.swiper-button-next-affected-lanes',
        prevEl: '.swiper-button-prev-affected-lanes',
      },
      pagination: {
        el: '.swiper-pagination-affected-lanes',
        clickable: true,
      },
      /** Disable slider arrows if there is only 1 available slide */
      watchOverflow: true,
    };

    this.swiperElm?.init();
    this.cdr.markForCheck();
  }

  deleteLane(laneIndex) {
    if (this.lanes.length === 1) return;
    this.deleteLaneByIndex.emit(laneIndex);
  }

  selectLane(selected: MatCheckboxChange, laneIndex: number) {
    const lane = this.lanes.at(laneIndex);
    lane.get('isClosed')?.setValue(selected.checked);
    lane.get('isAffected')?.setValue(selected.checked);

    this.heapService.trackUserSpecificAction(`heap-affected-lane-checked`, {
      isClosed: selected.checked,
      type: lane.value.type,
    });
  }

  moveGore(index: number, moveLeft: boolean) {
    this.moveGoreAction.emit({ index: index, moveLeft: moveLeft });
    this.swiperElm?.update();
  }

  laneHovered(lane, index) {
    this.laneHover.emit({ lane, index });
  }

  laneClicked(index: number) {
    const lane = this.lanes.at(index);
    const isAffected = lane.get('isAffected')?.value;
    this.lanes.at(index).get('isAffected')?.setValue(!isAffected);
    this.lanes.at(index).get('isClosed')?.setValue(!isAffected);
    this.cdr.markForCheck();
  }
}
