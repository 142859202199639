<form [formGroup]="form" class="rp-template-page-component" (keydown.enter)="$event.preventDefault()">
  <wc-loader-component [show]="requestInProgress"></wc-loader-component>
  <div class="rp-template-page-component__header">
    <span *ngIf="isClone; else edit"> {{ 'cloneResponsePlan' | translate }} </span>
    <ng-template #edit
      ><span> {{ 'editResponsePlan' | translate }} </span></ng-template
    >
    <i (click)="closeDialog()" class="far fa-times fa-lg"></i>
  </div>
  <div class="rp-template-page-component__title">
    <wc-text-field
      data-cy-id="response-plan-template-title"
      customValidationMessage="uniqueTemplateName"
      [viewCharacterCounter]="true"
      formControlName="title"
      [formFieldData]="titleFieldData"></wc-text-field>
  </div>
  <div class="rp-template-page-component__body">
    <wc-response-plan-form
      [uiMode]="uiModeEnum.Template"
      [regionalSetting]="regionalSetting"
      [entityType]="entityType"></wc-response-plan-form>
  </div>

  <div class="rp-template-page-component__footer">
    <button [disabled]="requestInProgress" (click)="closeDialog()" class="secondary-button">
      {{ 'cancel' | translate }}
    </button>
    <button
      [disabled]="requestInProgress"
      (click)="submitResponsePlan()"
      class="primary-button"
      data-cy-id="response-plan-template-save-btn">
      {{ 'save' | translate }}
    </button>
  </div>
</form>
