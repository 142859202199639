import { ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { APP_TYPE_TOKEN } from '@wc/wc-core/src/lib/injection-tokens';
import { AppTypeUnion } from '@wc/wc-models/src';
import { CustomFormControlComponent, FormFieldOption } from '../../lib/base/custom-form-control';

@Component({
  selector: 'wc-multiple-chips',
  templateUrl: './wc-multiple-chips.component.html',
  styleUrls: ['./wc-multiple-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MultipleChipsComponent extends CustomFormControlComponent implements OnInit {
  @Input() viewMode = false;
  @Input() isEditableList = false;
  @Input() viewChipListAsStrings: string[] = [];
  @Input() maxChipsAllowed = 7;
  @Input() maxChipTextLength = 25;
  showHiddenOptions = false;

  isBlockingAddChips = false;
  readonly separatorKeysCodes = [ENTER] as const;
  get appType() {
    return this._appMode;
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    cdr: ChangeDetectorRef,
    @Inject(APP_TYPE_TOKEN) private _appMode: AppTypeUnion
  ) {
    super(ngControl, cdr);
  }

  ngOnInit(): void {
    const controlValue = this.ngControl?.control?.value || [];
    this.formFieldData.options?.forEach(o => (o.selected = controlValue.includes(o.value)));
  }

  chipSelected(option: FormFieldOption) {
    option.selected = !option.selected;
    const selectedValues = this.formFieldData.options?.filter(o => o.selected).map(o => o.value);
    this.onChanged(selectedValues);
  }

  showHidden() {
    this.showHiddenOptions = true;
  }

  get visibleOptions() {
    return (
      this.formFieldData?.options?.filter(
        op => !op.hidden || (op.hidden && (this.ngControl?.control?.value || []).includes(op.value))
      ) || []
    );
  }

  get hiddenOptions() {
    return (
      this.formFieldData?.options?.filter(
        op => op.hidden === true && !(this.ngControl?.control?.value || []).includes(op.value)
      ) || []
    );
  }

  addChip(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value && this.formFieldData.options) {
      this.formFieldData.options.push({ displayName: value, value: value });
      this.onChanged(this.formFieldData.options.map(o => o.value) || []);
      if (this.formFieldData.options.length === this.maxChipsAllowed) this.isBlockingAddChips = true;
      this.ngControl.control?.markAsTouched();
    }

    event.chipInput?.clear();
  }

  removeChip(option: FormFieldOption): void {
    if (this.formFieldData.options) {
      this.ngControl.control?.markAsTouched();

      const index = this.formFieldData.options?.findIndex(o => o.value === option.value);

      if (index !== -1) {
        this.isBlockingAddChips = false;
        this.formFieldData.options?.splice(index, 1);
        this.onChanged(this.formFieldData.options?.map(o => o.value) || []);
      }
    }
  }
}
