import { HashLocationStrategy, Location, LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  LiveMapEntity,
  LiveMapEntityType,
  LocationService,
  MapCenterOptions,
  Position,
  WcMapConfigModel,
  WindowService,
} from '@wc/core';
import { WcMapConfig } from '@wc/core/livemap.config';
import { CacheService } from '@wc/core/services/cache.service';
import { ToastrAlertsService } from '@wc/wc-ui/src/services/toaster-alert.service';
import { getCenterOfBounds } from 'geolib';
import { DeviceDetectorService } from 'ngx-device-detector';
import { defaults as defaultControls } from 'ol/control';
import { Coordinate } from 'ol/coordinate';
import { easeOut } from 'ol/easing';
import { buffer, createEmpty, createOrUpdateFromCoordinate, extend, isEmpty } from 'ol/extent.js';
import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';
import { SimpleGeometry } from 'ol/geom';
import Layer from 'ol/layer/Layer';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import 'ol/ol.css';
import Overlay from 'ol/Overlay';
import { fromLonLat, toLonLat, transform } from 'ol/proj.js';
import Cluster from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';
import { Stroke, Style } from 'ol/style';
import View from 'ol/View';
import { fromEvent, interval, Observable, Subject, Subscription } from 'rxjs';
import { retry, switchMap, throttleTime } from 'rxjs/operators';
import { GeoCalc } from './geo-calc';
import { Drag, HoverEntity, SelectEntity } from './interactions';
import { mapboxglLayer } from './mapboxgl-layer';
import * as Styles from './styles/styles';
import { WcMapDrawService } from './wc-map-draw.service';
import { WcMapService } from './wc-map.service';

export interface WcAnimationOptions {
  duration?: number;
  offsetY?: number;
  offsetX?: number;
  zoom?: number;
}

type EntityType = Record<string, LiveMapEntityType | never | boolean>;
@Component({
  selector: 'wc-map',
  template: ``,
  styleUrls: ['../ol.scss'],
  providers: [Location, { provide: LocationStrategy, useClass: HashLocationStrategy }],
})
export class WcMapComponent implements AfterViewInit {
  @Output() ready: EventEmitter<Map> = new EventEmitter();
  @Output() selected: EventEmitter<EntityType[]> = new EventEmitter();
  @Output() mapCenterMoved: EventEmitter<{
    coordinates: Array<Array<Array<number>>>;
    options?: {
      duration?: number;
      padding?: Array<number>;
      bufferSize?: number;
      zoomLevel: number;
      maxZoom?: number;
    };
  }> = new EventEmitter();
  @Output() over: EventEmitter<EntityType> = new EventEmitter();
  @Output() dragEnd: EventEmitter<{
    entity: LiveMapEntity;
    coordinates: Coordinate;
  }> = new EventEmitter();
  @Output() rightClick: EventEmitter<{
    coords: Array<number>;
    pixel: Array<number>;
  }> = new EventEmitter();
  @Input() forceTileLayer = false;
  @Input() showFocusMapCenterIcon = false;
  @Input() allowZoomOnMap = true;

  @Input() set tooltipElm(elm: HTMLElement) {
    this.tooltipOverlay = new Overlay({
      element: elm,
    });
  }

  @Input() set modalElm(elm: HTMLElement) {
    this.modalOverlay = new Overlay({
      element: elm,
    });
  }

  _mapStyleMode!: 'day' | 'night';

  @Input() set mapStyleMode(styleMode: 'day' | 'night') {
    this.config;
    this._mapStyleMode = styleMode || 'day';
    if (!this.wcMapService) return;
    const backgroundColor =
      styleMode === 'night' ? this.wcMapService.config.backgroundDarkColor : this.wcMapService.config.backgroundColor;
    this.el.nativeElement.style.backgroundColor = backgroundColor;

    this.wcMapService.config.currentStyle = styleMode;
    this.map.removeLayer(this.basicMapLayer);
    if (this.config.isTabletMode || this.forceTileLayer) {
      this.basicMapLayer = this.tilesLayer();
    } else {
      const mapboxTiles = this._mapStyleMode === 'day' ? this.mapboxVectorStyleLight : this.mapboxVectorStyleDark;
      this.basicMapLayer = mapboxglLayer(this.map, this.wcMapService.config, mapboxTiles);
    }

    this.map.addLayer(this.basicMapLayer);

    this.map.getLayers().forEach(layer => {
      (layer as VectorLayer).getSource().changed();

      if (this.config.layers[layer.get('name')]) {
        if (layer.get('name') !== 'camera' && layer.get('name') !== 'dms') {
          (layer as VectorLayer).getSource().forEachFeature((feature: Feature) => {
            const style = Styles.styleType(feature.getProperties() as LiveMapEntity, this.config, feature);
            feature.setStyle(style);
          });
        }
      }
    });

    this.workspacesLayer.getSource().forEachFeature((feature: Feature) => {
      const style = Styles.styleType(feature.getProperties() as LiveMapEntity, this.config, feature);
      feature.setStyle(style);
    });
  }

  map!: Map;
  view!: View;
  wcMapService!: WcMapService;
  wcMapDrawService: WcMapDrawService = new WcMapDrawService();
  tooltipOverlay!: Overlay;
  modalOverlay!: Overlay;
  config!: WcMapConfigModel;
  mapIsDraged!: boolean;
  lastZoomLevel!: number;
  doUpdateUrlLocation = true;
  basicMapLayer!: Layer | TileLayer;
  lastSelectedFeature: { [layerName in LiveMapEntityType]?: Feature } = {};
  lastSelectedEntity: { [layerName in LiveMapEntityType]?: EntityType } = {};

  workspacesLayer!: VectorLayer;
  geoCalc!: GeoCalc;
  selectEntityHandler!: SelectEntity;
  hoverEntityHandler!: HoverEntity;
  dragEntityHandler!: Drag;

  pointerdrag$: Subject<unknown> = new Subject();
  moveend$: Subject<unknown> = new Subject();
  dragEnd$: Observable<unknown> = new Observable();

  onlineSub$: Subscription = new Subscription();
  isMobileDevice = this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet();
  private reCenterString!: string;
  jurisdictionLayerStyles = [
    new Style({
      stroke: new Stroke({
        color: '#FE767D',
        width: 4,
      }),
    }),
  ];

  mapboxRasterStyleLight!: string;
  mapboxVectorStyleLight!: string;
  mapboxRasterStyleDark!: string;
  mapboxVectorStyleDark!: string;

  constructor(
    private el: ElementRef,
    private urlLocation: Location,
    private httpClient: HttpClient,
    private alertService: ToastrAlertsService,
    private translateService: TranslateService,
    private cacheService: CacheService,
    private zone: NgZone,
    private windowService: WindowService,
    private locationService: LocationService,
    private deviceDetectorService: DeviceDetectorService
  ) {
    if (this.isMobileDevice) {
      const lightTablet = 'cl8cnp8p3000114o71yymyj87';
      const darkTablet = 'cl96r78r8009g14n3umpyoi0v';
      this.mapboxVectorStyleLight = `mapbox://styles/waycare/${lightTablet}`;
      this.mapboxVectorStyleDark = `mapbox://styles/waycare/${darkTablet}`;

      this.mapboxRasterStyleLight = `https://api.mapbox.com/styles/v1/waycare/${lightTablet}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoid2F5Y2FyZSIsImEiOiJjanp1eTd4aXAwMGt0M2NueHh3dXI0dWpiIn0.v27dO5Fkf58A8s2WzJi34A`;
      this.mapboxRasterStyleDark = `https://api.mapbox.com/styles/v1/waycare/${darkTablet}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoid2F5Y2FyZSIsImEiOiJjanp1eTd4aXAwMGt0M2NueHh3dXI0dWpiIn0.v27dO5Fkf58A8s2WzJi34A`;
    } else {
      const light = 'cky1dvfck9wvb14nuqu7myl62';
      const dark = 'cl82w734b002114l5pcv2yifc';
      this.mapboxVectorStyleLight = `mapbox://styles/waycare/${light}`;
      this.mapboxVectorStyleDark = `mapbox://styles/waycare/${dark}`;

      this.mapboxRasterStyleLight = `https://api.mapbox.com/styles/v1/waycare/${light}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoid2F5Y2FyZSIsImEiOiJjanp1eTd4aXAwMGt0M2NueHh3dXI0dWpiIn0.v27dO5Fkf58A8s2WzJi34A`;
      this.mapboxRasterStyleDark = `https://api.mapbox.com/styles/v1/waycare/${dark}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoid2F5Y2FyZSIsImEiOiJjanp1eTd4aXAwMGt0M2NueHh3dXI0dWpiIn0.v27dO5Fkf58A8s2WzJi34A`;
    }
  }

  ngAfterViewInit(): void {
    if (!this.map) {
      this.zone.runOutsideAngular(() => this._init());
    }
  }

  async _init() {
    this.reCenterString = await this.translateService.get('reCenter').toPromise();
    this.map = new Map({
      pixelRatio: 1,
      target: this.el.nativeElement,
      view: new View({
        center: [0, 0],
        zoom: 1,
        maxZoom: 21,
        constrainResolution: false,
      }),
      controls: defaultControls({
        zoom: this.allowZoomOnMap,
      }),
    });

    if (this.showFocusMapCenterIcon) this.addCenterMapButton();

    console.log(this.map.getSize(), this.map.getSize()?.includes(0));
    if (this.map.getSize()?.includes(0)) {
      this.updateMapSize();
    } else {
      this.ready.emit(this.map);
    }

    let isMapSizeChanged = false;

    this.map.on('change:size', () => {
      isMapSizeChanged = true;
    });
    this.map.on('pointerdrag', event => {
      // if user dragged the map - show focus map icon
      const elmIconRef = document.querySelectorAll('.map-center-btn');
      elmIconRef.forEach(element => {
        element.innerHTML = `<div class="glass map-center-location-focus-icon"></div> <span style="font-size: 20px;
        color: #113C64;">${this.reCenterString} </span>`;
      });
      this.pointerdrag$.next(event);
    });
    this.map.on('moveend', event => {
      if (!isMapSizeChanged) {
        this.moveend$.next(event);
      }
      isMapSizeChanged = false;
    });

    this.dragEnd$ = this.pointerdrag$.pipe(switchMap(() => this.moveend$));

    this.view = this.map.getView();

    if (this.tooltipOverlay) this.map.addOverlay(this.tooltipOverlay);
    if (this.modalOverlay) this.map.addOverlay(this.modalOverlay);

    this.geoCalc = new GeoCalc(this.map);
  }

  updateMapSize() {
    const elm = this.el.nativeElement;
    const seconds = interval(500);
    const mapSizeRetry = seconds.pipe(retry(10)).subscribe(
      () => {
        this.map.setSize([elm.clientWidth, elm.clientHeight]);
        this.ready.emit(this.map);
        mapSizeRetry.unsubscribe();
      },
      err => console.log(err)
    );
  }

  addCenterMapButton() {
    const reCenterBtn = `<div class="glass map-center-location-icon"></div> <span style="font-size: 20px;
      color: #113C64;">${this.reCenterString} </span>`;
    const element: HTMLElement = document.createElement('div');
    element.innerHTML = reCenterBtn;
    element.className = 'map-center-btn heap-tablet-map-center-btn';
    this.el.nativeElement.appendChild(element);

    const eventSource = fromEvent(element, 'click');
    eventSource.pipe(throttleTime(2000)).subscribe(async () => {
      const elmIconRef = document.querySelectorAll('.map-center-btn');
      elmIconRef.forEach(element => {
        element.innerHTML = reCenterBtn;
      });
      try {
        const pos: Position = (await this.locationService.getCurrentPosition()) as Position;
        let coordinates: number[] = [];
        if (pos) {
          coordinates = [pos.coords.longitude, pos.coords.latitude];
          this.moveMapCenterToCoordinates(coordinates);
        } else {
          const coordinatesBounds: { latitude: number; longitude: number }[] = [];
          WcMapConfig.mapCenter[0][0].forEach(cord => {
            coordinatesBounds.push({ latitude: cord[0], longitude: cord[1] });
          });
          const res = getCenterOfBounds(coordinatesBounds);
          console.log(res);
          this.moveMapCenterToCoordinates([res.latitude, res.longitude]);
        }
      } catch (error) {
        console.error(error);
      }
    });
  }

  moveMapCenterToCoordinates(coordinates: number[], duration?: number, shouldEmitDrag = true) {
    duration = duration || 1000;
    const coords = fromLonLat(coordinates);
    this.map.getView().animate({ center: coords, duration: duration }, () => {
      if (shouldEmitDrag) {
        this.pointerdrag$.next();
      }
    });
  }

  addWorkspacesBorders() {
    const workspaces = this.config.workspaces;
    try {
      if (!workspaces?.length) {
        console.error('workspaces not loaded yet!');
        return;
      }

      const workspacesGeoJson: { type: string; features: unknown[] } = {
        type: 'FeatureCollection',
        features: [],
      };

      workspaces.forEach(workspace => {
        workspacesGeoJson.features.push({
          type: 'Feature',
          id: workspace.id,
          geometry: {
            type: workspace.area.type,
            coordinates: this.wcMapService.multiPolygonConvertFromLatLong(workspace.area.coordinates),
          },
          properties: {
            id: workspace.id,
            featureType: 'workspace',
            featureSubTypeOf: 'workspace',
            status: 'inactive',
            title: workspace.title,
          },
        });
      });

      this.workspacesLayer = new VectorLayer({
        source: new VectorSource({
          features: new GeoJSON().readFeatures(workspacesGeoJson),
        }),
        style: feature => {
          const style = Styles.styleType(feature.getProperties() as LiveMapEntity, this.config, feature);
          return style;
        },
      });
      this.workspacesLayer.set('name', 'workspace');
      this.workspacesLayer.setZIndex(4);
      this.map.addLayer(this.workspacesLayer);
    } catch (e) {
      console.error(e);
    }
  }

  moveMapCenterToCoordinatesWithOffset(
    coordinates: number[],
    options: WcAnimationOptions = {
      duration: 1000,
      offsetY: 0,
      offsetX: 0,
      zoom: undefined,
    }
  ) {
    setTimeout(() => {
      const [EPSG_1, EPSG_2] = ['EPSG:4326', 'EPSG:3857'];
      this.map.updateSize();
      const transCoords = transform(coordinates, EPSG_1, EPSG_2);
      const pixel = this.map.getPixelFromCoordinate(transCoords);
      if (pixel) {
        const offset = this.map.getCoordinateFromPixel([pixel[0], pixel[1] + (options.offsetY ?? 0)]);
        const coords = fromLonLat(transform(offset, EPSG_2, EPSG_1));
        this.map.getView().animate({
          center: coords,
          duration: options.duration,
          zoom: options.zoom,
        });
      }
    });
  }

  setWorkspacesVisibility(workspacesIDs: number[]) {
    //What for workspace layer to be added.
    const seconds = interval(500);
    const mapSizeRetry = seconds.pipe(retry(10)).subscribe(
      () => {
        if (this.workspacesLayer) {
          this.workspacesLayer
            ?.getSource()
            .getFeatures()
            .forEach(feature => {
              const id: number = feature.getId() as number;
              // console.log(id, workspacesIDs)
              feature.setProperties({ status: 'inactive' });
              if (workspacesIDs.includes(id)) feature.setProperties({ status: 'active' });
              const style = Styles.styleType(feature.getProperties() as LiveMapEntity, this.config, feature);
              feature.setStyle(style);
            });
          mapSizeRetry.unsubscribe();
        }
      },
      err => console.error(err)
    );
  }

  setMouseEvent(mouseEventsMode: 'liveMap' | 'mapView' | 'draw' | 'static') {
    this.map.removeInteraction(this.selectEntityHandler);
    this.map.removeInteraction(this.hoverEntityHandler);
    this.map.removeInteraction(this.dragEntityHandler);
    this.map.getView().un('change', this.updateLocationInUrl.bind(this));

    switch (mouseEventsMode) {
      case 'liveMap':
      case 'mapView':
        this.map.addInteraction(this.selectEntityHandler);

        if (!this.config.isTabletMode) {
          // Desktop app
          this.map.addInteraction(this.hoverEntityHandler);
          this.map.addInteraction(this.dragEntityHandler);
          this.map.getView().on('change', this.updateLocationInUrl.bind(this));
        } else {
          // Tablet app - Only for users with laptops (non mobile devices)
          if (!this.isMobileDevice) {
            this.map.addInteraction(this.hoverEntityHandler);
          }
        }
        break;
      case 'draw':
        break;
      case 'static':
        this.map.getInteractions().forEach(interaction => {
          interaction.setProperties({ active: false });
        });
        this.map.getControls().forEach(mapControl => {
          this.map.removeControl(mapControl);
        });
    }
  }

  init(
    config: WcMapConfigModel,
    options?: {
      mouseEventsMode?: 'liveMap' | 'mapView' | 'draw' | 'static';
      centerFromUrl?: string | null;
      showWorkspaces?: boolean;
    }
  ) {
    this.selectEntityHandler = new SelectEntity(this.selectFeature.bind(this), config);
    this.hoverEntityHandler = new HoverEntity(this.over, this.tooltipOverlay, config);
    this.dragEntityHandler = new Drag(this.dragEnd);

    config.currentStyle = this._mapStyleMode || config.currentStyle;
    this._mapStyleMode = config.currentStyle;
    this.wcMapService = new WcMapService(
      this.map,
      config,
      this.httpClient,
      this.alertService,
      this.translateService,
      this.cacheService
    );
    this.config = config;

    if (options?.mouseEventsMode) this.setMouseEvent(options.mouseEventsMode);
    if (options?.mouseEventsMode === 'draw' || options?.mouseEventsMode === 'liveMap')
      this.wcMapDrawService.init(this.map, this.config);
    // const key = 'pk.eyJ1Ijoid2F5Y2FyZSIsImEiOiJjazB3Nm1tNW4xM2FnM25wNnhobWN5bHdwIn0.B1qyfO1CQn84vCsOG46ELQ';
    // olms(this.map, 'https://api.mapbox.com/styles/v1/mapbox/bright-v9?access_token=' + key);
    const mapStyle = config.style[config.currentStyle];

    // olms(this.map, `https://tiles.waycare.io/styles/${mapStyle}/style.json`);
    const backgroundColor = config.currentStyle === 'night' ? config.backgroundDarkColor : config.backgroundColor;
    this.el.nativeElement.style.backgroundColor = backgroundColor;

    setTimeout(() => {
      // Try to wait for spite.io to be called
      if (options?.mouseEventsMode === 'liveMap') {
        this.map.addLayer(this.wcMapService.trafficLayer());
      }

      //
      // this.olLayerElm.nativeElement.style.backgroundColor = backgroundColor;
      // Element.getElementsByClassName('ol-layer').style.backgroundColor = backgroundColor;
      // this.map.getTargetElement().style.backgroundColor = backgroundColor;
      if (this.config.isTabletMode || this.forceTileLayer) {
        this.basicMapLayer = this.tilesLayer();
        this.map.addLayer(this.basicMapLayer);
        // this.map.getTargetElement().style.backgroundColor = backgroundColor;
        this.onlineSub$ = this.windowService.online$.subscribe(isOnline => {
          if (isOnline) this.basicMapLayer.getSource().refresh();
          // this.map.getTargetElement().style.backgroundColor = backgroundColor;
        });
        if (options?.showWorkspaces) this.addWorkspacesBorders();
      } else {
        // const token = 'pk.eyJ1Ijoid2F5Y2FyZSIsImEiOiJjanp1eTd4aXAwMGt0M2NueHh3dXI0dWpiIn0.v27dO5Fkf58A8s2WzJi34A';
        // const tiles = this.tilesLayer();
        // this.map.addLayer(tiles);
        const mapboxTiles = this._mapStyleMode === 'day' ? this.mapboxVectorStyleLight : this.mapboxVectorStyleDark;
        this.basicMapLayer = mapboxglLayer(this.map, config, mapboxTiles);
        this.map.addLayer(this.basicMapLayer);
        // this.map.getTargetElement().style.backgroundColor = backgroundColor;
        if (options?.showWorkspaces) this.addWorkspacesBorders();
      }
    }, 500);

    if (!this.isMobileDevice) {
      this.map.getViewport().addEventListener('contextmenu', this.rightClickHandler.bind(this));
    }
    // if(options.showWorkspaces) this.addWorkspacesBorders();
  }

  onDrag(callback: () => void) {
    this.map.on('pointerdrag', callback);
  }

  onDragEnd(callback: () => void) {
    this.map.on('moveend', callback);
  }

  updateLocationInUrl() {
    const location = this.getMapLocationAsHash();
    if (location) {
      window.location.hash = location;
    }
  }

  getMapLocationAsHash(): string {
    if (this.doUpdateUrlLocation) {
      const coords: Coordinate = this.map.getView().getCenter() as Coordinate;
      const zoom = (this.map.getView().getZoom() as number) || 16;
      return `${toLonLat(coords)[1]},${toLonLat(coords)[0]},${Math.floor(zoom * 100) / 100}z`;
    }
    return '';
  }

  getCenter(): Coordinate {
    return this.map?.getView()?.getCenter() as Coordinate;
  }

  getCenterAsGeolocationPosition(): Position {
    const center = this.getCenter() as Coordinate;
    const coords = toLonLat(center);
    return {
      coords: {
        longitude: coords[0],
        latitude: coords[1],
      },
    };
  }

  getCenterCoords() {
    const center = this.getCenter() as Coordinate;
    const coords = toLonLat(center);
    return coords;
  }

  getCenterAt(pixel: number[]) {
    return this.map?.getCoordinateFromPixel(pixel);
  }

  setCenterFromUrlFragment(fragment: string /*, format: 'googleMap'*/) {
    const fragmentArr = fragment.replace('z', '').split(',');
    const params = fragmentArr.map(param => Number(param));
    this.setCenter({ coordinates: [params[1], params[0]], zoom: params[2] });
  }

  setCenter(mapCenter: { coordinates: number[]; zoom?: number }) {
    if (!this.map) return;
    const view = this.map.getView();
    const coords = fromLonLat(mapCenter.coordinates);
    view.setCenter(coords);
    if (mapCenter.zoom) {
      view.setZoom(mapCenter.zoom);
    }
  }

  setZoom(zoomLevel: number) {
    const view = this.map.getView();
    view.setZoom(zoomLevel);
  }

  setMapLocation(coordinates: Coordinate[][], options?: MapCenterOptions) {
    if (!coordinates) return;
    const { duration, padding, bufferSize } = {
      ...{ duration: 0, padding: [0, 0, 0, 0], bufferSize: 0 },
      ...this.config.centerOptions,
      ...options,
    };
    const combinedCoords: any[] = [];
    coordinates.forEach(area => area.forEach(poly => poly.forEach(coord => combinedCoords.push(coord))));
    if (options && options.zoomLevel) {
      this.map.getView().animate({
        zoom: options.zoomLevel,
        center: combinedCoords[0],
        duration: duration,
      });
    } else {
      let extent = createEmpty();

      combinedCoords.forEach((_coords: Coordinate) => {
        const coords = fromLonLat(_coords);
        if (isEmpty(extent)) {
          createOrUpdateFromCoordinate(coords, extent);
        } else {
          const newExtent = createEmpty();
          createOrUpdateFromCoordinate(coords, newExtent);
          extend(extent, newExtent);
        }
      });
      extent = buffer(extent, bufferSize, extent);
      // }
      const mapSize = this.map.getSize();
      this.doUpdateUrlLocation = false;

      this.map.getView().fit(extent, {
        size: mapSize,
        padding: padding,
        duration: duration,
        easing: easeOut,
      });
      this.doUpdateUrlLocation = true;
    }
  }

  pointerdrag() {
    this.mapIsDraged = true;
  }

  moveend() {
    this.lastZoomLevel = this.map.getView().getZoom() as number;
    if (this.mapIsDraged) {
      const coords = this.map.getView().getCenter() as Coordinate;
      this.mapCenterMoved.emit({
        coordinates: [[coords]],
        options: { zoomLevel: this.lastZoomLevel },
      });
    }
    this.mapIsDraged = false;
  }

  rightClickHandler(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    const coords = this.map.getEventCoordinate(e);
    const pixel = this.map.getPixelFromCoordinate(coords);
    const transCoords = transform(coords, 'EPSG:3857', 'EPSG:4326');
    this.rightClick.emit({ coords: transCoords, pixel: pixel });
  }

  //TODO: change unselect to be generic and not per layer
  unselectAllFeaturesInLayer(featureSubTypeOf: string, id?: number | string) {
    const layer = this.map
      .getLayers()
      .getArray()
      .find(_layer => _layer.getProperties().name === featureSubTypeOf) as VectorLayer;
    layer
      .getSource()
      ?.getFeatures()
      .forEach(feature => {
        if (feature.getProperties().id !== id) {
          const style = Styles.styleType(feature.getProperties() as LiveMapEntity, this.config, feature);
          feature.setProperties({ selected: false });
          feature.setProperties({ hovered: false });
          feature.setStyle(style);
        }
      });
  }

  unselectFeature(featureSubTypeOf: LiveMapEntityType, unselectId?: string) {
    let style;
    let feature: Feature | undefined;
    if (!unselectId) {
      this.selectEntityHandler.unselect();
      for (const entityType in this.lastSelectedFeature) {
        if (Object.prototype.hasOwnProperty.call(this.lastSelectedFeature, entityType)) {
          feature = this.lastSelectedFeature[entityType as LiveMapEntityType];
          feature?.setProperties({ hovered: false });
          feature?.setProperties({ selected: false });
          style = Styles.styleType(feature?.getProperties() as LiveMapEntity, this.config);
          feature?.setStyle(style);
        }
      }
    } else {
      const layer = this.map
        .getLayers()
        .getArray()
        .find(_layer => _layer.getProperties().name === featureSubTypeOf) as VectorLayer;
      if (!layer) return;
      const source =
        layer.getSource() instanceof Cluster
          ? (layer.getSource() as Cluster).getSource()
          : (layer as VectorLayer).getSource();
      feature = source.getFeatures().find(_feature => _feature.getProperties().id === unselectId);
      if (!feature) return;
      feature.setProperties({ selected: false });
      feature.setProperties({ hovered: false });
      style = Styles.styleType(feature.getProperties() as LiveMapEntity, this.config, feature);
      feature?.setStyle(style);
    }
    this.lastSelectedEntity = {};
  }

  selectFeature(entities: EntityType | EntityType[], features?: Feature | Feature[]) {
    if (Array.isArray(entities) && Array.isArray(features)) {
      this.unselectFeature(entities[0].featureSubTypeOf as LiveMapEntityType);
      this.modalOverlay?.setPosition((features[0].getGeometry() as SimpleGeometry).getFlatCoordinates());
      this.selected.emit(entities);
      this.wcMapService.updateFeatureStyle(features[0]);
      this.lastSelectedEntity[entities[0].featureSubTypeOf as LiveMapEntityType] = entities[0];
      this.lastSelectedFeature[entities[0].featureSubTypeOf as LiveMapEntityType] = features[0];
    } else {
      let _feature = features as Feature;
      const _entity = entities as EntityType;
      if (!_feature) {
        const entityType = _entity.featureType;
        const layers = this.map.getLayers().getArray();
        const layer = layers.find(_layer => _layer.getProperties().name === entityType) as VectorLayer;
        if (!layer) return;
        const source =
          layer.getSource() instanceof Cluster
            ? (layer.getSource() as Cluster).getSource()
            : (layer as VectorLayer).getSource();
        _feature = source.getFeatures().find(_feature => _feature.getProperties().id === _entity.id) as Feature;
      }
      if (!_feature) return;
      this.unselectFeature(_entity.featureSubTypeOf as LiveMapEntityType);
      this.lastSelectedEntity[_entity.featureSubTypeOf as LiveMapEntityType] = _entity;
      this.lastSelectedFeature[_entity.featureSubTypeOf as LiveMapEntityType] = _feature;
      _entity['selected'] = true;
      _feature.setProperties({ selected: true });
      const coordinate: SimpleGeometry = _feature.getGeometry() as SimpleGeometry;
      this.modalOverlay?.setPosition(coordinate.getFlatCoordinates());
      this.selected.emit([_entity]);
      this.wcMapService.updateFeatureStyle(_feature);
    }
  }

  editFeature(entity: EntityType, featureType?: string) {
    const entityType = featureType?.toLowerCase() || entity.featureType; //entity.type.toLowerCase().replace(/^wr_/,"");
    const layers = this.map.getLayers().getArray();
    const layer = layers.find(_layer => _layer.getProperties().name === entityType) as VectorLayer;
    if (!layer) return;
    if (this.hoverEntityHandler) this.hoverEntityHandler.active = false;
    if (this.selectEntityHandler) this.selectEntityHandler.active = false;
    try {
      const source = layer.getSource();
      const feature = source.getFeatures().find(_feature => _feature.getProperties().id === entity.id) as Feature;
      entity['selected'] = false;
      feature.setProperties({ selected: false });
      entity['editMode'] = true;
      feature.setProperties({ editMode: true });
      this.wcMapService.updateFeatureStyle(feature);
    } catch (error) {
      console.log(error);
    }
  }

  stopEditFeature(entity: EntityType) {
    if (this.hoverEntityHandler) this.hoverEntityHandler.active = true;
    if (this.selectEntityHandler) this.selectEntityHandler.active = true;
    const layer = this.map
      .getLayers()
      .getArray()
      .find(_layer => _layer.getProperties().name === entity.featureType) as VectorLayer;
    if (!layer) return;
    const source = layer.getSource();
    const feature = source.getFeatures().find(_feature => _feature.getProperties().id === entity.id) as Feature;
    feature?.setProperties({ editMode: false });
    entity['editMode'] = false;
    this.wcMapService.updateFeatureStyle(feature);
  }

  tilesLayer() {
    const mapboxTiles = this._mapStyleMode === 'day' ? this.mapboxRasterStyleLight : this.mapboxRasterStyleDark;
    const layer = new TileLayer({
      source: this.wcMapService.tileSource(this.config, mapboxTiles),
    });
    return layer;
  }

  ngOnDestroy(): void {
    console.log('Map ngOnDestroy');
    if (this.onlineSub$) this.onlineSub$.unsubscribe();
    if (navigator.userAgent.includes('iPad')) {
      console.log('Clear map cache for Safari');
      setTimeout(() => {
        this.map.setSize([0, 0]);
      }, 1000);
    }
  }
}
