import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { AssociatedUnitsComponent } from './associated-units.component';

@NgModule({
  declarations: [AssociatedUnitsComponent],
  imports: [CommonModule, TranslateModule, SwiperModule, WcIconModule],
  exports: [AssociatedUnitsComponent],
})
export class AssociatedUnitsModule {}
