import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleDocPreviewPipeModule } from '@wc/wc-common/src/lib/directives/google-docs.pipe.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { MediaThumbnailModule } from '../media-thumbnail/media-thumbnail.module';
import { WcLoaderComponentModule } from '../miscellaneous/loader/loader.module';
import { CctvSnapshotsFullScreenComponent } from './cctv-snapshots-full-screen.component';

@NgModule({
  declarations: [CctvSnapshotsFullScreenComponent],
  imports: [
    CommonModule,
    WcLoaderComponentModule,
    MediaThumbnailModule,
    GoogleDocPreviewPipeModule,
    MatTabsModule,
    SwiperModule,
    TranslateModule,
  ],
  exports: [CctvSnapshotsFullScreenComponent],
})
export class CctvSnapshotsFullScreenModule {}
