import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { chipStyles } from '@wc/wc-models/src/lib/enums/general';

@Component({
  selector: 'wc-chip',
  templateUrl: './wc-chip.component.html',
  styleUrls: ['./wc-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WcChipComponent {
  chipStyles = chipStyles;
  _text!: string;
  _style: chipStyles | '' = '';
  _tabletModeClass: 'tablet-mode' | '' = '';
  _clickableCss: 'clickable' | '' = '';
  _isMeClass: 'is_me' | '' = '';
  _isSelectedClass: 'selected' | '' = '';
  _disableTextSelection: 'disable-text-selection' | '' = '';

  @Input() set isTablet(v: boolean) {
    this._tabletModeClass = v ? 'tablet-mode' : '';
  }

  @Input() set isSelected(v: boolean) {
    this._isSelectedClass = v ? 'selected' : '';
  }
  @Input() set isMeStyle(v: boolean) {
    this._isMeClass = v ? 'is_me' : '';
  }
  @Input() set isClickable(v: boolean) {
    this._clickableCss = v ? 'clickable' : '';
  }
  @Input() set text(t: string) {
    this._text = t;
    if (!t) {
      console.warn('You have a chip without text...');
    }
  }
  @Input() set style(s: string) {
    if (!s || typeof s !== 'string' || chipStyles[s.toLowerCase()] === undefined) {
      console.error(`Style provided (${s}) is not supported`);
    } else this._style = s.toLowerCase() as chipStyles;
  }

  @Input() set disableTextSelection(v: boolean) {
    this._disableTextSelection = v ? 'disable-text-selection' : '';
  }
}
