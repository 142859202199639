import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { WcDateTimeModule } from '@wc/features/ui/form-fields-components/date-time/date-time.module';
import { WcTimeFieldModule } from '@wc/features/ui/form-fields-controls/time-field/time-field.module';
import { WcAutocompleteV2Module } from '../../form-components/autocomplete-v2/autocomplete-v2.module';
import { WcMultipleChipsModule } from '../../form-components/wc-multiple-chips/wc-multiple-chips.module';
import { BaseControlModule } from '../base-control/base-control.module';
import { MitigationComponent } from './mitigation.component';

@NgModule({
  declarations: [MitigationComponent],
  imports: [
    CommonModule,
    BaseControlModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    WcAutocompleteV2Module,
    WcMultipleChipsModule,
    WcTimeFieldModule, // TODO,
    WcDateTimeModule, // TODO,
  ],
  providers: [FormControl],
  exports: [MitigationComponent],
})
export class MitigationUnitsComponentModule {}
