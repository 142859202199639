import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RoadEventMitigatedByMyAccountPipe } from './road-event-mitigated-by-my-account.pipe';
import { RoadEventSortPipe } from './road-event-sort.pipe';
import { roadEventsAssignToFilter } from './road-events-assign-to-filter.pipe';

@NgModule({
  declarations: [
    RoadEventMitigatedByMyAccountPipe,
    RoadEventSortPipe,
    roadEventsAssignToFilter,
    RoadEventMitigatedByMyAccountPipe,
  ],
  imports: [CommonModule, TranslateModule],
  providers: [],
  exports: [
    RoadEventMitigatedByMyAccountPipe,
    RoadEventSortPipe,
    roadEventsAssignToFilter,
    RoadEventMitigatedByMyAccountPipe,
  ],
})
export class RoadEventPipesModule {}
