import { WcMapConfig } from '@wc/core/livemap.config';
import { WeatherLayersTabletConfig } from '@wc/core/livemap.weather.tablet.config';
import { WcMapConfigModel } from '@wc/core/models';

export function WcMapTabletConfig(): WcMapConfigModel {
  const config = WcMapConfig;
  config.layers.fsp_unit = config.layers.fsp_unit || {};
  config.layers.fsp_unit.pointStatusStyle = config.layers.fsp_unit.pointStatusStyle || {};
  config.layers.fsp_unit.pointStatusStyle.onbreak = {
    default: [
      { shape: 'marker', icon: { iconName: 'fsp_unit', anchor: [0.5, 0.5] } },
      {
        shape: 'marker',
        icon: { iconName: 'unit break', anchor: [-0.3, 1.3], scale: 1, disableRotation: true },
      },
    ],
    hover: [
      {
        shape: 'marker',
        icon: { iconName: 'fsp_unit.hover', anchor: [0.5, 0.5] },
      },
      {
        shape: 'marker',
        icon: { iconName: 'unit break', anchor: [-0.3, 1.3], scale: 1, disableRotation: true },
      },
    ],
    selected: [
      {
        shape: 'marker',
        icon: { iconName: 'fsp_unit.selected', anchor: [0.5, 0.5] },
      },
      {
        shape: 'marker',
        icon: { iconName: 'unit break', anchor: [-0.3, 1.3], scale: 1, disableRotation: true },
      },
    ],
  };
  config.appendedIconStyle = {
    shape: 'marker',
    icon: { iconName: 'main_event_mark_tablet', anchor: [-0.2, 3], scale: 1 },
  };
  config.isTabletMode = true;

  config.layers.workspace = {
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#D2A890', darkColor: '#A09791', width: 5 },
          {
            shape: 'lineSolid',
            color: '#F3DBCE',
            darkColor: '#675A52',
            width: 3,
            label: {
              textSrcField: 'title',
              placement: 'line',
              color: '#9A684C',
              outlineColor: '#FDF1EA',
              outlineWidth: 2,
              size: 17,
              maxAngle: 0.7853981633974483,
              textAlign: 'center',
            },
          },
        ],
      },
      inactive: { default: [] },
    },
  };

  config.layers.crash_risk_area_active = config.layers.crash_risk_area_active || {};
  config.layers.crash_risk_area_active.startPointStatusStyle = {
    default: {
      default: [
        {
          shape: 'marker',
          icon: { iconName: 'crash_risk_start', anchor: [0.5, 0.9] },
          zIndex: 15,
        },
        {
          shape: 'marker',
          icon: { iconName: 'status_active', anchor: [-0.2, 3.3], scale: 1 },
          zIndex: 15,
        },
      ],
      hover: [
        {
          shape: 'marker',
          icon: { iconName: 'crash_risk_start.hover', anchor: [0.5, 0.9] },
          zIndex: 15,
        },
        {
          shape: 'marker',
          icon: { iconName: 'status_active', anchor: [-0.2, 3.3], scale: 1 },
          zIndex: 15,
        },
      ],
      selected: [
        {
          shape: 'marker',
          icon: { iconName: 'crash_risk_start.selected', anchor: [0.5, 0.9] },
          zIndex: 15,
        },
        {
          shape: 'marker',
          icon: { iconName: 'status_active', anchor: [-0.2, 3.3], scale: 1 },
          zIndex: 15,
        },
      ],
    },
  };

  config.layers = { ...config.layers, ...WeatherLayersTabletConfig };
  config.layers.camera = {
    zIndex: 8,
    clusterDistance: 80,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'lineSolid',
            color: '#ffff',
            width: 1,
            label: {
              offsetX: 17,
              offsetY: -60,
              outlineColor: '#707EA6',
              darkOutlineColor: '#707EA6',
              textSrcField: 'length',
              placement: 'point',
              color: '#ffff',
              darkColor: '#ffff',
              size: 16,
              textAlign: 'center',
            },
          },
          { shape: 'marker', icon: { iconName: 'cameras_active' } },
          {
            shape: 'marker',
            icon: { iconName: 'counter', anchor: [0, 2.3] },
          },
        ],
        hover: [{ shape: 'marker', icon: { iconName: 'cameras_active.hover' } }],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'select_highlight_circle', zIndex: -1, anchor: [0.12, 1.9] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'cameras_active.selected', zIndex: 1 },
          },

          {
            shape: 'marker',
            icon: { iconName: 'counter', zIndex: 2, anchor: [0, 2.3] },
          },

          {
            shape: 'lineSolid',
            color: '#ffff',
            width: 1,
            label: {
              offsetX: 17,
              offsetY: -60,
              outlineColor: '#707EA6',
              darkOutlineColor: '#707EA6',
              textSrcField: 'length',
              placement: 'point',
              color: '#ffff',
              darkColor: '#ffff',
              size: 16,
              textAlign: 'center',
            },
          },
        ],
      },
      inactive: {
        default: [{ shape: 'marker', icon: { iconName: 'cameras_inactive' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'cameras_inactive.hover' } }],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'cameras_inactive.selected' },
          },
        ],
      },
    },
  };
  config.layers.dms = {
    clusterDistance: 40,
    pointStatusStyle: {
      default: {
        default: [
          { shape: 'marker', icon: { iconName: 'dms_inactive' } },
          {
            shape: 'marker',
            icon: { iconName: 'counter', zIndex: 2, anchor: [0, 2.3] },
          },
          {
            shape: 'lineSolid',
            color: '#ffff',
            width: 1,
            label: {
              offsetX: 17,
              offsetY: -60,
              outlineColor: '#707EA6',
              darkOutlineColor: '#707EA6',
              textSrcField: 'length',
              placement: 'point',
              color: '#ffff',
              darkColor: '#ffff',
              size: 16,
              textAlign: 'center',
            },
          },
        ],
        hover: [{ shape: 'marker', icon: { iconName: 'dms_inactive.hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'dms_inactive.selected' } }],
      },
      active: {
        default: [
          { shape: 'marker', icon: { iconName: 'dms_active' } },
          {
            shape: 'marker',
            icon: { iconName: 'counter', zIndex: 2, anchor: [0, 2.3] },
          },
          {
            shape: 'lineSolid',
            color: '#ffff',
            width: 1,
            label: {
              offsetX: 17,
              offsetY: -60,
              outlineColor: '#707EA6',
              darkOutlineColor: '#707EA6',
              textSrcField: 'length',
              placement: 'point',
              color: '#ffff',
              darkColor: '#ffff',
              size: 16,
              textAlign: 'center',
            },
          },
        ],
        hover: [{ shape: 'marker', icon: { iconName: 'dms_active.hover' } }],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'select_highlight_circle', zIndex: -1, anchor: [0.12, 1.9] },
          },
          { shape: 'marker', icon: { iconName: 'dms_active.selected' } },
          {
            shape: 'marker',
            icon: { iconName: 'counter', zIndex: 2, anchor: [0, 2.3] },
          },
          {
            shape: 'lineSolid',
            color: '#ffff',
            width: 1,
            label: {
              offsetX: 17,
              offsetY: -60,
              outlineColor: '#707EA6',
              darkOutlineColor: '#707EA6',
              textSrcField: 'length',
              placement: 'point',
              color: '#ffff',
              darkColor: '#ffff',
              size: 16,
              textAlign: 'center',
            },
          },
        ],
      },
    },
  };

  return config;
}
