import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { IncidentsService } from '@wc-core';
import { TrafficDisruptionStore } from '@wc/core';
import { Construction, Incident, LaneType, RoadClosure, TrafficDisruption } from '@wc/core/models';
import { PermissionsFacadeService } from '@wc/permissions/domain/src';
import { cloneDeep } from 'lodash';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { RoadEventLane } from '../affected-lanes-utils-service';
@Component({
  selector: 'wc-affected-lane-view',
  templateUrl: './affected-lanes-view.component.html',
  styleUrls: ['./affected-lanes-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AffectedLaneViewComponent implements AfterViewInit {
  @ViewChild(SwiperDirective, { static: true }) swiperElm?: SwiperDirective;

  _entity!: Incident | TrafficDisruption;
  _affectedLanes: RoadEventLane[] = [];
  _allLanes: RoadEventLane[] = [];
  lanesFormGroup = this.fb.array([]);

  hoverLaneIndex = null;
  TextualLanes = [LaneType.Gore, LaneType.LeftShoulder, LaneType.RightShoulder];

  config!: SwiperConfigInterface;

  @Input() set entity(entity: Incident | RoadClosure | Construction) {
    this._entity = entity;
    this._allLanes = cloneDeep(entity.affectedLanes) || [];

    this._affectedLanes = [];
    this._allLanes.forEach((lane: RoadEventLane) => {
      if (lane.isAffected) {
        this._affectedLanes.push(lane);
      }
    });
    this.initLanesSwiperConfig();
    this.swiperElm?.update();
    this.cdr.markForCheck();
  }
  @Input() allLanesAffected = false;
  @Input() isMultiDirection = false;
  @Input() isIncident = true;
  @Input() blockUserAction = false;

  get affectedLanes() {
    return this._affectedLanes;
  }

  get allLanes() {
    return this._allLanes;
  }

  get viewOnly() {
    return this.blockUserAction || (this.isIncident && !this.permissionsFacadeService.hasPermission('INCIDENT:UPDATE'));
  }

  constructor(
    private fb: FormBuilder,
    private incidentService: IncidentsService,
    private trafficDisruptionStore: TrafficDisruptionStore,
    public permissionsFacadeService: PermissionsFacadeService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.initLanesSwiperConfig();
    this.swiperElm?.update();
  }

  initLanesSwiperConfig() {
    this.config = {
      direction: 'horizontal',
      slidesPerView: 3,
      spaceBetween: 10,
      navigation: {
        nextEl: '.lanes-swiper-button-next',
        prevEl: '.lanes-swiper-button-prev',
      },
      pagination: {
        el: '.lanes-swiper-pagination',
        clickable: true,
      },
      watchOverflow: true,
    };
  }

  laneHovered($event) {
    if ($event && $event.lane) {
      this.hoverLaneIndex = $event.lane.positionIndex;
    } else if ($event) {
      this.hoverLaneIndex = $event.positionIndex;
    } else {
      this.hoverLaneIndex = null;
    }
  }

  updateLaneStatus(e: MatSlideToggleChange, lane: RoadEventLane) {
    const updatedLane = cloneDeep(lane);
    updatedLane.isClosed = e.checked;

    if (this.isIncident) {
      const newLanes = this._allLanes.map(lane => {
        if (lane.id === updatedLane.id) {
          lane.isClosed = e.checked;
        }
        return lane;
      });
      this._allLanes = cloneDeep(newLanes);
      this.incidentService
        .updateIncidentLanes({ ...this._entity, ...{ affectedLanes: newLanes } } as Incident, this._entity as Incident)
        .subscribe();
    } else {
      // TODO: change update for TD
    }
    this.cdr.markForCheck();
  }
}
