import { Pipe, PipeTransform } from '@angular/core';
import { TrafficDisruption } from '@wc/core/models';

@Pipe({
  name: 'trafficDisruptionsStatusFilter',
})
export class TrafficDisruptionsStatusFilterPipe implements PipeTransform {
  transform(trafficDisruptions: TrafficDisruption[], ...args: unknown[]): unknown {
    let filteredTrafficDisruptions: TrafficDisruption[] = [];
    filteredTrafficDisruptions = trafficDisruptions.filter(trafficDisruption => {
      if (args.includes(null)) return true;
      return args.includes(trafficDisruption.status);
    });

    return filteredTrafficDisruptions;
  }
}
