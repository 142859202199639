/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { EntityActions, EntityUIQuery, QueryEntity } from '@datorama/akita';
import { CongestionsStoreEntity, ModifiedEntities, RemovedEntities } from '@wc/wc-models/src';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LayerType } from '../../../../../../core/models/gql.models';
import { CongestionsState, CongestionsUIState, IrregularCongestionsStore } from './irregular_congestions.store';

@Injectable({ providedIn: 'root' })
export class IrregularCongestionsQuery extends QueryEntity<CongestionsState> {
  private layerTypeName = LayerType.IrregularCongestion;
  ui!: EntityUIQuery<CongestionsUIState>;
  constructor(store: IrregularCongestionsStore) {
    super(store);
    this.createUIQuery();
  }

  get modifiedIrregularCongestions$(): Observable<ModifiedEntities> {
    return this.selectEntityAction([EntityActions.Update, EntityActions.Add]).pipe(
      map(({ ids }) => ids.map(id => this.getEntity(id)).filter(e => !!e) as CongestionsStoreEntity[]),
      map(IrregularCongestions => ({
        [this.layerTypeName]: IrregularCongestions,
      }))
    );
  }

  get removedIrregularCongestions$(): Observable<RemovedEntities> {
    return this.selectEntityAction([EntityActions.Remove]).pipe(map(({ ids }) => ({ [this.layerTypeName]: ids })));
  }
}
