import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WcPopupCloserModule } from '@wc/wc-common/src/lib/directives';
import { WcButtonModule } from '../wc-button/wc-button.module';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { SupportComponent } from './support.component';

@NgModule({
  declarations: [SupportComponent],
  imports: [CommonModule, WcButtonModule, TranslateModule, WcIconModule, WcPopupCloserModule],
  exports: [SupportComponent],
})
export class SupportModule {}
