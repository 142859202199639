/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { EntityActions, EntityUIQuery, QueryEntity } from '@datorama/akita';
import { CamerasStoreEntity, ModifiedEntities, RemovedEntities } from '@wc/wc-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LayerType } from '../../../../../../core/models/gql.models';
import { GeoService } from '../../../../../../core/services/geo.service';
import { CamerasState, CamerasStore, CamerasUIState } from './cameras.store';

@Injectable({ providedIn: 'root' })
export class CamerasQuery extends QueryEntity<CamerasState> {
  private layerTypeName: LayerType.Camera = LayerType.Camera;
  ui!: EntityUIQuery<CamerasUIState>;
  constructor(protected store: CamerasStore, private geoService: GeoService) {
    super(store);
    this.createUIQuery();
  }

  get allCameras() {
    return this.selectAll();
  }

  get modifiedCameras$(): Observable<ModifiedEntities> {
    return this.selectEntityAction([EntityActions.Update, EntityActions.Add]).pipe(
      map(({ ids }) => ids.map(id => this.getEntity(id)).filter(e => !!e) as CamerasStoreEntity[]),
      map(cameras => ({
        [this.layerTypeName]: cameras,
      }))
    );
  }

  get removedCameras$(): Observable<RemovedEntities> {
    return this.selectEntityAction([EntityActions.Remove]).pipe(map(({ ids }) => ({ [this.layerTypeName]: ids })));
  }

  getCamerasByPoint(centerPoint: number[], radius: number): CamerasStoreEntity[] {
    return !centerPoint?.length || typeof radius !== 'number'
      ? []
      : this.getAll()
          .filter(({ location: { coordinates } }) =>
            this.geoService.isPointsWithinRadius(coordinates, centerPoint, radius)
          )
          .sort(
            (a: CamerasStoreEntity, b: CamerasStoreEntity) =>
              this.geoService.getPointsDistance(centerPoint, a.location.coordinates) -
              this.geoService.getPointsDistance(centerPoint, b.location.coordinates)
          )
          .slice(0, 7);
  }
}
