import { Injectable } from '@angular/core';
import { UpdateWorkingHoursInput } from '@wc/core';
import { WorkScheduleConfiguration } from '../../../types';

@Injectable({
  providedIn: 'root',
})
export class WorkScheduleConfigurationService {
  createUpdateWorkingHoursInput(formValue: WorkScheduleConfiguration): UpdateWorkingHoursInput {
    const { id, isHideEnabled, workingHours } = formValue;
    if (!id) throw new Error('Id is required to update working hours configuration in the backend service');

    return {
      id,
      // TODO: `true` should be reverted to isHideEnabled,
      // this is used by backend in development of hide feature
      isHideEnabled: true,
      isCustom: workingHours.isCustom,
      workingHours: workingHours.ranges,
    };
  }
}
