import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
} from '@angular/core';
import { createPopper, Instance } from '@popperjs/core';
import { Placement } from '@popperjs/core/lib/enums';
import { IconClassName } from '../../../../wc-models/src/lib/types/icon-class-names-type';
import { TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[wcTooltipText]',
})
export class TooltipDirective implements OnDestroy {
  @Input() wcTooltipDisabled = false;
  @Input() wcTooltipMaxWidthPx = 200;
  @Input() wcTooltipTitle: string | undefined = '';
  @Input() wcTooltipPlacement: Placement = 'auto';
  @Input() wcTooltipOffsetDistance = 10;
  @Input() wcTooltipOffsetSkidding = 0;
  @Input() wcTooltipIconNames: IconClassName[] = [];
  private _tooltipComponentRef: ComponentRef<TooltipComponent> | null = null;
  private _wcTooltipText: string | undefined = undefined;
  private _tooltipElement?: HTMLElement;
  private _popperInstance?: Instance;

  constructor(
    private elementRef: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  @Input() set wcTooltipText(text: string | undefined) {
    setTimeout(() => {
      if (this._wcTooltipText !== text && this._tooltipComponentRef) {
        this._wcTooltipText = text;
        this.destroy();
        this.onMouseEnter();
        this._tooltipComponentRef.changeDetectorRef.detectChanges();
      } else {
        this._wcTooltipText = text;
      }
    });
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.wcTooltipDisabled) return;
    if (this._tooltipComponentRef === null) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TooltipComponent);
      this._tooltipComponentRef = componentFactory.create(this.injector);
      this.appRef.attachView(this._tooltipComponentRef.hostView);
      this._tooltipElement = this._tooltipComponentRef.location.nativeElement as HTMLElement;

      document.body.appendChild(this._tooltipElement);
      this.showPopperTooltip();
    }
  }

  private showPopperTooltip() {
    if (this._tooltipComponentRef !== null && this._tooltipElement) {
      this._tooltipComponentRef.instance.tooltipText = this._wcTooltipText;
      this._tooltipComponentRef.instance.tooltipTitle = this.wcTooltipTitle;
      this._tooltipComponentRef.instance.tooltipsIcons = this.wcTooltipIconNames;
      this._tooltipComponentRef.instance.maxWidth = this.wcTooltipMaxWidthPx;
      this._tooltipComponentRef.changeDetectorRef.detectChanges();

      this._popperInstance = createPopper(this.elementRef.nativeElement, this._tooltipElement, {
        placement: this.wcTooltipPlacement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [this.wcTooltipOffsetSkidding, this.wcTooltipOffsetDistance],
            },
          },
        ],
      });
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.destroy();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    if (this._tooltipComponentRef !== null) {
      this._tooltipComponentRef.destroy();
      this._tooltipElement = undefined;
      this._tooltipComponentRef = null;
    }
    if (this._popperInstance) {
      this._popperInstance.destroy();
      this._popperInstance = undefined;
    }
  }
}
