import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { WcLoaderComponentModule } from '../../miscellaneous/loader/loader.module';
import { CameraViewerModule } from '../camera-viewer/camera-viewer.module';
import { UnitDashcamsComponent } from './unit-dashcams.component';

@NgModule({
  declarations: [UnitDashcamsComponent],
  imports: [CommonModule, CameraViewerModule, SwiperModule, WcLoaderComponentModule, TranslateModule],
  exports: [UnitDashcamsComponent],
})
export class UnitDashcamsModule {}
