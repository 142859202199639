import { LayerType } from '@wc/core';
import { MapLayer } from '@wc/wc-map-viewer/src';
import { EntityState, LayerNameStyleConfigKeys } from '@wc/wc-models/src';
import { LayersZIndexConfig } from './layers-z-index.config';

const _WeatherLayersConfig: MapLayer<LayerNameStyleConfigKeys, EntityState> = {
  air: {
    zIndex: LayersZIndexConfig.air,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'dust_storm' },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'dust_storm.hover' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'dust_storm.selected' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
        hover: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
        selected: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'dust_storm',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'dust_storm.hover',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'dust_storm.selected',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: 3 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'dust_storm_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'dust_storm_polygon.hover',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'dust_storm_polygon.selected',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  floods: {
    zIndex: LayersZIndexConfig.floods,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'flood' },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'flood.hover' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'flood.selected' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
        hover: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
        selected: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'flood',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'flood.hover',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'flood.selected',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: 3 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'flood_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'flood_polygon.hover',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'flood_polygon.selected',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  fog: {
    zIndex: LayersZIndexConfig.fog,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'dense_fog' },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'dense_fog.hover' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'dense_fog.selected' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
        hover: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
        selected: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'dense_fog',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'dense_fog.hover',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'dense_fog.selected',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: 3 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'dense_fog_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'dense_fog_polygon.hover',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'dense_fog_polygon.selected',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  marine: {
    zIndex: LayersZIndexConfig.marine,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'tsunami' },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'tsunami.hover' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'tsunami.selected' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
        hover: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
        selected: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tsunami',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tsunami.hover',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tsunami.selected',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: 3 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tsunami_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tsunami_polygon.hover',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tsunami_polygon.selected',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  specific_road_conditions: {
    zIndex: LayersZIndexConfig.specific_road_conditions,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'snow_on_road' },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'snow_on_road.hover' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'snow_on_road.selected' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
        hover: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
        selected: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'snow_on_road',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'snow_on_road.hover',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'snow_on_road.selected',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: 3 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'snow_on_road_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'snow_on_road_polygon.hover',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'snow_on_road_polygon.selected',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  temperature: {
    zIndex: LayersZIndexConfig.temperature,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'excessive_heat' },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'excessive_heat.hover' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'excessive_heat.selected' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
        hover: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
        selected: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'excessive_heat',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'excessive_heat.hover',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'excessive_heat.selected',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: 3 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'excessive_heat_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'excessive_heat_polygon.hover',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'excessive_heat_polygon.selected',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  thunderstorms: {
    zIndex: LayersZIndexConfig.thunderstorms,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'near_severe_thunderstorm' },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'near_severe_thunderstorm.hover' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'near_severe_thunderstorm.selected' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
        hover: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
        selected: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'near_severe_thunderstorm',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'near_severe_thunderstorm.hover',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'near_severe_thunderstorm.selected',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: 3 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'near_severe_thunderstorm_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'near_severe_thunderstorm_polygon.hover',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'near_severe_thunderstorm_polygon.selected',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  tornado: {
    zIndex: LayersZIndexConfig.tornado,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'tornado' },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'tornado.hover' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'tornado.selected' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
        hover: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
        selected: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tornado',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tornado.hover',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tornado.selected',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: 3 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tornado_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tornado_polygon.hover',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tornado_polygon.selected',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  tropical: {
    zIndex: LayersZIndexConfig.tropical,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'tropical_storm' },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'tropical_storm.hover' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'tropical_storm.selected' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
        hover: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
        selected: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tropical_storm',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tropical_storm.hover',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tropical_storm.selected',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: 3 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tropical_storm_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tropical_storm_polygon.hover',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'tropical_storm_polygon.selected',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  wind: {
    zIndex: LayersZIndexConfig.wind,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'wind' },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'wind.hover' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'wind.selected' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
        hover: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
        selected: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'wind',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'wind.hover',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'wind.selected',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: 3 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'wind_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'wind_polygon.hover',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'wind_polygon.selected',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  winter: {
    zIndex: LayersZIndexConfig.winter,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'snow' },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'snow.hover' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'snow.selected' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
        hover: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
        selected: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'snow',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'snow.hover',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'snow.selected',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: 3 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'snow_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'snow_polygon.hover',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'snow_polygon.selected',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  fire: {
    zIndex: LayersZIndexConfig.fire,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'fire' },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'fire.hover' },
          },
          {
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'fire.selected' },
          },
          {
            // isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.1, 3.3], scale: 0.85 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
        hover: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
        selected: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.6 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'fire',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'fire.hover',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'fire.selected',
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: 3 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#7dddff', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.2, zIndex: 3 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'fire_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'fire_polygon.hover',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'fire_polygon.selected',
              scale: 0.8,
              anchor: [0.5, 0.8],
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: { sourceField: 'status' },
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },
};

export const WeatherLayersConfig = Object.keys(_WeatherLayersConfig).reduce((acc, key) => {
  acc[`${LayerType.WeatherAlert}-${key}`] = _WeatherLayersConfig[key];
  return acc;
}, {});
