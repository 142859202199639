import { Injectable } from '@angular/core';
import { RoadEventsHighlighEntityType } from '@wc/wc-models/src/lib/enums/general';
import { union } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IncidentsQuery } from '../stores/entities/incidents/incidents.query';
import { LocalStorageKeys, LocalStorageService } from './local-storage.service';

export type InteractedRoadEventIds = {
  [RoadEventsHighlighEntityType.Incident]: number[];
  [RoadEventsHighlighEntityType.TrafficDisruptions]: number[];
};

@Injectable({
  providedIn: 'root',
})
export class InteractedEventsService {
  private _interactedRoadEventIds$ = new BehaviorSubject<InteractedRoadEventIds>({
    incident: [],
    trafficDisruption: [],
  });

  get interactedRoadEventIds() {
    return this._interactedRoadEventIds$.getValue();
  }

  constructor(private localStorageService: LocalStorageService, private incidentsQuery: IncidentsQuery) {
    const currentValue = this.localStorageService.get(LocalStorageKeys.InteractedRoadEventIds);
    if (currentValue) {
      this._interactedRoadEventIds$.next(currentValue);
    } else {
      this.localStorageService.set(LocalStorageKeys.InteractedRoadEventIds, {
        incident: [],
        trafficDisruption: [],
      });
    }

    this.incidentsQuery.allIncidents
      .pipe(
        take(1),
        map(allIncidents => allIncidents.map(incident => incident.id))
      )
      .subscribe(allIds => {
        const value = this._interactedRoadEventIds$.getValue();
        value[RoadEventsHighlighEntityType.Incident] = value[RoadEventsHighlighEntityType.Incident].filter(id =>
          allIds.includes(id)
        );
        this._interactedRoadEventIds$.next(value);
        this.localStorageService.set(LocalStorageKeys.InteractedRoadEventIds, this._interactedRoadEventIds$.getValue());
      });
  }

  updateInteractedRoadEvent(type: RoadEventsHighlighEntityType, id: number) {
    const value = this._interactedRoadEventIds$.getValue();
    value[type] = union(value[type], [id]);
    this._interactedRoadEventIds$.next(value);
    this.localStorageService.set(LocalStorageKeys.InteractedRoadEventIds, this._interactedRoadEventIds$.getValue());
  }

  removeInteractedRoadEvent(type: RoadEventsHighlighEntityType, id: number) {
    const value = this._interactedRoadEventIds$.getValue();
    const filtered = value[type].filter(_id => _id !== id);
    value[type] = filtered;
    this._interactedRoadEventIds$.next(value);
    this.localStorageService.set(LocalStorageKeys.InteractedRoadEventIds, this._interactedRoadEventIds$.getValue());
  }
}
