import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { MultiRadioButtonComponent } from './multi-radio-button.component';
import { MatRadioModule } from '@angular/material/radio';

const Materials: any[] = [MatRadioModule];

@NgModule({
  declarations: [MultiRadioButtonComponent],
  imports: [CommonModule, WcBaseControlFieldModule, Materials],
  providers: [],
  exports: [MultiRadioButtonComponent],
})
export class MultiRadioButtonComponentModule {}
