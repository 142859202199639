import { LiveMapEntity, UnitActivityStatus } from '@wc/core/models';
import { winterUnitTypes } from '@wc/wc-map/src/lib/config';
import { MapUnitTooltipResponses, NewLiveMapEntity } from '@wc/wc-models/src';

export type ActivityStatusName =
  | MapUnitTooltipResponses.on_active
  | MapUnitTooltipResponses.on_special_status
  | MapUnitTooltipResponses.on_responding
  | null;

export type AcitivityStatusDisplayEntity = {
  activityStatusesForDisplay: string[];
  activityStatusName: ActivityStatusName;
};

export const winterActivities = [
  UnitActivityStatus.BlowingSnow,
  UnitActivityStatus.SpreadingSalt,
  UnitActivityStatus.SpreadingSand,
  UnitActivityStatus.StreetSweeping,
];

export function getActivityStatusesForDisplay(entity: NewLiveMapEntity | LiveMapEntity): AcitivityStatusDisplayEntity {
  const { type, activityStatuses } = entity;
  if (!entity.activityStatuses?.length) {
    return {
      activityStatusName: null,
      activityStatusesForDisplay: [],
    };
  }

  if (activityStatuses?.some(activity => activity === UnitActivityStatus.Responding)) {
    return {
      activityStatusName: MapUnitTooltipResponses.on_responding,
      activityStatusesForDisplay: [],
    };
  }

  return {
    activityStatusName:
      winterUnitTypes.includes(type || '') && activityStatuses?.some(activity => winterActivities.includes(activity))
        ? MapUnitTooltipResponses.on_active
        : MapUnitTooltipResponses.on_special_status,
    activityStatusesForDisplay: [
      ...new Set(activityStatuses?.map(s => `activityStatuses.${s}`).sort((a, b) => b.length - a.length)),
    ],
  };
}
