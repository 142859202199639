<form class="shift-form end-shift-form" [formGroup]="endShiftForm" [class.language-rtl]="uiStore.isDirectionRtl">
  <div class="ending-your-shift">
    {{ 'shiftsAndRoutes.endingYourShift' | translate }}
  </div>

  <div class="shift-modal-row">
    <wc-number-field-control
      *ngIf="isUserHasUnit"
      [formControl]="$any(endShiftForm.get('endMileage'))"
      [fieldData]="shiftFieldsData.endMileage">
    </wc-number-field-control>
    <wc-select-control
      *ngIf="shiftFieldsData.endReason.options && shiftFieldsData.endReason.options.length"
      formControlName="endReason"
      [isTabletMode]="uiStore.isTabletMode"
      [formFieldData]="shiftFieldsData.endReason">
    </wc-select-control>
  </div>

  <div class="overtime-note-container" *ngIf="endShiftForm.controls.endReason.value === 'OTHER'">
    <wc-text-area-control
      [formControl]="$any(endShiftForm.get('endReasonNote'))"
      [fieldData]="shiftFieldsData.endReasonNote">
    </wc-text-area-control>
  </div>

  <mat-checkbox
    matInput
    data-focus
    class="mat-checkbox"
    [class.hidden-overtime-note]="!overtimeCheckbox.checked"
    #overtimeCheckbox
    (change)="overtimeCheckboxChanged($event)">
    <span class="checkbox-label">{{ 'shiftsAndRoutes.overtime' | translate }}</span>
  </mat-checkbox>

  <div class="overtime-note-container" *ngIf="overtimeCheckbox.checked">
    <wc-text-area-control
      [formControl]="$any(endShiftForm.get('overtimeNote'))"
      [fieldData]="shiftFieldsData.overtimeNote">
    </wc-text-area-control>
  </div>

  <div class="buttons-container">
    <button class="secondary-button" (click)="closeShiftForm()">
      {{ 'cancel' | translate }}
    </button>

    <button class="primary-button" (click)="submitEndShiftForm()">
      {{ 'shiftsAndRoutes.endShift' | translate }}
    </button>
  </div>
</form>
