import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HeapAnalyticsService } from '@wc-core';
import { RoadType } from '@wc/core';
import { SegmentAutocompleteSelectOption, SelectOptionGroup } from '@wc/features/ui/form-controls/form-models';
import { isHighway } from '@wc/utils';
import { AutocompleteV2Component } from '@wc/wc-ui/src/form-components/autocomplete-v2/autocomplete-v2.component';
import { map } from 'rxjs/operators';

enum GroupNames {
  Highways = 'HIGHWAYS',
  Streets = 'STREETS',
}

@Component({
  selector: 'wc-segment-autocomplete-v2',
  templateUrl: './segment-autocomplete-v2.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SegmentAutocompleteV2Component),
      multi: true,
    },
  ],
  styleUrls: ['./segment-autocomplete-v2.component.scss'],
})
export class SegmentAutocompleteV2Component extends AutocompleteV2Component implements AfterViewInit, OnDestroy {
  @Input() textForNearByOptionsBtn = '';
  MAX_OPTIONS = 20;
  optionsGroup: Array<SelectOptionGroup> = [];
  showFilteredOptions = false;
  offlineMode = false;

  constructor(
    protected elm: ElementRef,
    protected cdr: ChangeDetectorRef,
    protected heapService: HeapAnalyticsService
  ) {
    super(elm, cdr, heapService);
  }

  ngAfterViewInit() {
    this.filteredOptions = this.autocompleteInput.valueChanges.pipe(
      map(displayName => {
        let _options: SegmentAutocompleteSelectOption[] = [];
        if (displayName) {
          this.showFilteredOptions = true;
          _options = this._filter(displayName);
        } else {
          this.showFilteredOptions = false;
        }

        if (!_options.length) {
          _options.push({
            displayName: '',
            value: '',
            data: { distance: 0, roadType: RoadType.Street },
          });
        }

        displayName = displayName ? displayName : '';

        this.fieldFormControl.setValue(displayName);
        this.onChanged(displayName);
        return _options;
      })
    );

    if (!navigator.onLine && !this.fieldData.options?.length) {
      this.offlineMode = true;
      this.fieldData.options = [{ displayName: '', value: '', data: {} }];
      this.autocompleteInput.setValue(null);
    }
  }

  updateOptions(options: SegmentAutocompleteSelectOption[]) {
    let uniqueOptions = [...[], ...this._sortByData(options)];
    uniqueOptions = this.getUniqueOptions(uniqueOptions);
    this.optionsGroup = this.convertToGroupOptions(uniqueOptions);

    this.fieldData.options = uniqueOptions;

    if (this.allowFreeText && this.fieldFormControl.value) {
      this.addFreeTextToOptions(this.fieldFormControl.value);
    }

    this.autocompleteInput.setValue(this.fieldFormControl.value || null);
    this.changed();
  }

  protected _filter(displayName: string) {
    const filterValue = displayName.toLowerCase();
    const filterNumber = this.includesNumbers(filterValue);

    let filteredOptions: SegmentAutocompleteSelectOption[] = [];

    if (this.fieldData.options) {
      if (filterNumber && filterNumber.length) {
        filteredOptions = this.fieldData.options.filter(option => option.displayName?.includes(filterNumber));
      } else {
        filteredOptions = this.fieldData.options.filter(option =>
          option.displayName?.toLowerCase().includes(filterValue)
        );
      }

      filteredOptions = this.getMaxRowsOptions(this._sortByData(filteredOptions));
    }

    if (this.allowFreeText && !this.fieldData.options?.find(option => option.displayName === displayName)) {
      filteredOptions.push({
        displayName: displayName,
        value: displayName,
        data: { distance: 0, roadType: RoadType.Street },
      });
    }

    return filteredOptions.slice(0, this.MAX_OPTIONS);
  }

  writeValue(value) {
    if (value) {
      this.lastValue = value;
      if (!this.fieldData.options) {
        this.fieldData.options = [];
      }

      if (this.allowFreeText && !this.fieldData.options?.find(option => option.displayName === value)) {
        this.fieldData.options.push({
          displayName: value,
          value: value,
          data: {},
        });
      }

      setTimeout(() => {
        this.autocompleteInput.setValue(value);
      }, 600);
    }
  }

  clear() {
    this.fieldFormControl.setValue('');
    this.changed();
    this.showNoResultFound = false;
    this.autocompleteInput.setValue('');
  }

  includesNumbers(str: string): string | null {
    const matches = str.match(/(\d+)/g);
    return matches ? matches.join('') : null;
  }

  private _sortByData(options: SegmentAutocompleteSelectOption[]): SegmentAutocompleteSelectOption[] {
    return options.sort((option1, option2) => {
      return (option1.data?.distance as number) > (option2.data?.distance as number) ? 1 : -1;
    });
  }

  isHighway(roadType: RoadType) {
    return isHighway(roadType);
  }

  convertToGroupOptions(options: SegmentAutocompleteSelectOption[]): SelectOptionGroup[] {
    const optionsGroups: Array<SelectOptionGroup> = [];
    let _options: SegmentAutocompleteSelectOption[] = new Array(...options);
    Object.values(GroupNames).forEach((groupName, index) => {
      optionsGroups[index] = { name: groupName, options: [], disabled: false };
    });

    _options = this.getMaxRowsOptions(_options);

    _options.forEach(option => {
      if (isHighway(option.data?.roadType)) {
        optionsGroups.find(group => group.name === GroupNames.Highways)?.options?.push(option);
      } else {
        optionsGroups.find(group => group.name === GroupNames.Streets)?.options?.push(option);
      }
    });

    optionsGroups.forEach((group, index) => {
      if (!group.options?.length) {
        optionsGroups.splice(index, 1);
      }
    });

    return optionsGroups;
  }

  getMaxRowsOptions(options: SegmentAutocompleteSelectOption[]): SegmentAutocompleteSelectOption[] {
    let _options = [...[], ...options];
    if (_options.length > this.MAX_OPTIONS) {
      _options = options.slice(0, this.MAX_OPTIONS);
    }
    return _options;
  }

  getUniqueOptions(options: SegmentAutocompleteSelectOption[]) {
    const uniqueOptions: SegmentAutocompleteSelectOption[] = [];
    options.forEach(option => {
      const isFoundOption = uniqueOptions.find(uniqueOption => uniqueOption.value === option.value);
      if (!isFoundOption) {
        uniqueOptions.push(option);
      }
    });

    return uniqueOptions;
  }

  reset() {
    this.fieldData.options = [];
    this.optionsGroup = [];
    this.changed();

    this.fieldFormControl.setValue(null);
    this.autocompleteInput.setValue(null);
  }
}
