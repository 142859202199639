import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  CompletedRoadClosuresGQL,
  CompleteRoadClosureGQL,
  CreateRoadClosureGQL,
  CreateRoadClosureInput,
  RoadClosure,
  RoadClosureActivityLogGQL,
  RoadClosureGQL,
  UpdateRoadClosureGQL,
  UpdateRoadClosureInput,
} from '../models/gql.models';
import { CustomRxOperatorsService } from './custom-rx-operators.service';

@Injectable({
  providedIn: 'root',
})
export class RoadClosureService {
  readonly fetchCompleted$ = this.completedRoadClosuresGQL
    .fetch()
    .pipe(
      map(res => res.data?.completedRoadClosures.map(roadClosure => ({ ...roadClosure, featureType: 'road_closure' })))
    );

  constructor(
    private customOperators: CustomRxOperatorsService,
    private roadClosureGQL: RoadClosureGQL,
    private createRoadClosureGQL: CreateRoadClosureGQL,
    private updateRoadClosureGQL: UpdateRoadClosureGQL,
    private completeRoadClosureGQL: CompleteRoadClosureGQL,
    private completedRoadClosuresGQL: CompletedRoadClosuresGQL,
    private roadClosureActivityLogGQL: RoadClosureActivityLogGQL
  ) {}

  getCompleted() {
    return this.fetchCompleted$;
  }

  get(id: number) {
    return this.roadClosureGQL.fetch({ id: id }).pipe(
      map(res => res.data.roadClosure),
      this.customOperators.catchGqlErrors()
    );
  }

  create(input: CreateRoadClosureInput) {
    return this.createRoadClosureGQL.mutate({ input }).pipe(
      map<any, RoadClosure>(res => res?.data?.createRoadClosure),
      this.customOperators.catchGqlErrors()
    );
  }

  update(input: UpdateRoadClosureInput) {
    return this.updateRoadClosureGQL.mutate({ input }).pipe(
      map<any, RoadClosure>(res => res?.data?.updateRoadClosure),
      this.customOperators.catchGqlErrors()
    );
  }

  complete(id: number) {
    return this.completeRoadClosureGQL.mutate({ id }).pipe(
      map(res => res?.data?.completeRoadClosure),
      this.customOperators.catchGqlErrors()
    );
  }

  getActivityLog(id: number) {
    return this.roadClosureActivityLogGQL.fetch({ id: id }).pipe(
      map(res => res.data.roadClosureActivityLog),
      this.customOperators.catchGqlErrors()
    );
  }
}
