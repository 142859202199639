import { Component, Inject, Input } from '@angular/core';
import { StreetCamera } from '@wc/core';
import { APP_TYPE_TOKEN } from '@wc/wc-core/src/lib/injection-tokens';
import { AppTypeUnion, CameraViewerContextType } from '@wc/wc-models';

@Component({
  selector: 'wc-camera-modal',
  templateUrl: './camera-modal.component.html',
  styleUrls: ['./camera-modal.component.scss'],
})
export class CameraModalComponent {
  @Input() camera!: StreetCamera;
  @Input() isTabletMode!: boolean;
  cameraContextType: typeof CameraViewerContextType = CameraViewerContextType;
  appMode: AppTypeUnion;

  constructor(@Inject(APP_TYPE_TOKEN) appMode: AppTypeUnion) {
    this.appMode = appMode;
  }
}
