import { Injectable } from '@angular/core';
import { ScopeAccessModifier } from '@wc/wc-models/src';
import { PermissionsService } from '../infrastructure/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsFacadeService {
  constructor(private readonly permissionsService: PermissionsService) {}

  get permissionsGroupControls() {
    return this.permissionsService.permissionsGroupControls;
  }

  get permissionDependenciesByGroup() {
    return this.permissionsService.permissionDependenciesByGroup;
  }

  get permissionDependenciesById() {
    return this.permissionsService.permissionDependenciesById;
  }

  hasPermission(permissionStr: ScopeAccessModifier | ScopeAccessModifier[], op: 'AND' | 'OR' = 'OR') {
    return this.permissionsService.hasPermission(permissionStr, op);
  }

  hasTrafficDisruptionPermission() {
    return this.permissionsService.hasTrafficDisruptionPermission();
  }

  hasTrafficDisruptionPermissionCreate() {
    return this.permissionsService.hasTrafficDisruptionPermissionCreate();
  }
}
