import { Inject, Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { environment, User } from '@wc/core';
import { AppTypeUnion } from '@wc/wc-models/src/lib/types/general';
import { AppVersion } from '../app-version';
import { APP_TYPE_TOKEN } from '../injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class DatadogLoggerService {
  constructor(@Inject(APP_TYPE_TOKEN) private _appType: AppTypeUnion) {}

  init(authUser: User) {
    if (environment.enableProdMode) {
      datadogLogs.init({
        clientToken: 'pub89fe7e3ea4b0d9977952f83c74ef0559',
        site: 'datadoghq.com',
        forwardErrorsToLogs: true, // sending errors by defualt
        sampleRate: 100,
        env: environment.production ? 'prod' : 'staging',
        version: AppVersion,
      });

      datadogLogs.setLoggerGlobalContext({
        host: this._appType,
        service: 'frontend-client',
        env: environment.production ? 'prod' : 'staging',
        wc_version: AppVersion,
        wc_user_id: authUser.id,
        wc_account_id: authUser.account.id,
        wc_account_name: authUser.account.name,
        wc_user_name: authUser.name,
      });

      datadogRum.init({
        applicationId: '4715df34-5499-493a-8fee-c96ed6e16237',
        clientToken: 'pub80cadc05df3dd1cd2fc6f25014809192',
        site: 'datadoghq.com',
        env: environment.production ? 'prod' : 'staging',
        service: this._appType,
        version: AppVersion,
        sampleRate: 100,
        trackInteractions: true,
      });
    }
  }
}
