import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IncidentLane, IncidentLaneDetails, LaneType, UiStore } from '@wc/core';

@Component({
  selector: 'wc-affected-lanes-diagram',
  templateUrl: './affected-lanes-diagram.component.html',
  styleUrls: ['./affected-lanes-diagram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AffectedLanesDiagramComponent {
  @Input() isViewMode = false;
  @Input() lanes!: Partial<IncidentLane>[];
  @Input() analyticsLanes!: Partial<IncidentLaneDetails>[];

  @Input() isAnalyticsMode = false;
  @Input() hovered = null;
  @Output() laneClick = new EventEmitter<Object>();
  @Output() laneHovered = new EventEmitter<Object>();

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  TextualLanes = [LaneType.Gore, LaneType.LeftShoulder, LaneType.RightShoulder];

  constructor(public uiStore: UiStore) {}

  laneClicked(lane, index) {
    this.laneClick.emit({ lane, index });
  }

  laneHover(lane, index) {
    this.laneHovered.emit({ lane, index });
  }
}
