import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { AppVersion } from '../app-version';

const DURATION = 15000;

@Injectable({
  providedIn: 'root',
})
export class CheckVersionService {
  sub!: Subscription;
  interval!: Observable<number>;
  newVersion!: string;
  currentVersion: string = AppVersion;
  isLatestVersion$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient) {
    window.addEventListener('sw_updated', () => {
      console.log('=-=-=-=-=-=- sw_updated');
      this.isLatestVersion$.next(false);
    });
    this.startScheduler();
  }

  startScheduler(duration: number = DURATION) {
    this.interval = interval(duration);
    this.sub = this.interval.subscribe(
      () => {
        if (navigator.onLine) {
          this.getNewVersion()
            .then(res => {
              if (res) {
                this.setNewVersion(res['appVersion']);
                this.checkVersionDiff();
              }
            })
            .catch(err => console.error(err));
        }
      },
      err => {
        console.error(err);
      }
    );
  }

  async getNewVersion(): Promise<any> {
    const date = Date.now();
    const url = `/assets/app-version.json?${date}`;
    return await this.http.get(url).toPromise();
  }

  setNewVersion(version: string) {
    this.newVersion = version;
  }

  checkVersionDiff() {
    this.isLatestVersion$.next(this.currentVersion === this.newVersion);
  }

  stopScheduler() {
    this.sub.unsubscribe();
  }
}
