export enum SUPPORTED_FILE_FORMATS {
  mp4 = 'mp4',
  jpg = 'jpg',
  jpeg = 'jpeg',
  png = 'png',
  gif = 'gif',
  pdf = 'pdf',
  xls = 'xls',
  doc = 'doc',
  docx = 'docx',
  csv = 'csv',
}

export const SUPPORTED_DOC_FORMATS = ['pdf', 'xls', 'doc', 'docx', 'csv'];
export const SUPPORTED_DOC_MIME_TYPES = [
  'image/*',
  'video/*',
  'application/pdf', // (.pdf)
  'application/msword', // Microsoft Word (.doc)
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Microsoft Word (.docx)
  'application/vnd.ms-excel', // Microsoft Excel (.xls)
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Microsoft Excel (.xlsx),
  'text/csv', // (.csv)
];
