import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { datadogLogs } from '@datadog/browser-logs';
import { TranslateService } from '@ngx-translate/core';
import { EndShiftInput, ShiftEndReasons, ShiftStore, UiStore, UsersStore } from '@wc/core';
import { AlertsService } from '@wc/features/ui/services/alerts.service';
import { FormFieldData } from '@wc/wc-ui/src/lib/base';

type FormModel = EndShiftInput;

@Component({
  selector: 'wc-end-shift-form',
  templateUrl: './end-shift-form.component.html',
  styleUrls: ['./end-shift-form.component.scss'],
})
export class EndShiftFormComponent implements OnInit {
  endShiftForm!: FormGroup;

  @Output() modalClosed: EventEmitter<any> = new EventEmitter();

  shiftFieldsData: { [filedName in keyof FormModel | any]: FormFieldData } = {
    endMileage: {
      label: 'shiftsAndRoutes.mileage',
      required: true,
    },
    endReason: {
      label: 'shiftsAndRoutes.reason',
      options: this.shiftStore.shiftEndReasonsOptions,
      required: true,
    },
    endReasonNote: {
      label: 'shiftsAndRoutes.reasonOther',
      required: true,
    },
    overtimeNote: {
      placeholder: 'shiftsAndRoutes.overtimeNotePlaceholder',
      required: true,
    },
  };

  get isUserHasUnit() {
    return this.shiftStore.currentShift.unit?.id;
  }

  constructor(
    private fb: FormBuilder,
    private shiftStore: ShiftStore,
    public usersStore: UsersStore,
    public uiStore: UiStore,
    private translateService: TranslateService,
    private alertService: AlertsService
  ) {}

  ngOnInit(): void {
    this.endShiftForm = this.createForm({
      shiftId: this.shiftStore.currentShift.shiftId,
      endMileage: null,
      endReason: [null, Validators.required],
      endReasonNote: [null, Validators.maxLength(50)],
      overtimeNote: null,
    });

    if (this.isUserHasUnit) {
      this.endShiftForm
        .get('endMileage')
        ?.setValidators([
          Validators.required,
          Validators.min(this.shiftStore.currentShift?.startMileage || 0),
          Validators.pattern('[0-9]*'),
          Validators.maxLength(9),
        ]);
    }

    this.endShiftForm.get('endReason')?.valueChanges.subscribe(value => {
      const endReasonNote = this.endShiftForm.get('endReasonNote');
      if (value === ShiftEndReasons.Other) {
        endReasonNote?.setValidators([Validators.required, Validators.maxLength(50)]);
      } else {
        endReasonNote?.clearValidators();
        endReasonNote?.reset();
      }
      endReasonNote?.updateValueAndValidity();
    });
  }

  createForm(model: { [filedName in keyof FormModel]: any }): FormGroup {
    return this.fb.group(model);
  }

  closeShiftForm() {
    this.modalClosed.emit();
  }

  mileageValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null | { mileageIsLowerThanCurrentValue: { currentMileage: number } } {
    const startMileage = this.shiftStore.currentShift.startMileage;
    if (startMileage) {
      if (control.value !== null && (isNaN(control.value) || control.value - startMileage < 50)) {
        return { youHadDrivenLessThan50Miles: true };
      }

      if (control.value !== null && (isNaN(control.value) || control.value - startMileage > 300)) {
        return { youHadDrivenMoreThan300Miles: true };
      }
    }
    return null;
  }

  submitEndShiftForm() {
    this.endShiftForm.markAllAsTouched();

    if (this.endShiftForm.valid) {
      const endMileage = this.endShiftForm.get('endMileage')?.value;
      if (endMileage) this.endShiftForm.get('endMileage')?.setValue(parseInt(endMileage));

      this.shiftStore.endShift(this.endShiftForm.value).subscribe(
        response => {
          if (response) {
            this.closeShiftForm();
            datadogLogs.logger.info('endShiftSuccess', {
              shift: JSON.stringify(this.shiftStore.currentShift),
              userId: this.shiftStore.currentShift.user.id,
            });
            this.usersStore.logoutUser();
          }
        },
        error => {
          this.alertService.error(this.translateService.instant('errorMessages.endShiftFailed'), undefined, {
            enableHtml: true,
          });
          datadogLogs.logger.error('endShiftFailed', {
            shift: JSON.stringify(this.shiftStore.currentShift),
            userId: this.shiftStore.currentShift.user.id,
          });
          console.error(error);
        }
      );
    }
  }

  overtimeCheckboxChanged(overtime: MatCheckboxChange) {
    if (overtime.checked) {
      this.endShiftForm.get('overtimeNote')?.setValidators([Validators.required, Validators.maxLength(150)]);
    } else {
      this.endShiftForm.get('overtimeNote')?.clearValidators();
      this.endShiftForm.get('overtimeNote')?.reset();
      this.endShiftForm.get('overtimeNote')?.updateValueAndValidity();
    }
  }
}
