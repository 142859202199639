import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccessModifier, Account, EntityType, Permission, Scope, User } from '@wc/core/models/gql.models';
import { AuthUserService } from '@wc/wc-core/src/lib/services/auth-user.service';
import { ScopeAccessModifier } from '@wc/wc-models/src/lib/types/utility-types';
import { FormFieldData, FormFieldOption } from '@wc/wc-ui/src/lib/base';
import { getPermissionKey, updatePermissionOptions } from '../application/permissions.utils';
import { predefinedPermissionsOrder } from '../constants';
import { PermissionAccessLevel, PermissionGroupView } from '../enums';
import { PermissionWithDeps, PermissionWithDepsWithIds } from '../types';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private permissions: Array<string> = [];
  private accountPermissions: Array<Permission> = [];
  authUser?: User;
  account?: Account;
  permissionDependenciesByGroup: Partial<Record<EntityType | PermissionGroupView, PermissionWithDeps>> = {};
  permissionDependenciesById: Record<number, PermissionWithDepsWithIds> = {};

  constructor(private readonly authUserService: AuthUserService, private readonly translateService: TranslateService) {
    this.authUserService.authUser$.subscribe(authUser => {
      if (!Object.keys(authUser).length) {
        return;
      }

      this.authUser = authUser;
      this.account = authUser?.account;
      this.setPermissions(authUser.permissions);
    });
  }

  get permissionsGroupControls() {
    if (!this.account) {
      return {};
    }

    const permissions: {
      [entityName in string]?: FormFieldData<number[]>;
    } = {};

    const permissionsArray = this.account.groups.reduce((acc, permissionGroup) => {
      return acc.concat(permissionGroup.permissions);
    }, [] as Permission[]);

    permissionsArray.sort((a, b) => {
      const aIndex = predefinedPermissionsOrder.indexOf(a.scope.entity);
      const bIndex = predefinedPermissionsOrder.indexOf(b.scope.entity);
      return aIndex - bIndex;
    });

    permissionsArray.forEach((permission: Permission) => {
      const key = getPermissionKey(permission);

      const newPermission: FormFieldData<number[]> = {
        label: 'permissions.' + key,
        options: [{ value: [] }, { value: [] }, { value: [] }],
      };

      const _permission = permissions[key] || newPermission;
      const viewerOption = _permission.options?.[PermissionAccessLevel.Viewer] as FormFieldOption<number[]> | undefined;
      const managerOption = _permission.options?.[PermissionAccessLevel.Manager] as
        | FormFieldOption<number[]>
        | undefined;
      if (permission.scope.modifier === AccessModifier.Read) {
        (viewerOption?.value as number[]).push(permission.id);
      }
      (managerOption?.value as number[])?.push(permission.id);

      updatePermissionOptions(_permission, this.translateService, viewerOption, managerOption);

      permissions[key] = _permission;
    });

    // Account-Level permissions
    delete permissions.WEATHER;
    delete permissions.CONGESTION;
    delete permissions.TRAFFIC_IMPACT;
    delete permissions.CRASH_RISK;
    delete permissions.TRAIN_TRANSIT;
    delete permissions.WRONG_WAY_ALERT;
    delete permissions.DOWNLOAD_WIDGET;
    delete permissions.ROADWAY_STATUS_METRIC;

    return permissions;
  }

  hasPermission(permissionStr: ScopeAccessModifier | ScopeAccessModifier[], op: 'AND' | 'OR' = 'OR') {
    const _permissions = Array.isArray(permissionStr) ? permissionStr : [permissionStr];
    return op === 'OR'
      ? _permissions.some(permission => this.permissions.some(p => p === permission))
      : _permissions.every(permission => this.permissions.some(p => p === permission));
  }

  hasTrafficDisruptionPermission() {
    return this.hasPermission(['CONSTRUCTION:READ', 'ROAD_CLOSURE:READ', 'SPECIAL_EVENT:READ']);
  }

  hasTrafficDisruptionPermissionCreate() {
    return this.hasPermission(['CONSTRUCTION:CREATE', 'ROAD_CLOSURE:CREATE', 'SPECIAL_EVENT:CREATE']);
  }

  private setPermissions(permissions: Permission[]) {
    this.accountPermissions =
      this.account?.groups.reduce((acc, group) => {
        return acc.concat(group.permissions);
      }, [] as Permission[]) || [];

    this.accountPermissions.forEach(permission => {
      if (permission.dependsOnAll || permission.dependsOnAny) {
        const key = getPermissionKey(permission);
        if (this.permissionDependenciesByGroup[key]) {
          this.permissionDependenciesByGroup[key].dependsOnAll.push(
            ...this.getPermissionsFromScopes(permission.dependsOnAll)
          );
          this.permissionDependenciesByGroup[key].dependsOnAny.push(
            ...this.getPermissionsFromScopes(permission.dependsOnAny)
          );
        } else
          this.permissionDependenciesByGroup[key] = {
            permissionId: permission.id,
            dependsOnAll: this.getPermissionsFromScopes(permission.dependsOnAll),
            dependsOnAny: this.getPermissionsFromScopes(permission.dependsOnAny),
          };

        const permissionId = permission.id;
        this.permissionDependenciesById[permissionId] = {
          dependsOnAll: this.getPermissionsFromScopes(permission.dependsOnAll).map(permission => permission.id),
          dependsOnAny: this.getPermissionsFromScopes(permission.dependsOnAny).map(permission => permission.id),
        };
      }
    });

    this.permissions = [];
    permissions.forEach((permission: Permission) => {
      try {
        const { entity, modifier } = permission.scope;
        this.permissions.push(`${entity}:${modifier}`);
      } catch (error) {
        console.error(error);
        console.error('permission:', permission);
      }
    });
  }

  getPermissionId(scope: Scope): number | undefined {
    return this.accountPermissions.find(p => p.scope.entity === scope.entity && p.scope.modifier === scope.modifier)
      ?.id;
  }

  getPermissionsFromScopes(scope?: Scope[] | null): Permission[] {
    return this.accountPermissions.reduce((acc, permission) => {
      if (scope?.some(s => s.entity === permission.scope.entity && s.modifier === permission.scope.modifier)) {
        acc.push(permission);
      }
      return acc;
    }, [] as Permission[]);
  }
}
