import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

export function BeforeDateValidator(beforeControlName: string, afterControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
    const beforeControl = (formGroup as FormGroup).controls[beforeControlName];
    const afterControl = (formGroup as FormGroup).controls[afterControlName];

    if (beforeControl.errors && !beforeControl.errors.beforeDateRequired) {
      return null;
    }

    if (afterControl.value !== null && beforeControl.value === null) {
      beforeControl.setErrors({ beforeDateRequired: true });
      return { beforeDateRequired: true };
    } else {
      beforeControl.setErrors(null);
      return null;
    }
  };
}
