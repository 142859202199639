import { Injectable } from '@angular/core';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';
import { FormFieldOption } from '../../../../../wc-ui/src/lib/base/custom-form-control';

export type AutocompleteHistoricalSearch<T extends FormFieldOption> = {
  value: T['value'];
  searchTime: number;
};

@Injectable()
export class AutocompleteHistoricalSearchService<T extends FormFieldOption> {
  key!: string;
  usersHistoricalSearches: Map<string, AutocompleteHistoricalSearch<T>> = new Map();

  constructor(private readonly localStorageService: LocalStorageService) {}

  initHistoricalSearches() {
    const storageData = this.localStorageService.get(`autocomplete-historical-searches-${this.key}`) || [];
    const weekAgoDate = new Date();
    weekAgoDate.setDate(weekAgoDate.getDate() - 7);

    for (const item of storageData) {
      if (item.searchTime > weekAgoDate.getTime()) {
        this.usersHistoricalSearches.set(`${item.value}`, item);
      }
    }
  }

  setHistoryItem(selectedOption: T) {
    if (this.usersHistoricalSearches.has(`${selectedOption.value}`)) {
      return;
    }

    const newHistoryItem: AutocompleteHistoricalSearch<T> = {
      value: selectedOption.value,
      searchTime: Date.now(),
    };

    this.usersHistoricalSearches.set(`${selectedOption.value}`, newHistoryItem);

    const savedSearches = Array.from(this.usersHistoricalSearches.values());
    this.localStorageService.set(`autocomplete-historical-searches-${this.key}`, savedSearches);
  }

  isHistoricalOption(value: unknown): boolean {
    return this.usersHistoricalSearches.has(`${value}`);
  }
}
