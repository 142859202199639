import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { FocusDirectiveModule } from '@wc/wc-common/src';
import { SlideToggleModule } from '../slide-toggle/slide-toggle.module';
import { WcTooltipModule } from '../tooltip/tooltip.module';
import { WorkSpacesMenuComponent } from './work-spaces-menu.component';

@NgModule({
  declarations: [WorkSpacesMenuComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    TranslateModule,
    MatCheckboxModule,
    SlideToggleModule,
    MatSlideToggleModule,
    FocusDirectiveModule,
    WcTooltipModule,
  ],
  exports: [WorkSpacesMenuComponent],
})
export class WorkSpacesMenuModule {}
