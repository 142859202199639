import { Injectable } from '@angular/core';
import NoSleep from 'nosleep.js';

@Injectable({
  providedIn: 'root',
})
export class NoSleepService {
  noSleep = new NoSleep();

  constructor() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.noSleep.enable().then(() => {
          console.log('No sleep is->' + this.noSleep.isEnabled);
        });
      } else {
        this.noSleep.disable();
        console.log('No sleep is->' + this.noSleep.isEnabled);
      }
    });
  }
}
