export enum PermissionGroupKey {
  General = 'GENERAL',
  Events = 'EVENTS',
  LiveMap = 'LIVE_MAP',
  AdvancedAnalytics = 'ADVANCED_ANALYTICS',
  FieldOperations = 'FIELD_OPERATIONS',
}

export enum PermissionAccessLevel {
  Manager,
  Viewer,
  NoAccess,
}

export enum PermissionGroupView {
  TRAFFIC_DISRUPTION = 'TRAFFIC_DISRUPTION',
  SHARE_TRAFFIC_DISRUPTION = 'SHARE_TRAFFIC_DISRUPTION',
  REPORTS = 'REPORTS',
  HISTORY = 'HISTORY',
}
