import { Environment, StaticLayersKeysAndUrls } from './types';
import { createStaticLayersPerVendorList } from './utils';

const envKeysAndPaths: StaticLayersKeysAndUrls = {
  AWSKey:
    'v1.public.eyJqdGkiOiI4MDRlMzA4Ny0yMTNhLTRjNDQtYTYxMy1lNTM5OWRkZWQwOTMifYLc5HoI_VdMf_CNEd3-M-IIYmbBw10bYVY86zf4Chc0gorPHuD5ImEcxjMFGGVVZ27JtDmj3FOQ5Y4R3ZjaLcYk-WCfaEI9vuu6h8OOCV2r3bra1robwFnsYWXihlaSQUb9Bj13Yoe-4LwZtyX8DKJ7ne8A72Cc3y-kpHcwIilXgc9XYTbv-APU4zP91LEeLwvkAFBFovSgLxAFTCEJWMXgsqunuz44XsHW5aDUiQqNSywODQ9fBtTHIuSyiATMLIUCQfUb_y64n7P5Tv7Fm0pbOUELdrnw0e9Wwcj9iQZcRYTnJH4-aNCmr0UyKXtU7WOBuuvvKCxlTz-sWULGk58.ZWU0ZWIzMTktMWRhNi00Mzg0LTllMzYtNzlmMDU3MjRmYTkx',
  MAPBOXKey: 'pk.eyJ1Ijoid2F5Y2FyZSIsImEiOiJjanp1eTd4aXAwMGt0M2NueHh3dXI0dWpiIn0.v27dO5Fkf58A8s2WzJi34A',
  REKORUrl: 'https://command-tile-server.waycare.io',
  MAPBOXAccountUrl: 'mapbox://styles/waycare',
  MAPBOXLabelLayerStyleId: 'clfkucfpk001j01qpv13kwd0e',
};

export const environment: Environment = {
  hasServiceWorker: true,
  production: true,
  enableProdMode: true,
  defaultMitigationTypeId: 1,
  defaultMitigationTypeAccountId: 2,
  mapboxAccountNameAndStyleId: 'waycare/ckqeyhnka2mq918o4ec0gepyb',

  mapboxKey: envKeysAndPaths.MAPBOXKey,
  mapBoxUrl: envKeysAndPaths.MAPBOXAccountUrl,
  awsApiKey: envKeysAndPaths.AWSKey,

  staticLayersPerVendor: createStaticLayersPerVendorList(envKeysAndPaths),
  live_map_api_url: 'https://waycare-livemap.waycare.io/v1/livemap',
  new_live_map_api_url: 'https://waycare-livemap.waycare.io/v2/livemap',
  api_url: 'https://waycare-apis.waycare.io/v1/gql',
  root_api_url: 'https://waycare-apis.waycare.io/v1',
  ws_url: 'wss://waycare-collaboration.waycare.io/wss/',
  map_server: envKeysAndPaths.REKORUrl,
  baseRoutePath: '',
  imageServiceUrl: 'https://cameras.waycare.io/api/v1/cameras',

  auth0: {
    domain: 'login.waycare.io',
    // domain: "waycare-auth-us1-prod.auth0.com",
    // domain: "login.waycare2cloud.net",
    client_id: 'ycfGPPRneRbXk9AyZQoDveFsZGtfODLL',
  },
  heapAnalyticsTrackingCode: '934848375',
  googleAnalyticsTrackingCode: {
    web: 'UA-152060225-2',
    tablet: 'UA-152060225-3',
  },
  splitio: {
    authorizationKey: 'g5ei3mgihc2hn8301qtc2sn2vdtbkrjem9qj',
    key: 'key',
    trafficType: 'Account',
  },
  hotjarCode: {
    web: '2085323',
    tablet: '2085324',
  },
  smartlook: {
    key: '389986adaecb741aa1afba50058c37780fbd5293',
  },
  iFrameCameraHandlerServer: 'https://nati-582c5.web.app',
  downloadsDomain: 'https://tmg-downloads.waycare.io',
};
