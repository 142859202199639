<div class="involved-vehicles-component">
  <button
    data-cy-id="involved-vehicle-add-btn"
    class="button-add"
    rekor-basic
    leftSideIcon="plus"
    (click)="addInvolvedVehicle()"
    [disabled]="involvedVehicles.invalid">
    {{ 'add' | translate }}
  </button>

  <form [formGroup]="parentForm" [class.language-rtl]="uiStore.isHebrew">
    <div formArrayName="involvedVehicles" *ngIf="involvedVehicles.controls.length; else noVehicle">
      <div *ngFor="let involvedVehicle of involvedVehicles.controls; let i = index" [formGroupName]="i">
        <section class="form-subsection">
          <h3 class="form-subsection-title">{{ 'vehicle' | translate }} {{ i + 1 }}</h3>

          <wc-text-field
            maxlength="24"
            data-cy-id="involved-vehicle-licence-plate-number-textbox"
            formControlName="licensePlateNumber"
            [transformToUpperCase]="true"
            [formFieldData]="involvedVehiclesFields.licensePlateNumber!"></wc-text-field>

          <wc-autocomplete
            data-cy-id="involved-vehicle-state"
            [disableSort]="true"
            formControlName="state"
            [formFieldData]="involvedVehiclesFields.state!">
          </wc-autocomplete>

          <wc-autocomplete
            data-cy-id="involved-vehicle-color"
            [disableSort]="true"
            formControlName="color"
            [formFieldData]="involvedVehiclesFields.color!">
          </wc-autocomplete>

          <wc-autocomplete
            data-cy-id="involved-vehicle-model"
            [disableSort]="true"
            formControlName="make"
            [formFieldData]="involvedVehiclesFields.make!">
          </wc-autocomplete>

          <wc-autocomplete
            data-cy-id="involved-vehicle-type"
            [disableSort]="true"
            formControlName="type"
            [formFieldData]="involvedVehiclesFields.type!">
          </wc-autocomplete>

          <button
            rekor-basic
            leftSideIcon="times-circle"
            [wcTooltipText]="'tooltip.removeVehicle' | translate"
            wcTooltipPlacement="bottom"
            [disabled]="involvedVehicles.invalid"
            (click)="removeVehicle(i)">
            {{ 'remove' | translate }}
          </button>
        </section>
      </div>
    </div>

    <ng-template #noVehicle>
      <div class="no-vehicle-placeholder">
        <i class="empty-involved-vehicles"> </i>
        <div class="header">{{ 'noVehiclesPlaceholder' | translate }}</div>
        <div class="sub-header">{{ 'addNewVehicle' | translate }}</div>
      </div>
    </ng-template>
  </form>
</div>
