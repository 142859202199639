import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { UiStore } from '@wc/core/stores/ui.store';
import { WCErrorStateMatcher } from '@wc/wc-ui/src/lib/base';
import { BaseControlFieldComponent } from '../../base/base-control-field.component';

@Component({
  selector: 'wc-multi-select-control',
  templateUrl: './multi-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectControl),
      multi: true,
    },
  ],
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectControl extends BaseControlFieldComponent {
  matcher: ErrorStateMatcher = new WCErrorStateMatcher();

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  constructor(public uiStore: UiStore) {
    super();
  }
}
