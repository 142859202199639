import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { WcEntitiesState } from '@wc/wc-models';
import { EntitiesServiceV2 } from '../../services/entities.service';

function createInitialState(): WcEntitiesState {
  return {
    modified: {},
    removed: {},
    updatedAt: undefined,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'entities' })
export class EntitiesStore extends Store<WcEntitiesState> {
  constructor(private entitiesService: EntitiesServiceV2) {
    super(createInitialState());
    this.entitiesService.getDiff$.subscribe(entitiesDiff => {
      this.update(() => entitiesDiff);
    });
  }
}
