import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IsOneTimeClick } from './is-one-time-click.directive';

@NgModule({
  declarations: [IsOneTimeClick],
  imports: [CommonModule, TranslateModule],
  providers: [],
  exports: [IsOneTimeClick],
})
export class IsOneTimeClickModule {}
