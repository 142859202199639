import { Injectable, Optional } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastrAlertsService {
  private keepAfterRouteChange = false;
  isPortraitMode = false;

  get activeToastsLength() {
    return this.toaster.toasts.length;
  }

  constructor(private toaster: ToastrService, @Optional() router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    router?.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          this.keepAfterRouteChange = false;
        } else {
          this.clear();
        }
      }
    });
  }

  success(
    message: string,
    title?: string,
    options?: Partial<IndividualConfig>,
    keepAfterRouteChange = false
  ): ActiveToast<any> | undefined {
    if (!this.isShownAlready(message)) {
      this.keepAfterRouteChange = keepAfterRouteChange;

      if (this.isPortraitMode && options) {
        options.positionClass = options.positionClass + '__portrait-mode';
      }

      return this.toaster.success(message, title, options);
    }
    return undefined;
  }

  error(message: string, title?: string, options?: Partial<IndividualConfig>, keepAfterRouteChange = false) {
    if (!this.isShownAlready(message)) {
      this.keepAfterRouteChange = keepAfterRouteChange;

      if (this.isPortraitMode && options) {
        options.positionClass = options.positionClass + '__portrait-mode';
      }

      this.toaster.error(
        message,
        title,
        Object.assign(
          {
            timeOut: 3000,
          } as Partial<IndividualConfig>,
          options
        )
      );
    }
  }

  info(message: string, title?: string, options?, keepAfterRouteChange = false) {
    if (!this.isShownAlready(message)) {
      this.keepAfterRouteChange = keepAfterRouteChange;
      return this.toaster.info(message, title, options);
    }
    return;
  }

  warn(message: string, title?: string, options?, keepAfterRouteChange = false) {
    if (!this.isShownAlready(message)) {
      this.keepAfterRouteChange = keepAfterRouteChange;
      this.toaster.warning(message, title, options);
    }
  }

  clear() {
    this.toaster.clear();
  }

  isShownAlready(message: string, resetOnDuplicate = false, countDuplicats = false) {
    return !!this.toaster.findDuplicate('', message, resetOnDuplicate, countDuplicats);
  }

  setPortraitDesktopMode(isPortrait: boolean) {
    this.isPortraitMode = isPortrait;
  }
}
