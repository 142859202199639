<div
  [formGroup]="parentFormGroupDirective.form"
  class="response-plan-form prevent-animation"
  [ngClass]="'response-plan-form--' + _uiMode">
  <div class="response-plan-form__column-names">
    <!-- TODO:remove add row -->
    <span (click)="addRow(5)"> {{ 'time' | translate }} </span>
    <span> {{ 'units' | translate }} </span>
    <span> {{ 'action' | translate }} </span>
    <span> {{ 'values' | translate }} </span>
    <span> {{ 'text' | translate }} </span>
  </div>
  <!-- TODO check if the *ngIf can be removed -->
  <div
    *ngIf="parentFormGroupDirective.form.get('actions')"
    #scrollableContainer
    id="rp-table-container"
    class="response-plan-form__table-container">
    <div formArrayName="actions">
      <ng-container *ngFor="let action of actions.controls; let i = index">
        <wc-response-plan-form-action
          [action]="$any(action)"
          [entityType]="entityType"
          [actionIndex]="i"
          [actionsLength]="actions.controls.length"
          [regionalSetting]="responsePlanService.reginalSettings"
          [newAddedRowIndex]="newAddedRowIndex"
          [selectedAction]="selectedAction"
          [uiMode]="_uiMode"
          (optionsClicked)="clickOnItemOption($event)"
          (rowAdded)="addRow($event)"
          (rowRemoved)="removeRow($event)"></wc-response-plan-form-action>
      </ng-container>
    </div>
  </div>
</div>
