import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { IncidentSelectTypeComponent } from './incident-select-type.component';

@NgModule({
  declarations: [IncidentSelectTypeComponent],
  imports: [CommonModule, TranslateModule, WcIconModule],
  providers: [],
  exports: [IncidentSelectTypeComponent],
})
export class IncidentSelectTypeModule {}
