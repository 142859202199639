import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { TranslateModule } from '@ngx-translate/core';
import { WcIconModule } from '../../components/wc-icon/wc-icon.module';
import { MultipleChipsComponent } from './wc-multiple-chips.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [MultipleChipsComponent],
  imports: [CommonModule, TranslateModule, MatChipsModule, WcIconModule, FormsModule, ReactiveFormsModule, MatFormFieldModule],
  exports: [MultipleChipsComponent],
})
export class WcMultipleChipsModule {}
