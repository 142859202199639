import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { WcInvalidExpansionCssClassDirectiveModule } from '@wc/wc-common/src/lib/directives/wc-invalid-expansion-css-class.directive.module';
import {
  AssociatedUnitsModule,
  CameraComponentsModule,
  CollaborationViewerModule,
  ConfirmCreationChangesModalComponent,
  ConfirmEditChangesModalComponent,
  ConfirmGenericModalComponent,
  ConfirmModalComponent,
  CrashRiskModalComponentModule,
  DmsComponentsModule,
  DropFileModule,
  MediaFullScreenModule,
  MediaSectionModule,
  MediaThumbnailModule,
  SliderToggleControlModule,
  StaticMapViewerModule,
  TextFieldModule,
  TransitUnitModalModule,
  WcIconModule,
  WcNetworkBadgeModule,
  WcNotificationModule,
  WcSelectControlModule,
} from '@wc/wc-ui';
import { CheckboxListControlModule } from '@wc/wc-ui/src/components/checkbox-list-control/checkbox-list-control.module';
import { MapEditorModule } from '@wc/wc-ui/src/components/map-viewers/map-editor/map-editor.module';
import { WcAutocompleteV2Module } from '@wc/wc-ui/src/form-components/autocomplete-v2/autocomplete-v2.module';
import { ResponsePlanEntityControlModule } from '@wc/wc-ui/src/form-components/response-plan-entity-control/response-plan-entity-control.module';
import { MobxAngularModule } from 'mobx-angular';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { WcMapModule } from '../../wc-map/src/lib/wc-map.module';
import { WcBaseControlFieldModule } from './base/base-control-field.module';
import { BaseFormComponent } from './base/base-form/base-form.component';
import { UI_COMPONENTS } from './components';
import { AddressModalComponentModule } from './components/address-modal/address-modal-components.module';
import { CongestionModalComponentModule } from './components/congestion-modal/congestion-modal-components.module';
import { IncidentListItemModule } from './components/incident-list-item/incident-list-item.module';
import { WcLoaderModule } from './components/loader/loader.module';
import { MapDrawingModalModule } from './components/map-drawing-modal/map-drawing-modal.module';
import { MitigationFormModule } from './components/mitigation-form/mitigation-form.module';
import { NewIncidentLocationModule } from './components/new-incident-location/new-incident-location.module';
import { AffectedLanesExpentionModule } from './components/panel-mat-expention-views/affected-lanes-expention/affected-lanes-expention.module';
import { SelectTooltipComponent } from './components/select-tooltip/select-tooltip.component';
import { TabletVerticalStepperModule } from './components/tablet-vertical-stepper/tablet-vertical-stepper.module';
import { UnitCardComponentsModule } from './components/unit-card-components.module';
import { WorkspacesListModule } from './components/workspaces-list/workspaces-list.module';
import { UI_DIRECTIVES } from './directives';
import { IsOneTimeClickModule } from './directives/is-one-time-click.module';
import { PermissionDirectiveModule } from './directives/permission.directive.module';
import { RoadEventHighlightModule } from './directives/road-event-highlight.module';
import { UI_FORM_CONTROLS } from './form-controls';
import { MitigationComponentModule } from './form-controls/mitigation/mitigation-component.module';
import { UI_FORM_FILED_COMPONENTS } from './form-fields-components';
import { AffectedLanesFormFieldComponentsModule } from './form-fields-components/affected-lanes-form-field-components.module';
import { AssociatedUnitModule } from './form-fields-components/associated-unit/associated-unit.module';
import { LaneMoveModalComponent } from './form-fields-components/lane-move-modal/lane-move-modal.component';
import { NotesComponentModule } from './form-fields-components/notes/notes-component.module';
import { RoadsComponentModule } from './form-fields-components/roads/roads-component.module';
import { SchedulerComponentModule } from './form-fields-components/scheduler/scheduler-component.module';
import { UI_FORM_FILED_CONTROLS } from './form-fields-controls';
import { WcCheckboxModule } from './form-fields-controls/checkbox/checkbox.module';
import { DurationComponentModule } from './form-fields-controls/duration/duration.component.module';
import { EditableListComponentModule } from './form-fields-controls/editable-list/editable-list.component.module';
import { MapDrawModule } from './form-fields-controls/map-draw/map-draw.module';
import { MultiCheckboxComponentModule } from './form-fields-controls/multi-checkbox/multi-checkbox.component.module';
import { MultiRadioButtonComponentModule } from './form-fields-controls/multi-radio-button/multi-radio-button.component.module';
import { MultiSelectComponentModule } from './form-fields-controls/multi-select/multi-select.component.module';
import { WcNumberFieldModule } from './form-fields-controls/number-field/number-field.module';
import { NumberOnlyComponentModule } from './form-fields-controls/numbers-only-field/number-only.component.module';
import { RelatedEventsAutocompleteV2Module } from './form-fields-controls/related-events-autocomplete-v2/related-events-autocomplete-v2.module';
import { SegmentAutocompleteV2Module } from './form-fields-controls/segment-autocomplete-v2/segment-autocomplete-v2.module';
import { SelectBoxesControlModule } from './form-fields-controls/select-boxes-control/select-bixes-control.module';
import { WcSelectChipsModule } from './form-fields-controls/select-chips/select-ships.module';
import { WcTextAreaModule } from './form-fields-controls/text-area/text-area.module';
import { WcTextFiledModule } from './form-fields-controls/text-field/text-field.module';
import { FORMS_COMPONENTS } from './forms';
import { IncidentStepsModule } from './forms/incident-forms/incident-steps.module';
import { PublishIncidentFormModule } from './forms/publish-incident-form/publish-incident-form.module';
import { WcShiftFormsModule } from './forms/shift-forms/shift-forms.module';
import { MaterialModule } from './material-module';
import { UI_PIPES } from './pipes';
import { AffectedLanesPipesModule } from './pipes/affected-lanes.pipes.module';
import { FormatsPipesModule } from './pipes/formats.pipes.module';
import { IncidentPipesModule } from './pipes/incident.pipes.module';
import { RoadEventPipesModule } from './pipes/road-event.pipes.module';

export const DATE_TIME_FORMATS = {
  fullPickerInput: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
  monthYearA11yLabel: { year: 'numeric', month: 'long' },
  custom: { month: 'long', day: 'numeric', year: 'numeric' },
};

export const WC_FORM_FIELDS_MODULES: any[] = [
  WcBaseControlFieldModule,
  WcCheckboxModule,
  WcSelectControlModule,
  SliderToggleControlModule,
  WcNumberFieldModule,
  WcTextAreaModule,
  WcAutocompleteV2Module,
  WcSelectChipsModule,
  AffectedLanesFormFieldComponentsModule,
  SegmentAutocompleteV2Module,
  RelatedEventsAutocompleteV2Module,
  MultiSelectComponentModule,
  MapDrawModule,
  EditableListComponentModule,
  RoadsComponentModule,
  AssociatedUnitModule,
  WcTextFiledModule,
  SelectBoxesControlModule,
  StaticMapViewerModule,
  MapEditorModule,
  ResponsePlanEntityControlModule,
];

export const WC_UI_COMPONENTS_MODULES: any[] = [
  WcLoaderModule,
  IncidentListItemModule,
  WcNotificationModule,
  WcNetworkBadgeModule,
  CollaborationViewerModule,
  MitigationFormModule,
  MitigationComponentModule,
  NewIncidentLocationModule,
  TabletVerticalStepperModule,
  UnitCardComponentsModule,
  CameraComponentsModule,
  NumberOnlyComponentModule,
  DurationComponentModule,
  NotesComponentModule,
  AddressModalComponentModule,
  SchedulerComponentModule,
  MultiCheckboxComponentModule,
  MultiRadioButtonComponentModule,
  MapDrawingModalModule,
  CrashRiskModalComponentModule,
  CongestionModalComponentModule,
  WorkspacesListModule,
  AffectedLanesExpentionModule,
  IsOneTimeClickModule,
  PermissionDirectiveModule,
  TransitUnitModalModule,
  DmsComponentsModule,
  MediaThumbnailModule,
  MediaSectionModule,
  DropFileModule,
  MediaFullScreenModule,
  CheckboxListControlModule,
  WcIconModule,
];

export const WC_PIPE_MODULES: any[] = [
  FormatsPipesModule,
  RoadEventPipesModule,
  IncidentPipesModule,
  AffectedLanesPipesModule,
];

export const WC_FORMS_MODULES: any[] = [WcShiftFormsModule, IncidentStepsModule, PublishIncidentFormModule];

const MODULES = [
  TranslateModule,
  MobxAngularModule,
  WC_FORM_FIELDS_MODULES,
  WC_UI_COMPONENTS_MODULES,
  WC_FORMS_MODULES,
  WC_PIPE_MODULES,
];
@NgModule({
  imports: [
    ...MODULES,
    CommonModule,
    MaterialModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    SwiperModule,
    FlexLayoutModule,
    FormsModule,
    WcMapModule,
    MatDialogModule,
    OverlayModule,
    WorkspacesListModule,
    RoadEventHighlightModule,
    AffectedLanesExpentionModule,
    AssociatedUnitsModule,
    WcInvalidExpansionCssClassDirectiveModule,
    TextFieldModule,
  ],
  declarations: [
    ...UI_PIPES,
    ...UI_DIRECTIVES,
    ...UI_COMPONENTS,
    ...UI_FORM_CONTROLS,
    ...FORMS_COMPONENTS,
    ...UI_FORM_FILED_CONTROLS,
    ...UI_FORM_FILED_COMPONENTS,
    BaseFormComponent,
    LaneMoveModalComponent,
    SelectTooltipComponent,
    ConfirmEditChangesModalComponent,
    ConfirmCreationChangesModalComponent,
    ConfirmGenericModalComponent,
  ],
  exports: [
    ...MODULES,
    ...UI_PIPES,
    ...UI_DIRECTIVES,
    ...UI_COMPONENTS,
    ...UI_FORM_CONTROLS,
    ...FORMS_COMPONENTS,
    ...UI_FORM_FILED_CONTROLS,
    ...UI_FORM_FILED_COMPONENTS,
  ],
  providers: [
    {
      provide: OWL_DATE_TIME_FORMATS,
      useValue: DATE_TIME_FORMATS,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    DatePipe,
  ],
  entryComponents: [ConfirmModalComponent],
})
export class UISharedModule {}
