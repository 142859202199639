import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { WcInvalidExpansionCssClassDirectiveModule } from '@wc/wc-common/src/lib/directives/wc-invalid-expansion-css-class.directive.module';
import { WcLoaderComponentModule } from '../../components/miscellaneous/loader/loader.module';
import { ResponsePlanFormModule } from '../../components/response-plan-form/response-plan-form.module';
import { WcTooltipModule } from '../../components/tooltip/tooltip.module';
import { WcSelectModule } from '../wc-select/wc-select.module';
import { ResponsePlanEntityControlComponent } from './response-plan-entity-control.component';

@NgModule({
  declarations: [ResponsePlanEntityControlComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    ResponsePlanFormModule,
    WcSelectModule,
    MatExpansionModule,
    WcInvalidExpansionCssClassDirectiveModule,
    WcLoaderComponentModule,
    WcTooltipModule,
  ],
  exports: [ResponsePlanEntityControlComponent],
})
export class ResponsePlanEntityControlModule {}
