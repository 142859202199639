import { Injectable } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { wcFeatureProperties } from '@wc/wc-map-viewer/src';
import { TransitLayerType } from '@wc/wc-models';
import { Subject } from 'rxjs';
import { CrashRiskAreaStatus, LayerType, TrafficDisruptionStatus } from '../../../../core/models/gql.models';
import { weatherStatusOptionsEnum } from '../../../../core/models/models';
import { LocalStorageKeys, LocalStorageService } from './local-storage.service';

export interface liveMapFilters {
  trafficDisruptionStatus: TrafficDisruptionStatus | undefined;
  mitigatedByMyAccount: boolean | undefined;
  assignedToMe: boolean | undefined;
  weatherStatus: weatherStatusOptionsEnum | undefined;
  crashRiskStatus: CrashRiskAreaStatus | undefined;
  transitIsMyAgency: boolean | undefined;
  onlyNearCamerasIncidents: boolean | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class LiveMapFiltersService {
  private filterChangedSubject = new Subject<liveMapFilters>();
  filterChanged$ = this.filterChangedSubject.asObservable();
  private filtersObj: liveMapFilters = {
    trafficDisruptionStatus: undefined,
    mitigatedByMyAccount: undefined,
    assignedToMe: undefined,
    weatherStatus: weatherStatusOptionsEnum.allAlerts,
    crashRiskStatus: undefined,
    transitIsMyAgency: undefined,
    onlyNearCamerasIncidents: false,
  };

  constructor(private localStorageService: LocalStorageService) {
    this.initiateFilterObjectState();
  }

  get currentFilters(): liveMapFilters {
    return Object.assign(this.filtersObj, {});
  }

  updateLiveMapFilterObject<T extends keyof liveMapFilters>(key: T, value: liveMapFilters[T]) {
    this.filtersObj[key] = value;
    this.localStorageService.set(LocalStorageKeys.LiveMapFilters, this.filtersObj);
    this.filterChangedSubject.next(this.filtersObj);
  }

  applyFiltersOnFeatureProps<T extends string>(props: wcFeatureProperties<T> | undefined): boolean {
    if (props?.show === false) return false;
    if (
      !props ||
      !this.trafficDisruptionStatusFilter(props) ||
      !this.mitigatedByMyAccountFilter(props) ||
      !this.assignedToMeFilter(props) ||
      !this.weatherStatusFilter(props) ||
      !this.crashRiskStatusFilter(props) ||
      !this.transitIsMyAgency(props) ||
      !this.nearCamerasFilter(props)
    )
      return false;

    return true;
  }

  private initiateFilterObjectState() {
    const filterFromLocalStorage = this.localStorageService.get(LocalStorageKeys.LiveMapFilters);
    if (filterFromLocalStorage) {
      this.filtersObj = filterFromLocalStorage;
      this.filterChangedSubject.next(this.filtersObj);
    }
  }

  private trafficDisruptionStatusFilter<T extends string>(props: wcFeatureProperties<T>): boolean {
    if (!this.filtersObj.trafficDisruptionStatus) return true;
    if (
      (props.entitySubType.includes('special_event') ||
        props.entitySubType.includes('road_closure') ||
        props.entitySubType.includes('construction')) &&
      props.status?.toLowerCase() !== this.filtersObj.trafficDisruptionStatus.toLowerCase()
    ) {
      return false;
    }
    return true;
  }

  private mitigatedByMyAccountFilter<T extends string>(props: wcFeatureProperties<T>): boolean {
    if (this.filtersObj.mitigatedByMyAccount === true && props.mitigatedByMyAccount === false) {
      return false;
    }
    return true;
  }

  private assignedToMeFilter<T extends string>(props: wcFeatureProperties<T>): boolean {
    if (this.filtersObj.assignedToMe && !props.assignedToMe) {
      return false;
    }
    return true;
  }

  private weatherStatusFilter<T extends string>(props: wcFeatureProperties<T>): boolean {
    if (
      props.entityType !== LayerType.WeatherAlert ||
      this.filtersObj.weatherStatus === weatherStatusOptionsEnum.allAlerts ||
      this.filtersObj.weatherStatus === props.status
    )
      return true;
    return false;
  }

  private crashRiskStatusFilter<T extends string>(props: wcFeatureProperties<T>): boolean {
    if (
      props.entityType !== LayerType.CrashRiskArea ||
      this.filtersObj.crashRiskStatus === undefined ||
      this.filtersObj.crashRiskStatus === props.status?.toUpperCase()
    ) {
      return true;
    }
    return false;
  }

  private transitIsMyAgency<T extends string>(props: wcFeatureProperties<T>): boolean {
    if (
      (props.entityType === LayerType.Unit ||
        props.entityType === TransitLayerType.TransitRoute ||
        props.entityType === TransitLayerType.TransitFixedRouteBus) &&
      this.filtersObj.transitIsMyAgency &&
      !props.isMyAccount
    ) {
      return false;
    }
    return true;
  }

  private nearCamerasFilter<T extends string>(props: wcFeatureProperties<T>): boolean {
    if (props.entityType === LayerType.Incident && this.filtersObj.onlyNearCamerasIncidents && !props.nearCameras) {
      return false;
    }
    return true;
  }
}
