import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NewLiveMapEntity } from '@wc/wc-models';
import { LiveMapEntity } from '../../../../core/models';
import { SegmentDataViewMode } from '../roadway-status/internal-components/segment-data/segment-data.component';
import { SelectedSegmentData } from '../roadway-status/roadway-status-panel/roadway-status-panel.component';

export enum ContextTypesEnum {
  Select = 'select',
  Hover = 'hover',
}

export enum TooltipTypes {
  Incident = 'INCIDENT',
  RoadAsset = 'ROAD_ASSET',
  Unit = 'UNIT',
  Bus = 'BUS',
  Snowplow = 'SNOWPLOW',
  RoadClosure = 'ROAD_CLOSURE',
  Congestion = 'CONGESTION',
  IrregularCongestion = 'IrregularCongestion',
  CrashRisk = 'CrashRisk',
  WeatherEvent = 'WeatherAlert', //Support old live-map
  WeatherAlert = 'WeatherAlert',
  TransitUnit = 'TransitUnit',
  TransitFixedRoute = 'TransitFixedRoute',
  TransitFixedStop = 'TransitFixedStop',
  RoadwayStatus = 'ROADWAY_STATUS',
}
const LayerTypeToModalType = {
  CAMERA: TooltipTypes.RoadAsset,
  DMS: TooltipTypes.RoadAsset,
  ROADWAY_STATUS: TooltipTypes.RoadwayStatus,
};

@Component({
  selector: 'wc-clustering-tooltip-container',
  templateUrl: './clustering-tooltip-container.component.html',
  styleUrls: ['./clustering-tooltip-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClusteringTooltipContainerComponent {
  segmentTempType!: {
    length: number;
    direction: string;
    deviation: number;
    speed: number;
    vehicleCount: number;
    startTime: string;
    endTime: string;
  };
  readonly contextTypes = ContextTypesEnum;
  SegmentDataViewMode = SegmentDataViewMode;
  tooltipTypes = TooltipTypes;
  displayData: LiveMapEntity[] = [];
  totalLength = 0;
  maxHeight = 'auto';
  overflow: 'hidden' | 'auto' = 'hidden';
  remainingLength = 0;
  tooltipType!: string | null;
  context!: ContextTypesEnum;
  activeTemplate?: TemplateRef<HTMLElement>;
  @Output() entityClicked = new EventEmitter<{
    liveMapEntity: NewLiveMapEntity;
    additionalItems?: SelectedSegmentData;
  }>();
  //unknown will be type segment when ready
  @Output() moreDetailsClicked = new EventEmitter<unknown>();
  @Input() isTabletMode = false;
  @Input() isWidget = false;
  @Input() isHebrew = false;
  @Input() set data(data: {
    entities: (LiveMapEntity & { direction: string; length: number; averageSpeed: number; updatedAt: number })[];
    tooltipType: string;
    context: ContextTypesEnum;
  }) {
    if (!data || !data.entities?.length) {
      console.error('Please provide a valid data');
      return;
    }
    /**
     * @description LayerTypeToModalType is temporary while we support old and new map
     */
    this.tooltipType = data.tooltipType || LayerTypeToModalType[data.entities[0].layerType || ''];
    if (!this.tooltipType) {
      console.error('could not find a valid tooltipType');
      return;
    }

    this.totalLength = data?.entities.length;
    this.context = data.context;
    this.displayData = this.getDisplayDataByType(data?.entities, data.context);
    this.remainingLength = this.remainingLength = this.totalLength - this.displayData.length;
  }

  _entityClicked(liveMapEntity: NewLiveMapEntity, additionalItems?: SelectedSegmentData) {
    this.entityClicked.emit({ liveMapEntity, additionalItems });
  }

  _moreDetailsClicked(entity: unknown) {
    this.moreDetailsClicked.emit(entity);
  }

  private getDisplayDataByType(entities: LiveMapEntity[], context: ContextTypesEnum) {
    if (this.tooltipType === TooltipTypes.RoadAsset) {
      return entities?.slice(0, context === ContextTypesEnum.Hover ? 5 : 15).sort(this.assetsSortFunction);
    }

    if (this.tooltipType === TooltipTypes.RoadwayStatus) {
      return entities?.slice(0, context === ContextTypesEnum.Hover ? 1 : 5);
    }

    return entities;
  }

  private assetsSortFunction(a: LiveMapEntity, b: LiveMapEntity) {
    if (a.status === b.status) return 0;
    if (a.status === 'Active' && b.status === 'Inactive') return -1;
    return 1;
  }
}
