import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { WcButtonComponent, WcLinkButtonComponent } from './wc-button.component';

@NgModule({
  declarations: [WcButtonComponent, WcLinkButtonComponent],
  imports: [CommonModule, WcIconModule],
  exports: [WcButtonComponent, WcLinkButtonComponent],
})
export class WcButtonModule {}
