import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DayType } from '@wc/work-schedule-configuration/domain/src';

export const ShortDayName = {
  1: 'days.short.Monday',
  2: 'days.short.Tuesday',
  3: 'days.short.Wednesday',
  4: 'days.short.Thursday',
  5: 'days.short.Friday',
  6: 'days.short.Saturday',
  7: 'days.short.Sunday',
} as const;

export const FullDayName = {
  1: 'days.long.Monday',
  2: 'days.long.Tuesday',
  3: 'days.long.Wednesday',
  4: 'days.long.Thursday',
  5: 'days.long.Friday',
  6: 'days.long.Saturday',
  7: 'days.long.Sunday',
} as const;
@Injectable({ providedIn: 'root' })
@Pipe({ name: 'translateDayOfWeek' })
export class DayOfWeekPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(day: DayType, fullDayName = false): string {
    return this.translateService.instant(fullDayName ? FullDayName[day] : ShortDayName[day]);
  }
}
