import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  Address,
  AssociatedCamera,
  Incident,
  IncidentStatus,
  IncidentStore,
  IncidentType,
  LiveMapStore,
  LocalStorageService,
  LocationService,
  NavigationService,
  Position,
  UiStore,
} from '@wc/core';
import { AlertsService } from '@wc/features/ui/services/alerts.service';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { take } from 'rxjs/operators';
import { RoadEventsPanelSubTabIndexTablet, RoadEventsPanelTabIndex } from '../../../../core/models/enums';
import { AddressComponent } from '../../form-fields-components/address/address.component';
import { AddressRequiredValidator } from '../../form-validators';

@Component({
  selector: 'wc-new-incident-location',
  templateUrl: './new-incident-location.component.html',
  styleUrls: ['./new-incident-location.component.scss'],
})
export class NewIncidentLocationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(AddressComponent) addressControl!: AddressComponent;
  @Output() addressChanged: EventEmitter<Address> = new EventEmitter<Address>();
  @Output() loaded: EventEmitter<NewIncidentLocationComponent> = new EventEmitter<NewIncidentLocationComponent>();

  address: FormControl = new FormControl(null, [AddressRequiredValidator]);

  isOutsideJurisdiction!: boolean;
  disableButtons = false;
  subscription;

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  constructor(
    private incidentStore: IncidentStore,
    private uiStore: UiStore,
    private alertService: AlertsService,
    private translateService: TranslateService,
    private liveMapStore: LiveMapStore,
    private navigationService: NavigationService,
    private locationService: LocationService,
    private localStorageService: LocalStorageService
  ) {}
  ngAfterViewInit(): void {
    this.getInitialLocation();
  }

  ngOnInit(): void {
    this.isOutsideJurisdiction = false;

    /*   In case tablet user with PC right click on the map
     we need to populate the init by click coords and not locationService */
    if (this.liveMapStore.mapTabletRightClickLocation) {
      this.address.setValue({
        point: {
          type: 'Point',
          coordinates: [
            this.liveMapStore.mapTabletRightClickLocation?.coords.longitude,
            this.liveMapStore.mapTabletRightClickLocation?.coords.latitude,
          ],
        },
      });
      this.loaded.emit(this);
    }

    this.address.valueChanges.subscribe(address => {
      this.localStorageService.set(LocalStorageKeys.EditIncidentStepOne, { address: address, location: address.point });
    });
  }

  getInitialLocation() {
    this.subscription = this.addressControl?.mapReady
      .pipe(initialized => initialized, take(1))
      .subscribe(async () => {
        if (!this.liveMapStore.mapTabletRightClickLocation) {
          const fallbackPosition = this.liveMapStore.accountLocationCenter();
          console.log('fallbackPosition: ', fallbackPosition);
          const position: Position = this.locationService.locationUpdate$.value || {
            coords: {
              longitude: fallbackPosition[0],
              latitude: fallbackPosition[1],
            },
          };
          this.address.setValue({
            point: {
              type: 'Point',
              coordinates: [position?.coords?.longitude, position?.coords?.latitude],
            },
          });
          this.loaded.emit(this);
        }
      });
  }

  async onAddressError(error: { errorCode: number }) {
    if (error?.errorCode === -1) {
      const errorMessage: string = await this.translateService
        .get(`errorMessages.unableToLocateTheDeviceLocation`)
        .toPromise();
      !this.alertService.isShownAlready(errorMessage) && this.alertService.error(errorMessage);
      return;
    }
    if (error?.errorCode === 400) {
      console.log('outside workspace');
      this.isOutsideJurisdiction = true;
    } else {
      this.isOutsideJurisdiction = false;
    }
    if (error?.errorCode && error?.errorCode !== -1) {
      const errorMessage: string = await this.translateService.get(`errorMessages.${error.errorCode}`).toPromise();
      !this.alertService.isShownAlready(errorMessage) && this.alertService.error(errorMessage);
    }
  }

  onAddressLoading(loading: boolean) {
    loading ? (this.disableButtons = true) : (this.disableButtons = false);
  }

  cancel() {
    this.incidentStore.stopCreateIncidentFlow();
    this.navigationService.back();
  }

  async continueEdit() {
    if (this.isOutsideJurisdiction) return;
    if (this.address.valid) {
      this.addressChanged.emit(this.address.value);
      //await this.incidentStore.updateIncidentLocation(this.address.value?.point?.coordinates);
      this.incidentStore.editCurrentIncident({});
    }
  }

  async createAsDraft() {
    if (this.isOutsideJurisdiction) return;
    this.addressControl.markAsTouched();

    if (this.address.valid) {
      //const incident: Partial<Incident> = await this.incidentStore.updateIncidentLocation(null, this.address.value?.point?.coordinates);
      const incident = {
        address: {
          point: {
            coordinates: this.address.value?.point?.coordinates,
            type: 'Point',
          },
          ...this.address.value,
        },
        location: {
          coordinates: this.address.value?.point?.coordinates,
          type: 'Point',
        },
        status: IncidentStatus.Confirmed,
        startedAt: new Date(),
      } as Incident;
      const cameras = await this.incidentStore.camerasByPoint(this.address.value?.point?.coordinates);
      this.incidentStore.updateIncidentLocation(this.address.value?.point?.coordinates);
      const res = await this.incidentStore
        .createIncident({
          ...incident,
          cameras: cameras as AssociatedCamera[],
          type: IncidentType.Unidentified,
        })
        .toPromise();

      this.uiStore.setRoadEventsPanelTabIndex(RoadEventsPanelTabIndex.InProgress);
      this.uiStore.setRoadEventsInProgressSubTabIndex(RoadEventsPanelSubTabIndexTablet.Incidents);
      this.showSuccessNotification();
      this.navigationService.back();

      setTimeout(() => {
        this.incidentStore.stopCreateIncidentFlow();
      });
    }
  }

  showSuccessNotification() {
    this.alertService.success(
      this.translateService.instant('notifications.incidentCreatedInInProgressTab'),
      undefined,
      undefined,
      true
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.liveMapStore.mapTabletRightClickLocation = undefined;
  }
}
