import { Injectable } from '@angular/core';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import { AliasType, Scalars, SegmentDetails, SegmentGQL } from '../models';
import { CustomRxOperatorsService } from './custom-rx-operators.service';
import { SegmentAliasType } from "@wc/features/ui/form-fields-components/address/address.component";

type AddedOrEditedCustomAlias = {
  isAlias: boolean,
  createdAliasType: SegmentAliasType,
  updatedAliasType: SegmentAliasType
};

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  lastSegmentDetails$: BehaviorSubject<SegmentDetails | null> = new BehaviorSubject(<SegmentDetails | null>null);

  constructor(
      private segmentGQL: SegmentGQL,
      private customOperators: CustomRxOperatorsService
  ) {}

  segmentDetails(point: Scalars['Point']): Observable<SegmentDetails | undefined> {
    if (!navigator.onLine) return of();
    //return new Observable(subscriber => {subscriber.next(segmentDetailsMock); subscriber.complete()});
    if (point.type !== 'Point' || (point.coordinates[0] && point.coordinates[0] instanceof Array)) {
      return throwError({
        error: `Point type should be Point but got: ${JSON.stringify(point)}`,
      });
    }
    return this.segmentGQL.fetch({ point: point, radiusMeters: 3000 }).pipe(
      map(res => {
        if (res && res.errors) throw { errorCode: res.errors[0].extensions?.statusCode };
        if (res.data !== null) {
          this.lastSegmentDetails$.next(res.data.segment);
          return res.data.segment as SegmentDetails;
        } else {
          return undefined;
        }
      }),
      catchError(err => {
        this.lastSegmentDetails$.next(null);
        return err;
      }),
      this.customOperators.catchGqlErrors()
    );
  }
}
