import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class WCErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, formGroup: FormGroupDirective | NgForm | null): boolean {
    const formTouched = formGroup?.form?.touched;
    return !!(control && control.invalid && (formTouched || control.dirty || control.touched));
  }
}

export class InvalidStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control?.invalid || false;
  }
}
