import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { HeapAnalyticsService } from '@wc-core';
import { NewLiveMapEntity } from '@wc/wc-models/src';
import { Query } from 'apollo-angular';
import { cloneDeep, isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, MonoTypeOperatorFunction, Observable, of, OperatorFunction, pipe } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, takeWhile, tap } from 'rxjs/operators';
import { CustomFormControlComponent, FormFieldData, FormFieldOption } from '../../lib/base/custom-form-control';
import { AutocompleteErrorStateMatcher } from './autocomplete-error-matcher';
import {
  AutoCompleteOption,
  OptionSelectionEvent,
} from './components/autocomplete-option/autocomplete-option.component';
import { AutocompleteFetchService } from './services/autocomplete-fetch.service';
import { AutocompleteFreeTextService } from './services/autocomplete-free-text.service';
import { AutocompleteGroupingService, GroupedOption } from './services/autocomplete-grouping.service';
import { AutocompleteHistoricalSearchService } from './services/autocomplete-historical-search.service';
import {
  AutocompleteLoadMoreService,
  LoadMoreState,
  LoadMoreStateType,
} from './services/autocomplete-load-more.service';
import { AutocompleteMultiSelectionService } from './services/autocomplete-multi-selection.service';

export type OptionsMapper<T extends FormFieldOption> = { map: (results: NewLiveMapEntity[]) => T[] };
export type OptionsArray<T extends FormFieldOption<unknown, unknown>> = T[] | GroupedOption<T>[];
export const AUTOCOMPLETE_DEBOUNCE_DURATION_MS = 250;

export const ExtraOptionValue = {
  LoadMore: 'LOAD_MORE',
  All: 'ALL',
} as const;

export type ExtraOptionValueType = typeof ExtraOptionValue[keyof typeof ExtraOptionValue];

@Component({
  selector: 'wc-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    AutocompleteMultiSelectionService,
    AutocompleteFreeTextService,
    AutocompleteGroupingService,
    AutocompleteLoadMoreService,
    AutocompleteFetchService,
    AutocompleteHistoricalSearchService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AutocompleteComponent<T extends AutoCompleteOption<unknown, Record<string, any>>>
  extends CustomFormControlComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  // Services
  private multiSelectService?: AutocompleteMultiSelectionService<T>;
  private groupingService?: AutocompleteGroupingService<T>;
  private freeTextService?: AutocompleteFreeTextService<T>;
  private loadMoreService?: AutocompleteLoadMoreService<T>;
  private fetchService?: AutocompleteFetchService;
  private historicalSearchService?: AutocompleteHistoricalSearchService<T>;
  //---------------
  readonly separatorKeysCodes = [ENTER, COMMA];
  private isAlive = true;
  private readonly _options = new BehaviorSubject<T[]>([]);
  private _isMultiSelect = false;
  private _optionsQuery?: Query<any>;
  private _isGrouped = false;
  private _isDisabled = false;
  private _showSelectAllOption = false;
  private _loadMoreState: LoadMoreStateType = LoadMoreState.Off;

  changeDetectionRef!: ChangeDetectorRef;
  isPanelOpened = false;
  autocompleteInputFormControl = new FormControl('');
  filteredOptions$: Observable<OptionsArray<T>> = of([]);
  filteredOptionsList: T[] = [];
  filteredOptionsLength = 0;
  noResultsFound = false;
  preparedOptions$?: Observable<OptionsArray<T>>;
  regularOptions$: Observable<T[]> = of([]);
  groupedOptions$: Observable<GroupedOption<T>[]> = of([]);
  loadMoreDisabled = false;
  matcher!: AutocompleteErrorStateMatcher;
  isPanelOpenedSubject = new BehaviorSubject<boolean>(this.isPanelOpened);
  _formFieldData = {} as FormFieldData;
  _isFreeText = false;
  fetchedInitially = false;
  isFetchMode = false;
  options$ = this._options.asObservable();
  set options(values: T[]) {
    values.length === 0 && !this.optionsQuery && !this._isFreeText
      ? this.autocompleteInputFormControl.disable()
      : this.autocompleteInputFormControl.enable();

    this._options.next(values);
    this.initializeHiddenOptionsAndGroups(values);
  }

  get options(): T[] {
    return this._options.getValue();
  }

  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger!: MatAutocompleteTrigger;
  @ViewChild(MatAutocomplete) auto!: MatAutocomplete;

  autocompleteInput?: ElementRef;
  @ViewChild('autocompleteInput', { read: ElementRef }) set input(input: ElementRef) {
    this.autocompleteInput = input;
    if (this.multiSelectService) {
      this.multiSelectService.inputRef = input;
    }
  }

  @Output() onOptionSelected = new EventEmitter<T>();

  @Input() showLabel = true;
  @Input() disableSort = false;
  @Input() minSubstringLength = 3;
  @Input() queryDataKey = 'options';
  @Input() fetchedOptionsMapper: OptionsMapper<T> | undefined;
  @Input() commaSeparatedMultiSelect = true;
  @Input() hint!: string;
  @Input()
  set isDisabled(val: boolean) {
    this._isDisabled = val;
    val ? this.autocompleteInputFormControl.disable() : this.autocompleteInputFormControl.enable();
  }
  get isDisabled() {
    return this._isDisabled;
  }

  @Input()
  set historicalLocalStorageKey(key: string | undefined) {
    if (key) {
      this.historicalSearchService = this.injector.get(AutocompleteHistoricalSearchService);
      this.historicalSearchService.key = key;
    }
  }

  @Input()
  set optionsQuery(optionsQuery: Query<any> | undefined) {
    this._optionsQuery = optionsQuery;
    this.isFetchMode = !!optionsQuery;
    this.fetchService = optionsQuery ? this.injector.get(AutocompleteFetchService) : undefined;
  }

  get optionsQuery(): Query<any> | undefined {
    return this._optionsQuery;
  }

  @Input()
  set isGrouped(isGrouped: boolean) {
    this._isGrouped = isGrouped;
    if (this._isGrouped) {
      this.groupingService = this.injector.get(AutocompleteGroupingService);
    } else this.groupingService = undefined;
  }
  get isGrouped() {
    return this._isGrouped;
  }

  @Input()
  set isFreeText(val: boolean) {
    this._isFreeText = val && !this.optionsQuery;
    if (val) {
      this.freeTextService = this.injector.get(AutocompleteFreeTextService);
    } else this.freeTextService = undefined;
  }

  get isFreeTextOptionVisible() {
    return !!this.freeTextService?.getFreeTextVisible(this.options, this.autocompleteInputFormControl.value);
  }

  @Input()
  set isLoadMore(val: boolean) {
    this.loadMoreState = val ? LoadMoreState.On : LoadMoreState.Off;
  }

  set loadMoreState(state: LoadMoreStateType) {
    this._loadMoreState = state;
    if (this._loadMoreState === LoadMoreState.On) {
      this.loadMoreService = this.injector.get(AutocompleteLoadMoreService);
    } else this.loadMoreService = undefined;
  }

  get loadMoreState(): LoadMoreStateType {
    return this._loadMoreState;
  }

  @Input()
  set showSelectAllOption(val: boolean) {
    this._showSelectAllOption = val;
  }

  get showSelectAllOption() {
    return this._showSelectAllOption;
  }

  @Input()
  set formFieldData(data: FormFieldData) {
    this._formFieldData = data;
    if (isEqual(this.options, data.options)) return;

    this.options = (Array.isArray(data.options) ? data.options : []) as T[];

    if (!this.isPanelOpened) {
      this.multiSelectService?.resetSelections();
      if (this.options.length) this.setInitialValue();
    }
  }
  get formFieldData() {
    return this._formFieldData;
  }

  @Input()
  set isMultiSelect(val: boolean) {
    this._isMultiSelect = val;
    if (val) {
      this.multiSelectService = this.injector.get(AutocompleteMultiSelectionService);
    } else this.multiSelectService = undefined;
  }
  get isMultiSelect() {
    return this._isMultiSelect;
  }

  get multiSelectOptions() {
    return this.multiSelectService?.selectedOptions || [];
  }

  get selectedCount() {
    return Array.isArray(this.ngControl.value)
      ? this.ngControl.value.filter(optionValue => this.options.find(option => option.value === optionValue)).length
      : 0;
  }

  get displayedOptionsLength() {
    return this.options.reduce(
      (acc, option) => (this.loadMoreState === LoadMoreState.On ? acc + (option.hidden ? 0 : 1) : acc + 1),
      0
    );
  }

  get showExtraOptions() {
    return this.filteredOptionsLength >= this.displayedOptionsLength;
  }

  get showLoadMoreButton() {
    return (
      this.loadMoreState === LoadMoreState.On &&
      Object.entries(this.hiddenOptions).reduce((acc, [_, value]) => acc + (value ? 1 : 0), 0) > 0
    );
  }

  get allOptions() {
    return this.multiSelectService?.allOptions;
  }

  get loadMoreOption() {
    return this.loadMoreService?.loadMoreOption;
  }

  get hiddenOptions() {
    return this.loadMoreService?.hiddenOptions || {};
  }

  get hiddenGroups() {
    return this.loadMoreService?.hiddenGroups || {};
  }

  get isBelowThreshold() {
    return !!this.fetchService?.isBelowThreshold;
  }

  get isFetching() {
    return !!this.fetchService?.isFetching;
  }

  get multiSelectOverlayText() {
    return this.multiSelectService?.displayFn() || '';
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    cdr: ChangeDetectorRef,
    private readonly injector: Injector,
    private readonly heapService: HeapAnalyticsService
  ) {
    super(ngControl, cdr);
    this.changeDetectionRef = cdr;
    this.matcher = new AutocompleteErrorStateMatcher(this.ngControl, this.isPanelOpenedSubject);
  }

  ngOnInit(): void {
    const autocompleteValueChanges = this.autocompleteInputFormControl.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      debounceTime(AUTOCOMPLETE_DEBOUNCE_DURATION_MS),
      filter(val => typeof val === 'string' || typeof val === 'number')
    );

    this.setFilteredOptions(autocompleteValueChanges);
    this.regularOptions$ = this.filteredOptions$ as Observable<T[]>;
    this.groupedOptions$ = this.filteredOptions$ as Observable<GroupedOption<T>[]>;

    this.onSearchSendHeap(autocompleteValueChanges);

    if (this.optionsQuery && this._isFreeText) this.isFreeText = false;
    this.historicalSearchService?.initHistoricalSearches();
  }

  ngAfterViewInit(): void {
    this.setInitialValue();
  }

  initializeHiddenOptionsAndGroups(options: T[]) {
    if (this.loadMoreState === LoadMoreState.Loaded) this.loadMoreState = LoadMoreState.On;
    this.loadMoreService?.initializeHiddenOptions(options);
    this.loadMoreService?.initializeHiddenGroups(options);
  }

  setFilteredOptions(autocompleteValueChanges: Observable<string>) {
    try {
      this.filteredOptions$ =
        this.getFetchModeFilteredOptions(autocompleteValueChanges) || this.getFilteredOptions(autocompleteValueChanges);
    } catch (e) {
      this.filteredOptions$ = this.getFilteredOptions(autocompleteValueChanges);
    }
  }

  selectOption(event: OptionSelectionEvent<T>) {
    const { selectedOption, clickEvent } = event;

    const loadedMore = this.handleLoadMore(event);

    if (loadedMore) return;
    this.handleUpdateInput(selectedOption);
    this.handleSetHistoryItem(selectedOption);
    this.handleSelectedHiddenOption(event.selectedOption);
    this.handleMultiSelection(selectedOption, clickEvent);
    this.setUpdatedValue(this.multiSelectService?.selectedOptions.map(option => option.value) || selectedOption.value);
    this.handleSendOptionSelectedHeap(selectedOption);
    this.updateTriggerPosition();
    this.onOptionSelected.emit(selectedOption);
  }

  handleSetHistoryItem(selectedOption: T) {
    this.historicalSearchService?.setHistoryItem(selectedOption);
  }

  handleUpdateInput(selectedOption: T) {
    if (!this.isMultiSelect) this.autocompleteInputFormControl.setValue(selectedOption.displayName);
  }

  // Selecting a hidden option (after filter) should disable load more
  handleSelectedHiddenOption(selectedOption: T) {
    if (selectedOption.hidden) {
      this.loadMoreService?.showAllOptions(this.options);
      this.loadMoreState = LoadMoreState.Loaded;
    }
  }

  handleLoadMore({ selectedOption, clickEvent }: OptionSelectionEvent<T>) {
    if (selectedOption.value === 'LOAD_MORE' && this.loadMoreState !== LoadMoreState.Loaded) {
      clickEvent?.stopImmediatePropagation();
      this.loadMoreService?.showAllOptions(this.options);
      this.resetFormControl();
      this.loadMoreState = LoadMoreState.Loaded;

      return true;
    }
    return false;
  }

  insertValue(event?: Event) {
    event?.preventDefault();
    if (this.isMultiSelect) return;

    const option = this.freeTextService?.insertValue(this.options, this.autocompleteInputFormControl.value);

    if (option) this.selectOption({ selectedOption: option });
  }

  optionClicked(event: OptionSelectionEvent<T>) {
    if (this.isMultiSelect) {
      event.clickEvent?.stopImmediatePropagation();
      this.selectOption(event);
    }
  }

  handleSendOptionSelectedHeap(selectedOption: T) {
    this.sendHeapEvent('option-clicked', {
      fieldName: this.formFieldData.label,
      displayName: selectedOption.displayName,
    });
  }

  handleMultiSelection(selectedOption: T, clickEvent?: MouseEvent) {
    this.multiSelectService?.onOptionSelected(
      { selectedOption },
      this.autocompleteInputFormControl,
      clickEvent,
      this.options,
      this.loadMoreState === LoadMoreState.Off || this.loadMoreState === LoadMoreState.Loaded
    );
  }

  sendHeapEvent(
    actionName: string,
    data?: { fieldName?: string; displayName?: string; value?: unknown; selected?: boolean }
  ) {
    if (data && !data?.fieldName) throw new Error('Field name must exist in order to send analytics');
    this.heapService.trackUserSpecificAction(`heap-autocomplete-${actionName}`, data);
  }

  /**
   * Initializes the autocomplete control with the appropriate value.
   * Handles single/multi-select and fetch modes.
   * Updates `loadMoreState` if needed.
   */
  setInitialValue() {
    if (!this.autocompleteInputFormControl.value && this.ngControl.value) {
      this.multiSelectService?.setInitialValues(this.ngControl.value, this.options);

      if (!this.isMultiSelect) {
        const foundOption = this.options.find(option => option.value === this.ngControl.value);

        const displayName = foundOption?.displayName;
        if (displayName || (this.ngControl.value && this._isFreeText)) {
          if (!displayName) {
            this.appendSingleValueOption(this.ngControl.value);
          }
          this.autocompleteInputFormControl.setValue(displayName ?? this.ngControl.value);
        } else if (!displayName && this.ngControl.value && this.isFetchMode) {
          this.autocompleteInputFormControl.setValue(this.ngControl.value);
          this.autocompleteInputFormControl.setValue('', { emitEvent: false });
        }
      }

      if (this.hasHiddenSelected()) {
        this.loadMoreService?.showAllOptions(this.options);
        this.loadMoreState = LoadMoreState.Loaded;
      }
    }
  }

  setAutocompleteInputValue(value: string | undefined) {
    this.autocompleteInputFormControl.setValue(value);
  }

  hasHiddenSelected() {
    const hiddenOptionsMap = new Map(this.options.map(option => [option.value, option.hidden]));
    return this.isMultiSelect
      ? this.ngControl.value.some(value => hiddenOptionsMap.get(value))
      : hiddenOptionsMap.get(this.ngControl.value);
  }

  resetFormControl() {
    this.autocompleteInputFormControl.setValue('');
    if (this.autocompleteInput) this.autocompleteInput.nativeElement.value = '';
  }

  clear() {
    this.resetFormControl();
    this.ngControl.control?.setValue(null, { emitEvent: false });
    this.formFieldData.options = [];
  }

  updateTriggerPosition() {
    setTimeout(() => {
      this.autocompleteTrigger.updatePosition();
    });
  }

  triggerPanel() {
    setTimeout(() => {
      this.multiSelectService?.focusMultiSelectionInput();
      this.autocompleteInput?.nativeElement.focus();
      this.autocompleteTrigger.openPanel();
    });
  }

  displayFn(option: T) {
    if (!option) return '';
    return typeof option === 'string' ? option : option.displayName ?? '';
  }

  updateOptions(options: T[], selectedOptionValue?: string) {
    this.formFieldData = {
      ...this.formFieldData,
      options,
    };
    const selectedOption = options.find(opt => opt.value === selectedOptionValue);
    if (selectedOption) {
      this.selectOption({ selectedOption });
    }
  }

  clearSelection(event?: MouseEvent) {
    event?.stopPropagation();
    this.resetFormControl();
    if (!this.isMultiSelect) this.onChanged(null);
    this.triggerPanel();
    this.autocompleteInput?.nativeElement.focus();
  }

  setPanelAsOpened(event: boolean) {
    this.isPanelOpened = event;
    this.isPanelOpenedSubject.next(event);
    if (event) {
      this.sendHeapEvent('clicked');
    } else {
      if (
        typeof this.autocompleteInputFormControl.value === 'string' &&
        !this.isFetchMode &&
        this._isFreeText &&
        this.autocompleteInputFormControl.value &&
        !this.options.map(option => option.displayName).includes(this.autocompleteInputFormControl.value)
      ) {
        this.insertValue();
      }
    }
  }

  setActiveOption(option: T) {
    const shouldIncrement = this.showSelectAllOption
      ? !this.isFetchMode && this.showExtraOptions && (this.isMultiSelect || (this.isGrouped && this.isMultiSelect))
      : false;
    const options = this.filteredOptionsList.filter(option =>
      this.loadMoreState === LoadMoreState.On ? !option.hidden : true
    );

    if (option.value === ExtraOptionValue.All || option.value === ExtraOptionValue.LoadMore) {
      this.auto._keyManager.setActiveItem(
        option.value === ExtraOptionValue.LoadMore
          ? options.length + (this.filteredOptionsList.length === this.options.length && shouldIncrement ? 1 : 0)
          : 0
      );
      return;
    }

    let index = options.findIndex(_option => _option.value === option.value);

    if (shouldIncrement) {
      index++;
    }
    this.auto._keyManager.setActiveItem(index);
  }

  private onSearchSendHeap(autocompleteValueChanges: Observable<string>) {
    autocompleteValueChanges
      .pipe(
        takeWhile(() => this.isAlive),
        filter(value => !!value && typeof value === 'string')
      )
      .subscribe(value => {
        this.sendHeapEvent('search-value-changed', {
          value,
          fieldName: this.formFieldData.label,
        });
      });
  }

  private getFilteredOptions(autocompleteValueChanges: Observable<string>) {
    this.preparedOptions$ = this.options$.pipe(
      takeWhile(() => !this.isFetchMode),
      map(options => (this.isGrouped && this.groupingService ? this.groupingService.groupOptions(options) : options))
    );

    return combineLatest([autocompleteValueChanges, this.preparedOptions$])
      .pipe(takeWhile(() => this.isAlive))
      .pipe(this.filteredOptionPipes());
  }

  private getFetchModeFilteredOptions(autocompleteValueChanges: Observable<string>) {
    return this.fetchService
      ?.getFilteredOptionsObservable(
        autocompleteValueChanges,
        this.queryDataKey,
        this.minSubstringLength,
        this.changeDetectionRef,
        this.optionsQuery
      )
      .pipe(
        takeWhile(() => this.isAlive),
        this._mapFetchedResultsToOptions(),
        this.fetchFilteredOptionPipes()
      );
  }

  private fetchFilteredOptionPipes(): OperatorFunction<any[], OptionsArray<T>> {
    return pipe(
      this._setInitiallySelected(),
      this._clearFetchOptionSelections(),
      this._updateHistoricOptions(),
      map(options => {
        return (
          this.isGroupedOption(options) ? this.groupingService?.groupOptions(options) : options
        ) as OptionsArray<T>;
      }),
      this._updateGroupVisibilityBasedOnItsOptionsVisibility(),
      this._setFilteredOptionsLength(),
      this._checkNoResultsFound(),
      this._sort(),
      this._setFilteredOptionsList()
    );
  }

  private filteredOptionPipes(): OperatorFunction<[string, OptionsArray<T>], OptionsArray<T>> {
    return pipe(
      map(([value]) => this._trimInputValue(value)),
      this._clearSelection(),
      this._setOptionVisibility(),
      this._filterOptions(),
      this._updateGroupVisibilityBasedOnItsOptionsVisibility(),
      this._setFilteredOptionsLength(),
      this._checkNoResultsFound(),
      this._sort(),
      this._setFilteredOptionsList()
    );
  }

  private _setInitiallySelected(): MonoTypeOperatorFunction<T[]> {
    return tap(options => {
      if (!this.fetchedInitially) {
        // currently only supports options as string array
        if (this.isMultiSelect) {
          this.multiSelectService?.setInitialValues(
            this.ngControl.value,
            this.ngControl.value.map(value => ({ value, displayName: value }))
          );
        } else {
          const foundSelectedOption = options.find(option => option.value === this.ngControl.value);
          if (foundSelectedOption) {
            this.autocompleteInputFormControl.setValue(foundSelectedOption.displayName, { emitEvent: false });
          }
        }
        this.fetchedInitially = true;
      }
    });
  }

  private _clearFetchOptionSelections(): MonoTypeOperatorFunction<T[]> {
    return tap(options => {
      if (this.isMultiSelect) return;
      if (
        this.ngControl.value &&
        !options.some(option => option.displayName === this.autocompleteInputFormControl.value)
      )
        this.onChanged(null);
    });
  }

  private _updateHistoricOptions(): OperatorFunction<T[], T[]> {
    return map(options => {
      return options.map(option => {
        option.historical = this.historicalSearchService?.isHistoricalOption(option.value);
        return option;
      });
    });
  }

  private _clearSelection(): MonoTypeOperatorFunction<string> {
    return tap(value => {
      if (this.isMultiSelect) return;
      const optionValue = value.trim();

      if (
        this.ngControl.value &&
        this.options.length > 0 &&
        !this.options.find(option => option.displayName?.trim() === optionValue)
      ) {
        this.onChanged(null);
      }
    });
  }

  private _sort(): OperatorFunction<OptionsArray<T>, OptionsArray<T>> {
    return map(options => {
      const clone = cloneDeep(options);
      return this.disableSort ? clone : clone.sort((a, b) => (a.displayName || '').localeCompare(b.displayName || ''));
    });
  }

  private _mapFetchedResultsToOptions(): OperatorFunction<any[], T[]> {
    return map(results => this.fetchedOptionsMapper!.map(results));
  }

  private _filterOptions(): OperatorFunction<string, OptionsArray<T>> {
    return map(value => {
      return this.isGrouped && this.groupingService
        ? this.groupingService.groupedFilter(value)
        : this._nonGroupedFilter(value);
    });
  }

  private _updateGroupVisibilityBasedOnItsOptionsVisibility(): MonoTypeOperatorFunction<OptionsArray<T>> {
    return tap((groups: OptionsArray<T>) => {
      if (!this.isGroupedOption(groups)) return;
      groups.forEach(group => {
        if (this.loadMoreState === LoadMoreState.Off || !this.loadMoreService) return;

        if (group.options.some(option => !this.hiddenOptions[`${option.value}`])) {
          this.loadMoreService?.showGroup(group.label);
        } else this.loadMoreService?.hideGroup(group.label);
      });
    });
  }

  private _setOptionVisibility(): MonoTypeOperatorFunction<string> {
    return tap((value: string) => {
      if (this.loadMoreState === LoadMoreState.Off || !this.loadMoreService) return;

      if (value.length) {
        this.loadMoreService.showAllOptions(this.options);
      } else if (this.loadMoreState !== LoadMoreState.Loaded) {
        this.loadMoreService.hideOptions(this.options);
      }
    });
  }

  private isGroupedOption = (options: OptionsArray<T>): options is GroupedOption<T>[] => this.isGrouped;

  private _setFilteredOptionsLength(): MonoTypeOperatorFunction<OptionsArray<T>> {
    return tap(options => {
      this.filteredOptionsLength = this.isGroupedOption(options)
        ? options.reduce((acc, curr) => acc + curr.options.length, 0)
        : options.length;
    });
  }

  private _setFilteredOptionsList(): MonoTypeOperatorFunction<OptionsArray<T>> {
    return tap(options => {
      this.filteredOptionsList = this.isGroupedOption(options)
        ? options.reduce((acc, curr) => {
            return [...acc, ...curr.options];
          }, [] as T[])
        : options;
    });
  }

  private _nonGroupedFilter(inputValue: string) {
    const _inputValue = inputValue.trim().toLocaleLowerCase();
    return this.options.filter(
      item =>
        item.displayName?.toLocaleLowerCase().includes(_inputValue) ||
        item.extraLineField?.value?.toLocaleLowerCase().includes(_inputValue)
    );
  }

  private _checkNoResultsFound(): MonoTypeOperatorFunction<OptionsArray<T>> {
    return tap(options => {
      this.noResultsFound = !options.length;
    });
  }

  private setUpdatedValue(updatedValue: unknown | unknown[]) {
    if (!isEqual(this.ngControl.value ?? this.isMultiSelect ? [] : '', updatedValue)) {
      if (!this.isMultiSelect && typeof updatedValue === 'string' && !this.findOptionByValue(updatedValue)) {
        this.appendOption({ displayName: updatedValue, value: updatedValue } as T);
      }
      this.onChanged(updatedValue);
    } else if (this.isMultiSelect && !(updatedValue as (string | number)[]).length) {
      this.onChanged([]);
    }
  }

  private _trimInputValue(value: string | T) {
    return (value ? (typeof value === 'string' ? value : value.displayName ?? '') : '').trim();
  }

  private findOptionByValue(value: unknown): T | undefined {
    return this.options.find(option => option.value === value);
  }

  private appendSingleValueOption(value: string) {
    this.appendOption({ displayName: value, value, hidden: false } as T);
  }

  private appendOption(option: T) {
    this.formFieldData = { ...this.formFieldData, options: [...(this.formFieldData.options ?? []), option] };
  }

  ngOnDestroy() {
    this.isAlive = false;
  }
}
