import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HeapAnalyticsService } from '@wc-core';
import { IncidentLane, LaneType } from '@wc/core';
import { IncidentStore, UiStore } from '@wc/core/stores';
import { getAffectedLanesSlidesByScreenWidth } from '@wc/utils';
import { difference } from 'lodash';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { Subscription } from 'rxjs';
import { BaseControlFieldComponent } from '../../base/base-control-field.component';
import { LaneMoveModalComponent } from '../lane-move-modal/lane-move-modal.component';

@Component({
  selector: 'wc-affected-lanes-slider',
  templateUrl: './affected-lanes-slider.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AffectedLanesSliderComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AffectedLanesSliderComponent),
      multi: true,
    },
  ],
  styleUrls: ['./affected-lanes-slider.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class AffectedLanesSliderComponent
  extends BaseControlFieldComponent
  implements OnInit, AfterViewInit, Validator, OnDestroy
{
  getOptions(arg0: AbstractControl) {
    throw new Error('Method not implemented.');
  }
  @ViewChild(SwiperDirective, { static: true }) swiperElm!: SwiperDirective;
  lanes: FormArray = this.fb.array([]);
  lanesForm: FormGroup = this.fb.group({ lanes: this.lanes });
  selectOptions = [...[], ...this.incidentStore.laneTypesOptions];
  laneSelectOptions = null;
  shouldUpdateSlider = false;
  shouldUpdateForm = false;
  currentSliderIndex = 0;
  innerWidth = 768;
  lanesFormSubscription?: Subscription;
  lanesSubscription?: Subscription;

  @Input() newAutocomplete = false; // to be deprecated once no need for old autocomplete
  @Input() hovered: any;
  @Input() clicked: any;
  @Input() allLanesAffected = false;
  @Input() isDirectionAutopopulated = false;
  _isOneDirection = true;
  @Input() set isOneDirection(value: boolean) {
    this._isOneDirection = value;
    if (value) {
      this.updateFormData('direction', { required: false, validators: [] });
    } else {
      this.updateFormData('direction', {
        required: true,
        validators: [Validators.required],
      });
    }

    this.initSwiperConfig();
    this.cdr.detectChanges();
  }

  @Output() laneHover = new EventEmitter();
  @Output() select = new EventEmitter<Object>();
  @Output() move = new EventEmitter<Object>();

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.initSwiperConfig();
  }

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  laneFieldData = {
    isClosed: { label: '', labelPosition: '' },
    type: {
      label: 'laneType',
      required: true,
      options: this.incidentStore.laneTypesOptions,
    },
    direction: {
      label: 'direction',
      required: true,
      options: this.incidentStore.laneDirectionsOptions,
    },
    roadType: {
      label: 'roadType',
      required: true,
      options: this.incidentStore.roadTypesOptions,
    },
  };

  config!: SwiperConfigInterface;

  constructor(
    private fb: FormBuilder,
    public uiStore: UiStore,
    public incidentStore: IncidentStore,
    private dialog: MatDialog,
    public translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private heapService: HeapAnalyticsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.lanesSubscription = this.lanesForm.controls['lanes']?.valueChanges.subscribe(selectedValue => {
      const oldDirection = this.lanesForm.value['lanes'].map(lane => lane.direction);
      if (oldDirection.length === 1) return;
      const newDirection = selectedValue.map(lane => lane.direction);

      if (oldDirection.length === newDirection.length && this._isOneDirection) {
        const oldDirection = this.lanesForm.value['lanes'].map(lane => lane.direction);
        const newDirection = selectedValue.map(lane => lane.direction);

        const diff = difference(newDirection, oldDirection);
        if (diff.length) {
          selectedValue.forEach(lane => {
            lane.direction = diff[0];
          });
          this.lanesForm.patchValue({ lanes: selectedValue });
        }
      }
      // this.fieldFormControl.setValue(selectedValue);
      // this.onChanged(this.fieldFormControl.value);
    });

    this.lanesFormSubscription = this.lanesForm.valueChanges.subscribe(changes => {
      this.fieldFormControl.setValue(changes.lanes);
      this.onChanged(this.fieldFormControl.value);
    });

    this.innerWidth = window.innerWidth;
    this.initSwiperConfig();
  }

  ngAfterViewInit() {
    this.initSwiperConfig();
  }

  initSwiperConfig() {
    let { slidesPerView, spaceBetweenSlides } = getAffectedLanesSlidesByScreenWidth(this.innerWidth, this.isTabletMode);
    slidesPerView = this.isTabletMode && this.lanes.controls.length === 1 ? 1 : slidesPerView;

    this.config = {
      slideClass: 'affected-lanes-slider-lane',
      direction: 'horizontal',
      slidesPerView: slidesPerView,
      spaceBetween: spaceBetweenSlides,
      threshold: 5,
      //allowTouchMove: this.isTabletMode,
      navigation: {
        nextEl: '.swiper-button-next-affected-lanes',
        prevEl: '.swiper-button-prev-affected-lanes',
      },
      pagination: {
        el: '.swiper-pagination-affected-lanes',
        clickable: true,
      },
      /** Disable slider arrows if there is only 1 available slide */
      watchOverflow: true,
    };

    this.swiperElm?.init();
    this.cdr.markForCheck();
  }

  validate() {
    if (this.lanesForm.invalid) {
      return { invalid: true };
    }
    return null;
  }

  deleteLane(laneIndex) {
    if (this.lanes.value.length === 1) return;
    this.lanes.removeAt(laneIndex);
    const lanesValue = this.setPositionIndexes(this.lanes.value);
    this.lanes.patchValue(lanesValue);
    this.isTabletMode ? this.initSwiperConfig() : this.swiperElm.update();
  }

  setPositionIndexes(lanes) {
    lanes.forEach((lane, index) => {
      lane.positionIndex = index;
    });
    return lanes;
  }

  trackByFn(index: number, el: any) {
    return el.value?.positionIndex;
  }

  selectLane(laneIndex) {
    const lane = this.lanes.at(laneIndex).value;
    const isAffected = !lane.isAffected;
    let isClosed = false;
    if (!isAffected) {
      isClosed = false;
    } else {
      isClosed = true;
    }
    this.lanes.at(laneIndex).patchValue({ isAffected, isClosed });

    this.heapService.trackUserSpecificAction(`heap-affected-lane-checked`, { isClosed: isClosed, type: lane.type });
  }

  /*
  index - index of the gore to move
  direction: 1 - right
             0 - left
  */
  moveGore(index, direction) {
    if (direction && index + 1 < this.lanes.length) {
      const goreItem = this.lanes.at(index);
      this.lanes.removeAt(index);
      this.lanes.insert(index + 1, goreItem);
      this.swiperElm.nextSlide();
    } else if (!direction && index - 1 >= 0) {
      const goreItem = this.lanes.at(index);
      this.lanes.removeAt(index);
      this.lanes.insert(index - 1, goreItem);
      this.swiperElm.prevSlide();
    }
    this.swiperElm?.update();
  }

  writeValue(lanesArray: Array<any>): void {
    let shouldChangeActiveSlide = false;
    if (lanesArray) {
      if (lanesArray.length > this.lanesForm.get('lanes')?.value.length || lanesArray.length > this.lanes.length) {
        shouldChangeActiveSlide = true;
        if (!this.isLaneExists(LaneType.LeftShoulder) && lanesArray.find(lane => lane.type === LaneType.LeftShoulder)) {
          this.currentSliderIndex = 0;
        } else {
          this.currentSliderIndex = this.getLastSliderIndex(lanesArray.length);
        }
      }

      this.lanes.clear();
      lanesArray.forEach((lane, index) => {
        const newLane = this.laneFormGroup({
          id: null,
          number: null,
          roadType: [null, Validators.required],
          type: [null, Validators.required],
          direction: [null, !this._isOneDirection ? Validators.required : null],
          isClosed: null,
          positionIndex: index,
          isAffected: null,
        });
        newLane.patchValue(lane);
        this.lanes.push(newLane);
        this.lanes.markAsUntouched();
      });
      this.swiperElm.update();
      setTimeout(() => {
        if (shouldChangeActiveSlide) this.swiperElm.setIndex(this.currentSliderIndex);
      });
    }
  }

  updateFormData(controlName: string, objToUpdate) {
    this.laneFieldData[controlName] = {
      ...this.laneFieldData[controlName],
      ...objToUpdate,
    };
  }

  getLastSliderIndex(lanesNumber) {
    if (this.isTabletMode) {
      return lanesNumber <= 2 ? 0 : lanesNumber - 2;
    } else {
      return lanesNumber <= 3 ? 0 : lanesNumber - 3;
    }
  }

  laneFormGroup(model): FormGroup {
    return this.fb.group(model);
  }

  laneHovered(lane, index) {
    this.laneHover.emit({ lane, index });
  }

  openLaneMoveModal(gore: FormGroup) {
    const gorePositionIndex = gore.value.positionIndex;
    const lanesArr: Array<IncidentLane> = this.lanes.value.filter(lane => lane.type !== LaneType.Gore);
    const lanesNumberArr: Array<{ displayValue: string; index: number }> = lanesArr.map((lane: IncidentLane) => {
      return {
        displayValue: lane.number || this.translateService.instant('laneTypesShort.' + lane.type),
        index: lane.positionIndex,
      };
    });
    const dialogRef = this.dialog.open(LaneMoveModalComponent, {
      disableClose: false,
      data: {
        laneNumbers: lanesNumberArr,
      },
    });
    dialogRef.afterClosed().subscribe(index => {
      if (index !== undefined) {
        if (index > gorePositionIndex) {
          this.lanes.insert(index + 1, gore);
          this.lanes.removeAt(gorePositionIndex);
        } else {
          this.lanes.removeAt(gorePositionIndex);
          this.lanes.insert(index, gore);
        }

        this.swiperElm.setIndex(index);
        this.lanes.patchValue(this.setPositionIndexes(this.lanes.value));
      }
    });
  }

  isLaneSubType(type) {
    if (type !== LaneType.Gore && type !== LaneType.LeftShoulder && type !== LaneType.RightShoulder) {
      return true;
    }
    return false;
  }

  isLaneExists(laneType: LaneType) {
    const isLane = this.lanes.value.find(lane => lane.type === laneType);
    if (isLane) return true;
    return false;
  }

  getMaxGoreIndex() {
    if (this.lanes.value[this.lanes.length - 1].type === LaneType.RightShoulder) {
      return this.lanes.length - 2;
    } else return this.lanes.length - 1;
  }

  getMinGoreIndex() {
    if (this.lanes.value[0].type === LaneType.LeftShoulder) {
      return 2;
    } else return 1;
  }

  ngOnDestroy(): void {
    this.lanesSubscription?.unsubscribe();
    this.lanesFormSubscription?.unsubscribe();
  }
}
