import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { WcBaseControlFieldModule } from '@wc/wc-ui/src/lib/base/base-control-field.module';
import { WcIconModule } from '../../components/wc-icon/wc-icon.module';
import { TextAreaComponent } from './text-area.component';

@NgModule({
  declarations: [TextAreaComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    WcBaseControlFieldModule,
    WcIconModule,
  ],
  exports: [TextAreaComponent],
})
export class TextAreaModule {}
