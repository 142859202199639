<div class="cctv-snapshots-full-screen">
  <div class="cctv-snapshots-full-screen__header">
    <span data-cy-id="gallery-title"> {{ 'files' | translate }} ({{ mediaList.length }})</span>
    <i (click)="cancel()" class="far fa-times"></i>
  </div>

  <div class="cctv-snapshots-full-screen__content" [class.edit-mode]="isEditMode">
    <div class="cctv-snapshots-full-screen__content__info" *ngIf="isSelectedMedia">
      <span data-cy-id="gallery-type-title" class="cctv-snapshots-full-screen__content__title">
        <i data-cy-id="gallery-type-icon" class="snapshot-type-icon fas fa-folder"></i>
        <span class="snapshot-type">
          {{ 'snapshotsLabels.' + getSnapshotTypeFromImageUrl(selectedMedia?.media.key) | translate }} /
        </span>
        <span class="snapshot-name">{{ selectedMedia?.id }}</span>
      </span>
      <span
        class="cctv-snapshots-full-screen__content__download-button heap-desktop-download-snapshot-file"
        (click)="downloadFile(selectedMediaUrl)">
        <i data-cy-id="download-img-icon" class="fas cctv-snapshots-full-screen__content__download-icon"></i
        ><span data-cy-id="download-img-button" class="cctv-snapshots-full-screen__content__download-button-text">{{
          'download' | translate
        }}</span>
      </span>
    </div>

    <div class="cctv-snapshots-full-screen__content__select-media" [class.edit-mode]="isEditMode">
      <ng-container *ngIf="!isEmptyState; else emptyState">
        <div class="cctv-snapshots-full-screen__content__media-viewer-container">
          <wc-media-thumbnail
            [allowVideoControls]="true"
            [renewMediaUrlFunc]="data.renewUrlFunction"
            [showNewIndication]="false"
            [customObjectFit]="'contain'"
            [mediaObj]="this.selectedMedia">
          </wc-media-thumbnail>
        </div>
      </ng-container>

      <ng-template #emptyState>
        <div class="empty-state-container">
          <i class="empty-state-icon-media"></i>
          <div class="empty-state-message">
            {{ 'emptyStates.noSnapshotsTitle' | translate }}
          </div>
          <div class="empty-state-action">
            {{ 'emptyStates.noSnapshotsText' | translate }}
          </div>
        </div>
      </ng-template>
    </div>

    <div
      class="cctv-snapshots-full-screen__content__image-swiper-list"
      [class.cctv-snapshots-full-screen__content__image-swiper-list--border]="isEditMode"
      *ngIf="mediaList.length">
      <div [swiper]="config" class="swiper-container" (keyPress)="handleSwiperKeyPressed($event)">
        <div class="swiper-wrapper">
          <ng-container *ngFor="let media of mediaList; let i = index">
            <wc-media-thumbnail
              class="swiper-slide"
              [class.active]="i === this.selectedMediaIndex"
              [isEditMode]="isEditMode"
              [isInSliderInFileModal]="true"
              [mediaObj]="media"
              [renewMediaUrlFunc]="data.renewUrlFunction"
              [snapshotLabel]="{ snapshotType: getSnapshotTypeFromImageUrl(media?.media.key), id: media?.id }"
              (click)="setMainMedia(i)"
              (mediaDeleted)="mediaDeleted(i)">
            </wc-media-thumbnail>
          </ng-container>
        </div>
      </div>

      <div class="swiper-pagination-cctv-snapshots-full-screen"></div>
      <div class="swiper-button-prev swiper-button-prev-file"></div>
      <div class="swiper-button-next swiper-button-next-file"></div>
    </div>

    <div *ngIf="isEditMode" class="cctv-snapshots-full-screen__content__action-button">
      <button (click)="cancel()" class="secondary-button">{{ 'cancel' | translate }}</button>
      <button (click)="save()" class="primary-button">{{ 'apply' | translate }}</button>
    </div>
  </div>
</div>
