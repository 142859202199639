import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import * as Enums from '@wc/core/models/enums';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShiftDialogData, ShiftsAndRoutesDialogData, ShiftStore, UiStore } from '@wc/core';
import { FormGroup } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'wc-shift-modal',
  templateUrl: './shift-modal.component.html',
  styleUrls: ['./shift-modal.component.scss'],
})
export class ShiftModalComponent implements OnInit, OnDestroy {
  _shiftFormStateEnum = Enums.ShiftFormState;
  get shiftFormStateEnum() {
    return this._shiftFormStateEnum;
  }
  shiftFormState: Enums.ShiftFormState;
  shiftData: ShiftDialogData | {};
  shiftForm!: FormGroup;
  breakTime!: string;
  interval;
  moment: any = moment;

  get isHebrew() {
    return this.uiStore.isHebrew;
  }

  constructor(
    public dialogRef: MatDialogRef<ShiftModalComponent>,
    private shiftStore: ShiftStore,
    @Inject(MAT_DIALOG_DATA) public data: ShiftsAndRoutesDialogData,
    public uiStore: UiStore
  ) {
    this.shiftFormState = this.data.shiftFormState;
    this.shiftData = data.details;
  }

  ngOnInit(): void {
    if (this.shiftFormState === this._shiftFormStateEnum.PauseEnd) {
      this.startTimer();
    }
  }

  startTimer() {
    const start = this.moment(this.shiftStore.currentShiftPause.startedAt);

    this.interval = setInterval(() => {
      const duration = moment.duration(this.moment(Date.now()).diff(start));
      const hours = duration.hours().toString();
      const minutes = duration.minutes().toString();
      const calcDuration = `${hours.length > 1 ? hours : '0' + hours}:${minutes.length > 1 ? minutes : '0' + minutes}`;

      this.breakTime = calcDuration;
    }, 1000);
  }

  closeShiftForm() {
    this.dialogRef.close();
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
