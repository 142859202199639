import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@wc/core/environments/environment';

declare let ga: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  googleAnalyticsTrackingCode!: string;

  constructor(public router: Router) {
    this.router.events.subscribe(event => {
      try {
        if (typeof ga === 'function') {
          if (event instanceof NavigationEnd) {
            ga('set', 'page', event.urlAfterRedirects);
            ga('send', 'pageview');
          }
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  init(device: 'web' = 'web') {
    this.googleAnalyticsTrackingCode = environment.googleAnalyticsTrackingCode[device];
    try {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', '${this.googleAnalyticsTrackingCode}', 'auto');
      `;
      document.head.appendChild(script);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }

  public emitEvent(eventCategory: string, eventAction: string, eventLabel?: string, eventValue?: number) {
    if (!this.googleAnalyticsTrackingCode || this.googleAnalyticsTrackingCode === '') return;
    if (typeof ga === 'function') {
      ga('send', 'event', {
        eventCategory: eventCategory,
        eventLabel: eventLabel,
        eventAction: eventAction,
        eventValue: eventValue,
      });
    }
  }
}
