import { Injectable } from '@angular/core';
import { generateFeatureID } from '@wc/wc-map-viewer/src/lib/layer-utils/feature-utils';
import { WeatherEventsStoreEntity, WeatherEventsUIEntity } from '@wc/wc-models/src';
import moment from 'moment';
import { BehaviorSubject, interval, merge } from 'rxjs';
import { delay, map, mergeAll, take, tap } from 'rxjs/operators';
import { WeatherEventStatus } from '../../../../core/models/enums';
import {
  LayerType,
  WeatherAlertsSupportedTypesGQL,
  WeatherAlertSupportedType,
  WeatherAlertUrgency,
} from '../../../../core/models/gql.models';
import { weatherSortOptionsEnum } from '../../../../core/models/models';
import { WeatherEventsQuery } from '../stores/entities/weather_events/weather_events.query';
import { CustomRxOperatorsService } from './custom-rx-operators.service';
import { EntitiesServiceV2 } from './entities.service';
import { LiveMapService } from './live-map.service';
import { LocalStorageKeys, LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class WeatherEventsService {
  private layerTypeName = LayerType.WeatherAlert;
  private SupportedTypesSubject = new BehaviorSubject<WeatherAlertSupportedType[]>([]);
  public supportedTypesSubject$ = this.SupportedTypesSubject.asObservable();
  private statusUpdateInterval$ = merge([
    this.weatherEventsQuery.modifiedWeatherEvents$.pipe(
      delay(1000),
      map(
        modifiedWeatherEvents =>
          Object.values(modifiedWeatherEvents[this.layerTypeName] || []) as WeatherEventsStoreEntity[]
      )
      /**
       * Todo: This is @ work
       */
      // tap(data => {
      //   console.log(data);
      // })
    ),
    interval(60000).pipe(map(() => this.weatherEventsQuery.getAll())),
  ]).pipe(mergeAll());

  constructor(
    private liveMapService: LiveMapService,
    private weatherEventsQuery: WeatherEventsQuery,
    private entitiesService: EntitiesServiceV2,
    private weatherAlertsSupportedTypesGQL: WeatherAlertsSupportedTypesGQL,
    private customRxOperators: CustomRxOperatorsService,
    private localStorageService: LocalStorageService
  ) {
    this.statusUpdateInterval$.subscribe(weatherEvents => {
      const uiWeatherEvents: WeatherEventsUIEntity[] = weatherEvents?.map(weatherEvent => ({
        id: weatherEvent.id,
        status: this.getWeatherEventEntityStatus(weatherEvent),
      }));
      this.entitiesService.emitNewUIDiff({ WEATHER_ALERT: uiWeatherEvents });
    });

    this.getWeatherAlertsSupportedTypes().subscribe();
    this.loadWeatherEventAsViewed();
  }

  updateWeatherEventsFPropStatus(uiWeatherEvents: WeatherEventsUIEntity[]) {
    uiWeatherEvents.forEach(uiWeatherEvent => {
      this.liveMapService.updateFeaturePropByFeatureId(
        generateFeatureID(LayerType.WeatherAlert, uiWeatherEvent.id),
        'status',
        uiWeatherEvent.status as string
      );
    });
  }

  getWeatherEventEntityStatus(weatherEvent: WeatherEventsStoreEntity): WeatherEventStatus {
    if (moment(weatherEvent.endTime).isAfter(moment.utc()) || !weatherEvent.endTime) {
      if (weatherEvent.startTime) {
        if (moment(weatherEvent.startTime).isBefore(moment.utc())) {
          return WeatherEventStatus.Active;
        } else {
          return WeatherEventStatus.Forecast;
        }
      } else {
        switch (weatherEvent.urgency) {
          case WeatherAlertUrgency.Immediate:
          case WeatherAlertUrgency.Expected:
            return WeatherEventStatus.Active;

          case WeatherAlertUrgency.Future:
          case WeatherAlertUrgency.UnknownWeatherAlertUrgency:
            return WeatherEventStatus.Forecast;
        }
      }
    }
    return WeatherEventStatus.Forecast;
  }

  getWeatherAlertsSupportedTypes() {
    return this.weatherAlertsSupportedTypesGQL.fetch().pipe(
      map(res => res?.data?.weatherAlertsSupportedTypes as WeatherAlertSupportedType[]),
      tap(res => this.SupportedTypesSubject.next(res)),
      this.customRxOperators.catchGqlErrors()
    );
  }

  loadWeatherEventAsViewed() {
    const viewedWeatherEventIds = this.localStorageService.get(LocalStorageKeys.ViewedWeatherEventsIds);
    if (viewedWeatherEventIds)
      this.entitiesService.emitNewUIDiff({
        WEATHER_ALERT: viewedWeatherEventIds.map(id => ({ id, isViewed: true })),
      });
  }

  setWeatherEventAsViewed(ids: number[]) {
    this.entitiesService.emitNewUIDiff({ WEATHER_ALERT: ids.map(id => ({ id, isViewed: true })) });

    this.weatherEventsQuery
      .selectAll({ filterBy: entity => !!this.weatherEventsQuery.ui.getEntity(entity.id)?.isViewed })
      .pipe(take(1))
      .subscribe(viewedUiEntities => {
        this.localStorageService.set(
          LocalStorageKeys.ViewedWeatherEventsIds,
          viewedUiEntities.map(viewedUiEntities => viewedUiEntities.id)
        );
      });
  }

  saveFilterSelectionToLocalStorage(weatherSortOption: weatherSortOptionsEnum) {
    this.localStorageService.set(LocalStorageKeys.WeatherSortOption, weatherSortOption);
  }

  getFilterSelectionFromLocalStorage() {
    return weatherSortOptionsEnum[this.localStorageService.get(LocalStorageKeys.WeatherSortOption)];
  }
}
