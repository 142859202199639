import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UiStore } from '@wc/core';

@Component({
  selector: 'wc-lane-move-modal',
  templateUrl: './lane-move-modal.component.html',
  styleUrls: ['./lane-move-modal.component.scss'],
})
export class LaneMoveModalComponent implements OnInit {
  laneNumbers: Array<{ displayValue: string; index: number }>;

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  constructor(
    public dialogRef: MatDialogRef<LaneMoveModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private uiStore: UiStore,
    private cdr: ChangeDetectorRef
  ) {
    this.laneNumbers = this.data.laneNumbers;
    this.cdr.markForCheck();
  }

  ngOnInit(): void {}

  handleOptionClick(laneNumber: number) {
    this.dialogRef.close(laneNumber);
  }
}
