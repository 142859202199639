<div [formGroup]="itemsForm" [class.tablet-mode]="isTabletMode">
  <ul formArrayName="items" class="editable-list-wrapper">
    <li *ngFor="let item of items.controls; let i = index" [formGroupName]="i" class="list-item-field-wrapper">
      <ng-container *ngIf="!hiddenValues?.includes(item.get('value')?.value)">
        <wc-autocomplete-v2
          class="road-form-field"
          [formControl]="$any(item.get('value'))"
          [fieldData]="autocompleteData"
          [allowFreeText]="!allowOptionValueOnly"
          [optionsList]="autoCompleteOptions"
          (inputBlurred)="itemBlurred($event, i)"></wc-autocomplete-v2>
        <button
          *ngIf="items.controls.length !== 1"
          class="button-remove"
          mat-icon-button
          matSuffix
          (click)="deleteItem(i)">
          <i class="fas fa-trash-alt"></i>
        </button>
      </ng-container>
    </li>
  </ul>
</div>
