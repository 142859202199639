import { MVT } from 'ol/format';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { Stroke, Style } from 'ol/style';

export function trafficLayer(mapboxKey: string): VectorTileLayer {
  const urlTemplate = `https://{a-d}.tiles.mapbox.com/v4/mapbox.mapbox-traffic-v1/{z}/{x}/{y}.mvt?access_token=${mapboxKey}`;
  const layer = new VectorTileLayer({
    declutter: true,
    source: new VectorTileSource({
      cacheSize: 0,
      format: new MVT(),
      // attributions: ['© <a href="https://www.mapbox.com/about/maps" target="_blank">Mapbox</a>'],
      url: urlTemplate,
    }),
    style: feature => {
      let color = '#e0e4dd';
      let width = 1;

      const congestion = feature.get('congestion');

      switch (congestion) {
        case 'low':
          color = '#63d668';
          width = 2;
          break;
        case 'moderate':
          color = '#ffe500';
          width = 3;
          break;
        case 'heavy':
          color = '#ff8300';
          width = 4;
          break;
        case 'severe':
          color = '#ff0000';
          width = 5;
      }

      const line: Style = new Style({
        stroke: new Stroke({
          color: color,
          width: width,
        }),
      });

      return line;
    },
  });

  return layer;
}
