import { FormArray, FormGroup } from '@angular/forms';

export const Utils = {
  convertArrayToObject: (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  },
};

export function markNestedControlsAsTouched(form: FormGroup | FormArray) {
  Object.values(form.controls).forEach(control => {
    control.markAllAsTouched();
    control.updateValueAndValidity();
    if (control instanceof FormArray || control instanceof FormGroup) {
      markNestedControlsAsTouched(control);
    }
  });
}

export function removeNestedControlsErrors(form: FormGroup | FormArray) {
  Object.values(form.controls).forEach(control => {
    control.setErrors(null);
    control.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    if (control instanceof FormArray || control instanceof FormGroup) {
      removeNestedControlsErrors(control);
    }
  });
}
