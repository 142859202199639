import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UiStore } from '@wc/core';
import { sortObjectArrayByKey } from '@wc/utils/sortObjectArrayByKey';
import { BaseControlFieldComponent } from '../../base/base-control-field.component';
import { SelectOption } from '../../form-controls/form-models';

@Component({
  selector: 'wc-select-chips',
  templateUrl: './select-chips.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectChipsComponent),
      multi: true,
    },
    FormControl,
  ],
  styleUrls: ['./select-chips.component.scss'],
})
export class SelectChipsComponent extends BaseControlFieldComponent implements AfterViewInit {
  sortOptions;
  isTabletMode = true;

  @Output() chipsChange: EventEmitter<string> = new EventEmitter();
  @Input() hiddenValues: Array<number | string> = [];

  constructor(private uiStore: UiStore) {
    super();
    this.sortOptions = sortObjectArrayByKey;
  }

  valueSelected(option: SelectOption) {
    const controlValues = [...[], ...this.fieldFormControl.value];
    const index = controlValues.findIndex(val => val === option.value);
    if (index > -1) {
      controlValues.splice(index, 1);
    } else {
      controlValues.push(option.value);
    }

    this.fieldFormControl.setValue(controlValues);
    this.chipsChange.emit(this.fieldFormControl.value);
    this.changed();
  }

  ngAfterViewInit() {
    this.isTabletMode = this.uiStore.isTabletMode;
  }

  isOptionActive(option: SelectOption) {
    const controlValues: [] = this.fieldFormControl.value;
    const index = controlValues.findIndex(val => val === option.value);
    return index !== -1;
  }

  updateOptions(options: SelectOption[]) {
    // Allows externally change the options list
    this.fieldData.options = options;
    this.fieldFormControl.setValue(this.fieldFormControl.value);
    this.chipsChange.emit(this.fieldFormControl.value);
  }
}
