<ng-container *ngIf="!isOnline">
  <div class="offline-mode" [class.tablet-mode]="isTabletMode">
    <img src="assets/images/offline-camera.svg" />
    <span class="caption">{{ 'emptyStates.offlineCameraTitle' | translate }}</span>
    <span class="caption">{{ 'emptyStates.offlineCameraMessage' | translate }}</span>
  </div>
</ng-container>
<ng-container *ngIf="isOnline">
  <mat-tab-group
    data-cy-id="camera-tab-group"
    class="camera-tab-group"
    [selectedIndex]="selectedCameraTab"
    (selectedIndexChange)="selectedTabIndex($event)">
    <mat-tab [label]="('cctv' | translate) + ' (' + cameras.length + ')'">
      <section class="camera-section" *ngIf="cameras.length; else noCamera" [class.tablet-mode]="isTabletMode">
        <ng-container *ngIf="appType !== 'widget'">
          <div data-cy-id="camera-title" class="camera-title-row">
            <h2 class="camera-title">
              <span class="camera-address">{{ selectedCamera?.title }}</span>
              <span class="main-camera-badge" *ngIf="defaultCamera?.id === selectedCamera?.id">
                {{ 'mainCamera' | translate }}
              </span>
            </h2>

            <button
              data-cy-id="take-snapshot-button"
              class="take-snapshot-button"
              *ngIf="showTakeSnapshotButton"
              rekor-basic
              leftSideIcon="snapshot"
              [disabled]="isTakeSnapshotDisabled || isSelectedCameraHasIssues"
              (click)="takeSnapshot()"
              wcTooltipPlacement="bottom"
              [wcTooltipDisabled]="!isTakeSnapshotDisabled && !isSelectedCameraHasIssues"
              [wcTooltipText]="
                isSelectedCameraHasIssues ? ('cameraHasIssues' | translate) : ('maxMediaCapacity' | translate)
              ">
              {{ 'takeSnapshot' | translate }}
            </button>

            <button
              class="set-main-camera-button"
              data-cy-id="incident-set-main-camera-btn-desktop"
              *ngIf="!readMode && defaultCamera?.id !== selectedCamera?.id"
              (click)="setMainCamera()">
              {{ 'setAsMainCamera' | translate }}
            </button>
          </div>
          <wc-camera-viewer
            #cameraViewer
            *ngIf="selectedCamera && selectedCameraTab === cameraTabEnum.CCTV"
            [isTabletMode]="isTabletMode"
            [contextType]="cameraContextType.IncidentCameras"
            [camera]="selectedCamera"
            [entityId]="entityId"
            [defaultCameraId]="defaultCamera?.id"
            (snapshotUploaded)="updateEntityView($event)"
            (cameraStatusUpdated)="updateCameraStatus($event)">
          </wc-camera-viewer>

          <div class="camera-controls" *ngIf="!readMode">
            <div class="search-camera-container">
              <div class="cameras-section-line" *ngIf="!isAddCameraMode">
                <span data-cy-id="near-by-cameras-title" class="search-camera-title"
                  >{{ 'nearbyCameras' | translate }} ({{ cameras.length }})</span
                >

                <div
                  data-cy-id="add-camera-button-desktop-nested"
                  class="add-camera-button"
                  (click)="showSearchCamera()">
                  <i class="button-add-icon fas fa-plus"></i>
                  <span>{{ 'add' | translate }}</span>
                </div>
              </div>

              <form *ngIf="isAddCameraMode && isTabletMode">
                <mat-form-field floatLabel="never">
                  <input
                    type="text"
                    id="searchCamera"
                    [placeholder]="'searchCameraPlaceholder' | translate"
                    aria-label="Number"
                    matInput
                    [formControl]="$any(searchCameraControl)"
                    [matAutocomplete]="auto" />
                  <button mat-icon-button matSuffix class="cameras-search-close-button" (click)="clear()">
                    <i class="fas fa-times"></i>
                  </button>
                  <mat-autocomplete class="autocomplete-select-dropdown" #auto="matAutocomplete">
                    <mat-option
                      class="autocomplete-option"
                      [class.tablet-mode]="isTabletMode"
                      *ngFor="let camera of filteredRelatedCameras | async"
                      [value]="camera?.title"
                      (onSelectionChange)="addCamera(camera, $event)">
                      {{ camera?.title }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </form>

              <wc-autocomplete
                *ngIf="isAddCameraMode && !isTabletMode"
                [formControl]="searchCameraControl"
                data-cy-id="incident-search-camera-textbox-desktop"
                [formFieldData]="{
                  options: cameraOptions,
                  label: 'cameras',
                  placeholder: 'searchCameraPlaceholder'
                }"
                [showLabel]="false"
                (onOptionSelected)="addCamera($event)">
              </wc-autocomplete>
            </div>
          </div>
          <wc-camera-slider
            *ngIf="selectedCameraTab === cameraTabEnum.CCTV"
            [isTabletMode]="isTabletMode"
            [cameraTab]="selectedCameraTab"
            [cameras]="cameras"
            [readMode]="readMode"
            [defaultCamera]="defaultCamera"
            (cameraSelected)="selectCamera($event)"
            (cameraRemoved)="removeCamera($event)">
          </wc-camera-slider>
        </ng-container>

        <ng-container *ngIf="appType === 'widget'">
          <wc-camera-gallery
            [selectedCameraId]="selectedCameraId"
            (cameraSelected)="selectCamera($event)"
            [galleryData]="{cameras, defaultCamera}"></wc-camera-gallery>
        </ng-container>
      </section>
    </mat-tab>
    <mat-tab
      *ngIf="(appType !== 'widget' || (appType === 'widget' && dashCams.length)) && !hideDashcams"
      [disabled]="!dashCams.length || !readMode"
      [label]="('dashCams' | translate) + ' (' + dashCams.length + ')'">
      <ng-template mat-tab-label>
        <label
          matTooltipHideDelay="500"
          matTooltipShowDelay="500"
          [matTooltipDisabled]="dashCams.length > 0 && readMode"
          [matTooltip]="(readMode ? 'noDashCams' : 'dashCamsNotEditable') | translate"
          matTooltipClass="dash-cam-tooltip"
          class="dash-cam-tab-label">
          {{ ('dashCams' | translate) + ' (' + dashCams.length + ')' }}
        </label>
      </ng-template>
      <section class="camera-section" *ngIf="dashCams.length; else noCamera" [class.tablet-mode]="isTabletMode">
        <div class="camera-title-row">
          <h2 class="camera-title">
            <span class="camera-address">{{ selectedDashCam?.title }}</span>
          </h2>
        </div>

        <wc-camera-viewer
          *ngIf="selectedDashCam && selectedCameraTab === cameraTabEnum.Dash"
          [isTabletMode]="isTabletMode"
          [contextType]="cameraContextType.IncidentCameras"
          [camera]="selectedDashCam">
        </wc-camera-viewer>

        <wc-camera-slider
          *ngIf="selectedCameraTab === cameraTabEnum.Dash"
          [isTabletMode]="isTabletMode"
          [cameraTab]="selectedCameraTab"
          [dashCams]="dashCams"
          [defaultCamera]="dashCams[0]"
          [readMode]="readMode"
          (cameraSelected)="selectCamera($event)"
          (cameraRemoved)="removeCamera($event)">
        </wc-camera-slider>
      </section>
    </mat-tab>
  </mat-tab-group>

  <ng-template #noCamera>
    <section class="camera-section" [class.tablet-mode]="isTabletMode">
      <div
        data-cy-id="no-camera-container"
        class="no-camera-container"
        *ngIf="appType !== 'widget'; else noCameraWidget">
        <i data-cy-id="no-camera-img" class="empty-cameras"></i>
        <div class="no-camera-title">
          {{ 'noCamerasAddedYet' | translate }}
        </div>
        <div>
          {{ (readMode ? 'editModeAddCameraFromTheList' : 'addCameraFromTheList') | translate }}
        </div>
      </div>

      <ng-template #noCameraWidget>
        <div data-cy-id="no-camera-container" class="no-camera-container widget" *ngIf="appType === 'widget'">
          <img src="assets/images/widget-no-camera.svg" alt="no camera image" />
          <div class="no-camera-title">
            {{ 'widget.cameraSection.noCamera' | translate }}
          </div>
        </div>
      </ng-template>

      <div class="camera-controls" *ngIf="!readMode">
        <div class="search-camera-container">
          <div class="cameras-section-line" *ngIf="!isAddCameraMode">
            <span class="search-camera-title"></span>

            <div data-cy-id="add-camera-button-desktop-nested" class="add-camera-button" (click)="showSearchCamera()">
              <i class="button-add-icon fas fa-plus"></i>
              <span>{{ 'add' | translate }}</span>
            </div>
          </div>

          <form *ngIf="isAddCameraMode && isTabletMode">
            <mat-form-field floatLabel="never">
              <input
                type="text"
                [placeholder]="'searchCameraPlaceholder' | translate"
                aria-label="Number"
                matInput
                [formControl]="$any(searchCameraControl)"
                [matAutocomplete]="auto" />
              <button mat-icon-button matSuffix (click)="clear()">
                <i class="fas fa-times"></i>
              </button>
              <mat-autocomplete class="autocomplete-select-dropdown" #auto="matAutocomplete">
                <mat-option
                  class="autocomplete-option"
                  [class.tablet-mode]="isTabletMode"
                  *ngFor="let camera of filteredRelatedCameras | async"
                  [value]="camera?.title"
                  (onSelectionChange)="addCamera(camera)">
                  {{ camera?.title }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>
          <wc-autocomplete
            *ngIf="isAddCameraMode && !isTabletMode"
            [formControl]="searchCameraControl"
            data-cy-id="incident-search-camera-textbox-desktop"
            [formFieldData]="{
              options: cameraOptions,
              label: 'cameras',
              placeholder: 'searchCameraPlaceholder'
            }"
            [showLabel]="false"
            (onOptionSelected)="addCamera($event)">
          </wc-autocomplete>
        </div>
      </div>
    </section>
  </ng-template>
</ng-container>
