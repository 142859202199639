export const MOCK_DATA = {
  roadTypes: [
    {
      displayName: 'freeway',
      value: 'freeway',
    },
    {
      displayName: 'highway',
      value: 'highway',
    },
    {
      displayName: 'street',
      value: 'street',
    },
    {
      displayName: 'on ramp',
      value: 'on ramp',
    },
    {
      displayName: 'off ramp',
      value: 'off ramp',
    },
    {
      displayName: 'bridge',
      value: 'bridge',
    },
  ],
  laneNumber: [
    {
      displayName: '1',
      value: 1,
    },
    {
      displayName: '2',
      value: 2,
    },
    {
      displayName: '3',
      value: 3,
    },
    {
      displayName: '4',
      value: 4,
    },
    {
      displayName: '5',
      value: 5,
    },
    {
      displayName: '6',
      value: 6,
    },
    {
      displayName: '7',
      value: 7,
    },
  ],
  laneDirection: [
    {
      displayName: 'North',
      value: 'North',
    },
    {
      displayName: 'South',
      value: 'South',
    },
    {
      displayName: 'East',
      value: 'East',
    },
    {
      displayName: 'West',
      value: 'West',
    },
  ],
  orientationOptions: [
    {
      displayName: 'at',
      value: 'at',
    },
    {
      displayName: 'before',
      value: 'before',
    },
    {
      displayName: 'after',
      value: 'after',
    },
    {
      displayName: 'under',
      value: 'under',
    },
    {
      displayName: 'over',
      value: 'over',
    },
  ],
  crossroadOptions: [
    { value: '1', displayName: '6' },
    { value: '2', displayName: '11' },
    { value: '3', displayName: '15' },
    { value: '4', displayName: '78' },
    { value: '5', displayName: '93' },
    { value: '6', displayName: '104' },
  ],
  corridorOptions: [
    { value: '1', displayName: 'Averbuch' },
    { value: '2', displayName: 'Ave 7' },
    { value: '3', displayName: 'Amnimia' },
    { value: '4', displayName: 'Aviliona' },
    { value: '5', displayName: 'Obama' },
  ],
  incidentStatusOptions: [
    {
      value: 'ONGOING',
      displayName: 'Ongoing',
      icon: 'assets/icons/email.svg',
    },
    {
      value: 'COMPLETED',
      displayName: 'Completed',
      icon: 'assets/icons/email.svg',
    },
    {
      value: 'FRAFT',
      displayName: 'Quick draft',
      icon: 'assets/icons/email.svg',
    },
  ],
};

export const mapSearchMock = [
  {
    id: 5767,
    location: {
      coordinates: [-115.179496, 36.0349, 0.0],
      type: 'Point',
    },
    workspaces: [2, 3, 4, 507, 69, 334, 1, 336, 67, 7, 8, 9, 10, 133, 333, 100, 68, 525],
    externalId: 'nv-d1-123',
    title: '123 I-15 NB Blue Diamond South',
    status: 'Active',
    address: {
      point: {
        coordinates: [-115.179496, 36.0349, 0.0],
        type: 'Point',
      },
      segmentId: 'UNKNOWN_SEGMENT',
      corridor: null,
      direction: null,
      crossroad: null,
      orientation: null,
      roadType: null,
      roadLevelType: null,
      state: null,
      county: null,
      city: null,
      milemarker: null,
    },
    imageUrl: 'https://images.waycare.io/api/v1/camera?camera_id=nv-d1-123',
    videoUrl: 'https://wowza2.nvfast.org:443/bmw5/i-15_nb_blue_diamond_south_public.stream/playlist.m3u8',
    streamType: 'VIDEO_STREAM',
    layerType: 'CAMERA',
  },
  {
    id: 1641,
    location: {
      coordinates: [-115.210297, 36.215698, 0.0],
      type: 'Point',
    },
    workspaces: [2, 3, 4, 69, 334, 1, 336, 67, 7, 8, 9, 10, 133, 333, 100, 68, 335, 508],
    externalId: 'fast_lv_101',
    title: '1469 Rancho SB at Mossman',
    status: 'Active',
    address: {
      point: {
        coordinates: [-115.210297, 36.215698, 0.0],
        type: 'Point',
      },
      segmentId: 'UNKNOWN_SEGMENT',
      corridor: null,
      direction: null,
      crossroad: null,
      orientation: null,
      roadType: null,
      roadLevelType: null,
      state: null,
      county: null,
      city: null,
      milemarker: null,
    },
    editable: false,
    messages: [['', '', '']],
    lastUpdated: '2021-10-27T18:30:22.284852Z',
    layerType: 'DMS',
  },
  {
    id: 213361,
    location: {
      coordinates: [-115.26361966474188, 36.28539703650611, 0.0],
      type: 'Point',
    },
    workspaces: [2, 3, 4, 69, 334, 1, 67, 7, 8, 9, 10, 133, 333, 100, 68, 335],
    address: {
      point: {
        coordinates: [-115.26361966474188, 36.28539703650611, 0.0],
        type: 'Point',
      },
      segmentId: null,
      corridor: 'fdf',
      direction: null,
      crossroad: '',
      orientation: null,
      roadType: 'OFF_RAMP',
      roadLevelType: null,
      state: null,
      county: null,
      city: null,
      milemarker: null,
    },
    type: 'CCTV_MAINTENANCE',
    otherTypeDescription: 'cctv_maintenance',
    title: 'fdf',
    status: 'ACTIVE',
    lanesClosureType: 'PARTIAL',
    scheduled: false,
    startTime: '2021-10-08T19:44:03.103Z',
    allLanesAffected: false,
    multiDirectionLanesAffected: false,
    layerType: 'CONSTRUCTION',
  },

  {
    id: 3315237,
    location: {
      coordinates: [-115.243408, 36.021716, 0.0],
      type: 'Point',
    },
    workspaces: [2, 3, 4, 438, 69, 334, 1, 336, 7, 8, 9, 10, 133, 333, 100, 68, 508, 525],
    type: 'crash',
    startedAt: '2021-10-30T23:04:50Z',
    address: {
      point: {
        coordinates: [-115.243408, 36.021716, 0.0],
        type: 'Point',
      },
      segmentId: 'dbacacf1-bb97-5631-996f-e39f3d1d789b',
      corridor: 'Rainbow Boulevard',
      direction: null,
      crossroad: 'Blue Diamond Road',
      orientation: 'AT',
      roadType: 'SECONDARY',
      roadLevelType: 'STREET',
      state: 'Nevada',
      county: 'Clark County',
      city: null,
      milemarker: null,
    },
    associatedUnits: [],
    affectedLanes: [],
    creationSource: 'PLATFORM',
    status: 'CONFIRMED',
    allLanesAffected: false,
    multiDirectionLanesAffected: false,
    mitigatedAccounts: [
      {
        value: 1,
      },
    ],
    appended: false,
    layerType: 'INCIDENT',
  },
  {
    id: 221866,
    location: {
      coordinates: [-115.2075307027702, 36.1814693337525, 0.0],
      type: 'Point',
    },
    workspaces: [2, 3, 4, 69, 334, 1, 336, 67, 7, 8, 9, 10, 133, 333, 100, 68, 335, 508],
    address: {
      point: {
        coordinates: [-115.2075307027702, 36.1814693337525, 0.0],
        type: 'Point',
      },
      segmentId: 'bf7f742d-cede-550f-933a-2d361d1a5399',
      corridor: 'Cannon Circle',
      direction: null,
      crossroad: 'Yale Street',
      orientation: 'AT',
      roadType: 'STREET',
      roadLevelType: 'STREET',
      state: 'Nevada',
      county: 'Clark County',
      city: 'Las Vegas',
      milemarker: null,
    },
    title: 'Cannon Circle at Yale Street',
    status: 'ACTIVE',
    lanesClosureType: 'PARTIAL',
    scheduled: false,
    startTime: '2021-11-01T08:39:57.419Z',
    allLanesAffected: false,
    multiDirectionLanesAffected: false,
    layerType: 'ROAD_CLOSURE',
  },
];
