import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AppVersion } from '@wc/wc-core/src/lib/app-version';

@Component({
  selector: 'wc-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportComponent {
  private _textDirection: 'rtl' | 'ltr' = 'ltr';
  appVersion = AppVersion;

  @Input() set textDirection(value: 'rtl' | 'ltr') {
    this._textDirection = value;
  }
  get textDirection() {
    return this._textDirection;
  }

  @Output() closeButton = new EventEmitter<void>();

  openHelpCenter() {
    window.open('https://help.rekor.ai/rekor-command', '_blank');
  }

  closeButtonClicked(e) {
    e?.stopPropagation();
    this.closeButton.emit();
  }
}
