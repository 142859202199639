<div class="unit-dashcams-component" [class.tablet-mode]="isTabletMode">
  <div class="swiper-component">
    <div class="swiper-container" [swiper]="dashCamsSwiperConfig">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let camera of dashCameras">
          <wc-camera-viewer [camera]="camera" [imageReloadInterval]="imageReloadInterval" [isTabletMode]="isTabletMode">
          </wc-camera-viewer>
        </div>
      </div>

      <div
        data-cy-id="dashcam-swiper-pagination"
        class="swiper-pagination-dashcams swiper-pagination"
        *ngIf="showPagination"></div>
    </div>

    <div data-cy-id="dashcam-swiper-button-prev" class="swiper-button-prev dashcam-swiper-button-prev"></div>
    <div data-cy-id="dashcam-swiper-button-next" class="swiper-button-next dashcam-swiper-button-next"></div>
  </div>
</div>
