<div #mapContainer id="map-viewer-container"></div>

<ng-container *ngIf="(mapReady$ | async) === true">
  <wc-map-buttons-container class="map-modules-button-container" [class]="appType">
    <mat-button-toggle-group [value]="mapModulesBtnSelectedValue" name="mapModules" vertical="true">
      <ng-container *ngFor="let mapModuleBtnConfig of mapModuleBtnConfigs">
        <ng-container *wcLet="!!(mapModuleBtnConfig.enabled | async) as isEnabled">
          <span *ngIf="!!mapModuleBtnConfig?.isDivider && isEnabled">
            <div class="border-line"></div>
          </span>

          <span #mapModuleBtn *ngIf="isEnabled">
            <mat-button-toggle
              [attr.data-cy-id]="'map-button-' + mapModuleBtnConfig.name"
              [ngClass]="mapModuleBtnConfig.name + ' map-icon-button-container ' + mapModuleBtnConfig.heapClass"
              [class.is-opened-layer]="mapModulesBtnSelectedValue === mapModuleBtnConfig.name"
              [class.coming-soon]="!isEnabled"
              (change)="mapModulesBtnChange($event)"
              [value]="mapModuleBtnConfig.name"
              [disabled]="!isEnabled"
              [wcTooltipText]="'mapModulesNames.' + mapModuleBtnConfig.name | translate"
              wcTooltipPlacement="left">
              <img *ngIf="!isEnabled" [src]="mapModuleBtnConfig.comingSoonSrcImg" />
              <wc-icon *ngIf="isEnabled" [iconName]="mapModuleBtnConfig.iconName || ''"></wc-icon>
              <span
                *ngIf="(mapModuleBtnConfig.badgeSrcValue | async)?.length > 0"
                class="notification-badge"
                [class.active]="mapModulesBtnSelectedValue === mapModuleBtnConfig.name">
                {{ (mapModuleBtnConfig.badgeSrcValue | async).length }}
              </span>
            </mat-button-toggle>
          </span>
        </ng-container>
      </ng-container>
    </mat-button-toggle-group>
  </wc-map-buttons-container>

  <wc-map-buttons-container class="static-layers-buttons-container">
    <wc-map-milemarker-button
      [staticLayerOptionName]="staticLayerButtons.mileMarker"
      [activeState]="!!staticMapLayersStates[staticLayerButtons.mileMarker]"
      (statusChanged)="changeStaticMapLayerStatus($event)"></wc-map-milemarker-button>
    <wc-map-traffic-button
      [staticLayerOptionName]="staticLayerButtons.trafficButton"
      [activeState]="!!staticMapLayersStates[staticLayerButtons.trafficButton]"
      (statusChanged)="changeStaticMapLayerStatus($event)"></wc-map-traffic-button>
    <wc-map-satellite-button
      [staticLayerOptionName]="staticLayerButtons.satelliteButton"
      [activeState]="!!staticMapLayersStates[staticLayerButtons.satelliteButton]"
      (statusChanged)="changeStaticMapLayerStatus($event)"></wc-map-satellite-button>
  </wc-map-buttons-container>
</ng-container>

<div class="map-module-panel-container" [class]="appType" *ngIf="!!mapModulesBtnSelectedValue">
  <ng-container [ngSwitch]="mapModulesBtnSelectedValue">
    <span *ngSwitchCase="MapModuleNames.BASIC_LAYERS">
      <wc-layer-tree-panel
        class="heap-desktop-map-layer-panel-btn"
        [isAllWorkspacesUnchecked]="!!(isAllWorkspaceUnchecked$ | async)"
        [isPortraitDesktopMode]="isPortraitDesktopMode"
        (hidePanel)="closePanel(MapModuleNames.BASIC_LAYERS)">
        <wc-work-spaces-menu
          [appType]="appType"
          [workspacesArray]="availableWorkspaces$ | async"
          (workspaceSelection)="workspaceSelection($event)"></wc-work-spaces-menu>
      </wc-layer-tree-panel>
    </span>

    <span *ngSwitchCase="MapModuleNames.TRANSIT">
      <wc-transit-panel
        (hidePanel)="closePanel()"
        [accountId]="accountId"
        [isPortraitDesktopMode]="isPortraitDesktopMode"
        (filterChange)="changeFilter('transitIsMyAgency', $event)"
        (layersVisibilityStateChange)="updateTransitLayersVisibility($event)"></wc-transit-panel>
    </span>

    <span *ngSwitchCase="MapModuleNames.WEATHER_EVENTS">
      <wc-weather-events-panel
        (layersVisibilityStateChange)="setLayersVisibilityState($event)"
        (filterChange)="changeFilter('weatherStatus', $event)"
        (selected)="selectFeatureAndOverlay($event, layerTypes.WeatherAlert)"
        (hidePanel)="closePanel()"
        [currentMapFilters]="currentMapFilters.weatherStatus"
        [activeWeatherEvent]="activeWeatherEvent"
        [isPortraitDesktopMode]="isPortraitDesktopMode"
        [isDirectionRtl]="isDirectionRtl"
        [regionalSetting]="regionalSetting"
        [weatherEvents]="weatherEvents$ | async"
        [weatherStoreItems]="weatherStoreItems$ | async"></wc-weather-events-panel>
    </span>

    <span *ngSwitchCase="MapModuleNames.CRASH_RISK">
      <wc-crash-risk-panel
        (hidePanel)="closePanel()"
        (selected)="selectFeatureAndOverlay($event, layerTypes.CrashRiskArea)"
        (layersVisibilityStateChange)="setLayersVisibilityState($event)"
        (filterChange)="changeFilter('crashRiskStatus', $event)"
        [isPortraitDesktopMode]="isPortraitDesktopMode"
        [regionalSetting]="regionalSetting"
        [crashRiskListByTimeFrames]="crashRiskListByTimeFrames$ | async"
        [crashRiskItem]="crashRiskItem$ | async"></wc-crash-risk-panel>
    </span>

    <span *ngSwitchCase="MapModuleNames.ROADWAY_STATUS">
      <wc-roadway-status-panel
        (selected)="selectFeatureAndCenterMap($event, layerTypes.RoadwayStatus, false)"
        (layersVisibilityStateChange)="setLayersVisibilityState([$event])"
        (hidePanel)="closePanel(MapModuleNames.ROADWAY_STATUS)"
        [setSelectedSegmentDetails]="$any(selectedPanelAdditionalItems)"
        [regionalSetting]="regionalSetting"
        [roadwayStatusItem]="roadwayStatusItem | async"
        [isDirectionRtl]="isDirectionRtl"
        [isHebrew]="isHebrew"
        [isPortraitDesktopMode]="isPortraitDesktopMode"
        [measureUnitType]="regionalSetting.measureUnitType"
        [segments]="(roadwayStatusSegments$ | async) || []">
      </wc-roadway-status-panel>
    </span>
  </ng-container>
</div>
