import { datadogLogs } from '@datadog/browser-logs';
import { Mutex } from 'async-mutex';
import { isObservable, Observable, Subject } from 'rxjs';
import { pairwise } from 'rxjs/operators';
import { randomString } from '../../utils';
import { UploadEntityType } from '../models/models';
import { CacheService, DataBase } from './cache.service';
import { WindowService } from './window.service';
export enum RequestType {
  'create' = 'create',
  'update' = 'update',
}
export interface OfflineUpdateRequest {
  action: Function | string;
  type?: RequestType;
  params: any[];
  onError: Function;
  getId?: Function;
  setId?: Function;
  hasError?: boolean;
}

/**
 * supported offline requests
 *  common enum for all services(inicddent and workRequests)
 * */
export enum OfflineRequests {
  createIncident = 'createIncident',
  updateIncident = '_updateIncidentOneTime',
  completeIncident = 'completeIncident',
  undoCompleteIncident = 'undoCompleteIncident',
  confirmIIncident = 'confirmIIncident',
  rejectIncident = 'rejectIncident',
  undoRejectIncident = 'undoRejectIncident',
  updateIncidentUnitResponse = 'updateIncidentUnitResponse',
  updateIncidentMitigations = 'updateIncidentMitigations',
  updateShift = 'updateShift',
  assignUnitToUser = 'assignUnitToUser',
  _createWorkRequest = '_createWorkRequest',
  activateWorkRequest = 'activateWorkRequest',
  inactivateWorkRequest = 'inactivateWorkRequest',
  _updateWorkRequestOneTime = '_updateWorkRequestOneTime',
  _completeWorkRequest = '_completeWorkRequest',
  assignUserToWorkRequest = 'assignUserToWorkRequest',
  unassignUserToWorkRequest = 'unassignUserToWorkRequest',
  restoreWorkRequest = 'restoreWorkRequest',
}

export interface FileDescriptor {
  data: File;
  inProgress: boolean;
  progress: number;
  entityId: string;
  entityType: UploadEntityType;
}

export abstract class OfflineService {
  protected windowService: WindowService;
  protected cacheService: CacheService;

  offlineUpdateQueue: OfflineUpdateRequest[] = [];

  updateRequestComplete$: Subject<any> = new Subject();
  createRequestComplete$: Subject<any> = new Subject();
  flushMutex: Mutex = new Mutex();
  abstract nameSpace: string;

  constructor(windowService: WindowService, cacheService: CacheService) {
    this.windowService = windowService;
    this.cacheService = cacheService;
    this.windowService.online$.pipe(pairwise()).subscribe(([prevStatus, currentStatus]) => {
      // Transition from offline to online
      if (prevStatus === false && currentStatus === true) {
        this.flushRequestQueue();
      }
    });

    //this.loadOfflineUpdateQueue();
  }

  get cacheKey(): string {
    return `${this.nameSpace}-offlineUpdateQueue`;
  }

  async loadOfflineUpdateQueue() {
    this.offlineUpdateQueue = JSON.parse(
      (await this.cacheService.get(DataBase.offlineCache, this.cacheKey)) || '[]',
      (Function as any).deserialise
    );
  }

  async saveOfflineUpdateQueue() {
    await this.cacheService.put(DataBase.offlineCache, this.cacheKey, JSON.stringify(this.offlineUpdateQueue));
  }

  abstract canUpdate(updateRequest: OfflineUpdateRequest): boolean;
  abstract getIdFromObject(updateRequest: OfflineUpdateRequest);
  abstract setIdToObject(updateRequest: OfflineUpdateRequest, newId: string);

  getStringId = (updateRequest: OfflineUpdateRequest) => updateRequest.params[0];
  setStringId = (updateRequest: OfflineUpdateRequest, newId: string) => {
    updateRequest.params = [newId];
  };

  static generateID() {
    return `temp-${Date.now().toString().slice(7, -1)}`;
  }

  isValidId(id: any) {
    return !(typeof id === 'string' && id.indexOf('temp') === 0);
  }

  async loadOfflineCache(key: string): Promise<Map<string, any>> {
    const value = await this.cacheService.get(DataBase.offlineCache, key);
    return new Map(value);
  }

  async storeOfflineCache(key: string, map: Map<string, any>) {
    this.cacheService.put(DataBase.offlineCache, key, [...map]);
  }

  async replaceId(oldId: string, newId: string, idName = 'incidentId') {
    try {
      const updateRequests: Array<OfflineUpdateRequest> = this.offlineUpdateQueue.filter(updateRequest => {
        return (
          updateRequest.params[0] &&
          (updateRequest.params[0]?.[idName] === oldId || updateRequest.params[0].id === oldId)
        );
      });
      updateRequests.forEach(request => {
        request.setId?.apply(this, [request, newId]);
      });

      const res = await this.cacheService.find(DataBase.offlineUploads, 'upload-');
      if (res) {
        for (const row of res.rows) {
          const fileDescriptor: FileDescriptor = row?.doc?.object;
          const attachment: PouchDB.Core.FullAttachment = row.doc?._attachments?.file as PouchDB.Core.FullAttachment;
          const fileToUpload: File = new File([attachment.data], fileDescriptor.data.name, {
            type: attachment.content_type,
          });

          if (fileDescriptor.entityId === oldId) {
            fileDescriptor.entityId = newId;
            const _id = row?.doc?._id;
            const _rev = row?.doc?._rev;
            if (_id && _rev) this.cacheService.remove(DataBase.offlineUploads, _id, _rev);
            this.cacheService
              .put(DataBase.offlineUploads, `upload-${randomString(8)}`, fileDescriptor, {
                file: {
                  content_type: fileToUpload.type,
                  data: fileToUpload,
                },
              })
              .then(res => {
                this.windowService.renewIncidentId$.next(Object.assign({ id: newId }));
              })
              .catch(err => {
                console.error(err);
              });
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
    if (this.offlineUpdateQueue.length) this.flushRequestQueue();
  }

  async flushRequestQueue() {
    await this.flushMutex.acquire();
    const requestsToUpdate = this.offlineUpdateQueue.filter(
      updateRequest => this.canUpdate(updateRequest) && updateRequest.type !== RequestType.create
    );
    const requestsToCreate = this.offlineUpdateQueue.filter(updateRequest => updateRequest.type === RequestType.create);

    // console.log(requestsToUpdate);
    // console.log(requestsToCreate);

    let createResponse;
    for (const createRequest of requestsToCreate) {
      try {
        if (this[`${createRequest.action}`]) {
          createResponse = await this[`${createRequest.action}`](createRequest.params[0]).toPromise();
          if (isObservable(createResponse)) {
            throw 'cant create an incident';
          }
        } else {
          console.log(`${createRequest.action}`);
          console.log(this[`${createRequest.action}`]);
        }
        //createResponse = await createRequest.action.apply(this, createRequest.params)
      } catch (e) {
        console.log(e);
        createRequest.hasError = true;
      }
      this.createRequestComplete$.next({
        createRequest,
        oldId: createRequest.params[1],
        createResponse,
      });
      const requestIndex = this.offlineUpdateQueue.indexOf(createRequest);
      if (requestIndex > -1) {
        this.offlineUpdateQueue.splice(requestIndex, 1);
      }
    }

    let updateResponse;
    for (const updateRequest of requestsToUpdate) {
      try {
        updateResponse = await this[`${updateRequest.action}`](updateRequest.params[0]).toPromise();
        if (isObservable(updateResponse)) {
          throw 'cant update an incident';
        }
      } catch (e) {
        console.log(e);
        updateRequest.hasError = true;
      }
      this.updateRequestComplete$.next({ updateRequest, updateResponse });
      const requestIndex = this.offlineUpdateQueue.indexOf(updateRequest);
      if (requestIndex > -1) {
        this.offlineUpdateQueue.splice(requestIndex, 1);
      }
    }

    await this.saveOfflineUpdateQueue();
    this.flushMutex.release();
  }

  handleAPIError({ updateRequest, defaultValue }: { updateRequest: OfflineUpdateRequest; defaultValue: any }) {
    return async (err: any, caught: Observable<any>) => {
      if (
        navigator.onLine &&
        (this.isValidId(updateRequest.getId?.apply(this, [updateRequest])) || updateRequest.type === RequestType.create)
      ) {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          datadogLogs.logger.error('API error', {
            err: JSON.stringify(err),
          });
          throw new Error(
            JSON.stringify({
              errorCode: err.graphQLErrors[0].extensions?.statusCode,
              message: err.graphQLErrors[0].message,
            })
          );
        } else {
          throw new Error(JSON.stringify(err));
        }
      } else {
        this.offlineUpdateQueue.push(updateRequest);
        const handlerResponse = await updateRequest.onError?.apply(this, [updateRequest]);

        this.saveOfflineUpdateQueue();
        return handlerResponse || defaultValue;
      }
    };
  }
}
