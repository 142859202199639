import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { HeapClassDirectiveModule, WcLetModule } from '@wc/wc-common/src';
import { WcTooltipModule } from '../../../tooltip/tooltip.module';
import { WcButtonModule } from '../../../wc-button/wc-button.module';
import { CameraThumbnailModule } from '../../camera-thumbnail/camera-thumbnail.module';
import { CameraGalleryComponent } from './camera-gallery.component';
import { ExponentialStrengthPipe } from './camera-title.pipe';

@NgModule({
  declarations: [CameraGalleryComponent, ExponentialStrengthPipe],
  imports: [
    CommonModule,
    CameraThumbnailModule,
    TranslateModule,
    WcLetModule,
    WcButtonModule,
    MatIconModule,
    WcTooltipModule,
    HeapClassDirectiveModule,
  ],
  exports: [CameraGalleryComponent],
})
export class CameraGalleryModule {}
