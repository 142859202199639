import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FeatureEvent,
  MapStyleNameOnServer,
  ThemeName,
  wcCoordinate,
  wcCoordinateTypes,
  WcGeometry,
  WcMapViewerService,
} from '@wc/wc-map-viewer';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AppVersion } from '../../../../../core/app-version';
import { environment } from '../../../../../core/environments/environment';
import { MapEditorConfig } from './map-editor.config';

@Component({
  selector: 'wc-map-editor',
  templateUrl: './map-editor.component.html',
  styleUrls: ['./map-editor.component.scss'],
  providers: [WcMapViewerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapEditorComponent implements OnInit {
  private isMapReady = false; //For supporting storybook live update
  private editGeometry!: WcGeometry;
  private allFeaturesCoordinates: wcCoordinateTypes = [];
  private mapCenterPadding: number[] = [20, 20, 20, 20];
  private drawEndEventSub!: Subscription;

  @Input() set accountCenterCoords(coordinates: wcCoordinateTypes) {
    MapEditorConfig.centerOptions.mapCenter = coordinates;
    const mapCenter = MapEditorConfig.centerOptions.mapCenter;
    if (mapCenter && mapCenter.length > 0 && !this.isMapReady) this.initMap();
  }

  @Input() set geometryBoundingZoomPadding(padding: number[]) {
    this.mapCenterPadding = padding;
    this.updateMapDraws(); //For supporting storybook live update
  }

  @Input() set geometry(geometrySrc: WcGeometry) {
    this.editGeometry = geometrySrc;
    if (geometrySrc.coordinates) {
      this.allFeaturesCoordinates = geometrySrc.coordinates;
      this.reset(this.editGeometry);
    }
  }

  @Output() changed: EventEmitter<WcGeometry> = new EventEmitter();

  private get isEmptyMap() {
    return !this.editGeometry || !this.editGeometry.coordinates || this.editGeometry.coordinates.length === 0;
  }

  constructor(private mapViewerService: WcMapViewerService, private el: ElementRef) {}

  ngOnInit(): void {
    this.updateMapConfigStyleByTheme('', '', MapStyleNameOnServer.light, ThemeName.light);
  }

  initMap() {
    setTimeout(() => {
      this.mapViewerService.init(this.el.nativeElement, MapEditorConfig).subscribe(ready => {
        this.isMapReady = ready;
        this.updateMapDraws();
        this.geometryChangesListener();
        this.createGeometry();
      });
    });
  }

  private createGeometry() {
    if (!this.isMapReady) return;
    this.mapViewerService.createNewGeometry(
      this.editGeometry.type,
      // TODO: check wcCoordinate type issue.
      this.editGeometry.coordinates ? (this.editGeometry.coordinates as wcCoordinate) : null,
      MapEditorConfig.editFeatureOption?.editStyleArgs
    );
  }

  public reset(geometry: WcGeometry) {
    if (!this.isMapReady) return;
    this.mapViewerService.stopCreatingNewGeometry();
    this.editGeometry = geometry;
    this.drawEndEventSub.unsubscribe();
    this.geometryChangesListener();
    this.createGeometry();
  }

  private geometryChangesListener() {
    if (!this.isMapReady) return;
    this.drawEndEventSub = this.mapViewerService.drawEndEvent$
      .pipe(
        tap(event => this.emitGeometryFromEvent(event)),
        switchMap(() => this.mapViewerService.modifyEndEvent$)
      )
      .subscribe(event => this.emitGeometryFromEvent(event));
  }

  private emitGeometryFromEvent(event: FeatureEvent) {
    this.changed.emit(event.targets?.[0]?.geometry);
  }

  private updateMapDraws() {
    if (!this.isMapReady) return;
    const coordinates = !this.isEmptyMap ? this.allFeaturesCoordinates : MapEditorConfig.centerOptions.mapCenter;
    if (coordinates)
      this.mapViewerService.setMapCenter(coordinates, {
        padding: this.isEmptyMap ? [0, 0, 0, 0] : this.mapCenterPadding,
      });
  }

  private updateMapConfigStyleByTheme(
    theme,
    iconSrcPath: '' | 'dark_' = '',
    mapSrcPath: MapStyleNameOnServer,
    themeName: ThemeName
  ) {
    MapEditorConfig.mapTheme = {
      themeName,
      themeIcons: {
        json: theme,
        path: `assets/map-icons/${iconSrcPath}sprite@1.png?${AppVersion}`,
      },
      mapThemeUrl: `${environment.mapBoxUrl}/${mapSrcPath}`,
    };
  }
}
