/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { CongestionsStoreEntity, CongestionsUIEntity } from '@wc/wc-models/src';
import { map } from 'rxjs/operators';
import { LayerType } from '../../../../../../core/models/gql.models';
import { EntitiesServiceV2 } from '../../../services/entities.service';

export interface CongestionsState extends EntityState<CongestionsStoreEntity, number> {}
export interface CongestionsUIState extends EntityState<CongestionsUIEntity> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: LayerType.IrregularCongestion })
export class IrregularCongestionsStore extends EntityStore<CongestionsState> {
  ui!: EntityUIStore<CongestionsUIState>;
  constructor(entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$
      .pipe(map(({ IRREGULAR_CONGESTION }) => IRREGULAR_CONGESTION || []))
      .subscribe(UIIrregularCongestions => this.ui.upsertMany(UIIrregularCongestions));

    entitiesService.getDiff$
      .pipe(
        map(({ modified, removed }) => ({
          removedIrregularCongestions: removed?.IRREGULAR_CONGESTION,
          modifiedIrregularCongestions: modified.IRREGULAR_CONGESTION,
        }))
      )
      .subscribe(({ removedIrregularCongestions: removedIrregularCongestions, modifiedIrregularCongestions }) => {
        if (modifiedIrregularCongestions) this.upsertMany(modifiedIrregularCongestions);
        if (removedIrregularCongestions) this.remove(removedIrregularCongestions);
      });

    entitiesService.activeEntityIds$
      .pipe(map(({ IRREGULAR_CONGESTION }) => IRREGULAR_CONGESTION || []))
      .subscribe(id => this.ui.setActive(id));
  }
}
