<div class="numeric-increase-decrease-component">
  <mat-form-field [floatLabel]="formFieldData.floatLabel ? formFieldData.floatLabel : 'never'">
    <mat-label> {{ formFieldData.label || '' | translate }} </mat-label>
    <input
      autocomplete="off"
      matInput
      type="number"
      [step]="step"
      inputmode="numeric"
      [min]="minInput"
      [placeholder]="formFieldData.placeholder || '' | translate"
      [max]="maxInput"
      (change)="change($event)"
      [value]="ngControl.control?.value"
      [formControl]="$any(ngControl.control)" />
  </mat-form-field>
</div>
