<div class="lane-dialog-container" [class.tablet-mode]="isTabletMode">
  <div *ngFor="let option of contextMenuOptions">
    <button
      mat-button
      [disableRipple]="true"
      class="lane-add-button"
      [class.is-disabled]="!option.isActive"
      (click)="handleMenuItemClick(option)">
      <!-- <img class="lane-icon" [src]="option.icon" /> -->
      <div
        class="lane-icon"
        [ngClass]="{
          'icon-gore': option.type === 'Gore',
          'icon-lane': option.type === 'Lane',
          'icon-left-shoulder': option.type === 'Left shoulder',
          'icon-right-shoulder': option.type === 'Right shoulder'
        }"></div>
      <span class="lane-add-button-label">
        {{ option.type | translate }}
      </span>
    </button>
  </div>
</div>
