import { NgModule } from '@angular/core';
import { WcMapComponent } from './wc-map.component';
import { WcMapService } from './wc-map.service';

export const BASE_PROVIDERS: unknown[] = [WcMapService];

@NgModule({
  declarations: [WcMapComponent],
  imports: [],
  exports: [WcMapComponent],
})
export class WcMapModule {
  // static forChild(configuredProviders: Array<any>): ModuleWithProviders {
  //   return {
  //     ngModule: WcMapModule,
  //     providers: [...BASE_PROVIDERS, ...configuredProviders]
  //   };
  // }
}
