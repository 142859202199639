import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateFormatPipeModule } from '@wc/wc-common/src/lib/pipes/format-pipes/date-format.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { DmsContentComponent } from './dms-content/dms-content.component';
import { DmsModalComponent } from './dms-modal/dms-modal.component';
import { DmsViewerModule } from './dms-viewer/dms-viewer.module';
import { DmssListComponent } from './dmss-list/dmss-list.component';
import { DMSsListComponentsModule } from './dmss-list/dmss-list.module';

@NgModule({
  declarations: [DmsModalComponent, DmsContentComponent],
  imports: [
    CommonModule,
    DmsViewerModule,
    DMSsListComponentsModule,
    TranslateModule,
    DateFormatPipeModule,
    SwiperModule,
    WcIconModule,
  ],
  exports: [DmsModalComponent, DmsContentComponent, DmssListComponent],
})
export class DmsComponentsModule {}
