import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MediaService } from '@wc-core';
import { PermissionsFacadeService } from '@wc/permissions/domain';
import { APP_TYPE_TOKEN } from '@wc/wc-core/src/lib/injection-tokens';
import { HeapAnalyticsService } from '@wc/wc-core/src/lib/services/heap-analytics.service';
import { AppFeatureEnum, SplitIOService } from '@wc/wc-core/src/lib/services/split-io.service';
import { UrlUploadEntityEnum } from '@wc/wc-core/src/lib/services/upload.service';
import { AppTypeUnion, CameraViewerContextType, ToastrPositionClass } from '@wc/wc-models';
import { FormFieldOption } from '@wc/wc-ui/src/lib/base';
import { ToastrAlertsService } from 'libs/wc-ui/src/services/toaster-alert.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EntityType, StreetCamera } from '../../../../../core/models/gql.models';
import { CameraTab, DashCamView } from '../../../../../core/models/models';
import { RoadEventCamera } from '../../../../../core/models/types-convert';
import { preloadImage } from '../../../../../utils/utils';
import { CameraViewerComponent } from '../camera-viewer/camera-viewer.component';

@Component({
  selector: 'wc-camera-section',
  templateUrl: './camera-section.component.html',
  styleUrls: ['./camera-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraSectionComponent implements OnInit {
  @Input() entityType = EntityType.Incident;
  @Input() uploadSnapshotTarget = UrlUploadEntityEnum.INCIDENT;
  @Input() readMode!: boolean;
  @Input() selectedCameraId!: number | undefined;
  @Input() hideDashcams = false;
  @Input() dashCams: DashCamView[] = [];
  @Input() allCameras: StreetCamera[] = [];
  @Input() entityId: number | null = null;
  @Input() set roadEventCameras(roadEventCameras: RoadEventCamera[]) {
    if (!roadEventCameras) {
      return;
    }
    const _roadEventsCameras = [...roadEventCameras];

    this.cameras = (_roadEventsCameras as any[]).sort((a, b) => a.positionIndex - b.positionIndex);
    this.cameras = _roadEventsCameras?.map((roadEventCamera: RoadEventCamera) => roadEventCamera.camera);

    let defaultCamera: RoadEventCamera | null = null;

    if (this.cameras.length) {
      defaultCamera =
        _roadEventsCameras.find((roadEventCamera: RoadEventCamera) => roadEventCamera.default) || _roadEventsCameras[0];
    }

    if (defaultCamera && defaultCamera?.camera.id !== this.defaultCamera?.id) {
      this.defaultCamera = defaultCamera.camera;
      this.selectedCamera = this.defaultCamera;
    }
  }

  @Output() roadEventCamerasChanged: EventEmitter<{
    cameras: Array<StreetCamera>;
    mainCameraId: string;
  }> = new EventEmitter();
  @Output() cameraSelected = new EventEmitter<StreetCamera>();
  @Output() snapshotUploaded: EventEmitter<number | null> = new EventEmitter<number | null>();
  selectedCameraTab: CameraTab = CameraTab.CCTV;
  cameraTabEnum = CameraTab;
  cameras: Array<StreetCamera> = [];
  filteredRelatedCameras!: Observable<Array<StreetCamera>>;

  @Input() isTabletMode!: boolean;
  isAddCameraMode = false;
  searchCameraControl: FormControl;
  type!: string;
  defaultCamera: StreetCamera | null = null;
  selectedCamera: StreetCamera | null = null;
  selectedDashCam: DashCamView | null = null;
  @Input() isOnline!: boolean;
  cameraContextType: typeof CameraViewerContextType = CameraViewerContextType;
  cameraOptions = [] as FormFieldOption[];
  isTakeSnapshotDisabled = false;
  isSelectedCameraHasIssues = false;
  @Input() showTakesnapshotButton = true;

  @ViewChild('cameraViewer') cameraViewer!: CameraViewerComponent;

  constructor(
    private heapService: HeapAnalyticsService,
    private splitIOService: SplitIOService,
    private alertService: ToastrAlertsService,
    private translateService: TranslateService,
    private permissionsService: PermissionsFacadeService,
    private mediaService: MediaService,
    @Inject(APP_TYPE_TOKEN) private _appType: AppTypeUnion
  ) {
    // Preload offline assets
    preloadImage('assets/images/offline-camera.svg');

    this.searchCameraControl = new FormControl();
  }

  get appType() {
    return this._appType;
  }

  ngOnInit() {
    this.cameraOptions = this.allCameras.map((camera: StreetCamera) => {
      return {
        displayName: camera.title,
        value: camera.id,
      };
    });

    this.selectedDashCam = this.dashCams[0];
    this.isTakeSnapshotDisabled = this.shouldDisableTakeSnapshot;

    if (this.isTabletMode)
      this.filteredRelatedCameras = this.searchCameraControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): StreetCamera[] {
    if (!value) {
      return [];
    }
    const filterValue = value.toLowerCase();
    return this.allCameras.filter((camera: StreetCamera) => {
      // Filter cameras by search input
      return (
        camera.title.toLowerCase().includes(filterValue) &&
        // Filter cameras that already added to the road event
        !this.cameras.find((_camera: StreetCamera) => _camera.id === camera.id)
      );
    });
  }

  selectCamera(camera: StreetCamera | DashCamView) {
    if (camera) {
      const cameraAsStreetCamera = camera as StreetCamera;
      const cameraAsDashCamera = camera as DashCamView;
      if (typeof cameraAsStreetCamera.id !== 'undefined') {
        this.selectedCamera = cameraAsStreetCamera;
        this.cameraSelected.emit(this.selectedCamera);
      } else this.selectedDashCam = cameraAsDashCamera;
    }
  }

  removeCamera(id) {
    const index = this.cameras.findIndex((camera: StreetCamera) => camera.id === id);
    this.cameras.splice(index, 1);
    if (this.defaultCamera?.id) {
      this.roadEventCamerasChanged.emit({
        cameras: this.cameras,
        mainCameraId: this.defaultCamera?.id.toString(),
      });
    }

    if (this.isTabletMode) {
      this.trackUser('heap-tablet-fe-remove-camera');
    } else {
      this.trackUser('heap-desktop-fe-remove-camera');
    }
  }

  addCamera(selected: StreetCamera | FormFieldOption) {
    const camera = this.getStreetCamera(selected);

    if (this.cameras.length) {
      const cameraAlreadySelected = !!this.cameras.find(_camera => _camera.id === camera?.id);
      if (cameraAlreadySelected) {
        return;
      }
    } else {
      this.defaultCamera = camera;
    }

    this.cameras.push(camera);
    this.isAddCameraMode = false;
    if (this.defaultCamera?.id) {
      this.roadEventCamerasChanged.emit({
        cameras: this.cameras,
        mainCameraId: this.defaultCamera?.id.toString(),
      });
    }
    if (this.isTabletMode) {
      this.trackUser('heap-tablet-fe-add-camera');
    } else {
      this.trackUser('heap-desktop-fe-add-camera');
    }
  }

  getStreetCamera(selected: StreetCamera | FormFieldOption): StreetCamera {
    const isStreetCamera = this.isStreetCamera(selected);
    let camera: StreetCamera | undefined;
    if (!isStreetCamera) {
      camera = this.allCameras.find(
        camera => camera.id === (isStreetCamera ? (selected as StreetCamera).id : (selected as FormFieldOption).value)
      );
    }

    if (!camera) camera = selected as StreetCamera;

    return camera;
  }

  isStreetCamera = (camera: StreetCamera | FormFieldOption): camera is StreetCamera =>
    (camera as StreetCamera).id !== undefined;

  showSearchCamera() {
    this.searchCameraControl.setValue('');
    this.isAddCameraMode = true;

    // this.el.nativeElement.getElementById('searchCamera').focus();
  }

  clear() {
    this.searchCameraControl.setValue(null);
    this.isAddCameraMode = false;
  }

  setMainCamera() {
    this.defaultCamera = this.selectedCamera;
    if (this.defaultCamera?.id) {
      this.roadEventCamerasChanged.emit({
        cameras: this.cameras,
        mainCameraId: this.defaultCamera?.id.toString(),
      });
    }

    if (this.isTabletMode) {
      this.trackUser('heap-tablet-fe-update-main-camera');
    } else {
      this.trackUser('heap-desktop-fe-update-main-camera');
    }
  }

  selectedTabIndex(tabIndex: CameraTab) {
    this.selectedCameraTab = tabIndex;
    this.onTabChangedSelectCamera(tabIndex);
  }

  onTabChangedSelectCamera(tabIndex: CameraTab) {
    if (tabIndex === CameraTab.CCTV && this.defaultCamera) {
      this.selectCamera(this.defaultCamera);
      return;
    }

    if (tabIndex === CameraTab.Dash && this.dashCams.length) {
      this.selectCamera(this.dashCams[0]);
    }
  }

  get showTakeSnapshotButton(): boolean {
    return (
      !!(
        this.splitIOService.isActiveFeatureToggle(AppFeatureEnum.FE_USER_GENERATED_SNAPSHOTS) &&
        this.permissionsService.hasPermission(['INCIDENT:UPDATE', 'CAMERA:READ'], 'AND') &&
        this.entityId
      ) && this.showTakesnapshotButton
    );
  }

  get shouldDisableTakeSnapshot(): boolean {
    const maxTotalFilesSizeBeforeSnapshot = 49.8;
    return this.mediaService.totalFilesSize.value >= maxTotalFilesSizeBeforeSnapshot;
  }

  takeSnapshot(): void {
    if (!this.shouldDisableTakeSnapshot) {
      this.cameraViewer.takeSnapshot(this.uploadSnapshotTarget);
      this.sendTakeSnapshotHeapEvent();
    }
  }

  showSnapshotNotification() {
    setTimeout(() => {
      this.alertService.success(
        this.translateService.instant('notifications.snapshotHasBeenSaved'),
        undefined,
        {
          positionClass:
            this.uploadSnapshotTarget === UrlUploadEntityEnum.INCIDENT
              ? ToastrPositionClass.DesktopBottomAuxiliaryPanel
              : ToastrPositionClass.DesktopTopCenter,
          timeOut: 5000,
        },
        true
      );
    }, 500);
  }

  updateEntityView(event: number | null): void {
    this.showSnapshotNotification();

    if (!event) return;
    this.snapshotUploaded.emit(event);
  }

  updateCameraStatus(hasIssues: boolean) {
    this.isSelectedCameraHasIssues = hasIssues;
  }

  sendTakeSnapshotHeapEvent() {
    this.trackUser('heap-snapshots-clicked-take-snapshot-button');
  }

  private trackUser(key: string) {
    this.heapService.trackUserSpecificAction(key, {
      entityId: this.entityId,
      entityType: this.entityType === EntityType.Incident ? this.entityType : 'TRAFFIC_DISRUPTION',
      entitySubType: this.entityType === EntityType.Incident ? undefined : this.entityType,
    });
  }
}
