import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { BaseControlFieldComponent } from '../../base';
import { NG_VALUE_ACCESSOR, FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { SelectOption } from '../../form-controls/form-models';

@Component({
  selector: 'wc-multi-checkbox',
  templateUrl: './multi-checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiCheckboxComponent),
      multi: true,
    },
  ],
  styleUrls: ['./multi-checkbox.component.scss'],
})
export class MultiCheckboxComponent extends BaseControlFieldComponent implements OnInit {
  @Input() showCounter = true;
  @Input() showMoreOptions = true;
  @Input() oneColumn = false;
  @Input() twoRows = false;
  @Input() showMoreLength: number = 10;
  hideOption = true;
  displayStyle: object = { 'multi-checkbox-container': true };

  checkboxs: FormArray = this.fb.array([]);
  checkboxsForm: FormGroup = this.fb.group({ checkboxs: this.checkboxs });

  get selectedItemsCount() {
    return this.fieldFormControl.value ? this.fieldFormControl.value.length : 0;
  }

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.buildCheckboxArray(this.fieldData.options);
    if (this.oneColumn) {
      this.displayStyle = { 'multi-checkbox-container-one-column': true };
    }
    if (this.twoRows) {
      this.displayStyle = { 'multi-checkbox-two-rows': true };
    }
  }

  checkboxChanged(checked, checkbox) {
    checkbox.get('checked').setValue(checked);
    this.setFieldValue();
  }

  setFieldValue() {
    const valuesArray = this.checkboxsForm.value.checkboxs
      .filter(_checkbox => _checkbox.checked)
      .map(_checkbox => _checkbox.value);
    this.fieldFormControl.setValue(valuesArray);
    this.changed();
  }

  writeValue(val) {
    // if empty - reset all checkbox
    if (!val || !val.length) {
      this.resetCheckboxes();
    } else {
      //else reset all and check
      this.resetCheckboxes();
      this.checkboxs.controls.forEach(checkbox => {
        if (val?.includes(checkbox.get('value')?.value)) {
          checkbox.get('checked')?.setValue(true);
        }
      });
    }

    this.setFieldValue();
  }

  resetCheckboxes() {
    this.checkboxs.controls.forEach(checkbox => checkbox.get('checked')?.setValue(false));
  }

  buildCheckboxArray(options?: SelectOption[]) {
    if (!options) return;
    options.forEach(option => {
      const group = this.fb.group({
        checked: false,
        value: option.value ? option.value : option,
        displayName: option.displayName ? option.displayName : option,
      });
      this.checkboxs.push(group);
    });
  }
}
