<ng-container *ngIf="received === null && !showFeedbackSentMsg">
  <div class="feedback-component__container">
    <span [class.disabled]="isRequestInProgress">
      {{ 'feedback.wasThisHelpful' | translate }}
    </span>
    <div class="feedback-component__button-group">
      <button
        [disabled]="isRequestInProgress"
        matTooltipHideDelay="500"
        matTooltipShowDelay="500"
        matTooltipPosition="above"
        [matTooltip]="'feedback.helpful' | translate"
        matTooltipClass="feedback-tooltip"
        class="feedback-component__button-group--button"
        (click)="handleFeedback(true)">
        <i data-cy-id="feedback-thumbs-up" class="fas fa-thumbs-up"></i>
      </button>
      <div class="feedback-component__seperator"></div>
      <button
        [disabled]="isRequestInProgress"
        matTooltipHideDelay="500"
        matTooltipShowDelay="500"
        matTooltipPosition="above"
        [matTooltip]="'feedback.unhelpful' | translate"
        matTooltipClass="feedback-tooltip"
        class="feedback-component__button-group--button"
        (click)="handleFeedback(false)">
        <i data-cy-id="feedback-thumbs-down" class="fas fa-thumbs-down"></i>
      </button>
    </div>
  </div>
</ng-container>

<ng-container #sentFeedback>
  <div class="feedback-component__container" *ngIf="showFeedbackSentMsg">{{ 'feedback.thankYou' | translate }}</div>
</ng-container>
