import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { AlertsService } from '../../features/ui/services/alerts.service';
import { WebSocketType } from '../models/enums';
import { Point } from '../models/models';
import { WebsocketService } from './websocketService';
export interface Position {
  coords: {
    latitude: number;
    longitude: number;
  };
}

export type LocationUpdate = {
  type: 'location_update';
  point: Point;
  Bearing: number;
};

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  locationUpdate$: BehaviorSubject<Position | null> = new BehaviorSubject<Position | null>(null);
  gpsLocationUpdate$: BehaviorSubject<Position | null> = new BehaviorSubject<Position | null>(null);
  avlLocationUpdate$: BehaviorSubject<Position | null> = new BehaviorSubject<Position | null>(null);
  locationWatcher;
  locationSrc: 'AVL' | 'GPS' = 'GPS';
  lastAVLTimestamp!: Date;
  moment: any = moment;

  constructor(
    private translateService: TranslateService,
    private alertService: AlertsService,
    private wsService: WebsocketService
  ) {}

  watchLocation() {
    this.setLocationSrc(this.locationSrc);
    this.watchAVLLocation();
    this.watchGPSLocation();
  }

  setLocationSrc(src: 'AVL' | 'GPS') {
    this.locationSrc = src;
  }

  watchAVLLocation() {
    this.wsService.connect();
    this.wsService.subject.pipe().subscribe((res: LocationUpdate[]) => {
      if (!res[0] || res[0].type !== WebSocketType.locationUpdate) return;
      if (res[0].point.coordinates) {
        const position: Position = {
          coords: {
            longitude: res[0].point.coordinates[0],
            latitude: res[0].point.coordinates[1],
          },
        };
        this.lastAVLTimestamp = new Date();
        // console.log('AVL position', res[0]);
        this.avlLocationUpdate$.next(position);
        if (this.locationSrc === 'AVL') this.locationUpdate$.next(position);
      }
    });
  }

  watchGPSLocation() {
    this.locationWatcher = window.navigator.geolocation.watchPosition(
      (position: Position) => {
        this.gpsLocationUpdate$.next(position); // This is for sending unit update location
        if (this.locationSrc === 'GPS') this.locationUpdate$.next(position);
      },
      (error: any) => {
        datadogLogs.logger.info('Geolocation position error', {
          wc_geolocation_position_error: {
            code: error.code,
            message: error.message,
          },
        });
        if (error.code === 1) {
          this.alertService.info(this.translateService.instant('GPSPermissionsDenied'));
        } else {
          // code === 2 | 3
          this.alertService.info(this.translateService.instant('GPSTimeoutNoPosition'));
        }
        console.warn(error);
      },
      {
        enableHighAccuracy: true,
        maximumAge: 2500,
        // timeout: 2500
      }
    );
  }

  getCurrentPosition(fallbackPosition?: Position): Promise<Position | undefined> {
    return new Promise((resolve, reject) => {
      const durationFromLastAVL = this.moment.duration(
        this.moment(new Date()).diff(this.moment(this.lastAVLTimestamp))
      );
      // console.log('durationFromLastAVL: ', durationFromLastAVL.as('seconds'));
      // console.log(this.locationSrc === 'AVL' , this.lastAVLTimestamp , durationFromLastAVL.as('seconds') < 20)
      if (this.locationSrc === 'AVL' && this.lastAVLTimestamp && durationFromLastAVL.as('seconds') < 20) {
        resolve(this.avlLocationUpdate$.value as Position | PromiseLike<Position>);
      } else {
        // console.log(this.locationUpdate$.value, fallbackPosition);
        const location = this.locationUpdate$.value || fallbackPosition;
        const options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 1000,
        };
        window.navigator.geolocation.clearWatch(this.locationWatcher);
        window.navigator.geolocation.getCurrentPosition(
          (position: any) => {
            this.watchGPSLocation();
            const pos: Position = {
              coords: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
            };
            this.locationUpdate$.next(pos);
            resolve(pos);
          },
          err => {
            console.log(err);
            this.watchGPSLocation();
            resolve(location);
          },
          options
        );
      }
    });
  }
}
