import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HeapAnalyticsService } from '@wc-core';
import { EntityType, FeedbackType, UserFeedBackInput } from '@wc/core';
import { FeedbackService } from '@wc/core/services/feedback.service';
import { OnboardingTourName } from '@wc/wc-core/src/lib/types/tour';
import { ToastrAlertsService } from '../../services/toaster-alert.service';

@Component({
  selector: 'wc-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackComponent implements OnChanges, OnDestroy {
  @Input() clientSideFeedback = false;
  @Input() serverSideFeedback = true;
  @Input() feedbackFeatureType!: FeedbackType | OnboardingTourName;
  @Input() received?: boolean | null;
  @Input() externalId?: string;
  @Input() entityType!: EntityType;
  @Input() entityId!: number;
  @Output() feedbackSend = new EventEmitter<boolean>();
  showFeedbackSentMsg = false;
  isRequestInProgress = false;
  currrentFeedback: boolean | undefined | null = null;

  constructor(
    private readonly feedbackService: FeedbackService,
    private readonly heapService: HeapAnalyticsService,
    private alertService: ToastrAlertsService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnDestroy(): void {
    this.showFeedbackSentMsg = false;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['externalId']?.currentValue !== changes['externalId']?.previousValue) {
      this.showFeedbackSentMsg = false;
    }
  }

  handleFeedback(feedback: boolean) {
    if (this.serverSideFeedback) {
      this.sendFeedbackToServer(feedback);
    }
    if (this.clientSideFeedback) {
      this.saveFeedbackLocally(feedback, !this.serverSideFeedback);
    }
  }

  sendFeedbackToServer(feedback: boolean) {
    const userFeedBackInput: UserFeedBackInput = {
      feedback: feedback,
      feedbackType: this.feedbackFeatureType as FeedbackType,
      externalId: this.externalId,
      incidentId: this.entityId,
    };
    this.isRequestInProgress = true;
    this.feedbackService.sendUserFeedback(userFeedBackInput).subscribe({
      next: () => {
        this.heapService.trackUserSpecificAction(
          `heap-desktop-feedback-${this.feedbackFeatureType.toLocaleLowerCase()}-${
            feedback ? 'thumbs-up' : 'thumbs-down'
          }`
        );
        this.isRequestInProgress = false;
        this.showFeedbackSentMsg = true;
        this.cdr.markForCheck();

        setTimeout(() => {
          this.showFeedbackSentMsg = false;
          this.received = true;
          this.feedbackSend.emit(feedback);
        }, 5000);
      },
      error: () => {
        this.alertService.error(this.translateService.instant('feedback.failedToSendFeedBack'));
      },
    });
  }

  saveFeedbackLocally(feedback: boolean, emitEvent = true) {
    if (Object.values(FeedbackType).includes(this.feedbackFeatureType as FeedbackType)) {
      this.feedbackService.saveFeedbackLocally(this.feedbackFeatureType as FeedbackType, this.entityId);
    } else {
      this.feedbackService.saveFeedbackLocally(this.feedbackFeatureType, feedback);
    }

    if (emitEvent) {
      this.feedbackSend.emit(feedback);
      this.cdr.markForCheck();
    }

    this.heapService.trackUserSpecificAction(
      `heap-desktop-feedback-${this.feedbackFeatureType.toLocaleLowerCase()}-${feedback ? 'thumbs-up' : 'thumbs-down'}`
    );
  }
}
