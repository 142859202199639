import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Account,
  AccountPermit,
  AccountPermitInput,
  AnalyticsReportsUrls,
  IncidentAbandonedVehicleSubType,
  IncidentConfiguration,
  IncidentCrashSubType,
  IncidentDamageSubType,
  IncidentDebrisSubType,
  IncidentHazardSubType,
  IncidentPoliceActivitySubType,
  IncidentStalledVehicleSubType,
  IncidentTrafficStopSubType,
  IncidentType,
  RouteType,
  ShiftType,
  Status,
  UnitVehicle,
  UpdateIncidentConfigurationInput,
  VehicleType,
} from '@wc/core';
import { LocalStorageService } from '@wc/core/services/local-storage.service';
import { SelectOption } from '@wc/features/ui/form-controls/form-models';
import { PermissionsFacadeService } from '@wc/permissions/domain/src';
import * as Utils from '@wc/utils';
import { strArrayToOptions } from '@wc/wc-common/src/lib/utils/convertToSelectOptions';
import { ScopeAccessModifier } from '@wc/wc-models/src/lib/types/utility-types';
import { FormFieldData, FormFieldOption } from '@wc/wc-ui/src/lib/base/custom-form-control';
import { WorkScheduleConfiguration } from '@wc/work-schedule-configuration/domain-types';
import { WorkScheduleConfigurationFacadeService } from '@wc/work-schedule-configuration/domain/src';
import { indexOf, sortBy } from 'lodash';
import { BehaviorSubject, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, finalize, first, map, shareReplay, tap } from 'rxjs/operators';
import { AccountApiService } from './account-api.service';
import { AuthUserService } from './auth-user.service';
import { LoaderService } from './loader.service';
import { LocalStorageKeys } from './local-storage.service';
import { RoutesApiService } from './routes-api.service';

type reportTypes = keyof Omit<AnalyticsReportsUrls, '__typename'>;

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private incidentPriorityType$?: Observable<IncidentType[]>;
  private unitsByAccount$: ReplaySubject<UnitVehicle[]> = new ReplaySubject(1);
  private unitsByWorkspaces$: ReplaySubject<UnitVehicle[]> = new ReplaySubject(1);
  private permits: ReplaySubject<AccountPermit[]> = new ReplaySubject(1);
  permits$ = this.permits.asObservable();
  incidentConfigs: IncidentConfiguration = {
    additionalInfoMandatory: false,
    additionalInfoValues: [],
    affectedLanesMandatory: false,
    associatedUnitsMandatory: false,
    directionMandatory: false,
    casualtiesMandatory: false,
    incidentSubTypes: {
      abandonedVehicleSubTypeValues: [],
      crashSubTypeValues: [],
      damageSubTypeValues: [],
      debrisSubTypeValues: [],
      hazardSubTypeValues: [],
      policeActivitySubTypeValues: [],
      stalledVehicleSubTypeValues: [],
      trafficStopSubTypeValues: [],
    },
    incidentTypeValues: [],
    mitigationTypeValues: [],
    notesMandatory: false,
    orientationMandatory: false,
    orientationValues: [],
    roadTypeValues: [],
    sourceMandatory: false,
    sourceValues: [],
    responsePlanMandatory: false,
    severityMandatory: false,
    severityValues: [],
    attributesMandatory: false,
    attributeFixedValues: [],
    attributeFreeTextValues: [],
  };

  private _account: BehaviorSubject<Account> = new BehaviorSubject({} as Account);
  public account$ = this._account.asObservable().pipe(
    tap(() => {
      this.fetchAndUpdateRouteTypes();
    })
  );
  routeTypes$ = new BehaviorSubject<RouteType[]>([]);
  readonly accountRouteTypes$ = this.routeTypes$.asObservable();

  get account() {
    return this._account.value;
  }

  constructor(
    private accountApiService: AccountApiService,
    private routesService: RoutesApiService,
    private translateService: TranslateService,
    private localStorage: LocalStorageService,
    private authUserService: AuthUserService,
    private loaderService: LoaderService,
    private wscService: WorkScheduleConfigurationFacadeService,
    private permissionsFacadeService: PermissionsFacadeService
  ) {
    this.loaderService.update({ incidentConfigurations: true });
    this.authUserService.authUser$
      .pipe(first(({ account }) => !!account))
      .subscribe(({ account }) => this._account.next(account));
  }

  fetchAndUpdateRouteTypes() {
    this.routesService.accountRouteTypes(this.account?.id).subscribe(routeTypes => {
      this.account.routeTypes = routeTypes;
      this.routeTypes$.next(routeTypes);
    });
  }

  get accountModulesTypes() {
    return this.account.groups.map(permissionGroup => permissionGroup.type);
  }

  get reportSourceOptions() {
    return this.incidentConfigs?.sourceValues
      .filter(({ status }) => status === Status.Active)
      .map(s => ({ value: s.id, displayName: s.reportSource }));
  }

  get vehicleTypesOptions(): Observable<FormFieldOption[]> {
    const optionsOrder = [
      VehicleType.CityPoliceUnit,
      VehicleType.HighwayPoliceUnit,
      VehicleType.FspUnit,
      VehicleType.EmsUnit,
      VehicleType.FireDepartmentUnit,
      VehicleType.ConstructionUnit,
      VehicleType.MaintenanceUnit,
      VehicleType.SnowPlowUnit,
      VehicleType.StreetSweepUnit,
      VehicleType.OtherUnit,
    ];
    return this.unitsByWorkspaces.pipe(
      map(units => [...new Set(units.map(unit => unit.type))]),
      map(units => {
        let _options: FormFieldOption[] = Utils.strArrayToOptions(units, {
          translateService: this.translateService,
          translateBy: 'value',
          translatePath: 'VehicleTypes',
        });
        _options = sortBy(_options, item => indexOf(optionsOrder, item.value));
        return _options;
      })
    );
  }

  getVehicleTypeOption(vehicleType: VehicleType): SelectOption {
    return {
      value: vehicleType,
      displayName: this.translateService.instant(`VehicleTypes.${vehicleType}`),
    };
  }

  get unitsOptionsByWorkspaces(): Observable<FormFieldOption[]> {
    return this.unitsByWorkspaces.pipe(
      map(units => {
        const options: FormFieldOption[] = [];
        units.forEach(unit => {
          options.push({
            value: unit.id,
            displayName: unit.displayId,
            data: unit,
          });
        });
        options.sort((unit1, unit2) => {
          return (unit1.displayName as string)
            .toLocaleLowerCase()
            .localeCompare((unit2.displayName as string).toLocaleLowerCase());
        });
        return options;
      })
    );
  }

  get unitsByWorkspaces(): Observable<UnitVehicle[]> {
    if (this.unitsByWorkspaces$.observers.length === 0) {
      return this.accountApiService.unitsByWorkspaces();
    } else {
      return this.unitsByWorkspaces$.asObservable();
    }
  }

  get unitsByAccount(): Observable<UnitVehicle[]> {
    if (this.unitsByAccount$.observers.length === 0) {
      return this.accountApiService.unitsByAccount();
    } else {
      return this.unitsByWorkspaces$.asObservable();
    }
  }

  get mitigationsOptions(): SelectOption[] {
    return (
      this.incidentConfigs?.mitigationTypeValues?.map<SelectOption>(({ id, description }) => ({
        value: id,
        displayName: description,
      })) || []
    );
  }

  get allMitigationOptions(): SelectOption[] {
    const sortedMitigationTypes = this.incidentConfigs.mitigationTypeValues.sort((a, b) => {
      return a.description.localeCompare(b?.description, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });

    const activeFirstMitigationTypes = sortedMitigationTypes.sort(function (a, b) {
      return Number(a.isDeleted) - Number(b.isDeleted);
    });

    return (
      activeFirstMitigationTypes?.map<SelectOption>(({ id, description, isDeleted }) => ({
        value: id,
        displayName: isDeleted ? description + ' (' + this.translateService.instant('Del.') + ')' : description,
        disabled: isDeleted,
      })) || []
    );
  }

  get additionalInfosOptions(): SelectOption[] {
    return (
      this.incidentConfigs?.additionalInfoValues?.map<SelectOption>(({ id, info }) => ({
        value: id,
        displayName: info,
      })) || []
    );
  }

  get activeRouteTypes(): RouteType[] {
    return this.routeTypes$.value.filter(route => route.status === Status.Active);
  }

  get RoutesOptions(): SelectOption[] {
    const options: SelectOption[] = this.routeTypes$.value.map(route => {
      const option: SelectOption = {
        value: route.id,
        displayName:
          route.status === Status.Inactive
            ? route.name + ' (' + this.translateService.instant('Del.') + ')'
            : route.name,
      };
      return option;
    });
    return options;
  }

  get workspacesOptions(): SelectOption[] {
    const options = this.account.workspaces.workspaces
      .map<SelectOption>(({ id, title }) => ({
        value: id,
        displayName: title,
      }))
      .sort((a, b) => (a.displayName as string).localeCompare(b.displayName as string));

    options.unshift(
      options.splice(
        options.findIndex(o => o.value === 1),
        1
      )[0]
    );
    return options;
  }

  get ShiftsOptions(): SelectOption[] {
    const options: SelectOption[] = this.account.shiftTypes.map(shift => {
      const option: SelectOption = {
        value: shift.id,
        displayName:
          shift.status === Status.Inactive
            ? shift.name + ' (' + this.translateService.instant('Del.') + ')'
            : shift.name,
      };
      return option;
    });
    return options;
  }

  accountInfoSubmit(modifiedShiftTypes: ShiftType[]) {
    return this.accountApiService
      .updateAccountInfo(this.account, modifiedShiftTypes)
      .pipe(tap(() => console.error('todo')));
  }

  get incidentSubTypeStructure() {
    return {
      ABANDONED_VEHICLE: Utils.EnumToOptions(IncidentAbandonedVehicleSubType, {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'incidentSubTypes',
        visibleValues: this.incidentConfigs.incidentSubTypes.abandonedVehicleSubTypeValues as string[],
      }),

      CRASH: Utils.selectedOptionsAlphaNumericSort(
        Utils.EnumToOptions(IncidentCrashSubType, {
          translateService: this.translateService,
          translateBy: 'value',
          translatePath: 'incidentSubTypes',
          visibleValues: this.incidentConfigs.incidentSubTypes.crashSubTypeValues as string[],
        })
      ),

      DAMAGE: Utils.EnumToOptions(IncidentDamageSubType, {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'incidentSubTypes',
        visibleValues: this.incidentConfigs.incidentSubTypes.damageSubTypeValues as string[],
      }),

      DEBRIS: Utils.EnumToOptions(IncidentDebrisSubType, {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'incidentSubTypes',
        visibleValues: this.incidentConfigs.incidentSubTypes.debrisSubTypeValues as string[],
      }),

      HAZARD: Utils.EnumToOptions(IncidentHazardSubType, {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'incidentSubTypes',
        visibleValues: this.incidentConfigs.incidentSubTypes.hazardSubTypeValues as string[],
      }),

      POLICE_ACTIVITY: Utils.EnumToOptions(IncidentPoliceActivitySubType, {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'incidentSubTypes',
        visibleValues: this.incidentConfigs.incidentSubTypes.policeActivitySubTypeValues as string[],
      }),

      STALLED_VEHICLE: Utils.EnumToOptions(IncidentStalledVehicleSubType, {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'incidentSubTypes',
        visibleValues: this.incidentConfigs.incidentSubTypes.stalledVehicleSubTypeValues as string[],
      }),

      TRAFFIC_STOP: Utils.EnumToOptions(IncidentTrafficStopSubType, {
        translateService: this.translateService,
        translateBy: 'value',
        translatePath: 'incidentSubTypes',
        visibleValues: this.incidentConfigs.incidentSubTypes.trafficStopSubTypeValues as string[],
      }),
    };
  }

  get dataHubActiveReportsNames() {
    const activateReportsNames: string[] = [];
    const analyticsReportsUrls = this.account.analyticsReportsUrls || {};

    if (Object.keys(analyticsReportsUrls).length === 0) {
      return [];
    }
    const orderReportsRecord: Record<reportTypes, ScopeAccessModifier[]> = {
      unitsPerformanceReport: [],
      timPerformanceReport: ['DOMO_INCIDENT_REPORT:READ'],
      fspReport: ['DOMO_FSP_REPORT:READ'],
      transitReport: ['DOMO_TRANSIT_REPORT:READ'],
      incidentAnalysisReport: ['DOMO_INCIDENT_REPORT:READ'],
      mitigationsReport: ['DOMO_FSP_REPORT:READ'],
      speedMeasurementReport: [],
      travelReliabilityReport: [],
      fixedRouteTransitReport: ['DOMO_TRANSIT_REPORT:READ'],
    };

    for (const reportName in orderReportsRecord) {
      const report = analyticsReportsUrls[reportName];
      if (Array.isArray(report) && this.permissionsFacadeService.hasPermission(orderReportsRecord[reportName])) {
        if (report.length > 0) {
          activateReportsNames?.push(reportName);
        } else {
          activateReportsNames?.push(reportName + 'COME_SOON');
        }
      }
    }

    return activateReportsNames;
  }

  unitsOptionsByAccount(): Observable<FormFieldOption<number, UnitVehicle>[]> {
    const observable: Observable<UnitVehicle[]> = this.unitsByAccount;
    return observable.pipe(
      map(units => {
        const options: SelectOption[] = [];
        units.forEach(unit => {
          options.push({
            value: unit.id,
            displayName: unit.displayId,
            data: unit,
          });
        });
        options.sort((unit1, unit2) => {
          return (unit1.displayName as string)
            .toLocaleLowerCase()
            .localeCompare((unit2.displayName as string).toLocaleLowerCase());
        });
        return options;
      })
    );
  }

  updateIncidentConfiguration(input: UpdateIncidentConfigurationInput) {
    return this.accountApiService.updateIncidentConfiguration(input).pipe(
      tap(val => (this.incidentConfigs = val)),
      catchError(e => throwError(e))
    );
  }

  get incidentTypeConfigsData(): FormFieldData<IncidentType, { isExpanded: boolean }> {
    const options: FormFieldOption<IncidentType, { isExpanded: boolean }>[] = [];

    Object.values(IncidentType).forEach(filed => {
      if (
        filed !== IncidentType.UnknownIncidentType &&
        filed !== IncidentType.Unidentified &&
        filed !== IncidentType.TrafficAnomaly
      ) {
        options.push({
          selected: this.incidentConfigs?.incidentTypeValues.includes(filed),
          value: filed,
          data: { isExpanded: this.incidentSubTypeStructure[filed] ? true : false },
          displayName: this.translateService.instant('incidentTypes.' + filed),
        });
      }
    });
    return { options: options };
  }

  // Called from IncidentConfigInitService
  loadIncidentConfiguration(): Observable<IncidentConfiguration> {
    return this.accountApiService.loadIncidentConfiguration().pipe(
      tap(configs => {
        this.incidentConfigs = configs;
        this.localStorage.set(LocalStorageKeys.IncidentConfigs, this.incidentConfigs);
      }),
      catchError(err => {
        const configs = this.localStorage.get(LocalStorageKeys.IncidentConfigs);
        if (configs) {
          console.error('Get incident config failed - loading incidentConfig from localStorage');
          this.incidentConfigs = configs;
          return of(err);
        } else {
          console.error('Get incident config failed - no incidentConfig in localStorage -> refresh page');
          this.localStorage.set(LocalStorageKeys.IncidentConfigs, this.incidentConfigs);
          window.location.reload();
          return throwError(err);
        }
      }),
      finalize(() => setTimeout(() => this.loaderService.update({ incidentConfigurations: false })))
    );
  }

  loadWorkScheduleConfiguration(): Observable<WorkScheduleConfiguration> {
    return this.wscService.getWorkingHours();
  }

  get incidentByPriorityTypesOptions(): Observable<SelectOption[]> {
    return this.getIncidentTypePriority().pipe(
      map(types =>
        strArrayToOptions(types, {
          translateService: this.translateService,
          translateBy: 'value',
          translatePath: 'incidentTypes',
          removeSort: true,
        }).filter(opt => opt.value !== 'UNKNOWN_INCIDENT_TYPE')
      )
    );
  }

  private getIncidentTypePriority() {
    if (!this.incidentPriorityType$) {
      this.incidentPriorityType$ = this.accountApiService.getIncidentTypePriority().pipe(shareReplay(1));
    }
    return this.incidentPriorityType$;
  }

  updateIncidentTypePriority(input: IncidentType[]) {
    return this.accountApiService
      .updateIncidentTypePriority(input)
      .pipe(tap(() => (this.incidentPriorityType$ = undefined)));
  }

  permitsByAccountId() {
    if (this.permits.observers.length === 0) {
      return this.accountApiService.permitsByAccountId().pipe(tap(res => this.permits.next(res)));
    }
    return this.permits$;
  }
  updatePermitsByAccountId(input: AccountPermitInput[]) {
    return this.accountApiService.updatePermitsByAccountId(input);
  }
}
