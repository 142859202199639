import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AutocompleteModule } from '@wc/wc-ui/src/components/autocomplete/autocomplete.module';
import { WcTooltipModule } from '@wc/wc-ui/src/components/tooltip/tooltip.module';
import { WcAutocompleteV2Module } from '@wc/wc-ui/src/form-components/autocomplete-v2/autocomplete-v2.module';
import { WcSelectControlModule as WcSelectControlModuleOld } from '@wc/wc-ui/src/form-components/select/select.module';
import { WcSelectModule } from '@wc/wc-ui/src/form-components/wc-select/wc-select.module';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { MitigationComponentModule } from '../../form-controls/mitigation/mitigation-component.module';
import { AssociatedUnitComponent } from './associated-unit.component';

const Materials = [MatIconModule, MatButtonModule];

@NgModule({
  declarations: [AssociatedUnitComponent],
  imports: [
    CommonModule,
    WcBaseControlFieldModule,
    TranslateModule,
    Materials,
    WcSelectControlModuleOld,
    WcSelectModule,
    WcAutocompleteV2Module,
    AutocompleteModule,
    MitigationComponentModule,
    WcTooltipModule,
  ],
  exports: [AssociatedUnitComponent],
})
export class AssociatedUnitModule {}
