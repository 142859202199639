<div
  data-cy-id="media-thumbnail"
  class="media-thumbnail-component media-placeholder"
  [class.tablet-mode]="isTabletMode">
  <i
    data-cy-id="delete-img-button"
    *ngIf="isEditMode"
    class="media-thumbnail-component__remove-button fas fa-times-circle"
    (click)="deleteMedia()">
  </i>
  <i *ngIf="isExternalSource" class="media-thumbnail-component__external-icon fas fa-external-link"> </i>
  <div *ngIf="isNewFile && showNewIndication && !hideNewBadge" class="media-thumbnail-component__new-badge">
    {{ 'new' | translate }}
  </div>

  <ng-container *ngIf="mediaType === mediaTypeEnum.IMAGE; else docTmpl">
    <img [ngClass]="customObjectFit" #imageRef [src]="mediaEntity?.media?.url" (error)="mediaError($event)" />
    <span data-cy-id="media-thumbnail-label" *ngIf="isSnapshot" class="media-thumbnail-component__snapshot-label">
      {{ 'snapshotsLabels.' + snapshotLabel?.snapshotType | translate }} - {{ snapshotLabel?.id }}
    </span>
  </ng-container>

  <ng-template #videoTmpl>
    <video [ngClass]="customObjectFit" [controls]="allowVideoControls" #videoPlayer (canplay)="canPlay()">
      <source (error)="mediaError($event)" [src]="mediaEntity?.media?.url" type="video/mp4" />
    </video>
    <ng-container *ngIf="isVideoReady && !allowVideoControls">
      <i class="media-thumbnail-component__play-icon fas fa-play"></i>
      <span class="media-thumbnail-component__duration-tag">
        {{ videoPlayer.duration * 1000 | date: 'mm:ss' }}
      </span>
    </ng-container>
  </ng-template>

  <ng-template #docTmpl>
    <ng-container *ngIf="mediaType === mediaTypeEnum.DOCUMENT; else videoTmpl">
      <div class="doc-file-thumbnail">
        <i class="doc-file-icon-position doc-file-icon"></i>
        <div [class.is-in-file-modal-desktop]="isInSliderInFileModal && !isTabletMode" class="doc-file-title">
          {{ (mediaEntity ? mediaEntity.media.key : fileEntity.name) | mediaTitle: !!mediaEntity }}
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
