<div
  [id]="cause.affectingEventId"
  class="affected-service-cause-card heap-desktop-affect-service-cause-event-click"
  *ngFor="let cause of affectingEvents"
  (click)="clickOnAffectedCause(cause)"
  [class.highlighted-alert-card]="selectedCauseId === cause.affectingEventId">
  <ng-container [ngSwitch]="cause.affectingEntityType">
    <div
      class="affected-service-cause-card__cause-icon-container"
      [ngClass]="affectingEntityType.Incident"
      *ngSwitchCase="affectingEntityType.Incident">
      <div class="affected-service-cause-card__type-badge">IN</div>
      <wc-icon [iconName]="cause.additionalInfo?.incidentType?.toLowerCase()"></wc-icon>
    </div>
    <div
      class="affected-service-cause-card__cause-icon-container"
      [ngClass]="affectingEntityType.IrregularCongestion.toLowerCase()"
      *ngSwitchCase="affectingEntityType.IrregularCongestion">
      <div class="affected-service-cause-card__type-badge">IR</div>
      <wc-icon [iconName]="'congestion-irregular'"></wc-icon>
    </div>
    <div
      class="affected-service-cause-card__cause-icon-container"
      [ngClass]="affectingEntityType.WeatherAlert.toLowerCase()"
      *ngSwitchCase="affectingEntityType.WeatherAlert">
      <div class="affected-service-cause-card__type-badge">WE</div>
      <wc-icon class="weather-icon" [iconName]="cause.additionalInfo.weatherAlertSubtype?.toLowerCase()"></wc-icon>
    </div>
    <div
      class="affected-service-cause-card__cause-icon-container"
      [ngClass]="affectingEntityType.Construction"
      *ngSwitchCase="affectingEntityType.Construction">
      <div class="affected-service-cause-card__type-badge">CO</div>
      <i class="icon"> </i>
    </div>
    <div
      class="affected-service-cause-card__cause-icon-container"
      [ngClass]="affectingEntityType.SpecialEvent"
      *ngSwitchCase="affectingEntityType.SpecialEvent">
      <div class="affected-service-cause-card__type-badge">SE</div>
      <wc-icon [iconName]="'road_closure'"></wc-icon>
    </div>
    <div
      class="affected-service-cause-card__cause-icon-container"
      [ngClass]="affectingEntityType.RoadClosure"
      *ngSwitchCase="affectingEntityType.RoadClosure">
      <div class="affected-service-cause-card__type-badge">RC</div>
      <wc-icon [iconName]="'road_closure'"></wc-icon>
    </div>
  </ng-container>

  <div *ngIf="!cause.additionalInfo?.title; else showTitle" class="affected-service-cause-card__cause-address">
    {{ cause.address?.corridor }} {{ cause.address?.direction || '' | translate }} {{ cause.address?.crossroad }}
    {{ cause.address?.orientation ? (cause.address?.orientation || '' | translate) : '' }}
    {{ cause.additionalInfo?.address ? '' : cause.additionalInfo?.title }}
  </div>
  <ng-template #showTitle>
    <div class="affected-service-cause-card__cause-address">
      {{ cause.additionalInfo?.title }}
    </div>
  </ng-template>

  <div class="affected-service-cause-card__cause-details">
    <ng-container [ngSwitch]="cause.affectingEntityType">
      <ng-container *ngSwitchCase="affectingEntityType.Incident">
        <span class="affected-service-cause-card__cause-details--border-right"> {{ cause.affectingEventId }}</span>
        <span> {{ 'incidentTypes.' + cause.additionalInfo?.incidentType | translate }}</span>
        <span
          *ngIf="cause.additionalInfo?.incidentSubtype"
          class="affected-service-cause-card__cause-details--border-left">
          {{ 'incidentSubTypes.' + cause.additionalInfo?.incidentSubtype | translate }}</span
        >
      </ng-container>
      <ng-container *ngSwitchCase="affectingEntityType.IrregularCongestion">
        <span> {{ 'mapLayers.irregularCongestion' | translate }} </span>
        <span class="affected-service-cause-card__length-badge">
          {{ cause.additionalInfo.lengthMeters | distanceFormat }}
        </span>
      </ng-container>
      <ng-container *ngSwitchCase="affectingEntityType.WeatherAlert">
        <span>
          {{ 'weatherEvents.weatherAlertType.' + cause.additionalInfo.weatherAlertSubtype.toLowerCase() | translate }}
        </span>
        <span
          class="weather-severity-badge affected-service-cause-card__weather-severity-badge"
          [ngClass]="cause.additionalInfo.severity">
          {{ 'weatherEvents.severity.' + cause.additionalInfo.severity | translate }}
        </span>
      </ng-container>
      <ng-container *ngSwitchCase="affectingEntityType.Construction">
        <span class="affected-service-cause-card__cause-details--border-right"> {{ cause.affectingEventId }}</span>
        <span> {{ 'construction' | translate }} </span>
      </ng-container>
      <ng-container *ngSwitchCase="affectingEntityType.SpecialEvent">
        <span class="affected-service-cause-card__cause-details--border-right"> {{ cause.affectingEventId }}</span>
        <span> {{ 'specialEvent' | translate }} </span>
      </ng-container>
      <ng-container *ngSwitchCase="affectingEntityType.RoadClosure">
        <span class="affected-service-cause-card__cause-details--border-right"> {{ cause.affectingEventId }}</span>
        <span> {{ 'roadClosure' | translate }} </span>
      </ng-container>
    </ng-container>
  </div>
</div>
