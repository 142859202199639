/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { AffectedTransitUnit, VehicleType } from '@wc/core';
import {
  AffectedServiceIUEntity,
  AffectedServicesStoreNames,
  AffectedUnitServicesStoreEntity,
  TransitSubTypeNames,
} from '@wc/wc-models/src';
import { map } from 'rxjs/operators';
import { EntitiesServiceV2 } from '../../services/entities.service';

export interface AffectedUnitServiceState extends EntityState<AffectedUnitServicesStoreEntity, number> {}
export interface AffectedUnitServiceUIState extends EntityState<AffectedServiceIUEntity> {}

enum UnitColorsEnum {
  Pink1 = '#c883d8',
  Purple1 = '#8636a2',
}
@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: AffectedServicesStoreNames.AffectedUnitServices, idKey: 'unitId' })
export class AffectedUnitServicesStore extends EntityStore<AffectedUnitServiceState> {
  ui!: EntityUIStore<AffectedUnitServiceUIState>;
  constructor(private entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$
      .pipe(map(({ AFFECTED_UNIT_SERVICES }) => AFFECTED_UNIT_SERVICES || []))
      .subscribe(UIEntities => this.ui.upsertMany(UIEntities));

    entitiesService.getDiff$
      .pipe(
        map(({ modified, removed }) => ({
          removed: removed?.AFFECTED_UNIT_SERVICES,
          modified: modified.AFFECTED_UNIT_SERVICES,
        }))
      )
      .subscribe(({ removed, modified }) => {
        if (modified) {
          this.upsertMany(modified);
          this.ui.upsertMany(modified?.map(route => this.getUIEntity(route)));
        }
        if (removed) this.remove(removed);
      });

    entitiesService.activeEntityIds$
      .pipe(map(({ AFFECTED_ROUTE_SERVICES }) => AFFECTED_ROUTE_SERVICES || []))
      .subscribe(id => {
        this.ui.setActive(id);
      });
  }

  private getUIEntity(unit: Partial<AffectedTransitUnit>): AffectedServiceIUEntity {
    return {
      isViewed: this.entitiesService
        .getSavedViewedAffectedServicesID('affectedTransitUnits')
        .some(id => id === unit.unitId),
      affectedServiceType: 'unit',
      id: unit.unitId,
      color: unit.type === VehicleType.TransitOnDemandUnit ? UnitColorsEnum.Pink1 : UnitColorsEnum.Purple1,
      iconName: `${
        unit.type === VehicleType.TransitOnDemandUnit
          ? TransitSubTypeNames.transit_on_demand_unit
          : TransitSubTypeNames.transit_demand_response_unit
      }`,
      displayId: unit.displayId,
      affectingEvents: unit.affectingEvents,
      unitId: unit.unitId,
      type: unit.type,
    };
  }
}
