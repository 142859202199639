<mat-form-field
  class="date-field-control"
  appearance="none"
  floatLabel="never"
  [class.invalid-field]="fieldFormControl.invalid && fieldFormControl.touched"
  [class.tablet-mode]="isTabletMode">
  <div
    class="date-input-wrapper"
    [ngClass]="{
      open: isPickerOpen,
      invalid: isParentInvalid || formControl.invalid,
      focus: isInFocus && !readOnly,
      'read-only': readOnly
    }">
    <input
      data-cy-id="date-field-input"
      #dateField
      class="date-field-input"
      matInput
      [min]="minDate"
      [max]="maxDate"
      [owlDateTime]="dateTime"
      [owlDateTimeTrigger]="dateTime"
      [readonly]="readOnly"
      autocomplete="off"
      [formControl]="$any(fieldFormControl)"
      (dateTimeChange)="valueChanged()"
      data-focus
      (focus)="onFocus()"
      (blur)="onBlur()" />
    <i class="far fa-calendar-alt"></i>
  </div>
  <owl-date-time
    [pickerType]="'calendar'"
    (afterPickerOpen)="handleDatePickerOpen()"
    (afterPickerClosed)="handleDatePickerClosed()"
    #dateTime></owl-date-time>

  <mat-error *ngIf="validationError && showErrorMsg">
    {{ 'validationErrorMessages.' + $any(validationError) | translate }}
  </mat-error>
  <mat-error *ngIf="fieldFormControl.errors && fieldFormControl.errors.owlDateTimeParse">
    {{ 'validationErrorMessages.invalid' | translate }}
  </mat-error>
</mat-form-field>
