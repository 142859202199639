<div class="loader-overlay" *ngIf="showLoader && isDelay" id="loader-temp">
  <div class="loading-page-cont">
    <div class="loading-page">
      <div class="loading-lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
