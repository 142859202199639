<mat-form-field
  [floatLabel]="fieldData.floatLabel"
  [class.invalid-field]="formControl.invalid && formControl.touched"
  [class.tablet-mode]="isTabletMode"
  [class.language-rtl]="uiStore.isDirectionRtl">
  <mat-label class="field-label">
    <span class="required-section" *ngIf="fieldData.required">*</span>
    {{ fieldData.label | translate }}
  </mat-label>

  <input
    type="tel"
    matInput
    [formControl]="$any(fieldFormControl)"
    [placeholder]="fieldData.placeholder"
    [errorStateMatcher]="matcher"
    (input)="changed()"
    (blur)="doBlur()"
    data-focus />

  <mat-error *ngIf="validationError">
    {{
      'numberValidationErrorMessages.' + $any(validationError)
        | translate
          : {
              min: fieldFormControl.errors?.min?.min,
              max: fieldFormControl.errors?.max?.max,
              minLength: fieldFormControl.errors?.minlength?.requiredLength,
              maxLength: fieldFormControl.errors?.maxlength?.requiredLength
            }
    }}
  </mat-error>
</mat-form-field>
