/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { IncidentStoreEntity, IncidentUIEntity } from '@wc/wc-models/src';
import { map } from 'rxjs/operators';
import { LayerType } from '../../../../../../core/models/gql.models';
import { EntitiesServiceV2 } from '../../../services/entities.service';

export interface IncidentState extends EntityState<IncidentStoreEntity, number> {}
export interface IncidentUIState extends EntityState<IncidentUIEntity> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: LayerType.Incident })
export class IncidentsStore extends EntityStore<IncidentState> {
  ui!: EntityUIStore<IncidentUIState>;
  constructor(entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$
      .pipe(map(({ INCIDENT }) => INCIDENT || []))
      .subscribe(UIIncidents => this.ui.upsertMany(UIIncidents));

    entitiesService.getDiff$
      .pipe(
        map(({ modified, removed }) => ({ removedIncidents: removed?.INCIDENT, modifiedIncidents: modified.INCIDENT }))
      )
      .subscribe(({ removedIncidents, modifiedIncidents }) => {
        if (modifiedIncidents) this.upsertMany(modifiedIncidents as IncidentStoreEntity[]);
        if (removedIncidents) this.remove(removedIncidents);
      });

    entitiesService.activeEntityIds$.pipe(map(({ INCIDENT }) => INCIDENT || [])).subscribe(id => {
      this.ui.setActive(id);
    });
  }
}
