import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DropFileModule } from '../drop-file/drop-file.module';
import { MediaFullScreenModule } from '../media-full-screen/media-full-screen.module';
import { MediaThumbnailModule } from '../media-thumbnail/media-thumbnail.module';
import { WcTooltipModule } from '../tooltip/tooltip.module';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { MediaSectionComponent } from './media-section.component';
import { MatTabsModule } from "@angular/material/tabs";

@NgModule({
  declarations: [MediaSectionComponent],
    imports: [
        CommonModule,
        MediaThumbnailModule,
        TranslateModule,
        DropFileModule,
        MediaFullScreenModule,
        WcIconModule,
        WcTooltipModule,
        MatTabsModule,
    ],
  exports: [MediaSectionComponent],
})
export class MediaSectionModule {}
