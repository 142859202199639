import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { LineString, Point, Polygon } from 'ol/geom';
import { LineString, Point, Polygon } from '@wc/core/models';
import { WcMapConfig } from '../../../../core/livemap.config';
import { WcMapConfigModel } from '../../../../core/models';
import * as Enums from '../../../../core/models/enums';
import { WcMapComponent } from '../../../../wc-map/src/lib/wc-map.component';
@Component({
  selector: 'wc-map-drawing-modal',
  templateUrl: './map-drawing-modal.component.html',
  styleUrls: ['./map-drawing-modal.component.scss'],
})
export class MapDrawingModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('wcMapComp') wcMapComp!: WcMapComponent;
  wcMapConfig: WcMapConfigModel = WcMapConfig;
  _formViewModeEnum = Enums.FormViewMode;
  get formViewModeEnum() {
    return this._formViewModeEnum;
  }
  formViewMode: Enums.FormViewMode;
  geometry?: Point | Polygon | LineString;
  lastGeometry?: Point | Polygon | LineString;
  // initialGeometry : Point | Polygon | LineString;
  dialogTitle = '';

  constructor(public dialogRef: MatDialogRef<MapDrawingModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.formViewMode = this.data.formViewMode;
    this.geometry = data.details['geometry'];
    this.lastGeometry = this.geometry;
    this.dialogTitle = data.dialogTitle;
  }

  ngOnInit(): void {
    // console.log(this.wcMapComp);
    // this.wcMapComp.ready.subscribe( wcMap => {//.pipe(take(1))
    //   this.wcMapComp.init(this.wcMapConfig, {mouseEventsMode: 'draw'});
    //   this.wcMapComp.setMapLocation(this.wcMapConfig.mapCenter, {padding: [100,100,100,100]});
    //   console.log(this.geometry);
    //   // if (this.geometry) {
    //     this.wcMapComp.wcMapDrawService.edit(this.geometry);
    //   // } else {
    //   //   this.wcMapComp.wcMapDrawService.create(this.geometry);
    //   // }
    // })
  }

  ngAfterViewInit() {
    // console.log(this.wcMapComp.map)
    this.dialogRef.afterOpened().subscribe(() => {
      this.wcMapComp.init(this.wcMapConfig, { mouseEventsMode: 'draw' });
      let mapCenter = this.wcMapConfig.mapCenter;
      if (this.geometry?.coordinates) mapCenter = [this.geometry.coordinates as [][]];
      this.wcMapComp.setMapLocation(mapCenter, { padding: [100, 100, 100, 100] });
      this.wcMapComp.wcMapDrawService.geometry.subscribe(geometry => {
        this.geometry = geometry;
      });

      if (this.geometry) {
        this.lastGeometry = this.geometry;
        this.wcMapComp.wcMapDrawService.edit(this.geometry);
      } else {
        this.wcMapComp.wcMapDrawService.create('Polygon');
      }
    });
  }

  closeModal() {
    // this.wcMapComp.wcMapDrawService.geometry.unsubscribe();
    // if (this.geometry !== null) this.wcMapComp.wcMapDrawService.view(this.lastGeometry);
    this.dialogRef.close(this.lastGeometry ? { geometry: this.lastGeometry } : null);
  }

  confirmCloseModal() {
    // if (this.geometry !== null) this.wcMapComp.wcMapDrawService.view(this.geometry);
    this.dialogRef.close(this.geometry ? { geometry: this.geometry } : null);

    // if (this.formViewMode === Enums.FormViewMode.New) {
    //   this.closeModal();
    //   return;
    // }
    // this.confirmService.showConfirmDialog(Enums.ConfirmationModalType.DiscardChanges, null,
    //   (confirmedPositive) => {
    //     if (!confirmedPositive) {
    //       this.closeModal();
    //     }
    //   });
  }

  enableEditMode() {
    this.formViewMode = this.formViewModeEnum.Edit;
  }

  clear() {
    // this.dialogRef.close(null);
    this.wcMapComp.wcMapDrawService.resetLayer();
    this.geometry = undefined;
  }

  ngOnDestroy(): void {
    // this.wcMapComp.wcMapDrawService.geometry.unsubscribe();
  }
}
