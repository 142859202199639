import { TransitLayersConfig } from '@wc/core/livemap.transit.config';
import { WeatherLayersConfig } from '@wc/core/livemap.weather.config';
import { environment } from './environments/environment';
import { UnitsLayersConfig } from './livemap.unitsLayers.config';
import { EntityStatusStyle, LiveMapEntityUIState, MapLayerTreeMenu, WcMapConfigModel } from './models';

export const MapLayersTreeMenu: MapLayerTreeMenu[] = [
  {
    groupName: 'Events',
    layersGroups: [
      {
        name: 'incidents',
        appFeatureName: ['INCIDENT:READ'],
        layers: [
          'stalled_vehicle',
          'abandoned_vehicle',
          'crash',
          'left_on_arrival',
          'debris',
          'hazard',
          'vehicle_on_fire',
          'police_activity',
          'ems',
          'damage',
          'traffic_stop',
          'wrong_way',
          'other',
          'unidentified', // hidden in map-layers-panel.html
          'traffic_anomaly',
        ],
      },
      {
        name: 'traffic_disruption',
        appFeatureName: ['ROAD_CLOSURE:READ', 'CONSTRUCTION:READ', 'SPECIAL_EVENT:READ'],
        layers: ['road_closure', 'construction', 'special_event'],
      },
      // {
      //   name: 'disruptions',
      //   // layers: ['construction', 'ems', 'events']
      // },
      // {
      //   name: 'crashPrediction',
      //   // layers: ['crashPrediction']
      // },
      {
        name: 'fe_weather_events',
        appFeatureName: ['WEATHER:READ'],
        layers: [
          'air',
          'fire',
          'floods',
          'fog',
          'marine',
          'specific_road_conditions',
          'temperature',
          'thunderstorms',
          'tornado',
          'tropical',
          'wind',
          'winter',
        ],
      },
      {
        name: 'fe_congestion',
        appFeatureName: ['CONGESTION:READ'],
        layers: ['congestion', 'irregular_congestion'],
      },
      {
        name: 'fe_crash_risk',
        appFeatureName: ['CRASH_RISK:READ'],
        layers: ['crash_risk_area_active', 'crash_risk_area_predicted'],
      },
    ],
  },

  {
    groupName: 'Assets',
    layersGroups: [
      {
        name: 'cameras',
        layers: ['camera'],
        appFeatureName: ['CAMERA:READ'],
      },
      {
        name: 'dms',
        layers: ['dms'],
        appFeatureName: ['DMS:READ'],
      },
      {
        name: 'units',
        appFeatureName: ['UNIT:READ'],
        layers: [
          'fsp_unit',
          'highway_police_unit',
          'city_police_unit',
          'fire_department_unit',
          'construction_unit',
          'ems_unit',
          'maintenance_unit',
          'street_sweep_unit',
          'snow_plow_unit',
          'other_unit',
          //, 'city_police', 'highway_police', 'snow_plow'
          // , 'buses', 'snowplows', 'maintenance'
          // CITY_POLICE
          // CONSTRUCTION
          // EMS
          // FIRE_DEPARTMENT
          // FSP
          // HIGHWAY_POLICE
          // MAINTENANCE
          // SNOW_PLOW
          // STREET_SWEEP
          // OTHER
        ],
      },
    ],
  },
];

export const EditModeDefaultStyle: {
  [uiState in LiveMapEntityUIState]?: Array<EntityStatusStyle>;
} = {
  default: [
    {
      shape: 'circleIcon',
      color: '#0F1832',
      radius: 7,
      opacity: 0.4,
      stroke: { color: '#0F1832', width: 10, opacity: 0.2 },
    },
  ],
  selected: [
    {
      shape: 'circleIcon',
      color: '#0F1832',
      radius: 7,
      opacity: 0.4,
      stroke: { color: '#0F1832', width: 10, opacity: 0.2 },
    },
  ],
  hover: [
    {
      shape: 'circleIcon',
      color: '#0F1832',
      radius: 7,
      opacity: 0.4,
      stroke: { color: '#0F1832', width: 10, opacity: 0.2 },
    },
  ],
};

export const WcMapConfig: WcMapConfigModel = {
  tilesSrc: environment?.map_server,
  style: {
    day: 'basic041219',
    night: 'night_mode', // 'osm-liberty', //night_mode',
  },
  currentStyle: 'day',
  backgroundColor: '#E6E4DE',
  backgroundDarkColor: '#030303',
  // center: [[-115.3458023071289, 36.28261318875545], [-115.01243591308594, 36.07046978723051]],
  mapCenter: [],
  centerOptions: { padding: [0, 0, 0, 0], bufferSize: 100 },
  tablet: { iconScale: 1 },
  defaultIconStyle: {
    // src: 'assets/icons/police-patrol.svg',
    anchor: [0.5, 1],
    scale: 0.6,
  },
  appendedIconStyle: {
    shape: 'marker',
    icon: { iconName: 'main_event_mark', anchor: [-0.2, 2.6], scale: 1 },
  },
  defaultLayerZIndex: 10,
  defaultLayersVisibility: [
    'crash',
    'debris',
    'stalled_vehicle',
    'abandoned_vehicle',
    'left_on_arrival',
    'hazard',
    'vehicle_on_fire',
    'police_activity',
    'ems',
    'damage',
    'traffic_stop',
    'wrong_way',
    'other',
    'buses',
    'snowplows',
    'maintenance',
    'traffic_anomaly',
  ],
  layers: {
    ...UnitsLayersConfig,
    ...TransitLayersConfig,
    ...WeatherLayersConfig,
    ...{
      workspace: {
        zIndex: 10,
        polygonStatusStyle: {
          default: {
            default: [
              { shape: 'lineSolid', color: '#D2A890', darkColor: '#5f9ea0', width: 3 },
              {
                shape: 'lineSolid',
                color: '#F3DBCE',
                darkColor: '#387ab7',
                width: 1,
                label: {
                  textSrcField: 'title',
                  placement: 'line',
                  color: '#9A684C',
                  darkColor: '#387ab7',
                  outlineColor: '#FDF1EA',
                  darkOutlineColor: '#fff',
                  size: 16,
                  maxAngle: 0.7853981633974483,
                  textAlign: 'center',
                },
              },
            ],
          },
          inactive: { default: [] },
        },
      },
      interactive: {
        zIndex: 999999,
        pointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                zIndex: 999999,
                icon: {
                  src: 'assets/icons/map-cursor-icon.svg',
                  scale: 1,
                  anchor: [0.5, 0.5],
                },
              },
              {
                shape: 'circleIcon',
                color: '#ffffff',
                radius: 20,
                opacity: 0.01,
                stroke: { color: '#00000000', width: 0 },
              },
            ],
          },
          confirmed: {
            default: [
              // { shape: 'circleIcon', color: "#359edc", radius: 20, opacity: 0.5 },
              {
                shape: 'marker',
                zIndex: 999999,
                icon: {
                  src: 'assets/icons/map-cursor-icon.svg',
                  scale: 1,
                  anchor: [0.5, 0.5],
                },
              },
            ],
          },
        },
      },
      draw: {
        pointStatusStyle: {
          default: {
            default: [
              // { shape: 'circleIcon', color: "#359edc", radius: 20, opacity: 0.5, stroke: { color: "#359edc", width: 2 } },
              {
                shape: 'marker',
                icon: {
                  src: 'assets/icons/map-cursor-icon.svg',
                  scale: 1,
                  anchor: [0.5, 0.5],
                },
              },
            ],
          },
          // modify_point: [
          //   { shape: 'circleIcon', color: "#359edc", radius: 20, opacity: 0.5 },
          //   { shape: 'marker', icon: {src: 'assets/icons/addonmap.svg', scale: 1}},
          // ],
          modify_geometry: {
            default: [
              // { shape: 'lineSolid', color: "#359edc", width: 3 },
              // { shape: 'circleIcon', color: "#359edc", radius: 5, stroke: { color: "#fff", width: 2 }, zIndex: 10 },
              {
                shape: 'marker',
                icon: {
                  src: 'assets/icons/map-cursor-icon.svg',
                  scale: 1,
                  anchor: [0.5, 0.5],
                },
              },
            ],
          },
          modify_pointer: {
            default: [
              // { shape: 'lineSolid', color: "#359edc", width: 3 },
              // { shape: 'circleIcon', color: "#359edc", radius: 5, stroke: { color: "#fff", width: 2 }, zIndex: 10 },
              {
                shape: 'marker',
                zIndex: 999999,
                icon: {
                  src: 'assets/icons/map-cursor-icon.svg',
                  scale: 1,
                  anchor: [0.5, 0.5],
                },
              },
            ],
          },

          // modify_geometry_pointer: [
          //   { shape: 'circleIcon', color: "#359edc", radius: 20, opacity: 0.5 },
          //   { shape: 'marker', icon: {src: 'assets/icons/addonmap.svg', scale: 1}},
          // ],
        },
        multiLineStringStatusStyle: {
          default: {
            default: [
              { shape: 'lineSolid', color: '#359edc', width: 3 },
              {
                shape: 'circleIcon',
                color: '#fff',
                radius: 6,
                stroke: { color: '#359edc', width: 2 },
              },
            ],
          },
          modify_geometry: {
            default: [
              { shape: 'lineSolid', color: '#359edc', width: 3 },
              {
                shape: 'circleIcon',
                color: '#fff',
                radius: 6,
                stroke: { color: '#359edc', width: 2 },
              },
            ],
          },
          view_geometry: {
            default: [
              { shape: 'area', color: '#359edc', opacity: 0.25 },
              { shape: 'lineSolid', color: '#359edc', width: 3 },
            ],
          },
          modify_geometry_pointer: {
            default: [
              { shape: 'lineSolid', color: '#359edc', width: 3 },
              {
                shape: 'circleIcon',
                color: '#fff',
                radius: 6,
                stroke: { color: '#359edc', width: 2 },
              },
            ],
          },
        },
        lineStringStatusStyle: {
          default: {
            default: [
              { shape: 'lineSolid', color: '#359edc', width: 3 },
              {
                shape: 'circleIcon',
                color: '#fff',
                radius: 6,
                stroke: { color: '#359edc', width: 2 },
              },
            ],
          },
          modify_geometry: {
            default: [
              { shape: 'lineSolid', color: '#359edc', width: 3 },
              {
                shape: 'circleIcon',
                color: '#fff',
                radius: 6,
                stroke: { color: '#359edc', width: 2 },
              },
            ],
          },
          view_geometry: {
            default: [
              { shape: 'area', color: '#359edc', opacity: 0.25 },
              { shape: 'lineSolid', color: '#359edc', width: 3 },
            ],
          },
          modify_geometry_pointer: {
            default: [
              { shape: 'lineSolid', color: '#359edc', width: 3 },
              {
                shape: 'circleIcon',
                color: '#fff',
                radius: 6,
                stroke: { color: '#359edc', width: 2 },
              },
            ],
          },
        },
        polygonStatusStyle: {
          default: {
            default: [
              { shape: 'area', color: '#359edc', opacity: 0.25 },
              { shape: 'lineSolid', color: '#359edc', width: 3 },
              {
                shape: 'circleIcon',
                color: '#fff',
                radius: 6,
                stroke: { color: '#359edc', width: 2 },
              },
            ],
          },
          modify_geometry: {
            default: [
              { shape: 'area', color: '#359edc', opacity: 0.25 },
              { shape: 'lineSolid', color: '#359edc', width: 3 },
              {
                shape: 'circleIcon',
                color: '#fff',
                radius: 6,
                stroke: { color: '#359edc', width: 2 },
              },
            ],
          },
          view_geometry: {
            default: [
              { shape: 'area', color: '#359edc', opacity: 0.25 },
              { shape: 'lineSolid', color: '#359edc', width: 4 },
            ],
          },
          modify_geometry_pointer: {
            default: [
              { shape: 'area', color: '#359edc', opacity: 0.25 },
              { shape: 'lineSolid', color: '#359edc', width: 3 },
              {
                shape: 'circleIcon',
                color: '#fff',
                radius: 6,
                stroke: { color: '#359edc', width: 2 },
              },
            ],
          },
        },
      },
      camera: {
        zIndex: 8,
        clusterDistance: 40,
        pointStatusStyle: {
          default: {
            default: [
              { shape: 'marker', icon: { iconName: 'cameras_active' } },
              {
                shape: 'marker',
                icon: { iconName: 'counter', zIndex: 2, anchor: [0, 2.3] },
              },
              {
                shape: 'lineSolid',
                color: '#ffff',
                width: 1,
                label: {
                  offsetX: 11,
                  offsetY: -36,
                  outlineColor: '#707EA6',
                  darkOutlineColor: '#707EA6',
                  textSrcField: 'length',
                  placement: 'point',
                  color: '#ffff',
                  darkColor: '#ffff',
                  size: 10,
                  textAlign: 'center',
                },
              },
            ],
            hover: [{ shape: 'marker', icon: { iconName: 'cameras_active.hover' } }],
            selected: [
              {
                shape: 'marker',
                icon: { iconName: 'select_highlight_circle', zIndex: -1, anchor: [0.1, 1.9] },
              },
              {
                shape: 'marker',
                icon: { iconName: 'cameras_active.selected', zIndex: 1 },
              },

              {
                shape: 'marker',
                icon: { iconName: 'counter', zIndex: 2, anchor: [0, 2.3] },
              },

              {
                shape: 'lineSolid',
                color: '#ffff',
                width: 1,
                label: {
                  offsetX: 11,
                  offsetY: -36,
                  outlineColor: '#707EA6',
                  darkOutlineColor: '#707EA6',
                  textSrcField: 'length',
                  placement: 'point',
                  color: '#ffff',
                  darkColor: '#ffff',
                  size: 10,
                  textAlign: 'center',
                },
              },
            ],
          },
          inactive: {
            default: [{ shape: 'marker', icon: { iconName: 'cameras_inactive' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'cameras_inactive.hover' } }],
            selected: [
              {
                shape: 'marker',
                icon: { iconName: 'cameras_inactive.selected' },
              },
            ],
          },
        },
      },
      dms: {
        clusterDistance: 20,
        pointStatusStyle: {
          default: {
            default: [
              { shape: 'marker', icon: { iconName: 'dms_inactive' } },
              {
                shape: 'marker',
                icon: { iconName: 'counter', zIndex: 2, anchor: [0, 2.3] },
              },
              {
                shape: 'lineSolid',
                color: '#ffff',
                width: 1,
                label: {
                  offsetX: 11,
                  offsetY: -36,
                  outlineColor: '#707EA6',
                  darkOutlineColor: '#707EA6',
                  textSrcField: 'length',
                  placement: 'point',
                  color: '#ffff',
                  darkColor: '#ffff',
                  size: 10,
                  textAlign: 'center',
                },
              },
            ],
            hover: [{ shape: 'marker', icon: { iconName: 'dms_inactive.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'dms_inactive.selected' } }],
          },
          active: {
            default: [
              { shape: 'marker', icon: { iconName: 'dms_active' } },
              {
                shape: 'marker',
                icon: { iconName: 'counter', zIndex: 2, anchor: [0, 2.3] },
              },
              {
                shape: 'lineSolid',
                color: '#ffff',
                width: 1,
                label: {
                  offsetX: 11,
                  offsetY: -36,
                  outlineColor: '#707EA6',
                  darkOutlineColor: '#707EA6',
                  textSrcField: 'length',
                  placement: 'point',
                  color: '#ffff',
                  darkColor: '#ffff',
                  size: 10,
                  textAlign: 'center',
                },
              },
            ],
            hover: [{ shape: 'marker', icon: { iconName: 'dms_active.hover' } }],
            selected: [
              {
                shape: 'marker',
                icon: { iconName: 'select_highlight_circle', zIndex: -1, anchor: [0.1, 1.9] },
              },
              { shape: 'marker', icon: { iconName: 'dms_active.selected' } },
              {
                shape: 'marker',
                icon: { iconName: 'counter', zIndex: 2, anchor: [0, 2.3] },
              },
              {
                shape: 'lineSolid',
                color: '#ffff',
                width: 1,
                label: {
                  offsetX: 11,
                  offsetY: -36,
                  outlineColor: '#707EA6',
                  darkOutlineColor: '#707EA6',
                  textSrcField: 'length',
                  placement: 'point',
                  color: '#ffff',
                  darkColor: '#ffff',
                  size: 10,
                  textAlign: 'center',
                },
              },
            ],
          },
        },
      },
      abandoned_vehicle: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'abandoned_vehicle' } }],
            hover: [
              {
                shape: 'marker',
                icon: { iconName: 'abandoned_vehicle.hover' },
              },
            ],
            selected: [
              {
                shape: 'marker',
                icon: { iconName: 'abandoned_vehicle.selected' },
              },
            ],
          },
          editMode: EditModeDefaultStyle,
          unconfirmed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'abandoned_vehicle.unconfirmed' },
              },
            ],
          },
          completed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'abandoned_vehicle.completed' },
              },
            ],
          },
          rejected: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'abandoned_vehicle.rejected' },
              },
            ],
          },
        },
      },
      crash: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'crash' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'crash.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'crash.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unconfirmed: {
            default: [{ shape: 'marker', icon: { iconName: 'crash.unconfirmed' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'crash.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'crash.rejected' } }],
          },
        },
      },

      debris: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'debris' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'debris.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'debris.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          confirmed: {
            default: [{ shape: 'marker', icon: { iconName: 'debris' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'debris.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'debris.selected' } }],
          },
          unconfirmed: {
            default: [{ shape: 'marker', icon: { iconName: 'debris.unconfirmed' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'debris.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'debris.rejected' } }],
          },
        },
      },
      hazard: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'hazard' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'hazard.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'hazard.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unconfirmed: {
            default: [{ shape: 'marker', icon: { iconName: 'hazard.unconfirmed' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'hazard.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'hazard.rejected' } }],
          },
        },
      },
      weather_alert: {
        // placeholder for general layer , not in use
        pointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: '', srcByEntityProperty: 'type' },
              },
              { shape: 'marker', icon: { iconName: 'active' } },
            ],
          },
        },
      },
      stalled_vehicle: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'disabled_vehicle' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'disabled_vehicle.hover' } }],
            selected: [
              {
                shape: 'marker',
                icon: { iconName: 'disabled_vehicle.selected' },
              },
            ],
          },
          editMode: EditModeDefaultStyle,
          unconfirmed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'disabled_vehicle.unconfirmed' },
              },
            ],
          },
          completed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'disabled_vehicle.completed' },
              },
            ],
          },
          rejected: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'disabled_vehicle.rejected' },
              },
            ],
          },
        },
      },
      damage: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'damage' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'damage.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'damage.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unconfirmed: {
            default: [{ shape: 'marker', icon: { iconName: 'damage.unconfirmed' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'damage.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'damage.rejected' } }],
          },
        },
      },
      traffic_stop: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'traffic_stop' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'traffic_stop.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'traffic_stop.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unconfirmed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'traffic_stop.unconfirmed' },
              },
            ],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'traffic_stop.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'traffic_stop.rejected' } }],
          },
        },
      },

      wrong_way: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'wrong_way' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'wrong_way.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'wrong_way.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unconfirmed: {
            default: [{ shape: 'marker', icon: { iconName: 'wrong_way.unconfirmed' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'wrong_way.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'wrong_way.rejected' } }],
          },
        },
      },

      other: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'other' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'other.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'other.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unconfirmed: {
            default: [{ shape: 'marker', icon: { iconName: 'other.unconfirmed' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'other.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'other.rejected' } }],
          },
        },
      },
      unidentified: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'unknown' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'unknown.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'unknown.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unconfirmed: {
            default: [{ shape: 'marker', icon: { iconName: 'unknown.unconfirmed' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'unknown.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'unknown.rejected' } }],
          },
        },
      },
      traffic_anomaly: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'unknown' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'unknown.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'unknown.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unconfirmed: {
            default: [{ shape: 'marker', icon: { iconName: 'unknown.unconfirmed' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'unknown.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'unknown.rejected' } }],
          },
        },
      },
      vehicle_on_fire: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'vehicle_on_fire' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'vehicle_on_fire.hover' } }],
            selected: [
              {
                shape: 'marker',
                icon: { iconName: 'vehicle_on_fire.selected' },
              },
            ],
          },
          editMode: EditModeDefaultStyle,
          unconfirmed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'vehicle_on_fire.unconfirmed' },
              },
            ],
          },
          completed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'vehicle_on_fire.completed' },
              },
            ],
          },
          rejected: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'vehicle_on_fire.rejected' },
              },
            ],
          },
        },
      },
      police_activity: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'police_activity' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'police_activity.hover' } }],
            selected: [
              {
                shape: 'marker',
                icon: { iconName: 'police_activity.selected' },
              },
            ],
          },
          editMode: EditModeDefaultStyle,
          unconfirmed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'police_activity.unconfirmed' },
              },
            ],
          },
          completed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'police_activity.completed' },
              },
            ],
          },
          rejected: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'police_activity.rejected' },
              },
            ],
          },
        },
      },
      ems: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'ems' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'ems.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'ems.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unconfirmed: {
            default: [{ shape: 'marker', icon: { iconName: 'ems.unconfirmed' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'ems.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'ems.rejected' } }],
          },
        },
      },
      left_on_arrival: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'left_on_arrival' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'left_on_arrival.hover' } }],
            selected: [
              {
                shape: 'marker',
                icon: { iconName: 'left_on_arrival.selected' },
              },
            ],
          },
          editMode: EditModeDefaultStyle,
          unconfirmed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'left_on_arrival.unconfirmed' },
              },
            ],
          },
          completed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'left_on_arrival.completed' },
              },
            ],
          },
          rejected: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'left_on_arrival.rejected' },
              },
            ],
          },
        },
      },
      road_closure: {
        startPointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'road_closure_path', anchor: [0.5, 0.6] } }],
            hover: [{ shape: 'marker', icon: { iconName: 'road_closure_path.hover', anchor: [0.5, 0.6] } }],
            selected: [{ shape: 'marker', icon: { iconName: 'road_closure_path.selected', anchor: [0.5, 0.6] } }],
          },
        },
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'road_closure' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'road_closure.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'road_closure.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'road_closure.completed' } }],
          },
        },
        lineStringStatusStyle: {
          default: {
            default: [
              { shape: 'lineSolid', color: '#fff', width: 4 },
              { shape: 'lineDash', color: '#690106', width: 4 },
            ],
          },
          active: {
            default: [
              { shape: 'lineSolid', color: '#fff', width: 4 },
              { shape: 'lineDash', color: '#690106', width: 4 },
            ],
          },
        },
        multiLineStringStatusStyle: {
          default: {
            default: [
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
            ],
            hover: [
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
            ],
            selected: [
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
            ],
          },
        },
        polygonStatusStyle: {
          default: {
            default: [
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
              { shape: 'area', color: '#359edc', opacity: 0.0001, zIndex: 1 },
            ],
          },
          active: {
            default: [
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
              { shape: 'area', color: '#359edc', opacity: 0.0001, zIndex: 1 },
            ],
          },
        },
      },

      congestion: {
        multiLineStringStatusStyle: {
          default: {
            default: [{ shape: 'lineSolid', color: '#2C008A', width: 4 }],
            hover: [
              { shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 },
              { shape: 'lineSolid', color: '#2C008A', width: 4 },
            ],
            selected: [
              { shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 },
              { shape: 'lineSolid', color: '#2C008A', width: 4 },
            ],
          },
        },
        startPointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'regular_congestion_start',
                  anchor: [0.5, 0.9],
                },
                zIndex: 15,
              },
            ],
            hover: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'regular_congestion_start.hover',
                  anchor: [0.5, 0.9],
                },
                zIndex: 15,
              },
            ],
            selected: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'regular_congestion_start.selected',
                  anchor: [0.5, 0.9],
                },
                zIndex: 15,
              },
            ],
          },
        },
        endPointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'regular_congestion_end',
                  anchor: [0.5, 0.5],
                },
                zIndex: 10,
              },
            ],
            hover: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'regular_congestion_end.hover',
                  anchor: [0.5, 0.5],
                },
                zIndex: 10,
              },
            ],
            selected: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'regular_congestion_end.selected',
                  anchor: [0.5, 0.5],
                },
                zIndex: 10,
              },
            ],
          },
        },
        pointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'regular_congestion_start',
                  anchor: [0.5, 1],
                },
              },
            ],
            hover: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'regular_congestion_start.hover',
                  anchor: [0.5, 1],
                },
              },
            ],
            selected: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'regular_congestion_start.selected',
                  anchor: [0.5, 1],
                },
              },
            ],
          },
        },
        lineStringStatusStyle: {
          default: {
            default: [{ shape: 'lineSolid', color: '#2C008A', width: 4 }],
            hover: [{ shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 }],
          },
          active: {
            default: [{ shape: 'lineSolid', color: '#2C008A', width: 4 }],
          },
        },
      },
      segment_temp: {
        hitTolerance: 5,

        startPointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'edge-of-segment',
                  anchor: [0.5, 0.5],
                },
                zIndex: 15,
              },
            ],
            hover: [],
            selected: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'edge-of-segment',
                  anchor: [0.5, 0.5],
                },
                zIndex: 15,
              },
            ],
          },
        },
        endPointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'edge-of-segment',
                  anchor: [0.5, 0.5],
                },
                zIndex: 15,
              },
            ],
            hover: [],
            selected: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'edge-of-segment',
                  anchor: [0.5, 0.5],
                },
                zIndex: 15,
              },
            ],
          },
        },

        lineStringStatusStyle: {
          default: {
            default: [
              { shape: 'lineSolid', color: '#0F1832', width: 6 },
              { shape: 'lineDash', color: '#FFFFFF', width: 2 },
            ],
            hover: [],
          },
          active: {
            default: [{ shape: 'lineSolid', color: '#2C008A', width: 4 }],
          },
        },
      },

      crash_risk_area_active: {
        multiLineStringStatusStyle: {
          default: {
            default: [
              { shape: 'lineSolid', color: '#000000', width: 6 },
              { shape: 'lineDash', color: '#F78A04', width: 4 },
            ],
            hover: [
              { shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 },
              { shape: 'lineSolid', color: '#000000', width: 6 },
              // { shape: 'lineSolid', color: "#590BFF", width: 3},
              { shape: 'lineDash', color: '#F78A04', width: 4 },
            ],
            selected: [
              { shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 },
              { shape: 'lineSolid', color: '#000000', width: 6 },
              // { shape: 'lineSolid', color: "#590BFF", width: 3},
              { shape: 'lineDash', color: '#F78A04', width: 4 },
            ],
          },
        },

        startPointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'crash_risk_start', anchor: [0.5, 0.9] },
                zIndex: 15,
              },
              {
                shape: 'marker',
                icon: { iconName: 'status_active', anchor: [-0.5, 3.1] },
                zIndex: 15,
              },
            ],
            hover: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'crash_risk_start.hover',
                  anchor: [0.5, 0.9],
                },
                zIndex: 15,
              },
              {
                shape: 'marker',
                icon: { iconName: 'status_active', anchor: [-0.5, 3.1] },
                zIndex: 15,
              },
            ],
            selected: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'crash_risk_start.selected',
                  anchor: [0.5, 0.9],
                },
                zIndex: 15,
              },
              {
                shape: 'marker',
                icon: { iconName: 'status_active', anchor: [-0.5, 3.1] },
                zIndex: 15,
              },
            ],
          },
        },
        endPointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'crash_risk_end', anchor: [0.5, 0.5] },
                zIndex: 10,
              },
            ],
            hover: [
              {
                shape: 'marker',
                icon: { iconName: 'crash_risk_end.hover', anchor: [0.5, 0.5] },
                zIndex: 10,
              },
            ],
            selected: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'crash_risk_end.selected',
                  anchor: [0.5, 0.5],
                },
                zIndex: 10,
              },
            ],
          },
        },
        pointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'crash_risk_start', anchor: [0.5, 1] },
              },
              {
                shape: 'marker',
                icon: { iconName: 'status_active', anchor: [-0.5, 3.1] },
                zIndex: 15,
              },
            ],
            hover: [
              {
                shape: 'marker',
                icon: { iconName: 'crash_risk_start.hover', anchor: [0.5, 1] },
              },
              {
                shape: 'marker',
                icon: { iconName: 'status_active', anchor: [-0.5, 3.1] },
                zIndex: 15,
              },
            ],
            selected: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'crash_risk_start.selected',
                  anchor: [0.5, 1],
                },
              },
              {
                shape: 'marker',
                icon: { iconName: 'status_active', anchor: [-0.5, 3.1] },
                zIndex: 15,
              },
            ],
          },
        },
        lineStringStatusStyle: {
          default: {
            default: [{ shape: 'lineSolid', color: '#2C008A', width: 4 }],
            hover: [{ shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 }],
          },
          active: {
            default: [{ shape: 'lineSolid', color: '#2C008A', width: 4 }],
          },
        },
      },
      crash_risk_area_predicted: {
        multiLineStringStatusStyle: {
          default: {
            default: [
              { shape: 'lineSolid', color: '#000000', width: 6 },
              // { shape: 'lineSolid', color: "#590BFF", width: 3},
              { shape: 'lineDash', color: '#F78A04', width: 4 },
            ],
            hover: [
              { shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 },
              { shape: 'lineSolid', color: '#000000', width: 6 },
              // { shape: 'lineSolid', color: "#590BFF", width: 3},
              { shape: 'lineDash', color: '#F78A04', width: 4 },
            ],
            selected: [
              { shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 },
              { shape: 'lineSolid', color: '#000000', width: 6 },
              // { shape: 'lineSolid', color: "#590BFF", width: 3},
              { shape: 'lineDash', color: '#F78A04', width: 4 },
            ],
          },
        },

        startPointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'crash_risk_start', anchor: [0.5, 0.9] },
                zIndex: 15,
              },
            ],
            hover: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'crash_risk_start.hover',
                  anchor: [0.5, 0.9],
                },
                zIndex: 15,
              },
            ],
            selected: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'crash_risk_start.selected',
                  anchor: [0.5, 0.9],
                },
                zIndex: 15,
              },
            ],
          },
          active: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'crash_risk_start', anchor: [0.5, 0.9] },
                zIndex: 15,
              },
              {
                shape: 'marker',
                icon: { iconName: 'status_active', anchor: [-0.5, 3.1] },
                zIndex: 15,
              },
            ],
            hover: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'crash_risk_start.hover',
                  anchor: [0.5, 0.9],
                },
                zIndex: 15,
              },
              {
                shape: 'marker',
                icon: { iconName: 'status_active', anchor: [-0.5, 3.1] },
                zIndex: 15,
              },
            ],
            selected: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'crash_risk_start.selected',
                  anchor: [0.5, 0.9],
                },
                zIndex: 15,
              },
              {
                shape: 'marker',
                icon: { iconName: 'status_active', anchor: [-0.5, 3.1] },
                zIndex: 15,
              },
            ],
          },
          predicted: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'crash_risk_start', anchor: [0.5, 0.9] },
                zIndex: 15,
              },
              {
                shape: 'marker',
                icon: { iconName: 'status_predicted', anchor: [-0.5, 3.1] },
                zIndex: 15,
              },
            ],
            hover: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'crash_risk_start.hover',
                  anchor: [0.5, 0.9],
                },
                zIndex: 15,
              },
              {
                shape: 'marker',
                icon: { iconName: 'status_predicted', anchor: [-0.5, 3.1] },
                zIndex: 15,
              },
            ],
            selected: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'crash_risk_start.selected',
                  anchor: [0.5, 0.9],
                },
                zIndex: 15,
              },
              {
                shape: 'marker',
                icon: { iconName: 'status_predicted', anchor: [-0.5, 3.1] },
                zIndex: 15,
              },
            ],
          },
        },
        endPointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'crash_risk_end', anchor: [0.5, 0.5] },
                zIndex: 10,
              },
            ],
            hover: [
              {
                shape: 'marker',
                icon: { iconName: 'crash_risk_end.hover', anchor: [0.5, 0.5] },
                zIndex: 10,
              },
            ],
            selected: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'crash_risk_end.selected',
                  anchor: [0.5, 0.5],
                },
                zIndex: 10,
              },
            ],
          },
        },
        pointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'crash_risk_start', anchor: [0.5, 1] },
              },
            ],
            hover: [
              {
                shape: 'marker',
                icon: { iconName: 'crash_risk_start.hover', anchor: [0.5, 1] },
              },
            ],
            selected: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'crash_risk_start.selected',
                  anchor: [0.5, 1],
                },
              },
            ],
          },
        },
        lineStringStatusStyle: {
          default: {
            default: [{ shape: 'lineSolid', color: '#2C008A', width: 4 }],
            hover: [{ shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 }],
          },
          active: {
            default: [{ shape: 'lineSolid', color: '#2C008A', width: 4 }],
          },
        },
      },

      irregular_congestion: {
        startPointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'irregular_congestion_start',
                  anchor: [0.5, 0.9],
                },
                zIndex: 15,
              },
            ],
            hover: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'irregular_congestion_start.hover',
                  anchor: [0.5, 0.9],
                },
                zIndex: 15,
              },
            ],
            selected: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'irregular_congestion_start.selected',
                  anchor: [0.5, 0.9],
                },
                zIndex: 15,
              },
            ],
          },
          editMode: EditModeDefaultStyle,
        },
        endPointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'irregular_congestion_end',
                  anchor: [0.5, 0.5],
                },
                zIndex: 10,
              },
            ],
            hover: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'irregular_congestion_end.hover',
                  anchor: [0.5, 0.5],
                },
                zIndex: 10,
              },
            ],
            selected: [
              {
                shape: 'marker',
                icon: {
                  iconName: 'irregular_congestion_end.selected',
                  anchor: [0.5, 0.5],
                },
                zIndex: 10,
              },
            ],
          },
        },
        pointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'irregular_congestion_start' },
              },
            ],
            hover: [
              {
                shape: 'marker',
                icon: { iconName: 'irregular_congestion_start.hover' },
              },
            ],
            selected: [
              {
                shape: 'marker',
                icon: { iconName: 'irregular_congestion_start.selected' },
              },
            ],
          },
        },
        multiLineStringStatusStyle: {
          default: {
            default: [
              { shape: 'lineSolid', color: '#FFFFFF', width: 6 },
              // { shape: 'lineSolid', color: "#590BFF", width: 3},
              { shape: 'lineDash', color: '#590BFF', width: 4 },
            ],
            hover: [
              { shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 },
              { shape: 'lineSolid', color: '#FFFFFF', width: 6 },
              // { shape: 'lineSolid', color: "#590BFF", width: 3},
              { shape: 'lineDash', color: '#590BFF', width: 4 },
            ],
            selected: [
              { shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 },
              { shape: 'lineSolid', color: '#FFFFFF', width: 6 },
              // { shape: 'lineSolid', color: "#590BFF", width: 3},
              { shape: 'lineDash', color: '#590BFF', width: 4 },
            ],
          },
        },
      },

      construction: {
        zIndex: 9,
        startPointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'construction_path', anchor: [0.5, 0.6], zIndex: 15 } }],
            hover: [{ shape: 'marker', icon: { iconName: 'construction_path.hover', anchor: [0.5, 0.6], zIndex: 15 } }],
            selected: [
              { shape: 'marker', icon: { iconName: 'construction_path.selected', anchor: [0.5, 0.6], zIndex: 15 } },
            ],
          },
        },
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'construction' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'construction.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'construction.selected' } }],
          },

          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'construction.completed' } }],
          },
        },
        lineStringStatusStyle: {
          default: {
            default: [
              { shape: 'lineSolid', color: '#fff', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
            ],
            selected: [
              { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 8 },
              { shape: 'lineSolid', color: '#fff', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
            ],
            hover: [
              { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 8 },
              { shape: 'lineSolid', color: '#fff', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
            ],
          },
          active: {
            default: [
              { shape: 'lineSolid', color: '#fff', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
            ],
            selected: [
              { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 8 },
              { shape: 'lineSolid', color: '#fff', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
            ],
            hover: [
              { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 8 },
              { shape: 'lineSolid', color: '#fff', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
            ],
          },
        },
        polygonStatusStyle: {
          default: {
            default: [
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
              { shape: 'area', color: '#359edc', opacity: 0.0001, zIndex: 1 },
            ],
            selected: [
              { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 8 },
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
              { shape: 'area', color: '#359edc', opacity: 0.15, zIndex: 1 },
            ],
            hover: [
              { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 8 },
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
              { shape: 'area', color: '#359edc', opacity: 0.15, zIndex: 1 },
            ],
          },
          active: {
            default: [
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
              { shape: 'area', color: '#359edc', opacity: 0.0001, zIndex: 1 },
            ],
            selected: [
              { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 8 },
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
              { shape: 'area', color: '#359edc', opacity: 0.15, zIndex: 1 },
            ],
            hover: [
              { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 8 },
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
              { shape: 'area', color: '#359edc', opacity: 0.15, zIndex: 1 },
            ],
          },
        },
      },
      special_event: {
        startPointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'events', anchor: [0.5, 0.6] } }],
            hover: [{ shape: 'marker', icon: { iconName: 'events.hover', anchor: [0.5, 0.6] } }],
            selected: [{ shape: 'marker', icon: { iconName: 'events.selected', anchor: [0.5, 0.6] } }],
          },
        },
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'events' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'events.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'events.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'events.completed' } }],
          },
        },
        lineStringStatusStyle: {
          default: {
            default: [
              { shape: 'lineSolid', color: '#fff', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
            ],
          },
          active: {
            default: [
              { shape: 'lineSolid', color: '#fff', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
            ],
          },
        },
        multiLineStringStatusStyle: {
          default: {
            default: [
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
            ],
            hover: [
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
            ],
            selected: [
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
            ],
          },
        },
        polygonStatusStyle: {
          default: {
            default: [
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
              { shape: 'area', color: '#359edc', opacity: 0.0001 },
            ],
            selected: [
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
              { shape: 'area', color: '#359edc', opacity: 0.15 },
            ],
            hover: [
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
              { shape: 'area', color: '#359edc', opacity: 0.15 },
            ],
          },
          active: {
            default: [
              { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
              { shape: 'lineDash', color: '#690106', darkColor: '#764649', width: 4 },
              { shape: 'area', color: '#359edc', opacity: 0.0001 },
            ],
          },
        },
      },

      wr_traffic_control: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'traffic_control' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'traffic_control.hover' } }],
            selected: [
              {
                shape: 'marker',
                icon: { iconName: 'traffic_control.selected' },
              },
            ],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'traffic_control.unassigned' },
              },
            ],
          },
          completed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'traffic_control.completed' },
              },
            ],
          },
          rejected: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'traffic_control.rejected' },
              },
            ],
          },
        },
      },
      wr_dead_animal: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'dead_animal' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'dead_animal.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'dead_animal.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [{ shape: 'marker', icon: { iconName: 'dead_animal.unassigned' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'dead_animal.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'dead_animal.rejected' } }],
          },
        },
      },
      wr_attenuator: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'attenuator' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'attenuator.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'attenuator.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [{ shape: 'marker', icon: { iconName: 'attenuator.unassigned' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'attenuator.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'attenuator.rejected' } }],
          },
        },
      },
      wr_pothole_pavement: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'pothole_pavement' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'pothole_pavement.hover' } }],
            selected: [
              {
                shape: 'marker',
                icon: { iconName: 'pothole_pavement.selected' },
              },
            ],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'pothole_pavement.unassigned' },
              },
            ],
          },
          completed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'pothole_pavement.completed' },
              },
            ],
          },
          rejected: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'pothole_pavement.rejected' },
              },
            ],
          },
        },
      },
      wr_fence: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'fence' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'fence.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'fence.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [{ shape: 'marker', icon: { iconName: 'fence.unassigned' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'fence.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'fence.rejected' } }],
          },
        },
      },
      wr_sign: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'sign' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'sign.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'sign.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [{ shape: 'marker', icon: { iconName: 'sign.unassigned' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'sign.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'sign.rejected' } }],
          },
        },
      },
      wr_graffiti: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'graffiti' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'graffiti.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'graffiti.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [{ shape: 'marker', icon: { iconName: 'graffiti.unassigned' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'graffiti.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'graffiti.rejected' } }],
          },
        },
      },
      wr_homeless_issue: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'homeless_issue' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'homeless_issue.hover' } }],
            selected: [
              {
                shape: 'marker',
                icon: { iconName: 'homeless_issue.selected' },
              },
            ],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'homeless_issue.unassigned' },
              },
            ],
          },
          completed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'homeless_issue.completed' },
              },
            ],
          },
          rejected: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'homeless_issue.rejected' },
              },
            ],
          },
        },
      },
      wr_cable_rail: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'cable_rail' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'cable_rail.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'cable_rail.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [{ shape: 'marker', icon: { iconName: 'cable_rail.unassigned' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'cable_rail.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'cable_rail.rejected' } }],
          },
        },
      },
      wr_guard_rail: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'guard_rail' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'guard_rail.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'guard_rail.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [{ shape: 'marker', icon: { iconName: 'guard_rail.unassigned' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'guard_rail.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'guard_rail.rejected' } }],
          },
        },
      },
      wr_debris: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'wr_debris' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'wr_debris.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'wr_debris.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [{ shape: 'marker', icon: { iconName: 'wr_debris.unassigned' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'wr_debris.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'wr_debris.rejected' } }],
          },
        },
      },
      wr_fuel_oil: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'fuel_oil' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'fuel_oil.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'fuel_oil.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [{ shape: 'marker', icon: { iconName: 'fuel_oil.unassigned' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'fuel_oil.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'fuel_oil.rejected' } }],
          },
        },
      },
      wr_major_hazmat: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'major_hazmat' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'major_hazmat.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'major_hazmat.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'major_hazmat.unassigned' },
              },
            ],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'major_hazmat.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'major_hazmat.rejected' } }],
          },
        },
      },
      wr_concrete_damage: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'concrete_damage' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'concrete_damage.hover' } }],
            selected: [
              {
                shape: 'marker',
                icon: { iconName: 'concrete_damage.selected' },
              },
            ],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'concrete_damage.unassigned' },
              },
            ],
          },
          completed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'concrete_damage.completed' },
              },
            ],
          },
          rejected: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'concrete_damage.rejected' },
              },
            ],
          },
        },
      },
      wr_landscape: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'landscape' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'landscape.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'landscape.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [{ shape: 'marker', icon: { iconName: 'landscape.unassigned' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'landscape.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'landscape.rejected' } }],
          },
        },
      },
      wr_bridge: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'bridge' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'bridge.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'bridge.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [{ shape: 'marker', icon: { iconName: 'bridge.unassigned' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'bridge.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'bridge.rejected' } }],
          },
        },
      },
      wr_pavement_marking: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'pavement_marking' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'pavement_marking.hover' } }],
            selected: [
              {
                shape: 'marker',
                icon: { iconName: 'pavement_marking.selected' },
              },
            ],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'pavement_marking.unassigned' },
              },
            ],
          },
          completed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'pavement_marking.completed' },
              },
            ],
          },
          rejected: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'pavement_marking.rejected' },
              },
            ],
          },
        },
      },
      wr_flooding_drains: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'flooding_drains' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'flooding_drains.hover' } }],
            selected: [
              {
                shape: 'marker',
                icon: { iconName: 'flooding_drains.selected' },
              },
            ],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'flooding_drains.unassigned' },
              },
            ],
          },
          completed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'flooding_drains.completed' },
              },
            ],
          },
          rejected: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'flooding_drains.rejected' },
              },
            ],
          },
        },
      },
      wr_snow_ice: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'snow_ice' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'snow_ice.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'snow_ice.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [{ shape: 'marker', icon: { iconName: 'snow_ice.unassigned' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'snow_ice.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'snow_ice.rejected' } }],
          },
        },
      },
      wr_dust_storm_or_high_wind: {
        pointStatusStyle: {
          default: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'dust_storm_or_high_wind' },
              },
            ],
            hover: [
              {
                shape: 'marker',
                icon: { iconName: 'dust_storm_or_high_wind.hover' },
              },
            ],
            selected: [
              {
                shape: 'marker',
                icon: { iconName: 'dust_storm_or_high_wind.selected' },
              },
            ],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'dust_storm_or_high_wind.unassigned' },
              },
            ],
          },
          completed: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'dust_storm_or_high_wind.completed' },
              },
            ],
          },
          rejected: {
            default: [
              {
                shape: 'marker',
                icon: { iconName: 'dust_storm_or_high_wind.rejected' },
              },
            ],
          },
        },
      },
      wr_forest_fire: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'forest_fire' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'forest_fire.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'forest_fire.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [{ shape: 'marker', icon: { iconName: 'forest_fire.unassigned' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'forest_fire.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'forest_fire.rejected' } }],
          },
        },
      },
      wr_light_pole: {
        pointStatusStyle: {
          default: {
            default: [{ shape: 'marker', icon: { iconName: 'light_pole' } }],
            hover: [{ shape: 'marker', icon: { iconName: 'light_pole.hover' } }],
            selected: [{ shape: 'marker', icon: { iconName: 'light_pole.selected' } }],
          },
          editMode: EditModeDefaultStyle,
          unassigned: {
            default: [{ shape: 'marker', icon: { iconName: 'light_pole.unassigned' } }],
          },
          completed: {
            default: [{ shape: 'marker', icon: { iconName: 'light_pole.completed' } }],
          },
          rejected: {
            default: [{ shape: 'marker', icon: { iconName: 'light_pole.rejected' } }],
          },
        },
      },
    },
  },
};
