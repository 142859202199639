<mat-label class="scheduler-label">{{ fieldData.label | translate }}</mat-label>
<form [formGroup]="form" class="scheduler-form">
  <form [formGroup]="schedulerStateForm" class="scheduler-state-form">
    <wc-radio-button [formFieldData]="formFieldData" formControlName="schedule"></wc-radio-button>
  </form>

  <div class="custom-date-section" *ngIf="showPresetTypeOptions">
    <div>
      <wc-date-time-control
        [formControl]="$any(form.get('from'))"
        [fieldData]="timeDateFields.from"
        [class.invalid-field]="form.get('from')?.invalid"
        [timeReadOnly]="isAllDay"></wc-date-time-control>
      <wc-date-time-control
        [maxDate]="maxSchedulerDate"
        [formControl]="$any(form.get('to'))"
        [fieldData]="timeDateFields.to"
        [class.invalid-field]="form.get('to')?.invalid"
        [timeReadOnly]="isAllDay"></wc-date-time-control>
    </div>
    <mat-error *ngIf="form.get('to')?.errors && form.get('to')?.errors['dateMustNotBeLaterThanToday']">
      {{ 'validationErrorMessages.dateMustNotBeLaterThanToday' | translate }}
    </mat-error>
    <mat-error
      *ngIf="
        !form.get('to')?.errors &&
        form.get('from')?.errors &&
        form.get('from')?.errors['startDateNeedToBeBeforeEndDate']
      ">
      {{ 'validationErrorMessages.startDateNeedToBeBeforeEndDate' | translate }}
    </mat-error>

    <div *ngIf="!form.get('from')?.errors && !form.get('to')?.errors" class="custom-date-hint-text">
      {{ 'dataHubCustomTimeRangeHint' | translate }}
    </div>

    <div class="all-day-checkbox">
      <mat-checkbox class="mat-checkbox" (change)="allDayCheckboxChange($event.checked)">
        <span class="checkbox-label">{{ 'schedulerForm.allDay' | translate }}</span>
      </mat-checkbox>
    </div>

    <div *ngIf="displayDaySelection" [class.hide-days]="!showDays">
      <wc-multi-checkbox
        [formControl]="$any(form.get('daysOfTheWeek'))"
        [fieldData]="timeDateFields.daysOfTheWeek"
        [showCounter]="false"
        [twoRows]="true"
        [showMoreOptions]="false">
      </wc-multi-checkbox>
    </div>
  </div>
</form>
