import { Injectable } from '@angular/core';
import { AllBuses } from '@wc/core/stores/transit.store';
import { LiveMapData } from '@wc/wc-models/src';
import { Observable } from 'apollo-link';
import { action, computed, makeObservable, observable, runInAction, toJS } from 'mobx';
import { interval, ReplaySubject, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DiffParams, EntitiesDiff, Incident, LiveMapEntity, LiveMapEntityType } from '../';
import { TransitUnitLayers } from '../../core/models/enums';
import * as Utils from '../../utils';
import { IncidentType } from '../models';
import { CacheService, DataBase } from '../services/cache.service';
import { EntitiesService } from '../services/entites.service';
import { GeoService } from '../services/geo.service';
import { WindowService } from '../services/window.service';

export class EntitiesStoreMock {
  onIncidentModified$: Subject<Incident> = new Subject();
  onIncidentRemoved$: Subject<Incident> = new Subject();
}

@Injectable({
  providedIn: 'root',
})
export class EntitiesStore {
  @observable entities: { [layerName in LiveMapEntityType]?: {} } = {};
  @observable transitEntities: Partial<AllBuses> = {
    transit_demand_response_unit: {},
    transit_on_demand_unit: {},
  };
  @observable entitiesDiff: { diff: EntitiesDiff; refreshAll: boolean } = {
    diff: { modified: {}, removed: {}, updatedAt: 0 },
    refreshAll: true,
  };
  @observable weatherEventsDiff: { modified: {}; removed: [] } = {
    modified: {},
    removed: [],
  };

  id = 0;
  entitiesDiffInterval: Subscription = new Subscription();
  onIncidentModified$: Subject<Incident> = new Subject();
  // Temp solution to notify liveMapService on changes, without using mobx in there.
  onEntitiesDiffSubject = new ReplaySubject<LiveMapData>();
  onEntitiesDiff$ = this.onEntitiesDiffSubject.asObservable();
  //------------------------------------------------------------------------------
  onIncidentRemoved$: Subject<Incident> = new Subject();
  incidentTypes = (Object.values(IncidentType) as string[]).map(type => type.toLowerCase());

  constructor(
    private entitiesService: EntitiesService,
    private windowService: WindowService,
    private cacheService: CacheService,
    private geoService: GeoService
  ) {
    makeObservable(this);
    if (!navigator.onLine) {
      // this.loadCachedEntities();
    }
    // this.getAllEntities();
    // this.startDiffInterval();
  }

  cacheEnteties() {
    // this.cacheService.put(DataBase.offlineCache, 'cachedEnteties', toJS(this.entities));
  }

  async loadCachedEntities() {
    runInAction(async () => {
      this.entities = await this.cacheService.get(DataBase.offlineCache, 'cachedEnteties');
      console.log(`Loaded cached entities`);
    });
  }

  startDiffInterval() {
    this.entitiesDiffInterval = interval(5000).subscribe(() => {
      let sub: Subscription;
      sub = this.entitiesService
        .getDiff()
        .pipe(
          tap(async diff => {
            if (diff) {
              this.onEntitiesDiffSubject.next(diff as unknown as LiveMapData);

              runInAction(() => {
                this.entitiesDiff = { diff, refreshAll: false };
                this.weatherEventsDiff = {
                  ...{},
                  ...{
                    modified: diff.modified['weather_alert'],
                    removed: diff.removed['weather_alert'],
                  },
                };
              });

              await this.removedEntities(diff.removed);
              await this.modifiedEntities(diff.modified);
            }
          })
        )
        .subscribe(() => {
          sub?.unsubscribe();
        });
    });
  }

  @computed
  get camerasList() {
    if (!this.entities) {
      return [];
    }
    return Utils.toArray(this.entities.camera || {});
  }

  modifiedEntities(modifiedLayers: {
    [layerName in LiveMapEntityType]?: { [id in string]: LiveMapEntity };
  }): Promise<{}> {
    return new Promise((resolve, reject) => {
      for (const layerName in modifiedLayers) {
        if (modifiedLayers.hasOwnProperty(layerName)) {
          const layer = modifiedLayers[layerName];
          if (
            layerName === TransitUnitLayers.transit_demand_response_unit ||
            layerName === TransitUnitLayers.transit_on_demand_unit ||
            layerName === TransitUnitLayers.transit_fixed_route_bus_unit
          ) {
            // runInAction(() => {
            //     this.transitEntities[layerName] = (this.transitEntities[layerName] || {});
            // });
          } else {
            runInAction(() => {
              this.entities[layerName] = this.entities[layerName] || {};
            });
          }

          for (const entityId in layer) {
            if (layer.hasOwnProperty(entityId)) {
              const entity = layer[entityId];
              if (
                layerName === TransitUnitLayers.transit_demand_response_unit ||
                layerName === TransitUnitLayers.transit_on_demand_unit ||
                layerName === TransitUnitLayers.transit_fixed_route_bus_unit
              ) {
                runInAction(() => {
                  const layer = this.transitEntities[layerName] || {};
                  layer[entityId] = { ...layer[entityId], ...entity };
                });
              } else {
                if (entity['featureSubTypeOf'] === 'incident' || entity['featureSubTypeOf'] === 'work_request') {
                  this.checkForIncidentTypeChange(entity);
                }
                if (entity.associatedUnits) {
                  const associatedUnitsUsersIds = entity.associatedUnits.map(unit => unit.driverDetails?.userId);
                  entity['associatedUnitsUsersIds'] = associatedUnitsUsersIds;
                }
                runInAction(() => {
                  this.entities[layerName][entityId] = {
                    ...this.entities[layerName][entityId],
                    ...entity,
                  };
                });
              }
            }
          }
        }
      }

      runInAction(() => {
        this.entities = { ...{}, ...this.entities };
      });

      runInAction(() => {
        this.transitEntities = { ...{}, ...this.transitEntities };
      });

      const { transit_demand_response_unit, transit_on_demand_unit, transit_fixed_route_bus_unit, ...layers } =
        modifiedLayers;
      resolve(layers);
    });
  }

  checkForIncidentTypeChange(entity) {
    for (const layerName in this.entities) {
      if (this.entities.hasOwnProperty(layerName)) {
        const layer = this.entities[layerName];
        if (layer && layer[entity.id] && layer[entity.id].type !== entity.type) {
          delete this.entities[layerName][entity.id];
        }
      }
    }
  }

  @action
  refreshEntities() {
    this.entities = { ...{}, ...this.entities };
  }

  getEntity(entityId: string) {
    for (const layerName of Object.keys(this.entities)) {
      const layer = this.entities[layerName];
      if (layer[entityId]) {
        return toJS(layer[entityId]);
      }
    }
  }

  getEntityByLayer(layerName: string, entityId: string) {
    const layer = this.entities[layerName.toLowerCase()];
    if (layer) {
      return layer[entityId];
    }
  }

  getEntitiesBySubType(subType: string) {
    let subTypeEntities = [];
    for (const layerName of Object.keys(this.entities)) {
      const layer = this.entities[layerName];
      const layersEntities = Object.values(layer) as never[];

      if (layersEntities && layersEntities[0] && layersEntities[0]['featureSubTypeOf'] === subType) {
        subTypeEntities = subTypeEntities.concat(layersEntities as never[]);
      }
    }
    return subTypeEntities;
  }

  removedEntities(removedLayers: {
    [layerName in LiveMapEntityType]?: [number];
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      for (const layerName in removedLayers) {
        if (
          layerName === TransitUnitLayers.transit_demand_response_unit ||
          layerName === TransitUnitLayers.transit_on_demand_unit ||
          layerName === TransitUnitLayers.transit_fixed_route_bus_unit
        ) {
          if (
            this.transitEntities &&
            this.transitEntities.hasOwnProperty(layerName) &&
            removedLayers.hasOwnProperty(layerName)
          ) {
            const layer = removedLayers[layerName];
            layer?.forEach(entityId => {
              const layer = this.transitEntities[layerName];
              if (layer && layer[entityId]) {
                delete layer[entityId];
              }
            });
          }
        } else {
          if (this.entities.hasOwnProperty(layerName) && removedLayers.hasOwnProperty(layerName)) {
            const layer = removedLayers[layerName];

            layer.forEach(entityId => {
              delete this.entities[layerName][entityId];
            });
          }
        }
      }

      resolve(this.entities);
    });
  }

  getAllEntities(params?: DiffParams) {
    return this.entitiesService
      .getAll(params)
      .then(entities => {
        if (entities) {
          this.onEntitiesDiffSubject.next({ modified: entities, removed: {} });
        }
        const { transit_demand_response_unit, transit_on_demand_unit, ...rest } = entities;
        entities = rest;

        runInAction(() => {
          this.transitEntities = {
            transit_demand_response_unit,
            transit_on_demand_unit,
          };

          this.entities = entities;

          this.entitiesDiff = {
            diff: { modified: entities?.modified, removed: {}, updatedAt: 0 },
            refreshAll: true,
          };

          this.weatherEventsDiff = { ...{}, ...{ modified: entities['weather_alert'], removed: [] } };
        });

        // this.cacheEnteties();
        // console.log(toJS(this.entities));
      })
      .then(() => {
        setTimeout(() => {
          console.log('====== startDiffInterval ======');
          if (this.entitiesDiffInterval) this.entitiesDiffInterval.unsubscribe();
          this.startDiffInterval();
        }, 5000);
      });
  }

  getCamerasByPoint(centerPoint: number[], radius: number): Observable<any[]> {
    const cameras = this.entities.camera;
    const nearCameras: any[] = [];
    for (const id in cameras) {
      if (Object.prototype.hasOwnProperty.call(cameras, id)) {
        const camera = cameras[id];
        const bool = this.geoService.isPointsWithinRadius(camera.location.coordinates, centerPoint, radius);
        if (bool) nearCameras.push(toJS(camera));
      }
    }
    return Observable.of(nearCameras);
  }

  getDmsListByPoint(centerPoint: number[], radius: number) {
    const dmsList = this.entities.dms;
    const nearDmsList: any[] = [];

    for (const id in dmsList) {
      if (Object.prototype.hasOwnProperty.call(dmsList, id)) {
        const dms = dmsList[id];
        const isRelatedDms = this.geoService.isPointsWithinRadius(dms.location.coordinates, centerPoint, radius);
        if (isRelatedDms) nearDmsList.push(toJS(dms));
      }
    }

    return Observable.of(nearDmsList);
  }

  getUnitRouteFromDiff(unitType: string | undefined, unitId: number) {
    let routes = [];
    if (unitType) {
      const unitTypeLayer = this.entities[unitType.toLowerCase()];
      if (unitTypeLayer?.hasOwnProperty(unitId)) {
        routes = unitTypeLayer[unitId].routeNames;
      }
    }
    return routes;
  }
}
