import { Injectable, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { DateFormat, RegionalSetting, TimeFormat } from '../../../../../core/models/gql.models';

@Pipe({
  name: 'dateFormat',
  pure: true,
})
@Injectable({
  providedIn: 'root',
})
export class DateFormatPipe implements PipeTransform {
  transform(date: string | number | undefined, regionalSettings: RegionalSetting, ...args: any[]): string | void {
    if (!date || !regionalSettings) {
      return;
    }

    const dateFormat = regionalSettings.dateFormat;
    const timeFormat = regionalSettings.timeFormat;
    const format = args[0];

    if (dateFormat === DateFormat.DdMmmYyyy) {
      if (format === 'mediumDate') {
        return moment(date).format('DD MMM, YYYY');
      }
      if (format === 'mediumDateNoComma') {
        return moment(date).format('DD MMM YYYY');
      }

      return moment(date).format('HH:mm, DD MMM YYYY');
    } else if (dateFormat === DateFormat.MmmDdYyyy || dateFormat === DateFormat.MmmDdCommaYyyy) {
      if (format === 'mediumDate') {
        return moment(date).format('MMM DD, YYYY');
      }
      if (format === 'mediumDateNoComma') {
        return moment(date).format('MMM DD YYYY');
      }

      return timeFormat === TimeFormat.TwelveHours
        ? moment(date).format('h:mm A, MMM DD YYYY')
        : moment(date).format('HH:mm, MMM DD YYYY');
    }
  }
}
