import { AssociatedCamera, AssociatedCameraInput, AssociatedDms, AssociatedDmsInput } from '.';

export type RoadEventCamera = AssociatedCamera;
export type RoadEventCameraInput = AssociatedCameraInput;

export function asRoadEventCameraInput(roadEventCamera: RoadEventCamera) {
  return <RoadEventCameraInput>{
    cameraExternalId: roadEventCamera.camera.externalId,
    default: roadEventCamera.default,
  };
}

export function asRoadEventDmsInput(associatedDms: AssociatedDms) {
  return <AssociatedDmsInput>{
    dmsId: associatedDms.dms.id,
  };
}
