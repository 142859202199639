<div [formGroup]="addressForm" class="address-form">
  <div class="location-section" [class.disabled]="_disableFields">
    <div class="address-loader-container">
      <wc-loader-component class="address-loader" [show]="!!(addressLoader$ | async)"></wc-loader-component>
    </div>

    <div class="form-grid" fxLayoutGap="55px">
      <wc-autocomplete
        wcHeapClass="show-nearby-corridor-button"
        class="address-form-field corridor grid-item-corridor"
        data-cy-id="address-form-field-corridor"
        #corridorField
        [isGrouped]="true"
        formControlName="corridor"
        [formFieldData]="addressFieldData.corridor"
        [hint]="isAliasAvailable && !isEditMode ? ('formFieldAliasHint' | translate: { fieldName: 'corridor' }) : ''"
        [isFreeText]="true">
      </wc-autocomplete>

      <wc-select
        class="address-form-field direction hide-in-landscape"
        formControlName="direction"
        [formFieldData]="addressFieldData.direction">
      </wc-select>

      <wc-select
        class="address-form-field orientation hide-in-landscape"
        [dynamicClass]="'address-select'"
        [isLoadMoreView]="true"
        formControlName="orientation"
        [formFieldData]="addressFieldData.orientation">
      </wc-select>

      <wc-autocomplete
        class="address-form-field crossroad grid-item-crossroad"
        data-cy-id="address-form-field-crossroad"
        #crossroadField
        [formControl]="$any(addressForm.get('crossroad'))"
        [formFieldData]="addressFieldData.crossroad"
        [isFreeText]="true"
        [hint]="isAliasAvailable && !isEditMode ? ('formFieldAliasHint' | translate: { fieldName: 'crossroad' }) : ''"
        [isGrouped]="true">
      </wc-autocomplete>

      <wc-select
        class="address-form-field direction hide-in-portrait"
        [dynamicClass]="'address-select'"
        formControlName="direction"
        [formFieldData]="addressFieldData.direction">
      </wc-select>

      <wc-select
        class="address-form-field orientation hide-in-portrait"
        [dynamicClass]="'address-select'"
        [isLoadMoreView]="true"
        formControlName="orientation"
        [formFieldData]="addressFieldData.orientation">
      </wc-select>

      <wc-select
        class="address-form-field road-type grid-item-road-type"
        [dynamicClass]="'address-select'"
        formControlName="roadType"
        [isLoadMoreView]="true"
        [formFieldData]="addressFieldData.roadType">
      </wc-select>

      <wc-autocomplete
        data-cy-id="incident-form-milemarker-textbox"
        class="address-form-field milemarker grid-item-mile-marker"
        #milemarkerField
        [isFreeText]="true"
        formControlName="milemarker"
        [formFieldData]="addressFieldData.milemarker">
      </wc-autocomplete>
    </div>
  </div>
</div>

<div
  data-cy-id="segment-alias-note"
  class="segment-alias-note"
  *ngIf="isAliasAvailable && (isCorridorOrCrossroadChanged$ | async)">
  <span class="segment-alias-note__notice-marker">{{ 'notice' | translate }}:</span
  ><span class="segment-alias-note__notice-content">{{ 'segmentAlias.formChangeNote' | translate }}</span>
</div>
