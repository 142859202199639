import { InjectionToken } from '@angular/core';
import { LayerNamesOptionsType, LayerPanelStoreItem, LayerPanelUIStoreItem } from '@wc/wc-models/src';
import * as olMap from 'ol/Map';
import { wcCoordinateTypes } from '.';
import { StaticLayerName } from './enums';
import { mapViewerEnvConfig, WcMapConfigModel } from './interfaces';

export const wcMapToken = new InjectionToken<olMap.default>('wcMap');
export const staticLayersToken = new InjectionToken<StaticLayerName[] | null>('staticLayersMapToken');
export const mapViewerEnvConfigToken = new InjectionToken<mapViewerEnvConfig>('WcMapViewerConfig');
export const mapViewerConfigToken = new InjectionToken<
  WcMapConfigModel<string, wcCoordinateTypes, string | number | symbol>
>('mapViewerConfigToken');

export const LAYER_PANEL_STORE_ITEMS = new InjectionToken<LayerPanelStoreItem<LayerNamesOptionsType>[]>(
  'LAYER_PANEL_STORE_ITEMS'
);

export const TREE_PANEL_UI_REPRESENTATION = new InjectionToken<LayerPanelUIStoreItem[]>('TREE_PANEL_UI_REPRESENTATION');
