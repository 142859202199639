import { Directive, Inject, Input, Renderer2, ViewContainerRef } from '@angular/core';
import { APP_TYPE_TOKEN } from '@wc/wc-core/src/lib/injection-tokens';
import { AppTypeUnion } from '@wc/wc-models/src';

@Directive({
  selector: '[wcHeapClass]',
})
export class HeapClassDirective {
  @Input() set wcHeapClass(value: string) {
    if (this._appType && typeof value === 'string' && value.length) {
      this.renderer.addClass(this._viewContainerRef.element.nativeElement, `heap-${this._appType}-${value}`);
    }
  }

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    @Inject(APP_TYPE_TOKEN) private _appType: AppTypeUnion
  ) {}
}
