import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[isOneTimeClick]',
})
export class IsOneTimeClick implements OnInit {
  valid!: boolean;

  constructor(private elm: ElementRef, private translateService: TranslateService, private cd: ChangeDetectorRef) {
    // TODO
    // Handle button width
  }

  @Input()
  set isOneTimeClick(value) {
    this.valid = value;
  }

  @HostListener('click', ['$event'])
  click() {
    if (this.valid) {
      this.elm.nativeElement.disabled = true;
      this.elm.nativeElement.innerHTML = this.translateService.instant('processing');
      this.cd.markForCheck();
    }
  }

  ngOnInit() {}
}
