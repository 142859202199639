import { mapVendorName, wcCoordinateTypes, WcMapConfigModel } from '@wc/wc-map-viewer/src';
import { EntityState, LiveMapEntityType } from '@wc/wc-models/src';

export const staticMapConfig: WcMapConfigModel<LiveMapEntityType, wcCoordinateTypes, EntityState> = {
  mapTheme: {
    themeName: 'light' as any,
    themeIcons: {
      path: '',
      json: '',
    },
    mapThemeUrl: '',
  },
  defaultIconStyle: {
    src: 'assets/icons/police-patrol.svg',
    anchor: [0.5, 1],
    scale: 0.6,
  },
  centerOptions: {
    zoomLevel: 10,
    duration: 1000,
    mapCenter: [],
  },
  maxZoom: 21,
  setUrlWithMapLocation: false,
  interactions: null,
  controls: null,
  defaultLayersVisibility: {
    draw: true,
  },
  satelliteVendor: mapVendorName.MAPBOX,
  layers: {
    draw: {
      pointStatusStyle: {
        default: {
          default: [
            {
              shape: 'marker',
              icon: {
                src: 'assets/icons/map-cursor-icon-new.svg',
                scale: 1,
                anchor: [0.5, 0.5],
              },
            },
          ],
        },
        modify_geometry: {
          default: [
            {
              shape: 'marker',
              icon: {
                src: 'assets/icons/map-cursor-icon-new.svg',
                scale: 1,
                anchor: [0.5, 0.5],
              },
            },
          ],
        },
        modify_pointer: {
          default: [
            {
              shape: 'marker',
              zIndex: 999999,
              icon: {
                src: 'assets/icons/map-cursor-icon-new.svg',
                scale: 1,
                anchor: [0.5, 0.5],
              },
            },
          ],
        },
      },
      multiLineStringStatusStyle: {
        default: {
          default: [
            { shape: 'lineSolid', color: '#359edc', width: 3 },
            {
              shape: 'circleIcon',
              color: '#fff',
              radius: 6,
              stroke: { color: '#359edc', width: 2 },
            },
          ],
        },
        modify_geometry: {
          default: [
            { shape: 'lineSolid', color: '#359edc', width: 3 },
            {
              shape: 'circleIcon',
              color: '#fff',
              radius: 6,
              stroke: { color: '#359edc', width: 2 },
            },
          ],
        },
        view_geometry: {
          default: [
            { shape: 'area', color: '#359edc', opacity: 0.25 },
            { shape: 'lineSolid', color: '#359edc', width: 3 },
          ],
        },
        modify_geometry_pointer: {
          default: [
            { shape: 'lineSolid', color: '#359edc', width: 3 },
            {
              shape: 'circleIcon',
              color: '#fff',
              radius: 6,
              stroke: { color: '#359edc', width: 2 },
            },
          ],
        },
      },
      lineStringStatusStyle: {
        default: {
          default: [
            { shape: 'lineSolid', color: '#359edc', width: 3 },
            {
              shape: 'circleIcon',
              color: '#fff',
              radius: 6,
              stroke: { color: '#359edc', width: 2 },
            },
          ],
        },
        modify_geometry: {
          default: [
            { shape: 'lineSolid', color: '#359edc', width: 3 },
            {
              shape: 'circleIcon',
              color: '#fff',
              radius: 6,
              stroke: { color: '#359edc', width: 2 },
            },
          ],
        },
        view_geometry: {
          default: [
            { shape: 'area', color: '#359edc', opacity: 0.25 },
            { shape: 'lineSolid', color: '#359edc', width: 3 },
          ],
        },
        modify_geometry_pointer: {
          default: [
            { shape: 'lineSolid', color: '#359edc', width: 3 },
            {
              shape: 'circleIcon',
              color: '#fff',
              radius: 6,
              stroke: { color: '#359edc', width: 2 },
            },
          ],
        },
      },
      polygonStatusStyle: {
        default: {
          default: [
            { shape: 'area', color: '#359edc', opacity: 0.25 },
            { shape: 'lineSolid', color: '#359edc', width: 3 },
          ],
        },
        modify_geometry: {
          default: [
            { shape: 'area', color: '#359edc', opacity: 0.25 },
            { shape: 'lineSolid', color: '#359edc', width: 3 },
            {
              shape: 'circleIcon',
              color: '#fff',
              radius: 6,
              stroke: { color: '#359edc', width: 2 },
            },
          ],
        },
        view_geometry: {
          default: [
            { shape: 'area', color: '#359edc', opacity: 0.25 },
            { shape: 'lineSolid', color: '#359edc', width: 4 },
          ],
        },
        modify_geometry_pointer: {
          default: [
            { shape: 'area', color: '#359edc', opacity: 0.25 },
            { shape: 'lineSolid', color: '#359edc', width: 3 },
            {
              shape: 'circleIcon',
              color: '#fff',
              radius: 6,
              stroke: { color: '#359edc', width: 2 },
            },
          ],
        },
      },
    },
  },
};
