import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleDocsPreviewUrlPipe } from './google-docs.pipe';

@NgModule({
  declarations: [GoogleDocsPreviewUrlPipe],
  imports: [CommonModule],
  exports: [GoogleDocsPreviewUrlPipe],
})
export class GoogleDocPreviewPipeModule {}
