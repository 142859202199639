import { Injectable } from '@angular/core';
import { LayerType } from '@wc/core';
import { take } from 'rxjs/operators';
import { CrashRisksQuery } from '../stores/entities/crash_risk/crash_risk.query';
import { EntitiesServiceV2 } from './entities.service';
import { LocalStorageKeys, LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CrashRisksService {
  constructor(
    private entitiesService: EntitiesServiceV2,
    private localStorageService: LocalStorageService,
    private crashRiskQuery: CrashRisksQuery
  ) {}

  saveFilterSelectionToLocalStorage(crashRiskFilterOption: string) {
    this.localStorageService.set(LocalStorageKeys.CrashRiskFilterOption, crashRiskFilterOption);
  }

  setCrashRiskAsActive(id) {
    this.entitiesService.setUiEntitiesAsActive({
      [LayerType.CrashRiskArea]: [id],
    });
  }

  setCrashRiskAsViewed(ids: number[]) {
    this.entitiesService.emitNewUIDiff({ CRASH_RISK_AREA: ids.map(id => ({ id, isViewed: true })) });

    this.crashRiskQuery
      .selectAll({ filterBy: entity => !!this.crashRiskQuery.ui.getEntity(entity.id)?.isViewed })
      .pipe(take(1))
      .subscribe(viewedUiEntities => {
        this.localStorageService.set(
          LocalStorageKeys.ViewedCrashRisksIds,
          viewedUiEntities.map(viewedUiEntities => viewedUiEntities.id)
        );
      });
  }

  getFilterSelectionFromLocalStorage() {
    return this.localStorageService.get(LocalStorageKeys.CrashRiskFilterOption);
  }
}
