import { AbstractControl } from '@angular/forms';
import { Address } from '../../../core/models';

export function AddressRequiredValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const address: Partial<Address> = control.value;
  if (
    control.value !== null &&
    address &&
    address.corridor !== null &&
    address.point &&
    address.point.coordinates &&
    address.roadType !== null
  ) {
    return null;
  }
  return { locationValidator: true };
}
