<div
  [formGroup]="formGroupDirective.form"
  [class.new-autocomplete]="newAutocomplete"
  class="injuries-severity-component">
  <div class="injuries-severity-component__injury-number" data-cy-id="casualties-nested-dropdown">
    <wc-select class="injuries" formControlName="injuries" [formFieldData]="formFieldData.injuries"> </wc-select>
  </div>

  <ng-container *ngIf="injurySeveritiesControl.controls.length > 0">
    <div class="injuries-severity-component__separation-line"></div>
    <div if class="injuries-severity-component__injury-list" formArrayName="injurySeverities">
      <ng-container *ngFor="let c of injurySeveritiesControl.controls; let i = index">
        <wc-select
          data-cy-id="casualties-two-nested-dropdown"
          class="injuries"
          [formControlName]="i"
          [formFieldData]="formFieldData.injurySeverities">
        </wc-select>
      </ng-container>
    </div>
  </ng-container>
</div>
