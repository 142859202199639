import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IncidentsUtilsService } from '@wc-core';
import { Direction, IncidentLane, LaneInput, LaneType, RoadType, TrafficDisruptionLane } from '@wc/core';
import { EnumToOptions } from '@wc/wc-common/src/lib/utils/convertToSelectOptions';
import { FormFieldData } from '../../lib/base';

export type RoadEventLane = IncidentLane | TrafficDisruptionLane;

export type MoveGoreAction = {
  index: number;
  moveLeft: boolean;
};

export enum MenuLaneTypes {
  LANE = 'Lane',
  RIGHT_SHOULDER = 'Right shoulder',
  LEFT_SHOULDER = 'Left shoulder',
  GORE = 'Gore',
}
export interface MenuItem {
  type: MenuLaneTypes;
  icon: string;
  isActive: boolean;
}

export const maxSingleDirectionLanes = 9;
export const maxMultiDirectionLanes = 100;

export const contextMenuOptions: Array<MenuItem> = [
  {
    type: MenuLaneTypes.LANE,
    icon: '/assets/icons/lane-types/lane.svg',
    isActive: true,
  },
  {
    type: MenuLaneTypes.RIGHT_SHOULDER,
    icon: '/assets/icons/lane-types/right-shoulder.svg',
    isActive: true,
  },
  {
    type: MenuLaneTypes.LEFT_SHOULDER,
    icon: '/assets/icons/lane-types/left-shoulder.svg',
    isActive: true,
  },
  {
    type: MenuLaneTypes.GORE,
    icon: '/assets/icons/lane-types/gore.svg',
    isActive: true,
  },
];

const validLaneTypes = new Set([
  LaneType.LeftLane,
  LaneType.CentralLane,
  LaneType.RightLane,
  LaneType.TurnLeft,
  LaneType.TurnRight,
]);

const validLaneSubTypes = new Set([LaneType.Gore, LaneType.LeftShoulder, LaneType.RightShoulder]);

export function getLanesByLanesQuantity(lanesQuantity: number, roadType: RoadType, direction?: Direction): LaneInput[] {
  const lanes: LaneInput[] = [];
  lanes.push({
    direction: direction,
    roadType: roadType,
    type: LaneType.LeftShoulder,
    isClosed: false,
    number: null,
    positionIndex: 0,
    isAffected: false,
  });
  for (let i = 0; i < lanesQuantity; i++) {
    lanes.push({
      direction: direction,
      roadType: roadType,
      type: LaneType.CentralLane,
      isClosed: false,
      number: i + 1,
      positionIndex: i + 1,
      isAffected: false,
    });
  }
  lanes.push({
    direction: direction,
    roadType: roadType,
    type: LaneType.RightShoulder,
    isClosed: false,
    number: null,
    positionIndex: lanesQuantity + 1,
    isAffected: false,
  });

  return lanes;
}

export function isLaneType(type: LaneType): boolean {
  return validLaneTypes.has(type);
}

export function isLaneSubType(type: LaneType): boolean {
  return !validLaneSubTypes.has(type);
}

@Injectable({ providedIn: 'root' })
export class AffectedLanesUtilsService {
  constructor(
    private incidentUtilsService: IncidentsUtilsService,
    private translateService: TranslateService,
    private fb: FormBuilder
  ) {}

  getAffectedLanesFormData(isIncident: boolean): { [key: string]: FormFieldData } {
    return {
      direction: {
        heapClass: 'direction',
        label: 'direction',
        required: isIncident ? this.incidentUtilsService.isIncidentFieldMandatory('directionMandatory') : false,
        options: EnumToOptions(Direction).map(option => {
          option.displayName = option.displayName?.toUpperCase();
          return option;
        }),
        placeholder: 'direction',
      },

      roadType: {
        heapClass: 'roadType',
        required: true,
        label: 'roadType',
        options: isIncident
          ? this.incidentUtilsService.incidentRoadTypesOptions
          : EnumToOptions(RoadType, {
              translateService: this.translateService,
              translateBy: 'value',
              translatePath: 'roadTypes',
            }).filter(rt => rt.value !== 'UNKNOWN_ROAD_TYPE'),
        placeholder: 'roadType',
      },
      typeSingleDirection: {
        options: EnumToOptions(LaneType, {
          translateService: this.translateService,
          translateBy: 'value',
          translatePath: 'laneTypes',
        }).filter(type => !validLaneSubTypes.has(type.value)),
        heapClass: 'laneType',
        label: 'laneType',
        required: true,
      },
      typeMultiDirection: {
        options: EnumToOptions(LaneType, {
          translateService: this.translateService,
          translateBy: 'value',
          translatePath: 'laneTypes',
        }).filter(type => validLaneSubTypes.has(type.value)),
        heapClass: 'laneType',
        label: 'laneType',
        required: true,
      },
    };
  }

  getLaneFormGroup(
    positionIndex: number,
    number?: number,
    roadType?: RoadType,
    laneType?: LaneType,
    direction?: Direction,
    isClosed = true,
    isAffected = true
  ) {
    return this.fb.group({
      direction: [
        direction,
        this.incidentUtilsService.isIncidentFieldMandatory('directionMandatory') ? Validators.required : null,
      ],
      roadType: [roadType, Validators.required],
      type: [laneType, Validators.required],
      isClosed: isClosed,
      number: number,
      isAffected: isAffected,
      positionIndex: positionIndex,
    });
  }
}
