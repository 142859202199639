/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { EntityActions, EntityUIQuery, QueryEntity } from '@datorama/akita';
import { DmsStoreEntity, ModifiedEntities, RemovedEntities } from '@wc/wc-models/src';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LayerType } from '../../../../../../core/models/gql.models';
import { GeoService } from '../../../../../../core/services/geo.service';
import { DmsState, DmsStore, DmsUIState } from './dms.store';

@Injectable({ providedIn: 'root' })
export class DmsQuery extends QueryEntity<DmsState> {
  ui!: EntityUIQuery<DmsUIState>;
  constructor(protected store: DmsStore, private geoService: GeoService) {
    super(store);
    this.createUIQuery();
  }

  get allDMS() {
    return this.selectAll();
  }

  get modifiedDms$(): Observable<ModifiedEntities> {
    return this.selectEntityAction([EntityActions.Update, EntityActions.Add]).pipe(
      map(({ ids }) => ids.map(id => this.getEntity(id)).filter(e => !!e) as DmsStoreEntity[]),
      map(incidents => ({
        [LayerType.Dms]: incidents,
      }))
    );
  }

  get removedDms$(): Observable<RemovedEntities> {
    return this.selectEntityAction([EntityActions.Remove]).pipe(map(({ ids }) => ({ [LayerType.Dms]: ids })));
  }

  getDmsListByPoint(centerPoint: number[], radius: number): DmsStoreEntity[] {
    return !centerPoint?.length || typeof radius !== 'number'
      ? []
      : this.getAll().filter(({ location: { coordinates } }) =>
          this.geoService.isPointsWithinRadius(coordinates, centerPoint, radius)
        );
  }
}
