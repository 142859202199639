import { TranslateService } from '@ngx-translate/core';
import { EntityType, Permission } from '@wc/core/models/gql.models';
import { FormFieldData, FormFieldOption } from '@wc/wc-ui/src/lib/base/custom-form-control';
import { isEqual } from 'lodash';
import { permissionGroupViews } from '../constants';
import { PermissionAccessLevel, PermissionGroupView } from '../enums';

export const findMatchingOption = (options: FormFieldOption<number[]>[], permissionIds?: number[]) => {
  const managerPermission = options[PermissionAccessLevel.Manager];
  const viewerPermission = options[PermissionAccessLevel.Viewer];
  const noAccessPermission = options[PermissionAccessLevel.NoAccess];

  const isManagerByDefault = !viewerPermission.value.length && !!managerPermission.value.length;

  if (!permissionIds) {
    if (isManagerByDefault) return managerPermission;
    return viewerPermission;
  }

  const matchedOption = options.find(option => {
    const permissionIdsSet = new Set(permissionIds);
    const optionValueSet = new Set(option.value);

    return (
      permissionIdsSet.size === optionValueSet.size && [...permissionIdsSet].every(value => optionValueSet.has(value))
    );
  });

  const isViewerSelected = isEqual(matchedOption?.value, viewerPermission.value);

  const isNoAccessSelected = isEqual(matchedOption?.value, []);

  if (isNoAccessSelected) {
    return noAccessPermission;
  }

  if (isViewerSelected || !matchedOption) {
    if (viewerPermission.disabled) return noAccessPermission;
    else return viewerPermission;
  }

  return matchedOption;
};

export const getPermissionIdsForEntityType = (key: string, permissions: Permission[] | undefined) => {
  return permissions?.filter(permission => getPermissionKey(permission) === key).map(({ id }) => id);
};

export const getPermissionKey = (permission: Permission) => {
  return (
    Object.keys(permissionGroupViews).find(key => permissionGroupViews[key].includes(permission.scope.entity)) ||
    permission.scope.entity
  );
};

export const getPermissionKeyFromBaseKey = (permissionEntity: EntityType | PermissionGroupView) => {
  return (
    Object.keys(permissionGroupViews).find(key => permissionGroupViews[key].includes(permissionEntity)) ||
    permissionEntity
  );
};

export const updatePermissionOptions = (
  _permission: FormFieldData<number[]>,
  translateService: TranslateService,
  viewerOption?: FormFieldOption<number[]>,
  managerOption?: FormFieldOption<number[]>
) => {
  const disabledManagerOptionText = translateService.instant('rolesForm.tooltips.disabledManagerOption');
  const disabledViewerOptionText = translateService.instant('rolesForm.tooltips.disabledViewerOption');

  _permission.options?.forEach((option, index) => {
    switch (index) {
      case PermissionAccessLevel.Manager:
        const hasOnlyReadPermission = isEqual(viewerOption?.value, managerOption?.value);
        updateOption(option, hasOnlyReadPermission, disabledManagerOptionText);
        break;
      case PermissionAccessLevel.Viewer:
        const hasNoReadPermissions = viewerOption?.value.length === 0;
        updateOption(option, hasNoReadPermissions, disabledViewerOptionText);
        break;
      default:
        break;
    }
  });
};

const updateOption = (option: FormFieldOption<number[]>, condition: boolean, tooltipText: string) => {
  option.disabled = condition;
  option.tooltipText = condition ? tooltipText : undefined;
};
