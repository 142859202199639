import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MapEditorComponent } from './map-editor.component';

@NgModule({
  declarations: [MapEditorComponent],
  imports: [CommonModule, TranslateModule],
  exports: [MapEditorComponent],
})
export class MapEditorModule {}
