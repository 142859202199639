import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  SERVER_URL: string = environment.root_api_url;

  constructor(private httpClient: HttpClient) {}

  uploadToIncident(formData, incidentId) {
    return this.httpClient.post<any>(`${this.SERVER_URL}/incidents/${incidentId}/upload`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  uploadToTrafficDisruption(formData, trafficDisruptionId) {
    return this.httpClient.post<any>(`${this.SERVER_URL}/traffic-disruptions/${trafficDisruptionId}/upload`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  uploadToSharePublic(formData) {
    return this.httpClient.post<any>(`${this.SERVER_URL}/publication/media/upload`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }
}
