import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HeapAnalyticsService } from '@wc-core';
import moment from 'moment';
import { BaseControlFieldComponent } from '../../lib/base/base-control-field.component';
@Component({
  selector: 'wc-date-field-control',
  templateUrl: './date-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateFieldComponent),
      multi: true,
    },
  ],
  styleUrls: ['./date-field.component.scss'],
})
export class DateFieldComponent extends BaseControlFieldComponent {
  isPickerOpen = false;
  isInFocus = false;
  isParentInvalid = false;

  @ViewChild('dateField') dateField!: ElementRef;

  @Input() readOnly!: boolean;
  @Input() showErrorMsg = true;
  @Input() minDate: Date | null = null;
  @Input() maxDate: Date | null = null;

  constructor(private heapService: HeapAnalyticsService) {
    super();
  }

  writeValue(val): void {
    this.fieldFormControl.setValue(val);
  }

  valueChanged() {
    if (!this.fieldFormControl.value) {
      this.onChanged('');
      if (this.fieldFormControl.errors?.owlDateTimeParse) {
        this.formControl.setErrors({ invalid: true }, { emitEvent: true });
      }
    } else {
      this.onChanged(this.fieldFormControl.value);
    }
  }

  handleDatePickerOpen() {
    this.isPickerOpen = true;
    this.heapService.trackUserSpecificAction('heap-calendar-opened');
  }

  handleDatePickerClosed() {
    this.isPickerOpen = false;
    this.dateField.nativeElement.blur();
  }

  onFocus() {
    this.isInFocus = true;
  }

  onBlur() {
    this.isInFocus = false;
    if (this.fieldFormControl.value && !this.fieldFormControl.errors) {
      this.fieldFormControl.setValue(moment(this.fieldFormControl.value).format());
    }
  }

  setInvalid(invalidState: boolean) {
    this.isParentInvalid = invalidState;
  }
}
