import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WcIconModule } from '@wc/wc-ui/src';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { SelectBoxesControlComponent } from './select-boxes-control.component';

const Materials: any[] = [];

@NgModule({
  declarations: [SelectBoxesControlComponent],
  imports: [CommonModule, WcBaseControlFieldModule, TranslateModule, Materials, WcIconModule],
  providers: [FormControl],
  exports: [SelectBoxesControlComponent],
})
export class SelectBoxesControlModule {}
