/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { EntityActions, EntityUIQuery, QueryEntity } from '@datorama/akita';
import { LayerType } from '@wc/core';
import { ModifiedEntities, RemovedEntities, TransitLayerType, TransitRouteStoreEntity } from '@wc/wc-models/src';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TransitRoutesState, TransitRoutesStore, TransitRoutesUIState } from './transit_routes.store';

@Injectable({ providedIn: 'root' })
export class TransitRoutesQuery extends QueryEntity<TransitRoutesState> {
  ui!: EntityUIQuery<TransitRoutesUIState>;
  constructor(store: TransitRoutesStore) {
    super(store);
    this.createUIQuery();
  }

  get modifiedTransitRoutes$(): Observable<ModifiedEntities> {
    return this.selectEntityAction([EntityActions.Update, EntityActions.Add]).pipe(
      map(({ ids }) => ids.map(id => this.getEntity(id)).filter(e => !!e) as TransitRouteStoreEntity[]),
      map(transitRoutes => ({
        [TransitLayerType.TransitRoute]: transitRoutes as TransitRouteStoreEntity[],
      }))
    );
  }

  get removedTransitRoutes$(): Observable<RemovedEntities> {
    return this.selectEntityAction([EntityActions.Remove]).pipe(map(({ ids }) => ({ [LayerType.Unit]: ids })));
  }

  get allTransitRoutes$(): Observable<TransitRouteStoreEntity[]> {
    return this.selectAll({
      sortBy: (a, b) => a.number.localeCompare(b.number, undefined, { numeric: true, sensitivity: 'base' }),
    });
  }

  getRouteBuses(routeId: number) {
    return this.ui.getEntity(routeId)?.activeBusIds;
  }

  getAllVisibleRoutesIdsFilteredByIsMyAccount(filterByIsMyAccount: boolean) {
    return this.getAll({ filterBy: route => route.show && (filterByIsMyAccount ? route.isMyAccount : true) }).map(
      route => route.id
    );
  }

  getAllVisibleRoutesBusIdsFilteredByIsMyAccount(filterByIsMyAccount: boolean) {
    return Array.from(
      new Set(
        this.getAll({ filterBy: route => route.show && (filterByIsMyAccount ? route.isMyAccount : true) })
          .map(route => this.ui.getEntity(route.id as number)?.activeBusIds || [])
          .reduce((pre, curr) => {
            return pre.concat(curr);
          }, [])
      )
    );
  }

  getAllVisibleRoutesBusStopsFilteredByIsMyAccount(filterByIsMyAccount: boolean) {
    return Array.from(
      new Set(
        this.getAll({ filterBy: route => route.show && (filterByIsMyAccount ? route.isMyAccount : true) })
          .map(route => this.ui.getEntity(route.id as number)?.busStopIds || [])
          .reduce((pre, curr) => {
            return pre.concat(curr);
          }, [])
      )
    );
  }
}
