/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { DmsStoreEntity, DmsUIEntity } from '@wc/wc-models/src';
import { map } from 'rxjs/operators';
import { LayerType } from '../../../../../../core/models/gql.models';
import { EntitiesServiceV2 } from '../../../services/entities.service';

export interface DmsState extends EntityState<DmsStoreEntity, number> {}
export interface DmsUIState extends EntityState<DmsUIEntity> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: LayerType.Dms })
export class DmsStore extends EntityStore<DmsState> {
  ui!: EntityUIStore<DmsUIState>;
  constructor(entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$.pipe(map(({ DMS }) => DMS || [])).subscribe(UIDms => this.ui.upsertMany(UIDms));

    entitiesService.getDiff$
      .pipe(map(({ modified, removed }) => ({ removedDms: removed?.DMS, modifiedDms: modified.DMS })))
      .subscribe(({ removedDms, modifiedDms }) => {
        if (modifiedDms) this.upsertMany(modifiedDms as DmsStoreEntity[]);
        if (removedDms) this.remove(removedDms);
      });

    entitiesService.activeEntityIds$.pipe(map(({ DMS }) => DMS || [])).subscribe(id => {
      this.ui.setActive(id);
    });
  }
}
