import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HorizontalBarChartsComponent } from './horizontal-bar-charts.component';

@NgModule({
  declarations: [HorizontalBarChartsComponent],
  imports: [CommonModule, NgxChartsModule],
  exports: [HorizontalBarChartsComponent],
})
export class HorizontalBarChartsModule {}
