import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { HeapClassDirectiveModule } from '@wc/wc-common/src';
import { WcMapModule } from '@wc/wc-map/src';
import { AutocompleteModule } from '@wc/wc-ui/src/components/autocomplete/autocomplete.module';
import { WcNotificationModule } from '@wc/wc-ui/src/components/notification/notification.module';
import { WcAutocompleteV2Module } from '@wc/wc-ui/src/form-components/autocomplete-v2/autocomplete-v2.module';
import { WcSelectControlModule as WcSelectControlModuleOld } from '@wc/wc-ui/src/form-components/select/select.module';
import { WcSelectModule } from '@wc/wc-ui/src/form-components/wc-select/wc-select.module';
import { MobxAngularModule } from 'mobx-angular';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';
import { WcLoaderModule } from '../../components/loader/loader.module';
import { SegmentAutocompleteV2Module } from '../../form-fields-controls/segment-autocomplete-v2/segment-autocomplete-v2.module';
import { DistanceFormatPipe } from '../../pipes';
import { AddressComponent } from './address.component';

const Materials = [MatIconModule];

@NgModule({
  declarations: [AddressComponent],
  providers: [DistanceFormatPipe],
  imports: [
    CommonModule,
    MobxAngularModule,
    FlexLayoutModule,
    WcBaseControlFieldModule,
    WcSelectModule,
    WcSelectControlModuleOld,
    WcNotificationModule,
    TranslateModule,
    WcAutocompleteV2Module,
    WcLoaderModule,
    Materials,
    WcMapModule,
    SegmentAutocompleteV2Module,
    AutocompleteModule,
    HeapClassDirectiveModule,
  ],
  exports: [AddressComponent],
})
export class AddressModule {}
