import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { RegionalSetting } from '@wc/core/models/gql.models';
import { LastUpdated } from '@wc/core/models/models';
import { Swiper } from 'swiper';

@Component({
  selector: 'wc-dms-content',
  templateUrl: './dms-content.component.html',
  styleUrls: ['./dms-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DmsContentComponent implements OnInit {
  @ViewChild(SwiperDirective, { static: true }) swiperElm?: SwiperDirective;

  @Input() set dmsList(dmsList: Array<Array<string>>) {
    this.dmsMessages = dmsList;
    this.dmsListSwiperConfig = {};
    this.initDmsListSwiperConfig();
    this.swiperElm?.setIndex(0);
  };

  @Input() isTablet = false;
  @Input() lastUpdated: LastUpdated | string | null = null;
  @Input() set regionalSettings(accountRegionalSettings: RegionalSetting) {
    this.accountRegionalSettings = accountRegionalSettings;
  };

  dmsListSwiperConfig!: SwiperConfigInterface;
  dmsMessages: Array<Array<string>> = [];
  accountRegionalSettings!: RegionalSetting;
  activeSlideIndex = 1;

  ngOnInit(): void {
    this.initDmsListSwiperConfig();
  }

  initDmsListSwiperConfig() {
    this.dmsListSwiperConfig = {
      direction: 'horizontal',
      slidesPerView: 1,
      /** Allow navigation between messages by dragging allowed only on tablet and if there is more than 1 message */
      allowTouchMove: this.isTablet && this.dmsMessages?.length > 1,
      navigation: {
        nextEl: '.dms-swiper-button-next',
        prevEl: '.dms-swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination-dms',
        clickable: true,
      }
    };
  }

  onSlideChange($event: Swiper) {
    this.activeSlideIndex = $event.realIndex + 1;
  }
}
