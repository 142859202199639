import { TranslateService } from '@ngx-translate/core';
import { SelectOption } from '../features/ui/form-controls/form-models';
import { sortObjectArrayByKey } from './sortObjectArrayByKey';

export const EnumToOptions = function (
  enumObj,
  _options?: {
    translateService: TranslateService;
    translateBy: 'value' | 'key';
    translatePath?: string;
    removeSort?: boolean;
    visibleValues?: any[];
  }
): Array<SelectOption> {
  const options: Array<SelectOption> = [];
  // boolean flag to indicate whether or not all options have display names
  let sortableByDisplayname = true;
  for (const key in enumObj) {
    if (enumObj.hasOwnProperty(key)) {
      const value = enumObj[key];
      let displayName = key;
      if (_options) {
        const translateName = _options.translateBy === 'key' ? key : value;
        if (_options.translatePath) {
          displayName = _options.translateService.instant(`${_options.translatePath}.${translateName}`);
        } else {
          displayName = _options.translateService.instant(`${translateName}`);
        }
      }
      if (!displayName) sortableByDisplayname = false;
      options.push({ displayName: displayName, value: value, hidden: !_options?.visibleValues?.includes(value) });
    }
  }

  // returns sorted options if possible
  if (sortableByDisplayname && (!_options || !_options?.removeSort))
    return sortObjectArrayByKey(options, 'displayName');
  return options;
};

export const strArrayToOptions = function (
  arr: Array<string>,
  _options?: {
    translateService: TranslateService;
    translateBy: 'value' | 'key';
    translatePath?: string;
    removeSort?: boolean;
  }
): SelectOption[] {
  const options: SelectOption[] = [];
  // boolean flag to indicate whether or not all options have display names
  let sortableByDisplayname = true;
  for (const key in arr) {
    if (arr.hasOwnProperty(key)) {
      const value = arr[key];
      let displayName: string = arr[key];
      if (_options) {
        const translateName = _options.translateBy === 'key' ? key : value;
        const translateKey = _options.translatePath ? `${_options.translatePath}.${translateName}` : `${translateName}`;
        displayName = _options.translateService.instant(translateKey);
      }
      if (!displayName) sortableByDisplayname = false;
      options.push({ displayName: displayName, value: value });
    }
  }
  // returns sorted options if possible
  if (sortableByDisplayname && (!_options || !_options?.removeSort))
    return sortObjectArrayByKey(options, 'displayName');
  return options;
};
