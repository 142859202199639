import { Injectable } from '@angular/core';
import {
  CollaborationMessageType,
  IncidentCollaboration,
  IncidentCollaborationMessage,
  MergeUpdate,
  SharePublicResponseUpdate,
  TransitUnitCollaboration,
  WebsocketService,
} from '@wc/core';
import { WebSocketType } from '@wc/core/models/enums';
import { cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthUserService } from './auth-user.service';

export type collaboration = {
  view: Record<number, string[]>;
  edit: Record<number, string[]>;
};

@Injectable({
  providedIn: 'root',
})
export class CollaborationService {
  private readonly collaborationSubject = new BehaviorSubject<collaboration>({
    view: {},
    edit: {},
  });

  readonly collaboration$ = this.collaborationSubject.asObservable();

  get collaborations() {
    return this.collaborationSubject.value;
  }

  private _collaboration?: IncidentCollaborationMessage;

  constructor(private wsService: WebsocketService, private authUserService: AuthUserService) {
    this.initCollaborationSync();
  }

  initCollaborationSync() {
    this.wsService.connect();
    this.wsService.subject
      .pipe(
        map((res: IncidentCollaboration[] | TransitUnitCollaboration | MergeUpdate | SharePublicResponseUpdate) => {
          if (Array.isArray(res) && res[0]?.type === WebSocketType.incidentUpdate) {
            const authUserName = this.authUserService.user.name;
            return res.reduce<collaboration>(
              (acc, curr) => {
                if (!curr.type || curr.type === WebSocketType.incidentUpdate) {
                  if (curr.editing?.length)
                    acc.edit[curr.incidentId] = curr.editing.filter(name => name !== authUserName);
                  if (curr.viewing?.length)
                    acc.view[curr.incidentId] = curr.viewing.filter(name => name !== authUserName);
                }
                return acc;
              },
              { view: {}, edit: {} }
            );
          }
          return null;
        }),
        filter((res): res is collaboration => !!res)
      )
      .subscribe(res => this.collaborationSubject.next(res));
  }

  updateCollaboration(collaborationMsg: IncidentCollaborationMessage) {
    this._collaboration = cloneDeep(collaborationMsg);
    this.wsService.send(this._collaboration);
  }

  stopCollaboration() {
    const stopCollaboration = this.getCompleteCollaborationMessage();
    if (stopCollaboration) this.wsService.send(stopCollaboration);
  }

  private getCompleteCollaborationMessage(): IncidentCollaborationMessage | null {
    const _stopCollaboration = cloneDeep(this._collaboration);
    if (_stopCollaboration?.type === CollaborationMessageType.START_EDIT) {
      _stopCollaboration.type = CollaborationMessageType.COMPLETE_EDIT;
      return _stopCollaboration;
    }
    if (_stopCollaboration?.type === CollaborationMessageType.START_VIEW) {
      _stopCollaboration.type = CollaborationMessageType.COMPLETE_VIEW;
      return _stopCollaboration;
    }

    return null;
  }
}
