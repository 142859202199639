import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { crashRiskStatusOptionsEnum, RegionalSetting, weatherStatusOptionsEnum } from '@wc/core/models';
import { CrashRisksQuery, CrashRisksService, HeapAnalyticsService } from '@wc/wc-core/src';
import {
  CrashRiskStoreEntity,
  CrashRiskStoreUIEntity,
  LayerPanelStoreItem,
  LayerVisibilityItem,
  LiveMapLayerNames,
} from '@wc/wc-models/src';
import { CrashRiskListByTimeFrames, TimeFrameCrashRisk } from '@wc/wc-models/src/lib/types/crash-risk';
import moment from 'moment';
import { fromEvent } from 'rxjs';
import { debounceTime, filter, map, take, takeWhile } from 'rxjs/operators';
import { isElInViewInsideContainer } from '../../../../../utils';

@Component({
  selector: 'wc-crash-risk-panel',
  templateUrl: './crash-risk-panel.component.html',
  styleUrls: ['./crash-risk-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrashRiskPanelComponent implements AfterViewInit, OnDestroy {
  @ViewChild('container') panelContainer!: ElementRef<any>;
  @Output() hidePanel = new EventEmitter<boolean>();
  @Output() layersVisibilityStateChange = new EventEmitter<LayerVisibilityItem[]>();
  @Output() filterChange = new EventEmitter<weatherStatusOptionsEnum>();
  @Output() selected = new EventEmitter<CrashRiskStoreEntity>();
  @Input() isDirectionRtl = false;
  @Input() isHebrew = false;
  @Input() isPortraitDesktopMode = false;
  @Input() regionalSetting!: RegionalSetting;
  @Input() set crashRiskItem(layerPanelStoreItem: LayerPanelStoreItem | null) {
    if (!layerPanelStoreItem) return;
    this.isShowOnMap = layerPanelStoreItem.checked;
  }
  @Input() set crashRiskListByTimeFrames(crashRiskListByTimeFrames: CrashRiskListByTimeFrames | undefined | null) {
    if (!crashRiskListByTimeFrames) return;
    this.timeFrameCrashRisks = crashRiskListByTimeFrames.timeFrameCrashRisks;
    this.crashRiskAmount = crashRiskListByTimeFrames.amount;
  }

  private idOfLastCrashRiskSelected!: number | null;
  private readonly currentViewedEntityIds: Set<number> = new Set();
  private isAlive = true;
  readonly moment = moment;
  readonly activeCrashRisk = this.crashRiskQuery.ui.selectActiveId().pipe(filter(id => !!id));
  isShowOnMap = true;
  crashRiskAmount = 0;
  selectedMapFilter =
    this.crashRisksService.getFilterSelectionFromLocalStorage() || crashRiskStatusOptionsEnum.allAlerts;
  crashRiskOptions = Object.values(crashRiskStatusOptionsEnum);
  timeFrameCrashRisks: TimeFrameCrashRisk[] = [];

  readonly uiCrashRisksViewedStateObj$ = this.crashRiskQuery.ui.selectAll().pipe(
    filter(entities => !!entities.length),
    map(entities =>
      entities.reduce((acc, curr) => {
        acc[curr.id] = !!curr.isViewed;
        return acc;
      }, {})
    )
  );

  constructor(
    private crashRisksService: CrashRisksService,
    private crashRiskQuery: CrashRisksQuery,
    private heapService: HeapAnalyticsService
  ) {}

  ngAfterViewInit(): void {
    this.activeCrashRisk.pipe(take(1)).subscribe(id => {
      document.getElementById(id.toString())?.scrollIntoView({
        block: 'center',
      });
    });

    this.accumulateViewEntities();

    fromEvent(this.panelContainer.nativeElement, 'scroll')
      .pipe(
        debounceTime(50),
        takeWhile(() => this.isAlive)
      )
      .subscribe(() => this.accumulateViewEntities());
  }

  eventsStatusFilterChange(e) {
    const filterName = e.value === crashRiskStatusOptionsEnum.allAlerts ? undefined : e.value;
    this.filterChange.emit(filterName);
    this.saveFilterSelectionToLocalStorage(filterName);
  }

  sendHeapEvent(isChecked: boolean) {
    const state = isChecked ? 'on' : 'off';
    this.heapService.trackUserSpecificAction(`heap-desktop-crash-risk-show-on-map-${state}`);
  }

  toggleShowOnMap(e) {
    this.sendHeapEvent(e.checked);
    this.isShowOnMap = e.checked;
    this.layersVisibilityStateChange.emit([{ name: LiveMapLayerNames.CRASH_RISK_AREA, checked: e.checked }]);
  }

  clickOnCrashRisk(crashRisk: CrashRiskStoreEntity) {
    if (!this.isShowOnMap) return;
    if (crashRisk.id === this.idOfLastCrashRiskSelected) {
      this.selected.emit();
      this.idOfLastCrashRiskSelected = null;
    } else {
      this.selected.emit(crashRisk);
      this.idOfLastCrashRiskSelected = crashRisk.id;
      this.crashRisksService.setCrashRiskAsViewed([crashRisk.id]);
    }
  }

  accumulateViewEntities() {
    this.getCurrentlyViewedEntitiesIds(this.crashRiskQuery.ui.getAll().filter(e => !e.isViewed)).forEach(id =>
      this.currentViewedEntityIds.add(id)
    );
  }

  private getCurrentlyViewedEntitiesIds(uiEntities: CrashRiskStoreUIEntity[]): number[] {
    return uiEntities
      .filter(e => isElInViewInsideContainer(document.getElementById(`${e.id}`), this.panelContainer.nativeElement, 50))
      .map(e => e.id);
  }

  closePanel() {
    this.hidePanel.emit();
  }

  private saveFilterSelectionToLocalStorage(selectedOption) {
    this.crashRisksService.saveFilterSelectionToLocalStorage(selectedOption || crashRiskStatusOptionsEnum.allAlerts);
  }

  trackById(index, item) {
    if (!item) {
      return null;
    }
    return item.id;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.crashRisksService.setCrashRiskAsViewed(Array.from(this.currentViewedEntityIds));
  }
}
