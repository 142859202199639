/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { EntityType, LayerType } from '@wc/core';
import { FeatureEvent, wcCoordinate, wcFeatureProperties } from '@wc/wc-map-viewer/src';
import {
  LayerNamesOptionsType,
  LayerPanelStoreItem,
  LayerPanelUIStoreItem,
  LiveMapLayerNames,
  TransitLayerType,
} from '@wc/wc-models/src';

export function getFpropsAndEntityTypeFromEvent(e: FeatureEvent): {
  fProps: wcFeatureProperties[] | undefined;
  entityType: LayerType | undefined | TransitLayerType;
} {
  if (!e.targets) return { fProps: undefined, entityType: undefined };
  const fProps = e.targets.map(t => t.fProps).flat(1);

  return {
    fProps,
    entityType: fProps[0].entityType as LayerType,
  };
}

export function getCenterCoordinatesByConfig(
  mapCenterOnClick: 'firstCoordinate' | 'clickCoordinate' | undefined,
  fProps: wcFeatureProperties,
  extras: { clickedOnCoordinate?: wcCoordinate }
): wcCoordinate {
  if (mapCenterOnClick === undefined || mapCenterOnClick === 'firstCoordinate') {
    if (fProps.firstCoordinateLonLat === undefined) throw new Error("Can't use firstCoordinate with value undefined");
    return fProps.firstCoordinateLonLat;
  }

  if (mapCenterOnClick === 'clickCoordinate' && extras.clickedOnCoordinate !== undefined) {
    return extras.clickedOnCoordinate;
  }
  return fProps.firstCoordinateLonLat as wcCoordinate;
}

export function assertAsFeatureEvent(value): asserts value is FeatureEvent {
  if (value.eventType === undefined || value.targets === undefined) {
    console.error('not a valid FeatureEvent:', { featureEvent: value });
  }
}

export function createLayerPanelStoreItems<T extends Record<string, LayerNamesOptionsType>>(
  names: T
): LayerPanelStoreItem<LayerNamesOptionsType>[] {
  return Object.values(names).map((name: LayerNamesOptionsType) => ({
    checked: false,
    name,
    isDisabled: false,
    hasParent: true,
    isMapLayer: true,
    children: null,
  }));
}

export function createTDLayerStoreItems(): LayerPanelStoreItem<LayerNamesOptionsType>[] {
  return createLayerPanelStoreItems(LiveMapLayerNames.trafficDisruptions).map(layer => {
    layer.appFeatureName = [`${layer.name.split('-')[0] as EntityType}:READ`];
    return layer;
  });
}

export function createIncidentsLayerStoreItems<T extends Record<string, LayerNamesOptionsType>>(
  names: T
): LayerPanelStoreItem<LayerNamesOptionsType>[] {
  const unidentifiedLayerName = 'INCIDENT-unidentified';
  const trafficAnomalyLayerName = 'INCIDENT-traffic_anomaly';
  const action = (treeCmp, node) => {
    if (node.name === trafficAnomalyLayerName || node.name === unidentifiedLayerName) return;

    const trafficAnomalyLayer = treeCmp.getNodeRef(trafficAnomalyLayerName);
    const unidentifiedLayer = treeCmp.getNodeRef(unidentifiedLayerName);
    const incidentsGroup = treeCmp.getNodeRef('incidents');

    if (!trafficAnomalyLayer || !unidentifiedLayer || !incidentsGroup) return;

    const isCheckUserBlockedLayers = treeCmp.treeControl
      .getDescendants(incidentsGroup)
      .filter(({ name }) => name !== trafficAnomalyLayerName && name !== unidentifiedLayerName)
      .some(({ checked }) => checked);

    treeCmp.updateNode(trafficAnomalyLayer, isCheckUserBlockedLayers);
    treeCmp.updateNode(unidentifiedLayer, isCheckUserBlockedLayers);
  };
  return createLayerPanelStoreItems(names).map(item => ({
    ...item,
    isDisabled: item.name === trafficAnomalyLayerName,
    cssClasses: item.name === unidentifiedLayerName ? ['display-none'] : [],
    action,
  }));
}

export function getFlatTreeData(tree: LayerPanelUIStoreItem[]): LayerPanelStoreItem<LayerNamesOptionsType>[] {
  const results: LayerPanelStoreItem<LayerNamesOptionsType>[] = [];

  const traverse = (node: LayerPanelUIStoreItem) => {
    if (node.isMapLayer) {
      results.push(node as LayerPanelStoreItem<LayerNamesOptionsType>);
    }
    if (node.children?.length) {
      node.children.forEach(traverse);
    }
  };
  tree.forEach(traverse);
  return results;
}
