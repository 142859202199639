import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { getTimes, GetTimesResult } from 'suncalc';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { Position } from '../../core/services/location.service';
import { ConfirmModalService } from '../../wc-ui/src/components/entry-level-components/confirm-modal/confirm-modal.service';
import { ConfirmationModalType } from '../models/enums';

// theme types
export type DarkModeAutomation = 'automatic' | 'manual';
export const darkModeAutomation: DarkModeAutomation[] = ['automatic', 'manual'];

export type SunTimes = {
  sunrise: GetTimesResult['sunrise'] | null;
  sunset: GetTimesResult['sunset'] | null;
};

@Injectable({ providedIn: 'root' })
export class ThemeService {
  initialPosition: Position | undefined;
  darkModeAutomation: DarkModeAutomation = 'manual';
  sunTimes: SunTimes = { sunrise: null, sunset: null };
  timeout: any;

  private readonly _isDarkMode = new BehaviorSubject<boolean>(false);
  readonly isDarkMode$ = this._isDarkMode.asObservable();

  get isDarkMode(): boolean {
    return this._isDarkMode.getValue();
  }

  private set isDarkMode(v: boolean) {
    if (v !== this.isDarkMode) {
      this._isDarkMode.next(v);
    }
  }

  private readonly _darkModeToggle = new BehaviorSubject<boolean>(false);
  readonly darkModeToggle$ = this._darkModeToggle.asObservable();

  get darkModeToggle(): boolean {
    return this._darkModeToggle.getValue();
  }

  private set darkModeToggle(v: boolean) {
    if (v !== this.darkModeToggle) {
      this._darkModeToggle.next(v);
      this.localStorageService.set(LocalStorageKeys.DarkModeToggle, v);
      this.setThemeClassOnBody();
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private localStorageService: LocalStorageService,
    private confirmService: ConfirmModalService
  ) {
    const toggleDarkModeLocalStorage = localStorageService.get(LocalStorageKeys.DarkModeToggle);
    if (toggleDarkModeLocalStorage && typeof toggleDarkModeLocalStorage === 'boolean') {
      this.darkModeToggle = toggleDarkModeLocalStorage;
    } else {
      localStorageService.set(LocalStorageKeys.DarkModeToggle, this.darkModeToggle);
    }

    const darkModeAutomationLocalStorage = localStorageService.get(LocalStorageKeys.DarkModeAutomation);
    if (darkModeAutomationLocalStorage && darkModeAutomation.includes(darkModeAutomationLocalStorage)) {
      this.darkModeAutomation = darkModeAutomationLocalStorage;
    } else {
      this.localStorageService.set(LocalStorageKeys.DarkModeAutomation, this.darkModeAutomation);
    }

    if (this.darkModeAutomation === 'manual' && this.darkModeToggle) this.isDarkMode = true;
    this.setThemeClassOnBody();
  }

  setDarkModeAutomation(v: DarkModeAutomation) {
    if (v !== this.darkModeAutomation) {
      this.darkModeAutomation = v;
      this.localStorageService.set(LocalStorageKeys.DarkModeAutomation, v);
      this.darkModeTimeoutAutomation();
      if (this.darkModeToggle && v === 'manual') {
        this.isDarkMode = true;
        this.setThemeClassOnBody();
      }
    }
  }

  public darkModeIntroductionPopup() {
    const nightModeIntroducedLocalStorage = this.localStorageService.get(LocalStorageKeys.DarkModeIntroduced);
    if (!nightModeIntroducedLocalStorage) {
      this.confirmService.showConfirmDialog(ConfirmationModalType.DarkModePopup);
      this.localStorageService.set(LocalStorageKeys.DarkModeIntroduced, true);
    }
  }

  public setDarkModeToggle(v: boolean) {
    this.darkModeToggle = v;
    if (!v) {
      this.isDarkMode = false;
      this.setThemeClassOnBody();
    } else if (this.darkModeAutomation === 'manual') {
      this.isDarkMode = true;
      this.setThemeClassOnBody();
    }
    this.darkModeTimeoutAutomation();
  }

  setThemeClassOnBody() {
    const className = 'dark-theme';
    const body = this.document.getElementsByTagName('body')[0];

    if (this.darkModeToggle && (this.isDarkMode || this.darkModeAutomation === 'manual')) {
      body.classList.add(className);
    } else if (body.classList.contains(className)) {
      body.classList.remove(className);
    }
  }

  setInitialPositionAndTimes(location: number[]) {
    if (location[0] && location[1]) {
      this.initialPosition = {
        coords: {
          longitude: location[0],
          latitude: location[1],
        },
      };
    }

    if (this.setSunCalcTimes(new Date())) {
      this.darkModeTimeoutAutomation();
    } else {
      // failed to get position, show error message
      this.darkModeAutomation = 'manual';
    }
  }

  /**
   * This function uses sunCalc library to calculate sunrise and sunset times
   * @param date the desired date
   * @returns true if calculation succeded, depends on initialPosition
   */
  setSunCalcTimes(date: Date) {
    if (this.initialPosition) {
      const {
        coords: { latitude, longitude },
      } = this.initialPosition;
      const times = getTimes(date, latitude, longitude);

      this.sunTimes = {
        sunrise: times.sunrise,
        sunset: times.sunset,
      };
      return true;
    } else return false;
  }

  /**
   * night mode automation function
   * calculates the nearest time difference until a sunset or a sunrise and
   * sets the respective isDarkMode value and creates a timeout
   * to run after timeDiff in milliseconds and flip isDarkMode's value
   */
  darkModeTimeoutAutomation() {
    if (
      this.darkModeToggle &&
      this.initialPosition &&
      this.sunTimes.sunrise &&
      this.sunTimes.sunset &&
      this.darkModeAutomation === 'automatic'
    ) {
      let timeDiff: number = 0;
      const currentTime = moment(new Date());
      const sunriseMoment = moment(this.sunTimes.sunrise);
      const sunsetMoment = moment(this.sunTimes.sunset);

      const isBeforeSunrise = currentTime.isBefore(sunriseMoment);
      const isAfterSunrise = currentTime.isAfter(sunriseMoment);
      const isBeforeSunset = currentTime.isBefore(sunsetMoment);
      const isAfterSunset = currentTime.isAfter(sunsetMoment);

      if (isBeforeSunrise) {
        timeDiff = sunriseMoment.diff(currentTime);
        this.isDarkMode = true;
      } else if (isAfterSunrise && isBeforeSunset) {
        timeDiff = sunsetMoment.diff(currentTime);
        this.isDarkMode = false;
      } else if (isAfterSunset) {
        // set time diff until tomorrow morning
        const tomorrowMoment = moment().add(1, 'd');
        const tomorrowDate = tomorrowMoment.toDate();
        if (this.setSunCalcTimes(tomorrowDate)) {
          timeDiff = tomorrowMoment.diff(currentTime);
          this.isDarkMode = true;
        }
      }

      // set dark theme class on body
      this.setThemeClassOnBody();

      if (timeDiff > 0) {
        this.clearTimeout();
        this.timeout = setTimeout(() => {
          this.darkModeTimeoutAutomation(); // call again to figure out current isDarkMode
          console.log('Automatic night mode set');
        }, timeDiff);
        console.log('Created setTimeout to run in ' + Math.round(timeDiff / 1000) + 's.');
      } else this.clearTimeout();
    } else this.clearTimeout();
  }

  clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
      console.log('Timeout disposed');
    }
  }
}
