import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { HeapAnalyticsService } from '@wc-core';
import { CustomFormControlComponent, FormFieldOption } from '../../lib/base/custom-form-control';

@Component({
  selector: 'wc-checkbox-list-control',
  templateUrl: './checkbox-list-control.component.html',
  styleUrls: ['./checkbox-list-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxListControlComponent extends CustomFormControlComponent {
  @Input() isTabletMode = false;
  @Input() highlightRowStyle = false;
  @Input() isPadding = true;
  @Input() showAllOptions = false;
  @Input() maxShowMoreLength = 5;
  @Input() enableShowMoreButton = false;
  @Output() arrowClicked: EventEmitter<string> = new EventEmitter<string>();
  selectedArrowIndex = -1;

  constructor(@Self() public ngControl: NgControl, cdr: ChangeDetectorRef, private heapService: HeapAnalyticsService) {
    super(ngControl, cdr);
  }
  isSelected(option: FormFieldOption<unknown, unknown>) {
    return this.ngControl.value.includes(option.value);
  }

  selectionChanged(value: string[] | null) {
    this.onChanged(value);
    this.onTouched();
    this.heapService.trackUserSpecificAction('heap-' + this.formFieldData.heapClass + '-checkbox-list', {
      selectedValue: JSON.stringify(value),
    });
  }
}
