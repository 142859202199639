<div class="associated-unit-container" [formGroup]="form">
  <div
    mat-icon-button
    [wcTooltipText]="'tooltip.deleteUnit' | translate"
    wcTooltipPlacement="bottom"
    [wcTooltipOffsetDistance]="10"
    class="remove-associated-unit-button"
    (click)="deleteAssociatedUnit(); $event.preventDefault()">
    <i class="fas fa-times-circle"></i>
  </div>
  <div class="associated-unit type-content" data-cy-id="associated-unit-type-dropdown">
    <wc-select-control
      *ngIf="isTabletMode"
      class="associated-unit type"
      [formControl]="$any(unitTypeControl)"
      [isTabletMode]="true"
      [formFieldData]="associatedUnitFieldData.unitType">
    </wc-select-control>
    <wc-select
      *ngIf="!isTabletMode"
      [formControl]="$any(unitTypeControl)"
      [formFieldData]="associatedUnitFieldData.unitType"></wc-select>
  </div>

  <div class="unit-dependent-fields" [class.disabled]="disableAssociatedUnitFormField()">
    <wc-autocomplete-v2
      *ngIf="isTabletMode"
      class="associated-unit id"
      #unitId
      [disabled]="disableAssociatedUnitFormField()"
      [formControl]="$any(this.form.get('unitId'))"
      [checkForNoResult]="true"
      [isTabletMode]="isTabletMode"
      [noResultFoundLabel]="'noUnitsMatchYourSearch'"
      [fieldData]="associatedUnitFieldData.unitId">
    </wc-autocomplete-v2>
    <wc-autocomplete
      #unitIdDesktop
      *ngIf="!isTabletMode"
      data-cy-id="unit-id-dropdown"
      formControlName="unitId"
      [formFieldData]="associatedUnitFieldData.unitId"
      [isDisabled]="disableAssociatedUnitFormField()"></wc-autocomplete>

    <wc-autocomplete-v2
      *ngIf="isTabletMode"
      data-cy-id="unit-driver-name-dropdown"
      class="associated-unit driver-name"
      #driverName
      [disabled]="disableAssociatedUnitFormField()"
      [formControl]="$any(this.form.get('driverName'))"
      [checkForNoResult]="true"
      [isTabletMode]="isTabletMode"
      [noResultFoundLabel]="'noDriversMatchYourSearch'"
      [fieldData]="associatedUnitFieldData.driverName">
    </wc-autocomplete-v2>
    <wc-autocomplete
      #driverNameDesktop
      *ngIf="!isTabletMode"
      formControlName="driverName"
      [formFieldData]="associatedUnitFieldData.driverName"
      [isDisabled]="disableAssociatedUnitFormField()"></wc-autocomplete>

    <wc-select-control
      *ngIf="isTabletMode"
      class="associated-unit status"
      [disabled]="disableAssociatedUnitFormField()"
      [isTabletMode]="isTabletMode"
      [formControl]="$any(this.form.get('unitResponse'))"
      [formFieldData]="associatedUnitFieldData.status">
    </wc-select-control>
    <wc-select
      *ngIf="!isTabletMode"
      class="associated-unit status"
      data-cy-id="unit-status-dropdown"
      [disabled]="disableAssociatedUnitFormField()"
      formControlName="unitResponse"
      [formFieldData]="associatedUnitFieldData.status">
    </wc-select>
  </div>

  <!-- mitigation form control-->
  <div class="associated-unit mitigations-content" [class.disabled]="!showMitigationForm">
    <wc-mitigation
      class="associated-unit mitigation"
      [class.disabled]="!showMitigationForm"
      [isMitigatedMode]="showMitigationForm"
      [formControl]="$any(mitigations)"
      #mitigationField>
    </wc-mitigation>
  </div>
</div>
