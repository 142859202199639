import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { MediaSectionModule, WcButtonModule, WcIconModule, WcMultipleChipsModule } from '@wc/wc-ui/src';
import { SelectMultiBoxesComponentModule } from '@wc/wc-ui/src/components/select-multi-boxes/select-multi-boxes-component.module';
import { WcTooltipModule } from '@wc/wc-ui/src/components/tooltip/tooltip.module';
import { WcSelectControlModule } from '@wc/wc-ui/src/form-components/select/select.module';
import { WcCheckboxModule } from '../../form-fields-controls/checkbox/checkbox.module';
import { WcTextAreaModule } from '../../form-fields-controls/text-area/text-area.module';
import { PublishIncidentFormComponent } from './publish-incident-form.component';

@NgModule({
  declarations: [PublishIncidentFormComponent],
  imports: [
    CommonModule,
    MatDividerModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    WcCheckboxModule,
    WcSelectControlModule,
    WcTextAreaModule,
    SelectMultiBoxesComponentModule,
    MediaSectionModule,
    WcIconModule,
    WcTooltipModule,
    WcButtonModule,
    WcMultipleChipsModule,
  ],
  providers: [],
  exports: [PublishIncidentFormComponent],
})
export class PublishIncidentFormModule {}
