import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { SplitIOService } from '@wc/core';
import { DOC_SUPPORTED_MIME_TYPES } from '@wc/wc-models/src/lib/constants/media';
import { FILE_SUPPORTED_FORMATS } from '@wc/wc-models/src/lib/enums/media';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

export const MAX_FILE_SIZE = 12;
export const MAX_TOTAL_FILE_SIZE = 50;
export enum MediaErrorType {
  'FILE_TYPE',
  'FILE_SIZE',
  'TOTAL_FILES_SIZE',
}
@Component({
  selector: 'wc-drop-file',
  templateUrl: './drop-file.component.html',
  styleUrls: ['./drop-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropFileComponent {
  @Input() maxFileSize = MAX_FILE_SIZE;
  @Input() maxTotalFileSize = MAX_TOTAL_FILE_SIZE;
  @Input() supportedFileTypes: FILE_SUPPORTED_FORMATS[] = [];
  @Input() currentFilesSize = 0;
  @Input() isTabletMode = false;
  @Input() isOneFileLimit = false;
  @Input() isPasteEnabled = false;
  @Output() dropFileUpdated = new EventEmitter<File>();
  @Output() uploadError = new EventEmitter<MediaErrorType>();
  files: File[] = [];

  private mimeTypes = DOC_SUPPORTED_MIME_TYPES;
  public readonly acceptMimeTypes = this.mimeTypes.reduce(
    (mimeTypeString, mimeType, index, mimeTypes) =>
      (mimeTypeString += index !== mimeTypes.length - 1 ? mimeType + ', ' : mimeType),
    ''
  );

  @HostListener('document:paste', ['$event'])
  paste(event: ClipboardEvent) {
    if (this.isPasteEnabled) {
      const files = Array.from(event.clipboardData?.files || []);
      this.isOneFileLimit && files.length > 1 ? this.addFiles([files[0]]) : this.addFiles(files);
    }
  }

  constructor(private cdr: ChangeDetectorRef, private splitService: SplitIOService) {}

  async onDroppedFile(files: NgxFileDropEntry[]) {
    const _files: File[] = [];

    for (let i = 0; i < files.length; i++) {
      const fileEntry = files[i].fileEntry as FileSystemFileEntry;
      await new Promise(resolve => {
        fileEntry.file((f: File) => {
          _files.push(f);
          resolve(true);
        });
      });
    }

    this.addFiles(_files);
  }

  onFileUploaded($event: Event) {
    const files = Array.from(($event.target as HTMLInputElement).files as ArrayLike<File>);
    this.addFiles(files);
    ($event.target as HTMLInputElement).value = '';
  }

  private addFiles(files: File[]) {
    this.files = [];
    files.forEach(file => {
      if (this.isFileAllowed(file.name) && this.isFileSizeAllowed(file?.size)) {
        this.files.push(file);
        this.dropFileUpdated.emit(file);
      }
      this.cdr.detectChanges();
    });
  }

  private isFileAllowed(fileName: string) {
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (extension?.some(ex => this.supportedFileTypes.includes(ex as FILE_SUPPORTED_FORMATS))) {
      return true;
    }
    this.uploadError.emit(MediaErrorType.FILE_TYPE);
    return false;
  }

  private isFileSizeAllowed(sizeInBytes) {
    const sizeInMb = sizeInBytes * 0.000001;
    if (sizeInMb < this.maxFileSize) {
      if (sizeInMb + this.currentFilesSize > 50) {
        this.uploadError.emit(MediaErrorType.TOTAL_FILES_SIZE);
        return false;
      }
      return true;
    } else {
      this.uploadError.emit(MediaErrorType.FILE_SIZE);
      return false;
    }
  }

  openBrowserFiles() {
    document.getElementById('browse-files-btn')?.click();
  }

  resetFiles() {
    this.files = [];
    this.cdr.markForCheck();
  }
}
