<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<mat-toolbar-row class="header-toolbar" [class.language-rtl]="isDirectionRtl">
  <div class="navbar-header">
    <a data-cy-id="rekor-command-logo-desktop" class="navbar-brand heap-desktop-header-wc-logo" [routerLink]="['/']">
      <div class="navbar-brand">
        <strong>
          <img src="assets/rekor-command-logo-desktop.svg" alt="homepage" width="120px" class="light-logo" />
        </strong>
        <span fxShow="false" fxShow.gt-xs></span>
      </div>
    </a>
  </div>

  <div class="fill-remaining-space">
    <a
      class="main-nav-link heap-desktop-header-live-map-btn"
      mat-button
      [routerLink]="['/live-map']"
      [routerLinkActive]="['active']">
      {{ 'liveMap' | translate }}
    </a>
    <a
      *ngIf="hasDataHubPermission"
      class="main-nav-link heap-desktop-header-data-hub-btn"
      data-cy-id="data-hub-btn"
      mat-button
      [routerLink]="['/analytics']"
      [routerLinkActive]="['active']">
      {{ 'analytics' | translate }}
    </a>
  </div>

  <div class="right-side-buttons-container">
    <button
      wcHeapClass="return-to-widget"
      class="open-widget-btn"
      *ngIf="isElectronApp"
      rekor-basic
      [leftSideIcon]="'open-widget'"
      [buttonSize]="'small'"
      (click)="openWidget()">
      {{ 'widget.openWidget' | translate }}
    </button>

    <wc-onboarding-tours
      *ngIf="!hideButtonFromHeader"
      #onboardingTours
      [buttonInsideUserMenu]="false"></wc-onboarding-tours>

    <div *ngIf="(newReleasesCount | async) !== 0" class="header-toolbar__release-note-badge">
      {{ newReleasesCount | async }}
    </div>

    <wc-settings-menu #settingsMenu [showOnBoarding]="hideButtonFromHeader"></wc-settings-menu>
  </div>
</mat-toolbar-row>
