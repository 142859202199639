<div class="workspace-list-wrapper" [class.tablet-mode]="isTabletMode">
  <form class="workspaces-form" [formGroup]="workspacesForm">
    <ng-container *ngFor="let control of controls; let i = index">
      <wc-slider-toggle-control
        class="slide-toggle"
        [formControl]="control"
        [formFieldData]="filedFormDate[i]"
        [ngClass]="
          'heap-' +
          (isTablet ? 'tablet' : 'desktop') +
          '-' +
          fieldDataArray[i].label?.toLowerCase() +
          '-slide-toggle-control'
        ">
      </wc-slider-toggle-control>
      <div class="workspaces-form__cctv-badge" *ngIf="isFilterNearCamerasWorkspace[i]">
        {{ 'onlyIncidentNearCCTV' | translate }}
      </div>
    </ng-container>
  </form>
</div>
