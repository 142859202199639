/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AppFeatureEnum,
  LayerType,
  LocalStorageService,
  RegionalSetting,
  SplitIOService,
  weatherStatusOptionsEnum,
} from '@wc/core';
import { CustomRxOperatorsService } from '@wc/core/services/custom-rx-operators.service';
import { PermissionsFacadeService } from '@wc/permissions/domain/src';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  CrashRisksQuery,
  EntitiesServiceV2,
  liveMapFilters,
  LiveMapFiltersService,
  LiveMapQuery,
  LiveMapService,
  RoadwayStatusQuery,
  TransitBusStopsQuery,
  TransitQuery,
  TransitRoutesQuery,
  TransitService,
  WeatherEventsQuery,
} from '@wc/wc-core/src';
import { APP_TYPE_TOKEN } from '@wc/wc-core/src/lib/injection-tokens';
import { AccountService } from '@wc/wc-core/src/lib/services/account.service';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { MapCenterOptions, Offset, StaticLayerName, StaticLayerOptionNames, wcCoordinate } from '@wc/wc-map-viewer';
import {
  AppTypeUnion,
  LayerNamesOptionsType,
  LayerPanelStoreItem,
  LayerVisibility,
  LayerVisibilityItem,
  LiveMapLayerNames,
  RoadwayStatusStoreEntity,
  ScopeAccessModifier,
  TransitLayerType,
  WorkspaceSelection,
} from '@wc/wc-models/src';
import { fromEvent, merge, Observable, of } from 'rxjs';
import { debounceTime, filter, map, mergeAll, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { TransitAffectedServicesService } from '../../transit/transit-panel/transit-panel-main-tabs/route-list-tab/affected-services-transit/transit-affected-services.service';
import { TransitConfig } from '../../transit/transit.config';
import { entityTypeConfig } from './live-map-viewer.config';
import { TransitBusStopsLayersConfig } from './transit-style.config';

const userInputFields = ['INPUT', 'TEXTAREA'];

export type mapPanelModulesConfig<T extends Record<string, any>> = {
  name?: string;
  iconName?: string;
  enabled?: Observable<boolean>;
  comingSoonSrcImg?: string;
  badgeSrcValue?: Observable<any>;
  isDivider?: boolean;
  heapClass?: string;
};

export enum MapModuleNames {
  BASIC_LAYERS = 'basicLayers',
  WEATHER_EVENTS = 'weatherEvents',
  TRANSIT = 'transit',
  CRASH_RISK = 'crashRisk',
  ROADWAY_STATUS = 'roadwayStatus',
}

export type SameTypeEntitiesVisibility = { ids: number[]; entityType: string; value: boolean };
@Component({
  selector: 'wc-live-map-viewer',
  templateUrl: './live-map-viewer.component.html',
  styleUrls: ['./live-map-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiveMapViewerComponent implements AfterViewInit, OnDestroy, OnInit {
  readonly layerStoreItems$ = this.liveMapQuery.layerPanelStoreItemsMap$;
  readonly weatherStoreItems$ = this.layerStoreItems$.pipe(
    map(itemsMap =>
      Object.values(LiveMapLayerNames.WEATHER_ALERT).map(name => {
        return itemsMap.get(name as LayerNamesOptionsType) as LayerPanelStoreItem<LayerNamesOptionsType>;
      })
    )
  );
  readonly crashRiskItem$ = this.layerStoreItems$.pipe(
    map(itemsMap => itemsMap.get(LiveMapLayerNames.CRASH_RISK_AREA) as LayerPanelStoreItem<LayerNamesOptionsType>)
  );

  readonly roadwayStatusItem = this.layerStoreItems$.pipe(
    map(itemsMap => itemsMap.get(LiveMapLayerNames.ROADWAY_STATUS) as LayerPanelStoreItem<LayerNamesOptionsType>)
  );

  @Output() mapReady = new EventEmitter<boolean>();
  @Output() currentOpenPanel = new EventEmitter<undefined | MapModuleNames>();
  @ViewChild('mapContainer') mapContainer!: ElementRef;
  selectedPanelAdditionalItems?: Record<string, any>;
  @Input() set openPanel(value: { panelName: MapModuleNames | null; additionalItems?: Record<string, any> } | null) {
    this.mapModulesBtnSelectedValue = value?.panelName || undefined;

    this.selectedPanelAdditionalItems = value?.additionalItems;
  }

  @Input() isHebrew = false;
  @Input() isPortraitDesktopMode = false;
  @Input() isDirectionRtl = false;
  @Input() disposeMapOnDestroy = false;

  AppFeatureEnum = AppFeatureEnum;
  MapModuleNames = MapModuleNames;
  StaticLayerName = StaticLayerName;
  weatherStatusOptionsEnum = weatherStatusOptionsEnum;
  mapModulesBtnSelectedValue: MapModuleNames | undefined;
  private staticMapLayersState: {
    [K in StaticLayerOptionNames]?: boolean;
  } = {};

  staticLayerButtons = {
    mileMarker: 'mileMarkerREKOR',
    satelliteButton: this.splitIoService.isActiveFeatureToggle(AppFeatureEnum.FE_USE_AWS_SATELLITE_MAP)
      ? 'satelliteAWS'
      : 'satelliteMAPBOX',
    trafficButton: 'trafficMAPBOX',
  } as const;
  /**
   * The following object sets configuration for live map panels buttons.
   *
   * - hidePanelOnClickOutside (prop) - default false
   *
   * @usageNotes
   * By default, all panel shouldn't be closed on click outside. If there is a need to change this behavior for specific
   * panel add to 'hidePanelOnClickOutside: true' to specific panel configuration.
   *
   * @example
   * mapModuleBtnConfigs = [{
   *   {
   *     name: MapModuleNames.BASIC_LAYERS,
   *     hidePanelOnClickOutside: true
   *   }
   * }];
   *
   * Check the hideSidePanel() method for more details.
   */
  mapModuleBtnConfigs: mapPanelModulesConfig<any>[] = [
    {
      name: MapModuleNames.BASIC_LAYERS,
      iconName: 'layers',
      enabled: of(true),
      heapClass: 'heap-desktop-map-layers-panel-button',
    },
    {
      name: MapModuleNames.TRANSIT,
      iconName: 'bus',
      enabled: this.checkPermissions('TRANSIT:READ').pipe(
        map(isPermitted => {
          return isPermitted && this._appType === 'desktop';
        })
      ),
      comingSoonSrcImg: 'assets/images/transit-soon.svg',
      badgeSrcValue: this.transitQuery.notViewedAffectedServices$,
      heapClass: 'heap-desktop-transit-panel-button',
    },
    {
      name: MapModuleNames.WEATHER_EVENTS,
      iconName: 'weather',
      enabled: this.checkPermissions('WEATHER:READ').pipe(
        map(isPermitted => {
          return isPermitted && this._appType === 'desktop';
        })
      ),
      comingSoonSrcImg: 'assets/images/weather_soon.svg',
      badgeSrcValue: this.weatherEventsQuery.notViewedWeatherEventIds$,
      heapClass: 'heap-desktop-weather-alerts-panel-button',
    },
    {
      isDivider: true,
      name: MapModuleNames.CRASH_RISK,
      iconName: 'crash-risk',
      comingSoonSrcImg: 'assets/images/crash-risk-soon.svg',
      enabled: this.checkPermissions('CRASH_RISK:READ').pipe(
        map(isPermitted => {
          return isPermitted && this._appType === 'desktop';
        })
      ),
      badgeSrcValue: this.crashRisksQuery.ui.selectAll().pipe(map(entities => entities.filter(e => !e.isViewed))),
      heapClass: 'heap-desktop-crash-risk-panel-button',
    },
    {
      name: MapModuleNames.ROADWAY_STATUS,
      iconName: 'bar-chart',
      enabled: this.checkPermissions('ROADWAY_STATUS_METRIC:READ').pipe(
        map(isPermitted => {
          return isPermitted && this._appType === 'desktop';
        })
      ),
      heapClass: 'heap-desktop-realtime-analytics-panel-button',
    },
  ];

  isAlive = true;

  get layerTypes(): typeof LayerType {
    return LayerType;
  }

  get mapReady$() {
    return this.liveMapService.isMapReady$;
  }

  get isAllWorkspaceUnchecked$() {
    return this.liveMapQuery.isAllWorkspaceUnchecked$;
  }

  get availableWorkspaces$() {
    return this.liveMapQuery.availableWorkspaces$;
  }

  get crashRiskListByTimeFrames$() {
    return this.crashRisksQuery.crashRiskListByTimeFrames$;
  }

  get crashRisks$() {
    return this.crashRisksQuery.selectAll();
  }

  get weatherEvents$() {
    return this.weatherEventsQuery.weatherEventsByStatus$;
  }

  get activeWeatherEvent() {
    return this.weatherEventsQuery.ui.getActiveId();
  }

  get regionalSetting(): RegionalSetting {
    return this.accountService.account.regionalSetting;
  }

  get accountId(): number {
    return this.accountService.account.id;
  }

  get currentMapFilters() {
    return this.liveMapService.getCurrentFilters();
  }

  get roadwayStatusSegments$() {
    return this.roadwayStatusQuery.selectAll();
  }

  get mapZoomLevel() {
    return this.liveMapService.mapZoomLevel;
  }

  get staticMapLayersStates() {
    return this.staticMapLayersState;
  }

  get isActiveFeatureToggle() {
    return (featureEnum: AppFeatureEnum) => this.splitIoService.isActiveFeatureToggle(featureEnum);
  }

  get appType() {
    return this._appType;
  }

  constructor(
    private accountService: AccountService,
    private liveMapService: LiveMapService,
    private liveMapQuery: LiveMapQuery,
    private splitIoService: SplitIOService,
    private liveMapFiltersService: LiveMapFiltersService,
    private roadwayStatusQuery: RoadwayStatusQuery,
    private weatherEventsQuery: WeatherEventsQuery,
    private crashRisksQuery: CrashRisksQuery,
    private entitiesService: EntitiesServiceV2,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private transitService: TransitService,
    private transitBusStopsQuery: TransitBusStopsQuery,
    private transitRoutesQuery: TransitRoutesQuery,
    private affectedServicesService: TransitAffectedServicesService,
    private transitQuery: TransitQuery,
    private permissionsFacadeService: PermissionsFacadeService,
    private customRxOperators: CustomRxOperatorsService,
    @Inject(APP_TYPE_TOKEN) private _appType: AppTypeUnion
  ) {}

  ngOnInit(): void {
    this.updateViewedEntitiesFromLocalStorage();
    this.updateStaticMapLayersFromLocalStorage();
  }

  ngAfterViewInit(): void {
    const mapCenterOptions = this.getMapCenterOptionsFromUrlFragment(this.route.snapshot.fragment);
    this.liveMapService
      .init(this.mapContainer.nativeElement, this.liveMapService.getInitLiveMapLayersVisibilityState(), {
        centerFromUrl: mapCenterOptions,
      })
      .pipe(
        takeWhile(() => this.isAlive),
        this.customRxOperators.tapOnce(() => {
          this.initTransitAffectedServices()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(() => {
              this.initTransitAndUpdateTransitVisibility();
            });
          this.mapReady.emit(true);
        }),
        switchMap(() => this.liveMapService.moveEndEvent$.pipe(debounceTime(100))),
        filter(() => !this.router.url.includes('edit')),
        map(() => this.createNewFragmentFromLocationInfo()),
        tap(newFragment => this.updateLocationFragment(newFragment))
      )
      .subscribe();

    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        takeWhile(() => this.isAlive),
        filter(event => !this.tagNameIsUserInputType(event)),
        map(event => this.transformToZoomAction(event)),
        filter((zoomAction): zoomAction is 'ol-zoom-in' | 'ol-zoom-out' => zoomAction !== undefined)
      )
      .subscribe(zoomAction => (document.getElementsByClassName(zoomAction)?.[0] as HTMLElement).click());
  }

  checkPermissions(permission?: ScopeAccessModifier): Observable<boolean> {
    return of(permission ? this.permissionsFacadeService.hasPermission(permission) : false);
  }

  updateTransitLayersVisibility(layersVisibility: LayerVisibility[]) {
    this.localStorageService.set(LocalStorageKeys.TransitLayersVisibility, layersVisibility);
    this.liveMapService.updateTransitLayersVisibility(layersVisibility);
  }

  setLayersVisibilityState(shallowLayerNodes: LayerVisibilityItem[]) {
    this.liveMapService.setLayersVisibilityUpdates(shallowLayerNodes);
  }

  workspaceSelection(selectedWorkspace: WorkspaceSelection) {
    this.liveMapService.updateWorkSpaceSelection(selectedWorkspace);
  }

  mapModulesBtnChange(e: MatButtonToggleChange) {
    this.ifBasicLayersClosedResetUpdates(this.mapModulesBtnSelectedValue);
    this.mapModulesBtnSelectedValue === e.value
      ? (this.mapModulesBtnSelectedValue = undefined)
      : (this.mapModulesBtnSelectedValue = e.value);
    this.currentOpenPanel.emit(this.mapModulesBtnSelectedValue);
  }

  changeFilter<T extends keyof liveMapFilters>(key: T, value: liveMapFilters[T]) {
    this.liveMapFiltersService.updateLiveMapFilterObject(key, value);
  }

  selectFeatureAndOverlay(e, layerType: LayerType, emitEvent = true) {
    if (!e) return;
    this.liveMapService.selectFeatureAndOverlay(layerType, e.id, emitEvent);
  }

  selectFeatureAndCenterMap(e, layerType: LayerType, emitEvent = true) {
    this.liveMapService.unselectFeature();
    this.liveMapService.selectFeature(layerType, e.id, emitEvent);
    this.setMapCenterFromCoordinates(e);
  }

  changeMapOffset(offset: Offset) {
    this.liveMapService.setMapCenter(this.liveMapService.mapCenterPoint, {
      duration: 1000,
      offset,
    });
  }

  setMapCenterFromCoordinates(entity: RoadwayStatusStoreEntity) {
    this.liveMapService.setMapCenter(entity.location.coordinates, entityTypeConfig[entity.layerType]?.options);
  }

  private initTransitAndUpdateTransitVisibility() {
    this.checkPermissions('TRANSIT:READ')
      .pipe(
        takeWhile(() => this.isAlive),
        filter(isActive => isActive),
        switchMap(() =>
          this.transitService.init().pipe(
            switchMap(() =>
              this.entitiesService.getDiff$.pipe(
                filter(() => this.transitRoutesQuery.getCount() > 0),
                take(1),
                tap(() => {
                  const layersVisibility = this.transitService.initLayersVisibility || TransitConfig.LayersVisibility;
                  this.updateTransitLayersVisibility(layersVisibility);
                  this.transitService.setVisibleRoutes(this.transitService.initRouteIdsVisibility);

                  merge([this.liveMapService.dragEndEvent$, this.liveMapService.zoomEndEvent$])
                    .pipe(mergeAll(), debounceTime(100))
                    .subscribe(() => this.loadBusStopsByViewExtent());
                })
              )
            ),
            switchMap(() =>
              this.entitiesService.getDiff$.pipe(
                /**
                 * This filter prevent first load flicker visibility.
                 */
                filter(
                  ({ modified }) => !!modified.TRANSIT_FIXED_ROUTE_BUS?.length || !!modified.TRANSIT_BUS_STOP?.length
                ),
                take(1),
                tap(() => {
                  const layersVisibility = this.transitService.initLayersVisibility || TransitConfig.LayersVisibility;
                  this.updateTransitLayersVisibility(layersVisibility);
                  this.transitService.setVisibleRoutes(this.transitService.initRouteIdsVisibility);
                })
              )
            )
          )
        )
      )
      .subscribe();

    merge([
      this.transitBusStopsQuery.busStopsAddedQuery$,
      this.transitRoutesQuery.modifiedTransitRoutes$,
      this.liveMapFiltersService.filterChanged$,
    ])
      .pipe(
        mergeAll(),
        takeWhile(() => this.isAlive)
      )
      .subscribe(() => {
        this.transitService.updateBusStopsVisibility(
          false,
          this.transitBusStopsQuery.getAll().map(busStop => busStop.busStopId as number)
        );

        const filterByMyAccount = !!this.liveMapFiltersService.currentFilters.transitIsMyAgency;
        this.transitService.updateBusStopsVisibility(
          true,
          this.transitRoutesQuery.getAllVisibleRoutesBusStopsFilteredByIsMyAccount(filterByMyAccount)
        );
      });
  }

  private loadBusStopsByViewExtent() {
    if (
      this.liveMapService.mapZoomLevel <
      TransitBusStopsLayersConfig[`${TransitLayerType.TransitBusStop}-transit_fixed_stops`].minZoom
    )
      return;
    const coordinates = this.liveMapService.getViewExtentCoordinates();
    const loadedBusStopIds = this.transitBusStopsQuery.getAll();
    this.transitService.loadBusStopsByViewExtent(coordinates, loadedBusStopIds.map(stop => stop.busStopId) as number[]);
  }

  private initTransitAffectedServices() {
    return this.checkPermissions('TRANSIT:READ').pipe(
      switchMap(isActive => (isActive ? this.affectedServicesService.fetchAffectedServicesAndEmitDiff() : of(true)))
    );
  }

  private updateLocationFragment(fragment: string): void {
    location.hash = fragment;
  }

  private createNewFragmentFromLocationInfo(): string {
    return `${this.liveMapService.mapCenter[1]},${[this.liveMapService.mapCenter[0]]},${
      Math.floor(this.liveMapService.mapZoomLevel * 100) / 100
    }z`;
  }

  private getMapCenterOptionsFromUrlFragment(fragment: string | null): MapCenterOptions<wcCoordinate> | null {
    if (!fragment) return null;
    const fragmentArr = fragment
      .replace('z', '')
      .split(',')
      .map(param => +param);
    return {
      zoomLevel: fragmentArr[2],
      mapCenter: [fragmentArr[1], fragmentArr[0]],
    };
  }

  private updateViewedEntitiesFromLocalStorage() {
    this.entitiesService.emitNewUIDiff({
      CRASH_RISK_AREA: this.localStorageService
        .get(LocalStorageKeys.ViewedCrashRisksIds)
        ?.map(id => ({ id, isViewed: true } || [])),
    });
  }

  private updateStaticMapLayersFromLocalStorage() {
    if (this.localStorageService.get(LocalStorageKeys.StaticMapLayersState)) {
      this.staticMapLayersState = this.localStorageService.get(LocalStorageKeys.StaticMapLayersState);
    }
  }

  protected changeStaticMapLayerStatus(event: { name: StaticLayerOptionNames; status: boolean }) {
    this.staticMapLayersState[event.name] = event.status;
    this.localStorageService.set(LocalStorageKeys.StaticMapLayersState, this.staticMapLayersState);
  }

  closePanel(panelName?: MapModuleNames) {
    this.currentOpenPanel.emit(undefined);
    this.mapModulesBtnSelectedValue = undefined;
    this.ifBasicLayersClosedResetUpdates(panelName);
  }

  private ifBasicLayersClosedResetUpdates(panelName?: MapModuleNames | null | undefined | '') {
    if (panelName && panelName === MapModuleNames.BASIC_LAYERS) {
      this.liveMapService.resetDataUpdatesReplaySubject();
    }
  }

  private tagNameIsUserInputType(event: KeyboardEvent) {
    return userInputFields.includes(event.target?.['tagName']);
  }

  private transformToZoomAction(event: KeyboardEvent): 'ol-zoom-in' | 'ol-zoom-out' | undefined {
    const _key = event.key;
    if (!_key) return undefined;
    return _key === '+' || _key === '=' ? 'ol-zoom-in' : _key === '_' || _key === '-' ? 'ol-zoom-out' : undefined;
  }

  ngOnDestroy() {
    this.isAlive = false;
    this.transitService.clearSubscriptions();
    this.affectedServicesService.clearSubscriptions();
    if (this.disposeMapOnDestroy) {
      this.liveMapService.destroyMap();
    }
  }
}
