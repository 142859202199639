import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PermissionsFacadeService } from './application/permissions-facade.service';
import { RolesFacadeService } from './application/roles-facade.service';

@NgModule({
  imports: [CommonModule],
  providers: [PermissionsFacadeService, RolesFacadeService],
})
export class PermissionsDomainModule {}
