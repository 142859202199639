<div [formGroup]="group" class="list-component">
  <div data-cy-id="add-button" (click)="addControl()" class="list-component__add-btn">
    <wc-icon iconName="plus"></wc-icon>
    {{ 'add' | translate }}
  </div>
  <wc-checkbox-list-control
    [isPadding]="false"
    [highlightRowStyle]="true"
    [formFieldData]="formFieldData"
    [formControlName]="fixedControlName">
  </wc-checkbox-list-control>

  <ng-container [formArrayName]="freeTextControlName">
    <div
      data-cy-id="value-list-row"
      [formGroupName]="i"
      class="list-component__row"
      *ngFor="let control of formArray.controls; let i = index">
      <mat-checkbox data-cy-id="value-list-row-checkbox" formControlName="checked"> </mat-checkbox>
      <wc-text-field
        data-cy-id="free-text-field"
        class="list-component__text-field"
        [viewCharacterCounter]="true"
        formControlName="value"></wc-text-field>

      <wc-icon data-cy-id="delete-icon" (click)="removeControl(i)" iconName="trash"></wc-icon>
    </div>
  </ng-container>
</div>
