import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { formatDuration } from '../../utils/utils-functions';

@Pipe({
  name: 'durationCountDown',
})

// Get date as UTC string format
export class DurationCountDownPipe implements PipeTransform {
  transform(startTime: Date, endTime?: string): string {
    if (!endTime) return '00:05';
    const date1 = startTime;
    const date2 = new Date(endTime);

    if (moment(date2).isBefore(date1)) {
      return '00:05';
    }
    const timeDiff = moment.duration(date2.getTime() - date1.getTime());

    if (timeDiff.asHours() > 99) {
      return '99:00 +';
    }

    const duration = formatDuration(Math.floor(timeDiff.asMinutes()));
    return duration;
  }
}
