import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IncidentStore, TrafficDisruptionStore, UiStore, UsersStore } from '@wc/core';
import { Incident, IncidentLane, LaneType, TrafficDisruption, UpdateTrafficDisruptionLaneInput } from '@wc/core/models';
import { getAffectedLanesSlidesByScreenWidth } from '@wc/utils';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { RoadEventLane } from '../affected-lanes/affected-lanes.component';
@Component({
  selector: 'wc-affected-lanes-view',
  templateUrl: './affected-lanes-view.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AffectedLanesViewComponent),
      multi: true,
    },
  ],
  styleUrls: ['./affected-lanes-view.component.scss'],
})
export class AffectedLanesViewComponent implements OnInit, AfterViewInit {
  @ViewChild(SwiperDirective, { static: true }) swiperElm?: SwiperDirective;

  @Input() set entity(entity) {
    this._entity = entity;
    this.initLanesToggles();
    this.cdr.markForCheck();
  }
  @Input() allLanesAffected = false;
  @Input() multiDirectionLanesAffecteds = false;
  @Input() entityType!: 'Incident' | 'TrafficDisruption';
  @Input() mode: 'edit' | 'analytics' = 'edit';

  innerWidth = 768;
  _entity!: Incident | TrafficDisruption;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.initLanesSwiperConfig();
  }

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  get affectedLanes() {
    return this._entity.affectedLanes;
  }

  lanesToggels = new FormArray([]);
  isAffectedLanesFound = false;
  hoverLaneIndex = null;
  affectedLaneSwiper: {
    index: number;
    lane: any;
  }[] = [];
  TextualLanes = [LaneType.Gore, LaneType.LeftShoulder, LaneType.RightShoulder];

  config!: SwiperConfigInterface;

  constructor(
    private fb: FormBuilder,
    private incidentStore: IncidentStore,
    public uiStore: UiStore,
    private trafficDisruptionStore: TrafficDisruptionStore,
    public userStore: UsersStore,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.initLanesToggles();
  }

  ngAfterViewInit(): void {
    this.initLanesSwiperConfig();
    this.swiperElm?.update();
  }

  initLanesSwiperConfig() {
    let { slidesPerView, spaceBetweenSlides } = getAffectedLanesSlidesByScreenWidth(this.innerWidth, this.isTabletMode);
    slidesPerView = this.isTabletMode && this.affectedLaneSwiper.length === 1 ? 1 : slidesPerView;

    this.config = {
      direction: 'horizontal',
      slidesPerView: slidesPerView,
      spaceBetween: spaceBetweenSlides,
      navigation: {
        nextEl: '.lanes-swiper-button-next',
        prevEl: '.lanes-swiper-button-prev',
      },
      pagination: {
        el: '.lanes-swiper-pagination',
        clickable: true,
      },
      /** Disable slider arrows if there is only 1 available slide */
      watchOverflow: true,
    };
  }

  initLanesToggles() {
    this._entity.affectedLanes?.forEach((lane, index) => {
      if (lane.isAffected) {
        this.isAffectedLanesFound = true;
        this.affectedLaneSwiper.push({ index, lane });
      }
      this.lanesToggels.push(this.fb.control(lane.isClosed));
    });
  }

  laneHovered($event) {
    if ($event && $event.lane) {
      this.hoverLaneIndex = $event.lane.positionIndex;
    } else if ($event) {
      this.hoverLaneIndex = $event.positionIndex;
    } else {
      this.hoverLaneIndex = null;
    }
  }

  updateLaneStatus(index: number) {
    const _affectedLanes = this._entity.affectedLanes as RoadEventLane[];
    _affectedLanes[index].isClosed = !this.lanesToggels.controls[index].value;

    if (this.entityType === 'TrafficDisruption') {
      this.trafficDisruptionStore.updateTrafficDisruptionLane(
        this.trafficDisruptionStore.selectedTrafficDisruption,
        _affectedLanes[index] as UpdateTrafficDisruptionLaneInput
      );
    }
    if (this.entityType === 'Incident') {
      this._entity.affectedLanes = [...[], ...(this._entity.affectedLanes as Array<IncidentLane>)];
      this.incidentStore.updateIncident(this._entity as Incident, {} as Incident).subscribe();
    }
    this.cdr.markForCheck();
  }
}
