<div class="transit-panel-tab-content" *ngIf="selectedAffectedServiceUIEntity$ | async as activeService">
  <div class="transit-panel-header">
    <span class="transit-route-view-title" (click)="clickBack.emit(0)">
      <i class="fas fa-chevron-left"></i>
      {{ 'transit.' + activeService.type | translate }} #{{ activeService.displayId }}
    </span>
  </div>
  <div class="affected-service-route-content">
    <div class="affected-service-route-content__avg-delay-badge">
      <i class="fas fa-exclamation-circle"></i>
      {{ 'transit.clearanceTime' | translate }}:
      {{ activeService.parsedDuration }}
      {{ 'timeUnits.min' | translate }}
    </div>
    <mat-expansion-panel
      [expanded]="true"
      class="affected-service-mat-expansion affected-service-mat-expansion--route-view">
      <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
        <mat-panel-title>
          <div class="affected-route-header-title">
            {{ 'transit.eventAffectingThisRoute' | translate }} ({{ activeService.affectingEvents?.length }})
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <wc-affected-service-cause [affectingEvents]="activeService.affectingEvents || []"></wc-affected-service-cause>
    </mat-expansion-panel>
  </div>
</div>
