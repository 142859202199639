import { MapLayer, ShapeType } from '@wc/wc-map-viewer/src/types';
import { EntityState, LayerNameStyleConfigKeys } from '@wc/wc-models/src';
import { LayersZIndexConfig } from './layers-z-index.config';

export const miscellaneousLayersConfig: MapLayer<LayerNameStyleConfigKeys, EntityState> = {
  segment_line: {
    zIndex: LayersZIndexConfig.segment_line,
    lineStringStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', width: 8, color: '#FFFFFF' },
          { shape: 'lineSolid', width: 4, color: { sourceField: 'color' } },
        ],
        highlight: [
          { shape: 'lineSolid', width: 14, color: '#45C5F3', opacity: 0.6 },
          { shape: 'lineSolid', width: 8, color: '#FFFFFF' },
          { shape: 'lineSolid', width: 4, color: { sourceField: 'color' } },
        ],
        hover: [
          { shape: 'lineSolid', width: 14, color: '#45C5F3', opacity: 0.6 },
          { shape: 'lineSolid', width: 8, color: '#FFFFFF' },
          { shape: 'lineSolid', width: 4, color: { sourceField: 'color' } },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'regularShape',
            shapeType: ShapeType.triangle,
            color: { sourceField: 'color' },
            stroke: { color: '#FFFFFF', width: 2 },
            zIndex: LayersZIndexConfig.segment_line,
          },
        ],
        highlight: [
          {
            shape: 'regularShape',
            shapeType: ShapeType.triangle,
            color: { sourceField: 'color' },
            zIndex: LayersZIndexConfig.segment_line,
            stroke: { color: '#FFFFFF', width: 2 },
          },
        ],
        hover: [
          {
            shape: 'regularShape',
            shapeType: ShapeType.triangle,
            color: { sourceField: 'color' },
            stroke: { color: '#FFFFFF', width: 2 },
            zIndex: LayersZIndexConfig.segment_line,
          },
        ],
      },
    },
  },
  workspace: {
    zIndex: LayersZIndexConfig.workspace,
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#D2A890', darkColor: '#5f9ea0', width: 3 },
          {
            shape: 'lineSolid',
            color: '#F3DBCE',
            darkColor: '#387ab7',
            width: 1,
            label: {
              text: { sourceField: 'title' },
              placement: 'line',
              color: '#9A684C',
              darkColor: '#387ab7',
              outlineColor: '#FDF1EA',
              darkOutlineColor: '#fff',
              size: 16,
              maxAngle: 0.7853981633974483,
              textAlign: 'center',
            },
          },
        ],
      },
      inactive: { default: [] },
    },
  },
  'CAMERA-camera': {
    zIndex: LayersZIndexConfig['CAMERA-camera'],
    clusterDistance: 40,
    pointStatusStyle: {
      default: {
        default: [
          { shape: 'marker', icon: { iconName: 'cameras_active' } },
          {
            shape: 'marker',
            isStyleFn: true,
            icon: { iconName: 'counter', zIndex: 2, anchor: [0, 2.3] },
          },
          {
            shape: 'lineSolid',
            color: '#ffff',
            zIndex: 3,
            width: 1,
            label: {
              offsetX: 11,
              offsetY: -36,
              outlineColor: '#707EA6',
              darkOutlineColor: '#707EA6',
              text: 'length',
              placement: 'point',
              color: '#ffff',
              darkColor: '#ffff',
              size: 10,
              textAlign: 'center',
            },
          },
        ],

        hover: [
          {
            shape: 'marker',
            isStyleFn: true,
            icon: { iconName: 'select_highlight_circle', zIndex: -1, anchor: [0.1, 1.9] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'cameras_active.selected', zIndex: 1 },
          },

          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: 'counter', zIndex: 2, anchor: [0, 2.3] },
          },

          {
            shape: 'lineSolid',
            color: '#ffff',
            width: 1,
            zIndex: 3,
            label: {
              offsetX: 11,
              offsetY: -36,
              outlineColor: '#707EA6',
              darkOutlineColor: '#707EA6',
              text: 'length',
              placement: 'point',
              color: '#ffff',
              darkColor: '#ffff',
              size: 10,
              textAlign: 'center',
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'select_highlight_circle', zIndex: -1, anchor: [0.1, 1.9] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'cameras_active.selected', zIndex: 1 },
          },

          {
            shape: 'marker',
            icon: { iconName: 'counter', zIndex: 2, anchor: [0, 2.3] },
          },

          {
            shape: 'lineSolid',
            color: '#ffff',
            width: 1,
            zIndex: 3,
            label: {
              offsetX: 11,
              offsetY: -36,
              outlineColor: '#707EA6',
              darkOutlineColor: '#707EA6',
              text: 'length',
              placement: 'point',
              color: '#ffff',
              darkColor: '#ffff',
              size: 10,
              textAlign: 'center',
            },
          },
        ],
      },
      inactive: {
        default: [
          { shape: 'marker', icon: { iconName: 'cameras_inactive' } },
          {
            shape: 'marker',
            isStyleFn: true,
            icon: { iconName: 'inactive_counter', zIndex: 2, anchor: [0, 2.3] },
          },
          {
            shape: 'lineSolid',
            color: '#CCCCCC',
            zIndex: 3,
            width: 1,
            label: {
              offsetX: 11,
              offsetY: -36,
              outlineColor: '#707EA6',
              darkOutlineColor: '#707EA6',
              text: 'length',
              placement: 'point',
              color: '#ffff',
              darkColor: '#ffff',
              size: 10,
              textAlign: 'center',
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            isStyleFn: true,
            icon: { iconName: 'select_highlight_circle', zIndex: -1, anchor: [0.1, 1.9] },
          },
          { shape: 'marker', icon: { iconName: 'cameras_inactive.hover', zIndex: 1 } },
          {
            shape: 'marker',
            isStyleFn: true,
            icon: { iconName: 'inactive_counter', zIndex: 2, anchor: [0, 2.3] },
          },
          {
            shape: 'lineSolid',
            color: '#CCCCCC',
            zIndex: 3,
            width: 1,
            label: {
              offsetX: 11,
              offsetY: -36,
              outlineColor: '#707EA6',
              darkOutlineColor: '#707EA6',
              text: 'length',
              placement: 'point',
              color: '#ffff',
              darkColor: '#ffff',
              size: 10,
              textAlign: 'center',
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            isStyleFn: true,
            icon: { iconName: 'select_highlight_circle', zIndex: -1, anchor: [0.1, 1.9] },
          },
          {
            shape: 'marker',
            icon: { iconName: 'cameras_inactive.selected' },
          },
          {
            shape: 'marker',
            isStyleFn: true,
            icon: { iconName: 'inactive_counter', zIndex: 2, anchor: [0, 2.3] },
          },
          {
            shape: 'lineSolid',
            color: '#ccccc',
            zIndex: 3,
            width: 1,
            label: {
              offsetX: 11,
              offsetY: -36,
              outlineColor: '#707EA6',
              darkOutlineColor: '#707EA6',
              text: 'length',
              placement: 'point',
              color: '#ffff',
              darkColor: '#ffff',
              size: 10,
              textAlign: 'center',
            },
          },
        ],
      },
    },
  },
  'DMS-dms': {
    zIndex: LayersZIndexConfig['DMS-dms'],
    clusterDistance: 20,
    pointStatusStyle: {
      default: {
        default: [{ shape: 'marker', icon: { iconName: 'dms_inactive' } }],
        hover: [{ shape: 'marker', icon: { iconName: 'dms_inactive.hover' } }],
        selected: [{ shape: 'marker', icon: { iconName: 'dms_inactive.selected' } }],
      },
      active: {
        default: [
          { shape: 'marker', icon: { iconName: 'dms_active' } },
          {
            shape: 'marker',
            isStyleFn: true,
            icon: { iconName: 'counter', zIndex: 2, anchor: [0, 2.3] },
          },
          {
            shape: 'lineSolid',
            color: '#ffff',
            width: 1,
            zIndex: 3,
            label: {
              offsetX: 11,
              offsetY: -36,
              outlineColor: '#707EA6',
              darkOutlineColor: '#707EA6',
              text: 'length',
              placement: 'point',
              color: '#ffff',
              darkColor: '#ffff',
              size: 10,
              textAlign: 'center',
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            isStyleFn: true,
            icon: { iconName: 'select_highlight_circle', zIndex: -1, anchor: [0.1, 1.9] },
          },
          { shape: 'marker', icon: { iconName: 'dms_active.hover' } },
          {
            shape: 'marker',
            isStyleFn: true,
            icon: { iconName: 'counter', zIndex: 2, anchor: [0, 2.3] },
          },
          {
            shape: 'lineSolid',
            color: '#ffff',
            width: 1,
            zIndex: 3,
            label: {
              offsetX: 11,
              offsetY: -36,
              outlineColor: '#707EA6',
              darkOutlineColor: '#707EA6',
              text: 'length',
              placement: 'point',
              color: '#ffff',
              darkColor: '#ffff',
              size: 10,
              textAlign: 'center',
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            isStyleFn: true,
            icon: { iconName: 'select_highlight_circle', zIndex: -1, anchor: [0.1, 1.9] },
          },
          { shape: 'marker', icon: { iconName: 'dms_active.selected' } },
          {
            shape: 'marker',
            isStyleFn: true,
            icon: { iconName: 'counter', zIndex: 2, anchor: [0, 2.3] },
          },
          {
            shape: 'lineSolid',
            color: '#ffff',
            width: 1,
            zIndex: 3,
            label: {
              offsetX: 11,
              offsetY: -36,
              outlineColor: '#707EA6',
              darkOutlineColor: '#707EA6',
              text: 'length',
              placement: 'point',
              color: '#ffff',
              darkColor: '#ffff',
              size: 10,
              textAlign: 'center',
            },
          },
        ],
      },
    },
  },
  interactive: {
    zIndex: LayersZIndexConfig.interactive,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            zIndex: 999999,
            icon: {
              src: 'assets/icons/map-cursor-icon.svg',
              scale: 1,
              anchor: [0.5, 0.5],
            },
          },
          {
            shape: 'circleIcon',
            color: '#ffffff',
            radius: 20,
            opacity: 0.01,
            stroke: { color: '#00000000', width: 0 },
          },
        ],
      },
      confirmed: {
        default: [
          // { shape: 'circleIcon', color: "#359edc", radius: 20, opacity: 0.5 },
          {
            shape: 'marker',
            zIndex: 999999,
            icon: {
              src: 'assets/icons/map-cursor-icon.svg',
              scale: 1,
              anchor: [0.5, 0.5],
            },
          },
        ],
      },
    },
  },
  draw: {
    zIndex: LayersZIndexConfig.draw,
    pointStatusStyle: {
      default: {
        default: [
          // { shape: 'circleIcon', color: "#359edc", radius: 20, opacity: 0.5, stroke: { color: "#359edc", width: 2 } },
          {
            shape: 'marker',
            icon: {
              src: 'assets/icons/map-cursor-icon.svg',
              scale: 1,
              anchor: [0.5, 0.5],
            },
          },
        ],
      },
      // modify_point: [
      //   { shape: 'circleIcon', color: "#359edc", radius: 20, opacity: 0.5 },
      //   { shape: 'marker', icon: {src: 'assets/icons/addonmap.svg', scale: 1}},
      // ],
      modify_geometry: {
        default: [
          // { shape: 'lineSolid', color: "#359edc", width: 3 },
          // { shape: 'circleIcon', color: "#359edc", radius: 5, stroke: { color: "#fff", width: 2 }, zIndex: 10 },
          {
            shape: 'marker',
            icon: {
              src: 'assets/icons/map-cursor-icon.svg',
              scale: 1,
              anchor: [0.5, 0.5],
            },
          },
        ],
      },
      modify_pointer: {
        default: [
          // { shape: 'lineSolid', color: "#359edc", width: 3 },
          // { shape: 'circleIcon', color: "#359edc", radius: 5, stroke: { color: "#fff", width: 2 }, zIndex: 10 },
          {
            shape: 'marker',
            zIndex: 999999,
            icon: {
              src: 'assets/icons/map-cursor-icon.svg',
              scale: 1,
              anchor: [0.5, 0.5],
            },
          },
        ],
      },

      // modify_geometry_pointer: [
      //   { shape: 'circleIcon', color: "#359edc", radius: 20, opacity: 0.5 },
      //   { shape: 'marker', icon: {src: 'assets/icons/addonmap.svg', scale: 1}},
      // ],
    },
    multiLineStringStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#359edc', width: 3 },
          {
            shape: 'circleIcon',
            color: '#fff',
            radius: 6,
            stroke: { color: '#359edc', width: 2 },
          },
        ],
      },
      modify_geometry: {
        default: [
          { shape: 'lineSolid', color: '#359edc', width: 3 },
          {
            shape: 'circleIcon',
            color: '#fff',
            radius: 6,
            stroke: { color: '#359edc', width: 2 },
          },
        ],
      },
      view_geometry: {
        default: [
          { shape: 'area', color: '#359edc', opacity: 0.25 },
          { shape: 'lineSolid', color: '#359edc', width: 3 },
        ],
      },
      modify_geometry_pointer: {
        default: [
          { shape: 'lineSolid', color: '#359edc', width: 3 },
          {
            shape: 'circleIcon',
            color: '#fff',
            radius: 6,
            stroke: { color: '#359edc', width: 2 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#359edc', width: 3 },
          {
            shape: 'circleIcon',
            color: '#fff',
            radius: 6,
            stroke: { color: '#359edc', width: 2 },
          },
        ],
      },
      modify_geometry: {
        default: [
          { shape: 'lineSolid', color: '#359edc', width: 3 },
          {
            shape: 'circleIcon',
            color: '#fff',
            radius: 6,
            stroke: { color: '#359edc', width: 2 },
          },
        ],
      },
      view_geometry: {
        default: [
          { shape: 'area', color: '#359edc', opacity: 0.25 },
          { shape: 'lineSolid', color: '#359edc', width: 3 },
        ],
      },
      modify_geometry_pointer: {
        default: [
          { shape: 'lineSolid', color: '#359edc', width: 3 },
          {
            shape: 'circleIcon',
            color: '#fff',
            radius: 6,
            stroke: { color: '#359edc', width: 2 },
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'area', color: '#359edc', opacity: 0.25 },
          { shape: 'lineSolid', color: '#359edc', width: 3 },
          {
            shape: 'circleIcon',
            color: '#fff',
            radius: 6,
            stroke: { color: '#359edc', width: 2 },
          },
        ],
      },
      modify_geometry: {
        default: [
          { shape: 'area', color: '#359edc', opacity: 0.25 },
          { shape: 'lineSolid', color: '#359edc', width: 3 },
          {
            shape: 'circleIcon',
            color: '#fff',
            radius: 6,
            stroke: { color: '#359edc', width: 2 },
          },
        ],
      },
      view_geometry: {
        default: [
          { shape: 'area', color: '#359edc', opacity: 0.25 },
          { shape: 'lineSolid', color: '#359edc', width: 4 },
        ],
      },
      modify_geometry_pointer: {
        default: [
          { shape: 'area', color: '#359edc', opacity: 0.25 },
          { shape: 'lineSolid', color: '#359edc', width: 3 },
          {
            shape: 'circleIcon',
            color: '#fff',
            radius: 6,
            stroke: { color: '#359edc', width: 2 },
          },
        ],
      },
    },
  },
  'IRREGULAR_CONGESTION-irregular_congestion': {
    zIndex: LayersZIndexConfig['IRREGULAR_CONGESTION-irregular_congestion'],
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'irregular_congestion_start',
              anchor: [0.5, 0.9],
              zIndex: 15,
            },
            zIndex: 15,
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'irregular_congestion_start.hover',
              anchor: [0.5, 0.9],
              zIndex: 15,
            },
            zIndex: 15,
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'irregular_congestion_start.selected',
              anchor: [0.5, 0.9],
              zIndex: 15,
            },
            zIndex: 15,
          },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'irregular_congestion_end',
              anchor: [0.5, 0.5],
              zIndex: 15,
            },
            zIndex: 10,
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'irregular_congestion_end.hover',
              anchor: [0.5, 0.5],
              zIndex: 15,
            },
            zIndex: 10,
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'irregular_congestion_end.selected',
              anchor: [0.5, 0.5],
              zIndex: 15,
            },
            zIndex: 10,
          },
        ],
      },
    },
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'irregular_congestion_start' },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'irregular_congestion_start.hover' },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'irregular_congestion_start.selected' },
          },
        ],
      },
    },
    multiLineStringStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#FFFFFF', width: 6 },
          // { shape: 'lineSolid', color: "#590BFF", width: 3},
          { shape: 'lineDash', color: '#590BFF', width: 4 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 },
          { shape: 'lineSolid', color: '#FFFFFF', width: 6 },
          // { shape: 'lineSolid', color: "#590BFF", width: 3},
          { shape: 'lineDash', color: '#590BFF', width: 4 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 },
          { shape: 'lineSolid', color: '#FFFFFF', width: 6 },
          // { shape: 'lineSolid', color: "#590BFF", width: 3},
          { shape: 'lineDash', color: '#590BFF', width: 4 },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#2C008A', width: 4 }],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 },
          { shape: 'lineSolid', color: '#2C008A', width: 4 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 },
          { shape: 'lineSolid', color: '#2C008A', width: 4 },
        ],
      },
    },
  },
  'ROADWAY_STATUS-roadway_status': {
    zIndex: LayersZIndexConfig['ROADWAY_STATUS-roadway_status'],
    hitTolerance: 5,
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'dark_circle', anchor: [0.5, 0.55], zIndex: 15 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'dark_circle_hover',
              anchor: [0.5, 0.55],
              zIndex: 15,
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'dark_circle',
              anchor: [0.5, 0.55],
              zIndex: 20,
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'dark_circle_hover',
              anchor: [0.5, 0.55],
              zIndex: 15,
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'dark_circle',
              anchor: [0.5, 0.55],
              zIndex: 20,
            },
          },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'dark_circle', anchor: [0.5, 0.55], zIndex: 15 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'dark_circle_hover',
              anchor: [0.5, 0.55],
              zIndex: 15,
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'dark_circle',
              anchor: [0.5, 0.55],
              zIndex: 20,
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'dark_circle_hover',
              anchor: [0.5, 0.55],
              zIndex: 15,
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: 'dark_circle',
              anchor: [0.5, 0.55],
              zIndex: 20,
            },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [
          { shape: 'lineDash', color: '#fff', width: 2 },
          { shape: 'lineSolid', color: '#0F1832', darkColor: '#a74edd', width: 5 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 30, width: 11 },
          { shape: 'lineSolid', color: '#0F1832', darkColor: '#a74edd', width: 5, zIndex: 16 },
          { shape: 'lineDash', color: '#fff', width: 2, zIndex: 16 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#3090F8', opacity: 0.5, width: 11 },
          { shape: 'lineSolid', color: '#0F1832', darkColor: '#a74edd', width: 5, zIndex: 16 },
          { shape: 'lineDash', color: '#fff', width: 2, zIndex: 16 },
        ],
      },
      active: {
        default: [
          { shape: 'lineDash', color: '#fff', width: 4 },
          { shape: 'lineSolid', color: '#0F1832', darkColor: '#a74edd', width: 6 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 30, width: 11 },
          { shape: 'lineSolid', color: '#0F1832', darkColor: '#a74edd', width: 5, zIndex: 16 },
          { shape: 'lineDash', color: '#fff', width: 2, zIndex: 16 },
        ],
      },
    },
  },
  'CONSTRUCTION-construction': {
    zIndex: LayersZIndexConfig['CONSTRUCTION-construction'],
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'construction_path', anchor: [0.52, 0.6], zIndex: 15 },
            zIndex: 15,
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [0, 1.3], zIndex: 16 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'construction_path.hover', anchor: [0.5, 0.6], zIndex: 15 },
            zIndex: 15,
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [0, 1.3], zIndex: 16 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: { iconName: 'construction_path.selected', anchor: [0.5, 0.6], zIndex: 15 },
            zIndex: 15,
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [0, 1.3], zIndex: 16 },
          },
        ],
      },
    },
    pointStatusStyle: {
      default: {
        default: [
          { shape: 'marker', icon: { iconName: 'construction', zIndex: 15 } },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.3, 4.3], zIndex: 16 },
          },
        ],
        hover: [
          { shape: 'marker', icon: { iconName: 'construction.hover', zIndex: 15 } },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.3, 4.3], zIndex: 16 },
          },
        ],
        selected: [
          { shape: 'marker', icon: { iconName: 'construction.selected', zIndex: 15 } },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.3, 4.3], zIndex: 16 },
          },
        ],
      },

      completed: {
        default: [{ shape: 'marker', icon: { iconName: 'construction.completed', zIndex: 15 } }],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#fff', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#a74edd', width: 4 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 8 },
          { shape: 'lineSolid', color: '#fff', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#a74edd', width: 4 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 8 },
          { shape: 'lineSolid', color: '#fff', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#a74edd', width: 4 },
        ],
      },
      active: {
        default: [
          { shape: 'lineSolid', color: '#fff', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#a74edd', width: 4 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 8 },
          { shape: 'lineSolid', color: '#fff', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#a74edd', width: 4 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 8 },
          { shape: 'lineSolid', color: '#fff', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#a74edd', width: 4 },
        ],
      },
    },
    multiLineStringStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 10 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 10 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.0001, zIndex: 1 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 8 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.15, zIndex: 1 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 8 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.15, zIndex: 1 },
        ],
      },
      active: {
        default: [
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.0001, zIndex: 1 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 8 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.15, zIndex: 1 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 8 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.15, zIndex: 1 },
        ],
      },
    },
  },
  'CONGESTION-congestion': {
    zIndex: LayersZIndexConfig['CONGESTION-congestion'],
    multiLineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#2C008A', width: 4 }],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 },
          { shape: 'lineSolid', color: '#2C008A', width: 4 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 },
          { shape: 'lineSolid', color: '#2C008A', width: 4 },
        ],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'regular_congestion_start',
              anchor: [0.5, 0.9],
              zIndex: 15,
            },
            zIndex: 15,
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'regular_congestion_start.hover',
              anchor: [0.5, 0.9],
              zIndex: 15,
            },
            zIndex: 15,
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'regular_congestion_start.selected',
              anchor: [0.5, 0.9],
              zIndex: 15,
            },
            zIndex: 15,
          },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'regular_congestion_end',
              anchor: [0.5, 0.5],
              zIndex: 10,
            },
            zIndex: 10,
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'regular_congestion_end.hover',
              anchor: [0.5, 0.5],
              zIndex: 10,
            },
            zIndex: 10,
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'regular_congestion_end.selected',
              anchor: [0.5, 0.5],
              zIndex: 10,
            },
            zIndex: 10,
          },
        ],
      },
    },
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: 'regular_congestion_start',
              anchor: [0.5, 1],
            },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'regular_congestion_start.hover',
              anchor: [0.5, 1],
            },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'regular_congestion_start.selected',
              anchor: [0.5, 1],
            },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#2C008A', width: 4 }],
        hover: [{ shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 }],
      },
      active: {
        default: [{ shape: 'lineSolid', color: '#2C008A', width: 4 }],
      },
    },
  },
  'SPECIAL_EVENT-special_event': {
    zIndex: LayersZIndexConfig['SPECIAL_EVENT-special_event'],
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'event_path', anchor: [0.5, 0.6], zIndex: 15 },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [0, 1.3], zIndex: 16 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'event_path.hover',
              anchor: [0.5, 0.6],
              zIndex: 15,
            },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [0, 1.3], zIndex: 16 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'event_path.selected',
              anchor: [0.5, 0.6],
              zIndex: 15,
            },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [0, 1.3], zIndex: 16 },
          },
        ],
      },
    },
    pointStatusStyle: {
      default: {
        default: [
          { shape: 'marker', icon: { iconName: 'events', zIndex: 15 } },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.3, 4.3], zIndex: 16 },
          },
        ],
        hover: [
          { shape: 'marker', icon: { iconName: 'events.hover', zIndex: 15 } },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.3, 4.3], zIndex: 16 },
          },
        ],
        selected: [
          { shape: 'marker', icon: { iconName: 'events.selected', zIndex: 15 } },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.3, 4.3], zIndex: 16 },
          },
        ],
      },
      completed: {
        default: [{ shape: 'marker', icon: { iconName: 'events.completed', zIndex: 15 } }],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#fff', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#a74edd', width: 4 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 10 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 10 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
        ],
      },
      active: {
        default: [
          { shape: 'lineSolid', color: '#fff', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#a74edd', width: 4 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 10 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 10 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
        ],
      },
    },
    multiLineStringStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 10 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 10 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.0001, zIndex: 1 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.75, width: 10 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.15, zIndex: 1 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 10 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.15, zIndex: 1 },
        ],
      },
      active: {
        default: [
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.0001, zIndex: 1 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.75, width: 10 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.15, zIndex: 1 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 10 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.15, zIndex: 1 },
        ],
      },
    },
  },
  'ROAD_CLOSURE-road_closure': {
    zIndex: LayersZIndexConfig['ROAD_CLOSURE-road_closure'],
    startPointStatusStyle: {
      default: {
        default: [
          { shape: 'marker', icon: { iconName: 'road_closure_path', anchor: [0.5, 0.6], zIndex: 15 } },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [0, 1.3], zIndex: 16 },
          },
        ],
        hover: [
          { shape: 'marker', icon: { iconName: 'road_closure_path.hover', anchor: [0.5, 0.6], zIndex: 15 } },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [0, 1.3], zIndex: 16 },
          },
        ],
        selected: [
          { shape: 'marker', icon: { iconName: 'road_closure_path.selected', anchor: [0.5, 0.6], zIndex: 15 } },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [0, 1.3], zIndex: 16 },
          },
        ],
      },
    },
    pointStatusStyle: {
      default: {
        default: [
          { shape: 'marker', icon: { iconName: 'road_closure' } },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.3, 4.3], zIndex: 16 },
          },
        ],
        hover: [
          { shape: 'marker', icon: { iconName: 'road_closure.hover' } },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.3, 4.3], zIndex: 16 },
          },
        ],
        selected: [
          { shape: 'marker', icon: { iconName: 'road_closure.selected' } },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.3, 4.3], zIndex: 16 },
          },
        ],
      },

      completed: { default: [{ shape: 'marker', icon: { iconName: 'road_closure.completed' } }] },
    },
    lineStringStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#fff', width: 4 },
          { shape: 'lineDash', color: '#690106', width: 4 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 8 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 10 },
          { shape: 'lineSolid', color: '#fff', width: 4 },
          { shape: 'lineDash', color: '#690106', width: 4 },
        ],
      },
      active: {
        default: [
          { shape: 'lineSolid', color: '#fff', width: 4 },
          { shape: 'lineDash', color: '#690106', width: 4 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 8 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 10 },
          { shape: 'lineSolid', color: '#fff', width: 4 },
          { shape: 'lineDash', color: '#690106', width: 4 },
        ],
      },
    },
    multiLineStringStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 8 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 8 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.0001 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 8 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.15 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 8 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.15 },
        ],
      },
      active: {
        default: [
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.0001, zIndex: 1 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.7, width: 8 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.15 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#00A1DA', opacity: 0.5, width: 8 },
          { shape: 'lineSolid', color: '#fff', darkColor: '#e7e8e8', width: 4 },
          { shape: 'lineDash', color: '#690106', darkColor: '#b9484e', width: 4 },
          { shape: 'area', color: '#359edc', opacity: 0.15 },
        ],
      },
    },
  },
  'CRASH_RISK_AREA-crash_risk_area': {
    zIndex: LayersZIndexConfig['CRASH_RISK_AREA-crash_risk_area'],
    multiLineStringStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#000000', width: 3 },
          { shape: 'lineDash', color: '#F78A04', width: 2 },
        ],
        hover: [
          { shape: 'lineSolid', color: '#096DD9', width: 8, opacity: 0.5 },
          { shape: 'lineSolid', color: '#000000', width: 3 },
          { shape: 'lineDash', color: '#F78A04', width: 2 },
        ],
        selected: [
          { shape: 'lineSolid', color: '#096DD9', width: 8, opacity: 0.5 },
          { shape: 'lineSolid', color: '#000000', width: 3 },
          { shape: 'lineDash', color: '#F78A04', width: 2 },
        ],
      },
    },

    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'crash_risk_start', anchor: [0.5, 0.9], zIndex: 15 },
            zIndex: 15,
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.5, 3.1], zIndex: 16 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'crash_risk_start.hover',
              anchor: [0.5, 0.9],
              zIndex: 15,
            },
            zIndex: 15,
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.5, 3.1], zIndex: 16 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'crash_risk_start.selected',
              anchor: [0.5, 0.9],
              zIndex: 15,
            },
            zIndex: 15,
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.5, 3.1], zIndex: 16 },
          },
        ],
      },
      active: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'crash_risk_start', anchor: [0.5, 0.9], zIndex: 16 },
            zIndex: 16,
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.5, 3.1], zIndex: 17 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: {
              iconName: 'crash_risk_start.hover',
              anchor: [0.5, 0.9],
              zIndex: 16,
            },
            zIndex: 17,
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.5, 3.1], zIndex: 17 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'crash_risk_start.selected',
              anchor: [0.5, 0.9],
              zIndex: 16,
            },
            zIndex: 16,
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.5, 3.1], zIndex: 17 },
          },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'crash_risk_end', anchor: [0.5, 0.5], zIndex: 10 },
            zIndex: 10,
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'crash_risk_end.hover', anchor: [0.5, 0.5], zIndex: 10 },
            zIndex: 10,
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'crash_risk_end.selected',
              anchor: [0.5, 0.5],
              zIndex: 10,
            },
            zIndex: 10,
          },
        ],
      },
    },
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'crash_risk_start', anchor: [0.5, 1] },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.5, 3.1], zIndex: 16 },
          },
        ],
        hover: [
          {
            shape: 'marker',
            icon: { iconName: 'crash_risk_start.hover', anchor: [0.5, 1] },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.5, 3.1], zIndex: 16 },
          },
        ],
        selected: [
          {
            shape: 'marker',
            icon: {
              iconName: 'crash_risk_start.selected',
              anchor: [0.5, 1],
            },
          },
          {
            isStyleFn: true,
            shape: 'marker',
            icon: { iconName: { sourceField: 'status' }, anchor: [-0.5, 3.1], zIndex: 16 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#2C008A', width: 4 }],
        hover: [{ shape: 'lineSolid', color: '#00A1DA', width: 11, opacity: 0.5 }],
      },
      active: {
        default: [{ shape: 'lineSolid', color: '#2C008A', width: 4 }],
      },
    },
  },
};
