import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  AuthUserService,
  CheckVersionService,
  DatadogLoggerService,
  ElectronService,
  HeapAnalyticsService,
  IncidentsApiService,
  IncidentsService,
  InterceptorElectronAuthService,
  InterceptorService,
  LiveMapService,
  MediaApiService,
  SplitIOService,
  UsersApiService,
  UsersService,
} from '@wc-core';
import {
  Account,
  AppConfigService,
  CoreModule,
  DateFormat,
  IncidentConfigInitService,
  IncidentService,
  IncidentStore,
  IsTabletMode as OldIsTabletMode,
  LiveMapStore,
  SplitIOService as OldSplitIOService,
  Store,
  TrafficDisruptionStore,
  UploadStore,
  UsersStore,
} from '@wc/core';
import { environment } from '@wc/core/environments/environment';
import { CheckVersionService as CheckVersionServiceOld } from '@wc/core/services/check-version.service';
import { MediaService } from '@wc/core/services/media.service';
import { WebsocketService as WebsocketServiceOld } from '@wc/core/services/websocketService';
import { SpecialEventStore } from '@wc/core/stores/special-event.store';
import { AlertsService as AlertsServiceOld } from '@wc/features/ui/services/alerts.service';
import { PermissionsDomainModule } from '@wc/permissions/domain/src';
import { HeapClassDirectiveModule, PermissionUserDirectiveModule } from '@wc/wc-common/src';
import {
  APP_TYPE_TOKEN,
  Auth0Token,
  IsTabletMode,
  PlatformLanguageToken,
  PlatformWindowToken,
  PLATFORM_TOURS,
  PLATFORM_TOURS_TOKEN,
} from '@wc/wc-core/src/lib/injection-tokens';
import { AccountService } from '@wc/wc-core/src/lib/services/account.service';
import { TrafficDisruptionService } from '@wc/wc-core/src/lib/services/traffic-disruption.service';
import { UploadService } from '@wc/wc-core/src/lib/services/upload.service';
import { WebsocketService } from '@wc/wc-core/src/lib/services/websocketService';
import { WcMapViewerModule } from '@wc/wc-map-viewer/src';
import { LAYER_PANEL_STORE_ITEMS, TREE_PANEL_UI_REPRESENTATION } from '@wc/wc-map-viewer/src/injection-tokens';
import { ToastrPositionClass } from '@wc/wc-models/src';
import {
  ButtonToggleGroupModule,
  LiveMapViewerModule,
  OnboardingToursModule,
  StaticMapViewerModule,
  SupportModule,
  ToastrAlertsService,
  WcButtonModule,
} from '@wc/wc-ui/src';
import {
  liveMapLayerStoreItems,
  liveMapTreePanelUIRepresentation,
} from '@wc/wc-ui/src/components/map-viewers/live-map-viewer/live-map-viewer.config';
import { MobxAngularModule } from 'mobx-angular';
import moment from 'moment';
import { NgxElectronModule } from 'ngx-electron';
import { ToastrModule } from 'ngx-toastr';
import { filter, first, tap } from 'rxjs/operators';
import { TranslateLoaderService } from '../../../../libs/core/services/translate-loader.service';
import { UsersService as UsersServiceDeprecated } from '../../../../libs/core/services/users.service';
import { AccountStore } from '../../../../libs/core/stores/account.store';
import { momentLanguageConfig, momentLocaleSpecificationConfig } from '../../desktop-configs/localization-config';
import { MaterialModule } from '../material-module';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { FullComponent } from './layouts/full/full.component';
import { HeaderComponent } from './layouts/full/header/header.component';
import { DesktopUiStateService } from './shared/desktop-ui-state.service';
import { SharedModule } from './shared/shared.module';

function setMomentLocaleConfiguration() {
  return tap<Account>(({ regionalSetting }) => {
    if (regionalSetting.language === 'HE') {
      moment.updateLocale(momentLanguageConfig.hebrew, momentLocaleSpecificationConfig[momentLanguageConfig.hebrew]);
    } else {
      const enLocaleLang =
        regionalSetting.dateFormat === DateFormat.MmmDdYyyy
          ? momentLanguageConfig.english
          : momentLanguageConfig.englishGB;
      moment.updateLocale(enLocaleLang, momentLocaleSpecificationConfig[enLocaleLang]);
    }
  });
}

function setTranslationUsage(translateService: TranslateService) {
  return tap<Account>(({ regionalSetting }) => translateService?.use(regionalSetting.language.toLowerCase()));
}

function initializeLocalizationSettings(
  desktopUiState: DesktopUiStateService,
  accountService: AccountService,
  translateService: TranslateService
) {
  return () =>
    accountService.account$.pipe(
      first((account): account is Account => !!account?.regionalSetting),
      setMomentLocaleConfiguration(),
      setTranslationUsage(translateService),
      tap(({ regionalSetting }) => {
        if (regionalSetting.language === 'HE') {
          desktopUiState.updateIsHebrew(true);
        }
      })
    );
}

export function userTranslate() {
  const injector = Injector.create({ providers: [{ provide: TranslateService, useExisting: TranslateService }] });

  return injector.get(TranslateService).onLangChange;
}

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
};

export function winFactory() {
  return window;
}

export function platformLangFactory() {
  const browserLang = window.navigator.language || 'en'; // fallback English
  // browser language has 2 codes, ex: 'en-US'
  return browserLang.split('-')[0];
}

export function auth0config() {
  return environment.auth0;
}

export function isTabletMode(): boolean {
  return false;
}
@NgModule({
  declarations: [AppComponent, FullComponent, HeaderComponent],
  imports: [
    OnboardingToursModule,
    LiveMapViewerModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule,
    MobxAngularModule,
    FlexLayoutModule,
    SharedModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes),
    CoreModule.forRoot([
      { provide: PlatformLanguageToken, useFactory: platformLangFactory },
      { provide: PlatformWindowToken, useFactory: winFactory },
      { provide: Auth0Token, useFactory: auth0config },
      { provide: IsTabletMode, useFactory: isTabletMode },
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLoaderService,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({ positionClass: ToastrPositionClass.DesktopTopCenter, closeButton: true }),
    WcMapViewerModule.forRoot({
      isTabletMode: false,
      mapboxAccountNameAndStyleId: environment.mapboxAccountNameAndStyleId,
      staticLayersPerVendor: environment.staticLayersPerVendor,
    }),
    StaticMapViewerModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    NgxElectronModule,
    WcButtonModule,
    ButtonToggleGroupModule,
    HeapClassDirectiveModule,
    SupportModule,
    PermissionUserDirectiveModule,
    PermissionsDomainModule,
  ],
  providers: [
    Store,
    ElectronService,
    {
      provide: LiveMapStore,
      useExisting: LiveMapService,
    },
    {
      provide: OldSplitIOService,
      useExisting: SplitIOService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfig: AppConfigService, incidentConfigService: IncidentConfigInitService) => () =>
        appConfig.load().then(() => incidentConfigService.load()),
      deps: [AppConfigService, IncidentConfigInitService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (splitIoService: OldSplitIOService) => () =>
        new Promise(res => {
          splitIoService.featureToggles.pipe(filter(ft => Object.keys(ft).length !== 0)).subscribe(() => res(true));
        }),
      deps: [OldSplitIOService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authUserService: AuthUserService, datadogLoggerService: DatadogLoggerService) => () =>
        authUserService.getAuthUser().pipe(
          tap(user => {
            if (user?.id) datadogLoggerService.init(user);
          })
        ),
      deps: [AuthUserService, DatadogLoggerService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLocalizationSettings,
      multi: true,
      deps: [DesktopUiStateService, AccountService, TranslateService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: new ElectronService().isElectronApp ? InterceptorElectronAuthService : InterceptorService,
      multi: true,
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    {
      provide: LiveMapStore,
      useExisting: LiveMapService,
    },

    {
      provide: IncidentStore,
      useExisting: IncidentsService,
    },
    {
      provide: UsersStore,
      useExisting: UsersService,
    },
    {
      provide: UsersServiceDeprecated,
      useExisting: UsersApiService,
    },
    {
      provide: IncidentService,
      useExisting: IncidentsApiService,
    },
    {
      provide: PLATFORM_TOURS_TOKEN,
      useValue: PLATFORM_TOURS,
    },
    {
      provide: APP_TYPE_TOKEN,
      useValue: 'desktop',
    },
    {
      provide: TREE_PANEL_UI_REPRESENTATION,
      useValue: liveMapTreePanelUIRepresentation,
    },
    {
      provide: LAYER_PANEL_STORE_ITEMS,
      useValue: liveMapLayerStoreItems,
    },
    {
      provide: AccountStore,
      useExisting: AccountService,
    },
    {
      provide: SpecialEventStore,
      useExisting: TrafficDisruptionService,
    },
    {
      provide: TrafficDisruptionStore,
      useExisting: TrafficDisruptionService,
    },
    {
      //TODO: Temp support for wc-ui components that use the old IsTabletMode (is deprecated) injection token. replace with APP_TYPE_TOKEN.
      provide: OldIsTabletMode,
      useExisting: IsTabletMode,
    },
    {
      provide: UploadStore,
      useExisting: UploadService,
    },
    {
      provide: MediaService,
      useExisting: MediaApiService,
    },
    {
      provide: HeapAnalyticsService,
    },
    {
      provide: CheckVersionServiceOld,
      useExisting: CheckVersionService,
    },
    {
      provide: WebsocketServiceOld,
      useExisting: WebsocketService,
    },
    { provide: AlertsServiceOld, useExisting: ToastrAlertsService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
