import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WcIconModule } from '../../components/wc-icon/wc-icon.module';
import { DragDropOrderedListComponent } from './drag-drop-ordered-list.component';

@NgModule({
  declarations: [DragDropOrderedListComponent],
  imports: [CommonModule, TranslateModule, DragDropModule, WcIconModule],
  exports: [DragDropOrderedListComponent],
})
export class WcDragDropOrderedListModule {}
