import { Injectable } from '@angular/core';
import { EntityActions, EntityUIQuery, QueryEntity } from '@datorama/akita';
import { LayerType } from '@wc/core/models/gql.models';
import {
  ModifiedEntities,
  RoadwayStatusStoreEntity,
} from '@wc/wc-models/src/lib/types/entities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoadwayStatusState, RoadwayStatusStore, RoadwayStatusUIState } from './roadway_status.store';

@Injectable({ providedIn: 'root' })
export class RoadwayStatusQuery extends QueryEntity<RoadwayStatusState> {
  private layerTypeName = LayerType.RoadwayStatus;
  ui!: EntityUIQuery<RoadwayStatusUIState>;

  constructor(roadwayStatusStore: RoadwayStatusStore) {
    super(roadwayStatusStore);
    this.createUIQuery();
  }

  get modifiedRoadwayStatusSegments$(): Observable<ModifiedEntities> {
    return this.selectEntityAction([EntityActions.Update, EntityActions.Add]).pipe(
      map(({ ids }) => ids.map(id => this.getEntity(id)).filter(entity => !!entity) as RoadwayStatusStoreEntity[]),
      map(roadwayStatusSegments => ({
        [this.layerTypeName]: roadwayStatusSegments,
      }))
    );
  }
}
