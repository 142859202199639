import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { FocusDirectiveModule } from '@wc/wc-common/src';
import { WcButtonModule } from '../wc-button/wc-button.module';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { CheckboxListControlComponent } from './checkbox-list-control.component';

@NgModule({
  declarations: [CheckboxListControlComponent],
  imports: [CommonModule, MatListModule, TranslateModule, FocusDirectiveModule, WcIconModule, WcButtonModule],
  exports: [CheckboxListControlComponent],
})
export class CheckboxListControlModule {}
