import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LayerType } from '@wc/core';
import { CongestionsStoreEntity } from '@wc/wc-models/src';
import * as moment from 'moment';

@Component({
  selector: 'wc-congestion-modal',
  templateUrl: './congestion-modal.component.html',
  styleUrls: ['./congestion-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CongestionModalComponent {
  @Input() congestion!: CongestionsStoreEntity;
  @Input() isHebrew!: boolean;
  @Input() isTabletMode!: boolean;
  get LayerTypes(): typeof LayerType {
    return LayerType;
  }
  moment = moment;
}
