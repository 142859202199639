import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from '../models';
import { UsersService } from '../services';
import { UsersStore } from '../stores';

@Injectable({
  providedIn: 'root',
})
export class AuthUserResolverService implements Resolve<User> {
  authUserLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private usersStore: UsersStore, private usersService: UsersService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> | Observable<never> {
    return this.usersService.getAuthUser().pipe(tap(user => this.usersStore.setAuthUser(user)));
  }
}
