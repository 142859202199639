import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'roundDuration',
})
export class RoundDurationPipe implements PipeTransform {
  transform(milliseconds: number): string {
    const FIVE_MINUTES = 1000 * 60 * 5;
    // Getting remainder & subtract from 5 minutes
    const remainder = milliseconds % FIVE_MINUTES;
    const subtract = FIVE_MINUTES - remainder;
    // Create total duration
    const duration = moment.duration(milliseconds);
    // Add subtraction to total duration to closest 5th'
    if (duration.minutes() % 5 !== 0) {
      duration.add(subtract, 'milliseconds');
    }
    // Add day as hours to total result
    const addedHours = duration.days() * 24;
    const hours = duration.hours() + addedHours;
    const minutes = duration.minutes().toString().length > 1 ? duration.minutes() : '0' + duration.minutes();
    return `${hours}:${minutes}`;
  }
}
