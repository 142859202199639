import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ElementRef } from '@angular/core';
import { DashCamView, Incident, IncidentView, TimeFormat } from '@wc/core';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

export function scrollElementIntoView(
  innerElementId: string,
  scrollableContainerId: string,
  position: ScrollLogicalPosition = 'center'
) {
  const parentElement = document.getElementById(scrollableContainerId);
  const element = document.getElementById(innerElementId);

  const parentRect = parentElement?.getBoundingClientRect();
  const elementRect = element?.getBoundingClientRect();

  if (elementRect && parentRect && parentElement && element) {
    if (elementRect.top < parentRect.top || elementRect.bottom > parentRect.bottom) {
      if (!navigator.userAgent.includes('Chrome')) {
        parentElement.scrollTo({
          top: parentElement.scrollTop + elementRect.top - parentRect.top,
          behavior: 'smooth',
        });
      } else {
        element.scrollIntoView({ block: position, behavior: 'smooth' });
      }
    }
  }
}

export const binarySearch = (array: number[], value: number, low = 0, high = array.length - 1) => {
  if (low > high) return low;

  const mid = Math.trunc((low + high) / 2);
  if (array[mid] === value) return mid;
  if (array[mid] > value) return binarySearch(array, value, low, mid - 1);
  return binarySearch(array, value, mid + 1, high);
};

export function scrollToElementInVirtualScroll<T extends { id: number }>(
  itemList$: Observable<T[]>,
  virtualScrollContainerRef: CdkVirtualScrollViewport | undefined,
  targetId: number
) {
  itemList$
    .pipe(
      first(values => !!values.length),
      map(re => re.findIndex(({ id }) => id === targetId))
    )
    .subscribe(idx => setTimeout(() => virtualScrollContainerRef?.scrollToIndex(idx, 'smooth')));
}

export function getIncidentDashCams(incident: Incident | IncidentView) {
  const dashCams: DashCamView[] = [];

  incident.associatedUnits?.forEach(unit => {
    const { dashCameras } = unit;

    if (dashCameras && dashCameras.length) {
      dashCams.push(
        ...dashCameras.map(dashCam => ({
          ...dashCam,
          unitDisplayId: unit.displayId,
          unitVehicleType: unit.type,
        }))
      );
    }
  });

  return dashCams;
}

export function objectDiff(obj, modifiedObj) {
  const objDiff = {};
  for (const key of Object.keys(obj)) {
    if (!isEqual(obj[key], modifiedObj[key]) && obj[key] != modifiedObj[key] && modifiedObj[key] !== undefined) {
      objDiff[key] = modifiedObj[key];
    }
  }
  return objDiff;
}

export function scrollToInvalidPanel(el: ElementRef | undefined, offset: number) {
  const nativeElement = el?.nativeElement as HTMLElement | undefined;
  if (nativeElement) {
    const invalidPanelElmRefs = nativeElement.getElementsByClassName('invalid-panel') as HTMLCollectionOf<HTMLElement>;

    if (invalidPanelElmRefs.length > 0) {
      const scrollElmOffsetTop = nativeElement.offsetTop;
      const offsetTop = invalidPanelElmRefs[0].offsetTop;
      nativeElement.scrollTo({
        top: offsetTop - scrollElmOffsetTop - offset,
        behavior: 'smooth',
      });

      setTimeout(() => {
        const invalidArray = Array.from(invalidPanelElmRefs);
        invalidArray.forEach(element => {
          if (!element.classList.contains('mat-expanded')) {
            (element.firstChild as HTMLElement).click();
          }
        });
      }, 200);
    }
  } else {
    console.warn('cannot scroll to invalid expansion panel, element not found');
  }
}

export function isFunction(value: any): value is (...args) => ReturnType<typeof value> {
  return typeof value === 'function';
}

export function formatDuration(minutes: number = 0) {
  const min = minutes % 60;
  const hours = Math.floor(minutes / 60);

  return (hours < 10 ? '0' + hours : hours) + ':' + (min < 10 ? '0' + min : min);
}

export function formatMinutesToStringTime(minutes: number | string, timeFormat: TimeFormat) {
  if (typeof minutes === 'string') return minutes;
  const hours = Math.floor(minutes / 60);
  const min = minutes % 60;
  const stringHours = String(timeFormat === TimeFormat.TwelveHours ? hours % 12 || 12 : hours).padStart(2, '0');
  const stringMinutes = String(min).padStart(2, '0');
  return `${stringHours}:${stringMinutes}`;
}
