import { ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlFieldComponent } from '../../base/base-control-field.component';
import * as moment from 'moment';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { UiStore } from '@wc/core';

@Component({
  selector: 'wc-duration',
  templateUrl: './duration.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DurationComponent),
      multi: true,
    },
    FormControl,
  ],
  styleUrls: ['./duration.component.scss'],
})
export class DurationComponent extends BaseControlFieldComponent implements OnInit {
  form: FormGroup = new FormGroup({
    hours: new FormControl(''),
    minutes: new FormControl(''),
  });

  durationFields = {
    hours: {
      // label: 'hh'
      // placeholder: '---'
    },
    minutes: {
      // label: 'mm'
      // placeholder: '---'
    },
  };

  get isTabletMode() {
    return this.uiStore.isTabletMode;
  }

  constructor(dateTimeAdapter: DateTimeAdapter<any>, private uiStore: UiStore) {
    super();
    dateTimeAdapter.setLocale('us-US');

    this.form.valueChanges.subscribe(value => {
      let duration;

      if (value.hours && value.minutes) {
        duration = value.hours + ':' + value.minutes;
      } else if (value.hours && !value.minutes) {
        duration = value.hours + ':00';
      } else if (!value.hours && value.minutes) {
        duration = '00:' + value.minutes;
      }

      this.writeValue(duration);
    });
  }

  ngOnInit(): void {
    if (this.formControl?.value) {
      const duration = this.formControl.value.split(':');
      this.form.get('hours')?.setValue(duration[0]);
      this.form.get('minutes')?.setValue(duration[1]);
    }
  }

  writeValue(val): void {
    this.fieldFormControl.setValue(val);
    this.onChanged(this.fieldFormControl.value);
  }
}
