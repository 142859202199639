<div class="nav-menu-wrapper" data-cy-id="settings-menu">
  <button
    class="settings-menu__button heap-desktop-header-user-menu-btn"
    [class.settings-menu__active-menu]="isMenuOpen"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    (menuOpened)="isMenuOpen = !isMenuOpen"
    (menuClosed)="isMenuOpen = !isMenuOpen"
    [leftSideIcon]="'user'"
    [rightSideIcon]="'caret-down'"
    [buttonSize]="isElectronApp ? 'small' : 'medium'"
    rekor-basic>
    {{ authUserName | async }}
  </button>
  <mat-menu class="main-nav-menu header-component-ng-deep" #menu="matMenu">
    <ng-template matMenuContent>
      <div class="settings-menu-component">
        <div class="settings-menu-component__group-container">
          <div class="settings-menu-component__group-title">
            {{ 'settings.configurations' | translate }}
          </div>
          <button
            class="heap-desktop-user-menu-settings"
            *ngIf="hasPermission('ACCOUNT:READ') || hasPermission('USER:READ') || hasPermission('ROLE:READ')"
            data-cy-id="settings"
            mat-menu-item
            [routerLink]="['/settings/account/general']">
            <wc-icon iconName="cog"></wc-icon>
            {{ 'settings.settings' | translate }}
          </button>

          <button data-cy-id="portrait-mode-btn" mat-menu-item (click)="togglePortraitMode()">
            <wc-icon iconName="portrait-mode"></wc-icon>
            {{ 'portraitMode' | translate }}
            <mat-slide-toggle [formControl]="portraitMode" #matToggle></mat-slide-toggle>
          </button>
          <ng-container *ngIf="hasPermission('WRONG_WAY_ALERT:READ'); else onlySound">
            <button mat-menu-item class="audio-section">
              <span> <wc-icon iconName="bell"></wc-icon> {{ 'soundAlerts' | translate }}</span>
              <form data-cy-id="sound-alerts" class="settings-menu-component__audio-form" [formGroup]="soundsFormGroup">
                <wc-button-toggle-group
                  formControlName="state"
                  [formFieldData]="toggleAudioFormFieldData"></wc-button-toggle-group>
              </form>
            </button>
          </ng-container>
          <ng-template #onlySound>
            <button
              class="menu-toggle-button heap-desktop-user-menu-alert"
              (click)="toggleAlertSounds($event)"
              mat-menu-item
              [disableRipple]="true">
              <wc-icon iconName="bell"></wc-icon>
              {{ 'soundAlerts' | translate }}
              <mat-slide-toggle #matToggleSound [formControl]="alertSound"></mat-slide-toggle>
            </button>
          </ng-template>
        </div>
        <div class="settings-menu-component__group-container">
          <div class="settings-menu-component__group-title">
            {{ 'resourceCenter' | translate }}
          </div>
          <button mat-menu-item data-cy-id="support-btn" (click)="openSupportModal($event)">
            <wc-icon iconName="support-help"></wc-icon>
            {{ 'support' | translate }}
          </button>
          <button *ngIf="(releaseNotes | async)!.length > 0" (click)="openReleaseNotes($event)" mat-menu-item>
            <wc-release-notes-view
              #releaseView
              (closeParent)="menuTrigger.closeMenu()"
              (click)="$event.stopPropagation()"></wc-release-notes-view>
          </button>

          <button (click)="openOnboarding($event)" mat-menu-item *ngIf="showOnBoarding">
            <wc-onboarding-tours
              class="onboarding-tours"
              [buttonInsideUserMenu]="true"
              (click)="$event.stopPropagation()"
              (onboardingStarted)="onboardingStarted()"
              #onboardingTours></wc-onboarding-tours>
          </button>
        </div>

        <div class="settings-menu-component__group-container">
          <button
            data-cy-id="settings-widget-download-button"
            class="heap-desktop-user-menu-download-widget"
            *ngIf="hasPermission('DOWNLOAD_WIDGET:READ')"
            mat-menu-item
            (click)="downloadWidget()">
            <wc-icon iconName="download"></wc-icon>
            {{ 'downloadWidget' | translate }}
          </button>

          <button mat-menu-item class="heap-desktop-user-menu-logout" data-cy-id="log-out-btn" (click)="logout()">
            <wc-icon iconName="logout"></wc-icon>
            {{ 'logout' | translate }}
          </button>
        </div>
      </div>
    </ng-template>
  </mat-menu>
</div>

<wc-support
  *ngIf="showSupportModal"
  [textDirection]="isDirectionRtl ? 'rtl' : 'ltr'"
  (closeButton)="closeSupportModal()"></wc-support>
