/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { EntityUIQuery, QueryEntity } from '@datorama/akita';
import { AffectedServiceUI, BaseUIEntity, TransitLayerType } from '@wc/wc-models/src';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LiveMapQuery } from '../live-map/live-map.query';
import {
  AffectedRouteServicesStore,
  AffectedRouteServiceState,
  AffectedRouteServiceUIState,
} from './transit_affected_route_services.store';

@Injectable({ providedIn: 'root' })
export class AffectedRouteServicesQuery extends QueryEntity<AffectedRouteServiceState> {
  ui!: EntityUIQuery<AffectedRouteServiceUIState>;
  constructor(store: AffectedRouteServicesStore, private liveMapQuery: LiveMapQuery) {
    super(store);
    this.createUIQuery();
  }

  get filteredServicesUIEntities$() {
    return this.liveMapQuery
      .getVisibleEntitiesIdsByLayerType(TransitLayerType.TransitRoute)
      .pipe(switchMap(visibleRouteIds => this.ui.selectMany(visibleRouteIds)));
  }

  get allAffectedServicesUIEntities$() {
    return {
      asValue: this.ui.getAll(),
      asQuery: this.ui.selectAll(),
    };
  }

  getAffectedServiceUIByRouteId$(
    id: number | undefined
  ): Observable<Partial<BaseUIEntity<AffectedServiceUI>> | undefined> {
    return id ? this.ui.selectEntity(id) : of();
  }

  getAffectedServiceUIByRouteId(id: number | undefined) {
    return this.ui.getEntity(id);
  }
}
