import { Injectable } from '@angular/core';
import {
  GetRoadwayStatusSegmentMetricDetailsByIdGQL,
  LayerType,
  RoadwayStatusDetailedMetricDto,
} from '@wc/core/models/gql.models';
import { EntitiesServiceV2, LiveMapService, RoadwayStatusQuery } from '@wc/wc-core/src';
import { RoadwayStatusSegmentsSortOptions } from '@wc/wc-models';
import { map, tap } from 'rxjs/operators';
import { CustomRxOperatorsService } from './custom-rx-operators.service';
import { LocalStorageKeys, LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class RoadwayStatusService {
  constructor(
    private customOperators: CustomRxOperatorsService,
    private liveMapService: LiveMapService,
    private roadwayStatusQuery: RoadwayStatusQuery,
    private getRoadwayStatusSegmentMetricDetailsByIdGQL: GetRoadwayStatusSegmentMetricDetailsByIdGQL,
    private entitiesService: EntitiesServiceV2,
    private localStorageService: LocalStorageService
  ) {}

  getRoadwayStatusSegmentDetails = (segmentId: number) =>
    this.getRoadwayStatusSegmentMetricDetailsByIdGQL.fetch({ segmentId: segmentId }).pipe(
      map(res => res?.data?.getRoadwayStatusSegmentMetricDetailsById as RoadwayStatusDetailedMetricDto[]),
      tap(detailedMetrics => {
        this.entitiesService.emitNewUIDiff({
          [LayerType.RoadwayStatus]: [{ id: segmentId, detailedMetrics: detailedMetrics }],
        });
      })
    );

  saveSortTypeToLocalStorage(selectedSortType: RoadwayStatusSegmentsSortOptions) {
    this.localStorageService.set(LocalStorageKeys.RoadwayStatusSortType, selectedSortType);
  }

  getSortTypeFromLocalStorage() {
    return this.localStorageService.get(LocalStorageKeys.RoadwayStatusSortType);
  }
}
