<div class="segments-list-panel" [class.portrait-mode]="isPortraitDesktopMode">
  <div class="segments-list-panel__header">
    <span class="segments-list-panel__title">
      {{ 'roadwayStatus.mainTitle' | translate }}
    </span>

    <div class="segments-list-panel__close-button" (click)="closePanel()">
      <i class="far fa-times fa-lg close-button-icon"></i>
    </div>
  </div>

  <div class="segments-list-panel__show-location-section">
    <label class="checkbox-label">
      <mat-checkbox
        class="heap-desktop-realtime-analytics-show-on-map-checkbox"
        [checked]="isShowOnMap"
        (change)="toggleShowOnMap($event)">
      </mat-checkbox>

      <span class="checkbox-label-text">
        {{ 'roadwayStatus.showLocationsOnMap' | translate }}
      </span>
    </label>
  </div>

  <ng-container *ngIf="segments.length > 0; else emptyState">
    <div class="segments-list-panel__sort-section">
      <i class="fas fa-sort-alt"></i>
      <span class="sort-by-label">{{ 'sortBy' | translate }}</span>

      <mat-select
        [(ngModel)]="selectedSortSegments"
        (selectionChange)="saveSortSelectionToLocalStorage(selectedSortSegments)"
        [panelClass]="{
          'segment-select-dropdown': true,
          'language-rtl': isDirectionRtl
        }">
        <mat-option
          *ngFor="let sortItem of segmentsSortOptionsArray"
          [value]="sortItem"
          [ngClass]="'heap-desktop-realtime-analytics-sort-' + sortItem">
          {{ 'roadwayStatus.sortBy.' + sortItem | translate }}
        </mat-option>
      </mat-select>
    </div>

    <div class="segments-list-panel__body">
      <ng-container
        *ngFor="let segment of segments | roadwayStatusSort: { orderBy: selectedSortSegments };
                trackBy: trackById">
        <wc-segment-details-card
          (openDetailsClicked)="showSegmentDetails($event)"
          [measureUnitType]="measureUnitType"
          [entity]="segment"
          [mode]="mode"
          [context]="context"
          [regionalSettings]="regionalSettings"
        >
        </wc-segment-details-card>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #emptyState>
    <div class="empty-state">
      <img src="assets/images/no_segments_error_state.svg" alt="no segments in the list" />
      <div class="error-message">
        <div>
          {{ 'roadwayStatus.somethingWentWrong' | translate }}
        </div>
        <div>
          {{ 'roadwayStatus.error' | translate }}
        </div>
        <div>
          {{ 'roadwayStatus.tryLater' | translate }}
        </div>
      </div>
    </div>
  </ng-template>
</div>
