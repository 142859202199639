import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { AffectedLanesFormFieldComponentsModule } from '@wc/features/ui/form-fields-components/affected-lanes-form-field-components.module';
import { FormatsPipesModule } from '@wc/features/ui/pipes/formats.pipes.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { AffectedLanesExpentionComponent } from './affected-lanes-expention.component';

@NgModule({
  declarations: [AffectedLanesExpentionComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatExpansionModule,
    AffectedLanesFormFieldComponentsModule,
    FormatsPipesModule,
    SwiperModule,
  ],
  exports: [AffectedLanesExpentionComponent],
})
export class AffectedLanesExpentionModule {}
