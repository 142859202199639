import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData } from '@wc/core';

@Component({
  selector: 'wc-confirm-generic-modal',
  templateUrl: './confirm-generic-modal.component.html',
  styleUrls: ['./confirm-generic-modal.component.scss'],
})
export class ConfirmGenericModalComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmGenericModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {}

  approve() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
