/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ElementRef, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { asRoadEventCameraInput, asRoadEventDmsInput } from '@wc/core';
import { ConfirmationModalType } from '@wc/core/models/enums';
import { PublishIncidentFormComponent } from '@wc/features/ui/forms';
import { AffectedDirectionsToOptions, affectedLanesArrayDiff, EnumToOptions } from '@wc/utils';
import { objectDiff, scrollToInvalidPanel } from '@wc/wc-common/src';
import { wcCoordinate, wcCoordinateTypes, WcGeometry, WcGeometryType } from '@wc/wc-map-viewer/src';
import {
  ConstructionStoreEntity,
  CssIconsClassType,
  LayerTypeToInputUpdate,
  OccupancyRangeSortedByAmount,
  RoadClosureStoreEntity,
  ToastrPositionClass,
  TrafficDisruptionStoryEntity as TrafficDisruptionStoreEntity,
  TrafficDisruptionStoryEntity,
  TrafficDisruptionType,
} from '@wc/wc-models/src';
import { ConfirmModalService } from '@wc/wc-ui/src/';
import { liveMapConfig } from '@wc/wc-ui/src/components/map-viewers/live-map-viewer/live-map-viewer.config';
import { FormFieldData, FormFieldOption } from '@wc/wc-ui/src/lib/base/custom-form-control';
import { ToastrAlertsService } from '@wc/wc-ui/src/services/toaster-alert.service';
import { cloneDeep, has as _has } from 'lodash';
import moment from 'moment';
import { merge, MonoTypeOperatorFunction, Observable, of, pipe, Subject } from 'rxjs';
import { finalize, map, mergeAll, switchMap, take, tap } from 'rxjs/operators';
import {
  ActivityLogAggregated,
  ActivityLogEntityType,
  AssociatedCameraInput,
  AssociatedDmsInput,
  ConstructionType,
  ConstructionView,
  CreateConstructionInput,
  CreateRoadClosureInput,
  CreateSpecialEventInput,
  EntityType,
  LayerType,
  Permit,
  ResponsePlanEntityType,
  RoadClosureView,
  SpecialEvent,
  SpecialEventType,
  SpecialEventView,
  TrafficDisruption,
  TrafficDisruptionStatus,
  UpdateConstructionInput,
  UpdateRoadClosureInput,
  UpdateSpecialEventInput,
  VenueDetails,
} from '../../../../core/models/gql.models';
import { LineString, Point, Polygon } from '../../../../core/models/models';
import { GeoService } from '../../../../core/services';
import { ConstructionApiService } from './construction-api.service';
import { EntitiesServiceV2 } from './entities.service';
import { LiveMapService } from './live-map.service';
import { LoaderService } from './loader.service';
import { ResponsePlanService } from './response-plan-service';
import { RoadClosureApiService } from './road-closure-api.service';
import { SpecialEventApiService } from './special-event.api.service';
import { TrafficDisruptionApiService } from './traffic-disruption-api.service';
import { getSpecialEventData } from './traffic-disruption-utils';
import { UploadService, UrlUploadEntityEnum } from './upload.service';

type ServiceByLayerType = {
  [LayerType.Construction]: ConstructionApiService;
  [LayerType.RoadClosure]: RoadClosureApiService;
  [LayerType.SpecialEvent]: SpecialEventApiService;
};

type TrafficDisruptionCreateInput = CreateConstructionInput | CreateRoadClosureInput | CreateSpecialEventInput;

type OperationFunction = {
  create: {
    [LayerType.Construction]: CreateConstructionInput;
    [LayerType.RoadClosure]: CreateRoadClosureInput;
    [LayerType.SpecialEvent]: CreateSpecialEventInput;
  };
  update: {
    [LayerType.Construction]: UpdateConstructionInput;
    [LayerType.RoadClosure]: UpdateRoadClosureInput;
    [LayerType.SpecialEvent]: UpdateSpecialEventInput;
  };
};

export type TrafficDisruptionLayerType = LayerType.Construction | LayerType.RoadClosure | LayerType.SpecialEvent;

export enum AlertPrefixEnum {
  Construction = 'construction',
  RoadClosure = 'roadClosure',
  SpecialEvent = 'specialEvent',
}

export enum OperationsEnum {
  Create = 'create',
  Update = 'update',
}

export const layerToTranslationPath = {
  [LayerType.Construction]: 'construction',
  [LayerType.RoadClosure]: 'roadClosure',
  [LayerType.SpecialEvent]: 'specialEventType',
};

@Injectable({
  providedIn: 'root',
})
export class TrafficDisruptionService {
  private _currentPendingDelete: number[] = [];
  moment = moment;
  isFormSubmitted$ = new Subject<boolean>();

  startLocation!: Point;
  selectedTrafficDisruption!: TrafficDisruptionStoryEntity;

  completedTrafficDisruptions: {
    road_closure: RoadClosureView[];
    construction: ConstructionView[];
    special_event: SpecialEventView[];
  } = {
    road_closure: [],
    construction: [],
    special_event: [],
  };
  selectedTrafficDisruptionBeforeEdit: any;
  newlyCreatedTrafficDisruptionId: number | undefined = undefined;

  private readonly _onTrafficDisruptionEdited = new Subject<number>();
  readonly onTrafficDisruptionEdited$ = this._onTrafficDisruptionEdited.asObservable();

  //TODO: Removing fieldData to a dedicated service, that will serve all places in the app that uses fieldData.
  fieldData: Record<string, FormFieldData> = {};

  constructor(
    private translateService: TranslateService,
    private trafficDisruptionApiService: TrafficDisruptionApiService,
    private entitiesService: EntitiesServiceV2,
    private liveMapService: LiveMapService,
    private constructionApiService: ConstructionApiService,
    private roadClosureApiService: RoadClosureApiService,
    private specialEventApiService: SpecialEventApiService,
    private alertService: ToastrAlertsService,
    private geoService: GeoService,
    private uploadService: UploadService,
    private confirmService: ConfirmModalService,
    private responsePlanService: ResponsePlanService,
    private loaderService: LoaderService
  ) {
    this.initFieldData();
    const langChange$ = this.translateService.onLangChange.subscribe(() => {
      this.initFieldData();
      langChange$.unsubscribe();
    });
  }

  initFieldData() {
    this.fieldData = {
      address: { label: 'roadClosureForm.address' },
      title: {
        label: 'roadClosureForm.title',
        required: true,
      },
      eventName: {
        label: 'specialEventForm.eventName',
        required: true,
      },
      occupancyRange: {
        label: 'specialEventForm.occupancy',
        options: EnumToOptions(OccupancyRangeSortedByAmount, {
          translateBy: 'value',
          translateService: this.translateService,
          translatePath: 'specialEventOccupancyRange',
          removeSort: true,
        }),
      },
      venueLocation: {
        label: 'specialEventForm.location',
      },
      venueName: {
        label: 'specialEventForm.venueName',
        options: [],
      },
      venueAddress: {
        label: 'specialEventForm.venueAddress',
        options: [],
      },
      startTime: { label: 'roadClosureForm.startTime', required: true },
      endTime: { label: 'roadClosureForm.endTime', required: true },
      estimatedEndTime: { label: 'roadClosureForm.estimatedEndTime' },
      location: {},
      contactPerson: { label: 'roadClosureForm.contactPerson' },
      contactPersonEmail: { label: 'roadClosureForm.email' },
      contactPersonName: { label: 'roadClosureForm.name' },
      contactPersonPhone: { label: 'roadClosureForm.phoneNumber' },
      description: { label: 'roadClosureForm.description', placeholder: 'roadClosureForm.descriptionPlaceholder' },
      specialEventType: {
        label: 'subtype',
        options: this.eventTypeOptions(LayerType.SpecialEvent),
        required: false,
      },
      constructionType: {
        label: 'subtype',
        options: this.eventTypeOptions(LayerType.Construction),
        required: false,
      },
      otherTypeDescription: { label: 'description' },

      multiDirectionLanesAffected: {
        label: 'affectedDirectionsLabel',
        options: AffectedDirectionsToOptions({
          translateService: this.translateService,
        }),
      },
      allLanesAffected: {
        label: 'allLanesAreAffected',
      },
      affectedLanes: { label: 'roadClosureForm.lanesDetails' },
      lanesClosureType: {
        label: 'roadClosureForm.allLanesAreClosed',
      },
      isNotifyPublicFormData: {
        label: this.translateService.instant('shareAfterCreation'),
      },
    };
  }

  isTrafficDisruptionEditable(trafficDisruption: TrafficDisruption) {
    return !['MultiPolygon', 'MultiLineString'].includes(trafficDisruption?.location.type);
  }

  get selectedFeatureId() {
    return this.liveMapService.selectedFeatureId;
  }

  get mapCenterPoint() {
    return this.liveMapService.mapCenterPoint;
  }

  get isMapReady$() {
    return this.liveMapService.isMapReady$;
  }

  eventTypeOptions(layerType: TrafficDisruptionLayerType): FormFieldOption[] {
    const enumType = layerType === LayerType.SpecialEvent ? SpecialEventType : ConstructionType;
    return EnumToOptions(enumType, {
      translateService: this.translateService,
      translateBy: 'value',
      translatePath: layerType === LayerType.SpecialEvent ? 'specialEventType' : 'constructionType',
    });
  }

  fetchAndUpdateCompletedTrafficDisruptions(layerType: TrafficDisruptionLayerType) {
    (
      this.getServiceByLayerType(layerType).fetchCompleted$ as unknown as Observable<
        (ConstructionView | RoadClosureView | SpecialEventView)[]
      >
    )
      .pipe(map(entities => entities.map(entity => ({ ...entity, show: false }))))
      .subscribe({
        next: completedEntities =>
          this.entitiesService.emitNewEntitiesDiff({
            modified: {
              [layerType]: completedEntities,
            },
          }),
      });
  }

  createOrUpdateTrafficDisruption<T extends TrafficDisruptionStoreEntity>(
    layerType: TrafficDisruptionLayerType,
    entity: T,
    operation: OperationsEnum
  ) {
    let camerasInput: AssociatedCameraInput[] | undefined;
    let dmsInput: AssociatedDmsInput[] | undefined;
    let permit: Permit | undefined;

    if (this.isConstructionOrRoadClosure(entity)) {
      camerasInput = entity.cameras.map(image => asRoadEventCameraInput(image));
      dmsInput = entity.dmses.map(associatedDms => asRoadEventDmsInput(associatedDms));
      permit = entity.permit?.permitId || entity.permit?.url ? entity.permit : undefined;
    }

    return (
      operation === 'update'
        ? this.updateTrafficDisruption(layerType, { ...entity, relatedImages: [] })
        : this.createTrafficDisruption(layerType, {
            ...entity,
            ...{ cameras: camerasInput, dmses: dmsInput, relatedImages: [], permit: permit },
          })
    ).pipe(
      finalize(() => this.finishSubmissionProcess()),
      switchMap(res =>
        res
          ? of(res).pipe(
              this.addLayerTypeAndWorkSpaceToEntity(layerType),
              this.emitTrafficDisruptionUpdate(),
              this.liveMapService.toggleWorkspaceVisibilityOnEntityUpdate(),
              this.liveMapService.setEntityLayerAsVisible(layerType)
            )
          : of(null)
      )
    );
  }

  scrollToInvalidPanel() {
    const el = document.getElementById('panel-id');
    const elRef: ElementRef = new ElementRef(el);
    scrollToInvalidPanel(elRef, 10);
  }

  getTrafficDisruptionTranslationPath<T extends TrafficDisruptionLayerType>(
    trafficDisruption: TrafficDisruption,
    trafficDisruptionType: T
  ): typeof layerToTranslationPath[T] | '' {
    return !layerToTranslationPath[trafficDisruptionType]
      ? ''
      : layerToTranslationPath[trafficDisruptionType] === layerToTranslationPath.SPECIAL_EVENT
      ? layerToTranslationPath[trafficDisruptionType] + `.${(trafficDisruption as SpecialEvent).type}`
      : layerToTranslationPath[trafficDisruptionType];
  }

  getLoaderNames() {
    return {
      [TrafficDisruptionType.RoadClosure]: 'roadClouser',
      [TrafficDisruptionType.Construction]: 'construction',
      [TrafficDisruptionType.SpecialEvent]: 'specialEvent',
    };
  }

  handleInvalidSubmit() {
    setTimeout(() => {
      this.scrollToInvalidPanel();
    }, 10);
    this.alertService.error(this.translateService.instant('incidentFormValidationMessage'), undefined, {
      positionClass: ToastrPositionClass.DesktopBottomPanelLeft,
    });
  }

  // -----------------------new API------------------------:
  selectTrafficDisruption<T extends TrafficDisruptionStoreEntity>(
    trafficDisruption: T,
    zoomOn: boolean,
    emitEvent = true
  ): Observable<string | undefined> {
    if (!trafficDisruption.layerType) {
      throw new Error("can't select traffic disruption with layerType:" + trafficDisruption.layerType);
    }
    this.entitiesService.emitNewEntitiesDiff({
      modified: {
        [trafficDisruption.layerType]: [trafficDisruption],
      },
    });

    this.selectedTrafficDisruption = trafficDisruption;
    this.selectedTrafficDisruptionBeforeEdit = cloneDeep(trafficDisruption);
    if (zoomOn)
      this.liveMapService.setMapCenter(trafficDisruption.location.coordinates, {
        zoomLevel: 15,
        duration: 1000,
        padding: [100, 100, 100, 100],
      });
    this.selectedTrafficDisruption = trafficDisruption;
    return this.liveMapService.selectFeature(trafficDisruption.layerType, trafficDisruption.id, emitEvent);
  }

  clearTrafficDisruptionSelection() {
    this.stopModifyGeometry(false);
    this.stopCreateGeometry();
    this.liveMapService.unselectFeature();
    this.selectedTrafficDisruption = {} as TrafficDisruptionStoryEntity;
    this.selectedTrafficDisruptionBeforeEdit = null;
  }

  selectFeature(layerType: TrafficDisruptionLayerType, id: number, emitEvent: boolean) {
    return this.liveMapService.selectFeature(layerType, id, emitEvent);
  }

  unselectFeature() {
    this.liveMapService.unselectFeature();
  }

  updateTrafficDisruption<T extends TrafficDisruptionStoreEntity>(layerType: TrafficDisruptionLayerType, entity: T) {
    this.newlyCreatedTrafficDisruptionId = entity.id;
    this.updateTrafficDisruptionLoader(true);
    return of(
      affectedLanesArrayDiff(this.selectedTrafficDisruptionBeforeEdit.affectedLanes, entity.affectedLanes)
    ).pipe(
      switchMap(({ modified, removed, created }) =>
        modified.length || removed.length || created.length
          ? this.trafficDisruptionApiService
              .updateTrafficDisruptionLanes({
                trafficDisruptionId: entity.id,
                modified,
                removed,
                created,
              })
              .pipe(
                //Should be removed when switching to update lanes by the update entity API
                tap(
                  modifiedLanes =>
                    (this.selectedTrafficDisruption.affectedLanes = this.selectedTrafficDisruption.affectedLanes?.map(
                      l => modifiedLanes?.find(({ id }) => id === l.id) || l
                    ))
                )
              )
          : of(true)
      ),

      map(() => this.getEntityDiff(entity)),
      switchMap(diff => {
        return Object.keys(diff).length
          ? this.createOrUpdateEntity(
              layerType,
              OperationsEnum.Update,
              this.getAsTrafficDisruptionInput(diff, entity.id)
            )
          : of(null);
      })
    );
  }

  getActivityLog(id: number, type: ActivityLogEntityType): Observable<ActivityLogAggregated[]> {
    switch (type) {
      case ActivityLogEntityType.Construction:
        return this.constructionApiService.getActivityLog(id);
      case ActivityLogEntityType.RoadClosure:
        return this.roadClosureApiService.getActivityLog(id);
      case ActivityLogEntityType.SpecialEvent:
        return this.specialEventApiService.getActivityLog(id);

      default:
        console.error('TD getActivityLog not support type');
        return of();
    }
  }

  private emitTrafficDisruptionUpdate<T extends TrafficDisruptionStoreEntity>(): MonoTypeOperatorFunction<T> {
    return pipe(
      tap((data: T) => {
        if (!data?.layerType) {
          throw new Error('entity does not have layer type');
        }
        if (typeof data === 'object' && data !== null)
          this.entitiesService.emitNewEntitiesDiff({
            modified: {
              [data.layerType]: [data],
            },
          });
      }),
      tap(data => this._onTrafficDisruptionEdited.next(data.id))
    );
  }

  private addLayerTypeAndWorkSpaceToEntity<T extends TrafficDisruptionStoreEntity>(
    layerType: TrafficDisruptionLayerType
  ) {
    return map<T, T>((entity: T) => ({
      ...entity,
      layerType,
      workspaces: this.geoService.workspacesByLocation(entity.location.coordinates),
    }));
  }

  private createTrafficDisruption<T extends TrafficDisruptionStoreEntity>(
    layerType: TrafficDisruptionLayerType,
    entity: T
  ): Observable<T | null> {
    delete (<Partial<T>>entity)['id'];
    return this.createOrUpdateEntity(layerType, OperationsEnum.Create, {
      ...entity,
      relatedImages: [],
    } as TrafficDisruptionCreateInput);
  }

  private createOrUpdateEntity<T extends TrafficDisruptionLayerType, O extends OperationsEnum>(
    layerType: T,
    operation: O,
    input: OperationFunction[O][T]
  ): Observable<any> {
    const service = this.getServiceByLayerType(layerType);
    if (!service) {
      throw new Error(`could not find service with LayerType:${layerType}`);
    }
    switch (operation) {
      case 'create':
        return service.create(input as any);
      case 'update':
        return service.update(input as any);
      default:
        throw new Error(`Could not find operation for: ${operation} `);
    }
  }

  getVenuesNearPoint(point: Point): Observable<VenueDetails[]> {
    return this.getServiceByLayerType(LayerType.SpecialEvent).getVenuesNearPoint(point);
  }

  setMapCenter(coords: wcCoordinate): void {
    this.liveMapService.setMapCenter(coords);
  }

  private getAsTrafficDisruptionInput<T extends TrafficDisruptionStoreEntity>(
    entity: Partial<T>,
    id: number
  ): LayerTypeToInputUpdate<T> {
    return {
      ...entity,
      id,
      address: entity.address ? { value: entity.address } : null,
      contactPerson: entity.contactPerson ? { value: entity.contactPerson } : null,
      description: entity.description !== null ? { value: entity.description } : null,
      endTime: entity.endTime ? { value: entity.endTime } : null,
      schedule: entity.schedule
        ? _has(entity.schedule, 'value')
          ? entity.schedule
          : { value: entity.schedule }
        : null,

      permit:
        this.isConstructionOrRoadClosure(entity) && entity.permit
          ? { value: entity.permit.url || entity.permit.permitId ? entity.permit : null }
          : undefined,
      cameras:
        this.isConstructionOrRoadClosure(entity) && entity.cameras
          ? entity.cameras.map(camera => asRoadEventCameraInput(camera))
          : undefined,
      dmses:
        this.isConstructionOrRoadClosure(entity) && entity.dmses
          ? entity.dmses.map(dms => asRoadEventDmsInput(dms))
          : undefined,
    } as unknown as LayerTypeToInputUpdate<T>;
  }

  isConstructionOrRoadClosure(
    trafficDisruption: unknown
  ): trafficDisruption is ConstructionStoreEntity | RoadClosureStoreEntity {
    const roadClosureOrConstruction = trafficDisruption as ConstructionStoreEntity | RoadClosureStoreEntity;
    return (
      roadClosureOrConstruction.cameras !== undefined ||
      roadClosureOrConstruction.dmses !== undefined ||
      roadClosureOrConstruction.permit !== undefined
    );
  }

  private getEntityDiff<T extends TrafficDisruptionStoreEntity>(entity: T): Partial<T> {
    const diff = objectDiff(this.selectedTrafficDisruptionBeforeEdit, entity) as Partial<T>;
    delete diff.affectedLanes;
    return diff;
  }

  alertOnOperationEnd(isSuccess: boolean, operation: OperationsEnum, prefix: string) {
    if (isSuccess) {
      this.alertService.success(
        this.translateService.instant(`notifications.${prefix + (operation === 'update' ? 'Updated' : 'Created')}`),
        undefined,
        undefined,
        true
      );
    } else {
      this.alertService.error(
        this.translateService.instant(
          `notifications.${prefix + (operation === 'update' ? 'UpdateFailed' : 'CreationFailed')}`
        ),
        undefined,
        undefined,
        true
      );
    }
  }

  get modifyAndDrawEndEvents$() {
    return merge([this.liveMapService.modifyEndEvent$, this.liveMapService.drawEndEvent$]).pipe(mergeAll());
  }

  onAddressError(error: { errorCode: number }) {
    if (error?.errorCode) {
      this.translateService
        .get(`errorMessages.${error.errorCode}`)
        .pipe(take(1))
        .subscribe(errorMessage => {
          this.alertService.error(errorMessage);
        });
    }
  }

  getCssIconClass(trafficDisruptionType: LayerType): CssIconsClassType | '' {
    switch (trafficDisruptionType) {
      case LayerType.Construction:
        return 'list-icon-construction';
      case LayerType.SpecialEvent:
        return 'list-icon-special_event';
      case LayerType.RoadClosure:
        return 'list-icon-road_closure';

      default:
        return '';
    }
  }

  createNewGeometry(
    location: Point | LineString | Polygon
  ): Observable<WcGeometry | undefined | Point | LineString | Polygon> {
    return this.liveMapService
      .createGeometry(
        location.type,
        location.coordinates || (null as unknown as wcCoordinateTypes),
        liveMapConfig.editFeatureOption?.editStyleArgs,
        {
          editStyleArgs: liveMapConfig.editFeatureOption?.editStyleArgs,
        }
      )
      .pipe(map(drawEvent => drawEvent.targets?.[0]?.geometry));
  }

  private getServiceByLayerType<T extends TrafficDisruptionLayerType>(TrafficDisruptionType: T): ServiceByLayerType[T] {
    switch (TrafficDisruptionType) {
      case LayerType.Construction:
        return this.constructionApiService as ServiceByLayerType[T];
      case LayerType.RoadClosure:
        return this.roadClosureApiService as ServiceByLayerType[T];
      case LayerType.SpecialEvent:
        return this.specialEventApiService as ServiceByLayerType[T];
      default:
        throw new Error(`LayerType: ${TrafficDisruptionType} is not a valid TrafficDisruptionType`);
    }
  }

  getTrafficDisruption(id: number, layerType: TrafficDisruptionLayerType) {
    return this.getServiceByLayerType(layerType).get(id);
  }

  private finishSubmissionProcess() {
    this.uploadFiles(this.newlyCreatedTrafficDisruptionId);
    this.deleteTrafficDisruptionMedia(this.newlyCreatedTrafficDisruptionId);
    this.newlyCreatedTrafficDisruptionId = undefined;
  }

  editFeatureGeometry(featureId: string, geometryType: WcGeometryType) {
    return this.liveMapService.editFeatureGeometry(
      featureId,
      {
        showReference: true,
        editStyleArgs: liveMapConfig.editFeatureOption?.editStyleArgs,
        referenceStyleArgs: liveMapConfig.editFeatureOption?.referenceStyleArgs,
      },
      geometryType
    );
  }

  stopModifyGeometry(keepChanges = true) {
    this.liveMapService.stopEditGeometry(keepChanges);
  }

  stopCreateGeometry() {
    this.liveMapService.stopCreateGeometry();
  }

  completeTrafficDisruption(trafficDisruption: TrafficDisruptionStoreEntity, layerType?: LayerType) {
    const _layerType = trafficDisruption.layerType || layerType;
    if (!_layerType) {
      throw new Error(`invalid parameters for completing traffic disruption: layerType: ${_layerType}, `);
    }
    this.updateTrafficDisruptionLoader(true);

    return new Promise<boolean>(resolve => {
      this.isResponsePlanDone(trafficDisruption.id, _layerType).subscribe({
        next: done => {
          if (done === false) {
            this.confirmService.showConfirmDialog(
              ConfirmationModalType.ResponsePlanNotDone,
              EntityType.SpecialEvent,
              completeAnyWay => {
                if (completeAnyWay) {
                  this._completeTD(trafficDisruption, _layerType).subscribe(() => {
                    this.updateTrafficDisruptionLoader(false);
                    resolve(true);
                  });
                } else {
                  this.updateTrafficDisruptionLoader(false);
                  resolve(false);
                }
              }
            );
          } else {
            this._completeTD(trafficDisruption, _layerType).subscribe(() => {
              this.updateTrafficDisruptionLoader(false);
              resolve(true);
            });
          }
        },
        error: err => console.error('Failed to Reroute affected service', err),
      });
    });
  }

  private _completeTD(trafficDisruption: TrafficDisruptionStoreEntity, layerType: LayerType) {
    return this.getServiceByLayerType(layerType as TrafficDisruptionLayerType)
      .complete(+trafficDisruption.id)
      .pipe(
        tap(isSuccess => {
          if (!isSuccess) {
            throw new Error('fail');
          }
        }),
        tap(() => {
          if (this.selectedTrafficDisruption) {
            this.unselectFeature();
          }
          //TODO: Currently not updating the affected lanes. should be from the backend.
          this.entitiesService.emitNewEntitiesDiff({
            modified: {
              [layerType]: [
                {
                  id: trafficDisruption.id,
                  status: TrafficDisruptionStatus.Completed,
                  layerType: layerType,
                },
              ],
            },
            removed: {
              [layerType]: [trafficDisruption.id],
            },
          });
          this.alertService.success(
            this.translateService.instant('trafficDisruptionNotifications.' + TrafficDisruptionStatus.Completed, {
              type: this.translateService.instant('trafficDisruptionTypes.' + layerType).toLowerCase(),
            })
          );
        })
      );
  }

  /**
   * @deprecated
   * PublishIncidentFormComponent is must be in a least one place for widget to load and not break
   * This component is no longer in use in any place
   */
  private notifyPublic() {
    console.log(PublishIncidentFormComponent);
  }

  addFilesToPendingDelete(trafficDisruptionMediaId: number) {
    this._currentPendingDelete.push(trafficDisruptionMediaId);
  }

  hasPendingMediaDeletions() {
    return this._currentPendingDelete.length > 0;
  }

  resetPendingMediaDeletions() {
    this._currentPendingDelete = [];
  }

  uploadFiles(entityId?: number) {
    if (!entityId) throw new Error('Entity ID must exist');
    this.uploadService.addFilesToQueueAndUpload(UrlUploadEntityEnum.TRAFFIC_DISRUPTION, entityId).subscribe();
  }

  deleteTrafficDisruptionMedia(trafficDisruptionId) {
    if (this._currentPendingDelete.length)
      this.trafficDisruptionApiService
        .deleteMedia({
          trafficDisruptionId: trafficDisruptionId,
          removed: this._currentPendingDelete,
        })
        .subscribe(() => {
          this._currentPendingDelete = [];
        });
  }

  getSpecialEventData(specialEvent: SpecialEvent) {
    return getSpecialEventData(specialEvent, this.translateService);
  }

  isResponsePlanDone(id: number, layerType: LayerType) {
    return layerType === LayerType.SpecialEvent
      ? this.responsePlanService.isResponsePlanDone(id, ResponsePlanEntityType.SpecialEvent)
      : of(undefined);
  }

  private updateTrafficDisruptionLoader(state: boolean): void {
    this.loaderService.update({ trafficDisruptions: state });
  }
}
