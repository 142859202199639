import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SortByWrongWayAlertPipe } from './sort-by-wrong-way-alert.pipe';

@NgModule({
  declarations: [SortByWrongWayAlertPipe],
  imports: [CommonModule],
  exports: [SortByWrongWayAlertPipe],
})
export class SortByWrongWayAlertModule {}
