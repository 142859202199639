import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { WcSelectModule } from '../../form-components/wc-select/wc-select.module';
import { InjuriesSeverityComponent } from './injuries-severity.component';

@NgModule({
  declarations: [InjuriesSeverityComponent],
  imports: [CommonModule, WcSelectModule, ReactiveFormsModule, MatFormFieldModule, TranslateModule],
  exports: [InjuriesSeverityComponent],
})
export class WcInjuriesSeverityModule {}
