<div
  data-cy-id="lane-diagram-component"
  class="lane-diagram-component"
  [class.disabled]="isViewMode"
  wcTooltipPlacement="right"
  [wcTooltipText]="'tooltip.lanesDiagram' | translate"
  *ngIf="!isAnalyticsMode; else analyticsMode">
  <div data-cy-id="lanes-diagram-container" class="lanes-diagram-container">
    <div
      [attr.data-cy-id]="'lane-tile-' + lane.number"
      class="lane-tile"
      *ngFor="let lane of lanes; let i = index; let first = first; let last = last"
      (click)="laneClicked(i)"
      (mouseover)="laneHover(i)"
      (mouseleave)="laneHover(-1)"
      [class.hover]="i === hovered"
      [class.rounded-corners-left]="first"
      [class.rounded-corners-right]="last"
      [class.rounded-corners-both]="lanes.length === 1"
      [ngClass]="{
        open: !lane.isClosed,
        closed: lane.isClosed,
        notActive: !lane.isAffected
      }">
      <span
        [attr.data-cy-id]="'lane-number-' + lane.number"
        *ngIf="lane.type && !TextualLanes.includes(lane.type); else showType">
        {{ lane.number }}
      </span>
      <ng-template #showType>
        <span [attr.data-cy-id]="'lane-type-' + lane.type | lowercase">
          {{ 'laneTypesShort.' + $any(lane.type) | translate }}
        </span>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #analyticsMode>
  <div class="lane-diagram-component" [class.disabled]="isViewMode">
    <div class="lanes-diagram-container">
      <div
        class="lane-tile"
        *ngFor="let lane of analyticsLanes; let i = index; let first = first; let last = last"
        (click)="laneClicked(i)"
        (mouseover)="laneHover(i)"
        (mouseleave)="laneHover(-1)"
        [class.view-mode]="isViewMode"
        [class.hover]="i === hovered"
        [class.rounded-corners-left]="first"
        [class.rounded-corners-right]="last"
        [class.rounded-corners-both]="analyticsLanes.length === 1"
        [ngClass]="{
          open: !lane.incidentLane?.isClosed,
          closed: lane.incidentLane?.isClosed,
          notActive: !lane.incidentLane?.isAffected
        }">
        <span *ngIf="lane.incidentLane?.type && !TextualLanes.includes(lane.incidentLane!.type); else showType">
          {{ lane.incidentLane?.number }}
        </span>
        <ng-template #showType>
          <span>
            {{ 'laneTypesShort.' + lane.incidentLane?.type | translate }}
          </span>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>
