import { Component, Input, OnInit } from '@angular/core';
import { LiveMapEntity } from '@wc/core';

@Component({
  selector: 'wc-dms-viewer',
  templateUrl: './dms-viewer.component.html',
  styleUrls: ['./dms-viewer.component.scss']
})
export class DmsViewerComponent {
  @Input() message: LiveMapEntity[] = [];
  @Input() isTablet = false;
}
