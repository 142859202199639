import { Injectable } from '@angular/core';
import {
  CreateMediaAccessedLogInput,
  LogMediaAccessedGQL,
  RenewIncidentMediaUrlGQL,
  RenewTrafficDisruptionMediaUrlGQL,
} from '@wc/core/models/gql.models';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MediaApiService {
  pendingUploadFiles: File[] = [];

  constructor(
    private renewIncidentMediaUrlGQL: RenewIncidentMediaUrlGQL,
    private renewTrafficDisruptionUrlGQL: RenewTrafficDisruptionMediaUrlGQL,
    private logMediaAccessedGQL: LogMediaAccessedGQL
  ) {}

  logMediaAccessed(input: CreateMediaAccessedLogInput) {
    return this.logMediaAccessedGQL.mutate({ input }).pipe(
      map(res => res?.data?.logMediaAccessed),
      catchError(err => throwError({ errorCode: err.graphQLErrors[0]?.extensions.statusCode }))
    );
  }

  renewIncidentMediaUrl(incidentMediaId: number) {
    return this.renewIncidentMediaUrlGQL?.mutate({ id: incidentMediaId }).pipe(
      map(res => res.data?.renewIncidentMediaUrl),
      catchError(err =>
        throwError({
          errors: err.graphQLErrors,
          errorCode: err.graphQLErrors[0]?.extensions.statusCode,
        })
      )
    );
  }

  renewTrafficDisruptionMediaUrl(trafficDisruptionMediaId: number) {
    return this.renewTrafficDisruptionUrlGQL.mutate({ id: trafficDisruptionMediaId }).pipe(
      map(res => res.data?.renewTrafficDisruptionMediaUrl),
      catchError(err =>
        throwError({
          errors: err.graphQLErrors,
          errorCode: err.graphQLErrors[0]?.extensions.statusCode,
        })
      )
    );
  }

  resetPendingFiles() {
    this.pendingUploadFiles = [];
  }
}
