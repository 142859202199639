import Feature from 'ol/Feature';
import {
  LineString as olLineString,
  MultiLineString as olMultiLineString,
  MultiPolygon as olMultiPolygon,
  Point as olPoint,
  Polygon as olPolygon,
} from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import { toLonLat } from 'ol/proj';
import { WcGeometryEnum } from '../../enums';
import { wcFeatureProperties } from '../../interfaces';
import { wcCoordinate } from '../../types';
import { generateFeatureID } from '../layer-utils/feature-utils';
import { coordsFormatFromLonLat } from '../utils';

export class WcFeature<T = null> extends Feature {
  private _firstCoordinate!: wcCoordinate | undefined;
  private _lastCoordinate!: wcCoordinate | undefined;
  private _firstCoordinateLonLat!: wcCoordinate;

  get firstCoordinate() {
    return this._firstCoordinate;
  }

  get lastCoordinate() {
    return this._lastCoordinate;
  }

  get firstCoordinateLonLat() {
    return this._firstCoordinateLonLat;
  }

  getWcFeatureProp<T extends keyof wcFeatureProperties>(value: T) {
    return this.get(value);
  }

  constructor(properties: wcFeatureProperties<T>) {
    super(properties);

    this.setGeometryByType(properties.geomType, properties.coordinates);
    const { entityType, id } = properties;
    if (entityType && id) {
      this.setId(generateFeatureID(entityType, id));
      this.update({ featureId: this.getId() as string });
    }
  }

  update(props: Partial<wcFeatureProperties<T>>) {
    this.setProperties(props);
    if (props.geomType && props.coordinates) {
      this.setGeometryByType(props.geomType, props.coordinates);
    }
  }

  get isVisible(): boolean {
    return !!this.get('isVisible');
  }

  private setLastAndFirstCoordinates(geometry: olLineString | olMultiPolygon | olPolygon | olMultiLineString): void {
    this._lastCoordinate = geometry.getLastCoordinate();
    this._firstCoordinate = geometry.getFirstCoordinate();
    this._firstCoordinateLonLat = toLonLat(this._firstCoordinate);
    this.update({
      firstCoordinate: this._firstCoordinate,
      lastCoordinate: this._lastCoordinate,
      firstCoordinateLonLat: this._firstCoordinateLonLat,
    });
  }

  setGeometryByType(
    type: GeometryType | WcGeometryEnum,
    coordinates: wcCoordinate | wcCoordinate[] | wcCoordinate[][]
  ) {
    let geometry: olPoint | olLineString | olPolygon | olMultiPolygon | undefined | olMultiLineString = undefined;
    const _coordinates = coordsFormatFromLonLat(coordinates, type);
    this._lastCoordinate = undefined;
    this._firstCoordinate = undefined;
    switch (type) {
      case 'Point':
        geometry = new olPoint(_coordinates as wcCoordinate);
        this._firstCoordinateLonLat = toLonLat(geometry.getCoordinates());
        this.update({
          firstCoordinateLonLat: this._firstCoordinateLonLat,
        });
        break;
      case 'LineString':
        geometry = new olLineString(_coordinates as wcCoordinate[]);
        this.setLastAndFirstCoordinates(geometry);

        break;
      case 'Polygon':
        geometry = new olPolygon(_coordinates as wcCoordinate[][]);
        this.setLastAndFirstCoordinates(geometry);

        break;
      case 'MultiPolygon':
        geometry = new olMultiPolygon(_coordinates as wcCoordinate[][][]);
        this.setLastAndFirstCoordinates(geometry);

        break;

      case 'MultiLineString':
        geometry = new olMultiLineString(_coordinates as wcCoordinate[][]);
        this.setLastAndFirstCoordinates(geometry);
        break;
      default:
        console.error(`type provided: ${type} is not supported. will set geometry as undefined`);
        break;
    }
    this.setGeometry(geometry);
  }
}
