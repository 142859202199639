import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { FeedbackModule } from '../feedback/feedback.module';
import { WcButtonModule } from '../wc-button/wc-button.module';
import { WcIconModule } from '../wc-icon/wc-icon.module';
import { OnboardingToursComponent } from './onboarding-tours.component';

@NgModule({
  declarations: [OnboardingToursComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatMenuModule,
    WcIconModule,
    FeedbackModule,
    WcButtonModule,
  ],
  exports: [OnboardingToursComponent],
})
export class OnboardingToursModule {}
