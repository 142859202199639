import { Pipe, PipeTransform } from '@angular/core';
import { Incident, IncidentType } from '@wc/core';
import { EventListSortType, IncidentStoreEntity } from '@wc/wc-models/src';

const IncidentTypeOrderWithUnidentified: IncidentType[] = [
  IncidentType.Crash,
  IncidentType.VehicleOnFire,
  IncidentType.Hazard,
  IncidentType.StalledVehicle,
  IncidentType.Debris,
  IncidentType.Ems,
  IncidentType.PoliceActivity,
  IncidentType.LeftOnArrival,
  IncidentType.AbandonedVehicle,
  IncidentType.Damage,
  IncidentType.Other,
  IncidentType.TrafficStop,
  IncidentType.TrafficAnomaly,
  IncidentType.WrongWay,
  IncidentType.Unidentified,
];

@Pipe({
  name: 'incidentsListSort',
  pure: true,
})
export class IncidentListSortPipe implements PipeTransform {
  incidentTypeOrder: IncidentType[] = IncidentTypeOrderWithUnidentified;

  transform(incidents: IncidentStoreEntity[], args: EventListSortType): any[] {
    let newOrderedIncidents: IncidentStoreEntity[] = [];
    switch (args) {
      case EventListSortType.Type:
        this.incidentTypeOrder.forEach(incidentType => {
          const incidentsOfType: IncidentStoreEntity[] = incidents.filter((incident: Incident) => {
            return incident.type.toLocaleLowerCase() === incidentType.toLocaleLowerCase();
          });
          newOrderedIncidents = [...newOrderedIncidents, ...incidentsOfType];
        });

        return newOrderedIncidents;

      case EventListSortType.TimeOldFirst:
        newOrderedIncidents = incidents.sort((a, b) => {
          return new Date(a.startedAt).getTime() - new Date(b.startedAt).getTime();
        });
        return [...[], ...newOrderedIncidents];

      case EventListSortType.Priority:
        newOrderedIncidents = incidents.sort((a, b) => {
          return (b.priorityScore?.score || 0) - (a.priorityScore?.score || 0);
        });
        return [...[], ...newOrderedIncidents];

      case EventListSortType.TimeNewFirst:
      default:
        newOrderedIncidents = incidents.sort((a, b) => {
          return new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime();
        });
        return [...[], ...newOrderedIncidents];
    }
  }
}
