import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WcInvalidExpansionCssClassDirectiveModule } from '@wc/wc-common/src/lib/directives/wc-invalid-expansion-css-class.directive.module';
import { WcSelectModule } from '../../form-components/wc-select/wc-select.module';
import { ResponsePlanFormActionModule } from './response-plan-form-action/response-plan-form-action.module';
import { ResponsePlanFormComponent } from './response-plan-form.component';
@NgModule({
  declarations: [ResponsePlanFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    WcSelectModule,
    WcInvalidExpansionCssClassDirectiveModule,
    ResponsePlanFormActionModule,
  ],
  exports: [ResponsePlanFormComponent],
})
export class ResponsePlanFormModule {}
