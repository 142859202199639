import { Pipe, PipeTransform } from '@angular/core';

const canvas = document.createElement('canvas');

const context = canvas?.getContext('2d') as CanvasRenderingContext2D;
if (context) context.font = '12px Lato';
function getTextVisualLength(text) {
  return context.measureText(text).width;
}

@Pipe({ name: 'titlePipe', pure: true })
export class ExponentialStrengthPipe implements PipeTransform {
  transform(
    value: any,
    visualLength: number
  ): {
    id: string;
    titleToDisplay: string;
  } {
    if (typeof value !== 'string') return value;

    const _value = value.split(' '),
      id = _value.shift() as string,
      restOfTitle = _value.join(' ').trim();

    return {
      id,
      titleToDisplay: this.getTitleToDisplay(restOfTitle, visualLength),
    };
  }

  private getTitleToDisplay(value: string, visualLength: number): string {
    if (getTextVisualLength(value) <= visualLength) return value;

    while (getTextVisualLength(value) > visualLength) {
      value = value.slice(0, -1);
    }

    return value.slice(0, -3).trim() + '...';
  }
}
