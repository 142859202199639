<div class="workspaces-btn-wrapper heap-desktop-workspaces-btn-wrapper">
  <button data-cy-id="modify-workspaces-btn" class="workspaces-btn" [matMenuTriggerFor]="workspacesMenu">
    <i class="fas fa-globe workspaces-icon" [class]="appType"></i>
    <span *ngIf="appType === 'desktop'">{{ 'modifyWorkspaces' | translate }}</span>
  </button>
  <mat-menu
    #workspacesMenu
    [hasBackdrop]="true"
    [class]="appType === 'desktop' ? 'workspaces-container desktop' : 'workspaces-container'">
    <div class="toggle-container">
      <ng-container *ngFor="let workspace of workspacesArray">
        <button
          [id]="'workspace-' + workspace.id"
          class="toggle-button-wrapper"
          mat-menu-item
          (click)="handleClick($event, toggle, workspace.id)">
          <wc-slide-toggle
            #toggle
            [wcTooltipText]="workspace.title"
            [wcTooltipMaxWidthPx]="220"
            data-cy-id="workspaces-toggle"
            [ngClass]="'heap-desktop-' + (workspace.title | translate | lowercase) + '-checkbox'"
            [checked]="workspace.checked"
            (toggleChanged)="updateSelectedWorkspaces($event)"
            [toggleName]="workspace.title"
            [label]="workspace.title">
          </wc-slide-toggle>
        </button>
        <div class="workspaces-btn-wrapper__cctv-badge" *ngIf="workspace.isNearCamerasLabel">
          {{ 'onlyIncidentNearCCTV' | translate }}
        </div>
      </ng-container>
    </div>
  </mat-menu>
</div>
