import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CheckboxControlComponent } from './checkbox.component';
import { WcBaseControlFieldModule } from '../../base/base-control-field.module';

const Materials: any[] = [MatCheckboxModule];

@NgModule({
  declarations: [CheckboxControlComponent],
  imports: [CommonModule, WcBaseControlFieldModule, Materials],
  providers: [],
  exports: [CheckboxControlComponent],
})
export class WcCheckboxModule {}
