import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { HeapClassDirectiveModule } from '@wc/wc-common/src';
import { CollaborationViewerModule, WcChipModule, WcIconModule, WcNetworkBadgeModule } from '@wc/wc-ui';
import { WcTooltipModule } from '@wc/wc-ui/src/components/tooltip/tooltip.module';
import { MobxAngularModule } from 'mobx-angular';
import { PermissionDirectiveModule } from '../../directives/permission.directive.module';
import { RoadEventHighlightModule } from '../../directives/road-event-highlight.module';
import { FormatsPipesModule } from '../../pipes/formats.pipes.module';
import { IncidentListItemComponent } from './incident-list-item.component';

@NgModule({
  declarations: [IncidentListItemComponent],
  imports: [
    CommonModule,
    TranslateModule,
    WcNetworkBadgeModule,
    FormatsPipesModule,
    CollaborationViewerModule,
    WcNetworkBadgeModule,
    PermissionDirectiveModule,
    RoadEventHighlightModule,
    WcChipModule,
    MobxAngularModule,
    MatTooltipModule,
    HeapClassDirectiveModule,
    WcTooltipModule,
    MatChipsModule,
    WcIconModule,
  ],
  exports: [IncidentListItemComponent],
})
export class IncidentListItemModule {}
