import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonToggleGroupModule } from '../../components/button-toggle-group/button-toggle-group.module';
import { WcTooltipModule } from '../../components/tooltip/tooltip.module';
import { WcButtonModule } from '../../components/wc-button/wc-button.module';
import { WcIconModule } from '../../components/wc-icon/wc-icon.module';
import { WcDateTimeModule } from '../../outdated/date-time/date-time.module';
import { WcSelectModule } from '../wc-select/wc-select.module';
import { EventSchedulerComponent } from './event-scheduler.component';

@NgModule({
  declarations: [EventSchedulerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonToggleGroupModule,
    WcDateTimeModule,
    MatCheckboxModule,
    WcSelectModule,
    WcIconModule,
    WcButtonModule,
    WcTooltipModule,
  ],
  exports: [EventSchedulerComponent],
})
export class EventSchedulerModule {}
