<div class="text-area-component" [class.full-width]="isFullWidth">
  <label for="textArea" *ngIf="isLabelVisible && formFieldData.label" class="text-area-component__label">
    <span *ngIf="formFieldData.required" class="red-asterisk"> * </span>
    {{ formFieldData.label | translate }}
  </label>

  <textarea
    name="textArea"
    #textAreaReference
    data-cy-id="description-text-box"
    class="text-area-component__textarea"
    [class.disabled]="ngControl.control?.disabled"
    [class.read-only]="readOnly"
    [class.error]="errorMessage && (ngControl.control?.touched || ngControl.control?.dirty)"
    [placeholder]="formFieldData.placeholder || '' | translate"
    [formControl]="$any(ngControl.control)"
    (focus)="setIsFocused(true)"
    (blur)="setIsFocused(false)"
    [rows]="rows"></textarea>

  <button
    *ngIf="!hideClearButton && isFocused && ngControl.value"
    class="text-area-component__clear-button"
    [class.textarea-has-label]="isLabelVisible && formFieldData.label"
    (mousedown)="clearTextArea($event)">
    <wc-icon iconName="times-circle"></wc-icon>
  </button>

  <div
    class="text-area-component__error-message"
    *ngIf="errorMessage && (ngControl.control?.touched || ngControl.control?.dirty); else counterTemplate"
    @slideDownAnimation>
    {{
      'validationErrorMessages.' +
        (customValidationMessage && errorMessage === 'unique' ? customValidationMessage : $any(errorMessage))
        | translate
          : {
              min: ngControl.errors?.minlength?.min,
              max: ngControl.errors?.maxlength?.max,
              minLength: ngControl.errors?.minlength?.requiredLength,
              maxLength: ngControl.errors?.maxlength?.requiredLength
            }
    }}
  </div>

  <ng-template #counterTemplate>
    <div
      data-cy-id="text-area-component__character-count"
      *ngIf="viewCharacterCounter && ngControl.control && maxLength > 0"
      class="text-area-component__character-count"
      [class.error]="ngControl.control && ngControl.control.value?.length > maxLength">
      {{ 'charactersLimits' | translate: { currentLength: ngControl.control.value.length, maxLength: maxLength } }}
    </div>
  </ng-template>
</div>
