import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeapClassDirective } from './heap-class.directive';

@NgModule({
  declarations: [HeapClassDirective],
  exports: [HeapClassDirective],
  imports: [CommonModule],
})
export class HeapClassDirectiveModule {}
