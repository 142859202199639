import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Point } from '../models';
import {
  CompletedSpecialEventsGQL,
  CompleteSpecialEventGQL,
  CreateSpecialEventGQL,
  CreateSpecialEventInput,
  PublishInput,
  PublishSpecialEventGQL,
  SpecialEvent,
  SpecialEventActivityLogGQL,
  SpecialEventGQL,
  UpdateSpecialEventGQL,
  UpdateSpecialEventInput,
  VenueDetails,
  VenuesGQL,
} from '../models/gql.models';
import { CustomRxOperatorsService } from './custom-rx-operators.service';
@Injectable({
  providedIn: 'root',
})
export class SpecialEventService {
  private readonly visitedPointVenues = {};
  readonly fetchCompleted$ = this.completedSpecialEventsGQL
    .fetch()
    .pipe(
      map(({ data: { completedSpecialEvents } }) =>
        completedSpecialEvents.map(specialEvent => ({ ...specialEvent, featureType: 'special_event' }))
      )
    );
  constructor(
    private venuesGql: VenuesGQL,
    private customOperators: CustomRxOperatorsService,
    private specialEventGql: SpecialEventGQL,
    private createSpecialEventGQL: CreateSpecialEventGQL,
    private updateSpecialEventGQL: UpdateSpecialEventGQL,
    private completeSpecialEventGQL: CompleteSpecialEventGQL,
    private completedSpecialEventsGQL: CompletedSpecialEventsGQL,
    private specialEventActivityLogGQL: SpecialEventActivityLogGQL,
    private publishSpecialEventGQL: PublishSpecialEventGQL
  ) {}

  getCompleted() {
    return this.fetchCompleted$;
  }

  get(id: number) {
    return this.specialEventGql.fetch({ id: id }).pipe(
      map(res => res.data.specialEvent as SpecialEvent),
      this.customOperators.catchGqlErrors()
    );
  }

  create(input: CreateSpecialEventInput) {
    return this.createSpecialEventGQL.mutate({ input }).pipe(
      map<any, SpecialEvent>(res => res?.data?.createSpecialEvent),
      this.customOperators.catchGqlErrors()
    );
  }

  update(input: UpdateSpecialEventInput) {
    return this.updateSpecialEventGQL.mutate({ input }).pipe(
      map<any, SpecialEvent>(res => res?.data?.updateSpecialEvent),
      this.customOperators.catchGqlErrors()
    );
  }

  complete(id: number) {
    return this.completeSpecialEventGQL.mutate({ id }).pipe(
      map(res => res?.data?.completeSpecialEvent),
      this.customOperators.catchGqlErrors()
    );
  }

  getActivityLog(id: number) {
    return this.specialEventActivityLogGQL.fetch({ id: id }).pipe(
      map(res => res.data.specialEventActivityLog),
      this.customOperators.catchGqlErrors()
    );
  }

  publish(publishInput: PublishInput) {
    return this.publishSpecialEventGQL.mutate({ input: publishInput }).pipe(this.customOperators.catchGqlErrors());
  }

  fetchVenuesByPoint(point: Point) {
    return this.venuesGql.fetch({ point, radiusMeters: 3000 }).pipe(
      map(res => res?.data?.venues),
      this.customOperators.catchGqlErrors()
    );
  }

  getVenuesNearPoint(point: Point): Observable<VenueDetails[]> {
    if (!point || !point.coordinates) {
      throw new Error('A valid point was not provided');
    }

    const pointKey = `(${point.coordinates[0]}, ${point.coordinates[1]})`;
    return this.visitedPointVenues[pointKey]
      ? of(this.visitedPointVenues[pointKey])
      : this.fetchVenuesByPoint(point).pipe(tap(venues => (this.visitedPointVenues[pointKey] = venues)));
  }
}
