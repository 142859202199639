import { LiveMapEntityType, MapLayer } from './models';

// This config is the same as `livemap.weather.config` differences :
// 1. pointStatusStyle.default.default.scale = 0.6
// 2. no hover or selected state
export const WeatherLayersTabletConfig: {
  [layerName in LiveMapEntityType]?: MapLayer;
} = {
  air: {
    zIndex: 4,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'featureSubTypeOf', scale: 0.6 },
          },
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'status', anchor: [-0.1, 3], scale: 1 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '',
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'weather_end', anchor: [0.5, 0.5] },
            zIndex: 10,
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#ABE9FF', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: -1 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  floods: {
    zIndex: 4,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'featureSubTypeOf', scale: 0.6 },
          },
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'status', anchor: [-0.1, 3], scale: 1 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '',
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
              scale: 0.6,
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'weather_end', anchor: [0.5, 0.5] },
            zIndex: 10,
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#ABE9FF', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: -1 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  fog: {
    zIndex: 4,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'featureSubTypeOf', scale: 0.6 },
          },
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'status', anchor: [-0.1, 3], scale: 1 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '',
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
              scale: 0.6,
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'weather_end', anchor: [0.5, 0.5] },
            zIndex: 10,
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#ABE9FF', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: -1 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  marine: {
    zIndex: 4,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'featureSubTypeOf', scale: 0.6 },
          },
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'status', anchor: [-0.1, 3], scale: 1 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '',
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'weather_end', anchor: [0.5, 0.5] },
            zIndex: 10,
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#ABE9FF', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: -1 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  specific_road_conditions: {
    zIndex: 4,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'featureSubTypeOf', scale: 0.6 },
          },
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'status', anchor: [-0.1, 3], scale: 1 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '',
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
              scale: 0.6,
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'weather_end', anchor: [0.5, 0.5] },
            zIndex: 10,
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#ABE9FF', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: -1 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  temperature: {
    zIndex: 4,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'featureSubTypeOf', scale: 0.6 },
          },
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'status', anchor: [-0.1, 3], scale: 1 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '',
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
              scale: 0.6,
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'weather_end', anchor: [0.5, 0.5] },
            zIndex: 10,
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#ABE9FF', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: -1 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  thunderstorms: {
    zIndex: 4,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'featureSubTypeOf', scale: 0.6 },
          },
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'status', anchor: [-0.1, 3], scale: 1 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '',
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'weather_end', anchor: [0.5, 0.5] },
            zIndex: 10,
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#ABE9FF', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: -1 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  tornado: {
    zIndex: 4,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'featureSubTypeOf', scale: 0.6 },
          },
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'status', anchor: [-0.1, 3], scale: 1 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '',
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
              scale: 0.6,
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'weather_end', anchor: [0.5, 0.5] },
            zIndex: 10,
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#ABE9FF', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: -1 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  tropical: {
    zIndex: 4,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'featureSubTypeOf', scale: 0.6 },
          },
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'status', anchor: [-0.1, 3], scale: 1 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '',
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'weather_end', anchor: [0.5, 0.5] },
            zIndex: 10,
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#ABE9FF', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: -1 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  wind: {
    zIndex: 4,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'featureSubTypeOf', scale: 0.6 },
          },
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'status', anchor: [-0.1, 3], scale: 1 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '',
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'weather_end', anchor: [0.5, 0.5] },
            zIndex: 10,
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#ABE9FF', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: -1 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  winter: {
    zIndex: 4,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'featureSubTypeOf', scale: 0.6 },
          },
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'status', anchor: [-0.1, 3], scale: 1 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '',
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'weather_end', anchor: [0.5, 0.5] },
            zIndex: 10,
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#ABE9FF', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: -1 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },

  fire: {
    zIndex: 4,
    pointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'featureSubTypeOf', scale: 0.6 },
          },
          {
            shape: 'marker',
            icon: { iconName: '', srcByEntityProperty: 'status', anchor: [-0.1, 3], scale: 1 },
          },
        ],
      },
    },
    lineStringStatusStyle: {
      default: {
        default: [{ shape: 'lineSolid', color: '#0BC4FF', width: 5, opacity: 0.45 }],
      },
    },
    startPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '',
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              anchor: [-0.1, 2.3],
              scale: 1,
            },
          },
        ],
      },
    },
    endPointStatusStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: { iconName: 'weather_end', anchor: [0.5, 0.5] },
            zIndex: 10,
          },
        ],
      },
    },
    polygonStatusStyle: {
      default: {
        default: [
          { shape: 'lineSolid', color: '#ABE9FF', width: 2 },
          { shape: 'area', color: '#0BC4FF', opacity: 0.15, zIndex: -1 },
        ],
      },
    },
    startPointPolygonStyle: {
      default: {
        default: [
          {
            shape: 'marker',
            icon: {
              iconName: '_polygon',
              scale: 0.8,
              anchor: [0.5, 0.8],
              srcByEntityProperty: 'featureSubTypeOf',
            },
          },
          {
            shape: 'marker',
            icon: {
              iconName: '',
              srcByEntityProperty: 'status',
              scale: 0.8,
              anchor: [-0.1, 2.3],
            },
          },
        ],
      },
    },
  },
};
