import { AfterViewInit, Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WcMapConfig } from '../../../../core/livemap.config';
import { WcMapComponent } from '@wc/wc-map/src/lib/wc-map.component';
import { take } from 'rxjs/operators';
import { WcMapConfigModel } from '../../../../core/models';
import * as Enums from '../../../../core/models/enums';
import { LineString, Point, Polygon } from '../../../../core/models';
import { BaseControlFieldComponent } from '../../base/base-control-field.component';
import { MapDrawingModalComponent } from '../../components/map-drawing-modal/map-drawing-modal.component';

@Component({
  selector: 'wc-map-draw',
  templateUrl: './map-draw.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MapDrawComponent),
      multi: true,
    },
  ],
  styleUrls: ['./map-draw.component.scss'],
})
export class MapDrawComponent extends BaseControlFieldComponent implements OnInit, AfterViewInit {
  @ViewChild('wcMapStaticView', { static: true, read: WcMapComponent })
  wcMap!: WcMapComponent;
  wcMapConfig: WcMapConfigModel = WcMapConfig;
  dialogRef!: MatDialogRef<any>;
  geometry?: Point | Polygon | LineString;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder // public uiStore: UiStore,
  ) {
    super();
  }

  ngOnInit(): void {
    this.wcMap.ready.pipe(take(1)).subscribe(() => {
      this.wcMap.init(this.wcMapConfig, {
        mouseEventsMode: 'static',
        showWorkspaces: false,
      });
      this.wcMap.setMapLocation(this.wcMapConfig.mapCenter, {
        padding: [100, 100, 100, 100],
      });
    });
  }

  ngAfterViewInit() {}

  openRouteModal() {
    this.dialogRef = this.dialog.open(MapDrawingModalComponent, {
      maxWidth: '80vw',
      width: '1260px',
      disableClose: true,
      panelClass: 'settings-form-modal',
      data: {
        formViewMode: Enums.FormViewMode.Edit,
        details: { geometry: this.geometry },
        dialogTitle: 'analyticsModule.filterByPolygon',
      },
    });

    this.dialogRef.afterClosed().subscribe(res => {
      //  console.log(res);
      if (res === null || (res.geometry && res.geometry === null)) {
        this.reset();
      } else {
        this.geometry = res.geometry;
        this.updateMapDraw(this.geometry);
        this.wcMap.setMapLocation([this.geometry?.coordinates as [][]], {
          padding: [110, 110, 110, 110],
        });
        this.fieldFormControl.setValue(this.geometry);
        this.onChanged(this.geometry);
      }
    });
  }

  updateMapDraw(geometry) {
    setTimeout(() => {
      this.wcMap.setMapLocation([geometry.coordinates as [][]], {
        padding: [110, 110, 110, 110],
      });
      this.wcMap.wcMapDrawService.view(geometry);
      this.wcMap.wcMapDrawService.stopDraw();
    });
  }

  writeValue(val): void {
    this.geometry = val;
    if (val !== null && val.coordinates) {
      this.updateMapDraw(val);
    }
    this.fieldFormControl.setValue(val);
  }

  reset() {
    this.geometry = undefined;
    this.wcMap.wcMapDrawService.resetLayer();
    this.fieldFormControl.setValue(null);
    this.onChanged(null);
  }
}
