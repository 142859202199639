/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { CamerasStoreEntity, CamerasUIEntity } from '@wc/wc-models';
import { map } from 'rxjs/operators';
import { LayerType } from '../../../../../../core/models/gql.models';
import { EntitiesServiceV2 } from '../../../services/entities.service';

export interface CamerasState extends EntityState<CamerasStoreEntity, number> {}
export interface CamerasUIState extends EntityState<CamerasUIEntity> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: LayerType.Camera })
export class CamerasStore extends EntityStore<CamerasState> {
  ui!: EntityUIStore<CamerasUIState>;
  constructor(entitiesService: EntitiesServiceV2) {
    super();
    this.createUIStore();

    entitiesService.getUIDiff$
      .pipe(map(({ CAMERA }) => CAMERA || []))
      .subscribe(UICameras => this.ui.upsertMany(UICameras));

    entitiesService.getDiff$
      .pipe(map(({ modified, removed }) => ({ removedCameras: removed?.CAMERA, modifiedCameras: modified.CAMERA })))
      .subscribe(({ removedCameras, modifiedCameras }) => {
        if (modifiedCameras) this.upsertMany(modifiedCameras as CamerasStoreEntity[]);
        if (removedCameras) this.remove(removedCameras);
      });

    entitiesService.activeEntityIds$.pipe(map(({ CAMERA }) => CAMERA || [])).subscribe(id => {
      this.ui.setActive(id);
    });
  }
}
