import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateFormatPipeModule } from './date-format.module';
import { DayOfWeekPipeModule } from './day-of-week.module';
import { DurationCountDownPipe } from './duration-count-down.pipe';
import { MinutesDurationToTimePipeModule } from './minutesDurationToTime.module';
import { TimeFormatPipeModule } from './time-format.module';

@NgModule({
  declarations: [DurationCountDownPipe],
  imports: [
    CommonModule,
    DateFormatPipeModule,
    TimeFormatPipeModule,
    DayOfWeekPipeModule,
    MinutesDurationToTimePipeModule,
  ],
  exports: [
    DateFormatPipeModule,
    DurationCountDownPipe,
    TimeFormatPipeModule,
    DayOfWeekPipeModule,
    MinutesDurationToTimePipeModule,
  ],
})
export class FormatsPipesModule {}
