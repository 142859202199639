import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { markNestedControlsAsTouched, ResponsePlanService } from '@wc-core';
import {
  Action,
  CreateResponsePlanTemplateInput,
  ResponsePlanDialogData,
  ResponsePlanEntityType,
  ResponsePlanTemplate,
} from '@wc/core';
import { UiMode } from '@wc/wc-models';
import { takeWhile } from 'rxjs/operators';
import { FormFieldData } from '../../lib/base';
import { ToastrAlertsService } from '../../services/toaster-alert.service';
import { ResponsePlanFormComponent } from '../response-plan-form/response-plan-form.component';

@Component({
  selector: 'wc-response-plan-template-page',
  templateUrl: './response-plan-template-page.component.html',
  styleUrls: ['./response-plan-template-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponsePlanTemplatePageComponent implements AfterViewInit, OnDestroy {
  @ViewChild(ResponsePlanFormComponent, { static: false }) responsePlanFormComponent!: ResponsePlanFormComponent;
  form!: FormGroup;
  regionalSetting = {}; // should be removed , relevant only from event context
  uiModeEnum = UiMode;
  template: ResponsePlanTemplate | undefined = undefined;
  isClone: boolean | undefined = false;
  templateTitles: string[] = [];
  isAlive = true;
  entityType!: ResponsePlanEntityType;
  requestInProgress = false;

  constructor(
    public dialogRef: MatDialogRef<ResponsePlanTemplatePageComponent>,
    public responsePlanService: ResponsePlanService,
    private translateService: TranslateService,
    private alertService: ToastrAlertsService,
    @Inject(MAT_DIALOG_DATA) public data: ResponsePlanDialogData
  ) {
    this.template = data.template;
    this.isClone = data.isClone;
    this.templateTitles = data.templateTitles;
    this.regionalSetting = this.responsePlanService.reginalSettings;
    this.entityType = data.entityType;
    this.form = this.responsePlanService.generateResponsePlanForm(this.entityType, data.template, data.isClone);
    this.validateUniqueTitle();
  }

  titleFieldData: FormFieldData = {
    label: this.translateService.instant('templateName'),
    placeholder: this.translateService.instant('responsePlanTemplateTitlePlaceholder'),
  };

  ngAfterViewInit() {
    this.uniqueTitleErrorMessageOnClone(this.form.get('title')?.value);
  }

  validateUniqueTitle() {
    this.form
      .get('title')
      ?.valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe(templateTitle => {
        this.uniqueTitleErrorMessageOnClone(templateTitle);
      });
  }

  uniqueTitleErrorMessageOnClone(templateTitle?: string) {
    const formTitleControl = this.form.get('title');
    if (this.templateTitles?.some(_templateTitle => _templateTitle === templateTitle?.trim()))
      if (!this.template || templateTitle !== this.template?.title || this.isClone)
        formTitleControl?.setErrors({ unique: true });
  }

  submitResponsePlan() {
    this.responsePlanFormComponent.scrollIntoInvalidPanel();
    markNestedControlsAsTouched(this.form);
    if (this.form.valid) {
      const formValue = this.createFormValue();
      this.requestInProgress = true;
      if (formValue.id && !this.data.isClone) {
        this.responsePlanService
          .updateResponsePlanTemplate(this.responsePlanService.createUpdateResponsePlanTemplateInput(formValue))
          .subscribe({
            next: responsePlanTemplate => {
              this.closeDialog(responsePlanTemplate);
              this.responsePlanService.resetFormAndData();
              this.alertService.success(this.translateService.instant('notifications.successfullyEditTemplate'));
            },
            error: e => {
              this.alertService.error(this.translateService.instant('notifications.successfullyDeleteTemplate'));
              console.error(e);
            },
          });
      } else {
        formValue.actions.forEach(action => {
          delete action.templateActionId;
          delete action.templateId;
        });
        // create response plan also in clone mode
        const createResponsePlanTemplateInput = formValue as CreateResponsePlanTemplateInput;
        this.responsePlanService.createResponsePlanTemplate(createResponsePlanTemplateInput).subscribe({
          next: responsePlanTemplate => {
            this.closeDialog(responsePlanTemplate);
            this.responsePlanService.resetFormAndData();
            this.alertService.success(this.translateService.instant('notifications.successfullyCreateTemplate'));
          },
          error: e => {
            this.alertService.error(this.translateService.instant('notifications.failedToCreateResponseTemplate'));
            console.error(e);
          },
        });
      }
    }
  }

  createFormValue() {
    const formValue = this.form.value;
    const actions = formValue.actions;
    formValue.title = formValue.title?.trim();

    actions.forEach((action: Action & { timestamp?: number }) => {
      delete action.timestamp;
      action = this.responsePlanService.createActionValue(action);
    });

    return formValue;
  }

  closeDialog(responsePlanTemplate?: ResponsePlanTemplate) {
    this.dialogRef.close(responsePlanTemplate);
  }

  ngOnDestroy(): void {
    this.responsePlanService.resetFormAndData();
    this.isAlive = false;
  }
}
