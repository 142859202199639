/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { EntityUIQuery, QueryEntity } from '@datorama/akita';
import { LayerType } from '@wc/core';
import { switchMap } from 'rxjs/operators';
import { LiveMapQuery } from '../live-map/live-map.query';
import {
  AffectedUnitServicesStore,
  AffectedUnitServiceState,
  AffectedUnitServiceUIState,
} from './transit_affected_unit_services.store';

@Injectable({ providedIn: 'root' })
export class AffectedUnitServicesQuery extends QueryEntity<AffectedUnitServiceState> {
  ui!: EntityUIQuery<AffectedUnitServiceUIState>;
  constructor(store: AffectedUnitServicesStore, private liveMapQuery: LiveMapQuery) {
    super(store);
    this.createUIQuery();
  }

  get filteredServicesUIEntities$() {
    return this.liveMapQuery
      .getVisibleEntitiesIdsByLayerType(LayerType.Unit)
      .pipe(switchMap(visibleRouteIds => this.ui.selectMany(visibleRouteIds)));
  }

  get allAffectedServicesUIEntities$() {
    return {
      asValue: this.ui.getAll(),
      asQuery: this.ui.selectAll(),
    };
  }
}
