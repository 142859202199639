/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { AppFeatureEnum, SplitIOService } from '@wc-core';
import { FormSectionIncident, FormSectionTrafficDisruption } from '@wc/core';
import { AuthUserService } from '@wc/wc-core/src/lib/services/auth-user.service';
import { LocalStorageKeys, LocalStorageService } from '@wc/wc-core/src/lib/services/local-storage.service';
import {
  EventListSortType,
  getObservableReturnType,
  RoadEventsPanelSubTabIndexDesktop,
  RoadEventsPanelTabIndex,
} from '@wc/wc-models/src';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first, skip, take } from 'rxjs/operators';

export interface ExpandedSections {
  incident: Record<FormSectionIncident, boolean>;
  special_event: Record<FormSectionTrafficDisruption, boolean>;
  road_closure: Record<FormSectionTrafficDisruption, boolean>;
  construction: Record<FormSectionTrafficDisruption, boolean>;
}

export enum RoadEventsListTypes {
  NeedActionIncident = '_roadEventsNeedActionSort$',
  InProgressIncidents = '_roadEventsInProgressIncidentSort$',
  InProgressTD = '_roadEventsInProgressTD$',
  CompletedIncidents = '_roadEventsCompletedIncidentSort$',
  CompletedTD = '_roadEventCompletedTD$',
}

@Injectable({
  providedIn: 'root',
})
export class DesktopUiStateService {
  expandedSections: ExpandedSections = {
    incident: {
      location: true,
      time: true,
      involvedVehicles: false,
      type: true,
      mitigation: false,
      affectedLanes: false,
      cameras: false,
      autoPublish: false,
      notes: false,
      additionalInfo: false,
      source: false,
      casualties: false,
      externalId: false,
      media: false,
      associatedUnits: false,
      severity: false,
      attributes: false,
    },
    special_event: {
      description: false,
      affectedLanes: false,
      contactPerson: false,
      scheduled: false,
      media: false,
      permit: false,
      cameras: false,
    },
    road_closure: {
      description: false,
      affectedLanes: false,
      contactPerson: false,
      scheduled: false,
      media: false,
      permit: false,
      cameras: false,
    },
    construction: {
      description: false,
      affectedLanes: false,
      contactPerson: false,
      scheduled: false,
      media: false,
      permit: false,
      cameras: false,
    },
  };
  // Not is use, always false. kept to not break API
  readonly isDirectionRtl = false;
  private _keyboardInteraction = true;
  private _disablePageScroll$ = new BehaviorSubject<boolean>(false);
  private _isPortraitDesktopMode$ = new BehaviorSubject<boolean>(false);
  private _isRoadwaySideBarOpened$ = new BehaviorSubject<boolean>(true);
  private _roadEventsPanelTabIndex$ = new BehaviorSubject<RoadEventsPanelTabIndex>(RoadEventsPanelTabIndex.NeedAction);
  private _roadEventsInProgressSubTabIndex$ = new BehaviorSubject<RoadEventsPanelSubTabIndexDesktop>(
    RoadEventsPanelSubTabIndexDesktop.Incidents
  );
  private _roadEventsCompletedSubTabIndex$ = new BehaviorSubject<RoadEventsPanelSubTabIndexDesktop>(
    RoadEventsPanelSubTabIndexDesktop.Incidents
  );
  private _isHebrew$ = new BehaviorSubject<boolean>(false);
  isUserIdAvailable = false;

  private _roadEventsNeedActionSort$ = new BehaviorSubject<EventListSortType>(EventListSortType.Priority);
  private _roadEventsInProgressIncidentSort$ = new BehaviorSubject<EventListSortType>(EventListSortType.TimeNewFirst);
  private _roadEventsInProgressTD$ = new BehaviorSubject<EventListSortType>(EventListSortType.TimeNewFirst);
  private _roadEventsCompletedIncidentSort$ = new BehaviorSubject<EventListSortType>(EventListSortType.TimeNewFirst);
  private _roadEventCompletedTD$ = new BehaviorSubject<EventListSortType>(EventListSortType.TimeNewFirst);
  private _currentSelectedListEntityId$ = new BehaviorSubject<number>(-1);
  private _triggerListScroll$ = new Subject<number>();

  get disablePageScroll$() {
    return this._disablePageScroll$.asObservable();
  }

  get roadEventsNeedActionSortValue() {
    return {
      value: this._roadEventsNeedActionSort$.value,
      query$: this._roadEventsNeedActionSort$.asObservable(),
    };
  }

  get triggerListScroll$() {
    return this._triggerListScroll$.asObservable();
  }

  get roadEventsInProgressSubTabIndex() {
    return {
      value: this._roadEventsInProgressSubTabIndex$.value,
      query$: this._roadEventsInProgressSubTabIndex$.asObservable(),
    };
  }

  get currentSelectedListEntityId() {
    return {
      value: this._currentSelectedListEntityId$.value,
      query$: this._currentSelectedListEntityId$.asObservable(),
    };
  }

  get roadEventsCompletedSubTabIndex() {
    return {
      value: this._roadEventsCompletedSubTabIndex$.value,
      query$: this._roadEventsCompletedSubTabIndex$.asObservable(),
    };
  }

  get isRoadwaySideBarOpened() {
    return {
      value: this._isRoadwaySideBarOpened$.value,
      query$: this._isRoadwaySideBarOpened$.asObservable(),
    };
  }

  get isPortraitDesktopMode() {
    return {
      value: this._isPortraitDesktopMode$.value,
      query$: this._isPortraitDesktopMode$.asObservable(),
    };
  }

  get roadEventsPanelTabIndex() {
    return {
      value: this._roadEventsPanelTabIndex$.value,
      query: this._roadEventsPanelTabIndex$.asObservable(),
    };
  }

  get isHebrew() {
    return {
      value: this._isHebrew$.value,
      query: this._isHebrew$.asObservable(),
    };
  }

  get keyboardInteraction() {
    return this._keyboardInteraction;
  }
  get eventListSortPreferences() {
    return {
      [RoadEventsListTypes.NeedActionIncident]: this._roadEventsNeedActionSort$.asObservable(),
      [RoadEventsListTypes.InProgressIncidents]: this._roadEventsInProgressIncidentSort$.asObservable(),
      [RoadEventsListTypes.InProgressTD]: this._roadEventsInProgressTD$.asObservable(),
      [RoadEventsListTypes.CompletedIncidents]: this._roadEventsCompletedIncidentSort$.asObservable(),
      [RoadEventsListTypes.CompletedTD]: this._roadEventCompletedTD$.asObservable(),
    } as Record<RoadEventsListTypes, Observable<EventListSortType>>;
  }

  get roadEventsInProgressIncidentSort() {
    return this._roadEventsInProgressIncidentSort$.asObservable();
  }
  get roadEventsInProgressTDSort() {
    return this._roadEventsInProgressTD$.asObservable();
  }
  get roadEventsCompletedIncidentSort() {
    return this._roadEventsCompletedIncidentSort$.asObservable();
  }
  get roadEventCompletedTDSort() {
    return this._roadEventCompletedTD$.asObservable();
  }

  constructor(
    private localStorageService: LocalStorageService,
    authUserService: AuthUserService,
    private splitService: SplitIOService
  ) {
    authUserService.authUser$.pipe(first(user => !!user.id)).subscribe(() => {
      this.isUserIdAvailable = true;
      this.loadEventSortingPreferences();
    });
  }

  getExpendedSection<T extends keyof ExpandedSections, U extends keyof ExpandedSections[T] | undefined = undefined>(
    layerType: T,
    key?: U
  ): U extends undefined ? ExpandedSections[T] : boolean {
    const _layerSection = this.expandedSections[layerType];
    return typeof key === 'string' && _layerSection[key]
      ? _layerSection[key as keyof ExpandedSections[T]]
      : (_layerSection as any);
  }

  updateKeyboardInteraction(v: boolean) {
    this._keyboardInteraction = v;
  }

  updateIsPortraitDesktopMode<T = DesktopUiStateService['_isPortraitDesktopMode$'], U = getObservableReturnType<T>>(
    v: U
  ) {
    this._isPortraitDesktopMode$.next(v as any);
    return this.isPortraitDesktopMode;
  }

  updateRoadEventsInProgressSubTabIndex<
    T = DesktopUiStateService['_roadEventsInProgressSubTabIndex$'],
    U = getObservableReturnType<T>
  >(v: U) {
    this._roadEventsInProgressSubTabIndex$.next(v as any);
    return this.roadEventsInProgressSubTabIndex;
  }

  updateRoadEventsCompletedSubTabIndex<
    T = DesktopUiStateService['_roadEventsCompletedSubTabIndex$'],
    U = getObservableReturnType<T>
  >(v: U) {
    this._roadEventsCompletedSubTabIndex$.next(v as any);
    return this.roadEventsCompletedSubTabIndex;
  }

  updateRoadEventsPanelTabIndex<T = DesktopUiStateService['_roadEventsPanelTabIndex$'], U = getObservableReturnType<T>>(
    v: U
  ) {
    this._roadEventsPanelTabIndex$.next(v as any);
    return this.roadEventsPanelTabIndex;
  }

  updateIsHebrew<T = DesktopUiStateService['_isHebrew$'], U = getObservableReturnType<T>>(v: U) {
    this._isHebrew$.next(v as any);
    return this.isHebrew;
  }

  updateDisablePageScrollState<T = DesktopUiStateService['_disablePageScroll$'], U = getObservableReturnType<T>>(v: U) {
    this._disablePageScroll$.next(v as any);
    return this.disablePageScroll$;
  }

  updateIsRoadEventsSideBarOpened<
    T = DesktopUiStateService['_isRoadwaySideBarOpened$'],
    U = getObservableReturnType<T>
  >(v: U) {
    this._isRoadwaySideBarOpened$.next(v as any);
    return this.isRoadwaySideBarOpened;
  }

  updateExpendedSectionsState<T extends keyof ExpandedSections, U extends keyof ExpandedSections[T]>(
    layerType: T,
    key: U,
    value: ExpandedSections[T][U]
  ): void {
    this.canUpdateExpandedSections();
    this.expandedSections[layerType][key] = value;
  }

  setExpendedSectionsState(updateValue: Partial<ExpandedSections>) {
    this.canUpdateExpandedSections();
    if (!updateValue && typeof updateValue !== 'object') return;

    this.expandedSections = { ...this.expandedSections, ...updateValue };
    this.localStorageService.set(LocalStorageKeys.ExpandedSectionsStorage, this.expandedSections);

    return this.expandedSections;
  }

  updateEventListSort(listName: RoadEventsListTypes, eventListSortType: EventListSortType) {
    this[listName].next(eventListSortType);
    this.updateEventSortingPreferences(listName, eventListSortType);
  }

  updateCurrentSelectedListEntityId(id: number) {
    this._currentSelectedListEntityId$.next(id);
  }

  updateTriggerListScroll(id: number) {
    this._triggerListScroll$.next(id);
  }

  private canUpdateExpandedSections() {
    if (!this.isUserIdAvailable) throw new Error('Cannot update expanded sections before user Loaded');
  }

  private loadEventSortingPreferences() {
    this.splitService
      .isActiveFeatureToggle$(AppFeatureEnum.FE_PRIORITY_LIST_AND_FILTER)
      .pipe(skip(1), take(1))
      .subscribe(isPriorityOn => {
        let sorting: Record<RoadEventsListTypes, EventListSortType> = this.localStorageService.get(
          LocalStorageKeys.EventListSortingPreferences
        );
        if (!sorting) {
          this.localStorageService.set(LocalStorageKeys.EventListSortingPreferences, {
            [RoadEventsListTypes.NeedActionIncident]: EventListSortType.TimeNewFirst,
            [RoadEventsListTypes.InProgressIncidents]: EventListSortType.TimeNewFirst,
            [RoadEventsListTypes.InProgressTD]: EventListSortType.TimeNewFirst,
            [RoadEventsListTypes.CompletedIncidents]: EventListSortType.TimeNewFirst,
            [RoadEventsListTypes.CompletedTD]: EventListSortType.TimeNewFirst,
          });
          if (isPriorityOn) {
            this.localStorageService.set('firstTimeOverridePrioryFilter', true);
            this[RoadEventsListTypes.NeedActionIncident].next(EventListSortType.Priority);
          } else {
            this[RoadEventsListTypes.NeedActionIncident].next(EventListSortType.TimeNewFirst);
          }
        } else {
          if (isPriorityOn) {
            const prioryExists = this.localStorageService.get('firstTimeOverridePrioryFilter');
            if (!prioryExists) {
              this.localStorageService.set('firstTimeOverridePrioryFilter', true);
              sorting = this.updateEventSortingPreferences(
                RoadEventsListTypes.NeedActionIncident,
                EventListSortType.Priority
              );
              sorting[RoadEventsListTypes.NeedActionIncident] = EventListSortType.Priority;
            }
          } else {
            if (sorting[RoadEventsListTypes.NeedActionIncident] === EventListSortType.Priority) {
              sorting = this.updateEventSortingPreferences(
                RoadEventsListTypes.NeedActionIncident,
                EventListSortType.TimeNewFirst
              );
            }
          }

          this[RoadEventsListTypes.NeedActionIncident].next(sorting[RoadEventsListTypes.NeedActionIncident]);
          this[RoadEventsListTypes.InProgressIncidents].next(sorting[RoadEventsListTypes.InProgressIncidents]);
          this[RoadEventsListTypes.InProgressTD].next(sorting[RoadEventsListTypes.InProgressTD]);
          this[RoadEventsListTypes.CompletedIncidents].next(sorting[RoadEventsListTypes.CompletedIncidents]);
          this[RoadEventsListTypes.CompletedTD].next(sorting[RoadEventsListTypes.CompletedTD]);
        }
      });
  }

  private updateEventSortingPreferences(listName: RoadEventsListTypes, eventListSortType: EventListSortType) {
    const sorting: Record<RoadEventsListTypes, EventListSortType> = this.localStorageService.get(
      LocalStorageKeys.EventListSortingPreferences
    );
    if (!sorting) {
      console.error('Failed update sort preferences - no preferences found');
      return sorting;
    }
    sorting[listName] = eventListSortType;
    this.localStorageService.set(LocalStorageKeys.EventListSortingPreferences, sorting);
    return sorting;
  }
}
